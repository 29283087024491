import { createContext, useReducer, Dispatch, ReactNode } from 'react';

import { DiagramConfig } from 'types/processes';

enum ActionTypes {
  SET_DIAGRAM_CONFIG,
}

type Action = { type: ActionTypes.SET_DIAGRAM_CONFIG; payload: DiagramConfig };

type State = DiagramConfig;

const initialState: State = {
  symbols: {},
  connections: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_DIAGRAM_CONFIG:
      return {
        ...state,
        symbols: {
          ...state.symbols,
          ...action.payload.symbols,
        },
        connections: {
          ...state.connections,
          ...action.payload.connections,
        },
      };
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface IDiagramConfigContext {
  state: State;
  dispatch: Dispatch<Action>;
}

const DiagramConfigContext = createContext<IDiagramConfigContext | undefined>(undefined);

const DiagramConfigProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <DiagramConfigContext.Provider value={value}>{children}</DiagramConfigContext.Provider>;
};

export { ActionTypes as DiagramConfigActionTypes, DiagramConfigContext, DiagramConfigProvider };
