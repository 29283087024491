import { useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { DIAGRAM_ENVIRONMENTS, SYMBOL_WIDTH } from 'assets/constants/constants';
import ConnectorPorts from 'components/ConnectorPorts/ConnectorPorts';
import Selection from 'components/Selection/Selection';
import SymbolAttributes from 'components/Symbol/SymbolAttributes/SymbolAttributes';
import SymbolSelection from 'components/Symbol/SymbolSelection';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useOuterClick from 'hooks/useOuterClick';
import useSelection from 'hooks/useSelection';
import useSymbol from 'hooks/useSymbol';
import useTool from 'hooks/useTool';
import useValidation from 'hooks/useValidation';
import { Tool } from 'types/diagram';
import { ProcessStatus } from 'types/processes';
import { Symbol as SymbolType } from 'types/symbols';

import styles from './Symbol.module.scss';

const Symbol = (props: SymbolType) => {
  const {
    attributes,
    id,
    meta: { left, top, height, width },
    type,
    diagramLink,
    handleOptionClick,
    withinSymbolBoard,
    offset,
  } = props;
  const {
    bounds,
    diagramLanguage,
    dispatch,
    editableSymbol,
    grabAndMove,
    isCopiedSymbol,
    processData,
    selectedSymbolIds,
    ghostSymbol,
  } = useDiagramContext();
  const { handleMoveStart } = useDragAndDrop();
  const history = useHistory();
  const { handleSelect } = useSelection();
  const { handleOuterClick, onNameChange } = useSymbol();
  const { tool } = useTool();
  const { symbolsIdsWithErrors } = useValidation();
  const containerRef = useRef<HTMLDivElement>(null);
  const symbolNameInputRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [showAttributes, setShowAttributes] = useState(false);
  const { t } = useTranslation();

  const hasErrors = symbolsIdsWithErrors && symbolsIdsWithErrors.find((symbolId) => symbolId === id);
  useOuterClick(symbolNameInputRef, handleOuterClick, containerRef?.current);

  const symbolCoordinatesX = offset && offset.x > 0 ? left + offset.x : left;
  const symbolCoordinatesY = offset && offset.y > 0 ? top + offset.y : top;

  useEffect(() => {
    if (editableSymbol === id && textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [editableSymbol, id]);

  let cursor =
    tool !== Tool.CONNECT && !processData?.isPublishedVersion && processData?.status !== ProcessStatus.WORKFLOW
      ? 'pointer'
      : 'default';

  if (grabAndMove) cursor = '';

  const selectedSymbol = processData?.symbols?.find((symbol) => symbol.id === selectedSymbolIds[0]);

  return (
    <>
      <div
        className={`${styles.SymbolBackground} ${type} ${selectedSymbolIds?.includes(id) ? styles.Selected : ''} ${
          hasErrors ? styles.Error : ''
        } ${withinSymbolBoard ? styles.withinSymbolBoard : ''}`}
        id={id}
        onClick={(e) => {
          if (
            !processData?.isOnlyRead &&
            processData?.status !== ProcessStatus.WORKFLOW &&
            editableSymbol !== id &&
            !isCopiedSymbol.current
          ) {
            handleSelect(id, e);
          }
        }}
        onDoubleClick={() => {
          if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW) {
            dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: id });
          }
        }}
        onMouseDown={(e) => !processData?.isOnlyRead && editableSymbol !== id && !withinSymbolBoard && handleMoveStart(e, id)}
        onMouseLeave={() => setShowAttributes(false)}
        ref={containerRef}
        style={{
          left: `${withinSymbolBoard ? 0 : symbolCoordinatesX}em`,
          top: `${withinSymbolBoard ? 0 : symbolCoordinatesY}em`,
          height: `${height}em`,
          width: `${width > SYMBOL_WIDTH ? width : SYMBOL_WIDTH}em`,
          cursor: withinSymbolBoard ? '' : cursor,
        }}
      >
        <SymbolSelection id={id} />
        <TooltipComponent
          content={attributes[diagramLanguage]?.NAME || ''}
          cssClass={`mbc-tooltip nepos-tooltip ${attributes[diagramLanguage]?.NAME ? '-' : 'display-none'}`}
          position="RightCenter"
          showTipPointer={false}
        >
          <div
            className={`${styles.SymbolContainer} ${hasErrors ? styles.Error : ''} ${
              withinSymbolBoard ? styles.withinSymbolBoard : ''
            }`}
          >
            {editableSymbol === id ? (
              <div className={!withinSymbolBoard ? styles.SymbolNameInput : ''} ref={symbolNameInputRef}>
                {!withinSymbolBoard ? (
                  <textarea
                    onChange={(event) => {
                      onNameChange(id, event.target.value, textAreaRef.current);
                    }}
                    placeholder={t('diagram.objects.defaultName')}
                    ref={textAreaRef}
                    rows={1}
                    value={attributes[diagramLanguage]?.NAME}
                  />
                ) : (
                  attributes[diagramLanguage]?.NAME
                )}
              </div>
            ) : (
              <p
                className={diagramLink ? styles.SymbolNameLink : styles.SymbolName}
                onClick={(event) => {
                  if (
                    diagramLink &&
                    (processData?.isOnlyRead ||
                      (processData?.isPublishedVersion && diagramLink.processNumber) ||
                      processData?.status === ProcessStatus.WORKFLOW)
                  ) {
                    event.stopPropagation();
                    history.push(
                      `/${processData?.isPublishedVersion ? DIAGRAM_ENVIRONMENTS.PUBLISHED : DIAGRAM_ENVIRONMENTS.MODELING}/${
                        diagramLink.id
                      }`,
                    );
                  }
                }}
              >
                {attributes[diagramLanguage]?.NAME || t('diagram.objects.defaultName')}
              </p>
            )}
            <div className={`${styles.WrapperButtons} ${diagramLink?.type === 'EXTERNAL' ? styles.LinkExternalButton : ''}`}>
              {/* TODO: UNCOMMENT WHEN INTERFACES ARE READY
        <RoundButton className={styles.Interfaces} icon="di icon-link" id="symbol-interfaces" onClick={() => {}} text="7" /> */}
              {!processData?.isOnlyRead &&
                !processData?.isPublishedVersion &&
                processData?.status !== ProcessStatus.WORKFLOW &&
                diagramLink && (
                  <RoundButton
                    icon="di icon-unterebene-stapel"
                    id="symbol-diagram-link"
                    onClick={(event) => {
                      event.stopPropagation();
                      // If current diagram is SANDBOX, its child is also SANDBOX
                      const diagramUrl =
                        processData?.status === ProcessStatus.SANDBOX
                          ? DIAGRAM_ENVIRONMENTS.SANDBOX
                          : DIAGRAM_ENVIRONMENTS.MODELING;
                      history.push(`/${diagramUrl}/${diagramLink.id}`);
                    }}
                  />
                )}
              {(processData?.isOnlyRead ||
                processData?.isPublishedVersion ||
                processData?.status === ProcessStatus.WORKFLOW ||
                withinSymbolBoard) && (
                <RoundButton
                  className={styles.InfoDescriptionIcon}
                  icon="di icon-information"
                  id={`symbol-diagram-descr-ico-${id}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowAttributes((prv) => !prv);
                  }}
                />
              )}
              {showAttributes && (
                <SymbolAttributes attributes={attributes} id={id} symbolType={type} top={height} width={width} />
              )}
            </div>
          </div>
          <Selection onClick={handleOptionClick} symbolId={id} withinSymbolBoard={withinSymbolBoard} />
          <ConnectorPorts symbolId={id} />
        </TooltipComponent>
      </div>
      {selectedSymbol && selectedSymbol.id === id && ghostSymbol && (
        <div
          className={styles.EnlargeSymbol}
          style={{
            height: `${bounds?.height}em`,
            left: `${ghostSymbol}em`,
            top: `${withinSymbolBoard ? 0 : symbolCoordinatesY}em`,
          }}
        />
      )}
    </>
  );
};

export default Symbol;
