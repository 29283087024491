import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './SearchBar.module.scss';

const SearchBar = (props) => {
  const { t, loading } = props;
  const SearchBarRef = React.createRef();
  const SearchTextRef = React.createRef();
  const loadingClass = loading ? styles.LoadingClass : '';

  const handleFocus = (event) => {
    event.stopPropagation();

    if (!loading) {
      SearchBarRef.current.firstChild.focus();
    }
  };

  const handleSearch = () => {
    if (SearchTextRef.current.value.trim() && !loading) {
      props.sendSearch(SearchTextRef.current.value);
    }
  };

  const handleKeyPress = (event) => {
    if (SearchTextRef.current.value.trim() && !loading && event.key === 'Enter') {
      props.sendSearch(SearchTextRef.current.value);
    }
  };

  return (
    <div className={`${styles.SearchBar} ${loadingClass}`} id="search" onClick={(e) => handleFocus(e)} ref={SearchBarRef}>
      <input onKeyPress={handleKeyPress} placeholder={t('search')} ref={SearchTextRef} />
      <i className="icon-lupe-suche" onClick={handleSearch} />
    </div>
  );
};

export default withTranslation('common')(SearchBar);
