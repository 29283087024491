import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as Utils from '../../../assets/js/Utils';
import Card from './Card';
import styles from './Card.module.scss';

const Process = ({ id, nameMap, validFrom }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const title = nameMap[i18n.language.toUpperCase()];
  const date = Utils.getFormattedDate(validFrom);
  const cardDate =
    date !== '-' ? (
      <section>
        <span className={styles.Label}>{t('history.table.validFrom')}</span>
        <span>{date}</span>
      </section>
    ) : (
      ''
    );
  const content = <div className={styles.Content}>{cardDate}</div>;

  const goToProcess = () => history.push(`/published/${id}`);

  return <Card content={content} handleClick={goToProcess} id={id} title={title} />;
};

export default Process;
