import { ENVIRONMENTS, E2E } from './constants';

const { INT, PRO } = ENVIRONMENTS;

export const MANAGEMENT_PROCESSES = [
  {
    id: {
      [INT]: 11926,
      [PRO]: 9740,
      [E2E]: 15066,
    },
    processName: {
      EN: 'Manage business strategy',
      DE: 'Unternehmensstrategie steuern',
    },
    imgs: [
      {
        code: 'OK2100',
        userName: 'Ola Källenius',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/ola-kallenius-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11927,
      [PRO]: 9101,
      [E2E]: 15064,
    },
    processName: {
      EN: 'Manage  product strategy & projects',
      DE: 'Produktstrategie \n und -projekte steuern',
    },
    imgs: [
      {
        code: 'OK2100',
        userName: 'Ola Källenius',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/ola-kallenius-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11930,
      [PRO]: 8616,
      [E2E]: 15039,
    },
    processName: {
      EN: 'Manage suppliers',
      DE: 'Lieferanten managen',
    },
    imgs: [
      {
        code: 'MASCHA5',
        userName: 'Markus Schäfer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/markus-schafer-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11931,
      [PRO]: 8674,
      [E2E]: 15063,
    },
    processName: {
      EN: 'Manage product & process integrity',
      DE: 'Produkt- und Prozessintegrität absichern',
    },
    imgs: [
      {
        code: 'XR00981',
        userName: 'Jörg Burzer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/jorg-burzer-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 12386,
      [PRO]: 8463,
      [E2E]: 15062,
    },
    processName: {
      EN: 'Manage stocks, production program & sales',
      DE: 'Bestände, Produktions- programm und Absatz steuern',
    },
    imgs: [
      {
        code: 'XR00981',
        userName: 'Jörg Burzer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/jorg-burzer-face.png',
      },

      {
        code: 'WILHEHA',
        userName: 'Harald Wilhelm',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/harald-wilhelm-face.png',
      },
      {
        code: 'BFRANK',
        userName: 'Britta Seeger',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/britta-seeger-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11929,
      [PRO]: 9187,
      [E2E]: 15061,
    },
    processName: {
      EN: 'Manage markets & dealers',
      DE: 'Markt und Händler steuern',
    },
    imgs: [
      {
        code: 'BFRANK',
        userName: 'Britta Seeger',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/britta-seeger-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11928,
      [PRO]: 14850,
      [E2E]: 15060,
    },
    processName: {
      EN: 'Drive financial performance',
      DE: 'Unternehmen finanzwirtschaftlich steuern',
    },
    imgs: [
      {
        code: 'WILHEHA',
        userName: 'Harald Wilhelm',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/harald-wilhelm-face.png',
      },
    ],
  },
];

export const ENABLER_PROCESSES = [
  {
    id: {
      [INT]: 11919,
      [PRO]: 8449,
      [E2E]: 15058,
    },
    processName: {
      EN: 'Plan & control human resources',
      DE: 'Personal planen und steuern',
    },
    imgs: [
      {
        code: 'SKOHLEI',
        userName: 'Sabine Kohleisen',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/sabine-kohleisen-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11923,
      [PRO]: 12650,
      [E2E]: 15045,
    },
    processName: {
      EN: 'Support integrity & mitigate legal risks',
      DE: 'Integrität unterstützen und rechtliche Risiken minimieren',
    },
    imgs: [
      {
        code: 'RJUNGOB',
        userName: 'Renata Jungo Brüngger',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/renata-jungo-brungger-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11921,
      [PRO]: 8290,
      [E2E]: 15046,
    },
    processName: {
      EN: 'Deliver data & empower digitization',
      DE: 'Daten bereitstellen und Digitalisierung befähigen',
    },
    imgs: [
      {
        code: 'OK2100',
        userName: 'Ola Källenius',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/ola-kallenius-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11920,
      [PRO]: 9378,
      [E2E]: 15056,
    },
    processName: {
      EN: 'Provide plant-, equipment- & logistic-planning',
      DE: 'Werks-, Anlagen- und Logistikplanung durchführen',
    },
    imgs: [
      {
        code: 'XR00981',
        userName: 'Jörg Burzer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/jorg-burzer-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11922,
      [PRO]: 12649,
      [E2E]: 15050,
    },
    processName: {
      EN: 'Perform customer communication online/offline',
      DE: 'Kundenkommunikation online/ offline durchführen',
    },
    imgs: [
      {
        code: 'BFRANK',
        userName: 'Britta Seeger',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/britta-seeger-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11924,
      [PRO]: 14852,
      [E2E]: 15055,
    },
    processName: {
      EN: 'Ensure efficient financial transactions',
      DE: 'Effiziente finanzielle Transaktionen sicherstellen',
    },
    imgs: [
      {
        code: 'WILHEHA',
        userName: 'Harald Wilhelm',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/harald-wilhelm-face.png',
      },
    ],
  },
];

export const CORE_PROCESSES = [
  {
    id: {
      [INT]: 11912,
      [PRO]: 8807,
      [E2E]: 15059,
    },
    processName: {
      EN: '1. From idea to market readiness',
      DE: '1. Von der Idee bis zur Marktreife',
    },
    imgs: [
      {
        code: 'MASCHA5',
        userName: 'Markus Schäfer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/markus-schafer-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11913,
      [PRO]: 9330,
      [E2E]: 15053,
    },
    processName: {
      EN: '2. From offer to handover',
      DE: '2. Vom Angebot bis zur Übergabe',
    },
    imgs: [
      {
        code: 'XR00981',
        userName: 'Jörg Burzer',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/jorg-burzer-face.png',
      },
    ],
  },
  {
    id: {
      [INT]: 11914,
      [PRO]: 14851,
      [E2E]: 15054,
    },
    processName: {
      EN: '3. From takeover to end-of-life',
      DE: '3. Von der Übernahme bis End-of-Life',
    },
    imgs: [
      {
        code: 'BFRANK',
        userName: 'Britta Seeger',
        url: 'https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/landscape/britta-seeger-face.png',
      },
    ],
  },
];
