import { Fragment, useState, useRef, useEffect } from 'react';

import { TooltipComponent, TooltipEventArgs } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { ZOOM_LEVELS, ZOOM_TO_FIT, DIAGRAM_CATALOG_BOX_CLASS, SYMBOLS_WITHOUT_ATTRIBUTES } from 'assets/constants/constants';
import { getBounds } from 'assets/js/Utils';
import AttributesPanel from 'components/Attributes/AttributesPanel';
import Event from 'components/Event/Event';
import PaletteItem from 'components/PaletteItem/PaletteItem';
import ProcessInterface from 'components/ProcessInterface/ProcessInterface';
import ProcessStep from 'components/ProcessStep/ProcessStep';
import TextFieldNEPOS from 'components/TextFieldNEPOS/TextFieldNEPOS';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import MessageTag from 'components/UI/MessageTag/MessageTag';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import ZoomControl from 'components/ZoomControl/ZoomControl';
import {
  DiagramActionTypes,
  setIsTakingPicturesOnTheFly,
  setSymbolBoardsDisplayed,
  setSymbolObjectsToDisplay,
  startTakingPictures,
} from 'contexts/Diagram/DiagramContext';
import useConnector from 'hooks/useConnector';
import useDiagramConfig from 'hooks/useDiagramConfig';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLocalization from 'hooks/useLocalization';
import useSelection from 'hooks/useSelection';
import useTool from 'hooks/useTool';
import { PictureAction, Tool } from 'types/diagram';
import { ProcessStatus, ProcessType } from 'types/processes';
import { SymbolTypes } from 'types/symbols';

import LogicalGate from '../LogicalGate/LogicalGate';
import styles from './ToolbarNEPOS.module.scss';

const ToolbarNEPOS = () => {
  const {
    background,
    backgroundRef,
    boundsRef,
    containerRef,
    diagramLanguage,
    dispatch,
    fontSize,
    grabAndMove,
    isLoading,
    isTakingPictures,
    processData,
    undoRedoHistory,
    isDiagramPreparedForRelease,
    displayedSymbolBoards,
    selectedTools,
  } = useDiagramContext();
  const { updateSwimlanesConnectorsVertices } = useConnector();
  const { availableLanguages } = useLocalization();
  const { clearSelection, selectedSymbols } = useSelection();
  const [isAttributesPanelOpen, setIsAttributesPanelOpen] = useState(true);
  const paletteButtonRef = useRef<HTMLButtonElement>(null);
  const zoomRef = useRef<string>('');
  const { t } = useTranslation();
  const { tool, resetTool, toggleTool, toggleMultipleTool } = useTool();
  const isPaletteOpen = tool === Tool.ADD_SYMBOL;
  const isAnyNoteDisplayed = displayedSymbolBoards.length > 0;
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS;
  const areAttributesDisplayed = tool === Tool.DISPLAY_ATTRIBUTES;
  const areRolesDisplayed = selectedTools.includes(Tool.DISPLAY_ROLES);
  const areITSystemsDisplayed = selectedTools.includes(Tool.DISPLAY_IT_SYSTEMS);
  const areInputsDisplayed = selectedTools.includes(Tool.DISPLAY_INPUTS);
  const areOutputsDisplayed = selectedTools.includes(Tool.DISPLAY_OUTPUTS);
  const currentBounds = getBounds(
    areNotesDisplayed,
    processData?.symbols,
    processData?.isSwimlane,
    fontSize,
    displayedSymbolBoards,
  );
  const diagramConfig = useDiagramConfig();
  const checkEnvironment = useEnvironment();
  const { toolBarRef } = useDiagramContext();
  const { isFreezed } = useFeatureFlags();

  const beforeShowTooltip = (event: TooltipEventArgs) => {
    if (isPaletteOpen) event.cancel = true;
  };

  const updateFontSize = (size: number) => {
    // Keep the new fontSize inside our range
    const sizeInRange = Math.max(Math.min(size, ZOOM_LEVELS.MAX), ZOOM_LEVELS.MIN_DIAGRAM);
    if (sizeInRange !== fontSize) dispatch({ type: DiagramActionTypes.SET_FONT_SIZE, payload: sizeInRange });
  };

  const centerDiagram = (behavior: ScrollBehavior = 'auto', takePictures = false): void => {
    setTimeout(() => {
      boundsRef?.current?.scrollIntoView({ block: 'center', inline: 'center', behavior });
    }, 25);
    if (!takePictures) return;

    if (isTakingPictures.pictureAction === PictureAction.onTheFly) dispatch(setIsTakingPicturesOnTheFly(true));
    if (isTakingPictures.pictureAction === PictureAction.all) dispatch(startTakingPictures(diagramLanguage, availableLanguages));
  };

  // Find highest zoom that contains the diagram, starting with the max value
  const findZoom = (boundsWidth: number, boundsHeight: number, widthOffset = 0) => {
    if (!containerRef.current) return ZOOM_LEVELS.DEFAULT;
    const container = containerRef.current?.getBoundingClientRect();

    return (
      [...ZOOM_LEVELS.VALUES]
        .reverse()
        .find(
          (zoom) =>
            boundsWidth * zoom * ZOOM_LEVELS.PADDING < container.width - widthOffset &&
            boundsHeight * zoom * ZOOM_LEVELS.PADDING < container.height,
        ) || ZOOM_LEVELS.MIN_DIAGRAM
    );
  };

  const zoomToFit = (takePictures = false) => {
    if (!fontSize || !processData) return;

    let newFontSize = ZOOM_LEVELS.DEFAULT;

    if (processData.isSwimlane) {
      newFontSize = findZoom(currentBounds.width, currentBounds.height);
    } else if (processData.symbols.length > 0) {
      const [leftBox, rightBox] = document.getElementsByClassName(DIAGRAM_CATALOG_BOX_CLASS);
      const widthOffset =
        processData.status === ProcessStatus.WORKFLOW || processData.isPublishedVersion || isDiagramPreparedForRelease
          ? 0
          : leftBox.clientWidth + rightBox.clientWidth;

      newFontSize = findZoom(currentBounds.width, currentBounds.height, widthOffset);
    }

    zoomRef.current = ZOOM_TO_FIT;
    updateFontSize(newFontSize);
    centerDiagram('auto', takePictures);
    setTimeout(() => {
      dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: true });
    }, 100);
  };

  useEffect(() => {
    if (isTakingPictures.zoomToFit) zoomToFit(true);
  }, [isTakingPictures.zoomToFit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(
      setSymbolBoardsDisplayed(
        processData && areNotesDisplayed
          ? processData.symbols.filter((symbol) => symbol.type === SymbolTypes.SIPOC_ELEMENT).map((symbol) => symbol.id)
          : [],
      ),
    );
  }, [areNotesDisplayed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const symbolWithObjects =
      processData &&
      (areAttributesDisplayed || areRolesDisplayed || areITSystemsDisplayed || areInputsDisplayed || areOutputsDisplayed)
        ? processData.swimlanes
            .map((swimlane) => swimlane.symbols.filter((symbol) => symbol.type === SymbolTypes.PROCESS_STEP))
            .flat()
        : [];

    const filteredSymbolsIds = symbolWithObjects.map((symbol) => symbol.id);

    dispatch(setSymbolObjectsToDisplay(filteredSymbolsIds));
  }, [areAttributesDisplayed, areRolesDisplayed, areITSystemsDisplayed, areInputsDisplayed, areOutputsDisplayed]); // eslint-disable-line react-hooks/exhaustive-deps

  const SymbolTypeMap = {
    [SymbolTypes.SIPOC_ELEMENT]: <PaletteItem key={SymbolTypes.SIPOC_ELEMENT} type={SymbolTypes.SIPOC_ELEMENT} />,
    [SymbolTypes.SWIMLANE_ELEMENT]: <PaletteItem key={SymbolTypes.SWIMLANE_ELEMENT} type={SymbolTypes.SWIMLANE_ELEMENT} />,
    [SymbolTypes.TEXT]: <TextFieldNEPOS key={SymbolTypes.TEXT} type={SymbolTypes.PALETTE_TEXTFIELD} />,
    [SymbolTypes.AND_GATE]: (
      <LogicalGate id={SymbolTypes.AND_GATE} isPaletteItem key={SymbolTypes.AND_GATE} type={SymbolTypes.AND_GATE} />
    ),
    [SymbolTypes.OR_GATE]: (
      <LogicalGate id={SymbolTypes.OR_GATE} isPaletteItem key={SymbolTypes.OR_GATE} type={SymbolTypes.OR_GATE} />
    ),
    [SymbolTypes.PROCESS_INTERFACE]: (
      <ProcessInterface id={SymbolTypes.PROCESS_INTERFACE} isPaletteItem key={SymbolTypes.PROCESS_INTERFACE} />
    ),
    [SymbolTypes.PROCESS_STEP]: <ProcessStep id={SymbolTypes.PROCESS_STEP} isPaletteItem key={SymbolTypes.PROCESS_STEP} />,
    [SymbolTypes.XOR_GATE]: (
      <LogicalGate id={SymbolTypes.XOR_GATE} isPaletteItem key={SymbolTypes.XOR_GATE} type={SymbolTypes.XOR_GATE} />
    ),
    [SymbolTypes.PALETTE_TEXTFIELD]: '',
    [SymbolTypes.EVENT]: <Event id={SymbolTypes.EVENT} isPaletteItem key={SymbolTypes.EVENT} />,
  };

  useEffect(() => {
    if (isLoading === false && zoomRef.current === ZOOM_TO_FIT) {
      centerDiagram();
      zoomRef.current = '';
    }
  }, [background]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Zoom to fit when isLoading switches from true to false
    if (isLoading === false) zoomToFit();
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGrabAndMove = () => {
    if (!backgroundRef.current) return;
    clearSelection();
    backgroundRef.current.style.cursor = !grabAndMove ? 'grab' : 'default';
    dispatch({
      type: DiagramActionTypes.TOGGLE_GRAB_AND_MOVE,
      payload: !grabAndMove,
    });
  };

  const handleShowAttributes = () => {
    if (tool !== Tool.DISPLAY_ATTRIBUTES) {
      const multipleToolAttribute = [Tool.DISPLAY_ROLES, Tool.DISPLAY_IT_SYSTEMS, Tool.DISPLAY_INPUTS, Tool.DISPLAY_OUTPUTS];
      dispatch({ type: DiagramActionTypes.ADD_MULTIPLE_TOOL, payload: multipleToolAttribute });
    } else {
      dispatch({ type: DiagramActionTypes.ADD_MULTIPLE_TOOL, payload: [] });
    }
    toggleTool(Tool.DISPLAY_ATTRIBUTES);
    setTimeout(updateSwimlanesConnectorsVertices); // eslint-disable-line @typescript-eslint/no-implied-eval
  };
  const handleMultipleAttributes = (attributeSelected: Tool) => {
    toggleMultipleTool(attributeSelected);
    setTimeout(updateSwimlanesConnectorsVertices); // eslint-disable-line @typescript-eslint/no-implied-eval
    if (selectedTools.length === 4 || (selectedTools.length !== 4 && tool === Tool.DISPLAY_ATTRIBUTES)) {
      toggleTool(Tool.DISPLAY_ATTRIBUTES);
    }
  };

  useEffect(() => {
    dispatch({
      type: DiagramActionTypes.TOGGLE_GRAB_AND_MOVE,
      payload: false,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let toolBarButtons = [
    {
      icon: isAttributesPanelOpen ? 'di icon-pfeil-doppelt-chevron-links' : 'di icon-pfeil-doppelt-chevron-rechts',
      id: 'ToolbarNEPOS-symbol-attributes-button',
      onClick: () => selectedSymbols?.length === 1 && setIsAttributesPanelOpen(!isAttributesPanelOpen),
      className: styles.Button,
      disabled:
        isFreezed ||
        tool === Tool.DISPLAY_POSTITS ||
        selectedSymbols?.length !== 1 ||
        SYMBOLS_WITHOUT_ATTRIBUTES.includes(selectedSymbols[0].type),
      tooltipLabel: t(isAttributesPanelOpen ? 'hideAttributes' : 'showAttributes'),
    },
    {
      icon: 'di icon-plus-hinzufuegen',
      id: 'ToolbarNEPOS-add-symbol-button',
      onClick: () => {
        if (grabAndMove) {
          handleGrabAndMove();
        }
        toggleTool(Tool.ADD_SYMBOL);
      },
      className: `${styles.Button} ${isPaletteOpen ? styles.Active : ''}`,
      disabled: isFreezed || processData?.isOnlyRead || tool === Tool.DISPLAY_POSTITS || isAnyNoteDisplayed,
      ref: paletteButtonRef,
      tooltipLabel: t('add'),
    },
    {
      icon: 'di icon-verbindungslinie',
      id: 'ToolbarNEPOS-toggle-connector-button',
      onClick: () => toggleTool(Tool.CONNECT),
      className: `${styles.Button} ${tool === Tool.CONNECT ? styles.Active : ''}`,
      disabled: processData?.isOnlyRead || grabAndMove || tool === Tool.DISPLAY_POSTITS || isAnyNoteDisplayed,
      tooltipLabel: t('connectionMode'),
    },
    {
      icon: 'di icon-rueckgaengig-undo',
      id: 'ToolbarNEPOS-undo-button',
      onClick: () => {
        dispatch({ type: DiagramActionTypes.APPLY_UNDO_REDO, payload: undoRedoHistory.step - 1 });
        setTimeout(() =>
          updateSwimlanesConnectorsVertices({
            diagramConnectors: undoRedoHistory.stack[undoRedoHistory.step - 1].connectors,
          }),
        ); // eslint-disable-line @typescript-eslint/no-implied-eval
      },
      className: styles.Button,
      disabled: processData?.isOnlyRead || undoRedoHistory.step === 0,
      tooltipLabel: t('tool.undo'),
    },
    {
      icon: 'di icon-pfeil-wiederherstellen-redo',
      id: 'ToolbarNEPOS-redo-button',
      onClick: () => {
        dispatch({ type: DiagramActionTypes.APPLY_UNDO_REDO, payload: undoRedoHistory.step + 1 });
        setTimeout(() =>
          updateSwimlanesConnectorsVertices({
            diagramConnectors: undoRedoHistory.stack[undoRedoHistory.step + 1].connectors,
          }),
        ); // eslint-disable-line @typescript-eslint/no-implied-eval
      },
      className: styles.Button,
      disabled: processData?.isOnlyRead || undoRedoHistory.step === undoRedoHistory.stack.length - 1,
      tooltipLabel: t('tool.redo'),
    },
    { id: 'ToolbarNEPOS-zoom', tool: <ZoomControl beforeShowTooltip={beforeShowTooltip} key="ToolbarNEPOS-zoom" /> },
    {
      icon: 'di icon-zoom-anpassen-fokus',
      id: 'ToolbarNEPOS-zoom-to-fit',
      onClick: () => zoomToFit(),
      className: styles.Button,
      tooltipLabel: t('tool.zoomToFit'),
    },
    {
      icon: 'di icon-hand-bewegen',
      id: 'ToolbarNEPOS-grab-and-move',
      onClick: () => handleGrabAndMove(),
      className: `${styles.Button} ${grabAndMove ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: t('move'),
    },
    {
      icon: areNotesDisplayed ? 'di icon-klemmbrett-aufgabe-check' : 'di icon-klemmbrett-aufgabe-liste',
      id: 'ToolbarNEPOS-display-all-post-its',
      onClick: () => {
        toggleTool(Tool.DISPLAY_POSTITS);
        setIsAttributesPanelOpen(false);
      },
      className: `${styles.Button} ${areNotesDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT || processData?.symbols.length === 0 || processData?.isOnlyRead,
      tooltipLabel: areNotesDisplayed ? t('tool.hidePostIts') : t('tool.showPostIts'),
    },
    {
      icon: areAttributesDisplayed ? 'icon-pfeil-inhalte-reduzieren' : 'di icon-pfeil-inhalte-erweitern',
      id: 'ToolbarNEPOS-swimlane-attributes',
      onClick: () => {
        handleShowAttributes();
      },
      className: `${styles.Button} ${areAttributesDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: areAttributesDisplayed ? t('hideAttributes') : t('showAttributes'),
    },
    {
      icon: 'icon-person',
      id: 'ToolbarNEPOS-swimlane-roles',
      onClick: () => {
        handleMultipleAttributes(Tool.DISPLAY_ROLES);
      },
      className: `${styles.Button} ${styles.Small} ${areRolesDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: areRolesDisplayed ? t('hideRoles') : t('showRoles'),
    },
    {
      icon: 'icon-computer',
      id: 'ToolbarNEPOS-swimlane-IT-System',
      onClick: () => {
        handleMultipleAttributes(Tool.DISPLAY_IT_SYSTEMS);
      },
      className: `${styles.Button} ${styles.Small} ${areITSystemsDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: areITSystemsDisplayed ? t('hideITSystems') : t('showITSystems'),
    },
    {
      icon: 'icon-herunterladen',
      id: 'ToolbarNEPOS-swimlane-input',
      onClick: () => {
        handleMultipleAttributes(Tool.DISPLAY_INPUTS);
      },
      className: `${styles.Button} ${styles.Small} ${areInputsDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: areInputsDisplayed ? t('hideInput') : t('showInput'),
    },
    {
      icon: 'icon-hochladen',
      id: 'ToolbarNEPOS-swimlane-output',
      onClick: () => {
        handleMultipleAttributes(Tool.DISPLAY_OUTPUTS);
      },
      className: `${styles.Button} ${styles.Small} ${areOutputsDisplayed ? styles.Active : ''}`,
      disabled: tool === Tool.CONNECT,
      tooltipLabel: areOutputsDisplayed ? t('hideOutput') : t('showOutput'),
    },
  ];

  const separators = [
    'ToolbarNEPOS-show-symbol-attributes-button',
    'ToolbarNEPOS-toggle-connector-button',
    'ToolbarNEPOS-paste-text-button',
    'ToolbarNEPOS-redo-button',
    'ToolbarNEPOS-grab-and-move',
  ];

  const onlyReadButtons = ['ToolbarNEPOS-zoom', 'ToolbarNEPOS-zoom-to-fit', 'ToolbarNEPOS-grab-and-move'];

  const onlyReadButtonsSwimlane = [
    'ToolbarNEPOS-zoom',
    'ToolbarNEPOS-zoom-to-fit',
    'ToolbarNEPOS-grab-and-move',
    'ToolbarNEPOS-swimlane-attributes',
    'ToolbarNEPOS-swimlane-roles',
    'ToolbarNEPOS-swimlane-IT-System',
    'ToolbarNEPOS-swimlane-input',
    'ToolbarNEPOS-swimlane-output',
  ];

  toolBarButtons =
    (processData?.isOnlyRead || processData?.status === ProcessStatus.WORKFLOW) && processData?.isSwimlane
      ? toolBarButtons.filter((button) => onlyReadButtonsSwimlane.includes(button.id))
      : toolBarButtons;

  toolBarButtons =
    (processData?.isOnlyRead || processData?.status === ProcessStatus.WORKFLOW) && !processData?.isSwimlane
      ? toolBarButtons.filter((button) => onlyReadButtons.includes(button.id))
      : toolBarButtons;

  toolBarButtons =
    processData?.isSwimlane || !checkEnvironment('sandbox')
      ? toolBarButtons.filter((button) => button.id !== 'ToolbarNEPOS-display-all-post-its')
      : toolBarButtons;

  toolBarButtons = !processData?.isSwimlane
    ? toolBarButtons.filter((button) => !button.id.includes('ToolbarNEPOS-swimlane'))
    : toolBarButtons;

  return (
    <>
      <div className={styles.Container} ref={toolBarRef}>
        {toolBarButtons.map((button) =>
          button.tool ? (
            button.tool
          ) : (
            <Fragment key={button.id}>
              <TooltipComponent
                beforeOpen={beforeShowTooltip}
                content={button.tooltipLabel}
                cssClass="mbc-tooltip nepos-tooltip"
                position="BottomCenter"
                showTipPointer={false}
              >
                <RoundButton {...button} />
              </TooltipComponent>
              {separators.includes(button.id) ? <div className={styles.Separator} /> : ''}
            </Fragment>
          ),
        )}
        <div className={styles.Messages}>
          {processData?.isOnlyRead && !isFreezed && !processData?.isPublishedVersion && (
            <MessageTag
              dataQA="diagram-permission-message"
              icon="icon-schloss-sperren"
              message={t(`diagram.${processData.onlyRead?.reason}`, {
                diagramType: t(`${processData?.type}`),
                userBlock: processData.onlyRead?.usedBy,
              })}
            />
          )}
        </div>
        {isPaletteOpen && (
          <DropdownPanel
            close={resetTool}
            parentRef={paletteButtonRef}
            style={{ zIndex: 1000 }}
            width="unset"
            wrapperClassName={styles.PalettePanel}
          >
            <div className={styles.SymbolsContainer}>
              {processData?.type === ProcessType.SIPOC && diagramConfig.symbols.SIPOC?.map((symbol) => SymbolTypeMap[symbol])}
              {processData?.type === ProcessType.SWIMLANE &&
                diagramConfig.symbols.SWIMLANE?.map((symbol) => {
                  return (
                    <Fragment key={`fragment-${symbol}`}>
                      {SymbolTypeMap[symbol]}
                      {(symbol === SymbolTypes.SWIMLANE_ELEMENT || symbol === SymbolTypes.XOR_GATE) && (
                        <div className={styles.Separator} />
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </DropdownPanel>
        )}
      </div>
      {!processData?.isOnlyRead &&
        isAttributesPanelOpen &&
        selectedSymbols?.length === 1 &&
        !SYMBOLS_WITHOUT_ATTRIBUTES.includes(selectedSymbols[0].type) && <AttributesPanel symbolType={selectedSymbols[0].type} />}
    </>
  );
};

export default ToolbarNEPOS;
