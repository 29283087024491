import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { LANGUAGE, APP_VERSION, storageLabels } from 'assets/constants/constants';
import CheckboxPlain from 'components/UI/CheckBox/CheckboxPlain';
import Dropdown from 'components/UI/Dropdown/Dropdown';
import useLocalization from 'hooks/useLocalization';
import titleService from 'services/titleService';

import 'App.scss';
import styles from './Settings.module.scss';

const Settings = (props) => {
  const { updateLanguage, updateRememberLang } = props;
  const { t, i18n } = useTranslation();
  const { availableLanguages } = useLocalization();

  useEffect(() => {
    titleService.updatePageTitle(t('user.settings'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageChanged = (language) => {
    if (!language) return;
    updateLanguage(language);
  };

  return (
    <div className={styles.Settings}>
      <div className={styles.Block} id="language">
        <h4>{t('languageLabel')}</h4>
        <div className={`dropdown ${styles.DropdownSection}`}>
          <Dropdown
            code={LANGUAGE}
            id="language-selector"
            label={t('settings.userInterfaceLanguage')}
            onChange={languageChanged}
            options={availableLanguages}
            optionTranslationKey="language"
            placeholder={t('settings.placeholderLanguage')}
            value={i18n.language}
          />
        </div>
        <div className={styles.DropdownSection}>
          <label htmlFor="settings-LangCheckbox">{t('settings.defaultLanguageLabel')}</label>
          <CheckboxPlain
            checked={localStorage.getItem(storageLabels.rememberAppLang)}
            getChecked={updateRememberLang}
            id="settings-LangCheckbox"
            key="settings-LangCheckbox"
            label={t('settings.rememberLanguage')}
          />
        </div>
      </div>
      <div className={styles.Block} id="category">
        <h4>{t('settings.version')}</h4>
        <p>{APP_VERSION}</p>
      </div>
    </div>
  );
};

export default Settings;
