import { useState, useEffect, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { handleServiceError } from 'assets/js/serviceUtils';
import { downloadFile, getName, getTooltipText, getWarningIcon } from 'assets/js/Utils';
import Pill from 'components/ObjectCatalogBox/Pill/Pill';
import ShowMoreBox, { ShowMoreElement } from 'components/ShowMoreBox/ShowMoreBox';
import DialogAdminDocsLinks from 'components/UI/DialogAdminDocsLinks/DialogAdminDocsLinks';
import TextButton from 'components/UI/TextButton/TextButton';
import useActivitySpecification from 'hooks/useActivitySpecification';
import useActivitySpecificationContext from 'hooks/useActivitySpecificationContext';
import useDiagramContext from 'hooks/useDiagramContext';
import { getAllActivityDocuments } from 'services/documentation';
import { ActivityAditionalInformation, DocLinkResponse, DocLinkTypes, Document, Link } from 'types/activitySpecification';
import { Field, Chip } from 'types/forms';
import { SymbolObject } from 'types/symbols';

import { mapDocumentToChip, mapFieldToDoc, mapFieldToLink, mapLinkToChip } from './ActivitySpecification.mapper';
import styles from './ActivitySpecification.module.scss';

type Props = {
  fields: Field[];
  roles: SymbolObject[];
  itSystems: SymbolObject[];
  idActivity: number;
  processNumber?: string;
  version?: string;
};

const ActivitySpecification = ({ fields, roles, itSystems, idActivity, processNumber, version }: Props) => {
  const { t } = useTranslation();
  const { diagramLanguage } = useDiagramContext();
  const activityFields = useMemo(() => fields.slice(0, 2), [fields]);
  const processFields = useMemo(() => fields.slice(2, 8), [fields]);
  const MAX_ACTIVITY_ITSYSTEMS = 4;
  const { getActivityDocument, setDocuments, setLinks, removeDocument, removeLink, setVersion, setProcessId } =
    useActivitySpecification();
  const { links, documents } = useActivitySpecificationContext();

  const [adminDocsLinksType, setAdminDocsLinksType] = useState<DocLinkTypes | undefined>();

  useEffect(() => {
    if (links.length || documents.length) return;
    const getLinksAndDocs = (value: DocLinkResponse, type: DocLinkTypes): { links: Link[]; docs: Document[] } =>
      value
        ? {
            links: value.links.map((v) => mapFieldToLink(v, type)),
            docs: value.docs.map((v) => mapFieldToDoc(v, type)),
          }
        : { links: [], docs: [] };
    const activityLinksAndDocs = processFields.reduce(
      (linksDocsFound: { links: Link[]; docs: Document[] }, field: Field) => {
        const linksAndDocs = getLinksAndDocs(field.value as unknown as DocLinkResponse, field.code.toString() as DocLinkTypes);
        return { links: [...linksDocsFound.links, ...linksAndDocs.links], docs: [...linksDocsFound.docs, ...linksAndDocs.docs] };
      },
      { links: [], docs: [] },
    );

    setLinks(activityLinksAndDocs.links.flat());
    setDocuments(activityLinksAndDocs.docs.flat());
  }, [processFields, links.length, documents.length, setDocuments, setLinks]);

  useEffect(() => {
    if (processNumber) setProcessId(processNumber);
  }, [setProcessId, processNumber]);

  useEffect(() => {
    if (version) setVersion(version);
  }, [setVersion, version]);

  const mapObjectsToShowMoreElement = (object: SymbolObject): ShowMoreElement => ({
    value: getName(object, diagramLanguage, t) || '',
    stateIcon: object?.temporaryText ? 'computer-angefragt' : 'computer',
  });

  const handleRemoveChip = (typeFile: string, chip: Chip) => {
    if (chip?.code === ActivityAditionalInformation.FILE) {
      const docToRemove = documents.find((document) => document.name === chip.displayName && document.type === typeFile);
      if (docToRemove) removeDocument(docToRemove, idActivity);
    } else if (chip?.code === ActivityAditionalInformation.LINK) {
      const linkToRemove = links.find((link) => link.name === chip.displayName && link.type === typeFile);
      if (linkToRemove) removeLink(linkToRemove, idActivity);
    }
  };

  const handleOnClickChip = useCallback(
    (typeFile: DocLinkTypes, chip: Chip) => {
      if (chip.code === ActivityAditionalInformation.LINK) {
        const includedProtocol = chip.link?.toLowerCase().includes('https://');
        const link = includedProtocol ? chip.link : `//${chip.link}`;
        window.open(link, '_blank');
      } else if (chip.code === ActivityAditionalInformation.FILE) {
        getActivityDocument(chip.displayName, typeFile, idActivity);
      }
    },
    [idActivity, getActivityDocument],
  );

  const getChips = useCallback(
    (type: DocLinkTypes): Chip[][] => [
      links.filter((link) => link.type === type).map(mapLinkToChip),
      documents.filter((document) => document.type === type).map(mapDocumentToChip),
    ],
    [links, documents],
  );

  const getAllDocuments = useCallback(
    () =>
      getAllActivityDocuments(idActivity, processNumber, version)
        .then((res) => {
          const binaryData = [res.data];
          const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
          downloadFile(url, `documents-activity-${idActivity}.zip`, res.headers['content-disposition']);
        })
        .catch(handleServiceError),
    [idActivity, processNumber, version],
  );

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Center}>
          <h4>{t('activityLevel.specification', { lng: diagramLanguage })}</h4>
          <section>
            <div className={styles.DataBox}>
              <div className={styles.Label}>
                <i className="di icon-person" />
                <p> {t('activityLevel.necessary-roles', { lng: diagramLanguage })}</p>
              </div>
              {roles.map((role) => (
                <Pill
                  className={styles.Pill}
                  icon={role.temporaryText ? 'person-konturlinie-angefragt' : 'person'}
                  iconTooltip={{
                    icon: `${getWarningIcon(role)} ${styles.SecondaryIcon}`,
                    content: t(getTooltipText(role, t)),
                  }}
                  key={role.id}
                  text={getName(role, diagramLanguage, t)}
                />
              ))}
            </div>
            <div className={styles.DataBox}>
              <div className={styles.Label}>
                <i className="di icon-computer" />
                <p>{t('activityLevel.necessary-it-system', { lng: diagramLanguage })}</p>
              </div>
              {itSystems.slice(0, MAX_ACTIVITY_ITSYSTEMS).map((itSystem) => (
                <Pill
                  className={styles.Pill}
                  icon={itSystem.temporaryText ? 'computer-angefragt' : 'computer'}
                  iconTooltip={{
                    icon: `${getWarningIcon(itSystem)} ${styles.SecondaryIcon}`,
                    content: t(getTooltipText(itSystem, t)),
                  }}
                  key={itSystem.id}
                  text={getName(itSystem, diagramLanguage, t)}
                />
              ))}
              {itSystems.length > MAX_ACTIVITY_ITSYSTEMS && (
                <ShowMoreBox elements={itSystems.slice(MAX_ACTIVITY_ITSYSTEMS).map(mapObjectsToShowMoreElement)} />
              )}
            </div>
            {activityFields?.map(({ Component, ...fieldProps }) => (
              <Component {...fieldProps} />
            ))}
          </section>
          <div className={styles.TitleWithButton}>
            <h4>{t('activityLevel.additional-information', { lng: diagramLanguage })}</h4>
            <TextButton
              icon="di icon-herunterladen"
              id="download-all-attachments-button"
              onClick={getAllDocuments}
              text={t('attachments.downloadAll', { lng: diagramLanguage })}
            />
          </div>
          <section className={styles.ProcessFields}>
            {processFields?.map(({ Component, ...fieldProps }) => (
              <Component
                {...fieldProps}
                chips={getChips(fieldProps.code.toString() as DocLinkTypes)}
                className={!fieldProps.value ? styles.FixedHeight : ''}
                clearAll={() => {}}
                handleOnClickChip={(chip: Chip) => handleOnClickChip(fieldProps.code.toString() as DocLinkTypes, chip)}
                handleRemoveChip={(chip: Chip) => handleRemoveChip(fieldProps.code, chip)}
                isDoubleValueInput
                key={fieldProps.id}
                onClickPlusIcon={() => setAdminDocsLinksType(fieldProps.code.toString() as DocLinkTypes)}
              />
            ))}
          </section>
        </div>
      </div>
      {adminDocsLinksType && (
        <DialogAdminDocsLinks
          close={() => {
            setAdminDocsLinksType(undefined);
          }}
          idActivity={idActivity}
          type={adminDocsLinksType}
        />
      )}
    </>
  );
};

export default ActivitySpecification;
