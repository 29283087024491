import { useEffect, useState } from 'react';

import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, SORT_ASC, SORT_DESC } from 'assets/constants/constants';
import useError from 'hooks/useError';

import useUpdateEffect from './useUpdateEffect';

const usePagination = ({ getResults, pageSize, initialPage = DEFAULT_INITIAL_PAGE, auxFetchParam = undefined }) => {
  const [page, setPage] = useState(initialPage);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState({ filter: '', order: SORT_ASC });
  const [totalPages, setTotalPages] = useState();
  const [totalResults, setTotalResults] = useState('-');
  const [filters, setFilters] = useState({});

  const { handleServiceError } = useError();

  const fetchResults = () => {
    if (!getResults) return;

    const params = {
      page: initialPage === 0 ? page : page - 1,
      ...(searchText ? { search: searchText } : {}),
      size: pageSize || DEFAULT_PAGE_SIZE,
      ...(sorting.filter ? sorting : {}),
      ...filters,
    };
    setIsLoading(true);
    getResults(params, auxFetchParam)
      .then((parsedResponse) => {
        setIsLoading(false);
        setResults(parsedResponse.results);
        setTotalPages(parsedResponse.numberOfPages);
        setTotalResults(parsedResponse.totalResults);
      })
      .catch(handleServiceError);
  };

  useUpdateEffect(() => {
    if (!getResults) return;
    if (page === initialPage) {
      fetchResults();

      return;
    }
    setPage(initialPage);
  }, [getResults]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!getResults) return;
    fetchResults();
  }, [page, searchText, sorting, filters, Object.keys(filters).length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersChange = (newFilters) => {
    const filterKey = Object.keys(newFilters)[0];
    let allFilters = filters;

    if (newFilters[filterKey]) {
      allFilters = { ...filters, ...newFilters };
    } else {
      delete allFilters[filterKey];
    }

    setFilters(allFilters);
    setPage(initialPage);
  };

  const resetFilter = (filter) => {
    const newFilters = { ...filters };
    delete newFilters[filter];
    setFilters(newFilters);
    setPage(initialPage);
  };

  const resetFilters = () => {
    setFilters({});
    setPage(initialPage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
    setPage(initialPage);
  };

  const sortTable = (filter) => {
    if (sorting.filter !== filter) setSorting({ filter, order: SORT_ASC });
    else if (sorting.order === SORT_ASC) setSorting({ ...sorting, order: SORT_DESC });
    else setSorting({ ...sorting, order: SORT_ASC });
    setPage(initialPage);
  };

  return {
    fetchResults,
    filters,
    handleFiltersChange,
    handleSearchChange,
    resetFilter,
    resetFilters,
    isLoading,
    page,
    results,
    searchText,
    setPage,
    sorting,
    sortTable,
    totalPages,
    totalResults,
  };
};

export default usePagination;
