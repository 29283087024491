import ConnectorSelection from 'components/ConnectorSelection/ConnectorSelection';
import useConnector from 'hooks/useConnector';
import useDiagramContext from 'hooks/useDiagramContext';
import useSelection from 'hooks/useSelection';
import useTool from 'hooks/useTool';
import { Connector as ConnectorType, ConnectorDirectionTypes, ConnectorPorts, ConnectorVertex } from 'types/connectors';
import { Tool } from 'types/diagram';
import { ProcessType } from 'types/processes';

import styles from './Connector.module.scss';

const Connector = (props: ConnectorType) => {
  const { source, target, vertices } = props;
  const { fontSize, ghostConnector, grabAndMove, processData, selectedConnectorId, factors } = useDiagramContext();
  const { handleSelectConnector } = useSelection();
  const { tool } = useTool();
  const { sourceCoordinates, recalculateLastVertex, targetCoordinates } = useConnector(props);
  let newVertices: ConnectorVertex[] | undefined = vertices;

  if (processData?.type === ProcessType.SWIMLANE && vertices?.length > 0) {
    newVertices = recalculateLastVertex({
      vertices,
      connectorTargetCoordinates: targetCoordinates,
    });
  }

  const handleClick = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    event.stopPropagation();
    if (processData?.isOnlyRead) return;
    handleSelectConnector(source, target);
  };

  const isHidden =
    (ghostConnector?.source.id === source.id && ghostConnector.target.id === target.id) ||
    (source.node && !document.body.contains(source.node)) ||
    (target.node && !document.body.contains(target.node));
  const isSelected = selectedConnectorId?.sourceId === source.id && selectedConnectorId?.targetId === target.id;
  let cursor = tool !== Tool.CONNECT ? 'pointer' : 'default';
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS;

  if (grabAndMove || areNotesDisplayed) cursor = '';

  const getArrow = () => {
    if (!targetCoordinates) return '';

    const { left, top } = targetCoordinates;
    const modifier = 0.5 * (isSelected ? 1.3 : 1);

    // ConnectorPorts.BOTTOM
    let triangle = `${left},${top} ${left + modifier},${top + modifier} ${left - modifier},${top + modifier}`;

    if (target.port === ConnectorPorts.RIGHT) {
      triangle = `${left},${top} ${left + modifier},${top - modifier} ${left + modifier},${top + modifier}`;
    } else if (target.port === ConnectorPorts.TOP) {
      triangle = `${left},${top} ${left + modifier},${top - modifier} ${left - modifier},${top - modifier}`;
    } else if (target.port === ConnectorPorts.LEFT) {
      triangle = `${left},${top} ${left - modifier},${top - modifier} ${left - modifier},${top + modifier}`;
    }

    return triangle;
  };

  // SYMBOL_WIDTH
  return sourceCoordinates && targetCoordinates ? (
    <g className={isSelected ? styles.Selected : ''}>
      <path
        className={`${styles.Line} ${isHidden ? styles.Hidden : ''}`}
        d={`M ${sourceCoordinates.left},${sourceCoordinates.top}
          ${newVertices?.map(
            ({ direction, coordinate }) =>
              `${direction} ${
                areNotesDisplayed
                  ? coordinate *
                    (direction === ConnectorDirectionTypes.VERTICAL ? factors.postItHeightFactor : factors.postItWidthFactor)
                  : coordinate
              }`,
          )}
          L ${targetCoordinates.left},${targetCoordinates.top}`}
        strokeWidth={2 / (fontSize || 2)}
      />
      <path
        className={styles.ConnectorClickableArea}
        d={`M ${sourceCoordinates.left},${sourceCoordinates.top}
          ${vertices.map(
            ({ direction, coordinate }) =>
              `${direction} ${
                areNotesDisplayed
                  ? coordinate *
                    (direction === ConnectorDirectionTypes.VERTICAL ? factors.postItHeightFactor : factors.postItWidthFactor)
                  : coordinate
              }`,
          )}
          L ${targetCoordinates.left},${targetCoordinates.top}`}
        id={`${source.id}-${target.id}`}
        onClick={handleClick}
        strokeWidth={(2 / (fontSize || 2)) * 10}
        style={{ cursor }}
      />
      {!isHidden && <polygon className={styles.Arrow} points={getArrow()} />}
      <ConnectorSelection className={isHidden ? styles.Hidden : ''} {...props} />
    </g>
  ) : null;
};

export default Connector;
