import { Link } from 'types/activitySpecification';
import { Language } from 'types/config';
import { Attributes } from 'types/forms';
import { ProcessType, ProcessDataRequest } from 'types/processes';

import { axiosInstance } from './serviceConstants';

const designUrl = '/backend/ms-design/api/v1';
const designUrlV2 = '/backend/ms-design/api/v2';
const designUrlV3 = '/backend/ms-design/api/v3';

export const getDiagram = (id: string) => axiosInstance.get(`${designUrl}/diagram/${id}`);

export const getPublishedDiagram = (processNumber: string, version: string = 'last') =>
  axiosInstance.get(`${designUrlV2}/diagram/published/${processNumber}/${version}`);

export const createDiagram = (data: { modelers?: string[]; symbol: string; type: ProcessType }) =>
  axiosInstance.post(`${designUrl}/diagram/create`, data);

export const createSandbox = (data: { name: string; selected: boolean; id: string }) =>
  axiosInstance.post(`${designUrl}/diagram/create/sandbox`, data);

export const getImportSandboxDiagrams = () => axiosInstance.get(`${designUrl}/diagram/sandbox/import`);

export const deleteDiagram = (diagramId: string, confirmationString: string) =>
  axiosInstance.delete(`${designUrl}/diagram/send-recycle-bin/${diagramId}/`, { data: { confirmationString } });

export const deleteSandboxDiagram = (diagramId: string, confirmationString: string) =>
  axiosInstance.delete(`${designUrl}/diagram/${diagramId}`, { data: { confirmationString } });

export const getAvailableInterfaces = (diagramId: string, params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${designUrl}/diagram/${diagramId}/interface/available?${searchParams}`);
};

export const addInterfaces = (diagramId: string, interfacesIds: string[]) =>
  axiosInstance.post(`${designUrl}/diagram/${diagramId}/interface`, { interfaces: interfacesIds });

export const deleteInterfaces = (diagramId: string, interfacesIds: string[]) =>
  axiosInstance.delete(`${designUrl}/diagram/${diagramId}/interface/${interfacesIds}`);

export const saveDiagram = (id: string, data: ProcessDataRequest) => axiosInstance.post(`${designUrl}/diagram/${id}`, data);

export const findAllByObjectType = (type: string, params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${designUrlV3}/objectCatalog?${searchParams}&type=${type}`);
};

export const getAllByObjectType = (type: string, params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${designUrl}/diagram/by-type/${type}?${searchParams}`);
};

export const createObjectCatalog = (
  data: { attributes: Attributes; responsibleArea?: string; type: string; idSourceDiagram: string },
  isRequest?: boolean,
) => axiosInstance.post(`${designUrlV2}/objectCatalog`, data, { params: { isRequest } });

export const updateObjectCatalog = (data: { attributes: Attributes; responsibleArea: string; type: string }, idObject?: number) =>
  axiosInstance.put(`${designUrlV2}/objectCatalog/${idObject}`, data);

export const approveObjectCatalog = (id: number, idTask: number, type: number, attributes: Attributes) => {
  const params = {
    idTask,
    type,
  };
  const data = {
    attributes,
  };
  return axiosInstance.put(`${designUrlV2}/objectCatalog/${id}/approve`, data, { params: { ...params } });
};

export const inUseDiagram = (id: string, inUse: boolean) =>
  axiosInstance.put(`${designUrl}/diagram/in-use?idDiagram=${id}&inUse=${inUse}`);

export const getObject = (id: string) => axiosInstance.get(`${designUrlV2}/objectCatalog/${id}`);

export const genDocOnTheFly = (extension: string, data: { idDiagram: string; images: string[] }, language: Language) =>
  axiosInstance.post(`${designUrl}/diagram/gen-docs/on-the-fly/${extension}`, data, {
    responseType: 'blob',
    headers: { 'Accept-Language': language },
  });

export const importSandbox = (data: { id: number; idSymbol: string; modelers: string[] }) =>
  axiosInstance.put(`${designUrlV2}/diagram/sandbox/import`, data);

export const getProcessLevels = (id: string, environment: string) =>
  axiosInstance.get(`${designUrl}/diagram/process-level/${id}?environment=${environment}`);

export const getSandboxDiagramsToImport = (diagramType: string) =>
  axiosInstance.get(`${designUrlV2}/diagram/sandbox/import/${diagramType}`);

export const moveDiagram = (body: { idDiagram: number; idSymbol: string }) =>
  axiosInstance.post(`${designUrlV2}/diagram/move`, body);

export const getPublishedVcdsAndEpcs = () => axiosInstance.get(`${designUrl}/diagram/published/epc-vcd`);

export const updateActivitySpecification = (body: {
  id: number;
  attributes: Attributes;
  predecessors: string[];
  successors: string[];
}) => axiosInstance.patch(`${designUrlV2}/diagram/swimlanes/activity-specification/${body.id}`, body);

export const getActivityValues = (id: string) =>
  axiosInstance.get(`${designUrlV2}/diagram/swimlanes/symbol?idSymbolFront=${id} `);

export const deleteActivitySpecification = (body: { id: number; confirmationString: string }) =>
  axiosInstance.delete(`${designUrlV2}/diagram/swimlanes/activity-specification/${body.id}`, {
    data: { confirmationString: body.confirmationString },
  });

export const checkIsSymbolPredecessorOrSuccessor = (idSymbolFront: string) =>
  axiosInstance.get(`${designUrl}/symbols/${idSymbolFront}/check-delete-swimlane-symbol `);

export const addActivityLink = (link: Link, idActivity: number) =>
  axiosInstance.put(`${designUrlV2}/diagram/swimlanes/activity-specification/${idActivity}/link`, link);

export const removeActivityLink = ({ name, type }: Link, idActivity: number) =>
  axiosInstance.delete(`${designUrlV2}/diagram/swimlanes/activity-specification/${idActivity}/link`, { data: { name, type } });

export const downloadRolesExcel = (objectType: string) =>
  axiosInstance.get(`${designUrl}/objectCatalog/report/?objectType=${objectType}`, {
    responseType: 'blob',
  });
