export enum TableVariant {
  PLAIN,
  CHECKBOX,
  RADIO,
  DOCUMENT,
  REQUIREMENT,
  PROCESS_LEVEL_DATABASE,
  PROCESS_LEVEL_SELECTION,
}

export enum AccordionStatus {
  UNTOUCHED = 'UNTOUCHED',
  TOUCHED = 'TOUCHED',
  COMPLETED = 'COMPLETED',
}

export type TableColumn<T> = {
  id: keyof T & string;
  isSortable?: boolean;
  name?: string;
  style?: string;
};

export enum Pagination {
  NONE = 'None',
  PAGES = 'Pages',
  SHOW_MORE = 'ShowMore',
}

export enum TableTool {
  NEW = 'new',
  EDIT = 'edit',
  MERGE = 'merge',
  DELETE = 'delete',
  DOWNLOAD = 'download',
}

export type TableProps<T extends { id: string; onClick?: () => void }> = {
  columns: TableColumn<T>[];
  getPage?: (page: number) => void;
  onCheck?: (checkedList: string[]) => void;
  onClickIcon?: (versionRow: T) => void;
  page?: number;
  pageSize?: number;
  pagination?: Pagination;
  prefix?: string;
  multiselect?: boolean;
  rows?: T[];
  totalPages?: number;
  horizontallyScrollable?: boolean;
  getSorted?: (elementId: string) => void;
  sorting?: { filter: string; order: string };
  isLoading?: boolean;
  className?: string;
  disabledInput?: boolean;
  getInputValue?: (value: string, rowId: string, columnId: string) => void;
  inputComponent?: any;
  onRowClick?: (id: string) => void;
  selectedRow?: string;
  selectedRows?: string[];
  searchText?: string;
  isSearching?: boolean;
  isTreeStructure?: boolean;
} & (
  | {
      variant?: TableVariant.PLAIN;
    }
  | {
      onCheck: (checkedList: string[]) => void;
      variant: TableVariant.CHECKBOX;
    }
  | {
      onCheck: (checkedList: string[]) => void;
      variant: TableVariant.RADIO;
    }
  | {
      onClickIcon: (versionRow: T) => void;
      variant: TableVariant.DOCUMENT;
    }
  | {
      variant: TableVariant.REQUIREMENT;
    }
  | {
      variant: TableVariant.PROCESS_LEVEL_DATABASE;
    }
  | {
      variant: TableVariant.PROCESS_LEVEL_SELECTION;
    }
);

export enum TablePrefix {
  INTERFACES = 'interfaces',
  INPUT_OUTPUT = 'inputOutput',
  IT_SYSTEM = 'itSystem',
  ROLE = 'role',
  SCOPE = 'scope',
  SUPPLIER_CUSTOMER = 'supplierCustomer',
  ACTIVITY_PROCESSES = 'activityProcesses',
}
