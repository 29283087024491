import { createContext, useReducer, Dispatch, ReactNode } from 'react';

import { cloneObject } from 'assets/js/Utils';
import { Language } from 'types/config';
import { Attributes, FormLanguage } from 'types/forms';
import { ProcessDataCatalog } from 'types/processes';
import { ColumnsConfig, RequirementComment, RequirementData, SolutionApproach } from 'types/requirement';

enum ActionTypes {
  SET_REQUIREMENT_ATTRIBUTES = 'SET_REQUIREMENT_ATTRIBUTES',
  SET_LOADING_REQUIREMENT = 'SET_LOADING_REQUIREMENT',
  DELETE_REQUIREMENT = 'DELETE_REQUIREMENT',
  SET_REQUIREMENT = 'SET_REQUIREMENT',
  SET_COLUMNS_CONFIG = 'SET_COLUMNS_CONFIG',
  SET_COMMENTS = 'SET_COMMENTS',
  SET_CATALOG = 'SET_CATALOG',
  SET_LAST_MODIFIED_DATE = 'SET_LAST_MODIFIED_DATE',
  SET_SOLUTION_APPROACH = 'SET_SOLUTION_APPROACH',
}

type Action =
  | { type: ActionTypes.SET_REQUIREMENT_ATTRIBUTES; payload: { attributes: Attributes; language: string } }
  | { type: ActionTypes.SET_LOADING_REQUIREMENT; payload: boolean }
  | { type: ActionTypes.SET_REQUIREMENT; payload: RequirementData }
  | { type: ActionTypes.DELETE_REQUIREMENT }
  | { type: ActionTypes.SET_COLUMNS_CONFIG; payload: ColumnsConfig }
  | { type: ActionTypes.SET_COMMENTS; payload: RequirementComment[] }
  | { type: ActionTypes.SET_CATALOG; payload: ProcessDataCatalog }
  | { type: ActionTypes.SET_LAST_MODIFIED_DATE; payload: string }
  | {
      type: ActionTypes.SET_SOLUTION_APPROACH;
      payload: { solutionApproach: SolutionApproach[]; language: string };
    };

// Action Creators
export const setLoadingRequirement = (loading: boolean) =>
  ({ type: ActionTypes.SET_LOADING_REQUIREMENT, payload: loading } as Action);
export const setLastModifiedDateRequirement = (date: string) =>
  ({ type: ActionTypes.SET_LAST_MODIFIED_DATE, payload: date } as Action);
export const setSolutionApproach = (solutionApproach: SolutionApproach[], language: string) =>
  ({ type: ActionTypes.SET_SOLUTION_APPROACH, payload: { solutionApproach, language } } as Action);
export const setRequirement = (requirementData: RequirementData) =>
  ({ type: ActionTypes.SET_REQUIREMENT, payload: requirementData } as Action);
export const deleteRequirementData = () => ({ type: ActionTypes.DELETE_REQUIREMENT } as Action);
export const setRequirementAttributes = (attributes: Attributes, language: string) =>
  ({ type: ActionTypes.SET_REQUIREMENT_ATTRIBUTES, payload: { attributes, language } } as Action);
export const setColumnsConfig = (columnsConfig: ColumnsConfig) =>
  ({ type: ActionTypes.SET_COLUMNS_CONFIG, payload: columnsConfig } as Action);

export const setComments = (comments: RequirementComment[]) => ({ type: ActionTypes.SET_COMMENTS, payload: comments } as Action);

export const setCatalog = (catalog: ProcessDataCatalog) => ({ type: ActionTypes.SET_CATALOG, payload: catalog } as Action);

type State = {
  requirementData?: RequirementData;
  isLoading: boolean;
  savedColumnsConfig?: ColumnsConfig;
  comments?: RequirementComment[];
  catalog?: ProcessDataCatalog;
};

const initialState: State = {
  isLoading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING_REQUIREMENT:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SET_REQUIREMENT_ATTRIBUTES: {
      const clonedAttributes = cloneObject(action.payload.attributes);
      const clonedAttributesEN = cloneObject(action.payload.attributes[action.payload.language as Language]);
      Object.keys(clonedAttributes).forEach((language) => {
        if (language !== (action.payload.language as Language) && language !== FormLanguage.NOT_TRANSLATABLE) {
          clonedAttributes[language] = clonedAttributesEN;
        }
      });
      return {
        ...state,
        requirementData: {
          ...(state.requirementData as RequirementData),
          attributes: clonedAttributes,
        },
      };
    }
    case ActionTypes.SET_REQUIREMENT:
      return {
        ...state,
        requirementData: action.payload,
      };
    case ActionTypes.DELETE_REQUIREMENT:
      return {
        ...state,
        requirementData: undefined,
      };
    case ActionTypes.SET_COLUMNS_CONFIG:
      return {
        ...state,
        savedColumnsConfig: action.payload,
      };
    case ActionTypes.SET_COMMENTS:
      if (!state.requirementData) return state;
      return {
        ...state,
        requirementData: { ...state.requirementData, comments: action.payload },
      };
    case ActionTypes.SET_CATALOG:
      if (!state.requirementData) return state;
      return {
        ...state,
        requirementData: { ...state.requirementData, catalog: action.payload },
      };
    case ActionTypes.SET_LAST_MODIFIED_DATE:
      if (!state.requirementData) return state;
      return {
        ...state,
        requirementData: { ...state.requirementData, lastModifiedDate: action.payload },
      };
    case ActionTypes.SET_SOLUTION_APPROACH: {
      if (!state.requirementData) return state;

      const solutionApproachParsed = action.payload.solutionApproach.map((solution) => {
        const clonedAttributes = cloneObject(solution.attributes);
        const clonedAttributesLang = cloneObject(solution.attributes[action.payload.language as Language]);
        Object.keys(clonedAttributes).forEach((language) => {
          if (language !== (action.payload.language as Language) && language !== FormLanguage.NOT_TRANSLATABLE) {
            clonedAttributes[language] = clonedAttributesLang;
          }
        });
        return { ...solution, attributes: clonedAttributes };
      });

      return {
        ...state,
        requirementData: {
          ...state.requirementData,
          solutionApproach: solutionApproachParsed,
        },
      };
    }
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface IRequirementContext {
  state: State;
  dispatch: Dispatch<Action>;
}

const RequirementContext = createContext<IRequirementContext | undefined>(undefined);

const RequirementProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <RequirementContext.Provider value={value}>{children}</RequirementContext.Provider>;
};

export { ActionTypes as RequirementActionTypes, RequirementContext, RequirementProvider };
