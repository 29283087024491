import React from 'react';

import { useTranslation } from 'react-i18next';

import { DIAGRAM, OBJECT, SYMBOL } from 'assets/constants/constants';
import DialogError from 'components/UI/DialogBasics/DialogError';

import Style from './DialogFormError.module.scss';

const DialogFormError = (props) => {
  const { handleConfirm, formErrorResponseBody, objectsUsed, symbols, language, catalogObjects } = props;
  const { t } = useTranslation();

  const getName = (id, idSymbolFront, type) => {
    if (type === DIAGRAM.toUpperCase()) return '';
    if (type === SYMBOL) {
      const symbolData = symbols.find((symbol) => idSymbolFront === symbol.id || idSymbolFront === symbol?.idSymbolFront);

      return symbolData.attributes[language].OBJECT_NAME || symbolData.attributes[language].NAME;
    }

    let parsedAttributes;

    if (type === OBJECT) {
      const objectData = objectsUsed.find((object) => id === object.id);
      parsedAttributes = JSON.parse(objectData.attributes);

      return parsedAttributes[language].OBJECT_NAME;
    }

    if (type === 'OBJECT_CATALOG') {
      const catalogObjectData = catalogObjects.find((object) => id === object.id);
      parsedAttributes = JSON.parse(catalogObjectData.attributes);

      return parsedAttributes[language].OBJECT_NAME;
    }
  };

  const getDialogFormErrorMessages = () =>
    formErrorResponseBody.forms.map(({ id, idSymbolFront, type, ...forms }) => (
      <div key={id}>
        <div className={Style.ErrorType}>{`${t(`nameAttributes.${type}`, { lng: language })} ${getName(
          id,
          idSymbolFront,
          type,
        )}`}</div>
        <div>
          {Object.keys(forms).map((formCode) =>
            Object.entries(forms[formCode]).map(([lang, fields]) => (
              <div key={`${id}_${lang}`}>
                <div className={Style.ErrorLanguage}>-{t(`language.${lang}`)}</div>
                <div>
                  {Object.entries(fields).map(([fieldCode, error]) => (
                    <div className={Style.ErrorCode} key={`${id}_${lang}_${error.code}`}>
                      {t(`nameAttributes.${fieldCode}`)}: {t(`errors.${error.code}`)}
                    </div>
                  ))}
                </div>
              </div>
            )),
          )}
        </div>
      </div>
    ));

  return (
    <DialogError handleConfirm={handleConfirm}>
      <div>
        <div className={Style.GeneralError}>{t(`errors.${formErrorResponseBody.code}`)}</div>
        <div>{getDialogFormErrorMessages()}</div>
      </div>
    </DialogError>
  );
};

export default DialogFormError;
