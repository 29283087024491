import React, { useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import { getDangerouslySetInnerHTML } from '../../assets/js/Utils';
import Button from '../UI/Button/Button';
import roleStyles from './DialogAddRole.module.scss';
import styles from './DialogDetail.module.scss';
import RoleWithResponsibility from './RoleWithResponsibility';

const DialogAddRole = (props) => {
  const { parentCallback, refAssignment, visible, close, t, createCatalogObjects, title, icon, roles } = props;
  const [data, setData] = useState({ OBJECT_NAME: null });
  const [enableButton, setEnableButton] = useState(false);

  const closeDialog = () => {
    close();
  };

  const handleButtonClick = (action) => {
    if (action === 'back') {
      parentCallback('back');
    } else {
      parentCallback('cancel');
    }
  };

  const sendItems = () => {
    roles.addRoles = false;
    createCatalogObjects(roles);
  };

  const setDialogDetail = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.OBJECT_NAME === data.OBJECT_NAME) {
      setData({ OBJECT_NAME: null });
    } else {
      setData({
        OBJECT_NAME: item.OBJECT_NAME,
        OBJECT_DESCRIPTION: item.OBJECT_DESCRIPTION,
        LINKAGE: item.LINKAGE,
      });
    }
  };

  const setResponsibility = (selection, role) => {
    const index = roles.findIndex((r) => r.id === role.id);
    roles[index].RESPONSIBILITY = selection;

    setEnableButton(!!selection);
  };

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={closeDialog}
      id="dialog-add-role"
      isModal
      ref={(dialog) => refAssignment(dialog)}
      target="#dialog"
      visible={visible}
      width="800px"
    >
      <div className={styles.Content}>
        <div className={styles.Section}>
          <h3 className={roleStyles.Header}>{t('addX', { labelX: title })}</h3>
          <h5 className={roleStyles.Responsibility}>{t('defineResponsibility')}</h5>
          <div className={`${styles.ItemSelected} ${roleStyles.Roles}`}>
            {roles.map((role) => (
              <RoleWithResponsibility
                data={data}
                icon={icon}
                id={`role${role.id}`}
                key={`${role.id}${new Date()}`}
                returnResponsibility={(selection) => setResponsibility(selection, role)}
                role={role}
                setDialogDetail={(event) => setDialogDetail(role, event)}
              />
            ))}
          </div>
          <div className={styles.ButtonLine}>
            <div className={roleStyles.ButtonBackAlign}>
              <Button btnText={t('Back')} buttonStyle="Secondary" handleClick={() => handleButtonClick('back')} id="back" />
            </div>
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={() => handleButtonClick('cancel')} id="cancel" />
            <Button btnText={t('add')} buttonStyle="Primary" disabled={!enableButton} handleClick={sendItems} id="add" />
          </div>
        </div>
        <div className={styles.SectionDetails}>
          <h4>{t('detailOfX', { labelX: title })}</h4>
          {data.OBJECT_NAME === null ? (
            <div className={styles.Default}>{t('detailInfo', { labelX: title })}</div>
          ) : (
            <div className={styles.Details}>
              <div className={styles.Title}>{t('nameAttributes.OBJECT_NAME')}</div>
              <div className={styles.Content}>{data.OBJECT_NAME}</div>
              <div className={styles.Title}>{t('nameAttributes.OBJECT_DESCRIPTION')}</div>
              <div
                className={`${styles.Content} ${styles.Description}`}
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(data.OBJECT_DESCRIPTION)} // eslint-disable-line react/no-danger
              />
              <div className={styles.Title}>{t('nameAttributes.LINKAGE')}</div>
              <div className={styles.Content}>{data.LINKAGE}</div>
            </div>
          )}
        </div>
      </div>
    </DialogComponent>
  );
};

export default React.memo(
  withTranslation('common')(DialogAddRole),
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
);
