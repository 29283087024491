import { Attributes } from 'types/forms';
import { ColumnsConfig, RequirementActions, RequirementDocumentContext, SolutionApproach } from 'types/requirement';

import { axiosInstance } from './serviceConstants';

const requirementUrl = '/backend/ms-request/api/v1';
const documentationUrl = '/backend/ms-documentation/api/v1';

export const createRequirement = () => axiosInstance.post(`${requirementUrl}/request`);

export const deleteRequirement = (requirementId: number, confirmationString: string) =>
  axiosInstance.delete(`${requirementUrl}/request/${requirementId}`, { data: { confirmationString } });

export const updateRequirement = (data: { attributes: Attributes; id: number; solutionApproach: SolutionApproach[] }) =>
  axiosInstance.put(`${requirementUrl}/request/save`, data);

export const getRequirement = (requirementId: number) => axiosInstance.get(`${requirementUrl}/request/${requirementId}`);

export const copyRequirement = (requestId: string) => axiosInstance.post(`${requirementUrl}/request/copy?requestId=${requestId}`);

export const performRequirementAction = (requirementId: number, triggeredAction: RequirementActions, reasonString?: string) =>
  axiosInstance.put(
    `${requirementUrl}/request/transition/${requirementId}/${triggeredAction}`,
    reasonString ? { reasonString } : undefined,
  );

export const getAllRequirements = (params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${requirementUrl}/request/all?${searchParams}`);
};

export const uploadDocuments = (
  requirementId: number,
  data: FormData,
  context: RequirementDocumentContext,
  processResponsibleId?: number,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data; charset=utf-8; boundary="MBCMAP"',
  };
  return axiosInstance.post(
    `${documentationUrl}/storage/request/${requirementId}?context=${context}${
      processResponsibleId ? `&processResponsibleId=${processResponsibleId}` : ''
    }`,
    data,
    { headers },
  );
};

export const setSupportNeeded = (requestIds: string[]) =>
  axiosInstance.post(`${requirementUrl}/request/support-needed`, requestIds);

export const saveColumnsConfig = (data: ColumnsConfig) => axiosInstance.post(`${requirementUrl}/request/column-config`, data);

export const saveRequirementComment = (requirementId: string, comment: string) =>
  axiosInstance.post(`${requirementUrl}/request/comment/${requirementId}`, { comment });

export const updateRequirementComments = (requirementId: string) =>
  axiosInstance.get(`${requirementUrl}/request/comments/${requirementId}`);

export const getRequirementChanelog = (requirementId: string, page: number = 0, size: number = 5) =>
  axiosInstance.get(`${requirementUrl}/request/${requirementId}/changelog?page=${page}&size=${size}`);
