import { useEffect } from 'react';

const useOuterClick = (ref, handler, container) => {
  useEffect(() => {
    const handleOuterClick = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event, container);
    };

    document.addEventListener('mousedown', handleOuterClick);

    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [container, ref, handler]);
};

export default useOuterClick;
