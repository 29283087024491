import { Attributes } from './forms';
import { ProcessDataCatalog } from './processes';

export enum RequirementStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  DONE = 'DONE',
  ACCEPTED = 'ACCEPTED',
  FORWARD = 'FORWARD',
  RETRIEVED = 'RETRIEVED',
  CLOSED = 'CLOSED',
}

export enum RequirementActions {
  SEND = 'SEND',
  APPROVE = 'APPROVE',
  RETRIEVE = 'RETRIEVE',
  FORWARD = 'FORWARD',
  REOPEN = 'REOPEN',
  DECLINE = 'DECLINE',
  CLOSE = 'CLOSE',
  SET_AS_DONE = 'SET_AS_DONE',
  RESTART = 'RESTART',
}

export enum RequirementFormsViewsVariant {
  CREATOR = 'CREATOR',
  RESPONSIBLE = 'RESPONSIBLE',
  EXTERNAL = 'EXTERNAL',
}

export enum RequirementDocumentContext {
  INITIAL_SITUATION = 'INITIAL_SITUATION',
  SOLUTION_APPROACH = 'SOLUTION_APPROACH',
}

export enum RequirementCategories {
  PAIN_POINT = 'PAIN_POINT',
  GAME_CHANGER_IDEA = 'GAME_CHANGER_IDEA',
  EXTERNAL_REQUIREMENTS = 'EXTERNAL_REQUIREMENTS',
  ON_HOLD = 'ON_HOLD',
  ON_HOLD_INTERFACE = 'ON_HOLD_INTERFACE',
  OUT_OF_SCOPE_INTERFACE = 'OUT_OF_SCOPE_INTERFACE',
  INPUT_INTERFACE = 'INPUT_INTERFACE',
  OUTPUT_INTERFACE = 'OUTPUT_INTERFACE',
}

export enum RequiredChangelogActions {
  CREATED = 'CREATED',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DONE = 'DONE',
  REOPENED = 'REOPENED',
  RETRIEVED = 'RETRIEVED',
  FORWARDED = 'FORWARDED',
  ATTRIBUTES_UPDATED = 'ATTRIBUTES_UPDATED',
  RESTARTED = 'RESTARTED',
  CLOSED = 'CLOSED',
}

export enum RequiredChangelogRemark {
  TITLE = 'TITLE',
  CREATOR = 'CREATOR',
  INITIAL_CATEGORY = 'INITIAL_CATEGORY',
  INITIAL_DESCRIPTION = 'INITIAL_DESCRIPTION',
  EXAMPLE = 'EXAMPLE',
  RESPONSIBLE_PERSONS = 'RESPONSIBLE_PERSONS',
  RESPONSIBLE_PACEMAKERS = 'RESPONSIBLE_PACEMAKERS',
  ORIGIN_PROCESS = 'ORIGIN_PROCESS',
  RESPONSIBLE_PROCESS = 'RESPONSIBLE_PROCESS',
  POTENTIAL_ANNUAL_EBIT = 'POTENTIAL_ANNUAL_EBIT',
  POTENTIAL_ONE_TIME_CASHFLOW = 'POTENTIAL_ONE_TIME_CASHFLOW',
  TARGET_DATE = 'TARGET_DATE',
  SOLUTION_DESCRIPTION = 'SOLUTION_DESCRIPTION',
  FINANCE_APPROVED = 'FINANCE_APPROVED',
  MULTIPLE_CHANGES = 'MULTIPLE_CHANGES',
  FINANCIAL_EVALUATION = 'FINANCIAL_EVALUATION',
  LINKED_ORIGIN_PROCESS = 'LINKED_ORIGIN_PROCESS',
  UNLINKED_ORIGIN_PROCESS = 'UNLINKED_ORIGIN_PROCESS',
  LINKED_RESPONSIBLE_PROCESS = 'LINKED_RESPONSIBLE_PROCESS',
  UNLINKED_RESPONSIBLE_PROCESS = 'UNLINKED_RESPONSIBLE_PROCESS',
  ADD_SOLUTION_APPROACH_ATTACHMENT = 'ADD_SOLUTION_APPROACH_ATTACHMENT',
  REMOVE_SOLUTION_APPROACH_ATTACHMENT = 'REMOVE_SOLUTION_APPROACH_ATTACHMENT',
  ADD_INITIAL_SITUATION_ATTACHMENT = 'ADD_INITIAL_SITUATION_ATTACHMENT',
  REMOVE_INITIAL_SITUATION_ATTACHMENT = 'REMOVE_INITIAL_SITUATION_ATTACHMENT',
}

export interface SolutionApproach {
  attributes: Attributes;
  financeApproved: boolean;
  financialEvaluation: RequirementFinancialEvaluation[];
  idResponsibleProcess: number;
  targetDate: string;
  solutionApproachDocuments?: DocumentsRequirement[];
}

export type RequirementData = {
  allMyProcessesApproved?: boolean;
  id: number;
  attributes: Attributes;
  catalog: ProcessDataCatalog;
  initialSituationDocuments?: DocumentsRequirement[];
  status: RequirementStatus;
  acceptanceDate?: string;
  supportNeeded: boolean;
  comments: RequirementComment[];
  lastModifiedDate: string;
  responsibleProcesses?: Process[];
  originProcess?: Process;
  version: number;
  solutionApproach: SolutionApproach[];
};

export type Process = {
  attributes: Attributes;
  category: string;
  id: number;
  parentId: number;
  wave: number;
};

export type DocumentsRequirement = {
  creator: string;
  date: string;
  extension: string;
  name: string;
};

export type Requirement = {
  supportNeeded: boolean;
  id: string;
  title: string;
  category: string;
  status: string;
  description: string;
  example: string;
  responsiblePerson: string;
  responsiblePacemaker: string;
  responsibleProcess: string;
  originProcess: string;
  createdBy: string;
  creationInfo: string;
  acceptedBy?: string;
  acceptanceInfo?: string;
  potentialAnnualEbit: string;
  potentialOneTimeCashflow: string;
  solutionDescription: string;
  requiredInvest: string;
  evaluationAnnualEbit: string;
  evaluationOneTimeCashflow: string;
  targetDate: string;
  creator: string;
};

export type ColumnsConfig = {
  id: boolean;
  title: boolean;
  category: boolean;
  status: boolean;
  description: boolean;
  example: boolean;
  responsiblePerson: boolean;
  responsiblePacemaker: boolean;
  responsibleProcesses: boolean;
  originProcess: boolean;
  createdBy: boolean;
  creationInfo: boolean;
  acceptedBy?: boolean;
  acceptanceInfo?: boolean;
  potentialAnnualEbit: boolean;
  potentialOneTimeCashflow: boolean;
  solutionDescription: boolean;
  requiredInvest: boolean;
  evaluationAnnualEbit: boolean;
  evaluationOneTimeCashflow: boolean;
  targetDate: boolean;
  creator: boolean;
};

export type ChangeLogValuesType = {
  remark: string;
  previousValue: string;
  updatedValue: string;
  solutionApproachName?: string;
  requestId?: number;
};

export type RequirementsChangeLog = {
  id: string;
  timestamp: string;
  action: string;
  person?: string;
  changes?: ChangeLogValuesType[];
};

export type RequirementComment = {
  id: number;
  comment: string;
  createdBy: string;
  creationDate: string;
};

export type RequirementFinancialEvaluation = {
  id: string;
  year: string;
  requiredInvest: string;
  annualEbitEffect: string;
  oneTimeCashflowEffect: string;
};

export enum RequirementFilters {
  CATEGORY = 'category',
  STATUS = 'status',
  PROCESS_ID = 'processId',
  SUPPORT_NEEDED = 'supportNeeded',
}

export enum RequirementTabs {
  COMMENTS = 'comments',
  CHANGELOG = 'changelog',
}

export enum ResponsibleProcessUserTypes {
  ORIGIN_PROCESS_USERS = 'ORIGIN_PROCESS_USERS',
  RESPONSIBLE_PROCESS_USERS = 'RESPONSIBLE_PROCESS_USERS',
}
