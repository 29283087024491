import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { AREAS, BUTTON_PRIMARY, BUTTON_SECONDARY, STATUS } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import Table from 'components/UI/Table/Table';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import TextButton from 'components/UI/TextButton/TextButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useProcess from 'hooks/useProcess';
import NEPOSTabWrapper from 'pages/NEPOS/NEPOSTabWrapper';
import { deleteInterfaces, getAvailableInterfaces, addInterfaces } from 'services/design';
import { Language } from 'types/config';
import { ProcessInterface } from 'types/processes';
import { Pagination, TablePrefix, TableColumn, TableVariant } from 'types/tables';

import styles from './Interfaces.module.scss';

type InterfacesProps = {
  handleClose: () => void;
};

const constantColumns: TableColumn<ProcessInterface>[] = [
  { id: 'parsedTitle', style: styles.Title },
  { id: 'processResponsible', style: styles.Responsible },
  { id: 'department', style: styles.Department },
];

const Interfaces = ({ handleClose }: InterfacesProps) => {
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const { processData, dispatch, diagramLanguage } = useDiagramContext();
  const { filterProcessInterfaces } = useProcess(id);
  const [isAddInterfaceDialogOpen, setIsAddInterfaceDialogOpen] = useState(false);
  const [availableInterfaces, setAvailableInterfaces] = useState<(ProcessInterface & { id: string })[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [interfacesToAdd, setInterfacesToAdd] = useState<string[]>([]);
  const [interfacesToDelete, setInterfacesToDelete] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  useEffect(() => {
    setSearchText('');
    setPage(0);
    setTotalPages(1);
  }, [isAddInterfaceDialogOpen]);

  const rows = useMemo(
    () =>
      filterProcessInterfaces()?.map((elem) => {
        const diagramId = elem.idDiagram.toString();
        let diagramArea = 'diagram';

        if (processData?.isPublishedVersion) {
          diagramArea = AREAS.PUBLISHED;
        } else if (elem.status === STATUS.SANDBOX) {
          diagramArea = AREAS.SANDBOX;
        }
        return (
          {
            ...elem,
            id: diagramId,
            onClick: () => history.push(`/${diagramArea.toLowerCase()}/${diagramId}`),
            parsedTitle: elem.title[diagramLanguage],
            origin: t(`environment.${elem.status === STATUS.SANDBOX ? AREAS.SANDBOX : AREAS.MODELING}`.toLowerCase()),
            processResponsible: elem?.processResponsible?.toString(),
          } || []
        );
      }),
    [diagramLanguage, filterProcessInterfaces, history, processData?.isPublishedVersion, t],
  );

  const columns: TableColumn<ProcessInterface>[] = useMemo(
    () => (!processData?.isPublishedVersion ? [...constantColumns, { id: 'origin', style: styles.Origin }] : constantColumns),
    [processData?.isPublishedVersion],
  );

  const handleAddInterfaces = async () => {
    if (!availableInterfaces || !interfacesToAdd.length) return;

    try {
      await addInterfaces(id, interfacesToAdd);
      setIsAddInterfaceDialogOpen(false);
      const existingInterfaces = processData?.interfaces || [];
      const newInterfaces = interfacesToAdd.map(
        (interfaceId) => availableInterfaces.find((elem) => elem.id === interfaceId) as ProcessInterface,
      );
      setInterfacesToAdd([]);

      dispatch({
        type: DiagramActionTypes.UPDATE_PROCESS_INTERFACES,
        payload: [...existingInterfaces, ...newInterfaces],
      });
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const handleDeleteInterfaces = async () => {
    if (!processData) return;
    try {
      await deleteInterfaces(id, interfacesToDelete);
      const filteredInterfaces =
        processData.interfaces?.filter((element) => interfacesToDelete.indexOf(element.idDiagram.toString()) === -1) || [];
      setInterfacesToDelete([]);

      dispatch({
        type: DiagramActionTypes.UPDATE_PROCESS_INTERFACES,
        payload: filteredInterfaces,
      });
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const addInterfaceDialogButtons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !interfacesToAdd.length,
      handleClick: handleAddInterfaces,
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      handleClick: () => {
        setIsAddInterfaceDialogOpen(false);
      },
      key: 'cancel',
    },
  ];

  const fetchInterfaces = async (newPage: number = 0, search: string = '') => {
    setIsLoading(true);
    setPage(newPage);
    setSearchText(search);

    try {
      const { data } = await getAvailableInterfaces(id, { page: newPage, ...(search ? { search } : {}), size: 20 });
      setAvailableInterfaces(
        data.interfaces.map((elem: ProcessInterface) => ({
          ...elem,
          id: elem.idDiagram.toString(),
          parsedTitle: elem.title[i18n.language as Language],
          origin: t(`environment.${elem.status === STATUS.SANDBOX ? AREAS.SANDBOX : AREAS.MODELING}`.toLowerCase()),
        })),
      );
      setIsLoading(false);
      setTotalPages(data.numberOfPages);
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  return (
    <NEPOSTabWrapper handleClose={handleClose}>
      <h4>
        <i className="di icon-link" />
        {t('interfaces')}
      </h4>
      <div className={`${styles.ButtonsBar} ${interfacesToDelete.length ? styles.Checked : ''}`}>
        {!!interfacesToDelete.length && <span>{`${interfacesToDelete.length} ${t('entrySelected')}`}</span>}
        <div className={styles.ButtonsWrapper}>
          {!processData?.isOnlyRead && (
            <>
              <TextButton
                id="add-interface"
                onClick={() => {
                  setIsAddInterfaceDialogOpen(true);
                  fetchInterfaces();
                }}
                text={t('addInterface')}
              />
              <RoundButton
                className={`${styles.RemoveInterface} ${interfacesToDelete.length ? '' : styles.Disabled}`}
                disabled={!interfacesToDelete.length}
                icon="di icon-muelleimer-loeschen"
                id="delete-interface"
                onClick={handleDeleteInterfaces}
              />
            </>
          )}
        </div>
      </div>
      {isAddInterfaceDialogOpen && (
        <DialogNEPOS
          dialog={{
            title: t('addInterface'),
            buttons: addInterfaceDialogButtons,
          }}
        >
          <TableWithSearch
            columns={columns}
            getPage={(newPage) => fetchInterfaces(newPage, searchText)}
            id={TablePrefix.INTERFACES}
            isAsync
            isLoading={isLoading}
            onCheck={setInterfacesToAdd}
            page={page}
            pagination={Pagination.PAGES}
            rows={availableInterfaces}
            searching={(text: string) => {
              setTotalPages(1);
              fetchInterfaces(0, text);
            }}
            totalPages={totalPages}
            variant={TableVariant.CHECKBOX}
          />
        </DialogNEPOS>
      )}
      <Table
        columns={columns}
        isLoading={!rows}
        onCheck={setInterfacesToDelete}
        prefix="interfaces."
        rows={rows}
        variant={processData?.isOnlyRead ? TableVariant.PLAIN : TableVariant.CHECKBOX}
      />
    </NEPOSTabWrapper>
  );
};

export default Interfaces;
