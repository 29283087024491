import { Language } from 'types/config';
import { AttributeValue } from 'types/forms';
import { RequirementDocumentContext } from 'types/requirement';

import { axiosInstance } from './serviceConstants';

const documentationUrl = '/backend/ms-documentation/api/v1';

export const deleteDocumentDataset = (dataSetId: number, documentName: string) => {
  return axiosInstance.delete(`${documentationUrl}/storage/dataset/${dataSetId}/by-file?documentName=${documentName}`);
};

export const deleteDocumentRequirement = (
  requirementId: number,
  documentName: string,
  context: RequirementDocumentContext,
  processResponsibleId?: number,
) => {
  return axiosInstance.delete(
    `${documentationUrl}/storage/request/${requirementId}/by-file?documentName=${documentName}&context=${context}`,
    { params: { processResponsibleId } },
  );
};

export const getAllPublishedDatasets = (params = {}, language: string) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${documentationUrl}/search/datasets/published?${searchParams}`, {
    headers: { 'Accept-Language': language },
  });
};

export const removeActivityFile = (typeFile: string, idActivity: number, fileName: string) =>
  axiosInstance.delete(`${documentationUrl}/storage/activity/${idActivity}`, {
    data: { nameDocument: fileName, type: typeFile },
  });

export const uploadActivityFiles = (typeFile: string, activityId: number, document: File) => {
  const headers = {
    'Content-Type': 'multipart/form-data; charset=utf-8; boundary="MBCMAP"',
  };
  const formData = new FormData();
  formData.append('files', document, document.name);
  return axiosInstance.post(`${documentationUrl}/storage/activity/${activityId}?type=${typeFile}`, formData, { headers });
};

export const getActivityDoc = (
  name: string,
  type: string,
  activityId: number,
  processNumber: string,
  version?: string,
): Promise<any> => {
  const searchParams = new URLSearchParams({ name }).toString();
  const url = !version
    ? `${documentationUrl}/storage/activity/${activityId}/${type}?${searchParams}`
    : `${documentationUrl}/published/${processNumber}/${version}/activity/${activityId}/${type}?${searchParams}`;

  return axiosInstance.get(url, {
    responseType: 'blob',
  });
};

export const genDocsOnPublished = (processNumber: string, version: string, extension: string, language: Language) =>
  axiosInstance.get(`${documentationUrl}/storage/diagram/${processNumber}/${version}/${extension}`, {
    responseType: 'blob',
    headers: { 'Accept-Language': language },
  });

export const getAllActivityDocuments = (activityId: number, processNumber?: string, version?: string) => {
  const url = !version
    ? `${documentationUrl}/storage/activity/all/${activityId}`
    : `${documentationUrl}/published/${processNumber}/${version}/activity/${activityId}/all`;

  return axiosInstance.get(url, {
    responseType: 'blob',
  });
};

export const getActivityPublished = (processNumber: AttributeValue, version: AttributeValue, id: string) =>
  axiosInstance.get(`${documentationUrl}/published/${processNumber}/${version}/swimlanes/symbol?idSymbolFront=${id}`);
