import axios from 'axios';

let plant = '';
let backendEnv = '-dev';

try {
  const { default: localAuth } = require('../localAuth'); // eslint-disable-line global-require, import/extensions, import/no-unresolved
  plant = localAuth.LOCAL_BACKEND_PLANT ? `${localAuth.LOCAL_BACKEND_PLANT}.` : '';
  backendEnv = `-${localAuth.LOCAL_BACKEND_ENV || 'dev'}`;
} catch (error) {
  console.log(error); // eslint-disable-line no-console
}

const baseUrl = {
  // development: 'http://designer.ed1839ac4b9144c5a7e7.westeurope.aksapp.io/',
  local: `https://${plant}mbcmap${backendEnv}-app.corpinter.net`,
  development: '',
  int: '',
  mig: '',
  // qa: 'http://designer.7cbe25045d6a458492f2.westeurope.aksapp.io',
  qa: '',
  production: '',
};

const env = process.env.REACT_APP_ENV || 'development';
const base = baseUrl[env];

const endpointV1 = `${base}/backend/ms-design/api/v1`;
const endpointTasks = `${base}/backend/ms-tasks/api/v1`;
const endpointWorkflowV1 = `${base}/backend/ms-workflow/api/v1`;
const endpointUsers = `${base}/backend/ms-users/api/v1`;
const endpointDocumentation = `${base}/backend/ms-documentation/api/v1`;

export default {
  baseUrl: base || window.location.origin,
  endpointV1,
  endpointTasks,
  endpointWorkflowV1,
  endpointUsers,
  endpointDocumentation,
  env,
};

const axiosInstance = axios.create({ baseURL: base });

// Transforms error response to json type when request has responseType set to blob
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  },
);

export { axiosInstance };
