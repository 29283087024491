import { useEffect, useState } from 'react';

import { DIAGRAM_FONT_SIZE } from 'assets/constants/constants';
import { getBounds } from 'assets/js/Utils';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import usePrevious from 'hooks/usePrevious';
import { Background, Bounds, Coordinates, Dimensions, Padding, Tool } from 'types/diagram';

import useDiagramContext from './useDiagramContext';
import useTool from './useTool';

export default function useDiagramBackground() {
  const {
    background,
    bounds,
    containerRef,
    dispatch,
    fontSize,
    previousFontSize,
    origin,
    shouldRealignBackground,
    processData,
    shouldUpdateBackground,
    displayedSymbolBoards,
  } = useDiagramContext();
  const { tool } = useTool();
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS;
  const [originDisplacement, setOriginDisplacement] = useState<Coordinates | null>(null);
  const previousBackground = usePrevious(background);
  const symbols = processData?.symbols || [];

  const getPixels = (value: number) => value * (fontSize || 1);
  const getPreviousPixels = (value: number) => value * (previousFontSize || 1);

  const getOrigin = (newBackground: Background) => ({
    top: newBackground.height / (2 * (fontSize || 1)),
    left: newBackground.width / (2 * (fontSize || 1)),
  });

  const setDisplacement = (newBounds: Bounds, newPadding: Padding) => {
    if (bounds && fontSize !== previousFontSize && containerRef.current) {
      const displacement = { top: 0, left: 0 };
      const diffHeight = getPreviousPixels(bounds?.height) - getPixels(newBounds.height);
      const diffWidth = getPreviousPixels(bounds?.width) - getPixels(newBounds.width);

      const horizontalPaddPX = getPixels(newPadding.horizontal) / 2;
      const verticalPaddPX = getPixels(newPadding.vertical) / 2;
      const leftScroll = containerRef.current.scrollLeft - horizontalPaddPX;
      const topScroll = containerRef.current.scrollTop - verticalPaddPX;
      const horizontalPercent = leftScroll / getPreviousPixels(bounds.width);
      const verticalPercent = topScroll / getPreviousPixels(bounds.height);

      displacement.left = leftScroll <= newBounds.left ? 0 : diffWidth * -1 * Math.min(horizontalPercent, 1);
      displacement.top = topScroll <= newBounds.top ? 0 : diffHeight * -1 * Math.min(verticalPercent, 1);

      setOriginDisplacement(displacement);
    }
  };

  const getFitToContentFontSize = (
    { clientHeight: containerHeight, clientWidth: containerWidth }: HTMLDivElement,
    { height: boundsHeight, width: boundsWidth }: Bounds,
  ) => {
    // We add 1em to bounds height and width to leave a minimum blank margin between the diagram content and the container
    const minFontSize = Math.min(containerHeight / (boundsHeight + 1), containerWidth / (boundsWidth + 1), DIAGRAM_FONT_SIZE);
    const newFontSize = 2 * Math.floor(minFontSize / 2);
    dispatch({ type: DiagramActionTypes.SET_FONT_SIZE, payload: newFontSize });
    return newFontSize;
  };

  const getPadding = ({ clientHeight, clientWidth }: HTMLDivElement, currentFontSize: number) => ({
    horizontal: (clientWidth / currentFontSize) * (processData?.isOnlyRead ? 2 : 1),
    vertical: (clientHeight / currentFontSize) * (processData?.isOnlyRead ? 2 : 1),
  });

  const getBackground = (newBounds: Bounds, newPadding: Padding) => ({
    height: (newBounds.height + 2 * newPadding.vertical) * (fontSize || 1),
    width: (newBounds.width + 2 * newPadding.horizontal) * (fontSize || 1),
  });

  const isSameBackground = (newBg: Dimensions) => background?.height === newBg.height && background?.width === newBg.width;

  const updateBackground = () => {
    if (!containerRef.current || !processData) return;

    const newBounds = getBounds(areNotesDisplayed, symbols, processData.isSwimlane, fontSize, displayedSymbolBoards);
    if (!fontSize) {
      getFitToContentFontSize(containerRef.current, newBounds);
      return;
    }

    const newPadding = getPadding(containerRef.current, fontSize);
    const newBackground = getBackground(newBounds, newPadding);
    const newOrigin = isSameBackground(newBackground) ? (origin as Coordinates) : getOrigin(newBackground);

    if (shouldRealignBackground) setDisplacement(newBounds, newPadding);

    dispatch({
      type: DiagramActionTypes.UPDATE_BACKGROUND,
      payload: { background: newBackground, bounds: newBounds, origin: newOrigin, padding: newPadding },
    });
  };

  useEffect(() => {
    if (!shouldUpdateBackground) return;
    updateBackground();
  }, [processData, shouldUpdateBackground]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateScroll = () => {
    if (!previousBackground) {
      updateBackground();
    } else if (containerRef.current && originDisplacement) {
      containerRef.current.scrollLeft += originDisplacement.left;
      containerRef.current.scrollTop += originDisplacement.top;
      setOriginDisplacement(null);
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousBackground) === JSON.stringify(background)) return;
    updateScroll();
  }, [background]); // eslint-disable-line react-hooks/exhaustive-deps
}
