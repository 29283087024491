import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Search from '../Search/Search';
import styles from './SearchFilter.module.scss';

const ResultsList = ({ array, handleFunction, isSelected, icon }) =>
  array?.map((element) => (
    <div
      className={isSelected || element.isSelected ? styles.UsersSelected : styles.Results}
      key={element.id || element.value}
      onClick={() => handleFunction(element)}
    >
      <div className={styles.User}>
        <i className={icon} />
        <div className={styles.UserName}>{element.label}</div>
      </div>
      {element.checked && <i className={`di icon-kreis-check ${styles.UserCheck}`} />}
      {isSelected && <i className={`di icon-issue-status-circle ${styles.IconX}`} />}
    </div>
  ));

const MultiselectionFilter = ({ filter, toggleSelection, selected, icon }) => {
  const { t } = useTranslation();
  const [allResults, setAllResults] = useState([]);
  const searchValue = (word) => {
    filter.getResults(word).then((response) => {
      setAllResults(response);
    });
  };

  return (
    <>
      <div className={styles.SearchBox}>
        <Search searching={() => {}} sendSearch={(text) => searchValue(text)} />
      </div>
      <div className={styles.UsersList}>
        {selected.length > 0 && <p>{t('selected').toUpperCase()}</p>}
        <ResultsList array={selected} handleFunction={toggleSelection} icon={icon} isSelected />
      </div>
      <hr className={styles.Line} />
      <div className={styles.UsersList}>
        <p>{t('results').toUpperCase()}</p>
        <ResultsList
          array={allResults.map((result) => ({
            ...result,
            isSelected: selected.some((item) => item.value === result.value),
          }))}
          handleFunction={toggleSelection}
          icon={icon}
        />
      </div>
    </>
  );
};

export default MultiselectionFilter;
