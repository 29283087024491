import axios from 'axios';

import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const wfUrl = '/backend/ms-workflow/api/v1/workflow';
const tasksUrl = '/backend/ms-tasks/api/v1/tasks';

const getAllWorkflowTypes = (workflowTypeCode, isRecommended = false) =>
  axiosInstance.get(`${wfUrl}/types?workflowTypeCode=${workflowTypeCode}&isRecommended=${isRecommended}`);

const getWorkflowTypes = ({ idDiagramType, idDiagram, idProcess, version, isRecommendationActive }) =>
  axiosInstance.get(
    `${wfUrl}/release-menu?${
      idDiagram ? `idDiagram=${idDiagram}&` : ''
    }idDiagramType=${idDiagramType}&idProcess=${idProcess}&version=${version}&isRecommendationActive=${
      isRecommendationActive || false
    }`,
  );

const getBasicWorkflowInfo = (processNumber) => {
  return axiosInstance.get(`${wfUrl}/basic-by/${processNumber}`);
};

const workflowById = (idWorkflow) => axiosInstance.get(`${wfUrl}/by-id-workflow?idWorkflow=${idWorkflow}`);

const workflowByProcessNumber = (number) => axiosInstance.get(`${wfUrl}?processNumber=${number}`);

const sendForRelease = (data) => axiosInstance.post(`${wfUrl}/send-for-release/`, data);

const getProcesses = (params) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${wfUrl}/processes?${searchParams}`);
};

const saveWorkflow = (processNumber, data) => axiosInstance.put(`${wfUrl}?processNumber=${processNumber}`, data);

const approveWorkflow = (processNumber, data) => axiosInstance.put(`${wfUrl}/approve?processNumber=${processNumber}`, data);

const rejectWorkflow = (processNumber, rejectionReason) =>
  axiosInstance.put(`${wfUrl}/reject?processNumber=${processNumber}`, { rejectionReason });

const transferTask = (processNumber, code) => axiosInstance.put(`${wfUrl}/transfer?processNumber=${processNumber}`, { code });

const getOpenWorkflowTasks = () => axiosInstance.get(`${tasksUrl}/workflow-task/open`);

const getClosedWorkflowTasks = () => axiosInstance.get(`${tasksUrl}/workflow-task/closed`);

const delegateWorkflowStep = (commentary, processNumber, userCodeToList, userOrGroup) =>
  axiosInstance.put(`${wfUrl}/delegate`, { commentary, processNumber, userCodeToList, userOrGroup });

const backToGroup = (processNumber) => axiosInstance.put(`${wfUrl}/back-to-group?processNumber=${processNumber}`);

const getAllWorkflowTasks = () =>
  axios
    .all([getOpenWorkflowTasks(), getClosedWorkflowTasks()])
    .then((data) => data.map((elem) => elem.data))
    .catch(serviceUtils.handleServiceError);

export {
  getAllWorkflowTypes,
  getWorkflowTypes,
  getBasicWorkflowInfo,
  workflowById,
  workflowByProcessNumber,
  sendForRelease,
  saveWorkflow,
  approveWorkflow,
  rejectWorkflow,
  transferTask,
  getAllWorkflowTasks,
  delegateWorkflowStep,
  backToGroup,
  getProcesses,
};
