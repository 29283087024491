import { ReactNode, forwardRef, useEffect } from 'react';

import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import { DialogIcon, DialogInfo } from 'types/dialogs';

import styles from './DialogNEPOS.module.scss';

type Props = {
  children: ReactNode;
  dialog: DialogInfo;
  extraClass?: string;
  fetchOnLoad?: () => void;
  maxSize?: boolean;
};

const DialogNEPOS = forwardRef<HTMLDivElement, Props>(({ children, dialog, extraClass, fetchOnLoad, maxSize }, ref) => {
  useEffect(() => {
    if (fetchOnLoad) fetchOnLoad();
  }, [fetchOnLoad]);

  return (
    <Dialog
      className={`${styles.DialogContainer} ${dialog.isLegacyStyle ? styles.ContainerLegacy : ''} ${
        maxSize ? styles.MaxSize : ''
      } ${extraClass ? styles[extraClass] : ''} ${dialog.type && (styles[dialog.type] || '')}`}
      ref={ref}
    >
      <DialogHeader className={styles.Title}>
        {dialog.type && <i className={`di ${DialogIcon[dialog.type]} ${styles.Icon}`} />}
        <span>{dialog.title}</span>
      </DialogHeader>
      <div className={styles.DialogBody}>{children}</div>
      <DialogFooter buttons={dialog.buttons} extraClass={styles.Footer} isLegacyStyle={dialog.isLegacyStyle} />
    </Dialog>
  );
});

export default DialogNEPOS;
