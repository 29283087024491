import { useState, useRef } from 'react';

import { Coordinate } from 'types/diagram';

import CheckboxNEPOS from '../CheckboxNEPOS/CheckboxNEPOS';
import DropdownPanel from '../DropdownPanel/DropdownPanel';
import styles from './IconButtonDropdown.module.scss';

type IconButtonDropdownProps = {
  extraClass?: string;
  icon: string;
  id: string;
  isCheckboxDropdown?: boolean;
  value?: string;
  title?: string;
  onChange: (element: string) => void;
  options: { value: string; label: string; icon?: string; error?: boolean }[];
  disabled?: boolean;
  error?: boolean;
  buttonStyle?: string;
  width?: string;
  preventMultipleSelection?: boolean;
};

const IconButtonDropdown = (props: IconButtonDropdownProps) => {
  const {
    extraClass,
    icon,
    id,
    isCheckboxDropdown = false,
    title,
    value,
    options,
    onChange,
    disabled,
    error,
    buttonStyle,
    width,
    preventMultipleSelection,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedElements, setSelectedElements] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedElement = options.find((e) => e.value === value);
  const hasErrors = Boolean(error);

  const handleCheck = (itemValue: string) => {
    if (!isCheckboxDropdown) return;
    if (preventMultipleSelection) return setSelectedElements(selectedElements.includes(itemValue) ? [] : [itemValue]);
    let newSelectedElements = [...selectedElements];

    if (selectedElements.some((elem) => itemValue === elem)) {
      newSelectedElements = selectedElements.filter((el) => el !== itemValue);
    } else {
      newSelectedElements = [...selectedElements, itemValue];
    }

    setSelectedElements(newSelectedElements);
  };

  return (
    <div className={extraClass ? styles[extraClass] : ''}>
      <div
        className={`${styles.Button} ${isOpen ? styles.Selected : ''} ${disabled ? styles.Disabled : ''} ${buttonStyle || ''}`}
        id={id}
        onClick={() => {
          if (disabled) return;
          setIsOpen(!isOpen);
        }}
        ref={containerRef}
      >
        <i className={`${styles.Icon} ${hasErrors ? `di icon-blitz-fehler ${styles.Error}` : icon}`} />
        <span>{selectedElement?.label || title}</span>
        {!extraClass && <i className={`di icon-pfeil-chevron-unten ${styles.SecondIcon} ${isOpen ? styles.Open : ''}`} />}
      </div>
      {isOpen && !disabled && (
        <DropdownPanel
          className={`${styles.Panel} ${extraClass ? styles[extraClass] : ''}`}
          close={() => setIsOpen(false)}
          parentRef={containerRef}
          position={extraClass === 'DropUp' ? Coordinate.TOP : Coordinate.BOTTOM}
          width={width}
        >
          <ul className={`${styles.List} ${disabled ? styles.Disabled : ''}`}>
            {options.map((item) => (
              <li
                className={`${styles.Item} ${item.error ? styles.Error : ''} ${isCheckboxDropdown ? styles.HasCheckbox : ''}`}
                id={`${id}-${item.value}`}
                key={item.value}
                onClick={() => !isCheckboxDropdown && (onChange(item.value), setIsOpen(false))}
              >
                {isCheckboxDropdown ? (
                  <CheckboxNEPOS
                    checked={selectedElements.some((elem) => elem === item.value)}
                    handleCheck={() => {
                      handleCheck(item.value);
                      onChange(item.value);
                    }}
                    label={item.label}
                  />
                ) : (
                  <div className={styles.ItemContent}>
                    {item.icon && <i className={`${styles.Icon} ${item.icon}`} />}
                    {item.label}
                    {item.error && <i className={`${styles.Icon} di icon-blitz-fehler`} />}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </DropdownPanel>
      )}
    </div>
  );
};

export default IconButtonDropdown;
