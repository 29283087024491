import { useCallback, useEffect, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { DIAGRAM_ENVIRONMENTS } from 'assets/constants/constants';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import SearchNEPOS from 'components/UI/SearchNEPOS/SearchNEPOS';
import Spinner from 'components/UI/Spinner/Spinner';
import useEnvironment from 'hooks/useEnvironment';
import usePrevious from 'hooks/usePrevious';
import useProcess from 'hooks/useProcess';
import { TreeData, TreeElement } from 'types/processes';

import styles from './TreePanel.module.scss';

const TreePanel = (props: { diagramId: string }) => {
  const { diagramId } = props;
  const { t } = useTranslation();
  const { getTree } = useProcess(diagramId);
  const history = useHistory();
  const checkEnvironment = useEnvironment();
  const [treeExplorer, setTreeExplorer] = useState<TreeElement[]>([]);
  const [areExpanded, setAreExpanded] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [expandAllDiagrams, setExpandAllDiagrams] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isParentIDNedeed = true;
  const isSearching = Boolean(searchText && searchText.length > 2);
  const previousSearchText = usePrevious(searchText);

  const setFormattedDiagrams = (currentDiagrams: TreeData[], highestLevelDiagramID: number): TreeElement[] => {
    return currentDiagrams
      .filter((currentDiagram: TreeData) => currentDiagram.pid === highestLevelDiagramID)
      .map((currentDiagram: TreeData) => {
        return {
          id: currentDiagram.id,
          name: currentDiagram.name,
          children: setFormattedDiagrams(currentDiagrams, currentDiagram.id),
        };
      });
  };

  useEffect(() => {
    if (treeExplorer.length > 0) return;
    getTree(isParentIDNedeed).then((res) => {
      setIsLoading(true);
      const highestLevelDiagrams: TreeData[] = [];
      const dependentDiagrams: TreeData[] = [];

      res.forEach((diagram: TreeData) => {
        if (diagram.pid === undefined) {
          highestLevelDiagrams.push(diagram);
        } else {
          dependentDiagrams.push(diagram);
        }
      });

      const formattedDiagrams = highestLevelDiagrams.map((highestLevelDiagram: TreeData) => {
        return {
          id: highestLevelDiagram.id,
          name: highestLevelDiagram.name,
          children: setFormattedDiagrams(dependentDiagrams, highestLevelDiagram.id),
        };
      });

      setTreeExplorer(formattedDiagrams);
      setIsLoading(false);
    });
  }, [treeExplorer]); // eslint-disable-line react-hooks/exhaustive-deps

  const setExpandedDiagrams = useCallback(
    (value: number) => {
      let newDiagramsExpanded = [...areExpanded];

      if (newDiagramsExpanded.includes(value)) {
        newDiagramsExpanded = newDiagramsExpanded.filter((el) => el !== value);
      } else {
        newDiagramsExpanded = [...newDiagramsExpanded, value];
      }

      setAreExpanded(newDiagramsExpanded);
    },
    [areExpanded],
  );

  const handleHighlightText = (text: string, searchedText: string) => {
    const textToHighlight = text.split(new RegExp(`(${searchedText})`, 'gi'));
    return (
      <span>
        {textToHighlight.map((textPart, index) => {
          const id = `textPart-${index}`;
          return (
            <span className={textPart === searchedText ? styles.HighLighted : ''} key={id}>
              {textPart}
            </span>
          );
        })}
      </span>
    );
  };

  const setNewExpandedElements = (tree: TreeElement[]): number[] => {
    const allDiagrams: number[] = [];
    tree.forEach((diagram) => {
      if (diagram.children.length > 0) {
        const childrensHaveSearchedText = diagram.children.filter(
          (element) => isSearching && searchText && element.name && element.name.includes(searchText),
        );

        if (childrensHaveSearchedText.length > 0) {
          allDiagrams.push(diagram.id);
          allDiagrams.push(...setNewExpandedElements(diagram.children));
        } else {
          const childDiagrams = setNewExpandedElements(diagram.children);
          if (childDiagrams.length > 0) {
            allDiagrams.push(diagram.id);
            allDiagrams.push(...childDiagrams);
          }
        }
      }
    });

    return allDiagrams;
  };

  useEffect(() => {
    if (previousSearchText !== searchText) {
      setAreExpanded(setNewExpandedElements(treeExplorer));
      setExpandAllDiagrams(false);
    }
    setIsLoading(false);
  }, [expandAllDiagrams, treeExplorer, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const treeRenderer = useCallback(
    (tree: TreeElement[], indentationIndex?: number) => {
      if (tree.length === 0) return;
      const panel = tree.map((element: TreeElement) => {
        const hasSearchedString = isSearching && searchText && element.name?.includes(searchText);
        const hasChildren = element.children.length > 0;
        const indentationLevel = indentationIndex ? 1 + indentationIndex : 1;
        const indentation = `${indentationLevel * 2.25}rem`;
        const childrensHaveSearchedText = element.children.filter(
          (diagram) => isSearching && searchText && diagram.name && diagram.name.includes(searchText),
        );

        if (
          hasChildren &&
          ((isSearching && (childrensHaveSearchedText.length > 0 || hasSearchedString)) || !isSearching || expandAllDiagrams)
        ) {
          return (
            <div key={element.id}>
              <li
                className={`${styles.TreeItem} ${
                  ((isSearching && childrensHaveSearchedText.length > 0) || !isSearching || expandAllDiagrams) &&
                  styles.HasChildren
                }`}
                id={element.id.toString()}
                style={{ marginLeft: indentation }}
              >
                {((isSearching && childrensHaveSearchedText.length > 0) || !isSearching || expandAllDiagrams) && (
                  <div className={styles.Arrow} onClick={() => setExpandedDiagrams(element.id)}>
                    <i className={`di icon-pfeil-chevron-${areExpanded.includes(element.id) ? 'unten' : 'rechts'}`} />
                  </div>
                )}
                <TooltipComponent
                  content={element.name}
                  cssClass="mbc-tooltip nepos-tooltip"
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  <span
                    className={`${styles.ItemText} ${
                      !((isSearching && childrensHaveSearchedText.length > 0) || !isSearching || expandAllDiagrams)
                        ? styles.ExtraPadding
                        : ''
                    }`}
                    onClick={() =>
                      history.push(`/${checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) ? 'published' : 'diagram'}/${element.id}`)
                    }
                  >
                    {isSearching && hasSearchedString ? handleHighlightText(element.name, searchText) : element.name}
                  </span>
                </TooltipComponent>
              </li>
              <div className={`${styles.TreeItemPanel} ${areExpanded.includes(element.id) ? styles.IsOpen : ''}`}>
                {treeRenderer(element.children, indentationLevel)}
              </div>
            </div>
          );
        }

        if (!hasChildren && ((isSearching && hasSearchedString) || !isSearching || expandAllDiagrams)) {
          return (
            <li className={styles.TreeItem} id={element.id.toString()} key={element.id} style={{ marginLeft: indentation }}>
              <TooltipComponent
                content={element.name}
                cssClass="mbc-tooltip nepos-tooltip"
                position="BottomCenter"
                showTipPointer={false}
              >
                <span
                  className={`${styles.ItemText} ${styles.ExtraPadding}`}
                  onClick={() =>
                    history.push(`/${checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) ? 'published' : 'diagram'}/${element.id}`)
                  }
                >
                  {isSearching && hasSearchedString ? handleHighlightText(element.name, searchText) : element.name}
                </span>
              </TooltipComponent>
            </li>
          );
        }

        return undefined;
      });

      return panel;
    },
    [searchText, areExpanded, setExpandedDiagrams, checkEnvironment, history, expandAllDiagrams, isSearching],
  );

  const handleSearch = (text: string) => {
    if (isSearching) setIsLoading(true);
    setSearchText(text);
  };

  return (
    <div className={styles.TreePanel}>
      <div className={styles.Search}>
        <SearchNEPOS id="tree-explorer-search" isAsync isFullWidth searching={handleSearch} />
      </div>
      <ul className={styles.Tree}>
        {isLoading || treeExplorer.length === 0 ? (
          <div className="spinner">
            <Spinner isVisible />
          </div>
        ) : (
          treeRenderer(treeExplorer)
        )}
      </ul>
      {isSearching && !expandAllDiagrams && (
        <ButtonNEPOS
          className={styles.Button}
          handleClick={() => {
            setIsLoading(true);
            setExpandAllDiagrams(true);
          }}
          id="whole-tree-button"
          isSecondary
        >
          {t('showWholeTree')}
        </ButtonNEPOS>
      )}
    </div>
  );
};

export default TreePanel;
