import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import {
  DATA_SET,
  DIAGRAM,
  ERROR_WORKFLOW_015,
  NOT_TRANSLATABLE,
  QUICK_HIERARCHICAL_DS_WF_EPC,
  QUICK_RELEASE_DS_WF_VCD,
  STATUS,
} from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { parseWorkflowDataResponse } from 'assets/js/Utils';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import { DatasetActionTypes } from 'contexts/Dataset/DatasetContext';
import { DiagramActionTypes, setActivitiesError } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import useProcess from 'hooks/useProcess';
import { getDataset } from 'services/dataset';
import { datasetSendForRelease, getDatasetworkflowData, getDatasetWorkflowTypes } from 'services/datasetWorkflow';
import { getDiagram } from 'services/design';
import { getAllWorkflowTypes, sendForRelease, workflowByProcessNumber } from 'services/workflowServices';
import { Language } from 'types/config';

import useDataset from './useDataset';
import useDatasetContext from './useDatasetContext';
import useDiagramPicture from './useDiagramPicture';
import useTool from './useTool';

export default function useWorkflow(id: string) {
  const { dispatch, processData, pictures, isTakingPictures } = useDiagramContext();
  const { datasetData, dispatch: datasetDispatch } = useDatasetContext();
  const { t } = useTranslation();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const { parseProcessDataResponse, saveProcess } = useProcess(id);
  const { saveDataset, fetchPublishedDataset } = useDataset();
  const { getAllLanguagePictures } = useDiagramPicture(true);
  const [errorValidation, setErrorValidation] = useState(false);
  const { resetTool } = useTool();
  const wfCodeRef = useRef<string | null>(null);
  const history = useHistory();
  const location = useLocation();

  const fetchWorkflowData = useCallback(
    async (isComplete: boolean) => {
      try {
        const { data } = await getDiagram(id);

        fetchFormTypes(
          getFormTypesCode({
            isGroupFetch: true,
            status: data?.status,
            type: DIAGRAM,
            variant: data?.type,
          }),
        );
        dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA_SUCCESS, payload: parseProcessDataResponse(data) });
        const processNumber = data?.attributes[NOT_TRANSLATABLE]?.PROCESS_NUMBER;
        if (!isComplete) return;

        const wfResponse = await workflowByProcessNumber(processNumber);
        const wfStagesResponse = await getAllWorkflowTypes(wfResponse.data.type, data?.isRecommendation);

        dispatch({
          type: DiagramActionTypes.FETCH_WORKFLOW_DATA,
          payload: parseWorkflowDataResponse(wfResponse.data, wfStagesResponse.data),
        });
      } catch (err) {
        handleServiceError(err);
      }
    },
    [dispatch, fetchFormTypes, getFormTypesCode, id, parseProcessDataResponse],
  );

  const sendProcessForRelease = useCallback(
    (images: { [key in Language]?: string[] }) => {
      if (processData && wfCodeRef.current) {
        const workflowTypeCode = wfCodeRef.current;
        wfCodeRef.current = null;

        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW });

        saveProcess(processData).then(() => {
          const data = {
            idDiagram: parseInt(id, 10),
            workflowTypeCode,
            images,
          };

          sendForRelease(data)
            .then((res) => res.data)
            .then((isComplete: boolean) => {
              fetchWorkflowData(isComplete).then(() => dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS }));
            })
            .catch((err) => {
              const errorData = err.response.data;
              if (errorData.code) {
                if (errorData.code === 'FORM_VALIDATION_400') {
                  dispatch({ type: DiagramActionTypes.SEND_FOR_RELEASE_ERROR, payload: errorData });
                } else {
                  dispatch({
                    type: DiagramActionTypes.SET_ERROR,
                    payload: {
                      title: t('error'),
                      message: t(`errors.${errorData.code}`, errorData.code === ERROR_WORKFLOW_015 ? processData.type : ''),
                    },
                  });
                }
                if (errorData.errors?.activitiesWithAttributesErrors) {
                  dispatch(setActivitiesError(errorData.errors.activitiesWithAttributesErrors));
                  dispatch({
                    type: DiagramActionTypes.SET_DIAGRAM_VALIDATION,
                    payload: true,
                  });
                }
              } else {
                handleServiceError(err);
              }
              dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
            });
        });
      }
    },
    [dispatch, fetchWorkflowData, id, processData, saveProcess, t],
  );

  const prepareSendForRelease = useCallback(
    (workflowCode: string) => {
      resetTool();
      wfCodeRef.current = workflowCode;
      getAllLanguagePictures();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAllLanguagePictures],
  );

  const fetchWorkflowDatasetData = useCallback(
    async (workflowCode: string) => {
      try {
        if (!datasetData) return;
        const { data } = await getDataset(datasetData.id);
        const { data: workflowData } = await getDatasetworkflowData(parseInt(id, 10), data.version);

        fetchFormTypes(
          getFormTypesCode({
            isGroupFetch: true,
            status: STATUS.WORKFLOW,
            type: DATA_SET,
          }),
        );

        const wfStagesResponse = await getDatasetWorkflowTypes(workflowCode);

        datasetDispatch({
          type: DatasetActionTypes.SET_WORKFLOW_DATA,
          payload: { ...parseWorkflowDataResponse(workflowData, wfStagesResponse.data), type: workflowData.type.code },
        });

        datasetDispatch({
          type: DatasetActionTypes.SET_DATASET,
          payload: data,
        });
      } catch (err) {
        handleServiceError(err);
      }
    },
    [datasetDispatch, datasetData, fetchFormTypes, getFormTypesCode, id],
  );

  const sendProcessForReleaseDataset = useCallback(
    (workflowCode) => {
      if (datasetData && workflowCode) {
        const workflowTypeCode = workflowCode;
        wfCodeRef.current = null;

        saveDataset(datasetData).then(() => {
          const data: { idDataSet: number; workflowTypeCode: string } = {
            idDataSet: parseInt(id, 10),
            workflowTypeCode,
          };

          datasetDispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });

          datasetSendForRelease(data)
            .then(() => {
              if (workflowTypeCode === QUICK_HIERARCHICAL_DS_WF_EPC || workflowTypeCode === QUICK_RELEASE_DS_WF_VCD) {
                datasetDispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
                fetchPublishedDataset(datasetData.id);
                return history.push(`/dataset-published/${id}`);
              }
              fetchWorkflowDatasetData(workflowCode)
                .then(() => datasetDispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false }))
                .catch((err) => {
                  handleServiceError(err);
                });
            })
            .catch((err) => {
              handleServiceError(err, () => window.location.reload());
            });
        });
      }
    },
    [id, datasetDispatch, datasetData, saveDataset, fetchWorkflowDatasetData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (Object.keys(pictures).length > 0 && isTakingPictures.success && !isTakingPictures.all) {
      sendProcessForRelease(pictures);
    }
  }, [pictures, isTakingPictures, sendProcessForRelease]);

  useEffect(() => {
    if (processData?.status === STATUS.SANDBOX && !location.pathname.includes('/sandbox') && !errorValidation) {
      Dialog.showAlert({
        name: t('warning'),
        message: t('errors.notDiagram'),
        isError: true,
        closeClick: () => history.push('/diagram/1'),
      });
      setErrorValidation(true);
    }
  }, [processData, history, errorValidation, t, location.pathname]);

  return {
    fetchWorkflowData,
    prepareSendForRelease,
    sendProcessForReleaseDataset,
  };
}
