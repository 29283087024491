import React from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from '../../assets/constants/constants';
import styles from './StatusTag.module.scss';

const StatusTag = ({ code }) => {
  const { t } = useTranslation();

  if (!Constants.STATUS_TAG[code]) {
    return null;
  }

  return <span className={styles.Tag}>{t(Constants.STATUS_TAG[code])}</span>;
};

export default StatusTag;
