import TitleBar from 'components/TitleBar/TitleBar';

import styles from './Page.module.scss';

type PageProps = {
  hiddenWarning?: boolean;
  children: React.ReactNode;
};

const Page = ({ hiddenWarning, children }: PageProps) => (
  <div className={styles.Container}>
    <TitleBar hiddenWarning={hiddenWarning} />
    {children}
  </div>
);

export default Page;
