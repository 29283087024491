import useDiagramContext from 'hooks/useDiagramContext';

import styles from './GhostConnector.module.scss';

const GhostConnector = () => {
  const { fontSize, ghostConnector } = useDiagramContext();

  return ghostConnector ? (
    <path
      className={styles.Line}
      d={`M ${ghostConnector.source.left},${ghostConnector.source.top}
          ${ghostConnector.vertices.map(({ direction, coordinate }) => `${direction} ${coordinate}`)}
          L ${ghostConnector.target.left},${ghostConnector.target.top}`}
      strokeWidth={2 / (fontSize || 2)}
    />
  ) : null;
};

export default GhostConnector;
