import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import { DialogType } from 'types/dialogs';

const ErrorContext = React.createContext();

const ErrorProvider = ({ children }) => {
  const { t } = useTranslation();
  const [dialogError, setDialogError] = useState(null);

  const value = { dialogError, setDialogError };

  const handleConfirm = () => {
    if (typeof dialogError.handleConfirm === 'function') dialogError.handleConfirm();
    setDialogError(null);
  };

  const dialogButtons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: () => handleConfirm(),
      key: 'submit',
    },
  ];

  return (
    <ErrorContext.Provider value={value}>
      {children}
      {dialogError && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtons,
            title: dialogError.title || t('error'),
            type: DialogType.Error,
          }}
          extraClass="Modal"
        >
          {dialogError.content}
        </DialogNEPOS>
      )}
    </ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorProvider };
