import { CSSProperties, MouseEvent, RefObject, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import useOuterClick from 'hooks/useOuterClick';
import { Coordinate } from 'types/diagram';

import styles from './SelectionPanel.module.scss';

interface Props {
  close: () => void;
  parentRef: RefObject<HTMLDivElement>;
  options: {
    id: string;
    icon: string;
    click: (ev: MouseEvent) => void;
  }[];
  className?: string;
  style?: CSSProperties;
}

const SelectionPanel = ({ close, parentRef, options, className, style }: Props) => {
  const { t } = useTranslation();
  const handleOuterClick = useCallback(
    (event) => {
      if (parentRef.current?.contains(event.target)) return;
      close();
    },
    [close, parentRef],
  );
  useOuterClick(parentRef, handleOuterClick);

  return (
    <DropdownPanel
      className={`${styles.PalettePanel} ${className}`}
      close={close}
      parentRef={parentRef}
      position={Coordinate.TOP}
      style={style}
    >
      <div className={styles.Options} onMouseDown={(e: MouseEvent) => e.stopPropagation()}>
        {options.map((option) => (
          <button
            className={styles.Option}
            data-qa={`menu-${option.id}`}
            key={`menu-${option.id}`}
            onClick={(e: MouseEvent) => {
              option.click(e);
              close();
            }}
            type="button"
          >
            <i className={`di ${option.icon} ${styles.IconMenu}`} />
            <span className={styles.OptionText}>{t(option.id)}</span>
          </button>
        ))}
      </div>
    </DropdownPanel>
  );
};

export default SelectionPanel;
