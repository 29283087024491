import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import * as serviceUtils from 'assets/js/serviceUtils';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import Icon from 'components/UI/Icon/Icon';
import Search from 'components/UI/Search/Search';
import Spinner from 'components/UI/Spinner/Spinner';
import TabFilter from 'components/UI/TabFilter/TabFilter';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { ProcessType } from 'types/processes';

import styles from './SandboxDraft.module.scss';

const SandboxDraft = (props) => {
  const {
    handleClick,
    loadingSandbox,
    disabled,
    sandboxTree,
    addSandbox,
    isDefault,
    handleSearch,
    results,
    diagramId,
    isLateralPanel,
  } = props;
  const { NEPOS, isCreateSwimlaneEnabled, isCreateSIPOCEnabled, isFreezed } = useFeatureFlags();
  const { t, i18n } = useTranslation();
  const buttonRef = useRef(null);
  const [loadingDraft, setLoading] = useState(false);
  const [tabFilter, setTabFilter] = useState(!serviceUtils.isCentral() ? Constants.TAB_RECOMMENDATION : Constants.TAB_DRAFT);
  const [isDropdownPanelOpen, setIsDropdownPanelOpen] = useState(false);

  const filteredResults = useMemo(
    () => results.filter((elem) => (tabFilter === Constants.TAB_RECOMMENDATION ? elem.isRecommendation : !elem.isRecommendation)),
    [results, tabFilter],
  );

  useEffect(() => {
    setLoading(false);
  }, [sandboxTree, loadingSandbox]);

  const handleAddClick = () => {
    if (NEPOS) {
      setIsDropdownPanelOpen(true);
    } else {
      setLoading(true);
      addSandbox();
    }
  };

  const neposDraftOptions = [
    {
      key: 'EPC',
      label: t('EPC'),
      onClick: () => {
        setLoading(true);
        addSandbox(ProcessType.EPC);
        setIsDropdownPanelOpen(false);
      },
    },
    {
      key: 'NEPOS SIPOC',
      label: t('NEPOS SIPOC'),
      onClick: () => {
        setLoading(true);
        addSandbox(ProcessType.SIPOC);
        setIsDropdownPanelOpen(false);
      },
    },
    {
      key: 'NEPOS_SWIMLANE',
      label: t('NEPOS_SWIMLANE'),
      onClick: () => {
        setLoading(true);
        addSandbox(ProcessType.SWIMLANE);
        setIsDropdownPanelOpen(false);
      },
    },
  ];

  const filterNeposDraftOptions = () => {
    let filteredOptions = neposDraftOptions;
    if (!isCreateSwimlaneEnabled) {
      filteredOptions = filteredOptions.filter((option) => option.key !== 'NEPOS_SWIMLANE');
    }

    if (!isCreateSIPOCEnabled) {
      filteredOptions = filteredOptions.filter((option) => option.key !== 'NEPOS SIPOC');
    }
    return filteredOptions;
  };

  let content = (
    <>
      <div className={styles.SandboxOverviewTitle}>
        <h5>{t('sandboxOverviewTitle')}</h5>
        <div className={styles.AddIcon} ref={buttonRef}>
          <Icon
            disabled={isFreezed}
            extraClass={isDropdownPanelOpen ? `'card-tooltip' ${styles.HideTooltip}` : 'card-tooltip'}
            iconClass="di icon-plus-hinzufuegen-klein"
            id="addDraft"
            offsetY={-5}
            onClick={handleAddClick}
            tooltip={t('tool.addNew')}
          />
        </div>
      </div>
      {isDropdownPanelOpen && (
        <DropdownPanel close={() => setIsDropdownPanelOpen(false)} parentRef={buttonRef} width={120}>
          <div className={styles.Options}>
            {filterNeposDraftOptions().map((option) => (
              <div className={styles.Option} id={`SANDBOX_DRAFT_CREATE_${option.key}`} key={option.key} onClick={option.onClick}>
                {option.label}
              </div>
            ))}
          </div>
        </DropdownPanel>
      )}
      <Search searching={(text) => handleSearch(text)} />
      {!serviceUtils.isCentral() && (
        <div className={styles.Tabs}>
          {Constants.TABS_SANDBOX_OVERVIEW.map((filter) => (
            <TabFilter
              click={() => setTabFilter(filter)}
              customStyle={styles.Tab}
              customStyleSelected={styles.Selected}
              idFilter={filter}
              isSelected={filter === tabFilter}
              key={filter}
            />
          ))}
        </div>
      )}
      <div className={styles.DraftList}>
        {filteredResults?.length > 0 ? (
          filteredResults.map((draft) => (
            <div
              className={`${styles.DraftItem} ${draft.isRecommendation && !isLateralPanel ? styles.RightInfo : ''}
                ${draft.id === diagramId ? styles.DraftSelected : ''} ${isDefault ? styles.Default : ''}`}
              key={draft.id}
              onClick={() => handleClick(draft.id)}
            >
              <div>
                <i aria-hidden="true" className="di icon-hierarchie-diagramm" />
                {JSON.parse(draft.name)[i18n.language.toUpperCase()].PROCESS_NAME}
              </div>
              {draft.isRecommendation && !isLateralPanel && (
                <div className={styles.Right}>
                  <div className={styles.Data}>{draft.author}</div>
                  <div className={styles.Data}>{draft.date}</div>
                  <div className={styles.Data}>{draft.time}</div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>{t('noResults')}</p>
        )}
      </div>
    </>
  );

  if (loadingSandbox || loadingDraft) {
    content = (
      <div className="spinner">
        <Spinner isVisible />
      </div>
    );
  }

  return (
    <>
      {disabled && <div className={styles.DisableLayer} />}
      {content}
    </>
  );
};

export default SandboxDraft;
