import { useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import Button from '../UI/Button/Button';
import styles from './DialogDetail.module.scss';

const ObjectDialog = (props) => {
  const { refAssignment, visible, close, t, objectAttributes, objectsUsedDiagram, createObjects, language } = props;
  const [inputsToBeAdded, setInputs] = useState([]);
  const [inputText, setText] = useState('');
  let iconObjectType = (
    <i aria-hidden="true" className={`di ${Constants.OBJECT_ICONS[objectAttributes.code]} ${styles.IconType}`} />
  );
  const isMilestone = objectAttributes.code === Constants.MILESTONE;

  if (objectAttributes.code === Constants.INPUT || objectAttributes.code === Constants.OUTPUT) {
    iconObjectType = '';
  }

  let already;

  if (objectAttributes.code) {
    already = [...objectsUsedDiagram].filter(
      (elem) =>
        elem.idFront.includes(objectAttributes.code.replace('_', '')) &&
        !objectAttributes.symbolUsedObjects.some((usedElem) => usedElem.id === elem.idFront),
    );

    already = already.map((item) => {
      const attr = Utils.getObjectAttributes(item.attributes);

      return {
        OBJECT_NAME: attr[language].OBJECT_NAME,
        id: item.id,
        idFront: item.idFront,
      };
    });
  }

  const cleanDialog = () => {
    setText('');
    setInputs([]);
  };

  const addTitle = () => {
    if (inputText.trim() === '') {
      return;
    }

    const newItem = {
      OBJECT_NAME: inputText,
      newObject: true,
    };
    setInputs([...inputsToBeAdded, newItem]);
    setText('');
  };

  const deleteTitle = (titleToDelete) => {
    const titlesList = [...inputsToBeAdded].filter((title) => titleToDelete !== title);
    setInputs(titlesList);
  };

  const closeDialog = () => {
    cleanDialog();
    close();
  };

  const addTitles = () => {
    cleanDialog();
    createObjects(inputsToBeAdded);
  };

  const addAlreadyUsed = (elem) => {
    const isAdded = inputsToBeAdded.find((used) => used.OBJECT_NAME === elem.OBJECT_NAME);

    if (!isAdded) {
      const alreadyUsedClicked = already.find((clicked) => clicked.idFront === elem.idFront);
      alreadyUsedClicked.newObject = false;
      setInputs([...inputsToBeAdded, alreadyUsedClicked]);
    }
  };

  const alreadyUsed = already
    ? already.map((elem) => {
        const isBeingUsed = inputsToBeAdded.find((addedElem) => addedElem.idFront === elem.idFront);

        return (
          <div
            className={`${styles.ItemAlreadyUsed} ${isBeingUsed ? styles.BeingUsed : ''}`}
            id={elem.idFront}
            key={elem.idFront}
            onClick={() => addAlreadyUsed(elem)}
          >
            <span>{elem.OBJECT_NAME}</span>
          </div>
        );
      })
    : null;

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addTitle();
    }
  };

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={closeDialog}
      closeOnEscape
      id="dialog-detail"
      isModal
      ref={refAssignment}
      target="#dialog"
      visible={visible}
      width="510px"
    >
      <div className={styles.Content}>
        <div className={`${styles.Section} ${styles.SectionNoCatalog}`}>
          <h4>{t('addNewX', { labelX: t(`contextualMenu.${objectAttributes.code}`) })}</h4>
          <div className={styles.ObjectInput}>
            <input
              className={styles.InputText}
              id="inputObjectDialog"
              onChange={(e) => setText(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
              placeholder={t('insertTitle')}
              type="text"
              value={inputText}
            />
            <i
              aria-hidden="true"
              className={`di icon-kreis-hinzufuegen ${styles.PlusIcon}`}
              id="addNewInput"
              onClick={addTitle}
            />
          </div>
          <div className="alreadyUsedStyle">{t('alreadyUsed')}</div>
          <div className={styles.ItemUsed}>{alreadyUsed}</div>
          <p>{t('selectedXToBeAdded', { labelX: objectAttributes.title })}</p>
          <div className={styles.ItemSelected}>
            {inputsToBeAdded.map((input) => (
              <div className={isMilestone ? styles.Milestone : styles.AddedBox} key={input.OBJECT_NAME}>
                <i
                  aria-hidden="true"
                  className={`icon-kreis-loeschen ${styles.DeleteIcon}`}
                  id="delete"
                  onClick={() => deleteTitle(input)}
                />
                {iconObjectType}
                <p>{input.OBJECT_NAME}</p>
              </div>
            ))}
          </div>
          <div className={styles.ButtonLine}>
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={close} id="cancel" />
            <Button
              btnText={t('add')}
              buttonStyle="Primary"
              disabled={inputsToBeAdded.length === 0}
              handleClick={addTitles}
              id="add"
            />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default withTranslation('common')(ObjectDialog);
