import React from 'react';

import { withTranslation } from 'react-i18next';

import Button from '../Button/Button';
import styles from './SearchFilter.module.scss';

const PopupFilter = ({ children, resetClick, saveClick, isRecommendation, applyClick, t }) => (
  <div className={styles.Popup}>
    <div className={styles.Fields}>{children}</div>
    <div className={styles.ButtonLine}>
      <Button btnText={t('apply')} buttonStyle="Primary" handleClick={applyClick} id="apply" />
      <Button btnText={t('reset')} buttonStyle="Secondary" handleClick={resetClick} id="reset" />
      {isRecommendation && <Button btnText={t('save')} buttonStyle="Primary" handleClick={saveClick} id="save" />}
    </div>
  </div>
);

export default withTranslation('common')(PopupFilter);
