import { ReactElement } from 'react';

import { Language } from './config';

export enum AttributeCode {
  'ADDITIONAL_DOCUMENTS' = 'ADDITIONAL_DOCUMENTS',
  'APPROVER' = 'APPROVER',
  'APPROVERS' = 'APPROVERS',
  'APPROVERS_HISTORICAL' = 'APPROVERS_HISTORICAL',
  'AUTHOR' = 'AUTHOR',
  'AUTHOR_HISTORICAL' = 'AUTHOR_HISTORICAL',
  'AUTHOR_DELEGATE_AUTHOR' = 'AUTHOR_DELEGATE_AUTHOR',
  'AUTHOR_DELEGATE_INSERT_REASON' = 'AUTHOR_DELEGATE_INSERT_REASON',
  'AUTHOR_DELEGATE_NEW_AUTHOR' = 'AUTHOR_DELEGATE_NEW_AUTHOR',
  'METHOD_OWNER_DELEGATE_CURRENT_METHOD_OWNER' = 'METHOD_OWNER_DELEGATE_CURRENT_METHOD_OWNER',
  'METHOD_OWNER_DELEGATE_INSERT_REASON' = 'METHOD_OWNER_DELEGATE_INSERT_REASON',
  'METHOD_OWNER_DELEGATE_NEW_METHOD_OWNER' = 'METHOD_OWNER_DELEGATE_NEW_METHOD_OWNER',
  'QI_DELEGATE_CURRENT_QI' = 'QI_DELEGATE_CURRENT_QI',
  'QI_DELEGATE_INSERT_REASON' = 'QI_DELEGATE_INSERT_REASON',
  'QI_DELEGATE_NEW_QI' = 'QI_DELEGATE_NEW_QI',
  'AUTHORITY' = 'AUTHORITY',
  'BOARD_APPROVAL' = 'BOARD_APPROVAL',
  'CREATION' = 'CREATION',
  'CREATOR' = 'CREATOR',
  'LINKED_DIAGRAM' = 'LINKED_DIAGRAM',
  'CREATION_DATE' = 'CREATION_DATE',
  CREATOR_CODE = 'CREATOR_CODE',
  LAST_EDITOR_CODE = 'LAST_EDITOR_CODE',
  LAST_MODIFICATION = 'LAST_MODIFICATION',
  'COMMON_DESCRIPTION' = 'COMMON_DESCRIPTION',
  'COMMON_NAME' = 'COMMON_NAME',
  'COMMON_INFO_RELEASER' = 'COMMON_INFO_RELEASER',
  'DESCRIPTION' = 'DESCRIPTION',
  'DOCUMENT_NAME' = 'DOCUMENT_NAME',
  'DOCUMENT_LINK' = 'DOCUMENT_LINK',
  'FINANCE_APPROVED' = 'FINANCE_APPROVED',
  'FURTHER_DETAILING_ROLE' = 'FURTHER_DETAILING_ROLE',
  'INFO_APPROVERS' = 'INFO_APPROVERS',
  'INFO_MANAGERS' = 'INFO_MANAGERS',
  'KPI_NAME' = 'KPI_NAME',
  'KPI_DESCRIPTION' = 'KPI_DESCRIPTION',
  'KPI_TARGET' = 'KPI_TARGET',
  'KPI_FORMULA' = 'KPI_FORMULA',
  LINK = 'LINK',
  'LINK_RESPONSIBILITY' = 'LINK_RESPONSIBILITY',
  'METHOD_OWNER' = 'METHOD_OWNER',
  'METHOD_OWNER_HISTORICAL' = 'METHOD_OWNER_HISTORICAL',
  'MODELER' = 'MODELER',
  'MONTHS_VALIDITY' = 'MONTHS_VALIDITY',
  'NAME' = 'NAME',
  'NAME_REQUESTER' = 'NAME_REQUESTER',
  'NEXT_REVISION' = 'NEXT_REVISION',
  'NOTIFY_ALL_MANAGERS' = 'NOTIFY_ALL_MANAGERS',
  'OBJECT_DESCRIPTION' = 'OBJECT_DESCRIPTION',
  'OBJECT_NAME' = 'OBJECT_NAME',
  'OPPORTUNITIES_&_THREATS' = 'OPPORTUNITIES_&_THREATS',
  'PROCESS_NAME' = 'PROCESS_NAME',
  'PROCESS_NUMBER' = 'PROCESS_NUMBER',
  'PROCESS_TARGET' = 'PROCESS_TARGET',
  'PROCESS_DESCRIPTION' = 'PROCESS_DESCRIPTION',
  'PROCESS_KEY_FIGURE' = 'PROCESS_KEY_FIGURE',
  'PROCESS_RESPONSIBLE' = 'PROCESS_RESPONSIBLE',
  'PROCESS_STEP_NUMBER' = 'PROCESS_STEP_NUMBER',
  'PUBLICATION_DATE' = 'PUBLICATION_DATE',
  'QA_INSPECTOR_GROUP' = 'QA_INSPECTOR_GROUP',
  'QUALITY_INSPECTOR' = 'QUALITY_INSPECTOR',
  'QI_GROUP' = 'QI_GROUP',
  'REASON_ARCHIVE' = 'REASON_ARCHIVE',
  'REASON_CHANGE' = 'REASON_CHANGE',
  'RECOMMENDED_LOCATIONS' = 'RECOMMENDED_LOCATIONS',
  'RECOMMEND_NEW_LOCATIONS' = 'RECOMMEND_NEW_LOCATIONS',
  'rejectionReason' = 'rejectionReason',
  'RESPONSIBILITY' = 'RESPONSIBILITY',
  'RESPONSIBLE_AREA' = 'RESPONSIBLE_AREA',
  'RESPONSIBLE_PERSON' = 'RESPONSIBLE_PERSON',
  'SCOPE' = 'SCOPE',
  'SCOPES' = 'SCOPES',
  'SUBSCRIBERS' = 'SUBSCRIBERS',
  'SUBSCRIBERS_ARCHIVE' = 'SUBSCRIBERS_ARCHIVE',
  'TASKS' = 'TASKS',
  'TERMS_&_ABBREVIATIONS' = 'TERMS_&_ABBREVIATIONS',
  'TEXT_BLOCK' = 'TEXT_BLOCK',
  'TITLE' = 'TITLE',
  'LINKAGE' = 'LINKAGE',
  'VERSION' = 'VERSION',
  START_EVENT = 'START_EVENT',
  'LAST_EVENT' = 'LAST_EVENT',
  'INITIAL_DESCRIPTION' = 'INITIAL_DESCRIPTION',
  'EXAMPLE' = 'EXAMPLE',
  'RESPONSIBLE_PACEMAKER' = 'RESPONSIBLE_PACEMAKER',
  'RESPONSIBLE_PROCESS' = 'RESPONSIBLE_PROCESS',
  'ORIGIN_PROCESS' = 'ORIGIN_PROCESS',
  'POTENTIAL_ANNUAL_EBIT' = 'POTENTIAL_ANNUAL_EBIT',
  'POTENTIAL_ONE_TIME_CASHFLOW' = 'POTENTIAL_ONE_TIME_CASHFLOW',
  'SOLUTION_DESCRIPTION' = 'SOLUTION_DESCRIPTION',
  'REQUIRED_INVEST' = 'REQUIRED_INVEST',
  'EVALUATION_ANNUAL_EBIT' = 'EVALUATION_ANNUAL_EBIT',
  'EVALUATION_ONE_TIME_CASHFLOW' = 'EVALUATION_ONE_TIME_CASHFLOW',
  'TARGET_DATE' = 'TARGET_DATE',
  'INITIAL_CATEGORY' = 'INITIAL_CATEGORY',
  'PROCESS_TITLE' = 'PROCESS_TITLE',
  'RESPONSIBLE_PACEMAKERS' = 'RESPONSIBLE_PACEMAKERS',
  'RESPONSIBLE_COORDINATORS' = 'RESPONSIBLE_COORDINATORS',
  'RESPONSIBLE_PERSONS' = 'RESPONSIBLE_PERSONS',
  'PL_WAVE' = 'PL_WAVE',
  'CATEGORY' = 'CATEGORY',
  'COORDINATOR' = 'COORDINATOR',
  'LEVEL' = 'LEVEL',
  'LEVEL1_PROCESS' = 'LEVEL1_PROCESS',
  'LEVEL2_PROCESS' = 'LEVEL2_PROCESS',
  'PL_PACEMAKER' = 'PL_PACEMAKER',
  'RESPONSIBLE' = 'RESPONSIBLE',
  'PROCESS_RESPONSIBLE_L2' = 'PROCESS_RESPONSIBLE_L2',
  'ID_ORIGIN_PROCESS' = 'ID_ORIGIN_PROCESS',
  'IDS_RESPONSIBLE_PROCESS' = 'IDS_RESPONSIBLE_PROCESS',
  'SEND_WORKFLOW' = 'SEND_WORKFLOW',
}

/* export type AttributeValues = {
  [AttributeCode.PROCESS_NAME]: string;
  [AttributeCode.PROCESS_NUMBER]: string;
  [AttributeCode.PROCESS_TARGET]: string;
  [AttributeCode.PROCESS_DESCRIPTION]: string;
  [AttributeCode['OPPORTUNITIES_&_THREATS']]: string;
  [AttributeCode['TERMS_&_ABBREVIATIONS']]: string;
  [AttributeCode.PROCESS_KEY_FIGURE]: string;
  [AttributeCode.ADDITIONAL_DOCUMENTS]: string;
  [AttributeCode.SCOPES]: string;
  [AttributeCode.VERSION]: string;
  [AttributeCode.REASON_CHANGE]: string;
  [AttributeCode.MODELER]: string;
  [AttributeCode.AUTHOR]: string;
  [AttributeCode.METHOD_OWNER]: string;
  [AttributeCode.QA_INSPECTOR_GROUP]: string;
  [AttributeCode.APPROVER]: string;
  [AttributeCode.OBJECT_NAME]: string;
  [AttributeCode.OBJECT_DESCRIPTION]: string;
  [AttributeCode.PROCESS_STEP_NUMBER]: string;
  [AttributeCode.TEXT_BLOCK]: string;
  [AttributeCode.TASKS]: string;
  [AttributeCode.AUTHORITY]: string;
  [AttributeCode.RESPONSIBLE_AREA]: string;
  [AttributeCode.LINK_RESPONSIBILITY]: string;
  [AttributeCode.FURTHER_DETAILING_ROLE]: string;
  [AttributeCode.APPROVERS]: string;
  [AttributeCode.RECOMMENDED_LOCATIONS]: string;
  [AttributeCode.RECOMMEND_NEW_LOCATIONS]: string;
  [AttributeCode.INFO_APPROVERS]: string;
  [AttributeCode.MONTHS_VALIDITY]: string;
  [AttributeCode.INFO_MANAGERS]: string;
  [AttributeCode.NOTIFY_ALL_MANAGERS]: string;
  [AttributeCode.SUBSCRIBERS]: string;
  [AttributeCode.AUTHOR_HISTORICAL]: string;
  [AttributeCode.METHOD_OWNER_HISTORICAL]: string;
  [AttributeCode.APPROVERS_HISTORICAL]: string;
  [AttributeCode.REASON_ARCHIVE]: string;
  [AttributeCode.SUBSCRIBERS_ARCHIVE]: string;
  [AttributeCode.RESPONSIBILITY]: string;
  [AttributeCode.NAME_REQUESTER]: string;
  [AttributeCode.DOCUMENT_NAME]: string;
  [AttributeCode.DOCUMENT_LINK]: string;
  [AttributeCode.PUBLICATION_DATE]: string;
  [AttributeCode.BOARD_APPROVAL]: string;
  [AttributeCode.QUALITY_INSPECTOR]: string;
  [AttributeCode.NEXT_REVISION]: string;
  [AttributeCode.NAME]: string;
  [AttributeCode.DESCRIPTION]: string;
  [AttributeCode.PROCESS_RESPONSIBLE]: string;
  [AttributeCode.KPI_NAME]: string;
  [AttributeCode.KPI_DESCRIPTION]: string;
  [AttributeCode.KPI_TARGET]: string;
  [AttributeCode.KPI_FORMULA]: string;
}; */

export enum FormLanguage {
  EN = 'EN',
  DE = 'DE',
  CN = 'CN',
  NOT_TRANSLATABLE = 'NOT_TRANSLATABLE',
}

export enum Input {
  CHECKBOX = 'CHECKBOX',
  DATE_TIME = 'DATE_TIME',
  DIAGRAM_SELECTOR = 'DIAGRAM_SELECTOR',
  DOCUMENT_ID = 'DOCUMENT_ID',
  DROPDOWN = 'DROPDOWN',
  MULTISELECTION = 'MULTISELECTION',
  PROCESS_ID = 'PROCESS_ID',
  SEARCHINPUT = 'SEARCHINPUT',
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  TEXTAREA = 'TEXTAREA',
  URL = 'URL',
  USER_ID = 'USER_ID',
  WYSIWYG = 'WYSIWYG',
  WYSIWYG2 = 'WYSIWYG2',
  RADIOBUTTON = 'RADIOBUTTON',
  PROCESS_SELECTOR = 'PROCESS_SELECTOR',
  INPUT_FILE_CHIPS = 'INPUT_FILE_CHIPS',
}

export enum Care {
  MANDATORY = 'M',
  OPTIONAL = 'O',
  SYSTEM = 'S',
}

export enum SearchBy {
  USER = 'USER',
  SCOPE = 'SCOPE',
  PROCESS = 'PROCESS',
}

export enum VariantProperty {
  NUMERIC_DECIMAL = 'NUMERIC_DECIMAL',
  TOGGLE = 'TOGGLE',
  DOTS_DECIMAL = 'DOTS_DECIMAL',
  NUMERIC = 'NUMERIC',
}

export type SearchByInfo = {
  [key in SearchBy]: {
    service: (args: unknown) => void;
    displayValueFormatter: (item?: any) => string | undefined;
    multipleValueFormatter: (
      catalog: {
        attributes?: Attributes;
        code: string;
        commonName?: string;
        description?: { [k in Language]: string };
        id?: number;
      }[],
      item?: any,
    ) => Chip[];
  };
};

// FormTypes
export type FieldProperties = {
  ITEM_ID?: string;
  LINKED_FORM?: string;
  MAX?: string;
  MIN?: string;
  OPTIONS?: string;
  SEARCH_BY?: SearchBy;
  DIAGRAM_VCD_TYPE?: string;
  DIAGRAM_EPC_TYPE?: string;
  VARIANT?: VariantProperty;
  TOOLTIP?: string;
};

export type AttributeFormTypes = {
  care: Care;
  disabled?: boolean;
  onBlur?: (event?: React.FocusEvent) => void;
  position: number;
  properties?: FieldProperties;
  type: Input;
  tooltip: string;
};
export type LanguageFormTypes = {
  [key in AttributeCode]?: AttributeFormTypes;
};
export type FormTypes = {
  [key in FormLanguage]?: LanguageFormTypes;
};

// Values
export type AttributeValue = string | string[];
export type LanguageAttributes = {
  [key in AttributeCode]?: AttributeValue;
};
export type Attributes = {
  [key in FormLanguage]?: LanguageAttributes;
};

// Errors
export enum FieldError {
  NONE = '',
  REQUIRED = 'REQUIRED',
}
export type LanguageAttributeErrors = {
  [key in AttributeCode]?: FieldError;
};
export type AttributeErrors = {
  [key in FormLanguage]?: LanguageAttributeErrors;
};

// Flags (touched, focused...)
export type LanguageAttributeFlags = {
  [key in AttributeCode]?: boolean;
};
export type AttributeFlags = {
  [key in FormLanguage]?: LanguageAttributeFlags;
};

// Fields
export type FieldMeta = {
  itemId?: string;
  max?: number;
  min?: number;
  options?: string[];
  searchBy?: SearchBy;
  Component: (props: any) => ReactElement;
  'data-language': FormLanguage;
  'data-code': AttributeCode;
  disabled?: boolean;
  onBlur: (event: FocusEvent) => void;
  onChange: (value: AttributeValue) => void;
  required?: boolean;
  diagramEpcType: string;
  diagramVcdType: string;
  selected?: Chip[];
};
export type Field = Omit<AttributeFormTypes, 'properties'> &
  FieldMeta & {
    label: string;
    placeholder?: string;
    chips?: Chip[];
    code: AttributeCode;
    error: FieldError;
    id: string;
    key: string;
    touched: boolean;
    value: AttributeValue;
    clearInput?: () => void;
    externalChips?: Chip[];
    isRequirement?: boolean;
  };
export type FormMeta = {
  [language in FormLanguage]?: { [key in AttributeCode]?: Omit<AttributeFormTypes, 'properties'> & FieldMeta };
};
export type LanguageFormFields = Field[];
export type FormFields = {
  [key in FormLanguage]?: Field[];
};

export type FormError = {
  [key: string]: Object;
  id: number;
  type: string;
};

export type FormValidationError = {
  code: string;
  forms: FormError[];
};

export interface Chip {
  name?: string;
  idDiagramType?: number;
  code: string;
  displayName: string;
  icon?: string;
  link?: string;
}

export interface LanguageChip extends Chip {
  description: { [key in FormLanguage]: string };
}

export type Scope = {
  abbreviation: string;
  departmentId: string;
  description: string;
  firstIncumbentUser: string;
  parentDepartmentId: string;
  secondIncumbentUser: string;
  symbolicChildren: string[];
  validFrom: string;
  validUntil: string;
};

export type AttributeError = {
  language: string;
  code: string;
};

export type SymbolError = {
  id: string;
  attribute: AttributeError;
};

export type SymbolObjectError = {
  id: string | undefined;
  symbolId: string;
};

export interface InputComponent {
  className?: string;
  disabled?: boolean;
  error: {
    hasError: boolean;
    icon?: string;
  };
  id: string;
  reference: React.RefObject<HTMLInputElement> | React.RefObject<HTMLLabelElement>;
  styles: { readonly [key: string]: string };
  value: string;
}
