import { useEffect, useRef } from 'react';

export default function usePrevious(value: unknown) {
  const valueRef = useRef<unknown>();

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
}
