import { DialogUtility } from '@syncfusion/ej2-react-popups';
import './Dialogs.scss';

export const showAlert = ({ message, name, isError, closeClick = () => {}, textOk = 'OK' }) => {
  const title = isError ? `<i class="di icon-blitz-fehler error"></i>${name}` : name;

  DialogUtility.alert({
    animationSettings: { effect: 'Zoom' },
    okButton: { text: textOk, cssClass: 'alert-button' },
    closeOnEscape: true,
    content: message,
    cssClass: isError ? 'error-modal' : 'normal-modal',
    position: { x: 'center', y: 'center' },
    title,
    close: closeClick,
  });
};

export const showConfirm = ({ message, title, textCancel, textOk, okClick }) => {
  const dialog = DialogUtility.confirm({
    animationSettings: { effect: 'Zoom' },
    cancelButton: { text: textCancel, cssClass: 'confirm-button-cancel' },
    okButton: { text: textOk, click: okClick, cssClass: 'confirm-button-ok' },
    closeOnEscape: true,
    content: message,
    cssClass: 'confirm-modal',
    position: { x: 'center', y: 'center' },
    title,
  });
  dialog.btnObj.forEach((btn) => {
    btn.element.id = btn.isPrimary ? 'conf-dialog-btn-ok' : 'conf-dialog-btn-cancel';
  });
  return dialog;
};

export const showError = ({ closeClick, message, title }) =>
  DialogUtility.alert({
    animationSettings: { effect: 'Zoom' },
    closeOnEscape: false,
    content: message,
    cssClass: 'error-modal',
    okButton: { text: 'OK', cssClass: 'alert-button', click: closeClick },
    position: { x: 'center', y: 'center' },
    title: `<i class="di icon-blitz-fehler error"></i>${title}`,
    zIndex: 1002,
  });
