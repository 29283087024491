import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { handleServiceError } from 'assets/js/serviceUtils';
import { showAlert } from 'components/UI/Dialogs/Dialogs';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import { deleteVideo, uploadVideo } from 'services/videoManagement';

export default function useFile(
  maxFiles: number,
  maxSize: number,
  allowedExtensions: string[],
  allowedTypes: string[],
  multipleFiles: boolean = false,
) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { dispatch, processData } = useDiagramContext();
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<{ [key: string]: File[] }>({});

  const handleDragFile = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const alreadyExistsFile = (file: File, uploadedFiles: File[]) => uploadedFiles.find((doc) => doc.name === file.name);

  const areFilesEqual = (file1: File, file2: File) => file1.name === file2.name;

  const isValidFile = (file: File) =>
    allowedExtensions?.some((extension) => file.name.endsWith(extension)) || allowedTypes?.includes(file.type);

  const isTooBigFile = (file: File) => file.size > maxSize;

  const filterFiles = (baseList: File[], newList: File[]) => {
    let filteredList: File[] = [];

    if (baseList) {
      filteredList = newList.filter((newFile) => {
        let include = true;

        baseList.forEach((oldFile) => {
          if (areFilesEqual(newFile, oldFile)) {
            include = false;
          }
        });

        return include;
      });
    }

    return filteredList;
  };

  const checkFiles = (selectedFiles: FileList, uploadedFiles?: File[]) => {
    const files = Array.from(selectedFiles);
    const numUploadedFiles = uploadedFiles?.length || 0;

    if (files.length + numUploadedFiles > maxFiles) {
      showAlert({
        name: t('error'),
        message: t('dataset.errors.API_ERROR_11'),
        isError: true,
      });
      return;
    }

    let newValidFiles: File[] = [];
    let invalidFormatFiles: File[] = [];
    let tooBigFiles: File[] = [];
    let alreadyExistingFiles: File[] = [];

    // We filter invalid files
    files.forEach((file) => {
      if (multipleFiles && uploadedFiles && alreadyExistsFile(file, uploadedFiles)) {
        alreadyExistingFiles.push(file);
      } else if (!isValidFile(file)) {
        invalidFormatFiles.push(file);
      } else if (isTooBigFile(file)) {
        tooBigFiles.push(file);
      } else {
        newValidFiles.push(file);
      }
    });

    // We skip duplicate files
    if (multipleFiles && validFiles && invalidFiles) {
      newValidFiles = filterFiles(validFiles, newValidFiles);
      alreadyExistingFiles = filterFiles(invalidFiles.duplicate || [], alreadyExistingFiles);
    }
    invalidFormatFiles = filterFiles(invalidFiles?.format || [], invalidFormatFiles);
    tooBigFiles = filterFiles(invalidFiles?.size || [], tooBigFiles);

    // We update the selected files list
    setValidFiles([...(validFiles || []), ...newValidFiles]);
    setInvalidFiles({
      ...invalidFiles,
      duplicate: multipleFiles ? [...(invalidFiles?.duplicate || []), ...alreadyExistingFiles] : [],
      format: [...(invalidFiles?.format || []), ...invalidFormatFiles],
      size: [...(invalidFiles?.size || []), ...tooBigFiles],
    });
  };

  const handleDropFile = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!event.dataTransfer) return;
    checkFiles(event.dataTransfer.files);
  };

  const uploadFiles = async (files: File[]) => {
    if (!files.length) return;

    const formData = new FormData();

    formData.append('video', files[0]);
    formData.append('name', files[0].name);
    formData.append('diagram_id', id);

    uploadVideo(formData, {
      'Content-Type': 'multipart/form-data; charset=utf-8; boundary="MBCMAP"',
    })
      .then(() => {
        if (!processData) return;
        dispatch({ type: DiagramActionTypes.UPLOAD_VIDEO_SUCESS });
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const deleteFile = async (fileName?: string) => {
    try {
      await deleteVideo(id);

      if (!processData) return;
      setValidFiles(validFiles.filter((file) => file.name !== fileName));
      dispatch({ type: DiagramActionTypes.DELETE_VIDEO_SUCCESS });
    } catch (error) {
      handleServiceError(error);
    }
  };

  useEffect(() => {
    if (!validFiles.length) return;
    uploadFiles(validFiles);
  }, [validFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileSelection = (files: FileList) => {
    if (!files) return;
    checkFiles(files);
  };

  return {
    checkFiles,
    deleteFile,
    handleDragFile,
    handleDropFile,
    handleFileSelection,
    isValidFile,
    isTooBigFile,
    uploadFiles,
  };
}
