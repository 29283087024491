import React, { useReducer } from 'react';

const FormTypesActions = {
  GET_FORMTYPES: 'GET_FORMTYPES',
  GET_FORMTYPES_SUCCESS: 'GET_FORMTYPES_SUCCESS',
  GET_FORMTYPES_ERROR: 'GET_FORMTYPES_ERROR',
  SET_OLD_FORMTYPES: 'SET_OLD_FORMTYPES',
};

const initialState = {
  isLoading: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case FormTypesActions.GET_FORMTYPES:
      return {
        ...state,
        isLoading: true,
      };
    case FormTypesActions.GET_FORMTYPES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case FormTypesActions.GET_FORMTYPES_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case FormTypesActions.SET_OLD_FORMTYPES:
      return {
        ...state,
        old: {
          ...state.old,
          [payload.key]: payload.fieldConfig,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const FormTypesContext = React.createContext();

const FormTypesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <FormTypesContext.Provider value={value}>{children}</FormTypesContext.Provider>;
};

export { FormTypesActions, FormTypesContext, FormTypesProvider };
