import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import * as Utils from '../../assets/js/Utils';
import * as Dialog from '../../components/UI/Dialogs/Dialogs';
import styles from './Recommendation.module.scss';

const RecommendedMessage = (props) => {
  const { date, epcName, url } = props;
  const { t, i18n } = useTranslation();
  const creationDate = useMemo(() => Utils.getFullFormattedDate(date), [date]);
  const translatedEPCName = typeof epcName === 'string' ? JSON.parse(epcName)[i18n.language.toUpperCase()].TITLE : '';

  const onClickLinkConfirm = () => {
    if (url) window.location.href = url;
  };

  const linkDialog = {
    title: t('warningText'),
    message: t('recommendations.view.linkDialog.content'),
    textCancel: t('cancel'),
    textOk: t('confirm'),
    okClick: onClickLinkConfirm,
  };

  const onClickLink = () => {
    Dialog.showConfirm(linkDialog);
  };

  return (
    <>
      <div className={styles.AttributeWrapper}>
        <i className={`di icon-uhr-zeit ${styles.Icon}`} />
        <p className={styles.Label}>{t('recommendations.view.attributes.date')}</p>
        <p className={styles.Value}>{creationDate.split('-')[0]}</p>
        <div className={styles.SeparatorIcon}>•</div>
        <p className={styles.Value}>{creationDate.split('-')[1]}</p>
      </div>
      <div className={styles.AttributeWrapper}>
        <i className={`di icon-information ${styles.Icon}`} />
        <p className={styles.Label}>{t('recommendations.view.attributes.warning')}</p>
      </div>
      <div className={styles.LinkWrapper}>
        <p className={styles.Label}>{t('recommendations.view.attributes.link')}</p>
        <div className={styles.Link} onClick={onClickLink}>
          <i className={`di icon-link ${styles.Icon}`} />
          {translatedEPCName}
        </div>
      </div>
    </>
  );
};

export default RecommendedMessage;
