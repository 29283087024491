import React from 'react';

import { Validators } from 'react-reactive-form';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import CheckBox from '../UI/CheckBox/CheckBox';
import DropdownForm from '../UI/Dropdown/DropdownForm';
import InputTextLegacy from '../UI/InputText/InputTextLegacy';
import InputWysiwygLegacy from '../UI/InputWysiwyg/InputWysiwygLegacy';
import Multiselection from '../UI/Multiselection/Multiselection';

export const EPC_DIAGRAM = 'EPC';
export const VCD_DIAGRAM = 'VCD';
export const APPROVERS = 'Approvers';
export const QIG = 'Quality inspector group';
export const CURRENT = 'Current';
export const PENDING = 'Pending';
export const DONE = 'Done';
export const USER = 'USER';
export const GROUP = 'GROUP';

const components = {
  TEXT: (props) => <InputTextLegacy {...props} />,
  DROPDOWN: (props) => <DropdownForm {...props} />,
  DATE_TIME: (props) => <InputTextLegacy {...props} />,
  MULTISELECTION: (props) => <Multiselection {...props} />,
  CHECKBOX: CheckBox,
  TEXTAREA: (props) => <InputWysiwygLegacy {...props} />,
  WYSIWYG: (props) => <InputWysiwygLegacy {...props} />,
};

const fieldTypes = {
  TEXT: 'TEXT',
  DROPDOWN: 'DROPDOWN',
  DATE_TIME: 'DATE_TIME',
  MULTISELECTION: 'MULTISELECTION',
  CHECKBOX: 'CHECKBOX',
  TEXTAREA: 'TEXTAREA',
};

const monthsData = [
  { text: '6 months', value: '6', selected: false },
  { text: '12 months', value: '12', selected: false },
  { text: '18 months', value: '18', selected: false },
  { text: '24 months', value: '24', selected: false },
  { text: '30 months', value: '30', selected: false },
  { text: '36 months', value: '36', selected: false },
];

const WF_KEY = '_WF';

const fields = { text: 'Name', value: 'Code' };
const multiselectFields = [
  Constants.AUTHOR_HISTORICAL,
  Constants.METHOD_OWNER,
  Constants.METHOD_OWNER_HISTORICAL,
  Constants.APPROVERS,
  Constants.APPROVERS_HISTORICAL,
  Constants.SUBSCRIBERS,
  Constants.SUBSCRIBERS_ARCHIVE,
  Constants.SCOPES,
  Constants.RECOMMENDED_LOCATIONS,
  Constants.RECOMMEND_NEW_LOCATIONS,
];
export const multiselectUserFields = [
  Constants.AUTHOR,
  Constants.AUTHOR_HISTORICAL,
  Constants.METHOD_OWNER,
  Constants.METHOD_OWNER_HISTORICAL,
  Constants.APPROVERS,
  Constants.APPROVERS_HISTORICAL,
  Constants.SUBSCRIBERS,
  Constants.SUBSCRIBERS_ARCHIVE,
];

export const getWorkflowData = (formValues, qualityGroups) => {
  const workflowData = [];
  const keys = Object.keys(formValues);

  for (let i = 0; i < keys.length; i++) {
    let valuesList = [];
    let valuesInput = formValues[keys[i]];

    if (valuesInput) {
      if (keys[i].includes('QA_INSPECTOR_GROUP')) {
        if (valuesInput.selected) {
          // Dropdown sin modificar
          valuesList.push(valuesInput.value.toString());
        } else {
          const qaSelected = qualityGroups.find((a) => a === valuesInput);
          valuesList.push(qaSelected);
        }
      } else if (typeof valuesInput === 'string' || typeof valuesInput === 'number' || typeof valuesInput === 'boolean') {
        valuesList.push(Utils.purify(valuesInput).toString());
      } else if (valuesInput.selected) {
        // Dropdown sin modificaciones
        valuesList.push(valuesInput.value.toString());
      } else if (multiselectFields.includes(keys[i])) {
        valuesList = valuesInput.map((elem) => elem.Code || elem);
      } else {
        valuesInput.forEach((v) => {
          if (v) {
            valuesList.push(v.toString());
          }
        });
      }
    } else {
      valuesInput = '';
    }

    workflowData.push({
      code: keys[i],
      value: valuesList,
    });
  }

  return workflowData;
};

export const parseAttributesResponse = (data, attributeTypes, areasData) => {
  const modalData = {};
  const formData = {};

  for (let i = 0; i < data.length; i++) {
    const type = attributeTypes.find((at) => at.attributeType.code === data[i].code);

    if (type) {
      let optionSelected;
      if (type.attributeType.code.includes('QA_INSPECTOR_GROUP')) {
        optionSelected = areasData.find((a) => a.value === data[i].value[0]);

        if (optionSelected) {
          optionSelected.selected = true;
        }
        modalData[data[i].code] = optionSelected;
        formData[`${data[i].code}${WF_KEY}`] = optionSelected;
      } else if (type.attributeType.code === 'MONTHS_VALIDITY') {
        optionSelected = monthsData.find((m) => m.value === data[i].value[0]);
        optionSelected.selected = true;
        modalData[data[i].code] = optionSelected;
        formData[`${data[i].code}${WF_KEY}`] = optionSelected;
      } else if (['RECOMMENDED_LOCATIONS', 'RECOMMEND_NEW_LOCATIONS'].includes(type.attributeType.code)) {
        modalData[data[i].code] = data[i].value.map((elem) => ({ Code: elem, Name: elem.toString() }));
        formData[`${data[i].code}${WF_KEY}`] = data[i].value.map((elem) => ({ Code: elem, Name: elem.toString() }));
      } else if ([fieldTypes.MULTISELECTION, fieldTypes.MULTISELECTION_CONTROLLED].includes(type.attributeType.fieldType)) {
        modalData[data[i].code] = data[i].value;
        formData[`${data[i].code}${WF_KEY}`] = data[i].value;
      } else if (type.attributeType.fieldType === fieldTypes.CHECKBOX) {
        modalData[data[i].code] = data[i].value[0] === 'true';
        formData[`${data[i].code}${WF_KEY}`] = data[i].value[0] === 'true';
      } else if (type.attributeType.code === 'VALID_FROM') {
        modalData[data[i].code] = Utils.getFormattedDate(data[i].value[0]);
        formData[`${data[i].code}${WF_KEY}`] = Utils.getFormattedDate(data[i].value[0]);
      } else {
        // Type Input Text
        modalData[data[i].code] = data[i].value[0];
        formData[`${data[i].code}${WF_KEY}`] = data[i].value[0] || ' ';
      }
    }
  }

  return {
    modalData,
    formData,
  };
};

export const parseAttributesResponseNew = (data, fieldConfig, qualityGroups) => {
  const modalData = {};
  const formData = {};

  for (let i = 0; i < data.length; i++) {
    const fieldMeta = fieldConfig.controls[data[i].code]?.meta;

    if (fieldMeta) {
      let optionSelected;
      if (fieldMeta.code.includes('QA_INSPECTOR_GROUP')) {
        optionSelected = qualityGroups?.find((a) => a === data[i].value[0]);
        modalData[data[i].code] = optionSelected;
        formData[`${data[i].code}${WF_KEY}`] = optionSelected;
      } else if (fieldMeta.code === 'MONTHS_VALIDITY') {
        optionSelected = monthsData?.find((m) => m.value === data[i].value[0]);
        optionSelected.selected = true;
        modalData[data[i].code] = optionSelected;
        formData[`${data[i].code}${WF_KEY}`] = optionSelected;
      } else if (['RECOMMENDED_LOCATIONS', 'RECOMMEND_NEW_LOCATIONS'].includes(fieldMeta.code)) {
        modalData[data[i].code] = data[i].value.map((elem) => ({ Code: elem, Name: elem.toString() }));
        formData[`${data[i].code}${WF_KEY}`] = data[i].value.map((elem) => ({ Code: elem, Name: elem.toString() }));
      } else if ([fieldTypes.MULTISELECTION, fieldTypes.MULTISELECTION_CONTROLLED].includes(fieldMeta.fieldType)) {
        modalData[data[i].code] = data[i].value;
        formData[`${data[i].code}${WF_KEY}`] = data[i].value;
      } else if (fieldMeta.fieldType === fieldTypes.CHECKBOX) {
        modalData[data[i].code] = data[i].value[0] === 'true';
        formData[`${data[i].code}${WF_KEY}`] = data[i].value[0] === 'true';
      } else if (fieldMeta.code === 'VALID_FROM') {
        modalData[data[i].code] = Utils.getFormattedDate(data[i].value[0]);
        formData[`${data[i].code}${WF_KEY}`] = Utils.getFormattedDate(data[i].value[0]);
      } else {
        // Type Input Text
        modalData[data[i].code] = data[i].value[0];
        formData[`${data[i].code}${WF_KEY}`] = data[i].value[0] || ' ';
      }
    }
  }

  return {
    modalData,
    formData,
  };
};

export const getFieldConfig = (t, attributesData, qualityInspectors = [], attributesLoaded, systemAttributes) => {
  let controls = {};

  for (let i = 0; i < attributesData.length; i++) {
    const attribute = attributesData[i].attributeType;
    const key = attribute.code;

    if (systemAttributes || attributesData[i].care !== 'S' || key === 'VALID_FROM' || key === 'RECOMMENDED_LOCATIONS') {
      const newControl = {
        render: components[attribute.fieldType] || ((props) => <InputTextLegacy {...props} />),
        meta: {
          id: attribute.code,
          label: t(`workflowDialog.${key}`),
          placeholder: t(`workflowDialog.${key}`),
          attributeType: attributesData[i].care,
          code: attribute.code,
          description: attributesData[i].care !== 'S' ? t(`descriptionAttributes.${key}`) : false,
        },
      };

      if (attributesData[i].care === 'M') {
        newControl.options = {
          validators: [Validators.required, Utils.checkWhiteSpacesValidation],
        };
      }

      if (key === 'VALID_FROM') {
        newControl.meta.extraClass = 'inputValidFrom';
      } else if (key === 'MONTHS_VALIDITY') {
        newControl.meta.extraClass = 'inputValidTo';
      } else if (key === 'AUTHOR' || key === 'AUTHOR_HISTORICAL') {
        newControl.render = components.MULTISELECTION;
        attribute.fieldType = fieldTypes.MULTISELECTION;
      }

      if (key.includes('QA_INSPECTOR_GROUP')) {
        newControl.render = components.DROPDOWN;
        newControl.meta.options = qualityInspectors;
      } else if (key.includes('GAP_LOG_CATEGORY')) {
        newControl.render = newControl.meta.attributeType === 'S' ? components.TEXT : components.DROPDOWN;
        newControl.meta.options = [];
      } else if ([fieldTypes.MULTISELECTION, fieldTypes.MULTISELECTION_CONTROLLED].includes(attribute.fieldType)) {
        newControl.meta.fields = fields;
        newControl.meta.data = [];
        const multiSelectionAttribute = attributesLoaded.find((a) => a.code === key);
        const arrayValues = [];

        if (multiSelectionAttribute && multiSelectionAttribute.value) {
          for (let j = 0; j < multiSelectionAttribute.value.length; j++) {
            if (multiSelectionAttribute.value[j] === '0') {
              arrayValues.push(parseInt(multiSelectionAttribute.value[j], 10));
            } else {
              const newValue = multiSelectionAttribute.value[j].Code
                ? multiSelectionAttribute.value[j]
                : { Code: multiSelectionAttribute.value[j], Name: multiSelectionAttribute.value[j].toString() };
              arrayValues.push(newValue);
            }
          }
        }
        newControl.meta.valuesLoaded = arrayValues;
      } else if (key === 'MONTHS_VALIDITY') {
        newControl.render = components.DROPDOWN;
        newControl.meta.options = monthsData;
      }

      if (
        key === 'MONTHS_VALIDITY' ||
        key.includes('QA_INSPECTOR_GROUP') ||
        [fieldTypes.MULTISELECTION, fieldTypes.MULTISELECTION_CONTROLLED].includes(attribute.fieldType) ||
        key === 'NOTIFY_ALL_MANAGERS'
      ) {
        newControl.meta.disabled = attributesData[i].care === 'S';
      }

      controls = {
        ...controls,
        [key]: newControl,
      };
    }
  }

  return {
    controls,
  };
};

export const getWorkflowFieldConfig = (t, fieldsForm, qualityInspectors, attributesLoaded) => {
  let controls = {};

  for (let i = 0; i < fieldsForm.length; i++) {
    const attribute = fieldsForm[i].attributeType;
    let key = attribute.code;
    const input = {
      render: components[attribute.fieldType] || ((props) => <InputTextLegacy {...props} />),
      meta: {
        label: t(`workflowDialog.${key}`),
        placeholder: t(`workflowDialog.${key}`),
        attributeType: 'S',
        disabled: true,
      },
    };
    input.options = { validators: null };

    if (key === 'VALID_FROM') {
      input.meta.extraClass = 'inputValidFrom';
    }

    if (key.includes('QA_INSPECTOR_GROUP')) {
      input.render = components.DROPDOWN;
      input.meta.options = qualityInspectors;
    } else if (key.includes('GAP_LOG_CATEGORY')) {
      input.render = input.meta.attributeType === 'S' ? components.TEXT : components.DROPDOWN;
      input.meta.options = [];
    } else if ([fieldTypes.MULTISELECTION, fieldTypes.MULTISELECTION_CONTROLLED].includes(attribute.fieldType)) {
      input.meta.fields = fields;
      input.meta.data = [];
      const multiSelectionAttribute = attributesLoaded.find((a) => a.code === key);
      const arrayValues = [];

      if (multiSelectionAttribute && multiSelectionAttribute.value) {
        for (let j = 0; j < multiSelectionAttribute.value.length; j++) {
          if (multiSelectionAttribute.value[j] === '0') {
            arrayValues.push(parseInt(multiSelectionAttribute.value[j], 10));
          } else {
            arrayValues.push(multiSelectionAttribute.value[j]);
          }
        }
      }
      input.meta.valuesLoaded = arrayValues;
    } else if (key === 'MONTHS_VALIDITY') {
      input.render = components.DROPDOWN;
      input.meta.options = monthsData;
      input.meta.extraClass = 'inputValidTo';
    }

    key += WF_KEY;
    controls = {
      ...controls,
      [key]: input,
    };
  }

  return {
    controls,
  };
};

export const getUser = (stageType) => [`nameAttributes.${stageType}`, `workflowDialog.${stageType}`];

export const getDelegateFieldConfig = (t, stageType, isQIGroup, qaGroups) => {
  let controls = {
    [Constants.CURRENT_USER]: {
      render: (props) => <InputTextLegacy {...props} />,
      meta: {
        label: t('workflowDialog.currentUser', { userType: t(getUser(stageType)) }),
        attributeType: 'S',
        disabled: true,
      },
      options: {
        validators: null,
      },
    },
    [Constants.NEW_USER]: {
      render: components.MULTISELECTION,
      meta: {
        label: t('workflowDialog.newUser', { userType: t(getUser(stageType)) }),
        description: t('workflowDialog.descriptionNewUser', { userType: t(getUser(stageType)) }),
        placeholder: t('workflowDialog.newUser', { userType: t(getUser(stageType)) }),
        attributeType: 'M',
        id: Constants.NEW_APPROVER,
        valuesLoaded: [],
        max: 1,
        searchBy: Constants.SEARCH_BY.USER,
      },
      options: {
        validators: [Validators.required, Utils.checkWhiteSpacesValidation],
      },
    },
    [Constants.REASON]: {
      render: (props) => <InputTextLegacy {...props} />,
      meta: {
        label: t('workflowDialog.insertReason'),
        description: t('workflowDialog.descriptionReason', { userType: t(getUser(stageType)) }),
        placeholder: t('workflowDialog.reason'),
        attributeType: 'M',
      },
      options: {
        validators: [Validators.required, Utils.checkWhiteSpacesValidation],
      },
    },
  };

  if (isQIGroup) {
    controls = {
      [Constants.CURRENT_GROUP]: {
        render: (props) => <InputTextLegacy {...props} />,
        meta: {
          label: t('workflowDialog.currentUser', { userType: t(getUser(stageType)) }),
          attributeType: 'S',
          disabled: true,
        },
        options: {
          validators: null,
        },
      },
      [Constants.NEW_GROUP]: {
        render: components.DROPDOWN,
        meta: {
          label: t('workflowDialog.newUser', { userType: t(getUser(stageType)) }),
          description: t('workflowDialog.descriptionGroup'),
          attributeType: 'M',
          options: qaGroups,
        },
        options: {
          validators: [Validators.required, Utils.checkWhiteSpacesValidation],
        },
      },
      [Constants.REASON]: {
        render: (props) => <InputTextLegacy {...props} />,
        meta: {
          label: t('workflowDialog.insertReason'),
          description: t('workflowDialog.descriptionReason', { userType: t(getUser(stageType)) }),
          placeholder: t('workflowDialog.reason'),
          attributeType: 'M',
        },
        options: {
          validators: [Validators.required, Utils.checkWhiteSpacesValidation],
        },
      },
    };
  }

  return {
    controls,
  };
};
