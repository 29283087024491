import React, { useReducer } from 'react';

const Types = {
  ATTRIBUTE: 'attributeTypes',
  DIAGRAM: 'diagramTypes',
  OBJECT: 'objectTypes',
  QUALITY_GROUPS: 'qualityGroups',
  ROLE_RESPONSIBILITY: 'roleResponsibilityTypes',
  SYMBOL: 'symbolTypes',
};

const TypesActions = {
  GET_TYPES: 'GET_TYPES',
  GET_TYPES_SUCCESS: 'GET_TYPES_SUCCESS',
  GET_TYPES_ERROR: 'GET_TYPES_ERROR',
};

const initialState = {
  isLoading: false,
  [Types.ATTRIBUTE]: [],
  [Types.DIAGRAM]: [],
  [Types.OBJECT]: [],
  [Types.QUALITY_GROUPS]: [],
  [Types.ROLE_RESPONSIBILITY]: [],
  [Types.SYMBOL]: [],
};

const reducer = (state, action) => {
  const { actionType, error, ...types } = action;

  switch (actionType) {
    case TypesActions.GET_TYPES:
      return {
        ...state,
        isLoading: true,
      };
    case TypesActions.GET_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case TypesActions.GET_TYPES_SUCCESS:
      return {
        ...state,
        ...types,
        isLoading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

const TypesContext = React.createContext();

const TypesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <TypesContext.Provider value={value}>{children}</TypesContext.Provider>;
};

export { Types, TypesActions, TypesContext, TypesProvider };
