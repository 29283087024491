import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, ROLE_SWIMLANE } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import useDiagramContext from 'hooks/useDiagramContext';
import { DialogType } from 'types/dialogs';
import { ActivityProcessesSymbol, ActivityProcesses } from 'types/symbols';
import { TableColumn, TablePrefix, TableVariant } from 'types/tables';

import styles from './ActivityProcessesDialog.module.scss';

type Props = {
  handleClick: (processes?: ActivityProcessesSymbol[]) => void;
  processes: ActivityProcessesSymbol[];
  title: string;
};

const ActivityProcessesDialog = ({ handleClick, processes, title }: Props) => {
  const { t } = useTranslation();
  const { diagramLanguage } = useDiagramContext();
  const [selectedProcesses, setSelectedProcesses] = useState<string[]>([]);

  const getRolesFromProcess = (process: ActivityProcessesSymbol): string => {
    const roles = process.objects
      ?.filter((obj) => obj.type === ROLE_SWIMLANE)
      .map((role) => role.attributes?.[diagramLanguage]?.OBJECT_NAME?.toString());

    return roles?.join(', ') || '';
  };

  const orderProcesses = (processA: ActivityProcesses, processB: ActivityProcesses) => {
    if (processA.checked === processB.checked) return 0;
    return processA.checked ? -1 : 1;
  };

  const dataTableInitial: ActivityProcesses[] =
    processes
      ?.map((process: ActivityProcessesSymbol) => ({
        id: process.id,
        processStepName: process.attributes[diagramLanguage]?.OBJECT_NAME?.toString() || '',
        role: getRolesFromProcess(process),
        checked: process.isSelected,
      }))
      .sort(orderProcesses) || [];

  const [dataTable, setDataTable] = useState(dataTableInitial);

  const filterSelectedProcesses: ActivityProcessesSymbol[] = processes.map((process) => ({
    ...process,
    isSelected: selectedProcesses.includes(process.id),
  }));

  const ActivityProcessDialog = {
    title,
    type: DialogType.None,
    buttons: [
      {
        id: 'activity-process-dialog-confirm',
        handleClick: () => handleClick(filterSelectedProcesses),
        content: t('confirm'),
        buttonStyle: BUTTON_PRIMARY,
      },
      {
        id: 'activity-process-dialog-close',
        handleClick: () => handleClick(),
        content: t('CANCEL'),
        buttonStyle: BUTTON_SECONDARY,
      },
    ],
  };

  const handleSearch = (text: string) =>
    setDataTable(dataTableInitial.filter((proccess) => proccess.processStepName?.includes(text)));

  const ActivityProcessColumns: TableColumn<ActivityProcesses>[] = [
    { id: 'processStepName', name: 'Process Step Name', style: styles.columnWidth },
    { id: 'role', name: 'Role', style: styles.columnWidth },
  ];

  const handleRowClick = (id: string) => {
    const isAlreadyChecked = selectedProcesses.includes(id);
    const newSelectedProcesses = isAlreadyChecked
      ? selectedProcesses.filter((processId) => processId !== id)
      : [...selectedProcesses, id];
    setSelectedProcesses(newSelectedProcesses);
    setDataTable(dataTable.map((process) => ({ ...process, checked: newSelectedProcesses.includes(process.id) })));
  };

  return (
    <DialogNEPOS dialog={ActivityProcessDialog} extraClass="HistoryDialog">
      <TableWithSearch<ActivityProcesses>
        columns={ActivityProcessColumns}
        id={TablePrefix.ACTIVITY_PROCESSES}
        onCheck={setSelectedProcesses}
        onRowClick={handleRowClick}
        rows={dataTable}
        searching={handleSearch}
        variant={TableVariant.CHECKBOX}
      />
      <p>*{t('activityProcess.warningDisplay')}</p>
    </DialogNEPOS>
  );
};
export default ActivityProcessesDialog;
