import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { urlRegEx } from 'assets/constants/constants';
import { purify } from 'assets/js/Utils';
import ErrorTooltip from 'components/UI/ErrorTooltip/ErrorTooltip';
import FieldTooltip from 'components/UI/FieldTooltip/FieldTooltip';

import styles from './InputUrl.module.scss';

const InputUrl = (props) => {
  const { care, className, description, disabled, error, id, label, onChange, placeholder, required, value, touched, ...other } =
    props;
  const { t } = useTranslation();
  const hasError = Boolean(error);
  const isUrl = urlRegEx.test(value);

  const beforeShowTooltip = (event) => {
    if (isUrl) return;
    event.cancel = true;
  };

  const onClickInput = (event) => {
    if (!event.ctrlKey || !isUrl) return;
    window.open(value, '_blank', 'noopener noreferrer');
  };

  return (
    <div className={`${styles.Container} ${className || ''}`}>
      <div className={styles.LabelContainer}>
        <label className={`${styles.Label} ${hasError ? styles.Error : ''}`} htmlFor={id}>
          {label}
          {required ? '*' : ''}
        </label>
        {description && <FieldTooltip description={description} />}
        <ErrorTooltip message={error} />
      </div>
      {disabled && isUrl ? (
        <a className={styles.Url} href={value} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      ) : (
        <TooltipComponent
          beforeOpen={beforeShowTooltip}
          className="autosave-tooltip"
          content={t('inputLinkTooltip')}
          cssClass="mbc-tooltip"
          mouseTrail
          offsetX={30}
          offsetY={30}
          showTipPointer={false}
        >
          <input
            className={`${styles.Input} ${isUrl ? styles.UrlValue : ''} ${hasError ? styles.Error : ''} ${
              disabled ? styles.Disabled : ''
            }`}
            disabled={disabled}
            id={id}
            onChange={(event) => onChange(purify(event.target.value))}
            onClick={onClickInput}
            placeholder={disabled ? '-' : placeholder || t('fieldEnter', { field: label })}
            type="text"
            value={value}
            {...other}
          />
        </TooltipComponent>
      )}
    </div>
  );
};

export default InputUrl;
