import { createContext, useReducer, useRef, Dispatch, ReactNode, RefObject, MutableRefObject } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  BUTTON_SECONDARY,
  MAX_UNDO,
  NOT_TRANSLATABLE,
  SWIMLANE_MAX_HEIGHT,
  SWIMLANE_MAX_WIDTH,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
} from 'assets/constants/constants';
import { getLocalStorageAppLanguage } from 'assets/js/serviceUtils';
import { cloneObject } from 'assets/js/Utils';
import DialogFormError from 'components/DialogFormError/DialogFormError';
import DialogError from 'components/UI/DialogBasics/DialogError';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import { ObjectTypes, Role } from 'types/administration';
import { ButtonProps, Language } from 'types/config';
import { Connector, GhostConnectorData, GhostConnector } from 'types/connectors';
import {
  Background,
  Bounds,
  DiagramToImport,
  DiagramToLink,
  DiagramToMove,
  Orientation,
  Origin,
  Padding,
  PaletteActions,
  ScreenCoordinates,
  Tool,
  ResizeHandles,
} from 'types/diagram';
import { DialogType } from 'types/dialogs';
import { AttributeCode, Attributes, FormValidationError } from 'types/forms';
import {
  Levels,
  ProcessCatalogObject,
  ProcessData,
  ProcessDataCatalog,
  ProcessInterface,
  ProcessKPI,
  ProcessObject,
} from 'types/processes';
import { ProcessSupplierCustomer } from 'types/supplierCustomer';
import { SwimlaneV2 } from 'types/swimlanes';
import { PostIt, PostItTypes, Symbol, SymbolObject, SymbolTypes, TextFieldNEPOS } from 'types/symbols';
import { UndoRedoHistory } from 'types/undoRedo';
import { WorkflowData, WorkflowOption } from 'types/workflow';

type Refs = {
  backgroundRef: RefObject<HTMLDivElement>;
  boundsRef: RefObject<HTMLDivElement>;
  connectorPortMouseUp: MutableRefObject<boolean>;
  containerRef: RefObject<HTMLDivElement>;
  ghostConnectorData: MutableRefObject<GhostConnectorData | null>;
  initialCoords: MutableRefObject<{ x: number; y: number; displacement?: { x: number; y: number } } | null>;
  originRef: RefObject<HTMLDivElement>;
  shouldPreventClick: MutableRefObject<boolean>;
  isCopiedSymbol: MutableRefObject<boolean>;
  attributesRef: RefObject<HTMLDivElement>;
  attributesModalRef: RefObject<HTMLDivElement>;
  toolBarRef: RefObject<HTMLDivElement>;
  fontSizeRef: MutableRefObject<number | undefined>;
};

enum ActionTypes {
  CREATE_SWIMLANE = 'CREATE_SWIMLANE',
  CREATE_SYMBOL = 'CREATE_SYMBOL',
  CREATE_POST_IT = 'CREATE_POST_IT',
  CREATE_SYMBOL_IN_SWIMLANE = 'CREATE_SYMBOL_IN_SWIMLANE',
  MOVING_SWIMLANE_SYMBOL = 'MOVING_SWIMLANE_SYMBOL',
  DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS',
  DUPLICATE_SYMBOL_IN_SWIMLANE = 'DUPLICATE_SYMBOL_IN_SWIMLANE',
  CREATE_NOTE = 'CREATE_NOTE',
  CREATE_TEXTFIELD = 'CREATE_TEXTFIELD',
  DELETE_SWIMLANE = 'DELETE_SWIMLANE',
  SET_SWIMLANE_ROLE = 'SET_SWIMLANE_ROLE',
  SET_SWIMLANE_SYMBOL_OBJECTS = 'SET_SWIMLANE_SYMBOL_OBJECTS',
  DELETE_SWIMLANE_SYMBOL_OBJECT = 'DELETE_SWIMLANE_SYMBOL_OBJECT',
  INIT_GHOST_SWIMLANE = 'INIT_GHOST_SWIMLANE',
  UPDATE_GHOST_SWIMLANE = 'UPDATE_GHOST_SWIMLANE',
  UPDATE_GHOST_SYMBOL = 'UPDATE_GHOST_SYMBOL',
  END_GHOST_SWIMLANE = 'END_GHOST_SWIMLANE',
  UPDATE_SWIMLANES = 'UPDATE_SWIMLANES',
  UPDATE_SIZE_SWIMLANES = 'UPDATE_SIZE_SWIMLANES',
  FETCH_PROCESS_DATA = 'FETCH_PROCESS_DATA',
  FETCH_PROCESS_DATA_ERROR = 'FETCH_PROCESS_DATA_ERROR',
  FETCH_PROCESS_DATA_SUCCESS = 'FETCH_PROCESS_DATA_SUCCESS',
  FETCH_WORKFLOW = 'FETCH_WORKFLOW',
  FETCH_WORKFLOW_DATA = 'FETCH_WORKFLOW_DATA',
  FETCH_WORKFLOW_ERROR = 'FETCH_WORKFLOW_ERROR',
  FETCH_WORKFLOW_OPTIONS = 'FETCH_WORKFLOW_OPTIONS',
  FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS',
  IMPORT_SANDBOX_SUCCESS = 'IMPORT_SANDBOX_SUCCESS',
  IMPORT_SANDBOX_ERROR = 'IMPORT_SANDBOX_ERROR',
  INIT_GHOST_CONNECTOR = 'INIT_GHOST_CONNECTOR',
  RESET_GHOST_CONNECTOR = 'RESET_GHOST_CONNECTOR',
  SAVE_PROCESS_DATA = 'SAVE_PROCESS_DATA',
  SAVE_PROCESS_DATA_ERROR = 'SAVE_PROCESS_DATA_ERROR',
  SAVE_PROCESS_DATA_SUCCESS = 'SAVE_PROCESS_DATA_SUCCESS',
  PROCESS_DATA_SUCCESS_TIMEOUT = 'PROCESS_DATA_SUCCESS_TIMEOUT',
  SEND_FOR_RELEASE = 'SEND_FOR_RELEASE',
  SEND_FOR_RELEASE_ERROR = 'SEND_FOR_RELEASE_ERROR',
  SEND_FOR_RELEASE_SUCCESS = 'SEND_FOR_RELEASE_SUCCESS',
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SET_CONNECTOR_SELECTION = 'SET_CONNECTOR_SELECTION',
  SET_PROCESS_ATTRIBUTES = 'SET_PROCESS_ATTRIBUTES',
  SET_ERROR = 'SET_ERROR',
  SET_FONT_SIZE = 'SET_FONT_SIZE',
  SET_PROCESS_INTERFACES = 'SET_PROCESS_INTERFACES',
  SET_PROCESS_STEP_WITH_ERRORS = 'SET_PROCESS_STEP_WITH_ERRORS',
  SET_SELECTION = 'SET_SELECTION',
  SET_TOOL = 'SET_TOOL',
  SET_PALETTE_ACTION = 'SET_PALETTE_ACTION',
  SET_CURRENT_LEVEL = 'SET_CURRENT_LEVEL',
  SET_WARNING = 'SET_WARNING',
  TOGGLE_SHOULD_UPDATE_BACKGROUND = 'TOGGLE_SHOULD_UPDATE_BACKGROUND',
  TOGGLE_GRAB_AND_MOVE = 'TOGGLE_GRAB_AND_MOVE',
  ADD_MULTIPLE_TOOL = 'ADD_MULTIPLE_TOOL',
  UPDATE_BACKGROUND = 'UPDATE_BACKGROUND',
  UPDATE_CONNECTORS = 'UPDATE_CONNECTORS',
  UPDATE_GHOST_CONNECTOR = 'UPDATE_GHOST_CONNECTOR',
  UPDATE_SYMBOLS = 'UPDATE_SYMBOLS',
  UPDATE_PROCESS_INTERFACES = 'UPDATE_PROCESS_INTERFACES',
  UPDATE_PROCESS_KPIS = 'UPDATE_PROCESS_KPIS',
  UPDATE_PROCESS_CATALOG = 'UPDATE_PROCESS_CATALOG',
  UPDATE_DIAGRAM_LANGUAGE = 'UPDATE_DIAGRAM_LANGUAGE',
  UPDATE_SYMBOLS_AND_CONNECTORS = 'UPDATE_SYMBOLS_AND_CONNECTORS',
  UPDATE_PROCESS_IOS = 'UPDATE_PROCESS_IOS',
  UPDATE_PROCESS_SCS = 'UPDATE_PROCESS_SCS',
  UPDATE_PROCESS_DATA = 'UPDATE_PROCESS_DATA',
  UPDATE_UNDO_REDO_HISTORY = 'UPDATE_UNDO_REDO_HISTORY',
  UPDATE_UNDO_REDO = 'UPDATE_UNDO_REDO',
  UPLOAD_VIDEO_SUCESS = 'UPLOAD_VIDEO_SUCESS',
  UPDATING_SYMBOL_NAME = 'UPDATING_SYMBOL_NAME',
  APPLY_UNDO_REDO = 'APPLY_UNDO_REDO',
  PREPARE_TAKING_PICTURES = 'PREPARE_TAKING_PICTURES',
  ZOOM_TO_FIT_BEFORE_PICTURES = 'ZOOM_TO_FIT_BEFORE_PICTURES',
  START_TAKING_PICTURES = 'START_TAKING_PICTURES',
  ADD_PICTURE = 'ADD_PICTURE',
  SET_TAKING_PICTURES_ON_THE_FLY = 'SET_TAKING_PICTURES_ON_THE_FLY',
  TAKING_PICTURES_SUCCESS = 'TAKING_PICTURES_SUCCESS',
  TAKING_PICTURES_ERROR = 'TAKING_PICTURES_ERROR',
  SET_DIAGRAMS_TO_IMPORT = 'SET_DIAGRAMS_TO_IMPORT',
  SET_DIAGRAMS_TO_LINK = 'SET_DIAGRAMS_TO_LINK',
  SET_DIAGRAMS_TO_MOVE = 'SET_DIAGRAMS_TO_MOVE',
  SET_DIAGRAM_VALIDATION = 'SET_DIAGRAM_VALIDATION',
  SET_POST_IT_FACTOR = 'SET_POST_IT_FACTOR',
  SET_SYMBOL_BOARDS_DISPLAYED = 'SET_SYMBOL_BOARDS_DISPLAYED',
  SET_LOADING_FALSE = 'SET_LOADING_FALSE',
  SET_LOADING_TRUE = 'SET_LOADING_TRUE',
  SET_SHOW_SYMBOL_OBJECTS = 'SET_SHOW_SYMBOL_OBJECTS',
  SET_SWIMLANE_DISPLAYING_ATTRIBUTES = 'SET_SWIMLANE_DISPLAYING_ATTRIBUTES',
  DELETE_SWIMLANE_SYMBOLS = 'DELETE_SWIMLANE_SYMBOLS',
  DELETE_SWIMLANE_SYMBOL_CONNECTORS = 'DELETE_SWIMLANE_SYMBOL_CONNECTORS',
  MOVE_DIAGRAM_SUCCESS = 'MOVE_DIAGRAM_SUCCESS',
  LINK_DIAGRAM_SUCCESS = 'LINK_DIAGRAM_SUCCESS',
  MOVE_DIAGRAM_ERROR = 'MOVE_DIAGRAM_ERROR',
  LINK_DIAGRAM_ERROR = 'LINK_DIAGRAM_ERROR',
  TOGGLE_AUTOSAVE = 'TOGGLE_AUTOSAVE',
  SET_IS_AUTOSAVING = 'SET_IS_AUTOSAVING',
  SET_ACTIVITIES_ERROR = 'SET_ACTIVITIES_ERROR',
  DELETE_ACTIVITY_FROM_ERRORS = 'DELETE_ACTIVITY_FROM_ERRORS',
}

type Action =
  | { type: ActionTypes.CREATE_SWIMLANE; payload: SwimlaneV2 }
  | { type: ActionTypes.CREATE_SYMBOL; payload: Symbol }
  | { type: ActionTypes.CREATE_POST_IT; payload: { category: PostItTypes; symbolId: string } }
  | { type: ActionTypes.CREATE_SYMBOL_IN_SWIMLANE; payload: { symbol: Symbol; swimlaneId: string } }
  | { type: ActionTypes.MOVING_SWIMLANE_SYMBOL; payload: { isMoving: boolean; symbolId?: string; swimlaneId?: string } }
  | { type: ActionTypes.DELETE_VIDEO_SUCCESS }
  | { type: ActionTypes.DUPLICATE_SYMBOL_IN_SWIMLANE; payload: { symbol: Symbol; swimlaneId: string } }
  | { type: ActionTypes.CREATE_NOTE; payload: Symbol }
  | { type: ActionTypes.CREATE_TEXTFIELD; payload: TextFieldNEPOS }
  | { type: ActionTypes.DELETE_SWIMLANE; payload: string }
  | { type: ActionTypes.SET_SWIMLANE_ROLE; payload: { swimlaneId: string; role: Role } }
  | { type: ActionTypes.SET_SWIMLANE_SYMBOL_OBJECTS; payload: { symbolId: string; objects: SymbolObject[] } }
  | { type: ActionTypes.DELETE_SWIMLANE_SYMBOL_OBJECT; payload: { symbolId: string; object: SymbolObject } }
  | {
      type: ActionTypes.INIT_GHOST_SWIMLANE;
      payload: { swimlaneId: string; direction: string; initialCoords: ScreenCoordinates; width: number; height: number };
    }
  | { type: ActionTypes.UPDATE_GHOST_SWIMLANE; payload: ScreenCoordinates }
  | { type: ActionTypes.UPDATE_GHOST_SYMBOL; payload: { clickedHandle: ResizeHandles; id: string } | undefined }
  | { type: ActionTypes.UPDATE_SWIMLANES; payload: SwimlaneV2[] }
  | { type: ActionTypes.UPDATE_SIZE_SWIMLANES; payload: { newWidth?: number } }
  | { type: ActionTypes.END_GHOST_SWIMLANE; payload: { swimlaneId?: string } }
  | { type: ActionTypes.FETCH_PROCESS_DATA; payload?: boolean }
  | { type: ActionTypes.FETCH_PROCESS_DATA_ERROR }
  | { type: ActionTypes.FETCH_PROCESS_DATA_SUCCESS; payload: ProcessData }
  | { type: ActionTypes.FETCH_WORKFLOW }
  | { type: ActionTypes.FETCH_WORKFLOW_DATA; payload: WorkflowData }
  | { type: ActionTypes.FETCH_WORKFLOW_ERROR }
  | { type: ActionTypes.FETCH_WORKFLOW_OPTIONS; payload: WorkflowOption[] }
  | { type: ActionTypes.FETCH_WORKFLOW_SUCCESS }
  | { type: ActionTypes.INIT_GHOST_CONNECTOR; payload: Omit<GhostConnector, 'vertices'> & Partial<GhostConnector> }
  | { type: ActionTypes.RESET_GHOST_CONNECTOR }
  | { type: ActionTypes.SAVE_PROCESS_DATA }
  | { type: ActionTypes.SAVE_PROCESS_DATA_ERROR }
  | { type: ActionTypes.SAVE_PROCESS_DATA_SUCCESS; payload: ProcessData }
  | { type: ActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT }
  | { type: ActionTypes.SEND_FOR_RELEASE }
  | { type: ActionTypes.SEND_FOR_RELEASE_ERROR; payload: FormValidationError | null }
  | { type: ActionTypes.SEND_FOR_RELEASE_SUCCESS }
  | { type: ActionTypes.SET_BREADCRUMBS; payload: Levels[] }
  | { type: ActionTypes.SET_CONNECTOR_SELECTION; payload: { sourceId: string; targetId: string } | null }
  | { type: ActionTypes.SET_ERROR; payload: { title: string; message: string } | null }
  | { type: ActionTypes.SET_FONT_SIZE; payload: number }
  | { type: ActionTypes.SET_SELECTION; payload: string[] }
  | { type: ActionTypes.SET_PROCESS_ATTRIBUTES; payload: Attributes }
  | { type: ActionTypes.SET_PROCESS_INTERFACES; payload: ProcessInterface[] }
  | { type: ActionTypes.SET_PROCESS_STEP_WITH_ERRORS; payload: string[] }
  | { type: ActionTypes.SET_TOOL; payload: Tool }
  | { type: ActionTypes.SET_PALETTE_ACTION; payload: PaletteActions }
  | { type: ActionTypes.SET_CURRENT_LEVEL; payload: number }
  | {
      type: ActionTypes.SET_WARNING;
      payload: { buttons?: ButtonProps[]; message: string } | null;
    }
  | { type: ActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND; payload: boolean }
  | { type: ActionTypes.TOGGLE_GRAB_AND_MOVE; payload: boolean }
  | { type: ActionTypes.ADD_MULTIPLE_TOOL; payload: Tool[] }
  | {
      type: ActionTypes.UPDATE_BACKGROUND;
      payload: { background: Background; bounds: Bounds; origin: Origin; padding: Padding };
    }
  | { type: ActionTypes.UPDATE_CONNECTORS; payload: Connector[] }
  | { type: ActionTypes.UPDATE_GHOST_CONNECTOR; payload: Partial<GhostConnector> }
  | { type: ActionTypes.UPDATE_SYMBOLS; payload: Symbol[] }
  | { type: ActionTypes.UPDATE_DIAGRAM_LANGUAGE; payload: Language }
  | { type: ActionTypes.UPDATE_SYMBOLS_AND_CONNECTORS; payload: { symbols: Symbol[]; connectors: Connector[] } }
  | { type: ActionTypes.UPDATE_UNDO_REDO_HISTORY }
  | { type: ActionTypes.UPDATE_UNDO_REDO; payload: boolean }
  | { type: ActionTypes.UPLOAD_VIDEO_SUCESS }
  | { type: ActionTypes.APPLY_UNDO_REDO; payload: number }
  | { type: ActionTypes.UPDATE_PROCESS_INTERFACES; payload: ProcessInterface[] }
  | { type: ActionTypes.UPDATE_PROCESS_CATALOG; payload: ProcessDataCatalog }
  | { type: ActionTypes.UPDATE_PROCESS_IOS; payload: { data: ProcessCatalogObject[]; id?: string; type?: ProcessObject } }
  | { type: ActionTypes.UPDATE_PROCESS_SCS; payload: { data?: ProcessSupplierCustomer; id?: string; type?: ProcessObject } }
  | { type: ActionTypes.UPDATE_PROCESS_DATA }
  | { type: ActionTypes.UPDATE_PROCESS_KPIS; payload: ProcessKPI[] }
  | { type: ActionTypes.UPDATING_SYMBOL_NAME; payload: string }
  | { type: ActionTypes.PREPARE_TAKING_PICTURES; payload: { nextAction: string; isDiagramPreparedForRelease: boolean } }
  | { type: ActionTypes.ZOOM_TO_FIT_BEFORE_PICTURES }
  | { type: ActionTypes.START_TAKING_PICTURES; payload: { initialLanguage: Language; languagesLeft: Language[] } }
  | { type: ActionTypes.ADD_PICTURE; payload: { picture: string; languagesLeft: Language[] } }
  | { type: ActionTypes.SET_TAKING_PICTURES_ON_THE_FLY; payload: boolean }
  | { type: ActionTypes.TAKING_PICTURES_SUCCESS }
  | { type: ActionTypes.TAKING_PICTURES_ERROR; payload: string }
  | { type: ActionTypes.SET_DIAGRAMS_TO_IMPORT; payload: DiagramToImport[] }
  | { type: ActionTypes.SET_DIAGRAMS_TO_LINK; payload: DiagramToLink[] }
  | { type: ActionTypes.SET_DIAGRAMS_TO_MOVE; payload: DiagramToMove[] }
  | { type: ActionTypes.IMPORT_SANDBOX_SUCCESS }
  | { type: ActionTypes.IMPORT_SANDBOX_ERROR }
  | { type: ActionTypes.SET_DIAGRAM_VALIDATION; payload: boolean }
  | { type: ActionTypes.SET_POST_IT_FACTOR; payload: { postItHeightFactor: number; postItWidthFactor: number } }
  | { type: ActionTypes.SET_SYMBOL_BOARDS_DISPLAYED; payload: string[] }
  | { type: ActionTypes.SET_LOADING_FALSE }
  | { type: ActionTypes.SET_LOADING_TRUE }
  | { type: ActionTypes.SET_SHOW_SYMBOL_OBJECTS; payload: string[] }
  | { type: ActionTypes.SET_SWIMLANE_DISPLAYING_ATTRIBUTES; payload: string }
  | { type: ActionTypes.DELETE_SWIMLANE_SYMBOLS; payload: { swimlaneId: string; symbolId: string } }
  | { type: ActionTypes.DELETE_SWIMLANE_SYMBOL_CONNECTORS; payload: string }
  | { type: ActionTypes.MOVE_DIAGRAM_SUCCESS }
  | { type: ActionTypes.MOVE_DIAGRAM_ERROR }
  | { type: ActionTypes.LINK_DIAGRAM_SUCCESS }
  | { type: ActionTypes.LINK_DIAGRAM_ERROR }
  | { type: ActionTypes.TOGGLE_AUTOSAVE; payload: { isAutoSaveOn: boolean; autoSaveInterval: NodeJS.Timeout } }
  | { type: ActionTypes.SET_IS_AUTOSAVING; payload: boolean }
  | { type: ActionTypes.SET_ACTIVITIES_ERROR; payload: number[] }
  | { type: ActionTypes.DELETE_ACTIVITY_FROM_ERRORS; payload: number };

// Action Creators
export const prepareTakingPictures = (nextAction: string, isDiagramPreparedForRelease: boolean) =>
  ({ type: ActionTypes.PREPARE_TAKING_PICTURES, payload: { nextAction, isDiagramPreparedForRelease } } as Action);

export const zoomToFitBeforePictures = () => ({ type: ActionTypes.ZOOM_TO_FIT_BEFORE_PICTURES } as Action);

export const startTakingPictures = (initialLanguage: Language, languagesLeft: Language[]) =>
  ({ type: ActionTypes.START_TAKING_PICTURES, payload: { initialLanguage, languagesLeft } } as Action);

export const addNewPicture = (picture: string, languagesLeft: Language[]) =>
  ({ type: ActionTypes.ADD_PICTURE, payload: { picture, languagesLeft } } as Action);

export const takePicturesSuccess = () => ({ type: ActionTypes.TAKING_PICTURES_SUCCESS } as Action);

export const setIsTakingPicturesOnTheFly = (isTakingPictures: boolean) =>
  ({ type: ActionTypes.SET_TAKING_PICTURES_ON_THE_FLY, payload: isTakingPictures } as Action);

export const setDiagramsToImport = (diagrams: DiagramToImport[]) =>
  ({ type: ActionTypes.SET_DIAGRAMS_TO_IMPORT, payload: diagrams } as Action);

export const setDiagramsToLink = (diagrams: DiagramToLink[]) =>
  ({ type: ActionTypes.SET_DIAGRAMS_TO_LINK, payload: diagrams } as Action);

export const setDiagramsToMove = (diagrams: DiagramToMove[]) =>
  ({ type: ActionTypes.SET_DIAGRAMS_TO_MOVE, payload: diagrams } as Action);

export const setPostitFactor = (heightFactor: number, widthFactor: number) =>
  ({
    type: ActionTypes.SET_POST_IT_FACTOR,
    payload: { postItHeightFactor: heightFactor, postItWidthFactor: widthFactor },
  } as Action);

export const createNewSwimlane = (newSwimlane: Omit<SwimlaneV2, 'meta'> & { meta: { pos: number } }) =>
  ({ type: ActionTypes.CREATE_SWIMLANE, payload: newSwimlane } as Action);

export const createInSwimlane = (symbol: Symbol, swimlaneId: string) =>
  ({
    type: ActionTypes.CREATE_SYMBOL_IN_SWIMLANE,
    payload: { symbol, swimlaneId },
  } as Action);

export const duplicateSymbolInSwimlane = (symbol: Symbol, swimlaneId: string) =>
  ({
    type: ActionTypes.DUPLICATE_SYMBOL_IN_SWIMLANE,
    payload: { symbol, swimlaneId },
  } as Action);
export const deleteSwimlane = (swimlaneId: string) => ({ type: ActionTypes.DELETE_SWIMLANE, payload: swimlaneId } as Action);

export const setSwimlaneRole = (swimlaneId: string, role: Role) =>
  ({ type: ActionTypes.SET_SWIMLANE_ROLE, payload: { swimlaneId, role } } as Action);

export const setSwimlaneSymbolObjects = (symbolId: string, objects: SymbolObject[]) =>
  ({ type: ActionTypes.SET_SWIMLANE_SYMBOL_OBJECTS, payload: { symbolId, objects } } as Action);

export const deleteSwimlaneSymbolObject = (symbolId: string, object: SymbolObject) =>
  ({ type: ActionTypes.DELETE_SWIMLANE_SYMBOL_OBJECT, payload: { symbolId, object } } as Action);

export const initGhostSwimlane = (swimlaneId: string, initialCoords: ScreenCoordinates, direction: string) =>
  ({ type: ActionTypes.INIT_GHOST_SWIMLANE, payload: { swimlaneId, initialCoords, direction, width: 0, height: 0 } } as Action);

export const updateGhostSwimlane = (currentCoords: ScreenCoordinates) =>
  ({ type: ActionTypes.UPDATE_GHOST_SWIMLANE, payload: currentCoords } as Action);

export const updateGhostSymbol = (clickedHandle: ResizeHandles, id: string) =>
  ({ type: ActionTypes.UPDATE_GHOST_SYMBOL, payload: { clickedHandle, id } } as Action);

export const updateSwimlanes = (swimlanes: SwimlaneV2[]) =>
  ({ type: ActionTypes.UPDATE_SWIMLANES, payload: swimlanes } as Action);

export const updateSwimlanesSize = (newWidth: number) =>
  ({ type: ActionTypes.UPDATE_SIZE_SWIMLANES, payload: { newWidth } } as Action);

export const resetGhostSwimlane = (swimlaneId?: string) =>
  ({ type: ActionTypes.END_GHOST_SWIMLANE, payload: { swimlaneId } } as Action);

export const createPostIt = (category: PostItTypes, symbolId: string) =>
  ({
    type: ActionTypes.CREATE_POST_IT,
    payload: { symbolId, category },
  } as Action);

export const setSymbolBoardsDisplayed = (symbolBoardsToDisplay: string[]) =>
  ({ type: ActionTypes.SET_SYMBOL_BOARDS_DISPLAYED, payload: symbolBoardsToDisplay } as Action);

export const setLoadingFalse = () => ({ type: ActionTypes.SET_LOADING_FALSE } as Action);

export const setSymbolObjectsToDisplay = (symbolObjectsToDisplay: string[]) =>
  ({ type: ActionTypes.SET_SHOW_SYMBOL_OBJECTS, payload: symbolObjectsToDisplay } as Action);

export const setSwimlaneDisplayingAttributes = (swimlane?: string) =>
  ({ type: ActionTypes.SET_SWIMLANE_DISPLAYING_ATTRIBUTES, payload: swimlane } as Action);

export const updateSwimlaneSymbols = (swimlaneId: string, symbolId: string) =>
  ({ type: ActionTypes.DELETE_SWIMLANE_SYMBOLS, payload: { swimlaneId, symbolId } } as Action);

export const deleteSwimlaneSymbolConnectors = (symbolId: string) =>
  ({ type: ActionTypes.DELETE_SWIMLANE_SYMBOL_CONNECTORS, payload: symbolId } as Action);

export const movingSwimlaneSymbols = (isMoving: boolean = false, symbolId: string = '', swimlaneId: string = '') =>
  ({ type: ActionTypes.MOVING_SWIMLANE_SYMBOL, payload: { isMoving, symbolId, swimlaneId } } as Action);

export const toggleAutoSave = (isAutoSaveOn: boolean, autoSaveInterval?: NodeJS.Timeout) =>
  ({ type: ActionTypes.TOGGLE_AUTOSAVE, payload: { isAutoSaveOn, autoSaveInterval } } as Action);

export const setIsAutoSaving = (isAutoSaving: boolean) =>
  ({ type: ActionTypes.SET_IS_AUTOSAVING, payload: isAutoSaving } as Action);

export const setBreadcrumbs = (breadcrumbs: Levels[]) => ({ type: ActionTypes.SET_BREADCRUMBS, payload: breadcrumbs } as Action);

export const setActivitiesError = (activities: number[]) =>
  ({ type: ActionTypes.SET_ACTIVITIES_ERROR, payload: activities } as Action);

export const deleteActivityFromErrors = (activityId: number) =>
  ({ type: ActionTypes.DELETE_ACTIVITY_FROM_ERRORS, payload: activityId } as Action);

export const setCurrentLevel = (level: number) => ({ type: ActionTypes.SET_CURRENT_LEVEL, payload: level } as Action);

type State = {
  background?: Background;
  breadcrumbs: Levels[];
  bounds?: Bounds;
  diagramLanguage: Language;
  editableSymbol: string;
  error?: { title: string; message: string } | null;
  warning?: { buttons?: ButtonProps[]; message: string } | null;
  fontSize?: number;
  previousFontSize?: number;
  ghostConnector?: GhostConnector | null;
  ghostSwimlane?: {
    swimlaneId: string;
    initialCoords: ScreenCoordinates;
    direction: string;
    width: number;
    height: number;
  } | null;
  ghostSymbol?: Number | null;
  grabAndMove: boolean;
  isLoading: boolean;
  isSaveLoading: boolean;
  isSaveSuccess: boolean;
  isTakingPictures: {
    preparation: boolean;
    zoomToFit: boolean;
    pictureAction: string | null;
    onTheFly: boolean;
    all: boolean;
    success: boolean;
  };
  pictures: { [key in Language]?: string[] };
  pictureInitialLanguage: Language;
  pictureLanguagesLeft: Language[];
  textFields?: TextFieldNEPOS[] | null;
  origin?: Origin;
  padding?: Padding;
  paletteAction: PaletteActions;
  isAttributesPanelOpen: boolean;
  processData?: ProcessData;
  processStepWithErrors: string[];
  selectedSymbolIds: string[];
  selectedConnectorId: { sourceId: string; targetId: string } | null;
  sendForReleaseError: FormValidationError | null;
  shouldUpdateBackground: boolean;
  shouldRealignBackground: boolean;
  tool: Tool;
  undoRedoHistory: UndoRedoHistory;
  updateUndoRedo: boolean;
  workflowOptions?: WorkflowOption[];
  workflowData?: WorkflowData;
  diagramsToImport: DiagramToImport[];
  diagramsToLink: DiagramToLink[];
  diagramsToMove: DiagramToMove[];
  isValidationActive: boolean;
  isDiagramPreparedForRelease: boolean;
  factors: { postItHeightFactor: number; postItWidthFactor: number };
  displayedSymbolBoards: string[];
  symbolObjectsDisplayed: string[];
  swimlaneDisplayingAttributes: string;
  isMovingSwimlaneSymbol: { isMoving: boolean; symbolId?: string; swimlaneId?: string };
  selectedTools: Tool[];
  isAutoSaveOn: boolean;
  autoSaveInterval?: NodeJS.Timeout;
  isAutoSaving: boolean;
  activitiesError: number[];
  currentLevel: number;
};

const initialState: State = {
  activitiesError: [],
  breadcrumbs: [],
  editableSymbol: '',
  isLoading: false,
  isSaveLoading: false,
  isSaveSuccess: false,
  isTakingPictures: {
    preparation: false,
    zoomToFit: false,
    pictureAction: null,
    onTheFly: false,
    all: false,
    success: false,
  },
  pictures: {},
  pictureInitialLanguage: getLocalStorageAppLanguage(),
  pictureLanguagesLeft: [],
  processStepWithErrors: [],
  selectedSymbolIds: [],
  selectedConnectorId: null,
  sendForReleaseError: null,
  shouldUpdateBackground: true,
  shouldRealignBackground: false,
  undoRedoHistory: { step: 0, stack: [] },
  updateUndoRedo: false,
  grabAndMove: false,
  tool: Tool.NONE,
  paletteAction: PaletteActions.NONE,
  isAttributesPanelOpen: false,
  diagramLanguage: getLocalStorageAppLanguage(),
  diagramsToImport: [],
  diagramsToLink: [],
  diagramsToMove: [],
  isValidationActive: false,
  isDiagramPreparedForRelease: false,
  factors: { postItHeightFactor: 5, postItWidthFactor: 5 },
  displayedSymbolBoards: [],
  symbolObjectsDisplayed: [],
  swimlaneDisplayingAttributes: '',
  isMovingSwimlaneSymbol: {
    isMoving: false,
  },
  selectedTools: [],
  isAutoSaveOn: false,
  isAutoSaving: false,
  currentLevel: 1,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CREATE_SWIMLANE: {
      if (!state.processData?.swimlanes) return state;

      const initialSwimlanes = cloneObject(state.processData.swimlanes);
      const newSwimlane = action.payload;
      newSwimlane.meta.width = initialSwimlanes[0].meta.width;
      if (newSwimlane.meta.pos === initialSwimlanes.length) {
        initialSwimlanes.push(newSwimlane);
      } else {
        initialSwimlanes.splice(newSwimlane.meta.pos, 0, newSwimlane);
        initialSwimlanes.forEach((swimlane: SwimlaneV2, index: number) => {
          swimlane.meta.pos = index;
        });
      }

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: initialSwimlanes,
        },
        updateUndoRedo: true,
        shouldUpdateBackground: true,
      };
    }
    case ActionTypes.CREATE_SYMBOL:
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...state.processData,
          symbols: [...state.processData.symbols, action.payload],
        },
        updateUndoRedo: true,
      };
    case ActionTypes.CREATE_POST_IT: {
      if (!state.processData) return state;

      const selectedSymbol = state.processData?.symbols?.find((symbol) => symbol.id === action.payload.symbolId);

      if (!selectedSymbol) return state;
      const clonedSymbols = cloneObject(state.processData.symbols);

      const newSymbols = clonedSymbols.map((symbol: Symbol) => {
        if (symbol.id === selectedSymbol.id) {
          if (!symbol.postIts)
            symbol.postIts = {
              INPUT: [],
              OUTPUT: [],
              E2E_PROCESS_OWNER: [{ text: '', order: 0, id: '0' }],
              IT_SYSTEM: [],
              PAINPOINTS: [],
              KPI: [],
              POTENTIAL_ASSESSMENTS: [],
            };
          const newPostIt =
            action.payload.category === PostItTypes.INPUT || action.payload.category === PostItTypes.OUTPUT
              ? {
                  text: '',
                  linkage: '',
                  order: symbol.postIts[action.payload.category]?.length,
                  id: uuidv4(),
                }
              : {
                  text: '',
                  order: symbol.postIts[action.payload.category]?.length,
                  id: uuidv4(),
                };
          symbol.postIts[action.payload.category as PostItTypes]?.push(newPostIt as PostIt);
        }
        return symbol;
      });

      return {
        ...state,
        processData: {
          ...state.processData,
          symbols: newSymbols,
        },
        updateUndoRedo: true,
      };
    }
    case ActionTypes.CREATE_SYMBOL_IN_SWIMLANE: {
      if (!state.processData?.swimlanes) return state;
      const originalSwimlanes = cloneObject(state.processData.swimlanes);
      const swimlane: SwimlaneV2 = originalSwimlanes.find((s: SwimlaneV2) => s.id === action.payload.swimlaneId);

      if (!swimlane) return state;

      const originalSymbol: Symbol = cloneObject(action.payload.symbol);
      const newSymbol: Symbol = action.payload.symbol;

      if (swimlane.role?.id && originalSymbol.type === SymbolTypes.PROCESS_STEP) {
        originalSymbol.objects = [
          {
            id: swimlane.role?.id,
            type: ObjectTypes.ROLE_SWIMLANE,
            attributes: swimlane.role?.attributes,
            meta: { pos: 0 },
            approved: swimlane.role?.approved,
            subtype: undefined,
            responsability: 1,
          },
        ];
        const resultObjects = originalSymbol.objects.concat(newSymbol.objects || []);
        originalSymbol.objects = resultObjects;
      }

      swimlane.symbols.push(originalSymbol);
      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: originalSwimlanes,
        },
        updateUndoRedo: true,
      };
    }
    case ActionTypes.MOVING_SWIMLANE_SYMBOL: {
      return {
        ...state,
        isMovingSwimlaneSymbol: {
          isMoving: action.payload.isMoving,
          symbolId: action.payload.symbolId,
          swimlaneId: action.payload.swimlaneId,
        },
      };
    }
    case ActionTypes.DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          video: false,
        },
      };
    }
    case ActionTypes.DUPLICATE_SYMBOL_IN_SWIMLANE: {
      if (!state.processData?.swimlanes) return state;
      const originalSwimlanesObject = cloneObject(state.processData.swimlanes);
      const currentSwimlane = originalSwimlanesObject.find((s: SwimlaneV2) => s.id === action.payload.swimlaneId);
      if (!currentSwimlane) return state;
      const rowSymbols = currentSwimlane?.symbols.filter(
        (sym: { meta: { top: number } }) => sym.meta.top === action.payload.symbol.meta.top,
      );
      const mappedGaps = rowSymbols.map((sym: { meta: { left: number } }) => sym.meta.left);

      if (!mappedGaps || !currentSwimlane) return state;

      const maxGap = Math.max(...mappedGaps);

      if (maxGap === currentSwimlane.meta.width - 1) {
        currentSwimlane.meta.width += 1;
        originalSwimlanesObject.forEach((elem: { meta: { width: number } }) => {
          elem.meta.width = currentSwimlane.meta.width;
        });
      }

      const left = maxGap + 1;

      const newSymbol = {
        ...action.payload.symbol,
        id: `SWIMLANE_${action.payload.symbol.type}${uuidv4()}`,
        attributes: action.payload.symbol.attributes,
        objects: [],
        meta: { ...action.payload.symbol.meta, left },
      };

      currentSwimlane.symbols.push(newSymbol);
      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: originalSwimlanesObject,
        },
        updateUndoRedo: true,
      };
    }
    case ActionTypes.CREATE_TEXTFIELD:
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...state.processData,
          symbols: [...state.processData.symbols, action.payload],
        },
        updateUndoRedo: true,
      };
    case ActionTypes.DELETE_SWIMLANE: {
      if (!state.processData) return state;
      const initSwimlanes = cloneObject(state.processData.swimlanes);
      const deletedSwimlane = initSwimlanes?.filter((elSwimlane: SwimlaneV2) => elSwimlane.id === action.payload);
      const symbolIds = deletedSwimlane[0].symbols.map((symbol: { id: string }) => symbol.id);
      const newConnectors = state.processData.connectors.filter(
        (elem) => !symbolIds.includes(elem.source.id) && !symbolIds.includes(elem.target.id),
      );

      const updatedSwimlanes = initSwimlanes?.filter((elSwimlane: SwimlaneV2) => elSwimlane.id !== action.payload);
      updatedSwimlanes.forEach((updatedSwimlane: SwimlaneV2, index: number) => {
        updatedSwimlane.meta.pos = index;
      });

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: updatedSwimlanes,
          connectors: newConnectors,
        },
        updateUndoRedo: true,
        shouldUpdateBackground: true,
      };
    }
    case ActionTypes.SET_SWIMLANE_ROLE: {
      if (!state.processData) return state;

      const clonedSwimlanes = cloneObject(state.processData?.swimlanes);
      const selectedSwimlane = clonedSwimlanes.find((s: SwimlaneV2) => s.id === action.payload.swimlaneId);
      selectedSwimlane.role = action.payload.role;

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: clonedSwimlanes,
        },
      };
    }
    case ActionTypes.SET_SWIMLANE_SYMBOL_OBJECTS: {
      if (!state.processData) return state;

      const swimlanesClone = cloneObject(state.processData?.swimlanes);
      const allSymbols = swimlanesClone.reduce((prev: Symbol[], next: SwimlaneV2) => prev.concat(next.symbols), []);
      const selectedProcessStep = allSymbols.find((symbol: Symbol) => symbol.id === action.payload.symbolId);
      selectedProcessStep.objects = action.payload.objects;

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: swimlanesClone,
        },
      };
    }
    case ActionTypes.DELETE_SWIMLANE_SYMBOL_OBJECT: {
      if (!state.processData) return state;
      const { id: objectId, type, subtype, temporaryText } = action.payload.object;

      const clonedSwimlanes = cloneObject(state.processData?.swimlanes);
      const reducedSymbols = clonedSwimlanes.reduce((prev: Symbol[], next: SwimlaneV2) => prev.concat(next.symbols), []);
      const processStepToDeleteObject = reducedSymbols.find((symbol: Symbol) => symbol.id === action.payload.symbolId);

      const processStepObjectsFilter = (obj: SymbolObject) =>
        objectId ? obj.id !== objectId || obj.type !== type || obj.subtype !== subtype : obj.temporaryText !== temporaryText;

      processStepToDeleteObject.objects = processStepToDeleteObject.objects.filter(processStepObjectsFilter);

      processStepToDeleteObject.objects
        .sort((a: SymbolObject, b: SymbolObject) => {
          if (a.meta.pos > b.meta.pos) return 1;
          if (a.meta.pos < b.meta.pos) return -1;
          return 0;
        })
        .forEach((value: SymbolObject, index: number) => {
          value.meta.pos = index;
        });

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes: clonedSwimlanes,
        },
      };
    }
    case ActionTypes.INIT_GHOST_SWIMLANE:
      return {
        ...state,
        ghostSwimlane: action.payload,
      };
    case ActionTypes.UPDATE_GHOST_SWIMLANE: {
      if (!state.ghostSwimlane || !state.fontSize) return state;

      const ghostSwimlane = cloneObject(state.ghostSwimlane);

      const difference =
        ghostSwimlane?.direction === Orientation.HORIZONTAL
          ? action.payload.x - ghostSwimlane.initialCoords.x
          : action.payload.y - ghostSwimlane.initialCoords.y;
      let width = difference / state.fontSize / SYMBOL_WIDTH;
      let height = difference / state.fontSize / SYMBOL_HEIGHT;
      if (width < 0.15 && width > -0.15) width = 0;

      width = width < 0 ? Math.floor(width) : Math.ceil(width);
      height = height < 0 ? Math.round(height) : Math.ceil(height);
      if (state.processData!.swimlanes[0]?.meta.width + width > 0 && width !== ghostSwimlane.width) {
        ghostSwimlane.width = ghostSwimlane?.direction === Orientation.HORIZONTAL ? width : 0;
        ghostSwimlane.height = ghostSwimlane?.direction === Orientation.VERTICAL ? height : 0;
        return {
          ...state,
          ghostSwimlane,
        };
      }

      return state;
    }
    case ActionTypes.UPDATE_GHOST_SYMBOL: {
      if (state?.processData && state.processData.symbols?.length < 2) return state;

      const selectedSymbol = state.processData?.symbols?.find((symbol) => symbol.id === action?.payload?.id);

      const selectedBorder = selectedSymbol ? selectedSymbol.meta?.left + selectedSymbol?.meta?.width : undefined;

      const borderClick =
        action?.payload?.clickedHandle === ResizeHandles.CENTER_RIGHT ? selectedBorder : selectedSymbol?.meta?.left;

      const possibleSymbols = state.processData?.symbols?.filter((symbol) => symbol.id !== action?.payload?.id);

      const possibleBorders = possibleSymbols?.reduce(
        (acc, symbol) => [...acc, symbol.meta.left, symbol.meta.left + symbol.meta.width],
        [] as number[],
      );

      const closest =
        possibleBorders &&
        possibleBorders?.reduce((prev, curr) => {
          return Math.abs(curr - borderClick!!) < Math.abs(prev - borderClick!!) ? curr : prev;
        });

      return {
        ...state,
        ghostSymbol: closest,
      };
    }
    case ActionTypes.END_GHOST_SWIMLANE: {
      if (!state.processData || !state.ghostSwimlane) return state;

      const swimlanes = cloneObject(state.processData.swimlanes);
      const swimlane = swimlanes.find((s: SwimlaneV2) => s.id === action.payload.swimlaneId); // eslint-disable-line @typescript-eslint/no-redeclare
      const newWidth = state.processData.swimlanes[0].meta.width + state.ghostSwimlane.width;
      const newHeight = swimlane.meta.height + state.ghostSwimlane.height;

      const minSwimlanesWidth =
        1 + // Add 1 because positions are 0-indexed
        state.processData.swimlanes.reduce((sum, element: SwimlaneV2) => {
          // Get the last symbol's position in the swimlane
          const lastPosition = element.symbols.reduce((acc, symbol) => Math.max(acc, symbol.meta.left), 0);
          return Math.max(sum, lastPosition);
        }, 0);

      const minSwimlaneHeight =
        1 + swimlane.symbols.reduce((acc: number, symbol: { meta: { top: number } }) => Math.max(acc, symbol.meta.top), 0);

      if (
        (newWidth < minSwimlanesWidth && state.ghostSwimlane.direction === Orientation.HORIZONTAL) ||
        (newHeight < minSwimlaneHeight && state.ghostSwimlane.direction === Orientation.VERTICAL)
      ) {
        return {
          ...state,
          ghostSwimlane: null,
          error: { title: 'error', message: 'swimlanes.symbols.hidden' },
        };
      }

      if (newHeight > SWIMLANE_MAX_HEIGHT) {
        return {
          ...state,
          ghostSwimlane: null,
          error: { title: 'error', message: 'swimlane.maximumHeightReached' },
        };
      }

      if (newWidth > SWIMLANE_MAX_WIDTH) {
        return {
          ...state,
          ghostSwimlane: null,
          error: { title: 'error', message: 'swimlane.maximumWidthReached' },
        };
      }

      if (state.ghostSwimlane.direction === Orientation.HORIZONTAL) {
        swimlanes.forEach((element: SwimlaneV2) => {
          element.meta.width = newWidth;
          element.symbols
            .filter((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.LAST_EVENT === 'true')
            .forEach((symbol) => {
              symbol.meta.left = newWidth - 1;
            });
        });
      } else {
        swimlane.meta.height = newHeight;
      }
      return {
        ...state,
        ghostSwimlane: null,
        processData: {
          ...state.processData,
          swimlanes,
        },
      };
    }
    case ActionTypes.UPDATE_SWIMLANES: {
      if (!state.processData?.swimlanes) return state;

      let { customer, objects } = state.processData;

      const lastEvents =
        action.payload
          ?.sort((a, b) => a.meta.pos - b.meta.pos)
          .flatMap((swiml) => swiml.symbols.map((s) => ({ ...s, swimlaneId: swiml.id })))
          .filter((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.LAST_EVENT === 'true')
          .sort((a, b) => (a.swimlaneId === b.swimlaneId ? a.meta.top - b.meta.top : 0))
          .map((s) => ({ ...state.processData?.lastEvents?.find((event) => event.id === s.id), id: s.id })) || [];

      if (!state.processData.lastEvents?.length && lastEvents.length === 1) {
        const outputs = objects.filter((obj) => obj.subtype === ProcessObject.OUTPUT);
        lastEvents[0] = { ...lastEvents[0], outputs, customer };
        objects = objects.filter((obj) => obj.subtype !== ProcessObject.OUTPUT);
        customer = undefined;
      } else if (state.processData.lastEvents?.length === 1 && !lastEvents.length) {
        objects = [...objects, ...(state.processData.lastEvents[0].outputs || [])];
        customer = state.processData.lastEvents[0].customer; // eslint-disable-line prefer-destructuring
      }

      const startEvents =
        action.payload
          ?.sort((a, b) => a.meta.pos - b.meta.pos)
          .flatMap((swiml) => swiml.symbols.map((s) => ({ ...s, swimlaneId: swiml.id })))
          .filter((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.START_EVENT === 'true')
          .sort((a, b) => (a.swimlaneId === b.swimlaneId ? a.meta.top - b.meta.top : 0))
          .map((s) => ({ ...state.processData?.startEvents?.find((event) => event.id === s.id), id: s.id })) || [];

      return {
        ...state,
        processData: {
          ...state.processData,
          lastEvents,
          startEvents,
          swimlanes: action.payload,
          objects,
          customer,
        },
      };
    }
    case ActionTypes.UPDATE_SIZE_SWIMLANES: {
      const { newWidth } = action.payload;
      if (!state.processData || !newWidth) return state;

      const swimlanes = cloneObject(state.processData.swimlanes);

      if (newWidth > SWIMLANE_MAX_WIDTH) {
        return {
          ...state,
          error: { title: 'error', message: 'swimlane.maximumWidthReached' },
        };
      }

      swimlanes.forEach((element: SwimlaneV2) => {
        element.meta.width = newWidth;
      });

      return {
        ...state,
        processData: {
          ...state.processData,
          swimlanes,
        },
      };
    }
    case ActionTypes.FETCH_PROCESS_DATA:
      return {
        ...state,
        isLoading: true,
        bounds: undefined,
        origin: undefined,
        previousFontSize: 0,
        background: undefined,
        fontSize: action.payload ? state.fontSize : 4, // Reset the diagram zoom
        undoRedoHistory: { step: 0, stack: [] }, // Reset the undo-redo history
      };
    case ActionTypes.FETCH_PROCESS_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_PROCESS_DATA_SUCCESS:
      return {
        ...state,
        processData: action.payload,
        // Reset the undo-redo history
        undoRedoHistory: {
          step: 0,
          stack: [
            {
              symbols: action.payload.symbols,
              connectors: action.payload.connectors,
              swimlanes: action.payload.swimlanes,
            },
          ],
        },
      };
    case ActionTypes.FETCH_WORKFLOW_OPTIONS:
      return {
        ...state,
        workflowOptions: action.payload,
      };
    case ActionTypes.FETCH_WORKFLOW_DATA:
      return {
        ...state,
        workflowData: action.payload,
        isLoading: true,
      };
    case ActionTypes.INIT_GHOST_CONNECTOR:
      return {
        ...state,
        ghostConnector: {
          source: action.payload.source,
          target: action.payload.target,
          vertices: action.payload.vertices || [],
        },
      };
    case ActionTypes.RESET_GHOST_CONNECTOR:
      return {
        ...state,
        ghostConnector: null,
      };
    case ActionTypes.SAVE_PROCESS_DATA:
      return {
        ...state,
        isSaveLoading: true,
      };
    case ActionTypes.SAVE_PROCESS_DATA_ERROR:
      return {
        ...state,
        isSaveLoading: false,
        isSaveSuccess: false,
      };
    case ActionTypes.SAVE_PROCESS_DATA_SUCCESS:
      return {
        ...state,
        isSaveLoading: false,
        processData: action.payload,
        isSaveSuccess: true,
      };
    case ActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT:
      return {
        ...state,
        isLoading: false,
        isSaveSuccess: false,
      };
    case ActionTypes.FETCH_WORKFLOW:
      return {
        ...state,
        isLoading: true,
        isTakingPictures: {
          ...state.isTakingPictures,
          success: false,
        },
        isDiagramPreparedForRelease: true,
      };
    case ActionTypes.FETCH_WORKFLOW_ERROR:
      return {
        ...state,
        isLoading: false,
        isDiagramPreparedForRelease: false,
        isTakingPictures: {
          ...state.isTakingPictures,
          zoomToFit: false,
        },
      };
    case ActionTypes.FETCH_WORKFLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        undoRedoHistory: { step: 0, stack: [] },
        isTakingPictures: {
          ...state.isTakingPictures,
          zoomToFit: false,
        },
        isDiagramPreparedForRelease: false,
      };
    case ActionTypes.SEND_FOR_RELEASE_ERROR:
      return {
        ...state,
        sendForReleaseError: action.payload,
        isTakingPictures: {
          ...state.isTakingPictures,
          zoomToFit: false,
        },
        isDiagramPreparedForRelease: false,
      };
    case ActionTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case ActionTypes.SET_CONNECTOR_SELECTION:
      return {
        ...state,
        selectedConnectorId: action.payload,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        isDiagramPreparedForRelease: false,
      };
    case ActionTypes.SET_FONT_SIZE:
      return {
        ...state,
        shouldUpdateBackground: true,
        shouldRealignBackground: true,
        fontSize: action.payload,
        previousFontSize: state.fontSize,
      };
    case ActionTypes.SET_PROCESS_ATTRIBUTES:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          attributes: action.payload,
        },
      };
    case ActionTypes.SET_PROCESS_INTERFACES:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          interfaces: action.payload,
        },
      };
    case ActionTypes.SET_PROCESS_STEP_WITH_ERRORS:
      return {
        ...state,
        processStepWithErrors: action.payload,
      };
    case ActionTypes.SET_SELECTION:
      return {
        ...state,
        selectedSymbolIds: action.payload,
      };
    case ActionTypes.SET_TOOL:
      return {
        ...state,
        tool: action.payload,
      };
    case ActionTypes.SET_PALETTE_ACTION:
      return {
        ...state,
        paletteAction: action.payload,
      };
    case ActionTypes.SET_WARNING:
      return {
        ...state,
        warning: action.payload,
      };
    case ActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND:
      return {
        ...state,
        shouldUpdateBackground: action.payload,
      };
    case ActionTypes.TOGGLE_GRAB_AND_MOVE:
      return {
        ...state,
        grabAndMove: action.payload,
      };
    case ActionTypes.ADD_MULTIPLE_TOOL: {
      return {
        ...state,
        selectedTools: action.payload,
      };
    }
    case ActionTypes.UPDATE_BACKGROUND:
      return {
        ...state,
        shouldUpdateBackground: false,
        shouldRealignBackground: false,
        ...action.payload,
      };
    case ActionTypes.UPDATE_CONNECTORS:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          connectors: action.payload,
        },
      };
    case ActionTypes.UPDATE_GHOST_CONNECTOR:
      return !state.ghostConnector
        ? state
        : {
            ...state,
            ghostConnector: {
              ...state.ghostConnector,
              ...action.payload,
            },
          };
    case ActionTypes.UPDATE_SYMBOLS:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          symbols: action.payload,
        },
      };
    case ActionTypes.UPDATE_SYMBOLS_AND_CONNECTORS:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          symbols: action.payload.symbols,
          connectors: action.payload.connectors,
        },
      };
    case ActionTypes.UPDATE_PROCESS_INTERFACES:
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          interfaces: action.payload,
        },
      };
    case ActionTypes.UPDATE_PROCESS_CATALOG:
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          catalog: action.payload,
        },
      };
    case ActionTypes.UPDATE_PROCESS_IOS:
      if (!state.processData) return state;
      if (action.payload.id) {
        if (action.payload.type === ProcessObject.INPUT) {
          const startEvents = (state.processData as ProcessData).startEvents?.map((event) =>
            event.id === action.payload.id ? { ...event, inputs: action.payload.data } : event,
          );
          return {
            ...state,
            processData: { ...(state.processData as ProcessData), startEvents: startEvents as Symbol[] },
          };
        }
        if (action.payload.type === ProcessObject.OUTPUT) {
          const lastEvents = (state.processData as ProcessData).lastEvents?.map((event) =>
            event.id === action.payload.id ? { ...event, outputs: action.payload.data } : event,
          );
          return {
            ...state,
            processData: { ...(state.processData as ProcessData), lastEvents: lastEvents as Symbol[] },
          };
        }
      }
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          objects: action.payload.data,
        },
      };
    case ActionTypes.UPDATE_PROCESS_SCS: {
      if (!state.processData) return state;
      if (action.payload.id) {
        if (action.payload.type === ProcessObject.SUPPLIER) {
          const startEvents = state.processData.startEvents?.map((event) =>
            event.id === action.payload.id ? { ...event, supplier: action.payload.data } : event,
          );
          return {
            ...state,
            processData: { ...state.processData, startEvents: startEvents as Symbol[] },
          };
        }
        if (action.payload.type === ProcessObject.CUSTOMER) {
          const lastEvents = (state.processData as ProcessData).lastEvents?.map((event) =>
            event.id === action.payload.id ? { ...event, customer: action.payload.data } : event,
          );
          return {
            ...state,
            processData: { ...(state.processData as ProcessData), lastEvents: lastEvents as Symbol[] },
          };
        }
      }
      if (!action.payload.data) return state;
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          [action.payload.data?.type.toLowerCase()]: action.payload.data,
        },
      };
    }
    case ActionTypes.UPDATE_PROCESS_DATA: {
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
        },
      };
    }
    case ActionTypes.UPDATE_PROCESS_KPIS:
      if (!state.processData) return state;
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          kpis: action.payload,
        },
      };
    case ActionTypes.UPDATE_DIAGRAM_LANGUAGE:
      return {
        ...state,
        diagramLanguage: action.payload,
      };
    case ActionTypes.UPDATE_UNDO_REDO:
      return {
        ...state,
        updateUndoRedo: action.payload,
      };
    case ActionTypes.UPLOAD_VIDEO_SUCESS:
      return {
        ...state,
        processData: {
          ...(state.processData as ProcessData),
          video: true,
        },
      };
    case ActionTypes.UPDATE_UNDO_REDO_HISTORY: {
      const stepNumber = Math.min(state.undoRedoHistory.step + 1, MAX_UNDO);

      return {
        ...state,
        updateUndoRedo: false,
        undoRedoHistory: {
          step: stepNumber,
          stack: [
            ...(stepNumber === MAX_UNDO
              ? state.undoRedoHistory.stack.slice(-MAX_UNDO)
              : state.undoRedoHistory.stack.slice(0, stepNumber)),
            {
              symbols: state.processData?.symbols || [],
              connectors: state.processData?.connectors || [],
              swimlanes: state.processData?.swimlanes || [],
            },
          ],
        },
      };
    }
    case ActionTypes.UPDATING_SYMBOL_NAME:
      return {
        ...state,
        editableSymbol: action.payload,
      };
    case ActionTypes.APPLY_UNDO_REDO: {
      const newStep = action.payload;

      return {
        ...state,
        updateUndoRedo: false,
        undoRedoHistory: {
          ...state.undoRedoHistory,
          step: newStep,
        },
        processData: {
          ...(state.processData as ProcessData),
          symbols: state.undoRedoHistory.stack[newStep].symbols,
          connectors: state.undoRedoHistory.stack[newStep].connectors,
          swimlanes: state.undoRedoHistory.stack[newStep].swimlanes,
        },
      };
    }
    case ActionTypes.PREPARE_TAKING_PICTURES:
      return {
        ...state,
        isTakingPictures: {
          ...state.isTakingPictures,
          preparation: true,
          pictureAction: action.payload.nextAction,
        },
      };
    case ActionTypes.ZOOM_TO_FIT_BEFORE_PICTURES:
      return {
        ...state,
        isTakingPictures: {
          ...state.isTakingPictures,
          preparation: false,
          zoomToFit: true,
        },
      };
    case ActionTypes.START_TAKING_PICTURES:
      return {
        ...state,
        isTakingPictures: {
          ...state.isTakingPictures,
          all: true,
          success: false,
          zoomToFit: false,
          pictureAction: null,
        },
        selectedSymbolIds: [],
        selectedConnectorId: null,
        pictures: {},
        pictureInitialLanguage: action.payload.initialLanguage,
        pictureLanguagesLeft: action.payload.languagesLeft,
        diagramLanguage: action.payload.languagesLeft[0] || state.diagramLanguage,
      };
    case ActionTypes.ADD_PICTURE:
      return {
        ...state,
        pictures: {
          ...state.pictures,
          [state.diagramLanguage as string]: [action.payload.picture],
        },
        pictureLanguagesLeft: action.payload.languagesLeft,
        diagramLanguage: action.payload.languagesLeft[0] || state.pictureInitialLanguage,
      };
    case ActionTypes.SET_TAKING_PICTURES_ON_THE_FLY:
      return {
        ...state,
        selectedSymbolIds: [],
        selectedConnectorId: null,
        isTakingPictures: {
          ...state.isTakingPictures,
          onTheFly: action.payload,
          zoomToFit: false,
          pictureAction: null,
        },
      };
    case ActionTypes.TAKING_PICTURES_SUCCESS:
      return {
        ...state,
        isTakingPictures: {
          ...state.isTakingPictures,
          all: false,
          success: true,
        },
        pictureLanguagesLeft: [],
        diagramLanguage: state.pictureInitialLanguage,
      };
    case ActionTypes.SET_DIAGRAMS_TO_IMPORT:
      return {
        ...state,
        diagramsToImport: action.payload,
      };
    case ActionTypes.SET_DIAGRAMS_TO_LINK:
      return {
        ...state,
        diagramsToLink: action.payload,
      };
    case ActionTypes.IMPORT_SANDBOX_SUCCESS:
    case ActionTypes.IMPORT_SANDBOX_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SET_DIAGRAMS_TO_MOVE:
      return {
        ...state,
        diagramsToMove: action.payload,
      };
    case ActionTypes.SET_DIAGRAM_VALIDATION:
      return {
        ...state,
        isValidationActive: action.payload,
      };
    case ActionTypes.SET_POST_IT_FACTOR:
      return {
        ...state,
        factors: { postItHeightFactor: action.payload.postItHeightFactor, postItWidthFactor: action.payload.postItWidthFactor },
      };
    case ActionTypes.SET_SYMBOL_BOARDS_DISPLAYED:
      return {
        ...state,
        displayedSymbolBoards: action.payload,
        shouldUpdateBackground: false,
        isLoading: true,
      };
    case ActionTypes.SET_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SET_LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SET_SHOW_SYMBOL_OBJECTS:
      return {
        ...state,
        symbolObjectsDisplayed: action.payload,
      };
    case ActionTypes.SET_SWIMLANE_DISPLAYING_ATTRIBUTES:
      return {
        ...state,
        swimlaneDisplayingAttributes: action.payload,
      };
    case ActionTypes.DELETE_SWIMLANE_SYMBOLS: {
      if (!state.processData) return state;

      const swimlanesObjectClone = cloneObject(state.processData?.swimlanes);
      let swimlanesStartEventsClone = cloneObject(state.processData?.startEvents);
      let swimlanesLastEventsClone = cloneObject(state.processData?.lastEvents);
      let { objects, customer } = state.processData;
      const swimlaneSelected = swimlanesObjectClone.find((s: SwimlaneV2) => s.id === action.payload.swimlaneId);
      const deletedSymbol = swimlaneSelected.symbols.find((symbol: Symbol) => symbol.id === action.payload.symbolId);

      if (deletedSymbol.type === SymbolTypes.PROCESS_STEP) {
        const swimlaneProcessSteps = swimlanesObjectClone
          .map((s: SwimlaneV2) => s.symbols)
          .flat()
          .filter(
            (symbol: any) =>
              symbol.type === SymbolTypes.PROCESS_STEP &&
              symbol.attributes[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER >
                deletedSymbol.attributes[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER,
          )
          .sort(
            (a: any, b: any) =>
              a.attributes[NOT_TRANSLATABLE][AttributeCode.PROCESS_STEP_NUMBER] -
              b.attributes[NOT_TRANSLATABLE][AttributeCode.PROCESS_STEP_NUMBER],
          );

        swimlaneProcessSteps.forEach((processStep: Symbol, index: number) => {
          [...swimlanesObjectClone.map((s: SwimlaneV2) => s.symbols).flat()].find(
            (symbol: Symbol) => symbol.id === processStep.id,
          ).attributes[NOT_TRANSLATABLE].PROCESS_STEP_NUMBER = (
            parseInt(deletedSymbol.attributes[NOT_TRANSLATABLE].PROCESS_STEP_NUMBER, 10) + index
          ).toString();
        });
      }
      const updatedSymbols = swimlaneSelected.symbols?.filter((symbol: { id: string }) => symbol.id !== action.payload.symbolId);
      swimlaneSelected.symbols = updatedSymbols;

      if (deletedSymbol.type === SymbolTypes.EVENT) {
        swimlanesStartEventsClone = swimlanesStartEventsClone.filter((startEvent) => startEvent.id !== deletedSymbol.id);
        swimlanesLastEventsClone = swimlanesLastEventsClone.filter((lastEvent) => lastEvent.id !== deletedSymbol.id);
        if (swimlanesLastEventsClone.length === 0 && state.processData?.lastEvents?.length === 1) {
          customer = state.processData.lastEvents[0].customer; // eslint-disable-line prefer-destructuring
          objects = [...objects, ...(state.processData.lastEvents[0].outputs || [])];
        }
      }

      return {
        ...state,
        processData: {
          ...state.processData,
          customer,
          lastEvents: swimlanesLastEventsClone,
          objects,
          startEvents: swimlanesStartEventsClone,
          swimlanes: swimlanesObjectClone,
        },
        updateUndoRedo: true,
      };
    }
    case ActionTypes.DELETE_SWIMLANE_SYMBOL_CONNECTORS: {
      if (!state.processData) return state;

      return {
        ...state,
        processData: {
          ...state.processData,
          connectors: state.processData.connectors.filter((elem) => ![elem.source.id, elem.target.id].includes(action.payload)),
        },
        updateUndoRedo: true,
      };
    }
    case ActionTypes.MOVE_DIAGRAM_SUCCESS:
    case ActionTypes.MOVE_DIAGRAM_ERROR:
    case ActionTypes.LINK_DIAGRAM_SUCCESS:
    case ActionTypes.LINK_DIAGRAM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.TOGGLE_AUTOSAVE: {
      return {
        ...state,
        isAutoSaveOn: action.payload.isAutoSaveOn,
        autoSaveInterval: action.payload.autoSaveInterval,
      };
    }
    case ActionTypes.SET_IS_AUTOSAVING: {
      return {
        ...state,
        isAutoSaving: action.payload,
      };
    }
    case ActionTypes.SET_ACTIVITIES_ERROR: {
      return {
        ...state,
        activitiesError: action.payload,
      };
    }
    case ActionTypes.DELETE_ACTIVITY_FROM_ERRORS: {
      return {
        ...state,
        activitiesError: state.activitiesError.filter((activity) => activity !== action.payload),
      };
    }
    case ActionTypes.SET_CURRENT_LEVEL: {
      return {
        ...state,
        currentLevel: action.payload,
      };
    }
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface IDiagramContext {
  state: State;
  dispatch: Dispatch<Action>;
  refs: Refs;
}
const DiagramContext = createContext<IDiagramContext | undefined>(undefined);

const DiagramProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const originRef = useRef<HTMLDivElement>(null);
  const boundsRef = useRef<HTMLDivElement>(null);
  const attributesRef = useRef<HTMLDivElement>(null);
  const attributesModalRef = useRef<HTMLDivElement>(null);
  const toolBarRef = useRef<HTMLDivElement>(null);
  const fontSizeRef = useRef<number | undefined>();
  const connectorPortMouseUp = useRef(false);
  const ghostConnectorData = useRef(null);
  const shouldPreventClick = useRef(false);
  const isCopiedSymbol = useRef(false);
  const initialCoords = useRef<{ x: number; y: number; displacement?: { x: number; y: number } } | null>(null);
  const refs = {
    containerRef,
    backgroundRef,
    originRef,
    boundsRef,
    connectorPortMouseUp,
    ghostConnectorData,
    initialCoords,
    shouldPreventClick,
    isCopiedSymbol,
    attributesRef,
    attributesModalRef,
    toolBarRef,
    fontSizeRef,
  };

  fontSizeRef.current = state.fontSize;

  const value = { state, dispatch, refs };
  state.isLoading = state.isDiagramPreparedForRelease || state.isLoading;

  return (
    <DiagramContext.Provider value={value}>
      {children}
      {state.error ? (
        <DialogError handleConfirm={() => dispatch({ type: ActionTypes.SET_ERROR, payload: null })} title={t(state.error.title)}>
          {t(state.error.message)}
        </DialogError>
      ) : null}
      {state.warning ? (
        <DialogNEPOS
          dialog={{
            title: t('warningText'),
            type: DialogType.Warning,
            buttons: state.warning.buttons || [
              {
                id: `warning-ok-button`,
                handleClick: () => dispatch({ type: ActionTypes.SET_WARNING, payload: null }),
                content: t(`ok`),
                buttonStyle: BUTTON_SECONDARY,
              },
            ],
          }}
          extraClass="Modal"
        >
          {state.warning.message}
        </DialogNEPOS>
      ) : null}
      {state.sendForReleaseError ? (
        <DialogFormError
          catalogObjects={state.processData?.objects}
          formErrorResponseBody={state.sendForReleaseError}
          handleConfirm={() => dispatch({ type: ActionTypes.SEND_FOR_RELEASE_ERROR, payload: null })}
          language={state.diagramLanguage}
          objectsUsed={[]}
          symbols={state.processData?.symbols}
        />
      ) : null}
    </DiagramContext.Provider>
  );
};

export { ActionTypes as DiagramActionTypes, DiagramContext, DiagramProvider };
