import { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, NOT_TRANSLATABLE } from 'assets/constants/constants';
import { getFullFormattedDate } from 'assets/js/Utils';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import IconButton from 'components/UI/IconButton/IconButton';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import useAuth from 'hooks/useAuth';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useRequirement from 'hooks/useRequirement';
import useRequirementContext from 'hooks/useRequirementContext';
import { DialogType } from 'types/dialogs';
import { AttributeCode, AttributeValue } from 'types/forms';
import { ProcessLevelFormatted } from 'types/processLevel';
import { RequirementActions, RequirementStatus, RequirementTabs } from 'types/requirement';

import styles from './RequirementHeader.module.scss';

type Props = {
  handleSelectTab?: Dispatch<SetStateAction<RequirementTabs | null>>;
  onDelete: () => void;
  originProcess: ProcessLevelFormatted | undefined;
  selectedTab: RequirementTabs | null;
  setShowSidebar: () => void;
  showSidebar: boolean;
  title: string;
};

const RequirementHeader = (props: Props) => {
  const { handleSelectTab = () => null, onDelete, originProcess, selectedTab, setShowSidebar, showSidebar, title } = props;
  const { userInfo } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const { requirementData, isLoading } = useRequirementContext();
  const { saveRequirement, loggedUserMatchByType, triggerRequirementAction, isUserInProcess, isLoggedUserResponsible } =
    useRequirement();
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>('');
  const { isFreezed } = useFeatureFlags();

  const statusStylesMap: { [key in RequirementStatus]: string } = {
    DRAFT: 'Draft',
    PENDING: 'Pending',
    DECLINED: 'Declined',
    DONE: 'Done',
    ACCEPTED: 'Accepted',
    FORWARD: 'Forward',
    RETRIEVED: 'Retrieved',
    CLOSED: 'Closed',
  };

  const dialogButtonsConfirmDialog = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: () => {
        history.push('/requirements');
        setShowCancelConfirmationDialog(false);
        setSelectedButton('');
      },
      key: 'confirm-submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: () => {
        setShowCancelConfirmationDialog(false);
        setSelectedButton('');
      },
      key: 'confirm-cancel',
    },
  ];

  const checkProcessUser = useCallback(
    (userType: string) => originProcess?.[userType].some((user) => user.includes(userInfo?.code)),
    [originProcess, userInfo?.code],
  );

  const checkWritePermissions = useCallback(
    (responsibleProcessId?: AttributeValue) => {
      if (!originProcess || !responsibleProcessId) return false;

      if (originProcess.level === 1) {
        return (
          checkProcessUser('processCoordinators') || checkProcessUser('processResponsibles') || checkProcessUser('pacemakers')
        );
      }

      return checkProcessUser('processResponsibles') || checkProcessUser('pacemakers');
    },
    [checkProcessUser, originProcess],
  );

  const isUserNotCreatorOrResponsible: boolean = useMemo(
    () => !loggedUserMatchByType(AttributeCode.CREATOR) && !isLoggedUserResponsible,
    [loggedUserMatchByType, isLoggedUserResponsible],
  );

  const checkAllWritePermissions = useCallback(
    (processesValue?: string): boolean => {
      const processes = processesValue?.replace('[', '').replace(']', '').split(',');
      return !!processes && processes.some((processId) => checkWritePermissions(processId));
    },
    [checkWritePermissions],
  );

  const buttons = [
    {
      id: 'RequirementsHeader-button-update',
      content: t('update'),
      disabled: isFreezed,
      handleClick: (e) => {
        setSelectedButton(e.target.id);
        triggerRequirementAction(RequirementActions.REOPEN).then(() => history.go(0));
      },
      hidden:
        requirementData &&
        (!checkAllWritePermissions(requirementData?.attributes[NOT_TRANSLATABLE]?.IDS_RESPONSIBLE_PROCESS as string) ||
          !checkWritePermissions(requirementData?.attributes[NOT_TRANSLATABLE]?.ID_ORIGIN_PROCESS) ||
          requirementData.status !== RequirementStatus.DONE),
      icon: 'icon-pfeil-aktualisieren',
    },
    {
      id: 'RequirementsHeader-button-close',
      content: t('close'),
      disabled: isFreezed,
      handleClick: (e) => {
        setSelectedButton(e.target.id);
        saveRequirement(requirementData).then(() => {
          triggerRequirementAction(RequirementActions.CLOSE);
        });
      },
      hidden:
        requirementData &&
        (!checkAllWritePermissions(requirementData?.attributes[NOT_TRANSLATABLE]?.IDS_RESPONSIBLE_PROCESS as string) ||
          ![RequirementStatus.DRAFT, RequirementStatus.DECLINED].includes(requirementData.status)),
      icon: 'icon-schliessen',
    },
    {
      id: 'RequirementsHeader-button-cancel',
      content: t('cancel'),
      custom: 'custom',
      handleClick: (e) => {
        setSelectedButton(e.target.id);
        if (requirementData?.status === RequirementStatus.CLOSED) history.push('/requirements');
        setShowCancelConfirmationDialog(true);
      },
      hidden:
        (requirementData?.status === RequirementStatus.DRAFT && isUserNotCreatorOrResponsible) ||
        (requirementData?.status === RequirementStatus.RETRIEVED && isUserNotCreatorOrResponsible) ||
        (requirementData?.status === RequirementStatus.ACCEPTED && !isLoggedUserResponsible) ||
        requirementData?.status === RequirementStatus.PENDING ||
        requirementData?.status === RequirementStatus.DECLINED,
      icon: 'icon-schliessen',
    },
    {
      id: 'RequirementsHeader-button-delete',
      content: t('delete'),
      handleClick: onDelete,
      disabled: isFreezed,
      hidden:
        requirementData?.status === RequirementStatus.CLOSED ||
        (!loggedUserMatchByType(AttributeCode.CREATOR) && !isUserInProcess()),
      icon: 'icon-muelleimer-loeschen',
    },
    {
      id: 'RequirementsHeader-button-save',
      content: t('save'),
      disabled: isFreezed,
      handleClick: (e) => {
        setSelectedButton(e.target.id);
        saveRequirement(requirementData).then(() => {
          setSelectedButton('');
          if (requirementData?.status === RequirementStatus.RETRIEVED) {
            triggerRequirementAction(RequirementActions.RESTART).then(() => history.go(0));
          }
        });
      },
      hidden:
        (requirementData?.status === RequirementStatus.DRAFT && isUserNotCreatorOrResponsible) ||
        (requirementData?.status === RequirementStatus.RETRIEVED && isUserNotCreatorOrResponsible) ||
        (requirementData?.status === RequirementStatus.ACCEPTED && !isLoggedUserResponsible) ||
        (requirementData &&
          [RequirementStatus.CLOSED, RequirementStatus.DECLINED, RequirementStatus.DONE, RequirementStatus.PENDING].includes(
            requirementData?.status,
          )),
      icon: 'icon-diskette-speichern',
    },
  ];

  const HeaderTabs = [
    { tabId: RequirementTabs.CHANGELOG, icon: 'icon-historie-verlauf', disabled: false },
    {
      tabId: RequirementTabs.COMMENTS,
      icon: 'icon-sprechblase-chat',
      notifications: requirementData?.comments.length,
      disabled: !(
        (loggedUserMatchByType(AttributeCode.CREATOR) && !isUserInProcess()) ||
        loggedUserMatchByType(AttributeCode.RESPONSIBLE_PERSONS) ||
        loggedUserMatchByType(AttributeCode.RESPONSIBLE_PACEMAKERS)
      ),
    },
  ];

  return (
    <div className={styles.Header}>
      <div className={styles.TitleBar}>
        {!showSidebar && (
          <div className={`${styles.ButtonWrapper} ${showSidebar ? '' : styles.SidebarCollapsed}`}>
            <RoundButton
              className={styles.Button}
              icon="di icon-pfeil-doppelt-chevron-rechts"
              id="RequirementsHeader-hide-symbol-attributes-button"
              onClick={setShowSidebar}
            />
          </div>
        )}
        {!!title && !!requirementData?.status && (
          <span className={styles.Title}>
            {title}
            <span className={`${styles.Status} ${styles[`${statusStylesMap[requirementData?.status]}`]}`}>
              {requirementData?.status?.toLowerCase()}
            </span>
          </span>
        )}
        <span className={styles.Title}>
          <span className={styles.Version}>{`Version: V${requirementData?.version}`}</span>
        </span>
      </div>
      {!!requirementData?.id && (
        <div className={styles.Headline}>
          <span className={styles.Id}>
            <i aria-hidden="true" className="di icon-stapel-ablage" /> #{requirementData?.id}
          </span>
          {HeaderTabs.map(({ tabId, ...otherProps }) => (
            <IconButton
              dataQA={`RequirementHeader-tabs-${tabId}`}
              key={tabId}
              onClick={() => handleSelectTab(selectedTab === tabId || otherProps?.disabled ? null : tabId)}
              selected={selectedTab === tabId}
              {...otherProps}
            >
              {tabId === RequirementTabs.CHANGELOG ? getFullFormattedDate(requirementData.lastModifiedDate) : t(`${tabId}`)}
            </IconButton>
          ))}
        </div>
      )}
      <div className={styles.Buttons}>
        {requirementData &&
          !isLoading &&
          buttons
            .filter((button) => !button.hidden)
            .map((button) => (
              <ButtonNEPOS
                disabled={button.disabled || button.id === selectedButton}
                handleClick={button.handleClick}
                icon={`di ${button.icon}`}
                id={button.id}
                key={button.id}
              >
                {button.content}
              </ButtonNEPOS>
            ))}
      </div>
      {showCancelConfirmationDialog && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsConfirmDialog,
            title: t('requirement.cancel.modal.title'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requirement.cancel.modal.message')}
        </DialogNEPOS>
      )}
    </div>
  );
};

export default RequirementHeader;
