import { AnnotationConstraints, PortVisibility, NodeConstraints } from '@syncfusion/ej2-react-diagrams';
import DOMPurify from 'dompurify';
import i18next from 'i18next';
import { Validators } from 'react-reactive-form';

import * as Constants from 'assets/constants/constants';
import serviceConstants from 'services/serviceConstants';
import { ObjectIOSubTypes, ObjectTypes } from 'types/administration';
import { RequirementFilters } from 'types/requirement';
import { Swimlanes } from 'types/swimlanes';
import { WorkflowStatus } from 'types/workflow';
/**
 * Para entropía mayor que 128 bits, necesitamos length de 22 para un conjunto de 64 caracteres
 * +info sobre entropía https://inversegravity.net/2019/password-entropy/
 */
export const getRandomString = (length = 22) => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const crypto = window.crypto || window.msCrypto;
    const random = crypto.getRandomValues(bytes);

    /* eslint-disable no-loop-func, @typescript-eslint/no-loop-func, no-param-reassign, no-plusplus */
    random.forEach((c) => {
      if (length === 0) {
        return;
      }

      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
    /* eslint-enable */
  }

  return result;
};

export const addDots = (val) => val.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const addDotsToNumbers = (value) => addDots(value.replace(/[^0-9]/g, ''));

export const getWarningIcon = (object) => {
  if (object.temporaryText) {
    return 'icon-warnung-fehler';
  }
  if (!object.approved) {
    if (object.type === ObjectTypes.ROLE_SWIMLANE || object.type === ObjectTypes.IT_SYSTEM_SWIMLANE) {
      return 'icon-uhr-zeit';
    }
  }
  return '';
};

export const getTooltipText = (object, t) => {
  let objectType = '';
  switch (object.type) {
    case ObjectTypes.ROLE_SWIMLANE:
      objectType = 'ROLE';
      break;
    case ObjectTypes.IT_SYSTEM_SWIMLANE:
      objectType = 'IT_SYSTEM';
      break;
    case ObjectTypes.IO_SWIMLANE:
      if (object.subtype)
        objectType = object.subtype === ObjectIOSubTypes.INPUT ? 'diagram.objects.input' : 'diagram.objects.output';
      break;
    default:
      objectType = object.type;
  }
  if (object.temporaryText) return t('processStep.object.tooltip.temporary', { objectX: t(objectType) });
  if (object.type === ObjectTypes.ROLE_SWIMLANE || object.type === ObjectTypes.IT_SYSTEM_SWIMLANE) {
    return t('processStep.object.tooltip.request', { objectX: t(objectType) });
  }
  return t('processStep.object.tooltip.new', { objectX: t(objectType) });
};

export const getName = (object, diagramLanguage, t) => {
  if (object.temporaryText) return object.temporaryText;
  switch (object.type) {
    case ObjectTypes.ROLE_SWIMLANE:
      if (object.responsability) {
        const label = t(`responsibility.${Constants.LINK_RESPONSIBILITY_BY_ID[object.responsability]}`);
        return `${label.slice(label.indexOf('(') + 1, label.indexOf(')'))} - ${
          object.attributes?.[diagramLanguage]?.OBJECT_NAME
        }`;
      }
      return object.attributes?.[diagramLanguage]?.OBJECT_NAME;
    case ObjectTypes.IT_SYSTEM_SWIMLANE:
      return object.attributes?.[diagramLanguage]?.OBJECT_NAME;
    case ObjectTypes.IO_SWIMLANE:
      return object.attributes?.[diagramLanguage]?.NAME;
    default:
      return '';
  }
};

export const getFormattedDate = (originalDate, separator = '.') => {
  if (!originalDate || originalDate === 'null') {
    return '-';
  }

  const date = new Date(originalDate);
  if (!date.getTime()) return originalDate;
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}${separator}${month}${separator}${year}`;
};

export const getFullFormattedDate = (originalDate) => {
  if (originalDate === 'null') {
    return '-';
  }

  const date = new Date(originalDate);
  const hours = `0${date.getHours() || 12}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);

  let formateDate = '';

  if (
    originalDate &&
    (originalDate.includes('UTC') || originalDate.includes('T') || originalDate.includes('Z') || originalDate.includes('+0000'))
  ) {
    formateDate = `${getFormattedDate(originalDate)} - ${hours}:${minutes}`;
  } else if (originalDate) {
    formateDate = originalDate;
  }

  return formateDate;
};

export const getUTCDate = (formattedDate, isTimeIncluded = true) => {
  const formattedDateSplitted = formattedDate.split(' - ');
  const date = formattedDateSplitted[0];
  const hour = formattedDateSplitted[1];
  const dateSplitted = date.split('.');
  const year = dateSplitted[2];
  const month = dateSplitted[1];
  const day = dateSplitted[0];

  return isTimeIncluded ? `${year}-${month}-${day} ${hour}:00 UTC` : `${year}-${month}-${day}`;
};

export const cloneObject = (obj) => {
  if (typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }

  return obj;
};

export const checkLinkageValidation = (form, fieldsToValidate, linkageValue) => {
  let attributeType = 'M';
  let validator = Validators.required;
  const updatedForm = { ...form };

  if (linkageValue && linkageValue.trim() !== '') {
    attributeType = 'O';
    validator = '';
  }

  fieldsToValidate.forEach((field) => {
    updatedForm.controls[field].meta.attributeType = attributeType;
    updatedForm.controls[field].validator = validator;
    updatedForm.controls[field].updateValueAndValidity();
  });

  return updatedForm;
};

export const showFormErrors = (fieldConfig) => {
  const keys = Object.keys(fieldConfig.controls);
  const newFC = { ...fieldConfig.controls };

  for (let i = 0; i < keys.length; i++) {
    if (newFC[keys[i]].meta.attributeType === 'M') {
      newFC[keys[i]].meta.forceError = true;
    }
  }

  return {
    controls: newFC,
  };
};

export const parseObjectsDiagramList = (list) => list.map((object) => object.idFront);

export const parseObjectsCatalogList = (list) => {
  const parsedList = list.map((item) => ({
    idObjectCatalog: item.idObjectCatalog,
    rolResponsability: item.rolResponsability,
  }));

  return parsedList;
};

export const toggleTools = (toolIcons, toolIDs = [], isDisabled) => {
  return toolIcons.map((tool) => {
    if (toolIDs.includes(tool.id)) {
      tool.disabled = isDisabled;
    }

    return tool;
  });
};

export const getObjectAttributes = (attributes) => {
  if (!attributes) return null;
  return typeof attributes === 'string' ? JSON.parse(attributes) : attributes;
};

export const getInitialForm = (controls) => {
  let form = {};

  Object.keys(controls).forEach((field) => {
    form = {
      ...form,
      [field]: '',
    };
  });

  return form;
};

export const getLanguageAttributes = (attributes, lang = Constants.ENGLISH, isJsonParseNeeded = true) => {
  try {
    const attr = isJsonParseNeeded ? JSON.parse(attributes) : attributes;

    return {
      ...attr[lang.toUpperCase()],
      ...attr[Constants.NOT_TRANSLATABLE],
    };
  } catch {
    return {};
  }
};

export const checkWhiteSpacesValidation = (args) => {
  if (typeof args.value === 'string' && args.value?.trim() === '') {
    return { required: true };
  }

  return null;
};

export const updateObjectAttributes = (object, prevAttributes) => {
  const attributes = [...prevAttributes];
  const selected = attributes.findIndex((it) => it.id === object.id);
  const newObject = {
    ...object,
    attributes: JSON.parse(object.attributes),
  };

  if (attributes[selected]) {
    attributes[selected] = newObject;
  } else {
    attributes.push(newObject);
  }

  return attributes;
};

export const resetDropdowns = (fieldConfig, optionsSelected = [], saved = false) => {
  const keys = Object.keys(fieldConfig.controls);

  keys.forEach((key) => {
    if (fieldConfig.controls[key].render.name === Constants.DROPDOWN && fieldConfig.controls[key].meta.attributeType !== 'S') {
      const optionSelected = optionsSelected.find((option) => option.key === key);

      const options = fieldConfig.controls[key].meta.options.map((option) => {
        option.selected = optionSelected && ((option.value === optionSelected.value && !saved) || (saved && option.selected));

        return option;
      });

      if (key === Constants.MONTHS_VALIDITY && !saved && !options.find((option) => option.selected)) {
        options[5].selected = true;
      }

      fieldConfig.controls[key].meta.options = options;
    }
  });

  return fieldConfig;
};

export const formatTask = (task) => ({
  ...task,
  closeDate: getFormattedDate(task.lastModificationDate),
  deadline: getFormattedDate(task.finalDate),
  startDateTask: getFormattedDate(task.creationDate),
});

const groupTasksCodes = ['DESIGN_ROLE', 'DESIGN_IT_SYSTEM', 'WORKFLOW_APPROVAL_GROUP'];

export const getSeparatedTasks = (tasks) => {
  const tasksData = tasks.map((task) => formatTask(task));
  const myTasks = [];
  const groupTasks = [];
  const closedTasks = [];

  tasksData.forEach((task) => {
    if (task.status === Constants.DONE) {
      closedTasks.push(task);
    } else if (groupTasksCodes.includes(task.type) && task.status !== 'ACCEPTED') {
      groupTasks.push(task);
    } else {
      myTasks.push(task);
    }
  });

  return {
    myTasks,
    groupTasks,
    closedTasks,
  };
};

export const sortEmptyValues = (valueA, valueB, order) => {
  if (valueA === '-') {
    return order === Constants.SORT_ASC ? 1 : -1;
  }

  if (valueB === '-') {
    return order === Constants.SORT_ASC ? -1 : 1;
  }
};

export const getColumnsOrder = (columns, field) => {
  const order = columns[field] && columns[field] === Constants.SORT_ASC ? Constants.SORT_DESC : Constants.SORT_ASC;
  columns[field] = order;

  return columns;
};

export const getOppositeDirection = (direction) => (direction === Constants.SORT_ASC ? Constants.SORT_DESC : Constants.SORT_ASC);

export const sortTableByColumn = (field, fieldType, columns, resultsData, linkSortable = false, columnOrder = '') => {
  let results = [];
  let order = columnOrder;

  if (!columnOrder) {
    order = columns[field] && columns[field] === Constants.SORT_ASC ? Constants.SORT_DESC : Constants.SORT_ASC;
  }

  if (fieldType === Constants.DATE_TYPE) {
    results = [...resultsData].sort((a, b) => {
      const dateA = new Date(a[field]?.split('.')[2], a[field]?.split('.')[1] - 1, a[field]?.split('.')[0]);
      const dateB = new Date(b[field]?.split('.')[2], b[field]?.split('.')[1] - 1, b[field]?.split('.')[0]);

      if (a[field] === '-' || b[field] === '-') {
        return sortEmptyValues(a[field], b[field], order);
      }

      return order === Constants.SORT_ASC ? dateA - dateB : dateB - dateA;
    });
  } else if (fieldType === Constants.TEXT_TYPE) {
    if (linkSortable && field === 'link') {
      results = [...resultsData].sort((a, b) => {
        const isTaskARecommended = a.type.includes('RECOMMENDED');
        const isTaskBRecommended = b.type.includes('RECOMMENDED');
        const valueA = parseInt(a[field], 10);
        const valueB = parseInt(b[field], 10);

        if (order === Constants.SORT_ASC) {
          if ((isTaskARecommended && isTaskBRecommended) || (!isTaskARecommended && !isTaskBRecommended)) {
            return valueA > valueB ? 1 : -1;
          }

          return isTaskARecommended ? -1 : 1;
        }

        if ((isTaskARecommended && isTaskBRecommended) || (!isTaskARecommended && !isTaskBRecommended)) {
          return valueA < valueB ? 1 : -1;
        }

        return isTaskARecommended ? 1 : -1;
      });
    } else {
      results = [...resultsData].sort((a, b) => {
        if (order === Constants.SORT_ASC) {
          return a[field]?.toString().toLowerCase() > b[field]?.toString().toLowerCase() ? 1 : -1;
        }

        return a[field]?.toString().toLowerCase() < b[field]?.toString().toLowerCase() ? 1 : -1;
      });
    }
  } else {
    results = [...resultsData].sort((a, b) => {
      if (order === Constants.SORT_ASC) {
        return a[field] > b[field] ? 1 : -1;
      }

      return a[field] < b[field] ? 1 : -1;
    });
  }

  columns[field] = order;
  const sortResult = {
    columns,
    results,
  };

  return sortResult;
};

export const getFilteredSearch = (filtersData, results) => {
  const filterList = cloneObject(filtersData);

  const filterResults = Object.keys(filterList).reduce(
    (acc, filterKey) => {
      if (!acc.length) return [];
      const filter = filterList[filterKey];
      let filterResult = [...acc];

      switch (filter.type) {
        case Constants.CHECKBOX:
          filterResult = filterResult.filter((result) => filter.filterData.includes(result.type));
          break;
        case Constants.TEXTINPUT:
          if (!filter.filterData) break;

          filterResult = filterResult.filter((r) => r.processNumber?.includes(filter.filterData));
          break;
        case Constants.USER: {
          const usersSelected = filter.filterData?.filter((u) => u.checked)?.map((user) => user.code);
          if (!usersSelected?.length) break;

          filterResult = filterResult.filter(
            (r) => r[filterKey] && usersSelected.some((userCode) => r[filterKey].includes(userCode)),
          );
          break;
        }
        default:
      }

      return [...filterResult];
    },
    [...results],
  );

  return {
    filterList,
    filterResults,
  };
};

export const getFilterStatus = (filterType, filterData) => {
  let isSelected = false;

  if (filterType === Constants.USER || filterType === Constants.LOCATION) {
    const optionsSelected = filterData?.filter((elem) => elem.checked);
    isSelected = optionsSelected?.length > 0;
  } else if (filterType === Constants.CHECKBOX) {
    isSelected = filterData.length < 2; // When I don't select both options
  } else {
    isSelected = filterData !== '';
  }

  return isSelected;
};

export const mapScopes = (diagramScopes, language) => {
  const mappedScopes = diagramScopes.map((scope) => {
    const user = scope.firstIncumbentUser || scope.secondIncumbentUser || 'N.N.';

    return `${scope.abbreviation} - ${JSON.parse(scope.description)[language]} (${user})`;
  });

  return mappedScopes.join('\r\n');
};

/* -------- PAGINATION -------- */

export const getPageBounds = (page = 1) => ({
  start: Constants.DEFAULT_PAGE_SIZE * (page - 1),
  end: Constants.DEFAULT_PAGE_SIZE * page,
});

export const getTotalPages = (results) => Math.ceil(results?.length / Constants.DEFAULT_PAGE_SIZE) || 0;

export const deselectRows = (rows, checked = false) => {
  rows.forEach((elem) => {
    elem.checked = checked;
  });
};

export const replaceAll = (text, characters, replace) => text?.split(characters).join(replace) || '';

export const downloadFile = (url, fileName, contentDisposition) => {
  const downloadFileName = contentDisposition ? decodeURIComponent(contentDisposition).split('=')[1] : fileName;
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', downloadFileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadBlob = (data, headers, fileName) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  downloadFile(url, decodeURI(fileName), headers['content-disposition']);
};

export const getLocation = () => {
  const location = (serviceConstants.env === 'local' ? serviceConstants.baseUrl.split('//')[1] : window.location.hostname).split(
    '.',
  )[0];

  return Constants.LOCATIONS[location];
};

export const purify = (value) => (typeof value === 'string' ? DOMPurify.sanitize(value) : value);

export const purifyAttributes = (attributes) => {
  if (!attributes) return null;
  const areAttributesStringified = typeof attributes === 'string';
  const purifiedAttributes = areAttributesStringified ? JSON.parse(attributes) : cloneObject(attributes);
  Object.keys(purifiedAttributes).forEach((language) => {
    Object.keys(purifiedAttributes[language]).forEach((attribute) => {
      purifiedAttributes[language][attribute] = purify(purifiedAttributes[language][attribute]);
    });
  });

  return areAttributesStringified ? JSON.stringify(purifiedAttributes) : purifiedAttributes;
};

export const getDangerouslySetInnerHTML = (value) => ({
  __html: purify(value),
});

export const checkWysiwygCharacters = (field, data) =>
  [field?.fieldType, field?.type].includes(Constants.INPUT_WYSIWYG) ? replaceAll(data, '<o:p></o:p>', '') : data;

export const fieldConfigMultilanguage = (attributes, types, t, availableLanguages) => {
  const fieldConfig = { controls: {} };

  Object.keys(attributes).forEach((key) => {
    const { translatable, code } = types.find((a) => a.code === key);
    const data = { ...attributes[key] };
    availableLanguages.forEach((lang) => {
      const description = t(`nameAttributes.${code}`, { lng: lang });
      const newData = {
        ...data,
        meta: {
          ...data.meta,
          label: description,
          placeholder: description,
        },
      };

      if (translatable) {
        fieldConfig.controls = { ...fieldConfig.controls, [`${key}_${lang}`]: newData };
      } else {
        fieldConfig.controls = { ...fieldConfig.controls, [key]: newData };
      }
    });
  });

  return fieldConfig;
};

export const fieldConfigMultilanguageLegacy = (attributes, types, t) => {
  const fieldConfig = { controls: {} };

  Object.keys(attributes).forEach((key) => {
    const { translatable, code } = types.find((a) => a.code === key);
    const description = t(`nameAttributes.${code}`, { lng: Constants.ENGLISH });
    const germanLabel = t(`nameAttributes.${code}`, { lng: Constants.GERMAN });

    const data = { ...attributes[key] };
    const germanData = { ...attributes[key] };
    data.meta = {
      ...data.meta,
      label: description,
      placeholder: description,
    };

    if (translatable) {
      fieldConfig.controls = { ...fieldConfig.controls, [`${key}_${Constants.ENGLISH}`]: data };

      germanData.meta = {
        ...germanData.meta,
        label: germanLabel,
        placeholder: germanLabel,
      };
      fieldConfig.controls = { ...fieldConfig.controls, [`${key}_${Constants.GERMAN}`]: germanData };
    } else {
      fieldConfig.controls = { ...fieldConfig.controls, [key]: data };
    }
  });

  return fieldConfig;
};

export const getFieldLanguage = (field) => {
  const language = field.split('_').pop();

  if (Constants.AVAILABLE_LANGUAGES.includes(language)) {
    return language;
  }

  return Constants.NOT_TRANSLATABLE;
};

export const getFieldType = (field) => {
  const fieldLang = field.split('_').pop();
  let fieldName = field;

  if (Constants.AVAILABLE_LANGUAGES.includes(fieldLang)) {
    fieldName = field.slice(0, -3);
  }

  return fieldName;
};

export const fillFormData = (controls, attributes) => {
  let formData = {};

  Object.keys(controls).forEach((field) => {
    const lang = getFieldLanguage(field);
    const fieldType = getFieldType(field);
    formData = {
      ...formData,
      [field]: attributes[lang][fieldType] || '',
    };
  });

  return formData;
};

export const getInitialMultilanguageLegacy = () => ({
  [Constants.ENGLISH]: {},
  [Constants.GERMAN]: {},
  [Constants.NOT_TRANSLATABLE]: {},
});

export const getInitialMultilanguage = (availableLanguages) => {
  const languages = [...availableLanguages];
  languages.push(Constants.NOT_TRANSLATABLE);
  const langs = Object.fromEntries(languages.map((lang) => [lang, {}]));

  return langs;
};

export const prepareRequestObjectCatalog = (attributes) => {
  const attributesMultilanguage = getInitialMultilanguageLegacy();

  Object.keys(attributes).forEach((key) => {
    const lang = getFieldLanguage(key) || Constants.NOT_TRANSLATABLE;
    attributesMultilanguage[lang] = {
      ...attributesMultilanguage[lang],
      [getFieldType(key)]: purify(attributes[key]) || '',
    };
  });

  return attributesMultilanguage;
};

export const getNewLaneId = (lanes) =>
  Math.max.apply(
    this,
    lanes.map((l) => parseInt(l.id.split('_').pop(), 10)),
  ) /
    10 +
  1;

export const createLane = ({ rowNumber, orientation, headerContent, height, width }) => {
  const isHorizontal = orientation === Constants.HORIZONTAL;
  const laneHeight = height || (isHorizontal ? Constants.SWIMLANE_LANE_HEIGHT : Constants.SWIMLANE_HEIGHT);
  const laneWidth = width || (isHorizontal ? Constants.SWIMLANE_WIDTH : Constants.SWIMLANE_LANE_HEIGHT);

  return {
    id: `lane_${rowNumber}`,
    // Row/Column header
    header: {
      annotation: {
        content: headerContent || `Swimlane ${rowNumber + 1}`,
      },
      // Header size
      height: isHorizontal ? height || Constants.SWIMLANE_LANE_HEIGHT : Constants.SWIMLANE_HEADER_HEIGHT,
      width: isHorizontal ? Constants.SWIMLANE_HEADER_HEIGHT : width || Constants.SWIMLANE_LANE_HEIGHT,
    },
    // Row/Column size
    height: laneHeight,
    width: laneWidth,
    // Disable lane swapping
    canMove: false,
  };
};

export const createSwimlane = (orientation, lanes, horizontalOffset, verticalOffset, headerContent = '', height, width) => ({
  id: 'swimlane',
  shape: {
    id: 'shape',
    // Set the node type as Swimlane
    type: 'SwimLane',
    // Orientation of the swimlane (Horizontal/Vertical), default Horizontal
    orientation,
    // Header mandatory to drag the swimlane
    header: {
      annotation: { content: headerContent },
      height: Constants.SWIMLANE_HEADER_HEIGHT,
    },
    // Lanes are the rows/columns headers on the swimlane
    lanes,
    phases: [
      {
        id: 'phase1',
        header: { annotation: { constraints: AnnotationConstraints.ReadOnly, content: '' } },
      },
    ],
    phaseSize: 0.01,
  },
  // Position of the swimlane on the diagram
  offsetX: -(horizontalOffset - (Constants.DIAGRAM_MARGIN_X + Constants.SWIMLANE_WIDTH / 2)),
  offsetY: -(verticalOffset - (Constants.DIAGRAM_MARGIN_Y + Constants.SWIMLANE_HEIGHT / 2)),
  // Height and width looks mandatory, removing this properties or initializing them to 0 gives errors
  height:
    orientation === Constants.VERTICAL
      ? height || Constants.SWIMLANE_HEIGHT
      : height || lanes.length * Constants.SWIMLANE_LANE_HEIGHT,
  width:
    orientation === Constants.HORIZONTAL
      ? width || Constants.SWIMLANE_WIDTH
      : width || lanes.length * Constants.SWIMLANE_LANE_HEIGHT,
  // Visible property to show/hide swimlane
  visible: true,
});

export const isSwimlaneSelected = (diagramInstance) => diagramInstance?.selectedItems.nodes[0]?.id.includes('swimlane');

export const getSwimlaneNode = (diagramInstance) => diagramInstance?.nodes.find((node) => node.id === 'swimlane');

export const toggleSwimlaneLanes = (diagramInstance, isLane) => {
  const laneNodes = diagramInstance.nodes.filter((node) => node.id.includes('swimlanelane'));

  laneNodes.forEach((node) => {
    node.isLane = isLane;
  });
};

export const sendSwimlaneToBack = (diagramInstance, toggleCanSelect) => {
  const onlySwimlaneNodes = diagramInstance.nodes.every((node) => node.id.includes('swimlane'));
  if (onlySwimlaneNodes) return;
  const previousHistory = { ...diagramInstance.historyManager };
  const swimlaneNode = getSwimlaneNode(diagramInstance);
  if (!swimlaneNode) return;
  diagramInstance.select([swimlaneNode]);
  toggleCanSelect(true);

  diagramInstance.sendToBack();
  diagramInstance.clearSelection();
  diagramInstance.historyManager = previousHistory;
};

export const resetSwimlane = (diagramInstance, toggleCanSelect) => {
  const previousHistory = { ...diagramInstance.historyManager };
  const swimlane = getSwimlaneNode(diagramInstance);
  const { constraints, height, offsetX, offsetY, width } = swimlane;
  const { header, lanes, orientation } = swimlane.shape;

  diagramInstance.remove(swimlane);
  toggleCanSelect(true);

  const newLanes = lanes.map((lane) =>
    createLane({
      rowNumber: lane.id.split('_')[1],
      orientation,
      headerContent: lane.header.annotation.content,
      height: lane.height,
      width: lane.width,
    }),
  );

  const newSwimlane = createSwimlane(
    orientation,
    newLanes,
    -(offsetX - (Constants.DIAGRAM_MARGIN_X + Constants.SWIMLANE_WIDTH / 2)),
    -(offsetY - (Constants.DIAGRAM_MARGIN_Y + Constants.SWIMLANE_HEIGHT / 2)),
    header.annotation.content,
    height,
    width,
  );
  diagramInstance.add(newSwimlane);
  sendSwimlaneToBack(diagramInstance, toggleCanSelect);
  const isLocked = (constraints & NodeConstraints.Select) !== NodeConstraints.Select;
  const swimlaneNodes = diagramInstance.nodes.filter((node) => node.id.includes('swimlane'));
  swimlaneNodes.forEach((node) => {
    if (node.isLane) node.constraints = NodeConstraints.Default;

    if (isLocked) {
      node.constraints &= ~(NodeConstraints.Select | NodeConstraints.PointerEvents);
    }
  });
  const newSwimlaneNode = getSwimlaneNode(diagramInstance);
  newSwimlaneNode.constraints = constraints;
  diagramInstance.eventHandler.action = 'None';
  diagramInstance.historyManager = previousHistory;
};

const nodePorts = [
  {
    id: Constants.RIGHT_PORT,
    offset: { x: 1, y: 0.5 },
    visibility: PortVisibility.Connect,
  },
  {
    id: Constants.BOTTOM_PORT,
    offset: { x: 0.5, y: 0 },
    visibility: PortVisibility.Connect,
  },
  {
    id: Constants.LEFT_PORT,
    offset: { x: 0, y: 0.5 },
    visibility: PortVisibility.Connect,
  },
  {
    id: Constants.TOP_PORT,
    offset: { x: 0.5, y: 1 },
    visibility: PortVisibility.Connect,
  },
];

export const getNodeDefaults = (node) => {
  if (
    Constants.SYMBOLS_WITH_PORTS.some((symbol) => node.id.includes(symbol)) &&
    !nodePorts.every((port) => node.ports.find((nodePort) => nodePort.id === port.id))
  ) {
    node.ports = [...node.ports, ...nodePorts];
  }

  return node;
};

export const keepNodeAngles = (node, width, height) => {
  if (node.id.includes(Constants.OPEN_PROCESS_OVERVIEW)) {
    node.shape.data = `M0 0 L${width - Constants.ANGLE_DISTANCE} 0 L${width} ${height / 2} L${width - Constants.ANGLE_DISTANCE}
      ${height} L0 ${height} L${Constants.ANGLE_DISTANCE} ${height / 2} L0 0`;
  } else if (node.id.includes(Constants.EVENT)) {
    // EVENT symbol
    node.shape.data = `M0 ${height / 2} L${Constants.ANGLE_DISTANCE} 0 L${width - Constants.ANGLE_DISTANCE} 0 L${width}
      ${height / 2} L${width - Constants.ANGLE_DISTANCE} ${height} L${Constants.ANGLE_DISTANCE} ${height} L0 ${height / 2}`;
  }
};

export const alignProcessInterfaceAnnotations = (node) => {
  if (!node.id.includes(Constants.PROCESS_INTERFACE)) return;

  node.annotations[0].margin = { right: node.width / 8 };

  if (node.annotations.length > 2) {
    node.annotations[2].margin = { right: node.width / 8 };
  }
};

export const copyNodeStyles = (newNodeSelected, oldNodeSelected) => {
  const isNewNodeText = newNodeSelected.shape.type === 'Text';
  const isOldNodeText = oldNodeSelected.shape.type === 'Text';
  const newNodeStyles = newNodeSelected.style;
  const oldNodeStyles = oldNodeSelected.style;

  if (!isNewNodeText && !isOldNodeText) {
    newNodeStyles.strokeColor = oldNodeStyles.strokeColor;
  }

  if (oldNodeStyles.fill !== 'none') {
    newNodeStyles.fill = oldNodeStyles.fill;
  }

  const newNodeFontStyles = isNewNodeText ? newNodeSelected.style : newNodeSelected.annotations[0].style;
  const oldNodeFontStyles = isOldNodeText ? oldNodeSelected.style : oldNodeSelected.annotations[0].style;

  newNodeFontStyles.color = oldNodeFontStyles.color;
  newNodeFontStyles.fontSize = oldNodeFontStyles.fontSize;
  newNodeFontStyles.bold = oldNodeFontStyles.bold;
  newNodeFontStyles.italic = oldNodeFontStyles.italic;
  newNodeFontStyles.textDecoration = oldNodeFontStyles.textDecoration;
};

export const getArea = (location, status, isOnlyUser) => {
  if (status === Constants.STATUS.WORKFLOW) return Constants.AREAS.WORKFLOW;

  if (location.includes('published') || (location.includes('dataset') && isOnlyUser)) return Constants.AREAS.PUBLISHED;

  if (location.includes('sandbox')) return Constants.AREAS.SANDBOX;

  return Constants.AREAS.MODELING;
};

export const expandNode = (treeData, id) => {
  if (!id) {
    return;
  }
  const node = treeData.find((elem) => elem.id === id);
  node.expanded = true;
  expandNode(treeData, node.pid);
};

export const expandTree = (treeData, diagramId, isSandbox) => {
  treeData.forEach((elem) => {
    elem.selected = false;
    elem.expanded = false;
  });

  if (diagramId > 1 && !isSandbox) {
    const node = treeData.find((elem) => elem.id === parseInt(diagramId, 10));

    if (node) {
      node.selected = true;
      expandNode(treeData, node.pid);
    }
  }
};

export const prepareTree = (tree, diagramId, isSandbox, language) => {
  tree.map((elem) => {
    if (elem.pid === 0) {
      delete elem.pid;
    }
    elem.icon = elem.idDiagramType === Constants.EPC_DIAGRAM_ID ? 'di icon-hierarchie-diagramm' : 'di icon-etikett-2';

    return elem;
  });
  expandTree(tree, diagramId, isSandbox);

  return {
    dataSource: tree,
    id: 'id',
    parentID: 'pid',
    text: `name.${language}.PROCESS_NAME`,
    hasChildren: 'hasChild',
    iconCss: 'icon',
  };
};

const EMPTY_DIAGRAM_BOUNDS = { top: 0, left: 0, bottom: 0, right: 0, height: 0, width: 0 };

export const getBounds = (areNotesDisplayed, symbols = [], isSwimlane = false, fontSize, displayedSymbolBoards) => {
  let bounds = EMPTY_DIAGRAM_BOUNDS;

  const swimlaneWrapper = document.getElementById(Swimlanes.WRAPPER_DOM_ID)?.getBoundingClientRect();
  if (isSwimlane && swimlaneWrapper) {
    const { height = 0, width = 0 } = swimlaneWrapper;
    bounds = {
      top: 0,
      left: 0,
      bottom: height / fontSize,
      right: width / fontSize,
      height: height / fontSize,
      width: width / fontSize,
    };
  }

  if (symbols.length === 0) return bounds;

  const firstElementBounds = isSwimlane
    ? bounds
    : {
        bottom: symbols[0].meta?.top + symbols[0].meta.height,
        left: symbols[0].meta.left,
        right: symbols[0].meta.left + symbols[0].meta.width,
        top: symbols[0].meta.top,
      };

  const newBoundsCoordinates = symbols.reduce((boundsAcc, symbol) => {
    const isCurrentSymbolDisplayed = displayedSymbolBoards.includes(symbol.id);
    const symbolWidth = isCurrentSymbolDisplayed ? Constants.SYMBOL_BOARD_WIDTH : symbol.meta.width || Constants.SYMBOL_WIDTH;
    const displayedCurrentHeight = symbol.postItsHeigthOffset
      ? symbol.postItsHeigthOffset + Constants.SYMBOL_BOARD_MIN_HEIGHT
      : Constants.SYMBOL_BOARD_MIN_HEIGHT;
    const symbolHeight = isCurrentSymbolDisplayed ? displayedCurrentHeight : Constants.SYMBOL_HEIGHT;

    return {
      bottom: Math.max(boundsAcc.bottom, symbol.meta.top + symbolHeight + (symbol.offset?.y || 0)),
      left: Math.min(boundsAcc.left, symbol.meta.left),
      right: Math.max(boundsAcc.right, symbol.meta.left + symbolWidth + (symbol.offset?.x || 0)),
      top: Math.min(boundsAcc.top, symbol.meta.top),
    };
  }, firstElementBounds);

  return {
    ...newBoundsCoordinates,
    height: newBoundsCoordinates.bottom - newBoundsCoordinates.top,
    width: newBoundsCoordinates.right - newBoundsCoordinates.left,
  };
};

export const setFormattedDate = (date) => {
  if (date === 'null' || date === null || date === '') return '';

  const formattedDate = new Date(date);

  return `${formattedDate.toLocaleDateString(i18next.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} - ${formattedDate.toLocaleTimeString(i18next.language, {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export const getDeepFirstChild = (element) => (element?.firstChild ? getDeepFirstChild(element.firstChild) : element);

export const getSanitizedTooltipContent = (element) => {
  // eslint-disable-next-line no-underscore-dangle
  if (element?.$$typeof && element.props?.dangerouslySetInnerHTML?.__html) {
    // eslint-disable-next-line no-underscore-dangle
    return element.props?.dangerouslySetInnerHTML?.__html;
  }
  if (Array.isArray(element)) {
    return element.join();
  }
  if (typeof element !== 'string' && !(element instanceof String)) {
    return '';
  }
  return element;
};

export const stringIsArray = (str) => typeof str === 'string' && str && str[0] === '[' && str[str.length - 1] === ']';

export const parseWorkflowDataResponse = (workflowData, stagesData) => {
  const stagesMap = {};
  const wfStages = stagesData.workflowStageTypes;
  wfStages.forEach((stage) => {
    stagesMap[stage.stageType.code] = stage;
  });
  const parsedStages = workflowData?.stages.map((stage) => {
    return {
      ...stage,
      priority: stagesMap[stage.type].priority,
    };
  });
  parsedStages.sort((a, b) => a.priority - b.priority);

  const parsedData = {
    ...workflowData,
    users: workflowData.users,
    stages: parsedStages,
    status: workflowData.status.code || workflowData.status,
  };
  return parsedData;
};

export const generateYearsArray = (range) => {
  const min = new Date().getFullYear();
  const max = min + range;
  const years = [];
  for (let i = min; i <= max; i++) {
    years.push(`${i}`);
  }
  return years;
};

export const hasDuplicates = (array) => new Set(array).size < array.length;

export const removeSquareBracketsFromString = (element) => {
  return element.replaceAll('[', '').replaceAll(']', '').split(',');
};

export const removeHTMLTagsFromString = (element) => {
  if (!element || element === '') return;
  return element.toString().replace(/<(.|\n)*?>/g, '');
};

export const handleFilters = (value, filter, setFilter, filters, handleFiltersChange) => {
  let newFilters = [];
  if (filter === RequirementFilters.PROCESS_ID) {
    setFilter([]);
    if (filters.length === 0) newFilters = [...filters];
  } else if (filter === RequirementFilters.SUPPORT_NEEDED) newFilters = filters.includes(value) ? [value] : [];
  else newFilters = filters;

  newFilters = newFilters.includes(value) ? newFilters.filter((elem) => elem !== value) : [...newFilters, value];

  setFilter(newFilters);

  handleFiltersChange({
    [filter]: newFilters.join(','),
  });
};

export const isTaskValidClosedWorkflow = (task) => {
  const { status, workflowStatus } = task;
  return (
    Constants.CLOSED_TASK_TYPES.includes(status) &&
    [WorkflowStatus.REJECTED, WorkflowStatus.COMPLETED, WorkflowStatus.ESCALATED].includes(workflowStatus) &&
    // TODO: [closedWorkflow-US-2]: Remove next line for SIPOC/SWIMLANE implementation
    [Constants.EPC_DIAGRAM, Constants.VCD_DIAGRAM].includes(task.diagramType)
  );
};

export const checkEmptyAttributes = (attributes) => {
  return Object.keys(attributes)?.some((language) =>
    Object.keys(attributes[language]).some(
      (attribute) =>
        (typeof attributes[language][attribute] === 'string' && attributes[language][attribute] !== '') ||
        (typeof attributes[language][attribute] !== 'string' &&
          (attributes[language][attribute]?.links?.length > 0 || attributes[language][attribute]?.docs?.length > 0)),
    ),
  );
};
