import React from 'react';

import * as Constants from '../../../../assets/constants/constants';
import styles from './WorkflowsRow.module.scss';

const WorkflowsRow = ({ isHeader, element, handleRowClick, selectedTab, sortTable, direction, fieldToOrder }) => {
  let dateColumns = '';

  const getSortedClass = (field) => {
    return fieldToOrder === field ? styles[direction] : '';
  };

  if (isHeader) {
    dateColumns =
      selectedTab === 'closedWorkflows' ? (
        <th className={`${styles.CloseDate} ${getSortedClass('closeDate')}`} onClick={() => sortTable('closeDate')}>
          {element.closeDate}
        </th>
      ) : (
        <>
          <th className={`${styles.StartDateTask} ${getSortedClass('startDateTask')}`} onClick={() => sortTable('startDateTask')}>
            {element.startDateTask}
          </th>
          <th className={`${styles.Deadline} ${getSortedClass('deadline')}`} onClick={() => sortTable('deadline')}>
            {element.deadline}
          </th>
        </>
      );
  } else {
    dateColumns =
      element.status === Constants.DONE ? (
        <td className={`${styles.CloseDate} ${getSortedClass('closeDate')}`} onClick={() => sortTable('closeDate')}>
          {element.closeDate !== 'null' ? element.closeDate : ''}
        </td>
      ) : (
        <>
          <td className={`${styles.StartDateTask} ${getSortedClass('startDateTask')}`} onClick={() => sortTable('startDateTask')}>
            {element.startDateTask}
          </td>
          <td className={`${styles.Deadline} ${getSortedClass('deadline')}`} onClick={() => sortTable('deadline')}>
            {element.deadline !== 'null' ? element.deadline : ''}
          </td>
        </>
      );
  }

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${styles.ListItem} ${styles.Workflow}`}>
        <th
          className={`${styles.Name} ${selectedTab === 'closedWorkflows' ? styles.ClosedWorkflowsTab : ''} ${getSortedClass(
            'name',
          )}`}
          onClick={() => sortTable('name')}
        >
          {element.name}
        </th>
        <th className={`${styles.ProcessNumber} ${getSortedClass('processNumber')}`} onClick={() => sortTable('processNumber')}>
          {element.processNumber}
        </th>
        <th className={`${styles.Author} ${getSortedClass('author')}`} onClick={() => sortTable('author')}>
          {element.author}
        </th>
        <th className={`${styles.TypeTask} ${getSortedClass('typeTask')}`} onClick={() => sortTable('typeTask')}>
          {element.typeTask}
        </th>
        {dateColumns}
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={`${styles.ListItem} ${styles.Workflow}`} onClick={() => handleRowClick(element)}>
      <td className={`${styles.Name} ${element.status === Constants.DONE ? styles.ClosedWorkflowsTab : ''}`}>{element.name}</td>
      <td className={styles.ProcessNumber}>{element.processNumber}</td>
      <td className={styles.Author}>{element.author}</td>
      <td className={styles.TypeTask}>{element.typeTask}</td>
      {dateColumns}
    </tr>
  );
};

export default WorkflowsRow;
