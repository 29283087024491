import * as Utils from 'assets/js/Utils';
import { createSandbox as createSandboxService } from 'services/design';
import services from 'services/sandboxService';

const DEFAULT_NAME = 'New Sandbox Draft';

export const loadSandboxs = async (t, diagramId) => {
  let sandboxTree = [];

  return services
    .getSandboxTree()
    .then((response) => {
      sandboxTree = response.data.results.map((draft) => ({
        ...draft,
        selected: draft.id === parseInt(diagramId, 10),
      }));

      return sandboxTree;
    })
    .catch((err) => {
      services.handleServiceError(err);
    });
};

export const loadRecommendedSandboxs = async (t, diagramId) => {
  let sandboxTree = [];

  return services
    .getSandboxRecommendedTree()
    .then((response) => {
      sandboxTree = response.data.results.map((draft) => {
        const author = response.data.allUsers[draft.author.code];

        return {
          ...draft,
          author: author ? `${author?.surname}, ${author?.givenName}, ${author?.departments[0]}` : '',
          date: Utils.getFormattedDate(draft.createdDate),
          time: Utils.getFullFormattedDate(draft.createdDate).split(' - ')[1],
          selected: draft.id === parseInt(diagramId, 10),
        };
      });

      return sandboxTree;
    })
    .catch((err) => {
      services.handleServiceError(err);
    });
};

const getValidName = (tree) => {
  if (!tree || tree.length < 1) {
    return DEFAULT_NAME;
  }

  const defaultDrafts = tree.filter((draft) => draft.name.includes(DEFAULT_NAME));
  let counter = 1;

  for (let i = 0; i < defaultDrafts.length; i++) {
    const count = parseInt(defaultDrafts[i].name.split(' ')[3], 10);

    if (count && count > counter) {
      counter = count;
    }
  }

  return `${DEFAULT_NAME} ${counter + 1}`;
};

export const createSandbox = async (sandboxTree, language, availableLanguages, type) => {
  const diagramDefaultName = getValidName(sandboxTree);
  const name = Object.fromEntries(availableLanguages.map((lang) => [lang, { PROCESS_NAME: diagramDefaultName }]));
  const data = {
    name: diagramDefaultName,
    type,
  };

  return createSandboxService(data)
    .then((response) => {
      const newDraft = {
        name: JSON.stringify(name),
        selected: false,
        id: response.data.id,
      };
      sandboxTree.unshift(newDraft);

      return {
        sandboxTree,
        draftName: name[language].PROCESS_NAME,
        diagramId: newDraft.id,
      };
    })
    .catch((err) => {
      services.handleServiceError(err);
    });
};

export const searchSandbox = (text, tree, lang) =>
  tree.filter((result) => JSON.parse(result.name)[lang].PROCESS_NAME.toLowerCase().includes(text.toLowerCase()));
