import { LinkedDiagram } from './diagram';
import { Attributes, FormLanguage } from './forms';
import { CatalogUser } from './user';

export enum ObjectTypes {
  ROLE = 'ROLE',
  IO_SIPOC = 'IO_SIPOC',
  IO_SWIMLANE = 'IO_SWIMLANE',
  METHOD = 'METHOD',
  KEY_FIGURE = 'KEY_FIGURE',
  MILESTONE = 'MILESTONE',
  RISK = 'RISK',
  IT_SYSTEM = 'IT_SYSTEM',
  IT_SYSTEM_SWIMLANE = 'IT_SYSTEM_SWIMLANE',
  ROLE_SWIMLANE = 'ROLE_SWIMLANE',
}

export enum ObjectIOSubTypes {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export type InputOutputCatalog = {
  approved: boolean;
  approvedBy?: string;
  attributes: Attributes;
  creation?: string;
  diagramIdentifierList: LinkedDiagram[];
  containedIn: LinkedDiagram[];
  id: string;
  requestedBy: string;
  type: string;
  // Header props
  name?: string;
  description?: string;
  responsiblePerson?: string;
  creator?: string;
  status: string;
  qiGroup: string;
} & Partial<CatalogUser>;

export type IOCatalogHeader = {
  creation: string;
  containedIn: string;
  qiGroup?: string;
  department: string;
  status: string;
  creator?: string;
  responsiblePerson?: string;
  name: string;
  description: string;
};

export type SupplierCustomerCatalog = {
  id?: string;
  idProcess: string;
  status: string;
  processName: {
    [key in FormLanguage]: string;
  };
  processResponsible: string;
};

export type Role = {
  id?: number | null; // if temporary role -> null
  type?: string;
  diagramIdentifierList?: LinkedDiagram[];
  attributes?: Attributes;
  approved?: boolean;
  temporaryText?: string;
};

export type ItSystem = {
  id?: number | null; // if temporary it-system -> null
  type?: string;
  diagramIdentifierList?: LinkedDiagram[];
  attributes?: Attributes;
  approved?: boolean;
};
