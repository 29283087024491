import React from 'react';

import {
  AnnotationConstraints,
  Node as SyncfusionNode,
  PortVisibility,
  NodeConstraints,
  UndoRedo,
  Snapping,
  DiagramContextMenu,
  PrintAndExport,
  LineRouting,
  ConnectorEditing,
} from '@syncfusion/ej2-react-diagrams';

import * as Constants from 'assets/constants/constants';
import chevronSVG from 'components/SymbolPalette/ChevronSVG';
import CheckBox from 'components/UI/CheckBox/CheckBox';
import DropdownForm from 'components/UI/Dropdown/DropdownForm';
import InputTextLegacy from 'components/UI/InputText/InputTextLegacy';
import InputUrlLegacy from 'components/UI/InputUrl/InputUrlLegacy';
import InputWysiwygLegacy from 'components/UI/InputWysiwyg/InputWysiwygLegacy';
import Multiselection from 'components/UI/Multiselection/Multiselection';
import TextArea from 'components/UI/TextArea/TextArea';
import { VariantProperty } from 'types/forms';

import * as Utils from './Utils';

export const defaultInjectServices = [UndoRedo, Snapping, DiagramContextMenu, PrintAndExport, LineRouting, ConnectorEditing];

export const getGeneralPalette = (t, language) => {
  const palette = [
    {
      id: Constants.CLOSED_PROCESS_OVERVIEW,
      shape: { type: 'Flow', shape: 'Process' },
      annotations: [{ id: 'labelProcessStep', content: t('diagram.objects.defaultName', { lng: language }) }],
      errorX: 1,
    },
    {
      id: Constants.OPEN_PROCESS_OVERVIEW,
      shape: { type: 'Path', data: 'M0 0 H80 L100 30 L80 60 H0 L20 30 L0 0' },
      annotations: [{ id: 'labelprocessoverview', content: t('diagram.objects.defaultName', { lng: language }) }],
      errorX: 0.9,
    },
    {
      id: Constants.PROCESS_INTERFACE,
      shape: { type: 'Path', data: 'M80 10 L80 40 L10 40 V0 H80 L80 10 H90 L100 30 L90 50 L20 50 L20 40' },
      annotations: [{ id: 'labelprocessinterface', content: t('diagram.objects.defaultName', { lng: language }) }],
      errorX: 0.75,
    },
    {
      id: Constants.PROCESS_STEP,
      shape: { type: 'Flow', shape: 'Process' },
      annotations: [{ id: 'labelProcesOverview', content: t('diagram.objects.defaultName', { lng: language }) }],
      errorX: 1,
    },
    {
      id: Constants.EVENT,
      shape: { type: 'Path', data: 'M0 30 L20 0 H80 L100 30 L80 60 H20 L0 30' },
      annotations: [{ id: 'labelevent', content: t('diagram.objects.defaultName', { lng: language }) }],
      errorX: 0.9,
    },
    {
      id: Constants.AND_GATE,
      shape: { type: 'Basic', shape: 'Ellipse' },
      annotations: [{ id: 'labelAnd', content: 'AND', constraints: AnnotationConstraints.ReadOnly }],
      data: 'AND',
      errorX: 1,
    },
    {
      id: Constants.OR_GATE,
      shape: { type: 'Basic', shape: 'Ellipse' },
      annotations: [{ id: 'labelOr', content: 'OR', constraints: AnnotationConstraints.ReadOnly }],
      data: 'OR',
      errorX: 1,
    },
    {
      id: Constants.XOR_GATE,
      shape: { type: 'Basic', shape: 'Ellipse' },
      annotations: [{ id: 'labelXor', content: 'XOR', constraints: AnnotationConstraints.ReadOnly }],
      data: 'XOR',
      errorX: 1,
    },
    {
      id: Constants.TEXT,
      shape: { type: 'Text', content: t('diagram.objects.text', { lng: language }) },
    },
  ];

  palette.map((symbol) => {
    if (symbol.id !== 'TEXT') {
      symbol.style = { strokeWidth: 2, strokeColor: Constants.SHAPES_COLOR };
      symbol.annotations[0].style = { fontFamily: 'MBCorpoSText-Regular', fontSize: '11', textWrapping: 'WrapWithOverflow' };
      symbol.annotations.push({
        id: 'error-annotation',
        constraints: AnnotationConstraints.ReadOnly,
        content: '',
        style: {
          color: 'red',
          fontFamily: 'DaimlerIconFontRegular',
        },
        offset: {
          x: symbol.errorX,
          y: 0,
        },
        margin: {
          right: 0,
          top: 5,
        },
        horizontalAlignment: 'Right',
        verticalAlignment: 'Top',
        visibility: false,
      });

      symbol.constraints = NodeConstraints.Default & ~(NodeConstraints.Rotate | NodeConstraints.Tooltip);
    } else {
      symbol.style = { strokeWidth: 15, fill: 'none', fontFamily: 'MBCorpoSText-Regular' };
      symbol.annotations = [{}];
      symbol.annotations.push({
        id: 'error-annotation',
        constraints: AnnotationConstraints.ReadOnly,
        content: '',
        style: {
          color: 'red',
          fontFamily: 'DaimlerIconFontRegular',
        },
        offset: {
          x: 1,
          y: 0,
        },
        margin: {
          right: 0,
          top: 5,
        },
        horizontalAlignment: 'Right',
        verticalAlignment: 'Top',
        visibility: false,
      });
      symbol.constraints = NodeConstraints.Default & ~NodeConstraints.Tooltip;
    }

    return symbol;
  });

  return palette;
};

const symbolAnnotationOffsets = [
  { type: Constants.CLOSED_PROCESS_OVERVIEW, linkageX: 0, linkageY: 1 },
  { type: Constants.OPEN_PROCESS_OVERVIEW, linkageX: 0.15, linkageY: 1, linkedDiagramX: 0.9 },
  { type: Constants.PROCESS_INTERFACE, linkageX: 0, linkageY: 0.8 },
  { type: Constants.PROCESS_STEP, linkageX: 0, linkageY: 1 },
  { type: Constants.EVENT, linkageX: 0.2, linkageY: 1 },
];

export const getLinkageAnnotation = (linkage, symbolID) => {
  const symbol = symbolAnnotationOffsets.find((s) => symbolID.indexOf(s.type) === 0);

  return {
    id: `${Constants.LINKAGE_ANNOTATION_ID}_${symbolID}`,
    style: {
      fontFamily: 'DaimlerIconFontRegular',
      fontSize: '14',
    },
    hyperlink: {
      link: linkage,
      content: '',
      color: '#a6cad8',
    },
    offset: {
      x: symbol?.linkageX || 0,
      y: symbol?.linkageY || 1,
    },
    margin: {
      left: 3,
      bottom: -1,
    },
    horizontalAlignment: 'Left',
    verticalAlignment: 'Bottom',
  };
};

export const getDiagramLinkAnnotation = (symbolID = '', iconColor, iconId, diagramLinkChildType) => {
  const symbol = symbolAnnotationOffsets.find((s) => symbolID.indexOf(s.type) === 0);
  return [
    {
      constraints: AnnotationConstraints.ReadOnly,
      id: `linkProcessOverview_${iconId}`,
      content: diagramLinkChildType === Constants.EPC_DIAGRAM ? '' : '',
      style: {
        fontFamily: 'DaimlerIconFontRegular',
        fontSize: '16',
        color: iconColor,
      },
      offset: {
        x: symbol?.linkedDiagramX || 1,
        y: 1,
      },
      margin: {
        bottom: -2,
        right: 0,
      },
      horizontalAlignment: 'Right',
      verticalAlignment: 'Bottom',
    },
  ];
};

export const getCommonHandle = (leftValue = 0, topValue = 0) => ({
  name: 'deleteButtonHandle',
  pathData:
    'M285,256l72.5-84.2c7.9-9.2,6.9-23-2.3-31c-9.2-7.9-23-6.9-30.9,2.3L256,222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3c-9.2,7.9-10.2,21.8-2.3,31L227,256l-72.5,84.2c-7.9,9.2-6.9,23,2.3,31c4.1,3.6,9.2,5.3,14.3,5.3c6.2,0,12.3-2.6,16.6-7.6l68.2-79.2l68.2,79.2c4.3,5,10.5,7.6,16.6,7.6c5.1,0,10.2-1.7,14.3-5.3c9.2-7.9,10.2-21.8,2.3-31L285,256z',
  side: 'Right',
  visible: true,
  offset: 0,
  margin: { top: topValue, bottom: 0, left: leftValue, right: 0 },
  backgroundColor: '#9f1924',
  pathColor: 'white',
});

export const getContextMenuHandle = () => ({
  name: 'contextMenuButtonHandle',
  pathData:
    'M280.71,126.181h-97.822V28.338C182.889,12.711,170.172,0,154.529,0S126.17,12.711,126.17,28.338v97.843H28.359C12.722,126.181,0,138.903,0,154.529c0,15.621,12.717,28.338,28.359,28.338h97.811v97.843c0,15.632,12.711,28.348,28.359,28.348c15.643,0,28.359-12.717,28.359-28.348v-97.843h97.822c15.632,0,28.348-12.717,28.348-28.338C309.059,138.903,296.342,126.181,280.71,126.181z',
  side: 'Right',
  visible: true,
  offset: 0,
  margin: { top: 0, bottom: 0, left: 0, right: 0 },
  backgroundColor: '#007b93',
  pathColor: 'white',
});

export const getJsonList = (list) => (typeof list === 'string' ? list : JSON.stringify(list));

export const createSymbolAttributes = (fieldConfig) => {
  if (fieldConfig && fieldConfig.controls) {
    const attributes = {};
    Object.keys(fieldConfig.controls).forEach((field) => {
      attributes[field] = '';
    });

    return attributes;
  }

  return {};
};

export const createNewSymbolAttributes = (fieldConfig, types, availableLanguages) => {
  if (fieldConfig && fieldConfig.controls) {
    const attributes = Utils.getInitialMultilanguage(availableLanguages);
    Object.keys(fieldConfig.controls).forEach((field) => {
      const { translatable } = types.find((t) => t.code === field);

      if (translatable) {
        availableLanguages.forEach((lang) => {
          attributes[lang][field] = '';
        });
      } else {
        attributes[Constants.NOT_TRANSLATABLE][field] = '';
      }
    });

    return attributes;
  }

  return {};
};

export const createNewSymbolAttributesLegacy = (fieldConfig, types) => {
  if (fieldConfig && fieldConfig.controls) {
    const attributes = Utils.getInitialMultilanguageLegacy();

    Object.keys(fieldConfig.controls).forEach((field) => {
      const { translatable } = types.find((t) => t.code === field);

      if (translatable) {
        attributes[Constants.ENGLISH][field] = '';
        attributes[Constants.GERMAN][field] = '';
      } else {
        attributes[Constants.NOT_TRANSLATABLE][field] = '';
      }
    });

    return attributes;
  }

  return {};
};

export const prepareObjectsUsedDiagram = (objectsList) => {
  const prepareList = [];

  for (let i = 0; i < objectsList?.length; i++) {
    prepareList.push({
      attributes: getJsonList(objectsList[i].attributes),
      id: objectsList[i].id,
      idFront: objectsList[i].idFront,
      idObjectType: objectsList[i].idObjectType,
    });
  }

  return prepareList;
};

export const generateId = (array = [], wordKey) => {
  let id = 0;
  let maxId = 0;

  array.forEach((item) => {
    const prevId = id;
    id = item.id ? parseInt(item.id.split('_')[1], 10) : parseInt(item.split('_')[1], 10);
    id = Number.isNaN(id) ? prevId : id;
    maxId = Math.max(id, maxId);
  });

  return `${wordKey.replace('_', '')}_${maxId + 1}`;
};

export const generateConnectorId = (connectors = []) => {
  let maxId = 0;
  const objectConnectors = connectors.filter((connector) => connector.id.split('_')[1]);

  maxId = objectConnectors.reduce((acc, connector) => Math.max(acc, parseInt(connector.id.split('_')[1], 10)), 0);

  return `connector_${maxId + 1}`;
};

export const connectorNames = ['Arrow'];
export const getConnectors = () =>
  connectorNames.map((name) => ({
    id: name,
    targetDecorator: { shape: name },
    type: 'Orthogonal',
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 40, y: 40 },
    style: { strokeWidth: 2 },
  }));

const connectorColor = '#797979';

export const getConnectorDefaults = (args) => {
  args.targetDecorator.height = 5;
  args.targetDecorator.width = 5;
  args.targetDecorator.style = {
    fill: connectorColor,
    strokeColor: connectorColor,
  };

  if (args.type === 'Orthogonal') {
    args.style.strokeColor = connectorColor;
  }

  return args;
};

export const getConnectorAnnotation = (text) => [
  {
    content: text,
    offset: 0,
    margin: {
      left: 11,
      top: 5,
    },
    style: {
      color: 'black',
      fontFamily: 'MBCorpoSText-Regular',
      fontSize: '10',
    },
  },
];

export const handleDiagramDragEnter = (args) => {
  const obj = args.element;

  if (obj.properties.id.includes(Constants.GATE)) {
    obj.width = Constants.MIN_HEIGHT;
    obj.height = Constants.MIN_HEIGHT;
  } else if (
    obj.properties.id.includes(Constants.PROCESS_OVERVIEW) ||
    obj.properties.id.includes(Constants.EVENT) ||
    obj.properties.id.includes(Constants.PROCESS_STEP) ||
    obj.properties.id.includes(Constants.PROCESS_INTERFACE)
  ) {
    obj.width = Constants.NODE_MIN_WIDTH;
    obj.height = Constants.NODE_MIN_HEIGHT;

    if (obj.properties.id.includes(Constants.EVENT) || obj.properties.id.includes(Constants.PROCESS_INTERFACE)) {
      obj.annotations[0].width = obj.properties.id.includes(Constants.EVENT)
        ? Constants.NODE_MIN_WIDTH - 30
        : Constants.NODE_MIN_WIDTH - Constants.NODE_MIN_WIDTH / 2;

      if (obj.properties.id.includes(Constants.PROCESS_INTERFACE)) {
        obj.annotations[0].margin = { right: Constants.NODE_MIN_WIDTH / 8 };
      }
    }
  } else if (obj instanceof SyncfusionNode) {
    const ratio = Constants.NODE_MIN_WIDTH / obj.width;
    obj.width = Constants.NODE_MIN_WIDTH;
    obj.height *= ratio;
  }
};

const objectIcons = {
  [Constants.ROLE]: '',
  [Constants.INPUT]: '',
  [Constants.OUTPUT]: '',
  [Constants.METHOD]: '',
  [Constants.KEY_FIGURE]: '',
  [Constants.MILESTONE]: '',
  [Constants.RISK]: '',
  [Constants.IT_SYSTEM]: '',
};

const nodeIsObject = (nodeId) => {
  for (let i = 0; i < Constants.OBJECTS.length; i++) {
    if (nodeId.includes(Constants.OBJECTS[i].replace('_', ''))) {
      return true;
    }
  }

  return false;
};

export const getNodeObjectConstraints = (node, onlyRead) => {
  if (!nodeIsObject(node.id)) return;

  return onlyRead
    ? NodeConstraints.Select | NodeConstraints.PointerEvents | NodeConstraints.HideThumbs | NodeConstraints.Drag
    : (NodeConstraints.Default & ~(NodeConstraints.Resize | NodeConstraints.Rotate | NodeConstraints.ReadOnly)) |
        NodeConstraints.HideThumbs;
};

const components = {
  NUMERIC: (props) => <InputTextLegacy {...props} variant={VariantProperty.NUMERIC} />,
  TEXT: (props) => <InputTextLegacy {...props} />,
  DROPDOWN: (props) => <DropdownForm {...props} />,
  WYSIWYG: (props) => <InputWysiwygLegacy {...props} />,
  URL: (props) => <InputUrlLegacy {...props} />,
  PROCESS_ID: (props) => <InputTextLegacy {...props} />,
  DOCUMENT_ID: (props) => <InputTextLegacy {...props} />,
  USER_ID: (props) => <InputTextLegacy {...props} />,
  DATE_TIME: (props) => <InputTextLegacy {...props} />,
  CHECKBOX: CheckBox,
  TEXTAREA: (props) => <TextArea {...props} />,
  MULTISELECTION: (props) => <Multiselection {...props} />,
};
export const getComponent = (idComponent) => components[idComponent] || ((props) => <InputTextLegacy {...props} />);

export const createObjectsFieldConfig = (
  attributeTypes,
  t,
  language,
  types,
  isCatalog,
  responsibilities,
  valueOptionSelected,
  onlyRead,
  nodeData,
  featureFlags,
) => {
  const attributes = typeof attributeTypes === 'string' ? JSON.parse(attributeTypes) : attributeTypes;
  const keys = attributes.map(({ code }) => code);
  let controls = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (key !== 'NAME_REQUESTER' && types[key]) {
      let newControl = {
        render: components[types[key].fieldType] || ((props) => <InputTextLegacy {...props} />),
        meta: {
          label: t(`nameAttributes.${key}`, { lng: language }),
          attributeType: isCatalog || onlyRead ? 'S' : types[key].care,
          disabled: featureFlags.isFreezed,
        },
      };

      if (newControl.render === components.DROPDOWN && (isCatalog || onlyRead)) {
        newControl.render = (props) => <InputTextLegacy {...props} />;
      }

      if (key === 'RESPONSIBILITY') {
        const responsibilitiesList = [...responsibilities].map((elem) => {
          elem.selected = elem.value === valueOptionSelected;

          return elem;
        });

        newControl = {
          render: components.DROPDOWN,
          meta: {
            id: 'rolResponsibilityDropdown',
            label: t(`nameAttributes.${key}`, { lng: language }),
            attributeType: onlyRead ? 'S' : 'M',
            options: responsibilitiesList,
            disabled: featureFlags.isFreezed || onlyRead || types[key].care === 'S',
          },
        };
      }

      if (key === 'OBJECT_DESCRIPTION') {
        newControl.meta.id = 'OBJECT_DESCRIPTION';
        newControl.meta.language = language.toUpperCase();
        newControl.meta.idSymbolFront = nodeData.idSymbolFront;
        newControl.meta.idDiagram = nodeData.idDiagram;
        newControl.meta.version = nodeData.version;
      }

      controls = {
        ...controls,
        [key]: newControl,
      };
    }
  }

  return {
    controls,
  };
};

export const iconsEvent = {
  trash: {
    header: 'warning',
    contentConfirm: {
      diagram: 'diagram.confirmDeleteDiagram',
      functionSelected: 'diagram.confirmDeleteFunction',
    },
    content: {
      diagram: 'diagram.cannotDelete',
      functionSelected: 'diagram.linkedFunction',
    },
    height: 306,
    width: 512,
  },
};

export const createObjectsUsed = (name, idFront, idObjectType, attributesData, availableLanguages) => {
  const attributes = Utils.getInitialMultilanguage(availableLanguages);
  attributesData.forEach(({ code, translatable }) => {
    if (translatable) {
      availableLanguages.forEach((lang) => {
        attributes[lang][code] = '';
      });
    } else {
      attributes[Constants.NOT_TRANSLATABLE][code] = '';
    }
  });
  availableLanguages.forEach((lang) => {
    attributes[lang].OBJECT_NAME = name;
  });

  return {
    attributes,
    id: 0,
    idFront,
    idObjectType,
  };
};

export const createObjectsUsedLegacy = (name, idFront, idObjectType, attr, attributesData) => {
  const attributes = Utils.getInitialMultilanguageLegacy();

  Object.keys(attr).forEach((key) => {
    const field = attributesData.find((el) => el.code === key);

    if (field.translatable) {
      attributes[Constants.ENGLISH][key] = '';
      attributes[Constants.GERMAN][key] = '';
    } else {
      attributes[Constants.NOT_TRANSLATABLE][key] = '';
    }
  });
  attributes[Constants.ENGLISH].OBJECT_NAME = name;
  attributes[Constants.GERMAN].OBJECT_NAME = name;

  return {
    attributes,
    id: 0,
    idFront,
    idObjectType,
  };
};

export const getMaxZIndex = (diagramInstance) => {
  const diagramElements = [...diagramInstance.connectors, ...diagramInstance.nodes];

  return diagramElements.reduce((acc, elem) => Math.max(acc, elem.zIndex), 0) + 1;
};

export const getNewObject = (id, title, objectType, addInfo, offsetX, offsetY, portY, diagramInstance) => {
  let fill = '#FFFFFF';
  let strokeColor = '#E7E7E7';
  const isCatalogObject = Constants.CATALOG_OBJECTS.includes(objectType);

  if (isCatalogObject) {
    strokeColor = '#dfedf2';

    if (objectType === Constants.ROLE) {
      fill = '#DFECF4';
    }
  }

  return {
    id,
    addInfo: objectType === Constants.ROLE || objectType === Constants.IT_SYSTEM ? '' : addInfo,
    annotations: [
      {
        constraints: AnnotationConstraints.ReadOnly,
        content: title,
        style: {
          color: '#51838e',
          fontFamily: 'MBCorpoSText-Regular',
          textAlign: isCatalogObject ? 'Center' : 'Left',
        },
        margin: {
          left: 15,
          right: 5,
        },
      },
      {
        constraints: AnnotationConstraints.ReadOnly,
        content: objectIcons[objectType],
        style: {
          color: Constants.SHAPES_COLOR,
          fontSize: '13',
          fontFamily: 'DaimlerIconFontRegular',
        },
        margin: {
          top: 5,
          left: 3,
        },
        horizontalAlignment: 'Left',
        verticalAlignment: 'Top',
        offset: {
          x: 0,
          y: 0,
        },
      },
    ],
    style: {
      strokeColor,
      strokeWidth: 2,
      fill,
    },
    // Position of the node
    offsetX,
    offsetY,
    // Size of the node
    minWidth: 90,
    minHeight: Constants.NODE_MIN_HEIGHT,
    ports: [
      {
        // Create port id
        id: 'objectPort',
        offset: {
          x: 0.5,
          y: portY,
        },
        visibility: PortVisibility.Hidden,
      },
    ],
    constraints:
      (NodeConstraints.Default & ~(NodeConstraints.Resize | NodeConstraints.Rotate | NodeConstraints.ReadOnly)) |
      NodeConstraints.HideThumbs,
    zIndex: getMaxZIndex(diagramInstance),
  };
};

export const modifyAnnotationWrap = (diagramInstance) => {
  diagramInstance.nodes.forEach((node) => {
    if (node.annotations.length > 0) {
      node.annotations[0].style.textWrapping = 'WrapWithOverflow';
    }
  });
  diagramInstance.dataBind();
};

export const getXYObjectPosition = (position, y, offset) => {
  const objectPosition = {
    TOP_RIGHT: {
      symbolPortOffsetX: 1,
      symbolPortOffsetY: 0.3,
      objectPortOffsetY: 1,
      objectOffsetX: offset.offsetX || offset,
      objectOffsetY: offset.offsetY || y - Constants.OBJECT_OFFSET_Y,
    },
    TOP_LEFT: {
      symbolPortOffsetX: 0,
      symbolPortOffsetY: 0.3,
      objectPortOffsetY: 1,
      objectOffsetX: offset,
      objectOffsetY: y - Constants.OBJECT_OFFSET_Y,
    },
    BOTTOM_RIGHT: {
      symbolPortOffsetX: 1,
      symbolPortOffsetY: 0.7,
      objectPortOffsetY: 0,
      objectOffsetX: offset,
      objectOffsetY: y + Constants.OBJECT_OFFSET_Y,
    },
    DEFAULT: {
      symbolPortOffsetX: 0,
      symbolPortOffsetY: 0.7,
      objectPortOffsetY: 0,
      objectOffsetX: offset,
      objectOffsetY: y + Constants.OBJECT_OFFSET_Y,
    },
  };

  return objectPosition[position] || objectPosition.DEFAULT;
};

const shapesToDraw = {
  square: { type: 'Basic', shape: 'Rectangle' },
  circle: { type: 'Basic', shape: 'Ellipse' },
  diamond: { type: 'Basic', shape: 'Diamond' },
  triangle: { type: 'Basic', shape: 'Triangle' },
  chevron: { type: 'Native', content: chevronSVG },
};
const shapeStyles = {
  fill: 'white',
  strokeColor: Constants.SHAPES_COLOR,
  strokeWidth: 2,
};

export const getShapeToDraw = (tool) => ({
  annotations: [
    {
      content: '',
      style: {
        color: Constants.BLACK_COLOR,
      },
    },
  ],
  shape: shapesToDraw[tool] || shapesToDraw.square,
  style: shapeStyles,
});

export const getLineToDraw = () => ({
  type: 'Straight',
  targetDecorator: { shape: 'None' },
  style: {
    strokeColor: Constants.SHAPES_COLOR,
    strokeWidth: 3,
  },
});
export const QI = 'QI';
export const MODELER = 'MODELER';
export const AUTHOR = 'AUTHOR';
export const APPROVER = 'APPROVER';
export const APPROVERS = 'APPROVERS';
export const SUBSCRIBERS = 'SUBSCRIBERS';
export const METHOD_OWNER = 'METHOD_OWNER';
export const SCOPES = 'SCOPES';
export const QA_INSPECTOR_GROUP = 'QA_INSPECTOR_GROUP';
export const QA_INSPECTOR_GROUP_VCD = 'QA_INSPECTOR_GROUP_VCD';
export const MONTHS_VALIDITY = 'MONTHS_VALIDITY';

export const multiselectFields = [MODELER, AUTHOR, APPROVER, METHOD_OWNER];

export const CURRENT = 'CURRENT';
export const DONE = 'DONE';
export const COMPLETED = 'COMPLETED';

export const LAST_EDITOR_CODE = 'LAST_EDITOR_CODE';
export const CREATOR_CODE = 'CREATOR_CODE';
export const DATE_TIME = 'DATE_TIME';
export const BRING_TO_FRONT = 'BRINGTOFRONT';
export const SEND_TO_BACK = 'SENDTOBACK';

export const scrollSettings = {
  canAutoScroll: false,
  scrollLimit: 'Infinity',
  padding: {
    left: 20,
    right: 20,
    top: 0,
    bottom: 70,
  },
  minZoom: 0,
  width: '100%',
};

export const getOrderContextMenu = (t) => ({
  showCustomMenuOnly: true,
  show: true,
  items: [
    {
      text: t('bringToFront'),
      id: Constants.BRING_TO_FRONT,
      iconCss: 'di icon-in-den-hintergrund',
    },
    {
      text: t('sendToBack'),
      id: Constants.SEND_TO_BACK,
      iconCss: 'di icon-in-den-vordergrund',
    },
  ],
});

const qiOptions = [
  {
    code: Constants.LINK_VCD_EPC_ID,
    iconCss: 'di icon-link',
  },
  {
    code: Constants.IMPORT,
    iconCss: 'di icon-herunterladen',
  },
  {
    code: Constants.MOVE_EPC_ID,
    iconCss: 'di icon-herunterladen',
  },
];

export const getQIOptions = (t, availableLanguages) =>
  qiOptions.map((option) => ({
    text: availableLanguages.map((language) => ({ [language]: t(`contextualMenu.${option.code}`, { lng: language }) })),
    id: option.code,
    target: '.e-elementcontent',
    iconCss: option.iconCss,
  }));

export const getQIOptionsLegacy = (t) => [
  {
    text: {
      [Constants.ENGLISH]: t(`contextualMenu.${Constants.LINK_VCD_EPC_ID}`, { lng: Constants.ENGLISH }),
      [Constants.GERMAN]: t(`contextualMenu.${Constants.LINK_VCD_EPC_ID}`, { lng: Constants.GERMAN }),
    },
    id: Constants.LINK_VCD_EPC_ID,
    target: '.e-elementcontent',
    iconCss: 'di icon-link',
  },
  {
    text: {
      [Constants.ENGLISH]: t(`contextualMenu.${Constants.IMPORT}`, { lng: Constants.ENGLISH }),
      [Constants.GERMAN]: t(`contextualMenu.${Constants.IMPORT}`, { lng: Constants.GERMAN }),
    },
    id: Constants.IMPORT,
    target: '.e-elementcontent',
    iconCss: 'di icon-herunterladen',
  },
  {
    text: {
      [Constants.ENGLISH]: t(`contextualMenu.${Constants.MOVE_EPC_ID}`, { lng: Constants.ENGLISH }),
      [Constants.GERMAN]: t(`contextualMenu.${Constants.MOVE_EPC_ID}`, { lng: Constants.GERMAN }),
    },
    id: Constants.MOVE_EPC_ID,
    target: '.e-elementcontent',
    iconCss: 'di icon-herunterladen',
  },
];

export const getAdministratorOptions = (t, availableLanguages) => [
  {
    text: availableLanguages.map((language) => ({ [language]: t(`contextualMenu.${Constants.MOVE_VCD_ID}`, { lng: language }) })),
    id: Constants.MOVE_VCD_ID,
    target: '.e-elementcontent',
    iconCss: 'di icon-herunterladen',
  },
];

export const getAdministratorOptionsLegacy = (t) => [
  {
    text: {
      [Constants.ENGLISH]: t(`contextualMenu.${Constants.MOVE_VCD_ID}`, { lng: Constants.ENGLISH }),
      [Constants.GERMAN]: t(`contextualMenu.${Constants.MOVE_VCD_ID}`, { lng: Constants.GERMAN }),
    },
    id: Constants.MOVE_VCD_ID,
    target: '.e-elementcontent',
    iconCss: 'di icon-herunterladen',
  },
];

export const getContextMenuOptions = (t) => [
  {
    code: Constants.PROCESS_STEP,
    idSymbol: 3,
    options: [
      {
        catalog: true,
        code: Constants.ROLE,
        connectionType: Constants.SIMPLE_LINE,
        description: '',
        icon: 'di icon-person',
        idOption: 1,
        name: t(`contextualMenu.${Constants.ROLE}`),
      },
      {
        catalog: false,
        code: Constants.INPUT,
        connectionType: Constants.SIMPLE_ARROW,
        description: '',
        icon: 'di icon-herunterladen',
        idOption: 2,
        name: t(`contextualMenu.${Constants.INPUT}`),
      },
      {
        catalog: false,
        code: Constants.OUTPUT,
        connectionType: Constants.INCOMING_ARROW,
        description: '',
        icon: 'di icon-hochladen',
        idOption: 3,
        name: t(`contextualMenu.${Constants.OUTPUT}`),
      },
      {
        catalog: false,
        code: Constants.METHOD,
        connectionType: Constants.SIMPLE_ARROW,
        description: '',
        icon: 'di icon-schraubenschluessel-einstellungen',
        idOption: 4,
        name: t(`contextualMenu.${Constants.METHOD}`),
      },
      {
        catalog: false,
        code: Constants.KEY_FIGURE,
        connectionType: Constants.SIMPLE_LINE,
        description: '',
        icon: 'di icon-diagramm-balken-kurve',
        idOption: 5,
        name: t(`contextualMenu.${Constants.KEY_FIGURE}`),
      },
      {
        catalog: false,
        code: Constants.MILESTONE,
        connectionType: Constants.SIMPLE_LINE,
        description: '',
        icon: 'di icon-form-quadrat',
        idOption: 6,
        name: t(`contextualMenu.${Constants.MILESTONE}`),
      },
      {
        catalog: false,
        code: Constants.RISK,
        connectionType: Constants.SIMPLE_LINE,
        description: '',
        icon: 'di icon-blitz-fehler',
        idOption: 7,
        name: t(`contextualMenu.${Constants.RISK}`),
      },
      {
        catalog: true,
        code: Constants.IT_SYSTEM,
        connectionType: Constants.SIMPLE_LINE,
        description: '',
        icon: 'di icon-computer-laptop',
        idOption: 8,
        name: t(`contextualMenu.${Constants.IT_SYSTEM}`),
      },
    ],
  },
  {
    code: Constants.OPEN_PROCESS_OVERVIEW,
    idSymbol: 2,
    options: [
      {
        code: Constants.NEW_VCD_ID,
        description: 'New VCD option',
        icon: 'di icon-plus-hinzufuegen-klein',
        idOption: 1,
        name: t(`contextualMenu.${Constants.NEW_VCD_ID}`),
      },
      {
        code: Constants.NEW_EPC_ID,
        description: 'New EPC option',
        icon: 'di icon-plus-hinzufuegen-klein',
        idOption: 2,
        name: t(`contextualMenu.${Constants.NEW_EPC_ID}`),
      },
    ],
  },
  {
    code: Constants.CLOSED_PROCESS_OVERVIEW,
    idSymbol: 1,
    options: [
      {
        code: Constants.NEW_VCD_ID,
        description: 'New VCD option',
        icon: 'di icon-plus-hinzufuegen-klein',
        idOption: 1,
        name: t(`contextualMenu.${Constants.NEW_VCD_ID}`),
      },
      {
        code: Constants.NEW_EPC_ID,
        description: 'New EPC option',
        icon: 'di icon-plus-hinzufuegen-klein',
        idOption: 2,
        name: t(`contextualMenu.${Constants.NEW_EPC_ID}`),
      },
    ],
  },
  {
    code: Constants.PROCESS_INTERFACE,
    idSymbol: 4,
    options: [
      {
        code: Constants.LINK_EPC_ID,
        icon: 'di icon-link',
        idOption: 1,
        name: t(`contextualMenu.${Constants.LINK_EPC_ID}`),
      },
    ],
  },
];

const neposOptions = [
  {
    code: Constants.NEW_NEPOS_SIPOC,
    iconCss: 'di icon-plus-hinzufuegen-klein',
  },
  {
    code: Constants.NEW_NEPOS_SWIMLANE,
    iconCss: 'di icon-plus-hinzufuegen-klein',
  },
];

export const getNeposOptions = (t, availableLanguages, featureFlags, currentLevel) => {
  let filteredOptions = neposOptions;
  if (!featureFlags.isCreateSwimlaneEnabled || currentLevel !== 2) {
    filteredOptions = filteredOptions.filter((option) => option.code !== Constants.NEW_NEPOS_SWIMLANE);
  }

  if (!featureFlags.isCreateSIPOCEnabled || currentLevel > 1) {
    filteredOptions = filteredOptions.filter((option) => option.code !== Constants.NEW_NEPOS_SIPOC);
  }

  filteredOptions = filteredOptions.map((option) => ({
    text: availableLanguages.map((language) => ({ [language]: t(`contextualMenu.${option.code}`, { lng: language }) })),
    id: option.code,
    target: '.e-elementcontent',
    iconCss: option.iconCss,
  }));

  return filteredOptions;
};

export const getFirstObject = (objects, port) => {
  let firstObject = '';

  if (port === Constants.TOP_LEFT || port === Constants.BOTTOM_LEFT) {
    firstObject = objects.reduce((acc, node) => {
      if (acc.offsetX < node.offsetX) {
        return node;
      }

      return acc;
    });
  } else {
    firstObject = objects.reduce((acc, node) => {
      if (acc.offsetX > node.offsetX || (acc.offsetX === node.offsetX && acc.actualSize.width < node.actualSize.width)) {
        return node;
      }

      return acc;
    });
  }

  return firstObject;
};

export const hasPositionDownRight = (id) => {
  return (
    id.includes(Constants.KEY_FIGURE.replace('_', '')) ||
    id.includes(Constants.MILESTONE) ||
    id.includes(Constants.OUTPUT) ||
    id.includes(Constants.RISK)
  );
};

export const hasPositionDownLeft = (id) => {
  return id.includes(Constants.METHOD) || id.includes(Constants.INPUT);
};

export const getSymbolObjectsByPort = (symbolObjects, port) => {
  let objects = [];

  if (port === Constants.TOP_LEFT) {
    objects = symbolObjects.filter((elem) => elem.id.includes(Constants.IT_SYSTEM.replace('_', '')));
  } else if (port === Constants.BOTTOM_LEFT) {
    objects = symbolObjects.filter((elem) => hasPositionDownLeft(elem.id));
  } else if (port === Constants.TOP_RIGHT) {
    objects = symbolObjects.filter((elem) => elem.id.includes(Constants.ROLE));
  } else {
    objects = symbolObjects.filter((elem) => hasPositionDownRight(elem.id));
  }

  return objects;
};

export const getLastObject = (objects) => {
  const lastObject = objects.reduce((acc, node) => {
    if (acc.offsetX < node.offsetX || (acc.offsetX === node.offsetX && acc.actualSize.width < node.actualSize.width)) {
      return node;
    }

    return acc;
  });

  return lastObject;
};

export const getSymbolId = (objectId) => {
  let objectType = '';

  if (objectId.includes(Constants.IT_SYSTEM.replace('_', ''))) {
    objectType = Constants.IT_SYSTEM;
  } else if (objectId.includes(Constants.ROLE)) {
    objectType = Constants.ROLE;
  } else if (objectId.includes(Constants.INPUT)) {
    objectType = Constants.INPUT;
  } else if (objectId.includes(Constants.METHOD)) {
    objectType = Constants.METHOD;
  } else if (objectId.includes(Constants.OUTPUT)) {
    objectType = Constants.OUTPUT;
  } else if (objectId.includes(Constants.KEY_FIGURE.replace('_', ''))) {
    objectType = Constants.KEY_FIGURE;
  } else if (objectId.includes(Constants.MILESTONE)) {
    objectType = Constants.MILESTONE;
  } else if (objectId.includes(Constants.RISK)) {
    objectType = Constants.RISK;
  }

  return objectId.substring(6).split(objectType.replace('_', ''))[0];
};

export const isObjectFromProcessStep = (objectId, symbolId) => getSymbolId(objectId) === symbolId?.replace('_', '');

export const getExistingCategoryRolePosition = (diagramInstance, roleResponsibility, rolesFiltered, symbolNode) => {
  const objectNode = diagramInstance.nodes.find(
    (elem) =>
      isObjectFromProcessStep(elem.id, symbolNode.id) &&
      elem.id.split('_')[2] &&
      parseInt(elem.id.split('_')[2], 10) === roleResponsibility,
  );
  let higherResponsibilityRole = null;

  diagramInstance.nodes.forEach((node) => {
    const role = rolesFiltered.find(
      (elem) =>
        isObjectFromProcessStep(node.id, symbolNode.id) &&
        ((node.id.split('_')[2] &&
          Number(node.id.split('_')[2]) === elem.rolResponsability &&
          Number(node.id.split('_')[1]) === elem.idObjectCatalog) ||
          node.id === elem.id),
    );

    if (role && (!higherResponsibilityRole || node.offsetY < higherResponsibilityRole?.offsetY)) higherResponsibilityRole = node;
  });

  const result = {
    offsetX: objectNode.offsetX,
    offsetY: higherResponsibilityRole.offsetY - higherResponsibilityRole.actualSize.height / 2 - Constants.OBJECT_OFFSET_Y,
  };

  return result;
};

export const getNewCategoryRolePosition = (diagramInstance, symbolId) => {
  const symbolRoles = diagramInstance.nodes.filter(
    (node) => isObjectFromProcessStep(node.id, symbolId) && node.id.includes(Constants.ROLE),
  );
  let offset = 0;

  if (symbolRoles?.length > 0) {
    const lastRole = getLastObject(symbolRoles);

    offset = lastRole.offsetX + lastRole.actualSize.width / 2 + Constants.MAX_WIDTH / 2 + Constants.OFFSET_OBJECT;
  }

  return offset;
};

export const updateRolesOffsetX = (diagramInstance, symbolNode) => {
  const symbolRoles = diagramInstance.nodes.filter(
    (role) => isObjectFromProcessStep(role.id, symbolNode.id) && role.id.includes(Constants.ROLE),
  );

  if (symbolRoles.length > 0) {
    symbolRoles.forEach((role) => {
      role.offsetX += Constants.MAX_WIDTH + Constants.OFFSET_OBJECT;
    });
  }

  return symbolNode.offsetX + symbolNode.actualSize.width / 2 + Constants.ADDED_OFFSET_X;
};

export const getNewObjectOffsetX = (diagramInstance, symbolNode, position) => {
  const symbolObjects = diagramInstance.nodes.filter((elem) => isObjectFromProcessStep(elem.id, symbolNode.id));
  const objects = getSymbolObjectsByPort(symbolObjects, position);
  const lastObject = objects.length > 0 ? objects[objects.length - 1] : '';
  let offsetNewObject =
    position === Constants.BOTTOM_RIGHT
      ? symbolNode.offsetX + symbolNode.actualSize.width / 2 + Constants.OFFSET_FIRST_LONG_OBJECT
      : symbolNode.offsetX - symbolNode.actualSize.width / 2 - Constants.OFFSET_FIRST_LONG_OBJECT;

  if (lastObject) {
    const signo = hasPositionDownRight(lastObject.id) ? 1 : -1;
    offsetNewObject =
      lastObject.offsetX +
      signo * (lastObject.actualSize.width / 2) +
      signo * (Constants.MAX_WIDTH / 2) +
      signo * Constants.OFFSET_OBJECT;
  }

  return offsetNewObject;
};

export const updateObjectsBehindPosition = (objects, object, hasPortLeft, isRole) => {
  const objectsBehind = hasPortLeft
    ? objects.filter((elem) => elem.offsetX > object.offsetX)
    : objects.filter((elem) => elem.offsetX < object.offsetX);
  const objectIndex = objects.findIndex((node) => node.id === object.id);
  const prevOffset = object.offsetX;
  let objectBehind = objectIndex > 0 && objects[objectIndex - 1];
  let biggerRoleSameResponsibility = object;
  let rolesSameResponsibility = [];
  let rolesBehindSameResponsibility = [];

  if (objectBehind) {
    if (isRole && object.id.split('_')[2] !== objectBehind.id.split('_')[2]) {
      rolesSameResponsibility = objects.filter((roleNode) => roleNode.id.split('_')[2] === object.id.split('_')[2]);
      rolesBehindSameResponsibility = objects.filter((roleNode) => roleNode.id.split('_')[2] === objectBehind.id.split('_')[2]);

      biggerRoleSameResponsibility = rolesSameResponsibility.reduce((node, acc) => {
        if (node.actualSize.width > acc.actualSize.width) return node;

        return acc;
      });

      objectBehind = rolesBehindSameResponsibility.reduce((node, acc) => {
        if (node.actualSize.width > acc.actualSize.width) return node;

        return acc;
      });
    }

    if (hasPortLeft) {
      if (
        object.offsetX + object.actualSize.width / 2 !==
        objectBehind.offsetX - objectBehind.actualSize.width / 2 - Constants.OFFSET_OBJECT
      ) {
        object.offsetX =
          objectBehind.offsetX - objectBehind.actualSize.width / 2 - object.actualSize.width / 2 - Constants.OFFSET_OBJECT;
      }
    } else if (
      (!isRole &&
        object.offsetX - object.actualSize.width / 2 !==
          objectBehind.offsetX + objectBehind.actualSize.width / 2 + Constants.OFFSET_OBJECT) ||
      (isRole &&
        object.id.split('_')[2] !== objectBehind.id.split('_')[2] &&
        biggerRoleSameResponsibility.offsetX - biggerRoleSameResponsibility.actualSize.width / 2 !==
          objectBehind.offsetX + objectBehind.actualSize.width / 2 + Constants.OFFSET_OBJECT)
    ) {
      const objectNode = isRole ? biggerRoleSameResponsibility : objectBehind;
      object.offsetX =
        objectNode.offsetX + objectNode.actualSize.width / 2 + object.actualSize.width / 2 + Constants.OFFSET_OBJECT;

      if (isRole) {
        rolesSameResponsibility.forEach((roleNode) => {
          roleNode.offsetX = object.offsetX;
        });
      }
    }

    if (object.offsetX !== prevOffset) {
      objectsBehind.forEach((node) => {
        if ((hasPortLeft && node.offsetX < object.offsetX) || (!hasPortLeft && node.offsetX > object.offsetX)) {
          const offsetDiff = hasPortLeft ? prevOffset - object.offsetX : object.offsetX - prevOffset;
          node.offsetX += offsetDiff;
        }
      });
    }
  }
};

export const updateObjectsInFrontPosition = (objects, object, hasPortLeft, isRole) => {
  const objectsInFront = hasPortLeft
    ? objects.filter((elem) => elem.offsetX < object.offsetX)
    : objects.filter((elem) => elem.offsetX > object.offsetX);
  const objectIndex = objects.findIndex((node) => node.id === object.id);
  let objectInFront = objectIndex + 1 <= objects.length && objects[objectIndex + 1];
  const prevOffset = objectInFront?.offsetX;
  let biggerRoleSameResponsibility = object;
  let rolesSameResponsibility = [];
  let rolesInFrontSameResponsibility = [];

  if (objectInFront) {
    if (isRole && object.id.split('_')[2] !== objectInFront.id.split('_')[2]) {
      rolesSameResponsibility = objects.filter((roleNode) => roleNode.id.split('_')[2] === object.id.split('_')[2]);
      rolesInFrontSameResponsibility = objects.filter((roleNode) => roleNode.id.split('_')[2] === objectInFront.id.split('_')[2]);

      biggerRoleSameResponsibility = rolesSameResponsibility.reduce((node, acc) => {
        if (node.actualSize.width > acc.actualSize.width) return node;

        return acc;
      });

      objectInFront = rolesInFrontSameResponsibility.reduce((node, acc) => {
        if (node.actualSize.width > acc.actualSize.width) return node;

        return acc;
      });
    }

    if (hasPortLeft) {
      if (
        object.offsetX - object.actualSize.width / 2 !==
        objectInFront.offsetX + objectInFront.actualSize.width / 2 + Constants.OFFSET_OBJECT
      ) {
        objectInFront.offsetX =
          object.offsetX - object.actualSize.width / 2 - objectInFront.actualSize.width / 2 - Constants.OFFSET_OBJECT;
      }
    } else if (
      (!isRole &&
        object.offsetX + object.actualSize.width / 2 !==
          objectInFront.offsetX - objectInFront.actualSize.width / 2 - Constants.OFFSET_OBJECT) ||
      (isRole &&
        object.id.split('_')[2] !== objectInFront.id.split('_')[2] &&
        biggerRoleSameResponsibility.offsetX + biggerRoleSameResponsibility.actualSize.width / 2 !==
          objectInFront.offsetX - objectInFront.actualSize.width / 2 - Constants.OFFSET_OBJECT)
    ) {
      const objectNode = isRole ? biggerRoleSameResponsibility : object;
      objectInFront.offsetX =
        objectNode.offsetX + objectNode.actualSize.width / 2 + objectInFront.actualSize.width / 2 + Constants.OFFSET_OBJECT;

      if (isRole) {
        rolesInFrontSameResponsibility.forEach((roleNode) => {
          roleNode.offsetX = objectInFront.offsetX;
        });
      }
    }

    if (objectInFront.offsetX !== prevOffset) {
      objectsInFront.forEach((node) => {
        if ((hasPortLeft && node.offsetX < objectInFront.offsetX) || (!hasPortLeft && node.offsetX > objectInFront.offsetX)) {
          const offsetDiff = hasPortLeft ? prevOffset - objectInFront.offsetX : objectInFront.offsetX - prevOffset;
          node.offsetX += offsetDiff;
        }
      });
    }
  }
};

export const updateObjectsPositionByPort = (port, objects, symbol) => {
  objects.forEach((object, index) => {
    const hasPortLeft = port === Constants.BOTTOM_LEFT || port === Constants.TOP_LEFT;
    const isRole = object.id.includes(Constants.ROLE);

    if (index === 0) {
      const prevOffset = object.offsetX;

      if (
        hasPortLeft &&
        object.offsetX + object.actualSize.width / 2 !==
          symbol.offsetX - symbol.actualSize.width / 2 - Constants.OFFSET_FIRST_LONG_OBJECT
      ) {
        object.offsetX =
          symbol.offsetX - symbol.actualSize.width / 2 - object.actualSize.width / 2 - Constants.OFFSET_FIRST_LONG_OBJECT;
      } else if (
        !hasPortLeft &&
        object.offsetX - object.actualSize.width / 2 !==
          symbol.offsetX + symbol.actualSize.width / 2 + Constants.OFFSET_FIRST_LONG_OBJECT
      ) {
        object.offsetX =
          symbol.offsetX + symbol.actualSize.width / 2 + object.actualSize.width / 2 + Constants.OFFSET_FIRST_LONG_OBJECT;
      }

      if (prevOffset && prevOffset !== object.offsetX) {
        objects.forEach((node) => {
          if (node.id !== object.id) {
            const offsetDiff = hasPortLeft ? prevOffset - object.offsetX : object.offsetX - prevOffset;
            node.offsetX += offsetDiff;
          }
        });
      }
    } else {
      updateObjectsBehindPosition(objects, object, hasPortLeft, isRole);
    }

    updateObjectsInFrontPosition(objects, object, hasPortLeft, isRole);
  });
};

export const updateDiagramObjectsPosition = (diagramInstance, symbols) => {
  const symbolNodes = diagramInstance.nodes.filter(
    (node) => node.id.includes(Constants.PROCESS_STEP) && !node.id.includes(Constants.OBJECT),
  );

  symbolNodes.forEach((symbolNode) => {
    const symbol = symbols.find((elem) => symbolNode.id === elem.idSymbolFront);
    const symbolObjects = diagramInstance.nodes.filter((elem) => isObjectFromProcessStep(elem.id, symbol?.idSymbolFront));

    if (symbol?.objectsCatalogRelations) {
      const objectsTopRight = symbolObjects.filter((elem) => elem.id.includes(Constants.ROLE));

      if (objectsTopRight?.length > 0) {
        const sortedRoles = objectsTopRight.sort((a, b) => {
          return a.offsetX < b.offsetX ? -1 : 1;
        });
        updateObjectsPositionByPort(Constants.TOP_RIGHT, sortedRoles, symbolNode);
      }

      let objectsTopLeft = symbolObjects.filter((elem) => elem.id.includes(Constants.IT_SYSTEM.replace('_', '')));

      if (objectsTopLeft?.length > 0) {
        objectsTopLeft = objectsTopLeft.sort((a, b) => {
          return a.offsetX < b.offsetX ? 1 : -1;
        });
        updateObjectsPositionByPort(Constants.TOP_LEFT, objectsTopLeft, symbolNode);
      }
    }

    if (symbol?.objectsDiagram) {
      let objectsDownRight = symbolObjects.filter((elem) => hasPositionDownRight(elem.id));

      if (objectsDownRight?.length > 0) {
        objectsDownRight = objectsDownRight.sort((a, b) => {
          return a.offsetX < b.offsetX ? -1 : 1;
        });
        updateObjectsPositionByPort(Constants.BOTTOM_RIGHT, objectsDownRight, symbolNode);
      }

      let objectsDownLeft = symbolObjects.filter((elem) => hasPositionDownLeft(elem.id));

      if (objectsDownLeft?.length > 0) {
        objectsDownLeft = objectsDownLeft.sort((a, b) => {
          return a.offsetX < b.offsetX ? 1 : -1;
        });
        updateObjectsPositionByPort(Constants.BOTTOM_LEFT, objectsDownLeft, symbolNode);
      }
    }
  });
};

export const updateLongObjectsPosition = (diagramInstance, position, symbolNode) => {
  const symbolObjects = diagramInstance.nodes.filter((elem) => isObjectFromProcessStep(elem.id, symbolNode.id));
  let objects = getSymbolObjectsByPort(symbolObjects, position);

  if (position === Constants.TOP_RIGHT || objects.length === 1) {
    if (position === Constants.TOP_RIGHT) {
      objects = objects.sort((a, b) => {
        return a.offsetX < b.offsetX ? -1 : 1;
      });
    }

    updateObjectsPositionByPort(position, objects, symbolNode);
  } else if (objects.length > 1) {
    const lastObject = diagramInstance.nodes[diagramInstance.nodes.length - 1];

    if (position === Constants.BOTTOM_RIGHT) {
      lastObject.offsetX += (lastObject.actualSize.width - Constants.MAX_WIDTH) / 2;
    } else {
      lastObject.offsetX -= (lastObject.actualSize.width - Constants.MAX_WIDTH) / 2;
    }
  }
};

export const updateObjectsPosition = (objectDeleted, diagramInstance) => {
  const symbolId = objectDeleted.id.includes(Constants.OUTPUT)
    ? diagramInstance.connectors.find((elem) => elem.targetID === objectDeleted.id).sourceID
    : diagramInstance.connectors.find((elem) => elem.sourceID === objectDeleted.id)?.targetID;
  const symbolObjects = diagramInstance.nodes.filter(
    (node) => isObjectFromProcessStep(node.id, symbolId) && node.id !== objectDeleted.id,
  );
  let port = Constants.TOP_LEFT;

  if (objectDeleted.id.includes(Constants.ROLE)) {
    port = Constants.TOP_RIGHT;
  } else if (hasPositionDownRight(objectDeleted.id)) {
    port = Constants.BOTTOM_RIGHT;
  } else if (hasPositionDownLeft(objectDeleted.id)) {
    port = Constants.BOTTOM_LEFT;
  }

  let objects = getSymbolObjectsByPort(symbolObjects, port);
  const connectors = [];

  if (port === Constants.TOP_RIGHT) {
    objects = objects.sort((a, b) => {
      return a.offsetX < b.offsetX ? -1 : 1;
    });
  }

  objects.forEach((object) => {
    const connector = object.id.includes(Constants.OUTPUT)
      ? diagramInstance.connectors.find((elem) => elem.targetID === object.id && elem.sourceID === symbolId)
      : diagramInstance.connectors.find((elem) => elem.sourceID === object.id && elem.targetID === symbolId);
    connectors.push(connector);
    diagramInstance.remove(connector);
    diagramInstance.remove(object);
  });

  const symbolNode = diagramInstance.nodes.find((node) => node.id === symbolId);
  diagramInstance.remove(objectDeleted);
  let offset = 0;

  for (let i = 0; i < objects.length; i++) {
    const nodeOffsetY =
      port === Constants.TOP_LEFT || port === Constants.TOP_RIGHT
        ? symbolNode.offsetY - symbolNode.height / 2 - objects[i].actualSize.height / 2
        : symbolNode.offsetY + symbolNode.height / 2 + objects[i].actualSize.height / 2;

    if (port === Constants.TOP_RIGHT) {
      const roleResponsibility = parseInt(objects[i].id.split('_')[2], 10);
      const rolesFiltered = diagramInstance.nodes.filter(
        (node) => isObjectFromProcessStep(node.id, symbolId) && parseInt(node.id.split('_')[2], 10) === roleResponsibility,
      );

      if (i === 0) {
        offset = symbolNode.offsetX + symbolNode.actualSize.width / 2 + Constants.ADDED_OFFSET_X;
      } else if (rolesFiltered.length > 0) {
        offset = getExistingCategoryRolePosition(diagramInstance, roleResponsibility, rolesFiltered, symbolNode);
        offset.offsetY -= objects[i].actualSize.height / 2;
      } else {
        offset = getNewCategoryRolePosition(diagramInstance, symbolNode.id);
      }
    } else {
      offset = getNewObjectOffsetX(diagramInstance, symbolNode, port);
    }

    const { objectOffsetX, objectOffsetY } = getXYObjectPosition(port, nodeOffsetY, offset);
    objects[i].offsetX = objectOffsetX;
    objects[i].offsetY = objectOffsetY;
    diagramInstance.addNode(objects[i]);
    diagramInstance.addConnector(connectors[i]);
    updateLongObjectsPosition(diagramInstance, port, symbolNode);
    diagramInstance.dataBind();
  }
};

export const isStepWithObjects = (nodes, symbols) => {
  let stepWithObjects = false;

  nodes.forEach((node) => {
    if (node?.id.includes(Constants.PROCESS_STEP)) {
      const symbol = symbols.find((s) => s.idSymbolFront === node.id);

      if (symbol.objectsCatalogRelations?.length > 0 || symbol.objectsDiagram?.length > 0) {
        stepWithObjects = true;
      }
    }
  });

  return stepWithObjects;
};

export const getDiagramType = (id) => (id === Constants.EPC_DIAGRAM_ID ? Constants.EPC_DIAGRAM : Constants.VCD_DIAGRAM);

export const isWorkflow = (diagram) => diagram?.status === Constants.STATUS.WORKFLOW && !diagram.catalogObjectsMerge;

export const prepareWysiwygAttributes = (attributes, formTypes) => {
  if (!attributes) return null;
  const attributesParsed = Utils.getObjectAttributes(attributes);
  const languages = Object.keys(attributesParsed);

  for (let i = 0; i < languages.length; i++) {
    const lang = languages[i];
    const keys = Object.keys(attributesParsed[lang]);

    for (let j = 0; j < keys.length; j++) {
      attributesParsed[lang][keys[j]] = Utils.checkWysiwygCharacters(formTypes[lang][keys[j]], attributesParsed[lang][keys[j]]);
    }
  }

  return attributesParsed;
};

export const saveObjectsUsedDiagram = (objectsList) =>
  objectsList.map((elem) => ({
    attributes: getJsonList(prepareWysiwygAttributes(elem.attributes, elem.formTypes)),
    id: elem.id,
    idFront: elem.idFront,
    idObjectType: elem.idObjectType,
  }));

export const updateElementsZIndex = (elements, diagramElements, zIndex) => {
  let maxZIndex = zIndex;

  elements.forEach((connector) => {
    const elemSamezIndex = diagramElements.find((elem) => connector.id !== elem.id && elem.zIndex === connector.zIndex);

    if (elemSamezIndex) {
      maxZIndex += 1;
      connector.zIndex = maxZIndex;
    }
  });

  return maxZIndex;
};

export const fixZIndex = (diagramInstance) => {
  const diagramElements = [
    ...diagramInstance.connectors,
    ...diagramInstance.nodes.filter((node) => !node.id.includes('swimlane')),
  ];
  let maxZIndex = diagramElements.reduce((acc, elem) => Math.max(acc, elem.zIndex), 0);

  maxZIndex = updateElementsZIndex(diagramInstance.connectors, diagramElements, maxZIndex);
  updateElementsZIndex(diagramInstance.nodes, diagramElements, maxZIndex);

  diagramInstance.dataBind();
};

export const getDiagramLanguages = (diagramAttributes, availableLanguages) =>
  availableLanguages.filter((language) => diagramAttributes?.[language]?.PROCESS_NAME);
