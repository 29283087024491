import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const recycleBinUrl = '/backend/ms-design/api/v1/recycle-bin';

const getAllRecycleBinElements = (params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${recycleBinUrl}?${searchParams}`);
};

const restoreRecycledElements = (data) => axiosInstance.post(`${recycleBinUrl}/restore`, data);
const deleteRecycledElements = (data) => axiosInstance.delete(`${recycleBinUrl}/${data.join()}`);

export default {
  getAllRecycleBinElements,
  restoreRecycledElements,
  deleteRecycledElements,
  handleServiceError: serviceUtils.handleServiceError,
};
