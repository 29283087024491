import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { NOT_TRANSLATABLE } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import Page from 'components/Page/Page';
import RequirementHeader from 'components/Requirements/RequirementHeader';
import RequirementSidebar from 'components/Requirements/RequirementSidebar';
import ChangeLog from 'components/RequirementsTabs/ChangeLog';
import Comments from 'components/RequirementsTabs/Comments';
import DeleteDialog from 'components/UI/DeleteDialog/DeleteDialog';
import useProcessLevelDatabase from 'hooks/useProcessLevel';
import useRequirement from 'hooks/useRequirement';
import useRequirementContext from 'hooks/useRequirementContext';
import RequirementForm from 'pages/Requirements/RequirementForm';
import { getAllProcessesWithResponsiblePerson, getResponibleInProcesses } from 'services/processLevelDatabase';
import titleService from 'services/titleService';
import { Language } from 'types/config';
import { DeleteDialogPrefixes } from 'types/dialogs';
import { ProcessLevelFormatted } from 'types/processLevel';
import { RequirementTabs } from 'types/requirement';

import styles from './Requirement.module.scss';

const Requirement = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const { requirementData } = useRequirementContext();
  const { formatProcessLevel, getFinalResults } = useProcessLevelDatabase();
  const { fetchRequirement, deleteExistingRequirement } = useRequirement();
  const [showDeleteRequirementDialog, setShowDeleteRequirementDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState<RequirementTabs | null>(null);
  const [originRows, setOriginRows] = useState<ProcessLevelFormatted[]>();
  const [originProcessLevelRows, setOriginProcessLevelRows] = useState<ProcessLevelFormatted[]>();
  const [responsibleProcessLevelRows, setResponsibleProcessLevelRows] = useState<ProcessLevelFormatted[]>();
  const [responsibleRows, setResponsibleRows] = useState<ProcessLevelFormatted[]>();
  const [allProcesses, setAllProcesses] = useState<ProcessLevelFormatted[]>();
  const closeTab = () => setSelectedTab(null);

  useEffect(() => {
    if (!requirementData) {
      fetchRequirement(+id);
    }
    titleService.updatePageTitle(t('requirement'), id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    closeTab();
  }, [id]);

  const handleConfirmDelete = useCallback(
    async (confirmationValue?: string) => {
      setShowDeleteRequirementDialog(false);
      if (!confirmationValue) return;
      deleteExistingRequirement(+id, confirmationValue);
    },
    [deleteExistingRequirement, id],
  );

  useEffect(() => {
    if (!responsibleProcessLevelRows) {
      getAllProcessesWithResponsiblePerson()
        .then((res) => {
          setResponsibleRows(formatProcessLevel(res.data.results, res.data.catalog.USER, true));
          getFinalResults(res.data).then((finalResults) => {
            setResponsibleProcessLevelRows(finalResults);

            const processList: ProcessLevelFormatted[] = [];
            finalResults?.forEach((process: ProcessLevelFormatted) => {
              processList.push(process);
              processList.push(...process.children);
            });
            setAllProcesses(processList);
          });
        })
        .catch((err) => handleServiceError(err));
    }

    if (!originProcessLevelRows) {
      getResponibleInProcesses()
        .then((res) => {
          setOriginRows(formatProcessLevel(res.data.results, res.data.catalog.USER, true));
          getFinalResults(res.data).then((finalResults) => setOriginProcessLevelRows(finalResults));
        })
        .catch((err) => handleServiceError(err));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          {showSidebar && <RequirementSidebar onClose={() => setShowSidebar(false)} />}
          <div className={`${styles.Content} ${showSidebar ? styles.SidebarExpanded : styles.SidebarCollapsed}`}>
            <RequirementHeader
              handleSelectTab={setSelectedTab}
              onDelete={() => setShowDeleteRequirementDialog(true)}
              originProcess={allProcesses?.find(
                (process: ProcessLevelFormatted) =>
                  process.id.toString() === requirementData?.attributes[NOT_TRANSLATABLE]?.ID_ORIGIN_PROCESS?.toString(),
              )}
              selectedTab={selectedTab}
              setShowSidebar={() => setShowSidebar(true)}
              showSidebar={showSidebar}
              title={requirementData?.attributes[i18n.language as Language]?.TITLE as string}
            />
            {selectedTab === RequirementTabs.COMMENTS && requirementData && (
              <Comments
                catalog={requirementData.catalog}
                comments={requirementData.comments}
                extraClass={styles.RequirementWrapperTab}
                handleClose={closeTab}
              />
            )}
            {selectedTab === RequirementTabs.CHANGELOG && (
              <ChangeLog extraClass={styles.RequirementWrapperTab} handleClose={closeTab} />
            )}
            <div
              className={`${styles.Detail} ${showSidebar ? '' : styles.SidebarCollapsed} ${
                selectedTab !== null ? styles.HideRequirementForm : ''
              }`}
            >
              <RequirementForm
                originProcessLevelRows={originProcessLevelRows}
                originRows={originRows}
                responsibleProcessLevelRows={responsibleProcessLevelRows}
                responsibleRows={responsibleRows}
              />
            </div>
          </div>
        </div>
      </div>
      {showDeleteRequirementDialog && (
        <DeleteDialog
          handleClick={(confirmationValue) => handleConfirmDelete(confirmationValue)}
          objectName={requirementData?.attributes[i18n.language as Language]?.TITLE as string}
          prefix={DeleteDialogPrefixes.REQUIREMENT}
        />
      )}
    </Page>
  );
};

export default Requirement;
