import { useTranslation } from 'react-i18next';

import { PUBLISHED_STATUS, STATUS } from 'assets/constants/constants';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import Spinner from 'components/UI/Spinner/Spinner';
import useAuth from 'hooks/useAuth';

import styles from './DocumentCard.module.scss';

type Props = {
  date?: string;
  downloadDocument?: (documentName: string) => void;
  deleteDocument?: (documentName?: string) => void;
  retryUpload?: () => void;
  canRetryUpload?: boolean;
  id?: number;
  name: string;
  user?: string;
  uploading?: boolean;
  error?: string;
  status?: string;
  isRequirement?: boolean;
  readOnly?: boolean;
  environment?: string;
  blocked?: boolean;
  hideDownloadButton?: boolean;
};

const DocumentCard = (props: Props) => {
  const {
    date,
    downloadDocument,
    deleteDocument,
    retryUpload,
    id,
    name,
    user,
    canRetryUpload = false,
    uploading = false,
    error,
    status,
    isRequirement,
    readOnly,
    environment,
    blocked,
    hideDownloadButton,
  } = props;
  const { t } = useTranslation();
  const { isOnlyUser } = useAuth(true);
  const formatFileName = encodeURI(name);

  return (
    <div className={`${styles.Container}${error ? ` ${styles.Error}` : ''}`} onFocus={() => {}}>
      <div className={styles.Name}>
        {uploading && (
          <div className={styles.UploadingSpinner}>
            <Spinner isVisible />
          </div>
        )}
        {!uploading && <i className={error ? 'di icon-blitz-fehler error' : 'di icon-datei-pdf'} />}
        <span>{name}</span>
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Info}>
          {!error && !uploading && (
            <>
              <span>{user}</span>
              <span>{date}</span>
            </>
          )}
          {error && <span className={styles.ErrorMessage}>{error}</span>}
          {uploading && <span>{t('uploading')}</span>}
        </div>
        {!uploading && (
          <div className={styles.Buttons}>
            {!error && (
              <>
                {!hideDownloadButton && (
                  <RoundButton
                    className={styles.Button}
                    icon="di icon-herunterladen"
                    id={`document-download-${id}`}
                    onClick={() => downloadDocument?.(formatFileName)}
                  />
                )}
                {!isOnlyUser() &&
                  environment !== PUBLISHED_STATUS &&
                  ((isRequirement && !readOnly) || (!isRequirement && status !== STATUS.WORKFLOW && !blocked)) && (
                    <RoundButton
                      className={styles.Button}
                      icon="di icon-muelleimer-loeschen"
                      id={`document-delete-${id}`}
                      onClick={() => {
                        deleteDocument?.(formatFileName);
                      }}
                    />
                  )}
              </>
            )}
            {!!error && (
              <>
                <RoundButton
                  className={styles.Button}
                  icon="di icon-muelleimer-loeschen"
                  id={`document-delete-${name.replace(/\s/g, '')}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteDocument?.();
                  }}
                />
                {canRetryUpload && (
                  <RoundButton
                    className={styles.Button}
                    icon="di icon-pfeil-aktualisieren"
                    id={`document-retry-${name.replace(/\s/g, '')}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      retryUpload?.();
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
