import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { CATALOG_OBJECT_ACTIONS, ROLE, SWIMLANE } from 'assets/constants/constants';
import { downloadFile, getLanguageAttributes } from 'assets/js/Utils';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import MergeCatalogObjects from 'components/MergeCatalogObjects/MergeCatalogObjects';
import Toolbar from 'components/Toolbar/Toolbar';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import Pagination from 'components/UI/Pagination/Pagination';
import Search from 'components/UI/Search/Search';
import Spinner from 'components/UI/Spinner/Spinner';
import TableList from 'components/UI/TableList/TableList';
import useAuth from 'hooks/useAuth';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { downloadRolesExcel } from 'services/design';
import objectCatalogService from 'services/objectCatalogService';
import { TableTool } from 'types/tables';

import styles from './CatalogObjectTable.module.scss';

const DEFAULT_TOOLS = [TableTool.NEW, TableTool.EDIT, TableTool.MERGE, TableTool.DELETE, TableTool.DOWNLOAD];

const CatalogObjectTable = (props) => {
  const {
    header,
    labelX,
    objectType,
    pagination,
    rowComponent,
    pendingObjects,
    tools = objectType === ROLE ? DEFAULT_TOOLS : DEFAULT_TOOLS.slice(0, 4),
    isLegacy,
    catalogUsers,
  } = props;
  const { fetchResults, handleSearchChange, page, results, setPage, sorting, sortTable, totalPages, totalResults } = pagination;
  const { t, i18n } = useTranslation();
  const { handleServiceError } = useError();
  const { userInfo } = useAuth();
  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [isMergeObjects, setIsMergeObjects] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [deleteObjectsDialogData, setDeleteObjectsDialogData] = useState();
  const isLoading = isLoadingAction || pagination.isLoading;
  const isNotSwimlane = !location.pathname.includes(SWIMLANE);
  const isRoles = location.pathname.includes('roles');
  const isNotInputOutput = !location.pathname.includes('input-output');
  const isInNeposQIGroup = userInfo?.neposQIGroup && (!isNotInputOutput || !isNotSwimlane);
  const { isFreezed } = useFeatureFlags();

  const deleteObjects = async () => {
    try {
      setIsLoadingAction(true);
      const idsToDelete = checkedItems.map(({ id }) => id).join(',');
      const response = await objectCatalogService.deleteObjectsCatalog(idsToDelete, objectType);

      if (response.data?.length) {
        let objects = [];
        let diagrams = [];
        response.data.forEach((notDeletedObject) => {
          objects = [...objects, notDeletedObject.message.split(' ')[2]];
          const diagramNames = notDeletedObject.diagramSummaryList.map(
            ({ attributes }) => getLanguageAttributes(attributes, i18n.language.toUpperCase()).PROCESS_NAME,
          );
          diagrams = [...diagrams, ...diagramNames];
        });
        setDeleteObjectsDialogData({ objects: objects.join(', '), diagrams: diagrams.join(', ') });
      }
      fetchResults();
      setCheckedItems([]);
      setIsLoadingAction(false);
    } catch (error) {
      handleServiceError(error);
      setIsLoadingAction(false);
    }
  };

  const downloadExcel = () => {
    try {
      setIsLoadingAction(true);
      downloadRolesExcel(objectType).then((res) => {
        setIsLoadingAction(false);
        const binaryData = [res.data];
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
        downloadFile(url, `excel-roles-.xlsx`, res.headers['content-disposition']);
      });
    } catch (error) {
      setIsLoadingAction(false);
      handleServiceError(error);
    }
  };

  const toolIcons = [
    {
      id: TableTool.NEW,
      tooltip: t('tool.addNew'),
      iconClass: 'di icon-plus-hinzufuegen',
      disabled: isFreezed || isLoading || (!isInNeposQIGroup && (!isNotSwimlane || !isNotInputOutput)) || checkedItems.length > 0,
      click: () => setDialogType(CATALOG_OBJECT_ACTIONS.CREATE),
    },
    {
      id: TableTool.EDIT,
      tooltip: t('tool.edit'),
      iconClass: 'di icon-stift-bearbeiten',
      disabled:
        isFreezed || isLoading || checkedItems.length !== 1 || (!isInNeposQIGroup && (!isNotSwimlane || !isNotInputOutput)),
      click: () => setDialogType(CATALOG_OBJECT_ACTIONS.EDIT),
    },
    {
      id: TableTool.MERGE,
      tooltip: t('tool.merge'),
      iconClass: 'di icon-pfeil-minimieren',
      disabled: isFreezed || checkedItems.length < 2 || (!isInNeposQIGroup && (!isNotSwimlane || !isNotInputOutput)),
      click: () => setIsMergeObjects(true),
    },
    {
      id: TableTool.DELETE,
      tooltip: t('tool.delete'),
      iconClass: 'di icon-schliessen-2',
      disabled: isFreezed || checkedItems.length < 1 || (!isInNeposQIGroup && (!isNotSwimlane || !isNotInputOutput)),
      click: deleteObjects,
    },
    {
      id: TableTool.DOWNLOAD,
      tooltip: t('tool.download'),
      iconClass: 'di icon-herunterladen',
      click: downloadExcel,
    },
  ];

  const selectedTools = toolIcons.filter((tool) => tools.includes(tool.id));

  useEffect(() => {
    if (!results?.length) return;
    setIsHeaderChecked(results.every((object) => checkedItems.find(({ id }) => id === object.id)));
  }, [checkedItems, results]);

  const getObjectsToMerge = () => {
    const objectsToMerge = checkedItems.map((object) => ({
      ...object,
      name: getLanguageAttributes(object.attributes, i18n.language.toUpperCase()).OBJECT_NAME,
      checked: false,
    }));
    return objectsToMerge;
  };

  const handleCheckAll = () => {
    const newIsHeaderChecked = !isHeaderChecked;
    const newCheckedItems = [...checkedItems];
    results.forEach((result) => {
      const index = newCheckedItems.findIndex(({ id }) => id === result.id);
      if (index === -1 && newIsHeaderChecked) newCheckedItems.push(result);
      else if (index !== -1 && !newIsHeaderChecked) newCheckedItems.splice(index, 1);
    });
    setIsHeaderChecked(newIsHeaderChecked);
    setCheckedItems(newCheckedItems);
  };

  const handleRowClick = (id) => {
    if (!isInNeposQIGroup && (!isNotSwimlane || !isNotInputOutput)) return;
    const index = checkedItems.findIndex((item) => item.id === id);
    const newCheckedItems = index === -1 ? [...checkedItems, results.find((result) => result.id === id)] : [...checkedItems];

    if (index !== -1) {
      newCheckedItems.splice(index, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const hideMergeView = () => {
    if (page === 1) fetchResults();
    else setPage(1);
    setCheckedItems([]);
    setIsMergeObjects(false);
  };

  const closeDialog = () => setDialogType(null);

  const handleCreateObject = () => {
    if (page === 1) fetchResults();
    else setPage(1);
    setCheckedItems([]);
  };

  const handleUpdateObject = () => {
    fetchResults();
    setCheckedItems([]);
  };

  return (
    <>
      <div className={isLoadingAction ? styles.Spinner : ''}>
        <Spinner isVisible={isLoadingAction} />
      </div>
      {isMergeObjects ? (
        <MergeCatalogObjects
          header={header}
          hideMergeView={hideMergeView}
          objectType={objectType}
          RowComponent={rowComponent}
          selectedObjects={getObjectsToMerge()}
          setLoadingMerge={setIsLoadingAction}
        />
      ) : (
        <>
          <Toolbar toolIcons={selectedTools} />
          <div className={styles.Content}>
            <div className={styles.SearchSection}>
              <h4>{t('catalog.titleX', { labelX })}</h4>
              <Search disabled={isLoading} isAsync searching={handleSearchChange} />
            </div>
            {results && <h5 className={styles.Results}>{`${totalResults} ${t('results')}`}</h5>}
            <TableList
              catalogUsers={catalogUsers}
              checkedItems={checkedItems}
              direction={sorting.order}
              fieldToOrder={sorting.filter}
              handleCheckAll={handleCheckAll}
              handleRowClick={handleRowClick}
              header={header}
              isHeaderChecked={isHeaderChecked}
              isLoading={pagination.isLoading}
              isNeposQIGroup={isInNeposQIGroup}
              isNotInputOutput={isNotInputOutput}
              isNotSwimlane={isNotSwimlane}
              isRoles={isRoles}
              list={results}
              RowComponent={rowComponent}
              sortTable={sortTable}
            />
            {!pagination.isLoading && results?.length > 0 && totalPages > 1 && (
              <div className={styles.PagesSection}>
                <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
              </div>
            )}
          </div>
        </>
      )}
      {deleteObjectsDialogData && (
        <DialogWarning confirmText={t('ok')} handleConfirm={() => setDeleteObjectsDialogData(null)} hasIcon>
          {t('messageDialog', {
            type: t(`contextualMenu.${objectType}`),
            objects: deleteObjectsDialogData.objects,
            diagrams: deleteObjectsDialogData.diagrams,
          })}
        </DialogWarning>
      )}
      {dialogType && (
        <DialogObjectCatalog
          catalogUsers={catalogUsers}
          close={closeDialog}
          dialogType={dialogType}
          isLegacy={isLegacy}
          object={checkedItems[0]}
          objectType={objectType}
          onSubmit={dialogType === CATALOG_OBJECT_ACTIONS.EDIT ? handleUpdateObject : handleCreateObject}
          pendingObjects={pendingObjects}
          results={results}
        />
      )}
    </>
  );
};

export default CatalogObjectTable;
