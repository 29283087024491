import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useAuth as useOidcAuth } from 'react-oidc-context';

import { APP_VERSION } from 'assets/constants/constants';

import * as Dialog from '../components/UI/Dialogs/Dialogs';
import { FeatureFlagsActions, FeatureFlagsContext } from '../contexts/FeatureFlags/FeatureFlagsContext';
import featuresService from '../services/featuresService';

const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  // const { t } = useTranslation();
  // const auth = useOidcAuth();

  if (context === undefined) {
    throw new Error('useFleatureFlags must be used within a FeatureFlagsProvider');
  }

  const { state, dispatch } = context;

  const fetchFeatureFlags = async (userCode) => {
    // dispatch({ actionType: FeatureFlagsActions.GET_FEATURE_FLAGS });
    try {
      // const { data } = await featuresService.getFeatureFlags();
      // if (!data.version || !data.version.startsWith(APP_VERSION.split('.', 2).join('.'))) {
      //   Dialog.showAlert({
      //     message: t('newVersionError'),
      //   });
      // }
      // const whiteListFound = ['SASOCCI', 'KLOOSJU', 'HANMUE', 'JBROMME', 'JENSANC', 'CCARLES'].find((user) => user === userCode);
      // if (data.isFreezed && whiteListFound) {
      //   data.isFreezed = false;
      // }
      // dispatch({ actionType: FeatureFlagsActions.GET_FEATURE_FLAGS_SUCCESS, payload: data });
    } catch (error) {
      // const errorCode = error.response?.data?.code || error.response?.status;
      // const msgError = error.response ? `${t(`errors.${errorCode}`)} <br/></br/> (${errorCode})` : '';
      // dispatch({ actionType: FeatureFlagsActions.GET_FEATURE_FLAGS_ERROR });
      // Dialog.showError({
      //   closeClick: () => location.reload(), // eslint-disable-line no-restricted-globals
      //   isError: true,
      //   message: msgError,
      //   title: error.name || 'Error',
      // });
    }
  };

  const setLocalFeatureFlags = (featureFlags = {}) => {
    // dispatch({ actionType: FeatureFlagsActions.SET_LOCAL_FEATURE_FLAGS, payload: featureFlags });
  };

  return {
    ...state,
    ...state.localFeatureFlags,
    fetchFeatureFlags,
    setLocalFeatureFlags,
  };
};

export default useFeatureFlags;
