import { DragEvent, useRef, useEffect, useState, useCallback } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { GHOST_SWIMLANE_HEIGHT_FACTOR, SWIMLANE_MAX_HEIGHT, SYMBOL_HEIGHT, SYMBOL_WIDTH } from 'assets/constants/constants';
import Event from 'components/Event/Event';
import LogicalGate from 'components/LogicalGate/LogicalGate';
import ProcessInterface from 'components/ProcessInterface/ProcessInterface';
import ProcessStep from 'components/ProcessStep/ProcessStep';
import SwimlaneSelection from 'components/Selection/SwimlaneSelection';
import Symbol from 'components/Symbol/Symbol';
import { movingSwimlaneSymbols, updateSwimlanesSize } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import useOuterClick from 'hooks/useOuterClick';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import useValidation from 'hooks/useValidation';
import { Coordinate, Orientation, PaletteActions } from 'types/diagram';
import { ProcessStatus } from 'types/processes';
import { Swimlanes, SwimlaneV2 as SwimlanePropTypes } from 'types/swimlanes';
import { SymbolTypes } from 'types/symbols';

import styles from './Swimlane.module.scss';

// TODO: Move this to a common place
const SymbolTypeMap: { [key in SymbolTypes | 'EVENT']: { Component?: any; extraProps?: {} } } = {
  [SymbolTypes.TEXT]: {},
  [SymbolTypes.SWIMLANE_ELEMENT]: {},
  [SymbolTypes.PALETTE_TEXTFIELD]: {},
  [SymbolTypes.SIPOC_ELEMENT]: { Component: Symbol },
  [SymbolTypes.EVENT]: { Component: Event },
  // TODO: delete when back is correct
  EVENT: { Component: Event },
  [SymbolTypes.AND_GATE]: { Component: LogicalGate, extraProps: { type: SymbolTypes.AND_GATE } },
  [SymbolTypes.OR_GATE]: { Component: LogicalGate, extraProps: { type: SymbolTypes.OR_GATE } },
  [SymbolTypes.XOR_GATE]: { Component: LogicalGate, extraProps: { type: SymbolTypes.XOR_GATE } },
  [SymbolTypes.PROCESS_INTERFACE]: { Component: ProcessInterface },
  [SymbolTypes.PROCESS_STEP]: { Component: ProcessStep },
};

const Swimlane = (props: SwimlanePropTypes) => {
  const { id, meta, symbols, role } = props;
  const swimlaneRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(SYMBOL_HEIGHT);
  const { showAlert } = useError();
  const {
    diagramLanguage,
    ghostSwimlane,
    paletteAction,
    processData,
    selectedSymbolIds,
    isMovingSwimlaneSymbol,
    dispatch,
    fontSize,
    symbolObjectsDisplayed,
  } = useDiagramContext();
  const { handleSelect, clearSelection } = useSelection();
  const { createSymbol, moveSwimlaneSymbol, moveSymbolsAsideFromYPosition } = useSwimlane();
  const { swimlaneErrors } = useValidation();
  const { t } = useTranslation();

  const columns = Array(meta.width).fill(null);
  const rows = Array(meta.height).fill(null);
  const fullId = `swimlane-${meta.pos}`;
  const readOnly = processData?.isOnlyRead || processData?.status === ProcessStatus.WORKFLOW;
  const hasErrors = swimlaneErrors && swimlaneErrors.find((symbolId) => symbolId === id);

  const WARNING_MSG = {
    NO_ROLE: t('swimlane.noRoleWarning'),
    REQUEST_ROLE: t('swimlane.roleRequestedWarning'),
    TEMPORAL_ROLE: t('swimlane.tempRoleWarning'),
  };
  const WARNING_ICO = {
    NO_ROLE: 'di icon-warnung-fehler',
    REQUEST_ROLE: 'di icon-hilfe',
    TEMPORAL_ROLE: 'di icon-warnung-fehler',
  };

  const getWarningMsg = (): string => {
    if (!role?.id) return role?.temporaryText ? WARNING_MSG.TEMPORAL_ROLE : WARNING_MSG.NO_ROLE;

    return role.approved ? '' : WARNING_MSG.REQUEST_ROLE;
  };

  const getWarningClass = (): string => {
    if (!role?.id) return role?.temporaryText ? WARNING_ICO.TEMPORAL_ROLE : WARNING_ICO.NO_ROLE;

    return role.approved ? '' : WARNING_ICO.REQUEST_ROLE;
  };

  const toggleClass = (event: DragEvent<HTMLElement>, toggle = false) => event.currentTarget.classList.toggle('dragging', toggle);

  const handleDragEnter = (event: DragEvent<HTMLElement>) => {
    if (paletteAction === PaletteActions.ADD_SWIMLANE) return;
    event.stopPropagation();
    toggleClass(event, true);
  };

  const handleDrop = useCallback(
    (event: DragEvent<HTMLElement>, top: number, left: number) => {
      if (paletteAction === PaletteActions.ADD_SWIMLANE) {
        return document.getElementById(Swimlanes.WRAPPER_DOM_ID)?.classList.remove('dragging');
      }

      event.stopPropagation();
      toggleClass(event, false);
      dispatch(movingSwimlaneSymbols(false));

      if (symbols.find((s) => s.meta.left === left && s.meta.top === top)) {
        if (isMovingSwimlaneSymbol.symbolId) {
          showAlert(t('swimlane.dialog.alreadyPlacedSymbol'));
          return;
        }
        if (meta.pos === 0 && left === 0 && top === 0) {
          showAlert(t('errors.swimlaneFirstEvent'));
          return;
        }

        const swimlaneHasNoSpace = processData?.swimlanes.some((swimlane) =>
          swimlane.symbols.some((s) => s.meta[Coordinate.LEFT] + s.meta.width === swimlane.meta.width - 1),
        );

        if (swimlaneHasNoSpace) {
          dispatch(updateSwimlanesSize(columns.length + 1));
        }

        moveSymbolsAsideFromYPosition(left);

        setTimeout(() => createSymbol({ top, left }, id));
      } else if (isMovingSwimlaneSymbol.isMoving && isMovingSwimlaneSymbol.symbolId) {
        moveSwimlaneSymbol({ top, left }, id);
      } else {
        createSymbol({ top, left }, id);
      }
    },
    [
      columns,
      createSymbol,
      dispatch,
      id,
      isMovingSwimlaneSymbol.isMoving,
      isMovingSwimlaneSymbol.symbolId,
      meta.pos,
      t,
      moveSwimlaneSymbol,
      paletteAction,
      processData?.swimlanes,
      moveSymbolsAsideFromYPosition,
      showAlert,
      symbols,
    ],
  );

  const drawSymbol = (position: number, row?: number) => {
    const symbol = symbols.find((s) => s.meta.left === position && s.meta.top === row);

    if (!symbol) return null;

    const { Component, extraProps } = SymbolTypeMap[symbol.type];

    return <Component {...symbol} {...extraProps} swimlaneId={id} />;
  };

  useOuterClick(containerRef, () => {
    if (selectedSymbolIds.includes(id)) {
      clearSelection();
    }
  });

  useEffect(() => {
    if (swimlaneRef.current && fontSize) {
      setHeaderHeight(swimlaneRef.current.getBoundingClientRect().height / fontSize - 1);
    }
  }, [headerHeight, fontSize, symbolObjectsDisplayed, processData]);

  const enlargeSwimlaneRight =
    ghostSwimlane &&
    ghostSwimlane.direction === Orientation.HORIZONTAL &&
    ghostSwimlane.swimlaneId === id &&
    ghostSwimlane.width >= 0;
  const reduceSwimlaneRight =
    ghostSwimlane &&
    ghostSwimlane.direction === Orientation.HORIZONTAL &&
    ghostSwimlane.swimlaneId === id &&
    ghostSwimlane.width < 0;

  const enlargeSwimlaneBottom =
    ghostSwimlane &&
    ghostSwimlane.direction === Orientation.VERTICAL &&
    ghostSwimlane.swimlaneId === id &&
    ghostSwimlane.height >= 0;
  const reduceSwimlaneBottom =
    ghostSwimlane &&
    ghostSwimlane.direction === Orientation.VERTICAL &&
    ghostSwimlane.swimlaneId === id &&
    ghostSwimlane.height < 0;

  return (
    <>
      <article
        className={`${styles.Swimlane} ${selectedSymbolIds?.includes(id) ? styles.Selection : ''}`}
        id={fullId}
        onDrop={(ev) => ev.stopPropagation()}
        ref={swimlaneRef}
      >
        <header
          className={hasErrors ? styles.Error : ''}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!readOnly) {
              handleSelect(id, e);
            }
          }}
          ref={containerRef}
          style={{ pointerEvents: 'auto' }}
        >
          <div
            className={`${readOnly ? '' : styles.EditMode} ${styles.HeaderContent}`}
            style={{
              width: `${headerHeight}em`,
            }}
          >
            {role && Object.keys(role).length > 0 ? (
              <>
                {role.temporaryText ? (
                  <TooltipComponent content={role.temporaryText} position="BottomCenter" showTipPointer={false}>
                    {role.temporaryText}
                  </TooltipComponent>
                ) : (
                  <TooltipComponent
                    content={role.attributes?.[diagramLanguage]?.OBJECT_NAME}
                    position="BottomCenter"
                    showTipPointer={false}
                  >
                    {role.attributes?.[diagramLanguage]?.OBJECT_NAME}
                  </TooltipComponent>
                )}
              </>
            ) : (
              <p>{t('clickToEdit')}</p>
            )}
          </div>
        </header>
        <div className={styles.Content}>
          {rows.map((r, top) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.Row} key={`${fullId}-section-${top}`}>
              {columns.map((c, left) => (
                <section
                  data-dragging-text={t('dropSymbolHere')}
                  key={`${fullId}-section-${left}`} // eslint-disable-line react/no-array-index-key
                  onDragEnter={handleDragEnter}
                  onDragLeave={toggleClass}
                  onDrop={(ev) => handleDrop(ev, top, left)}
                  style={isMovingSwimlaneSymbol.isMoving ? { pointerEvents: 'auto' } : {}}
                >
                  {drawSymbol(left, top)}
                </section>
              ))}
            </div>
          ))}
        </div>

        <div className={styles.RoleWarning}>
          <TooltipComponent className="icon-tooltip" content={getWarningMsg()} position="BottomCenter" showTipPointer={false}>
            <i className={getWarningClass()} />
          </TooltipComponent>
        </div>
      </article>

      {enlargeSwimlaneRight && (
        <div className={styles.EnlargeSwimlaneRight} style={{ width: `${ghostSwimlane.width * SYMBOL_WIDTH}em` }} />
      )}
      {reduceSwimlaneRight && (
        <div className={styles.ReduceSwimlaneRight} style={{ right: `${-ghostSwimlane.width * SYMBOL_WIDTH}em` }} />
      )}
      <SwimlaneSelection position={meta.pos} swimlaneId={id} />

      {enlargeSwimlaneBottom && (
        <div className={styles.EnlargeSwimlaneBottom} style={{ height: `${ghostSwimlane.height * SWIMLANE_MAX_HEIGHT}em` }} />
      )}
      {reduceSwimlaneBottom && (ghostSwimlane.height + meta.height) * GHOST_SWIMLANE_HEIGHT_FACTOR > SWIMLANE_MAX_HEIGHT && (
        <div
          className={styles.ReduceSwimlaneBottom}
          style={{
            top: `${(ghostSwimlane.height + meta.height) * GHOST_SWIMLANE_HEIGHT_FACTOR}em`,
          }}
        />
      )}
    </>
  );
};

export default Swimlane;
