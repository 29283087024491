import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const tasksUrl = '/backend/ms-tasks/api/v1';
const tasksUrlv2 = '/backend/ms-tasks/api/v2';

const getAllTasks = () => axiosInstance.get(`${tasksUrl}/tasks`);

const getStatusTypes = () => axiosInstance.get(`${tasksUrl}/tasks-attributes/status-types`);

const getTask = (idTask) => axiosInstance.get(`${tasksUrl}/tasks/${idTask}`);

const getTasksByGroup = ({ group, ...params }) =>
  axiosInstance.get(`${tasksUrlv2}/tasks/${group.replace('Tasks', '')}?size=20`, { params });

const updateTask = (data) => axiosInstance.put(`${tasksUrl}/tasks`, data);

export default {
  handleServiceError: serviceUtils.handleServiceError,
  getAllTasks,
  getStatusTypes,
  getTask,
  getTasksByGroup,
  updateTask,
};
