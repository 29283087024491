import { useCallback, useState, useRef } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import useOuterClick from 'hooks/useOuterClick';

import styles from './OptionsLine.module.scss';

interface Props {
  className?: string;
  label: string;
  icons?: OptionsLineIcon[];
  stateIcon?: string;
  onClose?: () => void;
}

export interface OptionsLineIcon {
  icon: string;
  key: string;
  onClick: () => void;
}

const OptionsLine = ({ className, label, icons, stateIcon, onClose = () => {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentContainerRef = useRef<HTMLDivElement>(null);

  const handleOuterClick = useCallback(
    (event: MouseEvent) => {
      if (currentContainerRef.current?.contains(event.target as Node)) return;
      setIsOpen(false);
    },
    [currentContainerRef],
  );
  useOuterClick(currentContainerRef, handleOuterClick);

  return (
    <div
      className={`${styles.Container} ${className} ${icons && styles.Clickable} ${isOpen && styles.Opened}`}
      data-testid="OptionsLine"
      onClick={() => icons && setIsOpen(!isOpen)}
      ref={currentContainerRef}
    >
      {stateIcon && <i className={`di icon-${stateIcon} ${styles.IconState}`} />}
      <TooltipComponent content={label} cssClass="mbc-tooltip nepos-tooltip" showTipPointer={false}>
        <div className={styles.Text}>{label}</div>
      </TooltipComponent>

      {isOpen && icons && (
        <div className={styles.Buttons}>
          {icons.map((iconOption: OptionsLineIcon) => (
            <button
              className={styles.Button}
              data-qa={`menu-${iconOption.key}`}
              key={`menu-${iconOption.key}`}
              onClick={() => {
                iconOption.onClick();
                onClose();
              }}
              type="button"
            >
              <i className={`di ${iconOption.icon}`} />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionsLine;
