import styles from './WarningMessage.module.scss';

const WarningMessage = () => (
  <div className={styles.WarningMessage}>
    <p>
      MBC MAP will enter the final sundown (successive shutdown of the repositories) on 10/27/2024 to 10/31/2024 and can no longer
      be reached on 11/1/2024
      <br />
      MBC MAP wird vom 27.10.24 bis zum 31.10.24 in den finalen Sundown (sukzessive Abschaltung der Repositories) gehen und am
      01.11.2024 nicht mehr erreichbar sein
    </p>
  </div>
);

export default WarningMessage;
