import { Attributes } from './forms';
import { ProcessDataCatalog, ProcessType } from './processes';
import { CatalogUser } from './user';
import { DatasetWorkflowData } from './workflow';

export enum DatasetStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  WORKFLOW = 'WORKFLOW',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum DatasetWorkflowStages {
  AUTHOR = 'AUTHOR',
  METHOD_OWNER = 'METHOD_OWNER',
  QI_GROUP = 'QI_GROUP',
}

export type DocumentsDataset = {
  date: string;
  name: string;
  creator: string;
  extension: string;
};

export type DatasetUploadError = {
  file: string;
  code: string;
};

export type LinkedDiagramDataset = {
  id: number;
  type: ProcessType;
  attributes: Attributes;
};

export type DatasetData = {
  id: number;
  attributes: Attributes;
  status: DatasetStatus;
  linkedDiagram?: LinkedDiagramDataset;
  version: string;
  documents?: DocumentsDataset[];
  catalog: ProcessDataCatalog;
  workflowData: DatasetWorkflowData;
  versions: { version: string; validFrom: string; author: CatalogUser }[];
  dataSetInUse?: { blocked: boolean; lastUse: string; user: CatalogUser };
};

export type DocumentUploadData = {
  catalog: {
    users: CatalogUser[];
  };
  uploaded: DocumentsDataset[];
  errors: DatasetUploadError[];
};

export type DataSet = {
  id: number;
  name: string;
  description: string;
  versions: {
    date: string;
    user: string;
    version: string;
  }[];
  created: string;
  link: {
    id: number;
    name: string;
    type: number;
  };
  documents: DocumentsDataset[];
};

export type DataSetHeader = {
  [key in keyof DataSet]?: string;
};

export type DatasetItem = {
  code: string;
  enabled: boolean;
};
