import { Coordinates } from './diagram';

export enum ConnectionTypes {
  INCOMING_ARROW = 'INCOMING_ARROW',
  SIMPLE_ARROW = 'SIMPLE_ARROW',
  SIMPLE_LINE = 'SIMPLE_LINE',
}

export enum ConnectorPorts {
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
}

export enum ConnectorDirectionTypes {
  HORIZONTAL = 'H',
  VERTICAL = 'V',
}

export enum ConnectorEndpointType {
  SOURCE = 'source',
  TARGET = 'target',
}
export type ConnectorEndpoint = { id: string; port: ConnectorPorts; node?: SVGCircleElement | null | undefined };
export type ConnectorVertex = { direction: ConnectorDirectionTypes; coordinate: number };

export type Connector = {
  source: ConnectorEndpoint;
  target: ConnectorEndpoint;
  vertices: ConnectorVertex[];
};

export type GhostConnector = {
  source: Partial<ConnectorEndpoint> & Coordinates;
  target: Partial<ConnectorEndpoint> & Coordinates;
  vertices: ConnectorVertex[];
};

export type GhostConnectorData = Omit<GhostConnector, 'vertices'> & {
  movingEndpointType: ConnectorEndpointType;
  movingEndpointInitialCoords: Coordinates;
};

export type ConnectorHandle = Coordinates & {
  index: number;
  isActive: boolean;
  isHidden: boolean;
  onMouseDown: (event: React.MouseEvent) => void;
};
