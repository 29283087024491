import React, { useState } from 'react';

import { NodeConstraints } from '@syncfusion/ej2-react-diagrams';
import { useTranslation } from 'react-i18next';

import { HORIZONTAL } from '../../../assets/constants/constants';
import { createLane, getNewLaneId, getSwimlaneNode, resetSwimlane } from '../../../assets/js/Utils';
import Icon from '../Icon/Icon';
import styles from './SwimlaneTools.module.scss';

const SwimlaneTools = ({ diagramInstance, toggleCanSelect }) => {
  const { t } = useTranslation();
  const swimlane = getSwimlaneNode(diagramInstance);
  const isHorizontal = swimlane.shape.orientation === HORIZONTAL;
  const selection = diagramInstance.selectedItems.nodes.filter((n) => n.id.includes('swimlane'));
  const [isSwimlaneLocked, setIsSwimlaneLocked] = useState(
    (swimlane.constraints & NodeConstraints.Select) !== NodeConstraints.Select,
  );

  const buttons = [
    {
      id: isSwimlaneLocked ? 'unlockSwimlane' : 'lockSwimlane',
      onClick: () => {
        const isLocked = !isSwimlaneLocked;
        const swimlaneNodes = diagramInstance.nodes.filter((node) => node.id.includes('swimlane'));

        swimlaneNodes.forEach((node) => {
          if (isLocked) {
            node.constraints &= ~(NodeConstraints.Select | NodeConstraints.PointerEvents);
          } else {
            node.constraints |= NodeConstraints.Select | NodeConstraints.PointerEvents;
          }
        });

        setIsSwimlaneLocked(isLocked);
      },
      iconClass: `di icon-schloss-${isSwimlaneLocked ? '' : 'ent'}sperren`,
    },
    {
      id: 'addLane',
      onClick: () => {
        const currentSelection = diagramInstance.selectedItems.nodes.filter((n) => n.id.includes('swimlane'));
        const lanes = diagramInstance.nodes.filter((n) => n.isLane && !n.id.includes('_header'));
        let position = 0;

        if (currentSelection[0]?.isLane) {
          position = isHorizontal ? currentSelection[0].rowIndex - 1 : currentSelection[0].columnIndex;
        }
        const newLane = createLane({ rowNumber: getNewLaneId(lanes), orientation: swimlane.shape.orientation });
        diagramInstance.addLanes(swimlane, [newLane], position);
        resetSwimlane(diagramInstance, toggleCanSelect);
      },
      iconClass: `di icon-${isHorizontal ? 'zeilen' : 'spalten'}-hinzufuegen`,
    },
    {
      id: 'deleteLane',
      onClick: () => {
        const currentSelection = diagramInstance.selectedItems.nodes.filter((n) => n.id.includes('swimlane'));
        const laneId = currentSelection[0].id.slice(0, -1);
        const lane = swimlane.shape.lanes.find((l) => laneId.includes(l.id));
        diagramInstance.clearSelection();
        diagramInstance.removeLane(swimlane, lane);
        // This line forces the deletion of the lane which otherwise fails since syncfusion saves that value
        delete swimlane.wrapper.children[0].childTable[currentSelection[0].id];
      },
      iconClass: `di icon-${isHorizontal ? 'zeilen' : 'spalten'}-loeschen`,
      // TODO: enable deleteLane(0) to Vertical Swimlanes when syncfusion fixes it
      disabled: selection.length !== 1 || !selection[0].isLane || (!isHorizontal && selection[0].columnIndex === 0),
    },
    {
      id: 'removeSwimlane',
      onClick: () => diagramInstance.remove(swimlane),
      iconClass: 'di icon-schliessen',
    },
  ];

  return (
    <div className={styles.SwimlaneButtons} id="swimlane-buttons">
      {buttons.map((button) => (
        <div className={`${styles.SwimlaneButton} ${button.disabled ? styles.Disabled : ''}`} key={button.id}>
          <Icon
            disabled={button.disabled}
            extraClass="sandbox-tooltip"
            iconClass={button.iconClass}
            id={button.id}
            key={button.id}
            offsetY={-10}
            onClick={(!button.disabled && button.onClick) || null}
            tooltip={t(`swimlanes.tools.${button.id}`)}
          />
        </div>
      ))}
    </div>
  );
};

export default SwimlaneTools;
