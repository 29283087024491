/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,
  react/sort-comp, max-classes-per-file, no-return-assign */
import React from 'react';

import { Inject, DiagramComponent, Snapping } from '@syncfusion/ej2-react-diagrams';
import { withTranslation } from 'react-i18next';

import TitleBar from '../../components/TitleBar/TitleBar';
import Button from '../../components/UI/Button/Button';
import titleService from '../../services/titleService';
import styles from './DiagramCustom.module.scss';

const interval = [
  1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
];
const gridlines = { lineColor: '#e0e0e0', lineIntervals: interval };

class DiagramCustom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.diagramInstance = null;
    this.refTextArea = React.createRef();
    this.state = {
      errorMessage: '',
      isTextAreaOpen: false,
    };
  }

  componentDidMount() {
    titleService.updatePageTitle('Custom Diagram');
  }

  changeDiagram = () => {
    this.diagramInstance.clear();
    const newDiagram = this.refTextArea.current.value;

    try {
      this.diagramInstance?.loadDiagram(newDiagram);
    } catch (error) {
      this.setState({ errorMessage: 'This is not a valid JSON.' });
    }
  };

  handleChange = () => {
    this.setState({ errorMessage: '' });
  };

  toggleTextArea = () => {
    this.setState({ isTextAreaOpen: !this.state.isTextAreaOpen });
  };

  render() {
    const hasError = this.state.errorMessage !== '';

    return (
      <>
        <TitleBar />
        <div className={styles.Diagram}>
          <div className="diagram-content">
            <DiagramComponent
              id="diagram"
              ref={(diagram) => (this.diagramInstance = diagram)}
              snapSettings={{
                horizontalGridlines: gridlines,
                verticalGridlines: gridlines,
              }}
            >
              <Inject services={[Snapping]} />
            </DiagramComponent>
          </div>
        </div>
        <div className={`${styles.TextSection} ${this.state.isTextAreaOpen ? styles.Expanded : ''}`}>
          <div className={`toggleButton ${styles.Expander}`} onClick={this.toggleTextArea}>
            <i className="fas fa-angle-double-right" />
          </div>
          <p>
            <span>Change the diagram JSON here:</span>
            {hasError && <span className={styles.ErrorMessage}>{this.state.errorMessage}</span>}
          </p>
          <div className={`${styles.TextArea} ${hasError ? styles.Error : ''}`}>
            <textarea onChange={this.handleChange} placeholder="Diagram JSON" ref={this.refTextArea} />
            <Button
              btnText="> Change Diagram"
              buttonStyle="Primary"
              custom="custom"
              handleClick={this.changeDiagram}
              id="changeDiagram"
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('common')(DiagramCustom);
