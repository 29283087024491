import iconsFilesDisplay from 'services/useFormService';
import { ActivityAditionalInformation, DocLinkTypes, Document, DocumentResponse, Link } from 'types/activitySpecification';
import { Chip } from 'types/forms';

export const mapFieldToLink = (field: Link, type: DocLinkTypes): Link => ({
  name: field.name,
  type,
  value: field.value,
});

export const mapFieldToDoc = (field: DocumentResponse, type: DocLinkTypes): Document => ({
  name: field.name,
  type,
  value: field.lastModificationDate,
});

export const mapLinkToChip = (link: Link): Chip => ({
  code: ActivityAditionalInformation.LINK,
  displayName: link.name,
  link: link.value,
  icon: 'di icon-link',
});

export const mapDocumentToChip = (document: Document): Chip => ({
  code: ActivityAditionalInformation.FILE,
  displayName: document.name,
  icon: iconsFilesDisplay(document.name || ''),
});
