import { useEffect, useState } from 'react';

import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import { AccordionStatus, TableVariant } from 'types/tables';

import styles from './Accordion.module.scss';

type Props = {
  checked?: Record<string, boolean>;
  children: React.ReactNode;
  handleCheck?: () => void;
  kpiId?: string;
  name?: string;
  onlyRead?: boolean;
  subname?: string;
  status?: AccordionStatus;
  isExpandedByDefault?: boolean;
} & (
  | {
      variant?: TableVariant.PLAIN;
    }
  | {
      variant: TableVariant.CHECKBOX;
    }
  | {
      variant: TableVariant.RADIO;
    }
);

const Accordion = (props: Props) => {
  const {
    checked,
    children,
    handleCheck,
    kpiId,
    name,
    variant = TableVariant.PLAIN,
    onlyRead,
    subname,
    status,
    isExpandedByDefault,
  } = props;
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault || false);
  const statusClass: { [key in AccordionStatus]: string } = {
    UNTOUCHED: '',
    TOUCHED: `di icon-overflow-mehr-horizontal ${styles.Touched}`,
    COMPLETED: `di icon-check ${styles.Completed}`,
  };

  useEffect(() => {
    if (isExpandedByDefault) setIsExpanded(isExpandedByDefault);
  }, [isExpandedByDefault]);

  return (
    <div className={`${styles.Row} ${isExpanded ? styles.Expanded : ''} `}>
      <div className={`${styles.RowName} ${onlyRead ? styles.OnlyReadName : ''} ${status ? styles.Requirement : ''}`}>
        <div className={`${styles.Icon} ${status ? styles.Requirement : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
          <i className="di icon-pfeil-chevron-rechts" />
        </div>
        {variant === TableVariant.CHECKBOX && kpiId !== undefined && checked !== undefined && handleCheck !== undefined && (
          <Checkbox checked={checked[kpiId]} handleCheck={handleCheck} id={kpiId} />
        )}
        <div className={`${styles.NameWrapper} ${status ? styles.Requirement : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
          <span className={styles.Name}>{name}</span>
        </div>
        {!!status && (
          <>
            <div className={styles.SubnameWrapper} onClick={() => setIsExpanded(!isExpanded)}>
              <span className={styles.Subname}>{subname}</span>
            </div>
            <div className={styles.StatusWrapper} onClick={() => setIsExpanded(!isExpanded)}>
              <i className={`${styles.Status} ${statusClass[status]} `} />
            </div>
          </>
        )}
      </div>
      {isExpanded && children}
    </div>
  );
};

export default Accordion;
