import * as Constants from 'assets/constants/constants';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import documentationService from 'services/documentationService';

const uploadImage = async (args) => {
  const { file, fileData, setLoading = () => {}, t } = args;

  try {
    if (!file) {
      return;
    }

    if (file.size >= Constants.IMAGE_SIZE_LIMIT) throw new Error('imageSizeExceed');

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const { attributeName, idDiagram, idSymbolFront, language, version } = fileData;

    if (attributeName) {
      formData.append('attributeName', attributeName);
      formData.append('language', language);
    }

    if (idSymbolFront) {
      formData.append('idSymbolFront', idSymbolFront);
    }
    formData.append('idDiagram', idDiagram);
    formData.append('version', version);
    const response = await documentationService.uploadImage(formData);
    setLoading(false);

    return response;
  } catch (err) {
    if (err.message === 'imageSizeExceed') {
      Dialog.showAlert({
        name: 'Error',
        message: t('imageSizeExceed'),
        isError: true,
      });
    } else {
      setLoading(false);
      documentationService.handleServiceError(err);
    }
  }
};

// TODO: remove this method when all base64 inserted images have been converted
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export default {
  dataURLtoFile,
  uploadImage,
};
