import { ReactNode, useLayoutEffect, useRef } from 'react';

import styles from './ScrollBox.module.scss';

type ScrollBoxProps = {
  children: ReactNode;
  customClassName?: string;
};

const ScrollBox = ({ children, customClassName }: ScrollBoxProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!ref.current) return;

      const hasOverFlow = ref.current.scrollHeight > ref.current.clientHeight;
      if (!hasOverFlow) return;

      if (
        ref.current.previousSibling &&
        ref.current.previousSibling instanceof HTMLDivElement &&
        !ref.current.previousSibling.style.boxShadow
      ) {
        ref.current.previousSibling.style.boxShadow = '0 10px 10px -10px rgba(0, 0, 0, 0.2)';
        ref.current.previousSibling.style.zIndex = '2';
      }

      if (
        ref.current.nextSibling &&
        ref.current.nextSibling instanceof HTMLDivElement &&
        !ref.current.nextSibling.style.boxShadow
      ) {
        ref.current.nextSibling.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.2)';
        ref.current.nextSibling.style.zIndex = '2';
      }
    }, 0);
  }, []);

  return (
    <div className={`${styles.ScrollBox} ${customClassName}`} ref={ref}>
      {children}
    </div>
  );
};

export default ScrollBox;
