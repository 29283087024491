import React from 'react';

import styles from './RecycleBinRow.module.scss';

const RecycleBinRow = ({ isHeader, element, handleRowClick, handleCheckAll, sortTable, direction, fieldToOrder }) => {
  const checked = element.checked ? styles.Checked : '';
  const handleClick = isHeader ? handleCheckAll : handleRowClick;

  const getSortedClass = (field) => {
    return fieldToOrder === field ? styles[direction] : '';
  };

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${checked} ${styles.ListItem}`}>
        <th className={styles.Checkbox}>
          <span className={styles.Checkmark} onClick={() => handleCheckAll()} />
        </th>
        <th className={`${styles.Name} ${getSortedClass('diagramName')}`} onClick={() => sortTable('diagramName')}>
          {element.diagramName}
        </th>
        <th className={`${styles.Deleter} ${getSortedClass('deleterName')}`} onClick={() => sortTable('deleterName')}>
          {element.deleterName}
        </th>
        <th className={`${styles.Type} ${getSortedClass('typeName')}`} onClick={() => sortTable('typeName')}>
          {element.typeName}
        </th>
        <th className={`${styles.Date} ${getSortedClass('initialBinDate')}`} onClick={() => sortTable('initialBinDate')}>
          {element.initialBinDate}
        </th>
        <th className={`${styles.FinalDeletion} ${getSortedClass('finalBinDate')}`} onClick={() => sortTable('finalBinDate')}>
          {element.finalBinDate}
        </th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={`${styles.ListItem} ${checked}`} onClick={() => handleClick(element.id)}>
      <td className={styles.Checkbox}>
        <span className={styles.Checkmark} />
      </td>
      <td className={styles.Name}>{element.diagramName}</td>
      <td className={styles.Deleter}>{element.deleterName}</td>
      <td className={styles.Type}>{element.typeName}</td>
      <td className={styles.Date}>{element.initialBinDate}</td>
      <td className={styles.FinalDeletion}>{element.finalBinDate}</td>
    </tr>
  );
};

export default RecycleBinRow;
