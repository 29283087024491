import RoundButton from 'components/UI/RoundButton/RoundButton';

import styles from './DialogHeader.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  closeIcon?: boolean;
  handleClose?: () => void;
}

const DialogHeader = ({ children, className = '', closeIcon = false, handleClose = () => {} }: Props) => (
  <div className={`${styles.Container} ${className}`}>
    <div className={styles.Title}>{children}</div>
    {closeIcon && <RoundButton className={styles.Close} icon="icon-schliessen" id="close-dialog" onClick={handleClose} />}
  </div>
);

export default DialogHeader;
