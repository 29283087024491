import { createContext, useReducer, Dispatch, ReactNode } from 'react';

import { ProcessDataCatalog } from 'types/processes';
import { ColumnsConfig, ProcessLevel } from 'types/processLevel';

enum ActionTypes {
  SET_LOADING_PROCESS = 'SET_LOADING_PROCESS',
  SET_PROCESS_LEVEL_DATA = 'SET_PROCESS_LEVEL_DATA',
  SET_COLUMNS_CONFIG = 'SET_COLUMNS_CONFIG',
  SET_CATALOG = 'SET_CATALOG',
}

type Action =
  | { type: ActionTypes.SET_LOADING_PROCESS; payload: boolean }
  | { type: ActionTypes.SET_PROCESS_LEVEL_DATA; payload: ProcessLevel }
  | { type: ActionTypes.SET_COLUMNS_CONFIG; payload: ColumnsConfig }
  | { type: ActionTypes.SET_CATALOG; payload: ProcessDataCatalog };

// Action Creators
export const setLoadingProcess = (loading: boolean) => ({ type: ActionTypes.SET_LOADING_PROCESS, payload: loading } as Action);
export const setProcessLevel = (requirementData: ProcessLevel) =>
  ({ type: ActionTypes.SET_PROCESS_LEVEL_DATA, payload: requirementData } as Action);
export const setColumnsConfig = (columnsConfig: ColumnsConfig) =>
  ({ type: ActionTypes.SET_COLUMNS_CONFIG, payload: columnsConfig } as Action);
export const setCatalog = (catalog: ProcessDataCatalog) => ({ type: ActionTypes.SET_CATALOG, payload: catalog } as Action);

type State = {
  processLevelData?: ProcessLevel;
  isLoading: boolean;
  savedColumnsConfig?: ColumnsConfig;
  catalog?: ProcessDataCatalog;
};

const initialState: State = {
  isLoading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING_PROCESS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SET_PROCESS_LEVEL_DATA:
      return {
        ...state,
        processLevelData: action.payload,
      };
    case ActionTypes.SET_COLUMNS_CONFIG:
      return {
        ...state,
        savedColumnsConfig: action.payload,
      };
    case ActionTypes.SET_CATALOG:
      return {
        ...state,
        catalog: action.payload,
      };
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface IProcessLevelContext {
  state: State;
  dispatch: Dispatch<Action>;
}

const ProcessLevelContext = createContext<IProcessLevelContext | undefined>(undefined);

const ProcessLevelProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ProcessLevelContext.Provider value={value}>{children}</ProcessLevelContext.Provider>;
};

export { ActionTypes as ProcessLevelActionTypes, ProcessLevelContext, ProcessLevelProvider };
