import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';

import stylesNepos from './DropdownNEPOS.module.scss';
import stylesLegacy from './DropdownNEPOSLegacyStyle.module.scss';

type DropdownNEPOSProps = {
  className?: string;
  code: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label?: string;
  legacyStyles?: boolean;
  onChange: (option: string) => void;
  options: Array<string>;
  optionTranslationKey?: string;
  panelClassName?: string;
  required?: boolean;
  value: string;
  onBlur?: () => void;
};

const DropdownNEPOS = (props: DropdownNEPOSProps) => {
  const {
    className = '',
    code,
    disabled,
    error,
    id,
    label,
    legacyStyles,
    onChange,
    options,
    optionTranslationKey,
    panelClassName = '',
    required,
    value,
    onBlur = () => {},
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const containerRef = useRef<HTMLLabelElement>(null);
  const currentContainerRef = containerRef || newContainerRef;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;

  const hasError = Boolean(error);

  const getTranslation = (option: string) =>
    t(`${optionTranslationKey || `attributes.${code}.options`}?.${option}`, { defaultValue: option });

  const handleClickDropdown = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const optionSelected = (option: any) => {
    setIsOpen(false);
    onChange(option);
  };

  return (
    <>
      <label
        className={` ${hasError ? styles.ErrorLine : ''} ${styles.Container} ${isOpen ? styles.Focused : ''} ${
          disabled ? styles.Disabled : ''
        }  ${className || ''}`}
        htmlFor={id}
        onClick={() => handleClickDropdown()}
        ref={currentContainerRef}
      >
        <div className={`${hasError ? 'error' : ''} ${styles.Label} ${value?.trim() !== '' ? styles.Floating : ''}`}>
          {label}
          {required && !disabled && <span className="error">*</span>}
        </div>
        <div
          className={`${styles.Input} ${styles.Dropdown} ${isOpen ? styles.Open : ''} ${disabled ? styles.Disabled : ''}`}
          id={id}
          ref={boxRef}
        >
          <span>{getTranslation(value)}</span>
          <div>
            {hasError && <i className={`di icon-blitz-fehler error ${styles.IconError}`} />}
            {!disabled && <i className={`fas fa-chevron-down ${styles.Icon}`} />}
          </div>
        </div>
      </label>
      {isOpen && (
        <DropdownPanel
          close={() => {
            onBlur();
            setIsOpen(false);
          }}
          parentRef={currentContainerRef}
        >
          <div className={`${styles.Options} ${panelClassName}`}>
            {options.map((option) => (
              <div
                className={`${styles.Option} ${option === value ? styles.Selected : ''}`}
                key={option}
                onClick={() => optionSelected(option)}
              >
                {getTranslation(option)}
              </div>
            ))}
          </div>
        </DropdownPanel>
      )}
    </>
  );
};

export default DropdownNEPOS;
