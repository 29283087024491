import { RefObject, useRef } from 'react';

import {
  ATTRIBUTE_CARES,
  DATA_SET_FORMS,
  DIAGRAM,
  DS,
  SWIMLANE_DIAGRAM,
  SYMBOL,
  RR,
  NOT_TRANSLATABLE,
} from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDatasetContext from 'hooks/useDatasetContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import useFormTypes from 'hooks/useFormTypes';
import useRequirementContext from 'hooks/useRequirementContext';
import { ObjectTypes } from 'types/administration';
import { Language } from 'types/config';
import {
  AttributeCode,
  AttributeError,
  AttributeFormTypes,
  Attributes,
  AttributeValue,
  FormLanguage,
  FormTypes,
  LanguageAttributes,
  LanguageFormTypes,
  SymbolError,
  SymbolObjectError,
} from 'types/forms';
import { RequirementFormsViewsVariant } from 'types/requirement';
import { SwimlaneV2 } from 'types/swimlanes';
import { Symbol, SymbolTypes } from 'types/symbols';

enum ErrorTypes {
  DIAGRAM_ERROR = 'DIAGRAM',
  SWIMLANE = 'SWIMLANE',
  SYMBOL_ERROR = 'SYMBOL',
  START_EVENT = 'START_EVENT',
  LAST_EVENT = 'LAST_EVENT',
  SYMBOL_OBJECTS = 'SYMBOL_OBJECTS',
  PROCESS_STEP_NUMBER = 'PROCESS_STEP_NUMBER',
}

export default function useValidation() {
  const { getFormTypesCode, ...formTypes } = useFormTypes();
  const { processData, dispatch, isValidationActive } = useDiagramContext();
  const { handleServiceError } = useError();
  const { datasetData } = useDatasetContext();
  const { requirementData } = useRequirementContext();
  const hasDiagramErrors = useRef<boolean>(false);
  const symbolsIdsWithErrors = useRef<string[]>([]);
  const symbolObjectsIdsWithErrors = useRef<SymbolObjectError[]>([]);
  const processStepsIdsWithErrors = useRef<string[]>([]);
  const swimlaneRoleError = useRef<string[]>([]);
  const hasStartEvent = useRef<boolean>(false);
  const hasLastEvent = useRef<boolean>(false);
  const hasProcessStepNumberError = useRef<boolean>(false);
  const diagramLanguageErrors = useRef<Language[]>([]);
  const hasDatasetErrors = useRef<boolean>(false);
  const hasRequirementErrors = useRef<boolean>(false);

  // For attributes that have to be validated in both errors type validation (Diagram / Symbol) (example: Language)
  const checkPreviousRef = (currentRef: RefObject<any[]>, newRefValues: any[]): RefObject<any[]> => {
    if (!newRefValues || newRefValues.length === 0) return currentRef;
    if ((typeof currentRef.current !== 'boolean' && !currentRef.current) || currentRef.current.length === 0) {
      return { current: newRefValues };
    }

    const refsValues = [...currentRef.current, ...newRefValues];
    const checkedRefs = [...new Set(refsValues)];
    return { current: checkedRefs };
  };

  const isStringArrayValid = (attribute: AttributeValue) => {
    const values = (attribute as string)?.substring(1, (attribute as string).length - 1).split(',');
    const notEmptyValues = values.filter((arraElement) => arraElement.length > 0);
    return notEmptyValues.length > 0 && notEmptyValues.length === values.length;
  };

  const currentSymbolsByDiagramType = () =>
    processData?.isSwimlane
      ? [...new Set([...processData?.swimlanes.map((swimlane: SwimlaneV2) => swimlane.symbols).flat(), ...processData.symbols])]
      : processData?.symbols;

  const getAttributeErrors = (
    attributesFormTypes: FormTypes,
    attributes: Attributes | undefined = undefined,
  ): AttributeError[] => {
    if (!attributes) return [];
    const attributeErrors: AttributeError[] = [];

    Object.keys(attributesFormTypes).forEach((language) => {
      const attributesByLanguage: LanguageFormTypes | undefined = attributesFormTypes[language as FormLanguage];
      if (!attributesByLanguage) return;

      Object.keys(attributesByLanguage).forEach((code) => {
        const attributeType: AttributeFormTypes | undefined = attributesByLanguage[code as AttributeCode];
        if (!attributeType || attributeType?.care !== ATTRIBUTE_CARES.MANDATORY) return;

        const languageAttribute: LanguageAttributes | undefined = attributes[language as FormLanguage];
        if (!languageAttribute) return;

        const currentAttribute: AttributeValue | undefined = languageAttribute[code as AttributeCode];

        const isStringArray = typeof currentAttribute === 'string' && currentAttribute.indexOf('[' && ']') === 1;
        const isValidValue = isStringArray
          ? isStringArrayValid(currentAttribute)
          : (currentAttribute?.length && currentAttribute.length > 0) || typeof currentAttribute === 'number';

        if (!isValidValue) attributeErrors.push({ language: language as Language, code });
      });
    });

    return [...new Set(attributeErrors)];
  };

  const getSymbolsAttributesErrors = (attributesConf: { [key in SymbolTypes]?: FormTypes }): SymbolError[] => {
    if (!attributesConf) return [];

    const filteredSymbols =
      currentSymbolsByDiagramType()?.filter(
        (symbol) =>
          symbol.type !== SymbolTypes.AND_GATE && symbol.type !== SymbolTypes.OR_GATE && symbol.type !== SymbolTypes.XOR_GATE,
      ) || [];
    const multipleElementsErrors: SymbolError[] = [];

    filteredSymbols?.forEach((symbol) => {
      const symbolFormTypes = attributesConf[symbol.type];
      if (!symbol.type || !symbolFormTypes) return;

      const attributesErrors = getAttributeErrors(symbolFormTypes, symbol.attributes);
      attributesErrors.forEach((attribute) => multipleElementsErrors.push({ id: symbol.id, attribute }));
    });

    return [...new Set(multipleElementsErrors)];
  };
  const getProcessStepNumberErrors = (attributesConf: { [key in SymbolTypes]?: FormTypes }): SymbolError[] => {
    if (!attributesConf) return [];

    const filteredSymbols =
      currentSymbolsByDiagramType()?.filter(
        (symbol) =>
          symbol.type !== SymbolTypes.AND_GATE && symbol.type !== SymbolTypes.OR_GATE && symbol.type !== SymbolTypes.XOR_GATE,
      ) || [];
    const multipleElementsErrors: SymbolError[] = [];

    filteredSymbols
      .filter((symbol) => symbol.type === SymbolTypes.PROCESS_STEP)
      .sort((a, b) => {
        const first = parseInt(a.attributes?.[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER as string, 10);
        const second = parseInt(b.attributes?.[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER as string, 10);
        if (first && second) return first > second ? 1 : -1;
        return 1;
      })
      .forEach((element, index) => {
        const orderProcessStep = element.attributes?.[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER
          ? parseInt(element.attributes?.[NOT_TRANSLATABLE]?.PROCESS_STEP_NUMBER as string, 10)
          : -1;
        if (orderProcessStep !== index + 1) {
          multipleElementsErrors.push({
            id: element.id,
            attribute: { language: NOT_TRANSLATABLE, code: AttributeCode.PROCESS_STEP_NUMBER },
          });
          return [...new Set(multipleElementsErrors)];
        }
      });

    return [...new Set(multipleElementsErrors)];
  };

  const getSymbolsObjectsErrors = (): SymbolObjectError[] => {
    const symbols = currentSymbolsByDiagramType();
    const symbolObjectsErrors: SymbolObjectError[] = [];

    symbols?.forEach((symbol) => {
      symbol.objects?.forEach((object) => {
        if (
          (object.type === ObjectTypes.ROLE_SWIMLANE || object.type === ObjectTypes.IT_SYSTEM_SWIMLANE) &&
          object.id !== undefined &&
          !object.approved
        )
          symbolObjectsErrors.push({ id: object.id.toString(), symbolId: symbol.id });
      });
    });

    return symbolObjectsErrors;
  };

  const setErrors = (attributesConfig: FormTypes | { [key in SymbolTypes]?: FormTypes }, type: string) => {
    if (!attributesConfig) return;

    const setSymbolsErrors = () => {
      const idMapper = (element: { id?: string }) => (element.id ? element.id : '');

      const symbolsErrors: SymbolError[] = getSymbolsAttributesErrors(attributesConfig as { [key in SymbolTypes]?: FormTypes });
      const processStepNumberErrors: SymbolError[] = getProcessStepNumberErrors(
        attributesConfig as { [key in SymbolTypes]?: FormTypes },
      );
      const processStepNumberErrorsIds: string[] = [...new Set(processStepNumberErrors.map(idMapper))];

      const swimlaneRoleErrors: string[] =
        processData?.swimlanes.filter((swimlane: SwimlaneV2) => !swimlane.role?.approved).map(idMapper) || [];

      const symbolObjectsErrors: SymbolObjectError[] = processData?.isSwimlane ? getSymbolsObjectsErrors() : [];
      const objectsErrorsSymbolsIds: string[] = symbolObjectsErrors.map(idMapper);

      const processStepsWithoutActivitySpecification: string[] =
        (processData?.isSwimlane &&
          currentSymbolsByDiagramType()
            ?.filter(
              (symbol) => symbol.type === SymbolTypes.PROCESS_STEP && !(typeof symbol.activitySpecificationId === 'number'),
            )
            .map(idMapper)) ||
        [];

      const basicSymbolErrors: string[] = [...new Set(symbolsErrors.map(idMapper))];
      const symbolsWithErrorsIds: string[] =
        objectsErrorsSymbolsIds.length > 0 || processStepsWithoutActivitySpecification.length > 0
          ? [...new Set([...basicSymbolErrors, ...objectsErrorsSymbolsIds, ...processStepsWithoutActivitySpecification])]
          : basicSymbolErrors;

      const isAnyStartEvent: boolean =
        (processData?.isSwimlane &&
          currentSymbolsByDiagramType()
            ?.filter((symbol) => symbol.type === SymbolTypes.EVENT)
            .find((symbol) => symbol.attributes.NOT_TRANSLATABLE?.START_EVENT === 'true') !== undefined) ||
        false;

      const isAnyLastEvent: boolean =
        (processData?.isSwimlane &&
          currentSymbolsByDiagramType()
            ?.filter((symbol) => symbol.type === SymbolTypes.EVENT)
            .find((symbol) => symbol.attributes.NOT_TRANSLATABLE?.LAST_EVENT === 'true') !== undefined) ||
        false;

      const languagesErrors = [...new Set(symbolsErrors.map((symbolError) => symbolError.attribute.language))];

      hasStartEvent.current = isAnyStartEvent;
      hasLastEvent.current = isAnyLastEvent;
      hasProcessStepNumberError.current = isAnyLastEvent || isAnyStartEvent;
      swimlaneRoleError.current = swimlaneRoleErrors;
      symbolsIdsWithErrors.current = symbolsWithErrorsIds.length ? symbolsWithErrorsIds : [];
      symbolObjectsIdsWithErrors.current = symbolObjectsErrors.length ? symbolObjectsErrors : [];
      processStepsIdsWithErrors.current = processStepNumberErrorsIds.length ? processStepNumberErrorsIds : [];
      diagramLanguageErrors.current = checkPreviousRef(diagramLanguageErrors, languagesErrors).current as Language[];
    };

    const setDiagramErrors = () => {
      const diagramErrors: AttributeError[] = getAttributeErrors(attributesConfig as FormTypes, processData?.attributes);
      const languagesErrors = [...new Set(diagramErrors.map((attributesError: AttributeError) => attributesError.language))];
      hasDiagramErrors.current = diagramErrors.length > 0;
      diagramLanguageErrors.current = checkPreviousRef(diagramLanguageErrors, languagesErrors).current as Language[];
    };

    const setDatasetErrors = () => {
      const datasetErrors: AttributeError[] = getAttributeErrors(attributesConfig as FormTypes, datasetData?.attributes);
      hasDatasetErrors.current = datasetErrors.length > 0;
    };

    const setRequirementErrors = () => {
      const requirementErrors: AttributeError[] = getAttributeErrors(attributesConfig as FormTypes, requirementData?.attributes);
      hasRequirementErrors.current = requirementErrors.length > 0;
    };

    if (type === DIAGRAM) setDiagramErrors();
    else if (type === DS) setDatasetErrors();
    else if (type === RR) setRequirementErrors();
    else setSymbolsErrors();
  };

  const getErrors = (type: string, variant?: string, isRequirement?: boolean) => {
    if (type === SYMBOL) {
      const currentSymbols: Symbol[] | undefined = currentSymbolsByDiagramType();
      const symbolTypes: SymbolTypes[] | undefined = currentSymbols?.map((symbol: Symbol) => symbol.type);
      if (!symbolTypes || symbolTypes?.length === 0) return;

      let attributesMultipleConfig = {};
      symbolTypes.forEach((newVariant: string) => {
        const formCode = getFormTypesCode({
          status: processData?.status,
          type,
          variant: newVariant,
        });
        attributesMultipleConfig = { ...attributesMultipleConfig, [newVariant]: formTypes[formCode] };
      });

      if (!attributesMultipleConfig) return;
      setErrors(attributesMultipleConfig, type);
    }

    if (!variant) return;
    let status;
    switch (type) {
      case DIAGRAM:
        status = processData?.status;
        break;
      case DS:
        status = datasetData?.status;
        break;
      default:
        status = requirementData?.status;
    }
    const formCode = getFormTypesCode({
      status,
      type,
      variant,
      isRequirement,
    });

    const attributesConfig = formTypes[formCode];
    if (!attributesConfig) return;
    setErrors(attributesConfig, type);
  };

  const isDiagramValid = (): boolean | undefined => {
    if (!processData) return;

    diagramLanguageErrors.current = [];

    getErrors(SYMBOL);
    getErrors(DIAGRAM, processData.type);

    if (
      processData.type === SWIMLANE_DIAGRAM &&
      !hasDiagramErrors.current &&
      symbolsIdsWithErrors.current.length === 0 &&
      symbolObjectsIdsWithErrors.current.length === 0 &&
      swimlaneRoleError.current.length === 0 &&
      processStepsIdsWithErrors.current.length !== 0 &&
      hasLastEvent.current &&
      hasStartEvent.current
    ) {
      dispatch({
        type: DiagramActionTypes.SET_PROCESS_STEP_WITH_ERRORS,
        payload: processStepsIdsWithErrors.current,
      });
    } else {
      dispatch({
        type: DiagramActionTypes.SET_PROCESS_STEP_WITH_ERRORS,
        payload: [],
      });
    }
    const isValid =
      processData.type === SWIMLANE_DIAGRAM
        ? !hasDiagramErrors.current &&
          symbolsIdsWithErrors.current.length === 0 &&
          symbolObjectsIdsWithErrors.current.length === 0 &&
          swimlaneRoleError.current.length === 0 &&
          processStepsIdsWithErrors.current.length === 0 &&
          hasLastEvent.current &&
          hasStartEvent.current
        : !hasDiagramErrors.current && symbolsIdsWithErrors.current.length === 0;

    dispatch({
      type: DiagramActionTypes.SET_DIAGRAM_VALIDATION,
      payload: !isValid,
    });

    if (!isValid) {
      if (processData.isSwimlane && !hasStartEvent.current) {
        dispatch({
          type: DiagramActionTypes.SET_ERROR,
          payload: { title: 'error', message: 'errors.missingStartEvent' },
        });
      } else if (processData.isSwimlane && !hasLastEvent.current) {
        dispatch({
          type: DiagramActionTypes.SET_ERROR,
          payload: { title: 'error', message: 'errors.missingLastEvent' },
        });
      } else if (
        processData.isSwimlane &&
        processStepsIdsWithErrors.current?.length > 0 &&
        !hasDiagramErrors.current &&
        symbolsIdsWithErrors.current?.length === 0 &&
        swimlaneRoleError.current?.length === 0 &&
        symbolObjectsIdsWithErrors.current?.length === 0
      ) {
        dispatch({
          type: DiagramActionTypes.SET_ERROR,
          payload: { title: 'error', message: 'diagram.processStepNumberError' },
        });
      } else {
        handleServiceError({ response: { data: { code: 'diagram.emptyMandatoryAttributes' } } }, { shouldNotShowCode: true });
      }
    }

    return isValid;
  };

  const triggerValidation = (type: string, variant: string | undefined, errorsToCheck: ErrorTypes) => {
    if (!isValidationActive) return;
    diagramLanguageErrors.current = [];

    const triggerErrorTypeAction: { [key in ErrorTypes]: string[] | boolean | SymbolObjectError[] } = {
      [ErrorTypes.DIAGRAM_ERROR]: hasDiagramErrors.current as boolean,
      [ErrorTypes.SYMBOL_ERROR]: symbolsIdsWithErrors.current as string[],
      [ErrorTypes.SWIMLANE]: swimlaneRoleError.current as string[],
      [ErrorTypes.SYMBOL_OBJECTS]: symbolObjectsIdsWithErrors.current as SymbolObjectError[],
      [ErrorTypes.START_EVENT]: !hasStartEvent.current as boolean,
      [ErrorTypes.LAST_EVENT]: !hasLastEvent.current as boolean,
      [ErrorTypes.PROCESS_STEP_NUMBER]: processStepsIdsWithErrors.current as string[],
    };

    getErrors(type, variant || '');
    return triggerErrorTypeAction[errorsToCheck];
  };

  const handleLanguageErrors = () => {
    if (!isValidationActive) return;
    diagramLanguageErrors.current = [];

    getErrors(SYMBOL);
    getErrors(DIAGRAM, processData?.type);

    return diagramLanguageErrors.current;
  };

  const isDatasetValid = (): boolean | undefined => {
    getErrors(DS, DATA_SET_FORMS.COMMON);
    const isValid = !hasDatasetErrors.current;

    dispatch({
      type: DiagramActionTypes.SET_DIAGRAM_VALIDATION,
      payload: !isValid,
    });

    return isValid;
  };

  const isRequirementValid = (variant: RequirementFormsViewsVariant): boolean | undefined => {
    getErrors(RR, variant, true);
    const isValid = !hasRequirementErrors.current;

    dispatch({
      type: DiagramActionTypes.SET_DIAGRAM_VALIDATION,
      payload: !isValid,
    });

    return isValid;
  };

  return {
    isValidationActive,
    isDiagramValid,
    isDatasetValid,
    isRequirementValid,
    hasDiagramErrors: triggerValidation(DIAGRAM, processData?.type, ErrorTypes.DIAGRAM_ERROR) as boolean,
    symbolsIdsWithErrors: triggerValidation(SYMBOL, '', ErrorTypes.SYMBOL_ERROR) as string[],
    swimlaneErrors: triggerValidation(SYMBOL, '', ErrorTypes.SWIMLANE) as string[],
    symbolObjectsIdsWithErrors: triggerValidation(SYMBOL, '', ErrorTypes.SYMBOL_OBJECTS) as SymbolObjectError[],
    startEventErrors: triggerValidation(SYMBOL, '', ErrorTypes.START_EVENT) as boolean,
    lastEventErrors: triggerValidation(SYMBOL, '', ErrorTypes.LAST_EVENT) as boolean,
    processStepsNumberError: triggerValidation(SYMBOL, '', ErrorTypes.PROCESS_STEP_NUMBER) as string[],
    diagramLanguageErrors: handleLanguageErrors(),
  };
}
