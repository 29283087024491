import { useCallback, useContext } from 'react';

import { DiagramConfigActionTypes, DiagramConfigContext } from 'contexts/DiagramConfig/DiagramConfigContext';
import { DiagramConfig, ProcessType } from 'types/processes';

export default function useDiagramConfig() {
  const context = useContext(DiagramConfigContext);

  if (context === undefined) {
    throw new Error('useDiagramConfig must be used within a DiagramConfigProvider');
  }

  const { state, dispatch } = context;

  const setDiagramConfig = useCallback(
    (diagramConfig: DiagramConfig) => {
      if (state.symbols[Object.keys(diagramConfig.symbols)[0] as ProcessType]) return;
      dispatch({ type: DiagramConfigActionTypes.SET_DIAGRAM_CONFIG, payload: diagramConfig });
    },
    [dispatch, state.symbols],
  );

  return {
    ...state,
    setDiagramConfig,
  };
}
