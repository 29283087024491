import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';

import styles from './DialogBasics.module.scss';

type DialogConfirmProps = {
  cancelText?: string;
  children: ReactNode;
  close: () => void;
  confirmText?: string;
  handleConfirm: () => void;
  title: string | ReactNode;
  isLoading?: boolean;
};

const DialogConfirm = ({ cancelText, children, close, confirmText, handleConfirm, isLoading, title }: DialogConfirmProps) => {
  const { t } = useTranslation();
  const buttons = [
    {
      buttonStyle: BUTTON_SECONDARY,
      content: cancelText || t('cancel'),
      disabled: isLoading,
      handleClick: close,
      key: 'cancel',
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      content: confirmText || t('confirm'),
      handleClick: handleConfirm,
      isLoading,
      key: 'confirm',
    },
  ];

  return (
    <Dialog>
      <DialogHeader className={styles.Title}>{title}</DialogHeader>
      <div className={styles.Container}>{children}</div>
      <DialogFooter buttons={buttons} />
    </Dialog>
  );
};

export default DialogConfirm;
