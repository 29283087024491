import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { CATALOG_OBJECT_CREATION, DIAGRAM_ENVIRONMENTS, MODELING_STATUS, PUBLISHED_STATUS } from 'assets/constants/constants';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useForm from 'hooks/useForm';
import useFormTypes from 'hooks/useFormTypes';
import { Language } from 'types/config';
import { ProcessCatalogObject } from 'types/processes';

import styles from './AttributesModal.module.scss';

type Props = {
  onClose: () => void;
  modalTitle: string;
  object: ProcessCatalogObject;
};

const AttributesModal = ({ onClose, modalTitle, object }: Props) => {
  const { t, i18n } = useTranslation();
  const { processData, diagramLanguage } = useDiagramContext();
  const { getFormTypesCode, ...formTypes } = useFormTypes();
  const checkEnvironment = useEnvironment();

  const formTypesCode = useMemo(
    () =>
      getFormTypesCode({
        status: checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) ? PUBLISHED_STATUS : MODELING_STATUS,
        type: CATALOG_OBJECT_CREATION,
        variant: object.type,
      }),
    [getFormTypesCode, checkEnvironment, object],
  );

  const { fields, values } = useForm({
    formTypes: formTypes[formTypesCode],
    initialValues: object?.attributes,
    id: object?.id,
    catalog: processData?.catalog,
    usesDiagramTranslation: true,
  });

  return (
    <Dialog className={styles.ModalContainer}>
      <DialogHeader>{t(`${modalTitle}`)}</DialogHeader>
      <div className={styles.ModalBody}>
        {!!values[diagramLanguage] &&
          fields[i18n.language as Language]?.map(({ Component, ...fieldProps }) => (
            <Component disabled {...fieldProps} value={values[diagramLanguage]?.[fieldProps.code]} />
          ))}
      </div>
      <div className={styles.ButtonWrapper}>
        <ButtonNEPOS handleClick={onClose} id="new-request-button" isSecondary>
          {t('close')}
        </ButtonNEPOS>
      </div>
    </Dialog>
  );
};

export default React.memo(AttributesModal);
