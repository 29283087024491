import React, { useRef, useState } from 'react';

import { Inject } from '@syncfusion/ej2-react-diagrams';
import {
  HtmlEditor,
  Image as SyncfusionImage,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Table,
  ToolbarType,
  PasteCleanup,
} from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';

import documentationUtils from 'assets/js/documentationUtils';
import { purify } from 'assets/js/Utils';
import ErrorTooltip from 'components/UI/ErrorTooltip/ErrorTooltip';

import styles from './InputWysiwyg.module.scss';
import './InputWysiwyg.scss';

const InputWysiwyg = (props) => {
  const {
    className,
    deletedImages,
    description,
    diagramData = {},
    disabled,
    error,
    id,
    label,
    language,
    onChange,
    onDeleteImage,
    onInsertImage,
    placeholder,
    required,
    value,
    ...other
  } = props;
  const { idDiagram, idSymbolFront, version } = diagramData;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const rteObj = useRef(null);
  const currentUploadingImage = useRef(null);

  const toolbarSettings = {
    type: ToolbarType.Expand,
    enableFloating: false,
    items: [
      {
        template: `<button class="e-tbar-btn e-btn" tabindex="-1" id="${id}" style="width:100%"><div class="e-btn-icon e-dimension e-icons" style="font-weight: 500;" /></button>`,
        tooltipText: 'Expand Textarea',
      },
      '|',
      ...(!disabled && [
        'Image',
        'Bold',
        'Italic',
        'Underline',
        '|',
        'UnorderedList',
        'OrderedList',
        'Outdent',
        'Indent',
        'CreateLink',
        '|',
        'Alignments',
        'CreateTable',
        'superscript',
        'subscript',
      ]),
    ],
  };
  const pasteCleanupSettings = {
    plainText: true,
    deniedAttrs: ['class', 'title', 'id', 'style'],
  };

  // TODO: remove this method when all base64 inserted images have been converted
  const replaceBase64Images = async (val) => {
    const base64ImageTags = val?.match(/<img\s+[^>]*src="([^"]*)base64([^"]*)"[^>]*>/g);
    let parsedValue = val;

    if (base64ImageTags?.length) {
      const newUrls = await Promise.all(
        base64ImageTags.map(async (imageTag) => {
          const base64Url = imageTag.split('src="')[1].split('"')[0];
          const file = documentationUtils.dataURLtoFile(base64Url);
          const fileData = { attributeName: id, idSymbolFront, language, idDiagram, version };
          const response = await documentationUtils.uploadImage({ file, fileData, t });

          return { originalValue: base64Url, newValue: response.data };
        }),
      );

      newUrls.forEach(({ originalValue, newValue }) => {
        parsedValue = parsedValue.replace(originalValue, newValue);
      });
    }

    return parsedValue;
  };

  const create = async () => {
    let parsedValue = purify(value);
    rteObj.current.insertImageSettings.saveFormat = 'Blob';

    const expandBtn = document.getElementById(id);

    if (expandBtn) {
      expandBtn.onclick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(!isOpen);
      };
    }

    // TODO: remove this method call when all base64 inserted images have been converted
    if (!disabled) {
      parsedValue = await replaceBase64Images(parsedValue);
    }

    rteObj.current.value = parsedValue;

    if (parsedValue !== value) {
      onChange(parsedValue);
    }
  };

  const checkUndoDeleteImage = (val) => {
    const imageTags = val?.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);
    if (!imageTags) return;
    const restoredImageIndex = deletedImages.findIndex((imageUrl) => imageTags.some((imageTag) => imageTag.includes(imageUrl)));
    if (restoredImageIndex === -1) return;
    deletedImages.splice(restoredImageIndex, 1);
  };

  const handleChange = (event) => {
    checkUndoDeleteImage(event.value);
    onChange(event.value);
  };

  const dialogOpen = () => {
    currentUploadingImage.current = null;
  };

  const uploadImage = (args) => {
    const fileData = { attributeName: id, idSymbolFront, language, idDiagram, version };
    documentationUtils.uploadImage({ file: args.filesData[0].rawFile, fileData, t }).then((response) => {
      rteObj.current.imageModule.uploadUrl.url = response.data;
      onInsertImage(response.data);
      currentUploadingImage.current = response.data;
    });
  };

  const afterImageDelete = (event) => {
    if (!event.src) return;

    onDeleteImage(event.src);
  };

  const imageRemoving = () => {
    if (!currentUploadingImage.current) return;

    onDeleteImage(currentUploadingImage.current);
    currentUploadingImage.current = null;
  };

  return (
    <div className={`wysiwyg-wrapper ${styles.Container} ${error ? styles.Error : ''} ${isOpen ? 'expanded' : ''} ${className}`}>
      <div className={styles.LabelContainer}>
        <label className={`${styles.Label} ${error ? styles.Error : ''}`} htmlFor={id}>
          {label}
          {required ? '*' : ''}
        </label>
        <ErrorTooltip message={error} />
      </div>
      <RichTextEditorComponent
        afterImageDelete={afterImageDelete}
        change={handleChange}
        className="wysiwyg"
        created={create}
        dialogOpen={dialogOpen}
        imageRemoving={imageRemoving}
        imageSelected={uploadImage}
        key={id}
        pasteCleanupSettings={pasteCleanupSettings}
        readonly={disabled}
        ref={rteObj}
        saveInterval={1000}
        toolbarSettings={toolbarSettings}
        {...other}
      >
        <Inject services={[HtmlEditor, Toolbar, SyncfusionImage, Link, QuickToolbar, Table, PasteCleanup]} />
      </RichTextEditorComponent>
    </div>
  );
};

export default InputWysiwyg;
