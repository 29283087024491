import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import {
  ATTRIBUTE_CARES,
  DATA_SET_DIAGRAM_COMPONENT,
  DATA_SET_FORMS,
  DATA_SET_MODE,
  DS,
  MODELING_STATUS,
  PUBLISHED,
  PUBLISHED_STATUS,
} from 'assets/constants/constants';
import { getFullFormattedDate } from 'assets/js/Utils';
import VersionsDropDown from 'components/VersionsDropDown/VersionsDropDown';
import { DatasetActionTypes } from 'contexts/Dataset/DatasetContext';
import useBackendForm from 'hooks/useBackendForm';
import useDatasetContext from 'hooks/useDatasetContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useFormTypes from 'hooks/useFormTypes';
import { Language } from 'types/config';
import { Field } from 'types/forms';
import { ProcessType } from 'types/processes';

import styles from './DatasetForm.module.scss';

const DatasetForm = () => {
  const { t } = useTranslation();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const { diagramLanguage } = useDiagramContext();
  const { datasetData, dispatch } = useDatasetContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showVersions, setShowVersions] = useState(false);
  const location = useLocation();
  const checkEnvironment = useEnvironment();
  const environment = checkEnvironment(DATA_SET_MODE.PUBLISHED) ? PUBLISHED_STATUS : MODELING_STATUS;

  const formCode = getFormTypesCode({
    status: datasetData?.status,
    type: DS,
    variant: DATA_SET_FORMS.COMMON,
  });

  const { fields, values } = useBackendForm({
    formCode,
    catalog: datasetData?.catalog,
    initialValues: datasetData?.attributes,
    isTouchedNeeded: false,
    usesDiagramTranslation: true,
  });

  useEffect(() => {
    if (!datasetData?.status) return;
    fetchFormTypes(
      getFormTypesCode({
        status: datasetData?.status,
        type: ProcessType.DATA_SET,
      }),
    );
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length || !isLoading) return;
    setIsLoading(false);
  }, [dispatch, fields, isLoading, values]);

  useEffect(() => {
    if (!Object.keys(values).length) return;

    dispatch({ type: DatasetActionTypes.SET_DATASET_ATTRIBUTES, payload: values });
  }, [dispatch, values]);

  const datasetDiagramField = fields[diagramLanguage]?.find((elem: Field) => elem.type === DATA_SET_DIAGRAM_COMPONENT);
  const { fields: fieldsVCD } = useBackendForm({
    formCode: datasetDiagramField?.diagramVcdType ? datasetDiagramField?.diagramVcdType : '',
    initialValues: datasetData?.linkedDiagram?.attributes,
    catalog: datasetData?.catalog,
  });

  const { fields: fieldsEPC } = useBackendForm({
    formCode: datasetDiagramField?.diagramEpcType ? datasetDiagramField?.diagramEpcType : '',
    initialValues: datasetData?.linkedDiagram?.attributes,
    catalog: datasetData?.catalog,
  });

  const versionsElement = {
    id: datasetData?.id,
    versions:
      !datasetData?.versions || datasetData.versions.length === 0
        ? []
        : datasetData?.versions.map((element) => {
            return { user: element.author.commonName, version: element.version, date: getFullFormattedDate(element.validFrom) };
          }),
  };

  return (
    <>
      <div className={styles.Title}>
        <span className={styles.DatasetId}>{datasetData?.id}</span>
        {datasetData?.attributes[diagramLanguage]?.NAME ? datasetData?.attributes[diagramLanguage]?.NAME : t('dataset.title.new')}
        <span
          className={`${styles.Status} ${
            datasetData ? styles[datasetData?.dataSetInUse?.blocked ? styles[PUBLISHED] : datasetData.status] : ''
          }`}
        >
          {t(`${datasetData?.status}`)}
        </span>
      </div>
      {datasetData?.versions && (
        <VersionsDropDown
          element={versionsElement}
          setShowVersions={setShowVersions}
          showDate
          showLabel
          showVersions={showVersions}
        />
      )}
      <div className={styles.Form}>
        {fields[diagramLanguage as Language]?.map(({ Component, ...fieldProps }, index) => (
          <Component
            {...fieldProps}
            disabled={fieldProps.care === ATTRIBUTE_CARES.SYSTEM || datasetData?.dataSetInUse?.blocked}
            error={fields[diagramLanguage]?.[index].error}
            legacyStyles
          />
        ))}
        {datasetData?.linkedDiagram?.type === ProcessType.VCD &&
          fieldsVCD[diagramLanguage as Language]?.map(({ Component, ...fieldProps }, index) => (
            <Component
              disabled={environment === PUBLISHED_STATUS || datasetData?.dataSetInUse?.blocked}
              {...fieldProps}
              error={fieldsVCD[diagramLanguage]?.[index].error}
              legacyStyles
            />
          ))}
        {datasetData?.linkedDiagram?.type === ProcessType.EPC &&
          fieldsEPC[diagramLanguage as Language]?.map(({ Component, ...fieldProps }, index) => (
            <Component
              disabled={environment === PUBLISHED_STATUS || datasetData?.dataSetInUse?.blocked}
              {...fieldProps}
              error={fieldsEPC[diagramLanguage]?.[index].error}
              legacyStyles
            />
          ))}
      </div>
    </>
  );
};

export default DatasetForm;
