import { useState } from 'react';

import { TooltipComponent, TooltipEventArgs } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { DIAGRAM_ENVIRONMENTS } from 'assets/constants/constants';
import TreePanel from 'components/TreePanel/TreePanel';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import useEnvironment from 'hooks/useEnvironment';
import { Language } from 'types/config';
import { Levels, ProcessData } from 'types/processes';

import styles from './BreadcrumbsNEPOS.module.scss';

type Props = {
  language: Language;
  levels: Levels[];
  isLoading: boolean;
  currentDiagram: Levels | undefined;
  processData?: ProcessData;
};

const BreadcrumbsNEPOS = (props: Props) => {
  const { language, levels, processData, isLoading, currentDiagram } = props;
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const checkEnvironment = useEnvironment();
  const [isTreeOpen, setIsTreeOpen] = useState(false);
  const tooltipClass = 'mbc-tooltip nepos-tooltip';

  const beforeShowTooltip = (event: TooltipEventArgs) => {
    if (isTreeOpen) event.cancel = true;
  };

  return (
    <>
      <div className={styles.Levels}>
        {!checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) && <span className={styles.Status}>{processData?.status}</span>}
        <span>{t('processLevel')}</span>
        <div className={styles.Numbers}>
          {levels?.map(
            (element: Levels, index: any) =>
              index < 3 && (
                <TooltipComponent
                  className="mbc-tooltip nepos-tooltip"
                  content={
                    processData?.id === element.id &&
                    element.name[language]?.PROCESS_NAME !== processData?.attributes[language]?.PROCESS_NAME
                      ? `${processData?.attributes[language]?.PROCESS_NAME}`
                      : element.name[language]?.PROCESS_NAME
                  }
                  cssClass="mbc-tooltip nepos-tooltip"
                  key={element.id}
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  {processData && (
                    <Link to={`/${location.pathname.split('/', 2)[1]}/${element.id}`}>
                      <RoundButton
                        className={String(element.id) === id && !isLoading ? styles.Selected : ''}
                        disabled={isLoading || currentDiagram?.id === levels[3]?.id}
                        id={element.id}
                        key={element.id}
                        onClick={() => null}
                        text={isLoading ? '' : index + 1}
                      />
                    </Link>
                  )}
                </TooltipComponent>
              ),
          )}
          {levels.length < 1 && <RoundButton disabled id="second-level-button" text={isLoading ? '' : '1'} />}
          {levels.length < 2 && <RoundButton disabled id="second-level-button" text={isLoading ? '' : '2'} />}
          {levels.length < 3 && <RoundButton disabled id="third-level-button" text={isLoading ? '' : '3'} />}
          <div className={styles.Activity} />
          <RoundButton disabled id="activity-button" text={isLoading ? '' : 'A'} />
        </div>
        <div>
          <TooltipComponent
            beforeOpen={beforeShowTooltip}
            content={t('treeView')}
            cssClass={tooltipClass}
            position="BottomCenter"
            showTipPointer={false}
          >
            <RoundButton
              className={styles.HandleTree}
              disabled={false}
              icon={`di icon-pfeil-doppelt-chevron-${isTreeOpen ? 'rechts' : 'links'}`}
              id="BreadcrumbsNEPOS-show-tree-panel-button"
              onClick={() => setIsTreeOpen(!isTreeOpen)}
            />
          </TooltipComponent>
        </div>
      </div>
      {isTreeOpen && <TreePanel diagramId={id} />}
    </>
  );
};

export default BreadcrumbsNEPOS;
