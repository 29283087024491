import React from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import useLocalization from 'hooks/useLocalization';

import styles from './LanguageSelector.module.scss';

const LanguageSelector = (props) => {
  const { languageSelected, getLanguage, disabledLanguageStatus, disabled, diagramLanguages } = props;
  const { t } = useTranslation();
  const { availableLanguages } = useLocalization();

  return (
    <div className={`${styles.LanguageSelector} ${disabled ? styles.Disabled : ''}`}>
      {availableLanguages.map((language) =>
        disabledLanguageStatus && (!diagramLanguages.length || !diagramLanguages.includes(language)) ? (
          <TooltipComponent
            className="autosave-tooltip"
            content={t(`language.tooltip.infoNotAvailable.${disabledLanguageStatus}`)}
            cssClass="mbc-tooltip"
            key={language}
            position="BottomCenter"
            showTipPointer={false}
            width={180}
          >
            <button className={styles.Disabled} id={language} type="button">
              {t(`language.${language}`)}
            </button>
          </TooltipComponent>
        ) : (
          <button
            className={languageSelected === language ? styles.Selected : ''}
            disabled={disabled}
            id={language}
            key={language}
            onClickCapture={() => getLanguage(language)}
            type="button"
          >
            {t(`language.${language}`)}
          </button>
        ),
      )}
    </div>
  );
};

export default LanguageSelector;
