import React from 'react';

import { useTranslation } from 'react-i18next';

import { VariantProperty } from 'types/forms';

import ErrorLabel from '../ErrorLabel/ErrorLabel';
import FieldTooltip from '../FieldTooltip/FieldTooltip';
import styles from './InputTextLegacy.module.scss';

const InputTextLegacy = ({ handler, meta, touched, hasError, variant }) => {
  const { t } = useTranslation();
  const mandatory = meta.attributeType === 'M' ? '*' : ''; // Mandatory
  const isSystemAttribute = meta.attributeType === 'S'; // System
  // else 'O': Optional
  const showError = (meta.forceError || touched) && hasError('required');

  const setPlaceholder = () => {
    if (meta.code === 'OBJECT_NAME') {
      return t('diagram.objects.defaultName');
    }
    return isSystemAttribute ? '-' : `Enter ${meta.placeholder}`;
  };

  return (
    <div className={`form-group ${meta.extraClass || ''}`}>
      <label className={showError ? 'error' : ''} htmlFor={meta.id}>
        {meta.label}
        {mandatory}
      </label>
      {meta.description && <FieldTooltip description={meta.description} />}
      <ErrorLabel message={`${meta.label} is required`} visible={showError} />
      <input
        className={`form-control ${styles.Input}`}
        id={meta.id}
        placeholder={setPlaceholder()}
        readOnly={isSystemAttribute}
        type={variant?.includes(VariantProperty.NUMERIC) ? 'number' : 'text'}
        {...handler()}
        value={meta.isCode ? t(`attributes.${meta.id}.code.${handler().value}`) : handler().value}
      />
    </div>
  );
};

export default InputTextLegacy;
