import React, { useReducer } from 'react';

import { LOCATION_MANAGER, ROLES, USER_ROLE } from 'assets/constants/constants';
import { isCentral } from 'assets/js/serviceUtils';
import { getRandomString } from 'assets/js/Utils';
import { permissions, ssoAuthority, ssoClientID, ssoScopes } from 'hooks/authUtils';

const AuthContext = React.createContext(null);
const LegacyAuthProvider = AuthContext.Provider;

const getInitialState = (env) => ({
  authority: ssoAuthority[env],
  clientId: ssoClientID[env],
  clientRoot: window.location.origin,
  isLogged: false,
  env,
  isLoading: false,
  isMaintenance: false,
  nonce: getRandomString(23),
  scope: ssoScopes,
  state: getRandomString(23),
  userRights: [],
  userRoles: [],
});

const AuthActions = {
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR',
  SET_LEGACY_AUTH_SERVICE: 'SET_LEGACY_AUTH_SERVICE',
  SET_SESSION_INFO: 'SET_SESSION_INFO',
  SET_VISITED_SANDBOX: 'SET_VISITED_SANDBOX',
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case AuthActions.FETCH_USER:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActions.FETCH_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case AuthActions.GET_USER_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActions.GET_USER_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActions.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: payload,
        isLoading: false,
      };
    case AuthActions.SET_LEGACY_AUTH_SERVICE:
      return {
        ...state,
        legacyAuthService: payload.legacyAuthService,
      };
    case AuthActions.SET_SESSION_INFO:
      return {
        ...state,
        ...payload,
      };
    case AuthActions.SET_VISITED_SANDBOX:
      return {
        ...state,
        userInfo: { ...state.userInfo, metadata: { ...state.userInfo.metadata, VISITED_SANDBOX: 'true' } },
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(process.env.REACT_APP_ENV || 'development'));
  const { legacyAuthService, userRights, userName, userRoles } = state;

  const checkPermission = (key) => userRights?.includes(`${permissions[key]}`);

  const checkRole = (role) => {
    return userRoles?.some((userRole) =>
      role === ROLES.QI
        ? (userRole.includes(`MBCMAP.ROLE_${role}`) && !userRole.includes(`MBCMAP.ROLE_${role}_`)) ||
          (userRole.includes(`MBCMAP.${role}`) && !userRole.includes(`MBCMAP.${role}_`))
        : userRole.includes(`MBCMAP.ROLE_${role}`) || userRole.includes(`MBCMAP.${role}`),
    );
  };

  const getManagedPlants = () => {
    if (!isCentral()) return [];

    const prefix1 = `MBCMAP.ROLE_${LOCATION_MANAGER}`;
    const prefix2 = `MBCMAP.${LOCATION_MANAGER}`;
    const plants = [];

    userRoles?.forEach((role) => {
      let prefix;

      if (role.includes(prefix1)) {
        prefix = prefix1;
      } else if (role.includes(prefix2)) {
        prefix = prefix2;
      }

      if (prefix) {
        const roleParts = role.split('-');

        if ((roleParts.length === 3 && prefix === prefix1) || (roleParts.length === 2 && prefix === prefix2)) {
          plants.push(roleParts[1]);
        }
      }
    });

    return plants;
  };

  const canManagePlants = () => getManagedPlants()?.length > 0;

  const checkRoles = (roles = []) => roles.some((role) => checkRole(role));

  const value = {
    state,
    dispatch,
    checkPermission,
    checkRole,
    checkRoles,
    canManagePlants,
    userRoles,
    userName,
    ...legacyAuthService,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthActions, AuthContext, AuthProvider, LegacyAuthProvider };
