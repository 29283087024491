import { useCallback, useEffect } from 'react';

import clone from 'just-clone';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DIAGRAM,
  DIAGRAM_ENVIRONMENTS,
  NOT_TRANSLATABLE,
  STATUS,
  SYMBOL_BOARD_WIDTH,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
  SWIMLANE_DEFAULT_WIDTH,
  SWIMLANE_DEFAULT_HEIGHT,
} from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { cloneObject, parseWorkflowDataResponse, prepareTree } from 'assets/js/Utils';
import { DiagramActionTypes, setLoadingFalse } from 'contexts/Diagram/DiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import {
  createDiagram,
  deleteDiagram,
  getDiagram,
  getPublishedDiagram,
  saveDiagram,
  deleteSandboxDiagram,
  getPublishedVcdsAndEpcs,
} from 'services/design';
import diagramServices from 'services/diagramService';
import userService from 'services/userService';
import { getAllWorkflowTypes, getWorkflowTypes, workflowByProcessNumber } from 'services/workflowServices';
import { ObjectIOSubTypes } from 'types/administration';
import { Language } from 'types/config';
import { Connector, ConnectorDirectionTypes } from 'types/connectors';
import {
  LastEvent,
  ProcessCatalogObject,
  ProcessData,
  ProcessDataRequest,
  ProcessDataResponse,
  ProcessKPI,
  ProcessStatus,
  ProcessType,
  StartEvent,
  TreeData,
} from 'types/processes';
import { ProcessSupplierCustomer } from 'types/supplierCustomer';
import { PostIt, PostItTypes, Symbol, SymbolTypes } from 'types/symbols';

import useDiagramContext from './useDiagramContext';
import useEnvironment from './useEnvironment';
import useFeatureFlags from './useFeatureFlags';

export default function useProcess(id: string) {
  const { bounds, dispatch, origin, processData, fontSize, displayedSymbolBoards } = useDiagramContext();
  const { isFreezed } = useFeatureFlags();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const location = useLocation();
  const { i18n } = useTranslation();

  // const { mockSwimlane } = useSwimlane();
  const history = useHistory();
  const checkEnvironment = useEnvironment();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOrigin = useCallback<(symbols: Symbol[], connectors: Connector[]) => [Symbol[], Connector[]]>(
    (symbols, connectors) => {
      if (!bounds || !origin) return [symbols, connectors];
      const newOrigin = { top: bounds.height / 2, left: bounds.width / 2 };

      // Symbols and Connectors coordinates are moved to the new origin
      const displacement = {
        left: origin.left - newOrigin.left,
        top: origin.top - newOrigin.top,
      };
      const newSymbols = symbols.map((symbol) => ({
        ...symbol,
        meta: { ...symbol.meta, left: symbol.meta.left + displacement.left, top: symbol.meta.top + displacement.top },
      }));
      const newConnectors = connectors.map((connector) => ({
        ...connector,
        vertices: connector.vertices.map((vertex) => ({
          ...vertex,
          coordinate:
            vertex.coordinate + (vertex.direction === ConnectorDirectionTypes.HORIZONTAL ? displacement.left : displacement.top),
        })),
      }));
      return [newSymbols, newConnectors];
    },
    [bounds, origin],
  );

  const setSymbolsOffset = (symbols: Symbol[] | undefined): Symbol[] | undefined => {
    if (!symbols) return;

    const { height = 0 } = document.getElementById('symbol-board-container')?.getBoundingClientRect() || {};
    const symbolsClone = cloneObject(symbols);
    const offsetRight = SYMBOL_BOARD_WIDTH - SYMBOL_WIDTH;
    const newHeight = fontSize ? height / fontSize : height / 16;
    const offsetTop = newHeight - SYMBOL_HEIGHT;

    const orderedLeftMinToMaxSymbols: Symbol[] = symbolsClone.sort((a: Symbol, b: Symbol) => a.meta.left - b.meta.left);
    const orderedTopMinToMaxSymbols: Symbol[] = symbolsClone.sort((a: Symbol, b: Symbol) => a.meta.top - b.meta.top);
    return symbolsClone.map((currentSymbol: Symbol, index: number) => {
      if (index === 0) return currentSymbol;

      const getIndexElement = (orderedElements: Symbol[] | undefined): number | undefined => {
        if (!orderedElements) return undefined;
        return orderedElements?.findIndex((element) => currentSymbol === element);
      };
      const indexOfLeftElement = getIndexElement(orderedLeftMinToMaxSymbols);
      const indexOfTopElement = getIndexElement(orderedTopMinToMaxSymbols);

      const previousLeftElementsSymbols = indexOfLeftElement ? orderedLeftMinToMaxSymbols?.slice(0, indexOfLeftElement) : [];
      const areLeftPreviousOnDisplayed = previousLeftElementsSymbols?.filter((symbol: Symbol) =>
        displayedSymbolBoards?.includes(symbol.id),
      );

      const previousTopElementsSymbols = indexOfTopElement ? orderedTopMinToMaxSymbols?.slice(0, indexOfTopElement) : [];
      const areTopPreviousOnDisplayed = previousTopElementsSymbols?.filter((symbol: Symbol) =>
        displayedSymbolBoards?.includes(symbol.id),
      );

      const offsetFactorX = areLeftPreviousOnDisplayed.filter(
        (symbol: Symbol) =>
          symbol.meta.left < currentSymbol.meta.left && symbol.meta.left + SYMBOL_BOARD_WIDTH > currentSymbol.meta.left,
      ).length;
      const offsetX = offsetRight * offsetFactorX;

      const offsetFactorY = areTopPreviousOnDisplayed.filter(
        (symbol: Symbol) => symbol.meta.top < currentSymbol.meta.top && symbol.meta.top + newHeight > currentSymbol.meta.top,
      ).length;
      const offsetY = offsetTop * offsetFactorY;

      const isPreviousPushingX =
        previousLeftElementsSymbols[previousLeftElementsSymbols.length - 1]?.meta?.left + SYMBOL_BOARD_WIDTH >
        currentSymbol?.meta?.left;

      const isPreviousPushingY =
        previousTopElementsSymbols[previousTopElementsSymbols.length - 1]?.meta?.top + newHeight > currentSymbol?.meta?.top;

      return {
        ...currentSymbol,
        offset: {
          ...currentSymbol.offset,
          y: isPreviousPushingY ? offsetY : 0,
          x: isPreviousPushingX ? offsetX : 0,
        },
      };
    });
  };

  const extractAllSymbols = (data: ProcessData) => {
    if (!data) return;
    if (data.type !== ProcessType.SWIMLANE) {
      return data.symbols;
    }

    const symbols: Symbol[] = [];

    data?.swimlanes?.forEach((swimlane) => {
      symbols.push(...swimlane.symbols);
    });

    return symbols;
  };

  const mapSupplierCustomer = useCallback(
    (data: ProcessSupplierCustomer): ProcessSupplierCustomer => ({
      ...data,
      processName:
        data.status === STATUS.TEMPORAL
          ? {
              EN: data.temporaryText || '',
              CN: data.temporaryText || '',
              DE: data.temporaryText || '',
            }
          : data.processName,
    }),
    [],
  );

  const parseProcessDataResponse = useCallback<(processDataResponse: ProcessDataResponse, favorite?: boolean) => ProcessData>(
    (processDataResponse, favorite) => {
      const isSwimlane = processDataResponse.type === ProcessType.SWIMLANE;
      if (isFreezed) {
        processDataResponse.isOnlyRead = true;
      }

      if (processDataResponse.customer) {
        processDataResponse.customer = mapSupplierCustomer(processDataResponse.customer);
      }

      if (processDataResponse.supplier) {
        processDataResponse.supplier = mapSupplierCustomer(processDataResponse.supplier);
      }

      processDataResponse.symbols?.forEach((symbol) => {
        symbol.postIts?.INPUT?.forEach((inp) => {
          if (inp.linkage && !inp.linkage?.id) {
            inp.linkage.title = inp.linkage.text || '';
          }
        });

        symbol.postIts?.OUTPUT?.forEach((out) => {
          if (out.linkage && !out.linkage?.id) {
            out.linkage.title = out.linkage.text || '';
          }
        });

        if (!processDataResponse.symbols) return;
        const orderedSymbolsByTop = processDataResponse.symbols.sort((a, b) => {
          return a?.meta?.top < b?.meta?.top ? -1 : 1;
        });
        processDataResponse.symbols = orderedSymbolsByTop;
      });

      let startEvents: StartEvent[] = [];
      let lastEvents: LastEvent[] = [];

      if (processDataResponse.type === ProcessType.SWIMLANE) {
        startEvents =
          processDataResponse.swimlanes
            ?.sort((a, b) => a.meta.pos - b.meta.pos)
            .flatMap((swiml) => swiml.symbols.map((s) => ({ ...s, swimlaneId: swiml.id })))
            .filter((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.START_EVENT === 'true')
            .sort((a, b) => (a.swimlaneId === b.swimlaneId ? a.meta.top - b.meta.top : 1))
            .map((startEvent) => ({
              id: startEvent.id,
              supplier: startEvent.supplier && mapSupplierCustomer(startEvent.supplier),
              inputs: startEvent.objects?.filter((obj) => obj.subtype === ObjectIOSubTypes.INPUT) as
                | ProcessCatalogObject[]
                | undefined,
            })) || [];

        lastEvents =
          processDataResponse.swimlanes
            ?.sort((a, b) => a.meta.pos - b.meta.pos)
            .flatMap((swiml) => swiml.symbols.map((s) => ({ ...s, swimlaneId: swiml.id })))
            .filter((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.LAST_EVENT === 'true')
            .sort((a, b) => (a.swimlaneId === b.swimlaneId ? a.meta.top - b.meta.top : 1))
            .map((lastEvent) => ({
              id: lastEvent.id,
              customer: lastEvent.customer && mapSupplierCustomer(lastEvent.customer),
              outputs: lastEvent.objects?.filter((obj) => obj.subtype === ObjectIOSubTypes.OUTPUT) as
                | ProcessCatalogObject[]
                | undefined,
            })) || [];
      }

      processDataResponse.swimlanes?.forEach((swimlane, i) => {
        if (swimlane.meta.pos === undefined) swimlane.meta.pos = i;
        if (swimlane.meta.width === undefined) swimlane.meta.width = SWIMLANE_DEFAULT_WIDTH;
        if (swimlane.meta.height === undefined) swimlane.meta.height = SWIMLANE_DEFAULT_HEIGHT;
        swimlane.symbols?.forEach((symbol, j) => {
          if (symbol.meta.top === undefined) symbol.meta.top = 0;
          if (symbol.meta.left === undefined) symbol.meta.left = j;
          if (symbol.meta.width === undefined) symbol.meta.width = 0;
          if (symbol.meta.height === undefined) symbol.meta.height = 0;
          if (
            !startEvents.length &&
            symbol.attributes?.NOT_TRANSLATABLE &&
            symbol.type === SymbolTypes.EVENT &&
            symbol.meta.top === 0 &&
            symbol.meta.left === 0
          ) {
            symbol.attributes.NOT_TRANSLATABLE.START_EVENT = 'true';
            startEvents.push({
              id: symbol.id,
              supplier: symbol.supplier && mapSupplierCustomer(symbol.supplier),
              inputs: symbol.objects as ProcessCatalogObject[] | undefined,
            });
            delete processDataResponse?.supplier;
          }
        });
      });

      return {
        ...processDataResponse,
        isSwimlane,
        favorite,
        symbols: processDataResponse.symbols || [],
        notes: processDataResponse.symbols || [],
        connectors: processDataResponse.connectors || [],
        kpis: processDataResponse.kpis || [],
        interfaces: processDataResponse.interfaces || [],
        objects: processDataResponse.objects || [],
        swimlanes: processDataResponse.swimlanes || [],
        lastEvents,
        startEvents,
      };
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deleteProcess = useCallback(
    (confirmationValue: string, parentId: number, isSandbox: boolean) => {
      if (isSandbox) {
        return deleteSandboxDiagram(id, confirmationValue)
          .then(() => {
            history.push('/sandbox-overview');
          })
          .catch((err) => {
            handleServiceError(err);
          });
      }

      return deleteDiagram(id, confirmationValue)
        .then(() => {
          history.push(`/diagram/${parentId}`);
        })
        .catch((err) => {
          handleServiceError(err);
        });
    },
    [history, id],
  );

  const fetchProcess = useCallback(
    async (keepFontSize = false) => {
      dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA, payload: keepFontSize });
      try {
        const { data } = await getDiagram(id);
        const favorite = await userService.isFavorite(data.attributes[NOT_TRANSLATABLE]?.PROCESS_NUMBER);

        fetchFormTypes(
          getFormTypesCode({
            isGroupFetch: true,
            status: data.status,
            type: DIAGRAM,
            variant: data.type,
          }),
        );
        dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA_SUCCESS, payload: parseProcessDataResponse(data, favorite.data) });

        if (data.status === STATUS.WORKFLOW) {
          const processNumber = data.attributes[NOT_TRANSLATABLE]?.PROCESS_NUMBER;
          const wfResponse = await workflowByProcessNumber(processNumber);

          const wfStagesResponse = await getAllWorkflowTypes(wfResponse.data.type, data.isRecommendation);
          dispatch({
            type: DiagramActionTypes.FETCH_WORKFLOW_DATA,
            payload: parseWorkflowDataResponse(wfResponse.data, wfStagesResponse.data),
          });
        }

        setTimeout(() => dispatch({ type: DiagramActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT }), 500);
      } catch (err) {
        handleServiceError(err);
        dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA_ERROR });
      }
    },
    [id, location.pathname], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchPublishedProcess = useCallback(async () => {
    dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA });
    try {
      const diagram = await getDiagram(id);
      const processNumber = diagram.data.attributes[NOT_TRANSLATABLE].PROCESS_NUMBER;
      const { data } = await getPublishedDiagram(processNumber);
      const favorite = await userService.isFavorite(data.attributes[NOT_TRANSLATABLE]?.PROCESS_NUMBER);

      fetchFormTypes(
        getFormTypesCode({
          isGroupFetch: true,
          status: data.status,
          type: DIAGRAM,
          variant: data.type,
        }),
      );
      dispatch({
        type: DiagramActionTypes.FETCH_PROCESS_DATA_SUCCESS,
        payload: { ...parseProcessDataResponse(data, favorite.data), isOnlyRead: true, isPublishedVersion: true },
      });

      setTimeout(() => dispatch({ type: DiagramActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT }), 500);
    } catch (err) {
      handleServiceError(err);
      dispatch({ type: DiagramActionTypes.FETCH_PROCESS_DATA_ERROR });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapPostIts = (type: PostItTypes, clonedPostIts: any, symbol: { postIts?: any }) => {
    let postIts = [];

    postIts = clonedPostIts[type].map((postIt: PostIt) => {
      if (postIt.linkage?.id) {
        return {
          ...postIt,
          linkage: {
            id: typeof postIt.linkage.id === 'string' ? postIt.linkage.id.split('/')[2] : postIt.linkage.id,
          },
        };
      }

      if (postIt.linkage?.title) {
        return {
          ...postIt,
          linkage: {
            text: postIt.linkage?.title,
          },
        };
      }

      return {
        text: postIt.text,
        order: postIt.order,
        id: postIt.id,
      };
    });

    return {
      ...symbol,
      postIts: {
        ...symbol.postIts,
        [type]: postIts,
      },
    };
  };

  const saveProcess = useCallback(
    async (data: ProcessData) => {
      dispatch({ type: DiagramActionTypes.SAVE_PROCESS_DATA });
      try {
        // const [newSymbols, newConnectors] = updateOrigin(data.symbols, data.connectors);
        const newSwimlanes =
          data.type === ProcessType.SWIMLANE
            ? data.swimlanes.map((swimlane) => ({
                ...swimlane,
                symbols: swimlane.symbols.map((symbol) => {
                  if (symbol.activitySpecificationId === -1) {
                    delete symbol.activitySpecificationId;
                  }
                  if (symbol.type !== SymbolTypes.EVENT) return symbol;

                  const startEventSymbol = data.startEvents?.find((startEvent) => startEvent.id === symbol.id);
                  const lastEventSymbol = data.lastEvents?.find((lastEvent) => lastEvent.id === symbol.id);

                  if (startEventSymbol || lastEventSymbol) {
                    const existingSupplier =
                      startEventSymbol?.supplier?.status === STATUS.TEMPORAL
                        ? { temporaryText: startEventSymbol?.supplier.processName.EN }
                        : { id: startEventSymbol?.supplier?.id };
                    const existingCustomer =
                      lastEventSymbol?.customer?.status === STATUS.TEMPORAL
                        ? { temporaryText: lastEventSymbol?.customer.processName.EN }
                        : { id: lastEventSymbol?.customer?.id };

                    return {
                      ...symbol,
                      supplier: startEventSymbol?.supplier ? existingSupplier : undefined,
                      customer: lastEventSymbol?.customer ? existingCustomer : undefined,
                      objects: [...(startEventSymbol?.inputs || []), ...(lastEventSymbol?.outputs || [])],
                    };
                  }

                  delete symbol.supplier;
                  delete symbol.customer;
                  delete symbol.objects;

                  return symbol;
                }),
              }))
            : data.swimlanes;

        const newSymbols = data.symbols.map((symbol: { postIts?: any }) => {
          let updatedData = symbol;

          if (!symbol.postIts) {
            updatedData = {
              ...symbol,
            };
          } else {
            const clonedPostIts = cloneObject(symbol.postIts);

            if (clonedPostIts.INPUT && clonedPostIts.INPUT.length > 0) {
              updatedData = mapPostIts(PostItTypes.INPUT, clonedPostIts, updatedData);
            }

            if (clonedPostIts.OUTPUT && clonedPostIts.OUTPUT.length > 0) {
              updatedData = mapPostIts(PostItTypes.OUTPUT, clonedPostIts, updatedData);
            }
          }

          return updatedData;
        });

        const sanitizedConnectors: Connector[] = [];
        const connectorsMap: { [key: string]: Connector } = {};

        data.connectors.forEach((connector) => {
          const { source, target, vertices } = connector;
          sanitizedConnectors.push({
            source: { id: source.id, port: source.port },
            target: { id: target.id, port: target.port },
            vertices,
          });
          connectorsMap[`${source.id}_${target.id}`] = connector;
        });

        const filteredKpis: (ProcessKPI | Omit<ProcessKPI, 'id'>)[] = data.kpis.map((kpi) =>
          typeof kpi.id === 'string' ? { attributes: kpi.attributes } : kpi,
        );

        const filteredData: ProcessDataRequest = {
          type: data.type,
          attributes: data.attributes,
          // ...(newSymbols.length > 0 ? { symbols: newSymbols } : {}),
          // ...(newConnectors.length > 0 ? { connectors: newConnectors } : {}),
          ...(data.symbols.length > 0 ? { symbols: newSymbols as Symbol[] } : {}),
          ...(data.connectors.length > 0 ? { connectors: sanitizedConnectors } : {}),
          ...(data.kpis.length > 0 ? { kpis: filteredKpis } : {}),
          ...(data.objects.length > 0 ? { objects: data.objects } : {}),
          ...(data.supplier
            ? {
                supplier: {
                  ...(data.supplier.status === STATUS.TEMPORAL
                    ? { temporaryText: data.supplier.processName.EN }
                    : { id: data.supplier.id }),
                },
              }
            : {}),
          ...(data.customer
            ? {
                customer: {
                  ...(data.customer.status === STATUS.TEMPORAL
                    ? { temporaryText: data.customer.processName.EN }
                    : { id: data.customer.id }),
                },
              }
            : {}),
          ...(data.swimlanes ? { swimlanes: newSwimlanes } : {}),
        } as ProcessDataRequest;

        const response = await saveDiagram(id, filteredData);
        const parsedData = parseProcessDataResponse(response.data);
        parsedData.connectors = parsedData.connectors.map(
          (connector) => connectorsMap[`${connector.source.id}_${connector.target.id}`],
        );
        dispatch({ type: DiagramActionTypes.SAVE_PROCESS_DATA_SUCCESS, payload: parsedData });
        setTimeout(() => {
          dispatch({ type: DiagramActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT });
        }, 2000);
      } catch (err) {
        handleServiceError(err);
        dispatch({ type: DiagramActionTypes.SAVE_PROCESS_DATA_ERROR });
      }
    },
    [dispatch, id, parseProcessDataResponse],
  );

  const createProcess = (idSymbol: string, action: string, isSwimlane?: boolean) => {
    if (!processData) return;

    let tempIdSymbol = idSymbol;
    const newSymbols = processData?.symbols.map((symbol) => {
      if (idSymbol && idSymbol.includes(SymbolTypes.SIPOC_ELEMENT)) {
        tempIdSymbol = tempIdSymbol.replace(SymbolTypes.SIPOC_ELEMENT, '');
      }
      return symbol.id === tempIdSymbol ? { ...symbol, id: SymbolTypes.SIPOC_ELEMENT + tempIdSymbol } : symbol;
    });
    const newConnectors = processData?.connectors.map((connector) => {
      const mappedConnector = { ...connector };
      if (connector.source.id === tempIdSymbol) mappedConnector.source.id = SymbolTypes.SIPOC_ELEMENT + tempIdSymbol;
      if (connector.target.id === tempIdSymbol) mappedConnector.target.id = SymbolTypes.SIPOC_ELEMENT + tempIdSymbol;

      return mappedConnector;
    });

    dispatch({
      type: DiagramActionTypes.UPDATE_SYMBOLS_AND_CONNECTORS,
      payload: { symbols: newSymbols, connectors: newConnectors },
    });
    const newProcessData = clone(processData);
    newProcessData.symbols = newSymbols;
    newProcessData.connectors = newConnectors;

    saveProcess(newProcessData).then(() => {
      createDiagram({
        symbol: isSwimlane ? tempIdSymbol : SymbolTypes.SIPOC_ELEMENT + tempIdSymbol,
        type: action as ProcessType,
      })
        .then((response) => {
          // If current diagram is SANDBOX, the created child is also SANDBOX
          const diagramUrl = processData?.status === ProcessStatus.SANDBOX ? 'sandbox' : 'diagram';
          history.push(`/${diagramUrl}/${response.data.id}`);
          dispatch({
            type: DiagramActionTypes.SET_SELECTION,
            payload: [],
          });
        })
        .catch((error) => {
          handleServiceError(error);
        });
    });
  };

  const getSendForReleaseOptions = useCallback(
    async (version, processNumber, isRecommendationActive, idDiagramType) => {
      try {
        const response = await getWorkflowTypes({
          idDiagramType,
          idDiagram: id,
          idProcess: processNumber || '',
          version,
          isRecommendationActive,
        });

        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_OPTIONS, payload: response.data.results });
      } catch (err) {
        handleServiceError(err);
      }
    },
    [dispatch, id],
  );

  const getTree = (isParentIDNedeed: boolean = false) => {
    const isPublished =
      checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) || checkEnvironment(DIAGRAM_ENVIRONMENTS.CLOSED_WORKFLOW);

    return diagramServices
      .getDiagramTreeStructure(isPublished)
      .then((response) => {
        const tree = response.data.results;
        const treeExplorer = prepareTree(tree, id, false, i18n.language);

        return treeExplorer.dataSource.map((elem: TreeData) => {
          const name = JSON.parse(elem.name);

          let treeElement: TreeData = {
            id: elem.id,
            name: name[i18n.language as Language].PROCESS_NAME,
            idDiagramType: elem.idDiagramType,
            isRecommendation: elem.isRecommendation || false,
          };

          if (isParentIDNedeed) treeElement = { ...treeElement, pid: elem.pid };

          return treeElement;
        });
      })
      .catch((err) => {
        handleServiceError(err);
      });
  };

  const getPublishedVcdEpcTree = () => {
    return getPublishedVcdsAndEpcs()
      .then((response) => {
        const tree = response.data.results;
        const treeExplorer = prepareTree(tree, id, false, i18n.language);

        return treeExplorer.dataSource.map((elem: TreeData) => {
          const name = JSON.parse(elem.name);

          return {
            id: elem.id,
            name: name[i18n.language as Language].PROCESS_NAME,
            idDiagramType: elem.idDiagramType,
            isRecommendation: elem.isRecommendation || false,
          };
        });
      })
      .catch((err) => {
        handleServiceError(err);
      });
  };

  const linkExternalDiagram = useCallback(
    async (idDiagram: number, idSymbol: string) => {
      if (processData) {
        try {
          await saveProcess(processData);
          dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
          await diagramServices.linkExternalDiagramV2({
            idLinkedDiagram: idDiagram,
            idSourceSymbol: idSymbol,
          });
          fetchProcess();
        } catch (error) {
          dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
          handleServiceError(error);
        }
      }
    },
    [dispatch, fetchProcess, processData, saveProcess],
  );

  const filterProcessInterfaces = useCallback(
    () =>
      processData?.isPublishedVersion
        ? processData?.interfaces?.filter((elem) => [STATUS.PUBLISHED, STATUS.UPDATE].includes(elem.status || ''))
        : processData?.interfaces,
    [processData?.interfaces, processData?.isPublishedVersion],
  );

  useEffect(() => {
    if (!processData || (!processData.symbols && !processData.isSwimlane)) return;
    if (processData.isSwimlane) return dispatch(setLoadingFalse());

    let newSymbols: Symbol[];
    if (displayedSymbolBoards.length !== 0) {
      newSymbols = setSymbolsOffset(processData.symbols) || processData.symbols;

      dispatch({
        type: DiagramActionTypes.UPDATE_SYMBOLS,
        payload: newSymbols,
      });
    } else {
      newSymbols = processData?.symbols.map((symbol: Symbol) => {
        return { ...symbol, offset: undefined };
      });
      dispatch({
        type: DiagramActionTypes.UPDATE_SYMBOLS,
        payload: newSymbols,
      });
    }
    dispatch(setLoadingFalse());
  }, [displayedSymbolBoards]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    createProcess,
    deleteProcess,
    fetchProcess,
    fetchPublishedProcess,
    linkExternalDiagram,
    parseProcessDataResponse,
    getSendForReleaseOptions,
    getTree,
    getPublishedVcdEpcTree,
    saveProcess,
    filterProcessInterfaces,
    extractAllSymbols,
  };
}
