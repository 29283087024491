import React from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import Button from '../Button/Button';
import styles from './FormDialog.module.scss';
import './FormDialog.scss';

const FormDialog = (props) => {
  const {
    t,
    close,
    visible,
    title,
    titleStyle = 'Title',
    info,
    returnAction,
    actionName,
    actionButtonId,
    cancelName = t('cancel'),
    closeDialog = close,
    value,
    diagramName,
  } = props;
  let header = title.includes(t('warning'))
    ? title
    : `<span class='${styles[titleStyle]}'><i class='fas fa-times ${styles.Icon}'></i>${title}</span>`;

  if (titleStyle !== 'Title') {
    header = `<span class='${styles[titleStyle]}'>${title}</span>`;
  }

  return (
    <div className="normal-modal">
      <DialogComponent close={close} header={header} id="FormDialog" isModal target="#dialog" visible={visible} width="512">
        <div className={styles.Dialog}>
          <p>{info}</p>
          {title.includes(t('warning')) && (
            <>
              <span>{t('diagram.deleteDiagramLabel')}</span>
              <span className={styles.DiagramName}>{`*${diagramName}*`}</span>
            </>
          )}
          {props.children}
          <div className={styles.ButtonLine}>
            <Button btnText={cancelName} buttonStyle="Secondary" handleClick={closeDialog} id="cancel" />
            <Button
              btnText={actionName}
              buttonStyle="Primary"
              disabled={!value || value.length < 1}
              handleClick={returnAction}
              id={actionButtonId}
            />
          </div>
        </div>
      </DialogComponent>
    </div>
  );
};

export default withTranslation('common')(FormDialog);
