import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import styles from './TableRow.module.scss';

const TableRow = ({ isHeader, element }) => {
  if (isHeader) {
    return (
      <tr className={styles.ListItem}>
        {element.map((column) => (
          <th className={`${styles.Type} ${styles[column.className]}`}>{column.name}</th>
        ))}
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  const capitalizeStyle = (style) => style[0].toUpperCase() + style.slice(1);

  return (
    <tr className={styles.ListItem}>
      {Object.keys(element)
        .filter((key) => key !== 'id')
        .map((key) => (
          <td className={`${styles.Type}  ${styles[capitalizeStyle(key)]}`} key={`row-${key}`}>
            <TooltipComponent
              content={element[key] || ''}
              cssClass="mbc-tooltip nepos-tooltip"
              position="Bottom"
              showTipPointer={false}
            >
              {element[key]}
            </TooltipComponent>
          </td>
        ))}
    </tr>
  );
};

export default withTranslation('common')(TableRow);
