import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useProcess from 'hooks/useProcess';

import styles from './SaveButton.module.scss';

const SaveButton = () => {
  const { isSaveLoading, isSaveSuccess, processData } = useDiagramContext();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { saveProcess } = useProcess(id);
  const { isFreezed } = useFeatureFlags();

  const Content = () => {
    if (isSaveLoading) {
      return (
        <svg viewBox="-2000 -1000 4000 2000">
          <path d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" id="inf" />
          <use strokeDasharray="1570 5143" strokeDashoffset="6713px" xlinkHref="#inf" />
        </svg>
      );
    }

    if (isSaveSuccess) {
      return (
        <svg className={styles.Checkmark} viewBox="0 0 52 52">
          <path className={styles.Check} d="M14.1 27.2l7.1 7.2 16.7-16.8" fill="none" />
        </svg>
      );
    }

    return <span>{t('save')}</span>;
  };

  return (
    <ButtonNEPOS
      className={styles.SaveButton}
      disabled={isFreezed}
      handleClick={() => processData && saveProcess(processData)}
      icon={!isSaveLoading && !isSaveSuccess ? 'di icon-diskette-speichern' : ''}
      id="save-process-button"
    >
      <Content />
    </ButtonNEPOS>
  );
};

export default SaveButton;
