import React, { useCallback, useEffect, useState, createContext } from 'react';

import * as Constants from '../../../assets/constants/constants';
import styles from './ToastContext.module.scss';

const ToastContext = createContext();
export default ToastContext;

const toastConfig = {
  [Constants.INFO]: {
    icon: 'icon-information',
    classType: styles.Info,
  },
  [Constants.SUCCESS]: {
    icon: 'icon-check',
    classType: styles.Success,
  },
  [Constants.SUCCESS_NEPOS]: {
    icon: 'icon-check',
    classType: styles.SuccessNEPOS,
    lastIcon: 'icon-schliessen',
  },
};

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [toastType, setType] = useState(Constants.INFO);
  const [link, setLink] = useState({});
  const { classType, icon, lastIcon } = toastConfig[toastType];

  useEffect(() => {
    if (toasts.length > 0) {
      setTimeout(() => {
        setToasts(toasts.slice(1));
      }, 4000);
    }
  }, [toasts]);

  const addToast = useCallback((toast, type, infoLink = {}) => {
    setToasts([toast]);
    // setToasts([...toasts, toast]); Push notifications
    setType(type);
    setLink(infoLink);
  }, []);

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className={styles.ToastWrapper}>
        {toasts.map((toast) => (
          <div className={`${styles.Toast} ${classType}`} key={toast}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {icon && <i className={`di ${icon} ${styles.Icon}`} />}
              {toast}
            </div>
            {link && (
              <a className={styles.Link} href={link.url}>
                {link.text}
              </a>
            )}
            {lastIcon && <i className={`di ${lastIcon} ${styles.LastIcon}`} onClick={() => setToasts(toasts.slice(1))} />}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
