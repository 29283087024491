import { useCallback, useEffect, useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import clone from 'just-clone';
import { withTranslation } from 'react-i18next';

import SearchFilter from 'components/UI/SearchFilter/SearchFilter';
import Spinner from 'components/UI/Spinner/Spinner';

import * as Constants from '../../assets/constants/constants';
import * as serviceUtils from '../../assets/js/serviceUtils';
import sandboxStyles from '../SandboxDraft/SandboxDraft.module.scss';
import Button from '../UI/Button/Button';
import Search from '../UI/Search/Search';
import TabFilter from '../UI/TabFilter/TabFilter';
import styles from './DialogDetail.module.scss';

const LinkDiagramDialog = (props) => {
  const { diagramList, dialogTitle, buttonText, linkDiagram, assignModelers, close, t, target, isDiagramSelector, isLoading } =
    props;
  const [selectedDiagram, setSelectedDiagram] = useState('');
  const [selected, setSelected] = useState(false);
  const [filtersToMap, setFiltersToMap] = useState();
  const [searchText, setSearchText] = useState('');
  const [tabFilter, setTabFilter] = useState(
    !serviceUtils.isCentral() && dialogTitle === t('importDiagram') ? Constants.TAB_RECOMMENDATION : Constants.TAB_DRAFT,
  );
  const [diagramsToMap, setDiagramsToMap] = useState();

  const filterDiagrams = useCallback(
    (diagramType) => {
      const diagramsToFilter = diagramList.filter((elem) =>
        tabFilter === Constants.TAB_RECOMMENDATION ? elem.isRecommendation : !elem.isRecommendation,
      );
      const filteredByType =
        diagramType === undefined ? diagramsToFilter : diagramsToFilter.filter((elem) => elem.idDiagramType === diagramType);
      const filteredBySearchText =
        searchText.length > 0
          ? filteredByType.filter((elem) => elem.name?.toLowerCase().includes(searchText.toLowerCase()))
          : filteredByType;
      return filteredBySearchText;
    },
    [diagramList, tabFilter, searchText],
  );

  useEffect(() => {
    if (selected[0]?.nameFilter === 'epc') return setDiagramsToMap(filterDiagrams(Constants.EPC_DIAGRAM_ID));
    if (selected[0]?.nameFilter === 'vcd') return setDiagramsToMap(filterDiagrams(Constants.VCD_DIAGRAM_ID));
    return setDiagramsToMap(filterDiagrams());
  }, [selected, filterDiagrams]);

  const handleClick = (diagram, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedDiagram?.id !== diagram.id) {
      setSelectedDiagram(diagram);
    } else {
      setSelectedDiagram('');
    }
  };

  const buttonClick = (diagram) => {
    close();
    if (
      dialogTitle === t('importDiagram') &&
      selectedDiagram.type !== Constants.SIPOC_DIAGRAM &&
      selectedDiagram.type !== Constants.SWIMLANE_DIAGRAM
    ) {
      assignModelers(diagram.id);
    } else if (isDiagramSelector) {
      linkDiagram(diagram);
    } else {
      linkDiagram(diagram.id);
    }
  };

  const filtersInfo = [
    {
      text: 'all',
      nameFilter: 'all',
      isOpen: false,
      isSelected: true,
    },
    {
      text: 'EPC',
      nameFilter: 'epc',
      isOpen: false,
      isSelected: false,
    },
    {
      text: 'VCD',
      nameFilter: 'vcd',
      isOpen: false,
      isSelected: false,
    },
  ];

  const handleFilterClick = (filter) => {
    const filtersClone = filtersToMap || clone(filtersInfo);
    filtersClone.forEach((f) => {
      if (f.nameFilter === filter.nameFilter) {
        f.isSelected = !f.isSelected;
      } else {
        f.isSelected = false;
      }
    });
    setFiltersToMap(filtersClone);
    setSelected(filtersClone.filter((f) => f.isSelected));
  };

  useEffect(() => {
    setFiltersToMap(filtersInfo);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={close}
      height="800px"
      id="dialog-detail"
      isModal
      target={target || '#dialog'}
      visible
      width="510px"
    >
      <div className={`${styles.Content} ${styles.LinkDiagramContent}`}>
        <div className={`${styles.Section} ${styles.SectionLinkDiagram}`}>
          <h4>{dialogTitle}</h4>
          <Search searching={(text) => setSearchText(text)} />
          {isDiagramSelector && (
            <div className={styles.Filters}>
              {filtersToMap?.map((f) => (
                <SearchFilter
                  filter={f}
                  filtersInfo={filtersToMap}
                  filtersList={[]}
                  getResults={null}
                  handleFilterClick={(filter) => handleFilterClick(filter)}
                  handleFiltersChange={[]}
                  id={f.text}
                  isDiagramSelector
                  key={f.text}
                  locations={[]}
                  options={f.options}
                  paginationFilters={[]}
                  resetFilter={[]}
                  selected={selected}
                  value=""
                />
              ))}
            </div>
          )}
          {!serviceUtils.isCentral() && dialogTitle === t('importDiagram') && (
            <div className={styles.Tabs}>
              {Constants.TABS_SANDBOX_OVERVIEW.map((filter) => (
                <TabFilter
                  click={() => setTabFilter(filter)}
                  customStyle={styles.Tab}
                  customStyleSelected={styles.Selected}
                  idFilter={filter}
                  isSelected={filter === tabFilter}
                  key={filter}
                />
              ))}
            </div>
          )}
          <div className={`${styles.DiagramList} ${sandboxStyles.DraftList}`}>
            {isLoading && (
              <div className="spinner">
                <Spinner isVisible />
              </div>
            )}
            {diagramsToMap?.length > 0
              ? diagramsToMap.map((diagram) => (
                  <div
                    className={`${sandboxStyles.DiagramItem} ${
                      selectedDiagram.id === diagram.id ? sandboxStyles.SelectedDiagram : ''
                    }`}
                    key={diagram.id}
                    onClick={(event) => handleClick(diagram, event)}
                  >
                    {diagram.idDiagramType === Constants.EPC_DIAGRAM_ID || diagram.type === Constants.EPC_DIAGRAM ? (
                      <i aria-hidden="true" className="di icon-hierarchie-diagramm" />
                    ) : (
                      <i aria-hidden="true" className="di icon-etikett-2" />
                    )}
                    {diagram.name}
                  </div>
                ))
              : !isLoading && <p>{t('noResults')}</p>}
          </div>
          <div className={styles.ButtonLine}>
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={close} id="cancel" />
            <Button
              btnText={buttonText}
              buttonStyle="Primary"
              disabled={selectedDiagram === ''}
              handleClick={() => buttonClick(selectedDiagram)}
              id="link"
            />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default withTranslation('common')(LinkDiagramDialog);
