const iconsFilesDisplay = (name: string = ''): string => {
  const typeFile = name.slice(name.indexOf('.'), name.length);
  let icon: string;
  switch (typeFile) {
    case '.jpg':
      icon = 'di icon-bild';
      break;
    case '.xls':
      icon = 'di icon-datei-xls';
      break;
    case '.xlsx':
      icon = 'di icon-datei-xls';
      break;
    case '.zip':
      icon = 'di icon-stapel-ablage';
      break;
    case '.doc':
      icon = 'di icon-datei-doc';
      break;
    case '.pdf':
      icon = 'di icon-datei-pdf';
      break;
    case '.ppt':
      icon = 'di icon-datei-pptx-2';
      break;
    case '.docx':
      icon = 'di di icon-datei-doc';
      break;
    default:
      icon = 'di icon-datei-doc';
  }
  return icon;
};

export default iconsFilesDisplay;
