import { DragEvent, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import ObjectCatalogBox from 'components/ObjectCatalogBox/ObjectCatalogBox';
import Swimlane from 'components/Swimlanes/Swimlane';
import useDiagramContext from 'hooks/useDiagramContext';
import useSwimlane from 'hooks/useSwimlane';
import { Orientation, PaletteActions } from 'types/diagram';
import { Swimlanes } from 'types/swimlanes';

import styles from './SwimlanesWrapper.module.scss';

const SwimlanesWrapper = () => {
  const { processData, paletteAction, ghostSwimlane, swimlaneDisplayingAttributes } = useDiagramContext();
  const { createSwimlane } = useSwimlane();
  const { t } = useTranslation();
  const swimlanes = processData?.swimlanes || [];

  const swimlaneDrag = (event: DragEvent<HTMLElement>, toggle = false) => {
    event.stopPropagation();
    event.preventDefault();
    if (paletteAction === PaletteActions.ADD_SWIMLANE)
      document.getElementById(Swimlanes.WRAPPER_DOM_ID)?.classList.toggle('dragging', toggle);
  };

  const handleDragEnter = (event: DragEvent<HTMLElement>) => {
    if (!event.currentTarget) return;
    swimlaneDrag(event, true);
  };

  const handleDragLeave = (event: DragEvent<HTMLElement>) => {
    if (!event.currentTarget || event.currentTarget !== event.target || event.currentTarget.contains(event.relatedTarget as Node))
      return;
    swimlaneDrag(event, false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    if (processData?.swimlanes?.length) createSwimlane(processData.swimlanes.length, event);
    swimlaneDrag(event, false);
  };

  const handleDragEnterBetweenSwimlanes = (event: DragEvent<HTMLElement>, toggle = false) => {
    event.stopPropagation();
    if (paletteAction !== PaletteActions.ADD_SWIMLANE) return;
    event.currentTarget.classList.toggle('draggingBetweenSwimlanes', toggle);
    swimlaneDrag(event, !toggle);
  };

  const handleDropBetweenSwimlanes = (event: DragEvent<HTMLDivElement>, position: number) => {
    event.stopPropagation();
    createSwimlane(position + 1, event);
    event.currentTarget.classList.remove('draggingBetweenSwimlanes');
  };

  return (
    <div
      className={styles.Wrapper}
      id={Swimlanes.WRAPPER_DOM_ID}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div>
        {processData?.startEvents?.map((event, index) => (
          <ObjectCatalogBox
            eventId={event.id}
            key={`supplier-input-${event.id}`}
            style={{ zIndex: swimlaneDisplayingAttributes !== '' ? 0 : 1, marginTop: index ? '2em' : 'unset' }}
          />
        ))}
      </div>
      <div className={styles.Swimlanes}>
        {swimlanes
          .sort((a, b) => a.meta.pos - b.meta.pos)
          .map((swimlane, position) => (
            <Fragment key={`section-${swimlane.id}`}>
              <section
                className={styles.SwimlaneContainer}
                id={swimlane.id}
                style={{
                  transform:
                    swimlaneDisplayingAttributes !== '' && swimlaneDisplayingAttributes !== swimlane.id ? 'rotate(360deg)' : '',
                  flexDirection: ghostSwimlane && ghostSwimlane.direction === Orientation.VERTICAL ? 'column' : 'row',
                }}
              >
                <Swimlane {...swimlane} />
              </section>
              {swimlanes.length - 1 !== position && (
                <section
                  className={`${styles.SwimlaneContainer} ${styles.PlaceholderBetweenSwimlanes}`}
                  data-dragging-text={t('dropSwimlaneHere')}
                  onDragEnter={(ev) => handleDragEnterBetweenSwimlanes(ev, true)}
                  onDragLeave={handleDragEnterBetweenSwimlanes}
                  onDrop={(ev) => handleDropBetweenSwimlanes(ev as DragEvent<HTMLDivElement>, position)}
                />
              )}
            </Fragment>
          ))}
        <section className={`${styles.SwimlaneContainer} ${styles.Placeholder}`} data-dragging-text={t('dropSwimlaneHere')} />
      </div>
      <div>
        {processData?.lastEvents?.length ? (
          processData?.lastEvents?.map((event, index) => (
            <ObjectCatalogBox
              eventId={event.id}
              key={`customer-output-${event.id}`}
              right
              style={{
                zIndex: swimlaneDisplayingAttributes !== '' ? 0 : 1,
                alignSelf: 'auto',
                marginTop: index ? '2em' : 'unset',
              }}
            />
          ))
        ) : (
          <ObjectCatalogBox
            key="customer-output-empty"
            right
            style={{ zIndex: swimlaneDisplayingAttributes !== '' ? 0 : 1, alignSelf: 'auto', marginTop: 'unset' }}
          />
        )}
      </div>
    </div>
  );
};

export default SwimlanesWrapper;
