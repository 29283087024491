import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const url = '/backend/ms-design/api/v1/diagram';

const getSandboxTree = () => axiosInstance.get(`${url}/tree-structure/sandbox`);

const getSandboxRecommendedTree = () => axiosInstance.get(`${url}/tree-structure/recommended-sandbox`);

const deleteSandbox = (diagramId, confirmationString) =>
  axiosInstance.delete(`${url}/${diagramId}/`, { data: { confirmationString } });

const importSandbox = (data) => axiosInstance.put(`${url}/sandbox/import`, data);

export default {
  handleServiceError: serviceUtils.handleServiceError,
  getSandboxTree,
  getSandboxRecommendedTree,
  deleteSandbox,
  importSandbox,
};
