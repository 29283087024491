import React from 'react';

import styles from './Spinner.module.scss';

const Spinner = ({ isVisible, defaultSpinner = false }) => {
  return (
    <div className={`${styles.Spinner} ${defaultSpinner ? styles.DefaultSpinner : ''} ${isVisible ? styles.Visible : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
