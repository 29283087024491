import { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  DIAGRAM_ENVIRONMENTS,
  NOT_TRANSLATABLE,
  SIPOC_DIAGRAM_ID,
  STATUS,
  SWIMLANE_DIAGRAM_ID,
} from 'assets/constants/constants';
import cameraGif from 'assets/images/camera.gif';
import { handleServiceError } from 'assets/js/serviceUtils';
import Diagram from 'components/Diagram/Diagram';
import NEPOSHeader from 'components/NEPOSHeader/NEPOSHeader';
import Interfaces from 'components/NEPOSTabs/Interfaces';
import KPIS from 'components/NEPOSTabs/KPIS';
import ProcessAttributes from 'components/NEPOSTabs/ProcessAttributes';
import VideoDialog from 'components/NEPOSTabs/VideoDialog';
import Page from 'components/Page/Page';
import ToolbarNEPOS from 'components/ToolbarNEPOS/ToolbarNEPOS';
import Spinner from 'components/UI/Spinner/Spinner';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useProcess from 'hooks/useProcess';
import useTool from 'hooks/useTool';
import { inUseDiagram } from 'services/design';
import titleService from 'services/titleService';
import { Interval, Language } from 'types/config';
import { ProcessTabs, ProcessType } from 'types/processes';

import styles from './NEPOS.module.scss';

const NEPOS = () => {
  const { id } = useParams<{ id: string }>();
  const {
    backgroundRef,
    diagramLanguage,
    dispatch,
    isTakingPictures,
    grabAndMove,
    isLoading,
    processData,
    updateUndoRedo,
    workflowOptions,
  } = useDiagramContext();
  const [selectedTab, setSelectedTab] = useState<ProcessTabs | null>(null);
  const { t, i18n } = useTranslation();
  const { fetchProcess, fetchPublishedProcess, getSendForReleaseOptions } = useProcess(id);
  const intervalInUse = useRef<Interval>();
  const canEdit = !processData?.isOnlyRead && [STATUS.NEW, STATUS.UPDATE, STATUS.SANDBOX].includes(processData?.status || '');
  const checkEnvironment = useEnvironment();
  const { resetTool } = useTool();

  const closeTab = () => setSelectedTab(null);

  const diagramInUse = useCallback(
    (inUse = true) => {
      if (!processData) return;
      if (!inUse) clearInterval(intervalInUse.current as Interval);

      inUseDiagram(id, inUse).catch(handleServiceError);
    },
    [id, processData?.id], // eslint-disable-line
  );

  const turnOnDiagramInUse = useCallback(() => {
    clearInterval(intervalInUse.current as Interval);
    if (!canEdit) return;

    diagramInUse();

    intervalInUse.current = setInterval(() => {
      diagramInUse();
    }, 120000); // inUse=true every 2 minutes
  }, [diagramInUse, canEdit]);

  const turnOffDiagramInUse = useCallback(() => {
    if (!canEdit) return;

    diagramInUse(false);
  }, [diagramInUse, canEdit]);

  useEffect(() => {
    turnOnDiagramInUse();

    return () => turnOffDiagramInUse();
  }, [turnOnDiagramInUse, turnOffDiagramInUse]);

  useEffect(() => {
    if (checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED)) {
      fetchPublishedProcess();
    } else {
      fetchProcess();
    }
  }, [checkEnvironment, fetchPublishedProcess, fetchProcess]);

  useEffect(() => {
    if (!processData) return;

    const idDiagramType = processData.type === ProcessType.SIPOC ? SIPOC_DIAGRAM_ID : SWIMLANE_DIAGRAM_ID;
    if (
      processData.attributes &&
      ((!processData.isOnlyRead && [STATUS.NEW, STATUS.PUBLISHED, STATUS.UPDATE].includes(processData.status)) ||
        (processData.isOnlyRead && processData.status === STATUS.STARTING_WORKFLOW)) &&
      (!workflowOptions || workflowOptions?.length === 0)
    ) {
      getSendForReleaseOptions(
        processData.attributes[NOT_TRANSLATABLE]?.VERSION,
        processData.attributes[NOT_TRANSLATABLE]?.PROCESS_NUMBER,
        processData.isRecommendation,
        idDiagramType,
      );
    }
  }, [getSendForReleaseOptions, processData?.id, processData?.status, processData?.isOnlyRead]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (diagramLanguage) return;
    dispatch({
      type: DiagramActionTypes.UPDATE_DIAGRAM_LANGUAGE,
      payload: i18n.language as Language,
    });
  }, [i18n, diagramLanguage, dispatch]);

  useEffect(() => {
    closeTab();
    resetTool();

    if (workflowOptions) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_OPTIONS, payload: [] });
    }

    if (backgroundRef.current && grabAndMove) {
      backgroundRef.current.style.cursor = 'default';
      dispatch({
        type: DiagramActionTypes.TOGGLE_GRAB_AND_MOVE,
        payload: false,
      });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const processType = processData?.type || '';
    titleService.updatePageTitle(processType, id);
  }, [id, processData?.type]);

  useEffect(() => {
    if (!updateUndoRedo) return;
    dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO_HISTORY });
  }, [updateUndoRedo, dispatch]);

  return (
    <Page>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <NEPOSHeader handleSelectTab={setSelectedTab} selectedTab={selectedTab} />
          {selectedTab === ProcessTabs.PROCESS && <ProcessAttributes handleClose={closeTab} />}
          {selectedTab === ProcessTabs.INTERFACES && <Interfaces handleClose={closeTab} />}
          {selectedTab === ProcessTabs.KPIS && <KPIS handleClose={closeTab} />}
          {selectedTab === ProcessTabs.VIDEO && <VideoDialog handleClose={closeTab} />}
          <div
            className={`${styles.Diagram} ${selectedTab !== null && selectedTab !== ProcessTabs.VIDEO ? styles.HideDiagram : ''}`}
          >
            <ToolbarNEPOS />
            <Diagram />
            {(isTakingPictures.all || isTakingPictures.onTheFly || isTakingPictures.preparation) && (
              <div className={styles.PicturesLoading}>
                <p>{t('takingPictures')}</p>
                <img alt={t('takingPictures')} src={cameraGif} />
              </div>
            )}
          </div>
          {isLoading && (
            <div className={styles.Spinner}>
              <Spinner defaultSpinner isVisible />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default NEPOS;
