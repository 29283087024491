import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getFullFormattedDate } from 'assets/js/Utils';
import Pagination from 'components/UI/Pagination/Pagination';
import Table from 'components/UI/Table/Table';
import usePagination from 'hooks/usePagination';
import NEPOSTabWrapper from 'pages/NEPOS/NEPOSTabWrapper';
import { getRequirementChanelog } from 'services/requirement';
import { AttributeCode } from 'types/forms';
import { ChangeLogValuesType, RequiredChangelogActions, RequiredChangelogRemark, RequirementsChangeLog } from 'types/requirement';
import { TableColumn } from 'types/tables';
import { CatalogUser } from 'types/user';

import styles from './ChangeLog.module.scss';

type Props = {
  handleClose: () => void;
  extraClass: string;
};

const ChangeLog = ({ handleClose, extraClass }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const getResults = useCallback(
    (params) => {
      const { page, size } = params;
      return getRequirementChanelog(id, page, size).then((res) => {
        const { changelog, catalog, totalResults, numberOfPages, totalRequest } = res.data;

        const results = changelog.map(
          (elem: {
            id: string;
            modificationDate: string;
            editorCode: string;
            action: string;
            requestId: string;
            values?: ChangeLogValuesType[];
          }) => {
            const { action, editorCode, id: reqChangeLogId, modificationDate, requestId, values } = elem;
            const formatNeededFields = [
              'CREATOR',
              'INITIAL_CATEGORY',
              'POTENTIAL_ANNUAL_EBIT',
              'POTENTIAL_ONE_TIME_CASHFLOW',
              'FINANCE_APPROVED',
              'TARGET_DATE',
            ];

            const getUserFromUserCode = (userCode) => {
              const user = catalog.USER.find((e: CatalogUser) => userCode === e.code);
              return user ? `${user.commonName} (${editorCode})` : '';
            };

            const getPotentialTypeFieldsFormattedValue = (potentialFieldName: string, valueToFormat: string) => {
              const selectedPotentialValue = [
                'SAVING_POTENTIAL_INTERVAL_S',
                'SAVING_POTENTIAL_INTERVAL_M',
                'SAVING_POTENTIAL_INTERVAL_L',
                'SAVING_POTENTIAL_INTERVAL_XL',
              ].find((potentialValue) => potentialValue === valueToFormat);

              return selectedPotentialValue ? t(`attributes.${potentialFieldName}.options?.${selectedPotentialValue}`) : '';
            };

            const changelogValuesMapper: { [key in AttributeCode]?: (valueToFormat: string) => string } = {
              [AttributeCode.CREATOR]: (valueToFormat) => getUserFromUserCode(valueToFormat),
              [AttributeCode.INITIAL_CATEGORY]: (valueToFormat) =>
                valueToFormat ? t(`attributes.INITIAL_CATEGORY.options?.${valueToFormat}`) : '',
              [AttributeCode.POTENTIAL_ANNUAL_EBIT]: (valueToFormat) =>
                getPotentialTypeFieldsFormattedValue(AttributeCode.POTENTIAL_ANNUAL_EBIT, valueToFormat),
              [AttributeCode.POTENTIAL_ONE_TIME_CASHFLOW]: (valueToFormat) =>
                getPotentialTypeFieldsFormattedValue(AttributeCode.POTENTIAL_ONE_TIME_CASHFLOW, valueToFormat),
              [AttributeCode.FINANCE_APPROVED]: (valueToFormat) => (valueToFormat ? 'Yes' : 'No'),
              [AttributeCode.TARGET_DATE]: (valueToFormat) => (valueToFormat ? getFullFormattedDate(valueToFormat) : ''),
            };

            const formatFields = (remark: string, currentValue: string) =>
              formatNeededFields.includes(remark) ? changelogValuesMapper[remark]?.(currentValue) : currentValue;

            const changes = values?.map((element) => ({
              ...element,
              remark: element.remark
                ? t(`changelog.remark.${RequiredChangelogRemark[element.remark as RequiredChangelogRemark]}`)
                : '',
              previousValue: formatFields(element.remark, element.previousValue),
              updatedValue: formatFields(element.remark, element.updatedValue),
            }));

            return {
              id: reqChangeLogId,
              requestId,
              timestamp: getFullFormattedDate(modificationDate),
              person: `${catalog.USER.find((e: CatalogUser) => editorCode === e.code).commonName} (${editorCode})`,
              action: t(`changelog.action.${RequiredChangelogActions[action as RequiredChangelogActions]}`),
              changes,
            };
          },
        );

        return {
          numberOfPages,
          results,
          totalResults: totalResults || totalRequest,
        };
      });
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { isLoading, results, totalPages, page, setPage } = usePagination({
    getResults,
    pageSize: 4,
  });

  const columns: TableColumn<RequirementsChangeLog>[] = [
    { id: 'timestamp', style: styles.Time },
    { id: 'action', style: styles.Responsible },
    { id: 'person', style: styles.Responsible },
    { id: 'changes', style: styles.Changes },
  ];

  return (
    <NEPOSTabWrapper extraClass={`${extraClass} ${styles.Wrapper}`} handleClose={handleClose}>
      <h4>
        <i className="di icon-historie-verlauf" />
        {t('changelog')}
      </h4>
      <Table columns={columns} isLoading={isLoading} prefix="changelog." rows={results || []} />
      <div className={styles.PagesSection}>
        <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
      </div>
    </NEPOSTabWrapper>
  );
};

export default ChangeLog;
