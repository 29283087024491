import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AddKPIDialog from 'components/AddKPIDialog/AddKPIDialog';
import KPIItem from 'components/KPIItem/KPIItem';
import DialogConfirm from 'components/UI/DialogBasics/DialogConfirm';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import TextButton from 'components/UI/TextButton/TextButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import NEPOSTabWrapper from 'pages/NEPOS/NEPOSTabWrapper';

import styles from './KPIS.module.scss';

type Props = {
  handleClose: () => void;
};

const KPIS = ({ handleClose }: Props) => {
  const { t } = useTranslation();
  const [isAddKpiDialogVisible, setIsAddKpiDialogVisible] = useState(false);
  const { dispatch, processData } = useDiagramContext();
  const [kpisToDelete, setKpisToDelete] = useState<string[]>([]);
  const [isDeleteKpisDialogVisible, setIsDeleteKpisDialogVisible] = useState(false);
  const [checkedMap, setCheckedMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const checkedList = Object.entries(checkedMap)
      .filter(([, isChecked]) => isChecked)
      .map(([id]) => id);
    setKpisToDelete(checkedList);
  }, [checkedMap]);

  const handleDeleteKPIS = async () => {
    if (!kpisToDelete || !processData) return;
    const filteredKPIS = processData.kpis.filter((element) => kpisToDelete.indexOf(element.id.toString()) === -1) || [];
    setKpisToDelete([]);
    setCheckedMap({});
    setIsDeleteKpisDialogVisible(false);
    dispatch({
      type: DiagramActionTypes.UPDATE_PROCESS_KPIS,
      payload: filteredKPIS,
    });
  };

  return (
    <NEPOSTabWrapper handleClose={handleClose}>
      <h4>
        <i className="di icon-computer-diagramm" />
        {t('kpis')}
      </h4>
      <div className={`${styles.Content} ${processData?.isOnlyRead ? styles.OnlyReadContent : ''}`}>
        {!processData?.isOnlyRead && (
          <div className={styles.AddButtonWrapper}>
            <span>{t('addedX', { labelX: t('kpis') })}</span>

            <TextButton id="add-kpi" onClick={() => setIsAddKpiDialogVisible(true)} text={t('addKPI')} />
          </div>
        )}
        <div className={styles.KPISList}>
          {processData?.kpis && processData.kpis.length > 0 ? (
            processData.kpis.map((kpi) => (
              <KPIItem
                checked={checkedMap}
                handleCheck={() => setCheckedMap({ ...checkedMap, [kpi.id]: !checkedMap[kpi.id] })}
                key={kpi.id}
                kpi={kpi}
              />
            ))
          ) : (
            <div className={styles.NoResults}>{t('noResults')}</div>
          )}
        </div>
      </div>
      {!processData?.isOnlyRead && (
        <div className={`${styles.RemoveButtonWrapper} ${kpisToDelete.length === 0 ? styles.Disabled : ''}`}>
          {!!kpisToDelete.length && <span>{`${kpisToDelete.length} ${t('itemSelected', { labelX: t('kpis') })}`}</span>}
          {!processData?.isOnlyRead && (
            <RoundButton
              className={`${styles.RemoveKPI} ${kpisToDelete.length === 0 ? styles.Disabled : ''}`}
              disabled={!kpisToDelete.length}
              icon="di icon-muelleimer-loeschen"
              id="delete-kpi"
              onClick={() => setIsDeleteKpisDialogVisible(true)}
            />
          )}
        </div>
      )}
      {isAddKpiDialogVisible && <AddKPIDialog close={() => setIsAddKpiDialogVisible(false)} title={t('addKPI')} />}
      {isDeleteKpisDialogVisible && (
        <DialogConfirm
          close={() => setIsDeleteKpisDialogVisible(false)}
          confirmText={t('delete')}
          handleConfirm={handleDeleteKPIS}
          title={
            <>
              <i className="di icon-information" />
              {t('kpis.deleteKPIsLabel')}
            </>
          }
        >
          {t('kpis.deleteKPIsText')}
        </DialogConfirm>
      )}
    </NEPOSTabWrapper>
  );
};

export default KPIS;
