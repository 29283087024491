import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import ColorPopup from 'components/UI/ColorPopup/ColorPopup';
import Icon from 'components/UI/Icon/Icon';

import styles from './FormattingTools.module.scss';

const FormattingTools = ({ node, isText, resizeNode, setCopiedNodeStyles }) => {
  const { t } = useTranslation();
  const [selectedFontSize, setSelectedFontSize] = useState(isText ? node.style.fontSize : node.annotations[0].style.fontSize);
  const [colorPopupOpened, setColorPopupOpened] = useState('');
  const [nodeColor, setNodeColor] = useState('');
  const [isBold, setIsBold] = useState(node.bold);
  const [isItalic, setIsItalic] = useState(node.italic);
  const [isUnderline, setIsUnderline] = useState(
    node.style.textDecoration === 'Underline' || node.annotations[0]?.style.textDecoration === 'Underline',
  );
  const [isCopied, setIsCopied] = useState(false);

  const handlePopupOpen = (value) => {
    setColorPopupOpened((oldColorPopupOpenedState) => (value === oldColorPopupOpenedState ? '' : value));
  };

  const getNodeStyles = useCallback(() => (isText ? node.style : node.annotations[0].style), [isText, node]);

  const handleResizeNode = () => {
    if (isText || node.shape.type === 'Basic') return;

    resizeNode(node.id, node.annotations[0].content);
  };

  const handleFontSizeChange = (event) => {
    const nodeStyle = getNodeStyles();
    const fontSize = isText ? parseInt(event.target.value, 10) : event.target.value;

    nodeStyle.fontSize = fontSize;
    setSelectedFontSize(fontSize);
    handleResizeNode();
  };

  const handleFontStyleChange = (fontStyle) => {
    const nodeStyle = getNodeStyles();

    if (fontStyle === 'bold' || fontStyle === 'italic') {
      nodeStyle[fontStyle] = !nodeStyle[fontStyle];

      if (fontStyle === 'bold') {
        setIsBold(nodeStyle[fontStyle]);
        handleResizeNode();
      } else {
        setIsItalic(nodeStyle[fontStyle]);
      }
    } else {
      nodeStyle[fontStyle] = nodeStyle[fontStyle] === 'Underline' ? 'None' : 'Underline';
      setIsUnderline(nodeStyle[fontStyle] === 'Underline');
    }
  };

  useEffect(() => {
    let color = node.style.fill;
    const nodeStyle = getNodeStyles();

    if (colorPopupOpened === Constants.BORDER_COLOR_POPUP) {
      color = node.style.strokeColor;

      if (isText) {
        setColorPopupOpened('');
      }
    } else if (colorPopupOpened === Constants.TEXT_COLOR_POPUP) {
      color = isText ? node.style.color : node.annotations[0].style.color;
    }

    setNodeColor(color);
    setSelectedFontSize(nodeStyle.fontSize);
    setIsBold(nodeStyle.bold);
    setIsItalic(nodeStyle.italic);
    setIsUnderline(nodeStyle.textDecoration === 'Underline');
  }, [node, isText, colorPopupOpened, getNodeStyles]);

  useEffect(() => {
    setIsCopied(false);
  }, [node]);

  useEffect(() => {
    setCopiedNodeStyles(isCopied);
  }, [isCopied, setCopiedNodeStyles]);

  const setColor = (color) => {
    const nodeStyle = !isText && colorPopupOpened === Constants.TEXT_COLOR_POPUP ? node.annotations[0].style : node.style;
    setNodeColor(color);

    if (colorPopupOpened === Constants.FILL_COLOR_POPUP) {
      nodeStyle.fill = color;
    } else if (colorPopupOpened === Constants.BORDER_COLOR_POPUP) {
      nodeStyle.strokeColor = color;
    } else {
      nodeStyle.color = color;
    }
  };

  const handleCopyStyles = () => {
    setIsCopied((oldIsCopiedState) => !oldIsCopiedState);
  };

  const copyStyleButton = {
    id: 'formatPainter',
    onClick: handleCopyStyles,
    iconClass: 'di icon-pinsel',
    selected: isCopied,
  };

  const colorButtons = [
    {
      id: Constants.BORDER_COLOR_POPUP,
      onClick: () => handlePopupOpen(Constants.BORDER_COLOR_POPUP),
      iconClass: 'di icon-stift-randfarbe',
    },
    {
      id: Constants.FILL_COLOR_POPUP,
      onClick: () => handlePopupOpen(Constants.FILL_COLOR_POPUP),
      iconClass: 'di icon-farbtopf-fuellfarbe',
    },
    {
      id: Constants.TEXT_COLOR_POPUP,
      onClick: () => handlePopupOpen(Constants.TEXT_COLOR_POPUP),
      iconClass: 'di icon-schriftfarbe',
    },
  ];

  const fontStyleButtons = [
    {
      id: 'boldStyle',
      style: 'bold',
      iconClass: 'di icon-schriftschnitt-fett',
      selected: isBold,
    },
    {
      id: 'italicStyle',
      style: 'italic',
      iconClass: 'di icon-schriftschnitt-kursiv',
      selected: isItalic,
    },
    {
      id: 'underlineStyle',
      style: 'textDecoration',
      iconClass: 'di icon-schriftschnitt-unterstrichen',
      selected: isUnderline,
    },
  ];

  return (
    <div className={styles.FormattingButtons} id="formatting-buttons">
      <div className={`${styles.FormattingButton} ${isCopied ? styles.SelectedButton : ''}`}>
        <Icon
          extraClass="sandbox-tooltip"
          iconClass={copyStyleButton.iconClass}
          id={copyStyleButton.id}
          offsetY={-10}
          onClick={copyStyleButton.onClick}
          tooltip={t(`diagram.formattingTools.${copyStyleButton.id}`)}
        />
      </div>
      <div className={styles.VerticalLine} />
      {colorButtons.map((button) => {
        if (button.id === Constants.BORDER_COLOR_POPUP && isText) {
          return null;
        }

        return (
          <div className={styles.FormattingButton} key={button.id}>
            <Icon
              extraClass="sandbox-tooltip"
              iconClass={button.iconClass}
              id={button.id}
              key={button.id}
              offsetY={-10}
              onClick={button.onClick}
              tooltip={t(`diagram.formattingTools.${button.id}`)}
            />
          </div>
        );
      })}
      <div className={styles.VerticalLine} />
      <div className={styles.SizeSelect}>
        <select id="fontSize" onChange={handleFontSizeChange} value={selectedFontSize}>
          {Constants.FONT_SIZES.map((fontSize) => (
            <option
              className={selectedFontSize === fontSize ? styles.SelectedFontSize : ''}
              key={`fontSize_${fontSize}`}
              value={fontSize}
            >
              {fontSize}
            </option>
          ))}
        </select>
      </div>
      {fontStyleButtons.map((button) => (
        <div
          className={`${styles.FormattingButton} ${button.selected ? styles.SelectedButton : ''}`}
          key={button.id}
          onClick={() => handleFontStyleChange(button.style)}
        >
          <Icon
            extraClass="sandbox-tooltip"
            iconClass={button.iconClass}
            id={button.id}
            key={button.id}
            offsetY={-10}
            tooltip={t(`diagram.formattingTools.${button.id}`)}
          />
        </div>
      ))}
      {colorPopupOpened && (
        <ColorPopup isText={isText} nodeColor={nodeColor} popupOpened={colorPopupOpened} setColor={setColor} />
      )}
    </div>
  );
};

export default FormattingTools;
