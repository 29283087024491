import { useContext } from 'react';

import { DatasetContext } from 'contexts/Dataset/DatasetContext';

export default function useDatasetContext() {
  const context = useContext(DatasetContext);

  if (context === undefined) {
    throw new Error('useDatasetContext must be used within a DatasetProvider');
  }

  const { state, dispatch } = context;

  return {
    ...state,
    dispatch,
  };
}
