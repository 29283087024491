import React, { useRef, useState } from 'react';

import ErrorLabel from 'components/UI/ErrorLabel/ErrorLabel';

import styles from './InputFile.module.scss';

const InputFile = ({ className, error, extension, meta, setValue }) => {
  const { attributeType, id, label, buttonLabel, dropzoneLabel } = meta;
  const [isDragging, setIsDragging] = useState(false);
  const fileInput = useRef(null);

  const handleSelectFile = (event) => {
    setValue(event.target.files[0]);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <div className={`form-group ${styles.FormGroup} ${className || ''}`}>
      <label className={error ? 'error' : ''} htmlFor={id}>
        {label}
        {attributeType === 'M' ? '*' : ''}
      </label>
      <ErrorLabel message={error} visible={Boolean(error)} />
      <div className={styles.Container}>
        <div className={styles.FirstRow}>
          <button className={styles.Button} onClick={handleClick} type="button">
            {buttonLabel}
          </button>
          {fileInput.current?.files[0] && <span>{fileInput.current.files[0].name}</span>}
        </div>
        <div className={`${styles.DragZone} ${isDragging ? styles.Dragging : ''}`}>
          {dropzoneLabel}
          <input
            accept={extension}
            className={`form-control ${styles.Input}`}
            onChange={handleSelectFile}
            onDragEnter={() => setIsDragging(true)}
            onDragLeave={() => setIsDragging(false)}
            onDrop={() => setIsDragging(false)}
            ref={fileInput}
            title=""
            type="file"
          />
        </div>
      </div>
    </div>
  );
};

export default InputFile;
