import * as Constants from '../../assets/constants/constants';
import circleImg from '../../assets/images/circle.png';
import diamondImg from '../../assets/images/diamond.png';
import lineImg from '../../assets/images/line.png';
import shapeTool from '../../assets/images/shapeDesignTool.png';
import squareImg from '../../assets/images/square.png';
import triangleImg from '../../assets/images/triangle.png';

export const getNodeDefaults = (symbol) => {
  if (symbol.id === Constants.CLOSED_PROCESS_OVERVIEW || symbol.id === Constants.PROCESS_STEP) {
    symbol.width = 80;
    symbol.height = 40;
  } else if (symbol.id === Constants.AND_GATE || symbol.id === Constants.OR_GATE || symbol.id === Constants.XOR_GATE) {
    symbol.width = 50;
    symbol.height = 50;
  } else if (symbol.id === Constants.EVENT) {
    symbol.width = 80;
    symbol.height = 30;
  } else if (symbol.id === Constants.PROCESS_INTERFACE) {
    symbol.width = 80;
    symbol.height = 30;
  } else if (symbol.id === Constants.OPEN_PROCESS_OVERVIEW) {
    symbol.width = 80;
    symbol.height = 30;
  } else {
    symbol.width = 50;
    symbol.height = 40;
  }
};

const getGateInfo = (text) => ({
  width: 52,
  height: 52,
  fit: true,
  description: { text, overflow: 'Wrap' },
});

export const getSymbolInfo = (symbol) => {
  if (symbol.id === Constants.OR_GATE || symbol.id === Constants.XOR_GATE || symbol.id === Constants.AND_GATE) {
    return getGateInfo(symbol.id.replace('_GATE', ''));
  }

  return {
    fit: true,
  };
};

export const tools = ['shapes', 'square', 'circle', 'diamond', 'triangle', 'line'];

export const images = {
  shapes: shapeTool,
  square: squareImg,
  circle: circleImg,
  diamond: diamondImg,
  triangle: triangleImg,
  line: lineImg,
};
