import { useTranslation } from 'react-i18next';

import styles from './RadioButtonNEPOS.module.scss';

interface Props {
  disabled?: boolean;
  id?: string;
  label?: string;
  options: Array<string>;
  className?: string;
  code: string;
  error?: string;
  onChange: (option: string) => void;
  optionTranslationKey?: string;
  required?: boolean;
  value: string;
}

const RadioButtonNEPOS = (props: Props) => {
  const { disabled, id, label, options, className, code, error, onChange, optionTranslationKey, required, value } = props;
  const { t } = useTranslation();

  const hasError = Boolean(error);

  const getTranslation = (option: string) =>
    t(`${optionTranslationKey || `attributes.${code}.options`}?.${option}`, { defaultValue: option });

  return (
    <>
      <label
        className={` ${hasError ? styles.ErrorLine : ''} ${styles.Container} ${disabled ? styles.Disabled : ''}  ${
          className || ''
        }`}
        htmlFor={id}
      >
        <div className={`${hasError ? 'error' : ''} ${styles.Label} ${styles.Floating}`}>
          {label}
          {required && !disabled && <span className="error">*</span>}
        </div>
        <div className={`${styles.WrapperOptions} ${code === 'LEVEL' ? styles.Vertical : ''}`}>
          {options.map((option) => (
            <div className={styles.Option} key={option}>
              <div
                className={`${styles.WrapperRadioButton} ${option === value ? styles.isActive : ''} ${
                  disabled ? styles.Disabled : ''
                }`}
                id={`${id}-${option}`}
                onClick={() => !disabled && onChange(option)}
              >
                <div className={styles.RadioButton}>{option === value && <div className={styles.Center} />}</div>
              </div>
              <span className={styles.OptionLabel}>{getTranslation(option)}</span>
            </div>
          ))}
        </div>
      </label>
    </>
  );
};

export default RadioButtonNEPOS;
