import { Language } from './config';
import { FormLanguage } from './forms';

export enum Dimension {
  HEIGHT = 'height',
  WIDTH = 'width',
}

export type Dimensions = {
  [key in Dimension]: number;
};

export type ScreenCoordinates = {
  x: number;
  y: number;
};

export enum Coordinate {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export type Coordinates = {
  [Coordinate.LEFT]: number;
  [Coordinate.TOP]: number;
};
export type AllCoordinates = Coordinates & {
  [Coordinate.BOTTOM]: number;
  [Coordinate.RIGHT]: number;
};

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type Background = Dimensions;

export type Bounds = Dimensions & AllCoordinates;

export type Origin = Coordinates;

export type Padding = {
  [key in Orientation]: number;
};

export enum Direction {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export const VerticalDirections: Direction[] = [Direction.TOP, Direction.BOTTOM];
export const HorizontalDirections: Direction[] = [Direction.LEFT, Direction.RIGHT];

export enum ResizeHandles {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER_RIGHT = 'CENTER_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export enum TextFieldResizehandles {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum Tool {
  NONE = '',
  ADD_SYMBOL = 'ADD_SYMBOL',
  CONNECT = 'CONNECT',
  DISPLAY_POSTITS = 'DISPLAY_POSTITS',
  DISPLAY_ATTRIBUTES = 'DISPLAY_ATTRIBUTES',
  DISPLAY_ROLES = 'DISPLAY_ROLES',
  DISPLAY_IT_SYSTEMS = 'DISPLAY_IT_SYSTEMS',
  DISPLAY_INPUTS = 'DISPLAY_INPUTS',
  DISPLAY_OUTPUTS = 'DISPLAY_OUTPUTS',
}

export enum PaletteActions {
  NONE = '',
  ADD_SYMBOL = 'ADD_SYMBOL',
  ADD_TEXTFIELD = 'ADD_TEXTFIELD',
  ADD_SWIMLANE = 'ADD_SWIMLANE',
  ADD_AND_GATE = 'ADD_AND_GATE',
  ADD_OR_GATE = 'ADD_OR_GATE',
  ADD_XOR_GATE = 'ADD_XOR_GATE',
  ADD_PROCESS_STEP = 'ADD_PROCESS_STEP',
  ADD_PROCESS_INTERFACE = 'ADD_PROCESS_INTERFACE',
  ADD_EVENT = 'ADD_EVENT',
}

export type DiagramToImport = {
  id: number;
  type: string;
  name: {
    [key in Language]?: string;
  };
  isRecommendation?: boolean;
};

export type DiagramToLink = {
  id: number;
  type: string;
  name: string;
  idDiagramType: number;
  isRecommendation?: boolean;
};

export type DiagramToMove = {
  id: number;
  type: string;
  name: string;
  idDiagramType: number;
  isRecommendation?: boolean;
};

export enum PictureAction {
  all = 'all',
  onTheFly = 'onTheFly',
}

export type LinkedDiagram = {
  id: string;
  idProcess: string;
  status: string;
  nameMap: {
    [key in FormLanguage]?: {
      PROCESS_NAME: string;
    };
  };
};
