import React from 'react';

import { withTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import LanguageMenu from 'components/LanguageMenu/LanguageMenu';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import Icon from 'components/UI/Icon/Icon';
import useToastContext from 'components/UI/ToastContext/useToastContext';
import useFeatureFlags from 'hooks/useFeatureFlags';

import styles from './Toolbar.module.scss';

const Toolbar = (props) => {
  const {
    toolIcons,
    zoomIcons,
    swimlaneIcon,
    t,
    isWorkflow,
    isDiagram,
    buttons,
    leftButtons,
    onlyRead,
    onlyReadReason,
    isPublished,
    showLanguageMenu,
    showToolbarButtons = !onlyRead || isWorkflow || isPublished,
    diagramType,
    getLanguage,
    language,
    disabled,
    toolbarType,
    userBlock = '',
    inFavorite,
    setFavorites,
    singleObjectType,
    objectsType,
    openDialog,
    diagramId,
    diagramLanguages,
    disabledLanguageStatus,
    isDatasetBlocked,
    isClosedWorkflow,
  } = props;
  const addToast = useToastContext();
  const { areSwimlanesEnabled } = useFeatureFlags();

  const toggleFavorite = () => {
    if (!inFavorite) {
      addToast(t('addFavorites'), Constants.INFO, { url: `${window.location.origin}/processes`, text: t('viewAll') });
    } else {
      addToast(t('removeFavorites'), Constants.SUCCESS);
    }
    setFavorites();
  };

  const blockTag = (
    <div className={styles.WrapperOnlyRead}>
      {(onlyReadReason || isDatasetBlocked) && !isClosedWorkflow && (
        <div className={styles.ContainerOnlyRead}>
          <i className={`di icon-schloss-sperren ${styles.Lock}`} />
          <span id="onlyReadReason">
            {isDatasetBlocked
              ? t('datasetInUse', { diagramType, userBlock, singleObjectType, objectsType })
              : t(`diagram.${onlyReadReason}`, { diagramType, userBlock, singleObjectType, objectsType })}
          </span>
        </div>
      )}
    </div>
  );

  const toolIconsBlock = toolIcons && toolIcons.map((icon) => <Icon id={icon.id} key={icon.id} onClick={icon.click} {...icon} />);

  const zoomIconsBlock = zoomIcons && zoomIcons.map((icon) => <Icon id={icon.id} key={icon.id} onClick={icon.click} {...icon} />);

  const toolButtonsBlock =
    buttons &&
    buttons.map((btn) => (
      <btn.component
        handleClick={btn.id === 'addFavorites' ? () => toggleFavorite() : btn.handleClick}
        icon={btn.icon}
        id={btn.id}
        key={btn.id}
        t={t}
        text={btn.text}
        {...btn}
      />
    ));

  return (
    <div className={`${styles.Toolbar} ${toolbarType}`}>
      <div className={styles.Container}>
        {isDiagram && (
          <LanguageSelector
            diagramLanguages={diagramLanguages}
            disabled={disabled}
            disabledLanguageStatus={disabledLanguageStatus}
            getLanguage={(lng) => getLanguage(lng)}
            languageSelected={language}
          />
        )}
        {showLanguageMenu && <LanguageMenu diagramId={diagramId} disabled={disabled} openDialog={openDialog} />}
        {leftButtons}
      </div>
      {!isPublished && (toolIcons?.length > 0 || onlyReadReason || isDatasetBlocked) && (
        <div className={`${styles.Container} ${styles.Icons}`}>
          {(onlyRead || isDatasetBlocked) && blockTag}
          {!onlyRead && !isPublished && toolIconsBlock}
        </div>
      )}
      {isDiagram && <div className={`${styles.Container} ${styles.Icons}`}>{zoomIconsBlock}</div>}
      {areSwimlanesEnabled && toolbarType && (
        <div className={`${styles.Container} ${styles.Icons}`}>
          <Icon id={swimlaneIcon.id} key={swimlaneIcon.id} onClick={swimlaneIcon.click} {...swimlaneIcon} />
          <span className={styles.Label}>{swimlaneIcon.label}</span>
        </div>
      )}
      <div className={styles.Container}>{showToolbarButtons && toolButtonsBlock}</div>
    </div>
  );
};

export default withTranslation('common')(Toolbar);
