import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import styles from './ErrorTooltip.module.scss';

const ErrorTooltip = ({ message }) =>
  message ? (
    <TooltipComponent
      content={message}
      cssClass="mbc-tooltip error-tooltip"
      position="RightCenter"
      showTipPointer={false}
      width={180}
    >
      <i className={`fas fa-exclamation-circle ${styles.ErrorIcon}`} />
    </TooltipComponent>
  ) : null;

export default ErrorTooltip;
