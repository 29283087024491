import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, KPI, MODELING_STATUS, CREATE } from 'assets/constants/constants';
import DialogMultilanguage from 'components/UI/DialogMultilanguage/DialogMultilanguage';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import { Attributes } from 'types/forms';
import { ProcessKPI } from 'types/processes';

import styles from './AddKPIDialog.module.scss';

type Props = {
  close: () => void;
  title: string;
};

const AddKPIDialog = ({ close, title }: Props) => {
  const { i18n, t } = useTranslation();
  const { getFormTypesCode } = useFormTypes();
  const { dispatch, processData } = useDiagramContext();
  const formCode = getFormTypesCode({
    status: MODELING_STATUS,
    type: KPI,
    variant: CREATE,
  });
  const { fields, Form, isLanguageValid, isValid, values } = useBackendForm({ formCode });

  const handleAddKPIS = async () => {
    if (!values || !processData) return;
    const existingKPIS = processData.kpis || [];
    const newKPI: ProcessKPI = {
      id: uuidv4(),
      attributes: values as Attributes,
    };
    dispatch({
      type: DiagramActionTypes.UPDATE_PROCESS_KPIS,
      payload: [...existingKPIS, newKPI],
    });
    close();
  };

  const buttons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !isValid,
      handleClick: handleAddKPIS,
      key: 'confirm',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: close,
      key: 'cancel',
    },
  ];

  return (
    <DialogMultilanguage
      buttons={buttons}
      initialLanguage={i18n.language}
      isLanguageValid={isLanguageValid}
      isNEPOS
      title={title}
    >
      <Form className={styles.Form} fields={fields} />
    </DialogMultilanguage>
  );
};

export default AddKPIDialog;
