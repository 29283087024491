import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import InputTextNEPOS from 'components/UI/InputText/InputTextNEPOS';

import styles from 'components/DialogDeleteDiagram/DialogDeleteDiagram.module.scss';

type Props = {
  body?: string;
  close: () => void;
  confirm: (val: string) => void;
  label?: string;
  title: string;
};

const DialogInput = (props: Props) => {
  const { close, confirm, title, body, label = '' } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const buttons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('confirm'),
      disabled: !value,
      handleClick: () => confirm(value),
      key: 'confirm',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('Back'),
      handleClick: close,
      key: 'back',
    },
  ];

  return (
    <DialogNEPOS
      dialog={{
        title,
        buttons,
      }}
    >
      <p className={styles.DeleteDialogText}>{body}</p>
      <InputTextNEPOS id="dialog-input" label={label} onChange={(val) => setValue(val)} value={value} />
    </DialogNEPOS>
  );
};

export default DialogInput;
