import { useCallback } from 'react';

import { useLocation } from 'react-router';

export default function useEnvironment() {
  const location = useLocation();

  const checkEnvironment = useCallback((key: string) => location.pathname.includes(key), [location.pathname]);

  return checkEnvironment;
}
