import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useOuterClick from 'hooks/useOuterClick';

import * as Constants from '../../../assets/constants/constants';
import * as Utils from '../../../assets/js/Utils';
import CheckboxPlain from '../CheckBox/CheckboxPlain';
import DateFilter from './DateFilter';
import MultiselectionFilter from './MultiselectionFilter';
import PopupFilter from './PopupFilter';
import styles from './SearchFilter.module.scss';

const SearchFilter = (props) => {
  const {
    filter,
    paginationFilters,
    filtersList,
    filtersInfo,
    getResults,
    options = {},
    handleChange,
    handleFiltersChange,
    resetFilter,
    isRecommendation = false,
    value,
    isDiagramSelector,
    handleFilterClick,
    selected,
    id,
  } = props;
  const { t } = useTranslation();
  const boxRef = useRef(null);
  const [filterOpened, setFilterOpened] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const handleOuterClick = useCallback((event) => {
    if (boxRef.current.contains(event.target)) return;
    setFilterOpened(false);
  }, []);
  useOuterClick(boxRef, handleOuterClick);

  const handleCheck = (isChecked, filterId) => {
    if (!isChecked) {
      const newSelectedOptions = [...selectedList];
      newSelectedOptions.splice(newSelectedOptions.indexOf(filterId), 1);
      setSelectedList([...newSelectedOptions]);
    } else {
      setSelectedList([...selectedList, filterId]);
    }
  };

  const handleSelection = (e) => {
    if (selectedList.find((option) => option.value === e.value)) {
      const newList = [...selectedList];
      newList.splice(newList.indexOf(e), 1);
      setSelectedList([...newList]);
    } else {
      setSelectedList([...selectedList, e]);
    }
  };

  const fieldsFilter = {
    checkbox: Object.keys(options).map((key) => (
      <CheckboxPlain
        checked={value?.some((element) => element === options[key].keyName)}
        getChecked={handleCheck}
        id={options[key].keyName}
        key={options[key].id}
        label={options[key].label}
      />
    )),
    input: <input className={styles.Input} onChange={(e) => handleChange(e)} placeholder={filter.placeholder} type="text" />,
    users: (
      <MultiselectionFilter
        filter={filter}
        getResults={getResults}
        icon="di icon-person"
        selected={selectedList}
        toggleSelection={handleSelection}
      />
    ),
    locations: (
      <MultiselectionFilter
        filter={filter}
        getResults={getResults}
        icon="di icon-weltkugel"
        selected={selectedList}
        toggleSelection={handleSelection}
      />
    ),
    date: <DateFilter date={value} setDate={setSelectedList} />,
  };

  const openFilter = () => {
    setFilterOpened(!filterOpened);
    if (handleFilterClick) handleFilterClick(filter);
  };

  const resetClick = () => {
    const name = filter.nameFilter;
    const filters = Utils.cloneObject(filtersList);
    const filterReset = filtersInfo.find((f) => f.nameFilter === name);

    filters[name].filterData = filterReset.filterType === Constants.TEXTINPUT ? '' : [];
    filterReset.isSelected = false;
    filterReset.isOpen = false;

    if (filterReset.filterType === Constants.CHECKBOX) {
      Object.keys(filterReset.options).forEach((option) => {
        filterReset.options[option].checked = false;
      });
      setSelectedList([]);
      resetFilter(filterReset.nameFilter);
      if (filterReset.nameFilter === 'type') {
        filters[name].filterData = [Constants.VCD_DIAGRAM_ID, Constants.EPC_DIAGRAM_ID];
      }
    } else if (filterReset.filterType === Constants.USER || filterReset.filterType === Constants.LOCATION) {
      let allUsers = Utils.cloneObject(filtersList[name].filterData);

      allUsers = allUsers.map((user) => ({
        ...user,
        checked: false,
      }));
      setSelectedList([]);
      resetFilter(filterReset.nameFilter);
      filters[name].filterData = allUsers;
    } else {
      setSelectedList([]);
      resetFilter(filterReset.nameFilter);
    }

    setFilterOpened(!filterOpened);
  };

  const applyClick = () => {
    if (!selectedList.length) {
      return resetClick();
    }
    handleFiltersChange({ [filter.nameFilter]: selectedList?.map((option) => (option.value ? option.value : option)).join(',') });
    setFilterOpened(false);
  };

  useEffect(() => {
    if (Object.keys(paginationFilters).length === 0) {
      setSelectedList([]);
    }
  }, [paginationFilters]);

  return (
    <div className={styles.FilterDisplay} ref={boxRef}>
      <div
        className={`${styles.Filter} ${
          value || filter.isSelected || selected?.[0]?.nameFilter === filter.nameFilter ? styles.Selected : ''
        }
          ${isRecommendation ? styles.RecommendationFilter : ''}`}
        id={`tab-${id}`}
        onClick={openFilter}
      >
        {t(filter.text)}
      </div>
      {filterOpened && !isDiagramSelector && (
        <PopupFilter applyClick={() => applyClick()} resetClick={resetClick}>
          {fieldsFilter[filter.filterType]}
        </PopupFilter>
      )}
    </div>
  );
};

export default SearchFilter;
