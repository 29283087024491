import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Search from '../Search/Search';
import styles from './SearchFilter.module.scss';

const ResultsList = ({ array, handleFunction, isSelected }) =>
  array?.map((element) => (
    <div
      className={isSelected || element.isSelected ? styles.UsersSelected : styles.Results}
      key={element.id || element.code}
      onClick={() => handleFunction(element)}
    >
      <div className={styles.User}>
        <i className="di icon-person" />
        <div className={styles.UserName}>{element.scope || element.commonName}</div>
      </div>
      {element.checked && <i className={`di icon-kreis-check ${styles.UserCheck}`} />}
      {isSelected && <i className={`di icon-issue-status-circle ${styles.IconX}`} />}
    </div>
  ));

const MultiselectionFilterLegacy = ({ allResults, toggleSelection }) => {
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const selected = () => allResults.filter((u) => u.checked);

  const searchResults = (text) => {
    if (text.trim()) {
      setResults(allResults.filter((r) => r.commonName?.toLowerCase().includes(text.toLowerCase())));
    }
  };

  return (
    <>
      <div className={styles.SearchBox}>
        <Search searching={() => {}} sendSearch={(text) => searchResults(text)} />
      </div>
      <div className={styles.UsersList}>
        {selected()?.length > 0 && <p>{t('selected').toUpperCase()}</p>}
        <ResultsList array={selected()} handleFunction={toggleSelection} selectedList />
      </div>
      <hr className={styles.Line} />
      <div className={styles.UsersList}>
        <p>{t('results').toUpperCase()}</p>
        <ResultsList array={results} handleFunction={toggleSelection} />
      </div>
    </>
  );
};

export default MultiselectionFilterLegacy;
