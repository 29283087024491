import { useEffect, useRef, useState, useMemo, useCallback } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import ConnectorPorts from 'components/ConnectorPorts/ConnectorPorts';
import Selection from 'components/Selection/Selection';
import SwimlaneConnector, { SWIMLANE_CONNECTOR_MARGIN_TOP } from 'components/Swimlanes/SwimlaneConnector';
import SymbolAttributes from 'components/Symbol/SymbolAttributes/SymbolAttributes';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes, movingSwimlaneSymbols, setSwimlaneDisplayingAttributes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useOuterClick from 'hooks/useOuterClick';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import useSymbol from 'hooks/useSymbol';
import useTool from 'hooks/useTool';
import useValidation from 'hooks/useValidation';
import { PaletteActions, Tool } from 'types/diagram';
import { Attributes } from 'types/forms';
import { ProcessStatus } from 'types/processes';
import { SwimlaneV2 } from 'types/swimlanes';
import { SelectionButton, SymbolMeta, SymbolTypes } from 'types/symbols';

import styles from './Event.module.scss';

type Props = {
  id: string;
  isPaletteItem?: boolean;
  swimlaneId?: string;
  attributes?: Attributes;
  meta?: SymbolMeta;
};

const Event = (props: Props) => {
  const { attributes, id, isPaletteItem, swimlaneId, meta } = props;
  const { handleDragStart } = useDragAndDrop();
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleOuterClick, onNameChange } = useSymbol();
  const { getSelectedSwimlane, getSelectionButtons } = useSwimlane();
  const { toggleTool, tool } = useTool();
  const { dispatch, processData, grabAndMove, editableSymbol, diagramLanguage, selectedConnectorId, fontSize } =
    useDiagramContext();
  const { handleSelect, handleSelectionOuterClick } = useSelection();
  const { t } = useTranslation();
  const { symbolsIdsWithErrors } = useValidation();
  const [selectionButtons, setSelectionButtons] = useState<SelectionButton[]>();
  const [isDraggable, setIsDraggable] = useState(false);
  const [showAttributes, setShowAttributes] = useState(false);
  const swimlane = processData?.swimlanes.find((s: SwimlaneV2) => s.id === swimlaneId);
  const symbolNameInputRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hasErrors = !isPaletteItem && symbolsIdsWithErrors && symbolsIdsWithErrors.find((symbolId) => symbolId === id);
  const isStartEvent = useMemo(
    () => attributes?.NOT_TRANSLATABLE?.START_EVENT === 'true',
    [attributes?.NOT_TRANSLATABLE?.START_EVENT],
  );
  const isLastEvent = useMemo(
    () => attributes?.NOT_TRANSLATABLE?.LAST_EVENT === 'true',
    [attributes?.NOT_TRANSLATABLE?.LAST_EVENT],
  );

  useEffect(() => {
    if (editableSymbol === id && textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [editableSymbol, id]);

  const onDragStart = (e: any) => {
    if (!isDraggable || processData?.isOnlyRead) return;
    handleDragStart(e);
    if (isPaletteItem) {
      dispatch({
        type: DiagramActionTypes.SET_PALETTE_ACTION,
        payload: PaletteActions.ADD_EVENT,
      });
      dispatch(movingSwimlaneSymbols(true));
    } else if (swimlaneId) {
      dispatch(movingSwimlaneSymbols(true, id, swimlaneId));
    }
  };

  useEffect(() => {
    const isEventDraggable = !(isStartEvent || isLastEvent || tool === Tool.CONNECT || grabAndMove || selectedConnectorId);

    setIsDraggable(isEventDraggable);
  }, [grabAndMove, tool, selectedConnectorId, isStartEvent, isLastEvent]);

  useEffect(() => {
    const selectedSwimlane = getSelectedSwimlane(swimlaneId, processData?.swimlanes);

    setSelectionButtons(
      getSelectionButtons({
        diagramData: {
          isOnlyRead: processData?.isOnlyRead,
          swimlaneId,
          selectedSwimlane,
          startEventsIds: processData?.startEvents?.map((event) => event.id),
          lastEventsIds: processData?.lastEvents?.map((event) => event.id),
        },
        id,
        symbolType: SymbolTypes.EVENT,
      }),
    );
  }, [id, processData?.isOnlyRead, processData?.startEvents, processData?.swimlanes, swimlaneId]); // eslint-disable-line react-hooks/exhaustive-deps

  useOuterClick(symbolNameInputRef, handleOuterClick, id);
  useOuterClick(containerRef, handleSelectionOuterClick, id);

  const getConnectorHeight = useCallback(
    (isLast = false) => {
      if (!isStartEvent && !isLastEvent) return;
      const OFFSET = 14;

      const eventPos = containerRef.current?.getBoundingClientRect();
      const catalogBoxPos = document.getElementById(`Catalog-Box-${isLast ? 'last' : 'start'}-${id}`)?.getBoundingClientRect();
      const heightDifference = catalogBoxPos && eventPos ? (catalogBoxPos?.y - eventPos?.y) / (fontSize || 1) : 0;
      const connectorHeight =
        heightDifference > -OFFSET ? heightDifference + OFFSET : heightDifference + OFFSET + SWIMLANE_CONNECTOR_MARGIN_TOP;

      return connectorHeight > 3 || connectorHeight < -3 ? connectorHeight : 0;
    },
    [id, isStartEvent, isLastEvent, fontSize],
  );

  return (
    <>
      {!isPaletteItem && <Selection actionButtons={selectionButtons} className={styles.Selection} symbolId={id} />}
      <div
        className={`${styles.Container} ${isPaletteItem ? styles.isPaletteItem : ''}`}
        draggable={!processData?.isOnlyRead && editableSymbol !== id && isDraggable}
        id={id}
        onClick={() => isPaletteItem && toggleTool(Tool.ADD_SYMBOL)}
        onDoubleClick={() => {
          if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW) {
            dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: id });
          }
        }}
        onDragEnd={() => dispatch(movingSwimlaneSymbols(false))}
        onDragStart={onDragStart}
        onMouseLeave={() => {
          dispatch(setSwimlaneDisplayingAttributes(''));
          setShowAttributes(false);
        }}
        ref={containerRef}
      >
        {isStartEvent && swimlane && meta && <SwimlaneConnector height={getConnectorHeight()} />}

        {isLastEvent && swimlane && meta && (
          <SwimlaneConnector end height={getConnectorHeight(true)} length={swimlane.meta.width - (meta.left + 1)} />
        )}
        <div
          className={`${styles.Element} ${hasErrors ? styles.Error : ''}`}
          onClick={(e) => {
            handleSelect(id, e);
          }}
        >
          <ConnectorPorts
            portStyles={{
              leftPortClass: styles.LeftPort,
              rightPortClass: styles.RightPort,
            }}
            symbolId={id}
          />

          <div className={styles.Content}>
            {editableSymbol === id ? (
              <div className={styles.SymbolNameInput} ref={symbolNameInputRef} style={{ fontSize: fontSize || 'inherit' }}>
                <textarea
                  onChange={(event) => {
                    onNameChange(id, event.target.value, textAreaRef.current);
                  }}
                  placeholder={t('diagram.objects.defaultName')}
                  ref={textAreaRef}
                  rows={1}
                  value={attributes ? attributes[diagramLanguage]?.OBJECT_NAME : ''}
                />
              </div>
            ) : (
              <TooltipComponent
                content={isPaletteItem ? t('EVENT') : attributes?.[diagramLanguage]?.OBJECT_NAME || ''}
                cssClass={`mbc-tooltip nepos-tooltip ${
                  isPaletteItem || attributes?.[diagramLanguage]?.OBJECT_NAME ? '-' : 'display-none'
                }`}
                position="BottomCenter"
                showTipPointer={false}
              >
                <p className={styles.SymbolName} style={{ fontSize: isPaletteItem ? '' : fontSize || 'inherit' }}>
                  {isPaletteItem ? t('EVENT') : attributes?.[diagramLanguage]?.OBJECT_NAME || t('diagram.objects.defaultName')}
                </p>
              </TooltipComponent>
            )}
            {!isPaletteItem && (
              <div className={styles.WrapperButtons}>
                {attributes && attributes[diagramLanguage]?.LINKAGE && (
                  <a href={attributes[diagramLanguage]?.LINKAGE?.toString()} rel="noopener noreferrer" target="_blank">
                    <RoundButton
                      className={styles.Link}
                      icon="di icon-link-extern"
                      id={`processStep-link-${id}`}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </a>
                )}
                {(processData?.isOnlyRead ||
                  processData?.isPublishedVersion ||
                  processData?.status === ProcessStatus.WORKFLOW) && (
                  <RoundButton
                    className={styles.SymbolInfo}
                    icon="di icon-information"
                    id={`symbol-diagram-info-ico-${id}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(setSwimlaneDisplayingAttributes(swimlaneId));
                      setShowAttributes((prv) => !prv);
                      dispatch({ type: DiagramActionTypes.UPDATE_PROCESS_DATA });
                    }}
                  />
                )}
                {showAttributes && attributes && (
                  <SymbolAttributes attributes={attributes} id={id} symbolType={SymbolTypes.EVENT} top={6.2} width={15} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
