import styles from './IconButton.module.scss';

type IconButtonProps = {
  dataQA: string;
  icon: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  notifications?: number;
  children?: string;
  noHover?: boolean;
  error?: boolean;
  isEdited?: boolean;
  resetButtonClick?: () => void;
  extraClass?: string;
};

const IconButton = ({
  dataQA,
  icon,
  children = '',
  onClick,
  selected,
  disabled,
  notifications,
  noHover,
  error,
  isEdited,
  resetButtonClick,
  extraClass,
}: IconButtonProps) => {
  const selectedClass = selected ? styles.Selected : '';
  const favoriteClass = noHover ? styles.Favorite : '';

  return (
    <button
      className={`${styles.Button} ${selectedClass} ${favoriteClass} ${extraClass || ''}`}
      data-qa={dataQA}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <i className={`${styles.Icon} ${error ? `di icon-blitz-fehler ${styles.Error}` : `di ${icon}`}`} />
      <span>{children}</span>
      {typeof notifications === 'number' && <span className={styles.Notifications}>{notifications}</span>}
      {isEdited && (
        <div
          className={styles.ResetButton}
          onClick={(e) => {
            e.stopPropagation();
            if (resetButtonClick) resetButtonClick();
          }}
        >
          <i className={`${styles.Icon} di icon-schliessen`} />
        </div>
      )}
    </button>
  );
};

export default IconButton;
