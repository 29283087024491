import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FormGenerator } from 'react-reactive-form';
import { useHistory } from 'react-router-dom';

import * as Constants from '../../assets/constants/constants';
import TransferHistory from '../../components/TransferHistory/TransferHistory';
import FormDialog from '../../components/UI/FormDialog/FormDialog';
import recommendationServices from '../../services/recommendationService';
import * as Config from './TransferDialogConfig';

let form;

const TransferDialog = (props) => {
  const { id, isVisible, setIsVisible, usersHistorical = [] } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [fieldConfig, setFieldConfig] = useState({ controls: {} });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const newFieldConfig = Config.getTransferDialogFieldConfig(t);
    setFieldConfig(newFieldConfig);
  }, [t]);

  const checkForm = () => {
    let formFilled = false;

    if (
      form.get(Constants.NEW_ASSIGNEE) &&
      form.get(Constants.NEW_ASSIGNEE).value?.length === 1 &&
      form.get(Constants.REASON).value
    ) {
      formFilled = true;
    }
    setIsFormValid(formFilled);
  };
  const onMount = (mountedForm) => {
    form = mountedForm;
    form.valueChanges.subscribe(() => {
      checkForm();
    });
  };
  const closeDialog = () => setIsVisible(false);
  const onClickTransfer = () => {
    closeDialog();
    recommendationServices
      .transferTask({
        comment: form.get(Constants.REASON).value,
        idRecommended: id.toString(),
        newCodeUser: form.get(Constants.NEW_ASSIGNEE).value[0].Code,
      })
      .then(() => {
        history.push('/dashboard');
      })
      .catch((err) => {
        recommendationServices.handleServiceError(err);
      });
  };

  return (
    <FormDialog
      actionButtonId="Transfer"
      actionName={t('recommendations.view.buttons.transfer')}
      cancelName={t('cancel')}
      close={closeDialog}
      id="transferTaskDialog"
      info={t('recommendations.view.transfer.description')}
      returnAction={onClickTransfer}
      title={t('recommendations.view.transfer.title')}
      value={isFormValid}
      visible={isVisible}
    >
      <TransferHistory list={usersHistorical} />
      <FormGenerator fieldConfig={fieldConfig} onMount={onMount} />
    </FormDialog>
  );
};

export default TransferDialog;
