import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './TabFilter.module.scss';

const TabFilter = ({ click, customStyle, customStyleSelected, idFilter, isSelected, t, text, id }) => (
  <div
    className={`${styles.Filter} ${customStyle} ${isSelected ? `${styles.Selected} ${customStyleSelected}` : ''}`}
    id={id}
    onClick={() => click(idFilter)}
  >
    {text || t(idFilter)}
  </div>
);

export default withTranslation('common')(TabFilter);
