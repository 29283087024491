import { useEffect, useRef, useState } from 'react';

import ConnectorPorts from 'components/ConnectorPorts/ConnectorPorts';
import Selection from 'components/Selection/Selection';
import { DiagramActionTypes, movingSwimlaneSymbols } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useOuterClick from 'hooks/useOuterClick';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import useTool from 'hooks/useTool';
import { PaletteActions, Tool } from 'types/diagram';
import { SelectionButton, SymbolTypes } from 'types/symbols';

import styles from './LogicalGate.module.scss';

type Props = {
  id: string;
  isPaletteItem?: boolean;
  swimlaneId?: string;
  type: SymbolTypes;
};

const INNER_TEXTS: { [key in SymbolTypes]?: string } = {
  [SymbolTypes.AND_GATE]: 'AND',
  [SymbolTypes.OR_GATE]: 'OR',
  [SymbolTypes.XOR_GATE]: 'XOR',
};

const ACTIONS: { [key in SymbolTypes]?: PaletteActions } = {
  [SymbolTypes.AND_GATE]: PaletteActions.ADD_AND_GATE,
  [SymbolTypes.OR_GATE]: PaletteActions.ADD_OR_GATE,
  [SymbolTypes.XOR_GATE]: PaletteActions.ADD_XOR_GATE,
};

const LogicalGate = (props: Props) => {
  const { id, isPaletteItem, type, swimlaneId } = props;
  const { handleDragStart } = useDragAndDrop();
  const containerRef = useRef<HTMLDivElement>(null);
  const { getSelectedSwimlane, getSelectionButtons } = useSwimlane();
  const { selectedSymbolIds, dispatch, processData, grabAndMove, selectedConnectorId } = useDiagramContext();
  const { toggleTool, tool } = useTool();
  const { handleSelect, clearSelection } = useSelection();
  const [selectionButtons, setSelectionButtons] = useState<SelectionButton[]>();

  const onDragStart = (e: any) => {
    if (processData?.isOnlyRead) return;
    handleDragStart(e);
    if (isPaletteItem) {
      dispatch({
        type: DiagramActionTypes.SET_PALETTE_ACTION,
        payload: ACTIONS[type] as PaletteActions,
      });
      dispatch(movingSwimlaneSymbols(true));
    } else if (swimlaneId) {
      if (tool === Tool.CONNECT || grabAndMove || selectedConnectorId) return;

      dispatch(movingSwimlaneSymbols(true, id, swimlaneId));
    }
  };

  useEffect(() => {
    const selectedSwimlane = getSelectedSwimlane(swimlaneId, processData?.swimlanes);

    setSelectionButtons(
      getSelectionButtons({
        diagramData: {
          isOnlyRead: processData?.isOnlyRead,
          swimlaneId,
          selectedSwimlane,
        },
        id,
        symbolType: type,
      }),
    );
  }, [id, processData?.isOnlyRead, processData?.swimlanes, swimlaneId, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useOuterClick(containerRef, () => {
    if (selectedSymbolIds.includes(id)) {
      clearSelection();
    }
  });

  return (
    <>
      <div
        className={`${styles.Wrapper} ${isPaletteItem ? styles.isPaletteItem : ''}`}
        draggable={!processData?.isOnlyRead && tool !== Tool.CONNECT && !grabAndMove && !selectedConnectorId}
        onClick={() => isPaletteItem && toggleTool(Tool.ADD_SYMBOL)}
        onDragEnd={() => dispatch(movingSwimlaneSymbols(false))}
        onDragStart={onDragStart}
      >
        <div className={styles.Container} ref={containerRef}>
          {!isPaletteItem && <Selection actionButtons={selectionButtons} className={styles.Selection} symbolId={id} />}
          <div
            className={`${styles.LogicalGate} ${selectedSymbolIds.includes(id) && !isPaletteItem ? styles.Selected : ''}`}
            onClick={(e) => {
              handleSelect(id, e);
            }}
          >
            <p>{INNER_TEXTS[type]}</p>
          </div>
          <ConnectorPorts symbolId={id} />
        </div>
      </div>
    </>
  );
};

export default LogicalGate;
