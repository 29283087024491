import React from 'react';

import { SymbolPaletteComponent } from '@syncfusion/ej2-react-diagrams';
import { useTranslation } from 'react-i18next';

import { VCD_DIAGRAM } from '../../assets/constants/constants';
import shapeTool from '../../assets/images/shapeDesignTool.png';
import documentationUtils from '../../assets/js/documentationUtils';
import * as Config from './Config';
import ShapeSelector from './ShapeSelector/ShapeSelector';
import './SymbolPalette.scss';

const SymbolPalette = (props) => {
  const {
    symbolsPalette,
    toolSelected,
    changeTool,
    showShapes,
    diagramType,
    loadingImage,
    optimizeConnector,
    idDiagram,
    version,
    onInsertImage,
  } = props;
  const { t } = useTranslation();
  const isShapeSelected = () => Config.tools.includes(toolSelected);
  const img = Config.images[toolSelected] || shapeTool;

  const uploadImage = (event) => {
    documentationUtils
      .uploadImage({
        file: event.target.files[0],
        fileData: { idDiagram, version },
        setLoading: loadingImage,
        t,
      })
      .then((response) => {
        changeTool('picture', response.data);
        onInsertImage(response.data);
      });
  };

  const imgClick = () => {
    if (toolSelected === 'picture') {
      changeTool('');
    } else {
      document.getElementById('addImage').click();
    }
  };

  const handleClick = () => {
    optimizeConnector();
    changeTool('optimize');
  };

  return (
    <>
      <SymbolPaletteComponent
        enableAnimation={false}
        getNodeDefaults={Config.getNodeDefaults}
        getSymbolInfo={Config.getSymbolInfo}
        id="symbolpalette"
        palettes={[{ id: 'flow', symbols: symbolsPalette }]}
        symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }}
      />
      <div id="design-palette">
        <div className={`design-tools ${diagramType}`}>
          {showShapes && (
            <ShapeSelector
              close={() => changeTool('shapes')}
              diagramType={diagramType}
              selectShape={(tool) => changeTool(tool)}
            />
          )}
          <div
            className={`design-tool ${diagramType} ${isShapeSelected() ? 'active' : ''}`}
            id="shapes"
            onClick={() => changeTool('shapes')}
          >
            <img alt="Shape Tool" src={img} />
          </div>
          {diagramType === VCD_DIAGRAM && (
            <>
              <div
                className={`design-tool ${toolSelected === 'picture' ? 'active' : ''}`}
                id="picture"
                onClick={() => imgClick()}
              >
                
              </div>
              <input accept="image/*" className="inputImages" id="addImage" onChange={uploadImage} type="file" />
            </>
          )}
        </div>
        <div className="connection">
          <div
            className={`connection-mode ${toolSelected === 'connector' ? 'active' : ''}`}
            id="connector"
            onClick={() => changeTool('connector')}
          >
            {t('connectionMode')}
          </div>
          <div
            className={`connection-optimization ${toolSelected === 'optimize' ? 'active' : ''}`}
            id="connector-optimization"
            onClick={() => handleClick()}
          >
            <i className={toolSelected === 'optimize' ? 'di icon-rueckgaengig-undo' : 'di icon-hierarchie-stufe-2'} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SymbolPalette;
