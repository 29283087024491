import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './TableList.module.scss';

const TableList = (props) => {
  const {
    checkedItems = [],
    header,
    isHeaderChecked,
    list,
    RowComponent,
    t,
    handleRowClick,
    handleCheckAll,
    sortTable,
    direction,
    fieldToOrder,
    selectedTab,
    noToolbar = false,
    extraClick,
    showRecommendationColumns,
    handleDownloadClick,
    showHistoryDialog,
    switchUpdate,
    recommendationsTable,
    blockBody,
    isRadio = false,
    isLoading,
    isDepartmentView,
    isNeposQIGroup,
    isNotSwimlane,
    isNotInputOutput,
    isRoles,
    catalogUsers,
  } = props;

  const noToolbarClass = noToolbar ? styles.NoToolbar : '';
  const departmentViewClass = isDepartmentView ? styles.DepartmentView : '';

  let rows;

  if (list === null || isLoading) {
    rows = <RowComponent element={t('loading')} />;
  } else if (list.length === 0) {
    rows = <RowComponent element={t('noResults')} />;
  } else {
    rows = list.map((elem, index) => (
      <RowComponent
        catalogUsers={catalogUsers}
        direction={direction}
        element={elem}
        extraClick={extraClick}
        handleDownloadClick={handleDownloadClick}
        handleRowClick={handleRowClick}
        isChecked={checkedItems.find(({ id }) => id === elem.id)}
        isNeposQIGroup={isNeposQIGroup}
        isNotInputOutput={isNotInputOutput}
        isNotSwimlane={isNotSwimlane}
        isRadio={isRadio}
        isRoles={isRoles}
        key={elem.id || index}
        selectedTab={selectedTab}
        showHistoryDialog={showHistoryDialog}
        showRecommendationColumns={showRecommendationColumns}
        switchUpdate={switchUpdate}
      />
    ));
  }

  return (
    <table className={`${styles.List} ${noToolbarClass} ${departmentViewClass}`}>
      <thead>
        <RowComponent
          direction={direction}
          element={header}
          fieldToOrder={fieldToOrder}
          handleCheckAll={handleCheckAll}
          isChecked={isHeaderChecked}
          isDisabled={isLoading || !list?.length}
          isHeader
          isNeposQIGroup={isNeposQIGroup}
          isNotInputOutput={isNotInputOutput}
          isNotSwimlane={isNotSwimlane}
          isRadio={isRadio}
          isRoles={isRoles}
          selectedTab={selectedTab}
          showRecommendationColumns={showRecommendationColumns}
          sortTable={sortTable}
        />
      </thead>
      <tbody
        className={`${styles.TableBody} ${recommendationsTable ? styles.RecommendationsBody : ''} ${
          blockBody ? styles.BlockBody : ''
        }`}
      >
        {rows}
      </tbody>
    </table>
  );
};

export default withTranslation('common')(TableList);
