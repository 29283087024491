import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_PRIMARY_LEGACY, BUTTON_SECONDARY, BUTTON_SECONDARY_LEGACY } from 'assets/constants/constants';
import { DialogType, DeleteDialogPrefixes } from 'types/dialogs';

import DialogNEPOS from '../DialogNEPOS/DialogNEPOS';
import InputTextNEPOS from '../InputText/InputTextNEPOS';
import styles from './DeleteDialog.module.scss';

interface Props {
  handleClick: (confirmationValue?: string) => void;
  objectName: string;
  prefix?: DeleteDialogPrefixes;
  isLegacy?: boolean;
}

const DeleteDialog = ({ handleClick, objectName, prefix = DeleteDialogPrefixes.SIPOC, isLegacy }: Props) => {
  const [confirmationValue, setConfirmationValue] = useState<string>();
  const { t } = useTranslation();
  const isDiagram = prefix === DeleteDialogPrefixes.SIPOC || prefix === DeleteDialogPrefixes.SWIMLANE;
  const title = t(`${isDiagram ? 'process' : prefix}.delete`);

  const confirmButton = {
    id: 'confirm-delete',
    handleClick: () => handleClick(confirmationValue),
    content: t('confirmDelete'),
    buttonStyle: isLegacy ? BUTTON_PRIMARY_LEGACY : BUTTON_PRIMARY,
    disabled: !confirmationValue || confirmationValue.trim() === '',
  };

  const cancelButton = {
    id: 'cancel-delete',
    handleClick: () => handleClick(undefined),
    content: t('cancel'),
    buttonStyle: isLegacy ? BUTTON_SECONDARY_LEGACY : BUTTON_SECONDARY,
  };

  const deleteDialog = {
    title: isLegacy ? t('warningText') : title,
    type: isLegacy ? DialogType.Warning : DialogType.Info,
    buttons: isLegacy ? [cancelButton, confirmButton] : [confirmButton, cancelButton],
  };

  return (
    <DialogNEPOS dialog={deleteDialog} extraClass={isLegacy ? 'ModalLegacy' : 'Modal'}>
      <p className={styles.DeleteDialogText}>{t('deleteDialog.confirmDeleteLabel', { type: t(prefix) })}</p>
      <p className={styles.DeleteDialogText}>
        {t('deleteDialog.deleteLabel')}{' '}
        <span className={styles.ObjectName} id="delete-confirmation-label">
          {`*${objectName}*`}
        </span>
      </p>
      <InputTextNEPOS
        id="delete-confirmation-input"
        label=" "
        legacyStyles={isLegacy || prefix === DeleteDialogPrefixes.REQUIREMENT}
        onChange={(value) => setConfirmationValue(value)}
        value={confirmationValue || ''}
      />
    </DialogNEPOS>
  );
};

export default DeleteDialog;
