/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { getDeepFirstChild, getSanitizedTooltipContent } from 'assets/js/Utils';

import styles from './TooltipCell.module.scss';

const TooltipRow = (props: { element: any; value: any }) => {
  const { element, value } = props;
  const ref = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const nodeElement = getDeepFirstChild(ref.current).parentNode;

    setShowTooltip(!nodeElement || (nodeElement as HTMLSpanElement).scrollWidth > (nodeElement as HTMLSpanElement).clientWidth);
  }, [ref]);

  return (
    <span className={styles.Container} ref={ref}>
      <TooltipComponent
        content={getSanitizedTooltipContent(element)}
        cssClass={`mbc-tooltip nepos-tooltip ${showTooltip ? '-' : 'display-none'}`}
        position="BottomCenter"
        showTipPointer={false}
      >
        {value}
      </TooltipComponent>
    </span>
  );
};

export default TooltipRow;
