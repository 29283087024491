import styles from './Pagination.module.scss';

const Pagination = ({ totalPages, page, pageClick }) => {
  const handleClick = (number, condition) => {
    if (page !== condition) {
      pageClick(number);
    }
  };

  const getSelectedClass = (number) => (number === page ? styles.Selected : '');

  const Page = ({ number }) => (
    <div className={getSelectedClass(number)} onClick={() => pageClick(number)}>
      {number}
    </div>
  );

  return (
    <div className={styles.Pagination}>
      <div className={page === 1 ? styles.Disabled : ''} onClick={() => handleClick(page - 1, 1)}>
        <i className="di icon-pfeil-chevron-links" />
      </div>
      <Page number={1} />
      {page - 2 > 2 && <div className={styles.More}>···</div>}
      {page - 2 > 1 && <Page number={page - 2} />}
      {page - 1 > 1 && <Page number={page - 1} />}

      {(page - 2 >= 0 || page - 1 >= 1) && (page + 2 < totalPages || page + 1 <= totalPages) && <Page number={page} />}

      {page + 1 < totalPages && <Page number={page + 1} />}
      {page + 2 < totalPages && <Page number={page + 2} />}
      {page + 2 < totalPages - 1 && <div className={styles.More}>···</div>}
      {totalPages > 1 && <Page number={totalPages} />}
      <div className={page === totalPages ? styles.Disabled : ''} onClick={() => handleClick(page + 1, totalPages)}>
        <i className="di icon-pfeil-chevron-rechts" />
      </div>
    </div>
  );
};

export default Pagination;
