import * as serviceUtils from 'assets/js/serviceUtils';

import { axiosInstance } from './serviceConstants';

const usersUrl = '/backend/ms-users/api/v1';
const usersUrlv2 = '/backend/ms-users/api/v2';
const usersUrlV3 = '/backend/ms-teams/api/v1';
const favoritesUrl = `${usersUrl}/users/favourites/diagrams`;
const favoritesUrlV2 = `${usersUrlv2}/users/favourites/diagrams`;

const GEMS_ROLES = {
  default: ['User', 'Admin'],
  modeler: ['Modeler', 'QI_MODELERS'],
  author: ['User', 'Admin'],
  approver: ['User', 'Admin'],
  method_owner: ['User', 'Admin'],
};

const getGemsRoles = (role) => GEMS_ROLES[role] || GEMS_ROLES.default;

const getAllUsers = (params) => axiosInstance.get(`${usersUrl}/users/all`, { params });

const getUsersByRole = (user, role = 'default') => {
  const lowerCaseRole = role.toLowerCase().split('_')[3] || role.toLowerCase().replace(' ', '_');

  const roles = getGemsRoles(lowerCaseRole)
    .map((gemRole) => `roles=${gemRole}`)
    .join('&');

  return axiosInstance.get(`${usersUrl}/users/by-role?page=0&pageSize=20&${roles}&searchString=${user}&sort=code&sortDir=ASC`);
};

const getScopesByName = (params) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${usersUrl}/users/scopes/by-name?${searchParams}`);
};

const getResponsibleAreas = () =>
  axiosInstance.get(`${usersUrl}/quality-group/all`).then((res) => {
    const responsibleAreas = res.data.map((elem, index) => ({
      id: index,
      text: elem,
      value: elem,
      selected: false,
    }));

    return responsibleAreas;
  });

const getFavorites = (params) => {
  const language = serviceUtils.getUserLanguage();
  const searchParams = new URLSearchParams(params).toString();

  return axiosInstance.get(`${favoritesUrlV2}?${searchParams}`).then((res) => {
    res.data.results.forEach((elem) => {
      // Remove when ms-users is adapted to multilanguage
      elem.processName = elem.nameMap[language] || '';
      elem.author = elem.author.code === 'NOT_FOUND' ? '-' : elem.author.commonName;
    });

    return res;
  });
};

const isFavorite = (processNumber) => axiosInstance.get(`${favoritesUrl}/is-favorite?processNumber=${processNumber}`);

const addToFavorites = (idProcess) => axiosInstance.put(favoritesUrl, { idProcess });

// axios DELETE needs a data object
const removeFavorite = (idProcess) => axiosInstance.delete(favoritesUrl, { data: { idProcess } });

const getScopesList = (departmentId) => axiosInstance.get(`${usersUrl}/users/scopes/hierarchical/${departmentId}`);

const getUserInfo = () => axiosInstance.get(`${usersUrl}/users/basic-information/my`);

const setVisitedSandbox = () => axiosInstance.post(`${usersUrl}/users/metadata/set-visited-sandbox`);

const getUsersImages = (userCodes) => axiosInstance.get(`${usersUrlV3}/teams/images/${userCodes}`);

export default {
  getAllUsers,
  getUsersImages,
  getUsersByRole,
  handleServiceError: serviceUtils.handleServiceError,
  getScopesByName,
  getResponsibleAreas,
  getFavorites,
  isFavorite,
  addToFavorites,
  removeFavorite,
  getScopesList,
  getUserInfo,
  setVisitedSandbox,
};
