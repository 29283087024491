import { useCallback, useEffect } from 'react';

import html2canvas from 'html2canvas';

import { addNewPicture, prepareTakingPictures, takePicturesSuccess } from 'contexts/Diagram/DiagramContext';
import { PictureAction } from 'types/diagram';

import useDiagramContext from './useDiagramContext';

export default function useDiagramPicture(needsAllPictures: boolean) {
  const { dispatch, diagramLanguage, isTakingPictures, pictureLanguagesLeft } = useDiagramContext();

  const makeDiagramPicture = useCallback(async () => {
    try {
      const canvas = await html2canvas(document.getElementById('diagram-picture') as HTMLElement, {
        scale: 4,
      });
      return canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
    } catch (error) {
      console.error("Couldn't get the diagram picture", error); // eslint-disable-line no-console
      return '';
    }
  }, []);

  const getAllLanguagePictures = useCallback(async () => {
    dispatch(prepareTakingPictures(PictureAction.all, true));
  }, [dispatch]);

  const makeNewDiagramPicture = useCallback(async () => {
    if (pictureLanguagesLeft.length === 0) return dispatch(takePicturesSuccess());

    const img = await makeDiagramPicture();
    const langsLeft = pictureLanguagesLeft.filter((lang) => lang !== diagramLanguage);
    dispatch(addNewPicture(img, langsLeft));
  }, [diagramLanguage, dispatch, pictureLanguagesLeft, makeDiagramPicture]);

  // Executed in loop to take every language picture
  useEffect(() => {
    if (!needsAllPictures || !isTakingPictures.all) return;
    makeNewDiagramPicture();
  }, [isTakingPictures.all, needsAllPictures, makeNewDiagramPicture]);

  return {
    makeDiagramPicture,
    getAllLanguagePictures,
  };
}
