import React from 'react';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useFormTypes from 'hooks/useFormTypes';

import WorkflowBar from './WorkflowBar';

const WorkflowBarWrapper = (props) => {
  const featureFlags = useFeatureFlags();
  const { fetchFormTypes, getFormTypesCode, getReactReactiveFormFieldConfig, ...formTypes } = useFormTypes();

  return (
    <WorkflowBar
      {...props}
      featureFlags={featureFlags}
      fetchFormTypes={fetchFormTypes}
      formTypes={formTypes}
      getFormTypesCode={getFormTypesCode}
      getReactReactiveFormFieldConfig={getReactReactiveFormFieldConfig}
    />
  );
};

export default WorkflowBarWrapper;
