import { v4 as uuidv4 } from 'uuid';

import { Role } from './administration';
import { Attributes } from './forms';
import { Symbol } from './symbols';

export enum Swimlanes {
  SWIMLANE = 'SWIMLANE',
  WRAPPER_DOM_ID = 'swimlane-wrapper',
}

// TODO: change name everything works
export type SwimlaneV2 = {
  id: string;
  containsOutput: boolean; // Contains the event connected to the output
  symbols: Symbol[];
  role: Role | null;
  meta: {
    pos: number;
    width: number;
    height: number;
  };
};

export type ElementContextMenu = {
  diagramData: {
    diagramAttributes?: Attributes;
    diagramLink?: boolean;
    isOnlyRead?: boolean;
    processLevel?: number;
    selectedSwimlane?: SwimlaneV2;
    startEventsIds?: string[];
    lastEventsIds?: string[];
    swimlaneId?: string;
    symbolObjectsDisplayed?: string[];
  };
  id: string;
  isOpen?: boolean;
  onClick?: (isOpen?: boolean | string) => void;
  symbolType: string;
};

export const createNewSwimlaneNEPOS = (pos: number): Omit<SwimlaneV2, 'meta'> & { meta: { pos: number; height: number } } => {
  return {
    id: `SWIMLANE_SWIMLANE${uuidv4()}`,
    containsOutput: false,
    symbols: [],
    role: {},
    meta: {
      pos,
      height: 1,
    },
  };
};
