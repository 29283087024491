import { useEffect, useState, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY } from 'assets/constants/constants';
import LinkForm from 'components/LinkForm/LinkForm';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import FileUploader from 'components/UI/FileUploader/FileUploader';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import { UploadCardI } from 'components/UploadCard/UploadCard';
import UploadCardList from 'components/UploadCardList/UploadCardList';
import useActivitySpecification from 'hooks/useActivitySpecification';
import useActivitySpecificationContext from 'hooks/useActivitySpecificationContext';
import iconsFilesDisplay from 'services/useFormService';
import { DocLinkTypes, Document, LinkWithStatus, UploadStatus } from 'types/activitySpecification';
import { DialogType } from 'types/dialogs';
import { LanguageAttributes } from 'types/forms';

import styles from './DialogAdminDocsLinks.module.scss';

const enum Tabs {
  DOCUMENTS = 'Documents',
  LINKS = 'Links',
}

type Props = {
  idActivity: number;
  close: () => void;
  type: DocLinkTypes;
};

const DialogAdminDocsLinks = ({ idActivity, close, type }: Props) => {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState<string>(Tabs.LINKS);
  const { links: allLinks, documents: allDocuments } = useActivitySpecificationContext();
  const { addDocuments, addLink, getActivityDocument, removeErrors, removeDocument, removeLink } = useActivitySpecification();

  const links = useMemo(() => allLinks.filter((link) => link.type === type), [type, allLinks]);
  const documents = useMemo(() => allDocuments.filter((document) => document.type === type), [type, allDocuments]);
  const uploadFileInput = window.document.getElementById('uploadFileInput');

  const dialogButtons = useMemo(
    () => ({
      title: t('activity.docs-links-dialog-title'),
      type: DialogType.None,
      buttons: [
        {
          id: 'activity-docs-links-done',
          handleClick: close,
          content: t('done'),
          buttonStyle: BUTTON_PRIMARY,
        },
      ],
    }),
    [t, close],
  );

  const tabOptions = useMemo(
    () => [
      {
        name: `${t(Tabs.LINKS)}`,
        id: Tabs.LINKS,
        isValid: true,
      },
      {
        name: `${t(Tabs.DOCUMENTS)}`,
        id: Tabs.DOCUMENTS,
        isValid: true,
      },
    ],
    [t],
  );

  useEffect(() => () => removeErrors(), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddLink = useCallback(
    ({ LINK, NAME }: LanguageAttributes) => {
      if (!LINK || !NAME) return;
      addLink({ name: NAME.toString(), value: LINK.toString(), type }, idActivity);
    },
    [idActivity, addLink, type],
  );

  const handleRemoveLink = useCallback(
    (link: LinkWithStatus) => {
      if (!link) return;
      removeLink(link, idActivity);
    },
    [idActivity, removeLink],
  );

  const openLink = (link: string) => {
    const includedProtocol = link?.toLowerCase().includes('https://' || 'http://');
    const linkOpened = includedProtocol ? link : `//${link}`;
    window.open(linkOpened, '_blank');
  };

  const mapLinkToCard = useCallback(
    (link: LinkWithStatus): UploadCardI => ({
      description: link.value,
      icon: 'icon-link',
      id: `link-${link.value}`,
      onClick: () => openLink(link.value),
      onDelete:
        link.status !== UploadStatus.LOADING
          ? () => {
              handleRemoveLink(link);
            }
          : undefined,
      title: link.name,
      isLoading: link.status === UploadStatus.LOADING,
      errorMessage: link.status === UploadStatus.ERROR ? t('upload.failed') : undefined,
    }),
    [t, handleRemoveLink],
  );

  const mapDocumentsToCard = useCallback(
    (document: Document): UploadCardI => ({
      date: document.value,
      icon: iconsFilesDisplay(document.name || ''),
      id: `doc-${document.name}`,
      onClick: () => getActivityDocument(document.name, document.type, idActivity),
      onDelete: document.status !== UploadStatus.LOADING ? () => document && removeDocument(document, idActivity) : undefined,
      title: document.name,
      isLoading: document.status === UploadStatus.LOADING,
      errorMessage: document.status === UploadStatus.ERROR ? t('upload.failed') : undefined,
    }),
    [getActivityDocument, t, removeDocument, idActivity],
  );

  return (
    <DialogNEPOS dialog={dialogButtons} maxSize>
      <div className={styles.Container}>
        <TabSelector
          className={styles.Tabs}
          handleClick={setTabSelected}
          isNEPOS
          options={tabOptions}
          selectedOption={tabSelected}
        />
        <div className={styles.TabContainer}>
          <div className={styles.TabSelected} hidden={tabSelected !== Tabs.LINKS}>
            <LinkForm onClick={handleAddLink} />
            <div className={`${styles.Links} ${!links.length && styles.Square}`}>
              {links.length ? (
                <UploadCardList list={links.map(mapLinkToCard)} />
              ) : (
                <div className={styles.LinkMessage}>{t('link.emptyMessage')}</div>
              )}
            </div>
          </div>
          <div className={styles.DocumentsTabContent} hidden={tabSelected !== Tabs.DOCUMENTS} id={idActivity.toString()}>
            <div className={styles.UploadButton}>
              <ButtonNEPOS
                handleClick={() => {
                  uploadFileInput?.click();
                }}
                id="upload-files-button"
                isSecondary
              >
                {t('uploadFiles')}
              </ButtonNEPOS>
            </div>
            <div className={documents.length ? styles.ScrollDocs : ''}>
              <UploadCardList list={documents.map(mapDocumentsToCard)} />
            </div>
            <div hidden={documents.length > 0}>
              <FileUploader
                allowedExtensions={['.doc', '.zip', '.xls', '.jpg', '.pdf', '.ppt', '.pptx', '.docx', '.xlsx']}
                handleUploadFiles={(files) => addDocuments(type, idActivity, files)}
                isEmpty
                maxFiles={16}
                uploaderText={t('dragDropXFiles', { min: 1, max: 16 })}
              />
            </div>
          </div>
        </div>
      </div>
    </DialogNEPOS>
  );
};

export default DialogAdminDocsLinks;
