import React from 'react';

import { Validators } from 'react-reactive-form';

import * as Utils from '../../assets/js/Utils';
import DropdownForm from '../../components/UI/Dropdown/DropdownForm';
import InputTextLegacy from '../../components/UI/InputText/InputTextLegacy';
import InputUrlLegacy from '../../components/UI/InputUrl/InputUrlLegacy';

export const getFormatedDate = (originalDate) => {
  const date = new Date(originalDate);
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

const components = {
  TEXT: (props) => <InputTextLegacy {...props} />,
  DROPDOWN: (props) => <DropdownForm {...props} />,
  WYSIWYG: (props) => <InputTextLegacy {...props} />,
  URL: (props) => <InputUrlLegacy {...props} />,
  PROCESS_ID: (props) => <InputTextLegacy {...props} />,
  DOCUMENT_ID: (props) => <InputTextLegacy {...props} />,
  USER_ID: (props) => <InputTextLegacy {...props} />,
  DATE_TIME: (props) => <InputTextLegacy {...props} />,
};

const fieldTypes = {
  TEXT: 'TEXT',
  DROPDOWN: 'DROPDOWN',
  WYSIWYG: 'WYSIWYG',
  URL: 'URL',
  PROCESS_ID: 'PROCESS_ID',
  DOCUMENT_ID: 'DOCUMENT_ID',
  USER_ID: 'USER_ID',
  DATE_TIME: 'DATE_TIME',
};

const cares = {
  SYSTEM: 'S',
  MANDATORY: 'M',
  OPTIONAL: 'O',
};

export const getFieldConfig = (t, attributesData, responsibleAreas = []) => {
  let controls = {};
  const keys = Object.keys(attributesData);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const attribute = attributesData[key];

    if (attribute.care !== cares.SYSTEM) {
      const newControl = {
        render: components[attribute.fieldType] || ((props) => <InputTextLegacy {...props} />),
        meta: {
          label: t(key),
          placeholder: t(key),
          attributeType: attribute.care,
        },
      };

      if (attribute.care === cares.MANDATORY) {
        newControl.options = {
          validators: [Validators.required, Utils.checkWhiteSpacesValidation],
        };
      }

      if (attribute.fieldType === fieldTypes.DROPDOWN && key === 'RESPONSIBLE_AREA') {
        newControl.meta.options = responsibleAreas;
      }

      controls = {
        ...controls,
        [key]: newControl,
      };
    }
  }

  return {
    controls,
  };
};
