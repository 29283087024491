import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { registerLicense } from '@syncfusion/ej2-base';
import i18next from 'i18next';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider as OidcAuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from 'contexts/Localization/LocalizationContext';
import { oidcConfig } from 'hooks/authUtils';

import App from './App';
import { ENGLISH } from './assets/constants/constants';
import * as serviceUtils from './assets/js/serviceUtils';
import { AuthProvider } from './contexts/Auth/AuthContext';
import { ErrorProvider } from './contexts/Error/ErrorContext';
import { FeatureFlagsProvider } from './contexts/FeatureFlags/FeatureFlagsContext';
import all from './i18n/all.json';
import * as serviceWorker from './serviceWorker';

import './index.css';

i18next.init({
  interpolation: { escapeValue: false },
  lng: serviceUtils.getLocalStorageAppLanguage() || ENGLISH,
  fallbackLng: ENGLISH,
  keySeparator: false,
  resources: all,
  defaultNS: 'common',
});

// Registering Syncfusion license key
registerLicense(
  'Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5VdkFiWH5adXFXTw==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUVlWXtfcXVRQ2ZUWA==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjX39acXVUQmVUV0A=;MTI2MTIxOEAzMjMwMmUzNDJlMzBDdlZGSzZra29GcWIxWmxQdDVIcngwenhVSjdRSk0rQVJaNFRKYVFoemlrPQ==;MTI2MTIxOUAzMjMwMmUzNDJlMzBkOW42UEh4RWNmRlRTRHdSRTYwY0lpcmkvT005bk9iNy9CMTJ3UThYMWlBPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWXledHVUR2RaV0J/;MTI2MTIyMUAzMjMwMmUzNDJlMzBGY2NIbXkvd3FSenZXTFBtNWsvQlJENzJsKzllZFZGb09hdXRaWE5EVkEwPQ==;MTI2MTIyMkAzMjMwMmUzNDJlMzBLbzVjV25kMi9sdkk5U2RvMC9uZm1uNGpRRURLSUtaWWVJQUYrVlhTR0pvPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjX39acXVUQmdYVkM=;MTI2MTIyNEAzMjMwMmUzNDJlMzBNd0VjeG9kZFRHWWVWUGE3S1dycDJlQWdwNnFzVG1rK2pPMjFUaEhUMU5NPQ==;MTI2MTIyNUAzMjMwMmUzNDJlMzBIeXlLODhHc3R5L2VGMkJTMmFKNkowMnNocktXRHJqSzZsbDU3WjdMaWhrPQ==;MTI2MTIyNkAzMjMwMmUzNDJlMzBGY2NIbXkvd3FSenZXTFBtNWsvQlJENzJsKzllZFZGb09hdXRaWE5EVkEwPQ==',
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <ErrorProvider>
        <OidcAuthProvider {...oidcConfig}>
          <AuthProvider>
            <FeatureFlagsProvider>
              <LocalizationProvider>
                <App />
              </LocalizationProvider>
            </FeatureFlagsProvider>
          </AuthProvider>
        </OidcAuthProvider>
      </ErrorProvider>
    </BrowserRouter>
  </I18nextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
