import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './HistoryRow.module.scss';

const HistoryRow = ({ isHeader, element, sortTable, direction, fieldToOrder, handleDownloadClick }) => {
  const getSortedClass = (field) => {
    return fieldToOrder === field ? styles[direction] : '';
  };

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${styles.ListItem}`}>
        <th className={`${styles.Type} ${getSortedClass('type')}`} onClick={() => sortTable('type')}>
          {element.type}
        </th>
        <th className={`${styles.Name} ${getSortedClass('name')}`} onClick={() => sortTable('name')}>
          {element.name}
        </th>
        <th className={`${styles.Version} ${getSortedClass('version')}`} onClick={() => sortTable('version')}>
          {element.version}
        </th>
        <th className={`${styles.Author} ${getSortedClass('author')}`} onClick={() => sortTable('author')}>
          {element.author}
        </th>
        <th className={`${styles.ValidFrom} ${getSortedClass('validFrom')}`} onClick={() => sortTable('validFrom')}>
          {element.validFrom}
        </th>
        <th className={`${styles.ValidTo} ${getSortedClass('validTo')}`} onClick={() => sortTable('validTo')}>
          {element.validTo}
        </th>
        <th className={styles.IcoPdf}>{}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={styles.ListItem}>
      <td className={styles.Type}>{element.processType}</td>
      <td className={styles.Name}>{element.name}</td>
      <td className={styles.Version}>{element.version}</td>
      <td className={styles.Author}>{element.author}</td>
      <td className={styles.ValidFrom}>{element.validFrom}</td>
      <td className={styles.ValidTo}>{element.validTo}</td>
      <td className={styles.IcoPdf}>
        <span
          className={`di ${styles.Document}`}
          id={`download-pdf-${element.name}-${element.version}`}
          onClick={() => handleDownloadClick(element.version, element.name)}
        >
          
        </span>
      </td>
    </tr>
  );
};

export default withTranslation('common')(HistoryRow);
