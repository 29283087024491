import { forwardRef, useState, useEffect, useRef } from 'react';

import styles from './ButtonNEPOS.module.scss';

type Props = {
  className?: string;
  children?: React.ReactNode;
  dataQA?: string;
  disabled?: boolean;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  id: string;
  isSecondary?: boolean;
  gradientButton?: string;
};

const ButtonNEPOS = forwardRef<HTMLButtonElement, Props>(
  ({ id, dataQA = id, children, className, disabled, handleClick, icon, isSecondary, gradientButton }, ref) => {
    const [canSelect, setCanSelect] = useState(true);
    const timeoutRef = useRef<number | null>(null);

    const resetSelectionControl = () => {
      setCanSelect(false);
      timeoutRef.current = window.setTimeout(() => {
        setCanSelect(true);
      }, 500);
    };

    useEffect(
      () => () => {
        clearTimeout(timeoutRef.current as number);
      },
      [],
    );

    return (
      <button
        className={`${styles.Button} ${isSecondary ? styles.Secondary : ''} ${
          gradientButton ? `${styles.GradientButton} ${styles[gradientButton]}` : ''
        } ${className} ${disabled ? styles.Disabled : ''}`}
        data-qa={dataQA}
        disabled={disabled}
        id={id}
        onClick={(e) => {
          if (canSelect) {
            resetSelectionControl();
            handleClick(e);
          }
        }}
        ref={ref}
        type="button"
      >
        {icon && <i className={`di ${icon} ${styles.Icon}`} />}
        {children}
      </button>
    );
  },
);

export default ButtonNEPOS;
