import { useCallback } from 'react';

import { AUTOSAVE_INTERVAL } from 'assets/constants/constants';
import { DiagramActionTypes, setIsAutoSaving, toggleAutoSave } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';

const useInterval = () => {
  const { dispatch, isAutoSaveOn, autoSaveInterval, isLoading, isSaveLoading, processData } = useDiagramContext();

  const setToggleAutoSave = useCallback(() => {
    if (!isLoading && !isSaveLoading && processData) {
      if (autoSaveInterval) clearInterval(autoSaveInterval);

      if (!isAutoSaveOn) {
        const interval = setInterval(() => {
          if (!isLoading && !isSaveLoading && processData) {
            dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
            dispatch(setIsAutoSaving(true));
          }
        }, AUTOSAVE_INTERVAL);
        dispatch(toggleAutoSave(true, interval));
      } else {
        dispatch(toggleAutoSave(false));
      }
    }
  }, [autoSaveInterval, dispatch, isAutoSaveOn, isLoading, isSaveLoading, processData]);

  return {
    setToggleAutoSave,
  };
};

export default useInterval;
