import React from 'react';

import { withTranslation } from 'react-i18next';

import { DATE_TYPE, INT_TYPE, TEXT_TYPE } from 'assets/constants/constants';
import {
  cloneObject,
  getFormattedDate,
  getPageBounds,
  getTotalPages,
  isTaskValidClosedWorkflow,
  sortTableByColumn,
} from 'assets/js/Utils';
import Page from 'components/Page/Page';

import { handleServiceError } from '../../assets/js/serviceUtils';
import TitleBar from '../../components/TitleBar/TitleBar';
import Pagination from '../../components/UI/Pagination/Pagination';
import Search from '../../components/UI/Search/Search';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';
import WorkflowsRow from '../../components/UI/TableList/WorkflowsRow/WorkflowsRow';
import diagramService from '../../services/diagramService';
import titleService from '../../services/titleService';
import { getAllWorkflowTasks, workflowById } from '../../services/workflowServices';
import taskStyles from '../MyTasks/MyTasks.module.scss';
import styles from './Workflows.module.scss';

class Workflows extends React.PureComponent {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.header = {
      name: t('tasks.name'),
      processNumber: t('workflows.processNumber'),
      author: t('tasks.userCodeCreator'),
      typeTask: t('tasks.typeTask'),
      startDateTask: t('tasks.startDateTask'),
      deadline: t('tasks.deadline'),
      closeDate: t('workflows.closeDate'),
    };

    this.sortData = {
      name: '',
      processNumber: '',
      author: '',
      typeTask: '',
      startDateTask: '',
      deadline: '',
      closeDate: '',
    };

    this.state = {
      openWorkflows: null,
      closedWorkflows: null,
      page: 1,
      workflowTabSelected: props.location.state?.tabSelected || 'openWorkflows',
      language: props.i18n.language.toUpperCase(),
      searchText: '',
      searchResults: [],
      lastFieldSorted: 'id',
      direction: 'ASC',
      defaultOrder: [],
      isWorkflowLoading: false,
    };
  }

  componentDidMount() {
    titleService.updatePageTitle(this.props.t(`workflows.${this.state.workflowTabSelected}`));

    getAllWorkflowTasks()
      .then((response) => {
        const [openWorkflowsResponse, closedWorkflowsResponse] = response;
        const openWorkflows = this.getWorkflowList(openWorkflowsResponse.openTasks, openWorkflowsResponse.users);
        const closedWorkflows = this.getWorkflowList(closedWorkflowsResponse.closedTasks, closedWorkflowsResponse.users);
        let defaultOrder = openWorkflows;

        if (this.state.workflowTabSelected === 'closedWorkflows') {
          defaultOrder = closedWorkflows;
        }

        this.setState({
          openWorkflows,
          closedWorkflows,
          defaultOrder,
        });
      })
      .catch((err) => {
        handleServiceError(err);
      });
  }

  // TODO: US3 [Technical]: Recommend a complementary technical user story for changing diagram/workflow forms
  //  in order to use the ones provided by the back-end team and not the state managed way that the front-end team implemented.
  handleRowClick(element) {
    const isOpenWorkflows = this.state.workflowTabSelected === 'openWorkflows';

    this.setState({ isWorkflowLoading: true }, () => {
      if (isOpenWorkflows) {
        diagramService
          .getBasicDiagramInfo(element.processNumber)
          .then((response) =>
            this.setState({ isWorkflowLoading: false }, this.props.history.push(`/diagram/${response.data.id}`)),
          )
          .catch((err) => this.setState({ isWorkflowLoading: false }, diagramService.handleServiceError(err)));
      } else {
        workflowById(element.id)
          .then((res) => {
            const workflowData = res.data;
            const workflowType = workflowData.type.split('_').pop();

            this.setState({ isWorkflowLoading: false }, () => {
              const pseudoTask = {
                // TODO: [closedWorkflow-US-2]: Remove next line for SIPOC/SWIMLANE implementation
                diagramType: workflowType,
                status: element.status,
                workflowStatus: workflowData.status,
              };

              if (!isTaskValidClosedWorkflow(pseudoTask)) {
                diagramService
                  .getBasicDiagramInfo(element.processNumber)
                  .then((response) =>
                    this.setState({ isWorkflowLoading: false }, this.props.history.push(`/diagram/${response.data.id}`)),
                  )
                  .catch((err) => this.setState({ isWorkflowLoading: false }, diagramService.handleServiceError(err)));
              }

              this.props.history.push(`/closed-workflow/${workflowData.id}`);
            });
          })
          .catch((err) => {
            this.setState({ isWorkflowLoading: false });
            diagramService.handleServiceError(err);
          });
      }
    });
  }

  getWorkflowTabSelected(tab) {
    return this.state.workflowTabSelected === tab ? taskStyles.TabSelected : '';
  }

  getWorkflowList(workflows, users) {
    const workflowsList = workflows.map((elem) => {
      const author = users.find((user) => user.code === elem.userCodeCreator);
      const workflow = {
        id: elem.id,
        name: JSON.parse(elem.taskTitle)[this.state.language].TITLE,
        processNumber: elem.processNumber,
        author: author?.commonName || '',
        typeTask: this.props.t(`workflows.${elem.taskTypeCode}`),
        status: 'APPROVED',
      };

      if (elem.taskCreationDate) {
        workflow.startDateTask = getFormattedDate(elem.taskCreationDate);
        workflow.deadline = getFormattedDate(elem.taskDeadline);
      } else {
        workflow.closeDate = getFormattedDate(elem.lastModificationDate);
        workflow.status = 'DONE';
      }

      return workflow;
    });

    return workflowsList;
  }

  getWorkflows() {
    return this.state.searchText !== '' ? this.state.searchResults : this.state[this.state.workflowTabSelected];
  }

  getPageWorkflows() {
    let workflows = this.state[this.state.workflowTabSelected];
    const { start, end } = getPageBounds(this.state.page);

    if (this.state.searchText !== '') {
      workflows = this.state.searchResults;
    }

    return workflows?.slice(start, end) || null;
  }

  getWorkflowsNum(tab) {
    let workflowsNum = this.state[tab]?.length;

    if (tab === this.state.workflowTabSelected && this.state.searchText !== '') {
      workflowsNum = this.state.searchResults?.length;
    }

    return workflowsNum;
  }

  sendSearch(text) {
    const searchResults = this.state[this.state.workflowTabSelected].filter((workflow) =>
      workflow.name.toLowerCase().includes(text.toLowerCase()),
    );

    this.setState({ searchResults, searchText: text, page: 1 });
  }

  selectWorkflowTab(tab) {
    titleService.updatePageTitle(this.props.t(`workflows.${tab}`));

    this.setState(
      {
        workflowTabSelected: tab,
        lastFieldSorted: 'id',
        direction: 'ASC',
        defaultOrder: this.state[tab],
        page: 1,
      },
      () => {
        if (this.state.searchText !== '') {
          this.sendSearch(this.state.searchText);
        }
      },
    );
  }

  pageClick(page) {
    this.setState({ page });
  }

  sortWorkflows(field) {
    let columns = this.sortData;
    let results = '';
    let fieldType = ['startDateTask', 'deadline', 'closeDate'].includes(field) ? DATE_TYPE : TEXT_TYPE;

    if (field === 'processNumber') {
      fieldType = INT_TYPE;
    }

    if (columns[field] === 'DESC') {
      columns[field] = '';
      results = this.state.defaultOrder;
    } else {
      const sortResult = sortTableByColumn(field, fieldType, columns, this.state[this.state.workflowTabSelected]);
      columns = cloneObject(sortResult.columns);
      results = cloneObject(sortResult.results);
    }

    this.sortData = columns;
    this.setState({
      [this.state.workflowTabSelected]: results,
      lastFieldSorted: field,
      direction: columns[field],
      page: 1,
    });
  }

  render() {
    const { t } = this.props;
    const workflows = this.getWorkflows();
    const totalPages = getTotalPages(workflows);

    return (
      <>
        {this.state.isWorkflowLoading && (
          <div className={styles.WorkflowLoading}>
            <Spinner isVisible />
          </div>
        )}
        <Page>
          <div className={`${taskStyles.Content} ${styles.WFContent}`}>
            <div className={taskStyles.SearchSection}>
              <h4>{this.props.t(`workflows.${this.state.workflowTabSelected}`)}</h4>
              <Search disabled={this.getPageWorkflows() === null} searching={(text) => this.sendSearch(text)} />
            </div>
            <div className={taskStyles.MyTasksHeader} id="workflowsHeader">
              <div className={taskStyles.TaskTabs}>
                <div
                  className={`${taskStyles.TaskTab} ${this.getWorkflowTabSelected('openWorkflows')}`}
                  onClick={() => this.selectWorkflowTab('openWorkflows')}
                >
                  <span>{t('workflows.openWorkflows')}</span>
                  <span className={taskStyles.TaskNumber}>{this.getWorkflowsNum('openWorkflows')}</span>
                </div>
                <div
                  className={`${taskStyles.TaskTab} ${this.getWorkflowTabSelected('closedWorkflows')}`}
                  onClick={() => this.selectWorkflowTab('closedWorkflows')}
                >
                  <span>{t('workflows.closedWorkflows')}</span>
                  <span className={taskStyles.TaskNumber}>{this.getWorkflowsNum('closedWorkflows')}</span>
                </div>
              </div>
            </div>
            <TableList
              direction={this.state.direction}
              fieldToOrder={this.state.lastFieldSorted}
              handleRowClick={(element) => this.handleRowClick(element)}
              header={this.header}
              list={this.getPageWorkflows()}
              RowComponent={WorkflowsRow}
              selectedTab={this.state.workflowTabSelected}
              sortTable={(fieldToOrder) => this.sortWorkflows(fieldToOrder)}
            />
            {workflows?.length > 0 && totalPages > 1 && (
              <div className={taskStyles.PagesSection}>
                <Pagination page={this.state.page} pageClick={(n) => this.pageClick(n)} totalPages={totalPages} />
              </div>
            )}
          </div>
        </Page>
      </>
    );
  }
}

export default withTranslation('common')(Workflows);
