import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { ADMINISTRATOR, MODELER, QI, QI_MODELER } from 'assets/constants/constants';
import * as serviceUtils from 'assets/js/serviceUtils';
import Search from 'components/UI/Search/Search';
import useAuth from 'hooks/useAuth';
import useFeatureFlags from 'hooks/useFeatureFlags';

import styles from './SideBar.module.scss';

const SideBar = () => {
  const history = useHistory();
  const {
    areRequirementsEnabled,
    isDepartmentViewEnabled,
    showDocumentManagement,
    showProcessLevelDatabase,
    showRecommendationOverview,
    isLandscapeEnabledE2E,
  } = useFeatureFlags();
  const { t } = useTranslation();
  const { checkPermission, checkRole, checkRoles, userInfo } = useAuth();

  const sendSearch = (text) => {
    if (text.trim()) {
      history.push({
        pathname: '/search',
        search: `?text=${text}`,
      });
    }
  };

  return (
    <div className={styles.SideBar}>
      {checkRole('USER') && <Search searching={() => {}} sendSearch={(text) => sendSearch(text)} />}
      {(checkPermission('epcRead') || userInfo?.neposInvolve) && (
        <Link to="/diagram/1">
          <button className={styles.Button} id="modeling" type="button">
            <i className={`di icon-hierarchie-diagramm ${styles.SideBarIcon}`} />
            {t('modeling')}
          </button>
        </Link>
      )}
      {checkRole('USER') && (
        <Link to="/sandbox-overview">
          <button className={styles.Button} id="sandbox" type="button">
            <i className={`di icon-sanduhr ${styles.SideBarIcon}`} />
            {t('sandbox')}
          </button>
        </Link>
      )}
      {checkRole('USER') && (
        <Link to="/published/1">
          <button className={styles.Button} id="process" type="button">
            <i className={`di icon-weltkugel ${styles.SideBarIcon}`} />
            {t('processMap')}
          </button>
        </Link>
      )}
      {checkRole('USER') && serviceUtils.isCentral() && isLandscapeEnabledE2E && (
        <Link to="/published/e2e/1">
          <button className={styles.Button} id="process-e2e" type="button">
            <i className={`di icon-unterebene-stapel ${styles.SideBarIcon}`} />
            {t('processMapE2E')}
          </button>
        </Link>
      )}
      {checkRole('USER') && serviceUtils.isCentral() && isLandscapeEnabledE2E && (
        <Link to="/diagram/e2e/1">
          <button className={styles.Button} id="modeling-view-e2e" type="button">
            <i className={`di icon-diagramm-baum ${styles.SideBarIcon}`} />
            {t('modelingE2E')}
          </button>
        </Link>
      )}
      {isDepartmentViewEnabled && (
        <Link to="/department-view">
          <button className={styles.Button} id="department-view" type="button">
            <i className={`di icon-werk-daimler ${styles.SideBarIcon}`} />
            {t('departmentView.title')}
          </button>
        </Link>
      )}
      {showRecommendationOverview && (
        <Link to="/recommendations">
          <button className={styles.Button} id="recommendation" type="button">
            <i className={`di icon-datei-check ${styles.SideBarIcon}`} />
            {t('processRecommendations')}
          </button>
        </Link>
      )}
      {checkRoles([ADMINISTRATOR, MODELER, QI, QI_MODELER]) && showDocumentManagement && (
        <Link to="/datasets">
          <button className={styles.Button} id="dataset" type="button">
            <i className={`di icon-ordner ${styles.SideBarIcon}`} />
            {t('documentManagement')}
          </button>
        </Link>
      )}
      {checkPermission('vcdWrite') && (
        <Link to="/administration">
          <button className={styles.Button} id="admin" type="button">
            <i className={`di icon-stift-bearbeiten ${styles.SideBarIcon}`} />
            {t('administration.name')}
          </button>
        </Link>
      )}
      {areRequirementsEnabled && serviceUtils.isCentral() && userInfo?.requirementReadAllowed && (
        <Link to="/requirements">
          <button className={styles.Button} id="requirements" type="button">
            <i className={`di icon-klemmbrett-aufgabe-liste ${styles.SideBarIcon}`} />
            {t('potentialsAndRequirements')}
          </button>
        </Link>
      )}
      {serviceUtils.isCentral() && showProcessLevelDatabase && (
        <Link to="/process-level-database">
          <button className={styles.Button} id="process-level-database" type="button">
            <i className={`di icon-hierarchie-stufe-struktur ${styles.SideBarIcon}`} />
            {t('processLevelDatabase')}
          </button>
        </Link>
      )}
      {checkRole('USER') && (
        <a
          className={styles.Button}
          href="https://social.intra.corpintra.net/groups/mbcmap/pages/hilfe-support"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className={`di icon-hilfe ${styles.SideBarIcon}`} />
          {t('helpSupport')}
        </a>
      )}
      <a
        className={styles.Button}
        href="https://mbcblobstorageprod.blob.core.windows.net/elearning-mbc/EN/v2/index.html"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className={`di icon-praesentation ${styles.SideBarIcon}`} />
        {t('eLearning')}
      </a>
    </div>
  );
};

export default SideBar;
