import { useEffect, useMemo, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { AREAS, BUTTON_PRIMARY, BUTTON_SECONDARY, STATUS, TEXTAREA_DEFAULT_LENGTH } from 'assets/constants/constants';
import SelectionActions from 'components/Selection/SelectionActions';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import InputTextNEPOS from 'components/UI/InputText/InputTextNEPOS';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useOuterClick from 'hooks/useOuterClick';
import useSymbol from 'hooks/useSymbol';
import { getAvailableInterfaces } from 'services/design';
import { Language } from 'types/config';
import { ProcessInterface } from 'types/processes';
import { PostIt as PostItProps } from 'types/symbols';
import { Pagination, TablePrefix, TableColumn, TableVariant } from 'types/tables';

import styles from './PostIt.module.scss';

type Props = {
  category: string;
  symbolId: string;
  postIt: PostItProps;
  title: string;
  isLowerLane?: boolean;
};

const columns: TableColumn<ProcessInterface>[] = [
  { id: 'parsedTitle', style: styles.Title },
  { id: 'processResponsible', style: styles.Responsible },
  { id: 'origin', style: styles.Origin },
];

const PostIt = (props: Props) => {
  const { postIt, title, isLowerLane, category, symbolId } = props;
  const { deletePostIt, updatePostIt } = useSymbol();
  const { id: diagramId } = useParams<{ id: string }>();
  const paletteButtonRef = useRef<HTMLDivElement>(null);
  const [isControlBarOpen, setIsControlBarOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { diagramLanguage, dispatch } = useDiagramContext();
  const { text, linkage, id } = postIt;
  const linkRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [hasOverFlow, setHasOverFlow] = useState(false);
  const [isMaxLength, setIsMaxLength] = useState(false);
  const [availableInterfaces, setAvailableInterfaces] = useState<(ProcessInterface & { id: string })[]>();
  const [interfaceToLink, setInterfaceToLink] = useState<string[]>([]);
  const [temporaryInterface, setTemporaryInterface] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [openDialog, setOpenDialog] = useState('');
  const maxLength = TEXTAREA_DEFAULT_LENGTH;
  const newText = text?.length > maxLength ? text.substring(0, maxLength) : text;
  const [value, setValue] = useState<string>(newText);
  useOuterClick(containerRef, () => setIsControlBarOpen(false));

  useEffect(() => {
    if (openDialog !== 'addInterface') {
      setAvailableInterfaces(undefined);
      setSearchText('');
      setPage(0);
      setTotalPages(1);
    }
  }, [openDialog]);

  const fetchInterfaces = async (newPage: number = 0, search: string = '') => {
    setAvailableInterfaces(undefined);
    setPage(newPage);
    setSearchText(search);

    try {
      const { data } = await getAvailableInterfaces(diagramId, { page: newPage, ...(search ? { search } : {}), size: 20 });
      setAvailableInterfaces(
        data.interfaces.map((elem: ProcessInterface) => ({
          ...elem,
          id: elem.idDiagram.toString(),
          parsedTitle: elem.title[i18n.language as Language],
          origin: t(`environment.${elem.status === STATUS.SANDBOX ? AREAS.SANDBOX : AREAS.MODELING}`.toLowerCase()),
        })),
      );
      setTotalPages(data.numberOfPages);
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const buttons = [
    {
      icon: 'di icon-link',
      id: 'post-it-link-button',
      onClick: () => {
        setOpenDialog('addInterface');
        fetchInterfaces();
      },
      tooltipLabel: t('link'),
    },
    {
      icon: 'di icon-link-loeschen',
      id: 'post-it-unlink-button',
      isHidden: !linkage,
      onClick: () => {
        postIt.linkage = undefined;
        setTemporaryInterface('');
      },
      tooltipLabel: t('remove-linkage'),
    },
    {
      icon: 'di icon-muelleimer-loeschen',
      id: 'post-it-delete-button',
      onClick: () => deletePostIt(category, symbolId, id),
      tooltipLabel: t('tool.delete'),
    },
  ];

  const linkInterface = () => {
    const linkedInterface = availableInterfaces?.find((elem) => elem.id === interfaceToLink[0]) || temporaryInterface;

    if (!linkedInterface) return;

    postIt.linkage = {
      id:
        // eslint-disable-next-line no-nested-ternary
        typeof linkedInterface === 'string'
          ? undefined
          : linkedInterface.status === STATUS.SANDBOX
          ? `/sandbox/${linkedInterface.idDiagram}`
          : `/diagram/${linkedInterface.idDiagram}`,
      title: typeof linkedInterface === 'string' ? temporaryInterface : linkedInterface.title,
    };
    updatePostIt(category, postIt, symbolId);
    setOpenDialog('');
  };

  useEffect(() => {
    postIt.text = value;
    updatePostIt(category, postIt, symbolId);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const addInterfaceDialogButtons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: openDialog === 'addInterface' ? !interfaceToLink.length : !temporaryInterface,
      handleClick: () => linkInterface(),
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t(openDialog === 'addInterface' ? 'cancel' : 'back'),
      disabled: false,
      handleClick: () => {
        setOpenDialog(openDialog === 'addInterface' ? '' : 'addInterface');

        if (openDialog !== 'addInterface') fetchInterfaces();
      },
      key: openDialog === 'addInterface' ? 'cancel' : 'back',
    },
  ];

  const availableButtons = title === 'Input' || title === 'Output' ? buttons : buttons.slice(2);

  useEffect(() => {
    if (!linkRef.current) return;
    setHasOverFlow(linkRef.current.scrollHeight > linkRef.current.clientHeight);
  }, [postIt.linkage]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredInterfaces = useMemo(
    () =>
      !availableInterfaces
        ? undefined
        : availableInterfaces.filter((elem) => elem.parsedTitle?.toLowerCase().includes(searchText.toLowerCase())) || [],
    [availableInterfaces, searchText],
  );

  const Linkage = () => {
    if (!linkage) return <></>;

    return (
      <>
        {typeof linkage.title === 'string' ? (
          <div className={`${styles.Linkage} ${styles.TemporaryLinkage}`}>
            <span ref={linkRef}>{linkage.title}</span>
          </div>
        ) : (
          <Link to={String(linkage.id)}>
            <div className={styles.Linkage}>
              <i className="di icon-link" />
              <span ref={linkRef}>{linkage.title[diagramLanguage]}</span>
            </div>
          </Link>
        )}
      </>
    );
  };

  return (
    <>
      <div
        onClick={(e) => {
          if (!isLowerLane && textAreaRef.current === e.target) return;
          setIsControlBarOpen(!isControlBarOpen);
        }}
        ref={containerRef}
        style={{ position: 'relative' }}
      >
        {isControlBarOpen && <SelectionActions buttons={availableButtons} paletteButtonRef={paletteButtonRef} />}
        <div className={`${styles.PostIt} ${isLowerLane ? styles.LowerLane : ''} ${isControlBarOpen ? styles.Selected : ''}`}>
          {(title === 'Input' || title === 'Output') && <span className={styles.PostItTitle}>{`${title} Name`}</span>}
          <textarea
            className={`${styles.TextArea} ${isLowerLane ? styles.LowerLane : ''}`}
            id={`${id}-textarea`}
            maxLength={TEXTAREA_DEFAULT_LENGTH}
            onChange={(event) => {
              if (event.target.value.length >= maxLength) {
                setIsMaxLength(true);
                return;
              }
              setIsMaxLength(false);
              setValue(event.target.value);
            }}
            onKeyDown={(keyEvent) => {
              return isMaxLength ? keyEvent.key !== 'Backspace' : true;
            }}
            ref={textAreaRef}
            rows={1}
            value={value}
          />

          <>
            {!isLowerLane && <span className={styles.PostItTitle}>Prozessschnittstelle</span>}

            {linkage &&
              (hasOverFlow ? (
                <TooltipComponent
                  content={typeof linkage.title === 'string' ? linkage.title : linkage.title[diagramLanguage]}
                  cssClass="mbc-tooltip nepos-tooltip"
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  <Linkage />
                </TooltipComponent>
              ) : (
                <Linkage />
              ))}
          </>
        </div>
      </div>
      {openDialog && (
        <DialogNEPOS
          dialog={{
            title: t(openDialog === 'addInterface' ? 'addX' : 'addTemporalX', { labelX: t('processTab') }),
            buttons: addInterfaceDialogButtons,
          }}
        >
          {openDialog === 'addInterface' ? (
            <>
              <TableWithSearch
                buttonText={t('addTemporalX', { labelX: t('processTab') })}
                columns={columns}
                getPage={(newPage) => fetchInterfaces(newPage, searchText)}
                id={TablePrefix.INTERFACES}
                isAsync
                multiselect={false}
                onCheck={setInterfaceToLink}
                onClick={() => setOpenDialog('addTemporaryInterface')}
                page={page}
                pagination={Pagination.PAGES}
                rows={filteredInterfaces}
                searching={(search: string) => {
                  setTotalPages(1);
                  fetchInterfaces(0, search);
                }}
                totalPages={totalPages}
                variant={TableVariant.CHECKBOX}
              />
            </>
          ) : (
            <InputTextNEPOS
              id="add-temporal-interface-input"
              label={t('temporalX', { labelX: t('processTab') })}
              onChange={setTemporaryInterface}
              value={temporaryInterface}
            />
          )}
        </DialogNEPOS>
      )}
    </>
  );
};

export default PostIt;
