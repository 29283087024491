import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import Search from 'components/UI/Search/Search';

import styles from './SearchNEPOS.module.scss';

type Props = {
  buttonText?: string;
  disabled?: boolean;
  hasDropdown?: boolean;
  id: string;
  isAsync: boolean;
  onClick?: () => void;
  onClickDropdownItem?: (item: string) => void;
  searching: (text: string) => void;
  sendSearch?: (text: string) => void;
  isFullWidth?: boolean;
};

const SearchNEPOS = (props: Props) => {
  const { buttonText = false, disabled = false, onClick = () => {}, isFullWidth = false } = props;
  return (
    <div className={styles.SearchWrapper}>
      <Search disabled={disabled} extraClass={`${styles.SearchNEPOS} ${isFullWidth ? styles.IsFullWidth : ''}`} {...props} />
      {onClick && buttonText && (
        <ButtonNEPOS handleClick={onClick} id="new-request-button" isSecondary>
          {buttonText}
        </ButtonNEPOS>
      )}
    </div>
  );
};

export default SearchNEPOS;
