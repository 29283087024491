import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import RoundButton from 'components/UI/RoundButton/RoundButton';

import styles from './RequirementSidebar.module.scss';

type Props = {
  onClose: () => void;
};
const RequirementSidebar = (props: Props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  return (
    <div className={styles.Content}>
      <div className={styles.ButtonWrapper}>
        <RoundButton
          className={styles.Button}
          icon="di icon-pfeil-doppelt-chevron-links"
          id="RequirementSidebar-hide-symbol-attributes-button"
          onClick={onClose}
        />
        <span>
          <Link className={styles.Search} to="/requirements">
            <i className="di icon-suche-ergebnisse" />
            <span>{t('requirements.searchResults')}</span>
          </Link>
        </span>
      </div>
      <section />
    </div>
  );
};

export default RequirementSidebar;
