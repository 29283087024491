import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import styles from './WorkflowStepNEPOS.module.scss';

type Props = {
  date?: string;
  id: number;
  name?: string;
  namesToShow?: string[];
  status: string;
  label: string | undefined;
  isLegacyStyle?: boolean;
};

const WorkflowStepNEPOS = (props: Props) => {
  const { date, id, name, namesToShow, status, label, isLegacyStyle } = props;

  const Icon = {
    HIERARCHICAL: 'icon-hierarchie-diagramm',
    EDITORIAL: 'icon-pfeil-aktualisieren',
    HISTORICAL: '',
    DONE: 'icon-check',
    INITIAL: 'icon-check',
    CURRENT: 'icon-overflow-mehr-horizontal',
    PENDING: '',
    RELEASE: 'icon-hierarchie-diagramm',
    ARCHIVE: 'icon-hierarchie-diagramm',
  };

  const formattedNames = () => {
    if (name) {
      return (
        <p className={styles.Name}>
          {name} · <span>{date}</span>
        </p>
      );
    }
    return namesToShow?.map((nameToShow) => (
      <p className={styles.Name} key={nameToShow}>
        {nameToShow} · <span>{date}</span>
      </p>
    ));
  };

  const legacyFormattedNames = () => {
    if (name) {
      return <p className={`${styles.Name} ${styles.LegacyName}`}>{name}</p>;
    }
    return namesToShow?.map((nameToShow) => (
      <p className={styles.Name} key={nameToShow}>
        {nameToShow}
      </p>
    ));
  };

  return (
    <div className={`${styles.Step} ${styles[status]} ${isLegacyStyle ? styles.LegacyStep : ''}`} id={id.toString()}>
      <div>
        <p className={styles.Label}>{label}</p>
        <TooltipComponent
          content={() => (isLegacyStyle ? date : formattedNames())}
          cssClass="mbc-tooltip nepos-tooltip wokflow-nepos-tooltip"
          position="TopCenter"
          showTipPointer={false}
        >
          {isLegacyStyle ? legacyFormattedNames() : formattedNames()}
        </TooltipComponent>
      </div>

      <div className={styles.IconLineWrapper}>
        <i className={`di ${Icon[status as keyof typeof Icon]} ${styles.Icon}`} />
        <div className={styles.Line} />
      </div>
    </div>
  );
};

export default WorkflowStepNEPOS;
