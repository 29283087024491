import { User } from './catalog';
import { Attributes, AttributeCode, Input, Care } from './forms';
import { CatalogUser } from './user';

export enum WorkflowType {
  HIERARCHICAL_WF_SIPOC = 'HIERARCHICAL',
  EDITORIAL_WF_SIPOC = 'EDITORIAL',
  HISTORICAL_WF_SIPOC = 'HISTORICAL',
  HIERARCHICAL_WF_SWIMLANE = 'HIERARCHICAL',
  EDITORIAL_WF_SWIMLANE = 'EDITORIAL',
  HISTORICAL_WF_SWIMLANE = 'HIERARCHICAL',
  HIERARCHICAL_DS_WF_EPC = 'HIERARCHICAL',
  ARCHIVE_DS_WF_VCD = 'ARCHIVE',
  ARCHIVE_DS_WF_EPC = 'ARCHIVE',
}

export enum WorkflowCode {
  HIERARCHICAL_WF_SIPOC = 'HIERARCHICAL_WF_SIPOC',
  EDITORIAL_WF_SIPOC = 'EDITORIAL_WF_SIPOC',
  HISTORICAL_WF_SIPOC = 'HISTORICAL_WF_SIPOC',
  HIERARCHICAL_WF_SWIMLANE = 'HIERARCHICAL_WF_SWIMLANE',
  EDITORIAL_WF_SWIMLANE = 'EDITORIAL_WF_SWIMLANE',
  HISTORICAL_WF_SWIMLANE = 'HISTORICAL_WF_SWIMLANE',
  ARCHIVE_DS_WF_VCD = 'ARCHIVE_DS_WF_VCD',
  ARCHIVE_DS_WF_EPC = 'ARCHIVE_DS_WF_EPC',
}

export enum WorkflowIcon {
  HIERARCHICAL_WF_SIPOC = 'di icon-hierarchie-diagramm',
  EDITORIAL_WF_SIPOC = 'di icon-pfeil-aktualisieren',
  HISTORICAL_WF_SIPOC = 'di icon-archiv-karton',
  HIERARCHICAL_WF_SWIMLANE = 'di icon-hierarchie-diagramm',
  EDITORIAL_WF_SWIMLANE = 'di icon-pfeil-aktualisieren',
  HISTORICAL_WF_SWIMLANE = 'di icon-archiv-karton',
  ARCHIVE_DS_WF_VCD = 'di icon-hierarchie-diagramm',
  ARCHIVE_DS_WF_EPC = 'di icon-hierarchie-diagramm',
}

export enum WorkflowStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
  ESCALATED = 'ESCALATED',
}

export type WorkflowOption = {
  code: string;
  enabled: boolean;
  id: number;
};

export type ResponsibleList = {
  id: number;
  userCode: string;
  responsibleType: string;
  finishDate: string | null;
};

export type WorkflowStage = {
  id: number;
  priority: number;
  attributes: StageAttributeType[];
  type: string;
  status: string;
  initialDate: string | null;
  expirationDate: string | null;
  responsibleList: ResponsibleList[];
};

export type WorkflowStageResponse = {
  id: number;
  type: string;
  status: string;
  initialDate: string | null;
  expirationDate: string | null;
  responsibleList: ResponsibleList[];
};

// export type Users = {
//   [key: string]: User;
// };

export type WorkflowData = {
  id: number;
  type: WorkflowCode;
  attributes: Attributes;
  diagramVersion: string;
  onlyRead: boolean;
  onlyReadReason: string;
  processNumber: string;
  scopes?: string[];
  status: WorkflowStatus;
  stages: WorkflowStage[];
  users: User[];
};

export type DatasetWorkflowData = Omit<WorkflowData, 'diagramVersion' | 'attributes' | 'processNumber'> & {
  datasetVersion: string;
};

export type WorkflowDataResponse = {
  id: number;
  type: WorkflowCode;
  attributes: Attributes;
  diagramVersion: string;
  onlyRead: boolean;
  onlyReadReason: string;
  processNumber: string;
  scopes?: string[];
  status: WorkflowStatus;
  stages: WorkflowStageResponse[];
  users: CatalogUser[];
};

export type AttributeType = {
  id: number;
  code: AttributeCode;
  fieldType: Input;
};

export type StageAttributeType = {
  priority: number;
  care: Care;
  attributeType: AttributeType;
};

export type StageTypeData = {
  id: number;
  code: AttributeCode;
  stageAttributeTypes: StageAttributeType[];
};

export type WorkflowStageType = {
  id: number;
  priority: number;
  stageType: StageTypeData;
};

export type WorkflowStageDataResponse = {
  id: number;
  code: WorkflowCode;
  workflowStageTypes: WorkflowStageType[];
};
