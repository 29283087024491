import { useCallback, useEffect, useMemo } from 'react';

import clone from 'just-clone';
import { useParams } from 'react-router';

import { SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD } from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import {
  Connector,
  ConnectorDirectionTypes,
  ConnectorEndpoint,
  ConnectorEndpointType,
  ConnectorPorts,
  ConnectorVertex,
  GhostConnector,
  GhostConnectorData,
} from 'types/connectors';
import { Coordinates, Orientation, Tool } from 'types/diagram';
import { Symbol } from 'types/symbols';

import useDiagramContext from './useDiagramContext';
import useProcess from './useProcess';

const orientationByPort = {
  [ConnectorPorts.BOTTOM]: Orientation.VERTICAL,
  [ConnectorPorts.LEFT]: Orientation.HORIZONTAL,
  [ConnectorPorts.RIGHT]: Orientation.HORIZONTAL,
  [ConnectorPorts.TOP]: Orientation.VERTICAL,
};
const connectorExtraSpace = 0.35;

export default function useConnector(connector?: Connector) {
  const {
    connectorPortMouseUp,
    fontSizeRef,
    dispatch,
    fontSize,
    ghostConnector,
    ghostConnectorData,
    initialCoords,
    originRef,
    processData,
    selectedConnectorId,
    tool,
    shouldPreventClick,
  } = useDiagramContext();
  const selectedConnector = useMemo(
    () =>
      (selectedConnectorId &&
        processData?.connectors.find(
          (element) => element.source.id === selectedConnectorId?.sourceId && element.target.id === selectedConnectorId?.targetId,
        )) ||
      null,
    [processData?.connectors, selectedConnectorId],
  );
  const idProcess = useParams<{ id: string }>().id;
  const { extractAllSymbols } = useProcess(idProcess);
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS; // eslint-disable-line @typescript-eslint/no-unused-vars

  const getEndpointCoordinates = useCallback(
    (endpoint?: ConnectorEndpoint) => {
      if (!endpoint || !processData) return null;
      const originBounds = originRef?.current?.getBoundingClientRect();
      const portBounds = endpoint?.node?.getBoundingClientRect();
      const portTop = (portBounds?.top || 0) - (originBounds?.top || 0);
      const portLeft = (portBounds?.left || 0) - (originBounds?.left || 0);
      const currentFontSize = fontSizeRef.current || fontSize || 1;

      return {
        left: portLeft / currentFontSize + connectorExtraSpace,
        top: portTop / currentFontSize + connectorExtraSpace,
      };
    },
    [fontSize, fontSizeRef, originRef, processData],
  );
  const source = connector?.source || selectedConnector?.source;
  const target = connector?.target || selectedConnector?.target;
  const sourceCoordinates = useMemo(() => getEndpointCoordinates(source), [getEndpointCoordinates, source]);
  const targetCoordinates = useMemo(() => getEndpointCoordinates(target), [getEndpointCoordinates, target]);

  const getConnectorError = useCallback(
    (newSource: ConnectorEndpoint, newTarget: ConnectorEndpoint) => {
      let error = '';
      if (newSource.id === newTarget.id) {
        error = 'errors.selfConnection';
      } else if (
        tool === Tool.CONNECT &&
        processData?.connectors.some(
          (elem) =>
            (elem.source.id === newSource.id && elem.target.id === newTarget.id) ||
            (elem.target.id === newSource.id && elem.source.id === newTarget.id),
        )
      ) {
        error = 'errors.connectionExists';
      }

      return error;
    },
    [processData?.connectors, tool],
  );

  const splitConnector = useCallback(
    (
      connectorSource: ConnectorEndpoint,
      connectorTarget: ConnectorEndpoint,
      connectorSourceCoordinates: Coordinates,
      connectorTargetCoordinates: Coordinates,
    ) => {
      if (!processData) return;

      let newVertices: ConnectorVertex[] = [];
      if (
        [connectorSource.port, connectorTarget.port].every((port) =>
          [ConnectorPorts.LEFT, ConnectorPorts.RIGHT].includes(port),
        ) &&
        Math.abs(connectorSourceCoordinates.top - connectorTargetCoordinates.top) > SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD
      ) {
        newVertices = [
          {
            direction: ConnectorDirectionTypes.HORIZONTAL,
            coordinate: connectorSourceCoordinates.left + (connectorTargetCoordinates.left - connectorSourceCoordinates.left) / 2,
          },
          {
            direction: ConnectorDirectionTypes.VERTICAL,
            coordinate: connectorTargetCoordinates.top,
          },
        ];
      } else if (
        [connectorSource.port, connectorTarget.port].every((port) =>
          [ConnectorPorts.TOP, ConnectorPorts.BOTTOM].includes(port),
        ) &&
        Math.abs(connectorSourceCoordinates.left - connectorTargetCoordinates.left) > SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD
      ) {
        newVertices = [
          {
            direction: ConnectorDirectionTypes.VERTICAL,
            coordinate: connectorSourceCoordinates.top + (connectorTargetCoordinates.top - connectorSourceCoordinates.top) / 2,
          },
          {
            direction: ConnectorDirectionTypes.HORIZONTAL,
            coordinate: connectorTargetCoordinates.left,
          },
        ];
      } else if (
        orientationByPort[connectorSource.port] === Orientation.VERTICAL &&
        orientationByPort[connectorTarget.port] === Orientation.HORIZONTAL
      ) {
        newVertices = [
          {
            direction: ConnectorDirectionTypes.VERTICAL,
            coordinate: connectorTargetCoordinates.top,
          },
        ];
      } else if (
        orientationByPort[connectorSource.port] === Orientation.HORIZONTAL &&
        orientationByPort[connectorTarget.port] === Orientation.VERTICAL
      ) {
        newVertices = [
          {
            direction: ConnectorDirectionTypes.HORIZONTAL,
            coordinate: connectorTargetCoordinates.left,
          },
        ];
      }

      return { source: connectorSource, target: connectorTarget, vertices: newVertices, connectorTargetCoordinates };
    },
    [processData],
  );

  const checkSplitConnector = useCallback(
    (
      symbols: Symbol[],
      connectors: Connector[],
      newValues?: ({ meta: { left: number; top: number; width: number; height: number } } & { id: string })[],
    ) => {
      let connectorsData;
      connectors.forEach((element) => {
        const sourceSymbol =
          newValues?.find(({ id }) => element.source.id === id) || symbols.find(({ id }) => element.source.id === id);
        const targetSymbol =
          newValues?.find(({ id }) => element.target.id === id) || symbols.find(({ id }) => element.target.id === id);

        if (!sourceSymbol || !targetSymbol) return;

        const sourceEndpointCoordinates = getEndpointCoordinates(element.source);
        const targetEndpointCoordinates = getEndpointCoordinates(element.target);

        if (!sourceEndpointCoordinates || !targetEndpointCoordinates) return;

        if (
          element.vertices.length === 0 &&
          sourceEndpointCoordinates.left !== targetEndpointCoordinates.left &&
          sourceEndpointCoordinates.top !== targetEndpointCoordinates.top
        ) {
          connectorsData = splitConnector(element.source, element.target, sourceEndpointCoordinates, targetEndpointCoordinates);
        }
      });
      return connectorsData;
    },
    [getEndpointCoordinates, splitConnector],
  );

  const getNewConnectorVertices = useCallback(
    (newSource: ConnectorEndpoint, newTarget: ConnectorEndpoint) => {
      const connectorVertices: ConnectorVertex[] = [];
      const newSourceCoordinates = getEndpointCoordinates(newSource);
      const newTargetCoordinates = getEndpointCoordinates(newTarget);

      if (!newSourceCoordinates || !newTargetCoordinates) return connectorVertices;

      if (
        newTargetCoordinates &&
        orientationByPort[newSource.port] === Orientation.VERTICAL &&
        orientationByPort[newTarget.port] === Orientation.HORIZONTAL
      ) {
        connectorVertices.push({ direction: ConnectorDirectionTypes.VERTICAL, coordinate: newTargetCoordinates.top });
      } else if (
        newTargetCoordinates &&
        orientationByPort[newSource.port] === Orientation.HORIZONTAL &&
        orientationByPort[newTarget.port] === Orientation.VERTICAL
      ) {
        connectorVertices.push({ direction: ConnectorDirectionTypes.HORIZONTAL, coordinate: newTargetCoordinates.left });
      } else if (
        [newSource.port, newTarget.port].every((port) => [ConnectorPorts.LEFT, ConnectorPorts.RIGHT].includes(port)) &&
        Math.abs(newSourceCoordinates.top - newTargetCoordinates.top) > SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD
      ) {
        connectorVertices.push(
          {
            direction: ConnectorDirectionTypes.HORIZONTAL,
            coordinate: newSourceCoordinates.left + (newTargetCoordinates.left - newSourceCoordinates.left) / 2,
          },
          {
            direction: ConnectorDirectionTypes.VERTICAL,
            coordinate: newTargetCoordinates.top,
          },
        );
      } else if (
        [newSource.port, newTarget.port].every((port) => [ConnectorPorts.TOP, ConnectorPorts.BOTTOM].includes(port)) &&
        Math.abs(newSourceCoordinates.left - newTargetCoordinates.left) > SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD
      ) {
        connectorVertices.push(
          {
            direction: ConnectorDirectionTypes.VERTICAL,
            coordinate: newSourceCoordinates.top + (newTargetCoordinates.top - newSourceCoordinates.top) / 2,
          },
          {
            direction: ConnectorDirectionTypes.HORIZONTAL,
            coordinate: newTargetCoordinates.left,
          },
        );
      }

      return connectorVertices;
    },
    [getEndpointCoordinates],
  );

  const updateConnectorsShape = useCallback(
    (params?: { diagramConnectors?: Connector[]; symbolId?: string }) => {
      const { diagramConnectors, symbolId } = params || {};
      if (!processData || (!processData?.connectors.length && !diagramConnectors)) return;
      const connectors = diagramConnectors || processData.connectors;
      let newConnectors = connectors.map((conn) => {
        const sourceEndpointCoordinates = getEndpointCoordinates(conn.source);
        const targetEndpointCoordinates = getEndpointCoordinates(conn.target);

        if (
          !sourceEndpointCoordinates ||
          !targetEndpointCoordinates ||
          (symbolId && conn.source.id !== symbolId && conn.target.id !== symbolId)
        )
          return conn;

        return splitConnector(conn.source, conn.target, sourceEndpointCoordinates, targetEndpointCoordinates) || conn;
      });
      newConnectors = newConnectors.filter(
        (conn) =>
          conn.source.node &&
          document.body.contains(conn.source.node) &&
          conn.target.node &&
          document.body.contains(conn.target.node),
      );
      dispatch({
        type: DiagramActionTypes.UPDATE_CONNECTORS,
        payload: newConnectors,
      });
    },
    [dispatch, getEndpointCoordinates, splitConnector, processData],
  );

  const createConnector = useCallback(
    (newSource: ConnectorEndpoint, newTarget: ConnectorEndpoint) => {
      if (!processData) return;
      const error = getConnectorError(newSource, newTarget);
      if (error) {
        dispatch({ type: DiagramActionTypes.SET_ERROR, payload: { title: 'errors.connectionError', message: error } });
        return;
      }
      const newConnector = {
        source: newSource,
        target: newTarget,
        vertices: getNewConnectorVertices(newSource, newTarget),
      };
      if (!newConnector) return;

      const splittedConnector = checkSplitConnector(extractAllSymbols(processData) || [], [newConnector]);

      dispatch({
        type: DiagramActionTypes.UPDATE_CONNECTORS,
        payload: [...processData.connectors, splittedConnector || newConnector],
      });
      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
    },
    [checkSplitConnector, dispatch, extractAllSymbols, getConnectorError, getNewConnectorVertices, processData],
  );

  const deleteConnector = useCallback(
    (connectorSource: ConnectorEndpoint, connectorTarget: ConnectorEndpoint) => {
      if (!processData) return;
      const updatedConnectors = processData.connectors.filter(
        (elem) => elem.source.id !== connectorSource.id || elem.target.id !== connectorTarget.id,
      );

      dispatch({ type: DiagramActionTypes.SET_CONNECTOR_SELECTION, payload: null });
      dispatch({ type: DiagramActionTypes.UPDATE_CONNECTORS, payload: updatedConnectors });
      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
    },
    [dispatch, processData],
  );

  const updateConnector = useCallback(
    (newSource: ConnectorEndpoint, newTarget: ConnectorEndpoint) => {
      const error = getConnectorError(newSource, newTarget);
      if (error) {
        dispatch({ type: DiagramActionTypes.SET_ERROR, payload: { title: 'errors.connectionError', message: error } });
        return;
      }
      if (!processData) return;

      const updatedConnectors = processData.connectors.map((elem) =>
        elem.source.id === selectedConnectorId?.sourceId && elem.target.id === selectedConnectorId?.targetId
          ? { source: newSource, target: newTarget, vertices: getNewConnectorVertices(newSource, newTarget) }
          : elem,
      );

      dispatch({ type: DiagramActionTypes.UPDATE_CONNECTORS, payload: updatedConnectors });
      dispatch({ type: DiagramActionTypes.SET_CONNECTOR_SELECTION, payload: selectedConnectorId });
      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
    },
    [dispatch, getConnectorError, getNewConnectorVertices, processData, selectedConnectorId],
  );

  const deleteSymbolConnectors = useCallback(
    (symbolId: string) => {
      if (!processData) return;

      const newConnectors = processData.connectors.filter((elem) => ![elem.source.id, elem.target.id].includes(symbolId));

      if (newConnectors.length === processData.connectors.length) return;
      dispatch({ type: DiagramActionTypes.UPDATE_CONNECTORS, payload: newConnectors });
    },
    [dispatch, processData],
  );

  const updateConnectorVertices = useCallback(
    (newVertices: ConnectorVertex[]) => {
      if (!processData || !source || !target) return;
      const newConnectors = processData.connectors.map((elem) =>
        elem.source.id !== source.id || elem.target.id !== target.id ? elem : { source, target, vertices: newVertices },
      );

      dispatch({ type: DiagramActionTypes.UPDATE_CONNECTORS, payload: newConnectors });
    },
    [dispatch, processData, source, target],
  );

  const recalculateLastVertex = useCallback(
    (connectorsData: { vertices: ConnectorVertex[]; connectorTargetCoordinates: { left: number; top: number } | null }) => {
      if (!connectorsData || !connectorsData.vertices.length || !processData || !connectorsData.connectorTargetCoordinates)
        return;

      const newVertices = clone(connectorsData.vertices);
      const lastVertex = newVertices[connectorsData.vertices.length - 1];

      if (
        lastVertex.direction === ConnectorDirectionTypes.HORIZONTAL &&
        lastVertex.coordinate !== connectorsData.connectorTargetCoordinates.left
      ) {
        newVertices[newVertices.length - 1] = {
          direction: lastVertex.direction,
          coordinate: connectorsData.connectorTargetCoordinates.left,
        };
      }
      if (
        lastVertex.direction === ConnectorDirectionTypes.VERTICAL &&
        lastVertex.coordinate !== connectorsData.connectorTargetCoordinates.top
      ) {
        newVertices[newVertices.length - 1] = {
          direction: lastVertex.direction,
          coordinate: connectorsData.connectorTargetCoordinates.top,
        };
      }

      return newVertices;
    },
    [processData],
  );

  // This is for Swimlanes. Although 'updateLastVertices' exists,
  // that function handles symbol meta, which does not exist in
  // Swimlane diagrams.
  const updateSwimlanesConnectorsVertices = useCallback(
    (params?: { diagramConnectors?: Connector[]; symbolId?: string }) => {
      const { diagramConnectors, symbolId } = params || {};
      if (!processData || (!processData?.connectors.length && !diagramConnectors)) return;
      const connectors = diagramConnectors || processData.connectors;

      let newConnectors = connectors.map((conn) => {
        const sourceEndpointCoordinates = getEndpointCoordinates(conn.source);
        const targetEndpointCoordinates = getEndpointCoordinates(conn.target);

        if (
          !sourceEndpointCoordinates ||
          !targetEndpointCoordinates ||
          (symbolId && conn.source.id !== symbolId && conn.target.id !== symbolId)
        )
          return conn;

        const newVertices = recalculateLastVertex({
          vertices: conn.vertices,
          connectorTargetCoordinates: targetEndpointCoordinates,
        });

        if (!newVertices) {
          if (
            sourceEndpointCoordinates.left !== targetEndpointCoordinates.left &&
            sourceEndpointCoordinates.top !== targetEndpointCoordinates.top
          ) {
            return splitConnector(conn.source, conn.target, sourceEndpointCoordinates, targetEndpointCoordinates) || conn;
          }
          return conn;
        }

        return { source: conn.source, target: conn.target, vertices: newVertices };
      });

      newConnectors = newConnectors.filter(
        (conn) =>
          conn.source.node &&
          document.body.contains(conn.source.node) &&
          conn.target.node &&
          document.body.contains(conn.target.node),
      );

      dispatch({
        type: DiagramActionTypes.UPDATE_CONNECTORS,
        payload: newConnectors,
      });
    },
    [dispatch, getEndpointCoordinates, processData, recalculateLastVertex, splitConnector],
  );

  const updateLastVertex = useCallback(
    (connectorsData: {
      source: ConnectorEndpoint;
      target: ConnectorEndpoint;
      vertices: ConnectorVertex[];
      connectorTargetCoordinates: { left: number; top: number };
    }) => {
      if (!connectorsData || !processData) return;

      const { vertices, connectorTargetCoordinates } = connectorsData;
      const newVertices = recalculateLastVertex({ vertices, connectorTargetCoordinates });

      if (!newVertices) return;

      const newConnectors = processData.connectors.map((elem) =>
        elem.source.id !== connectorsData.source.id || elem.target.id !== connectorsData.target.id
          ? elem
          : { source: connectorsData.source, target: connectorsData.target, vertices: newVertices },
      );

      return newConnectors;
    },
    [processData, recalculateLastVertex],
  );

  const updateLastVertices = useCallback(
    (newValues?: ({ meta: { left: number; top: number; width: number; height: number } } & { id: string })[]) => {
      if (!newValues || !processData) return;

      const newConnectorsData = processData.connectors.map((element) => {
        const newSymbol = newValues.find(({ id }) => element.target.id === id);
        if (!newSymbol || element.vertices.length === 0) return element;
        const newVertices = clone(element.vertices);
        const lastVertex = newVertices[element.vertices.length - 1];
        if (lastVertex.direction === ConnectorDirectionTypes.HORIZONTAL && lastVertex.coordinate !== newSymbol.meta.left) {
          newVertices[newVertices.length - 1] = {
            direction: lastVertex.direction,
            coordinate: newSymbol.meta.left + newSymbol.meta.width / 2,
          };
        }
        if (lastVertex.direction === ConnectorDirectionTypes.VERTICAL && lastVertex.coordinate !== newSymbol.meta.top) {
          newVertices[newVertices.length - 1] = {
            direction: lastVertex.direction,
            coordinate: newSymbol.meta.top + newSymbol.meta.height / 2,
          };
        }

        return { source: element.source, target: element.target, vertices: newVertices };
      });

      return newConnectorsData;
    },
    [processData],
  );

  const handleCreateConnectorMove = useCallback(
    (event: MouseEvent) => {
      if (!ghostConnectorData.current?.source.port || !initialCoords.current || !fontSize) return;

      const dx = (event.clientX - initialCoords.current.x) / fontSize;
      const dy = (event.clientY - initialCoords.current.y) / fontSize;

      const newTarget = {
        left: ghostConnectorData.current.source.left + dx,
        top: ghostConnectorData.current.source.top + dy,
      };
      const newVertices: ConnectorVertex[] = [ConnectorPorts.LEFT, ConnectorPorts.RIGHT].includes(
        ghostConnectorData.current.source.port,
      )
        ? [
            {
              direction: ConnectorDirectionTypes.HORIZONTAL,
              coordinate: ghostConnectorData.current.source.left + (newTarget.left - ghostConnectorData.current.source.left) / 2,
            },
            {
              direction: ConnectorDirectionTypes.VERTICAL,
              coordinate: newTarget.top,
            },
          ]
        : [
            {
              direction: ConnectorDirectionTypes.VERTICAL,
              coordinate: ghostConnectorData.current.source.top + (newTarget.top - ghostConnectorData.current.source.top) / 2,
            },
            {
              direction: ConnectorDirectionTypes.HORIZONTAL,
              coordinate: newTarget.left,
            },
          ];
      dispatch({
        type: DiagramActionTypes.UPDATE_GHOST_CONNECTOR,
        payload: { target: newTarget, vertices: newVertices },
      });
    },
    [dispatch, fontSize, ghostConnectorData, initialCoords],
  );

  const handleCreateConnectorEnd = useCallback(() => {
    if (!connectorPortMouseUp.current) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: 'errors.connectionError', message: 'errors.connectionStartEnd' },
      });
    }
    document.body.classList.remove('grabbing');
    document.removeEventListener('mousemove', handleCreateConnectorMove);
    document.removeEventListener('mouseup', handleCreateConnectorEnd);
    dispatch({ type: DiagramActionTypes.RESET_GHOST_CONNECTOR });
    initialCoords.current = null;
    ghostConnectorData.current = null;
    connectorPortMouseUp.current = false;
  }, [connectorPortMouseUp, dispatch, ghostConnectorData, handleCreateConnectorMove, initialCoords]);

  const handleCreateConnectorStart = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>, endpoint: ConnectorEndpoint) => {
      const endpointCoordinates = getEndpointCoordinates(endpoint);
      if (!endpointCoordinates) return;

      initialCoords.current = {
        x: event.clientX,
        y: event.clientY,
      };
      ghostConnectorData.current = {
        source: { ...endpoint, ...endpointCoordinates },
        target: endpointCoordinates,
        movingEndpointType: ConnectorEndpointType.TARGET,
        movingEndpointInitialCoords: endpointCoordinates,
      };
      document.addEventListener('mousemove', handleCreateConnectorMove);
      document.addEventListener('mouseup', handleCreateConnectorEnd);
      dispatch({
        type: DiagramActionTypes.INIT_GHOST_CONNECTOR,
        payload: { source: { ...endpoint, ...endpointCoordinates }, target: endpointCoordinates },
      });
    },
    [dispatch, getEndpointCoordinates, ghostConnectorData, handleCreateConnectorEnd, handleCreateConnectorMove, initialCoords],
  );

  const handleUpdateConnectorMove = useCallback(
    (event: MouseEvent) => {
      if (!selectedConnector || !ghostConnectorData.current?.source.port || !initialCoords.current || !fontSize) return;

      const dx = (event.clientX - initialCoords.current.x) / fontSize;
      const dy = (event.clientY - initialCoords.current.y) / fontSize;
      const newCoordinates = {
        left: ghostConnectorData.current.movingEndpointInitialCoords.left + dx,
        top: ghostConnectorData.current.movingEndpointInitialCoords.top + dy,
      };
      ghostConnectorData.current[ghostConnectorData.current.movingEndpointType] = {
        ...ghostConnectorData.current[ghostConnectorData.current.movingEndpointType],
        ...newCoordinates,
      };
      const newVertices: ConnectorVertex[] = [ConnectorPorts.LEFT, ConnectorPorts.RIGHT].includes(
        ghostConnectorData.current.source.port,
      )
        ? [
            {
              direction: ConnectorDirectionTypes.HORIZONTAL,
              coordinate:
                ghostConnectorData.current.source.left +
                (ghostConnectorData.current.target.left - ghostConnectorData.current.source.left) / 2,
            },
            {
              direction: ConnectorDirectionTypes.VERTICAL,
              coordinate: ghostConnectorData.current.target.top,
            },
          ]
        : [
            {
              direction: ConnectorDirectionTypes.VERTICAL,
              coordinate:
                ghostConnectorData.current.source.top +
                (ghostConnectorData.current.target.top - ghostConnectorData.current.source.top) / 2,
            },
            {
              direction: ConnectorDirectionTypes.HORIZONTAL,
              coordinate: ghostConnectorData.current.target.left,
            },
          ];
      dispatch({
        type: DiagramActionTypes.UPDATE_GHOST_CONNECTOR,
        payload: {
          [ghostConnectorData.current.movingEndpointType]:
            ghostConnectorData.current[ghostConnectorData.current.movingEndpointType],
          vertices: newVertices,
        },
      });
    },
    [dispatch, fontSize, ghostConnectorData, initialCoords, selectedConnector],
  );

  const handleUpdateConnectorStart = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>, endpoint: ConnectorEndpoint) => {
      if (!selectedConnector) return;
      const { endpointType, otherEndpointType } =
        selectedConnector.source.id === endpoint.id
          ? { endpointType: ConnectorEndpointType.SOURCE, otherEndpointType: ConnectorEndpointType.TARGET }
          : { endpointType: ConnectorEndpointType.TARGET, otherEndpointType: ConnectorEndpointType.SOURCE };
      const endpointCoordinates = getEndpointCoordinates(selectedConnector[endpointType]);
      const otherEndpointCoordinates = getEndpointCoordinates(selectedConnector[otherEndpointType]);
      if (!endpointCoordinates || !otherEndpointCoordinates) return;

      initialCoords.current = {
        x: event.clientX,
        y: event.clientY,
      };
      ghostConnectorData.current = {
        [endpointType]: { ...endpoint, ...endpointCoordinates },
        [otherEndpointType]: { ...selectedConnector[otherEndpointType], ...otherEndpointCoordinates },
        movingEndpointType: endpointType,
        movingEndpointInitialCoords: endpointCoordinates,
      } as GhostConnectorData;
      document.body.classList.add('grabbing');
      document.addEventListener('mousemove', handleUpdateConnectorMove);
      document.addEventListener('mouseup', handleCreateConnectorEnd);
      dispatch({
        type: DiagramActionTypes.INIT_GHOST_CONNECTOR,
        payload: {
          [endpointType]: ghostConnectorData.current[endpointType],
          [otherEndpointType]: ghostConnectorData.current[otherEndpointType],
          vertices: selectedConnector.vertices,
        } as GhostConnector,
      });
    },
    [
      dispatch,
      getEndpointCoordinates,
      ghostConnectorData,
      handleCreateConnectorEnd,
      handleUpdateConnectorMove,
      initialCoords,
      selectedConnector,
    ],
  );

  const handlePortMouseUp = useCallback(
    (endpoint: ConnectorEndpoint) => {
      if (!ghostConnectorData.current || (!ghostConnector?.source && !ghostConnector?.target)) return;
      const newSource =
        ghostConnectorData.current.movingEndpointType === ConnectorEndpointType.TARGET
          ? (ghostConnector.source as ConnectorEndpoint)
          : endpoint;
      const newTarget =
        ghostConnectorData.current.movingEndpointType === ConnectorEndpointType.TARGET
          ? endpoint
          : (ghostConnector.target as ConnectorEndpoint);
      connectorPortMouseUp.current = true;
      if (tool === Tool.CONNECT) createConnector(newSource, newTarget);
      else if (tool === Tool.NONE && selectedConnector) {
        if (
          selectedConnector?.source?.id === newSource?.id &&
          selectedConnector?.target?.id === newTarget?.id &&
          selectedConnector?.source?.port === newSource?.port &&
          selectedConnector?.target?.port === newTarget?.port
        ) {
          return;
        }

        updateConnector(newSource, newTarget);
      }
    },
    [
      connectorPortMouseUp,
      createConnector,
      ghostConnector?.source,
      ghostConnector?.target,
      ghostConnectorData,
      selectedConnector,
      tool,
      updateConnector,
    ],
  );

  const handlePortMouseDown = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>, endpoint: ConnectorEndpoint) => {
      if (tool === Tool.CONNECT && !ghostConnector?.source) handleCreateConnectorStart(event, endpoint);
      else if (tool === Tool.NONE) {
        shouldPreventClick.current = true;
        event.stopPropagation();
        handleUpdateConnectorStart(event, endpoint);
      }
    },
    [ghostConnector?.source, handleCreateConnectorStart, handleUpdateConnectorStart, tool, shouldPreventClick],
  );

  useEffect(() => {
    if (ghostConnector) dispatch({ type: DiagramActionTypes.RESET_GHOST_CONNECTOR });
  }, [tool]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    createConnector,
    deleteConnector,
    deleteSymbolConnectors,
    handlePortMouseDown,
    handlePortMouseUp,
    selectedConnector,
    sourceCoordinates,
    getEndpointCoordinates,
    recalculateLastVertex,
    targetCoordinates,
    updateConnectorVertices,
    splitConnector,
    updateLastVertices,
    updateLastVertex,
    checkSplitConnector,
    updateConnectorsShape,
    updateSwimlanesConnectorsVertices,
  };
}
