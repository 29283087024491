import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import ErrorTooltip from 'components/UI/ErrorTooltip/ErrorTooltip';
import FieldTooltip from 'components/UI/FieldTooltip/FieldTooltip';

import styles from './Dropdown.module.scss';

type Props = {
  className?: string;
  code: string;
  description?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label?: string;
  onChange: (option: string) => void;
  options: Array<string>;
  optionTranslationKey?: string;
  panelClassName?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
};

const Dropdown = (props: Props) => {
  const {
    className = '',
    code,
    description,
    disabled,
    error,
    id,
    label,
    onChange,
    options,
    optionTranslationKey,
    panelClassName = '',
    placeholder,
    required,
    value,
    ...other
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const hasError = Boolean(error);

  const getTranslation = (option: string) =>
    t(`${optionTranslationKey || `attributes.${code}.options`}.${option}`, { defaultValue: option });

  return (
    <div className={`${styles.Container} ${className}`}>
      {label && (
        <div className={styles.LabelContainer}>
          <label className={`${styles.Label} ${hasError ? styles.Error : ''}`} htmlFor={id}>
            {label}
            {required ? <span className="error">*</span> : ''}
          </label>
          {description && <FieldTooltip description={description} />}
          <ErrorTooltip message={error} />
        </div>
      )}
      <div
        className={`${styles.Input} ${styles.Dropdown} ${isOpen ? styles.Open : ''} ${hasError ? styles.Error : ''} ${
          disabled ? styles.Disabled : ''
        }`}
        id={id}
        onClick={() => setIsOpen(!isOpen)}
        ref={boxRef}
        {...other}
      >
        {value ? getTranslation(value) : placeholder || label}
        <i className={`fas fa-chevron-down ${styles.Icon}`} />
        {isOpen && (
          <DropdownPanel close={() => setIsOpen(false)} parentRef={boxRef}>
            <div className={`${styles.Options} ${panelClassName}`}>
              {options.map((option) => (
                <div
                  className={`${styles.Option} ${option === value ? styles.Selected : ''}`}
                  id={option}
                  key={option}
                  onClick={() => onChange(option)}
                >
                  {getTranslation(option)}
                  {option === value && <i className={`fas fa-check-circle ${styles.Icon}`} />}
                </div>
              ))}
            </div>
          </DropdownPanel>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
