import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  ATTRIBUTE_CARES,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SECONDARY_LEFT,
  CATALOG_OBJECT_ACTIONS,
  CATALOG_OBJECT_CREATION,
  CATALOG_OBJECT_ID_BY_TYPE,
  CATALOG_OBJECT_REJECT,
  FIELD_TYPES,
  IO_SWIMLANE,
  IT_SYSTEM_SWIMLANE,
  NEPOS_OBJECTS,
  NOT_TRANSLATABLE,
  ROLE_SWIMLANE,
} from 'assets/constants/constants';
import { purify } from 'assets/js/Utils';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import DialogMultilanguage from 'components/UI/DialogMultilanguage/DialogMultilanguage';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import Spinner from 'components/UI/Spinner/Spinner';
import useBackendForm from 'hooks/useBackendForm';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useForm from 'hooks/useForm';
import useFormTypes from 'hooks/useFormTypes';
import { approveObjectCatalog, createObjectCatalog, updateObjectCatalog } from 'services/design';
import objectCatalogService from 'services/objectCatalogService';
import { DialogType } from 'types/dialogs';

import styles from './DialogObjectCatalog.module.scss';

const SUBMIT_SERVICES_LEGACY = {
  [CATALOG_OBJECT_ACTIONS.CREATE]: objectCatalogService.createObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.EDIT]: objectCatalogService.modifyObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.MANAGE]: objectCatalogService.approveObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.REQUEST]: objectCatalogService.createRequestObjectCatalog,
};

const SUBMIT_SERVICES = {
  [CATALOG_OBJECT_ACTIONS.CREATE]: createObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO]: createObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.EDIT]: updateObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.MANAGE]: approveObjectCatalog,
  [CATALOG_OBJECT_ACTIONS.REQUEST]: createObjectCatalog,
};

const DialogObjectCatalog = (props) => {
  const {
    close,
    dialogType,
    object,
    objectType,
    onSubmit = () => {},
    idTask,
    isNEPOS,
    parentCallback,
    pendingObjects,
    showRequestedRoles,
    isLegacy = !NEPOS_OBJECTS.includes(objectType),
    catalogUsers,
    responseNeeded = false,
  } = props;
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { handleServiceError } = useError();
  const { fetchFormTypes, getFormTypesCode, isLoading: isFormTypesLoading, ...formTypesContext } = useFormTypes();
  const [loading, setLoading] = useState(true);
  const { isFreezed } = useFeatureFlags();

  const formTypesCode = useMemo(
    () =>
      getFormTypesCode({
        type: CATALOG_OBJECT_CREATION,
        variant: objectType,
        actionType: dialogType === CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO ? CATALOG_OBJECT_ACTIONS.CREATE : dialogType,
      }),
    [dialogType, getFormTypesCode, objectType],
  );
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [isRejectDialogVisible, setIsRejectDialogVisible] = useState(false);
  const objectName = t(objectType);
  const actions = {
    [CATALOG_OBJECT_ACTIONS.EDIT]: {
      btnText: t('save'),
      titleModal: t('catalog.editNewX', { labelX: objectName }),
    },
    [CATALOG_OBJECT_ACTIONS.CREATE]: {
      btnText: t('create'),
      titleModal: t('catalog.createNewX', { objectType: objectName }),
    },
    [CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO]: {
      btnText: t('create'),
      titleModal: t('catalog.createNewX', { objectType: objectName }),
    },
    [CATALOG_OBJECT_ACTIONS.MANAGE]: {
      btnText: t('approve'),
      titleModal: t('requestX', { labelX: objectName }),
    },
    [CATALOG_OBJECT_ACTIONS.REQUEST]: {
      btnText: t('Request'),
      titleModal: t('requestX', { labelX: objectName }),
    },
  };

  const getObjectAttributes = () => {
    if (!object || ![CATALOG_OBJECT_ACTIONS.EDIT, CATALOG_OBJECT_ACTIONS.MANAGE].includes(dialogType)) return;

    const parsedAttributes = typeof object.attributes === 'string' ? JSON.parse(object.attributes) : object.attributes;

    if (dialogType === CATALOG_OBJECT_ACTIONS.MANAGE && object.requestedByCommonName) {
      object.attributes[NOT_TRANSLATABLE].NAME_REQUESTER = `${object.requestedByCommonName} (${object.requestedBy})`;
    }

    return parsedAttributes;
  };

  const { fields, Form, isLanguageValid, isValid, purifyForm, values } = useBackendForm({
    formCode: formTypesCode,
    initialValues: getObjectAttributes(),
    isLegacy,
    catalog: catalogUsers,
  });

  const rejectForm = useForm({
    formTypes: {
      [i18n.language]: {
        REJECT_REASON: {
          care: ATTRIBUTE_CARES.MANDATORY,
          label: '',
          placeholder: t('textinputDialog.reason'),
          type: FIELD_TYPES.TEXT,
        },
      },
    },
    formCode: CATALOG_OBJECT_REJECT,
  });

  const handleSubmit = async () => {
    try {
      setIsRequestLoading(true);
      const attributes = purifyForm(values);
      if (!attributes) return;
      const data = {
        attributes: JSON.stringify(attributes),
        idObjectType: CATALOG_OBJECT_ID_BY_TYPE[objectType],
      };

      let successData = [];

      if (isLegacy && objectType !== ROLE_SWIMLANE && objectType !== IT_SYSTEM_SWIMLANE) {
        await SUBMIT_SERVICES_LEGACY[dialogType]({ data, id: object?.id, idTask });
      } else if (dialogType === CATALOG_OBJECT_ACTIONS.MANAGE) {
        await SUBMIT_SERVICES[dialogType](object.id, idTask, object.type, attributes);
      } else {
        const request = await SUBMIT_SERVICES[dialogType](
          {
            attributes,
            ...(objectType !== IO_SWIMLANE && { responsibleArea: attributes[NOT_TRANSLATABLE].RESPONSIBLE_AREA }),
            type: CATALOG_OBJECT_ID_BY_TYPE[objectType],
            idSourceDiagram: id,
          },
          object ? object.id : (dialogType === CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO || dialogType === 'REQUEST') && true,
        );

        successData =
          request.status === 200
            ? {
                ...request.data,
                id: request.data.id,
                type: CATALOG_OBJECT_ID_BY_TYPE[objectType],
                attributes,
              }
            : [];
      }

      onSubmit(responseNeeded ? successData : []);

      if (dialogType === CATALOG_OBJECT_ACTIONS.REQUEST) {
        setShowWarningDialog(true);
      } else {
        close();
      }
    } catch (error) {
      handleServiceError(error);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsRequestLoading(true);
      const purifiedValue = purify(Object.values(rejectForm.values)[0].REJECT_REASON.trim());
      if (!purifiedValue) return;
      await objectCatalogService.rejectObjectCatalog(object.id, idTask, purifiedValue);
      onSubmit();
      close();
    } catch (error) {
      handleServiceError(error);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const buttons = [
    ...(dialogType === CATALOG_OBJECT_ACTIONS.REQUEST || dialogType === CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO
      ? [
          {
            buttonStyle: BUTTON_SECONDARY_LEFT,
            content: t('Back'),
            handleClick: parentCallback,
            key: 'back',
            id: 'btn-back',
          },
        ]
      : []),
    ...(dialogType === CATALOG_OBJECT_ACTIONS.MANAGE
      ? [
          {
            buttonStyle: BUTTON_SECONDARY,
            content: t('reject'),
            handleClick: () => setIsRejectDialogVisible(true),
            key: 'reject',
            id: 'btn-reject',
          },
        ]
      : []),
    ...(isNEPOS
      ? [
          {
            buttonStyle: BUTTON_PRIMARY,
            content: actions[dialogType].btnText,
            disabled: isFreezed || isRequestLoading || !isValid,
            handleClick: handleSubmit,
            isLoading: isRequestLoading,
            key: 'create',
            id: 'btn-create',
          },
          {
            buttonStyle: BUTTON_SECONDARY,
            content: t('cancel'),
            handleClick: close,
            key: 'cancel',
            id: 'btn-cancel',
          },
        ]
      : [
          {
            buttonStyle: BUTTON_SECONDARY,
            content: t('cancel'),
            handleClick: close,
            key: 'cancel',
            id: 'btn-cancel',
          },
          {
            buttonStyle: BUTTON_PRIMARY,
            content: actions[dialogType].btnText,
            disabled: isFreezed || !isValid,
            handleClick: handleSubmit,
            isLoading: isRequestLoading,
            key: 'create',
            id: 'btn-create',
          },
        ]),
  ];

  const rejectButtons = [
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: isRequestLoading,
      handleClick: () => setIsRejectDialogVisible(false),
      key: 'cancel',
      id: 'btn-cancel',
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('submit'),
      disabled: isFreezed || !rejectForm.isValid,
      handleClick: handleReject,
      isLoading: isRequestLoading,
      key: 'submit',
      id: 'btn-submit',
    },
  ];

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length) return;
    setLoading(false);
  }, [fields, values]);

  useEffect(() => {
    if (isFormTypesLoading || formTypesContext[formTypesCode]) return;
    fetchFormTypes(
      getFormTypesCode({
        isGroupFetch: true,
        type: CATALOG_OBJECT_CREATION,
        variant: objectType,
        actionType: dialogType === CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO ? CATALOG_OBJECT_ACTIONS.CREATE : dialogType,
      }),
    );
  }, [dialogType, fetchFormTypes, formTypesCode, formTypesContext, getFormTypesCode, isFormTypesLoading, objectType]);

  return isFormTypesLoading || !fields || loading || !Object.keys(fields).length ? (
    <div className={styles.Spinner}>
      <Spinner defaultSpinner isVisible />
    </div>
  ) : (
    <>
      {showWarningDialog ? (
        <DialogNEPOS
          dialog={{
            buttons: [
              {
                buttonStyle: BUTTON_PRIMARY,
                content: t('ok'),
                handleClick: close,
                id: 'ModalButton-OK',
                key: 'sucess',
                disabled: isFreezed,
              },
            ],
            title: t('success'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requestObjectOk', { objectType: t(`${objectType}`) })}
        </DialogNEPOS>
      ) : (
        <>
          <DialogMultilanguage
            buttons={buttons}
            isLanguageValid={isLanguageValid}
            isNEPOS={isNEPOS}
            objectType={objectType}
            pendingObjects={pendingObjects}
            showRequestedRoles={showRequestedRoles}
            title={actions[dialogType].titleModal}
          >
            <Form className={styles.Form} fields={fields} />
          </DialogMultilanguage>
          {isRejectDialogVisible && (
            <Dialog className={styles.RejectDialog}>
              <DialogHeader className={styles.Header}>
                <i className={`fas fa-times ${styles.Icon}`} />
                {t('textinputDialog.headerRequest')}
              </DialogHeader>
              <div className={styles.Form}>
                <p>
                  {t('textinputDialog.message', {
                    dialogType: t('textinputDialog.requested', { objectType: t(objectType) }),
                    labelName: object.attributes[i18n.language].OBJECT_NAME,
                  })}
                </p>
                <rejectForm.Form fields={rejectForm.fields} />
              </div>
              <DialogFooter buttons={rejectButtons} />
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default DialogObjectCatalog;
