import * as Constants from 'assets/constants/constants';
import * as Utils from 'assets/js/Utils';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import services from 'services/dashboardServices';
import { getObject } from 'services/design';
import objectCatalogService from 'services/objectCatalogService';

import styles from '../Dashboard/Dashboard.module.scss';

export const statusChange = {
  1: 2,
  2: 4,
};

export const DESIGN_DIAGRAM = 'DESIGN_DIAGRAM';
export const DESIGN_ROLE = 'DESIGN_ROLE';
export const DESIGN_IO_SIPOC = 'DESIGN_IO_SIPOC';
export const DESIGN_IT_SYSTEM = 'DESIGN_IT_SYSTEM';
export const DESIGN_ROLE_SWIMLANE = 'DESIGN_ROLE_SWIMLANE';
export const DESIGN_IT_SYSTEM_SWIMLANE = 'DESIGN_IT_SYSTEM_SWIMLANE';
export const WORKFLOW_APPROVAL_GROUP = 'WORKFLOW_APPROVAL_GROUP';
export const WORKFLOW_APPROVAL = 'WORKFLOW_APPROVAL';
export const DATASET_WORKFLOW_APPROVAL_GROUP = 'DATASET_WORKFLOW_APPROVAL_GROUP';
export const DATASET_WORKFLOW_APPROVAL = 'DATASET_WORKFLOW_APPROVAL';
export const RECOMMENDED = 'RECOMMENDED';
export const REWORKED = 'REWORKED';
export const DELETED = 'DELETED';
export const RECOMMENDATION_LINK = 'RECOMMENDATION_LINK';
export const REQUIREMENT_REQUEST = 'REQUIREMENT_REQUEST';
export const REQUIREMENT_WORKFLOW = 'REQUIREMENT_WORKFLOW';
export const NEPOS_OBJECTS_TASKS = [DESIGN_IO_SIPOC, DESIGN_IT_SYSTEM_SWIMLANE, DESIGN_ROLE_SWIMLANE];

export const confirmButton = [
  {
    click: () => {
      this.hideDialogConfirm();
    },
    buttonModel: {
      content: 'Cancel',
      cssClass: 'buttonCancel',
    },
  },
  {
    click: () => {
      this.restoreElement();
    },
    buttonModel: {
      content: 'Restore',
      cssClass: 'buttonOk',
      isPrimary: true,
    },
  },
];

export const isItSystem = (idObjectType) =>
  idObjectType === Constants.IT_SYSTEM_OBJECT_TYPE || idObjectType === Constants.IT_SYSTEM_SWIMLANE;

export const getTaskToApproveLegacy = (task) =>
  objectCatalogService
    .getObject(task.link)
    .then((response) => {
      const infoTask = response.data;
      infoTask.attributes = JSON.parse(infoTask.attributes);

      return {
        infoTask,
        isItSystem: isItSystem(infoTask.idObjectType),
        taskSelected: task,
      };
    })
    .catch((err) => {
      objectCatalogService.handleServiceError(err);
    });

export const getTaskToApprove = (task) =>
  getObject(task.link)
    .then((response) => {
      const infoTask = response.data;

      return {
        infoTask,
        isItSystem: isItSystem(infoTask.idObjectType) || isItSystem(infoTask.type),
        taskSelected: task,
      };
    })
    .catch((err) => {
      objectCatalogService.handleServiceError(err);
    });

export const reloadTasks = (data, task, allTasks) => {
  const newTasks = [...allTasks];
  const taskIndex = newTasks.findIndex((elem) => elem.id === data);
  newTasks.splice(taskIndex, 1, Utils.formatTask(task));

  return newTasks;
};

export const acceptTask = (task, allTasks, taskTabSelected) => {
  if (task.status === Constants.PENDING) {
    if (task.type === DESIGN_IT_SYSTEM || task.type === DESIGN_ROLE) {
      return getTaskToApproveLegacy(task);
    }

    if (task.type === DESIGN_IO_SIPOC || task.type === DESIGN_IT_SYSTEM_SWIMLANE || task.type === DESIGN_ROLE_SWIMLANE) {
      return getTaskToApprove(task);
    }

    if (
      task.type.includes(WORKFLOW_APPROVAL_GROUP) ||
      taskTabSelected === Constants.TASK_GROUPS.GROUP_TASKS ||
      task.type.includes(REQUIREMENT_WORKFLOW)
    ) {
      const data = {
        idTask: task.id,
        taskStatusTypeCode: Constants.ACCEPTED,
      };

      return services
        .updateTask(data)
        .then((response) => {
          if (response.data) {
            return reloadTasks(response.data, task, allTasks);
          }
        })
        .catch((err) => {
          services.handleServiceError(err);
        });
    }
  }
};

export const deleteObjectCatalog = (tasks, id) => [...tasks].filter((task) => task.id !== id);

export const approveITSystem = (objectInfo, objectTask, task, t) => {
  objectInfo.RESPONSIBLE_AREA = objectInfo.RESPONSIBLE_AREA.value || objectInfo.RESPONSIBLE_AREA;
  const body = {
    attributes: JSON.stringify(Utils.prepareRequestObjectCatalog(objectInfo)),
    idObjectType: objectTask.idObjectType,
  };

  return objectCatalogService.approveObjectCatalog(task.link, task.id, body).then((response) => {
    if (response.data) {
      Dialog.showAlert({
        name: `<span class='${styles.DialogApprovedTitle}'>
          <i class='fas fa-check ${styles.Check}'></i>${t('approvedRequest')}</span>`,
        message: `${t('approvedRequestContent', {
          name: objectInfo.OBJECT_NAME_EN,
          type: isItSystem(objectTask.idObjectType) ? t('IT_SYSTEM') : t('ROLE'),
        })}`,
        isError: false,
      });
    }
  });
};

export const confirmReject = (reason, task) => objectCatalogService.rejectObjectCatalog(task.link, task.id, reason);
