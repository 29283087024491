import React from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { SelectionButton } from 'types/symbols';

import styles from './SelectionActions.module.scss';

const tooltipClass = 'mbc-tooltip nepos-tooltip';

type Props = {
  buttons: SelectionButton[];
  paletteButtonRef?: React.RefObject<HTMLDivElement>;
  className?: string;
};

const SelectionActions = ({ buttons, paletteButtonRef, className }: Props) => {
  return (
    <div
      className={`${styles.Container} ${className}`}
      onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
      ref={paletteButtonRef || null}
    >
      {buttons.map((button) => {
        if (button.isHidden) return;

        return (
          <>
            {button.extraClass === 'Separator' && <div className={styles.VerticalLine} />}
            {button.children ? (
              button.children
            ) : (
              <button
                className={`${styles.Button} ${button.selected ? styles.Selected : ''} ${button.extraClass}`}
                data-qa={button.id}
                id={button.id}
                key={button.icon}
                onClick={button.onClick}
                type="button"
              >
                <TooltipComponent
                  content={button.tooltipLabel}
                  cssClass={`${tooltipClass} ${button.tooltipLabel ? '-' : 'display-none'}`}
                  key={button.id}
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  <i className={`${button.icon} ${styles.IconButton}`} />
                </TooltipComponent>
              </button>
            )}
          </>
        );
      })}
    </div>
  );
};
export default SelectionActions;
