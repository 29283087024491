import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, CATALOG_OBJECT_ACTIONS } from 'assets/constants/constants';
import { getDangerouslySetInnerHTML } from 'assets/js/Utils';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import DialogInput from 'components/UI/DialogInput/DialogInput';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import useLocalization from 'hooks/useLocalization';
import useResize from 'hooks/useResize';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import { findAllByObjectType } from 'services/design';
import hasSwimlaneActivity from 'services/swimlaneService';
import { ObjectTypes, Role } from 'types/administration';
import { ButtonProps, Language } from 'types/config';
import { ResizeHandles } from 'types/diagram';
import { Attributes } from 'types/forms';
import { ProcessRole } from 'types/processes';
import { Pagination, TablePrefix, TableColumn, TableVariant } from 'types/tables';

import SelectionActions from './SelectionActions';
import SelectionPanel from './SelectionPanel';
import styles from './SwimlaneSelection.module.scss';

type Props = {
  position: number;
  swimlaneId: string;
};

const SwimlaneSelection = ({ position, swimlaneId }: Props) => {
  const { clearSelection } = useSelection();
  const { processData, selectedSymbolIds, diagramLanguage, dispatch } = useDiagramContext();
  const { t } = useTranslation();
  const [isShowWarningDeleteOpen, setIsShowWarningDeleteOpen] = useState(false);
  const paletteButtonRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [showTemporaryRoleDialog, setShowTemporaryRoleDialog] = useState(false);
  const { deleteSelectedSwimlane, setTemporaryRole, setRole, createSwimlaneBelow } = useSwimlane();
  const [showRequestRoleDialog, setShowRequestRoleDialog] = useState(false);
  const [rolesResult, setRolesResult] = useState<Role[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const { showAlert } = useError();
  const { handleResizeSwimlane } = useResize();

  const { availableLanguages } = useLocalization();

  const [roles, setRoles] = useState<{ [key in Language]?: (ProcessRole & { id: number })[] }>({});
  const availableRoles = roles[diagramLanguage] || undefined;

  const [rolesToAdd, setRolesToAdd] = useState<string[]>([]);
  const hasStartEvent = useMemo(
    () =>
      processData?.swimlanes &&
      processData.swimlanes
        .find((s) => s.id === swimlaneId)
        ?.symbols.some((symbol) => symbol.attributes?.NOT_TRANSLATABLE?.START_EVENT === 'true'),
    [processData?.swimlanes, swimlaneId],
  );

  useEffect(() => {
    setSearchText('');
    setPage(0);
    setTotalPages(1);
  }, [showRoleDialog]);

  const selectedSwimlane = processData?.swimlanes?.find((swimlane) => swimlane.id === swimlaneId);

  const handleDeleteSwimlane = () => {
    if (!processData) return;

    if (selectedSwimlane && hasSwimlaneActivity(selectedSwimlane)) return showAlert(t('errors.DESIGN_043'));
    if (selectedSwimlane?.symbols && selectedSwimlane.symbols.length > 0) {
      setIsShowWarningDeleteOpen(true);
    } else {
      deleteSelectedSwimlane(swimlaneId);
    }
  };

  const buttons = [
    {
      icon: 'di icon-plus-hinzufuegen',
      id: 'swimlane-add-role-button',
      onClick: () => setIsMenuOpen(!isMenuOpen),
      tooltipLabel: t('addX', { labelX: t('ROLE') }),
    },
    {
      icon: 'di icon-zeilen-hinzufuegen',
      id: 'swimlane-add-swimlane-button',
      onClick: () => createSwimlaneBelow(position),
      tooltipLabel: t('swimlanes.tools.addLane'),
    },
    {
      icon: 'di icon-muelleimer-loeschen',
      id: 'swimlane-delete-button',
      onClick: () => selectedSymbolIds?.length === 1 && handleDeleteSwimlane(),
      tooltipLabel: t('swimlanes.tools.deleteLane'),
    },
  ];

  const fetchRoles = async (newPage: number = 0, search: string = '') => {
    if (!processData?.type) return;
    setPage(newPage);
    setSearchText(search);

    try {
      const { data } = await findAllByObjectType(ObjectTypes.ROLE_SWIMLANE, {
        page: newPage,
        ...(search ? { search } : {}),
        size: 20,
      });

      setRolesResult(data.results);
      const rolesData = availableLanguages.reduce((acc: Attributes, lang: Language) => {
        const results = data.results.map((elem: Role) => {
          return {
            id: elem.id,
            name: elem.attributes?.[lang]?.OBJECT_NAME,
            description: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.OBJECT_DESCRIPTION)}
              />
            ),
            tasks: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.TASKS)}
              />
            ),
            status: elem.approved ? '' : t('REQUESTED'),
          };
        });
        setTotalPages(data.numberOfPages);
        return {
          ...acc,
          [lang]: results,
        };
      }, {});
      setRoles(rolesData);
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const handleRoleClick = (setShowDialog: Function) => (event: React.MouseEvent) => {
    event.stopPropagation();
    clearSelection();
    setIsMenuOpen(false);
    setShowDialog(true);
    fetchRoles();
  };

  const roleOptions = [
    {
      id: 'contextualMenu.ROLE',
      icon: 'icon-person',
      click: handleRoleClick(setShowRoleDialog),
    },
    {
      id: 'contextualMenu.TEMPORARY_ROLE',
      icon: 'icon-person-konturlinie-angefragt',
      click: handleRoleClick(setShowTemporaryRoleDialog),
    },
  ];

  const checkDuplicatedRole = (selectedRole) => {
    let duplicatedRole;
    selectedSwimlane?.symbols?.forEach((elem) => {
      duplicatedRole = elem.objects?.find((obj) => obj.id === selectedRole.id);
    });
    return !!duplicatedRole;
  };

  const roleDialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !rolesToAdd.length,
      id: `add-real-role-submit-button`,
      handleClick: () => {
        setShowRoleDialog(false);
        setSearchText('');
        const selectedRole = rolesResult.find((elem) => elem.id === +rolesToAdd[0]);
        setRolesToAdd([]);
        if (selectedRole && selectedRole.id) {
          if (processData?.swimlanes?.find((elem) => elem.role?.id === selectedRole.id)) {
            showAlert(t('swimlane.dialog.duplicateRoleWarning'), t('warningText'));
          } else if (checkDuplicatedRole(selectedRole)) {
            showAlert(t('swimlane.processStep.duplicateObject.roleItSystem'), t('warningText'));
          } else {
            setRole(swimlaneId, selectedRole);
          }
        }
      },
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: 'real-role-cancel-button',
      handleClick: () => {
        setShowRoleDialog(false);
        setSearchText('');
      },
      key: 'cancel',
    },
  ];

  const roleColumns: TableColumn<ProcessRole>[] = [
    { id: 'name', style: styles.Name },
    { id: 'description', style: styles.Description },
    { id: 'tasks', style: styles.Tasks },
    { id: 'status', style: styles.Status },
  ];

  const availableRolesRows = useMemo(
    () =>
      !availableRoles
        ? undefined
        : availableRoles.filter((elem) => elem.name?.toLowerCase().includes(searchText.toLowerCase())) || [],
    [availableRoles, searchText],
  );

  return (
    <>
      {selectedSymbolIds?.includes(swimlaneId) ? (
        <>
          <svg
            className={`${styles.SelectionHandler} ${styles.Right}`}
            key={`handler-r-${swimlaneId}`}
            onMouseDown={(event) => handleResizeSwimlane(event, ResizeHandles.CENTER_RIGHT)}
            viewBox="0 0 100 100"
          />
          <svg
            className={`${styles.SelectionHandler} ${styles.Bottom}`}
            key={`handler-b-${swimlaneId}`}
            onMouseDown={(event) => handleResizeSwimlane(event, ResizeHandles.BOTTOM_CENTER)}
            viewBox="0 0 100 100"
          />

          {selectedSymbolIds?.includes(swimlaneId) && selectedSymbolIds.length === 1 && !processData?.isOnlyRead && (
            <>
              <SelectionActions
                buttons={position <= 0 || hasStartEvent ? buttons.slice(0, -1) : buttons}
                paletteButtonRef={paletteButtonRef}
              />
              {isMenuOpen && (
                <SelectionPanel close={() => setIsMenuOpen(false)} options={roleOptions} parentRef={paletteButtonRef} />
              )}
            </>
          )}
        </>
      ) : null}

      {isShowWarningDeleteOpen && (
        <DialogNEPOS
          dialog={{
            buttons: [
              {
                buttonStyle: BUTTON_PRIMARY,
                content: t('ok'),
                handleClick: () => {
                  deleteSelectedSwimlane(swimlaneId);
                },
                key: 'submit',
              },
              {
                buttonStyle: BUTTON_SECONDARY,
                content: t('cancel'),
                disabled: false,
                handleClick: () => setIsShowWarningDeleteOpen(false),
                key: 'cancel',
              },
            ],
            title: t('warningText'),
          }}
          extraClass="Modal"
        >
          <p>{t('swimlane.dialog.warning.delete')}</p>
        </DialogNEPOS>
      )}

      {showTemporaryRoleDialog && (
        <DialogInput
          close={() => setShowTemporaryRoleDialog(false)}
          confirm={(roleName: string) => {
            setShowTemporaryRoleDialog(false);
            setTemporaryRole(swimlaneId, roleName);
          }}
          label={t('contextualMenu.TEMPORARY_ROLE')}
          title={t('addX', { labelX: t('contextualMenu.TEMPORARY_ROLE') })}
        />
      )}
      {showRoleDialog && (
        <DialogNEPOS
          dialog={{
            title: t('swimlane.role.dialog.title'),
            buttons: roleDialogButtons,
          }}
        >
          <TableWithSearch
            buttonText={t('RequestNewObject', {
              objectType: t('ROLE'),
            })}
            columns={roleColumns}
            getPage={(newPage) => fetchRoles(newPage, searchText)}
            id={TablePrefix.ROLE}
            isAsync
            isLoading={availableRolesRows === undefined}
            multiselect={false}
            onCheck={setRolesToAdd}
            onClick={() => {
              setShowRequestRoleDialog(true);
              setShowRoleDialog(false);
            }}
            page={page}
            pagination={Pagination.PAGES}
            rows={availableRolesRows}
            searching={(text: string) => {
              setTotalPages(1);
              fetchRoles(0, text);
            }}
            totalPages={totalPages}
            variant={TableVariant.CHECKBOX}
          />
        </DialogNEPOS>
      )}
      {showRequestRoleDialog && (
        <DialogObjectCatalog
          close={() => setShowRequestRoleDialog(false)}
          dialogType={CATALOG_OBJECT_ACTIONS.REQUEST}
          isLegacy={false}
          isNEPOS
          objectType={ObjectTypes.ROLE_SWIMLANE}
          onSubmit={(createdObject: Role) => {
            setRole(swimlaneId, createdObject);
          }}
          parentCallback={() => {
            setShowRoleDialog(true);
            setShowRequestRoleDialog(false);
          }}
          responseNeeded
        />
      )}
    </>
  );
};

export default SwimlaneSelection;
