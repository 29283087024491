import React, { useState, useEffect, useCallback, useRef } from 'react';

import { withTranslation } from 'react-i18next';

import userService from '../../services/userService';
import Search from '../UI/Search/Search';
import styles from './WorkflowDialogExplorer.module.scss';

const WorkflowDialogExplorerScopes = (props) => {
  const { t, scopes, patchValue, selected = [] } = props;
  const [searchText, setSearchText] = useState('');
  const [scopesList, setScopesList] = useState([]);
  const [page, setPage] = useState(0);
  const containerRef = useRef(null);
  let typingTimeout = 0;

  const selectScope = useCallback(
    (scope) => () => {
      const isDelete = selected.some((elem) => elem.Code === scope.id);
      let newScopes = [...selected];

      if (isDelete) {
        newScopes = newScopes.filter((elem) => elem.Code !== scope.id);
      } else {
        const user = scope.firstIncumbentUser || scope.secondIncumbentUser || 'N.N.';

        newScopes.push({
          Code: scope.id,
          Name: `${scope.abbreviation} - ${scope.description} (${user})`,
        });
      }
      patchValue({ SCOPES: newScopes });
    },
    [patchValue, selected],
  );

  const getFilteredScopes = useCallback(
    (newPage = 0) => {
      const params = {
        page: newPage,
        pageSize: 50,
        searchString: searchText,
        sortDir: 'ASC',
      };
      userService
        .getScopesByName(params)
        .then((res) => {
          const newScopes = res.data.results.map((scope) => ({
            id: scope.departmentId,
            abbreviation: scope.abbreviation,
            description: JSON.parse(scope.description)[props.i18n.language.toUpperCase()],
            firstIncumbentUser: scope.firstIncumbentUser,
            secondIncumbentUser: scope.secondIncumbentUser,
            selected: selected.some((elem) => elem.Code === scope.departmentId),
          }));
          setScopesList(newPage === 0 ? newScopes : scopesList.concat(newScopes));
        })
        .catch((err) => {
          userService.handleServiceError(err);
        });
    },
    [props.i18n.language, scopesList, searchText, selected],
  );

  const handleSearch = () => {
    containerRef.current.scrollTop = 0;
    setPage(0);
    getFilteredScopes(0);
  };

  const refresh = () => {
    containerRef.current.scrollTop = 0;
    setScopesList(scopes);
  };

  useEffect(() => (searchText ? handleSearch() : refresh()), [scopes, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapScopes = useCallback(() => {
    return scopesList.map((elem) => {
      let scopeName = `${elem.abbreviation} - ${elem.description} (`;
      let user = 'N.N.';

      if (elem.firstIncumbentUser) {
        user = elem.firstIncumbentUser;
      } else if (elem.secondIncumbentUser) {
        user = elem.secondIncumbentUser;
      }

      scopeName += `${user})`;

      return (
        <li
          className={selected.some((scope) => scope.Code === elem.id) ? styles.Selected : ''}
          key={`list_${elem.id}`}
          onMouseDown={selectScope(elem)}
          title={scopeName}
        >
          <span>{scopeName}</span>
          <i className="di icon-schliessen" />
        </li>
      );
    });
  }, [scopesList, selectScope, selected]);

  const searchScopesText = (text) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    typingTimeout = setTimeout(() => {
      setSearchText(text.trim());
      if (text === '') setPage(0);
    }, 500);
  };

  const handleScroll = (e) => {
    e.preventDefault();
    const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
    if (bottom) {
      setPage(page + 1);
      getFilteredScopes(page + 1);
    }
  };

  return (
    <>
      <div className={styles.SearchInput}>
        <label htmlFor="search-scopes">{`${t('nameAttributes.SCOPES')}*`}</label>
        <Search id="search-scopes" searching={searchScopesText} />
      </div>
      <span className={styles.Advisor}>{`(${t('scopes.searchLimitAdvisor')})`}</span>
      <div className={styles.SearchResult}>
        <ul className={styles.List} onScroll={handleScroll} ref={containerRef}>
          {mapScopes()}
        </ul>
      </div>
    </>
  );
};

export default React.memo(
  withTranslation('common')(WorkflowDialogExplorerScopes),
  (prev, next) =>
    JSON.stringify(prev.scopes) === JSON.stringify(next.scopes) &&
    JSON.stringify(prev.selected) === JSON.stringify(next.selected),
);
