import React from 'react';

import { useTranslation } from 'react-i18next';

import ErrorTooltip from 'components/UI/ErrorTooltip/ErrorTooltip';
import FieldTooltip from 'components/UI/FieldTooltip/FieldTooltip';

import styles from './InputText.module.scss';

const InputText = (props) => {
  const { className, description, disabled, error, id, label, onChange, placeholder, required, touched, ...other } = props;
  const { t } = useTranslation();
  const hasError = Boolean(error);

  return (
    <div className={`${styles.Container} ${className || ''}`}>
      {label && (
        <div className={styles.LabelContainer}>
          <label className={`${styles.Label} ${hasError ? styles.Error : ''}`} htmlFor={id}>
            {label}
            {required ? '*' : ''}
          </label>
          {description && <FieldTooltip description={description} />}
          <ErrorTooltip message={error} />
        </div>
      )}
      <input
        className={`${styles.Input} ${hasError ? styles.Error : ''} ${disabled ? styles.Disabled : ''}`}
        disabled={disabled}
        id={id}
        onChange={(event) => onChange(event.target.value)}
        placeholder={disabled ? '-' : placeholder || t('fieldEnter', { field: label })}
        type="text"
        {...other}
      />
    </div>
  );
};

export default InputText;
