import React from 'react';

import onClickOutside from 'react-onclickoutside';

import { ATTRIBUTE_CARES } from 'assets/constants/constants';

import FieldTooltip from '../FieldTooltip/FieldTooltip';
import styles from './DropdownForm.module.scss';

class DropdownForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.meta.id,
      label: this.props.meta.label,
      placeholder: this.props.meta.placeholder,
      options: this.props.meta.options,
      attributeType: this.props.meta.attributeType,
      isOpen: false,
      extraClass: this.props.meta.extraClass,
    };
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  handleChange(value) {
    const newOptions = [...this.state.options];
    newOptions.map((elem) => {
      elem.selected = elem.value === value;

      return elem;
    });

    this.setState({
      options: newOptions,
    });

    this.props.setValue(value);
  }

  getSelectedText() {
    const selected = this.state.options.find((option) => option.selected);

    return selected ? selected.label || selected.text : this.state.placeholder;
  }

  toggleList() {
    if (!this.props.meta.disabled) {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    }
  }

  render() {
    const selection = this.getSelectedText();
    const isOpenClass = this.state.isOpen ? styles.Open : '';
    const isMandatory = this.state.attributeType === ATTRIBUTE_CARES.MANDATORY ? '*' : '';
    const isDisabled = this.props.meta.disabled || this.state.attributeType === ATTRIBUTE_CARES.SYSTEM;

    return (
      <div className={`form-group dropdown ${this.state.extraClass} `}>
        <label className={styles.Label} htmlFor={this.state.id}>
          {this.state.label}
          {isMandatory}
        </label>
        {this.props.meta.description && <FieldTooltip description={this.props.meta.description} />}
        <div
          className={`${styles.Wrapper} ${!isDisabled ? isOpenClass : ''}`}
          id={this.state.id}
          onClick={() => this.toggleList()}
        >
          <div className={`${styles.Header} ${isDisabled ? styles.Blocked : ''}`}>
            <span>{selection}</span>
            <i className="fas fa-chevron-down" />
          </div>
          <ul className={`${styles.List} ${isOpenClass}`}>
            {this.state.options.map((option) => (
              <li
                className={`${styles.ListItem} ${option.selected ? styles.Selected : ''}`}
                key={(option.id || option.value) + Math.random().toString()}
                onClick={() => this.handleChange(option.value)}
              >
                {option.text || option.label}
                <i className="fas fa-check-circle" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default onClickOutside(DropdownForm);
