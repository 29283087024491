import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import RoundButton from 'components/UI/RoundButton/RoundButton';
import { ActionIcon } from 'types/actionIcon';

import styles from './ActionBox.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  label?: string;
  onClick?: () => void;
  innerIcon?: ActionIcon;
};

const ActionBox = ({ id, children, className, label, onClick, innerIcon }: Props) => {
  return (
    <div className={`${styles.Box} ${className}`} data-qa={id} data-testid="ActionBox" id={id} onClick={onClick}>
      {label === undefined ? (
        <i className="di icon-plus-hinzufuegen" />
      ) : (
        <div className={styles.Content}>
          <TooltipComponent content={label} cssClass="mbc-tooltip nepos-tooltip" showTipPointer={false}>
            <p>{label}</p>
          </TooltipComponent>
          {children}
          {innerIcon && (
            <div className={styles.Icon}>
              <RoundButton
                disabled={!!innerIcon.disabled}
                icon={innerIcon.icon}
                id={innerIcon.id}
                onClick={(event) => {
                  innerIcon.onClick();
                  event.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionBox;
