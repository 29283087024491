import { useState, useEffect } from 'react';

import useSymbol from 'hooks/useSymbol';
import { PostIt, PostItTypes } from 'types/symbols';

import styles from './E2EResponsible.module.scss';

const E2EResponsible = (props: { id: string; symbolId: string; value: string }) => {
  const { id, symbolId, value } = props;
  const [currentValue, setCurrentValue] = useState<string>(value);
  const { updatePostIt } = useSymbol();

  useEffect(() => {
    const newValue: PostIt = { id: '0', order: 0, text: currentValue };
    updatePostIt(PostItTypes.E2E_PROCESS_OWNER, newValue, symbolId);
  }, [currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.Container}>
      <span className={styles.Label}>E2E-Prozessverantwortlicher</span>
      <textarea
        className={styles.TextArea}
        id={id}
        maxLength={100}
        onChange={(event) => setCurrentValue(event.target.value)}
        rows={1}
        value={currentValue}
      />
    </div>
  );
};

export default E2EResponsible;
