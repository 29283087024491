import { CSSProperties, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { DELETE_BUTTON, DOWNLOAD_BUTTON, REDIRECT_BUTTON } from 'assets/constants/ActionButtons';
import * as Constants from 'assets/constants/constants';
import { OptionsLineIcon } from 'components/ShowMoreBox/OptionsLine/OptionsLine';
import ShowMoreBox, { ShowMoreElement } from 'components/ShowMoreBox/ShowMoreBox';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import useOuterClick from 'hooks/useOuterClick';
import { ActivityAditionalInformation } from 'types/activitySpecification';
import { Chip } from 'types/forms';

import styles from './InputTextChipsNEPOS.module.scss';

interface Props {
  className?: string;
  clearAll: (event: React.MouseEvent) => void;
  chips: Chip[] | Chip[][];
  disabled?: boolean;
  id: string;
  isEditing?: boolean;
  isModal?: boolean;
  label: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnClickChip?: (value: Chip) => void;
  handleRemoveChip: (value: Chip) => void;
  required?: boolean;
  value: string;
  reference?: React.RefObject<HTMLInputElement>;
  containerRef?: React.RefObject<HTMLLabelElement>;
  error?: string;
  onBlur?: () => void;
  placeholder: string;
  max: number;
  onClickPlusIcon?: () => void | string;
  tooltip?: string;
  isLegacy?: boolean;
}

const InputTextChipNEPOS = (props: Props) => {
  const {
    className,
    clearAll,
    chips,
    disabled,
    id,
    isEditing,
    isModal,
    label,
    onChange,
    placeholder,
    required,
    value,
    onKeyDown,
    reference,
    containerRef,
    error,
    onBlur,
    handleOnClickChip,
    handleRemoveChip,
    max,
    tooltip,
    onClickPlusIcon,
    isLegacy,
  } = props;

  const newInputRef = useRef<HTMLInputElement>(null);
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentTextInputRef = reference || newInputRef;
  const currentContainerRef = containerRef || newContainerRef;
  const [focused, setFocused] = useState(false);
  const isDoubleValueInput = Array.isArray(chips) && chips.some((item) => Array.isArray(item));
  const { t } = useTranslation();

  const hasError = Boolean(error);
  const isErrorTriggered = isLegacy ? hasError : hasError && !focused;
  const MAX_CHIPS_SHOWN = 3;

  const showPlaceholder = () =>
    (focused && (!chips || chips.length < max)) || (!focused && (!chips || (chips.length < max && chips.length !== 0)));

  const inputStyle = (): CSSProperties => {
    if (disabled || isDoubleValueInput) return { width: '100%' };
    return showPlaceholder() || isLegacy ? { width: `${placeholder?.length}ch` } : { width: `${value?.length + 1}ch` };
  };
  useOuterClick(currentContainerRef, () => {
    setFocused(false);
  });

  const handleContainerClick = () => {
    if (!currentTextInputRef.current) return;
    setFocused(true);
    currentTextInputRef.current.focus();
  };

  const onChangeInputText = (event: any) => {
    if (!disabled) onChange(event);
  };

  const onRemoveChip = (chip: Chip) => {
    handleRemoveChip(chip);
  };

  const iconsDisplay = (item: Chip) => {
    if (!isDoubleValueInput) {
      return item.idDiagramType === Constants.EPC_DIAGRAM_ID
        ? `di icon-hierarchie-diagramm ${styles.IconLegacy}`
        : `di icon-etikett-2 ${styles.IconLegacy} `;
    }
    return `di icon-link ${styles.IconLegacy} `;
  };

  const handleClick = (item: Chip) => handleOnClickChip && handleOnClickChip(item);

  const renderChip = (item: Chip) => (
    <span
      className={` ${isLegacy ? styles.LegacyChip : styles.Chip}  ${!handleOnClickChip ? '' : styles.Pointer}`}
      id={id}
      key={`chip-${item.displayName}`}
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick(item);
      }}
    >
      {isLegacy && <i className={`${iconsDisplay(item)} ${styles.IconLegacy}`} />}
      {!isLegacy && item.icon && <i className={`${item.icon} ${styles.ChipIcon}`} />}
      <span className={isLegacy ? styles.LegacyText : styles.Text}>{item.displayName || item.name}</span>
      {!(disabled && isDoubleValueInput) && (
        <i
          className={`fas fa-times-circle ${styles.Icon}`}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onRemoveChip(item);
          }}
        />
      )}
    </span>
  );

  const mapChipsToShowMoreElement = (chip: Chip): ShowMoreElement => {
    const isLink = chip.code === ActivityAditionalInformation.LINK;
    const actionButton = isLink ? REDIRECT_BUTTON : DOWNLOAD_BUTTON;
    const mainAction = {
      ...actionButton,
      id: `show-more-chips-${actionButton.id}`,
      key: chip.displayName,
      onClick: () => handleOnClickChip && handleOnClickChip(chip),
    };

    const deleteAction = {
      ...DELETE_BUTTON,
      id: `show-more-chips-${DELETE_BUTTON.id}`,
      key: chip.displayName,
      onClick: () => handleRemoveChip(chip),
    };

    const actions: OptionsLineIcon[] = disabled ? [] : [deleteAction];
    if (handleOnClickChip) actions.unshift(mainAction);

    return {
      value: chip.displayName || '',
      stateIcon: chip.icon,
      actions,
    };
  };

  const renderChips = (item: Chip | Chip[], index: number) => {
    const areArraysEmpty = (chips as Chip[][]).every((arr) => Array.isArray(arr) && arr.length === 0);

    return Array.isArray(item) ? (
      <div className={!areArraysEmpty ? styles.ChipsBlock : ''} key={index}>
        <div className={`${styles.ChipsWrapper} ${!areArraysEmpty ? styles.MinHeight : ''}`}>
          <div>{item.slice(0, MAX_CHIPS_SHOWN).map(renderChip)}</div>
          {item.length > MAX_CHIPS_SHOWN && (
            <ShowMoreBox
              className={styles.ChipsShowMoreButton}
              elements={item.slice(MAX_CHIPS_SHOWN).map(mapChipsToShowMoreElement)}
            />
          )}
        </div>
        {!areArraysEmpty && <hr className={index === 0 ? styles.LineSeparation : styles.InputMargin} />}
      </div>
    ) : (
      renderChip(item)
    );
  };

  return (
    <>
      {isLegacy && (
        <div
          className={`${styles.LegacyLabel} ${isEditing || value?.trim() !== '' || chips?.length !== 0 ? styles.Floating : ''} ${
            isErrorTriggered ? styles.Error : ''
          }`}
        >
          {label}
          {required && <span className="error">*</span>}
          {hasError && (
            <i className={`di ${isLegacy ? 'fas fa-exclamation-circle' : 'icon-blitz-fehler'} error ${styles.IconError}`} />
          )}
        </div>
      )}
      <label
        className={`${isLegacy ? styles.LegacyContainer : styles.Container}  ${focused ? styles.Focused : ''} ${
          disabled ? styles.Disabled : ''
        }  ${className || ''} ${isErrorTriggered ? styles.Error : ''}`}
        htmlFor={id}
        onClick={() => handleContainerClick()}
        ref={currentContainerRef}
      >
        {!isLegacy && (
          <div
            className={`${styles.Label} ${isDoubleValueInput ? styles.LabelDouble : ''}  ${
              isEditing || (!isDoubleValueInput && value?.trim() !== '') || chips?.length !== 0 ? styles.Floating : ''
            } ${isErrorTriggered ? styles.Error : ''}`}
          >
            {label}
            {required && <span className="error">*</span>}
            {hasError && <i className={`di icon-blitz-fehler error ${styles.IconError}`} />}
            {tooltip && (
              <TooltipComponent
                className="mbc-tooltip nepos-tooltip"
                content={t(tooltip)}
                cssClass="mbc-tooltip nepos-tooltip"
                position="BottomCenter"
                showTipPointer={false}
                target="#info"
              >
                <i className={`di icon-information ${styles.Info}`} id="info" />
              </TooltipComponent>
            )}
          </div>
        )}
        <div className={styles.Wrapper} style={{ cursor: isLegacy ? 'pointer' : '' }}>
          {(isDoubleValueInput || (!isDoubleValueInput && !disabled)) && Array.isArray(chips) && (
            <div className={styles.ColumnWrapper}>{chips.map(renderChips)}</div>
          )}
          {!disabled && (
              <RoundButton className={styles.Close} icon="icon-schliessen" id="close-process-attributes" onClick={clearAll} />
            ) &&
            (isModal || onClickPlusIcon) &&
            !isLegacy && (
              <RoundButton
                className={`${styles.Add} `}
                icon="icon-plus-hinzufuegen"
                id="add-chips-icon"
                onClick={onClickPlusIcon}
              />
            )}
        </div>
        {((isModal && disabled) || (!isDoubleValueInput && !isModal)) && (
          <div className={styles.Input} style={inputStyle()}>
            {disabled && ((Array.isArray(chips) && chips.length > 0) || value) ? (
              <TooltipComponent
                content={Array.isArray(chips) ? chips.map((item) => item.displayName || item).join(' • ') : value}
                cssClass="mbc-tooltip nepos-tooltip"
                position="TopCenter"
                showTipPointer={false}
              >
                <input
                  autoComplete="off"
                  className={styles.Input}
                  disabled={disabled || isModal}
                  id={id}
                  onBlur={onBlur}
                  onChange={(event) => onChangeInputText(event.target.value)}
                  onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : {})}
                  placeholder={showPlaceholder() || (isLegacy && chips.length === 0) ? placeholder : ''}
                  ref={currentTextInputRef}
                  type="text"
                  value={chips.map((item) => item.displayName || item).join(' • ')}
                />
              </TooltipComponent>
            ) : (
              <input
                autoComplete="off"
                className={styles.Input}
                disabled={disabled || isModal}
                id={id}
                onBlur={onBlur}
                onChange={(event) => onChangeInputText(event.target.value)}
                onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : {})}
                placeholder={showPlaceholder() || (isLegacy && chips.length === 0) ? placeholder : ''}
                ref={currentTextInputRef}
                type="text"
                value={value}
              />
            )}
          </div>
        )}
      </label>
    </>
  );
};

export default InputTextChipNEPOS;
