import { RefObject, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { LINK_RESPONSIBILITY_BY_ID } from 'assets/constants/constants';
import { getDangerouslySetInnerHTML } from 'assets/js/Utils';
import { setSwimlaneDisplayingAttributes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useOuterClick from 'hooks/useOuterClick';
import { ObjectTypes } from 'types/administration';
import { SymbolObject } from 'types/symbols';

import styles from './ProcessStepObjectAttributes.module.scss';

type Props = {
  data: SymbolObject;
  onClose: () => void;
  parentRef: RefObject<HTMLElement>;
};

const ProcessStepObjectAttributes = ({ data, onClose, parentRef }: Props) => {
  const { t } = useTranslation();
  const refContainer = useRef<HTMLDivElement>(null);

  const { diagramLanguage, dispatch } = useDiagramContext();
  const NEW = t('NEW');
  const REQUESTED = t('REQUESTED');
  const TEMPORARY = t('TEMPORARY');

  const statusLabelColor = (status: string) => {
    const Color: { [key: string]: string } = {
      [ObjectTypes.ROLE_SWIMLANE]: styles.Requested,
      [ObjectTypes.IT_SYSTEM_SWIMLANE]: styles.Requested,
      [ObjectTypes.IO_SWIMLANE]: styles.New,
    };

    return Color[status] || '';
  };

  const statusLabel = (status: string) => {
    const Color: { [key: string]: string } = {
      [ObjectTypes.ROLE_SWIMLANE]: REQUESTED,
      [ObjectTypes.IT_SYSTEM_SWIMLANE]: REQUESTED,
      [ObjectTypes.IO_SWIMLANE]: NEW,
    };

    return Color[status] || '';
  };

  const handleOuterClick = useCallback(
    (event) => {
      if (refContainer.current?.contains(event.target) || parentRef.current?.contains(event.target)) return;
      onClose();
      dispatch(setSwimlaneDisplayingAttributes(''));
    },
    [dispatch, onClose, parentRef],
  );
  useOuterClick(refContainer, handleOuterClick);

  return (
    <div className={styles.Container} ref={refContainer}>
      {!data.temporaryText && (
        <>
          <div className={styles.Section}>
            <div>
              <span className={styles.Title}>{t('OBJECT_NAME')}</span>
              <br />
              <div className={styles.Content}>
                <span>
                  {data.type === ObjectTypes.IO_SWIMLANE
                    ? data.attributes?.[diagramLanguage]?.NAME
                    : data.attributes?.[diagramLanguage]?.OBJECT_NAME}
                </span>
              </div>
            </div>
            {!data.approved && (
              <div className={styles.Status}>
                <span className={`${styles.Tag} ${statusLabelColor(data.type)}`}>{statusLabel(data.type)}</span>
              </div>
            )}
          </div>
          {data.type === ObjectTypes.ROLE_SWIMLANE && (
            <div className={styles.Section}>
              <div>
                <span className={styles.Title}>{t('RESPONSIBILITY')}</span>
                <br />
                <div>
                  <span className={styles.Content}>
                    {data.responsability
                      ? t(`responsibility.${(LINK_RESPONSIBILITY_BY_ID as { [key: number]: string })[data.responsability]}`)
                      : ''}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className={styles.Section}>
            <div>
              <span className={styles.Title}>{t('OBJECT_DESCRIPTION')}</span>
              <br />
              <div className={styles.Content}>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={getDangerouslySetInnerHTML(
                    data.type === ObjectTypes.IO_SWIMLANE
                      ? data.attributes?.[diagramLanguage]?.DESCRIPTION
                      : data.attributes?.[diagramLanguage]?.OBJECT_DESCRIPTION,
                  )}
                />
              </div>
            </div>
          </div>
          {data.type === ObjectTypes.ROLE_SWIMLANE && (
            <>
              <div className={styles.Section}>
                <div>
                  <span className={styles.Title}>{t('TASKS')}</span>
                  <br />
                  <div className={styles.Content}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={getDangerouslySetInnerHTML(data.attributes?.[diagramLanguage]?.TASKS)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.Section}>
                <div>
                  <span className={styles.Title}>{t('AUTHORITY')}</span>
                  <br />
                  <div className={styles.Content}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={getDangerouslySetInnerHTML(data.attributes?.[diagramLanguage]?.AUTHORITY)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.Section}>
                <div>
                  <span className={styles.Title}>{t('RESPONSIBILITY')}</span>
                  <br />
                  <div className={styles.Content}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={getDangerouslySetInnerHTML(data.attributes?.[diagramLanguage]?.RESPONSIBILITY)}
                    />{' '}
                  </div>
                </div>
              </div>
            </>
          )}
          {(data.type === ObjectTypes.ROLE_SWIMLANE || data.type === ObjectTypes.IT_SYSTEM_SWIMLANE) && (
            <>
              <div className={styles.Section}>
                <div>
                  <span className={styles.Title}>{t('qiGroup')}</span>
                  <br />
                  <div className={styles.Content}>
                    <span>{data.attributes?.NOT_TRANSLATABLE?.RESPONSIBLE_AREA}</span>
                  </div>
                </div>
              </div>
              <div className={styles.Section}>
                <div>
                  <span className={styles.Title}>{t('LINKAGE')}</span>
                  <br />
                  <div className={styles.Content}>
                    <span>{data.attributes?.[diagramLanguage]?.LINKAGE}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {data.temporaryText && (
        <div className={styles.Section}>
          <div>
            <span className={styles.Title}>{t('OBJECT_NAME')}</span>
            <br />
            <div className={styles.Content}>
              <span>{data.temporaryText}</span>
            </div>
          </div>
          <div className={styles.Status}>
            <span className={`${styles.Tag} ${styles.Requested}`}>{TEMPORARY}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessStepObjectAttributes;
