import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import escapeHtml from 'escape-html';
import { useTranslation } from 'react-i18next';
import { createEditor, BaseEditor, Descendant, Text, Transforms, Location, Editor, Range, Path } from 'slate';
import { jsx } from 'slate-hyperscript';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import { purify } from 'assets/js/Utils';
import useDiagramContext from 'hooks/useDiagramContext';
import useOuterClick from 'hooks/useOuterClick';
import usePrevious from 'hooks/usePrevious';

import DialogNEPOS from '../DialogNEPOS/DialogNEPOS';
import InputTextNEPOS from '../InputText/InputTextNEPOS';
import BlockButton from './BlockButton';
import MarkButton from './MarkButton';
import stylesNepos from './WysiwygNEPOS.module.scss';
import stylesLegacy from './WysiwygNEPOSLegacyStyle.module.scss';

type CustomElement = { type: string; children: CustomText[]; url?: string };
type CustomText = { text: string; bold?: string; italic?: string; underline?: string; type?: string };
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
interface Props {
  className?: string;
  disabled?: boolean;
  id: string;
  label: string;
  legacyStyles?: boolean;
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
  containerRef?: React.RefObject<HTMLLabelElement>;
  error?: string;
  onBlur?: () => void;
  tooltip?: string;
}

const WysiwygNEPOS = (props: Props) => {
  const { diagramLanguage, selectedSymbolIds, originRef } = useDiagramContext();
  const deserialize: any = (el: HTMLElement) => {
    if (el.nodeType === 3) return el.textContent;
    if (el.nodeType !== 1) return null;

    let children: ChildNode[] | [{ text: string }] = Array.from(el.childNodes).map((child) => deserialize(child as HTMLElement));

    if (children.length === 0) children = [{ text: '' }];

    switch (el.nodeName) {
      case 'BODY':
        return jsx('fragment', {}, children);
      case 'P':
        return jsx('element', { type: 'paragraph' }, children);
      case 'A': {
        const url = el.getAttribute('href');
        if (!url) return;
        return jsx('element', { type: 'link', url }, children);
      }
      case 'LI':
        return jsx('element', { type: 'list-item' }, children);
      case 'UL':
        return jsx('element', { type: 'bulleted-list' }, children);
      case 'OL':
        return jsx('element', { type: 'numbered-list' }, children);
      case 'BLOCKQUOTE':
        return jsx('element', { type: 'indent' }, children);
      case 'STRONG':
        return { text: el.textContent, bold: 'bold' };
      case 'EM':
        return { text: el.textContent, italic: 'italic' };
      case 'U':
        return { text: el.textContent, underline: 'underline' };
      default:
        return el.textContent;
    }
  };
  const { className, disabled, id, label, onChange, required, value, containerRef, legacyStyles, tooltip } = props;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;
  const document = new DOMParser().parseFromString(value, 'text/html');
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentContainerRef = containerRef || newContainerRef;
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectionHasChanged, setSelectionHasChanged] = useState(false);
  const [isDialogLinkOpen, setIsDialogLinkOpen] = useState(false);
  const [linkValue, setLinkValue] = useState('');
  const { t } = useTranslation();
  const previousSymbolId = usePrevious(selectedSymbolIds);
  const editor = useMemo(() => withReact(createEditor()), []);
  const { insertBreak, insertText, isInline } = editor;
  editor.isInline = (element) => element.type === 'link' || isInline(element);
  const hasError = Boolean(props.error);

  const initValue: any = useMemo(
    () =>
      value
        ? deserialize(document.body)
        : [
            {
              type: 'paragraph',
              children: [
                {
                  text: '',
                },
              ],
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document.body, value],
  );

  const updateValue = () => {
    const selection = editor.selection?.anchor;
    Transforms.select(editor, []);
    Transforms.removeNodes(editor, { mode: 'highest', hanging: true });
    Transforms.insertNodes(editor, initValue);
    Transforms.select(editor, selection || { path: [0, 0], offset: 0 });
  };

  useEffect(() => {
    if (JSON.stringify(previousSymbolId) === JSON.stringify(selectedSymbolIds)) return;
    setSelectionHasChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSymbolIds]);

  useEffect(() => {
    if (selectionHasChanged) {
      Transforms.select(editor, { path: [0, 0], offset: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionHasChanged]);

  useEffect(() => {
    if (value === undefined || (value === '' && !selectionHasChanged && !!originRef.current)) return;
    updateValue();
    setSelectionHasChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    updateValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramLanguage]);

  useOuterClick(currentContainerRef, () => setIsFocused(false));

  const renderLeaf = useCallback(
    ({ attributes, children, leaf }) => (
      <span
        {...attributes}
        style={{
          fontWeight: leaf.bold ? 'bold' : 'normal',
          fontStyle: leaf.italic ? 'italic' : 'normal',
          textDecoration: leaf.underline ? 'underline' : 'none',
        }}
      >
        {children}
      </span>
    ),
    [],
  );

  const renderElement = ({ attributes, children, element }: { attributes: any; children: string; element: any }) => {
    switch (element.type) {
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'link':
        return (
          <span onClick={() => window.open(element.url, '_blank')}>
            <a {...attributes} href={element.url} style={{ cursor: 'pointer' }}>
              {children}
            </a>
          </span>
        );
      case 'indent':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'outdent':
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const serialize = (node: Descendant) => {
    if (Text.isText(node)) {
      let stringText = escapeHtml(node.text);
      if (node.bold) stringText = `<strong>${stringText}</strong>`;
      if (node.italic) stringText = `<em>${stringText}</em>`;
      if (node.underline) stringText = `<u>${stringText}</u>`;
      return stringText;
    }

    const children: string = (node as CustomElement).children?.map((n: CustomText) => serialize(n)).join('');

    switch ((node as CustomElement).type) {
      case 'paragraph':
        return `<p>${children}</p>`;
      case 'bulleted-list':
        return `<ul>${children}</ul>`;
      case 'list-item':
        return `<li>${children}</li>`;
      case 'numbered-list':
        return `<ol>${children}</ol>`;
      case 'link':
        return `<a href=${node.url}>${children}</a>`;
      case 'indent':
        return `<blockquote>${children}</blockquote>`;
      case 'outdent':
        return `<p>${children}</p>`;
      default:
        return children;
    }
  };

  const handleInputChange = (element: string) => {
    setLinkValue(element);
  };

  const markButtons = [
    {
      buttonId: 'Wysiwyg-bold-button',
      disabledButton: false,
      format: 'bold',
      icon: 'icon-schriftschnitt-fett',
    },
    {
      buttonId: 'Wysiwyg-italic-button',
      disabledButton: false,
      format: 'italic',
      icon: 'icon-schriftschnitt-kursiv',
    },
    {
      buttonId: 'Wysiwyg-underline-button',
      disabledButton: false,
      format: 'underline',
      icon: 'icon-schriftschnitt-unterstrichen',
    },
  ];

  const isOutdentEnabled = useMemo(
    () =>
      !!editor.children[editor.selection?.focus.path[0] || 0] &&
      editor.children[editor.selection?.focus.path[0] || 0].type === 'indent',
    [editor.children, editor.selection?.focus.path],
  );

  const blockButtons = [
    {
      buttonId: 'Wysiwyg-bulleted-list-button',
      disabledButton: false,
      format: 'bulleted-list',
      icon: 'icon-text-liste-aufzaehlung',
    },
    {
      buttonId: 'Wysiwyg-numbered-list-button',
      disabledButton: false,
      format: 'numbered-list',
      icon: 'icon-text-liste-nummerierung',
    },
    {
      buttonId: 'Wysiwyg-indent-button',
      disabledButton: false,
      format: 'indent',
      icon: 'icon-text-einzug-vergroessern',
    },
    {
      buttonId: 'Wysiwyg-outdent-button',
      disabledButton: !isOutdentEnabled,
      format: 'outdent',
      icon: 'icon-text-einzug-verkleinern',
    },
    {
      buttonId: 'Wysiwyg-link-button',
      disabledButton: false,
      format: 'link',
      icon: 'icon-link',
      onClick: () => setIsDialogLinkOpen(true),
    },
  ];

  editor.insertBreak = () => {
    const [selectedElement, path] = Editor.parent(editor, editor.selection as Location);

    if ((selectedElement as CustomElement).type === 'link') {
      const endPoint = Range.end(editor.selection as Range);
      const [selectedLeaf] = Editor.node(editor, endPoint);
      if ((selectedLeaf as CustomText).text.length === endPoint.offset) {
        if (Range.isExpanded(editor.selection as Range)) {
          Transforms.delete(editor);
        }
        Transforms.select(editor, Path.next(path));
        insertBreak();
      }
    } else {
      insertBreak();
    }
  };

  editor.insertText = (text?: string) => {
    const [selectedElement, path] = Editor.parent(editor, editor.selection as Location);

    if ((selectedElement as CustomElement).type === 'link') {
      const endPoint = Range.end(editor.selection as Range);
      const [selectedLeaf] = Editor.node(editor, endPoint);
      if ((selectedLeaf as CustomText).text.length === endPoint.offset) {
        if (Range.isExpanded(editor.selection as Range)) {
          Transforms.delete(editor);
        }

        Transforms.select(editor, Path.next(path));
        insertText(text || '');
      }
    } else {
      insertText(text || '');
    }
  };

  const handleInsertLink = () => {
    const createLinkElement = () => {
      if (!linkValue) return;
      return jsx('element', { type: 'link', url: linkValue }, { text: linkValue });
    };

    const linkElement = createLinkElement();
    if (!linkElement || !editor.selection) return;
    Transforms.insertNodes(editor, linkElement);
  };

  return (
    <>
      <label
        className={`${styles.Container} ${isFocused ? styles.Focused : ''} ${disabled ? styles.Disabled : ''} ${
          isExpanded ? styles.Expanded : ''
        } ${className || ''} ${hasError && !isFocused ? styles.Error : ''}`}
        htmlFor={id}
        onBlur={props.onBlur}
        onClick={() => setIsFocused(!disabled)}
        ref={currentContainerRef}
      >
        <div
          className={`${styles.Label} ${isFocused ? styles.Floating : ''}  ${tooltip ? styles.tooltipIcon : ''} ${
            hasError && !isFocused ? styles.Error : ''
          }`}
        >
          <span>
            {label}
            {required && <span className="error">*</span>}
          </span>
          {tooltip && (
            <TooltipComponent
              className="mbc-tooltip nepos-tooltip"
              content={t(tooltip)}
              cssClass="mbc-tooltip nepos-tooltip"
              position="BottomCenter"
              showTipPointer={false}
              target="#info"
            >
              <i className={`di icon-information ${styles.Info}`} id="info" />
            </TooltipComponent>
          )}
          {hasError && <i className={`di icon-blitz-fehler error ${styles.IconError}`} />}
        </div>
        <Slate
          editor={editor}
          onChange={(val) => {
            const newValues: string = val.map(serialize).join('');
            onChange(purify(newValues));
          }}
          value={initValue}
        >
          <Editable className={styles.Input} id={id} readOnly={disabled} renderElement={renderElement} renderLeaf={renderLeaf} />
          {isFocused && (
            <div className={styles.ContainerToolbar}>
              <button
                className={`${disabled ? styles.Disabled : ''} ${isExpanded ? styles.Active : ''}`}
                id="Wysiwyg-expand-button"
                onClick={() => setIsExpanded(!isExpanded)}
                type="button"
              >
                <i className={`di ${isExpanded ? 'icon-pfeil-minimieren' : 'icon-pfeil-maximieren'}`} />
              </button>
              {markButtons.map(({ buttonId, disabledButton, format, icon }) => (
                <MarkButton
                  buttonId={buttonId}
                  disabled={disabledButton}
                  format={format}
                  icon={icon}
                  key={buttonId}
                  legacyStyles={legacyStyles}
                />
              ))}
              {blockButtons.map(({ buttonId, disabledButton, format, icon, onClick }) => (
                <BlockButton
                  buttonId={buttonId}
                  disabled={disabledButton}
                  format={format}
                  icon={icon}
                  key={buttonId}
                  legacyStyles={legacyStyles}
                  onClick={onClick}
                />
              ))}
            </div>
          )}
        </Slate>
      </label>
      {isDialogLinkOpen && (
        <DialogNEPOS
          dialog={{
            title: t('insertLink'),
            buttons: [
              {
                buttonStyle: BUTTON_PRIMARY,
                content: t('add'),
                disabled: !linkValue,
                handleClick: () => {
                  setIsDialogLinkOpen(false);
                  handleInsertLink();
                  setLinkValue('');
                },
                key: 'submit',
              },
              {
                buttonStyle: BUTTON_SECONDARY,
                content: t('cancel'),
                handleClick: () => {
                  setIsDialogLinkOpen(false);
                  setLinkValue('');
                },
                key: 'cancel',
              },
            ],
          }}
        >
          <InputTextNEPOS id="web-address" isFocused label={t('webAddress')} onChange={handleInputChange} value={linkValue} />
        </DialogNEPOS>
      )}
    </>
  );
};

export default WysiwygNEPOS;
