import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const baseDesignUrl = '/backend/ms-design/api';
const designUrl = `${baseDesignUrl}/v1`;
const designUrlv2 = `${baseDesignUrl}/v2`;
const catalogUrl = `${designUrl}/objectCatalog`;

const getObjectCatalog = (idObjectType) =>
  axiosInstance
    .get(`${catalogUrl}?idObjectType=${idObjectType}`)
    .then((res) => res.data)
    .catch(serviceUtils.handleServiceError);

const getCatalogObjectsByType = (idObjectType, params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${designUrlv2}/objectCatalog?${searchParams}&idObjectType=${idObjectType}`);
};

const createObjectCatalog = ({ data }) => axiosInstance.post(catalogUrl, data);

const deleteObjectsCatalog = (ids, type) => axiosInstance.delete(`${catalogUrl}?idObjects=${ids}&type=${type}`);

const createRequestObjectCatalog = ({ data }) => axiosInstance.post(`${catalogUrl}/request`, data);

const modifyObjectCatalog = ({ id, data }) => axiosInstance.put(`${catalogUrl}?idObject=${id}`, data);

const approveObjectCatalog = ({ id, idTask, data }) =>
  axiosInstance.put(`${catalogUrl}/approve?idObject=${id}&idTask=${idTask}`, data);

const rejectObjectCatalog = (idObject, idTask, data) =>
  axiosInstance.delete(`${catalogUrl}/reject?idObject=${idObject}&idTask=${idTask}`, { data });

const getObject = (id) => axiosInstance.get(`${catalogUrl}/${id}`);

const searchObjects = (value, objectType, lang, params = {}) =>
  axiosInstance.get(
    `${catalogUrl}/search?`,
    { params: { ...params, objectType, value } },
    { headers: { 'Accept-Language': lang } },
  );

const getPending = (objectType) => axiosInstance.get(`${catalogUrl}/pending?objectType=${objectType}`);

const mergeObjectsCatalog = (data) => axiosInstance.post(`${catalogUrl}/merge`, data);

export default {
  handleServiceError: serviceUtils.handleServiceError,
  getObjectCatalog,
  getCatalogObjectsByType,
  createObjectCatalog,
  deleteObjectsCatalog,
  createRequestObjectCatalog,
  modifyObjectCatalog,
  approveObjectCatalog,
  rejectObjectCatalog,
  getObject,
  searchObjects,
  getPending,
  mergeObjectsCatalog,
};
