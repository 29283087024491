import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const formUrl = '/backend/ms-form/api/v1';

const getFormTypesService = (code) => axiosInstance.get(`${formUrl}/forms/${code}`);

export default {
  handleServiceError: serviceUtils.handleServiceError,
  getFormTypesService,
};
