import { WebStorageStateStore } from 'oidc-client-ts';

import { STORAGE } from 'assets/constants/constants';

/**
 * Para entropía mayor que 128 bits, necesitamos length de 22 para un conjunto de 64 caracteres
 * +info sobre entropía https://inversegravity.net/2019/password-entropy/
 */
const randomString = (length = 23) => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);

    /* eslint-disable no-loop-func, no-param-reassign, no-plusplus */
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    random.forEach((c) => {
      if (length === 0) {
        return;
      }

      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
    /* eslint-enable */
  }

  return result;
};

const nonce = randomString();
const state = randomString();

export const env = process.env.REACT_APP_ENV || 'development';
export const ssoScopes = 'openid authorization_group entitlement_group job_title scoped_entitlement offline_access';

export const ssoAuthority = {
  local: 'https://sso-int.mercedes-benz.com',
  development: 'https://sso-int.mercedes-benz.com',
  qa: 'https://sso-int.mercedes-benz.com',
  mig: 'https://sso-int.mercedes-benz.com',
  int: 'https://sso-int.mercedes-benz.com',
  production: 'https://sso.mercedes-benz.com',
};

// 'https://mbcmap-dev-app.corpinter.net' - dev, qa, int o nada, según cada entorno
export const ssoClientRoot = window.location.origin;

export const ssoClientID = {
  local: '63e9e6e8-750a-4000-8765-ee7258ccd154',
  development: '63e9e6e8-750a-4000-8765-ee7258ccd154',
  qa: '5edf894d-99de-4d50-8ea6-c66607a03652',
  mig: '5edf894d-99de-4d50-8ea6-c66607a03652',
  int: '4a51c48d-1dfc-4355-918d-ed1c39064993',
  production: 'c5773ea8-76c0-4459-b683-d16bc329577f',
};

export const permissions = {
  explorerRead: 'MBCMAP.Explorer_Read',
  explorerWrite: 'MBCMAP.Explorer_Write',
  epcRead: 'MBCMAP.EPC_DA_Read',
  epcWrite: 'MBCMAP.EPC_DA_Write',
  epcCreate: 'MBCMAP.EPC_DA_Create',
  epcDelete: 'MBCMAP.EPC_DA_Delete',
  epcRD: 'MBCMAP.EPC_DA_RD',
  taskView: 'MBCMAP.Task_Location_View',
  vcdRead: 'MBCMAP.VCD_DA_Read',
  vcdWrite: 'MBCMAP.VCD_DA_Write',
  vcdCreate: 'MBCMAP.VCD_DA_Create',
  vcdDelete: 'MBCMAP.VCD_DA_Delete',
  workflowRead: 'MBCMAP.WF_Read',
  workflowWrite: 'MBCMAP.WF_Write',
};

export const mockUser = {
  USER: 'USER',
  MODELER: 'MODELER',
  QI_MODELER: 'QI MODELER',
  QI: 'QI',
  ADMINISTRATOR: 'ADMINISTRATOR',
};

export const mockUserRights = {
  USER: ['MBCMAP.Explorer_Read', 'MBCMAP.WF_Read', 'MBCMAP.WF_Write', 'MBCMAP.Task_Location_View'],
  MODELER: [
    'MBCMAP.EPC_A_Read',
    'MBCMAP.EPC_A_Write',
    'MBCMAP.EPC_DA_Create',
    'MBCMAP.EPC_DA_RD',
    'MBCMAP.EPC_DA_Read',
    'MBCMAP.EPC_DA_Write',
    'MBCMAP.Explorer_Read',
  ],
  QI_MODELER: [
    'MBCMAP.EPC_A_Read',
    'MBCMAP.EPC_A_Write',
    'MBCMAP.EPC_DA_Create',
    'MBCMAP.EPC_DA_Delete',
    'MBCMAP.EPC_DA_RD',
    'MBCMAP.EPC_DA_Read',
    'MBCMAP.EPC_DA_Write',
    'MBCMAP.Explorer_Read',
    'MBCMAP.Explorer_Write',
    'MBCMAP.VCD_A_Read',
    'MBCMAP.VCD_A_Write',
    'MBCMAP.VCD_DA_Create',
    'MBCMAP.VCD_DA_Delete',
    'MBCMAP.VCD_DA_Read',
    'MBCMAP.VCD_DA_Write',
  ],
  QI: [
    'MBCMAP.EPC_A_Read',
    'MBCMAP.EPC_A_Write',
    'MBCMAP.EPC_DA_Create',
    'MBCMAP.EPC_DA_Delete',
    'MBCMAP.EPC_DA_RD',
    'MBCMAP.EPC_DA_Read',
    'MBCMAP.EPC_DA_Write',
    'MBCMAP.Explorer_Read',
    'MBCMAP.Explorer_Write',
    'MBCMAP.VCD_A_Read',
    'MBCMAP.VCD_A_Write',
    'MBCMAP.VCD_DA_Create',
    'MBCMAP.VCD_DA_Delete',
    'MBCMAP.VCD_DA_Read',
    'MBCMAP.VCD_DA_Write',
    'MBCMAP.WF_Read',
    'MBCMAP.WF_Write',
  ],
  ADMINISTRATOR: [
    'MBCMAP.EPC_A_Read',
    'MBCMAP.EPC_A_Write',
    'MBCMAP.EPC_DA_Create',
    'MBCMAP.EPC_DA_Delete',
    'MBCMAP.EPC_DA_RD',
    'MBCMAP.EPC_DA_Read',
    'MBCMAP.EPC_DA_Write',
    'MBCMAP.Explorer_Read',
    'MBCMAP.Explorer_Write',
    'MBCMAP.VCD_A_Read',
    'MBCMAP.VCD_A_Write',
    'MBCMAP.VCD_DA_Create',
    'MBCMAP.VCD_DA_Delete',
    'MBCMAP.VCD_DA_Read',
    'MBCMAP.VCD_DA_Write',
    'MBCMAP.WF_Read',
    'MBCMAP.WF_Write',
  ],
};

export const oidcConfig = {
  authority: ssoAuthority[env || ''],
  client_id: ssoClientID[env || ''],
  loadUserInfo: true,
  metadataUrl: `${ssoAuthority[env || '']}/.well-known/openid-configuration`,
  nonce,
  state,
  redirect_uri: ssoClientRoot || '',
  scope: ssoScopes,
  onSigninCallback: () => {
    // Remove SSO query params
    window.history.replaceState({}, document.title, window.location.pathname);
    // Redirect to the desired URL (deep linking)
    window.location.pathname = localStorage.getItem(STORAGE.SIGN_IN_REDIRECT) || '/';
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    acr_values: 'daimler:idp:gas:strong',
    nonce,
    state,
  },
};
