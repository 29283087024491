import { LegacyRef } from 'react';

import { useTranslation } from 'react-i18next';

import { DataSet } from 'types/dataset';
import { Coordinates } from 'types/diagram';

import styles from './VersionsDropDown.module.scss';

type Props = {
  setShowVersions: (showVersions: boolean) => void;
  versionRef?: LegacyRef<HTMLDivElement>;
  element: { id: number | undefined } & Pick<DataSet, 'versions'>;
  showVersions?: boolean;
  versionDropdownRef?: LegacyRef<HTMLDivElement>;
  coords?: Coordinates;
  showDate?: boolean;
  showLabel?: boolean;
};
const VersionsDropDown = (props: Props) => {
  const { setShowVersions, versionRef, element, showVersions, versionDropdownRef, coords, showDate, showLabel = false } = props;
  const { t } = useTranslation();

  const handleOpenVersions = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowVersions(!showVersions);
  };

  return (
    <div className={styles.VersionButton} onClick={(e) => handleOpenVersions(e)} onMouseLeave={() => setShowVersions(false)}>
      {element.versions.length > 0 && (
        <span className={`${styles.LastVersion} ${showVersions ? styles.Clicked : ''}`} ref={versionRef}>
          {showLabel && (
            <span>
              <i className={`di icon-uhr-zeit ${styles.ClockIcon}`} />
              {t('VERSION')}
            </span>
          )}
          {element.versions[0].version}
          {showDate && ` | ${element.versions[0].date}`}
        </span>
      )}
      {showVersions && (
        <div className={styles.VersionsDropDown} ref={versionDropdownRef} style={coords}>
          <div className={styles.VersionsWrapper}>
            {element.versions.map((version: any) => {
              return (
                <div className={styles.Version} key={`version-${version.version}-${element.id}`}>
                  <span className={styles.Name}>{`${t('nameAttributes.VERSION')} ${version.version}`}</span>
                  <div className={styles.Details}>
                    <span>{version.user}</span>
                    <span>{version.date}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default VersionsDropDown;
