import { v4 as uuidv4 } from 'uuid';

import * as Constants from 'assets/constants/constants';
import { Attributes } from 'types/forms';

export interface DiagramCopy {
  connections: string;
  diagramType: string;
  objectsCatalogUsedDiagram: any[];
  objectsUsedDiagram: any[];
  symbols: any[];
}

const changeFrontIds = (diagram: DiagramCopy): DiagramCopy => {
  diagram.symbols.forEach((symbol) => {
    const symbolType = Constants.SYMBOL_TYPES_BY_ID[symbol.idSymbolType];
    const oldSymbolIdWithoutType = symbol.idSymbolFront.replace(symbolType, '');
    const newIdWithoutType = uuidv4();
    const idSymbolFront = symbolType ? `${symbolType}${newIdWithoutType}` : symbol.id;

    diagram.connections = diagram.connections.replaceAll(oldSymbolIdWithoutType, newIdWithoutType);
    symbol.idSymbolFront = idSymbolFront;
    symbol.type = symbolType;
  });

  diagram.objectsUsedDiagram.forEach((object) => {
    object.id = 0;
  });

  return diagram;
};

const cleanAttributes = (attributes: Attributes): Attributes => {
  const notTranlatableAttributes = { ...attributes.NOT_TRANSLATABLE };
  notTranlatableAttributes.CREATOR_CODE = '';
  notTranlatableAttributes.LAST_EDITOR_CODE = '';
  notTranlatableAttributes.CREATION_DATE = '';
  notTranlatableAttributes.LAST_MODIFICATION = '';

  return { ...attributes, NOT_TRANSLATABLE: notTranlatableAttributes };
};

const cleanSymbol = (symbol) => {
  const attributes = JSON.parse(symbol.attributes);
  if (attributes?.NOT_TRANSLATABLE) {
    const cleanedAttributes = cleanAttributes(attributes);
    symbol.attributes = JSON.stringify(cleanedAttributes);
  }
  symbol.idDiagramLink = 0;
  symbol.id = 0;
  delete symbol.diagramChildType;
  delete symbol.diagramLinkType;

  return symbol;
};

const getDiagram = (): DiagramCopy | undefined => {
  const diagramAsString = localStorage.getItem(Constants.storageLabels.copy);
  if (!diagramAsString) return undefined;

  const localDiagram = JSON.parse(diagramAsString);
  const diagramWithNewIds = changeFrontIds(localDiagram);

  return diagramWithNewIds;
};

const getDiagramType = (): string | undefined => {
  const localData = localStorage.getItem(Constants.storageLabels.copy);
  return localData ? JSON.parse(localData)?.diagramType : undefined;
};

const setDiagram = (diagram): void => {
  diagram.symbols = diagram.symbols.map(cleanSymbol);

  diagram.objectsUsedDiagram = diagram.objectsUsedDiagram.map((object) => {
    if (typeof object.attributes === 'string') {
      object.attributes = JSON.parse(object.attributes);
    }
    const cleanedAttributes = cleanAttributes(object.attributes);
    return { ...object, attributes: JSON.stringify(cleanedAttributes) };
  });

  localStorage.setItem(Constants.storageLabels.copy, JSON.stringify(diagram));
};

export default { getDiagram, setDiagram, getDiagramType };
