import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import userService from 'services/userService';
import { ButtonProps } from 'types/config';
import { Chip, FormLanguage, LanguageChip, Scope } from 'types/forms';
import { ProcessScope } from 'types/processes';
import { TableColumn, Pagination, TableVariant, TablePrefix } from 'types/tables';

import styles from './AddScopeDialog.module.scss';

type Props = {
  add: (scopes: LanguageChip[]) => void;
  close: () => void;
  selected: Chip[];
};

const scopeColumns: TableColumn<ProcessScope>[] = [
  { id: 'scope', style: styles.Scope },
  { id: 'displayValue', style: styles.Description },
];

const AddScopeDialog = ({ close, add, selected }: Props) => {
  const { t, i18n } = useTranslation();
  const [scopeToAdd, setScopeToAdd] = useState<string[]>([]);
  const [storedScopes, setStoredScopes] = useState<ProcessScope[]>(
    selected.map((scope) => ({
      scope: scope.code,
      displayValue: scope.displayName,
    })),
  );
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [availableScopes, setAvailableScopes] =
    useState<(ProcessScope & { id: string; checked: boolean; description: { [key in FormLanguage]: string } })[]>();
  const [isLoading, setIsLoading] = useState(true);

  const handleAddScope = () => {
    const scopesToAdd = scopeToAdd.map((scp) => {
      const scope = availableScopes?.find((elem) => String(elem.id) === scp);
      return {
        code: scp,
        displayName: `${scope?.scope} - ${scope?.displayValue}`,
        description: scope?.description,
      };
    }) as LanguageChip[];

    const storedScopesToAdd = storedScopes.map((scp) => ({
      code: scp.scope,
      displayName: scp.displayValue,
      description: { [i18n.language.toUpperCase()]: scp.displayValue },
    })) as LanguageChip[];

    add([...scopesToAdd, ...storedScopesToAdd]);
    close();
  };

  const addScopeDialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !scopeToAdd.length && !storedScopes.length,
      id: 'add-scope-submit-button',
      handleClick: handleAddScope,
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: 'scope-cancel-button',
      handleClick: close,
      key: 'cancel',
    },
  ];

  const fetchScope = useCallback(
    (newPage: number = 0, actualScopes: (ProcessScope & { id: string; checked: boolean })[] = []) => {
      const scopesToAdd = scopeToAdd.map((scp) => ({
        scope: scp,
        displayValue: `${availableScopes?.find((elem) => String(elem.id) === scp)?.scope} - ${
          availableScopes?.find((elem) => String(elem.id) === scp)?.displayValue
        }`,
      })) as ProcessScope[];

      const stored = [...storedScopes, ...scopesToAdd];
      const params = {
        page: newPage,
        pageSize: 50,
        ...(searchText ? { searchString: searchText } : {}),
        sortDir: 'ASC',
      };

      userService
        .getScopesByName(params)
        .then((res) => {
          const newScopes = res.data.results.map((scope: Scope) => ({
            id: scope.departmentId,
            scope: scope.abbreviation,
            displayValue: JSON.parse(scope.description)[i18n.language.toUpperCase()],
            description: JSON.parse(scope.description),
            checked: stored.some((elem) => scope.departmentId === elem.scope),
          }));
          setStoredScopes(stored.filter((std) => !newScopes.some((elem: ProcessScope) => std.scope === elem.id?.toString())));
          setAvailableScopes([...actualScopes, ...newScopes]);
          setIsLoading(false);
          setPage(newPage);
        })
        .catch((err) => {
          userService.handleServiceError(err);
        });
    },
    [availableScopes, i18n.language, scopeToAdd, searchText, storedScopes],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchScope();
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DialogNEPOS
      dialog={{
        title: t('add.scope.header'),
        buttons: addScopeDialogButtons,
      }}
    >
      <TableWithSearch
        columns={scopeColumns}
        getPage={(newPage) => fetchScope(newPage, availableScopes)}
        id={TablePrefix.SCOPE}
        isAsync
        isLoading={isLoading}
        multiselect
        onCheck={setScopeToAdd}
        page={page}
        pagination={Pagination.SHOW_MORE}
        rows={availableScopes}
        searching={(search: string) => setSearchText(search)}
        variant={TableVariant.CHECKBOX}
      />
    </DialogNEPOS>
  );
};

export default AddScopeDialog;
