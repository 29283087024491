import { useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { SIPOC_DIAGRAM_ID, SWIMLANE_DIAGRAM_ID } from 'assets/constants/constants';
import ConnectorPorts from 'components/ConnectorPorts/ConnectorPorts';
import LinkDiagramDialog from 'components/DialogDetail/LinkDiagramDialog';
import Selection from 'components/Selection/Selection';
import SymbolAttributes from 'components/Symbol/SymbolAttributes/SymbolAttributes';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes, movingSwimlaneSymbols, setSwimlaneDisplayingAttributes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useOuterClick from 'hooks/useOuterClick';
import useProcess from 'hooks/useProcess';
import useSelection from 'hooks/useSelection';
import useSymbol from 'hooks/useSymbol';
import useTool from 'hooks/useTool';
import useValidation from 'hooks/useValidation';
import { PaletteActions, Tool } from 'types/diagram';
import { ProcessStatus, TreeData } from 'types/processes';
import { SwimlanePaletteItemProps, Symbol as SymbolType, SymbolTypes } from 'types/symbols';

import styles from './ProcessInterface.module.scss';

type Props = {
  isPaletteItem?: boolean;
};

const ProcessInterface = (props: (Props & SymbolType) | SwimlanePaletteItemProps) => {
  const { attributes, diagramLink, id, isPaletteItem, swimlaneId } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleDragStart } = useDragAndDrop();
  const {
    editableSymbol,
    diagramLanguage,
    dispatch,
    processData,
    selectedSymbolIds,
    grabAndMove,
    selectedConnectorId,
    fontSize,
  } = useDiagramContext();
  const { getTree, linkExternalDiagram } = useProcess(processData?.id as string);
  const { handleOuterClick, onNameChange } = useSymbol();
  const { toggleTool, tool } = useTool();
  const { t } = useTranslation();
  const { handleSelect, handleSelectionOuterClick } = useSelection();
  const { symbolsIdsWithErrors } = useValidation();
  const [isLinkDiagramOpen, setIsLinkDiagramOpen] = useState(false);
  const [diagramList, setDiagramList] = useState([]);
  const [showAttributes, setShowAttributes] = useState(false);
  const symbolNameInputRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const history = useHistory();
  const hasErrors = !isPaletteItem && symbolsIdsWithErrors && symbolsIdsWithErrors.find((symbolId) => symbolId === id);

  useEffect(() => {
    if (editableSymbol === id && textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [editableSymbol, id]);

  const onDragStart = (e: any) => {
    if (processData?.isOnlyRead) return;
    handleDragStart(e);
    if (isPaletteItem) {
      dispatch({
        type: DiagramActionTypes.SET_PALETTE_ACTION,
        payload: PaletteActions.ADD_PROCESS_INTERFACE,
      });
      dispatch(movingSwimlaneSymbols(true));
    } else if (swimlaneId) {
      if (tool === Tool.CONNECT || grabAndMove || selectedConnectorId) return;

      dispatch(movingSwimlaneSymbols(true, id, swimlaneId));
    }
  };

  useOuterClick(symbolNameInputRef, handleOuterClick, id);
  useOuterClick(containerRef, handleSelectionOuterClick, id);

  const handleClick = () => {
    setIsLinkDiagramOpen(true);
    getTree().then((res) => {
      const diagrams = res.filter(
        (diagram: TreeData) => diagram.idDiagramType === SIPOC_DIAGRAM_ID || diagram.idDiagramType === SWIMLANE_DIAGRAM_ID,
      );
      setDiagramList(diagrams);
    });
  };

  return (
    <>
      {!isPaletteItem && <Selection className={styles.Selection} onClick={handleClick} swimlaneId={swimlaneId} symbolId={id} />}
      <div
        className={`${styles.Wrapper} ${isPaletteItem ? styles.isPaletteItem : ''}`}
        draggable={
          !processData?.isOnlyRead && editableSymbol !== id && tool !== Tool.CONNECT && !grabAndMove && !selectedConnectorId
        }
        onClick={() => isPaletteItem && toggleTool(Tool.ADD_SYMBOL)}
        onDoubleClick={() => {
          if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW) {
            dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: id });
          }
        }}
        onDragEnd={() => dispatch(movingSwimlaneSymbols(false))}
        onDragStart={onDragStart}
        onMouseLeave={() => {
          dispatch(setSwimlaneDisplayingAttributes(''));
          setShowAttributes(false);
        }}
      >
        <div
          className={`${styles.Container} ${isPaletteItem ? styles.isPaletteItem : ''}`}
          onClick={(e) => {
            handleSelect(id, e);
          }}
          ref={containerRef}
        >
          <div
            className={`${styles.BackElement} ${selectedSymbolIds.includes(id) && !isPaletteItem ? styles.Selected : ''}  ${
              hasErrors ? styles.Error : ''
            }`}
          />
          <div
            className={`${styles.FrontElement} ${selectedSymbolIds.includes(id) && !isPaletteItem ? styles.Selected : ''}  ${
              hasErrors ? styles.Error : ''
            }`}
          >
            {editableSymbol === id ? (
              <div className={styles.SymbolNameInput} ref={symbolNameInputRef} style={{ fontSize: fontSize || 'inherit' }}>
                <textarea
                  onChange={(event) => {
                    onNameChange(id, event.target.value, textAreaRef.current);
                  }}
                  placeholder={t('diagram.objects.defaultName')}
                  ref={textAreaRef}
                  rows={1}
                  value={attributes ? attributes[diagramLanguage]?.OBJECT_NAME : ''}
                />
              </div>
            ) : (
              <TooltipComponent
                content={isPaletteItem ? t('PROCESS_INTERFACE') : attributes?.[diagramLanguage]?.OBJECT_NAME || ''}
                cssClass={`mbc-tooltip nepos-tooltip ${
                  isPaletteItem || attributes?.[diagramLanguage]?.OBJECT_NAME ? '-' : 'display-none'
                }`}
                position="BottomCenter"
                showTipPointer={false}
              >
                <p
                  className={styles.SymbolName}
                  onClick={(event) => {
                    if (
                      !isPaletteItem &&
                      diagramLink &&
                      (processData?.isOnlyRead ||
                        (processData?.isPublishedVersion && diagramLink.processNumber) ||
                        processData?.status === ProcessStatus.WORKFLOW)
                    ) {
                      event.stopPropagation();
                      history.push(`/${processData?.isPublishedVersion ? 'published' : 'diagram'}/${diagramLink.id}`);
                    }
                  }}
                  style={{ fontSize: isPaletteItem ? '' : fontSize || 'inherit' }}
                >
                  {isPaletteItem
                    ? t('PROCESS_INTERFACE')
                    : attributes?.[diagramLanguage]?.OBJECT_NAME || t('diagram.objects.defaultName')}
                </p>
              </TooltipComponent>
            )}
            {!isPaletteItem && (
              <div className={styles.WrapperButtons}>
                {attributes && attributes[diagramLanguage]?.LINKAGE && (
                  <a href={attributes[diagramLanguage]?.LINKAGE?.toString()} rel="noopener noreferrer" target="_blank">
                    <RoundButton
                      className={styles.Link}
                      icon="di icon-link-extern"
                      id={`processInterface-link-${id}`}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </a>
                )}
                {!processData?.isOnlyRead &&
                  !isPaletteItem &&
                  !processData?.isPublishedVersion &&
                  processData?.status !== ProcessStatus.WORKFLOW &&
                  diagramLink && (
                    <RoundButton
                      icon="di icon-unterebene-stapel"
                      id="symbol-diagram-link"
                      onClick={(event) => {
                        event.stopPropagation();
                        history.push(`/diagram/${diagramLink.id}`);
                      }}
                    />
                  )}
                {(processData?.isOnlyRead ||
                  processData?.isPublishedVersion ||
                  processData?.status === ProcessStatus.WORKFLOW) && (
                  <RoundButton
                    className={styles.SymbolInfo}
                    icon="di icon-information"
                    id={`symbol-diagram-info-ico-${id}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(setSwimlaneDisplayingAttributes(swimlaneId));
                      setShowAttributes((prv) => !prv);
                    }}
                  />
                )}
                {showAttributes && attributes && (
                  <SymbolAttributes
                    attributes={attributes}
                    id={id}
                    symbolType={SymbolTypes.PROCESS_INTERFACE}
                    top={6.2}
                    width={15}
                  />
                )}
              </div>
            )}
          </div>
          <ConnectorPorts
            portStyles={{
              rightPortClass: styles.RightPort,
              leftPortClass: styles.LeftPort,
              topPortClass: styles.TopPort,
            }}
            symbolId={id}
          />
        </div>
      </div>
      {isLinkDiagramOpen && (
        <LinkDiagramDialog
          assignModelers={() => {}}
          buttonText={t('link')}
          close={() => setIsLinkDiagramOpen(false)}
          diagramList={diagramList}
          dialogTitle={t('linkExistingDiagram')}
          lang={diagramLanguage}
          linkDiagram={(idDiagramToLink: number) => linkExternalDiagram(idDiagramToLink, id)}
          target="#root"
        />
      )}
    </>
  );
};

export default ProcessInterface;
