import React, { useState, useRef } from 'react';

import DatePicker from 'react-date-picker';
import { withTranslation } from 'react-i18next';

import './DateFilter.scss';
import styles from './SearchFilter.module.scss';

const DateFilter = (props) => {
  const { setDate, date } = props;
  const [value, setValue] = useState(date);
  const DatePickerRef = useRef(null);

  const handleChange = (event) => {
    const formattedDate = event
      ? [`${event?.getFullYear()}-${`0${event?.getMonth() + 1}`.slice(-2)}-${`0${event?.getDate()}`.slice(-2)}`]
      : [];
    setDate(formattedDate);
    setValue(event);
    DatePickerRef.current.closeCalendar();
  };

  return (
    <>
      <div className={styles.SearchBox}>
        <DatePicker
          dayPlaceholder="dd"
          defaultView="month"
          format="dd/MM/yy"
          locale={props.i18n.language}
          minDetail="decade"
          monthPlaceholder="mm"
          onChange={handleChange}
          ref={DatePickerRef}
          value={value}
          yearPlaceholder="yyyy"
        />
      </div>
    </>
  );
};

export default withTranslation('common')(DateFilter);
