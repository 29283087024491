import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  ADMINISTRATOR,
  ATTRIBUTE_CARES,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  CURRENT,
  DELEGATE_DIAGRAM,
  FIELD_TYPES,
  QI,
  USER_ROLE,
} from 'assets/constants/constants';
import { parseWorkflowDataResponse, purify, setFormattedDate } from 'assets/js/Utils';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import WorkflowStepNEPOS from 'components/WorkflowStepNEPOS/WorkflowStepNEPOS';
import { DatasetActionTypes } from 'contexts/Dataset/DatasetContext';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useAuth from 'hooks/useAuth';
import useBackendForm from 'hooks/useBackendForm';
import useDataset from 'hooks/useDataset';
import useDatasetContext from 'hooks/useDatasetContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useForm from 'hooks/useForm';
import useFormTypes from 'hooks/useFormTypes';
import useProcess from 'hooks/useProcess';
import {
  approveDataSetWorkflow,
  backToGroupDataset,
  delegateDataSetWorkflow,
  getDatasetWorkflowTypes,
  rejectDatasetWorkflow,
} from 'services/datasetWorkflow';
import { approveWorkflow, backToGroupWorkflow } from 'services/workflow';
import { rejectWorkflow, getAllWorkflowTypes, delegateWorkflowStep } from 'services/workflowServices';
import { DatasetWorkflowStages } from 'types/dataset';
import { AttributeCode, Attributes, SearchBy } from 'types/forms';
import { ResponsibleList, WorkflowStage, WorkflowType } from 'types/workflow';

import * as Constants from '../../assets/constants/constants';
import { handleServiceError } from '../../assets/js/serviceUtils';
import styles from './WorkflowBarNEPOS.module.scss';

type Props = {
  isLegacyStyle?: boolean;
  isDatasetWorkflow?: boolean;
  isOnlyRead?: boolean;
};

const WorkflowBarNEPOS = (props: Props) => {
  const { isLegacyStyle, isDatasetWorkflow, isOnlyRead } = props;
  const { checkRole, checkRoles, userInfo } = useAuth();
  const { dispatch, workflowData, processData, diagramLanguage } = useDiagramContext();
  const { datasetData, dispatch: datasetDispatch, workflowData: datasetWorkflowData } = useDatasetContext();
  const { fetchDataset, fetchPublishedDataset } = useDataset();
  const { id } = useParams<{ id: string }>();
  const { fetchProcess } = useProcess(id);
  const processNumber = workflowData?.processNumber;
  const currentWorkflowData = isDatasetWorkflow ? datasetWorkflowData : workflowData;
  const { t, i18n } = useTranslation();
  const [isDialogRejectOpen, setIsDialogRejectOpen] = useState(false);
  const [isDialogDelegateOpen, setIsDialogDelegateOpen] = useState(false);
  const [isDialogApproveOpen, setIsDialogApproveOpen] = useState(false);
  const [isDialogBackGroupOpen, setIsDialogBackGroupOpen] = useState(false);
  const [showBackGroupButton, setShowBackGroupButton] = useState(false);
  const [isDialogConfirmApproveOpen, setIsDialogConfirmApproveOpen] = useState(false);
  const [msgDialogConfirmApprove, setMsgDialogConfirmApprove] = useState('');
  const { getFormTypesCode } = useFormTypes();
  const { isFreezed } = useFeatureFlags();

  const history = useHistory();

  const formTypes = {
    [i18n.language]: {
      rejectionReason: {
        care: ATTRIBUTE_CARES.MANDATORY,
        label: t('workflow.reject.dialog.reason'),
        placeholder: t('workflow.reject.dialog.reason'),
        type: FIELD_TYPES.TEXT,
      },
    },
  };

  const currStage = currentWorkflowData?.stages?.find((stage) => stage.status === CURRENT);

  const formCode = getFormTypesCode({
    status: processData?.status,
    type: DELEGATE_DIAGRAM,
    variant: processData?.type,
    workflowStage: `${currStage?.type}_UPDATE`,
  });

  const formCodeDataSet = getFormTypesCode({
    status: datasetData?.status,
    type: DELEGATE_DIAGRAM,
    variant: `DATASET_${datasetData?.linkedDiagram?.type}`,
    workflowStage: `${currStage?.type}_UPDATE`,
  });

  const existingCatalog = processData?.catalog;

  const catalogForWorkflow = {
    ...existingCatalog,
    [SearchBy.USER]: currentWorkflowData?.users || [],
    [SearchBy.SCOPE]: [],
    [SearchBy.PROCESS]: [],
  };

  const { fields, Form, isValid, values } = useForm({ formTypes });
  const group = useForm({ formTypes });
  const delegate = useBackendForm({ formCode });

  const DatasetInitialAttributesMap = {
    [DatasetWorkflowStages.AUTHOR]: {
      AUTHOR_DELEGATE_INSERT_REASON: '',
      AUTHOR_DELEGATE_AUTHOR: currStage?.responsibleList[0].userCode,
      AUTHOR_DELEGATE_NEW_AUTHOR: '',
    },
    [DatasetWorkflowStages.METHOD_OWNER]: {
      METHOD_OWNER_DELEGATE_INSERT_REASON: '',
      METHOD_OWNER_DELEGATE_CURRENT_METHOD_OWNER: currStage?.responsibleList[0].userCode,
      METHOD_OWNER_DELEGATE_NEW_METHOD_OWNER: '',
    },
    [DatasetWorkflowStages.QI_GROUP]: {
      QI_DELEGATE_INSERT_REASON: '',
      QI_DELEGATE_CURRENT_QI: currStage?.responsibleList[0].userCode,
      QI_DELEGATE_NEW_QI: currStage?.responsibleList[0].userCode,
    },
    empty: {},
  };

  const initialAttributes: Attributes = {};
  initialAttributes[Constants.NOT_TRANSLATABLE] =
    DatasetInitialAttributesMap[currStage ? (currStage.type as DatasetWorkflowStages) : 'empty'];

  const delegateDataSet = useBackendForm({
    formCode: formCodeDataSet,
    catalog: catalogForWorkflow,
    initialValues: initialAttributes,
  });

  const approveTitle = isLegacyStyle
    ? t('dateset.workflow.approve.title', { type: t(`dataset.workflow.approve.dialog`) })
    : t('workflow.approve.dialog', {
        type: t(`workflow.${currentWorkflowData?.type}`),
      });

  const handleReject = async () => {
    try {
      const purifiedValue = purify(Object.values(values)[0].rejectionReason!.toString().trim());

      if (!purifiedValue) return;
      setIsDialogRejectOpen(false);
      if (isDatasetWorkflow && datasetData) {
        datasetDispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });
        await rejectDatasetWorkflow(datasetData.id, datasetData?.version, purifiedValue);
        await fetchDataset(datasetData.id);
      } else {
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW });
        await rejectWorkflow(processNumber, purifiedValue);
        await fetchProcess();
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
      handleServiceError(error);
    }
  };

  const handleApproveDataSet = () => {
    setIsDialogApproveOpen(false);
    datasetDispatch({ type: DatasetActionTypes.FETCH_WORKFLOW });
    if (!datasetData?.id) return;

    approveDataSetWorkflow(datasetData?.id, datasetData?.version)
      .then(async (response) => {
        const wfStagesResponse = await getDatasetWorkflowTypes(response.data.type.code);

        datasetDispatch({
          type: DatasetActionTypes.SET_WORKFLOW_DATA,
          payload: {
            ...parseWorkflowDataResponse(response.data, wfStagesResponse.data),
            type: response.data.type.code,
          },
        });

        setMsgDialogConfirmApprove(
          response.data.status.code === Constants.COMPLETED
            ? t('textinputDialog.finished', { type: t('dataSet') })
            : t('workflowDialog.approvedWorkflow'),
        );

        setIsDialogConfirmApproveOpen(true);
        datasetDispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
      })
      .catch((error) => {
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
        setIsDialogConfirmApproveOpen(false);
        handleServiceError(error);
      });
  };

  const handleApprove = () => {
    setIsDialogApproveOpen(false);
    dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW });
    if (!processNumber) return;

    approveWorkflow(processNumber, [])
      .then(async (response) => {
        const wfStagesResponse = await getAllWorkflowTypes(response.data.type, false);

        dispatch({
          type: DiagramActionTypes.FETCH_WORKFLOW_DATA,
          payload: parseWorkflowDataResponse(response.data, wfStagesResponse.data),
        });
        setMsgDialogConfirmApprove(
          response.data.status === Constants.COMPLETED
            ? t('textinputDialog.finished', { type: t(`${processData?.type}`) })
            : t('workflowDialog.approvedWorkflow'),
        );
        setIsDialogConfirmApproveOpen(true);
        setTimeout(() => dispatch({ type: DiagramActionTypes.PROCESS_DATA_SUCCESS_TIMEOUT }), 500);
      })
      .catch((error) => {
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
        setIsDialogConfirmApproveOpen(false);
        handleServiceError(error);
      });
  };

  const FieldCommentaryMap = {
    [DatasetWorkflowStages.AUTHOR]: AttributeCode.AUTHOR_DELEGATE_INSERT_REASON,
    [DatasetWorkflowStages.METHOD_OWNER]: AttributeCode.METHOD_OWNER_DELEGATE_INSERT_REASON,
    [DatasetWorkflowStages.QI_GROUP]: AttributeCode.QI_DELEGATE_INSERT_REASON,
  };

  const FieldUserCodeMap = {
    [DatasetWorkflowStages.AUTHOR]: AttributeCode.AUTHOR_DELEGATE_NEW_AUTHOR,
    [DatasetWorkflowStages.METHOD_OWNER]: AttributeCode.METHOD_OWNER_DELEGATE_NEW_METHOD_OWNER,
    [DatasetWorkflowStages.QI_GROUP]: AttributeCode.QI_DELEGATE_NEW_QI,
  };

  const handleDelegateDataSet = async () => {
    try {
      if (!datasetData || !currStage) return;

      const fieldCommentary = FieldCommentaryMap[currStage.type as DatasetWorkflowStages];
      const fieldUserCode = FieldUserCodeMap[currStage.type as DatasetWorkflowStages];

      const purifiedCommentary = purify(Object.values(delegateDataSet.values)[0][fieldCommentary]!.toString().trim());
      const userCodeToList = purify(Object.values(delegateDataSet.values)[0][fieldUserCode]!.toString().trim());
      setIsDialogDelegateOpen(false);

      datasetDispatch({ type: DatasetActionTypes.FETCH_WORKFLOW });
      await delegateDataSetWorkflow(
        purifiedCommentary,
        datasetData.id,
        datasetData.version,
        [userCodeToList],
        fieldUserCode === AttributeCode.QI_DELEGATE_NEW_QI ? 'GROUP' : 'USER',
      );
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      await fetchDataset(datasetData.id);
    } catch (error) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
      handleServiceError(error);
    }
  };

  const handleDelegate = async () => {
    try {
      const processResponsible = AttributeCode.PROCESS_RESPONSIBLE;
      const field = currStage?.type === AttributeCode.QA_INSPECTOR_GROUP ? AttributeCode.QUALITY_INSPECTOR : processResponsible;
      const initialProcessResponsibles = currStage?.responsibleList.map((element) => element.userCode);
      const purifiedValue = purify(Object.values(delegate.values)[0][field as AttributeCode]?.toString().trim());
      const value =
        field === AttributeCode.QUALITY_INSPECTOR ? [purifiedValue] : [...initialProcessResponsibles!!, purifiedValue];
      setIsDialogDelegateOpen(false);
      if (
        currStage?.type === AttributeCode.QA_INSPECTOR_GROUP &&
        currStage?.responsibleList.find((element) => element.userCode === purifiedValue)
      ) {
        Dialog.showError({
          closeClick: () => history.go(0), // eslint-disable-line no-restricted-globals
          message: t('duplicated_qi_group_error'),
          title: t('error'),
        });
      } else {
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW });
        await delegateWorkflowStep('', processNumber, value, field === AttributeCode.QUALITY_INSPECTOR ? 'GROUP' : 'USER');
        await fetchProcess();
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
      handleServiceError(error);
    }
  };

  const handleCloseConfirmApprove = () => {
    setIsDialogConfirmApproveOpen(false);
    if (workflowData?.status === Constants.COMPLETED) {
      return fetchProcess(true).then(() => {
        dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS });
      });
    }
    if (datasetData) {
      if (
        datasetWorkflowData?.status === Constants.COMPLETED &&
        (datasetWorkflowData?.type === Constants.ARCHIVE_DS_WF_VCD || datasetWorkflowData?.type === Constants.ARCHIVE_DS_WF_EPC)
      ) {
        history.push('/dashboard');
      } else if (datasetWorkflowData?.status === Constants.COMPLETED) {
        fetchPublishedDataset(datasetData.id);
        history.push(`/dataset-published/${id}`);
      } else {
        fetchDataset(datasetData.id);
      }
      location.reload(); // eslint-disable-line no-restricted-globals
    }
    dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS });
  };

  const handleBackToGroupDataset = async () => {
    try {
      if (!datasetData) return;

      const purifiedValue = purify(Object.values(group.values)[0].rejectionReason!.toString().trim());

      if (!purifiedValue) return;
      setIsDialogBackGroupOpen(false);

      await backToGroupDataset(datasetData.id, datasetData.version, purifiedValue);
      datasetDispatch({ type: DatasetActionTypes.FETCH_WORKFLOW });
      await fetchDataset(datasetData.id);
    } catch (error) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
      handleServiceError(error, () => window.location.reload());
    }
  };

  const handleBackToGroup = async () => {
    try {
      if (!processNumber) return;

      const purifiedValue = purify(Object.values(group.values)[0].rejectionReason!.toString().trim());

      if (!purifiedValue) return;
      setIsDialogBackGroupOpen(false);

      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW });
      await backToGroupWorkflow(processNumber, purifiedValue);
      await fetchProcess();
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_SUCCESS });
    } catch (error) {
      dispatch({ type: DiagramActionTypes.FETCH_WORKFLOW_ERROR });
      handleServiceError(error);
    }
  };

  const isUserResponsible = useCallback(() => {
    const currentStage = currentWorkflowData?.stages?.find((stage) => stage.status === CURRENT);
    if (!currentStage) return false;

    const responsible = currentStage.responsibleList.find((user) => user.userCode === userInfo?.code);
    return !!responsible;
  }, [userInfo, currentWorkflowData]);

  const namesToShow = (list: ResponsibleList[]) => {
    if (!currentWorkflowData) return;
    const userCodes = list.map((elem) => elem.userCode);
    return userCodes.map((value) => {
      const currentUser = currentWorkflowData.users.find((e) => e.code === value);
      if (currentUser) return `${currentUser.surname}, ${currentUser.givenName}`;
      return value;
    });
  };

  const dateToShow = (stage: WorkflowStage): string => {
    const approversDone = stage.responsibleList.find((approver) => approver.finishDate !== null);
    return approversDone?.finishDate ? setFormattedDate(approversDone.finishDate) : setFormattedDate(stage.initialDate);
  };

  useEffect(() => {
    const showGroupButton = currentWorkflowData?.stages
      .find(
        (stage) =>
          stage.status === Constants.CURRENT &&
          (stage.type.includes(Constants.QA_INSPECTOR_GROUP) || stage.type.includes(Constants.QI_GROUP)),
      )
      ?.responsibleList.some(({ responsibleType }) => responsibleType === USER_ROLE);

    setShowBackGroupButton(showGroupButton && (checkRole(ADMINISTRATOR) || isUserResponsible()));
  }, [currentWorkflowData, checkRole, isUserResponsible]);

  const isResponsibleUsers = currStage?.responsibleList?.some((responsible) => responsible.responsibleType === 'USER');

  return (
    <>
      <div className={`${styles.Container} ${isLegacyStyle ? styles.LegacyContainer : ''}`}>
        <div className={styles.StepsWrapper}>
          {currentWorkflowData && (
            <>
              <WorkflowStepNEPOS
                date={`${t('workflow.stage.status.INITIAL')} ${setFormattedDate(currentWorkflowData?.stages[0].initialDate)}`}
                id={1}
                isLegacyStyle={isLegacyStyle}
                label={isDatasetWorkflow ? Constants.DATASET : processData?.type}
                name={t(`workflow.${currentWorkflowData.type}`)}
                status={isDatasetWorkflow ? currentWorkflowData.type.split('_')[0] : WorkflowType[currentWorkflowData.type]}
              />
              {currentWorkflowData.stages.map((stage) => (
                <WorkflowStepNEPOS
                  date={`${t(`workflow.stage.status.${stage.status}`)} ${dateToShow(stage)}`}
                  id={stage.id}
                  isLegacyStyle={isLegacyStyle}
                  key={stage.id}
                  label={t(`workflow.stage.type.${stage.type}`)}
                  namesToShow={namesToShow(stage.responsibleList)}
                  status={stage.status}
                />
              ))}
            </>
          )}
        </div>
        <div className={styles.ButtonsWrapper}>
          {checkRoles([ADMINISTRATOR, QI]) && (!processData?.isSwimlane || (processData?.isSwimlane && !isResponsibleUsers)) && (
            <ButtonNEPOS
              className={`${styles.ActionButton} ${isLegacyStyle ? styles.ActionButtonLegacy : ''}`}
              disabled={isFreezed || isOnlyRead}
              gradientButton={BUTTON_SECONDARY}
              handleClick={() => {
                setIsDialogDelegateOpen(true);
              }}
              id="delegate-button"
            >
              {t('delegate')}
            </ButtonNEPOS>
          )}
          {showBackGroupButton && (
            <ButtonNEPOS
              className={`${styles.ActionButton} ${isLegacyStyle ? styles.ActionButtonLegacy : ''}`}
              disabled={isFreezed || isOnlyRead}
              gradientButton={BUTTON_SECONDARY}
              handleClick={() => {
                setIsDialogBackGroupOpen(true);
              }}
              id="backGroup-button"
            >
              {t('backToGroup')}
            </ButtonNEPOS>
          )}
          {(showBackGroupButton || checkRole(ADMINISTRATOR)) && <span className={styles.Bar} />}
          {(checkRole(ADMINISTRATOR) || isUserResponsible()) && (
            <ButtonNEPOS
              className={`${styles.ActionButton} ${isLegacyStyle ? styles.ActionButtonLegacy : ''}`}
              disabled={isFreezed || isOnlyRead}
              gradientButton={isUserResponsible() ? BUTTON_SECONDARY : BUTTON_PRIMARY}
              handleClick={() => {
                setIsDialogRejectOpen(true);
              }}
              id="reject-button"
            >
              {t('reject')}
            </ButtonNEPOS>
          )}
          {isUserResponsible() && (
            <ButtonNEPOS
              className={`${styles.ActionButton} ${isLegacyStyle ? styles.ActionButtonLegacy : ''}`}
              disabled={isFreezed || isOnlyRead}
              gradientButton={BUTTON_PRIMARY}
              handleClick={() => {
                setIsDialogApproveOpen(true);
              }}
              id="approve-button"
            >
              {t('approve')}
            </ButtonNEPOS>
          )}
        </div>
        {isDialogDelegateOpen && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  content: t('ok'),
                  handleClick: isDatasetWorkflow ? handleDelegateDataSet : handleDelegate,
                  disabled: isFreezed || isDatasetWorkflow ? !delegateDataSet.isValid : !delegate.isValid,
                  id: 'ModalButton-OK',
                  key: 'submit',
                },
                {
                  buttonStyle: BUTTON_SECONDARY,
                  content: t('cancel'),
                  handleClick: () => {
                    setIsDialogDelegateOpen(false);
                  },
                  key: 'cancel',
                },
              ],
              title: t('workflow.delegate.dialog'),
              isLegacyStyle,
            }}
            extraClass="Modal"
          >
            <Form
              className={styles.Form}
              fields={isDatasetWorkflow ? delegateDataSet.fields : delegate.fields}
              language={diagramLanguage}
              legacyStyles={isDatasetWorkflow}
            />
          </DialogNEPOS>
        )}

        {isDialogRejectOpen && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  content: t('ok'),
                  handleClick: handleReject,
                  disabled: isFreezed || !isValid,
                  id: 'ModalButton-OK',
                  key: 'submit',
                },
                {
                  buttonStyle: BUTTON_SECONDARY,
                  content: t('cancel'),
                  handleClick: () => {
                    setIsDialogRejectOpen(false);
                  },
                  key: 'cancel',
                },
              ],
              title: t('workflow.reject.dialog', { type: t(`workflow.${currentWorkflowData?.type}`) }),
              isLegacyStyle,
            }}
            extraClass="Modal"
          >
            <p>{t('workflow.reject.dialog.text', { type: t(`workflow.${currentWorkflowData?.type}`) })}</p>
            <Form className={styles.Form} fields={fields} />
          </DialogNEPOS>
        )}
        {isDialogApproveOpen && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  disabled: isFreezed,
                  content: t('approve'),
                  handleClick: isDatasetWorkflow ? handleApproveDataSet : handleApprove,
                  id: 'modal-approval-ok',
                  key: 'submit',
                },
                {
                  buttonStyle: BUTTON_SECONDARY,
                  content: t('cancel'),
                  handleClick: () => {
                    setIsDialogApproveOpen(false);
                  },
                  id: 'modal-approval-cancel',
                  key: 'cancel',
                },
              ],
              title: approveTitle,
              isLegacyStyle,
            }}
            extraClass="Modal"
          >
            <p>{t('workflow.approve.dialog.text', { type: t(`workflow.${currentWorkflowData?.type}`) })}</p>
          </DialogNEPOS>
        )}
        {isDialogConfirmApproveOpen && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  content: t('ok'),
                  disabled: isFreezed,
                  handleClick: handleCloseConfirmApprove,
                  id: 'modal-confirm-approval-ok',
                  key: 'submit',
                },
              ],
              title: t('success'),
              isLegacyStyle,
            }}
            extraClass="Modal"
          >
            <p>{msgDialogConfirmApprove}</p>
          </DialogNEPOS>
        )}
        {isDialogBackGroupOpen && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  content: t('backToGroup'),
                  handleClick: isDatasetWorkflow ? handleBackToGroupDataset : handleBackToGroup,
                  disabled: isFreezed || !group.isValid,
                  id: 'ModalButton-OK',
                  key: 'submit',
                },
                {
                  buttonStyle: BUTTON_SECONDARY,
                  content: t('cancel'),
                  handleClick: () => {
                    setIsDialogBackGroupOpen(false);
                  },
                  key: 'cancel',
                },
              ],
              title: t('workflow.backToGroup.dialog'),
              isLegacyStyle,
            }}
            extraClass="Modal"
          >
            <p>{t('workflow.backToGroup.dialog.text', { type: t(`workflow.${currentWorkflowData?.type}`) })}</p>
            <group.Form className={styles.Form} fields={group.fields} />
          </DialogNEPOS>
        )}
      </div>
    </>
  );
};

export default WorkflowBarNEPOS;
