import { useEffect, useMemo, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { SYMBOL, MODELING_STATUS, BUTTON_PRIMARY, BUTTON_SECONDARY, NOT_TRANSLATABLE } from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import { AttributeCode, VariantProperty } from 'types/forms';
import { ProcessType } from 'types/processes';
import { SymbolTypes } from 'types/symbols';

import styles from './AttributesPanel.module.scss';

interface Props {
  symbolType?: SymbolTypes;
}

const AttributesPanel = (props: Props) => {
  const { symbolType } = props;
  const { t } = useTranslation();
  const { getFormTypesCode } = useFormTypes();
  const { dispatch, editableSymbol, processData, processStepWithErrors, selectedSymbolIds, diagramLanguage, attributesRef } =
    useDiagramContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const id = selectedSymbolIds.length ? selectedSymbolIds[0] : undefined;
  const swimlaneLastPos = useMemo(
    () => (processData && processData.swimlanes[0] ? processData.swimlanes[0].meta?.width - 1 : 0),
    [processData],
  );

  const symbol = useMemo(
    () =>
      [...(processData?.symbols || []), ...(processData?.swimlanes.map((swimlane) => swimlane.symbols).flat() || [])].find(
        (element) => element.id === id,
      ),
    [id, processData?.swimlanes, processData?.symbols],
  );
  const symbolNameAttribute = processData?.type === ProcessType.SWIMLANE ? AttributeCode.OBJECT_NAME : AttributeCode.NAME;
  const nameAttribute = symbolType === SymbolTypes.TEXT ? AttributeCode.TEXT_BLOCK : symbolNameAttribute;
  const wrongProcessStepNumber = processStepWithErrors.find((processStepId) => processStepId === id);

  const formCode = getFormTypesCode({
    status: MODELING_STATUS,
    type: SYMBOL,
    variant: symbolType,
  });

  const { fields, values } = useBackendForm({
    formCode,
    initialValues: symbol?.attributes,
    id,
    usesDiagramTranslation: true,
    isTouchedNeeded: false,
  });

  const errors = fields[diagramLanguage];

  const isLastEventDisabled = useMemo(
    () =>
      processData?.lastEvents &&
      (processData.lastEvents.filter((event) => event.id !== symbol?.id).length >= 10 || symbol?.meta.left !== swimlaneLastPos),
    [processData?.lastEvents, swimlaneLastPos, symbol?.meta.left, symbol?.id],
  );

  const isStartEventDisabled = useMemo(
    () =>
      processData?.startEvents &&
      (processData.startEvents.filter((event) => event.id !== symbol?.id).length >= 10 ||
        (processData.startEvents.length <= 1 && processData.startEvents[0]?.id === symbol?.id) ||
        symbol?.meta.left !== 0),
    [symbol?.id, processData?.startEvents, symbol?.meta.left],
  );

  const handleEventCheck = useCallback(
    (eventCode: string) => {
      if (
        values[NOT_TRANSLATABLE]?.[eventCode] === 'true' &&
        (AttributeCode.START_EVENT === eventCode || (processData?.lastEvents && processData.lastEvents.length > 1))
      ) {
        dispatch({
          type: DiagramActionTypes.SET_WARNING,
          payload: {
            buttons: [
              {
                id: `start-event-cancel-delete`,
                handleClick: () => dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null }),
                content: t(`cancel`),
                buttonStyle: BUTTON_SECONDARY,
              },
              {
                id: `start-event-confirm-delete`,
                handleClick: () => {
                  fields?.[diagramLanguage]?.find((field) => field.code === eventCode)?.onChange('false');
                  dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null });
                },
                content: t(`confirm`),
                buttonStyle: BUTTON_PRIMARY,
              },
            ],
            message: AttributeCode.LAST_EVENT === eventCode ? t('deleteLastEventWarningMsg') : t('deleteStartEventWarningMsg'),
          },
        });
      } else {
        fields?.[diagramLanguage]?.find((field) => field.code === eventCode)?.onChange('true');
      }
    },
    [diagramLanguage, dispatch, fields, processData?.lastEvents, t, values],
  );

  const getDisabledMessage = useCallback(
    (code: AttributeCode) => {
      if (!processData?.startEvents || symbol?.type !== SymbolTypes.EVENT) return;

      if (code === AttributeCode.LAST_EVENT && isLastEventDisabled && symbol?.meta.left === swimlaneLastPos) {
        return t('limitLastEvents');
      }

      if (code === AttributeCode.START_EVENT && isStartEventDisabled && symbol?.meta.left === 0) {
        return t(processData.startEvents.length <= 1 ? 'lastStartEvent' : 'limitStartEvents');
      }
    },
    [symbol?.type, isLastEventDisabled, isStartEventDisabled, processData?.startEvents, symbol?.meta.left, t, swimlaneLastPos],
  );

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length || !isLoading) return;
    setIsLoading(false);
  }, [fields, isLoading, values]);

  useEffect(() => {
    if (!processData) return;
    const newSymbols = processData?.symbols.map((element) => (element.id === id ? { ...element, attributes: values } : element));
    const newSwimlane = processData?.swimlanes.map((swimlane) => ({
      ...swimlane,
      symbols: swimlane.symbols.map((element) =>
        element.id === id ? { ...element, attributes: Object.keys(values).length !== 0 ? values : element.attributes } : element,
      ),
    }));
    dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols });
    dispatch({ type: DiagramActionTypes.UPDATE_SWIMLANES, payload: newSwimlane });
  }, [dispatch, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.AttributesPanel} ref={attributesRef}>
        <section className={styles.Form}>
          {!isLoading &&
            Object.keys(values).length > 0 &&
            fields[diagramLanguage]?.map(({ Component, ...fieldProps }, index) => (
              <Component
                {...fieldProps}
                disabled={
                  processData?.isOnlyRead ||
                  symbol?.diagramLink ||
                  (fieldProps.code === AttributeCode.START_EVENT && isStartEventDisabled) ||
                  (fieldProps.code === AttributeCode.LAST_EVENT && isLastEventDisabled)
                }
                disabledMessage={getDisabledMessage(fieldProps.code)}
                error={(fieldProps.code === AttributeCode.PROCESS_STEP_NUMBER && wrongProcessStepNumber) || errors?.[index].error}
                isEditing={fieldProps.code === nameAttribute && editableSymbol === id}
                onChange={
                  fieldProps.code === AttributeCode.START_EVENT || fieldProps.code === AttributeCode.LAST_EVENT
                    ? () => handleEventCheck(fieldProps.code)
                    : fieldProps.onChange
                }
                value={fieldProps.code === nameAttribute ? values[diagramLanguage]?.[nameAttribute] : fieldProps.value}
                variant={fieldProps.code === AttributeCode.PROCESS_STEP_NUMBER ? VariantProperty.NUMERIC : ''}
              />
            ))}
        </section>
      </div>
    </>
  );
};

export default AttributesPanel;
