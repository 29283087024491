import { DatasetProvider } from 'contexts/Dataset/DatasetContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { ProcessLevelProvider } from 'contexts/ProcessLevel/ProcessLevelContext';
import { RequirementProvider } from 'contexts/Requirement/RequirementContext';

import Requirement from './Requirement';

const RequirementWrapper = () => {
  return (
    <DiagramProvider>
      <DatasetProvider>
        <ProcessLevelProvider>
          <RequirementProvider>
            <Requirement />
          </RequirementProvider>
        </ProcessLevelProvider>
      </DatasetProvider>
    </DiagramProvider>
  );
};

export default RequirementWrapper;
