import { useCallback, useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import Multiselection from 'components/UI/Multiselection/Multiselection';

import * as Constants from '../../assets/constants/constants';
import { getDangerouslySetInnerHTML, getObjectAttributes } from '../../assets/js/Utils';
import Button from '../UI/Button/Button';
import styles from './DialogDetail.module.scss';

const DialogDetail = (props) => {
  const { refAssignment, visible, close, t, createCatalogObjects, objectAttributes, parentCallback, language } = props;
  const [inputsToBeAdded, setInputs] = useState([]);
  const [data, setData] = useState({ OBJECT_NAME: null });
  const [catalogObjectsAdded, setCatalogObjects] = useState([]);

  const [multiselectionValue, setMultiselectionValue] = useState([]);

  const parseObjectResults = useCallback(
    (object) => {
      const attributes = getObjectAttributes(object.data.attributes);

      return {
        id: object.data.id,
        OBJECT_NAME: attributes[language].OBJECT_NAME,
        OBJECT_DESCRIPTION: attributes[language].OBJECT_DESCRIPTION,
        RESPONSIBILITY: attributes[language].RESPONSIBILITY,
        LINKAGE: attributes[language].LINKAGE,
      };
    },
    [language],
  );

  const addTitle = (objectSelected) => {
    const selectedItem = parseObjectResults(objectSelected);

    if (selectedItem) {
      const itemAlreadyAdded = inputsToBeAdded.find((i) => i.id === selectedItem.id);

      if (!itemAlreadyAdded) {
        setInputs([...inputsToBeAdded, selectedItem]);
        setCatalogObjects([...catalogObjectsAdded, objectSelected.data]);
      }
    }
  };

  const getSelectedItemCatalogId = (args) => {
    const selectedItem = args[0];

    if (objectAttributes.objectsList.find((obj) => obj.id === selectedItem.data.id)) {
      return;
    }

    addTitle(selectedItem);
    setMultiselectionValue('');
  };

  const deleteItem = (itemToDelete, e) => {
    e.preventDefault();
    e.stopPropagation();
    const titlesList = [...inputsToBeAdded].filter((title) => itemToDelete.id !== title.id);
    setInputs(titlesList);
    setData({ OBJECT_NAME: null });
    setCatalogObjects([...catalogObjectsAdded].filter((obj) => itemToDelete.id !== obj.id));
  };

  const closeDialog = () => {
    setInputs([]);
    close();
  };

  const sendItems = () => {
    if (objectAttributes.code === Constants.ROLE) {
      inputsToBeAdded.addRoles = true;
    }
    createCatalogObjects(inputsToBeAdded, catalogObjectsAdded);
    closeDialog();
  };

  const setDialogDetail = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.OBJECT_NAME === data.OBJECT_NAME) {
      setData({ OBJECT_NAME: null });
    } else {
      setData({
        OBJECT_NAME: item.OBJECT_NAME,
        OBJECT_DESCRIPTION: item.OBJECT_DESCRIPTION,
        RESPONSIBILITY: item.RESPONSIBILITY,
        LINKAGE: item.LINKAGE,
      });
    }
  };

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={closeDialog}
      id="dialog-detail"
      isModal
      ref={(dialog) => refAssignment(dialog)}
      target="#dialog"
      visible={visible}
      width="800px"
    >
      <div className={styles.Content}>
        <div className={styles.Section}>
          <h4>{t('addX', { labelX: t(`${objectAttributes.code}`) })}</h4>
          <Multiselection
            language={language}
            meta={{
              attributeType: 'M',
              data: [],
              id: objectAttributes.idObject,
              label: t('searchX', { labelX: t(`${objectAttributes.code}`) }),
              max: 1,
              options: [],
              placeholder: t('searchX', { labelX: t(`${objectAttributes.code}`) }),
              searchBy: Constants.SEARCH_BY.OBJECT,
              valuesLoaded: [],
            }}
            patchValue={(newValue) => {
              getSelectedItemCatalogId(newValue);
            }}
            value={multiselectionValue}
          />
          <div className={styles.TitleSelected}>{t('selectedXToBeAdded', { labelX: t(`${objectAttributes.code}`) })}</div>
          <div className={styles.ItemSelected}>
            {inputsToBeAdded.map((input) => (
              <div
                className={`${styles.CatalogBox} ${input.OBJECT_NAME === data.OBJECT_NAME ? styles.InputSelected : ''}`}
                id={input.id}
                key={input.id}
                onClick={(event) => setDialogDetail(input, event)}
              >
                <div aria-hidden="true" className={styles.DeleteIcon} id="delete" onClick={(event) => deleteItem(input, event)}>
                  
                </div>
                <i aria-hidden="true" className={`${objectAttributes.icon} ${styles.CatalogIcon}`} />
                <p>{input.OBJECT_NAME}</p>
              </div>
            ))}
          </div>
          <div className={styles.ButtonLine}>
            <Button
              btnText={t('RequestNewObject', { objectType: t(objectAttributes.code) })}
              buttonStyle="Primary"
              handleClick={(e) => parentCallback(e, objectAttributes.idObject)}
              id="requestNew"
            />
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={closeDialog} id="cancel" />
            <Button
              btnText={objectAttributes.code === Constants.ROLE ? t('next') : t('add')}
              buttonStyle="Primary"
              disabled={inputsToBeAdded.length === 0}
              handleClick={sendItems}
              id={objectAttributes.code === Constants.ROLE ? 'next' : 'add'}
            />
          </div>
        </div>
        <div className={styles.SectionDetails}>
          <h4>{t('detailOfX', { labelX: t(`${objectAttributes.code}`) })}</h4>
          {data.OBJECT_NAME === null ? (
            <div className={styles.Default}>{t('detailInfo', { labelX: t(`${objectAttributes.code}`) })}</div>
          ) : (
            <div className={styles.Details}>
              <div className={styles.Title}>{t('nameAttributes.OBJECT_NAME')}</div>
              <div className={styles.Content}>{data.OBJECT_NAME}</div>
              <div className={styles.Title}>{t('nameAttributes.OBJECT_DESCRIPTION')}</div>
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.Content} dangerouslySetInnerHTML={getDangerouslySetInnerHTML(data.OBJECT_DESCRIPTION)} />
              <div className={styles.Title}>{t('nameAttributes.RESPONSIBILITY')}</div>
              <div className={styles.Content}>{data.RESPONSIBILITY}</div>
              <div className={styles.Title}>{t('nameAttributes.LINKAGE')}</div>
              <div className={styles.Content}>{data.LINKAGE}</div>
            </div>
          )}
        </div>
      </div>
    </DialogComponent>
  );
};

export default withTranslation('common')(DialogDetail);
