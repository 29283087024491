import React from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import styles from './ButtonToggle.module.scss';

const ButtonToggle = ({ id, t, handleClick, iconClass, btnText, disabled, selected }) => {
  const selectedClass = selected ? styles.Selected : '';
  const tooltipText = selected ? t(`${btnText}-on`) : t(`${btnText}-off`);

  return (
    <TooltipComponent
      className="autosave-tooltip"
      content={tooltipText}
      cssClass="mbc-tooltip"
      offsetX={4}
      offsetY={0}
      position="BottomCenter"
      showTipPointer={false}
    >
      <button className={`${styles.Toggle} ${selectedClass}`} disabled={disabled} id={id} onClick={handleClick} type="button">
        {iconClass && <i className={`di ${iconClass}`} />}
        <span className={styles.Text}>{t(btnText)}</span>
      </button>
    </TooltipComponent>
  );
};

export default React.memo(
  withTranslation('common')(ButtonToggle),
  (prev, next) => prev.disabled === next.disabled && prev.selected === next.selected,
);
