import { axiosInstance } from './serviceConstants';

const datasetWFUrl = '/backend/ms-dataset-workflow/api/v1';

export const getDatasetworkflowData = (dataSetId: number, dataSetVersion: string) =>
  axiosInstance.get(`${datasetWFUrl}/dataset/workflow/datasetworkflow`, { params: { dataSetId, dataSetVersion } });

export const getDatasetReleaseMenu = (dataSetId: number, dataSetVersion: string, diagramId: number, diagramTypeEnum: string) =>
  axiosInstance.get(`${datasetWFUrl}/dataset/workflow/release-menu`, {
    params: { dataSetId, dataSetVersion, diagramId, diagramTypeEnum },
  });

export const getDatasetWorkflowTypes = (workflowTypeCode: string) =>
  axiosInstance.get(`${datasetWFUrl}/dataset/workflow/types`, { params: { workflowTypeCode } });

export const datasetSendForRelease = (params: { idDataSet: number; workflowTypeCode: string }) =>
  axiosInstance.post(`${datasetWFUrl}/dataset/workflow/send-for-release`, { ...params });

export const rejectDatasetWorkflow = (dataSetId: number, dataSetVersion: string, rejectionReason: string) =>
  axiosInstance.put(`${datasetWFUrl}/dataset/workflow/reject?dataSetId=${dataSetId}&dataSetVersion=${dataSetVersion}`, {
    rejectionReason,
  });

export const approveDataSetWorkflow = (dataSetId: number, dataSetVersion: string) =>
  axiosInstance.put(`${datasetWFUrl}/dataset/workflow/approve?dataSetId=${dataSetId}&dataSetVersion=${dataSetVersion}`);

export const backToGroupDataset = (dataSetId: number, dataSetVersion: string, reason: string) =>
  axiosInstance.put(`${datasetWFUrl}/dataset/workflow/back-to-group`, { dataSetId, dataSetVersion, reason });

export const delegateDataSetWorkflow = (
  commentary: string,
  dataSetId: number,
  dataSetVersion: string,
  userCodeToList: [string],
  userOrGroup: string,
) =>
  axiosInstance.put(`${datasetWFUrl}/dataset/workflow/delegate`, {
    commentary,
    dataSetId,
    dataSetVersion,
    userCodeToList,
    userOrGroup,
  });
