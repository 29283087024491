import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DELETE_BUTTON, REDIRECT_BUTTON } from 'assets/constants/ActionButtons';
import {
  ACTIVITY_LEVEL,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  IT_SYSTEM_SWIMLANE,
  NEW,
  NOT_TRANSLATABLE,
  ROLE_SWIMLANE,
} from 'assets/constants/constants';
import ActionBox from 'components/ActionBox/ActionBox';
import ActivityProcessesDialog from 'components/ActivityProcessesDialog/ActivityProcessesDialog';
import ActivitySpecification from 'components/ActivitySpecification/ActivitySpecification';
import ButtonsPanel from 'components/ButtonsPanel/ButtonsPanel';
import CircleConnector from 'components/CircleConnector/CircleConnector';
import ExpandableBox, { BoxPosition } from 'components/ExpandableBox/ExpandableBox';
import Pill from 'components/ObjectCatalogBox/Pill/Pill';
import ShowMoreBox, { ShowMoreElement } from 'components/ShowMoreBox/ShowMoreBox';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import { ActivitySpecificationProvider } from 'contexts/ActivitySpecification/ActivitySpecificationContext';
import useAuth from 'hooks/useAuth';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useFormTypes from 'hooks/useFormTypes';
import { ActionIcon } from 'types/actionIcon';
import { ObjectIOSubTypes } from 'types/administration';
import { Language } from 'types/config';
import { DialogType, DialogInfo, DeleteDialogPrefixes } from 'types/dialogs';
import { Attributes, Care } from 'types/forms';
import { SwimlaneV2 } from 'types/swimlanes';
import { SymbolObject, Symbol, SymbolTypes, ActivityProcessesSymbol, ProcessStepSymbol } from 'types/symbols';

import DeleteDialog from '../DeleteDialog/DeleteDialog';
import DialogHeader from '../DialogHeader/DialogHeader';
import DialogNEPOS from '../DialogNEPOS/DialogNEPOS';
import TextButton from '../TextButton/TextButton';
import styles from './DialogActivity.module.scss';

type Props = {
  handleActivityDialog: (type: ActivityActions, process?: ProcessStepSymbol | Symbol, confirmationValue?: string) => void;
  symbol: ProcessStepSymbol;
  selectedSwimlane?: SwimlaneV2;
};
interface VisualProcess {
  id: string;
  name: string;
}
interface WarningDialogType {
  body: DialogInfo;
  message: string;
}

enum ProcessType {
  PREDECESSOR,
  SUCCESSOR,
}

export enum ActivityActions {
  CLOSE,
  DELETE,
  REDIRECTION,
  UPDATE,
}

const DialogActivity = ({ handleActivityDialog, symbol, selectedSwimlane }: Props) => {
  const { t, i18n } = useTranslation();
  const { processData, diagramLanguage } = useDiagramContext();
  const { isFreezed } = useFeatureFlags();
  const { userInfo } = useAuth();
  const { getFormTypesCode, fetchFormTypes } = useFormTypes();
  const [showProcessDialog, setShowProcessDialog] = useState<ProcessType>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>();
  const [warningDialogOpen, setWarningDialogOpen] = useState<WarningDialogType | null>();

  const [inputs, setInputs] = useState<SymbolObject[]>([]);
  const [outputs, setOutputs] = useState<SymbolObject[]>([]);
  const MAX_ACTIVITY_ITEMS = 3;

  const [predecessors, setPredecessors] = useState<VisualProcess[]>([]);
  const [successors, setSuccessors] = useState<VisualProcess[]>([]);

  const formCode = getFormTypesCode({
    isGroupFetch: true,
    type: ACTIVITY_LEVEL,
    status: NEW,
  });

  const InitialValuesFormat = Object.keys({ ...symbol?.activitySpecification?.attributes }).reduce(
    (value: Attributes, attribute) =>
      attribute !== NOT_TRANSLATABLE
        ? {
            ...value,
            [attribute]: { ...value[attribute], ACTIVITY_DESCRIPTION: symbol.attributes[attribute].OBJECT_DESCRIPTION },
          }
        : value,

    { ...symbol?.activitySpecification?.attributes },
  );

  const { fields, values } = useBackendForm({
    formCode,
    initialValues: InitialValuesFormat,
    usesDiagramTranslation: true,
  });

  const processStepsCollection: ActivityProcessesSymbol[] =
    processData?.swimlanes.reduce(
      (array: Symbol[], swimlane: SwimlaneV2) =>
        [
          ...array,
          ...swimlane.symbols?.filter((symb) => symb.type === SymbolTypes.PROCESS_STEP && symb.id !== symbol.id && !symb.isNew),
        ].map((process) => ({
          ...process,
          role: swimlane.role,
          isSelected: false,
        })),
      [],
    ) || [];

  const processStepsRoles: SymbolObject[] =
    symbol.objects?.filter((object) => object.type === ROLE_SWIMLANE && object.id === selectedSwimlane?.role?.id) || [];

  const processStepsItSystems: SymbolObject[] = symbol.objects?.filter((object) => object.type === IT_SYSTEM_SWIMLANE) || [];

  const mapProcessToView = (process: Symbol) => ({
    id: process.id,
    name: process.attributes[diagramLanguage]?.OBJECT_NAME?.toString() || '',
  });

  const getVisualProcesses = (processes: string[] = []): VisualProcess[] =>
    processStepsCollection.filter((process) => processes.includes(process.id)).map(mapProcessToView);

  useEffect(() => {
    fetchFormTypes(formCode);
    setPredecessors(getVisualProcesses(symbol.activitySpecification?.predecessors));
    setSuccessors(getVisualProcesses(symbol.activitySpecification?.successors));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveActivitySpecification = () =>
    symbol.activitySpecification &&
    handleActivityDialog(ActivityActions.UPDATE, {
      ...symbol,
      activitySpecification: {
        ...symbol.activitySpecification,
        attributes: values,
        predecessors: predecessors.map((elem) => elem.id),
        successors: successors.map((elem) => elem.id),
      },
    });

  const warningDialogBody = {
    title: t('activity.warning'),
    type: DialogType.Warning,
    buttons: [
      {
        id: 'activity-warning-confirm',
        handleClick: () => {
          setWarningDialogOpen(null);
        },
        content: t('confirm'),
        buttonStyle: BUTTON_PRIMARY,
      },
    ],
  };

  const warningUnsaved = {
    title: t('activity.unSaved'),
    type: DialogType.Warning,
    buttons: [
      {
        id: 'activity-warning-save',
        handleClick: () => saveActivitySpecification(),
        content: t('save'),
        buttonStyle: BUTTON_PRIMARY,
      },
      {
        id: 'activity-save-discard',
        handleClick: () => {
          setWarningDialogOpen(null);
          handleActivityDialog(ActivityActions.CLOSE);
        },
        content: t('discard'),
        buttonStyle: BUTTON_SECONDARY,
      },
      {
        id: 'activity-save-cancel',
        handleClick: () => {
          setWarningDialogOpen(null);
        },
        content: t('cancel'),
        buttonStyle: BUTTON_SECONDARY,
      },
    ],
  };

  const editButton = {
    buttonStyle: BUTTON_PRIMARY,
    content: t('activity.save_changes', { lng: diagramLanguage }),
    handleClick: () => saveActivitySpecification(),
    key: 'confirm',
    id: 'save-activity-specification',
  };

  const deleteButton = {
    buttonStyle: BUTTON_SECONDARY,
    content: t('activity.delete_specification', { lng: diagramLanguage }),
    handleClick: () => setIsDeleteDialogOpen(true),
    key: 'delete-activity-specification',
    id: 'delete-activity-specification',
  };

  const buttons =
    processData?.attributes[NOT_TRANSLATABLE]?.AUTHOR?.includes(userInfo.code) ||
    processData?.attributes[NOT_TRANSLATABLE]?.PROCESS_RESPONSIBLE?.includes(userInfo.code)
      ? [editButton, deleteButton]
      : [editButton];

  const renderPills = (pillsInfo: SymbolObject[]): React.ReactNode =>
    pillsInfo.slice(0, MAX_ACTIVITY_ITEMS).map((pill) => (
      <Pill
        icon={pill.subtype === ObjectIOSubTypes.INPUT ? 'herunterladen' : 'hochladen'}
        iconTooltip={
          !pill.approved
            ? {
                icon: styles.New,
                content: t('processStep.object.tooltip.new', {
                  objectX: t(`diagram.objects.${pill.subtype === ObjectIOSubTypes.INPUT ? 'input' : 'output'}`, {
                    lng: diagramLanguage,
                  }),
                }),
              }
            : undefined
        }
        key={pill.id}
        text={pill.attributes?.[diagramLanguage]?.NAME?.toString() || ''}
      />
    ));

  useEffect(() => {
    setInputs(symbol?.objects?.filter((object: SymbolObject) => object.subtype === ObjectIOSubTypes.INPUT) || []);
    setOutputs(symbol?.objects?.filter((object: SymbolObject) => object.subtype === ObjectIOSubTypes.OUTPUT) || []);
  }, [symbol?.objects]);

  const mapObjectsToShowMoreElement = (object: SymbolObject): ShowMoreElement => ({
    value: object.attributes?.[diagramLanguage]?.NAME?.toString() || '',
  });

  const redirectActivity = (processId: string) => {
    const selectedProcess = processStepsCollection?.find((process) => process.id === processId);
    handleActivityDialog(ActivityActions.REDIRECTION, selectedProcess);
  };

  const deletePredecessorProcess = (processId: string) => {
    setPredecessors(predecessors.filter((process) => process.id !== processId));
  };

  const deleteSuccessorProcess = (processId: string) => {
    setSuccessors(successors.filter((process) => process.id !== processId));
  };

  const deleteProcess = (processId: string, type: ProcessType) => {
    if (type === ProcessType.PREDECESSOR) {
      deletePredecessorProcess(processId);
    } else {
      deleteSuccessorProcess(processId);
    }
  };

  const mapProccessToShowMoreElement = (process: VisualProcess, type: ProcessType) => {
    const linkAction = {
      icon: 'icon-link-extern',
      key: 'link',
      onClick: () => redirectActivity(process.id),
    };

    return {
      value: process.name,
      actions: processData?.isOnlyRead
        ? [linkAction]
        : [
            {
              icon: 'icon-muelleimer-loeschen',
              key: 'delete',
              onClick: () => deleteProcess(process.id, type),
            },
            linkAction,
          ],
    };
  };

  const setExistingProcess = (process: ActivityProcessesSymbol, processes: VisualProcess[]): ActivityProcessesSymbol => ({
    ...process,
    isSelected: processes.some((activityProcess) => activityProcess.id === process.id),
  });

  const isAlreadyAdded = (processesToAdd: VisualProcess[], processesAlreadySelected: VisualProcess[]) =>
    processesToAdd.some((selected: VisualProcess) =>
      processesAlreadySelected.some((existingProcess: VisualProcess) => existingProcess.id === selected.id),
    );

  const handleActivityProcess = (
    setProcesses: React.Dispatch<React.SetStateAction<VisualProcess[]>>,
    processesAlreadySelected: VisualProcess[],
    processesToAdd?: ActivityProcessesSymbol[],
  ) => {
    if (!processesToAdd) return;
    const newVisualProcesses: VisualProcess[] = processesToAdd.filter((process) => process.isSelected).map(mapProcessToView);
    if (!isAlreadyAdded(newVisualProcesses, processesAlreadySelected)) {
      setProcesses(newVisualProcesses);
    } else {
      setWarningDialogOpen({ body: warningDialogBody, message: 'warningMessageRepeatProcess' });
    }
  };

  const renderProcesses = (type: ProcessType): React.ReactNode =>
    (type === ProcessType.PREDECESSOR ? predecessors : successors).slice(0, MAX_ACTIVITY_ITEMS).map((process) => {
      const innerIcon: ActionIcon = {
        ...REDIRECT_BUTTON,
        id: `activity-specification-${REDIRECT_BUTTON.id}`,
        onClick: () => redirectActivity(process.id),
      };
      const processButtons: ActionIcon[] = [
        {
          ...DELETE_BUTTON,
          id: `${process.id}-${DELETE_BUTTON.id}`,
          onClick: () => {
            deleteProcess(process.id, type);
          },
        },
      ];

      return processData?.isOnlyRead ? (
        <ActionBox id={process.id} innerIcon={innerIcon} label={process.name} />
      ) : (
        <ButtonsPanel buttons={processButtons} key={process.id}>
          <ActionBox id={process.id} innerIcon={innerIcon} label={process.name} />
        </ButtonsPanel>
      );
    });

  const isSameProcesses = (initialProcess, newValues) => {
    const processesList = newValues.map((a: { id: string }) => a.id);
    return [...initialProcess].sort().join('') === processesList.sort().join('');
  };

  const checkChangesUnSaved = () => {
    if (
      isSameProcesses(symbol.activitySpecification?.predecessors, predecessors) &&
      isSameProcesses(symbol.activitySpecification?.successors, successors) &&
      JSON.stringify(InitialValuesFormat) === JSON.stringify(values)
    ) {
      handleActivityDialog(ActivityActions.CLOSE);
    }
    setWarningDialogOpen({ body: warningUnsaved, message: 'warningSavingMessage' });
  };

  const handleDeleteDialogActions = (confirmationValue?: string) => {
    setIsDeleteDialogOpen(false);
    if (!confirmationValue) return;
    handleActivityDialog(ActivityActions.DELETE, symbol, confirmationValue);
  };

  return (
    <>
      <Dialog className={styles.ContainerBig}>
        <DialogHeader closeIcon handleClose={() => checkChangesUnSaved()}>
          {symbol.attributes[diagramLanguage]?.OBJECT_NAME}
        </DialogHeader>
        <div className={styles.DialogBody}>
          <ExpandableBox icons={['icon-datei-offener-antrag-links', 'icon-herunterladen']}>
            <div className={styles.TitleRow}>
              <div className={styles.Label}>
                <i className="di icon-datei-offener-antrag-links" />
                <label htmlFor="predecessors">{t('predecessor', { lng: diagramLanguage })}</label>
              </div>
              {predecessors.length > 0 && !processData?.isOnlyRead && (
                <TextButton
                  id="edit-predecessors"
                  onClick={() => setShowProcessDialog(ProcessType.PREDECESSOR)}
                  text={t('activity.editLabel')}
                />
              )}
            </div>
            <div className={styles.ProcessSpace} id="predecessors">
              {predecessors.length ? (
                renderProcesses(ProcessType.PREDECESSOR)
              ) : (
                <ActionBox
                  id="add-predecessor"
                  onClick={() => !processData?.isOnlyRead && setShowProcessDialog(ProcessType.PREDECESSOR)}
                />
              )}
            </div>
            {predecessors.length > MAX_ACTIVITY_ITEMS && (
              <ShowMoreBox
                elements={predecessors
                  .slice(MAX_ACTIVITY_ITEMS)
                  .map((process) => mapProccessToShowMoreElement(process, ProcessType.PREDECESSOR))}
              />
            )}

            <div className={styles.Label}>
              <i className="di icon-herunterladen" />
              <label htmlFor="inputs">{t('diagram.objects.input')}</label>
            </div>
            <div className={styles.InputOutpuPillsWrapper} id="inputs">
              {renderPills(inputs)}
            </div>
            {inputs.length > MAX_ACTIVITY_ITEMS && (
              <ShowMoreBox elements={inputs.slice(MAX_ACTIVITY_ITEMS).map(mapObjectsToShowMoreElement)} />
            )}
          </ExpandableBox>
          <CircleConnector />
          <ActivitySpecificationProvider>
            <ActivitySpecification
              fields={
                (processData?.isOnlyRead
                  ? fields[diagramLanguage]?.map((field) => ({ ...field, care: Care.SYSTEM, disabled: true }))
                  : fields[diagramLanguage]) || []
              }
              idActivity={symbol?.activitySpecification.id}
              itSystems={processStepsItSystems}
              processNumber={processData?.attributes?.NOT_TRANSLATABLE?.PROCESS_NUMBER?.toString()}
              roles={processStepsRoles}
              version={
                processData?.isPublishedVersion ? processData?.attributes?.NOT_TRANSLATABLE?.VERSION?.toString() : undefined
              }
            />
          </ActivitySpecificationProvider>
          <CircleConnector />
          <ExpandableBox icons={['icon-datei-offener-antrag-rechts', 'icon-hochladen']} position={BoxPosition.RIGHT}>
            <div className={styles.TitleRow}>
              <div className={styles.Label}>
                <i className="di icon-datei-offener-antrag-rechts" />
                <label htmlFor="successor">{t('successor', { lng: diagramLanguage })}</label>
              </div>
              {successors.length > 0 && !processData?.isOnlyRead && (
                <TextButton
                  id="edit-sucessors"
                  onClick={() => setShowProcessDialog(ProcessType.SUCCESSOR)}
                  text={t('activity.editLabel')}
                />
              )}
            </div>
            <div className={styles.ProcessSpace} id="successor">
              {successors.length ? (
                renderProcesses(ProcessType.SUCCESSOR)
              ) : (
                <ActionBox
                  id="add-successor"
                  onClick={() => !processData?.isOnlyRead && setShowProcessDialog(ProcessType.SUCCESSOR)}
                />
              )}
            </div>
            {successors.length > MAX_ACTIVITY_ITEMS && (
              <ShowMoreBox
                elements={successors
                  .slice(MAX_ACTIVITY_ITEMS)
                  .map((process) => mapProccessToShowMoreElement(process, ProcessType.SUCCESSOR))}
                right
              />
            )}

            <div className={styles.Label}>
              <i className="di icon-hochladen" />
              <label htmlFor="outputs">{t('diagram.objects.output')}</label>
            </div>
            <div className={styles.InputOutpuPillsWrapper} id="outputs">
              {renderPills(outputs)}
            </div>
            {outputs.length > MAX_ACTIVITY_ITEMS && (
              <ShowMoreBox elements={outputs.slice(MAX_ACTIVITY_ITEMS).map(mapObjectsToShowMoreElement)} right />
            )}
          </ExpandableBox>
        </div>
        <DialogFooter buttons={processData?.isOnlyRead ? [] : buttons} extraClass={styles.Footer} />
        {isDeleteDialogOpen && (
          <DeleteDialog
            handleClick={handleDeleteDialogActions}
            objectName={(symbol?.attributes[i18n.language as Language]?.OBJECT_NAME as string) || ''}
            prefix={DeleteDialogPrefixes.ACTIVITY_SPECIFICATION}
          />
        )}
      </Dialog>
      {showProcessDialog === ProcessType.PREDECESSOR ? (
        <ActivityProcessesDialog
          handleClick={(processes) => {
            handleActivityProcess(setPredecessors, successors, processes);
            setShowProcessDialog(undefined);
          }}
          processes={processStepsCollection.map((process) => setExistingProcess(process, predecessors))}
          title={t('activity.addPredecessorProcess')}
        />
      ) : (
        showProcessDialog === ProcessType.SUCCESSOR && (
          <ActivityProcessesDialog
            handleClick={(processes) => {
              handleActivityProcess(setSuccessors, predecessors, processes);
              setShowProcessDialog(undefined);
            }}
            processes={processStepsCollection.map((process) => setExistingProcess(process, successors))}
            title={t('activity.addSuccessorProcess')}
          />
        )
      )}
      {!isFreezed && warningDialogOpen && (
        <DialogNEPOS dialog={warningDialogOpen?.body} extraClass="Modal">
          <p className={styles.DeleteDialogText}>{t(warningDialogOpen?.message)}</p>
        </DialogNEPOS>
      )}
    </>
  );
};

export default DialogActivity;
