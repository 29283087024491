import React, { useState, useEffect, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';

import { EPC_DIAGRAM_ID } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { getWorkflowTypes } from 'services/workflowServices';

import styles from './SendForRelease.module.scss';

let confirmDialog;

const SendForRelease = (props) => {
  const { t, text, icon, idDiagram, idDiagramType, idProcess, isLoading, isRecommendationActive, onOptionClick, version } = props;

  const [openClass, setOpenClass] = useState('');
  const [items, setItems] = useState([]);
  const [diagramId, setDiagramId] = useState(0);
  const timeoutRef = useRef(null);
  const { isFreezed } = useFeatureFlags();

  let objWorkflow;
  SendForRelease.handleClickOutside = () => setOpenClass('');

  const sendForRelease = () => {
    if (idDiagram && !isLoading) {
      const data = {
        idDiagram,
        workflowTypeCode: objWorkflow.code,
      };

      confirmDialog.hide();
      timeoutRef.current = window.setTimeout(() => {
        onOptionClick(data);
      }, 500);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const getWFData = (option) => {
    const diagramType = idDiagramType === EPC_DIAGRAM_ID ? t('EPC') : t('VCD');

    return {
      title: option,
      message: t('send.areYouSure', { diagramType }),
      textCancel: t('cancel'),
      textOk: 'OK',
      okClick: () => {
        sendForRelease();
      },
    };
  };

  const isDisabled = () => isFreezed || isLoading || !items || items.length === 0;

  const toggleMenu = () => {
    if (isDisabled()) {
      return;
    }

    const newClass = openClass === '' ? styles.Open : '';
    setOpenClass(newClass);
  };

  useEffect(() => {
    if (!isLoading && diagramId !== idDiagram) {
      setItems([]);
      getWorkflowTypes({ idDiagramType, idProcess, version, isRecommendationActive })
        .then((response) => {
          setItems(response.data.results);
          setDiagramId(idDiagram);
        })
        .catch((err) => {
          handleServiceError(err);
        });
    }
  }, [isLoading, idDiagramType, diagramId, idProcess, idDiagram, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const click = (code) => {
    const item = items.find((elem) => elem.code === code);

    if (item.enabled) {
      objWorkflow = Object.assign(item);
      confirmDialog = Dialog.showConfirm(getWFData(t(`sendForRelease.${item.code}`)));
    }
  };

  return (
    <div className={`${styles.Wrapper} ${openClass} ${isDisabled() ? styles.Disabled : ''}`}>
      <div className={styles.Button} id="sendForRelease" onClick={toggleMenu}>
        <span>{text}</span>
        <i className={`${icon} ${styles.Chevron}`} />
      </div>
      <div className={styles.Items}>
        {items &&
          items.map((item) => (
            <span className={item.enabled ? '' : styles.Disable} id={item.code} key={item.code} onClick={() => click(item.code)}>
              {t(`sendForRelease.${item.code}`)}
            </span>
          ))}
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => SendForRelease.handleClickOutside,
};

export default React.memo(
  onClickOutside(withTranslation('common')(SendForRelease), clickOutsideConfig),
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
);
