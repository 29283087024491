import React from 'react';

import { Validators } from 'react-reactive-form';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import Multiselection from '../../components/UI/Multiselection/Multiselection';
import TextArea from '../../components/UI/TextArea/TextArea';

export const getTransferDialogFieldConfig = (t) => ({
  controls: {
    [Constants.NEW_ASSIGNEE]: {
      render: (props) => <Multiselection {...props} />,
      meta: {
        label: t('recommendations.view.transfer.assigneeLabel'),
        description: false,
        placeholder: t('recommendations.view.transfer.assigneeLabel'),
        attributeType: 'M',
        id: Constants.NEW_ASSIGNEE,
        valuesLoaded: [],
        max: 1,
        searchBy: Constants.SEARCH_BY.USER,
      },
      options: {
        validators: [Validators.required, Utils.checkWhiteSpacesValidation],
      },
    },
    [Constants.REASON]: {
      render: (props) => <TextArea {...props} />,
      meta: {
        label: t('recommendations.view.transfer.commentLabel'),
        description: false,
        placeholder: t('recommendations.view.transfer.commentLabel'),
        attributeType: 'M',
      },
      options: {
        validators: [Validators.required, Utils.checkWhiteSpacesValidation],
      },
    },
  },
});

export default getTransferDialogFieldConfig;
