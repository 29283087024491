import { CSSProperties, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import Portal from 'components/UI/Portal/Portal';
import useOuterClick from 'hooks/useOuterClick';
import { Coordinate } from 'types/diagram';

import styles from './DropdownPanel.module.scss';

const root = document.getElementById('dropdown-panel-root') as HTMLDivElement;

type DropdownPanelProps = {
  children: ReactNode;
  className?: string;
  close: () => void;
  parentRef: RefObject<HTMLElement>;
  width?: string | number;
  position?: Coordinate.TOP | Coordinate.BOTTOM;
  horizontalPosition?: Coordinate.LEFT | Coordinate.RIGHT;
  style?: CSSProperties;
  wrapperClassName?: string;
};

const DropdownPanel = ({
  children,
  className = '',
  close,
  parentRef,
  width,
  position = Coordinate.BOTTOM,
  horizontalPosition = Coordinate.LEFT,
  style = {},
  wrapperClassName = '',
}: DropdownPanelProps) => {
  const [coords, setCoords] = useState({});
  const ref = useRef<HTMLDivElement>(null);

  const handleOuterClick = useCallback(
    (event) => {
      if (ref.current?.contains(event.target) || parentRef.current?.contains(event.target)) return;
      close();
    },
    [close, parentRef],
  );
  useOuterClick(ref, handleOuterClick);

  useEffect(() => {
    const calculateCoords = () => {
      if (!parentRef.current) return;
      const parentBounds = parentRef.current.getBoundingClientRect();
      const verticalCoordinate =
        position === Coordinate.BOTTOM
          ? { top: parentBounds.y + parentBounds.height + window.scrollY }
          : { bottom: window.innerHeight - parentBounds.y + window.scrollY };

      const widthCoordinate = position === Coordinate.BOTTOM ? { width: width || parentBounds.width || Coordinate.BOTTOM } : {};

      setCoords({
        left:
          horizontalPosition === Coordinate.LEFT ? parentBounds.x : parentBounds.x - (width ? +width : 0) + parentBounds.width,
        ...verticalCoordinate,
        ...widthCoordinate,
      });
    };

    const handleScroll = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) return;

      close();
    };

    calculateCoords();
    window.addEventListener('resize', calculateCoords);
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('resize', calculateCoords);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [close, horizontalPosition, parentRef, position, width]);

  return (
    <Portal root={root}>
      <div className={`${styles.Panel} ${wrapperClassName}`} ref={ref} style={{ ...coords, ...style }}>
        <div className={className}>{children}</div>
      </div>
    </Portal>
  );
};

export default DropdownPanel;
