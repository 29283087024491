import { DragEvent, useCallback } from 'react';

import clone from 'just-clone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  NOT_TRANSLATABLE,
  SYMBOL,
  SWIMLANE_MAX_WIDTH,
  LINK_RESPONSIBILITY_IDS,
  BUTTON_SECONDARY,
  BUTTON_PRIMARY,
  PROCESS_STEP,
} from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { checkEmptyAttributes, cloneObject } from 'assets/js/Utils';
import {
  createInSwimlane,
  createNewSwimlane,
  deleteSwimlane,
  setSwimlaneSymbolObjects,
  deleteSwimlaneSymbolObject,
  setSwimlaneRole,
  updateSwimlaneSymbols,
  deleteSwimlaneSymbolConnectors,
  duplicateSymbolInSwimlane,
  movingSwimlaneSymbols,
  DiagramActionTypes,
  setSymbolObjectsToDisplay,
} from 'contexts/Diagram/DiagramContext';
import useConnector from 'hooks/useConnector';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useError from 'hooks/useError';
import useFormTypes from 'hooks/useFormTypes';
import useSymbol from 'hooks/useSymbol';
import { checkIsSymbolPredecessorOrSuccessor, getActivityValues } from 'services/design';
import { ObjectIOSubTypes, ObjectTypes, Role } from 'types/administration';
import { Coordinate, Coordinates, PaletteActions } from 'types/diagram';
import { Attributes, AttributeCode } from 'types/forms';
import { createNewSwimlaneNEPOS, ElementContextMenu, SwimlaneV2 } from 'types/swimlanes';
import { ProcessStepObjectsOrderType, ORDER_OBJECTS_PROCESS_STEP, Symbol, SymbolObject, SymbolTypes } from 'types/symbols';

const SYMBOL_TYPE = {
  [PaletteActions.NONE]: SymbolTypes.SIPOC_ELEMENT,
  [PaletteActions.ADD_SYMBOL]: SymbolTypes.SIPOC_ELEMENT,
  [PaletteActions.ADD_TEXTFIELD]: SymbolTypes.PALETTE_TEXTFIELD,
  [PaletteActions.ADD_SWIMLANE]: SymbolTypes.SWIMLANE_ELEMENT,
  [PaletteActions.ADD_AND_GATE]: SymbolTypes.AND_GATE,
  [PaletteActions.ADD_OR_GATE]: SymbolTypes.OR_GATE,
  [PaletteActions.ADD_XOR_GATE]: SymbolTypes.XOR_GATE,
  [PaletteActions.ADD_PROCESS_INTERFACE]: SymbolTypes.PROCESS_INTERFACE,
  [PaletteActions.ADD_PROCESS_STEP]: SymbolTypes.PROCESS_STEP,
  [PaletteActions.ADD_EVENT]: SymbolTypes.EVENT,
};

export default function useSwimlane() {
  const { dispatch, isMovingSwimlaneSymbol, paletteAction, processData } = useDiagramContext();
  const { t } = useTranslation();
  const { updateSwimlanesConnectorsVertices } = useConnector();
  const { handleDrop } = useDragAndDrop();
  const { showAlert } = useError();
  const { getFormTypesCode, getInitialValues, ...formTypes } = useFormTypes();
  const { getProcessStepNumber, checkDeleteSymbol } = useSymbol();
  const getSymbolTypeByAction = (action: PaletteActions): SymbolTypes => SYMBOL_TYPE[action];

  const getProcessStepObjectsOrderType = (object: SymbolObject) => {
    switch (object.type) {
      case ObjectTypes.ROLE_SWIMLANE:
        if (object.id && object.approved) {
          switch (object.responsability) {
            case LINK_RESPONSIBILITY_IDS.RESPONSIBLE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RESPONSIBLE;
            case LINK_RESPONSIBILITY_IDS.CONSULTED:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_CONSULTED;
            case LINK_RESPONSIBILITY_IDS.INFORMED:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_INFORMED;
            case LINK_RESPONSIBILITY_IDS.ACCOUNTABLE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_ACCOUNTABLE;
            case LINK_RESPONSIBILITY_IDS.ACCEPTANCE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_ACCEPTANCE;
            default:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RESPONSIBLE;
          }
        } else if (object.id && !object.approved) {
          switch (object.responsability) {
            case LINK_RESPONSIBILITY_IDS.RESPONSIBLE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_RESPONSIBLE;
            case LINK_RESPONSIBILITY_IDS.CONSULTED:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_CONSULTED;
            case LINK_RESPONSIBILITY_IDS.INFORMED:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_INFORMED;
            case LINK_RESPONSIBILITY_IDS.ACCOUNTABLE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_ACCOUNTABLE;
            case LINK_RESPONSIBILITY_IDS.ACCEPTANCE:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_ACCEPTANCE;
            default:
              return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RESPONSIBLE;
          }
        } else {
          return ProcessStepObjectsOrderType.ROLE_SWIMLANE_TEMPORARY;
        }
      case ObjectTypes.IT_SYSTEM_SWIMLANE: {
        if (object.id && object.approved) {
          return ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE;
        }
        if (object.id && !object.approved) {
          return ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE_REQUEST;
        }
        return ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE_TEMPORARY;
      }
      case ObjectTypes.IO_SWIMLANE: {
        if (object.subtype === ObjectIOSubTypes.INPUT && object.approved) {
          return ProcessStepObjectsOrderType.INPUT;
        }
        if (object.subtype === ObjectIOSubTypes.INPUT && !object.approved) {
          return ProcessStepObjectsOrderType.INPUT_NEW;
        }
        if (object.subtype === ObjectIOSubTypes.OUTPUT && object.approved) {
          return ProcessStepObjectsOrderType.OUTPUT;
        }
        return ProcessStepObjectsOrderType.OUTPUT_NEW;
      }
      default:
        return ProcessStepObjectsOrderType.ROLE_SWIMLANE_RESPONSIBLE;
    }
  };
  const getOrderedObjects = (unsortedArray: SymbolObject[]) => {
    const sortedObjects = unsortedArray.sort((n1, n2) => {
      const resultOrder =
        ORDER_OBJECTS_PROCESS_STEP.indexOf(getProcessStepObjectsOrderType(n1)) -
        ORDER_OBJECTS_PROCESS_STEP.indexOf(getProcessStepObjectsOrderType(n2));
      if (resultOrder === 0) {
        if (n1.meta.pos > n2.meta.pos) {
          return 1;
        }
        if (n1.meta.pos < n2.meta.pos) {
          return -1;
        }
        return 0;
      }
      return resultOrder;
    });

    sortedObjects.forEach((value, index) => {
      value.meta.pos = index;
    });

    return sortedObjects;
  };
  const createSwimlane = (position: number, event: DragEvent<HTMLDivElement>) => {
    const type = getSymbolTypeByAction(paletteAction);
    if (!type) return;

    if (type === SymbolTypes.SWIMLANE_ELEMENT) {
      dispatch(createNewSwimlane(createNewSwimlaneNEPOS(position)));
      setTimeout(updateSwimlanesConnectorsVertices);
    } else if (type === SymbolTypes.PALETTE_TEXTFIELD) {
      handleDrop(event);
    } else {
      showAlert(t('dropSymbolError'));
      dispatch(movingSwimlaneSymbols(false));
    }
  };

  const createSwimlaneBelow = (position: number) => {
    dispatch(createNewSwimlane(createNewSwimlaneNEPOS(position + 1)));
    setTimeout(updateSwimlanesConnectorsVertices);
  };

  const createSymbol = useCallback(
    ({ top, left }: Coordinates, swimlaneId: string) => {
      if (!processData) return;
      const selectedSwimlane = processData.swimlanes.find((swimlane) => swimlane.id === swimlaneId);
      if (!selectedSwimlane) return;
      const symbolType = getSymbolTypeByAction(paletteAction);
      const attributes = getInitialValues(
        formTypes[
          getFormTypesCode({
            status: processData.status,
            type: SYMBOL,
            variant: symbolType,
          })
        ],
      );

      const newSymbol: Symbol = {
        attributes,
        id: `SWIMLANE_${symbolType}${uuidv4()}`,
        meta: {
          top,
          left,
          height: 0,
          width: 0,
        },
        type: symbolType,
        isNew: true,
      };

      if (symbolType === SymbolTypes.PROCESS_STEP) {
        newSymbol.activitySpecificationId = -1;

        if (!attributes?.[NOT_TRANSLATABLE]?.[AttributeCode.PROCESS_STEP_NUMBER]) {
          attributes[NOT_TRANSLATABLE][AttributeCode.PROCESS_STEP_NUMBER] = getProcessStepNumber();
        }
      }

      if (![SymbolTypes.PALETTE_TEXTFIELD, SymbolTypes.SWIMLANE_ELEMENT].includes(symbolType))
        dispatch(createInSwimlane(newSymbol, swimlaneId));
    },
    [dispatch, formTypes, getFormTypesCode, getInitialValues, getProcessStepNumber, paletteAction, processData],
  );

  const deleteSwimlaneNode = useCallback(
    (swimlaneId: string, symbolId: string) => {
      dispatch(updateSwimlaneSymbols(swimlaneId, symbolId));
      dispatch(deleteSwimlaneSymbolConnectors(symbolId));
    },
    [dispatch],
  );

  const checkDeleteSwimlaneSymbol = useCallback(
    (symbolId: string, selectedSwimlane?: SwimlaneV2, startEventsIds: string[] = [], lastEventIds: string[] = []) => {
      if (!processData) return;

      const selectedSymbol = selectedSwimlane?.symbols.find((symbol) => symbol.id === symbolId);

      if (startEventsIds && startEventsIds.length <= 1 && startEventsIds?.find((eventId) => eventId === selectedSymbol?.id))
        return showAlert(t('lastStartEvent'));

      if (selectedSymbol?.diagramLink?.id) return showAlert(t('errors.swimlaneDiagramLink'));

      if (!selectedSwimlane) return;

      if (selectedSymbol?.type === SymbolTypes.PROCESS_STEP)
        dispatch({
          type: DiagramActionTypes.SET_PROCESS_STEP_WITH_ERRORS,
          payload: [],
        });

      const isStartEvent = startEventsIds.some((eventId) => eventId === selectedSymbol?.id);
      const isLastEventAndThereIsMore = lastEventIds.length > 1 && lastEventIds.some((eventId) => eventId === selectedSymbol?.id);

      if (isStartEvent || isLastEventAndThereIsMore) {
        dispatch({
          type: DiagramActionTypes.SET_WARNING,
          payload: {
            buttons: [
              {
                id: `cancel-delete`,
                handleClick: () => dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null }),
                content: t(`cancel`),
                buttonStyle: BUTTON_SECONDARY,
              },
              {
                id: `confirm-delete`,
                handleClick: () => {
                  deleteSwimlaneNode(selectedSwimlane.id, symbolId);
                  dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null });
                },
                content: t(`confirm`),
                buttonStyle: BUTTON_PRIMARY,
              },
            ],
            message: isStartEvent ? t('deleteStartEventWarningMsg') : t('deleteLastEventWarningMsg'),
          },
        });

        return;
      }

      if (selectedSymbol?.activitySpecificationId && selectedSymbol.id.includes(PROCESS_STEP)) {
        getActivityValues(selectedSymbol.id)
          .then((response) => {
            if (
              response.data?.activitySpecification?.attributes &&
              checkEmptyAttributes(response.data.activitySpecification.attributes)
            ) {
              dispatch({
                type: DiagramActionTypes.SET_WARNING,
                payload: {
                  buttons: [
                    {
                      id: `activity-specification-cancel-delete`,
                      handleClick: () => dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null }),
                      content: t(`cancel`),
                      buttonStyle: BUTTON_SECONDARY,
                    },
                    {
                      id: `activity-specification-confirm-delete`,
                      handleClick: () => {
                        deleteSwimlaneNode(selectedSwimlane.id, symbolId);
                        dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null });
                      },
                      content: t(`confirm`),
                      buttonStyle: BUTTON_PRIMARY,
                    },
                  ],
                  message: t('deleteActivitySpecificationError'),
                },
              });
            } else {
              deleteSwimlaneNode(selectedSwimlane.id, symbolId);
            }
          })
          .catch((error) => {
            handleServiceError(error);
          });
      } else {
        deleteSwimlaneNode(selectedSwimlane.id, symbolId);
      }
    },
    [dispatch, processData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const duplicateSwimlaneSymbol = useCallback(
    (symbolId: string, swimlaneId?: string, swimlaneSymbols?: Symbol[]) => {
      if (!swimlaneId || !swimlaneSymbols) return;

      const symbol = swimlaneSymbols.find((element) => element.id === symbolId);

      if (!symbol) return;

      const rowSymbols = swimlaneSymbols.filter((sym) => sym.meta.top === symbol.meta.top);
      const mappedGaps = rowSymbols.map((sym) => sym.meta.left);

      if (mappedGaps) {
        const maxGap = Math.max(...mappedGaps);

        // As meta top and left are indexes starting at 0,
        // the maximum one is (SWIMLANE_MAX_WIDTH - 1)
        if (maxGap === SWIMLANE_MAX_WIDTH - 1) {
          showAlert(t('swimlane.maximumWidthReached'));
          return;
        }
      }

      const newSymbol = cloneObject(symbol);

      if (newSymbol.type === SymbolTypes.PROCESS_STEP) {
        delete newSymbol.activitySpecificationId;
        if (newSymbol.attributes[NOT_TRANSLATABLE]) {
          newSymbol.attributes[NOT_TRANSLATABLE]!.PROCESS_STEP_NUMBER = getProcessStepNumber();
        }
      }

      // If we copy an event, we set its 'LAST_EVENT' and 'START_EVENT' property to false (we can
      // only have one last event)
      if (newSymbol.type === SymbolTypes.EVENT && newSymbol.attributes[NOT_TRANSLATABLE]) {
        newSymbol.attributes[NOT_TRANSLATABLE]!.START_EVENT = false;
        newSymbol.attributes[NOT_TRANSLATABLE]!.LAST_EVENT = false;
        delete newSymbol.supplier;
        delete newSymbol.objects;
      }

      dispatch(duplicateSymbolInSwimlane(newSymbol, swimlaneId));
    },
    [dispatch, processData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const moveSymbol = useCallback(
    ({ top, left }: Coordinates, symbol: Symbol, targetSwimlaneId: string, originalSwimlane?: SwimlaneV2) => {
      if (!processData) return;
      const targetSymbol = clone(symbol);
      targetSymbol.meta.top = top;
      targetSymbol.meta.left = left;
      const originalSwimlaneId = originalSwimlane?.id || targetSwimlaneId;
      if (originalSwimlane)
        targetSymbol.objects = targetSymbol.objects?.filter((roleObject) => roleObject.id !== originalSwimlane?.role?.id);

      if (originalSwimlaneId && symbol.id) {
        dispatch(updateSwimlaneSymbols(originalSwimlaneId, symbol.id));
        setTimeout(() => updateSwimlanesConnectorsVertices({ symbolId: symbol.id }));
        dispatch(createInSwimlane(targetSymbol, targetSwimlaneId));
      }
    },
    [dispatch, processData, updateSwimlanesConnectorsVertices],
  );

  const moveSwimlaneSymbol = useCallback(
    (coordinates: Coordinates, targetSwimlaneId: string) => {
      if (!processData) return;
      const { symbolId, swimlaneId } = isMovingSwimlaneSymbol;
      const originalSwimlane = processData.swimlanes.find((swimlane) => swimlane.id === swimlaneId);

      const symbol = originalSwimlane?.symbols.find((element: { id: string }) => element.id === symbolId);

      if (symbol) {
        moveSymbol(coordinates, symbol, targetSwimlaneId, originalSwimlane);
      }
    },
    [isMovingSwimlaneSymbol, processData, moveSymbol],
  );

  const moveSymbolsAsideFromYPosition = useCallback(
    (left: number): void => {
      const symbolsToMove = processData?.swimlanes.map((swimlane) => ({
        ...swimlane,
        symbols: swimlane.symbols
          .filter((s) => s.meta.left >= left && !(swimlane?.meta.pos === 0 && s?.meta.left === 0 && s?.meta.top === 0))
          .sort((a, b) => b.meta[Coordinate.LEFT] - a.meta[Coordinate.LEFT]),
      }));
      symbolsToMove?.forEach((swimlane) => {
        const symbolsAreConnected = processData?.connectors.some((connector) =>
          swimlane.symbols.some((symbol) => symbol.id === connector.source.id || symbol.id === connector.target.id),
        );
        swimlane.symbols.forEach(async (symbol) => {
          if (symbolsAreConnected) {
            setTimeout(() => {
              moveSymbol({ top: symbol.meta.top, left: symbol.meta.left + 1 }, symbol, swimlane.id, swimlane);
            });
          } else {
            moveSymbol({ top: symbol.meta.top, left: symbol.meta.left + 1 }, symbol, swimlane.id, swimlane);
          }
        });
      });
    },
    [moveSymbol, processData?.swimlanes, processData?.connectors],
  );

  const deleteSelectedSwimlane = (swimlaneId: string) => {
    if (!processData) return;
    setTimeout(updateSwimlanesConnectorsVertices); // eslint-disable-line @typescript-eslint/no-implied-eval
    dispatch(deleteSwimlane(swimlaneId));
  };

  const addOrderedObjectToSymbol = (symbolId: string, newObject: SymbolObject) => {
    const swimlanesClone = cloneObject(processData?.swimlanes);
    const allSymbols = swimlanesClone.reduce((prev: Symbol[], next: SwimlaneV2) => prev.concat(next.symbols), []);
    const selectedProcessStep = allSymbols.find((symbol: Symbol) => symbol.id === symbolId);
    let unsortedObjects: SymbolObject[] = selectedProcessStep.objects || [];

    selectedProcessStep.objects = selectedProcessStep.objects ? [...selectedProcessStep.objects, newObject] : [newObject];

    if (newObject.responsability === 1) {
      unsortedObjects = unsortedObjects?.filter((ob) => !(ob.type === ObjectTypes.ROLE_SWIMLANE && ob.responsability === 1));
    }
    if (newObject.responsability !== 1 || newObject.id) {
      unsortedObjects.push(newObject);
    }

    const sortedObjects = getOrderedObjects(unsortedObjects);

    dispatch(setSwimlaneSymbolObjects(symbolId, sortedObjects));
    setTimeout(updateSwimlanesConnectorsVertices);
  };

  const setRole = (swimlaneId: string, role: Role) => {
    const newProcessStepObject: SymbolObject = {
      id: role.id ? role.id : undefined,
      type: ObjectTypes.ROLE_SWIMLANE,
      attributes: role.attributes,
      approved: role.approved,
      subtype: undefined,
      responsability: 1,
      meta: { pos: 0 },
    };
    const clonedSwimlanes = cloneObject(processData?.swimlanes);
    const selectedSwimlane = clonedSwimlanes.find((s: SwimlaneV2) => s.id === swimlaneId);
    const processSteps: Symbol[] = selectedSwimlane.symbols.filter(
      (foundSymbol: Symbol) => foundSymbol.type === SymbolTypes.PROCESS_STEP,
    );
    processSteps.forEach((ps) => {
      addOrderedObjectToSymbol(ps.id, newProcessStepObject);
    });
    dispatch(setSwimlaneRole(swimlaneId, role));
  };

  const createTemporaryRole = (name: string): Role => {
    return {
      temporaryText: name,
    };
  };

  const setTemporaryRole = (swimlaneId: string, name: string) => {
    setRole(swimlaneId, createTemporaryRole(name));
  };

  const addSymbolTemporaryObject = (symbolId: string, name: string, type: ObjectTypes, pos: number) => {
    addOrderedObjectToSymbol(symbolId, {
      temporaryText: name,
      type,
      meta: { pos },
    });
  };

  const addSymbolObject = (
    symbolId: string,
    objectId: number,
    type: ObjectTypes,
    pos: number,
    attributes?: Attributes,
    approved?: boolean,
    subtype?: ObjectIOSubTypes,
    responsability?: number,
  ) => {
    addOrderedObjectToSymbol(symbolId, {
      id: objectId,
      type,
      attributes,
      meta: { pos },
      approved,
      subtype,
      responsability,
    });
  };

  const deleteSymbolObject = (symbolId: string, object: SymbolObject) => {
    dispatch(deleteSwimlaneSymbolObject(symbolId, object));
    setTimeout(updateSwimlanesConnectorsVertices);
  };

  const toggleShowSymbolObjects = (idSymbol: string, symbolObjectsDisplayed?: string[]) => {
    if (!symbolObjectsDisplayed) return;

    const areSymbolObjectsDisplayed = symbolObjectsDisplayed.find((symbolId: string) => symbolId === idSymbol);
    const IdsWithoutCurrentSymbol = symbolObjectsDisplayed.filter((symbolId: string) => symbolId !== idSymbol);
    const resultIds =
      areSymbolObjectsDisplayed !== undefined ? [...IdsWithoutCurrentSymbol] : [...symbolObjectsDisplayed, idSymbol];
    dispatch(setSymbolObjectsToDisplay(resultIds));
    setTimeout(updateSwimlanesConnectorsVertices); // eslint-disable-line @typescript-eslint/no-implied-eval
  };

  const getSelectedSwimlane = (swimlaneId?: string, swimlanes?: SwimlaneV2[]) => {
    if (!swimlanes) return;

    return swimlanes.find((swimlane: { id: string | undefined }) => swimlane.id === swimlaneId);
  };

  const getSelectionButtons = useCallback(
    (params: ElementContextMenu) => {
      const { diagramData, id, isOpen, onClick, symbolType } = params;
      const { isOnlyRead, swimlaneId, selectedSwimlane, startEventsIds, symbolObjectsDisplayed, lastEventsIds } = diagramData;
      const buttons = [
        {
          icon: 'di icon-plus-hinzufuegen',
          id: 'processStep-add-attributes-button',
          onClick: () => onClick && onClick(symbolType === SymbolTypes.PROCESS_STEP ? 'objects' : !isOpen),
          tooltipLabel: t('add'),
        },
        {
          icon: symbolObjectsDisplayed?.includes(id) ? 'di icon-pfeil-inhalte-reduzieren' : 'di icon-pfeil-inhalte-erweitern',
          id: 'processStep-show-attributes-button',
          onClick:
            processData?.isSwimlane && symbolType === SymbolTypes.PROCESS_STEP && onClick
              ? () => {
                  onClick();
                  toggleShowSymbolObjects(id, symbolObjectsDisplayed);
                }
              : undefined,
          tooltipLabel: symbolObjectsDisplayed?.includes(id) ? t('hideAttributes') : t('showAttributes'),
        },
        {
          icon: 'di icon-datei-doppelt-kopieren',
          id: 'processStep-copy-button',
          onClick: () => {
            duplicateSwimlaneSymbol(id, swimlaneId, selectedSwimlane?.symbols);
          },
          tooltipLabel: t('tool.copy'),
        },
        {
          icon: 'di icon-muelleimer-loeschen',
          id: 'processStep-delete-button',
          onClick: () =>
            checkIsSymbolPredecessorOrSuccessor(id)
              .then(() => {
                if (selectedSwimlane) {
                  checkDeleteSwimlaneSymbol(id, selectedSwimlane, startEventsIds, lastEventsIds);
                } else {
                  checkDeleteSymbol(id);
                }
              })
              .catch((err) => {
                handleServiceError(err);
              }),
          tooltipLabel: t('tool.delete'),
        },
      ];

      if (symbolType === SymbolTypes.PROCESS_STEP && isOnlyRead) {
        return [buttons[1]];
      }

      if (isOnlyRead) return [];

      if (symbolType === SymbolTypes.TEXT) {
        return [buttons[3]];
      }

      if (symbolType !== SymbolTypes.PROCESS_STEP) {
        return buttons.slice(2);
      }

      return buttons;
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    addSymbolObject,
    addSymbolTemporaryObject,
    createSwimlane,
    createSwimlaneBelow,
    createSymbol,
    checkDeleteSwimlaneSymbol,
    deleteSymbolObject,
    duplicateSwimlaneSymbol,
    deleteSelectedSwimlane,
    getSelectedSwimlane,
    getSelectionButtons,
    getSymbolTypeByAction,
    moveSymbolsAsideFromYPosition,
    moveSwimlaneSymbol,
    setRole,
    setTemporaryRole,
    toggleShowSymbolObjects,
  };
}
