import { useMemo } from 'react';

import styles from './SwimlaneConnector.module.scss';

type Props = {
  end?: boolean;
  length?: number;
  height?: number;
};

export const SWIMLANE_CONNECTOR_MARGIN_TOP = 2.92;
const ARROW_HEIGHT = 4;

const SwimlaneConnector = ({ end = false, length = 0, height = 0 }: Props) => {
  const connectorWidth = useMemo(() => (end ? 35 + length * 100 : 50), [end, length]);
  const hasVertices = useMemo(() => height !== 0, [height]);
  const arrowWidth = useMemo(() => (hasVertices ? 1 : 2), [hasVertices]);
  const widthFactor = useMemo(() => (end ? 4.7 : 7.15), [end]);
  const marginTop = useMemo(() => (hasVertices && height < 0 ? height : SWIMLANE_CONNECTOR_MARGIN_TOP), [hasVertices, height]);
  const marginRight = useMemo(() => (end ? -1.6 : 0), [end]);

  return (
    <>
      <hr
        className={styles.BackgroundLine}
        style={{
          width: `${widthFactor + length * 16 - (hasVertices ? 1 : 0)}em`,
          height: ARROW_HEIGHT / 4,
          left: end ? '' : `-${arrowWidth}em`,
          right: end ? '0em' : '',
          marginTop: `${SWIMLANE_CONNECTOR_MARGIN_TOP}em`,
          marginRight: `${hasVertices ? -1 : marginRight}em`,
          position: 'absolute',
        }}
      />
      {hasVertices && (
        <>
          <hr
            className={styles.BackgroundLine}
            style={{
              width: ARROW_HEIGHT / 4,
              height: `${Math.abs(height) + (height < 0 ? SWIMLANE_CONNECTOR_MARGIN_TOP : 0)}em`,
              left: end ? '' : `-${arrowWidth}em`,
              right: end ? '0em' : '',
              marginTop: `${marginTop}em`,
              marginBottom: end ? 0 : '',
              marginRight: '-1em',
              position: 'absolute',
            }}
          />

          <hr
            className={styles.BackgroundLine}
            style={{
              width: end ? '0.6em' : '1em',
              height: ARROW_HEIGHT / 4,
              left: end ? '' : `-${arrowWidth}em`,
              right: end ? `-${arrowWidth + 0.6}em` : '',
              marginLeft: '-1em',
              marginTop: `${height > 0 ? height + marginTop : height}em`,
              position: 'absolute',
            }}
          />
        </>
      )}
      <div
        className={`${styles.Connector} ${end ? styles.End : ''}`}
        style={
          end
            ? {
                width: `${widthFactor + 0.2 + length * 16}em`,
                marginTop: `${height >= 0 ? height + marginTop - 0.9 : height - 0.9}em`,
              }
            : { width: `${widthFactor + 0.2 + length * 16}em` }
        }
      >
        <svg viewBox={`0 0 ${connectorWidth} ${ARROW_HEIGHT}`} xmlns="http://www.w3.org/2000/svg">
          <polygon points={`${connectorWidth - ARROW_HEIGHT},-1 ${connectorWidth},2 ${connectorWidth - ARROW_HEIGHT},5`} />
        </svg>
      </div>
    </>
  );
};

export default SwimlaneConnector;
