import React, { useRef, useState } from 'react';

import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import * as Constants from '../../assets/constants/constants';
import Button from '../UI/Button/Button';
import styles from './SwimlaneDialog.module.scss';

const SwimlaneDialog = (props) => {
  const { createSwimlane, close } = props;
  const { t } = useTranslation();
  const [orientation, setOrientation] = useState(Constants.HORIZONTAL);
  const [rowsNumber, setRowsNumber] = useState(2);
  const [showError, setShowError] = useState(false);
  const timer = useRef(null);

  const validate = (value) => {
    clearInterval(timer.current);

    if (!value || value < 1 || value > 15) setShowError(true);
    else if (showError) setShowError(false);
  };

  const beforeShowTooltip = (event) => {
    if (!showError) event.cancel = true;
  };

  const handleChange = (event) => {
    const newValue = event.target.value ? parseInt(event.target.value, 10) : '';

    setRowsNumber(newValue);
    clearInterval(timer.current);
    timer.current = setInterval(() => validate(newValue), 1000);
  };

  const handleAddClick = () => {
    close();
    createSwimlane(rowsNumber, orientation);
  };

  const updateRowsNumber = (increment) => {
    const currentValue = rowsNumber || 0;
    setRowsNumber(currentValue + increment);
    validate(currentValue + increment);
  };

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={close}
      height="500px"
      id="dialog-swimlane"
      isModal
      target="#dialog"
      visible
      width="500px"
    >
      <div className={`${styles.Content} ${styles.LinkDiagramContent}`}>
        <div className={`${styles.Section} ${styles.SectionSwimlanes}`}>
          <h4>{t('swimlanes.dialog.title')}</h4>
          <span>{t('swimlanes.dialog.description')}</span>
          <div className={styles.Orientation}>
            <p>{t('swimlanes.dialog.orientation')}</p>
            <div className={styles.OrientationOptions}>
              <div
                className={`${styles.Option} ${orientation === Constants.HORIZONTAL ? styles.Selected : ''}`}
                onClick={() => setOrientation(Constants.HORIZONTAL)}
              >
                <i className="di icon-zeilen" />
                {t('swimlanes.dialog.horizontal')}
              </div>
              <div
                className={`${styles.Option} ${orientation === Constants.VERTICAL ? styles.Selected : ''}`}
                onClick={() => setOrientation(Constants.VERTICAL)}
              >
                <i className="di icon-spalten" />
                {t('swimlanes.dialog.vertical')}
              </div>
            </div>
          </div>
          <div className={styles.RowsNumber}>
            <p>{t('swimlanes.dialog.rowsNumber')}</p>
            <div>
              <div className={styles.Icon} onClick={() => updateRowsNumber(-1)}>
                <i className="di icon-minus" />
              </div>
              <TooltipComponent
                beforeOpen={beforeShowTooltip}
                content={t('swimlanes.dialog.error')}
                cssClass="mbc-tooltip error-tooltip"
                position="BottomCenter"
                showTipPointer={false}
                width={180}
              >
                <input
                  className={`${styles.Input} ${showError ? styles.Error : ''}`}
                  onChange={handleChange}
                  value={rowsNumber}
                />
              </TooltipComponent>
              <div className={styles.Icon} onClick={() => updateRowsNumber(1)}>
                <i className="di icon-plus-hinzufuegen" />
              </div>
            </div>
          </div>
          <div className={styles.ButtonLine}>
            <Button btnText={t('cancel')} buttonStyle="Secundary" handleClick={close} id="cancel" />
            <Button btnText={t('add')} buttonStyle="Primary" disabled={showError} handleClick={handleAddClick} id="add" />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default SwimlaneDialog;
