import axios from 'axios';

import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const designUrl = '/backend/ms-design/api';
const diagramUrl = `${designUrl}/v1/diagram`;
const diagramUrlv2 = `${designUrl}/v2/diagram`;

const deleteDiagram = (diagramId, confirmationString) =>
  axiosInstance.delete(`${diagramUrl}/send-recycle-bin/${diagramId}/`, { data: { confirmationString } });

const linkExternalDiagram = (data) => axiosInstance.post(`${diagramUrl}/link-external-diagram`, data);

const linkExternalDiagramV2 = (data) => axiosInstance.post(`${diagramUrlv2}/link-external-diagram`, data);

const moveDiagram = (body) => axiosInstance.post(`${diagramUrl}/move`, body);

const getDiagram = (diagramId) => axiosInstance.get(diagramUrl, { params: { id: diagramId } });

const getSymbolToDelete = (symbolId) => axiosInstance.get(`${designUrl}/v1/symbols/${symbolId}/delete/`);

const getDiagramTreeStructure = (isPublished) => {
  const published = isPublished ? '/published' : '';

  return axiosInstance.get(`${diagramUrl}${published}/tree-structure`);
};

const saveDiagram = (data, diagramId) => axiosInstance.put(`${diagramUrl}?idDiagram=${diagramId}`, data);

const genDocs = (extension, data, language) =>
  axiosInstance.post(`${diagramUrl}/gen-docs/on-the-fly/${extension}`, data, {
    responseType: 'blob',
    headers: { 'Accept-Language': language },
  });

const getObjectCatalog = (idObjectType) => axiosInstance.get(`${designUrl}/v1/objectCatalog?idObjectType=${idObjectType}`);

const getDiagramPublished = (processNumber, version = 'last') =>
  axiosInstance.get(`${diagramUrl}/published/${processNumber}/${version}`);

const getClosedWorkflow = (id, processNumber) => axiosInstance.get(`${diagramUrl}/${processNumber}/${id}`);

export const searchDiagrams = (params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${diagramUrl}/search?${searchParams}`);
};

const inUseDiagram = (diagramId, inUse = false) =>
  axiosInstance.put(`${diagramUrl}/in-use?idDiagram=${diagramId}&inUse=${inUse}`);

const getBasicDiagramInfo = (processNumber) => axiosInstance.get(`${diagramUrl}/basic-by?processNumber=${processNumber}`);

const getDiagramBasic = (diagramId) => axiosInstance.get(`${diagramUrl}/${diagramId}/basic`);

const reloadDiagram = (id) =>
  axios
    .all([getDiagram(id)])
    .then((data) => data.map((elem) => elem.data.results || elem.data))
    .catch(serviceUtils.handleServiceError);

const getCatalogs = (idRole, idItSystem) =>
  axios
    .all([getObjectCatalog(idRole), getObjectCatalog(idItSystem)])
    .then((data) => data.map((elem) => elem.data))
    .catch(serviceUtils.handleServiceError);

const exportDiagramXML = (diagramId, language) =>
  axiosInstance.get(`${diagramUrl}/${diagramId}/translation/export`, { params: { language } });

const importDiagramXML = (diagramId, file, language) =>
  axiosInstance.put(`${diagramUrl}/${diagramId}/translation/import`, file, { params: { language } });

export default {
  reloadDiagram,
  linkExternalDiagram,
  linkExternalDiagramV2,
  moveDiagram,
  getDiagram,
  deleteDiagram,
  getSymbolToDelete,
  saveDiagram,
  genDocs,
  getDiagramTreeStructure,
  getObjectCatalog,
  getClosedWorkflow,
  getDiagramPublished,
  handleServiceError: serviceUtils.handleServiceError,
  getCatalogs,
  searchDiagrams,
  inUseDiagram,
  getBasicDiagramInfo,
  getDiagramBasic,
  exportDiagramXML,
  importDiagramXML,
};
