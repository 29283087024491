import React from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import { cloneObject } from 'assets/js/Utils';
import DialogMultilanguage from 'components/UI/DialogMultilanguage/DialogMultilanguage';
import useForm from 'hooks/useForm';

import styles from './DialogLinkedForm.module.scss';

const DialogLinkedForm = ({ code, close, formTypes, language, submit, title, value }) => {
  const { t } = useTranslation();
  const { fields, Form, isLanguageValid, isValid, values } = useForm({ formTypes, initialValues: value, formCode: code });

  const handleSubmit = () => {
    const newValue = cloneObject(values);
    submit(newValue);
    close();
  };

  const buttons = [
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel', { lng: language }),
      handleClick: close,
      key: 'cancel',
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      content: value ? t('update', { lng: language }) : t('add', { lng: language }),
      disabled: !isValid,
      handleClick: handleSubmit,
      key: 'confirm',
    },
  ];

  return (
    <DialogMultilanguage buttons={buttons} initialLanguage={language} isLanguageValid={isLanguageValid} title={title}>
      <Form className={styles.Form} fields={fields} />
    </DialogMultilanguage>
  );
};

export default DialogLinkedForm;
