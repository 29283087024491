import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ATTRIBUTE_CARES, DATASET_LINKAGE, DIAGRAM_ENVIRONMENTS, urlRegEx } from 'assets/constants/constants';
import ErrorLabel from 'components/UI/ErrorLabel/ErrorLabel';
import FieldTooltip from 'components/UI/FieldTooltip/FieldTooltip';
import useEnvironment from 'hooks/useEnvironment';

import styles from './InputUrl.module.scss';

const InputUrlLegacy = (props) => {
  const { handler, meta, touched, hasError } = props;
  const { t } = useTranslation();
  const mandatory = meta.attributeType === ATTRIBUTE_CARES.MANDATORY ? '*' : ''; // Mandatory
  const isSystemAttribute = meta.attributeType === ATTRIBUTE_CARES.SYSTEM; // System
  // else 'O': Optional
  const isDatasetLinkage = meta.code === DATASET_LINKAGE;
  const showError = (meta.forceError || touched) && hasError('required');

  const Content = ({ value }) => {
    return (
      <div className={styles.Content}>
        {isDatasetLinkage && <i className={`di icon-link ${styles.Icon}`} />}
        <span className={styles.Value}>{value}</span>
      </div>
    );
  };

  const SystemLink = () => {
    const checkEnvironment = useEnvironment();
    return (
      <>
        {isDatasetLinkage ? (
          <div className={`form-control ${styles.Url}`}>
            {handler().value.map((dataset) => {
              return (
                <Link
                  key={`linked-document-set-${dataset.dataSetId}`}
                  to={`/${checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) ? 'dataset-published' : 'dataset'}/${
                    dataset.dataSetId
                  }`}
                >
                  <Content value={`${dataset.dataSetId} - ${dataset.name}` || '-'} />
                </Link>
              );
            })}
          </div>
        ) : (
          <a className={`form-control ${styles.Url}`} href={handler().value} rel="noopener noreferrer" target="_blank">
            <Content value={handler().value} />
          </a>
        )}
      </>
    );
  };

  const beforeShowTooltip = (event) => {
    if (!urlRegEx.test(handler().value)) {
      event.cancel = true;
    }
  };

  const onClickInput = (event) => {
    if (event.ctrlKey && urlRegEx.test(handler().value)) {
      window.open(handler().value, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <div className={`form-group ${meta.extraClass || ''}`}>
      <label className={showError ? 'error' : ''} htmlFor={meta.id}>
        {meta.label}
        {mandatory}
      </label>
      {meta.description && <FieldTooltip description={meta.description} />}
      <ErrorLabel message={`${meta.label} is required`} visible={showError} />
      {isSystemAttribute && ((isDatasetLinkage && handler().value?.length > 0) || urlRegEx.test(handler().value)) ? (
        <SystemLink />
      ) : (
        <TooltipComponent
          beforeOpen={beforeShowTooltip}
          className="autosave-tooltip"
          content={t('inputLinkTooltip')}
          cssClass="mbc-tooltip"
          mouseTrail
          offsetX={30}
          offsetY={30}
          showTipPointer={false}
        >
          <input
            className={`form-control ${styles.Input} ${
              !isDatasetLinkage && urlRegEx.test(handler().value) ? styles.UrlValue : ''
            }`}
            id={meta.label.replace(/ /g, '_')}
            onClick={onClickInput}
            placeholder={isSystemAttribute ? '-' : `Enter ${meta.placeholder}`}
            readOnly={isSystemAttribute}
            type="text"
            {...(!isDatasetLinkage ? handler() : '')}
          />
        </TooltipComponent>
      )}
    </div>
  );
};

export default InputUrlLegacy;
