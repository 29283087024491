import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import useUpdateEffect from 'hooks/useUpdateEffect';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import TitleBar from '../../components/TitleBar/TitleBar';
import * as Dialog from '../../components/UI/Dialogs/Dialogs';
import Pagination from '../../components/UI/Pagination/Pagination';
import Search from '../../components/UI/Search/Search';
import ProcessesRow from '../../components/UI/TableList/ProcessesRow/ProcessesRow';
import TableList from '../../components/UI/TableList/TableList';
import titleService from '../../services/titleService';
import userService from '../../services/userService';
import { getProcesses } from '../../services/workflowServices';
import taskStyles from '../MyTasks/MyTasks.module.scss';
import styles from './Processes.module.scss';

let dialog = '';

const Processes = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);
  const [isLoadingProcesses, setIsLoadingProcesses] = useState(false);
  const [tabSelected, setTabSelected] = useState(location.state?.tabSelected || Constants.PROCESS_GROUPS.MY_FAVORITES);
  const [totalProcessCount, setTotalProcessCount] = useState();
  const [totalFavoriteCount, setTotalFavoriteCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState({ filter: '', order: Constants.SORT_ASC });

  const fetchProcesses = () => {
    setIsLoadingProcesses(true);
    getProcesses({
      page: page - 1,
      ...(searchText ? { search: searchText } : {}),
      size: Constants.DEFAULT_PAGE_SIZE,
      ...(sorting.filter ? sorting : {}),
    })
      .then((response) => {
        setTotalProcessCount(response.data.totalProcesses);

        if (tabSelected === Constants.PROCESS_GROUPS.MY_PROCESSES) {
          const parsedProcesses = response.data.processes.map((process) => ({
            id: process.id,
            processName: process.nameMap[i18n.language.toUpperCase()],
            role: process.stages.map((stage) => t(`workflowDialog.${stage}`)).join('; '),
            version: process.version,
            validFrom: Utils.getFormattedDate(process.validFrom),
          }));
          setTotalPages(response.data.numberOfPages);
          setResults(parsedProcesses);
        }
        setIsLoadingProcesses(false);
      })
      .catch((err) => userService.handleServiceError(err));
  };

  const fetchFavorites = () => {
    setIsLoadingFavorites(true);
    userService
      .getFavorites({
        page: page - 1,
        ...(searchText ? { search: searchText } : {}),
        size: Constants.DEFAULT_PAGE_SIZE,
        ...(sorting.filter ? sorting : {}),
      })
      .then((response) => {
        setTotalFavoriteCount(response.data.totalResults);

        if (tabSelected === Constants.PROCESS_GROUPS.MY_FAVORITES) {
          setTotalPages(response.data.numberOfPages);
          setResults(response.data.results);
        }

        setIsLoadingFavorites(false);
      })
      .catch((error) => userService.handleServiceError(error));
  };

  useEffect(() => {
    fetchFavorites();
    fetchProcesses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    titleService.updatePageTitle(t(`processes.${tabSelected}`));
  }, [tabSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(
    () => (tabSelected === Constants.PROCESS_GROUPS.MY_PROCESSES ? fetchProcesses() : fetchFavorites()),
    [page, searchText, sorting.filter, sorting.order, tabSelected], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getClassTabSelected = (tab) => (tabSelected === tab ? taskStyles.TabSelected : '');

  const onSearch = (text) => {
    if (text.trim() === '') setSearchText('');
    setSearchText(text);
  };

  const confirmRemoveFavorite = ({ idProcess }) => {
    dialog.hide();
    userService
      .removeFavorite(idProcess)
      .then(fetchFavorites)
      .catch((err) => userService.handleServiceError(err));
  };

  const removeFavorite = (diagram, event) => {
    event.stopPropagation();

    const removeDialog = {
      title: t('removeFavorite'),
      message: t('infoRemoveFavorite', { diagram: diagram.processName }),
      textCancel: t('cancel'),
      textOk: t('removeFavorite'),
      okClick: () => confirmRemoveFavorite(diagram),
    };
    dialog = Dialog.showConfirm(removeDialog);
  };

  const sortTable = (filter) => {
    if (sorting.filter !== filter) setSorting({ filter, order: Constants.SORT_ASC });
    else if (sorting.order === Constants.SORT_ASC) setSorting({ ...sorting, order: Constants.SORT_DESC });
    else setSorting({ ...sorting, order: Constants.SORT_ASC });
    setPage(1);
  };

  return (
    <>
      <TitleBar />
      <div className={`${styles.Content} ${styles.NoToolbar}`}>
        <div className={styles.SearchSection}>
          <Search disabled={results === null} isAsync searching={onSearch} />
        </div>
        <div className={taskStyles.MyTasksHeader} id="tasksHeader">
          <div className={taskStyles.TaskTabs}>
            {Object.values(Constants.PROCESS_GROUPS).map((group) => (
              <div
                className={`${taskStyles.TaskTab} ${getClassTabSelected(group)}`}
                id={`processes-tab-${group}`}
                key={`processes-tab-${group}`}
                onClick={() => {
                  setTabSelected(group);
                  setPage(1);
                  setSorting({ filter: '', order: Constants.SORT_ASC });
                }}
              >
                <span>{t(`processes.${group}`)}</span>
                <span className={taskStyles.TaskNumber}>
                  {group === Constants.PROCESS_GROUPS.MY_PROCESSES ? totalProcessCount || '-' : totalFavoriteCount || '-'}
                </span>
              </div>
            ))}
          </div>
        </div>
        <TableList
          direction={sorting.order}
          extraClick={removeFavorite}
          fieldToOrder={sorting.filter}
          handleRowClick={(idDiagram) => history.push(`/published/${idDiagram}`)}
          header={{
            processName: t('history.table.processName'),
            author: t('history.table.author'),
            version: t('history.table.version'),
            role: t('diagram.objects.role'),
            validFrom: t('history.table.validFrom'),
          }}
          isLoading={tabSelected === Constants.PROCESS_GROUPS.MY_FAVORITES ? isLoadingFavorites : isLoadingProcesses}
          list={results}
          noToolbar
          RowComponent={ProcessesRow}
          selectedTab={tabSelected}
          sortTable={sortTable}
        />
        {totalPages > 1 && (
          <div className={styles.PagesSection}>
            <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
          </div>
        )}
      </div>
    </>
  );
};

export default Processes;
