import React, { useEffect, useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';

import * as Constants from '../../assets/constants/constants';
import communicationService from '../../services/communicationService';
import recommendationService from '../../services/recommendationService';
import serviceConstants from '../../services/serviceConstants';
import Button from '../UI/Button/Button';
import Multiselection from '../UI/Multiselection/Multiselection';
import Spinner from '../UI/Spinner/Spinner';
import styles from '../Workflow/WorkflowDialog/WorkflowDialog.module.scss';
import WorkflowDialogExplorerLocations from '../WorkflowDialogExplorer/WorkflowDialogExplorerLocations';
import '../Workflow/WorkflowDialog/WorkflowDialog.scss';

const DialogRecommendToLocation = (props) => {
  const { attributes, close, diagramId, isVisible, processNumber, version } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [recommendedLocations, setRecommendedLocations] = useState([]);
  const [recommendNewLocations, setRecommendNewLocations] = useState({ touched: false, value: [] });
  const { getManagedPlants } = useAuth();

  const clearDialogData = () => {
    setLocations([]);
    setRecommendNewLocations({ touched: false, value: [] });
  };

  useEffect(() => {
    if (isVisible && locations.length === 0) {
      communicationService
        .getLocations()
        .then((response) => {
          const allButCentral = response.data
            .filter((location) => location.code !== Constants.CENTRAL)
            .map((elem) => ({
              ...elem,
              displayName: `${elem.name} - ${elem.location}, ${elem.country}${elem.daimlerCode ? ` (${elem.daimlerCode})` : ''}`,
              selected: false,
            }));
          const allActiveLocations = allButCentral.filter((location) => location.active);
          setLocations(allActiveLocations);
          recommendationService
            .getRecommendedLocations(processNumber, version)
            .then(({ data }) => {
              setRecommendedLocations(
                data.map((locationCode) => {
                  const locationData = allButCentral.find((location) => location.code === locationCode);

                  return {
                    Code: locationData.code,
                    Name: locationData.displayName,
                  };
                }),
              );
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              recommendationService.handleServiceError(err);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          communicationService.handleServiceError(err);
        });
    }
  }, [isVisible, locations.length, processNumber, version]);

  useEffect(() => {
    clearDialogData();
  }, [diagramId]);

  const onClickLocation = (newValue) => {
    setRecommendNewLocations({ touched: true, value: newValue[Constants.RECOMMEND_NEW_LOCATIONS] });
  };
  const onDeletelocation = (newValue) => {
    setRecommendNewLocations({ ...recommendNewLocations, value: newValue });
  };
  const onClickCancel = () => {
    close();
    setRecommendNewLocations({ touched: false, value: [] });
  };
  const onClickRecommend = () => {
    const authorCode = attributes[Constants.NOT_TRANSLATABLE][Constants.AUTHOR][0].Code;
    const processNameDe = attributes[Constants.GERMAN][Constants.PROCESS_NAME];
    const processNameEn = attributes[Constants.ENGLISH][Constants.PROCESS_NAME];
    const epcName = `{"DE":{"TITLE":"${processNameDe}"},"EN":{"TITLE":"${processNameEn}"}}`;
    const url = `${serviceConstants.baseUrl}/published/${diagramId}`;
    const data = {
      attributes: {
        authorCode,
        processNameDe,
        processNameEn,
        title: epcName,
        link: diagramId,
        url,
        processNumber,
        version,
        qaGroup: attributes[Constants.NOT_TRANSLATABLE][Constants.QA_INSPECTOR_GROUP],
      },
      epcName,
      idAuthor: authorCode,
      locations: recommendNewLocations.value.map((location) => location.Code),
      messageType: 'RECOMMENDED_NEW',
      processNumber,
      url,
      version,
    };
    setIsLoading(true);
    recommendationService
      .createRecommendation(data)
      .then(() => {
        close();
        setIsLoading(false);
        clearDialogData();
      })
      .catch((err) => {
        setIsLoading(false);
        recommendationService.handleServiceError(err);
      });
  };

  const header = () => {
    const formTitle = `<div class="workflowHeader">${t('recommendations.recommendToLocation')}</div>`;
    const explorerTitle = `<div class="workflowHeader scopesHeader">${t('locations.header')}</div>`;

    return `${formTitle}${explorerTitle}`;
  };

  return (
    <DialogComponent
      close={close}
      header={header()}
      height="800px"
      isModal
      target="#recommend-to-location-dialog"
      visible={isVisible}
      width="950px"
    >
      <div>
        {isLoading && (
          <div className={styles.Spinner}>
            <Spinner defaultSpinner isVisible />
          </div>
        )}
        <div className={styles.Content}>
          <div className={styles.Section}>
            <div>
              <Multiselection
                meta={{
                  disabled: true,
                  id: `recommendToLocation${Constants.RECOMMENDED_LOCATIONS}`,
                  label: t(`workflowDialog.${Constants.RECOMMENDED_LOCATIONS}`),
                }}
                value={recommendedLocations}
              />
              <Multiselection
                meta={{
                  attributeType: 'M',
                  description: t(`workflowDialog.${Constants.RECOMMEND_NEW_LOCATIONS}`),
                  id: Constants.RECOMMEND_NEW_LOCATIONS,
                  label: t(`workflowDialog.${Constants.RECOMMEND_NEW_LOCATIONS}`),
                }}
                onBlur={() => setRecommendNewLocations({ ...recommendNewLocations, touched: true })}
                patchValue={onDeletelocation}
                touched={recommendNewLocations.touched}
                value={recommendNewLocations.value}
              />
            </div>
            <div className={styles.ButtonLine}>
              <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={onClickCancel} id="cancelRecommendToLocation" />
              <Button
                btnText={t('recommendations.recommend')}
                buttonStyle="Primary"
                disabled={recommendNewLocations.value.length === 0}
                handleClick={onClickRecommend}
                id="actionRecommendToLocation"
              />
            </div>
          </div>
          <div className={styles.WorkflowDialogExplorer}>
            <WorkflowDialogExplorerLocations
              locations={locations}
              managedPlants={getManagedPlants()}
              patchValue={onClickLocation}
              recommended={recommendedLocations}
              selected={recommendNewLocations.value}
            />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default DialogRecommendToLocation;
