import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  ADDITIONAL_DOCUMENTS,
  AUTHOR,
  METHOD_OWNER,
  MODELER,
  MULTISELECTION_DEFAULT_ITEM_ID,
  MULTISELECTION_DEFAULT_LIMIT,
  NEW_APPROVER,
  NEW_ASSIGNEE,
  RECOMMEND_NEW_LOCATIONS,
  RECOMMENDED_LOCATIONS,
  SCOPES,
  SEARCH_BY,
  ATTRIBUTE_CARES,
} from 'assets/constants/constants';
import * as Constants from 'assets/constants/constants';
import { getObjectAttributes } from 'assets/js/Utils';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import FieldTooltip from 'components/UI/FieldTooltip/FieldTooltip';
import useError from 'hooks/useError';
import objectCatalogService from 'services/objectCatalogService';
import userService from 'services/userService';

import styles from './Multiselection.module.scss';

const searchByFetchMethods = {
  [SEARCH_BY.USER]: userService.getUsersByRole,
  [SEARCH_BY.OBJECT]: objectCatalogService.searchObjects,
};

const inputLimitMap = {
  [AUTHOR]: 1,
  [METHOD_OWNER]: 1,
  [MODELER]: 3,
  [NEW_APPROVER]: 1,
  [NEW_ASSIGNEE]: 1,
};

const defaultGetText = (item) => `${item.Name} (${item.Code})`;

const Multiselection = (props) => {
  const { meta, onBlur, onFocus, patchValue, touched, value = [], language } = props;
  const {
    attributeType,
    description,
    disabled,
    id = 'multiselection',
    itemId = MULTISELECTION_DEFAULT_ITEM_ID,
    getText = defaultGetText,
    label,
    max,
    onClickAdd,
    onClickChip,
    searchBy: metaSearchBy,
  } = meta;
  const { handleServiceError } = useError();
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const searchTimeout = useRef();
  const boxRef = useRef();
  const searchInputRef = useRef();
  const searchResultsRef = useRef();
  const isDisabled = disabled || attributeType === ATTRIBUTE_CARES.SYSTEM;
  const isMandatory = attributeType === 'M';

  // TODO: remove this when inputLimit and searchBy come from the backend
  const inputLimit = max || inputLimitMap[id] || MULTISELECTION_DEFAULT_LIMIT;
  const searchBy =
    metaSearchBy ||
    ([ADDITIONAL_DOCUMENTS, RECOMMENDED_LOCATIONS, RECOMMEND_NEW_LOCATIONS, SCOPES].some((element) => id.includes(element))
      ? undefined
      : SEARCH_BY.USER);

  const parseObjectResults = useCallback(
    (results) =>
      results?.map((result) => {
        const attributes = getObjectAttributes(result.attributes);

        return {
          [itemId]: result.id,
          Name: attributes[language].OBJECT_NAME,
          data: result,
        };
      }) || [],
    [language, itemId],
  );

  const fetchOptions = () => {
    const fetchMethod = searchByFetchMethods[searchBy];
    setIsSearchLoading(true);
    fetchMethod(searchText, id)
      .then((res) => {
        let users;
        if (searchBy === Constants.SEARCH_BY.OBJECT) {
          users = parseObjectResults(res?.data?.results);
        } else {
          users = res.data.results
            .filter((user) => !value.some((selectedUser) => selectedUser[itemId] === user.code))
            .map((user) => ({ Name: user.commonName, [itemId]: user.code }));
        }
        setSearchResults(users);
        setIsSearchLoading(false);
      })
      .catch((err) => {
        handleServiceError(err);
        setIsSearchLoading(false);
      });
  };

  useEffect(() => {
    setShowError(isMandatory && touched && value.length === 0);
  }, [isMandatory, touched, value]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    if (!searchText.trim()) {
      setSearchResults(null);

      return;
    }

    searchTimeout.current = setTimeout(() => {
      fetchOptions();
      clearTimeout(searchTimeout.current);
    }, 300);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBoxClick = () => {
    if (!searchInputRef.current) return;

    searchInputRef.current.focus();
  };

  const handleFocusChange = () => {
    if (isDisabled || isFocused) return;

    setIsFocused(true);

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleBlurChange = (event) => {
    if (
      isDisabled ||
      !isFocused ||
      boxRef.current.contains(event.relatedTarget) ||
      searchResultsRef.current?.contains(event.relatedTarget)
    ) {
      return;
    }

    setIsFocused(false);
    setSearchText('');
    setSearchResults(null);

    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  const handleInputChange = (event) => {
    if (value.length >= inputLimit) return;

    setSearchText(event.target.value);
  };

  const handleClickChip = (chip) => () => {
    if (typeof onClickChip !== 'function') return;
    onClickChip(chip);
  };

  const handleDeleteChip = (chip) => () => {
    if (isDisabled) return;
    patchValue(value.filter((item) => item[itemId] !== chip[itemId]));
  };

  const handleSelect = (item) => {
    if (value.length >= inputLimit) return;

    setSearchText('');
    setSearchResults(null);
    patchValue([...value, item]);
    searchInputRef.current.focus();
  };

  const handleInputKeyDown = (event) => {
    if (!searchResultsRef.current || event.key !== 'ArrowDown') return;
    event.preventDefault();
    searchResultsRef.current.firstElementChild.focus();
  };

  const handleListKeyDown = (event) => {
    if (!['ArrowUp', 'ArrowDown'].includes(event.key)) return;
    event.preventDefault();
    const active = document.activeElement;

    if (event.key === 'ArrowUp') {
      if (active === searchResultsRef.current.firstElementChild) searchInputRef.current.focus();
      else active.previousElementSibling.focus();
    }

    if (event.key === 'ArrowDown' && active !== searchResultsRef.current.lastElementChild) {
      active.nextElementSibling.focus();
    }
  };

  return (
    <div className={`form-group dropdown ${showError ? styles.MultiSelectError : ''}`} id={id}>
      <label className={showError ? 'error' : styles.Label} htmlFor={id}>
        {label}
        {isMandatory ? '*' : ''}
      </label>
      {description && <FieldTooltip description={description} />}
      {onClickAdd && !isDisabled && <i className={`di icon-plus-hinzufuegen-klein ${styles.IconAdd}`} onClick={onClickAdd} />}
      <div
        className={`e-multiselect e-input-group e-control-wrapper e-valid-input ${isFocused ? 'e-input-focus' : ''}
          ${isDisabled ? 'e-disabled' : ''}`}
        id={id}
        onBlur={handleBlurChange}
        onClick={handleBoxClick}
        onFocus={handleFocusChange}
        ref={boxRef}
        tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      >
        <div className="e-multi-select-wrapper">
          <div className="e-chips-collection">
            {Array.isArray(value) &&
              value.map((item) => (
                <span
                  className={`e-chips ${styles.Chip} ${onClickChip ? styles.clicable : ''}`}
                  key={item[itemId]}
                  onClick={handleClickChip(item)}
                >
                  <span className="e-chipcontent">{getText(item)}</span>
                  <span className="e-chips-close" onMouseDown={handleDeleteChip(item)} />
                </span>
              ))}
          </div>
          {!isDisabled && value.length < inputLimit && searchBy && (
            <div className="e-searcher e-multiselect-box e-zero-size">
              <input
                className={`e-dropdownbase ${isFocused ? '' : styles.InvisibleInput}`}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                ref={searchInputRef}
                type="text"
                value={searchText}
              />
            </div>
          )}
        </div>
        {isFocused && (isSearchLoading || searchResults) && (
          <DropdownPanel className={styles.Panel} close={() => handleBlurChange({})} parentRef={boxRef}>
            {isSearchLoading || !searchResults.length ? (
              <div className={styles.Placeholder}>{isSearchLoading ? t('loading') : t('multiselection.noRecordsFound')}</div>
            ) : (
              <ul className={styles.List} onKeyDown={handleListKeyDown} ref={searchResultsRef}>
                {searchResults.map((item, index) => (
                  <li
                    className={styles.Item}
                    key={item[itemId]}
                    onClick={() => handleSelect(item)}
                    onKeyDown={(event) => event.key === 'Enter' && handleSelect(item)}
                    tabIndex={index}
                  >
                    {getText(item)}
                  </li>
                ))}
              </ul>
            )}
          </DropdownPanel>
        )}
      </div>
    </div>
  );
};

export default Multiselection;
