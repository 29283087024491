import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';

import styles from 'components/DialogDeleteDiagram/DialogDeleteDiagram.module.scss';
import formDialogStyles from 'components/UI/FormDialog/FormDialog.module.scss';

type DialogDeleteDiagramProps = {
  close: () => void;
  confirmDelete: () => void;
  confirmationValue: string;
  diagramType: string;
  onChange: (value: string) => void;
  processName?: string;
};

const DialogDeleteDiagram = (props: DialogDeleteDiagramProps) => {
  const { close, confirmationValue, confirmDelete, diagramType, onChange, processName } = props;
  const { t } = useTranslation();

  const buttons = [
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      dataQa: 'legacy-cancel-deletion',
      id: 'legacy-cancel-deletion',
      handleClick: close,
      key: 'cancel',
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('confirmDelete'),
      dataQa: 'legacy-confirm-deletion',
      id: 'legacy-confirm-deletion',
      disabled: !confirmationValue,
      handleClick: confirmDelete,
      key: 'confirm',
    },
  ];

  return (
    <Dialog className={styles.DialogContainer}>
      <DialogHeader className={styles.Title}>
        <i className="di icon-warnung-fehler" />
        <span>{t('warningText')}</span>
      </DialogHeader>
      <div className={styles.DialogBody}>
        <span>{t('diagram.confirmDeleteDiagram', { diagramType: t(diagramType) })}</span>
        <span>{t('diagram.deleteDiagramLabel')}</span>
        <span className={styles.DiagramName}>{`*${processName?.replace(/\n/g, ' ')}*`}</span>
        <input
          autoComplete="off"
          className={formDialogStyles.Input}
          id="deleteDiagramInput"
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
      <DialogFooter buttons={buttons} />
    </Dialog>
  );
};

export default DialogDeleteDiagram;
