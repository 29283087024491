export enum DocLinkTypes {
  MANDATORY_QUALIFICATION_DOCUMENTS = 'MANDATORY_QUALIFICATION_DOCUMENTS',
  PROCESS_DESCRIPTIVE_DOCUMENTS = 'PROCESS_DESCRIPTIVE_DOCUMENTS',
  SECURING_CONTRIBUTION_DOCUMENTS = 'SECURING_CONTRIBUTION_DOCUMENTS',
}

export enum UploadStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ActivityAditionalInformation {
  LINK = 'link',
  FILE = 'file',
}

export interface DocLinkResponse {
  links: Link[];
  docs: DocumentResponse[];
}

export interface Link {
  name: string;
  type: DocLinkTypes;
  value: string;
}

export interface Document {
  name: string;
  type: DocLinkTypes;
  value?: string;
  status?: UploadStatus;
}

export interface DocumentResponse {
  name: string;
  lastModificationDate: string;
}

export interface LinkWithStatus extends Link {
  status?: UploadStatus;
}
