import { useRef } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { urlRegEx } from 'assets/constants/constants';
import { purify } from 'assets/js/Utils';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { AttributeCode, InputComponent, VariantProperty } from 'types/forms';

interface Props {
  characterLimit?: number;
  code: string | undefined;
  input: InputComponent;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onClick?: (event?: React.MouseEvent) => void;
  onClickDelete: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  showClearButton: boolean;
  placeholder?: string;
  variant?: string;
}

const Input = (props: Props) => {
  const {
    characterLimit,
    code,
    input,
    onBlur,
    onChange,
    onClick,
    onClickDelete,
    onKeyDown,
    showClearButton,
    placeholder,
    variant,
  } = props;
  const { t } = useTranslation();
  const { disabled, error, id, reference, styles, value } = input;
  const newInputRef = useRef<HTMLInputElement>(null);
  const currentTextInputRef = reference || newInputRef;
  const isUrl = urlRegEx.test(value) && code === AttributeCode.LINKAGE;

  const beforeShowTooltip = (event: any) => {
    if (isUrl) return;
    event.cancel = true;
  };

  return (
    <>
      {disabled && isUrl ? (
        <a className={`${styles.Input} ${styles.UrlValue}`} href={value} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      ) : (
        <TooltipComponent
          beforeOpen={beforeShowTooltip}
          className="autosave-tooltip"
          content={t('inputLinkTooltip')}
          cssClass="mbc-tooltip"
          mouseTrail
          offsetX={30}
          offsetY={30}
          showTipPointer={false}
        >
          <input
            autoComplete="off"
            className={`${styles.Input} ${isUrl ? styles.UrlValue : ''} ${error.hasError ? styles.Error : ''}`}
            disabled={disabled}
            id={id}
            key={id}
            maxLength={characterLimit}
            onBlur={onBlur}
            onChange={(event) => onChange(purify(event.target.value))}
            onClick={onClick}
            onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : {})}
            placeholder={placeholder}
            ref={currentTextInputRef as React.RefObject<HTMLInputElement>}
            type={variant?.includes(VariantProperty.NUMERIC) ? 'number' : 'text'}
            value={value}
          />
          {showClearButton && (
            <div className={styles.IconDeleteWrapper}>
              <RoundButton
                className={styles.IconDelete}
                icon="icon-schliessen"
                id="delete-input-value-icon"
                onClick={(event) => onClickDelete(event)}
              />
            </div>
          )}
        </TooltipComponent>
      )}
    </>
  );
};

export default Input;
