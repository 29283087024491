import React from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import styles from './SettingsMenu.module.scss';

const SettingsMenu = ({ t, history, location }) => {
  let buttonSelected = '';

  const getPathForButtonSelected = () => {
    return location.pathname.split('/').length > 2 ? location.pathname.split('/')[2].toString() : 'settings';
  };

  buttonSelected = getPathForButtonSelected();

  const handleClick = (route, button) => {
    if (location.pathname !== route) {
      history.push(route);
      buttonSelected = button;
    }
  };

  return (
    <div className={styles.SettingsMenu}>
      <button
        className={buttonSelected === 'settings' ? styles.OptionSelected : styles.MenuOption}
        id="buttonSettings"
        onClick={() => handleClick('/user/settings', 'settings')}
        type="button"
      >
        {t('settings.generalSettings')}
      </button>
      <button
        className={buttonSelected === 'profile' ? styles.OptionSelected : styles.MenuOption}
        id="buttonProfile"
        onClick={() => handleClick('/user/profile', 'profile')}
        type="button"
      >
        {t('settings.myProfile')}
      </button>
    </div>
  );
};

export default withRouter(withTranslation('common')(SettingsMenu));
