import React, { useMemo } from 'react';

import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import { ProcessLevelFormatted as Row } from 'types/processLevel';

import TooltipRow from '../../components/UI/Table/TooltipCell/TooltipCell';
import styles from './ProcessLevelDatabaseTable.module.scss';

type RowProps = {
  columns: Record<string, string>;
  row: Row;
  isChecked?: boolean;
  isExtended?: boolean;
  isTreeStructure?: boolean;
  onCheck?: () => void;
  onClick?: () => void;
  onExtend?: () => void;
};

const ProcessLevelDatabaseRow = React.memo(
  ({
    columns,
    row,
    onCheck,
    onClick,
    isChecked = false,
    onExtend = () => {},
    isExtended = false,
    isTreeStructure = false,
  }: RowProps) => {
    const hasChildren = useMemo(() => row.children && row.children.length > 0, [row.children]);

    return (
      <tr
        className={`${isChecked ? styles.Checked : ''} ${hasChildren || onClick ? styles.Clickable : ''}`}
        data-qa={`process-level-row-${row.id}`}
        data-testid="process-level-row"
        key={`process-level-row-${row.id}`}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick();
        }}
        style={isTreeStructure ? ({ '--level': row.level } as React.CSSProperties) : {}}
      >
        {isTreeStructure && (
          <td className={styles.ArrowColumn} key={`process-level-arrow-${row.id}`}>
            <div
              className={`${hasChildren ? styles.ArrowWrapper : styles.EmptySpace} ${isExtended ? styles.Open : ''}`}
              onMouseDown={(e) => {
                e.stopPropagation();
                onExtend();
              }}
            >
              <div className={styles.ArrowIcon}>{hasChildren && <i className="di icon-pfeil-chevron-unten" />}</div>
            </div>
          </td>
        )}
        {onCheck && (
          <td className={styles.CheckboxColumn} key={`process-level-checkbox-${row.id}`}>
            <Checkbox
              checked={isChecked}
              handleCheck={(id, event) => {
                event?.stopPropagation();
                onCheck();
              }}
              id={row.id}
            />
          </td>
        )}
        {Object.entries(columns).map(([id, style]) => (
          <td className={`${styles.NormalColumn} ${style || ''}`} key={`${row.id}-${id}`}>
            <TooltipRow element={row[id]} value={row[id]} />
          </td>
        ))}
      </tr>
    );
  },
);

export default ProcessLevelDatabaseRow;
