import E2EResponsible from 'components/E2EResponsible/E2EResponsible';
import Symbol from 'components/Symbol/Symbol';
import SymbolBoardCategory from 'components/SymbolBoardCategory/SymbolBoardCategory';
import { PostItCategory, PostItTypes, Symbol as SymbolType } from 'types/symbols';

import styles from './SymbolBoard.module.scss';

type Props = {
  symbolProps: SymbolType;
};

const SymbolBoard = (props: Props) => {
  const { symbolProps } = props;
  const postIts: PostItCategory = symbolProps.postIts as PostItCategory;
  const symbolCoordinatesX =
    symbolProps.offset && symbolProps.offset.x > 0 ? symbolProps.meta.left + symbolProps.offset.x : symbolProps.meta.left;
  const symbolCoordinatesY =
    symbolProps.offset && symbolProps.offset.y > 0 ? symbolProps.meta.top + symbolProps.offset.y : symbolProps.meta.top;

  return (
    <div
      className={styles.Container}
      id="symbol-board-container"
      style={{
        left: `${symbolCoordinatesX}em`,
        top: `${symbolCoordinatesY}em`,
      }}
    >
      <div className={styles.UpperContainer}>
        <SymbolBoardCategory
          category={PostItTypes.INPUT}
          postIts={postIts?.[PostItTypes.INPUT] || []}
          symbolId={symbolProps.id}
          title="Input"
        />
        <div className={styles.MiddleContainer}>
          <Symbol {...symbolProps} withinSymbolBoard />
          <E2EResponsible
            id={`e2e-${symbolProps.id}`}
            symbolId={symbolProps.id}
            value={postIts?.[PostItTypes.E2E_PROCESS_OWNER]?.[0]?.text || ''}
          />
        </div>
        <SymbolBoardCategory
          category={PostItTypes.OUTPUT}
          postIts={postIts?.[PostItTypes.OUTPUT] || []}
          symbolId={symbolProps.id}
          title="Output"
        />
      </div>
      <div className={styles.LowerContainer}>
        <div className={styles.LowerLane}>
          <SymbolBoardCategory
            category={PostItTypes.IT_SYSTEM}
            isLowerLane
            postIts={postIts?.[PostItTypes.IT_SYSTEM] || []}
            symbolId={symbolProps.id}
            title="IT-System Name"
          />
          <SymbolBoardCategory
            category={PostItTypes.PAINPOINTS}
            isLowerLane
            postIts={postIts?.[PostItTypes.PAINPOINTS] || []}
            symbolId={symbolProps.id}
            title="Painpoints"
          />
        </div>
        <div className={styles.LowerLane}>
          <SymbolBoardCategory
            category={PostItTypes.KPI}
            isLowerLane
            postIts={postIts?.[PostItTypes.KPI] || []}
            symbolId={symbolProps.id}
            title="KPI"
          />
          <SymbolBoardCategory
            category={PostItTypes.POTENTIAL_ASSESSMENTS}
            isLowerLane
            postIts={postIts?.[PostItTypes.POTENTIAL_ASSESSMENTS] || []}
            symbolId={symbolProps.id}
            title="Potenzialabschätzungen"
          />
        </div>
      </div>
    </div>
  );
};

export default SymbolBoard;
