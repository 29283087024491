import React from 'react';

import { withTranslation } from 'react-i18next';

import * as Utils from '../../../../assets/js/Utils';
import styles from './RecommendationHistoryRow.module.scss';

const RecommendationHistoryRow = ({ isHeader, element, sortTable, direction, fieldToOrder, handleDownloadClick }) => {
  const getSortedClass = (field) => (fieldToOrder === field ? styles[direction] : '');

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${styles.ListItem}`}>
        <th className={`${styles.Name} ${getSortedClass('name')}`} onClick={() => sortTable('name')}>
          {element.name}
        </th>
        <th className={`${styles.Version} ${getSortedClass('version')}`} onClick={() => sortTable('version')}>
          {element.version}
        </th>
        <th className={`${styles.RecommendedBy} ${getSortedClass('recommendedBy')}`} onClick={() => sortTable('recommendedBy')}>
          {element.recommendedBy}
        </th>
        <th className={`${styles.Date} ${getSortedClass('date')}`} onClick={() => sortTable('date')}>
          {element.date}
        </th>
        <th className={`${styles.Status} ${getSortedClass('status')}`} onClick={() => sortTable('status')}>
          {element.status}
        </th>
        <th className={`${styles.Gaplog} ${getSortedClass('gaplog')}`} onClick={() => sortTable('gaplog')}>
          {element.gaplog}
        </th>
        <th className={styles.IcoPdf}>{}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={styles.ListItem}>
      <td className={styles.Name}>{element.name}</td>
      <td className={styles.Version}>{element.version}</td>
      <td className={styles.RecommendedBy}>{element.recommendedBy}</td>
      <td className={styles.Date}>{Utils.getFullFormattedDate(element.date)}</td>
      <td className={styles.Status}>{element.status}</td>
      <td className={styles.Gaplog}>{element.gaplog}</td>
      <td className={styles.IcoPdf}>
        {element.versionPlant && (
          <i
            className={`di icon-datei-pdf ${styles.Document}`}
            onClick={() =>
              handleDownloadClick({
                processNumber: element.processNumber,
                version: element.versionPlant,
                locationCode: element.locationCode,
                name: element.name,
              })
            }
          />
        )}
      </td>
    </tr>
  );
};

export default withTranslation('common')(RecommendationHistoryRow);
