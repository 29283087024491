import React, { useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';

import { Collapse } from '../UI/Collapse/Collapse';
import Search from '../UI/Search/Search';
import styles from './WorkflowDialogExplorer.module.scss';

const WorkflowDialogExplorerLocations = (props) => {
  const { t, locations, managedPlants, patchValue, selected = [], recommended = [] } = props;
  const [searchText, setSearchText] = useState('');

  const groupedLocations = useMemo(() => {
    const result = {};
    locations
      .filter(
        (elem) =>
          elem.active &&
          (!managedPlants.length || managedPlants.includes(elem.code)) &&
          elem.displayName.toLowerCase().includes(searchText.toLowerCase()),
      )
      .forEach((location) => {
        result[location.regionCode] = [...(result[location.regionCode] || []), location];
      });

    return result;
  }, [locations, managedPlants, searchText]);

  const selectLocation = (location) => () => {
    const isDelete = selected.some((elem) => elem.Code === location.code);
    let newLocations = [...selected];

    if (isDelete) {
      newLocations = newLocations.filter((elem) => elem.Code !== location.code);
    } else {
      newLocations.push({
        Code: location.code,
        Name: location.displayName,
      });
    }
    patchValue({ RECOMMEND_NEW_LOCATIONS: newLocations });
  };

  return (
    <>
      <div className={styles.SearchInput}>
        <label htmlFor="search-recommend-new-locations">{`${t('nameAttributes.RECOMMEND_NEW_LOCATIONS')}*`}</label>
        <Search
          id="search-recommend-new-locations"
          searching={(text) => {
            setSearchText(text.trim());
          }}
        />
      </div>
      <span className={styles.Advisor}>{`(${t('locations.searchLimitAdvisor')})`}</span>
      <div className={styles.SearchResult}>
        <ul className={styles.List}>
          {Object.entries(groupedLocations).map(([key, list]) => {
            if (list.length === 0) return null;

            return (
              <Collapse defaultExpanded key={key} title={t(`locations.zones.${key}`)}>
                {list.map((elem) => {
                  const isRecommended = recommended.some((location) => location.Code === elem.code);
                  const isSelected = !isRecommended && selected.some((location) => location.Code === elem.code);

                  return (
                    <li
                      className={`${isRecommended ? styles.Disabled : ''} ${isSelected || isRecommended ? styles.Selected : ''}`}
                      key={`list_${elem.code}`}
                      onMouseDown={selectLocation(elem)}
                      title={elem.displayName}
                    >
                      <span>{elem.displayName}</span>
                      <i className="di icon-close" />
                    </li>
                  );
                })}
              </Collapse>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default React.memo(
  withTranslation('common')(WorkflowDialogExplorerLocations),
  (prev, next) =>
    JSON.stringify(prev.locations) === JSON.stringify(next.locations) &&
    JSON.stringify(prev.selected) === JSON.stringify(next.selected) &&
    JSON.stringify(prev.recommended) === JSON.stringify(next.recommended),
);
