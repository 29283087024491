import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const getRecommendationUrl = (version = 1) => `/backend/ms-recommendations/api/v${version}/message/recommended`;
const recommendationUrl = getRecommendationUrl();
const recommendationUrlv2 = getRecommendationUrl(2);

const acceptRecommendation = (id) => axiosInstance.put(`${recommendationUrl}/accept?id=${id}`);

const createRecommendation = (data) => axiosInstance.post(`${recommendationUrl}/published`, data);

const getGapLogTypes = (data) => axiosInstance.get(`${recommendationUrl}/gap-log-types`, data);

const getRecommendation = (id) => axiosInstance.get(`${recommendationUrl}/by-id-message?id=${id}`);

const getRecommendationDiagram = (id) => axiosInstance.get(`${recommendationUrl}/diagram?idRecommended=${id}`);

const getRecommendations = (params) => {
  const searchParams = new URLSearchParams(params).toString();
  return params.epcName === undefined
    ? axiosInstance.get(recommendationUrlv2, { params })
    : axiosInstance.get(`${recommendationUrlv2}?${searchParams}`);
};

const getRecommendedLocations = (processNumber, version) =>
  axiosInstance.get(`${recommendationUrl}/candidate-recommend/process-number/${processNumber}/version/${version}`);

const getProcessRecommendations = (params) => axiosInstance.get(`${recommendationUrlv2}/by-process-number`, { params });

const getUsersHistorical = (id) => axiosInstance.get(`${recommendationUrl}/users-historical?idRecommended=${id}`);

const rejectRecommendation = (comment, id) => axiosInstance.post(`${recommendationUrl}/reject?comment=${comment}&id=${id}`);

const transferTask = (data) => axiosInstance.put(`${recommendationUrl}/transfer`, data);

const isRecommendation = (processNumber) =>
  axiosInstance.get(`${recommendationUrl}/isRecommended?processNumber=${processNumber}`);

const getRecommendationVersions = (location, processNumber) => {
  const params = {
    ...(location ? { location } : {}),
    ...(processNumber ? { processNumber } : {}),
  };

  return axiosInstance.get(`${recommendationUrl}/by-process-number-location`, { params });
};

const takeOverRecommendation = (idRecommended) =>
  axiosInstance.post(`${recommendationUrl}/diagram/take-over`, null, { params: { idRecommended } });

export default {
  handleServiceError: serviceUtils.handleServiceError,
  acceptRecommendation,
  createRecommendation,
  getGapLogTypes,
  getRecommendation,
  getRecommendationDiagram,
  getRecommendations,
  getRecommendedLocations,
  getProcessRecommendations,
  getUsersHistorical,
  rejectRecommendation,
  transferTask,
  isRecommendation,
  getRecommendationVersions,
  takeOverRecommendation,
};
