import { useRef, useState } from 'react';

import uploadDocumentImage from 'assets/images/uploadDocuments.svg';
import uploadDocumentHoverImage from 'assets/images/uploadDocumentsHover.svg';

import styles from './FileUploader.module.scss';

type Props = {
  allowedExtensions: string[];
  isEmpty?: boolean;
  uploaderText?: string;
  maxFiles: number;
  handleUploadFiles: (files: File[]) => void;
};

const FileUploader = (props: Props) => {
  const { allowedExtensions, isEmpty = false, uploaderText = '', maxFiles, handleUploadFiles } = props;
  const [isHovering, setIsHovering] = useState(false);
  const filesInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (!target || !target.files || target.files?.length > maxFiles) return;
    handleUploadFiles([...target.files]);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    handleUploadFiles([...droppedFiles]);
  };

  return (
    <div className={styles.Content}>
      {isEmpty && (
        <div
          className={styles.InitialView}
          onClick={() => filesInputRef.current?.click()}
          onDragOver={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onDrop={handleDrop}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {uploaderText && <span>{uploaderText}</span>}
          <img alt="Upload file" id="upload-file" src={isHovering ? uploadDocumentHoverImage : uploadDocumentImage} />
        </div>
      )}
      <input
        accept={allowedExtensions.join(',')}
        className={styles.FilesInput}
        id="uploadFileInput"
        multiple
        onChange={(event) => {
          handleFileSelection(event);
        }}
        ref={filesInputRef}
        type="file"
        value={undefined || ''}
      />
    </div>
  );
};

export default FileUploader;
