const env = process.env.REACT_APP_ENV || 'development';

const appInsightsKey = {
  local: '922cf2ab-23b3-4468-b848-76e527d93afa',
  development: '922cf2ab-23b3-4468-b848-76e527d93afa',
  qa: '24e629e7-c876-4b31-9bd1-3c06e221cbd3',
  mig: '922cf2ab-23b3-4468-b848-76e527d93afa',
  int: '4e983730-1c8a-4cb9-a658-00309b90228b',
  production: 'd19aa18a-1be7-4664-a0da-5948a44ac753',
};

export default function getEnvironmentAppInsightsKey() {
  return appInsightsKey[env];
}
