import { useState, useRef } from 'react';

import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import { Coordinate } from 'types/diagram';

import OptionsLine, { OptionsLineIcon } from './OptionsLine/OptionsLine';
import styles from './ShowMoreBox.module.scss';

interface Props {
  elements: ShowMoreElement[];
  right?: boolean;
  className?: string;
}

export interface ShowMoreElement {
  value: string;
  actions?: OptionsLineIcon[];
  stateIcon?: string;
}

const ShowMoreBox = ({ elements, right, className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={`${styles.Container} ${className || ''}`} ref={containerRef}>
      <div className={styles.Box} data-testid="Dropdown" onClick={() => setIsOpen(!isOpen)}>
        +{elements.length}
      </div>
      {isOpen && (
        <DropdownPanel
          className={styles.Panel}
          close={() => setIsOpen(false)}
          horizontalPosition={right ? Coordinate.RIGHT : Coordinate.LEFT}
          parentRef={containerRef}
          width={200}
        >
          <div className={styles.Options}>
            {elements.map((opt: ShowMoreElement) =>
              opt.actions ? (
                <OptionsLine
                  className={styles.Option}
                  icons={opt.actions}
                  key={opt.value}
                  label={opt.value}
                  onClose={() => setIsOpen(false)}
                  stateIcon={opt.stateIcon}
                />
              ) : (
                <OptionsLine className={styles.Option} key={opt.value} label={opt.value} stateIcon={opt.stateIcon} />
              ),
            )}
          </div>
        </DropdownPanel>
      )}
    </div>
  );
};

export default ShowMoreBox;
