import { useCallback, useEffect, useRef, useState } from 'react';

import { TooltipComponent, TooltipEventArgs } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { ZOOM_LEVELS, DIAGRAM_FONT_SIZE } from 'assets/constants/constants';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';

import styles from './ZoomControl.module.scss';

type Props = {
  beforeShowTooltip: (event: TooltipEventArgs) => void;
};
const ZoomControl = (props: Props) => {
  const { beforeShowTooltip } = props;
  const zoomRef = useRef<string>('');
  const { backgroundRef, containerRef, dispatch, fontSize } = useDiagramContext();
  const { t } = useTranslation();
  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout>();
  const [scrollSteps, setScrollSteps] = useState<number>(0);

  const updateFontSize = useCallback(
    (size: number) => {
      const sizeInRange = Math.max(Math.min(size, ZOOM_LEVELS.MAX), ZOOM_LEVELS.MIN_DIAGRAM);
      if (sizeInRange !== fontSize) dispatch({ type: DiagramActionTypes.SET_FONT_SIZE, payload: sizeInRange });
    },
    [dispatch, fontSize],
  );

  const zoomDiagram = useCallback(
    (step: number) => {
      if (!backgroundRef.current) return;
      const newFontSize = (fontSize || DIAGRAM_FONT_SIZE) + step;
      zoomRef.current = '';
      updateFontSize(newFontSize);
      setTimeout(() => {
        dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: true });
      }, 25);
    },
    [backgroundRef, dispatch, fontSize, updateFontSize],
  );

  const formatZoom = `${Math.round((fontSize!! * 100) / ZOOM_LEVELS.MAX)}%`;

  const isZoomable = useCallback(
    (newZoom: number): Boolean => {
      if ((fontSize || DIAGRAM_FONT_SIZE) >= ZOOM_LEVELS.MAX && newZoom < 0) return false;
      if ((fontSize || DIAGRAM_FONT_SIZE) <= ZOOM_LEVELS.MIN_DIAGRAM && newZoom > 0) return false;
      return true;
    },
    [fontSize],
  );

  const scrollToZoom = useCallback(
    (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        clearTimeout(scrollTimeout);
        if (!backgroundRef.current || !isZoomable(event.deltaY)) return;
        const steps = event.deltaY < 0 ? 0.4 : -0.4;
        const scroll = scrollSteps + steps;
        setScrollSteps(scroll);
        setScrollTimeout(
          setTimeout(() => {
            zoomDiagram(scroll);
            setScrollSteps(0);
          }, 500),
        );
      }
    },
    [scrollTimeout, backgroundRef, isZoomable, scrollSteps, zoomDiagram],
  );

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (!currentContainer) return;
    currentContainer?.addEventListener('wheel', scrollToZoom);
    return () => {
      currentContainer?.removeEventListener('wheel', scrollToZoom);
    };
  }, [containerRef, scrollToZoom]);

  return (
    <div className={styles.zoomContainer}>
      <>
        <TooltipComponent
          beforeOpen={beforeShowTooltip}
          content={t('tool.zoomOut')}
          cssClass="mbc-tooltip nepos-tooltip"
          position="BottomCenter"
          showTipPointer={false}
        >
          <RoundButton
            className={styles.Button}
            disabled={fontSize === ZOOM_LEVELS.MIN_DIAGRAM}
            icon="di icon-lupe-herauszoomen"
            id="ToolbarNEPOS-zoom-out"
            onClick={() => zoomDiagram(-ZOOM_LEVELS.STEP)}
          />
        </TooltipComponent>
        <p>{fontSize === 1 ? '1%' : formatZoom}</p>
        <TooltipComponent
          beforeOpen={beforeShowTooltip}
          content={t('tool.zoomIn')}
          cssClass="mbc-tooltip nepos-tooltip"
          position="BottomCenter"
          showTipPointer={false}
        >
          <RoundButton
            className={styles.Button}
            disabled={fontSize === ZOOM_LEVELS.MAX}
            icon="di icon-lupe-hineinzoomen"
            id="ToolbarNEPOS-zoom-in"
            key="ToolbarNEPOS-zoom-in"
            onClick={() => zoomDiagram(ZOOM_LEVELS.STEP)}
          />
        </TooltipComponent>
      </>
    </div>
  );
};

export default ZoomControl;
