import useConnectorHandles from 'hooks/useConnectorHandles';

import styles from './ConnectorHandles.module.scss';

const ConnectorHandles = ({ className = '' }: { className?: string }) => {
  const { handles } = useConnectorHandles();

  return (
    <>
      {handles.map(({ index, isActive, isHidden, left, onMouseDown, top }) => (
        <rect
          className={`${styles.Handle} ${isActive ? styles.Active : ''} ${isHidden ? styles.Hidden : ''} ${className}`}
          height="1"
          key={index}
          onMouseDown={onMouseDown}
          strokeWidth={1 / 7}
          width="1"
          x={left}
          y={top}
        />
      ))}
    </>
  );
};

export default ConnectorHandles;
