import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EPC_DIAGRAM_ID, VCD_DIAGRAM, VCD_DIAGRAM_ID } from 'assets/constants/constants';
import LinkDiagramDialog from 'components/DialogDetail/LinkDiagramDialog';
import useDataset from 'hooks/useDataset';
import useDatasetContext from 'hooks/useDatasetContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import useProcess from 'hooks/useProcess';
import { linkDiagramToDataset } from 'services/dataset';
import { AttributeCode, Chip } from 'types/forms';

import DropdownPanel from '../DropdownPanel/DropdownPanel';
import styles from '../InputSearch/InputSearchNEPOS.module.scss';
import InputTextChipsNEPOS from '../InputText/InputTextChipsNEPOS';

interface Props {
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  error?: string;
  max?: number;
  selected: string;
  service: (value: string) => Promise<any>;
  onBlur?: () => void;
  placeholder: string;
  code: string;
}

const DiagramSelector = <T extends { code: string; commonName?: string; departments?: string[]; linkTeams?: string }>(
  props: Props,
) => {
  const { id, label, required, disabled, service, placeholder, onBlur, max = 1, selected = '', code } = props;
  const { t } = useTranslation();
  const [canSelect, setCanSelect] = useState(true);
  const { handleServiceError } = useError();
  const idDataSet = useParams<{ id: string }>().id;
  const containerRef = useRef<HTMLLabelElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<Chip[] | null>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { processData, diagramLanguage } = useDiagramContext();
  const { datasetData } = useDatasetContext();
  const { getPublishedVcdEpcTree } = useProcess(processData?.id as string);
  const [diagramList, setDiagramList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isModal = code === AttributeCode.LINKED_DIAGRAM;
  const isSmall = code === AttributeCode.PROCESS_RESPONSIBLE;
  const { saveDataset, fetchDataset } = useDataset();

  const fetchOptions = () => {
    setIsSearchLoading(true);
    return service(searchText)
      .then((res) => {
        setSearchResults(res.data.results);
        setIsSearchLoading(false);
      })
      .catch((err) => {
        handleServiceError(err);
        setIsSearchLoading(false);
      });
  };

  useEffect(() => {
    if (selected?.length > 0) {
      setChips([
        {
          code: selected,
          displayName: datasetData?.linkedDiagram?.attributes?.[diagramLanguage]?.PROCESS_NAME as string,
          name: datasetData?.linkedDiagram?.attributes?.[diagramLanguage]?.PROCESS_NAME as string,
          idDiagramType: datasetData?.linkedDiagram?.type === VCD_DIAGRAM ? VCD_DIAGRAM_ID : EPC_DIAGRAM_ID,
        },
      ]);
    } else {
      setChips([]);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    clearTimeout(timeoutRef.current as number);

    if (!searchText.trim()) {
      setSearchResults([]);
      return;
    }

    timeoutRef.current = window.setTimeout(() => {
      fetchOptions();
    }, 300);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (item: string) => {
    setSearchText(item);
    setIsOpen(!!item);
  };

  const handleRemoveChip = async () => {
    setIsOpen(false);
    setCanSelect(false);
    setSearchText('');
    try {
      setChips([]);
      await linkDiagramToDataset(+idDataSet, null);
      await saveDataset(datasetData, true);
      await fetchDataset(+idDataSet);
      setCanSelect(true);
    } catch (err) {
      handleServiceError(err);
    }
  };

  const handleClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setChips([]);
  };

  const handleClick = () => {
    setIsModalOpen(isModal);
    getPublishedVcdEpcTree().then((res) => {
      setDiagramList(res);
      setIsLoading(false);
    });
  };

  const handleLinkDiagram = async (diagram: { id: number; name: string; idDiagramType: number; isRecommendation: boolean }) => {
    try {
      setChips([
        {
          code: `${id}`,
          name: diagram.name,
          displayName: diagram.name,
          idDiagramType: diagram.idDiagramType,
        },
      ]);
      await saveDataset(datasetData);
      await linkDiagramToDataset(+idDataSet, diagram.id);
      await fetchDataset(+idDataSet);
    } catch (err) {
      handleServiceError(err);
    }
  };

  return (
    <>
      <div
        className={isSmall ? styles.ContainerMultiselectionSmall : styles.ContainerMultiselection}
        onClick={() => !disabled && canSelect && handleClick()}
      >
        <InputTextChipsNEPOS
          chips={chips || []}
          clearAll={handleClearAll}
          containerRef={containerRef}
          disabled={disabled}
          error={props.error}
          handleRemoveChip={handleRemoveChip}
          id={id}
          isLegacy
          isModal={isModal}
          label={label}
          max={max}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          reference={textInputRef}
          required={required}
          value={searchText}
        />
        {isOpen && (
          <DropdownPanel
            className={styles.Panel}
            close={() => {
              setIsOpen(false);
              setSearchText('');
            }}
            parentRef={containerRef}
          >
            {isSearchLoading || !searchResults.length ? (
              <div className={styles.Placeholder}>{isSearchLoading ? t('loading') : t('multiselection.noRecordsFound')}</div>
            ) : (
              <ul className={styles.List}>
                {searchResults.map((item, index) => (
                  <li className={styles.Item} key={item.code} tabIndex={index} />
                ))}
              </ul>
            )}
          </DropdownPanel>
        )}
      </div>
      {isModalOpen && (
        <LinkDiagramDialog
          assignModelers={() => {}}
          buttonText={t('select')}
          close={() => setIsModalOpen(false)}
          diagramList={diagramList}
          dialogTitle={t('selectEpcOrVcd')}
          isDiagramSelector
          isLoading={isLoading}
          lang={diagramLanguage}
          linkDiagram={(diagram: { id: number; name: string; idDiagramType: number; isRecommendation: boolean }) =>
            handleLinkDiagram(diagram)
          }
          target="#root"
        />
      )}
    </>
  );
};

export default DiagramSelector;
