import React from 'react';

import onClickOutside from 'react-onclickoutside';

import circleImg from '../../../assets/images/circle.png';
import diamondImg from '../../../assets/images/diamond.png';
import lineImg from '../../../assets/images/line.png';
import squareImg from '../../../assets/images/square.png';
import triangleImg from '../../../assets/images/triangle.png';
import styles from './ShapeSelector.module.scss';

const ShapeSelector = ({ selectShape, diagramType }) => {
  ShapeSelector.handleClickOutside = () => selectShape('');

  return (
    <div className={`${styles.Selector} ${styles[diagramType]}`}>
      <div className={styles.Item} id="square" onClick={() => selectShape('square')}>
        <img alt="square" src={squareImg} />
      </div>
      <div className={styles.Item} id="circle" onClick={() => selectShape('circle')}>
        <img alt="circle" src={circleImg} />
      </div>
      <div className={styles.Item} id="diamond" onClick={() => selectShape('diamond')}>
        <img alt="diamond" src={diamondImg} />
      </div>
      <div className={styles.Item} id="triangle" onClick={() => selectShape('triangle')}>
        <img alt="triangle" src={triangleImg} />
      </div>
      <div className={styles.Item} id="line" onClick={() => selectShape('line')}>
        <img alt="line" src={lineImg} />
      </div>
    </div>
  );
};

ShapeSelector.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => ShapeSelector.handleClickOutside,
};

export default onClickOutside(ShapeSelector, clickOutsideConfig);
