import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import styles from './TabSelector.module.scss';

const TabSelector = ({ options, selectedOption, handleClick, className, isNEPOS }) => {
  return (
    <div className={`${styles.Container} ${className}`}>
      {options?.map((tabOption) => {
        const button = (
          <button
            className={`${styles.Tab} ${tabOption.className} ${
              selectedOption === tabOption.id ? styles.Selected : styles.NoSelected
            } ${tabOption?.isValid ? '' : styles.Error} ${!tabOption?.isValid && isNEPOS && styles.ErrorNepos}`}
            id={tabOption.id}
            key={`TAB_SELECTOR_${tabOption.id}`}
            onClick={() => handleClick(tabOption.id)}
            type="button"
          >
            {tabOption.name}
            {!tabOption?.isValid && !isNEPOS && <i className="di icon-kreis-ausrufezeichen-wichtig" />}
            {!tabOption?.isValid && isNEPOS && <span className="error">*</span>}
          </button>
        );

        return tabOption?.tooltip ? (
          <TooltipComponent
            className={styles.ScopeTooltip}
            content={tabOption.tooltip}
            cssClass="mbc-tooltip"
            key={tabOption.id}
            openDelay={500}
            position="BottomCenter"
            showTipPointer={false}
          >
            {button}
          </TooltipComponent>
        ) : (
          button
        );
      })}
    </div>
  );
};

export default TabSelector;
