import { useEffect, useState, useCallback } from 'react';

import { RouteComponentProps, useParams, useHistory } from 'react-router-dom';

import { CLOSED_TASK_TYPES } from 'assets/constants/constants';
import { isTaskValidClosedWorkflow } from 'assets/js/Utils';
import TitleBar from 'components/TitleBar/TitleBar';
import Spinner from 'components/UI/Spinner/Spinner';
import useEnvironment from 'hooks/useEnvironment';
import DatasetWrapper from 'pages/Dataset/DatasetWrapper';
import DiagramWrapper from 'pages/Diagram/DiagramWrapper';
import NEPOSWrapper from 'pages/NEPOS/NEPOSWrapper';
import RequirementWrapper from 'pages/Requirements/RequirementWrapper';
import diagramService from 'services/diagramService';
import { workflowById } from 'services/workflowServices';
import { ProcessType } from 'types/processes';
import { WorkflowStatus } from 'types/workflow';

const DiagramComponentByTypeMap = {
  [ProcessType.SWIMLANE]: NEPOSWrapper,
  [ProcessType.SIPOC]: NEPOSWrapper,
  [ProcessType.VCD]: DiagramWrapper,
  [ProcessType.EPC]: DiagramWrapper,
  [ProcessType.DATA_SET]: DatasetWrapper,
  [ProcessType.REQUIREMENT]: RequirementWrapper,
};

const DiagramRedirection = (props: RouteComponentProps) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory<{ type?: ProcessType }>();
  const [diagramType, setDiagramType] = useState<ProcessType>();
  const [isLoading, setIsLoading] = useState(true);
  const checkEnvironment = useEnvironment();

  const getDiagramRedirection = useCallback(() => {
    const catchCallback = (err) => {
      diagramService.handleServiceError(err);
      setIsLoading(false);
    };

    const isClosedWorkflow = history.location.pathname.includes('closed-workflow');
    if (!isClosedWorkflow)
      return diagramService
        .getDiagramBasic(id)
        .then((res) => setDiagramType(res.data.type))
        .catch(catchCallback);

    workflowById(id)
      .then((res) => {
        const workflowData = res.data;
        const workflowType = workflowData.type.split('_').pop();

        setDiagramType(workflowType);
        const pseudoTask = {
          // TODO: [closedWorkflow-US-2]: Remove next line for SIPOC/SWIMLANE implementation
          diagramType: workflowType,
          status: workflowData.status === WorkflowStatus.IN_PROGRESS ? CLOSED_TASK_TYPES[1] : CLOSED_TASK_TYPES[0],
          workflowStatus: workflowData.status,
        };

        if (!isTaskValidClosedWorkflow(pseudoTask)) {
          return diagramService
            .getBasicDiagramInfo(workflowData.processNumber)
            .then((response) => history.push(`/diagram/${response.data.id}`))
            .catch(catchCallback);
        }

        history.push(`/closed-workflow/${workflowData.id}`);
      })
      .catch(catchCallback);
  }, [history, id]);

  useEffect(() => {
    if (history.location.state?.type) {
      setDiagramType(history.location.state.type);
      const state = { ...history.location.state };
      delete state.type;
      history.replace({ ...history.location, state });
      return;
    }

    if (checkEnvironment('dataset')) {
      setDiagramType(ProcessType.DATA_SET);
    } else if (checkEnvironment('requirements')) {
      setDiagramType(ProcessType.REQUIREMENT);
    } else {
      getDiagramRedirection();
    }
  }, [checkEnvironment, getDiagramRedirection, history, id]);

  if (!diagramType) {
    return (
      <>
        <TitleBar isLoading />
        <div className="spinner">
          <Spinner defaultSpinner isVisible={isLoading} />
        </div>
      </>
    );
  }
  const DiagramComponent = DiagramComponentByTypeMap[diagramType];
  return <DiagramComponent {...props} />;
};

export default DiagramRedirection;
