import { Process, Scope, User } from './catalog';
import { Language } from './config';
import { ConnectionTypes, Connector } from './connectors';
import { Attributes, SearchBy } from './forms';
import { ProcessSupplierCustomer } from './supplierCustomer';
import { SwimlaneV2 } from './swimlanes';
import { Symbol, SymbolTypes } from './symbols';

export enum ProcessType {
  DATA_SET = 'DATA_SET',
  EPC = 'EPC',
  VCD = 'VCD',
  SIPOC = 'SIPOC',
  SWIMLANE = 'SWIMLANE',
  REQUIREMENT = 'REQUIREMENT',
}

export type DiagramConfig = {
  symbols: {
    [processType in ProcessType]?: SymbolTypes[];
  };
  connections: {
    [symbolType in SymbolTypes]?: {
      type: ConnectionTypes;
      target: SymbolTypes[];
    };
  };
};

export type ProcessKPI = {
  id: string;
  attributes: Attributes;
};

export type ProcessInterface = {
  idDiagram: number;
  department?: string;
  processResponsible?: string;
  title: {
    [key in Language]?: string;
  };
  parsedTitle?: string;
  status?: string;
  origin?: string;
};

export type ProcessCatalogObject = {
  id: string;
  type: string;
  subtype: string;
  attributes: Attributes;
};

export type ProcessInputOutput = {
  id?: number;
  name: string;
  description: string;
  responsiblePerson?: string;
  creator?: string;
  containedIn?: [
    {
      id: string;
      status: string;
      nameMap?: string;
    },
  ];
  status: string;
  creation: string;
  attributes: Attributes;
};

export type ProcessHistoryDocuments = {
  id: string;
  type?: string;
  name?: string;
  author?: string | null;
  version?: string;
  validFrom?: string;
  validTo?: string;
};

export enum ProcessObject {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER',
}

export type ProcessDataCatalog = {
  [SearchBy.USER]: (Object & User)[];
  [SearchBy.SCOPE]: (Object & Scope)[];
  [SearchBy.PROCESS]: (Object & Process)[];
};

export type Levels = {
  id: string;
  name: Attributes;
};
export type StartEvent = { id: string; supplier?: ProcessSupplierCustomer; inputs?: ProcessCatalogObject[] };
export type LastEvent = { id: string; customer?: ProcessSupplierCustomer; outputs?: ProcessCatalogObject[] };

export type ProcessData = {
  id: string;
  type?: string;
  symbols: Symbol[];
  swimlanes: SwimlaneV2[];
  connectors: Connector[];
  attributes: Attributes;
  catalog?: ProcessDataCatalog;
  objects: ProcessCatalogObject[];
  supplier?: ProcessSupplierCustomer;
  customer?: ProcessSupplierCustomer;
  status: ProcessStatus;
  kpis: ProcessKPI[];
  interfaces?: ProcessInterface[];
  parentId: number;
  isOnlyRead: boolean;
  isPublishedVersion?: boolean;
  isRecommendation: boolean;
  isSwimlane: boolean;
  onlyRead?: {
    reason: string;
    usedBy: string;
  };
  lastEvents?: LastEvent[];
  startEvents?: StartEvent[];
  favorite?: boolean;
  video?: boolean;
};

export type ProcessDataRequest = Pick<
  ProcessData,
  'type' | 'symbols' | 'connectors' | 'attributes' | 'kpis' | 'objects' | 'supplier' | 'customer' | 'swimlanes'
>;

export type ProcessDataResponse = Omit<ProcessData, 'symbols' | 'connectors' | 'interfaces'> & Partial<ProcessData>;

export enum ProcessStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  WORKFLOW = 'WORKFLOW',
  PUBLISHED = 'PUBLISHED',
  SANDBOX = 'SANDBOX',
  ARCHIVED = 'ARCHIVED',
}

export enum ProcessTabs {
  PROCESS = 'processTab',
  KPIS = 'kpis',
  INTERFACES = 'interfaces',
  VIDEO = 'video',
}

export type Modeler = {
  Name: string;
  Code: string;
};

export type Modelers = Modeler[] & {
  linkDiagram: boolean;
};

export type ProcessScope = {
  id?: number;
  scope: string;
  displayValue: string;
};

export type ProcessRole = {
  id?: string | null;
  status?: string | null;
  name?: string;
  description?: string;
  containedIn?: string[];
  creation?: string;
  tasks?: string;
};

export type ProcessItSystem = {
  id?: string | null;
  status?: string | null;
  name?: string;
  description?: string;
  containedIn?: string[];
  creation?: string;
};

export type TreeData = {
  id: number;
  name: string;
  idDiagramType: number;
  isRecommendation: boolean;
  pid?: number;
};

export type TreeElement = {
  id: number;
  name: string;
  children: TreeElement[];
};

export type VideoData = {
  name: string;
  user: string;
  lastModification: string;
};
