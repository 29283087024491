import { ReactNode } from 'react';

import RoundButton from 'components/UI/RoundButton/RoundButton';

import styles from './NEPOSTabWrapper.module.scss';

type NEPOSTabWrapperProps = {
  children?: ReactNode;
  handleClose: () => void;
  extraClass?: string;
};

const NEPOSTabWrapper = ({ children, handleClose, extraClass }: NEPOSTabWrapperProps) => {
  return (
    <div className={`${styles.Wrapper} ${extraClass || ''}`}>
      <RoundButton className={styles.Close} icon="icon-schliessen" id="close-process-attributes" onClick={handleClose} />
      {children}
    </div>
  );
};

export default NEPOSTabWrapper;
