import { useCallback, useEffect, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { DELETE_BUTTON, EDIT_BUTTON, INFO_BUTTON, REDIRECT_BUTTON } from 'assets/constants/ActionButtons';
import { STATUS } from 'assets/constants/constants';
import ActionBox from 'components/ActionBox/ActionBox';
import ButtonsPanel from 'components/ButtonsPanel/ButtonsPanel';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import { ActionIcon } from 'types/actionIcon';
import { AttributeValue } from 'types/forms';

import styles from './InnerBlock.module.scss';

type Props = {
  id: string;
  onClick?: () => void;
  onClickInnerButton?: () => void;
  onClickEditButton?: () => void;
  handleDelete?: () => void;
  isFilled?: boolean;
  blockName?: AttributeValue;
  right?: boolean;
  isSupplierCustomer?: boolean;
  status?: string;
  supplierCustomerPublished?: boolean;
  isSwimlaneStyle?: boolean;
};
const InnerBlock = ({
  id,
  onClick,
  isFilled,
  blockName,
  onClickInnerButton,
  onClickEditButton,
  handleDelete,
  right,
  isSupplierCustomer,
  status,
  supplierCustomerPublished,
  isSwimlaneStyle,
}: Props) => {
  const { processData } = useDiagramContext();
  const [buttons, setButtons] = useState<ActionIcon[]>([]);
  const { t } = useTranslation();
  const { showAlert } = useError();

  useEffect(() => {
    const newButtons: ActionIcon[] = [];
    if (onClickEditButton) newButtons.push({ ...EDIT_BUTTON, id: `${id}-${EDIT_BUTTON.id}`, onClick: onClickEditButton });
    if (handleDelete)
      newButtons.push({
        ...DELETE_BUTTON,
        id: `${id}-${EDIT_BUTTON.id}`,
        onClick: handleDelete,
      });
    setButtons(newButtons);
  }, [handleDelete, id, onClickEditButton]);

  const handleActionBoxClick = () => {
    if (isFilled && isSupplierCustomer && processData?.isPublishedVersion && !supplierCustomerPublished) {
      showAlert(t('process.not.released.warning'), t('warningText'));
      return;
    }
    if (
      onClick &&
      ((!isFilled && !processData?.isOnlyRead) ||
        ((processData?.isPublishedVersion || processData?.status === STATUS.WORKFLOW) && isFilled))
    )
      onClick();
  };

  const renderIcon = useCallback((): ActionIcon | undefined => {
    const disabled = isSupplierCustomer && processData?.status === STATUS.PUBLISHED && !supplierCustomerPublished;

    return isSupplierCustomer
      ? {
          ...REDIRECT_BUTTON,
          id: 'redirect-diagram-button',
          onClick: () => {
            if (onClickInnerButton) onClickInnerButton();
          },
          disabled,
        }
      : {
          ...INFO_BUTTON,
          onClick: () => {
            if (onClickInnerButton) onClickInnerButton();
          },
          disabled,
        };
  }, [isSupplierCustomer, processData?.status, supplierCustomerPublished, onClickInnerButton]);

  const renderContent = useCallback((): React.ReactNode => {
    if (
      isSupplierCustomer &&
      (processData?.isPublishedVersion || (processData?.isSwimlane && processData?.status === STATUS.WORKFLOW)) &&
      !supplierCustomerPublished &&
      status !== STATUS.TEMPORAL
    )
      return (
        <div className={styles.Pending}>
          <TooltipComponent
            content={t('process.not.released.warning')}
            cssClass="mbc-tooltip nepos-tooltip"
            position={right ? 'TopLeft' : 'TopRight'}
            showTipPointer={false}
          >
            {t('label.pending')}
          </TooltipComponent>
        </div>
      );

    if (status)
      return (
        <TooltipComponent
          className={styles.LabelTooltip}
          content={t(`status.tooltip.${status}`)}
          position="BottomCenter"
          showTipPointer={false}
        >
          <span className={styles.Label}>{t(`status.${status}`)}</span>
        </TooltipComponent>
      );
  }, [
    isSupplierCustomer,
    processData?.isPublishedVersion,
    processData?.isSwimlane,
    processData?.status,
    right,
    status,
    supplierCustomerPublished,
    t,
  ]);

  return (
    <div>
      <ButtonsPanel buttons={buttons} disabled={!isFilled} key={id}>
        <ActionBox
          className={`${styles.ActionBox} ${isSwimlaneStyle ? styles.Swimlane : ''}`}
          id={`${id}-actionBox`}
          innerIcon={
            !processData?.isPublishedVersion && processData?.status !== STATUS.WORKFLOW && !status ? renderIcon() : undefined
          }
          label={isFilled ? blockName?.toString() : undefined}
          onClick={handleActionBoxClick}
        >
          {renderContent()}
        </ActionBox>
      </ButtonsPanel>
    </div>
  );
};

export default InnerBlock;
