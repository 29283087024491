import { MouseEvent } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { VariantProperty } from 'types/forms';

import styles from './CheckboxNEPOS.module.scss';

interface Props {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  handleCheck: (id?: string, event?: MouseEvent) => void;
  disabledMessage?: string;
  label?: string;
  onChange?: (id?: string) => void;
  variant?: VariantProperty;
}

const CheckboxNEPOS = (props: Props) => {
  const { checked, disabled, id, handleCheck, disabledMessage, label, onChange, variant } = props;

  const Checkbox = () => {
    return (
      <div className={styles.CheckFilter}>
        <div className={`${styles.Box} ${checked ? styles.Checked : ''}`}>{checked && <i className="di icon-check" />}</div>
      </div>
    );
  };

  const Toggle = () => {
    return (
      <>
        <div className={`${styles.Toggle} ${checked ? styles.Checked : ''} ${disabled ? styles.Disabled : ''} `}>
          <div
            className={`${styles.Button} ${checked ? styles.Checked : ''} ${disabled ? styles.Disabled : ''} `}
            onClick={() => !disabled && (onChange ? onChange(id) : handleCheck(id))}
          />
        </div>
      </>
    );
  };

  return (
    <div
      className={`${label ? styles.FormCheckbox : ''} ${disabled ? styles.Disabled : ''} ${
        variant === VariantProperty.TOGGLE ? styles.Toggle : ''
      }`}
    >
      <div
        className={`${variant === VariantProperty.TOGGLE ? styles.WrapperToggle : styles.WrapperCheckbox} ${
          checked ? styles.Checked : ''
        }`}
        id={id}
        onClick={(event) =>
          !disabledMessage &&
          !disabled &&
          variant !== VariantProperty.TOGGLE &&
          (onChange ? onChange(id) : handleCheck(id, event))
        }
      >
        {disabledMessage ? (
          <TooltipComponent
            content={disabledMessage}
            cssClass="mbc-tooltip nepos-tooltip"
            position="TopRight"
            showTipPointer={false}
          >
            <Checkbox />
          </TooltipComponent>
        ) : (
          <>
            {variant !== VariantProperty.TOGGLE && <Checkbox />}
            {variant === VariantProperty.TOGGLE && <Toggle />}
          </>
        )}
      </div>
      {label && <div className={`${styles.Label} ${variant === VariantProperty.TOGGLE ? styles.Toggle : ''}`}>{label}</div>}
    </div>
  );
};

export default CheckboxNEPOS;
