import React, { useState } from 'react';

import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { AUTHORS, MODELERS, ASSIGN_AUTHOR_PROCESS_TYPES } from 'assets/constants/constants';

import UsersService from '../../services/userService';
import Button from '../UI/Button/Button';
import styles from './DialogDetail.module.scss';

const AssignUserDialog = (props) => {
  const { assignUsers, close, contextMenuOptionSelected, linkDiagram, refAssignment, target } = props;
  const { t } = useTranslation();
  const [usersToBeAssigned, setUsersToBeAssigned] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [autoCompleteElement, setAutoComplete] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [timeout, updateTimeout] = useState(undefined);
  const userLimit = ASSIGN_AUTHOR_PROCESS_TYPES.includes(contextMenuOptionSelected) ? 1 : 3;
  const fields = {
    value: 'Name',
  };

  const selectUser = (selectedUser) => {
    if (selectedUser) {
      const itemAlreadyAdded = usersToBeAssigned.find((user) => user.Code === selectedUser.Code);

      if (!itemAlreadyAdded) {
        setUsersToBeAssigned([...usersToBeAssigned, selectedUser]);
      }
    }
  };

  const autocompleteTemplate = (item) => (
    <span className="autocompleteItem">
      <div className="iconPerson2"></div>
      {`${item.Name} (${item.Code})`}
    </span>
  );

  const emptyAutoCompleteElement = () => {
    if (autoCompleteElement) {
      autoCompleteElement.value = null;
    }
  };

  const reset = () => {
    emptyAutoCompleteElement();
    setUsersList([]);
  };

  const cleanDialog = () => {
    emptyAutoCompleteElement();
    setUsersToBeAssigned([]);
  };

  const deleteItem = (userToDelete, e) => {
    e.preventDefault();
    e.stopPropagation();
    const filteredUsersList = [...usersToBeAssigned].filter((user) => userToDelete.Code !== user.Code);
    setUsersToBeAssigned(filteredUsersList);
  };

  const closeDialog = () => {
    cleanDialog();
    close();
  };

  const sendUsers = () => {
    closeDialog();

    if (linkDiagram) {
      usersToBeAssigned.linkDiagram = true;
      assignUsers(usersToBeAssigned);
      linkDiagram();
    } else {
      assignUsers(usersToBeAssigned);
    }
  };

  const getUsersList = (event) => {
    clearTimeout(timeout);

    if (event.text === '') {
      setUsersList([]);

      return;
    }

    setLoading(true);

    updateTimeout(
      setTimeout(() => {
        UsersService.getUsersByRole(event.text, 'MODELER')
          .then((response) => {
            const multiselectionUsers = response.data.results.map((user) => ({ Name: user.commonName, Code: user.code }));

            if (multiselectionUsers.length > 0) {
              setUsersList(multiselectionUsers);
              setTimeout(() => {
                if (autoCompleteElement) {
                  autoCompleteElement.showPopup();
                }
              }, 100);
            }

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            UsersService.handleServiceError(err);
          });
      }, 400),
    );
  };

  const loadDefaultText = () => {
    let defaultText = t('loading');

    if (!loading) {
      defaultText =
        autoCompleteElement && autoCompleteElement.properties.dataSource.length !== 0
          ? t('multiselection.noRecordsFound')
          : t('multiselection.enterUser');
    }

    return `<span>${defaultText}</span>`;
  };

  return (
    <DialogComponent
      className={styles.DialogDetail}
      close={closeDialog}
      id="dialog-detail"
      isModal
      ref={(dialog) => refAssignment(dialog)}
      target={target || '#dialog'}
      width="510px"
    >
      <div className={`${styles.Content} ${styles.ModelerContent}`}>
        <div className={`${styles.Section} ${styles.SectionNoCatalog}`}>
          <h4>
            {t('assignUsers', {
              userRole: ASSIGN_AUTHOR_PROCESS_TYPES.includes(contextMenuOptionSelected) ? t(AUTHORS) : t(MODELERS),
            })}
          </h4>
          <div className={`autocomplete ${styles.Input}`} id="user-autocomplete">
            <AutoCompleteComponent
              blur={() => reset()}
              dataSource={usersList}
              fields={fields}
              filtering={(e) => getUsersList(e)}
              id="inputAutoCompleteUsers"
              itemTemplate={(item) => autocompleteTemplate(item)}
              noRecordsTemplate={loadDefaultText()}
              placeholder={t('searchX', {
                labelX: ASSIGN_AUTHOR_PROCESS_TYPES.includes(contextMenuOptionSelected) ? t(AUTHORS) : t(MODELERS),
              })}
              ref={(autoComplete) => {
                setAutoComplete(autoComplete);
              }}
              select={(args) => selectUser(args.itemData)}
              showPopupButton
            />
            <div className={styles.IcoSearch} id="search">
              
            </div>
          </div>
          <div className={`${styles.TitleSelected} ${usersToBeAssigned.length > userLimit ? styles.TitleWarning : ''}`}>
            {t('selectedUsersToBeAssigned', {
              userRole: ASSIGN_AUTHOR_PROCESS_TYPES.includes(contextMenuOptionSelected) ? t(AUTHORS) : t(MODELERS),
              limit: userLimit,
            })}
            {usersToBeAssigned.length > userLimit && <i className="di icon-information" />}
          </div>
          <div className={styles.ItemSelected}>
            {usersToBeAssigned.map((input) => (
              <div className={styles.CatalogBox} key={input.Code}>
                <div aria-hidden="true" className={styles.DeleteIcon} id="delete" onClick={(event) => deleteItem(input, event)}>
                  
                </div>
                <div aria-hidden="true" className={styles.CatalogIcon}>
                  
                </div>
                <p>{`${input.Name} (${input.Code})`}</p>
              </div>
            ))}
          </div>
          {usersToBeAssigned.length > userLimit && (
            <div className={styles.LimitMsg}>
              {t('usersLimit', {
                userRole: ASSIGN_AUTHOR_PROCESS_TYPES.includes(contextMenuOptionSelected) ? t(AUTHORS) : t(MODELERS),
                min: 1,
                max: userLimit,
              })}
            </div>
          )}
          <div className={styles.ButtonLine}>
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={close} id="cancel" />
            <Button
              btnText={t('assign')}
              buttonStyle="Primary"
              disabled={usersToBeAssigned.length === 0 || usersToBeAssigned.length > userLimit}
              handleClick={sendUsers}
              id="assign"
            />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default React.memo(AssignUserDialog, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
