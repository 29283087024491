import { useCallback } from 'react';

import { handleServiceError } from 'assets/js/serviceUtils';
import { downloadFile } from 'assets/js/Utils';
import {
  ActivitySpecificationActionTypes,
  addDocument as addDocumentContext,
  addLink as addLinkContext,
  removeDocument as removeDocumentContext,
  removeLink as removeLinkContext,
  setDocument as setDocumentContext,
  setDocuments as setDocumentsContext,
  setId,
  setLink,
  setLinks as setLinksContext,
  setVersion as setVersionContext,
} from 'contexts/ActivitySpecification/ActivitySpecificationContext';
import { addActivityLink, removeActivityLink } from 'services/design';
import { getActivityDoc, removeActivityFile, uploadActivityFiles } from 'services/documentation';
import { Link, UploadStatus, Document, LinkWithStatus, DocLinkTypes } from 'types/activitySpecification';

import useActivitySpecificationContext from './useActivitySpecificationContext';

export default function useActivitySpecification() {
  const { links, documents: documentsContext, id: processNumber, version, dispatch } = useActivitySpecificationContext();

  const setLinks = useCallback(
    (newLinks: Link[]) => {
      dispatch(setLinksContext(newLinks.map((link) => ({ ...link, status: UploadStatus.SUCCESS }))));
    },
    [dispatch],
  );

  const addLink = useCallback(
    (link: Link, activityId: number) => {
      if (links.some((l) => l.name === link.name && l.type === link.type)) return;
      dispatch(addLinkContext({ ...link, status: UploadStatus.LOADING }));
      addActivityLink(link, activityId)
        .then(() => dispatch(setLink({ ...link, status: UploadStatus.SUCCESS })))
        .catch(() => {
          dispatch(setLink({ ...link, status: UploadStatus.ERROR }));
        });
    },
    [dispatch, links],
  );

  const removeLink = useCallback(
    (link: LinkWithStatus, activityId: number) => {
      if (link.status === UploadStatus.ERROR) {
        dispatch(removeLinkContext({ ...link }));
      } else {
        dispatch(setLink({ ...link, status: UploadStatus.LOADING }));
        removeActivityLink(link, activityId)
          .then(() => {
            dispatch(removeLinkContext({ ...link }));
          })
          .catch(() => {
            dispatch(setLink({ ...link }));
          });
      }
    },
    [dispatch],
  );

  const removeErrors = useCallback(() => {
    dispatch({ type: ActivitySpecificationActionTypes.CLEAN_LINKS, payload: undefined });
    dispatch({ type: ActivitySpecificationActionTypes.CLEAN_DOCS, payload: undefined });
  }, [dispatch]);

  const setDocuments = useCallback(
    (documentsToSet: Document[]) => {
      dispatch(setDocumentsContext(documentsToSet.map((doc) => ({ ...doc, status: UploadStatus.SUCCESS }))));
    },
    [dispatch],
  );

  const addDocument = useCallback(
    (typeFile: DocLinkTypes, activityId: number, document: File, index: number): void => {
      if (documentsContext.some((doc) => doc.name === document.name && doc.type === typeFile)) return;
      const MAX_DOCS = 16;
      const documentToShow = { name: document.name, type: typeFile, value: new Date().toISOString() };
      if (
        documentsContext.filter((doc) => doc.status === UploadStatus.SUCCESS && doc.type === typeFile).length + index >=
        MAX_DOCS
      ) {
        dispatch(addDocumentContext({ ...documentToShow, status: UploadStatus.ERROR }));
        return;
      }
      dispatch(addDocumentContext({ ...documentToShow, status: UploadStatus.LOADING }));
      uploadActivityFiles(typeFile, activityId, document)
        .then((response) => {
          if (response.data.uploaded.length > 0) {
            dispatch(setDocumentContext({ ...documentToShow, status: UploadStatus.SUCCESS }));
          } else {
            dispatch(setDocumentContext({ ...documentToShow, status: UploadStatus.ERROR }));
          }
        })
        .catch(() => {
          dispatch(setDocumentContext({ ...documentToShow, status: UploadStatus.ERROR }));
        });
    },
    [dispatch, documentsContext],
  );

  const addDocuments = useCallback(
    (typeFile: DocLinkTypes, activityId: number, documents: File[]) => {
      if (documents.length <= 0) return;
      documents.forEach((document, index) => {
        addDocument(typeFile, activityId, document, index);
      });
    },
    [addDocument],
  );

  const removeDocument = useCallback(
    (document: Document, activityId: number) => {
      if (document.status === UploadStatus.ERROR) {
        dispatch(removeDocumentContext({ ...document }));
      } else {
        dispatch(setDocumentContext({ ...document, status: UploadStatus.LOADING }));
        removeActivityFile(document.type, activityId, document.name)
          .then(() => {
            dispatch(removeDocumentContext({ ...document }));
          })
          .catch(() => {
            dispatch(setDocumentContext({ ...document }));
          });
      }
    },
    [dispatch],
  );

  const getActivityDocument = useCallback(
    (name: string, typeFile: DocLinkTypes, idActivity: number) =>
      getActivityDoc(name, typeFile, idActivity, processNumber, version)
        .then((res) => {
          const binaryData = [res.data];
          const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
          downloadFile(url, name, res.headers['content-disposition']);
        })
        .catch(handleServiceError),
    [processNumber, version],
  );

  const setProcessId = useCallback(
    (id: string) => {
      dispatch(setId(id));
    },
    [dispatch],
  );

  const setVersion = useCallback(
    (newVersion: string) => {
      dispatch(setVersionContext(newVersion));
    },
    [dispatch],
  );

  return {
    addDocuments,
    addLink,
    getActivityDocument,
    removeDocument,
    removeErrors,
    removeLink,
    setDocuments,
    setLinks,
    setProcessId,
    setVersion,
  };
}
