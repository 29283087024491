import { useEffect, useRef, useState, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  MAX_REQUIREMENT_FILES,
  MAX_REQUIREMENT_FILE_SIZE,
  NOT_TRANSLATABLE,
  RR,
} from 'assets/constants/constants';
import uploadDocumentImage from 'assets/images/uploadDocuments.svg';
import uploadDocumentHoverImage from 'assets/images/uploadDocumentsHover.svg';
import { downloadBlob, getFullFormattedDate, removeSquareBracketsFromString } from 'assets/js/Utils';
import Accordion from 'components/UI/Accordion/Accordion';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import DocumentCard from 'components/UI/DocumentCard/DocumentCard';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import SearchNEPOS from 'components/UI/SearchNEPOS/SearchNEPOS';
import Spinner from 'components/UI/Spinner/Spinner';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import TextAreaNEPOS from 'components/UI/TextArea/TextAreaNEPOS';
import {
  setRequirementAttributes,
  setSolutionApproach as setSolutionApproachContext,
} from 'contexts/Requirement/RequirementContext';
import useAuth from 'hooks/useAuth';
import useBackendForm from 'hooks/useBackendForm';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useFormTypes from 'hooks/useFormTypes';
import useRequirement from 'hooks/useRequirement';
import useRequirementContext from 'hooks/useRequirementContext';
import useValidation from 'hooks/useValidation';
import ProcessLevelDatabaseTable from 'pages/ProcessLevelDatabase/ProcessLevelDatabaseTable';
import FinancialEvaluationTable from 'pages/Requirements/FinancialEvaluation/FinancialEvaluationTable';
import { deleteDocumentRequirement } from 'services/documentation';
import { getRequirementDocument } from 'services/documentationService';
import { uploadDocuments } from 'services/requirement';
import { Language } from 'types/config';
import { DialogType } from 'types/dialogs';
import { AttributeCode, Chip, Field, SearchBy } from 'types/forms';
import { ProcessLevelFormatted } from 'types/processLevel';
import {
  DocumentsRequirement,
  RequirementActions,
  RequirementDocumentContext,
  RequirementFinancialEvaluation,
  RequirementStatus,
  ResponsibleProcessUserTypes,
  SolutionApproach,
} from 'types/requirement';
import { AccordionStatus, TableColumn, TableVariant } from 'types/tables';
import { CatalogUser } from 'types/user';

import styles from './RequirementForm.module.scss';

type Props = {
  originProcessLevelRows: ProcessLevelFormatted[] | undefined;
  originRows: ProcessLevelFormatted[] | undefined;
  responsibleProcessLevelRows: ProcessLevelFormatted[] | undefined;
  responsibleRows: ProcessLevelFormatted[] | undefined;
};

const DEFAULT_FINANCIAL_ROW = {
  id: '0',
  year: String(new Date().getFullYear()),
  requiredInvest: '',
  annualEbitEffect: '',
  oneTimeCashflowEffect: '',
};

const DEFAULT_FINANCIAL_INPUT = { value: '', rowId: '', columnId: '' };

const RequirementForm = (props: Props) => {
  const { originProcessLevelRows, responsibleProcessLevelRows, responsibleRows } = props;
  const { userInfo } = useAuth();
  const { t, i18n } = useTranslation();
  const { getFormTypesCode } = useFormTypes();
  const { requirementData, dispatch } = useRequirementContext();
  const { isFreezed } = useFeatureFlags();
  const {
    triggerRequirementAction,
    getVariantView,
    loggedUserMatchByType,
    isUserInProcess,
    isLoggedUserResponsible,
    isUserResponsibleOrPacemakerInProcess,
  } = useRequirement();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState<string>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [requirementAction, setRequirementAction] = useState<RequirementActions>();
  const [showDialogDeleteDocumentInitialSituation, setShowDialogDeleteDocumentInitialSituation] = useState<boolean>(false);
  const [showDialogDeleteDocumentSolutionAproach, setShowDialogDeleteDocumentSolutionAproach] = useState<boolean>(false);
  const [documentNameToDelete, setDocumentNameToDelete] = useState<string>('');
  const [solutionApproach, setSolutionApproach] = useState<SolutionApproach[]>([]);
  useEffect(() => {
    if (solutionApproach.length || !requirementData?.solutionApproach) return;
    setSolutionApproach(requirementData.solutionApproach);
  }, [requirementData?.solutionApproach, solutionApproach]);

  const [formStatusInitualSituation, setFormStatusInitialSituation] = useState(AccordionStatus.UNTOUCHED);
  const [formStatusSolutionApproach, setFormStatusSolutionApproach] = useState(AccordionStatus.UNTOUCHED);
  const [validInitialSituationFiles, setValidInitialSituationFiles] = useState<File[]>([]);
  const [validSolutionApproachFiles, setValidSolutionApproachFiles] = useState<File[]>([]);
  const [invalidInitialSituationFiles, setInvalidInitialSituationFiles] = useState<{ [key: string]: File[] }>({});
  const [invalidSolutionApproachFiles, setInvalidSolutionApproachFiles] = useState<{ [key: string]: File[] }>({});
  const [chips, setChips] = useState<Chip[]>([]);
  const filesInitialSituationInputRef = useRef<HTMLInputElement | null>(null);
  const filesSolutionApproachInputRef = useRef<HTMLInputElement | null>(null);
  const { isRequirementValid, isValidationActive } = useValidation();
  const { handleServiceError } = useError();
  const [initialSituationDocuments, setInitialSituationDocuments] = useState<
    { name: string; id: number; user: string; date: string }[]
  >([]);
  const [solutionApproachDocuments, setSolutionApproachDocuments] = useState<
    { name: string; id: number; user: string; date: string }[]
  >([]);

  const areOriginAndResponsibleSameProcesses = (): boolean => {
    const currentAttributes = requirementData?.attributes.NOT_TRANSLATABLE;
    if (!currentAttributes) return false;
    const responsibleProcesses = removeSquareBracketsFromString(currentAttributes.IDS_RESPONSIBLE_PROCESS);

    return (
      !!currentAttributes.ID_ORIGIN_PROCESS &&
      responsibleProcesses?.length === 1 &&
      currentAttributes.ID_ORIGIN_PROCESS === responsibleProcesses[0]
    );
  };

  const invalidInitialSituationFilesExist =
    !!invalidInitialSituationFiles.duplicate?.length ||
    !!invalidInitialSituationFiles.format?.length ||
    !!invalidInitialSituationFiles.size?.length;
  const invalidSolutionApproachFilesExist =
    !!invalidSolutionApproachFiles.duplicate?.length ||
    !!invalidSolutionApproachFiles.format?.length ||
    !!invalidSolutionApproachFiles.size?.length;
  const isEmptyInitialSituationList =
    !initialSituationDocuments.length && !validInitialSituationFiles.length && !invalidInitialSituationFilesExist;
  const isEmptySolutionApproachList =
    !solutionApproachDocuments.length && !validSolutionApproachFiles.length && !invalidSolutionApproachFilesExist;
  const [isInitialSituationExpanded, setIsInitialSituationExpanded] = useState<boolean>(false);
  const [isSolutionApproachExpanded, setIsSolutionApproachExpanded] = useState<boolean>(false);
  const [isInitialDocumentsEditable, setIsInitialDocumentsEditable] = useState<boolean>(false);
  const [isSolutionDocumentsEditable, setIsSolutionDocumentsEditable] = useState<boolean>(false);
  const [finantialEvaluationToggle, setFinacialEvaluationToggle] = useState<boolean>(false);
  const [showDeclineRequirementDialog, setShowDeclineRequirementDialog] = useState(false);
  const [declineMessageValue, setDeclineMessageValue] = useState('');
  const [inputValue, setInputValue] = useState(DEFAULT_FINANCIAL_INPUT);
  const [rows, setRows] = useState([DEFAULT_FINANCIAL_ROW]);
  const [selectedElements, setSelectedElements] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showProcessSelectionDialog, setShowProcessSelectionDialog] = useState(false);
  const [processSelectionDialogType, setProcessSelectionDialogType] = useState<AttributeCode | undefined>(undefined);
  const [selectedOriginProcessId, setSelectedOriginProcessId] = useState<string>('');
  const [selectedResponsibleProcessIds, setSelectedResponsibleProcessIds] = useState<string[]>([]);
  const [selectedSolutionApproachTab, setSelectedSolutionApproachTab] = useState<string>('');
  const [searchText, setSearchText] = useState<string>();
  const isOriginProcess = processSelectionDialogType === AttributeCode.ID_ORIGIN_PROCESS;
  const isSearching = Boolean(searchText && searchText.length > 2);

  const errorReasonMap: { [key: string]: string } = {
    duplicate: 'requirement.errors.API_ERROR_601',
    format: 'requirement.errors.API_ERROR_13',
    size: 'requirement.errors.API_ERROR_12',
    other: 'requirement.errors.API_ERROR_14',
  };

  const apiErrorMap: { [key: string]: string } = {
    API_ERROR_601: 'duplicate',
    API_ERROR_13: 'format',
    API_ERROR_12: 'size',
    API_ERROR_14: 'other',
  };

  const financialEvaluationColumns: TableColumn<RequirementFinancialEvaluation>[] = useMemo(() => {
    return [
      { id: 'year', style: '' },
      { id: 'requiredInvest', style: '' },
      { id: 'annualEbitEffect', style: '' },
      { id: 'oneTimeCashflowEffect', style: '' },
    ];
  }, []);

  const processLevelColumns: TableColumn<ProcessLevelFormatted>[] = [
    { id: 'title', style: styles.ProcessTitle },
    { id: 'level', style: styles.ProcessLevel },
    { id: 'processResponsibles', style: styles.ProcessResponsibles },
    { id: 'department', style: styles.Department },
  ];

  const setFinantialEvaluationInSolutionApproachSelected = (financialEvaluation: RequirementFinancialEvaluation[]) => {
    setSolutionApproach((approaches) => {
      approaches
        .filter((approach) => approach.idResponsibleProcess === Number(selectedSolutionApproachTab))
        .map((approach) => ({ ...approach, financialEvaluation }));
      return approaches;
    });
  };

  const getProcessResponsibleId = (context: RequirementDocumentContext) => {
    if (!requirementData) return;

    return context === RequirementDocumentContext.SOLUTION_APPROACH
      ? requirementData.responsibleProcesses?.find((process) => process.id === Number(selectedSolutionApproachTab))?.id
      : undefined;
  };

  const handleAddRow = () => {
    if (!requirementData) return;

    let newRows: RequirementFinancialEvaluation[] = [];

    rows.push({
      ...DEFAULT_FINANCIAL_ROW,
      id: rows.length ? String(Number(rows[rows.length - 1].id) + 1) : '0',
    });

    newRows = rows;

    setInputValue({ ...DEFAULT_FINANCIAL_INPUT });
    setFinantialEvaluationInSolutionApproachSelected(newRows);
    setIsCheckedAll(false);
    setRows(newRows);
  };

  useEffect(() => {
    const financialEvaluation = solutionApproach.find(
      (approach) => approach.idResponsibleProcess === Number(selectedSolutionApproachTab),
    )?.financialEvaluation;
    if (!solutionApproach || !financialEvaluation) return;
    setInputValue({ ...DEFAULT_FINANCIAL_INPUT });
    setRows(financialEvaluation.length > 0 ? financialEvaluation : [{ ...DEFAULT_FINANCIAL_ROW }]);
  }, [solutionApproach, selectedSolutionApproachTab]);

  const handleFinancialInput = useCallback(
    (value, rowId, columnId) => {
      setInputValue({ value, rowId, columnId });

      const updatedRow = rows?.find((row) => row.id === rowId);
      if (!updatedRow) return;
      updatedRow[columnId as keyof RequirementFinancialEvaluation] = value;

      if (!requirementData) return;
      setFinantialEvaluationInSolutionApproachSelected(rows);
      return updatedRow;
    },
    [rows, inputValue.columnId, inputValue.rowId, inputValue.value], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCheck = (itemId: string) => {
    let newSelectedElements = [...selectedElements];

    if (selectedElements.some((elem) => itemId === elem)) {
      newSelectedElements = selectedElements.filter((el) => el !== itemId);
      setIsCheckedAll(false);
    } else if (itemId === 'all') {
      newSelectedElements = isCheckedAll ? [] : rows.map(({ id }) => id);
      setIsCheckedAll(!isCheckedAll);
    } else {
      newSelectedElements = [...selectedElements, itemId];
      if (newSelectedElements.length === rows.length) setIsCheckedAll(true);
    }
    setSelectedElements(newSelectedElements);
  };

  const handleDeleteRow = () => {
    if (!requirementData) return;
    const newRows = rows.filter((element) => selectedElements.indexOf(element.id) === -1);
    setInputValue({ ...DEFAULT_FINANCIAL_INPUT });
    setRows(newRows);
    setFinantialEvaluationInSolutionApproachSelected(newRows);
    setIsCheckedAll(false);
    setSelectedElements([]);
  };

  const uploadRequirementDocuments = (files: File[], context: RequirementDocumentContext) => {
    if (!requirementData || !files.length) return;

    const formData = new FormData();

    files.forEach((file) => formData.append('files', file, file.name));

    uploadDocuments(requirementData.id, formData, context, getProcessResponsibleId(context)).then((res) => {
      const { data } = res;

      if (data.uploaded?.length) {
        let uploadedFileNames: string[] = [];
        let newInvalidFiles: { [key: string]: File[] } = {};

        if (context === RequirementDocumentContext.INITIAL_SITUATION) {
          setInitialSituationDocuments([
            ...initialSituationDocuments,
            ...data.uploaded.map((doc: DocumentsRequirement) => ({
              id: requirementData.id,
              name: doc.name,
              user: data.catalog?.users?.find((user: CatalogUser) => user.code === doc.creator)?.commonName || '',
              date: getFullFormattedDate(doc.date),
            })),
          ]);

          uploadedFileNames = data.uploaded?.map((file: DocumentsRequirement) => file.name);
          newInvalidFiles = {};
          ['duplicate', 'format', 'size', 'other'].forEach((key) => {
            newInvalidFiles[key] = [...(invalidInitialSituationFiles[key] || [])].filter(
              (file) => !uploadedFileNames.includes(file.name),
            );
          });

          data.errors?.forEach((error: { file: string; code: string }) => {
            const { file, code } = error;
            const fileObj = validInitialSituationFiles.find((validFile) => validFile.name === file);
            if (!fileObj) return;

            const errorKey = apiErrorMap[code];
            newInvalidFiles[errorKey].push(fileObj);
          });

          setValidInitialSituationFiles([]);
          setInvalidInitialSituationFiles(newInvalidFiles);
        } else if (context === RequirementDocumentContext.SOLUTION_APPROACH) {
          const documentsUploaded = data.uploaded.map((doc: DocumentsRequirement) => ({
            id: requirementData.id,
            name: doc.name,
            user: data.catalog?.users?.find((user: CatalogUser) => user.code === doc.creator)?.commonName || '',
            date: getFullFormattedDate(doc.date),
          }));
          setSolutionApproachDocuments([...solutionApproachDocuments, ...documentsUploaded]);
          requirementData.solutionApproach
            .find((approach) => approach.idResponsibleProcess === Number(selectedSolutionApproachTab))
            ?.solutionApproachDocuments?.push(...documentsUploaded);

          uploadedFileNames = data.uploaded?.map((file: DocumentsRequirement) => file.name);
          newInvalidFiles = {};
          ['duplicate', 'format', 'size', 'other'].forEach((key) => {
            newInvalidFiles[key] = [...(invalidSolutionApproachFiles[key] || [])].filter(
              (file) => !uploadedFileNames.includes(file.name),
            );
          });

          data.errors?.forEach((error: { file: string; code: string }) => {
            const { file, code } = error;
            const fileObj = validSolutionApproachFiles.find((validFile) => validFile.name === file);
            if (!fileObj) return;

            const errorKey = apiErrorMap[code];
            newInvalidFiles[errorKey].push(fileObj);
          });

          setValidSolutionApproachFiles([]);
          setInvalidSolutionApproachFiles(newInvalidFiles);
        }
      }
    });
  };

  const formCode = getFormTypesCode({
    status: requirementData?.status,
    type: RR,
    variant: getVariantView(requirementData?.status),
    isRequirement: true,
  });

  const { fields, values, getValue, setValue } = useBackendForm({
    formCode,
    catalog: requirementData && {
      ...requirementData.catalog,
      [SearchBy.PROCESS]:
        responsibleRows?.map((row) => ({
          attributes: {
            NOT_TRANSLATABLE: { PROCESS_TITLE: row.title },
          },
          category: row.category,
          id: Number(row.id),
          wave: Number(row.wave),
        })) || requirementData.catalog[SearchBy.PROCESS],
    },
    initialValues: requirementData?.attributes,
    isTouchedNeeded: false,
  });

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length || !isLoading) return;
    setIsLoading(false);
  }, [dispatch, fields, isLoading, values]);

  useEffect(() => {
    if (!Object.keys(values).length) return;
    dispatch(setRequirementAttributes(values, i18n.language));
  }, [dispatch, i18n.language, values]);

  const getSolutionApproachWithForm = useCallback(
    (): SolutionApproach[] =>
      solutionApproach.map((solution: SolutionApproach) =>
        solution.idResponsibleProcess !== Number(selectedSolutionApproachTab)
          ? solution
          : {
              ...solution,
              attributes: {
                ...solution.attributes,
                [i18n.language]: {
                  ...solution.attributes[i18n.language],
                  [AttributeCode.SOLUTION_DESCRIPTION]: values[i18n.language].SOLUTION_DESCRIPTION as string,
                },
              },
              financialEvaluation: values.NOT_TRANSLATABLE?.FINANCE_APPROVED === 'true' ? rows : [],
              financeApproved: values.NOT_TRANSLATABLE?.FINANCE_APPROVED === 'true',
              targetDate: values.NOT_TRANSLATABLE?.TARGET_DATE as string,
            },
      ),
    [i18n.language, rows, selectedSolutionApproachTab, solutionApproach, values],
  );

  useEffect(() => {
    if (!solutionApproach.some((solution) => solution.idResponsibleProcess === Number(selectedSolutionApproachTab))) return;
    const newSolutionApproach = getSolutionApproachWithForm();
    dispatch(setSolutionApproachContext(newSolutionApproach, i18n.language));
  }, [dispatch, getSolutionApproachWithForm, i18n.language, selectedSolutionApproachTab, solutionApproach, values]);

  useEffect(() => {
    if (requirementData?.status) {
      setIsInitialSituationExpanded(true);
      setIsSolutionApproachExpanded(
        [RequirementStatus.ACCEPTED, RequirementStatus.DECLINED, RequirementStatus.DONE].includes(requirementData?.status),
      );
      setIsInitialDocumentsEditable(
        (requirementData?.status === RequirementStatus.DRAFT || requirementData?.status === RequirementStatus.RETRIEVED) &&
          (loggedUserMatchByType(AttributeCode.CREATOR) || isLoggedUserResponsible),
      );

      switch (requirementData?.status) {
        case RequirementStatus.DRAFT:
          setFormStatusInitialSituation(AccordionStatus.TOUCHED);
          break;
        case RequirementStatus.RETRIEVED:
          setFormStatusInitialSituation(AccordionStatus.TOUCHED);
          break;
        case RequirementStatus.PENDING:
          setFormStatusInitialSituation(AccordionStatus.COMPLETED);
          setFormStatusSolutionApproach(AccordionStatus.UNTOUCHED);
          break;
        case RequirementStatus.ACCEPTED:
        case RequirementStatus.DECLINED:
          setFormStatusInitialSituation(AccordionStatus.COMPLETED);
          setFormStatusSolutionApproach(AccordionStatus.TOUCHED);
          break;
        default:
          setFormStatusInitialSituation(AccordionStatus.COMPLETED);
          setFormStatusSolutionApproach(AccordionStatus.COMPLETED);
      }
    }
  }, [loggedUserMatchByType, requirementData?.status, isLoggedUserResponsible]);

  useEffect(() => {
    if (requirementData?.initialSituationDocuments?.length) {
      setInitialSituationDocuments(
        requirementData.initialSituationDocuments.map((doc) => ({
          id: requirementData.id,
          name: doc.name,
          user: requirementData.catalog.USER.find((user) => user.code === doc.creator)?.commonName || '',
          date: getFullFormattedDate(doc.date),
        })),
      );
    }

    const solutionApproachDocs = requirementData?.solutionApproach?.find(
      (approach) => approach.idResponsibleProcess === Number(selectedSolutionApproachTab),
    )?.solutionApproachDocuments;

    if (requirementData && solutionApproachDocs?.length) {
      setSolutionApproachDocuments(
        solutionApproachDocs.map((doc) => ({
          id: requirementData.id,
          name: doc.name,
          user: requirementData.catalog.USER.find((user) => user.code === doc.creator)?.commonName || '',
          date: getFullFormattedDate(doc.date),
        })),
      );
    }
  }, [requirementData, selectedSolutionApproachTab]);

  const handleChangeTab = useCallback(
    (newTab: string) => {
      if (!requirementData || newTab === selectedSolutionApproachTab) return;
      const newSolutionApproach = getSolutionApproachWithForm();
      setSolutionApproach(newSolutionApproach);
      const newSolution = solutionApproach.find((solution) => solution.idResponsibleProcess === Number(newTab));
      setValue(AttributeCode.FINANCE_APPROVED, newSolution?.financeApproved.toString());
      setValue(AttributeCode.SOLUTION_DESCRIPTION, newSolution?.attributes[i18n.language].SOLUTION_DESCRIPTION, i18n.language);
      setValue(AttributeCode.TARGET_DATE, newSolution?.targetDate);
      setSolutionApproachDocuments(
        requirementData.solutionApproach
          .find((approach) => approach.idResponsibleProcess === Number(newTab))
          ?.solutionApproachDocuments?.map((doc) => ({
            id: requirementData.id,
            name: doc.name,
            user: requirementData.catalog.USER.find((user) => user.code === doc.creator)?.commonName || '',
            date: getFullFormattedDate(doc.date),
          })) || [],
      );

      setSelectedSolutionApproachTab(newTab);
    },
    [getSolutionApproachWithForm, i18n.language, requirementData, selectedSolutionApproachTab, setValue, solutionApproach],
  );

  useEffect(() => {
    if (
      !solutionApproach.length ||
      !requirementData?.attributes?.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS ||
      selectedSolutionApproachTab !== ''
    )
      return;

    const responsibleProcesses = removeSquareBracketsFromString(
      requirementData.attributes.NOT_TRANSLATABLE.IDS_RESPONSIBLE_PROCESS,
    );

    handleChangeTab(responsibleProcesses[0]);
  }, [
    handleChangeTab,
    requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS,
    selectedSolutionApproachTab,
    solutionApproach.length,
  ]);

  useEffect(() => {
    if (!validInitialSituationFiles.length) return;
    uploadRequirementDocuments(validInitialSituationFiles, RequirementDocumentContext.INITIAL_SITUATION);
  }, [validInitialSituationFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!validSolutionApproachFiles.length) return;
    uploadRequirementDocuments(validSolutionApproachFiles, RequirementDocumentContext.SOLUTION_APPROACH);
  }, [validSolutionApproachFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFinacialEvaluationToggle(
      (values[NOT_TRANSLATABLE]?.FINANCE_APPROVED as string) === 'true' &&
        isUserResponsibleOrPacemakerInProcess(selectedSolutionApproachTab),
    );
    setIsSolutionDocumentsEditable(
      requirementData?.status === RequirementStatus.ACCEPTED &&
        isUserResponsibleOrPacemakerInProcess(selectedSolutionApproachTab),
    );
  }, [
    i18n.language,
    isUserResponsibleOrPacemakerInProcess,
    requirementData?.responsibleProcesses,
    requirementData?.status,
    selectedSolutionApproachTab,
    userInfo?.code,
    values,
  ]);

  const fieldSeparatorInitialSituationCommon = 9;
  const fieldSeparatorSavingPotential = 11;
  const fieldSeparatorSolutionApproachCommon = 15;

  const initialSituationCommonAttributes = fields[i18n.language as Language]?.slice(0, fieldSeparatorInitialSituationCommon);
  const initialSituationSavingPotentialAttributes = fields[i18n.language as Language]?.slice(
    fieldSeparatorInitialSituationCommon,
    fieldSeparatorSavingPotential,
  );

  let solutionApproachCommonAttributes: Field[] | undefined = [];

  if (requirementData?.status !== RequirementStatus.DRAFT && requirementData?.status !== RequirementStatus.RETRIEVED) {
    solutionApproachCommonAttributes = fields[i18n.language as Language]?.slice(
      fieldSeparatorSavingPotential,
      fieldSeparatorSolutionApproachCommon,
    );
  }

  const [isHoveringAttachmentsInitial, setIsHoveringAttachmentsInitial] = useState(false);
  const [isHoveringSolutionApproach, setIsHoveringSolutionApproach] = useState(false);

  const deleteDocument = (context: RequirementDocumentContext) => {
    if (!requirementData?.id || !documentNameToDelete) return;

    deleteDocumentRequirement(requirementData.id, documentNameToDelete, context, getProcessResponsibleId(context))
      .then(() => {
        let filteredDocus;

        if (context === RequirementDocumentContext.INITIAL_SITUATION) {
          filteredDocus = initialSituationDocuments.filter((element) => element.name !== documentNameToDelete) || [];
          setInitialSituationDocuments(filteredDocus);
        } else if (context === RequirementDocumentContext.SOLUTION_APPROACH) {
          filteredDocus = solutionApproachDocuments.filter((element) => element.name !== documentNameToDelete) || [];
          (
            requirementData.solutionApproach.find(
              (approach) => approach.idResponsibleProcess === Number(selectedSolutionApproachTab),
            ) as SolutionApproach
          ).solutionApproachDocuments = filteredDocus;
          setSolutionApproachDocuments(filteredDocus);
        }
      })
      .catch((error) => {
        handleServiceError(error);
      })
      .finally(() => {
        setDocumentNameToDelete('');
      });
  };

  const downloadDocument = (documentName: string, context: RequirementDocumentContext) => {
    if (!requirementData) return;

    getRequirementDocument(requirementData.id, documentName, context, getProcessResponsibleId(context))
      .then((res) => {
        downloadBlob(res.data, res.headers, decodeURI(documentName));
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const areFilesEqual = (file1: File, file2: File) => file1.name === file2.name;
  const allowedTypes = [
    'application/octet-stream',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'text/csv',
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/mp4',
    'video/quicktime',
    'video/x-msvideo',
    'application/x-7z-compressed',
    'application/zip',
  ];
  const allowedExtensions = [
    'doc',
    '.docx',
    '.dot',
    '.csv',
    '.xls',
    '.xlsb',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pdf',
    '.zip',
    '.7z',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.avi',
    '.mov',
    '.mp4',
  ];
  const isValidFile = (file: File) =>
    allowedExtensions.some((extension) => file.name.endsWith(extension)) || allowedTypes.includes(file.type);
  const isTooBigFile = (file: File) => file.size > MAX_REQUIREMENT_FILE_SIZE;
  const alreadyExistsFile = (file: File, context: RequirementDocumentContext) =>
    context === RequirementDocumentContext.INITIAL_SITUATION
      ? initialSituationDocuments.map((doc) => doc.name).includes(file.name)
      : solutionApproachDocuments.map((doc) => doc.name).includes(file.name);
  const filterDuplicateFiles = (baseList: File[], newList: File[]) => {
    let filteredList: File[] = [];
    if (baseList) {
      filteredList = newList.filter((newFile) => {
        let include = true;
        baseList.forEach((oldFile) => {
          if (areFilesEqual(newFile, oldFile)) {
            include = false;
          }
        });
        return include;
      });
    }
    return filteredList;
  };
  const updateFiles = (selectedFiles: FileList, context: RequirementDocumentContext) => {
    const allNewFiles = Array.from(selectedFiles);

    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      if (allNewFiles.length + initialSituationDocuments.length > MAX_REQUIREMENT_FILES) {
        Dialog.showAlert({
          name: t('error'),
          message: t('requirement.errors.API_ERROR_11'),
          isError: true,
        });
        return;
      }
    } else if (allNewFiles.length + solutionApproachDocuments.length > MAX_REQUIREMENT_FILES) {
      Dialog.showAlert({
        name: t('error'),
        message: t('requirement.errors.API_ERROR_11'),
        isError: true,
      });
      return;
    }

    let newValidFiles: File[] = [];
    let invalidFormatFiles: File[] = [];
    let tooBigFiles: File[] = [];
    let alreadyExistingFiles: File[] = [];
    // We filter invalid files
    allNewFiles.forEach((file) => {
      if (alreadyExistsFile(file, context)) {
        alreadyExistingFiles.push(file);
      } else if (!isValidFile(file)) {
        invalidFormatFiles.push(file);
      } else if (isTooBigFile(file)) {
        tooBigFiles.push(file);
      } else {
        newValidFiles.push(file);
      }
    });
    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      // We skip duplicate files
      newValidFiles = filterDuplicateFiles(validInitialSituationFiles, newValidFiles);
      alreadyExistingFiles = filterDuplicateFiles(invalidInitialSituationFiles.duplicate || [], alreadyExistingFiles);
      invalidFormatFiles = filterDuplicateFiles(invalidInitialSituationFiles.format || [], invalidFormatFiles);
      tooBigFiles = filterDuplicateFiles(invalidInitialSituationFiles.size || [], tooBigFiles);
      // We update the selected files list
      setValidInitialSituationFiles([...(validInitialSituationFiles || []), ...newValidFiles]);
      setInvalidInitialSituationFiles({
        ...invalidInitialSituationFiles,
        duplicate: [...(invalidInitialSituationFiles.duplicate || []), ...alreadyExistingFiles],
        format: [...(invalidInitialSituationFiles.format || []), ...invalidFormatFiles],
        size: [...(invalidInitialSituationFiles.size || []), ...tooBigFiles],
      });
    } else {
      // We skip duplicate files
      newValidFiles = filterDuplicateFiles(validSolutionApproachFiles, newValidFiles);
      alreadyExistingFiles = filterDuplicateFiles(invalidSolutionApproachFiles.duplicate || [], alreadyExistingFiles);
      invalidFormatFiles = filterDuplicateFiles(invalidSolutionApproachFiles.format || [], invalidFormatFiles);
      tooBigFiles = filterDuplicateFiles(invalidSolutionApproachFiles.size || [], tooBigFiles);
      // We update the selected files list
      setValidSolutionApproachFiles([...(validSolutionApproachFiles || []), ...newValidFiles]);
      setInvalidSolutionApproachFiles({
        ...invalidSolutionApproachFiles,
        duplicate: [...(invalidSolutionApproachFiles.duplicate || []), ...alreadyExistingFiles],
        format: [...(invalidSolutionApproachFiles.format || []), ...invalidFormatFiles],
        size: [...(invalidSolutionApproachFiles.size || []), ...tooBigFiles],
      });
    }
  };

  const retryDocumentUpload = (file: File, failureReason: string, context: RequirementDocumentContext) => {
    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      setInvalidInitialSituationFiles({
        ...invalidInitialSituationFiles,
        [failureReason]: invalidInitialSituationFiles[failureReason]?.filter((f) => !areFilesEqual(f, file)) || [],
      });
      setValidInitialSituationFiles([file]); // This will trigger the upload
    } else if (context === RequirementDocumentContext.SOLUTION_APPROACH) {
      setInvalidSolutionApproachFiles({
        ...invalidSolutionApproachFiles,
        [failureReason]: invalidSolutionApproachFiles[failureReason]?.filter((f) => !areFilesEqual(f, file)) || [],
      });
      setValidSolutionApproachFiles([file]); // This will trigger the upload
    }
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>, isInitialAttachment: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    if (isInitialAttachment) {
      setIsHoveringAttachmentsInitial(true);
    } else {
      setIsHoveringSolutionApproach(true);
    }
  };

  const handleDragOut = (event: React.DragEvent<HTMLDivElement>, isInitialAttachment: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    if (isInitialAttachment) {
      setIsHoveringAttachmentsInitial(false);
    } else {
      setIsHoveringSolutionApproach(false);
    }
  };

  // This function handles when files are 'dropped'
  const handleDrop = (event: React.DragEvent<HTMLDivElement>, context: RequirementDocumentContext) => {
    event.preventDefault();
    event.stopPropagation();
    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      setIsHoveringAttachmentsInitial(false);
      if (!isInitialDocumentsEditable || !event.dataTransfer || !!validInitialSituationFiles?.length) return;
      updateFiles(event.dataTransfer.files, context);
    } else {
      setIsHoveringSolutionApproach(false);
      if (!isSolutionDocumentsEditable || !event.dataTransfer || !!validSolutionApproachFiles?.length) return;
      updateFiles(event.dataTransfer.files, context);
    }
  };

  const handleFileSelection = (event: any, context: RequirementDocumentContext) => {
    const { target } = event;
    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      if (!target || !target.files || !!validInitialSituationFiles?.length) return;
      updateFiles(target.files, context);
    } else {
      if (!target || !target.files || !!validSolutionApproachFiles?.length) return;
      updateFiles(target.files, context);
    }
  };

  const deleteFailure = (failureKey: string, fileName: string, context: RequirementDocumentContext) => {
    let files: File[] = [];
    let filteredFiles;

    if (context === RequirementDocumentContext.INITIAL_SITUATION) {
      files = invalidInitialSituationFiles[failureKey];
      if (!files?.length) return;

      filteredFiles = files.filter((file) => file.name !== fileName);
      setInvalidInitialSituationFiles({
        ...invalidInitialSituationFiles,
        [failureKey]: filteredFiles,
      });
    } else {
      files = invalidSolutionApproachFiles[failureKey];
      if (!files?.length) return;

      filteredFiles = files.filter((file) => file.name !== fileName);
      setInvalidSolutionApproachFiles({
        ...invalidSolutionApproachFiles,
        [failureKey]: filteredFiles,
      });
    }
  };

  const isResponsibleWithOriginProcessAndOthers = (): boolean | undefined => {
    if (
      !requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS ||
      !requirementData.attributes.NOT_TRANSLATABLE.ID_ORIGIN_PROCESS
    )
      return false;

    const idsProcessResponsible = removeSquareBracketsFromString(
      requirementData.attributes.NOT_TRANSLATABLE.IDS_RESPONSIBLE_PROCESS,
    );

    return (
      idsProcessResponsible.length > 1 &&
      idsProcessResponsible.includes(requirementData.attributes.NOT_TRANSLATABLE.ID_ORIGIN_PROCESS)
    );
  };

  const handleSend = () => {
    if (!isRequirementValid(getVariantView(requirementData?.status))) {
      handleServiceError({ response: { data: { code: 'requirement.emptyMandatoryAttributes' } } }, { shouldNotShowCode: true });
    } else if (isResponsibleWithOriginProcessAndOthers()) {
      handleServiceError({ response: { data: { code: 'REQUEST_012' } } }, { shouldNotShowCode: true });
    } else {
      setConfirmationDialogTitle(t('requirement.send.modal.title'));
      setConfirmationDialogMessage(t('requirement.send.modal.message'));
      setRequirementAction(RequirementActions.SEND);
      setShowConfirmDialog(true);
    }
  };

  const handleApprove = () => {
    setConfirmationDialogTitle(t('requirement.approve.modal.title'));
    setConfirmationDialogMessage(t('requirement.approve.modal.message'));
    setRequirementAction(RequirementActions.APPROVE);
    setShowConfirmDialog(true);
  };

  const checkValidSolutionApproach = (solutionApproachValue: SolutionApproach[]): boolean => {
    const usersSolutionApproach = solutionApproachValue.filter((solution) =>
      isUserResponsibleOrPacemakerInProcess(solution.idResponsibleProcess.toString()),
    );

    return !usersSolutionApproach.some(
      ({ attributes, targetDate }: SolutionApproach) =>
        !targetDate || !attributes[i18n.language as Language]?.SOLUTION_DESCRIPTION,
    );
  };

  const handleSetAsDone = () => {
    if (
      !isRequirementValid(getVariantView(requirementData?.status)) &&
      !checkValidSolutionApproach(requirementData?.solutionApproach || [])
    ) {
      handleServiceError({ response: { data: { code: 'requirement.emptyMandatoryAttributes' } } }, { shouldNotShowCode: true });
    } else if (isResponsibleWithOriginProcessAndOthers()) {
      handleServiceError({ response: { data: { code: 'REQUEST_012' } } }, { shouldNotShowCode: true });
    } else {
      triggerRequirementAction(RequirementActions.SET_AS_DONE, undefined);
    }
  };

  const handleCloseDeclineDialog = () => {
    setShowDeclineRequirementDialog(false);
    setDeclineMessageValue('');
  };

  const handleConfirmDecline = () => {
    triggerRequirementAction(RequirementActions.DECLINE, declineMessageValue);
    handleCloseDeclineDialog();
  };

  const handleInputValue = (isOriginProcessOrResponsibleProcess: boolean, defaultValue: any, inputId: string) => {
    if (!values.NOT_TRANSLATABLE) return;
    let value = defaultValue;

    if (isOriginProcessOrResponsibleProcess && inputId.includes(AttributeCode.ID_ORIGIN_PROCESS)) {
      value = (responsibleRows || []).find((process) => process.id.toString() === value.toString())?.title || defaultValue;
    }

    return value;
  };

  const handleSearch = (text: string) => {
    setIsLoading(isSearching);
    setSearchText(text);
  };

  const handleChips = useCallback(
    (field: string, selectedProcesses: string[]) => {
      const responsibleProcesses = responsibleRows?.filter((elem) => selectedProcesses.includes(elem.id.toString()));

      return responsibleProcesses?.reduce(
        (array: { displayName: string; code: string }[], process) => [
          ...array,
          ...process[field]
            .filter((user) => !array.some((us) => us.displayName === user))
            .map((user: string) => {
              const code = user.split('(')[1].split(')')[0];
              return { code, displayName: user };
            }),
        ],
        [],
      );
    },
    [responsibleRows],
  );

  const updateChipValues = useCallback(
    (selectedProcesses: string[]) => {
      const chipValues = [
        { field: 'processResponsibles', code: AttributeCode.RESPONSIBLE_PERSONS },
        { field: 'pacemakers', code: AttributeCode.RESPONSIBLE_PACEMAKERS },
      ];

      chipValues.map((chipValue) => {
        const chipsArray = chips;
        chipsArray[chipValue.code] = handleChips(chipValue.field, selectedProcesses);
        return setChips(chipsArray);
      });
    },
    [chips, handleChips],
  );

  const updateResponsibleProcesses = useCallback(
    (selectedProcesses: string[]) => {
      const responsibleProcesses = responsibleRows?.filter((elem) => selectedProcesses.includes(elem.id.toString()));

      if (!responsibleProcesses || !values.NOT_TRANSLATABLE) return;

      values.NOT_TRANSLATABLE[AttributeCode.RESPONSIBLE_PERSONS] = responsibleProcesses.flatMap((process) =>
        process.processResponsibles.map((user) => user.split('(')[1].split(')')[0]),
      );
      values.NOT_TRANSLATABLE[AttributeCode.RESPONSIBLE_PACEMAKERS] = responsibleProcesses.flatMap((process) =>
        process.pacemakers.map((user) => user.split('(')[1].split(')')[0]),
      );

      updateChipValues(selectedProcesses);
      dispatch(setRequirementAttributes(values, i18n.language));
    },
    [dispatch, i18n.language, responsibleRows, updateChipValues, values],
  );

  useEffect(() => {
    if (!requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS) return;

    const responsibleProcesses = removeSquareBracketsFromString(
      requirementData.attributes.NOT_TRANSLATABLE.IDS_RESPONSIBLE_PROCESS,
    );
    setSelectedResponsibleProcessIds(responsibleProcesses);
    updateResponsibleProcesses(responsibleProcesses);
  }, [
    requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS,
    setSelectedResponsibleProcessIds,
    updateResponsibleProcesses,
  ]);

  const handleAddProcess = () => {
    if (!values.NOT_TRANSLATABLE || !fields?.[i18n.language as Language]) return;
    if (isOriginProcess && selectedOriginProcessId) {
      setValue(AttributeCode.ID_ORIGIN_PROCESS, selectedOriginProcessId);
      dispatch(setRequirementAttributes(values, i18n.language));
    } else if (selectedResponsibleProcessIds) {
      setValue(AttributeCode.IDS_RESPONSIBLE_PROCESS, `[${selectedResponsibleProcessIds}]`);
      updateResponsibleProcesses(selectedResponsibleProcessIds);
    }
  };

  const handleRemoveOriginProcess = (action: () => void) => {
    if (!values.NOT_TRANSLATABLE) return;

    setSelectedOriginProcessId('');
    setValue(AttributeCode.ID_ORIGIN_PROCESS, '');

    action();
  };

  const handleRemoveResponsibleProcess = (process: Chip) => {
    if (!values.NOT_TRANSLATABLE) return;

    const newSelectedProcessIds = selectedResponsibleProcessIds.filter((id) => process.code !== id.toString());
    setSelectedResponsibleProcessIds(newSelectedProcessIds);
    updateResponsibleProcesses(newSelectedProcessIds);
  };

  const declineDialog = {
    title: t('requirement.decline.title'),
    type: DialogType.Info,
    buttons: [
      {
        id: 'requirement-confirm-decline',
        key: 'requirement-confirm-decline',
        handleClick: handleConfirmDecline,
        content: t('decline'),
        buttonStyle: BUTTON_PRIMARY,
        disabled: isFreezed || declineMessageValue.trim() === '' || isLoading,
      },
      {
        id: 'requirement-cancel-decline',
        key: 'requirement-cancel-decline',
        handleClick: handleCloseDeclineDialog,
        content: t('cancel'),
        buttonStyle: BUTTON_SECONDARY,
        disabled: isLoading,
      },
    ],
  };

  const dialogButtonsConfirmDialog = [
    {
      id: 'requirement-confirm-submit',
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: () => {
        if (requirementAction) triggerRequirementAction(requirementAction);
        setShowConfirmDialog(false);
      },
      key: 'confirm-submit',
    },
    {
      id: 'requirement-cancel-submit',
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: () => setShowConfirmDialog(false),
      key: 'confirm-cancel',
    },
  ];

  const dialogButtonsDeleteDocumentInitial = [
    {
      id: 'requirement-confirm-delete-document-initial',
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: () => {
        deleteDocument(RequirementDocumentContext.INITIAL_SITUATION);
        setShowDialogDeleteDocumentInitialSituation(false);
      },
      key: 'delete-submit',
    },
    {
      id: 'requirement-cancel-delete-document-initial',
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: () => setShowDialogDeleteDocumentInitialSituation(false),
      key: 'delete-cancel',
    },
  ];
  const dialogButtonsDeleteDocumentSolution = [
    {
      id: 'requirement-confirm-delete-document-solution',
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: () => {
        deleteDocument(RequirementDocumentContext.SOLUTION_APPROACH);
        setShowDialogDeleteDocumentSolutionAproach(false);
      },
      key: 'delete-submit',
    },
    {
      id: 'requirement-cancel-delete-document-solution',
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: () => setShowDialogDeleteDocumentSolutionAproach(false),
      key: 'delete-cancel',
    },
  ];

  const processSelectionDialog = {
    buttons: [
      {
        buttonStyle: BUTTON_PRIMARY,
        content: t('add'),
        handleClick: () => {
          handleAddProcess();
          setShowProcessSelectionDialog(false);
          setSearchText('');
        },
        key: 'process-selection-button-submit',
      },
      {
        buttonStyle: BUTTON_SECONDARY,
        content: t('cancel'),
        handleClick: () => {
          if (!isOriginProcess && values.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS) {
            const actualResponsibleProcesses = removeSquareBracketsFromString(values.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS);
            setSelectedResponsibleProcessIds(actualResponsibleProcesses);
          }
          setShowProcessSelectionDialog(false);
          setSearchText('');
        },
        key: 'process-selection-button-cancel',
      },
    ],
    title: isOriginProcess
      ? t('requirement.dialog.origin-process-selection.title')
      : t('requirement.dialog.responsible-process-selection.title'),
  };

  const formButtons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      handleClick: handleSend,
      showBtn:
        requirementData?.status === RequirementStatus.DRAFT &&
        (loggedUserMatchByType(AttributeCode.CREATOR) ||
          isLoggedUserResponsible ||
          isUserInProcess(ResponsibleProcessUserTypes.ORIGIN_PROCESS_USERS)),
      icon: 'icon-verteiler',
      id: 'button-send-requirement',
      disabled: isFreezed,
      text: t('send'),
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      handleClick: () => setShowDeclineRequirementDialog(true),
      showBtn:
        requirementData?.status === RequirementStatus.PENDING &&
        isLoggedUserResponsible &&
        !requirementData.allMyProcessesApproved,
      icon: '',
      id: 'button-decline-requirement',
      disabled: isFreezed,
      text: t('decline'),
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      handleClick: handleApprove,
      showBtn:
        requirementData?.status === RequirementStatus.PENDING &&
        isLoggedUserResponsible &&
        !requirementData.allMyProcessesApproved,
      icon: 'icon-check',
      id: 'button-approve-requirement',
      disabled: isFreezed,
      text: t('approve'),
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      handleClick: () => triggerRequirementAction(RequirementActions.REOPEN),
      showBtn: requirementData?.status === RequirementStatus.DECLINED && loggedUserMatchByType(AttributeCode.CREATOR),
      icon: 'icon-pfeil-wiederherstellen-redo',
      id: 'button-reopen-requirement',
      disabled: isFreezed,
      text: t('reopen'),
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      handleClick: handleSetAsDone,
      showBtn: requirementData?.status === RequirementStatus.ACCEPTED && isLoggedUserResponsible,
      icon: 'icon-check',
      id: 'button-set-as-done',
      disabled: isFreezed,
      text: t('setAsDone'),
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      handleClick: () => triggerRequirementAction(RequirementActions.FORWARD),
      showBtn:
        requirementData?.status === RequirementStatus.ACCEPTED &&
        isLoggedUserResponsible &&
        !areOriginAndResponsibleSameProcesses(),
      icon: 'icon-objekt-pfeil-bewegen',
      id: 'button-forward',
      disabled: isFreezed,
      text: t('forward'),
    },
  ];

  return (
    <>
      {isLoading && (
        <div className="spinner">
          <Spinner isVisible />
        </div>
      )}
      {!isLoading && (
        <div className={styles.AccordionWrapper}>
          <div className={styles.Accordion}>
            <Accordion
              isExpandedByDefault={isInitialSituationExpanded}
              name={t('requirement.initialSituation')}
              status={formStatusInitualSituation}
              subname={t('requirement.step', {
                stepX: 1,
                ofX: 2,
              })}
              variant={TableVariant.PLAIN}
            >
              {!isLoading && (
                <div className={styles.RequirementWrapper}>
                  <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
                    {initialSituationCommonAttributes?.map(({ Component, ...fieldProps }) => {
                      const isOriginProcessOrResponsibleProcess =
                        fieldProps.code === AttributeCode.ID_ORIGIN_PROCESS ||
                        fieldProps.code === AttributeCode.IDS_RESPONSIBLE_PROCESS;
                      const fieldValue =
                        values.NOT_TRANSLATABLE?.[fieldProps.code] || values[i18n.language as Language]?.[fieldProps.code];

                      const currentValue = fieldValue
                        ? handleInputValue(isOriginProcessOrResponsibleProcess, fieldValue, fieldProps.id)
                        : '';
                      const hasError =
                        (isValidationActive &&
                          isOriginProcessOrResponsibleProcess &&
                          (currentValue === undefined || currentValue === '')) ||
                        fieldProps.error;

                      return (
                        <Component
                          {...fieldProps}
                          className={styles.FormAttribute}
                          clearInput={() => fieldProps.clearInput && handleRemoveOriginProcess(fieldProps.clearInput)}
                          error={hasError}
                          externalChips={chips[fieldProps.code] || undefined}
                          isRequirement
                          key={fieldProps.code}
                          onClick={() => {
                            if (isOriginProcessOrResponsibleProcess) {
                              setProcessSelectionDialogType(fieldProps.code);
                              setShowProcessSelectionDialog(true);
                            }
                          }}
                          onRemoveChip={(chip) => handleRemoveResponsibleProcess(chip)}
                          value={currentValue}
                        />
                      );
                    })}
                  </section>
                  <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.FormSectionTitle}>
                      {t('requirement.attachments')}
                      {isInitialDocumentsEditable && (
                        <div>
                          <ButtonNEPOS
                            disabled={isFreezed}
                            handleClick={() => filesInitialSituationInputRef.current?.click()}
                            id="button-upload-attachment-initial"
                            isSecondary
                          >
                            {t('requirement.button.uploadAttachment')}
                          </ButtonNEPOS>
                          <input
                            accept={`${allowedTypes.join(',')},${allowedExtensions.join(',')}`}
                            className={styles.FilesInput}
                            disabled={isFreezed}
                            id="requirement-initial-uploadDocumentInput"
                            multiple
                            onChange={(event) => {
                              handleFileSelection(event, RequirementDocumentContext.INITIAL_SITUATION);
                              event.target.value = '';
                            }}
                            ref={filesInitialSituationInputRef}
                            type="file"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className={`${styles.DocumentsContent} ${styles.NoDocuments} ${
                        isEmptyInitialSituationList && !isInitialDocumentsEditable ? styles.NoEditable : ''
                      }`}
                      onClick={() => (isEmptyInitialSituationList ? filesInitialSituationInputRef.current?.click() : null)}
                      onDragEnter={(e) => handleDrag(e, true)}
                      onDragLeave={(e) => handleDragOut(e, true)}
                      onDragOver={(e) => handleDrag(e, true)}
                      onDrop={(e) => handleDrop(e, RequirementDocumentContext.INITIAL_SITUATION)}
                      onMouseEnter={() => setIsHoveringAttachmentsInitial(true)}
                      onMouseLeave={() => setIsHoveringAttachmentsInitial(false)}
                    >
                      {!isEmptyInitialSituationList && (
                        <div className={styles.Documents}>
                          {validInitialSituationFiles.map((file) => (
                            <DocumentCard
                              isRequirement
                              key={file.name}
                              name={file.name}
                              readOnly={isFreezed || !isInitialDocumentsEditable}
                              status={requirementData?.status}
                              uploading
                            />
                          ))}
                          {['other', 'duplicate', 'format', 'size']
                            .map((errorReason) =>
                              invalidInitialSituationFiles[errorReason]?.map((file) => (
                                <DocumentCard
                                  canRetryUpload={errorReason === 'other'}
                                  deleteDocument={() =>
                                    deleteFailure(errorReason, file.name, RequirementDocumentContext.INITIAL_SITUATION)
                                  }
                                  error={t(errorReasonMap[errorReason])}
                                  isRequirement
                                  key={file.name}
                                  name={file.name}
                                  readOnly={isFreezed || !isInitialDocumentsEditable}
                                  retryUpload={() =>
                                    retryDocumentUpload(file, errorReason, RequirementDocumentContext.INITIAL_SITUATION)
                                  }
                                  status={requirementData?.status}
                                />
                              )),
                            )
                            .flat(1)}
                          {initialSituationDocuments.map((doc) => (
                            <DocumentCard
                              {...doc}
                              deleteDocument={(documentName?: string) => {
                                if (documentName) {
                                  setDocumentNameToDelete(documentName);
                                  setShowDialogDeleteDocumentInitialSituation(true);
                                }
                              }}
                              downloadDocument={(documentName: string) =>
                                downloadDocument(documentName, RequirementDocumentContext.INITIAL_SITUATION)
                              }
                              isRequirement
                              key={doc.name}
                              readOnly={isFreezed || !isInitialDocumentsEditable}
                            />
                          ))}
                        </div>
                      )}
                      {isEmptyInitialSituationList && isInitialDocumentsEditable && (
                        <div className={styles.InitialView}>
                          <span>{t('requirement.drop.document')}</span>
                          <img
                            alt="Upload documents"
                            id="uploadDocumentImage"
                            src={isHoveringAttachmentsInitial ? uploadDocumentHoverImage : uploadDocumentImage}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.FormSectionTitle}>{t('requirement.savingPotential')}</div>
                    {initialSituationSavingPotentialAttributes?.map(({ Component, ...fieldProps }) => (
                      <Component {...fieldProps} className={styles.FormAttribute} />
                    ))}
                  </section>
                </div>
              )}
            </Accordion>
          </div>

          {requirementData?.status !== RequirementStatus.DRAFT && requirementData?.status !== RequirementStatus.RETRIEVED && (
            <div className={styles.Accordion}>
              <Accordion
                isExpandedByDefault={isSolutionApproachExpanded}
                name={t('requirement.solutionApproach')}
                status={formStatusSolutionApproach}
                subname={t('requirement.step', {
                  stepX: 2,
                  ofX: 2,
                })}
                variant={TableVariant.PLAIN}
              >
                {!isLoading && (
                  <div className={styles.RequirementWrapper}>
                    {selectedResponsibleProcessIds?.length > 1 && (
                      <TabSelector
                        className={styles.Tabs}
                        handleClick={handleChangeTab}
                        isNEPOS
                        options={selectedResponsibleProcessIds?.map((responsibleProcessId) => {
                          const responsibleProcessName = handleInputValue(
                            true,
                            responsibleProcessId,
                            AttributeCode.ID_ORIGIN_PROCESS,
                          );

                          return {
                            className: selectedSolutionApproachTab === responsibleProcessId ? styles.Selected : '',
                            id: responsibleProcessId,
                            isValid: true,
                            name: responsibleProcessName,
                            tooltip: responsibleProcessName,
                          };
                        })}
                        selectedOption={selectedSolutionApproachTab}
                      />
                    )}
                    <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
                      {solutionApproachCommonAttributes?.map(({ Component, ...fieldProps }) => (
                        <Component
                          {...fieldProps}
                          className={styles.FormAttribute}
                          disabled={fieldProps.disabled || !isUserResponsibleOrPacemakerInProcess(selectedSolutionApproachTab)}
                          error={
                            fieldProps.error &&
                            requirementData?.responsibleProcesses?.find(
                              (process) =>
                                process.id === parseInt(selectedSolutionApproachTab, 10) &&
                                process.attributes.NOT_TRANSLATABLE?.RESPONSIBLE_PERSONS?.includes(userInfo?.code),
                            )
                          }
                        />
                      ))}
                    </section>
                    <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
                      <div className={styles.FormSectionTitle}>
                        {t('requirement.solutionAttachments')}
                        {isSolutionDocumentsEditable && (
                          <div>
                            <ButtonNEPOS
                              handleClick={() => filesSolutionApproachInputRef.current?.click()}
                              id="button-upload-attachment-solution"
                              isSecondary
                            >
                              {t('requirement.button.uploadAttachment')}
                            </ButtonNEPOS>
                            <input
                              accept={`${allowedTypes.join(',')},${allowedExtensions.join(',')}`}
                              className={styles.FilesInput}
                              id="requirement-solution-uploadDocumentInput"
                              multiple
                              onChange={(event) => {
                                handleFileSelection(event, RequirementDocumentContext.SOLUTION_APPROACH);
                                event.target.value = '';
                              }}
                              ref={filesSolutionApproachInputRef}
                              type="file"
                            />
                          </div>
                        )}
                      </div>

                      <div
                        className={`${styles.DocumentsContent} ${styles.NoDocuments} ${
                          isEmptySolutionApproachList && !isSolutionDocumentsEditable ? styles.NoEditable : ''
                        }`}
                        onClick={() => (isEmptySolutionApproachList ? filesSolutionApproachInputRef.current?.click() : null)}
                        onDragEnter={(e) => handleDrag(e, true)}
                        onDragLeave={(e) => handleDragOut(e, true)}
                        onDragOver={(e) => handleDrag(e, true)}
                        onDrop={(e) => handleDrop(e, RequirementDocumentContext.SOLUTION_APPROACH)}
                        onMouseEnter={() => setIsHoveringAttachmentsInitial(true)}
                        onMouseLeave={() => setIsHoveringAttachmentsInitial(false)}
                      >
                        {!isEmptySolutionApproachList && (
                          <div className={styles.Documents}>
                            {validSolutionApproachFiles.map((file) => (
                              <DocumentCard
                                isRequirement
                                key={file.name}
                                name={file.name}
                                readOnly={isFreezed || !isSolutionDocumentsEditable}
                                status={requirementData?.status}
                                uploading
                              />
                            ))}
                            {['other', 'duplicate', 'format', 'size']
                              .map((errorReason) =>
                                invalidSolutionApproachFiles[errorReason]?.map((file) => (
                                  <DocumentCard
                                    canRetryUpload={errorReason === 'other'}
                                    deleteDocument={() =>
                                      deleteFailure(errorReason, file.name, RequirementDocumentContext.SOLUTION_APPROACH)
                                    }
                                    error={t(errorReasonMap[errorReason])}
                                    isRequirement
                                    key={file.name}
                                    name={file.name}
                                    readOnly={isFreezed || !isSolutionDocumentsEditable}
                                    retryUpload={() =>
                                      retryDocumentUpload(file, errorReason, RequirementDocumentContext.SOLUTION_APPROACH)
                                    }
                                    status={requirementData?.status}
                                  />
                                )),
                              )
                              .flat(1)}
                            {solutionApproachDocuments.map((doc) => (
                              <DocumentCard
                                {...doc}
                                deleteDocument={(documentName?: string) => {
                                  if (documentName) {
                                    setDocumentNameToDelete(documentName);
                                    setShowDialogDeleteDocumentSolutionAproach(true);
                                  }
                                }}
                                downloadDocument={(documentName: string) =>
                                  downloadDocument(documentName, RequirementDocumentContext.SOLUTION_APPROACH)
                                }
                                isRequirement
                                key={doc.name}
                                readOnly={isFreezed || !isSolutionDocumentsEditable}
                              />
                            ))}
                          </div>
                        )}
                        {isEmptySolutionApproachList && isSolutionDocumentsEditable && (
                          <div className={styles.InitialView}>
                            <span>{t('requirement.drop.document')}</span>
                            <img
                              alt="Upload documents"
                              id="uploadDocumentImage"
                              src={isHoveringSolutionApproach ? uploadDocumentHoverImage : uploadDocumentImage}
                            />
                          </div>
                        )}
                      </div>
                    </section>
                    <section className={styles.Attributes}>
                      <div className={styles.FormSectionTitle}>
                        {t('requirement.financialEvaluation')}
                        <div className={styles.WrapperButtons}>
                          <ButtonNEPOS
                            disabled={
                              isFreezed ||
                              rows.length === 10 ||
                              !finantialEvaluationToggle ||
                              requirementData?.status === RequirementStatus.DONE ||
                              !isLoggedUserResponsible
                            }
                            handleClick={handleAddRow}
                            id="button-add-new-fiancial-evaluation"
                            isSecondary
                          >
                            {t('tool.addNew')}
                          </ButtonNEPOS>
                          <RoundButton
                            className={styles.RoundButton}
                            disabled={
                              isFreezed ||
                              !finantialEvaluationToggle ||
                              requirementData?.status === RequirementStatus.DONE ||
                              !isLoggedUserResponsible
                            }
                            icon="di icon-muelleimer-loeschen"
                            id="button-delete-fiancial-evaluation"
                            onClick={handleDeleteRow}
                          />
                        </div>
                      </div>
                      <FinancialEvaluationTable
                        columns={financialEvaluationColumns}
                        disabled={
                          !finantialEvaluationToggle ||
                          requirementData?.status === RequirementStatus.DONE ||
                          !isLoggedUserResponsible
                        }
                        getValue={getValue}
                        handleCheck={handleCheck}
                        inputValue={inputValue}
                        isCheckedAll={isCheckedAll}
                        onChange={(value: string, rowId: string, columnId: string) => {
                          handleFinancialInput(value, rowId, columnId);
                        }}
                        rows={rows}
                        selectedElements={selectedElements}
                      />
                    </section>
                  </div>
                )}
              </Accordion>
            </div>
          )}
          <div className={styles.ButtonWrapper}>
            {formButtons
              .filter((btn) => btn.showBtn)
              .map(({ buttonStyle, handleClick, icon, id, text, disabled }) => (
                <ButtonNEPOS
                  disabled={disabled}
                  gradientButton={buttonStyle}
                  handleClick={handleClick}
                  icon={icon}
                  id={id}
                  key={id}
                >
                  {text}
                </ButtonNEPOS>
              ))}
          </div>
        </div>
      )}
      {showConfirmDialog && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsConfirmDialog,
            title: confirmationDialogTitle,
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {confirmationDialogMessage}
        </DialogNEPOS>
      )}
      {showDialogDeleteDocumentInitialSituation && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsDeleteDocumentInitial,
            title: t('requirement.delete.modal.title'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requirement.delete.modal.message')}
        </DialogNEPOS>
      )}
      {showDialogDeleteDocumentSolutionAproach && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsDeleteDocumentSolution,
            title: t('requirement.delete.modal.title'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requirement.delete.modal.message')}
        </DialogNEPOS>
      )}
      {showDeclineRequirementDialog && (
        <DialogNEPOS dialog={declineDialog} extraClass="Modal">
          <p className={styles.DeclineDialogText}>{t('requirement.dialog.decline.message')}</p>
          <TextAreaNEPOS
            className={styles.DeclineTextArea}
            id="decline-requirement-message-input"
            label={t('requirement.dialog.decline.label')}
            onChange={(value) => setDeclineMessageValue(value)}
            required
            value={declineMessageValue}
          />
        </DialogNEPOS>
      )}
      {showProcessSelectionDialog && (
        <DialogNEPOS dialog={processSelectionDialog}>
          {processLevelColumns && (
            <>
              <SearchNEPOS id="requirements-process-level-selection" isAsync searching={handleSearch} />
              <ProcessLevelDatabaseTable
                columns={processLevelColumns}
                isLoading={isLoading}
                multiselect={!isOriginProcess}
                onCheck={(ids: string[]) =>
                  isOriginProcess ? setSelectedOriginProcessId(ids[0]) : setSelectedResponsibleProcessIds(ids)
                }
                rows={isOriginProcess ? originProcessLevelRows : responsibleProcessLevelRows}
                searchText={searchText}
                selectedRows={isOriginProcess ? [selectedOriginProcessId] : selectedResponsibleProcessIds}
              />
            </>
          )}
        </DialogNEPOS>
      )}
    </>
  );
};

export default RequirementForm;
