import { useRef } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { STATUS, WORKFLOW_STAGES } from 'assets/constants/constants';
import * as Utils from 'assets/js/Utils';
import Button from 'components/UI/Button/Button';

import styles from './WorkflowStep.module.scss';

const Tooltip = ({ children, className }) => {
  if (typeof children !== 'string') {
    return <div className={className}>{children}</div>;
  }

  return (
    <TooltipComponent
      className={className}
      content={children}
      cssClass="mbc-tooltip"
      openDelay={500}
      position="BottomCenter"
      showTipPointer={false}
    >
      {children}
    </TooltipComponent>
  );
};

const WorkflowStep = (props) => {
  const {
    code,
    codeStatus,
    content,
    expirationDate,
    finishDate,
    group,
    initialDate,
    isClosedWorkflow,
    isDelegableStage,
    isWorkflowBarOpen,
    isLastStep,
    openDelegateDialog,
    rejectReason,
    rejectedBy,
    title,
    usersList,
  } = props;
  const { t } = useTranslation();
  const dates = {
    DONE: finishDate,
    CURRENT: expirationDate,
    PENDING: initialDate,
    INITIAL: finishDate,
  };
  const tooltipInstance = useRef(null);
  const workflowStepRef = useRef(null);
  const subTitleRef = useRef(null);

  const isApproversBlock = code === WORKFLOW_STAGES.APPROVERS;
  const isQIBlock = code === WORKFLOW_STAGES.QA_INSPECTOR_GROUP;

  const getDelegateBlock = (user) => {
    if (isDelegableStage && !isApproversBlock) {
      const userBlock = isQIBlock && !isApproversBlock ? user : <Tooltip className={styles.UserBlock}>{user}</Tooltip>;
      const qiStyle = isQIBlock ? styles.QIStyle : '';
      const currentUser = isQIBlock ? group[0].userCode : `${content[0].userName} (${content[0].userCode})`;

      return (
        <div className={`${styles.DelegateUser} ${qiStyle}`}>
          {userBlock}
          <Button
            btnText={t('delegate')}
            buttonStyle="Delegate"
            handleClick={() => openDelegateDialog(currentUser, isQIBlock)}
            id="delegate-button"
          />
        </div>
      );
    }

    return user;
  };

  const prepareUsers = () => {
    if (typeof content[0] !== 'string') {
      for (let i = 0; i < content.length; i++) {
        const userName = usersList?.filter((elem) => elem.code === content[i].userCode)[0].commonName;
        content[i] = {
          ...content[i],
          userName,
        };
      }
    }

    let [user] = content.map((u) => (typeof u === 'string' ? u : u.userName || ''));

    if (!isQIBlock && content.length > 1) {
      user = (
        <div className={styles.Persons}>
          <div className={styles.Icon}></div>
          <div>{`${content.length} ${t('persons')}`}</div>
        </div>
      );
    } else if (isQIBlock) {
      [user] = group.map((u) => (typeof u === 'string' ? u : u.userName || u.userCode));
    }

    user = getDelegateBlock(user);

    return user;
  };

  const prepareStatus = () => {
    let statusText = codeStatus === STATUS.INITIAL ? '' : t(`workflow.stage.status.${codeStatus}`) || '';

    if (isApproversBlock) {
      const approversDone = content.filter((approver) => approver.finishDate !== null);
      statusText =
        approversDone.length < content.length
          ? `${approversDone.length}/${content.length} ${t('APPROVED')}`
          : t(`workflow.stage.status.${codeStatus}`) || '';
    }

    return statusText;
  };

  const prepareContent = () => {
    let usersContent = content.map((elem) => (
      <div className={styles.User} key={elem.user && (elem.userName || '')}>
        <i className={`${styles.Ico} ${styles[codeStatus]}`} />
        {elem.user && (elem.userName || elem.userCode)}
      </div>
    ));

    if (isApproversBlock || isQIBlock) {
      usersContent = content.map((elem) => {
        if (elem.userCode === '[]') {
          elem.userCode = '';
        }
        const isDone = elem.finishDate !== null;
        const stepStyle = isApproversBlock ? styles.Approvers : styles.QIBlock;

        return (
          <div className={`${styles.User} ${stepStyle}`} key={elem.userName || ''}>
            <i className={`${styles.Ico} ${isDone ? styles.DONE : styles.PENDING}`} />
            <Tooltip className={styles.UserName}>{elem.userName || elem.userCode}</Tooltip>
            {isDone && isApproversBlock && (
              <span className={styles.Separator}>{Utils.getFullFormattedDate(elem.finishDate)}</span>
            )}
            {!isQIBlock && !isDone && isDelegableStage && (
              <Button
                btnText={t('delegate')}
                buttonStyle="Delegate"
                handleClick={() => openDelegateDialog(`${elem.userName} (${elem.userCode})`, false)}
                id="delegate-button"
              />
            )}
          </div>
        );
      });
    }

    return usersContent;
  };

  const handleWorkflowStepClick = (instance) => {
    if (
      !isClosedWorkflow ||
      codeStatus !== STATUS.REJECTED ||
      !instance?.current ||
      !workflowStepRef?.current?.children[3]?.children[0]?.classList
    )
      return;

    if (workflowStepRef.current.children[3].children[0].classList.contains('e-tooltip') && instance.current.isHidden) {
      instance.current.open(isWorkflowBarOpen ? subTitleRef.current : workflowStepRef.current);
    } else {
      instance.current.close();
    }
  };

  const addStyle = (style) => styles[style] || '';

  const propietary = prepareUsers();
  const rejectUser =
    isClosedWorkflow && codeStatus === STATUS.REJECTED && rejectedBy
      ? usersList.find((user) => user.code === rejectedBy).commonName
      : '';
  const stepStatus = `${prepareStatus()} ${rejectUser.length > 0 && rejectUser !== propietary ? `by ${rejectUser}` : ''}`;
  const stepContent = prepareContent();
  const delegateStyle = isDelegableStage ? styles.DelegateStep : '';
  const lastStepStyle = isLastStep ? styles.LastStep : '';

  return (
    <div
      className={`${styles.WorkflowStep} ${delegateStyle} ${lastStepStyle} ${addStyle(title)} ${
        isClosedWorkflow && codeStatus === STATUS.REJECTED ? styles.RejectedStage : ''
      }`}
      onClick={() => handleWorkflowStepClick(tooltipInstance)}
      ref={workflowStepRef}
    >
      <Tooltip className={styles.Title}>{t(title)}</Tooltip>
      <Tooltip className={styles.Content}>{propietary === undefined ? '-' : propietary}</Tooltip>
      <div className={`${styles.Status} ${addStyle(codeStatus)}`}>
        <div className={`${styles.IcoInitial} ${addStyle(title)}`}>
          <div className={styles.IcoEPC}></div>
          <div className={styles.IcoVCD}></div>
        </div>
        <i className={`${styles.Ico} ${addStyle(codeStatus)}`} />
        <div className={`${styles.Border} ${addStyle(codeStatus)}`} />
      </div>
      <div className={`${styles.WorkflowStepStatus} ${styles.SubTitle}`} ref={subTitleRef}>
        <TooltipComponent
          className={styles.SubTitle}
          content={`${t('workflow.reject.dialog.reason')}: ${rejectReason}`}
          cssClass="mbc-tooltip"
          opensOn="Custom"
          position="RightCenter"
          ref={tooltipInstance}
          showTipPointer
        >
          {stepStatus}
        </TooltipComponent>
      </div>
      <div className={styles.Date}>{dates[codeStatus]}</div>
      {(isQIBlock || isApproversBlock) && <div className={`${styles.Content} ${styles.Users}`}>{stepContent}</div>}
    </div>
  );
};

export default WorkflowStep;
