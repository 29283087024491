import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { handleServiceError } from 'assets/js/serviceUtils';
import { downloadBlob } from 'assets/js/Utils';
import DropdownForm from 'components/UI/Dropdown/DropdownForm';
import FormDialog from 'components/UI/FormDialog/FormDialog';
import useLocalization from 'hooks/useLocalization';
import diagramServices from 'services/diagramService';

const ExportDialog = (props) => {
  const { close, diagramId, setDiagramLoading, setDiagramIsSaved, saveDiagram, errorSavingDiagram } = props;
  const { t } = useTranslation();
  const { availableLanguages } = useLocalization();
  const [selectedValue, setSelectedValue] = useState('');
  const options = availableLanguages.map((option) => {
    return {
      id: option,
      value: t(`language.${option}`),
      text: t(`language.${option}`),
      selected: false,
    };
  });

  const exportXML = () => {
    const selectedValueCode = options.find((lang) => lang.value === selectedValue).id;

    close();
    setDiagramLoading(true);

    saveDiagram()
      .then(() => {
        diagramServices
          .exportDiagramXML(diagramId, selectedValueCode)
          .then((res) => {
            downloadBlob(`\ufeff${res.data}`, res.headers);
            setDiagramIsSaved();
          })
          .catch((err) => {
            handleServiceError(err);
            setDiagramLoading(false);
          });
      })
      .catch((error) => {
        setDiagramLoading(false);
        errorSavingDiagram(error);
      });
  };

  return (
    <FormDialog
      actionButtonId="Export"
      actionName={t('language.dialog.export.name')}
      close={close}
      id="exportXMLDialog"
      returnAction={exportXML}
      title={t('language.dialog.export.title')}
      titleStyle="XMLTitle"
      value={selectedValue}
      visible
    >
      <DropdownForm
        meta={{
          label: `${t('language.dialog.export.label')}*`,
          placeholder: `${t('language.dialog.export.placeholder')}`,
          options,
          extraClass: 'export',
        }}
        setValue={setSelectedValue}
      />
    </FormDialog>
  );
};

export default ExportDialog;
