import { ReactNode, forwardRef } from 'react';

import { DIALOG_PARENT_ID } from 'assets/constants/constants';
import Portal from 'components/UI/Portal/Portal';

import styles from './Dialog.module.scss';

const root = document.getElementById(DIALOG_PARENT_ID) as HTMLDivElement;

type DialogProps = {
  children: ReactNode;
  className?: string;
};

const Dialog = forwardRef<HTMLDivElement, DialogProps>(({ children, className = '' }, ref) => (
  <Portal root={root}>
    <div className={styles.Dialog} ref={ref}>
      <div
        className={`${styles.Container}
      ${className}`}
      >
        {children}
      </div>
    </div>
  </Portal>
));

export default Dialog;
