import { forwardRef } from 'react';

import styles from './RoundButton.module.scss';

type ButtonProps = {
  id: string;
  className?: string;
  icon?: string;
  dataQA?: string;
  text?: string;
  variant?: string;
  isFilled?: boolean;
} & (
  | {
      onClick: React.MouseEventHandler<HTMLButtonElement>;
      disabled?: boolean;
    }
  | {
      disabled: true;
      onClick?: never;
    }
  | {
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      disabled?: boolean;
    }
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ icon, id, dataQA = id, onClick, className, disabled, text, variant, isFilled }, ref) => {
    const variantClass = variant === 'gradient' ? styles.Gradient : '';

    return (
      <button
        className={`${styles.Button} ${className} ${variantClass} ${!isFilled ? '' : styles.Filled}`}
        data-qa={dataQA}
        disabled={disabled}
        id={id}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {icon && <i className={`di ${icon} ${styles.Icon} ${!isFilled && variant === 'gradient' ? styles.NotFilled : ''}`} />}
        {text && <span>{text}</span>}
      </button>
    );
  },
);

export default Button;
