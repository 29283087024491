export const APP_VERSION = '6.15.0-release';

// Storage
export const storageLabels = {
  copy: 'mbcMap.copyDiagram',
  lang: 'mbcMap.AppLanguage',
  rememberAppLang: 'mbcMap.rememberAppLanguage',
};

export const STORAGE = {
  LANG: 'mbcMap.AppLanguage',
  SIGN_IN_REDIRECT: 'mbcMapSigninRedirect',
};

// Locations
export const CENTRAL = 'DE_CENTRAL';
export const KAMENZ = 'DE_KAMENZ';
export const TUSCALOOSA = 'US_TUSCALOOSA';
export const BEIJING = 'CN_BEIJING';
export const SEBGIR = 'RO_SEBGIR';
export const MBUSA = 'US_MBUSA';
export const VAN = 'ES_VAN';
export const LOCATIONS = {
  kamenz: KAMENZ,
  tuscaloosa: TUSCALOOSA,
  beijing: BEIJING,
  sebgir: SEBGIR,
  mbusa: MBUSA,
  van: VAN,
};

// Environments
export const LOCAL = 'local';
export const DEV = 'development';
export const QA = 'qa';
export const INT = 'int';
export const PRO = 'production';
export const ENVIRONMENTS = {
  LOCAL,
  DEV,
  QA,
  INT,
  PRO,
};
export const URL_TO_ENVIRONMENT = {
  dev: DEV,
  qa: QA,
  int: INT,
  pro: PRO,
};

// Language
export const LANGUAGE = 'APP_LANGUAGE';
export const ENGLISH = 'EN';
export const GERMAN = 'DE';
export const CHINESE = 'CN';
export const NOT_TRANSLATABLE = 'NOT_TRANSLATABLE';
export const AVAILABLE_LANGUAGES = [ENGLISH, GERMAN];
export const IMPORT_XML = 'IMPORT_XML';
export const EXPORT_XML = 'EXPORT_XML';
export const LANGUAGE_MENU_OPTIONS = [IMPORT_XML, EXPORT_XML];
export const DEFAULT_LANGUAGES_BY_LOCATION = {
  [CENTRAL]: GERMAN,
  [KAMENZ]: GERMAN,
  [TUSCALOOSA]: ENGLISH,
  [BEIJING]: ENGLISH,
  [VAN]: ENGLISH,
  [MBUSA]: ENGLISH,
  [SEBGIR]: ENGLISH,
};

// User Roles
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const QI = 'QI';
export const QI_MODELER = 'QI_MODELER';
export const LOCATION_MANAGER = 'LOCATION_MANAGER';
export const SUPPORT = 'SUPPORT';
export const USER_ROLE = 'USER';
export const MODELER = 'MODELER';
export const ROLES = {
  [ADMINISTRATOR]: ADMINISTRATOR,
  [QI]: QI,
  [QI_MODELER]: QI_MODELER,
  [LOCATION_MANAGER]: LOCATION_MANAGER,
  [SUPPORT]: SUPPORT,
  [MODELER]: MODELER,
  USER: USER_ROLE,
};

// Interfaces
export const INTERFACE = 'INTERFACE';
export const MAX_ROWS_TO_SHOW = 10;

// Diagram
export const DIAGRAM = 'diagram';
export const DELEGATE_DIAGRAM = 'DELEGATE_DIAGRAM';
export const EPC_DIAGRAM = 'EPC';
export const VCD_DIAGRAM = 'VCD';
export const SIPOC_DIAGRAM = 'SIPOC';
export const SWIMLANE_DIAGRAM = 'SWIMLANE';
export const EPC_DIAGRAM_ID = 1;
export const VCD_DIAGRAM_ID = 2;
export const SIPOC_DIAGRAM_ID = 3;
export const SWIMLANE_DIAGRAM_ID = 4;
export const DATASET_ID = 5;
export const EXTERNAL_LINK_TYPE = 'EXTERNAL';
export const HISTORICAL_WF = 'HISTORICAL_WF';
export const HISTORICAL_WF_EPC = 'HISTORICAL_WF_EPC';
export const HISTORICAL_WF_VCD = 'HISTORICAL_WF_VCD';
export const HISTORICAL_WF_TYPES = [HISTORICAL_WF_EPC, HISTORICAL_WF_VCD];
export const ARCHIVE_DS_WF = 'ARCHIVE_DS_WF';
export const ARCHIVE_DS_WF_VCD = 'ARCHIVE_DS_WF_VCD';
export const ARCHIVE_DS_WF_EPC = 'ARCHIVE_DS_WF_EPC';
export const QUICK_HIERARCHICAL_DS_WF_EPC = 'QUICK_HIERARCHICAL_DS_WF_EPC';
export const QUICK_RELEASE_DS_WF_VCD = 'QUICK_RELEASE_DS_WF_VCD';
export const ACTIVITY_LEVEL = 'ACTIVITY_LEVEL';
export const E2E = 'E2E';

export const DIAGRAM_OFFSET_Y = 150;
export const DIAGRAM_OFFSET_X = 550;
export const OBJECT_OFFSET_X = 125;
export const ADDED_OFFSET_X = 60;
export const DIAGRAM_MARGIN_X = 70;
export const DIAGRAM_MARGIN_Y = 50;
export const OBJECT_OFFSET_Y = 30;
export const MAX_ROL_LENGTH = 40;
export const MAX_ROLES_LENGTH = 20;
export const OFFSET_OBJECT = 35;
export const OFFSET_FIRST_LONG_OBJECT = 20;
export const OFFSET_NEXT_LONG_OBJECT = 35;
export const TOP_LEFT = 'TOP_LEFT';
export const TOP_RIGHT = 'TOP_RIGHT';
export const BOTTOM_LEFT = 'BOTTOM_LEFT';
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
export const TOP_PORT = 'TOP_PORT';
export const RIGHT_PORT = 'RIGHT_PORT';
export const LEFT_PORT = 'LEFT_PORT';
export const BOTTOM_PORT = 'BOTTOM_PORT';
export const PRINT_SETTINGS = {
  [VCD_DIAGRAM]: {
    height: 460,
    width: 960,
  },
  [EPC_DIAGRAM]: {
    height: 785,
    width: 630,
  },
};

export const SYMBOL_HEIGHT = 8.25;
export const SYMBOL_WIDTH = 15;
export const SYMBOL_MIN_HEIGHT = 4;
export const SYMBOL_MIN_WIDTH = 10;
export const SYMBOL_COPY_OFFSET = 2;
export const SYMBOL_BOARD_WIDTH = 77.25;
export const SYMBOL_BOARD_MIN_HEIGHT = 43.5;

export const TEXTFIELD_HEIGHT = 3;
export const TEXTFIELD_WIDTH = 15;

export const NODE_MIN_WIDTH = 110;
export const NODE_MIN_HEIGHT = 30;
export const OBJECT_DEFAULT_WIDTH = 90;
export const MAX_WIDTH = 90;
export const MIN_HEIGHT = 40;
export const ANGLE_DISTANCE = 22;
export const NODE_WIDTH_MARGIN = 25;
export const OBJECT_WIDTH_MARGIN = 10;
export const EVENT_WIDTH_MARGIN = 40;
export const ADDED_NODE_WIDTH = 30;
export const PROCESS_INTERFACE_ADDED_HEIGHT = 15;
export const PROCESS_INTERFACE_ADDED_MARGIN = 7;
export const PROCESS_STEP_PANEL_ADDED_HEIGHT = 46;

// Swimlanes
export const SWIMLANE = 'swimlane';
export const HORIZONTAL = 'Horizontal';
export const VERTICAL = 'Vertical';
export const SWIMLANE_HEADER_HEIGHT = 30;
export const SWIMLANE_LANE_HEIGHT = 150;
export const SWIMLANE_HEIGHT = 350;
export const SWIMLANE_WIDTH = 900;

// Symbols
export const SYMBOL = 'SYMBOL';
export const CLOSED_PROCESS_OVERVIEW = 'CLOSED_PROCESS_OVERVIEW';
export const OPEN_PROCESS_OVERVIEW = 'OPEN_PROCESS_OVERVIEW';
export const PROCESS_OVERVIEW = 'PROCESS_OVERVIEW';
export const PROCESS_STEP = 'PROCESS_STEP';
export const PROCESS_INTERFACE = 'PROCESS_INTERFACE';
export const EVENT = 'EVENT';
export const AND = 'AND';
export const OR = 'OR';
export const XOR = 'XOR';
export const AND_GATE = 'AND_GATE';
export const OR_GATE = 'OR_GATE';
export const XOR_GATE = 'XOR_GATE';
export const GATE = 'GATE';
export const TEXT = 'TEXT';
export const IMAGE = 'Image';
export const SYMBOL_TYPE_IDS = {
  [CLOSED_PROCESS_OVERVIEW]: 1,
  [OPEN_PROCESS_OVERVIEW]: 2,
  [PROCESS_STEP]: 3,
  [PROCESS_INTERFACE]: 4,
  [EVENT]: 5,
  [AND_GATE]: 6,
  [OR_GATE]: 7,
  [XOR_GATE]: 8,
  [TEXT]: 9,
  [IMAGE.toUpperCase()]: 10,
};
export const SYMBOL_TYPES_BY_ID = {
  1: CLOSED_PROCESS_OVERVIEW,
  2: OPEN_PROCESS_OVERVIEW,
  3: PROCESS_STEP,
  4: PROCESS_INTERFACE,
  5: EVENT,
  6: AND_GATE,
  7: OR_GATE,
  8: XOR_GATE,
  9: TEXT,
  10: IMAGE.toUpperCase(),
};

export const SELECTABLE_SYMBOLS = [
  CLOSED_PROCESS_OVERVIEW,
  OPEN_PROCESS_OVERVIEW,
  PROCESS_STEP,
  PROCESS_INTERFACE,
  EVENT,
  TEXT,
  IMAGE,
];
export const RESIZABLE_SYMBOLS = [CLOSED_PROCESS_OVERVIEW, OPEN_PROCESS_OVERVIEW, PROCESS_STEP, PROCESS_INTERFACE, EVENT, GATE];
export const SYMBOLS_WITH_PORTS = [CLOSED_PROCESS_OVERVIEW, OPEN_PROCESS_OVERVIEW, PROCESS_STEP, PROCESS_INTERFACE, EVENT, GATE];

// Process
export const NEW_VCD_ID = 'NEW_VCD';
export const NEW_EPC_ID = 'NEW_EPC';
export const NEW_NEPOS_SWIMLANE = 'NEW_NEPOS_SWIMLANE';
export const NEW_NEPOS_SIPOC = 'NEW_NEPOS_SIPOC';
export const LINK_VCD_EPC_ID = 'LINK_VCD_EPC';
export const LINK_EPC_ID = 'LINK_EPC';
export const IMPORT = 'IMPORT';
export const IMPORT_EPC_ID = 'IMPORT_EPC';
export const MOVE_EPC_ID = 'MOVE_EPC';
export const MOVE_VCD_ID = 'MOVE_VCD';
export const EXTRA_VCD_OPTIONS = [LINK_VCD_EPC_ID, IMPORT, MOVE_EPC_ID, MOVE_VCD_ID];
export const ASSIGN_AUTHOR_PROCESS_TYPES = [NEW_NEPOS_SWIMLANE, SWIMLANE.toUpperCase()];

// Objects
export const OBJECT = 'OBJECT';
export const CATALOG_OBJECT = 'CATALOG_OBJECT';
export const IT_SYSTEM = 'IT_SYSTEM';
export const IT_SYSTEM_SWIMLANE = 'IT_SYSTEM_SWIMLANE';
export const SC = 'SC';
export const IO = 'IO';
export const IO_SIPOC = 'IO_SIPOC';
export const IO_SWIMLANE = 'IO_SWIMLANE';
export const INPUT = 'INPUT';
export const OUTPUT = 'OUTPUT';
export const MILESTONE = 'MILESTONE';
export const ROLE = 'ROLE';
export const ROLE_SWIMLANE = 'ROLE_SWIMLANE';
export const METHOD = 'METHOD';
export const KEY_FIGURE = 'KEY_FIGURE';
export const RISK = 'RISK';
export const OBJECTS_OPTIONS = [IT_SYSTEM, INPUT, OUTPUT, MILESTONE, ROLE, METHOD, KEY_FIGURE, RISK];
export const SIMPLE_LINE = 'SIMPLE_LINE';
export const SIMPLE_ARROW = 'SIMPLE_ARROW';
export const INCOMING_ARROW = 'INCOMING_ARROW';
export const OBJECTS = [IT_SYSTEM, INPUT, OUTPUT, MILESTONE, ROLE, METHOD, KEY_FIGURE, RISK];
export const CATALOG_OBJECTS = [IT_SYSTEM, ROLE];
export const SELECTABLE_OBJECTS = [IT_SYSTEM, ROLE, RISK, MILESTONE, INPUT, OUTPUT, METHOD, KEY_FIGURE];
export const NEPOS_OBJECTS = [IO_SIPOC, IT_SYSTEM_SWIMLANE, ROLE_SWIMLANE];
export const OBJECT_TYPE_IDS = {
  [ROLE]: 1,
  [INPUT]: 2,
  [OUTPUT]: 3,
  [METHOD]: 4,
  [KEY_FIGURE]: 5,
  [MILESTONE]: 6,
  [RISK]: 7,
  [IT_SYSTEM]: 8,
};
export const OBJECT_TYPES_BY_ID = {
  1: ROLE,
  2: INPUT,
  3: OUTPUT,
  4: METHOD,
  5: KEY_FIGURE,
  6: MILESTONE,
  7: RISK,
  8: IT_SYSTEM,
};
export const OBJECT_ICONS = {
  [ROLE]: 'icon-person',
  [INPUT]: 'icon-herunterladen',
  [OUTPUT]: 'icon-hochladen',
  [METHOD]: 'icon-schraubenschluessel-einstellungen',
  [KEY_FIGURE]: 'icon-diagramm-balken-kurve',
  [MILESTONE]: 'icon-form-quadrat',
  [RISK]: 'icon-blitz-fehler',
  [IT_SYSTEM]: 'icon-computer-laptop',
};

export const VALIDATE_LINKAGE = {
  EN: ['OBJECT_DESCRIPTION_EN', 'TASKS_EN'],
  DE: ['OBJECT_DESCRIPTION_DE', 'TASKS_DE'],
};

export const ROLE_ID = 1;
export const IT_SYSTEM_ID = 8;
export const IT_SYSTEM_SWIMLANE_ID = 10;
export const ROLE_SWIMLANE_ID = 11;
export const ROLE_OBJECT_TYPE = 1;
export const IT_SYSTEM_OBJECT_TYPE = 8;
export const LINKAGE_ANNOTATION_ID = 'linkageAnnotation';

export const CATALOG_OBJECT_ID_BY_TYPE = {
  [ROLE]: ROLE_ID,
  [IT_SYSTEM]: IT_SYSTEM_ID,
  [IO_SIPOC]: IO_SIPOC,
  [IO_SWIMLANE]: IO_SWIMLANE,
  [IT_SYSTEM_SWIMLANE]: IT_SYSTEM_SWIMLANE,
  [ROLE_SWIMLANE]: ROLE_SWIMLANE,
};

export const CATALOG_OBJECT_ACTIONS = {
  CREATE: 'CREATE',
  CREATE_SWIMLANE_IO: 'CREATE_SWIMLANE_IO',
  EDIT: 'EDIT',
  MANAGE: 'MANAGE',
  REQUEST: 'REQUEST',
};
export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
export const EDIT = 'EDIT';

export const CATALOG_OBJECT_CREATION = 'CATALOG_OBJECT_CREATION';
export const CATALOG_OBJECT_REJECT = 'CATALOG_OBJECT_REJECT';
export const GET_CATALOG_OBJECTS_BY_TYPE_RESULTS = 'objectsCatalogs';
export const GET_CATALOG_OBJECTS_BY_TYPE_TOTAL_RESULTS = 'totalObjectCatalogs';
export const DIAGRAM_CATALOG_BOX_CLASS = 'diagram-catalog-box';

// Attributes
export const ATTRIBUTE_CARES = {
  SYSTEM: 'S',
  MANDATORY: 'M',
  OPTIONAL: 'O',
};
export const INPUT_TEXT = 'TEXT';
export const INPUT_SEARCH = 'SEARCHINPUT';
export const INPUT_WYSIWYG = 'WYSIWYG';
export const INPUT_MULTISELECTION = 'MULTISELECTION';
export const INPUT_URL = 'URL';
export const INPUT_PROCESS_ID = 'PROCESS_ID';
export const INPUT_DOCUMENT_ID = 'DOCUMENT_ID';
export const INPUT_USER_ID = 'USER_ID';
export const INPUT_DATE_TIME = 'DATE_TIME';
export const DROPDOWN = 'DROPDOWN';
export const TEXTAREA = 'TEXTAREA';
export const FIELD_TYPES = {
  DATE_TIME: INPUT_DATE_TIME,
  DOCUMENT_ID: INPUT_DOCUMENT_ID,
  DROPDOWN,
  MULTISELECTION: INPUT_MULTISELECTION,
  PROCESS_ID: INPUT_PROCESS_ID,
  SEARCHINPUT: INPUT_SEARCH,
  TEXT: INPUT_TEXT,
  TEXTAREA,
  URL: INPUT_URL,
  USER_ID: INPUT_USER_ID,
  WYSIWYG: INPUT_WYSIWYG,
};
export const DEFAULT_FORM_CODE = 'FORM';

export const MODELERS = 'MODELERS';
export const AUTHOR = 'AUTHOR';
export const AUTHORS = 'AUTHORS';
export const APPROVER = 'APPROVER';
export const APPROVERS = 'APPROVERS';
export const NEW_APPROVER = 'NEW_APPROVER';
export const SUBSCRIBERS = 'SUBSCRIBERS';
export const METHOD_OWNER = 'METHOD_OWNER';
export const SCOPES = 'SCOPES';
export const RECOMMENDED_LOCATIONS = 'RECOMMENDED_LOCATIONS';
export const RECOMMEND_NEW_LOCATIONS = 'RECOMMEND_NEW_LOCATIONS';
export const QA_INSPECTOR_GROUP = 'QA_INSPECTOR_GROUP';
export const QI_GROUP = 'QI_GROUP';
export const QA_INSPECTOR_MODELER = 'QA_INSPECTOR_MODELER';
export const QA_INSPECTOR_GROUP_VCD = 'QA_INSPECTOR_GROUP_VCD';
export const MONTHS_VALIDITY = 'MONTHS_VALIDITY';
export const GAP_LOG_CATEGORY = 'GAP_LOG_CATEGORY';
export const GAP_LOG_CATEGORIES = ['CATEGORY1', 'CATEGORY2', 'CATEGORY3'];
export const GAP_LOG_COMMENT = 'GAP_LOG_COMMENT';
export const PROCESS_NAME = 'PROCESS_NAME';
export const AUTHOR_HISTORICAL = 'AUTHOR_HISTORICAL';
export const METHOD_OWNER_HISTORICAL = 'METHOD_OWNER_HISTORICAL';
export const APPROVERS_HISTORICAL = 'APPROVERS_HISTORICAL';
export const SUBSCRIBERS_ARCHIVE = 'SUBSCRIBERS_ARCHIVE';
export const DATASET_LINKAGE = 'DATASET_LINKAGE';
export const ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS';
export const DOCUMENT_UUID = 'DOCUMENT_UUID';
export const DOCUMENT_NAME = 'DOCUMENT_NAME';
export const DOCUMENT_LINK = 'DOCUMENT_LINK';
export const LINK_RESPONSIBILITY = 'LINK_RESPONSIBILITY';
export const RESPONSIBLE = 'RESPONSIBLE';
export const ACCOUNTABLE = 'ACCOUNTABLE';
export const CONSULTED = 'CONSULTED';
export const INFORMED = 'INFORMED';
export const ACCEPTANCE = 'ACCEPTANCE';
export const LINK_RESPONSIBILITIES = [RESPONSIBLE, ACCOUNTABLE, CONSULTED, INFORMED, ACCEPTANCE];
export const LINK_RESPONSIBILITY_IDS = {
  [RESPONSIBLE]: 1,
  [ACCOUNTABLE]: 2,
  [CONSULTED]: 3,
  [INFORMED]: 4,
  [ACCEPTANCE]: 5,
};
export const LINK_RESPONSIBILITY_BY_ID = {
  1: RESPONSIBLE,
  2: ACCOUNTABLE,
  3: CONSULTED,
  4: INFORMED,
  5: ACCEPTANCE,
};
export const KPI = 'KPI';
export const DS = 'DS';

export const MULTISELECTION_DEFAULT_LIMIT = 20;
export const MULTISELECTION_DEFAULT_ITEM_ID = 'Code';
export const MULTISELECT_FIELDS = [MODELER, AUTHOR, APPROVER, METHOD_OWNER];

export const CURRENT = 'CURRENT';
export const DONE = 'DONE';
export const COMPLETED = 'COMPLETED';

export const LAST_EDITOR_CODE = 'LAST_EDITOR_CODE';
export const CREATOR_CODE = 'CREATOR_CODE';
export const DATE_TIME = 'DATE_TIME';
export const BRING_TO_FRONT = 'BRINGTOFRONT';
export const SEND_TO_BACK = 'SENDTOBACK';
export const IMAGE_SIZE_LIMIT = 4000000;

export const DEFAULT_PAGE_SIZE = 20;
export const REQUIREMENT_PAGE_SIZE = 8;
export const DEFAULT_INITIAL_PAGE = 1;
export const REQUIREMENT_INITIAL_PAGE = 0;
export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';
export const DATE_TYPE = 'date';
export const TEXT_TYPE = 'string';
export const INT_TYPE = 'integer';

export const PUBLISHED_STATUS = 'PUBLISHED';
export const MODELING_STATUS = 'MODELING';
export const REPORT_PROCESSES = 'REPORT_PROCESSES';
export const NEW = 'NEW';
export const PUBLISHED = 'publishedArea';
export const MODELING = 'modelingArea';
export const DOCUMENT = 'document';
export const AREAS = {
  MODELING: 'MODELING',
  PUBLISHED: 'PUBLISHED',
  SANDBOX: 'SANDBOX',
  WORKFLOW: 'WORKFLOW',
};

export const TAB_SEARCH_FILTER = [PUBLISHED, MODELING, DOCUMENT];
export const ROLES_WITHOUT_USER = [MODELER, QI_MODELER, QI, ADMINISTRATOR];

export const CURRENT_USER = 'CURRENT_USER';
export const NEW_USER = 'NEW_USER';
export const CURRENT_GROUP = 'CURRENT_GROUP';
export const NEW_GROUP = 'NEW_GROUP';
export const REASON = 'REASON';

// Tools: Undo/Redo
export const POSITION = 'PositionChanged';
export const RESIZE = 'SizeChanged';
export const REMOVE_CONNECTOR = 'removeConnector';
export const NEW_CONNECTOR = 'newConnector';
export const NEW_SYMBOL = 'newSymbol';
export const REMOVE_SYMBOL = 'removeSymbol';
export const NAME_CHANGED = 'nameChanged';
export const UNDO_ACTIONS = [POSITION, RESIZE, REMOVE_CONNECTOR, NEW_CONNECTOR, NEW_SYMBOL, REMOVE_SYMBOL, NAME_CHANGED];
export const MAX_UNDO = 10;
export const AUTOSAVE_INTERVAL = 900000; // 900000 = 15 min.

// Workflow
export const STATUS = {
  APPROVED: 'APPROVED',
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  NEW: 'NEW',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  REQUESTED: 'REQUESTED',
  SANDBOX: 'SANDBOX',
  TEMPORAL: 'TEMPORAL',
  UPDATE: 'UPDATE',
  WORKFLOW: 'WORKFLOW',
  STARTING_WORKFLOW: 'STARTING_WORKFLOW',
};
export const WORKFLOW_STAGES = {
  MODELER,
  AUTHOR,
  QA_INSPECTOR_GROUP,
  QA_INSPECTOR_MODELER,
  METHOD_OWNER,
  APPROVERS,
};
export const STATUS_TAG = {
  [STATUS.SANDBOX]: 'draft',
  [STATUS.ARCHIVED]: 'ARCHIVED',
};
export const MODELING_STATUS_CODES = [STATUS.NEW, STATUS.UPDATE, STATUS.PUBLISHED];
export const CLOSED_WF_STATUS = {
  KO: ['ESCALATED', 'REJECTED'],
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const WORKFLOW_412 = 'WORKFLOW_412';
export const WORKFLOW_401 = 'WORKFLOW_401';

// Search
export const CHECKBOX = 'checkbox';
export const USER = 'users';
export const TEXTINPUT = 'input';
export const DATE = 'date';
export const LOCATION = 'locations';
export const SEARCH_BY = {
  USER: 'USER',
  OBJECT: 'OBJECT',
};

// RegExp to verify if the linkage is a valid URL
export const urlRegEx = /^(http|https):\/\/[^ "]+$/;

export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const SUCCESS_NEPOS = 'SUCCESS_NEPOS';

// Pdf
export const EXCHANGE = 'exchange';

// Recommendations
export const NEW_ASSIGNEE = 'NEW_ASSIGNEE';
export const REWORKED = 'REWORKED';
export const RECOMMENDED_REWORK = 'RECOMMENDED_REWORK';
export const RECOMMENDED_ARCHIVE = 'RECOMMENDED_ARCHIVE';
export const RECOMMENDATION_RECOMMENDED_VERSION = 'RECOMMENDATION_RECOMMENDED_VERSION';
export const RECOMMENDATION_LOCAL_VERSION = 'RECOMMENDATION_LOCAL_VERSION';
export const RECOMMENDATION_VERSION_TABS = [RECOMMENDATION_RECOMMENDED_VERSION, RECOMMENDATION_LOCAL_VERSION];
export const RECOMMENDATION_TYPE = 'RECOMMENDATION';
export const RECOMMENDATION_REWORK_TYPE = 'REWORK';
export const RECOMMENDATION_TYPES = [RECOMMENDATION_TYPE, RECOMMENDATION_REWORK_TYPE];

export const ACCEPTED = 'ACCEPTED';
export const CATEGORY1 = 'CATEGORY1';
export const CATEGORY2 = 'CATEGORY2';
export const CATEGORY3 = 'CATEGORY3';
export const FEEDBACK_GIVEN = 'FEEDBACK_GIVEN';
export const NOT_ACCEPTED = 'NOT_ACCEPTED';
export const NOT_RELEVANT = 'NOT_RELEVANT';
export const RECOMMENDED = 'RECOMMENDED';

export const ACCEPTED_ID = 'ACCEPTED_ID';
export const CATEGORY1_ID = 'CATEGORY1_ID';
export const CATEGORY2_ID = 'CATEGORY2_ID';
export const CATEGORY3_ID = 'CATEGORY3_ID';
export const FEEDBACK_GIVEN_ID = 'FEEDBACK_GIVEN_ID';
export const NOT_ACCEPTED_ID = 'NOT_ACCEPTED_ID';
export const NOT_RELEVANT_ID = 'NOT_RELEVANT_ID';
export const PUBLISHED_ID = 'PUBLISHED_ID';
export const RECOMMENDED_ID = 'RECOMMENDED_ID';

// Sandbox
export const TAB_RECOMMENDATION = 'recommendations.processRecommendations';
export const TAB_DRAFT = 'sandboxDraft';
export const TABS_SANDBOX_OVERVIEW = [TAB_RECOMMENDATION, TAB_DRAFT];

// Tasks
export const TASK_GROUPS = {
  MY_TASKS: 'myTasks',
  GROUP_TASKS: 'groupTasks',
  CLOSED_TASKS: 'closedTasks',
};
export const PROCESS_GROUPS = {
  MY_FAVORITES: 'myFavorites',
  MY_PROCESSES: 'myProcesses',
};
export const NOT_DONE = 'NOT_DONE';
export const PENDING = 'PENDING';
export const BLOCKED = 'BLOCKED';
export const DISABLED_TASK_TYPES = [DONE, NOT_DONE, BLOCKED, RECOMMENDED_ARCHIVE];
export const CLOSED_TASK_TYPES = [DONE, NOT_DONE];

// Error codes
export const FORM_FIELD_ERRORS = {
  REQUIRED: 'REQUIRED',
};
export const ERROR_DATASET_007 = 'DATASET_007';
export const ERROR_DESIGN_008 = 'DESIGN_008';
export const ERROR_DESIGN_021 = 'DESIGN_021';
export const ERROR_DESIGN_012 = 'DESIGN_012';
export const ERROR_DESIGN_403 = 'DESIGN_403';
export const ERROR_DESIGN_404 = 'DESIGN_404';
export const ERROR_WORKFLOW_015 = 'WORKFLOW_015';
export const ERROR_WORKFLOW_016 = 'WORKFLOW_016';
export const ERROR_WORKFLOW_017 = 'WORKFLOW_017';
export const ERROR_WORKFLOW_020 = 'WORKFLOW_020';
export const ERROR_WORKFLOW_018 = 'WORKFLOW_018';
export const SET_HISTORICAL_ERRORS = [ERROR_WORKFLOW_016, ERROR_WORKFLOW_017, ERROR_WORKFLOW_018];
export const DASHBOARD_REDIRECT_ERRORS = [
  ERROR_DATASET_007,
  ERROR_DESIGN_008,
  ERROR_DESIGN_021,
  ERROR_DESIGN_403,
  ERROR_DESIGN_404,
  ERROR_WORKFLOW_020,
];
export const REQUEST_005 = 'REQUEST_005';

// Colors
export const RED_COLOR = '#ff2600';
export const WHITE_COLOR = 'white';
export const BLACK_COLOR = 'black';
export const GREY_COLOR = '#444444';
export const SHAPES_COLOR = '#007a93';
export const EXTERNAL_LINK_COLOR = '#6ea046';
export const POPUP_NODE_COLORS = [
  { color: '#83ceda', checkColor: GREY_COLOR },
  { color: '#49b5c6', checkColor: WHITE_COLOR },
  { color: '#1ca2b8', checkColor: WHITE_COLOR },
  { color: '#1397a6', checkColor: WHITE_COLOR },
  { color: '#097582', checkColor: WHITE_COLOR },
  { color: '#05434b', checkColor: WHITE_COLOR },
  { color: '#022024', checkColor: WHITE_COLOR },
  { color: '#dfedf2', checkColor: GREY_COLOR },
  { color: '#a6cad8', checkColor: GREY_COLOR },
  { color: '#79aebf', checkColor: WHITE_COLOR },
  { color: '#5097ab', checkColor: WHITE_COLOR },
  { color: '#0888a6', checkColor: WHITE_COLOR },
  { color: '#007a93', checkColor: WHITE_COLOR },
  { color: '#00566a', checkColor: WHITE_COLOR },
  { color: '#e6e6e6', checkColor: GREY_COLOR },
  { color: '#c8c8c8', checkColor: GREY_COLOR },
  { color: '#9e9e9e', checkColor: WHITE_COLOR },
  { color: '#878787', checkColor: WHITE_COLOR },
  { color: '#707070', checkColor: WHITE_COLOR },
  { color: '#444444', checkColor: WHITE_COLOR },
  { color: '#333333', checkColor: WHITE_COLOR },
  { color: '#f0be7d', checkColor: WHITE_COLOR },
  { color: '#c8a0a0', checkColor: WHITE_COLOR },
  { color: '#aa736e', checkColor: WHITE_COLOR },
  { color: '#a8f9b0', checkColor: GREY_COLOR },
  { color: '#41d344', checkColor: WHITE_COLOR },
  { color: '#aac891', checkColor: WHITE_COLOR },
  { color: '#6ea245', checkColor: WHITE_COLOR },
  { color: WHITE_COLOR, checkColor: GREY_COLOR },
  { color: BLACK_COLOR, checkColor: WHITE_COLOR },
];

export const BORDER_COLOR_POPUP = 'BorderColor';
export const FILL_COLOR_POPUP = 'FillColor';
export const TEXT_COLOR_POPUP = 'TextColor';
export const DIAGRAM_FONT_SIZE = 14;
export const FONT_SIZES = [10, 12, 14, 16, 18, 20, 22, 24, 26];

// Connectors
export const CONNECTOR_ALIGN_SEGMENT_THRESHOLD = 0.5;
export const CONNECTOR_HIDE_HANDLE_THRESHOLD = 5;

// Components
export const BUTTON_PRIMARY = 'Primary';
export const BUTTON_SECONDARY = 'Secondary';
export const BUTTON_PRIMARY_LEGACY = 'PrimaryLegacy';
export const BUTTON_SECONDARY_LEGACY = 'SecondaryLegacy';
export const BUTTON_SECONDARY_LEFT = 'SecondaryLeft';
export const BUTTON_ERROR = 'Error';

export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';
export const ZOOM_TO_FIT = 'ZOOM_TO_FIT';
export const DEFAULT_ZOOM = 1;
export const ZOOM_FACTOR = 0.4;
export const ZOOM_LEVELS = {
  DEFAULT: 14, // fontSize: 14px
  MIN: 4, // fontSize: 4px
  MAX: 18, // fontSize: 18px
  MIN_DIAGRAM: 1, // fontSize: 1px
  STEP: 2,
  PADDING: 1.1,
  VALUES: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
};

export const DIAGRAM_ENVIRONMENTS = {
  CLOSED_WORKFLOW: 'closed-workflow',
  DATASET: 'dataset',
  MODELING: 'diagram',
  PUBLISHED: 'published',
  SANDBOX: 'sandbox',
};

// Forms
export const TEXTAREA_DEFAULT_LENGTH = 500;
export const TEXTAREA_DEFAULT_ROWS = 4;

// Swimlane process step
export const MAX_OBJECTS_PROCESS_STEP = {
  IO_SWIMLANE: 30,
  IT_SYSTEM_SWIMLANE: 50,
  ROLE_SWIMLANE: 50,
};
export const MAX_OBJECTS_PROCESS_STEP_BY_ROLE_CATEGORY = 15;
export const SYMBOLS_WITHOUT_ATTRIBUTES = [OR_GATE, XOR_GATE, AND_GATE];

// Swimlane NEPOS widths and heights
export const SWIMLANE_DEFAULT_WIDTH = 3;
export const SWIMLANE_DEFAULT_HEIGHT = 1;
export const SWIMLANE_MAX_HEIGHT = 10;
export const SWIMLANE_MAX_WIDTH = 40;
export const SWIMLANE_SYMBOL_PORTS_DIFFERENCE_THRESHOLD = 1;
export const GHOST_SWIMLANE_HEIGHT_FACTOR = 11;

export const DIAGRAM_STATUS_REDIRECT = {
  UPDATE: 'diagram',
  NEW: 'diagram',
  SANDBOX: 'sandbox',
  PUBLISHED: 'published',
  WORKFLOW: 'diagram',
  BLOCKED: 'diagram',
  CLOSED_WORKFLOW: 'closed-workflow',
};

// Data Set
export const DATA_SET = 'DATA_SET';
export const DATASET = 'DATASET';
export const DATA_SET_EDIT_STATUS = {
  UNLOCKED: 'UNLOCKED',
  LOCKED: 'LOCKED',
};
export const DATA_SET_FORMS = {
  COMMON: 'COMMON',
  VCD: 'VCD',
  EPC: 'EPC',
};
export const DATA_SET_MODE = {
  PUBLISHED: '/dataset-published/',
};
export const DATA_SET_DIAGRAM_COMPONENT = 'DIAGRAM_SELECTOR';
export const MEGABYTE = 1048576; // 1MB = 1,048,576 bytes
export const MAX_DATASET_FILES = 30;
export const MAX_NEPOS_VIDEO_FILES = 1;
export const MAX_DATASET_FILE_SIZE = 10 * MEGABYTE;
export const MAX_REQUIREMENT_FILES = 10;
export const MAX_REQUIREMENT_FILE_SIZE = 25 * MEGABYTE;
export const MAX_VIDEO_SIZE = 50 * MEGABYTE;

// Requirements
export const REQUIREMENT = 'REQUIREMENT';
export const RR = 'RR';
export const REQUIREMENT_REQUEST = 'REQUIREMENT_REQUEST';

// Process Level Database
export const PROCESS_LEVEL = 'PROCESS_LEVEL';
export const PL = 'PL';

export const RANGE_OF_YEARS = 10;

export const DIALOG_PARENT_ID = 'dialog-root';
