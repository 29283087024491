import { useState } from 'react';

import RoundButton from 'components/UI/RoundButton/RoundButton';

import styles from './ExpandableBox.module.scss';

export const enum BoxPosition {
  LEFT,
  RIGHT,
}

type Props = {
  position?: BoxPosition;
  children?: React.ReactNode;
  icons?: string[];
};

const ExpandableBox = ({ position, children, icons }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={`${styles.Wrapper} ${isOpen ? styles.Open : ''} ${position ? styles.Right : ''}`}>
      <div className={styles.Content}>
        {isOpen ? (
          children
        ) : (
          <div className={styles.Buttons}>
            {icons?.map((icon, index) => (
              <RoundButton
                icon={icon}
                id={`icon-${index}`}
                isFilled
                key={icon}
                onClick={() => setIsOpen(!isOpen)}
                variant="gradient"
              />
            ))}
          </div>
        )}
      </div>
      <div className={styles.ArrowSection}>
        <div className={styles.Arrow} data-qa={`open-arrow-${position ? 'right' : 'left'}`} onClick={() => setIsOpen(!isOpen)}>
          <i className={`di icon-pfeil-doppelt-chevron-${position ? 'links' : 'rechts'}`} />
        </div>
      </div>
    </div>
  );
};

export default ExpandableBox;
