import React from 'react';

import { withTranslation } from 'react-i18next';
import { FormGenerator } from 'react-reactive-form';
import './AttributesForm.scss';

const WorkflowForm = (props) => {
  const { fieldConfig, data, loadWorkflow } = props;
  let form;

  const setFormValues = () => {
    if (data && form && loadWorkflow) {
      form.patchValue(data);
    }
  };

  const onMount = (mountedForm) => {
    form = mountedForm;
    setFormValues();
  };

  setFormValues();

  return (
    <>
      <hr />
      <h5 className="attributes-title">{props.t('diagram.releaseInformation')}</h5>
      <FormGenerator fieldConfig={fieldConfig} onMount={onMount} />
    </>
  );
};

export default withTranslation('common')(WorkflowForm);
