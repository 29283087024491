import { useCallback, useEffect, useMemo } from 'react';

import { DIALOG_PARENT_ID } from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import { ConnectorEndpoint } from 'types/connectors';
import { Tool } from 'types/diagram';

import useDiagramContext from './useDiagramContext';

export default function useSelection() {
  const {
    dispatch,
    grabAndMove,
    originRef,
    processData,
    selectedSymbolIds,
    selectedConnectorId,
    tool,
    shouldPreventClick,
    attributesRef,
    attributesModalRef,
    toolBarRef,
  } = useDiagramContext();
  const symbols = useMemo(() => {
    const pDSymbols = processData?.symbols || [];
    const swSymbols = processData?.swimlanes.map((swimlane) => swimlane.symbols).flat() || [];

    return [...pDSymbols, ...swSymbols];
  }, [processData?.swimlanes, processData?.symbols]);

  const connectors = useMemo(() => processData?.connectors || [], [processData?.connectors]);

  const selectedSymbols = useMemo(
    () => (selectedSymbolIds.length > 0 ? symbols.filter((symbol) => selectedSymbolIds.includes(symbol.id)) : undefined),
    [symbols, selectedSymbolIds],
  );
  const selectedConnector = useMemo(
    () =>
      selectedConnectorId
        ? connectors.find(
            (connector) =>
              connector.source.id === selectedConnectorId.sourceId && connector.target.id === selectedConnectorId.targetId,
          )
        : undefined,
    [connectors, selectedConnectorId],
  );

  const handleSelect = useCallback(
    (newIds: string | string[], e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (shouldPreventClick.current || tool === Tool.CONNECT || grabAndMove) {
        shouldPreventClick.current = false;
        return;
      }

      let newSelectedSymbolIds = [newIds].flat();
      if (
        e?.getModifierState('Control') &&
        newSelectedSymbolIds.length === 1 &&
        selectedSymbolIds.includes(newSelectedSymbolIds[0])
      ) {
        newSelectedSymbolIds = selectedSymbolIds.filter((symbolId) => symbolId !== newSelectedSymbolIds[0]);
      } else if (
        e?.getModifierState('Control') &&
        newSelectedSymbolIds.some((symbolId) => !selectedSymbolIds.includes(symbolId))
      ) {
        newSelectedSymbolIds = [
          ...selectedSymbolIds,
          ...newSelectedSymbolIds.filter((symbolId) => !selectedSymbolIds.includes(symbolId)),
        ];
      }
      dispatch({ type: DiagramActionTypes.SET_SELECTION, payload: newSelectedSymbolIds });
      dispatch({ type: DiagramActionTypes.SET_CONNECTOR_SELECTION, payload: null });
    },
    [dispatch, grabAndMove, tool, selectedSymbolIds, shouldPreventClick],
  );

  const handleSelectConnector = useCallback(
    (source: ConnectorEndpoint, target: ConnectorEndpoint) => {
      if (
        (tool !== Tool.NONE && tool !== Tool.DISPLAY_ATTRIBUTES) ||
        grabAndMove ||
        !connectors.some((connector) => connector.source.id === source.id && connector.target.id === target.id) ||
        (selectedConnector?.source === source && selectedConnector.target === target)
      ) {
        return;
      }
      dispatch({
        type: DiagramActionTypes.SET_CONNECTOR_SELECTION,
        payload: { sourceId: source.id, targetId: target.id },
      });
      dispatch({ type: DiagramActionTypes.SET_SELECTION, payload: [] });
    },
    [connectors, dispatch, grabAndMove, selectedConnector?.source, selectedConnector?.target, tool],
  );

  const clearSelection = useCallback(
    (event?: React.MouseEvent<HTMLDivElement>) => {
      if (event && originRef.current?.contains(event.target as Node) && (event.target as HTMLDivElement).id !== 'diagram-bounds')
        return;
      dispatch({ type: DiagramActionTypes.SET_CONNECTOR_SELECTION, payload: null });
      dispatch({ type: DiagramActionTypes.SET_SELECTION, payload: [] });
    },
    [dispatch, originRef],
  );

  useEffect(() => {
    if (tool !== Tool.CONNECT || (!selectedSymbols?.length && !selectedConnector)) return;

    clearSelection();
  }, [clearSelection, selectedConnector, selectedSymbols?.length, tool]);

  const handleSelectionOuterClick = useCallback(
    (event, idSymbol) => {
      const dialogParent = document.getElementById(DIALOG_PARENT_ID);
      if (
        event &&
        !dialogParent?.contains(event.target as Node) &&
        !toolBarRef?.current?.contains(event.target as Node) &&
        !attributesRef.current?.contains(event.target as Node) &&
        !attributesModalRef.current?.contains(event.target as Node) &&
        selectedSymbolIds.includes(idSymbol)
      ) {
        clearSelection();
      }
    },
    [attributesRef, attributesModalRef, clearSelection, selectedSymbolIds, toolBarRef],
  );

  return {
    handleSelect,
    handleSelectConnector,
    handleSelectionOuterClick,
    clearSelection,
    selectedConnector,
    selectedSymbols,
  };
}
