import { useRef, useState } from 'react';

import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import { getFormattedDate, getFullFormattedDate, getUTCDate } from 'assets/js/Utils';

import styles from './DatePickerNEPOS.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  id: string;
  isEditing?: boolean;
  label: string;
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
  containerRef?: React.RefObject<HTMLLabelElement>;
  error?: string;
};

const DatePickerNEPOS = (props: Props) => {
  const { className, disabled, id, isEditing, label, onChange, required, value, containerRef, error } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentContainerRef = containerRef || newContainerRef;
  const hasError = Boolean(error);
  const { i18n } = useTranslation();

  const handleContainerClick = () => {
    if (!currentContainerRef.current) return;
    setIsCalendarOpen(true);
    currentContainerRef.current.focus();
  };

  const handleChange = (date: Date) => {
    const stringDate = date ? date.toISOString() : '';
    const fullFormattedDate = getFullFormattedDate(stringDate);
    const utcDate = getUTCDate(fullFormattedDate, false);
    onChange(utcDate);
    setIsCalendarOpen(false);
  };

  return (
    <>
      <label
        className={`${styles.Container} ${isCalendarOpen ? styles.Focused : ''} ${disabled ? styles.Disabled : ''}
        ${className || ''} ${hasError && !isCalendarOpen ? styles.Error : ''}`}
        htmlFor={id}
        onClick={() => !disabled && handleContainerClick()}
        ref={currentContainerRef}
      >
        <div
          className={`${styles.Label} ${isEditing || value?.trim() !== '' ? styles.Floating : ''}
          ${hasError ? styles.Error : ''}`}
        >
          {label}
          {required && <span className="error">*</span>}
        </div>
        <div
          className={`${styles.Input} ${styles.IconWrpper} ${isCalendarOpen ? styles.Open : ''}
          ${disabled ? styles.Disabled : ''}`}
        >
          <span>{value ? getFormattedDate(value, '-') : ''}</span>
          <div>
            {hasError && <i className={`di icon-blitz-fehler error ${styles.IconError}`} />}
            {!disabled && <i className={`di icon-kalender ${styles.Icon}`} />}
          </div>
        </div>

        <div className={styles.Calendar}>
          {isCalendarOpen && (
            <Calendar
              formatShortWeekday={(locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
              locale={i18n.language}
              navigationLabel={({ date, locale }) => `${date.toLocaleString(locale, { month: 'long' })} ${date.getFullYear()}`}
              next2Label={null}
              nextLabel={<i className={`di icon-pfeil-chevron-rechts ${styles.NavigationArrows}`} />}
              onChange={handleChange}
              prev2Label={null}
              prevLabel={<i className={`di icon-pfeil-chevron-links ${styles.NavigationArrows}`} />}
              showNeighboringMonth={false}
            />
          )}
        </div>
      </label>
    </>
  );
};

export default DatePickerNEPOS;
