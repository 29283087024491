import styles from './CircleConnector.module.scss';

const CircleConnector = () => (
  <div className={styles.GreyBoxConnector}>
    <div className={styles.Line} />
    <div className={styles.Circle}>
      <span className={styles.Dot} />
    </div>
  </div>
);

export default CircleConnector;
