import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import {
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  CATALOG_OBJECT_ACTIONS,
  LINK_RESPONSIBILITIES,
  LINK_RESPONSIBILITY_IDS,
  MAX_OBJECTS_PROCESS_STEP,
  MAX_OBJECTS_PROCESS_STEP_BY_ROLE_CATEGORY,
  NOT_TRANSLATABLE,
  PROCESS_STEP,
  PROCESS_STEP_PANEL_ADDED_HEIGHT,
} from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { getDangerouslySetInnerHTML, getFormattedDate, removeHTMLTagsFromString } from 'assets/js/Utils';
import ConnectorPorts from 'components/ConnectorPorts/ConnectorPorts';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import ProcessStepObject from 'components/ProcessStepObject/ProcessStepObject';
import Selection from 'components/Selection/Selection';
import SelectionPanel from 'components/Selection/SelectionPanel';
import SymbolAttributes from 'components/Symbol/SymbolAttributes/SymbolAttributes';
import DialogActivity, { ActivityActions } from 'components/UI/DialogActivity/DialogActivity';
import DialogInput from 'components/UI/DialogInput/DialogInput';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import DropdownNEPOS from 'components/UI/Dropdown/DropdownNEPOS';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import {
  deleteActivityFromErrors,
  DiagramActionTypes,
  movingSwimlaneSymbols,
  setSwimlaneDisplayingAttributes,
  setSymbolObjectsToDisplay,
} from 'contexts/Diagram/DiagramContext';
import useConnector from 'hooks/useConnector';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useError from 'hooks/useError';
import useLocalization from 'hooks/useLocalization';
import useOuterClick from 'hooks/useOuterClick';
import usePagination from 'hooks/usePagination';
import useProcess from 'hooks/useProcess';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import useSymbol from 'hooks/useSymbol';
import useTool from 'hooks/useTool';
import useValidation from 'hooks/useValidation';
import {
  getActivityValues,
  updateActivitySpecification,
  deleteActivitySpecification,
  findAllByObjectType,
} from 'services/design';
import { getActivityPublished } from 'services/documentation';
import { InputOutputCatalog, ItSystem, ObjectIOSubTypes, ObjectTypes, Role } from 'types/administration';
import { ButtonProps, Language } from 'types/config';
import { LinkedDiagram, PaletteActions, Tool } from 'types/diagram';
import { AttributeCode, Attributes } from 'types/forms';
import {
  ProcessCatalogObject,
  ProcessData,
  ProcessInputOutput,
  ProcessItSystem,
  ProcessRole,
  ProcessStatus,
} from 'types/processes';
import { SwimlaneV2 } from 'types/swimlanes';
import {
  SymbolObject,
  Symbol,
  Symbol as SymbolType,
  SwimlanePaletteItemProps,
  SymbolTypes,
  ActivitySpecification,
  ProcessStepSymbol,
  SelectionButton,
} from 'types/symbols';
import { Pagination, TablePrefix, TableColumn, TableVariant } from 'types/tables';
import { CatalogUser } from 'types/user';

import styles from './ProcessStep.module.scss';

type Props = {
  isPaletteItem?: boolean;
};

const ProcessStep = (props: (Props & SymbolType) | SwimlanePaletteItemProps) => {
  const { activitySpecificationId, attributes, id, objects, swimlaneId, diagramLink, isPaletteItem } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    activitiesError,
    breadcrumbs,
    dispatch,
    editableSymbol,
    diagramLanguage,
    processData,
    processStepWithErrors,
    symbolObjectsDisplayed,
    grabAndMove,
    selectedTools,
    selectedConnectorId,
    fontSize,
  } = useDiagramContext();
  const diagramId = useParams<{ id: string }>().id;
  const { saveProcess } = useProcess(diagramId);
  const { deleteSymbolObject, getSelectedSwimlane, getSelectionButtons, addSymbolObject, addSymbolTemporaryObject } =
    useSwimlane();
  const { handleDragStart } = useDragAndDrop();
  const { handleOuterClick, onNameChange } = useSymbol();
  const { toggleTool, tool } = useTool();
  const { handleSelect, handleSelectionOuterClick } = useSelection();
  const { t } = useTranslation();
  const history = useHistory();
  const { showAlert } = useError();
  const { updateSwimlanesConnectorsVertices } = useConnector();
  const { availableLanguages } = useLocalization();
  const { symbolsIdsWithErrors } = useValidation();
  const location = useLocation();
  const [selectionButtons, setSelectionButtons] = useState<SelectionButton[]>();
  const [isMenuOpen, setIsMenuOpen] = useState<string>('');
  const [showAttributes, setShowAttributes] = useState(false);
  const symbolNameInputRef = useRef<HTMLDivElement>(null);
  const [responsibility, setResponsibility] = useState<string>('');
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [IOResult, setIOResult] = useState<InputOutputCatalog[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0);
  const [rolesResult, setRolesResult] = useState<Role[]>([]);
  const [roles, setRoles] = useState<{ [key in Language]?: (ProcessRole & { id: number })[] }>({});
  const [rolesToAdd, setRolesToAdd] = useState<string[]>([]);
  const [inputOutputToAdd, setInputOutputToAdd] = useState<string[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [requestNewObject, setRequestNewObject] = useState<ObjectTypes | null>(null);
  const [showItSystemDialog, setShowItSystemDialog] = useState(false);
  const [itSystemsResult, setItSystemsResult] = useState<Role[]>([]);
  const [itSystemToAdd, setItSystemToAdd] = useState<string[]>([]);
  const [itSystems, setItSystems] = useState<{ [key in Language]?: (ProcessItSystem & { id: number })[] }>({});
  const [showTemporaryRoleDialog, setShowTemporaryRoleDialog] = useState(false);
  const [showTemporaryITSystemDialog, setShowTemporaryITSystemDialog] = useState(false);
  const [isAddIODialogOpen, setIsAddIODialogOpen] = useState(false);
  const [objectType, setObjectType] = useState<ObjectIOSubTypes>();
  const [isActivityDialogOpen, setIsActivityDialogOpen] = useState(false);
  const [activityId, setActivityId] = useState<number>();
  const availableRoles = roles[diagramLanguage] || undefined;
  const availableItSystems = itSystems[diagramLanguage] || undefined;
  const processStepNumberError = processStepWithErrors.find((processStepId) => processStepId === id);
  const hasActivityError = useMemo(() => activitiesError?.some((ac) => ac === activityId), [activitiesError, activityId]);
  const hasErrors = !isPaletteItem && symbolsIdsWithErrors && symbolsIdsWithErrors.find((symbolId) => symbolId === id);
  const defaultId = -1;

  useEffect(() => {
    if (activitySpecificationId) {
      setActivityId(activitySpecificationId);
    }
  }, [activitySpecificationId]);

  const defaultSelectedProcessStep = processData?.swimlanes
    .reduce(
      (array: Symbol[], swimlane: SwimlaneV2) => [
        ...array,
        ...swimlane.symbols?.filter((symbol) => symbol.type === SymbolTypes.PROCESS_STEP),
      ],
      [],
    )
    .find((symbol) => symbol.id === id);

  const [selectedProcessStep, setSelectedProcessStep] = useState<ProcessStepSymbol>();

  const selectedSwimlane = getSelectedSwimlane(swimlaneId, processData?.swimlanes);

  useEffect(() => {
    setSearchText('');
    setPage(0);
    setTotalPages(1);
  }, [showRoleDialog]);

  useEffect(() => {
    setSelectionButtons(
      getSelectionButtons({
        diagramData: {
          diagramAttributes: processData?.attributes,
          diagramLink: !!diagramLink,
          isOnlyRead: processData?.isOnlyRead,
          processLevel: breadcrumbs.length,
          swimlaneId,
          selectedSwimlane,
          symbolObjectsDisplayed,
        },
        id,
        isOpen: isMenuOpen !== '',
        onClick: (type?: boolean | string) => {
          setIsMenuOpen(isMenuOpen !== (type as string) ? (type as string)!! : '');
        },
        symbolType: SymbolTypes.PROCESS_STEP,
      }),
    );
  }, [
    breadcrumbs,
    diagramLink,
    getSelectionButtons,
    id,
    isMenuOpen,
    location.pathname,
    processData?.attributes,
    processData?.isOnlyRead,
    processData?.swimlanes,
    selectedSwimlane,
    symbolObjectsDisplayed,
    swimlaneId,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editableSymbol === id && textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [editableSymbol, id]);

  const onDragStart = (e: any) => {
    if (processData?.isOnlyRead) return;
    handleDragStart(e);
    if (isPaletteItem) {
      dispatch({
        type: DiagramActionTypes.SET_PALETTE_ACTION,
        payload: PaletteActions.ADD_PROCESS_STEP,
      });
      dispatch(movingSwimlaneSymbols(true));
    } else if (swimlaneId) {
      if (tool === Tool.CONNECT || grabAndMove || selectedConnectorId) return;

      dispatch(movingSwimlaneSymbols(true, id, swimlaneId));
    }
  };

  const handleMenuOuterClick = useCallback(
    (event) => {
      setIsMenuOpen('');
      handleSelectionOuterClick(event, id);
    },
    [handleSelectionOuterClick, id],
  );
  useOuterClick(symbolNameInputRef, handleOuterClick, id);
  useOuterClick(containerRef, handleMenuOuterClick, id);

  const availableRolesRows = useMemo(() => (!availableRoles ? undefined : availableRoles), [availableRoles]);

  const availableItSystemsRows = useMemo(() => (!availableItSystems ? undefined : availableItSystems), [availableItSystems]);

  const requestNewObjectParentCallback = (type: string) => {
    switch (type) {
      case ObjectTypes.IT_SYSTEM_SWIMLANE:
        return setShowItSystemDialog(true);
      case ObjectTypes.ROLE_SWIMLANE:
        return setShowRoleDialog(true);
      case ObjectTypes.IO_SWIMLANE:
        return setIsAddIODialogOpen(true);
      default:
        return () => {};
    }
  };

  const responsibilitiesOptions = LINK_RESPONSIBILITIES.slice(1);

  const handleDeleteClick = (object: SymbolObject) => deleteSymbolObject(id, object);

  const getToolFromType = (type: ObjectTypes, subtype?: ObjectIOSubTypes) => {
    const objectsToDisplay: { [key in ObjectTypes | ObjectIOSubTypes]?: Tool } = {
      [ObjectTypes.ROLE_SWIMLANE]: Tool.DISPLAY_ROLES,
      [ObjectTypes.IT_SYSTEM_SWIMLANE]: Tool.DISPLAY_IT_SYSTEMS,
      [ObjectIOSubTypes.INPUT]: Tool.DISPLAY_INPUTS,
      [ObjectIOSubTypes.OUTPUT]: Tool.DISPLAY_OUTPUTS,
    };

    if (subtype) {
      return objectsToDisplay[subtype];
    }

    return objectsToDisplay[type];
  };

  const filteredObjects = (object: SymbolObject) => {
    if (symbolObjectsDisplayed.includes(id) && !selectedTools.length) {
      return true;
    }
    const toolSelected = getToolFromType(object.type, object.subtype);
    return toolSelected ? selectedTools.includes(toolSelected) : false;
  };

  const fetchItSystems = async (newPage: number = 0, search: string = '') => {
    if (!processData?.type) return;
    setItSystems({});
    setPage(newPage);
    setSearchText(search);

    try {
      const { data } = await findAllByObjectType(ObjectTypes.IT_SYSTEM_SWIMLANE, {
        page: newPage,
        ...(search ? { search } : {}),
        size: 20,
      });
      setItSystemsResult(data.results);
      const itSystemsData = availableLanguages.reduce((acc: Attributes, lang: Language) => {
        const results = data.results.map((elem: ItSystem) => {
          return {
            id: elem.id,
            name: elem.attributes?.[lang]?.OBJECT_NAME,
            description: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.OBJECT_DESCRIPTION)}
              />
            ),
            containedIn: elem.diagramIdentifierList?.map((diagram: LinkedDiagram) => ({
              id: diagram.id,
              nameMap: diagram.nameMap[lang]?.PROCESS_NAME,
              status: diagram.status,
            })),
            creation: getFormattedDate(elem.attributes?.[NOT_TRANSLATABLE]?.CREATION),
            status: elem.approved ? '' : t('REQUESTED'),
          };
        });

        setTotalPages(data.numberOfPages);

        return {
          ...acc,
          [lang]: results,
        };
      }, {});

      setItSystems(itSystemsData);
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const fetchRoles = async (newPage: number = 0, search: string = '') => {
    if (!processData?.type) return;
    setRoles({});
    setPage(newPage);
    setSearchText(search);

    try {
      const { data } = await findAllByObjectType(ObjectTypes.ROLE_SWIMLANE, {
        page: newPage,
        ...(search ? { search } : {}),
        size: 20,
      });

      setRolesResult(data.results);
      const rolesData = availableLanguages.reduce((acc: Attributes, lang: Language) => {
        const results = data.results.map((elem: Role) => {
          return {
            id: elem.id,
            name: elem.attributes?.[lang]?.OBJECT_NAME,
            description: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.OBJECT_DESCRIPTION)}
              />
            ),
            tasks: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.TASKS)}
              />
            ),
            containedIn: elem.diagramIdentifierList?.map((diagram: LinkedDiagram) => ({
              id: diagram.id,
              nameMap: diagram.nameMap[lang]?.PROCESS_NAME,
              status: diagram.status,
            })),
            creation: getFormattedDate(elem.attributes?.[NOT_TRANSLATABLE]?.CREATION),
            status: elem.approved ? '' : t('REQUESTED'),
          };
        });
        setTotalPages(data.numberOfPages);
        return {
          ...acc,
          [lang]: results,
        };
      }, {});
      setRoles(rolesData);
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const fetchInputOutput = useCallback(
    (params) =>
      findAllByObjectType(ObjectTypes.IO_SWIMLANE, { diagramId, includeAll: false, ...params }).then((res) => {
        const { catalog, results } = res.data;
        const finalIoData: { [lang: string]: string } = {};

        availableLanguages?.forEach((lang: Language) => {
          const formattedResults = results.map((elem: InputOutputCatalog) => ({
            id: elem.id,
            name: elem.attributes?.[lang]?.NAME,
            description: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.DESCRIPTION)}
              />
            ),
            [processData?.isSwimlane ? 'creator' : 'responsiblePerson']: catalog.USER?.find(
              (catalogUser: CatalogUser) =>
                catalogUser.code ===
                elem.attributes?.[NOT_TRANSLATABLE]?.[
                  processData?.isSwimlane ? AttributeCode.CREATOR : AttributeCode.RESPONSIBLE_PERSON
                ],
            )?.commonName,
            containedIn: elem.diagramIdentifierList?.map((diagram: LinkedDiagram) => ({
              id: diagram.id,
              nameMap: diagram.nameMap[lang]?.PROCESS_NAME,
              status: diagram.status,
            })),
            status: t(elem.status),
            creation: getFormattedDate(elem.attributes?.[NOT_TRANSLATABLE]?.CREATION),
            attributes: elem.attributes,
          }));

          finalIoData[lang] = formattedResults;
        });

        setIOResult(res.data.results);

        return {
          numberOfPages: res.data.numberOfPages,
          results: finalIoData[diagramLanguage],
          totalResults: res.data.totalResults,
        };
      }),
    [availableLanguages, diagramId, diagramLanguage, processData?.isSwimlane, t],
  );

  const pagination = usePagination({ getResults: fetchInputOutput, pageSize: 20, initialPage: 0 });
  const {
    results,
    page: paginatedPage,
    setPage: setPaginationPage,
    totalPages: totalPaginationPages,
    handleSearchChange,
    isLoading: isPaginationLoading,
  } = pagination;

  const handleIOClick = (type: ObjectIOSubTypes) => {
    setIsMenuOpen('');
    setIsAddIODialogOpen(true);
    setObjectType(type);
  };

  const objectsOptions = [
    {
      id: 'contextualMenu.ROLE',
      icon: 'icon-person',
      click: () => {
        setIsMenuOpen('');
        setResponsibility('');
        setShowRoleDialog(true);
        fetchRoles();
      },
    },
    {
      id: 'contextualMenu.TEMPORARY_ROLE',
      icon: 'icon-person-konturlinie-angefragt',
      click: () => {
        setIsMenuOpen('');
        setShowTemporaryRoleDialog(true);
      },
    },
    {
      id: 'contextualMenu.IT_SYSTEM',
      icon: 'icon-computer',
      click: () => {
        setIsMenuOpen('');
        setShowItSystemDialog(true);
        fetchItSystems();
      },
    },
    {
      id: 'contextualMenu.TEMPORARY_IT_SYSTEM',
      icon: 'icon-computer-angefragt',
      click: () => {
        setIsMenuOpen('');
        setShowTemporaryITSystemDialog(true);
      },
    },
    {
      id: 'contextualMenu.INPUT',
      icon: 'icon-herunterladen',
      click: () => {
        setIsMenuOpen('');
        handleIOClick(ObjectIOSubTypes.INPUT);
      },
    },
    {
      id: 'contextualMenu.OUTPUT',
      icon: 'icon-hochladen',
      click: () => {
        setIsMenuOpen('');
        handleIOClick(ObjectIOSubTypes.OUTPUT);
      },
    },
  ];

  const openActivityDialog = (processId?: string) => {
    const noTranslatableAttributes = processData?.attributes?.NOT_TRANSLATABLE;
    const processNumber = noTranslatableAttributes?.PROCESS_NUMBER;
    const version = noTranslatableAttributes?.VERSION;
    return (
      processData?.isPublishedVersion && version && processNumber
        ? getActivityPublished(processNumber, version, id)
        : getActivityValues(processId || id)
    )
      .then((response) => {
        setSelectedProcessStep(response.data);
        setIsActivityDialogOpen(true);
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const createActivity = () => {
    if (!processData) return;
    if (activityId === defaultId) {
      const swimlanes = [...processData.swimlanes];
      swimlanes.forEach((swimlane) =>
        swimlane.symbols
          .filter((symbol) => symbol.activitySpecificationId === -1)
          .forEach((symbol) => delete symbol.activitySpecificationId),
      );

      const dataToSave: ProcessData = {
        ...processData,
        swimlanes,
      };
      saveProcess(dataToSave)
        .then(() => {
          dispatch({
            type: DiagramActionTypes.SET_SELECTION,
            payload: [],
          });
          setIsActivityDialogOpen(true);
          openActivityDialog();
        })
        .catch((error) => {
          handleServiceError(error);
        });
    } else {
      openActivityDialog();
    }
  };

  const updateActivity = (activitySpecificationData?: ActivitySpecification) => {
    if (!selectedProcessStep || !selectedProcessStep.activitySpecification || !activitySpecificationData) return;
    const { attributes: activityAttributes, predecessors, successors } = activitySpecificationData;
    const currentActivityId = Number(selectedProcessStep.activitySpecification.id);

    updateActivitySpecification({
      id: currentActivityId,
      attributes: activityAttributes,
      predecessors,
      successors,
    })
      .then(() => {
        dispatch({
          type: DiagramActionTypes.SET_SELECTION,
          payload: [],
        });
        if (!hasActivityError) return;

        const activityAttributesLanguageErrors: number[] = [];
        availableLanguages.forEach((lang: string) => {
          if (!lang || !currentActivityId || !activityAttributes) return;

          const currentAttribute = activityAttributes[lang];
          const currentAttributeValue = removeHTMLTagsFromString(currentAttribute?.ACTIVITY_CONTRIBUTION);
          const notValidAttribute = currentAttributeValue === '' || currentAttributeValue === undefined;

          if (notValidAttribute) activityAttributesLanguageErrors.push(currentActivityId);
        });

        if (activityAttributesLanguageErrors.length === 0) dispatch(deleteActivityFromErrors(currentActivityId));
      })
      .catch((error) => {
        handleServiceError(error);
      })
      .finally(() => setIsActivityDialogOpen(false));
  };

  const deleteActivityFromProccess = () => {
    if (!processData) return;

    const swimlanes = { ...processData.swimlanes };
    const currentSwimlaneIndex = processData.swimlanes.findIndex((swimlane) => swimlane.id === swimlaneId);
    const currentSymbol = swimlanes[currentSwimlaneIndex].symbols.find((symbol) => symbol.id === id);

    if (!currentSymbol || !currentSymbol.activitySpecificationId) return;

    currentSymbol.activitySpecificationId = undefined;
  };

  const deleteActivity = (confirmationString?: string) => {
    if (!selectedProcessStep || !selectedProcessStep.activitySpecification || !confirmationString) return;

    deleteActivitySpecification({ id: Number(selectedProcessStep.activitySpecification.id), confirmationString })
      .then(() => {
        setActivityId(undefined);
        deleteActivityFromProccess();
        dispatch({
          type: DiagramActionTypes.SET_SELECTION,
          payload: [],
        });
        setSelectedProcessStep(undefined);
      })
      .catch((error) => {
        handleServiceError(error);
      })
      .finally(() => setIsActivityDialogOpen(false));
  };

  const checkDuplicates = (type: ObjectTypes, objectId?: number, temporaryText?: string): boolean => {
    return objectId
      ? !!defaultSelectedProcessStep?.objects?.find((ob) => ob.id === objectId && ob.subtype === objectType)
      : !!defaultSelectedProcessStep?.objects?.find((ob) => ob.temporaryText === temporaryText && ob.type === type);
  };

  const TYPE_TRANSLATIONS = {
    ROLE_SWIMLANE: 'ROLE',
    IT_SYSTEM_SWIMLANE: 'IT_SYSTEM',
    IO_SWIMLANE: 'IO_SWIMLANE',
  };

  const getTypeTranslations = (type: string) => {
    return (TYPE_TRANSLATIONS as { [key in string]: string })[type] || type;
  };

  const getMaxObjectsByType = (type: ObjectTypes) => {
    return (MAX_OBJECTS_PROCESS_STEP as { [key: string]: number })[type] || 50;
  };

  const isMaxObjects = (type: ObjectTypes): boolean => {
    const objectsBySelectedType = defaultSelectedProcessStep?.objects?.filter((obj: SymbolObject) => obj.type === type);
    if (objectsBySelectedType && objectsBySelectedType?.length >= getMaxObjectsByType(type)) {
      showAlert(t('swimlane.processStep.maxObjects', { objectX: t(getTypeTranslations(type)) }), t('warningText'));
      return true;
    }
    if (type === ObjectTypes.ROLE_SWIMLANE) {
      const objectsBySelectedTypeAndCategory = defaultSelectedProcessStep?.objects?.filter(
        (obj: SymbolObject) =>
          obj.type === type && obj.responsability === (LINK_RESPONSIBILITY_IDS as { [key: string]: number })[responsibility],
      );
      if (
        objectsBySelectedTypeAndCategory &&
        objectsBySelectedTypeAndCategory?.length >= MAX_OBJECTS_PROCESS_STEP_BY_ROLE_CATEGORY
      ) {
        showAlert(
          t('swimlane.processStep.responsability.maxObjects', { objectX: t(getTypeTranslations(type)) }),
          t('warningText'),
        );
        return true;
      }
    }

    return false;
  };

  const openSymbolObjects = () => {
    const isSymbolAlreadyOpen = !!symbolObjectsDisplayed?.find((symbolId) => symbolId === id);
    if (!isSymbolAlreadyOpen) {
      dispatch(setSymbolObjectsToDisplay([...symbolObjectsDisplayed, id]));
      setTimeout(updateSwimlanesConnectorsVertices); // eslint-disable-line @typescript-eslint/no-implied-eval
    }
  };

  const roleDialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !rolesToAdd.length || !responsibility,
      id: `add-real-role-submit-button`,
      handleClick: () => {
        setShowRoleDialog(false);
        setSearchText('');
        const selectedRoled = rolesResult.find((elem) => elem.id === +rolesToAdd[0]);
        setRolesToAdd([]);
        if (selectedRoled && selectedRoled.id) {
          if (checkDuplicates(ObjectTypes.ROLE_SWIMLANE, selectedRoled.id)) {
            showAlert(t('swimlane.processStep.duplicateObject.roleItSystem'), t('warningText'));
          } else if (!isMaxObjects(ObjectTypes.ROLE_SWIMLANE)) {
            addSymbolObject(
              id,
              selectedRoled.id,
              ObjectTypes.ROLE_SWIMLANE,
              0,
              selectedRoled.attributes,
              selectedRoled.approved,
              undefined,
              (LINK_RESPONSIBILITY_IDS as { [key: string]: number })[responsibility],
            );
            openSymbolObjects();
          }
        }
      },
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: 'real-role-cancel-button',
      handleClick: () => {
        setShowRoleDialog(false);
        setSearchText('');
      },
      key: 'cancel',
    },
  ];

  const roleColumns: TableColumn<ProcessRole>[] = [
    { id: 'name', style: styles.Name },
    { id: 'description', style: styles.Description },
    { id: 'tasks', style: styles.Tasks },
    { id: 'containedIn', style: styles.ContainedIn },
    { id: 'creation', style: styles.CreationDate },
    { id: 'status', style: styles.Status },
  ];

  const itSystemColumns: TableColumn<ProcessItSystem>[] = [
    { id: 'name', style: styles.Name },
    { id: 'description', style: styles.Description },
    { id: 'containedIn', style: styles.ContainedIn },
    { id: 'creation', style: styles.CreationDate },
    { id: 'status', style: styles.Status },
  ];

  const ioColumns: TableColumn<ProcessInputOutput>[] = useMemo(
    () => [
      { id: 'name', style: styles.Name },
      { id: 'description', style: styles.Description },
      processData?.isSwimlane
        ? { id: 'creator', style: styles.Responsible }
        : { id: 'responsiblePerson', style: styles.Responsible },
      { id: 'status', style: styles.Status },
    ],
    [processData?.isSwimlane],
  );

  const resetPaginationParams = () => {
    setPaginationPage(0);
    setPage(0);
    setSearchText('');
    handleSearchChange('');
  };

  const addIODialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !inputOutputToAdd.length,
      id: `submit-button`,
      handleClick: () => {
        setIsAddIODialogOpen(false);
        resetPaginationParams();
        const selectedIO = IOResult.find((elem) => +elem.id === +inputOutputToAdd[0]);
        setInputOutputToAdd([]);
        if (selectedIO) {
          if (checkDuplicates(ObjectTypes.IO_SWIMLANE, +selectedIO.id)) {
            showAlert(t('swimlane.processStep.duplicateObject.inputOutput'), t('warningText'));
          } else if (!isMaxObjects(ObjectTypes.IO_SWIMLANE)) {
            addSymbolObject(
              id,
              +selectedIO.id,
              ObjectTypes.IO_SWIMLANE,
              0,
              selectedIO.attributes,
              selectedIO.status !== 'NEW',
              objectType,
            );
            openSymbolObjects();
          }
        }
      },
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: `cancel-button`,
      handleClick: () => {
        setIsAddIODialogOpen(false);
        resetPaginationParams();
      },
      key: 'cancel',
    },
  ];

  const itSystemDialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled: !itSystemToAdd.length,
      id: `add-itSystem-submit-button`,
      handleClick: () => {
        setShowItSystemDialog(false);
        setSearchText('');
        const selectedItSystem = itSystemsResult.find((elem) => elem.id === +itSystemToAdd[0]);
        setItSystemToAdd([]);
        if (selectedItSystem && selectedItSystem.id) {
          if (checkDuplicates(ObjectTypes.IT_SYSTEM_SWIMLANE, selectedItSystem.id)) {
            showAlert(t('swimlane.processStep.duplicateObject.roleItSystem'), t('warningText'));
          } else if (!isMaxObjects(ObjectTypes.IT_SYSTEM_SWIMLANE)) {
            addSymbolObject(
              id,
              selectedItSystem.id,
              ObjectTypes.IT_SYSTEM_SWIMLANE,
              0,
              selectedItSystem.attributes,
              selectedItSystem.approved,
            );
            openSymbolObjects();
          }
        }
      },
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: 'itSystem-cancel-button',
      handleClick: () => {
        setShowItSystemDialog(false);
        setSearchText('');
      },
      key: 'cancel',
    },
  ];

  const handleActivityDialog = (type: ActivityActions, process?: Symbol | ProcessStepSymbol, confirmationValue?: string) => {
    switch (type) {
      case ActivityActions.DELETE:
        deleteActivity(confirmationValue);
        break;
      case ActivityActions.REDIRECTION:
        setIsActivityDialogOpen(false);
        if (process && (process as Symbol).activitySpecificationId) {
          openActivityDialog(process.id);
        }
        break;
      case ActivityActions.UPDATE:
        updateActivity((process as ProcessStepSymbol)?.activitySpecification);
        break;
      default:
        setIsActivityDialogOpen(false);
    }
  };

  const containerBounds = containerRef?.current?.getBoundingClientRect();
  return (
    <>
      {isActivityDialogOpen && selectedProcessStep && (
        <DialogActivity
          handleActivityDialog={handleActivityDialog}
          selectedSwimlane={selectedSwimlane}
          symbol={selectedProcessStep}
        />
      )}
      {!isPaletteItem && (
        <Selection
          actionButtons={selectionButtons}
          className={`${styles.Selection} ${processStepNumberError || hasErrors ? styles.Error : ''}`}
          symbolId={id}
        />
      )}
      <div
        className={`${styles.Container} ${isPaletteItem ? styles.isPaletteItem : styles.isElementItem}`}
        draggable={
          !processData?.isOnlyRead && editableSymbol !== id && tool !== Tool.CONNECT && !grabAndMove && !selectedConnectorId
        }
        onClick={() => isPaletteItem && toggleTool(Tool.ADD_SYMBOL)}
        onDoubleClick={() => {
          if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW) {
            dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: id });
          }
        }}
        onDragEnd={() => dispatch(movingSwimlaneSymbols(false))}
        onDragStart={onDragStart}
        onMouseLeave={() => {
          dispatch(setSwimlaneDisplayingAttributes(''));
          setShowAttributes(false);
        }}
        ref={containerRef}
      >
        {isPaletteItem ? (
          <div className={styles.Element}>
            <p>{t(PROCESS_STEP)}</p>
          </div>
        ) : (
          <>
            {isMenuOpen && (
              <SelectionPanel
                className={styles.SelectionPanel}
                close={() => setIsMenuOpen('')}
                options={objectsOptions}
                parentRef={containerRef}
                style={
                  containerBounds
                    ? { bottom: window.innerHeight - containerBounds.y + window.scrollY + PROCESS_STEP_PANEL_ADDED_HEIGHT }
                    : undefined
                }
              />
            )}
            {(showTemporaryRoleDialog || showTemporaryITSystemDialog) && (
              <DialogInput
                close={() => {
                  setShowTemporaryRoleDialog(false);
                  setShowTemporaryITSystemDialog(false);
                }}
                confirm={(name: string) => {
                  setShowTemporaryRoleDialog(false);
                  setShowTemporaryITSystemDialog(false);
                  if (
                    checkDuplicates(
                      showTemporaryRoleDialog ? ObjectTypes.ROLE_SWIMLANE : ObjectTypes.IT_SYSTEM_SWIMLANE,
                      undefined,
                      name,
                    )
                  ) {
                    showAlert(t('swimlane.processStep.duplicateObject.roleItSystem'), t('warningText'));
                  } else if (
                    !isMaxObjects(showTemporaryRoleDialog ? ObjectTypes.ROLE_SWIMLANE : ObjectTypes.IT_SYSTEM_SWIMLANE)
                  ) {
                    addSymbolTemporaryObject(
                      id,
                      name,
                      showTemporaryRoleDialog ? ObjectTypes.ROLE_SWIMLANE : ObjectTypes.IT_SYSTEM_SWIMLANE,
                      0,
                    );
                    openSymbolObjects();
                  }
                }}
                label={showTemporaryRoleDialog ? t('contextualMenu.TEMPORARY_ROLE') : t('contextualMenu.TEMPORARY_IT_SYSTEM')}
                title={t('addX', {
                  labelX: showTemporaryRoleDialog ? t('contextualMenu.TEMPORARY_ROLE') : t('contextualMenu.TEMPORARY_IT_SYSTEM'),
                })}
              />
            )}
            {showRoleDialog && (
              <DialogNEPOS
                dialog={{
                  title: t('setX', {
                    labelX: t('ROLE'),
                  }),
                  buttons: roleDialogButtons,
                }}
                extraClass="ProcessStepDialog"
              >
                <TableWithSearch
                  buttonText={t('RequestNewObject', {
                    objectType: t('ROLE'),
                  })}
                  columns={roleColumns}
                  getPage={(newPage) => fetchRoles(newPage, searchText)}
                  id={TablePrefix.ROLE}
                  isAsync
                  isLoading={availableRolesRows === undefined}
                  multiselect={false}
                  onCheck={setRolesToAdd}
                  onClick={() => {
                    setRequestNewObject(ObjectTypes.ROLE_SWIMLANE);
                    setShowRoleDialog(false);
                  }}
                  page={page}
                  pagination={Pagination.PAGES}
                  rows={availableRolesRows}
                  searching={(text: string) => {
                    setTotalPages(1);
                    fetchRoles(0, text);
                  }}
                  totalPages={totalPages}
                  variant={TableVariant.CHECKBOX}
                />
                <div className={styles.Dropdown}>
                  <DropdownNEPOS
                    code="processStep"
                    label={t('RESPONSIBILITY')}
                    onChange={(option: string) => {
                      setResponsibility(option);
                    }}
                    options={responsibilitiesOptions}
                    panelClassName={styles.DropdownPanel}
                    required
                    value={responsibility}
                  />
                </div>
              </DialogNEPOS>
            )}
            {showItSystemDialog && (
              <DialogNEPOS
                dialog={{
                  title: t('setX', {
                    labelX: t('IT_SYSTEM'),
                  }),
                  buttons: itSystemDialogButtons,
                }}
                extraClass="ProcessStepDialog"
              >
                <TableWithSearch
                  buttonText={t('RequestNewObject', {
                    objectType: t('IT_SYSTEM'),
                  })}
                  columns={itSystemColumns}
                  getPage={(newPage) => fetchItSystems(newPage, searchText)}
                  id={TablePrefix.IT_SYSTEM}
                  isAsync
                  isLoading={availableItSystemsRows === undefined}
                  multiselect={false}
                  onCheck={setItSystemToAdd}
                  onClick={() => {
                    setRequestNewObject(ObjectTypes.IT_SYSTEM_SWIMLANE);
                    setShowItSystemDialog(false);
                  }}
                  page={page}
                  pagination={Pagination.PAGES}
                  rows={availableItSystemsRows}
                  searching={(text: string) => {
                    setTotalPages(1);
                    fetchItSystems(0, text);
                  }}
                  totalPages={totalPages}
                  variant={TableVariant.CHECKBOX}
                />
              </DialogNEPOS>
            )}
            {isAddIODialogOpen && (
              <DialogNEPOS
                dialog={{
                  title: t('addX', {
                    labelX:
                      objectType === ObjectIOSubTypes.INPUT
                        ? t(`contextualMenu.${ObjectIOSubTypes.INPUT}`)
                        : t(`contextualMenu.${ObjectIOSubTypes.OUTPUT}`),
                  }),
                  buttons: addIODialogButtons,
                }}
                extraClass="ProcessStepDialog"
              >
                <TableWithSearch<ProcessInputOutput & { id: string }>
                  buttonText={t('catalog.createNewX', {
                    objectType: objectType === ObjectIOSubTypes.INPUT ? t('diagram.objects.input') : t('diagram.objects.output'),
                  })}
                  columns={ioColumns || []}
                  getPage={setPaginationPage}
                  id={TablePrefix.INPUT_OUTPUT}
                  isAsync
                  isLoading={isPaginationLoading || !results}
                  multiselect={false}
                  onCheck={setInputOutputToAdd}
                  onClick={() => {
                    setIsAddIODialogOpen(false);
                    setRequestNewObject(ObjectTypes.IO_SWIMLANE);
                  }}
                  page={paginatedPage}
                  pagination={Pagination.PAGES}
                  rows={isPaginationLoading || !results ? [] : results}
                  searching={handleSearchChange}
                  totalPages={totalPaginationPages}
                  variant={TableVariant.CHECKBOX}
                />
              </DialogNEPOS>
            )}
            {requestNewObject && (
              <DialogObjectCatalog
                close={() => {
                  setRequestNewObject(null);
                }}
                dialogType={
                  requestNewObject === ObjectTypes.IO_SWIMLANE
                    ? CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO
                    : CATALOG_OBJECT_ACTIONS.REQUEST
                }
                isLegacy={false}
                isNEPOS
                objectType={requestNewObject}
                onSubmit={(createdObject: ProcessCatalogObject) => {
                  if (!createdObject) return;
                  const newObject = {
                    ...createdObject,
                    subtype: createdObject.type === ObjectTypes.IO_SWIMLANE && objectType ? objectType : undefined,
                  };
                  if (newObject.type !== ObjectTypes.ROLE_SWIMLANE) {
                    addSymbolObject(
                      id,
                      Number(newObject.id),
                      newObject.type as ObjectTypes,
                      0,
                      newObject.attributes,
                      false,
                      newObject.subtype,
                    );
                  } else setShowRoleDialog(true);
                }}
                parentCallback={() => {
                  requestNewObjectParentCallback(requestNewObject);
                  setRequestNewObject(null);
                }}
                responseNeeded
              />
            )}
            <div
              className={`${styles.Element} ${processStepNumberError || hasErrors ? styles.Error : ''}`}
              onClick={(e) => {
                handleSelect(id, e);
              }}
            >
              <ConnectorPorts
                portStyles={{
                  rightPortClass: styles.RightPort,
                  leftPortClass: styles.LeftPort,
                }}
                symbolId={id}
              />
              {editableSymbol === id ? (
                <div className={styles.SymbolNameInput} ref={symbolNameInputRef} style={{ fontSize: fontSize || 'inherit' }}>
                  <textarea
                    onChange={(event) => {
                      onNameChange(id, event.target.value, textAreaRef.current);
                    }}
                    placeholder={t('diagram.objects.defaultName')}
                    ref={textAreaRef}
                    rows={1}
                    value={attributes ? attributes[diagramLanguage]?.OBJECT_NAME : ''}
                  />
                </div>
              ) : (
                <TooltipComponent
                  content={attributes?.[diagramLanguage]?.OBJECT_NAME || ''}
                  cssClass={`mbc-tooltip nepos-tooltip ${attributes?.[diagramLanguage]?.OBJECT_NAME ? '-' : 'display-none'}`}
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  <p className={styles.SymbolName} style={{ fontSize: fontSize || 'inherit' }}>
                    {attributes?.[diagramLanguage]?.OBJECT_NAME || t('diagram.objects.defaultName')}
                  </p>
                </TooltipComponent>
              )}
              {!isPaletteItem && (
                <div className={`${styles.WrapperButtons}  ${activityId ? '' : styles.alignChilds}`}>
                  {activityId && (
                    <RoundButton
                      className={hasActivityError ? styles.ErrorActivity : ''}
                      icon="di icon-klemmbrett-aufgabe-liste"
                      id="symbol-activity-specification"
                      onClick={(event) => {
                        event.stopPropagation();
                        createActivity();
                      }}
                    />
                  )}
                  {attributes && attributes[diagramLanguage]?.LINKAGE && (
                    <a href={attributes[diagramLanguage]?.LINKAGE?.toString()} rel="noopener noreferrer" target="_blank">
                      <RoundButton
                        className={styles.Link}
                        icon="di icon-link-extern"
                        id={`processStep-link-${id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    </a>
                  )}
                  {!processData?.isOnlyRead &&
                    !processData?.isPublishedVersion &&
                    processData?.status !== ProcessStatus.WORKFLOW &&
                    diagramLink && (
                      <RoundButton
                        icon="di icon-unterebene-stapel"
                        id="symbol-diagram-link"
                        onClick={(event) => {
                          event.stopPropagation();
                          history.push(`/diagram/${diagramLink.id}`);
                        }}
                      />
                    )}
                  {(processData?.isOnlyRead ||
                    processData?.isPublishedVersion ||
                    processData?.status === ProcessStatus.WORKFLOW) && (
                    <RoundButton
                      className={styles.SymbolInfo}
                      icon="di icon-information"
                      id={`symbol-diagram-info-ico-${id}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(setSwimlaneDisplayingAttributes(swimlaneId));
                        setShowAttributes((prv) => !prv);
                      }}
                    />
                  )}
                  {showAttributes && attributes && (
                    <SymbolAttributes
                      attributes={attributes}
                      id={id}
                      symbolType={SymbolTypes.PROCESS_STEP}
                      top={6.2}
                      width={15}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!isPaletteItem && symbolObjectsDisplayed?.find((idSymbol: string) => idSymbol === id) && (
        <div className={styles.Objects}>
          {objects
            ?.sort((a, b) => {
              if (a.meta.pos > b.meta.pos) return 1;
              if (a.meta.pos < b.meta.pos) return -1;
              return 0;
            })
            .map(
              (object) =>
                filteredObjects(object) && (
                  <ProcessStepObject
                    key={object.id ? `${object.id}${object.subtype}` : object.temporaryText}
                    object={object}
                    onDelete={() => handleDeleteClick(object)}
                    swimlaneId={swimlaneId}
                    swimlaneRoleId={selectedSwimlane?.role?.id || undefined}
                  />
                ),
            )}
        </div>
      )}
    </>
  );
};

export default ProcessStep;
