import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DIAGRAM, SWIMLANE, SWIMLANE_DIAGRAM } from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useFormTypes from 'hooks/useFormTypes';
import NEPOSTabWrapper from 'pages/NEPOS/NEPOSTabWrapper';
import { Language } from 'types/config';
import { AttributeCode } from 'types/forms';

import styles from './ProcessAttributes.module.scss';

type ProcessAttributesProps = {
  handleClose: () => void;
};

const ProcessAttributes = (props: ProcessAttributesProps) => {
  const { handleClose } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const { dispatch, processData, diagramLanguage } = useDiagramContext();
  const { t, i18n } = useTranslation();
  const { getFormTypesCode } = useFormTypes();
  const { isFreezed } = useFeatureFlags();

  const formCode = getFormTypesCode({
    status: processData?.status,
    type: DIAGRAM,
    variant: processData?.type,
  });

  const { fields, values } = useBackendForm({
    formCode,
    initialValues: processData?.attributes,
    catalog: processData?.catalog,
    usesDiagramTranslation: true,
  });

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length) return;
    setLoading(false);
  }, [fields, values]);

  useEffect(() => {
    dispatch({ type: DiagramActionTypes.SET_PROCESS_ATTRIBUTES, payload: values });
  }, [dispatch, values]);

  const fieldSeparator = processData?.type !== SWIMLANE.toUpperCase() ? 3 : 5;
  const piAttributes = fields[diagramLanguage]?.slice(0, fieldSeparator);
  const eiAttributes =
    processData?.type !== SWIMLANE.toUpperCase()
      ? fields[diagramLanguage]?.slice(fieldSeparator)
      : fields[diagramLanguage]?.slice(fieldSeparator, 12);
  const piLabels = fields[i18n.language as Language]?.slice(0, fieldSeparator);
  const eiLabels =
    processData?.type !== SWIMLANE.toUpperCase()
      ? fields[i18n.language as Language]?.slice(fieldSeparator)
      : fields[i18n.language as Language]?.slice(fieldSeparator, 12);
  const chancesAttributes = processData?.type === SWIMLANE.toUpperCase() ? fields[i18n.language as Language]?.slice(12) : null;
  const disabled = (fieldProps) => isFreezed || processData?.isOnlyRead || fieldProps.disabled;
  return (
    <NEPOSTabWrapper handleClose={handleClose}>
      {!loading && (
        <>
          <h4>
            <i className="di icon-information" />
            {t('processInformation')}
          </h4>
          <section className={styles.Form}>
            {piAttributes?.map(({ Component, ...fieldProps }, index) => {
              const label =
                fieldProps.code === AttributeCode.PROCESS_RESPONSIBLE && processData?.isSwimlane
                  ? t(`attributes.${SWIMLANE_DIAGRAM}.${AttributeCode.PROCESS_RESPONSIBLE}.name`)
                  : piLabels?.[index].label;
              return <Component {...fieldProps} disabled={disabled(fieldProps)} label={label} />;
            })}
          </section>
          <h4>
            <i className="di icon-datei-text" />
            {t('editorialInformation')}
          </h4>
          <section className={styles.Form}>
            {eiAttributes?.map(({ Component, ...fieldProps }, index) => (
              <Component {...fieldProps} disabled={disabled(fieldProps)} label={eiLabels?.[index].label} />
            ))}
          </section>
          {chancesAttributes && (
            <>
              <h4>
                <i className="di icon-diagramm-donut" />
                {t('chances&risks')}
              </h4>
              <section className={styles.Form}>
                {chancesAttributes?.map(({ Component, ...fieldProps }) => (
                  <Component {...fieldProps} disabled={disabled(fieldProps)} />
                ))}
              </section>
            </>
          )}
        </>
      )}
    </NEPOSTabWrapper>
  );
};

export default ProcessAttributes;
