import React from 'react';

import { BUTTON_SECONDARY, BUTTON_SECONDARY_LEFT } from 'assets/constants/constants';
import Button from 'components/UI/Button/Button';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';

import styles from './DialogFooter.module.scss';

const DialogFooter = ({ buttons, extraClass = '', isLegacyStyle = false }) => {
  const Component = extraClass && !isLegacyStyle ? ButtonNEPOS : Button;

  return (
    <div className={`${styles.Container} ${extraClass}`} data-testid="DialogFooter">
      {buttons?.map(({ content, ...props }) =>
        props.buttonStyle === BUTTON_SECONDARY_LEFT ? (
          <div className={styles.Left} key={`${props.id}_button`}>
            <Component className={styles[props.buttonStyle]} isSecondary {...props}>
              {content}
            </Component>
          </div>
        ) : (
          <Component
            className={styles[props.buttonStyle]}
            isSecondary={props.buttonStyle === BUTTON_SECONDARY}
            key={`${props.id}_button`}
            {...props}
          >
            {content}
          </Component>
        ),
      )}
    </div>
  );
};

export default DialogFooter;
