import React from 'react';

import { withTranslation } from 'react-i18next';

import * as Utils from 'assets/js/Utils';
import * as Config from 'pages/MyTasks/Config';

import Card from './Card';
import styles from './Card.module.scss';

const Task = ({ t, loading, card, cardType, handleClick, id, language }) => {
  let date = card.finalDate;
  let dateText = `${t('card.deadline')}:`;
  let stateName = t(`tasks.type.${card.type}`);
  const statusName = `${t(`recommendations.type.${card.type}`)}`;

  if (card.status === 'DONE') {
    date = card.lastModificationDate;
    dateText = `${t('card.closeDate')}:`;
  } else if (card.type.includes(Config.RECOMMENDED)) {
    date = card.creationDate;
    dateText = `${t('card.recommendedDate')}:`;
    stateName = `${t('recommendations.name')}`;
  }

  const cardDate =
    date !== 'null' ? (
      <section>
        <span className={styles.Label}>{dateText}</span>
        <span>{Utils.getFormattedDate(date)}</span>
      </section>
    ) : (
      ''
    );
  const content = <div className={styles.Content}>{cardDate}</div>;
  const state = <div className={styles.TaskState}>{stateName}</div>;
  const status = card.type.includes(Config.RECOMMENDED) ? <div className={styles.TaskStatus}>{statusName}</div> : '';
  const title = JSON.parse(card.title)[language].TITLE;

  return (
    <Card
      card={card}
      cardType={cardType}
      content={content}
      handleClick={handleClick}
      id={id}
      loading={loading}
      state={state}
      status={status}
      title={title}
    />
  );
};

export default withTranslation('common')(Task);
