import React, { useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import * as Constants from 'assets/constants/constants';
import { ErrorContext } from 'contexts/Error/ErrorContext';

const useError = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const context = useContext(ErrorContext);

  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }

  const { setDialogError } = context;

  const getErrorMessage = useCallback(
    (errorResponse, error, shouldNotShowCode) => {
      const errorCode = errorResponse?.data?.code || errorResponse?.status;
      let msgError = errorResponse ? (
        <>
          {t(`errors.${errorCode}`)}
          <br />
          <br />
          {!shouldNotShowCode && errorCode}
        </>
      ) : (
        ''
      );

      if (error.config?.url.includes('/recycle-bin/restore') && errorResponse?.status === 500) {
        msgError = t('errors.500_RECYCLE_BIN');
      }

      if (location.pathname.includes('/diagram/') && errorResponse?.status === 403) {
        msgError = t('errors.403_DIAGRAM');
      }

      if (
        errorCode === Constants.ERROR_DESIGN_008 &&
        errorResponse?.status === 500 &&
        errorResponse?.config?.url?.endsWith('/diagram') &&
        location.pathname.includes('/diagram/')
      ) {
        msgError = t('errors.emptyDiagram');
      }

      if (
        errorCode === Constants.ERROR_DESIGN_008 &&
        errorResponse?.status === 500 &&
        errorResponse?.config?.url?.endsWith('/diagram') &&
        location.pathname.includes('/published/')
      ) {
        msgError = t('errors.emptyPublished');
      }

      if (Constants.SET_HISTORICAL_ERRORS.includes(errorCode)) {
        msgError = '';
        const msgErrorChildrenList = (listErrorCode, childrenList) => (
          <>
            {t(`errors.${listErrorCode}`)}
            <br />
            {childrenList.map((item) => (
              <>
                <br />
                <a className="diagram-link" href={`/diagram/${item.id}`} target="blank">
                  {JSON.parse(item.nameMap)[i18n.language.toUpperCase()].PROCESS_NAME}
                </a>
              </>
            ))}
          </>
        );

        if (error.response.data.publishedChildrenList?.length > 0) {
          msgError = msgErrorChildrenList(Constants.ERROR_WORKFLOW_017, error.response.data.publishedChildrenList);
        }

        if (error.response.data.unpublishedChildrenList?.length > 0) {
          msgError = `
          ${
            msgError ? (
              <>
                {msgError}
                <br />
                <br />
                <br />
              </>
            ) : (
              ''
            )
          }
          ${msgErrorChildrenList(Constants.ERROR_WORKFLOW_018, error.response.data.unpublishedChildrenList)}
        `;
        }

        if (Constants.ERROR_DESIGN_012 === errorCode) {
          msgError = (
            <>
              {t(`errors.${Constants.ERROR_DESIGN_012}`)}
              <br />
              {errorResponse.data.diagramInUseList.map((item) => (
                <>
                  <br />
                  <a
                    className="diagram-link"
                    href={`/${item.status === Constants.STATUS.SANDBOX ? 'sandbox' : 'diagram'}/${item.id}`}
                    target="blank"
                  >
                    {JSON.parse(item.nameMap)[i18n.language.toUpperCase()].PROCESS_NAME}
                  </a>
                </>
              ))}
            </>
          );
        }
      }

      return msgError || error.message;
    },
    [i18n.language, location.pathname, t],
  );

  const handleServiceError = useCallback(
    (err, { shouldRefreshPage = false, shouldRedirectToDashboard = false, shouldNotShowCode = false } = {}) => {
      const error = err.toJSON ? err.toJSON() : err;
      const message = getErrorMessage(err.response, error, shouldNotShowCode);

      let closeClick;

      if (shouldRefreshPage) {
        closeClick = () => history.go(0);
      } else if (shouldRedirectToDashboard || err.response?.data?.code === 'DESIGN_008') {
        closeClick = () => history.push('/dashboard');
      }

      console.error(`Error ${err.response?.data?.code || ''}: ${err.response?.data?.description || message}`); // eslint-disable-line no-console
      setDialogError({
        content: message,
        handleConfirm: closeClick,
        title: error.name,
      });
    },
    [getErrorMessage, history, setDialogError],
  );

  const showAlert = (content, title = '', handleConfirm = () => null) => setDialogError({ title, content, handleConfirm });

  return {
    handleServiceError,
    showAlert,
  };
};

export default useError;
