import React from 'react';

import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Constants from 'assets/constants/constants';
import { isTaskValidClosedWorkflow } from 'assets/js/Utils';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import Page from 'components/Page/Page';
import SideBar from 'components/SideBar/SideBar';
import TitleBar from 'components/TitleBar/TitleBar';
import Card from 'components/UI/Card/Card';
import Process from 'components/UI/Card/Process';
import Task from 'components/UI/Card/Task';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import Spinner from 'components/UI/Spinner/Spinner';
import * as Config from 'pages/MyTasks/Config';
import services from 'services/dashboardServices';
import titleService from 'services/titleService';
import userService from 'services/userService';
import { getProcesses } from 'services/workflowServices';

import styles from './Dashboard.module.scss';

const MAX_TILES = 6;

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      totalTaskCount: {},
      totalProcessCount: {},
      loadingTasks: true,
      loadingTaskID: null,
      loadingProcesses: {
        [Constants.PROCESS_GROUPS.MY_FAVORITES]: true,
        [Constants.PROCESS_GROUPS.MY_PROCESSES]: true,
      },
      taskTabSelected: Constants.TASK_GROUPS.MY_TASKS,
      processTabSelected: Constants.PROCESS_GROUPS.MY_FAVORITES,
      isManageObjectDialogVisible: false,
      isConfirmRejectObjectDialogVisible: false,
      showTextInputDialog: false,
      objectType: '',
      taskSelected: {},
      objectTask: {},
      rejectReason: '',
      language: props.i18n.language.toUpperCase(),
      users: [],
      catalogUsers: [],
      isFreezed: this.props.isFreezed,
    };

    this.form = {};
    this.cache = {};
    this.responsibleAreas = [];
  }

  componentDidMount() {
    titleService.updatePageTitle(this.props.t('Home'));
    this.fetchTasks();
    this.fetchProcesses();
  }

  getTasks() {
    return this.state[this.state.taskTabSelected];
  }

  getTaskTabSelected(tab) {
    return this.state.taskTabSelected === tab ? styles.TabSelected : '';
  }

  getProcessTabSelected(tab) {
    return this.state.processTabSelected === tab ? styles.TabSelected : '';
  }

  getTotalTaskCount = (response) => ({
    closedTasks: response.data.closedTasks,
    groupTasks: response.data.groupTasks,
    myTasks: response.data.myTasks,
  });

  fetchTasks() {
    this.setState({ loadingTasks: true });
    services
      .getTasksByGroup({ group: Constants.TASK_GROUPS.MY_TASKS })
      .then((response) => {
        this.setState({
          [Constants.TASK_GROUPS.MY_TASKS]: response.data.results,
          totalTaskCount: this.getTotalTaskCount(response),
          loadingTasks: false,
          users: response.data.allUsers,
          diagramStatus: response.data.diagramStatus,
          workflowStatus: response.data.workflowStatus,
        });
        if (this.state.isManageObjectDialogVisible) {
          this.setState({ loadingTasks: false });
        }
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  fetchProcesses() {
    userService
      .getFavorites()
      .then((response) => {
        this.setState({
          [Constants.PROCESS_GROUPS.MY_FAVORITES]: response.data.results,
          totalProcessCount: {
            ...this.state.totalProcessCount,
            [Constants.PROCESS_GROUPS.MY_FAVORITES]: response.data.totalResults,
          },
          loadingProcesses: { ...this.state.loadingProcesses, [Constants.PROCESS_GROUPS.MY_FAVORITES]: false },
        });
      })
      .catch((err) => {
        userService.handleServiceError(err);
      });

    getProcesses({ pageNumber: 0, pageSize: 6 })
      .then((response) => {
        this.setState({
          [Constants.PROCESS_GROUPS.MY_PROCESSES]: response.data.processes,
          totalProcessCount: {
            ...this.state.totalProcessCount,
            [Constants.PROCESS_GROUPS.MY_PROCESSES]: response.data.totalProcesses,
          },
          loadingProcesses: { ...this.state.loadingProcesses, [Constants.PROCESS_GROUPS.MY_PROCESSES]: false },
        });
      })
      .catch((err) => {
        userService.handleServiceError(err);
      });
  }

  selectTaskTab(tab) {
    if (!this.state.loadingTasks) {
      this.setState(
        {
          loadingTasks: !this.state[tab],
          taskTabSelected: tab,
        },
        () => {
          if (!this.state[tab]) {
            services.getTasksByGroup({ group: tab }).then((response) => {
              const { results, diagramStatus, allUsers } = response.data;
              results
                .filter((element) => isTaskValidClosedWorkflow(element))
                .forEach((element) => {
                  diagramStatus[element.idWorkflow] = 'CLOSED_WORKFLOW';
                });

              this.setState({
                loadingTasks: false,
                [tab]: results,
                totalTaskCount: this.getTotalTaskCount(response),
                users: allUsers,
                diagramStatus,
              });
            });
          }
        },
      );
    }
  }

  manageTask(task) {
    const { type: taskTypeCode, status: taskStatusCode } = task;
    const isClosedTask = Constants.CLOSED_TASK_TYPES.includes(taskStatusCode);
    const taskIsValidClosedWorflow =
      isClosedTask && isTaskValidClosedWorkflow({ ...task, workflowStatus: this.state.diagramStatus });

    if (taskTypeCode === Constants.DISABLED_TASK_TYPES.BLOCKED) return;
    if (isClosedTask) return this.redirectToDiagram(task.idWorkflow, taskIsValidClosedWorflow);

    if (taskTypeCode.includes(Config.RECOMMENDED)) {
      this.redirectToRecommendation(task.link);
    } else if (
      taskTypeCode === Config.DESIGN_DIAGRAM ||
      taskTypeCode === Config.WORKFLOW_APPROVAL ||
      taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL ||
      (taskStatusCode === Constants.ACCEPTED && taskTypeCode === Config.WORKFLOW_APPROVAL_GROUP) ||
      (taskStatusCode === Constants.ACCEPTED && taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL_GROUP)
    ) {
      return taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL || taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL_GROUP
        ? this.redirectToDataset(task)
        : this.redirectToDiagram(task.link);
    } else if (taskTypeCode === Config.REQUIREMENT_REQUEST || taskTypeCode === Config.REQUIREMENT_WORKFLOW) {
      this.props.history.push(`/requirements/${task.link}`);
    } else if (this.state.isFreezed) {
      switch (taskTypeCode) {
        case taskTypeCode.includes(Config.DATASET_WORKFLOW_APPROVAL):
          this.redirectToDataset(task);
          break;
        case Config.REQUIREMENT_WORKFLOW:
          this.props.history.push(`/requirements/${task.link}`);
          break;
        default:
          this.redirectToDiagram(task.link);
          break;
      }
    } else {
      this.setState({ loadingTaskID: task.id }, () => {
        Config.acceptTask(task, this.responsibleAreas, this.state.taskTabSelected)
          .then((response) => {
            if (!response) {
              return;
            }

            this.setState({ catalogUsers: response.infoTask?.catalog });

            if (
              taskTypeCode === Config.DESIGN_IT_SYSTEM ||
              taskTypeCode === Config.DESIGN_ROLE ||
              taskTypeCode === Config.DESIGN_IO_SIPOC ||
              taskTypeCode === Config.DESIGN_IT_SYSTEM_SWIMLANE ||
              taskTypeCode === Config.DESIGN_ROLE_SWIMLANE
            ) {
              this.responsibleAreas = response.responsibleAreas;
              let objectType = Constants.IO_SIPOC;

              if (response.isItSystem) {
                objectType =
                  taskTypeCode === Config.DESIGN_IT_SYSTEM_SWIMLANE ? Constants.IT_SYSTEM_SWIMLANE : Constants.IT_SYSTEM;
              } else if (taskTypeCode.includes(Constants.ROLE)) {
                objectType = taskTypeCode === Config.DESIGN_ROLE ? Constants.ROLE : Constants.ROLE_SWIMLANE;
              }

              this.setState({
                objectTask: {
                  ...response.infoTask,
                  requestedByCommonName: Config.NEPOS_OBJECTS_TASKS.includes(taskTypeCode)
                    ? this.state.users.find((user) => user.code === response.infoTask.attributes.NOT_TRANSLATABLE.REQUESTER)
                        .commonName
                    : this.state.users.find((user) => user.code === response.infoTask.requestedBy).commonName,
                },
                objectType,
                isManageObjectDialogVisible: true,
                taskSelected: response.taskSelected,
              });
            } else {
              this.setState({ myTasks: response });
              if (taskTypeCode.includes(Config.DATASET_WORKFLOW_APPROVAL)) {
                this.redirectToDataset(task);
              } else if (taskTypeCode === Config.REQUIREMENT_WORKFLOW) {
                this.props.history.push(`/requirements/${task.link}`);
              } else {
                this.redirectToDiagram(task.link);
              }
            }
          })
          .finally(() => {
            this.setState({ loadingTaskID: null });
          });
      });
    }
  }

  redirectToDiagram(taskLink, isClosedWF = false) {
    if (taskLink) {
      const diagramID = isClosedWF ? taskLink : taskLink.split('/').pop();
      const diagramStatusType = this.state.diagramStatus[taskLink];
      const target = isClosedWF ? 'closed-workflow' : Constants.DIAGRAM_STATUS_REDIRECT[diagramStatusType];
      if (diagramStatusType && target) this.props.history.push(`/${target}/${diagramID}`);
    }
  }

  redirectToDataset(task) {
    if (task) {
      this.props.history.push(`/dataset/${task.link}`);
    }
  }

  redirectToRecommendation(taskLink) {
    if (taskLink) {
      const diagramID = taskLink.split('/').pop();
      this.props.history.push(`/recommendation/${diagramID}`);
    }
  }

  removeFavorite(event, fav) {
    event.stopPropagation();

    const removeDialog = {
      title: this.props.t('removeFavorite'),
      message: this.props.t('infoRemoveFavorite', { diagram: fav.processName }),
      textCancel: this.props.t('cancel'),
      textOk: this.props.t('removeFavorite'),
      okClick: () => this.confirmRemoveFavorite(fav),
    };
    this.dialog = Dialog.showConfirm(removeDialog);
  }

  confirmRemoveFavorite({ id, idProcess }) {
    this.dialog.hide();
    let favorites = this.state.favorites ? [...this.state.favorites] : [];
    favorites = favorites.filter((fav) => fav.id !== id);

    userService
      .removeFavorite(idProcess)
      .then(() => {
        this.setState({ favorites });
        this.props.history.go(0);
      })
      .catch((err) => userService.handleServiceError(err));
  }

  renderTasks() {
    const tasks = this.state[this.state.taskTabSelected];
    return tasks.slice(0, MAX_TILES).map((task) => {
      let cardType = task.type === Config.DESIGN_IT_SYSTEM || task.type === Config.DESIGN_ROLE ? 'approvalCard' : 'task';

      if (task.type.includes(Config.RECOMMENDED)) {
        cardType = 'recommendedTask';
      }

      return (
        <Task
          card={task}
          cardType={cardType}
          handleClick={() => this.manageTask(task)}
          id={`task${task.id}`}
          key={task.id}
          language={this.state.language}
          loading={this.state.loadingTaskID === task.id}
        />
      );
    });
  }

  renderFavorites() {
    return this.state[Constants.PROCESS_GROUPS.MY_FAVORITES]
      .slice(0, MAX_TILES)
      .map((favourite) => (
        <Card
          card={favourite}
          cardType="fav"
          handleClick={() => this.props.history.push(`/published/${favourite.id}`)}
          iconClick={(event) => this.removeFavorite(event, favourite)}
          id={`fav-${favourite.id}`}
          key={favourite.id}
          title={favourite.processName}
        />
      ));
  }

  renderProcesses() {
    return this.state[Constants.PROCESS_GROUPS.MY_PROCESSES]
      .slice(0, MAX_TILES)
      .map((process) => <Process key={process.id} {...process} />);
  }

  renderTasksContent() {
    return this.state.totalTaskCount[this.state.taskTabSelected] > 0 ? (
      this.renderTasks()
    ) : (
      <div className={styles.TasksMsg}>
        <span>{this.props.t('dashboard.noTasks')}</span>
      </div>
    );
  }

  renderProcessesContent() {
    const isFavoritesTabSelected = this.state.processTabSelected === Constants.PROCESS_GROUPS.MY_FAVORITES;

    if (this.state.loadingProcesses[this.state.processTabSelected]) {
      return <Spinner defaultSpinner isVisible />;
    }

    if (this.state.totalProcessCount[this.state.processTabSelected] === 0) {
      const placeholderText = isFavoritesTabSelected ? 'noFavorites' : 'noProcesses';

      return (
        <div className={styles.TasksMsg}>
          <span>{this.props.t(`dashboard.${placeholderText}`)}</span>
        </div>
      );
    }

    return isFavoritesTabSelected ? this.renderFavorites() : this.renderProcesses();
  }

  render() {
    const { t } = this.props;

    return (
      <Page>
        <div className={styles.PageContent}>
          <div className={styles.Dashboard}>
            <div className={styles.Block} id="tasks">
              <div className={styles.MyTasksHeader} id="tasksHeader">
                <div className={styles.TaskTabs}>
                  <div
                    className={`${styles.TaskTab} ${this.getTaskTabSelected(Constants.TASK_GROUPS.MY_TASKS)}`}
                    id="tab-my-tasks"
                    onClick={() => this.selectTaskTab(Constants.TASK_GROUPS.MY_TASKS)}
                  >
                    <span>{t('tasks.myTasks')}</span>
                    <span className={styles.TaskNumber}>{this.state.totalTaskCount[Constants.TASK_GROUPS.MY_TASKS]}</span>
                  </div>
                  <div
                    className={`${styles.TaskTab} ${this.getTaskTabSelected(Constants.TASK_GROUPS.GROUP_TASKS)}`}
                    id="tab-group-tasks"
                    onClick={() => this.selectTaskTab(Constants.TASK_GROUPS.GROUP_TASKS)}
                  >
                    <span>{t('tasks.groupTasks')}</span>
                    <span className={styles.TaskNumber}>{this.state.totalTaskCount[Constants.TASK_GROUPS.GROUP_TASKS]}</span>
                  </div>
                  <div
                    className={`${styles.TaskTab} ${this.getTaskTabSelected(Constants.TASK_GROUPS.CLOSED_TASKS)}`}
                    id="tab-closed-tasks"
                    onClick={() => this.selectTaskTab(Constants.TASK_GROUPS.CLOSED_TASKS)}
                  >
                    <span>{t('tasks.closedTasks')}</span>
                    <span className={styles.TaskNumber}>{this.state.totalTaskCount[Constants.TASK_GROUPS.CLOSED_TASKS]}</span>
                  </div>
                </div>
                {this.state.loadingTasks ? (
                  <span className={`${styles.ShowAll} ${styles.Disabled}`} id="showAll-tasks-disabled">
                    {t('showAll')}
                  </span>
                ) : (
                  <Link
                    className={styles.ShowAll}
                    id="showAll-tasks"
                    to={{ pathname: '/my-tasks', state: { tabSelected: this.state.taskTabSelected } }}
                  >
                    {t('showAll')}
                  </Link>
                )}
              </div>
              <div className={`${styles.TasksContent} ${this.state.loadingTasks ? styles.JustifyCenter : ''}`}>
                {this.state.loadingTasks ? <Spinner defaultSpinner isVisible /> : this.renderTasksContent()}
              </div>
            </div>
            <div className={styles.Block} id="favorites">
              <div className={styles.MyTasksHeader} id="favoritesHeader">
                <div className={styles.TaskTabs}>
                  <div
                    className={`${styles.TaskTab} ${this.getProcessTabSelected(Constants.PROCESS_GROUPS.MY_FAVORITES)}`}
                    id="tab-my-favorites"
                    onClick={() => this.setState({ processTabSelected: Constants.PROCESS_GROUPS.MY_FAVORITES })}
                  >
                    <span>{t('processes.myFavorites')}</span>
                    <span className={styles.TaskNumber}>
                      {this.state.totalProcessCount[Constants.PROCESS_GROUPS.MY_FAVORITES]}
                    </span>
                  </div>
                  <div
                    className={`${styles.TaskTab} ${this.getProcessTabSelected(Constants.PROCESS_GROUPS.MY_PROCESSES)}`}
                    id="tab-my-processes"
                    onClick={() => this.setState({ processTabSelected: Constants.PROCESS_GROUPS.MY_PROCESSES })}
                  >
                    <span>{t('processes.myProcesses')}</span>
                    <span className={styles.TaskNumber}>
                      {this.state.totalProcessCount[Constants.PROCESS_GROUPS.MY_PROCESSES]}
                    </span>
                  </div>
                </div>
                {this.state.loadingProcesses[this.state.processTabSelected] ? (
                  <span className={`${styles.ShowAll} ${styles.Disabled}`} id="showAll-processes-disabled">
                    {t('showAll')}
                  </span>
                ) : (
                  <Link
                    className={styles.ShowAll}
                    id="showAll-processes"
                    to={{ pathname: '/processes', state: { tabSelected: this.state.processTabSelected } }}
                  >
                    {t('showAll')}
                  </Link>
                )}
              </div>
              <div
                className={`${styles.TasksContent} ${
                  this.state.loadingProcesses[this.state.processTabSelected] ? styles.JustifyCenter : ''
                }`}
              >
                {this.renderProcessesContent()}
              </div>
            </div>
          </div>
          <SideBar />
        </div>
        {this.state.isManageObjectDialogVisible && (
          <DialogObjectCatalog
            catalogUsers={this.state.catalogUsers}
            close={() => {
              this.setState({ isManageObjectDialogVisible: false });
            }}
            dialogType={Constants.CATALOG_OBJECT_ACTIONS.MANAGE}
            idTask={this.state.taskSelected.id}
            isLegacy={!Constants.NEPOS_OBJECTS.includes(this.state.objectType)}
            loadingTasks={this.state.loadingTasks}
            object={this.state.objectTask}
            objectType={this.state.objectType}
            onSubmit={() => {
              this.fetchTasks();
              this.setState({
                [this.state.taskTabSelected]: [...this.state[this.state.taskTabSelected]].filter(
                  (task) => task.id !== this.state.taskSelected.id,
                ),
              });
            }}
          />
        )}
        <div id="dialog" />
      </Page>
    );
  }
}

export default withTranslation('common')(Dashboard);
