import { VAN } from 'assets/constants/constants';
import { getCurrentPlant } from 'assets/js/serviceUtils';

import mbcMapImg from '../../assets/images/MBC_MAP_logo.png';
import mbvMapImg from '../../assets/images/MBV_MAP_logo.png';
import styles from './Sundown.module.scss';

const Sundown = () => {
  const mbcMapLogo = getCurrentPlant() === VAN ? mbvMapImg : mbcMapImg;

  return (
    <div className={styles.sundown}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img alt="MBC MAP Logo" src={mbcMapLogo} /> <br />
        </div>
        <div className={styles.header}>
          <br />
        </div>
        <div className={styles.highlight}>
          <p>The migration has been completed from MBCMAP to Signavio! Here you can find the links to Signavio:</p>
          <br />
          <p>Die Migration von MBC MAP zu Signavio ist beendet! Hier findet Ihr die Links zu Signavio:</p>
        </div>

        <table>
          <thead>
            <tr>
              <th>Repository</th>
              <th>Workspace</th>
              <th>Owner / Deputy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>End-to-End</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/6380f364059a4ea3812c11654b02f309">
                  End-to-End-Entry Point
                </a>
              </td>
              <td>sandro.socci@mercedes-benz.com / maria.droeghoff@mercedes-benz.de</td>
            </tr>
            <tr>
              <td>End-to-End &#40;RD&#41;</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/model/aa5d65fe6f1c40bbb8c56ed8695368b6">
                  K1 End-to-End Entry Point &#40;RD&#41;
                </a>
              </td>
              <td>jens.bromme@mercedes-benz.com / steffen.frisenborg@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Central &#40;RD&#41;</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/ccd890ba6ba54addb45b33665a3ed972">
                  Mercedes-Benz Cars - QMS &#40;RD&#41;
                </a>
              </td>
              <td>jens.bromme@mercedes-benz.com / steffen.frisenborg@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Central</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/2f149e434e1f43d1b6561df26ad48256">
                  Mercedes-Benz Cars - QMS
                </a>
              </td>
              <td>juergen.kloos@mercedes-benz.com / hannah.mueller@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>VAN</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/d4d10e095c864ea583f1964285362768">Mercedes-Benz VAN</a>
              </td>
              <td>nadine.teixeira@mercedes-benz.com / steffen.sm.mueller@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Sebgir</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/25638a48759e472986552f40ac1865d7">
                  Star Assembly SRL – QMS
                </a>
              </td>
              <td>andreea.vasile@mercedes-benz.com / diana_teodora.boanca@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Sebgir</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/ebd3b3c053be4b7faf5cd6d92cac9a7e">
                  Star Transmission srl - QMS
                </a>
              </td>
              <td>diana_teodora.boanca@mercedes-benz.com / andreea.vasile@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>MBCS</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/ebd3b3c053be4b7faf5cd6d92cac9a7e">
                  Mercedes-Benz Customer Solutions GmbH - QMS
                </a>
              </td>
              <td>jiannula.rudolph@mercedes-benz.com / nele.schmitt@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>MBUSA</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/89604903968a43a29a07e17d1289f325">
                  Mercedes-Benz USA, LLC - QMS
                </a>
              </td>
              <td>tamika.mitchell@mbusa.com / angelina.a.perez@mbusa.com</td>
            </tr>
            <tr>
              <td>MB ExTra</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/22e318bdfc2d497298ad46da1bc89bb6">
                  Mercedes-Benz ExTra LLC - QMS
                </a>
              </td>
              <td>chris.mccune@mercedes-benz.com / sebastian.krause@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Charleston</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/04e29aca7172432684c211ef92d65809">
                  Mercedes-Benz Vans, LLC - QMS
                </a>
              </td>
              <td>diego.araujo@mercedes-benz.com / marcus.shipman@mercedes-benz.com</td>
            </tr>
            <tr>
              <td>Tuscaloosa</td>
              <td>
                <a href="https://editor.signavio.com/p/hub/de_de/folder/5ad53f6c072b4bd9b7293a2207a61082">
                  Mercedes-Benz U.S. International, Inc. - QMS
                </a>
              </td>
              <td>darlene.stevenson@mercedes-benz.com / thiago.rovarotto@mercedes-benz.com</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sundown;
