import { CSSProperties, useCallback, useEffect, useState, useMemo } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import clone from 'just-clone';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { DELETE_BUTTON, INFO_BUTTON } from 'assets/constants/ActionButtons';
import {
  AREAS,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  CATALOG_OBJECT_ACTIONS,
  DIAGRAM_CATALOG_BOX_CLASS,
  DIAGRAM_ENVIRONMENTS,
  IO,
  NOT_TRANSLATABLE,
  SC,
  STATUS,
} from 'assets/constants/constants';
import { getDangerouslySetInnerHTML, getFormattedDate } from 'assets/js/Utils';
import AttributesModal from 'components/Attributes/AttributesModal';
import CircleConnector from 'components/CircleConnector/CircleConnector';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import InnerBlock from 'components/ObjectCatalogBox/InnerBlock/InnerBlock';
import ShowMoreBox, { ShowMoreElement } from 'components/ShowMoreBox/ShowMoreBox';
import DialogInput from 'components/UI/DialogInput/DialogInput';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import TextButton from 'components/UI/TextButton/TextButton';
import { DiagramActionTypes, zoomToFitBeforePictures } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useLocalization from 'hooks/useLocalization';
import useTool from 'hooks/useTool';
import { findAllByObjectType, getAllByObjectType } from 'services/design';
import { InputOutputCatalog, ObjectTypes, SupplierCustomerCatalog } from 'types/administration';
import { ButtonProps, Language } from 'types/config';
import { LinkedDiagram, Tool } from 'types/diagram';
import { Attributes, SearchBy } from 'types/forms';
import {
  ProcessCatalogObject,
  ProcessDataCatalog,
  ProcessInputOutput,
  ProcessObject,
  ProcessStatus,
  ProcessType,
} from 'types/processes';
import { ProcessSupplierCustomer } from 'types/supplierCustomer';
import { Pagination, TablePrefix, TableColumn, TableVariant } from 'types/tables';
import { CatalogUser } from 'types/user';

import styles from './ObjectCatalogBox.module.scss';

type Props = {
  right?: boolean;
  style?: CSSProperties;
  eventId?: string;
};

const MAX_IOS = 2;

let ioColumns: TableColumn<ProcessInputOutput>[] = [
  { id: 'name', style: styles.Name },
  { id: 'description', style: styles.Description },
  { id: 'responsiblePerson', style: styles.Responsible },
  { id: 'creation', style: styles.CreationDate },
  { id: 'status', style: styles.Status },
];

const scColumns: TableColumn<ProcessSupplierCustomer>[] = [
  { id: 'name', style: styles.Name },
  { id: 'processResponsible', style: styles.Responsible },
  { id: 'origin', style: styles.Origin },
];

const ObjectCatalogBox = (props: Props) => {
  const { eventId, right, style } = props;
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { dispatch, diagramLanguage, isTakingPictures, processData, isDiagramPreparedForRelease } = useDiagramContext();
  const { availableLanguages } = useLocalization();
  const [isOpen, setIsOpen] = useState(true);
  const [processIOs, setProcessIOs] = useState<{ [key in Language]?: (ProcessInputOutput & { id: string })[] }>({});
  const [processSCs, setProcessSCs] = useState<{ [key in Language]?: (ProcessSupplierCustomer & { id: string })[] }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [itemToAdd, setItemToAdd] = useState<string[]>([]);
  const [objectToEdit, setObjectToEdit] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState('');
  const [isTempProcessDialogOpen, setIsTempProcessDialogOpen] = useState(false);
  const [isRequestIODialogOpen, setIsRequestIODialogOpen] = useState(false);
  const [blockType, setBlockType] = useState('');
  const [objectSelected, setObjectSelected] = useState<ProcessCatalogObject | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const { tool } = useTool();
  const checkEnvironment = useEnvironment();
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS;
  const ID = {
    CUSTOMER_SUPPLIER: right ? 'add-customer' : 'add-supplier',
    INPUT_OUTPUT: right ? 'add-input' : 'add-output',
  };

  const startEvent = useMemo(
    () => processData?.startEvents?.find((event) => event.id === eventId),
    [processData?.startEvents, eventId],
  );
  const lastEvent = useMemo(
    () => processData?.lastEvents?.find((event) => event.id === eventId),
    [processData?.lastEvents, eventId],
  );
  const inputFilled = useMemo(() => {
    const objects = processData?.isSwimlane
      ? startEvent?.inputs
      : processData?.objects.filter((elem) => elem.subtype === ProcessObject.INPUT);
    return objects || [];
  }, [startEvent, processData?.objects, processData?.isSwimlane]);
  const outputFilled = useMemo(() => {
    const objects =
      processData?.isSwimlane && eventId
        ? lastEvent?.outputs
        : processData?.objects.filter((elem) => elem.subtype === ProcessObject.OUTPUT);
    return objects || [];
  }, [eventId, lastEvent, processData?.objects, processData?.isSwimlane]);
  const ioToShow = (right ? outputFilled : inputFilled) || [];
  const supplierFilled = useMemo(
    () => (processData?.isSwimlane ? startEvent?.supplier : processData?.supplier),
    [startEvent, processData?.isSwimlane, processData?.supplier],
  );
  const customerFilled = useMemo(
    () => (processData?.isSwimlane && eventId ? lastEvent?.customer : processData?.customer),
    [eventId, lastEvent, processData?.isSwimlane, processData?.customer],
  );

  useEffect(() => {
    if (processData?.isSwimlane && ioColumns[2].id !== 'containedIn') {
      ioColumns = [
        { id: 'name', style: styles.Name },
        { id: 'description', style: styles.Description },
        { id: 'creator', style: styles.Responsible },
        { id: 'containedIn', style: styles.ContainedIn },
        { id: 'creation', style: styles.CreationDate },
        { id: 'status', style: styles.Status },
      ];
    }
  }, [processData?.isSwimlane]);

  useEffect(() => {
    setIsOpen(!areNotesDisplayed);
  }, [id, areNotesDisplayed]);

  useEffect(() => {
    if (isTakingPictures.preparation) {
      setIsOpen(true);
      if (isOpen && right) dispatch(zoomToFitBeforePictures());
    }
    if (isTakingPictures.preparation && isOpen && right) setTimeout(() => dispatch(zoomToFitBeforePictures()), 320);
  }, [isTakingPictures.preparation, isOpen, right, dispatch]);

  useEffect(() => {
    if (dialogOpen) {
      setSearchText('');
      setPage(0);
      setTotalPages(1);
    }
  }, [dialogOpen]);

  const handleRedirectToDiagram = useCallback(() => {
    let diagramUrl;
    if (processData?.isPublishedVersion) {
      diagramUrl = 'published';
    } else if (processData?.status === ProcessStatus.SANDBOX) {
      diagramUrl = 'sandbox';
    } else {
      diagramUrl = 'diagram';
    }
    if (!customerFilled && !supplierFilled) return;
    const diagramId = right ? customerFilled?.id : supplierFilled?.id;
    history.push(`/${diagramUrl}/${diagramId}`);
  }, [customerFilled, history, processData?.isPublishedVersion, processData?.status, right, supplierFilled]);

  const updateResponsiblePersonCatalog = (code?: string, name?: string) => {
    if (code && name && processData) {
      const newCatalog = {
        [SearchBy.USER]: [
          {
            code,
            commonName: name,
          },
        ],
      };

      if (!processData?.catalog) return;

      const existingCatalog = processData.catalog;

      let payload: ProcessDataCatalog = {
        ...existingCatalog,
        ...newCatalog,
      };

      if (existingCatalog[SearchBy.USER]) {
        const existingElement = existingCatalog[SearchBy.USER].find((elem) => code?.includes(elem.code));
        if (!existingElement) {
          existingCatalog[SearchBy.USER].push({
            code,
            commonName: name,
          });
        }
        payload = existingCatalog;
      }

      dispatch({
        type: DiagramActionTypes.UPDATE_PROCESS_CATALOG,
        payload,
      });
    }
  };

  const getOriginString = (element: string): string => {
    const newElement = element !== AREAS.SANDBOX ? AREAS.MODELING : element;
    return t(`environment.${newElement.toLowerCase()}`);
  };

  const handleBoxItem = async (dialog: string, isEdit: boolean) => {
    const isObject = dialog === IO;

    if ((!processIOs && isObject) || (!processSCs && !isObject) || !itemToAdd.length) return;

    try {
      const processItems = isObject ? processIOs : processSCs;
      let sameSideItems;
      let otherSideItems;
      if (isObject) {
        const otherSideInputItems = processData?.isSwimlane
          ? processData?.startEvents?.flatMap((event) => event.inputs)
          : inputFilled;
        const lastEventsOutputs = processData?.lastEvents?.length
          ? processData?.lastEvents?.flatMap((event) => event.outputs)
          : processData?.objects.filter((obj) => obj.subtype === ProcessObject.OUTPUT);
        const otherSideOutputItems = processData?.isSwimlane ? lastEventsOutputs : outputFilled;
        sameSideItems = (right ? outputFilled : inputFilled) || [];
        otherSideItems = (right ? otherSideInputItems : otherSideOutputItems) || [];
      } else {
        const customerSupplier = right ? processData?.supplier : processData?.customer;
        const customerSupplierArray = customerSupplier ? [customerSupplier] : [];
        sameSideItems = [];
        if (processData?.isSwimlane) {
          const lastEventCustomers = processData?.lastEvents?.length
            ? processData?.lastEvents?.filter((event) => event.customer).map((e) => e.customer)
            : customerSupplierArray;
          otherSideItems =
            (right ? processData?.startEvents?.filter((event) => event.supplier).map((e) => e.supplier) : lastEventCustomers) ||
            [];
        } else {
          otherSideItems = customerSupplierArray;
        }
      }

      const newItems = itemToAdd.map((itemId) => {
        if (isObject) {
          return (processItems[diagramLanguage] as unknown as ProcessInputOutput[])?.find(
            (elem: ProcessInputOutput) => String(elem.id) === itemId,
          );
        }

        return (processItems[diagramLanguage] as ProcessSupplierCustomer[])?.find(
          (elem: ProcessSupplierCustomer) => String(elem.id) === itemId,
        );
      });

      if (isObject && isEdit) {
        newItems.forEach((newItem) =>
          updateResponsiblePersonCatalog(
            (newItem as ProcessInputOutput)?.attributes?.[NOT_TRANSLATABLE]?.RESPONSIBLE_PERSON as string,
            (newItem as ProcessInputOutput)?.responsiblePerson,
          ),
        );
      }

      const itemsToHandle = newItems.map((elem) => {
        if (isObject) {
          return {
            id: String((elem as ProcessInputOutput).id),
            type: processData?.isSwimlane ? ObjectTypes.IO_SWIMLANE : ObjectTypes.IO_SIPOC,
            subtype: right ? ProcessObject.OUTPUT : ProcessObject.INPUT,
            attributes: (elem as ProcessInputOutput).attributes,
          };
        }
        const item = elem as ProcessSupplierCustomer;

        return {
          id: String(item.id),
          idProcess: item.idProcess,
          processName: item.processName,
          processResponsible: item.processResponsible,
          status: item.status,
          origin: getOriginString(item.status),
          type: right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER,
        };
      });

      if (itemsToHandle.some((item) => sameSideItems.some((i) => String(i.id) === item.id))) {
        setDialogOpen('');

        dispatch({
          type: DiagramActionTypes.SET_WARNING,
          payload: {
            message: t('objectExist'),
          },
        });
        return;
      }

      if (itemsToHandle.some((item) => otherSideItems.some((i) => String(i.id) === item.id))) {
        setDialogOpen('');

        dispatch({
          type: DiagramActionTypes.SET_WARNING,
          payload: {
            message: isObject ? t('inputOutput.warning') : t('supplierCustomer.warning'),
          },
        });
        return;
      }

      setItemToAdd([]);
      setEdit(false);
      setDialogOpen('');

      if (isObject) {
        let objectsToAdd: ProcessCatalogObject[] = [];

        if (objectToEdit && processData?.isSwimlane) {
          objectsToAdd = [...itemsToHandle, ...sameSideItems.filter((object) => object.id !== objectToEdit)];
          setObjectToEdit('');
        } else {
          objectsToAdd = processData?.isSwimlane ? [...itemsToHandle, ...sameSideItems] : [...itemsToHandle, ...otherSideItems];
        }

        dispatch({
          type: DiagramActionTypes.UPDATE_PROCESS_IOS,
          payload: {
            data: objectsToAdd,
            id: eventId,
            type: right ? ProcessObject.OUTPUT : ProcessObject.INPUT,
          },
        });
      } else {
        dispatch({
          type: DiagramActionTypes.UPDATE_PROCESS_SCS,
          payload: {
            data: itemsToHandle[0] as ProcessSupplierCustomer,
            id: eventId,
            type: right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const handleDeleteInputOutput = useCallback(
    async (deleteInputid?: string) => {
      try {
        let payload: ProcessCatalogObject[] = [];
        if (!processData?.isSwimlane || !eventId) {
          const existingIOs = processData?.objects || [];
          const existingClone = clone(existingIOs);
          const newObjects = existingClone.filter((elem) => deleteInputid !== elem.id);
          payload = [...newObjects];
        } else {
          payload =
            (right
              ? lastEvent?.outputs?.filter((elem) => elem.id !== deleteInputid)
              : startEvent?.inputs?.filter((elem) => elem.id !== deleteInputid)) || [];
        }
        dispatch({
          type: DiagramActionTypes.UPDATE_PROCESS_IOS,
          payload: { data: payload, id: eventId, type: right ? ProcessObject.OUTPUT : ProcessObject.INPUT },
        });
      } catch (error) {
        dispatch({
          type: DiagramActionTypes.SET_ERROR,
          payload: { title: t('error'), message: t('errors.500') },
        });
      }
    },
    [dispatch, eventId, lastEvent?.outputs, processData?.isSwimlane, processData?.objects, right, startEvent?.inputs, t],
  );

  const showDeleteObjectModal = useCallback(
    (io?: ProcessCatalogObject) => {
      if (!io) return;

      dispatch({
        type: DiagramActionTypes.SET_WARNING,
        payload: {
          buttons: [
            {
              id: `io-cancel-delete`,
              handleClick: () => dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null }),
              content: t(`cancel`),
              buttonStyle: BUTTON_SECONDARY,
            },
            {
              id: `io-confirm-delete`,
              handleClick: () => {
                handleDeleteInputOutput(io.id);
                dispatch({ type: DiagramActionTypes.SET_WARNING, payload: null });
              },
              content: t(`confirm`),
              buttonStyle: BUTTON_PRIMARY,
            },
          ],
          message: t('deleteSwimlaneObject', {
            objectType: t(`diagram.objects.${io.subtype.toLocaleLowerCase()}`).toLocaleLowerCase(),
          }),
        },
      });
    },
    [dispatch, handleDeleteInputOutput, t],
  );

  const dialogButtons: ButtonProps[] = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      disabled:
        dialogOpen !== IO || !processData?.isSwimlane
          ? !itemToAdd.length
          : !itemToAdd.length || [...itemToAdd, ...ioToShow].length > 10,
      id: dialogOpen === IO ? `${ID.INPUT_OUTPUT}-submit-button` : `${ID.CUSTOMER_SUPPLIER}-submit-button`,
      handleClick: () => handleBoxItem(dialogOpen, edit),
      key: 'submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      disabled: false,
      id: dialogOpen === IO ? `${ID.INPUT_OUTPUT}-cancel-button` : `${ID.CUSTOMER_SUPPLIER}-cancel-button`,
      handleClick: () => {
        setDialogOpen('');
        setEdit(false);
      },
      key: 'cancel',
    },
  ];

  const handleDeleteSupplierCustomer = () => {
    if (!processData) return;
    if (eventId) {
      dispatch({
        type: DiagramActionTypes.UPDATE_PROCESS_SCS,
        payload: { data: undefined, id: eventId, type: right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER },
      });
      return;
    }
    try {
      if (right && customerFilled) {
        delete processData.customer;
      } else if (supplierFilled) {
        delete processData.supplier;
      }
      dispatch({
        type: DiagramActionTypes.UPDATE_PROCESS_DATA,
      });
    } catch (error) {
      dispatch({
        type: DiagramActionTypes.SET_ERROR,
        payload: { title: t('error'), message: t('errors.500') },
      });
    }
  };

  const fetchInputOutput = useCallback(
    (newPage: number = 0, search: string = '') => {
      if (!processData?.type) return;
      setIsLoading(true);
      setProcessIOs({});
      setPage(newPage);
      setSearchText(search);

      findAllByObjectType(processData.isSwimlane ? ObjectTypes.IO_SWIMLANE : ObjectTypes.IO_SIPOC, {
        ...(processData.isSwimlane && { diagramId: id }),
        page: newPage,
        ...(search ? { search } : {}),
        size: 20,
      })
        .then((res) => {
          // Creates an object with every language's results { EN: [], DE: [] }
          const ioData = availableLanguages.reduce((acc: Attributes, lang: Language) => {
            const list = res.data.results.map((elem: InputOutputCatalog) => ({
              id: elem.id,
              name: elem.attributes?.[lang]?.NAME,
              description: (
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={getDangerouslySetInnerHTML(elem.attributes?.[lang]?.DESCRIPTION)}
                />
              ),
              ...(processData.type === ProcessType.SIPOC && {
                responsiblePerson: res.data.catalog.USER?.find(
                  (catalogUser: CatalogUser) => catalogUser.code === elem.attributes?.[NOT_TRANSLATABLE]?.RESPONSIBLE_PERSON,
                )?.commonName,
              }),
              ...(processData.isSwimlane && {
                creator: res.data.catalog.USER?.find(
                  (catalogUser: CatalogUser) => catalogUser.code === elem.attributes?.[NOT_TRANSLATABLE]?.CREATOR,
                )?.commonName,
                containedIn: elem.diagramIdentifierList?.map((diagram: LinkedDiagram) => ({
                  id: diagram.id,
                  nameMap: diagram.nameMap[diagramLanguage]?.PROCESS_NAME,
                  status: diagram.status,
                })),
              }),
              status: t(elem.status),
              creation: getFormattedDate(elem.attributes?.[NOT_TRANSLATABLE]?.CREATION),
              attributes: elem.attributes,
            }));

            return {
              ...acc,
              [lang]: list,
            };
          }, {});

          setTotalPages(res.data.numberOfPages);
          setProcessIOs(ioData);
          setIsLoading(false);
        })
        .catch(console.error); // eslint-disable-line no-console
    },
    [processData?.type, processData?.isSwimlane, id, availableLanguages, t, diagramLanguage],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickUpdate = (type: string, isEdit: boolean = false, inputId: string = '') => {
    setDialogOpen(type === ProcessObject.OUTPUT || type === ProcessObject.INPUT ? IO : SC);
    setBlockType(type);
    setEdit(isEdit);
    setObjectToEdit(inputId);
  };

  const handleBlockClick = useCallback(
    (type: string, ioObject?: ProcessCatalogObject) => {
      let isFilled = right && type === ProcessObject.OUTPUT ? !!outputFilled?.length : !!customerFilled;

      if (!right) {
        isFilled = type === ProcessObject.INPUT ? !!inputFilled?.length : !!supplierFilled;
      }

      if ((processData?.isPublishedVersion || processData?.status === STATUS.WORKFLOW) && isFilled) {
        if (type === ProcessObject.OUTPUT || type === ProcessObject.INPUT) {
          setObjectSelected(ioObject);
        } else {
          handleRedirectToDiagram();
        }
      } else {
        handleClickUpdate(type);
      }
    },
    [
      customerFilled,
      handleRedirectToDiagram,
      inputFilled?.length,
      outputFilled,
      processData?.isPublishedVersion,
      processData?.status,
      right,
      supplierFilled,
    ],
  );

  const fetchSupplierCustomer = useCallback(
    (newPage: number = 0, search: string = '') => {
      if (!processData?.type) return;
      setProcessSCs({});
      setIsLoading(true);
      setPage(newPage);
      setSearchText(search);

      getAllByObjectType(processData.type, { page: newPage, ...(search ? { search } : {}), size: 20 })
        .then((res) => {
          // Creates an object with every language's results { EN: [], DE: [] }
          const scData = availableLanguages.reduce((acc: Attributes, lang: Language) => {
            const list = res.data.diagrams.map((elem: SupplierCustomerCatalog) => ({
              id: elem.id,
              idProcess: elem.id,
              status: t(elem.status),
              processName: elem.processName,
              name: elem.processName[lang],
              origin: getOriginString(elem.status),
              processResponsible: res.data.catalog.USER?.filter(
                (catalogUser: CatalogUser) => catalogUser.code === elem.processResponsible,
              ).map((user: CatalogUser) => user.commonName),
            }));

            return {
              ...acc,
              [lang]: list,
            };
          }, {});

          setTotalPages(res.data.numberOfPages);
          setProcessSCs(scData);
          setIsLoading(false);
        })
        .catch(console.error); // eslint-disable-line no-console
    },
    [availableLanguages, processData?.type, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleTempProcessDialog = (dialog: string) => {
    setDialogOpen(dialog);
    setIsTempProcessDialogOpen(!dialog);
  };

  const handleAddTempSupplierCustomer = (val: string) => {
    const payload = {
      processName: {
        DE: val,
        EN: val,
        CN: val,
      },
      processResponsible: '',
      status: STATUS.TEMPORAL,
      origin: '',
      idProcess: '',
      type: right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER,
    };

    setIsTempProcessDialogOpen(false);
    setItemToAdd([]);
    dispatch({
      type: DiagramActionTypes.UPDATE_PROCESS_SCS,
      payload: { data: payload, id: eventId, type: right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER },
    });
  };

  const mapIOToShowMoreElement = (io: ProcessCatalogObject): ShowMoreElement => {
    const linkAction = { ...INFO_BUTTON, onClick: () => setObjectSelected(io), key: `info-${io.id}` };

    return {
      value: io?.attributes?.[diagramLanguage]?.NAME?.toString() || '',
      actions: processData?.isOnlyRead
        ? [linkAction]
        : [
            {
              ...DELETE_BUTTON,
              key: `delete-${io.id}`,
              onClick: () => showDeleteObjectModal(io),
            },
            linkAction,
          ],
    };
  };

  const addIOButton = (disabled?: boolean): JSX.Element => {
    const type = right ? ProcessObject.OUTPUT : ProcessObject.INPUT;
    return (
      <TextButton
        className={styles.AddInput}
        disabled={disabled}
        id={`add-${type}`}
        onClick={() => handleClickUpdate(type)}
        text={t('add')}
      />
    );
  };

  const TooltipButton = (): JSX.Element => {
    if (!processData?.isSwimlane) return <></>;

    return (ioToShow as ProcessCatalogObject[])?.length === 10 ? (
      <TooltipComponent
        className="mbc-tooltip nepos-tooltip"
        content={t('maxSwimlaneObjects', { max: 10, objectType: t('INPUTS') })}
        cssClass="mbc-tooltip"
        openDelay={500}
        position="BottomCenter"
        showTipPointer={false}
      >
        {addIOButton(true)}
      </TooltipComponent>
    ) : (
      addIOButton()
    );
  };

  const renderIO = useCallback(
    (io?: ProcessCatalogObject): JSX.Element => {
      return (
        <InnerBlock
          blockName={io?.attributes?.[diagramLanguage]?.NAME}
          handleDelete={() => (processData?.isSwimlane ? showDeleteObjectModal(io) : handleDeleteInputOutput(io?.id))}
          id={ID.INPUT_OUTPUT}
          isFilled={!!io}
          isSwimlaneStyle={processData?.isSwimlane}
          key={`${ID.INPUT_OUTPUT}-${io?.id}`}
          onClick={() => handleBlockClick(right ? ProcessObject.OUTPUT : ProcessObject.INPUT, io)}
          onClickEditButton={() => handleClickUpdate(right ? ProcessObject.OUTPUT : ProcessObject.INPUT, true, io?.id)}
          onClickInnerButton={() => setObjectSelected(io)}
          right={right}
        />
      );
    },
    [
      ID.INPUT_OUTPUT,
      diagramLanguage,
      handleBlockClick,
      handleDeleteInputOutput,
      processData?.isSwimlane,
      right,
      showDeleteObjectModal,
    ],
  );

  return (
    <div
      className={`${styles.Wrapper} ${right ? styles.Right : ''} ${
        processData?.type !== ProcessType.SWIMLANE && (processData?.isOnlyRead || isDiagramPreparedForRelease)
          ? styles.OnlyRead
          : ''
      } ${processData?.isSwimlane ? styles.Swimlane : ''}`}
      id={`Catalog-Box-${right ? 'last' : 'start'}-${eventId}`}
      style={style}
    >
      <div
        className={`${DIAGRAM_CATALOG_BOX_CLASS} ${styles.Box} ${right ? styles.Right : styles.Left} ${
          isOpen ? styles.Open : ''
        }`}
      >
        {!isOpen ? (
          <div className={styles.Buttons}>
            <RoundButton
              icon={right ? 'icon-person-gruppe' : 'icon-lkw-lieferant'}
              id={ID.CUSTOMER_SUPPLIER}
              isFilled={right ? !!customerFilled : !!supplierFilled}
              onClick={() => {}}
              variant="gradient"
            />
            <RoundButton
              icon={right ? 'icon-hochladen' : 'icon-herunterladen'}
              id={ID.INPUT_OUTPUT}
              isFilled={!!ioToShow}
              onClick={() => {}}
              variant="gradient"
            />
          </div>
        ) : (
          <div className={styles.InnerBlocksContainer}>
            <div>
              <div className={styles.Label}>
                <i className={right ? 'di icon-person-gruppe' : 'di icon-lkw-lieferant'} />
                <label htmlFor={ID.CUSTOMER_SUPPLIER}>{right ? t('customer') : t('supplier')}</label>
              </div>
              <InnerBlock
                blockName={right ? customerFilled?.processName[diagramLanguage] : supplierFilled?.processName[diagramLanguage]}
                handleDelete={() => handleDeleteSupplierCustomer()}
                id={ID.CUSTOMER_SUPPLIER}
                isFilled={right ? !!customerFilled : !!supplierFilled}
                isSupplierCustomer
                isSwimlaneStyle={processData?.isSwimlane}
                onClick={() => handleBlockClick(right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER)}
                onClickEditButton={() => handleClickUpdate(right ? ProcessObject.CUSTOMER : ProcessObject.SUPPLIER, true)}
                onClickInnerButton={handleRedirectToDiagram}
                status={
                  (right && customerFilled?.status === STATUS.TEMPORAL) || (!right && supplierFilled?.status === STATUS.TEMPORAL)
                    ? STATUS.TEMPORAL
                    : undefined
                }
                supplierCustomerPublished={right ? customerFilled?.published : supplierFilled?.published}
              />
            </div>
            <div>
              <div className={styles.Title}>
                <div className={styles.Label}>
                  <i className={right ? 'di icon-hochladen' : 'di icon-herunterladen'} />
                  <label htmlFor={ID.INPUT_OUTPUT}>{right ? t('diagram.objects.output') : t('diagram.objects.input')}</label>
                </div>
                <TooltipButton />
              </div>
              {ioToShow?.length ? ioToShow.slice(0, MAX_IOS)?.map(renderIO) : renderIO(undefined)}
              {ioToShow?.length > MAX_IOS && (
                <ShowMoreBox className={styles.ShowMoreBox} elements={ioToShow.slice(MAX_IOS).map(mapIOToShowMoreElement)} />
              )}
            </div>
          </div>
        )}
        <div className={styles.Arrow} data-qa={`open-arrow-${right ? 'right' : 'left'}`} onClick={() => setIsOpen(!isOpen)}>
          <i className={`di icon-pfeil-doppelt-chevron-${right ? 'links' : 'rechts'}`} />
        </div>
      </div>

      {dialogOpen && (
        <DialogNEPOS
          dialog={{
            title: edit
              ? t('editX', { labelX: t(`contextualMenu.${blockType}`) })
              : t('addX', { labelX: t(`contextualMenu.${blockType}`) }),
            buttons: dialogButtons,
          }}
          fetchOnLoad={dialogOpen === IO ? fetchInputOutput : fetchSupplierCustomer}
        >
          <TableWithSearch
            buttonText={
              dialogOpen === IO
                ? t(processData?.isSwimlane ? 'catalog.createNewX' : 'RequestNewObject', {
                    objectType: right ? t('diagram.objects.output') : t('diagram.objects.input'),
                  })
                : t(`add.temporary.process`)
            }
            columns={
              dialogOpen === IO
                ? (ioColumns as unknown as TableColumn<{ id: string }>[])
                : (scColumns as unknown as TableColumn<{ id: string }>[])
            }
            getPage={(newPage: number) =>
              dialogOpen === IO ? fetchInputOutput(newPage, searchText) : fetchSupplierCustomer(newPage, searchText)
            }
            id={dialogOpen === IO ? TablePrefix.INPUT_OUTPUT : TablePrefix.SUPPLIER_CUSTOMER}
            isAsync
            isLoading={isLoading}
            multiselect={processData?.isSwimlane && !edit && dialogOpen === IO}
            onCheck={setItemToAdd}
            onClick={() => {
              if (!checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED)) {
                if (dialogOpen === IO) {
                  setDialogOpen('');
                  setIsRequestIODialogOpen(true);
                } else {
                  handleTempProcessDialog('');
                }
              }
            }}
            page={page}
            pagination={Pagination.PAGES}
            rows={
              dialogOpen === IO
                ? (processIOs[diagramLanguage] as unknown as ProcessInputOutput & { id: string }[])
                : (processSCs[diagramLanguage] as unknown as ProcessSupplierCustomer & { id: string }[])
            }
            searching={(text: string) => {
              setTotalPages(1);

              if (dialogOpen === IO) {
                fetchInputOutput(0, text);
              } else {
                fetchSupplierCustomer(0, text);
              }
            }}
            totalPages={totalPages}
            variant={TableVariant.CHECKBOX}
          />
        </DialogNEPOS>
      )}
      {isRequestIODialogOpen && (
        <DialogObjectCatalog
          close={() => setIsRequestIODialogOpen(false)}
          dialogType={processData?.isSwimlane ? CATALOG_OBJECT_ACTIONS.CREATE_SWIMLANE_IO : CATALOG_OBJECT_ACTIONS.REQUEST}
          isLegacy={false}
          isNEPOS
          objectType={processData?.isSwimlane ? ObjectTypes.IO_SWIMLANE : ObjectTypes.IO_SIPOC}
          onSubmit={(createdObject?: ProcessCatalogObject) => {
            if (!createdObject || !processData?.isSwimlane) return;

            let payload: ProcessCatalogObject[] = [];
            const newIO = { ...createdObject, subtype: right ? ProcessObject.OUTPUT : ProcessObject.INPUT };
            if (!right) {
              payload = [newIO, ...inputFilled];
            } else {
              payload = [newIO, ...outputFilled];
            }

            dispatch({
              type: DiagramActionTypes.UPDATE_PROCESS_IOS,
              payload: { data: payload, id: eventId, type: right ? ProcessObject.OUTPUT : ProcessObject.INPUT },
            });
          }}
          parentCallback={() => {
            setDialogOpen(IO);
            setIsRequestIODialogOpen(false);
          }}
          responseNeeded
        />
      )}
      {objectSelected && (
        <AttributesModal
          modalTitle={right ? t('diagram.objects.output') : t('diagram.objects.input')}
          object={objectSelected}
          onClose={() => setObjectSelected(undefined)}
        />
      )}
      {processData?.type !== ProcessType.SWIMLANE &&
        (processData?.isPublishedVersion || isDiagramPreparedForRelease || processData?.status === STATUS.WORKFLOW) && (
          <CircleConnector />
        )}
      {isTempProcessDialogOpen && (
        <DialogInput
          close={() => handleTempProcessDialog(SC)}
          confirm={(val: string) => handleAddTempSupplierCustomer(val)}
          label={t('temporary.process')}
          title={t('add.temporary.process')}
        />
      )}
    </div>
  );
};

export default ObjectCatalogBox;
