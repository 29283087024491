import { useCallback, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth as useOidcAuth } from 'react-oidc-context';

import {
  CENTRAL,
  ENVIRONMENTS,
  LOCATIONS,
  LOCATION_MANAGER,
  ROLES,
  STORAGE,
  SUPPORT,
  URL_TO_ENVIRONMENT,
  USER_ROLE,
} from 'assets/constants/constants';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import { AuthActions, AuthContext } from 'contexts/Auth/AuthContext';
import { setIsAutoSaving } from 'contexts/Diagram/DiagramContext';
import { FeatureFlagsActions } from 'contexts/FeatureFlags/FeatureFlagsContext';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLocalization from 'hooks/useLocalization';
import serviceConstants from 'services/serviceConstants';
import userService from 'services/userService';

import { mockUserRights, permissions } from './authUtils';

const useAuth = (isApp) => {
  const { state, dispatch, isReadyForLogin } = useContext(AuthContext);
  const { isLoading, userInfo, userName, userRights, userRoles, isSaveLoading, processData } = state;
  const { t } = useTranslation();
  const auth = useOidcAuth();
  const isAuthReadyAndIdle = !hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading;
  const { handleServiceError } = useError();
  const {
    fetchFeatureFlags,
    isLoading: isFeatureFlagsLoading,
    isMaintenance,
    setLocalFeatureFlags,
    isFreezed,
  } = useFeatureFlags();
  const { areTranslationsLoading, fetchAvailableLanguages, fetchTranslations, translations } = useLocalization();

  const fetchInitialData = useCallback(() => {
    fetchAvailableLanguages();
  }, [fetchAvailableLanguages, fetchFeatureFlags]);

  const initLocalUser = useCallback(() => {
    try {
      const { default: localAuth } = require('../localAuth'); // eslint-disable-line
      const { LOCAL_AUTH_TOKEN, LOCAL_USER_NAME, LOCAL_USER_ROLES, LOCAL_FEATURE_FLAGS } = localAuth;
      const localRights = [];
      const localRoles = LOCAL_USER_ROLES.map((userRole) => {
        const role = userRole.split('-')[0].replace('ROLE_', '');
        mockUserRights[role]?.forEach((userRight) => {
          if (!localRights.includes(userRight)) {
            localRights.push(userRight);
          }
        });
        return `MBCMAP.${userRole}`;
      });
      localStorage.mbcMapToken = LOCAL_AUTH_TOKEN || '';
      setLocalFeatureFlags(LOCAL_FEATURE_FLAGS);
      dispatch({
        type: AuthActions.FETCH_USER_SUCCESS,
        payload: {
          userName: LOCAL_USER_NAME,
          userRights: localRights,
          userRoles: localRoles,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`localAuth file not found.\nNode error: ${error}`);
    }
  }, [dispatch, setLocalFeatureFlags]);

  const fetchUser = useCallback(() => {
    // dispatch({ type: AuthActions.FETCH_USER });
    // if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'mig') {
    //   initLocalUser();
    //   return;
    // }
    // try {
    //   if (isAuthReadyAndIdle) {
    //     // eslint-disable-next-line no-console
    //     console.log('You are not logged in.');
    //     localStorage.setItem('mbcMapSigninRedirect', window.location.pathname);
    //     auth.signinRedirect().catch(console.error); // eslint-disable-line no-console
    //   }
    // } catch (error) {
    //   dispatch({ type: AuthActions.FETCH_USER_ERROR, payload: error });
    // }
  }, [auth, dispatch, initLocalUser, isAuthReadyAndIdle]);

  useEffect(() => {
    if (!auth.user?.access_token) return;
    try {
      dispatch({
        type: AuthActions.FETCH_USER_SUCCESS,
        payload: {
          user: auth.user,
          userName: auth.user?.profile.sub || auth.user,
          userRights: auth.user?.profile?.entitlement_group || [],
          userRoles: auth.user?.profile?.authorization_group || [],
        },
      });
      fetchFeatureFlags(auth.user?.profile.sub);
      localStorage.mbcMapToken = auth.user?.access_token || 'no_token';
      localStorage.removeItem('mbcMapSigninRedirect');
    } catch (error) {
      dispatch({ type: AuthActions.FETCH_USER_ERROR, payload: error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.access_token]);

  useEffect(() => {
    if (auth.error) console.error(auth.error); // eslint-disable-line no-console
    if (isAuthReadyAndIdle) {
      localStorage.setItem(STORAGE.SIGN_IN_REDIRECT, window.location.pathname);
      fetchUser();
    }
  }, [isAuthReadyAndIdle]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserInfo = async () => {
    // dispatch({ type: AuthActions.GET_USER_INFO });
    // try {
    //   const { data } = await userService.getUserInfo();
    //   dispatch({ type: AuthActions.GET_USER_INFO_SUCCESS, payload: data });
    // } catch (err) {
    //   handleServiceError(err);
    //   dispatch({ type: AuthActions.GET_USER_INFO_ERROR });
    // }
  };

  const addEvents = () => {
    if (!isFreezed) {
      auth.events.addAccessTokenExpiring(() => {
        document.getElementById('save')?.click();
        auth
          .signinSilent()
          .then((response) => {
            if (!response?.error) {
              fetchFeatureFlags(auth.user?.profile.sub);
              // eslint-disable-next-line no-console
              console.log('renewToken SUCCESS!', response);
              localStorage.mbcMapToken = response?.access_token || 'no_token';
              if (!response?.access_token) {
                Dialog.showAlert({
                  name: t('warning'),
                  message: t('reloadTokenMessage'),
                });
                setTimeout(() => {
                  window.location.reload();
                }, 60000);
              }
            } else {
              throw Error();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('--- renewToken ERROR! ', error);
            Dialog.showAlert({
              name: t('warning'),
              message: t('reloadTokenMessage'),
            });
            dispatch(setIsAutoSaving(true));
            setTimeout(() => {
              window.location.reload();
            }, 60000);
          });
      });
    }
  };

  const removeEvents = () => {
    auth.events.removeAccessTokenExpiring(() => console.log('removeAccessTokenExpiring')); // eslint-disable-line no-console
  };

  useEffect(() => {
    if (!userName || isFeatureFlagsLoading || areTranslationsLoading || translations || !isApp) return;

    fetchTranslations();
  }, [isFeatureFlagsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userName || isLoading || userInfo || !isApp) return;

    fetchUserInfo();
  }, [userName]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkRole = (role) => {
    return userRoles?.some((userRole) =>
      role === ROLES.QI
        ? (userRole.includes(`MBCMAP.ROLE_${role}`) && !userRole.includes(`MBCMAP.ROLE_${role}_`)) ||
          (userRole.includes(`MBCMAP.${role}`) && !userRole.includes(`MBCMAP.${role}_`))
        : userRole.includes(`MBCMAP.ROLE_${role}`) || userRole.includes(`MBCMAP.${role}`),
    );
  };

  const checkPermission = (key) => userRights?.includes(`${permissions[key]}`);

  const checkRoles = (roles = []) => roles.some((role) => checkRole(role));

  const cleanupAuth = () => {
    removeEvents();
  };

  const logout = () => auth.signoutRedirect();

  const isCentral = () =>
    (serviceConstants.env === ENVIRONMENTS.LOCAL ? serviceConstants.baseUrl : state.clientRoot).split('.')[0].includes('mbcmap');

  const getPlant = () => {
    if (isCentral()) return CENTRAL;
    const plant = (serviceConstants.env === ENVIRONMENTS.LOCAL ? serviceConstants.baseUrl : state.clientRoot)
      .split('//')[1]
      .split('.')[0];

    return LOCATIONS[plant];
  };

  const isOnlyUser = () =>
    ((serviceConstants.env === ENVIRONMENTS.LOCAL && userRoles.length === 1) ||
      (serviceConstants.env !== ENVIRONMENTS.LOCAL && userRoles.length === 2)) &&
    checkRole(USER_ROLE);

  const getManagedPlants = () => {
    if (!isCentral()) return [];

    const prefix1 = `MBCMAP.ROLE_${LOCATION_MANAGER}`;
    const prefix2 = `MBCMAP.${LOCATION_MANAGER}`;
    const plants = [];

    userRoles?.forEach((role) => {
      let prefix;

      if (role.includes(prefix1)) {
        prefix = prefix1;
      } else if (role.includes(prefix2)) {
        prefix = prefix2;
      }

      if (prefix) {
        const roleParts = role.split('-');

        if ((roleParts.length === 3 && prefix === prefix1) || (roleParts.length === 2 && prefix === prefix2)) {
          plants.push(roleParts[1]);
        }
      }
    });

    return plants;
  };

  const canManagePlants = () => getManagedPlants()?.length > 0;

  const initAuth = async () => {
    // eslint-disable-next-line no-console
    console.log('ENVIRONMENT', process.env);

    // Internet Explorer 6-11
    const isIE = false || !!document.documentMode;
    addEvents();

    if (isIE) {
      Dialog.showAlert({
        name: 'Error',
        message: t('browser'),
        isError: true,
      });
    }

    // fetchInitialData();
  };

  const setVisitedSandbox = async () => {
    try {
      await userService.setVisitedSandbox('true');
      dispatch({ type: AuthActions.SET_VISITED_SANDBOX });
    } catch (error) {
      handleServiceError(error);
    }
  };

  useEffect(() => {
    if (userName && !auth.isAuthenticated && !isFeatureFlagsLoading && translations && isApp) {
      dispatch({
        type: AuthActions.SET_SESSION_INFO,
        payload: {
          env: state.env === ENVIRONMENTS.LOCAL ? URL_TO_ENVIRONMENT[serviceConstants.baseUrl.split('-')[1]] : state.env,
          isLogged: true,
          isMaintenance: Boolean(isMaintenance) && !checkRole(SUPPORT),
        },
      });
      // TODO: remove this after all components are converted into function components and are using useAuth instead of AuthContext
      dispatch({
        type: AuthActions.SET_LEGACY_AUTH_SERVICE,
        payload: {
          legacyAuthService: {
            canManagePlants,
            checkPermission,
            checkRole,
            checkRoles,
            getManagedPlants,
            logout,
            userInfo,
            userName,
            userRoles,
          },
        },
      });
    }
  }, [userName, isFeatureFlagsLoading, translations, userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ...state,
    canManagePlants,
    checkPermission,
    checkRole,
    checkRoles,
    cleanupAuth,
    getManagedPlants,
    getPlant,
    initAuth,
    isCentral,
    isOnlyUser,
    isReadyForLogin,
    logout,
    setVisitedSandbox,
  };
};

export default useAuth;
