import { useCallback, useRef } from 'react';

import { SYMBOL_MIN_HEIGHT, SYMBOL_MIN_WIDTH } from 'assets/constants/constants';
import {
  DiagramActionTypes,
  resetGhostSwimlane,
  initGhostSwimlane,
  updateGhostSwimlane,
  updateGhostSymbol,
} from 'contexts/Diagram/DiagramContext';
import { Direction, HorizontalDirections, VerticalDirections, ResizeHandles, Orientation } from 'types/diagram';

import useConnector from './useConnector';
import useDiagramContext from './useDiagramContext';
import useSelection from './useSelection';
import useSymbol from './useSymbol';

const handleDirections: Record<ResizeHandles, Direction[]> = {
  [ResizeHandles.TOP_LEFT]: [Direction.TOP, Direction.LEFT],
  [ResizeHandles.TOP_CENTER]: [Direction.TOP],
  [ResizeHandles.TOP_RIGHT]: [Direction.TOP, Direction.RIGHT],
  [ResizeHandles.CENTER_LEFT]: [Direction.LEFT],
  [ResizeHandles.CENTER_RIGHT]: [Direction.RIGHT],
  [ResizeHandles.BOTTOM_LEFT]: [Direction.BOTTOM, Direction.LEFT],
  [ResizeHandles.BOTTOM_CENTER]: [Direction.BOTTOM],
  [ResizeHandles.BOTTOM_RIGHT]: [Direction.BOTTOM, Direction.RIGHT],
};

const handleCursors = {
  [ResizeHandles.TOP_LEFT]: 'nwse-resize',
  [ResizeHandles.TOP_CENTER]: 'ns-resize',
  [ResizeHandles.TOP_RIGHT]: 'nesw-resize',
  [ResizeHandles.CENTER_LEFT]: 'ew-resize',
  [ResizeHandles.CENTER_RIGHT]: 'ew-resize',
  [ResizeHandles.BOTTOM_LEFT]: 'nesw-resize',
  [ResizeHandles.BOTTOM_CENTER]: 'ns-resize',
  [ResizeHandles.BOTTOM_RIGHT]: 'nwse-resize',
};

type MouseFunction = (event: MouseEvent) => void;

export default function useResize() {
  const { updateSwimlanesConnectorsVertices } = useConnector();
  const { dispatch, fontSize, initialCoords, selectedSymbolIds } = useDiagramContext();
  const { updateSymbol } = useSymbol();
  const { selectedSymbols } = useSelection();
  const symbol = selectedSymbols?.[0];
  const handle = useRef<ResizeHandles | null>(null);

  const startResizing = useCallback(
    (event: React.MouseEvent, clickedHandle: ResizeHandles, move: MouseFunction, end: any) => {
      event.stopPropagation();
      document.body.classList.add(`resizing-${handleCursors[clickedHandle]}`);
      initialCoords.current = { x: event.clientX, y: event.clientY };
      handle.current = clickedHandle;
      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', end);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const stopResizing = useCallback(
    (move: MouseFunction, end: any) => {
      if (handle.current) document.body.classList.remove(`resizing-${handleCursors[handle.current]}`);
      initialCoords.current = null;
      handle.current = null;
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', end);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleResizeMove = useCallback(
    (event: MouseEvent) => {
      if (!initialCoords.current || !handle.current || !symbol || !fontSize) return;

      const directions = handleDirections[handle.current];
      const dx = ((event.clientX - initialCoords.current.x) / fontSize) * (directions.includes(Direction.LEFT) ? -1 : 1);
      const dy = ((event.clientY - initialCoords.current.y) / fontSize) * (directions.includes(Direction.TOP) ? -1 : 1);
      const newValues = {
        meta: {
          ...(directions.includes(Direction.LEFT) ? { left: symbol.meta.left - dx } : {}),
          ...(directions.includes(Direction.TOP) ? { top: symbol.meta.top - dy } : {}),
          ...(directions.some((direction) => VerticalDirections.includes(direction)) ? { height: symbol.meta.height + dy } : {}),
          ...(directions.some((direction) => HorizontalDirections.includes(direction)) ? { width: symbol.meta.width + dx } : {}),
        },
      };

      if (newValues.meta.height && newValues.meta.height < SYMBOL_MIN_HEIGHT) {
        newValues.meta.height = SYMBOL_MIN_HEIGHT;
        if (newValues.meta.top) newValues.meta.top = symbol.meta.top + symbol.meta.height - SYMBOL_MIN_HEIGHT;
      }
      if (newValues.meta.width && newValues.meta.width < SYMBOL_MIN_WIDTH) {
        newValues.meta.width = SYMBOL_MIN_WIDTH;
        if (newValues.meta.left) newValues.meta.left = symbol.meta.left + symbol.meta.width - SYMBOL_MIN_WIDTH;
      }

      updateSymbol(symbol.id, newValues);
      dispatch(updateGhostSymbol(handle.current, symbol.id));
    },
    [fontSize, initialCoords, symbol, updateSymbol], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleResizeEnd = useCallback(() => {
    stopResizing(handleResizeMove, handleResizeEnd);
    dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: true });
    dispatch({ type: DiagramActionTypes.UPDATE_GHOST_SYMBOL, payload: undefined });
  }, [dispatch, handleResizeMove, stopResizing]);

  const handleResize = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>, clickedHandle: ResizeHandles) => {
      startResizing(event, clickedHandle, handleResizeMove, handleResizeEnd);
      dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: false });
    },
    [dispatch, handleResizeEnd, handleResizeMove, startResizing],
  );

  const handleResizeSwimlaneMove = useCallback(
    (event: MouseEvent) => {
      dispatch(updateGhostSwimlane({ x: event.clientX, y: event.clientY }));
    },
    [dispatch],
  );

  const handleResizeSwimlaneEnd = useCallback(() => {
    stopResizing(handleResizeSwimlaneMove, handleResizeSwimlaneEnd);
    dispatch(resetGhostSwimlane(selectedSymbolIds[0]));
    setTimeout(updateSwimlanesConnectorsVertices);
  }, [dispatch, handleResizeSwimlaneMove, selectedSymbolIds, stopResizing, updateSwimlanesConnectorsVertices]);

  const handleResizeSwimlane = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>, clickedHandle: ResizeHandles) => {
      const currentCoords = { x: event.clientX, y: event.clientY };
      const swimlaneId = event.currentTarget.parentElement?.id || '';
      const direction = clickedHandle === ResizeHandles.CENTER_RIGHT ? Orientation.HORIZONTAL : Orientation.VERTICAL;
      startResizing(event, clickedHandle, handleResizeSwimlaneMove, handleResizeSwimlaneEnd);
      dispatch(initGhostSwimlane(swimlaneId, currentCoords, direction));
    },
    [dispatch, handleResizeSwimlaneEnd, handleResizeSwimlaneMove, startResizing],
  );

  return {
    handleResize,
    handleResizeSwimlane,
  };
}
