import i18next from 'i18next';

import * as Constants from 'assets/constants/constants';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import { ssoClientRoot } from 'hooks/authUtils';
import serviceConstants, { axiosInstance } from 'services/serviceConstants';

export const isCentral = () =>
  (serviceConstants.env === 'local' ? serviceConstants.baseUrl : ssoClientRoot).split('.')[0].includes('mbcmap');

export const getCurrentPlant = () => {
  if (isCentral()) return Constants.CENTRAL;
  const plant = (serviceConstants.env === 'local' ? serviceConstants.baseUrl : ssoClientRoot).split('//')[1].split('.')[0];

  return Constants.LOCATIONS[plant];
};

export const getLocalStorageAppLanguage = () => {
  const savedLang = localStorage.getItem(Constants.storageLabels.lang) || sessionStorage.getItem(Constants.storageLabels.lang);

  return savedLang?.toUpperCase() || Constants.DEFAULT_LANGUAGES_BY_LOCATION[getCurrentPlant()];
};

export const getUserLanguage = () => i18next.language.toUpperCase() || getLocalStorageAppLanguage();

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    'Accept-Language': getUserLanguage(),
    ...config.headers,
    Authorization: `Bearer ${localStorage.mbcMapToken}`,
    ...(serviceConstants.env !== 'local' && {
      'Content-Security-Policy': `default-src: 'none'; frame-ancestors 'none'; script-src: 'none';`,
    }),
  };

  return config;
});

// TODO: Cambiar o quitar los if cuando se haga el manejo de excepciones
const getErrorMessage = (errorResponse, error) => {
  const errorCode = errorResponse?.data?.code || errorResponse?.status;
  let msgError = errorResponse ? `${i18next.t(`errors.${errorCode}`)} <br/></br/> (${errorCode})` : '';

  if (error.config?.url.includes('/recycle-bin/restore') && errorResponse?.status === 500) {
    msgError = i18next.t('errors.500_RECYCLE_BIN');
  }

  if (window.location.pathname.includes('/diagram/') && errorResponse?.status === 403) {
    msgError = i18next.t('errors.403_DIAGRAM');
  }

  if (
    errorCode === Constants.ERROR_DESIGN_008 &&
    errorResponse?.status === 500 &&
    errorResponse?.config?.url?.endsWith('/diagram') &&
    window.location.pathname.includes('/diagram/')
  ) {
    msgError = i18next.t('errors.emptyDiagram');
  }

  if (errorCode === Constants.REQUEST_005) {
    msgError = i18next.t('errors.403');
  }

  if (
    errorCode === Constants.ERROR_DESIGN_008 &&
    errorResponse?.status === 500 &&
    errorResponse?.config?.url?.endsWith('/diagram') &&
    window.location.pathname.includes('/published/')
  ) {
    msgError = i18next.t('errors.emptyPublished');
  }

  if (Constants.SET_HISTORICAL_ERRORS.includes(errorCode)) {
    msgError = '';

    if ((error.response?.data.publishedChildrenList || errorResponse?.data.publishedChildrenList)?.length > 0) {
      msgError = `
        ${i18next.t(`errors.${Constants.ERROR_WORKFLOW_017}`)}
        <br/>
        ${(error.response?.data.publishedChildrenList || errorResponse?.data.publishedChildrenList)
          .map(
            (item) => `
              <br/>
              <a href="/diagram/${item.id}" target="blank" class="diagram-link">${
              JSON.parse(item.nameMap)[i18next.language.toUpperCase()].PROCESS_NAME
            }</a>
        `,
          )
          .join('')}
      `;
    }

    if ((error.response?.data.unpublishedChildrenList || errorResponse?.data.unpublishedChildrenList)?.length > 0) {
      msgError = `
        ${msgError ? `${msgError}<br/><br/><br/>` : ''}
        ${i18next.t(`errors.${Constants.ERROR_WORKFLOW_018}`)}
        <br/>
        ${(error.response?.data.unpublishedChildrenList || errorResponse?.data.unpublishedChildrenList)
          .map(
            (item) => `
          <br/>
          <a href="/diagram/${item.id}" target="blank" class="diagram-link">${
              JSON.parse(item.nameMap)[i18next.language.toUpperCase()].PROCESS_NAME
            }</a>
        `,
          )
          .join('')}
      `;
    }
  }

  if (Constants.ERROR_DESIGN_012 === errorCode) {
    const objectType =
      JSON.parse(errorResponse.config.data).idObjectType === Constants.ROLE_ID ? Constants.ROLE : Constants.IT_SYSTEM;

    msgError = `
      ${i18next.t(`errors.${Constants.ERROR_DESIGN_012}_${objectType}`)}
      <br/>
      ${errorResponse.data.diagramInUseList
        .map(
          (item) => `
        <br/>
        <a href="/${item.status === Constants.STATUS.SANDBOX ? 'sandbox' : 'diagram'}/${
            item.id
          }" target="blank" class="diagram-link">${JSON.parse(item.nameMap)[i18next.language.toUpperCase()].PROCESS_NAME}</a>
      `,
        )
        .join('')}
    `;
  }

  return msgError || error.message;
};

export const handleServiceError = (err, closeClick = () => {}) => {
  const error = err.toJSON ? err.toJSON() : err;
  const message = getErrorMessage(err.response, error);
  const dashboardUrl = `${window.location.origin}/dashboard`;

  Dialog.showAlert({
    name: error.name || 'Error',
    message,
    isError: true,
    closeClick: Constants.DASHBOARD_REDIRECT_ERRORS.includes(err.response?.data?.code)
      ? () => window.location.replace(dashboardUrl)
      : () => closeClick(),
  });
};
