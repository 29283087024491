import { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { DIAGRAM_ENVIRONMENTS, NOT_TRANSLATABLE, ROLES, STATUS } from 'assets/constants/constants';
import { removeSquareBracketsFromString } from 'assets/js/Utils';
import Connector from 'components/Connector/Connector';
import AssignUserDialog from 'components/DialogDetail/AssignUserDialog';
import LinkDiagramDialog from 'components/DialogDetail/LinkDiagramDialog';
import GhostConnector from 'components/GhostConnector/GhostConnector';
import ObjectCatalogBox from 'components/ObjectCatalogBox/ObjectCatalogBox';
import SwimlanesWrapper from 'components/Swimlanes/SwimlanesWrapper';
import Symbol from 'components/Symbol/Symbol';
import SymbolBoard from 'components/SymbolBoard/SymbolBoard';
import TextFieldNEPOS from 'components/TextFieldNEPOS/TextFieldNEPOS';
import IconButtonDropdown from 'components/UI/IconButtonDropdown/IconButtonDropdown';
import WorkflowBarNEPOS from 'components/WorkflowBarNEPOS/WorkflowBarNEPOS';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useAuth from 'hooks/useAuth';
import useDiagramBackground from 'hooks/useDiagramBackground';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useEnvironment from 'hooks/useEnvironment';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useSelection from 'hooks/useSelection';
import useSymbol from 'hooks/useSymbol';
import useValidation from 'hooks/useValidation';
import useWorkflow from 'hooks/useWorkflow';
import { Language } from 'types/config';
import { Tool, DiagramToImport, DiagramToMove, DiagramToLink } from 'types/diagram';
import { Modeler, Modelers, ProcessType } from 'types/processes';
import { SwimlaneV2 as SwimlanePropTypes } from 'types/swimlanes';
import { SymbolTypes, Symbol as SymbolPropTypes, TextFieldNEPOS as TextFieldPropTypes, SymbolActions } from 'types/symbols';
import { WorkflowIcon, WorkflowCode } from 'types/workflow';

import styles from './Diagram.module.scss';

const Diagram = () => {
  const {
    background,
    backgroundRef,
    bounds,
    boundsRef,
    containerRef,
    diagramLanguage,
    diagramsToImport,
    diagramsToMove,
    diagramsToLink,
    dispatch,
    fontSize,
    isLoading,
    isTakingPictures,
    origin,
    originRef,
    padding,
    processData,
    shouldUpdateBackground,
    tool,
    workflowOptions,
    isDiagramPreparedForRelease,
    displayedSymbolBoards,
    isMovingSwimlaneSymbol,
  } = useDiagramContext();
  const [isLinkDiagramOpen, setIsLinkDiagramOpen] = useState({ isOpen: false, action: '' });
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [idLinkedDiagram, setIdLinkedDiagram] = useState<number>();
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const { handleDrop, mouseDownHandler } = useDragAndDrop();
  const { prepareSendForRelease } = useWorkflow(id);
  useDiagramBackground();
  const { clearSelection } = useSelection();
  const { checkRole, userInfo } = useAuth();
  const { isDiagramValid } = useValidation();
  const { showDiagramColors, isHistoricalWFSIPOCEnabled, isSendForReleaseSIPOCEnabled, isFreezed } = useFeatureFlags();
  const canDrag = tool === Tool.ADD_SYMBOL || isMovingSwimlaneSymbol.isMoving;
  const isDiagramVisible = !isLoading && !shouldUpdateBackground;
  const { importFromSandbox, moveDiagramFromTree, linkDiagramFromTree } = useSymbol();
  const [selectedSymbolId, setSelectedSymbolId] = useState('');
  const checkEnvironment = useEnvironment();
  const history = useHistory();
  const hasUserAlreadySentForRelease = useMemo(() => {
    const alreadySentWFUsers = removeSquareBracketsFromString(processData?.attributes.NOT_TRANSLATABLE?.SEND_WORKFLOW || '');
    return alreadySentWFUsers.includes(userInfo?.code);
  }, [processData?.attributes.NOT_TRANSLATABLE?.SEND_WORKFLOW, userInfo?.code]);

  const isUserAbleToSendForRelease = useMemo(
    () =>
      (checkRole(ROLES.QI_MODELER) && processData?.isSwimlane) ||
      (checkRole(ROLES.USER) &&
        ((processData?.attributes[NOT_TRANSLATABLE]?.AUTHOR &&
          processData?.attributes[NOT_TRANSLATABLE]?.AUTHOR?.includes(userInfo?.code) &&
          !processData?.isSwimlane) ||
          processData?.attributes[NOT_TRANSLATABLE]?.PROCESS_RESPONSIBLE?.includes(userInfo?.code))),
    [checkRole, processData?.attributes, processData?.isSwimlane, userInfo?.code],
  );

  const linkDiagramToImportSearch = diagramsToImport.map((elem: DiagramToImport) => ({
    id: elem.id,
    name: elem.name[i18n.language.toUpperCase() as Language],
    isRecommendation: elem.isRecommendation,
    type: elem.type,
  }));

  const linkDiagramToMoveSearch = diagramsToMove.map((elem: DiagramToMove) => ({
    id: elem.id,
    name: elem.name,
    idDiagramType: elem.idDiagramType,
    isRecommendation: elem.isRecommendation,
  }));

  const linkDiagramToLinkSearch = diagramsToLink.map((elem: DiagramToLink) => ({
    id: elem.id,
    name: elem.name,
    idDiagramType: elem.idDiagramType,
    isRecommendation: elem.isRecommendation,
  }));

  const DIALOG_LABEL_BUTTON_BY_TYPE: { [key in SymbolActions]?: string } = {
    [SymbolActions.IMPORT]: t('import'),
    [SymbolActions.MOVE]: t('move'),
    [SymbolActions.LINK]: t('link'),
  };

  const DIALOG_LABEL_TITLE_BY_TYPE: { [key in SymbolActions]?: string } = {
    [SymbolActions.IMPORT]: t('importDiagram'),
    [SymbolActions.MOVE]: t('moveDiagram'),
    [SymbolActions.LINK]: t('linkDiagram'),
  };

  const DIALOG_DATA_BY_TYPE: { [key in SymbolActions]?: any } = {
    [SymbolActions.IMPORT]: linkDiagramToImportSearch,
    [SymbolActions.MOVE]: linkDiagramToMoveSearch,
    [SymbolActions.LINK]: linkDiagramToLinkSearch,
  };
  const getDialogLabelButton = (type: SymbolActions) => DIALOG_LABEL_BUTTON_BY_TYPE[type] || DIALOG_LABEL_BUTTON_BY_TYPE.IMPORT;
  const getDialogLabelTitle = (type: SymbolActions) => DIALOG_LABEL_TITLE_BY_TYPE[type] || DIALOG_LABEL_TITLE_BY_TYPE.IMPORT;
  const getDialogData = (type: SymbolActions) => DIALOG_DATA_BY_TYPE[type] || DIALOG_DATA_BY_TYPE.IMPORT;

  const handleLinkDiagram = (idDiagram: number) => {
    switch (isLinkDiagramOpen.action) {
      case SymbolActions.IMPORT:
        importFromSandbox(idDiagram, [], selectedSymbolId);
        break;
      case SymbolActions.MOVE:
        moveDiagramFromTree(idDiagram, selectedSymbolId);
        break;
      default:
        linkDiagramFromTree(idDiagram, selectedSymbolId);
        break;
    }
  };

  const handleOptionClick = (action?: SymbolActions, symbolId?: string) => {
    if (!action || !symbolId) return;
    if (
      action === SymbolActions.IMPORT ||
      action === SymbolActions.LINK ||
      (action === SymbolActions.MOVE && !processData?.isSwimlane)
    ) {
      setSelectedSymbolId(symbolId);
      setIsLinkDiagramOpen({ isOpen: true, action });
    }
  };

  const getComponentByType = (symbolProps: SymbolPropTypes) => {
    if (!processData?.symbols) return;
    const { type, id: symbolId } = symbolProps;

    if (type !== SymbolTypes.SIPOC_ELEMENT && type !== SymbolTypes.TEXT) return;

    const isSymbolPostItsDisplayed = displayedSymbolBoards?.find((idSymbol) => idSymbol === symbolId);
    if (isSymbolPostItsDisplayed) return <SymbolBoard key={symbolId} symbolProps={symbolProps as SymbolPropTypes} />;

    type PickEnum<T, K extends T> = {
      [P in keyof K]: P extends K ? P : never;
    };

    type ValidSymbolTypes = PickEnum<SymbolTypes, SymbolTypes.SIPOC_ELEMENT | SymbolTypes.TEXT>;

    type PropTypesByComponentType = {
      SIPOC_ELEMENT: SymbolPropTypes;
      TEXT: TextFieldPropTypes;
      SWIMLANE_ELEMENT: SwimlanePropTypes;
    };

    const SymbolTypeMap: {
      [keyType in ValidSymbolTypes]: { Component: (props: PropTypesByComponentType[keyType]) => JSX.Element };
    } = {
      [SymbolTypes.SIPOC_ELEMENT]: { Component: Symbol },
      [SymbolTypes.TEXT]: { Component: TextFieldNEPOS },
    };

    const { Component } = SymbolTypeMap[type];

    return <Component handleOptionClick={handleOptionClick} key={symbolId} {...symbolProps} />;
  };

  const assignModelers = (modelers: Modelers) => {
    const diagramModelers = modelers.map((elem: Modeler) => elem.Code);
    if (idLinkedDiagram) importFromSandbox(idLinkedDiagram, diagramModelers, selectedSymbolId);
  };

  const checkIsInViewport = () => {
    const swimlaneMain = document.getElementById('swimlane-wrapper');
    const sides = ['top', 'bottom', 'left', 'right'];
    return sides.every((side: string) => {
      const sideChecked = swimlaneMain?.getBoundingClientRect()[side as keyof DOMRect] as number;
      if (side === 'right' || side === 'left') {
        return sideChecked && sideChecked >= 0 && sideChecked <= window.innerWidth;
      }
      return sideChecked && sideChecked >= 0 && sideChecked <= window.innerHeight;
    });
  };

  return !processData ? (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <div className={styles.Background} />
      </div>
    </div>
  ) : (
    <>
      <div
        className={`${styles.Wrapper} ${processData.isOnlyRead || isDiagramPreparedForRelease ? styles.Plain : ''} ${
          processData?.status === STATUS.WORKFLOW ? styles.IsWorkflow : ''
        } ${
          isTakingPictures.all || isTakingPictures.onTheFly || isTakingPictures.zoomToFit || isTakingPictures.preparation
            ? 'printing'
            : ''
        }`}
        id="diagram-picture"
      >
        {!processData.isSwimlane && <ObjectCatalogBox />}
        <div
          className={`${styles.Container} ${showDiagramColors ? styles.Colors : ''}
               ${!processData.isSwimlane || checkIsInViewport() ? styles.HiddenScroll : ''}
                ${
                  processData?.type !== ProcessType.SWIMLANE &&
                  (processData?.isPublishedVersion || isDiagramPreparedForRelease || processData?.status === STATUS.WORKFLOW)
                    ? styles.GreyBox
                    : ''
                }`}
          ref={containerRef}
        >
          <div
            className={`${styles.Background} ${styles[tool]}`}
            onClick={clearSelection}
            onDragOver={canDrag ? (e) => e.preventDefault() : undefined}
            onDrop={canDrag ? handleDrop : undefined}
            onMouseDown={(e) => mouseDownHandler(e)}
            ref={backgroundRef}
            style={{
              fontSize: fontSize ? `${fontSize}px` : '1rem',
              height: background ? `${background.height}px` : '100%',
              width: background ? `${background.width}px` : '100%',
            }}
          >
            <div className={styles.RealOrigin} style={{ left: `${origin?.left || 0}em`, top: `${origin?.top || 0}em` }} />
            <div
              className={styles.Origin} // Diagram Center
              ref={originRef}
              style={{
                left: padding ? `${padding.horizontal}em` : '50%',
                top: padding ? `${padding.vertical}em` : '50%',
                ...(isDiagramVisible ? {} : { visibility: 'hidden' }),
              }}
            >
              <div
                className={styles.Bounds}
                id="diagram-bounds"
                ref={boundsRef}
                style={{
                  height: `${bounds?.height || 0}em`,
                  width: `${bounds?.width || 0}em`,
                  left: `${bounds?.left || 0}em`,
                  top: `${bounds?.top || 0}em`,
                }}
              />
              {processData.symbols?.map((symbol) => getComponentByType(symbol))}
              {processData.isSwimlane && <SwimlanesWrapper />}
            </div>
            <svg
              style={processData.isSwimlane && !isMovingSwimlaneSymbol.isMoving ? { position: 'relative' } : {}}
              viewBox={
                background && fontSize && padding
                  ? `${padding.horizontal * -1} ${padding.vertical * -1} ${background.width / fontSize} ${
                      background.height / fontSize
                    }`
                  : '0 0 100 100'
              }
              xmlns="http://www.w3.org/2000/svg"
            >
              {isDiagramVisible &&
                processData.connectors.map((connector) => (
                  <Connector key={`connector-${connector.source.id}-${connector.target.id}`} {...connector} />
                ))}
              <GhostConnector />
            </svg>
          </div>
        </div>
        {!processData.isSwimlane && <ObjectCatalogBox right />}
        {checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) &&
          ((checkRole(ROLES.QI_MODELER) && processData?.isSwimlane) ||
            (checkRole(ROLES.USER) &&
              ((processData.attributes[NOT_TRANSLATABLE]?.AUTHOR &&
                processData.attributes[NOT_TRANSLATABLE]?.AUTHOR?.includes(userInfo.code)) ||
                processData.attributes[NOT_TRANSLATABLE]?.PROCESS_RESPONSIBLE?.includes(userInfo.code)))) && (
            <div className={styles.EditButton} onClick={() => history.push(`/diagram/${id}`)}>
              <i className="di icon-stift-bearbeiten" />
              <span>{t('tool.editDiagram')}</span>
            </div>
          )}
        {isSendForReleaseSIPOCEnabled &&
          workflowOptions &&
          processData &&
          ((!processData.isOnlyRead && [STATUS.NEW, STATUS.PUBLISHED, STATUS.UPDATE].includes(processData.status)) ||
            (processData.isOnlyRead &&
              processData.attributes[NOT_TRANSLATABLE]?.SEND_WORKFLOW &&
              processData.status === STATUS.STARTING_WORKFLOW &&
              !hasUserAlreadySentForRelease)) && (
            <div className={styles.ReleaseButton}>
              <IconButtonDropdown
                disabled={isFreezed}
                extraClass="DropUp"
                icon="di icon-verteiler"
                id="workflow-dropdown"
                onChange={(workflowOption) => {
                  if (!isUserAbleToSendForRelease) {
                    dispatch({
                      type: DiagramActionTypes.SET_ERROR,
                      payload: { title: 'error', message: 'errors.sendToReleasePermissions' },
                    });
                  } else {
                    const isValid = isDiagramValid();

                    if (isValid) {
                      prepareSendForRelease(workflowOption);
                    }
                  }
                }}
                options={workflowOptions
                  .filter(
                    (workflowOption) =>
                      (workflowOption.enabled && workflowOption.code !== WorkflowCode.HISTORICAL_WF_SIPOC) ||
                      (workflowOption.enabled &&
                        isHistoricalWFSIPOCEnabled &&
                        workflowOption.code === WorkflowCode.HISTORICAL_WF_SIPOC),
                  )
                  .map((workflowOption) => {
                    return {
                      value: workflowOption.code,
                      label: t(`workflow.${workflowOption.code}`),
                      icon: WorkflowIcon[workflowOption.code as WorkflowCode],
                    };
                  })}
                title={t('send.toRelease')}
              />
            </div>
          )}
      </div>
      {processData?.status === STATUS.WORKFLOW && <WorkflowBarNEPOS />}
      {isLinkDiagramOpen.isOpen && (
        <LinkDiagramDialog
          assignModelers={(idDiagram: number) => {
            setIsUserDialogOpen(true);
            setIdLinkedDiagram(idDiagram);
          }}
          buttonText={getDialogLabelButton(isLinkDiagramOpen.action as SymbolActions)}
          close={() => setIsLinkDiagramOpen({ isOpen: false, action: '' })}
          diagramList={getDialogData(isLinkDiagramOpen.action as SymbolActions)}
          dialogTitle={getDialogLabelTitle(isLinkDiagramOpen.action as SymbolActions)}
          lang={diagramLanguage}
          linkDiagram={(idDiagram: number) => handleLinkDiagram(idDiagram)}
          target="#root"
        />
      )}
      {isUserDialogOpen && (
        <AssignUserDialog
          assignUsers={(modelers: Modelers) => assignModelers(modelers)}
          close={() => setIsUserDialogOpen(false)}
          linkDiagram={() => {}}
          refAssignment={() => {}}
          target="#root"
        />
      )}
    </>
  );
};

export default Diagram;
