import { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { downloadBlob } from 'assets/js/Utils';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import DropdownNEPOS from 'components/UI/Dropdown/DropdownNEPOS';
import FileUploader from 'components/UI/FileUploader/FileUploader';
import UploadCard from 'components/UploadCard/UploadCard';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useLocalization from 'hooks/useLocalization';
import useProcess from 'hooks/useProcess';
import diagramServices from 'services/diagramService';
import { DialogType } from 'types/dialogs';

import styles from './ExportImportDialog.module.scss';

const ALLOWED_TYPES = ['text/xml'];
const ALLOWED_EXTENSIONS = ['.xml'];

type Props = {
  isExportDialog: boolean;
  closeDialog: () => void;
};

const ExportImportDialog = (props: Props) => {
  const { isExportDialog, closeDialog } = props;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { saveProcess } = useProcess(id);
  const { dispatch, processData } = useDiagramContext();
  const { i18n, t } = useTranslation();
  const { availableLanguages } = useLocalization();
  const [xmlLanguage, setXmlLanguage] = useState('');
  const [file, setFile] = useState<File>();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const importFileInputRef = useRef<HTMLInputElement | null>(null);
  const dialogText = isExportDialog ? 'export' : 'import';

  const handleImportFileSelection = useCallback((event: any) => {
    const { target } = event;

    if (!target || !target.files) return;

    setFile(target.files[0]);
  }, []);

  const getSelectedLanguageCode = useCallback(() => {
    return availableLanguages
      .map((language) => {
        return {
          code: language,
          value: t(`language.${language}`),
        };
      })
      .find((language) => language.value === xmlLanguage).code;
  }, [availableLanguages, t, xmlLanguage]);

  const exportXML = useCallback(async () => {
    if (!processData) return;

    dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
    await saveProcess(processData);
    const selectedLanguageCode = getSelectedLanguageCode();

    diagramServices
      .exportDiagramXML(id, selectedLanguageCode)
      .then((res) => {
        downloadBlob(
          `\ufeff${res.data}`,
          res.headers,
          `${processData.attributes[i18n.language].PROCESS_NAME}_${selectedLanguageCode}.xml`,
        );
        dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
      })
      .catch((err) => {
        dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
        handleServiceError(err);
      });
  }, [dispatch, getSelectedLanguageCode, i18n.language, id, processData, saveProcess]);

  const importXML = useCallback(async () => {
    dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });

    try {
      const formData = new FormData();
      formData.append('file', file as File);
      await diagramServices.importDiagramXML(id, formData, getSelectedLanguageCode());
      setIsConfirmDialogOpen(true);
      dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
    } catch (error) {
      dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
      handleServiceError(error);
    }
  }, [dispatch, file, getSelectedLanguageCode, id]);

  const handleClose = () => {
    closeDialog();
    setXmlLanguage('');

    if (!isExportDialog && file) {
      setIsWarningDialogOpen(false);
      setFile(undefined);
    }
  };

  const expImpDialog = {
    title: t(`language.dialog.${dialogText}.title`),
    type: DialogType.None,
    buttons: [
      {
        id: `NEPOS-${dialogText}-btn`,
        handleClick: () => {
          if (isExportDialog) {
            exportXML();
            handleClose();
          } else setIsWarningDialogOpen(true);
        },
        content: t(`language.dialog.${dialogText}.name`),
        buttonStyle: BUTTON_PRIMARY,
        disabled: (isExportDialog ? !xmlLanguage : !xmlLanguage || !file) as boolean,
      },
      {
        id: `NEPOS-cancel-${dialogText}-btn`,
        handleClick: () => handleClose(),
        content: t('cancel'),
        buttonStyle: BUTTON_SECONDARY,
      },
    ],
  };

  return (
    <>
      <DialogNEPOS dialog={expImpDialog}>
        <DropdownNEPOS
          code="language"
          label={t('Language')}
          onChange={(option: string) => setXmlLanguage(option)}
          options={availableLanguages.map((language: string) => t(language))}
          required
          value={xmlLanguage}
        />
        {!isExportDialog && (
          <div className={styles.ImportUploader}>
            <ButtonNEPOS
              className={styles.UploadButton}
              handleClick={() => importFileInputRef.current?.click()}
              id="button-upload-import-file"
              isSecondary
            >
              {t('language.dialog.import.file.label')}
            </ButtonNEPOS>
            <input
              accept={`${ALLOWED_TYPES.join(',')},${ALLOWED_EXTENSIONS.join(',')}`}
              className={styles.FilesInput}
              id="uploadImportFileInput"
              onChange={(event) => {
                handleImportFileSelection(event);
                event.target.value = '';
              }}
              ref={importFileInputRef}
              type="file"
            />
            <div>
              {file ? (
                <UploadCard
                  icon="icon-datei-xls"
                  id="import-file"
                  key={file.name}
                  onDelete={() => setFile(undefined)}
                  title={file.name}
                />
              ) : (
                <FileUploader
                  allowedExtensions={ALLOWED_EXTENSIONS}
                  handleUploadFiles={(files: File[]) => setFile(files[0])}
                  isEmpty
                  maxFiles={1}
                  uploaderText={t('dialog.uploadVideo.dropVideo')}
                />
              )}
            </div>
          </div>
        )}
      </DialogNEPOS>
      {isWarningDialogOpen && (
        <DialogNEPOS
          dialog={{
            title: t('warningText'),
            type: DialogType.Warning,
            buttons: [
              {
                id: `NEPOS-confirm-import`,
                handleClick: () => importXML(),
                content: t(`language.dialog.import.name`),
                buttonStyle: BUTTON_PRIMARY,
              },
              {
                id: `NEPOS-cancel-confirm-import-btn`,
                handleClick: () => setIsWarningDialogOpen(false),
                content: t('cancel'),
                buttonStyle: BUTTON_SECONDARY,
              },
            ],
          }}
          extraClass="Modal"
        >
          {t('language.dialog.import.warning')}
        </DialogNEPOS>
      )}
      {isConfirmDialogOpen && (
        <DialogNEPOS
          dialog={{
            title: t('success'),
            type: DialogType.Warning,
            buttons: [
              {
                id: `NEPOS-confirm-import`,
                handleClick: () => {
                  handleClose();
                  history.go(0);
                },
                content: t(`confirm`),
                buttonStyle: BUTTON_PRIMARY,
              },
            ],
          }}
          extraClass="Modal"
        >
          {t('language.dialog.import.confirmation')}
        </DialogNEPOS>
      )}
    </>
  );
};

export default ExportImportDialog;
