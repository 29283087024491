import React from 'react';

import useDiagramConfig from 'hooks/useDiagramConfig';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useFormTypes from 'hooks/useFormTypes';
import useLocalization from 'hooks/useLocalization';

import Diagram from './Diagram';

const DiagramWrapper = (props) => {
  const diagramConfig = useDiagramConfig();
  const featureFlags = useFeatureFlags();
  const { fetchFormTypes, getAttributesData, getFormTypesCode, getReactReactiveFormFieldConfig, ...formTypes } = useFormTypes();
  const { availableLanguages } = useLocalization();

  return (
    <Diagram
      {...props}
      availableLanguages={availableLanguages}
      diagramConfig={diagramConfig}
      featureFlags={featureFlags}
      fetchFormTypes={fetchFormTypes}
      formTypes={formTypes}
      getAttributesData={getAttributesData}
      getFormTypesCode={getFormTypesCode}
      getReactReactiveFormFieldConfig={getReactReactiveFormFieldConfig}
    />
  );
};

export default DiagramWrapper;
