import React, { useReducer } from 'react';

const FeatureFlagsActions = {
  GET_FEATURE_FLAGS: 'GET_FEATURE_FLAGS',
  GET_FEATURE_FLAGS_SUCCESS: 'GET_FEATURE_FLAGS_SUCCESS',
  GET_FEATURE_FLAGS_ERROR: 'GET_FEATURE_FLAGS_ERROR',
  SET_LOCAL_FEATURE_FLAGS: 'SET_LOCAL_FEATURE_FLAGS',
};

const initialState = {
  isLoading: false,
  isLoaded: false,
};

const reducer = (state, action) => {
  const { actionType, payload } = action;

  switch (actionType) {
    case FeatureFlagsActions.GET_FEATURE_FLAGS:
      return {
        ...state,
        isLoading: true,
      };
    case FeatureFlagsActions.GET_FEATURE_FLAGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    case FeatureFlagsActions.GET_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isLoaded: true,
      };
    case FeatureFlagsActions.SET_LOCAL_FEATURE_FLAGS:
      return {
        ...state,
        localFeatureFlags: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

const FeatureFlagsContext = React.createContext();

const FeatureFlagsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export { FeatureFlagsActions, FeatureFlagsContext, FeatureFlagsProvider };
