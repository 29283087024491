import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import useTreeStructure from 'hooks/useTreeStructure';
import { ProcessLevelFormatted as Row, ProcessLevelTableFormatted } from 'types/processLevel';
import { TableProps } from 'types/tables';

import ProcessLevelDatabaseRow from './ProcessLevelDatabaseRow';
import styles from './ProcessLevelDatabaseTable.module.scss';

const ProcessLevelDatabaseTable = React.memo(
  ({
    columns,
    onCheck = () => {},
    rows,
    isLoading,
    className,
    searchText,
    multiselect,
    selectedRows,
    isTreeStructure,
  }: TableProps<Row>) => {
    const { t } = useTranslation();
    const [checkedMap, setCheckedMap] = useState<Record<string, boolean>>({});
    const [extendedRows, setExtendedRows] = useState<string[]>([]);
    const isCheckedAll = useMemo(() => (!rows?.length ? false : !!rows.every(({ id }) => checkedMap[id])), [checkedMap, rows]);
    const colNumber = useMemo(() => columns.length + 1, [columns.length]); // +1 because of the checkbox column
    const columnsTable = useMemo(() => columns.reduce((ret, column) => ({ ...ret, [column.id]: column.style }), {}), [columns]);

    useEffect(() => {
      if (!selectedRows) return;

      setCheckedMap(selectedRows.reduce((checkedRows, rowId) => ({ ...checkedRows, [rowId]: true }), {}));
    }, [selectedRows]);

    const rowMapper = useCallback(
      (row) => ({
        ...row,
        category: t(`requirements.${row.category}`),
        processResponsibles: row.processResponsibles.join(', '),
        processCoordinators: row.processCoordinators.join(', '),
        pacemakers: row.pacemakers.join(', '),
        children: row.children?.length ? row.children.map(rowMapper) : undefined,
      }),
      [t],
    );

    const parsedRows = useMemo(() => rows?.map(rowMapper), [rows, rowMapper]);

    const handleCheckAll = useCallback(() => {
      if (!rows) return;
      const checked = Object.fromEntries(rows.map(({ id }) => [id, !isCheckedAll]));
      setCheckedMap(checked);
      onCheck(
        Object.entries(checked)
          .filter(([, isChecked]) => isChecked)
          .map(([id]) => id),
      );
    }, [isCheckedAll, onCheck, setCheckedMap, rows]);

    const handleExtendRow = useCallback(
      (rowId: string) => {
        const isCurrentRowExtended = extendedRows?.includes(rowId);
        const newExtendedRows = isCurrentRowExtended ? extendedRows?.filter((id) => id !== rowId) : extendedRows?.concat([rowId]);
        setExtendedRows(newExtendedRows);
      },
      [extendedRows],
    );

    const onRowCheck = useCallback(
      (rowId) => {
        setCheckedMap((prevCheckedMap) => {
          const checked = { [rowId]: !prevCheckedMap[rowId] };
          onCheck(
            Object.entries(checked)
              .filter(([, isChecked]) => isChecked)
              .map(([id]) => id),
          );
          return checked;
        });
      },
      [onCheck],
    );

    const onRowMultiCheck = useCallback(
      (rowId) => {
        setCheckedMap((prevCheckedMap) => {
          const checked = { ...prevCheckedMap, [rowId]: !prevCheckedMap[rowId] };
          onCheck(
            Object.entries(checked)
              .filter(([, isChecked]) => isChecked)
              .map(([id]) => id),
          );
          return checked;
        });
      },
      [onCheck],
    );

    const renderRow: any = useCallback(
      (row: Row) => {
        const isExtended = extendedRows && extendedRows.some((extendedRow) => extendedRow === row.id);
        const isChecked = checkedMap[row.id];

        return (
          <Fragment key={`process-level-fragment-${row.id}`}>
            <ProcessLevelDatabaseRow
              columns={columnsTable}
              isChecked={isChecked}
              isExtended={isExtended}
              isTreeStructure={isTreeStructure}
              key={`row-${row.id}`}
              onCheck={multiselect ? () => onRowMultiCheck(row.id) : undefined}
              onClick={multiselect ? undefined : () => onRowCheck(row.id)}
              onExtend={() => handleExtendRow(row.id)}
              row={row}
            />
            {isExtended && row.children.map((r: Row) => renderRow(r))}
          </Fragment>
        );
      },
      [extendedRows, checkedMap, columnsTable, isTreeStructure, multiselect, onRowMultiCheck, onRowCheck, handleExtendRow],
    );

    const { tree } = useTreeStructure({
      rows: parsedRows as ProcessLevelTableFormatted[],
      extendedRows,
      checkedMap,
      rowRenderer: renderRow,
      searchText,
      propertiesToSearchIn: ['title', 'processResponsibles', 'processCoordinators'],
    });

    const content = useMemo(() => {
      if (isLoading)
        return (
          <tr>
            <td className={styles.NoResults} colSpan={colNumber}>
              ...
            </td>
          </tr>
        );
      return tree?.length === 0 ? (
        <tr>
          <td className={styles.NoResults} colSpan={colNumber}>
            {t('noResults')}
          </td>
        </tr>
      ) : (
        <>{tree?.map((element) => element)}</>
      );
    }, [colNumber, isLoading, t, tree]);

    return (
      <div className={`${styles.TableWrapper} ${className || ''}`}>
        <table className={styles.Table}>
          <thead>
            <tr className={styles.Fixed}>
              {isTreeStructure && (
                <th className={styles.ArrowColumn} key="arrow">
                  <div className={styles.ArrowWrapper}>
                    <div className={styles.ArrowIcon}>
                      <i className="di icon-pfeil-chevron-unten" />
                    </div>
                  </div>
                </th>
              )}
              {multiselect && (
                <th className={styles.CheckboxColumn} key="checkbox">
                  <Checkbox checked={isCheckedAll} handleCheck={handleCheckAll} id="check-all" />
                </th>
              )}
              {columns.map((column) => (
                <th className={column.style || ''} key={column.id}>
                  <span>{t(`requirements.${column.id}`)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    );
  },
);

export default ProcessLevelDatabaseTable;
