import { createContext, useReducer, Dispatch, ReactNode } from 'react';

import { LinkWithStatus, Document, UploadStatus } from 'types/activitySpecification';

enum ActionTypes {
  ADD_LINK = 'ADD_LINK',
  SET_LINKS = 'SET_LINKS',
  SET_LINK = 'SET_LINK',
  CLEAN_LINKS = 'CLEAN_LINKS',
  CLEAN_DOCS = 'CLEAN_DOCS',
  DELETE_LINK = 'DELETE_LINK',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  SET_DOCUMENT = 'SET_DOCUMENT',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  SET_ID = 'SET_ID',
  SET_VERSION = 'SET_VERSION',
}

const initialState = {
  links: [],
  documents: [],
  id: '',
};

type Action =
  | { type: ActionTypes.ADD_LINK; payload: LinkWithStatus }
  | { type: ActionTypes.SET_LINKS; payload: LinkWithStatus[] }
  | { type: ActionTypes.SET_LINK; payload: LinkWithStatus }
  | { type: ActionTypes.CLEAN_LINKS; payload: undefined }
  | { type: ActionTypes.CLEAN_DOCS; payload: undefined }
  | { type: ActionTypes.DELETE_LINK; payload: LinkWithStatus }
  | { type: ActionTypes.DELETE_DOCUMENT; payload: Document }
  | { type: ActionTypes.SET_DOCUMENTS; payload: Document[] }
  | { type: ActionTypes.ADD_DOCUMENT; payload: Document }
  | { type: ActionTypes.SET_DOCUMENT; payload: Document }
  | { type: ActionTypes.SET_ID; payload: string }
  | { type: ActionTypes.SET_VERSION; payload: string };

export const setLinks = (links: LinkWithStatus[]) => ({ type: ActionTypes.SET_LINKS, payload: links } as Action);
export const addLink = (link: LinkWithStatus) => ({ type: ActionTypes.ADD_LINK, payload: link } as Action);
export const setLink = (link: LinkWithStatus) => ({ type: ActionTypes.SET_LINK, payload: link } as Action);
export const removeLink = (link: LinkWithStatus) => ({ type: ActionTypes.DELETE_LINK, payload: link } as Action);

export const setDocuments = (documents: Document[]) => ({ type: ActionTypes.SET_DOCUMENTS, payload: documents } as Action);
export const setDocument = (document: Document) => ({ type: ActionTypes.SET_DOCUMENT, payload: document } as Action);
export const addDocument = (document: Document) => ({ type: ActionTypes.ADD_DOCUMENT, payload: document } as Action);
export const removeDocument = (document: Document) => ({ type: ActionTypes.DELETE_DOCUMENT, payload: document } as Action);

export const setId = (id: string) => ({ type: ActionTypes.SET_ID, payload: id } as Action);
export const setVersion = (version: string) => ({ type: ActionTypes.SET_VERSION, payload: version } as Action);

type State = {
  links: LinkWithStatus[];
  documents: Document[];
  id: string;
  version?: string;
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.CLEAN_LINKS:
      return {
        ...state,
        links: [...state.links.filter((link) => link.status === UploadStatus.SUCCESS || link.status === UploadStatus.LOADING)],
      };
    case ActionTypes.CLEAN_DOCS:
      return {
        ...state,
        documents: [
          ...state.documents.filter(
            (document) => document.status === UploadStatus.SUCCESS || document.status === UploadStatus.LOADING,
          ),
        ],
      };
    case ActionTypes.ADD_LINK:
      return {
        ...state,
        links: [...state.links, payload],
      };
    case ActionTypes.SET_LINKS:
      return {
        ...state,
        links: payload,
      };
    case ActionTypes.SET_LINK: {
      const indexLink = state.links.findIndex((link) => link.name === payload.name && link.type === payload.type);

      if (indexLink >= 0) {
        state.links[indexLink] = { ...state.links[indexLink], status: payload.status };
      }

      return {
        ...state,
        links: [...state.links],
      };
    }
    case ActionTypes.SET_DOCUMENT: {
      const indexDocument = state.documents.findIndex(
        (document) => document.name === payload.name && document.type === payload.type,
      );

      if (indexDocument >= 0) {
        state.documents[indexDocument] = { ...state.documents[indexDocument], status: payload.status };
      }

      return {
        ...state,
        documents: [...state.documents],
      };
    }
    case ActionTypes.DELETE_LINK:
      return {
        ...state,
        links: [...state.links.filter((link) => link.name !== payload.name || link.type !== payload.type)],
      };
    case ActionTypes.DELETE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents.filter((document) => document.name !== payload.name || document.type !== payload.type)],
      };
    case ActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
      };
    case ActionTypes.ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, payload],
      };
    case ActionTypes.SET_ID:
      return {
        ...state,
        id: payload,
      };
    case ActionTypes.SET_VERSION:
      return {
        ...state,
        version: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export interface IActivitySpecificationContext {
  state: State;
  dispatch: Dispatch<Action>;
}

const ActivitySpecificationContext = createContext<IActivitySpecificationContext | undefined>(undefined);

const ActivitySpecificationProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <ActivitySpecificationContext.Provider value={value}>{children}</ActivitySpecificationContext.Provider>;
};

export { ActionTypes as ActivitySpecificationActionTypes, ActivitySpecificationContext, ActivitySpecificationProvider };
