import { useEffect, useState } from 'react';

import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useConnector from 'hooks/useConnector';
import useDiagramContext from 'hooks/useDiagramContext';
import useSelection from 'hooks/useSelection';
import useTool from 'hooks/useTool';
import { ConnectorEndpointType, ConnectorPorts as Ports } from 'types/connectors';
import { Tool } from 'types/diagram';

import styles from './ConnectorPorts.module.scss';

interface Props {
  symbolId: string;
  portStyles?: {
    topPortClass?: string;
    bottomPortClass?: string;
    leftPortClass?: string;
    rightPortClass?: string;
  };
}

const ConnectorPorts = ({ portStyles, symbolId }: Props) => {
  const { dispatch, fontSize, ghostConnector, ghostConnectorData, processData } = useDiagramContext();
  const { handlePortMouseDown, handlePortMouseUp } = useConnector();
  const { selectedConnector } = useSelection();
  const { tool } = useTool();
  const [topPortElem, setTopPortElem] = useState<SVGCircleElement | null>();
  const [leftPortElem, setLeftPortElem] = useState<SVGCircleElement | null>();
  const [rightPortElem, setRightPortElem] = useState<SVGCircleElement | null>();
  const [bottomPortElem, setBottomPortElem] = useState<SVGCircleElement | null>();
  const { topPortClass, bottomPortClass, leftPortClass, rightPortClass } = portStyles || {};
  const size = (fontSize || 1) * 2;
  const radius = (fontSize || 0) / 2.8;

  const handles = [
    { id: Ports.TOP, style: styles.Top, externalStyle: topPortClass, ref: topPortElem, refSetter: setTopPortElem },
    { id: Ports.LEFT, style: styles.Left, externalStyle: leftPortClass, ref: leftPortElem, refSetter: setLeftPortElem },
    { id: Ports.RIGHT, style: styles.Right, externalStyle: rightPortClass, ref: rightPortElem, refSetter: setRightPortElem },
    { id: Ports.BOTTOM, style: styles.Bottom, externalStyle: bottomPortClass, ref: bottomPortElem, refSetter: setBottomPortElem },
  ];

  useEffect(() => {
    if (!processData?.connectors?.length || !topPortElem || !leftPortElem || !rightPortElem || !bottomPortElem) {
      return;
    }
    const portMap = {
      [Ports.TOP]: topPortElem,
      [Ports.LEFT]: leftPortElem,
      [Ports.RIGHT]: rightPortElem,
      [Ports.BOTTOM]: bottomPortElem,
    };
    processData.connectors.forEach((connector) => {
      if (connector.source.id === symbolId && (!connector.source.node || !document.body.contains(connector.source.node))) {
        connector.source.node = portMap[connector.source.port];
      }
      if (connector.target.id === symbolId && (!connector.target.node || !document.body.contains(connector.target.node))) {
        connector.target.node = portMap[connector.target.port];
      }
    });
    dispatch({ type: DiagramActionTypes.UPDATE_PROCESS_DATA });
  }, [dispatch, processData?.connectors, topPortElem, leftPortElem, rightPortElem, bottomPortElem, symbolId]);

  return (
    <div className={styles.Container} style={{ fontSize }}>
      {handles.map(({ id, ref, refSetter, style, externalStyle }) => {
        const isSelected =
          selectedConnector?.source.id === symbolId &&
          selectedConnector?.source.port === id &&
          ghostConnectorData.current?.movingEndpointType !== ConnectorEndpointType.SOURCE;
        const isVisible =
          (ghostConnector?.source?.id === symbolId &&
            ghostConnector?.source?.port === id &&
            ghostConnectorData.current?.movingEndpointType !== ConnectorEndpointType.SOURCE) ||
          isSelected ||
          ghostConnector ||
          tool === Tool.CONNECT;
        const exist =
          isVisible ||
          (selectedConnector?.target?.id === symbolId &&
            selectedConnector?.target?.port === id &&
            ghostConnectorData.current?.movingEndpointType !== ConnectorEndpointType.TARGET);

        return (
          <svg
            className={`${styles.Wrapper} ${exist ? styles.Visible : ''} ${style} ${externalStyle || ''}`}
            id={`PORT-${symbolId}-${id}`}
            key={id}
            onMouseDown={(event) => handlePortMouseDown(event, { id: symbolId, node: ref, port: id })}
            onMouseUp={() => handlePortMouseUp({ id: symbolId, node: ref, port: id })}
            viewBox={`-${size / 2} -${size / 2} ${size} ${size}`}
          >
            <circle
              className={`${styles.Port} ${isVisible || ghostConnector ? styles.Visible : ''} ${isSelected ? styles.Active : ''}`}
              r={radius}
              ref={refSetter}
            />
          </svg>
        );
      })}
    </div>
  );
};

export default ConnectorPorts;
