import { useContext } from 'react';

import { ProcessLevelContext } from 'contexts/ProcessLevel/ProcessLevelContext';

export default function useProcessLevelDatabaseContext() {
  const context = useContext(ProcessLevelContext);

  if (context === undefined) {
    throw new Error('useProcessLevelContext must be used within a ProcessLevelDatabaseProvider');
  }

  const { state, dispatch } = context;

  return {
    ...state,
    dispatch,
  };
}
