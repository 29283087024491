import { ButtonProps } from 'types/config';

export enum DialogType {
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning',
  None = 'None',
}

export enum DialogIcon {
  Error = 'icon-blitz-fehler error',
  Info = 'icon-information info',
  Warning = 'icon-warnung-fehler warning',
  None = '',
}

export type DialogInfo = {
  buttons: ButtonProps[];
  title: string;
  type?: DialogType;
  isLegacyStyle?: boolean;
};

export enum DeleteDialogPrefixes {
  DATASET = 'dataSet',
  REQUIREMENT = 'requirement',
  ACTIVITY_SPECIFICATION = 'activitySpecification',
  PROCESS_LEVEL = 'processLevel',
  SIPOC = 'SIPOC',
  SWIMLANE = 'SWIMLANE',
}
