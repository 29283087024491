import React from 'react';

import styles from './ErrorLabel.module.scss';

const ErrorLabel = ({ visible, message }) =>
  visible && (
    <i className={`fas fa-exclamation-circle ${styles.ErrorLabel}`}>
      <span>{message}</span>
    </i>
  );

export default ErrorLabel;
