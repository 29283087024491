import { axiosInstance } from './serviceConstants';

const workflowUrlV2 = '/backend/ms-workflow/api/v2';

// eslint-disable-next-line import/prefer-default-export
export const approveWorkflow = (processNumber: string, data: any) =>
  axiosInstance.put(`${workflowUrlV2}/workflow/approve?processNumber=${processNumber}`, data);

export const backToGroupWorkflow = (processNumber: string, reasonChange: string) =>
  axiosInstance.put(`${workflowUrlV2}/workflow/back-to-group`, { reasonChange, processNumber });
