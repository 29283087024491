import React from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';

import styles from './ColorPopup.module.scss';

const ColorPopup = ({ nodeColor, setColor, popupOpened, isText }) => {
  const { t } = useTranslation();

  const handleResetColor = () => {
    let color = Constants.WHITE_COLOR;

    if (popupOpened === Constants.FILL_COLOR_POPUP && isText) {
      color = 'none';
    } else if (popupOpened === Constants.BORDER_COLOR_POPUP) {
      color = Constants.SHAPES_COLOR;
    } else if (popupOpened === Constants.TEXT_COLOR_POPUP) {
      color = Constants.BLACK_COLOR;
    }

    setColor(color);
  };

  return (
    <div className={`${styles.ColorPopup} ${styles[popupOpened]}`}>
      <div className={styles.Content}>
        <div className={styles.Colors}>
          {Constants.POPUP_NODE_COLORS.map((color) => (
            <div
              className={styles.Color}
              key={`color_${color.color}`}
              onClick={() => setColor(color.color)}
              style={{ backgroundColor: color.color }}
            >
              {nodeColor === color.color ? <i className="di icon-check" style={{ color: color.checkColor }} /> : ''}
            </div>
          ))}
        </div>
        <button className={styles.Button} disabled={!nodeColor} id="no-color" onClick={handleResetColor} type="button">
          <i className="di icon-ablehnen" />
          {t('diagram.formattingTools.noColor')}
        </button>
      </div>
    </div>
  );
};

export default ColorPopup;
