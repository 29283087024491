import React from 'react';

import { useTranslation } from 'react-i18next';

import { ENGLISH, ROLE } from 'assets/constants/constants';

import styles from './DialogRequestRole.module.scss';

const DialogRequestPendingRole = (props) => {
  const { pendingObjects, objectType } = props;
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('requestedX', { labelX: t(objectType === ROLE ? 'roles' : 'IT-System') })}</h4>
      {pendingObjects ? (
        pendingObjects.map((elem) => {
          const attributes = JSON.parse(elem.attributes);

          return (
            <div key={attributes[ENGLISH].OBJECT_NAME + Math.random()}>
              <p>
                {t('RequestOn')} {attributes[ENGLISH].CREATION_DATE}
              </p>
              <div className={styles.Request}>
                <i className="di icon-computer-laptop" />
                <span className={styles.TextRequestPending}>{attributes[ENGLISH].OBJECT_NAME}</span>
                <i className={`di icon-uhr-zeit ${styles.ClockIco}`} />
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default DialogRequestPendingRole;
