import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { PROCESS_LEVEL, SUCCESS_NEPOS } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import useToastContext from 'components/UI/ToastContext/useToastContext';
import { setLoadingProcess, setCatalog } from 'contexts/ProcessLevel/ProcessLevelContext';
import {
  createProcessLevel,
  editProcessLevel,
  getAllProcesses,
  getProcessesByLevel,
  deleteProcessLevel,
} from 'services/processLevelDatabase';
import { AttributeCode, Attributes, AttributeValue, LanguageAttributes } from 'types/forms';
import {
  Levels,
  ProcessLevelFormatted,
  ProcessLevelRaw,
  ProcessLevelSelectionTableRow,
  ProcessLevelTree,
} from 'types/processLevel';
import { MultiDepartmentCatalogUser } from 'types/user';

import useFormTypes from './useFormTypes';
import useProcessLevelDatabaseContext from './useProcessLevelContext';

export default function useProcessLevelDatabase() {
  const { dispatch } = useProcessLevelDatabaseContext();
  const { t } = useTranslation();
  const addToast = useToastContext();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const firstLevel = 1;

  const levelMap = new Map([
    [1, Levels.LEVEL_1],
    [2, Levels.LEVEL_2],
    [3, Levels.LEVEL_3],
  ]);

  const getArrayFromStringArray = (stringArray: string): string[] =>
    stringArray
      .replace('[', '')
      .replace(']', '')
      .split(',')
      .map((e) => e.trim());

  const getUsersWithCatalog = (usersArray: string[], catalog: MultiDepartmentCatalogUser[], isDepartmentNeeded: boolean) => {
    if (usersArray.length === 0 || usersArray.filter((e) => e === '').length === usersArray.length) return [];
    const users = usersArray.map((e) => catalog.find((element) => e === element.code));
    if (!users) return [];

    return users.map((user) => {
      const userString = user ? `${user.commonName} (${user.code})` : '';
      const userDepartmentString = user && isDepartmentNeeded ? ` · ${user.departments?.[0]}` : '';
      return user ? `${userString} ${userDepartmentString}` : '';
    });
  };

  const getDepartmentsWithCatalog = (usersArray: string[], catalog: MultiDepartmentCatalogUser[]) => {
    if (usersArray.length === 0 || usersArray.filter((e) => e === '').length === usersArray.length) return [];
    const users = usersArray.map((e) => catalog.find((element) => e === element.code));
    if (!users) return [];

    return users.map((user) => {
      return user ? user.departments : '';
    });
  };

  const getUsersFromString = useCallback(
    (usersString: AttributeValue | undefined, catalog: MultiDepartmentCatalogUser[], isDepartmentNeeded: boolean): string[] => {
      const users = getArrayFromStringArray(usersString as string);
      if (!users) return [];

      return getUsersWithCatalog(users, catalog, isDepartmentNeeded);
    },
    [],
  );

  const getDepartmentFromUsersString = useCallback(
    (usersString: AttributeValue | undefined, catalog: MultiDepartmentCatalogUser[]) => {
      const users = getArrayFromStringArray(usersString as string);
      const departments = getDepartmentsWithCatalog(users, catalog);

      return departments;
    },
    [],
  );

  const formatProcessLevel = useCallback(
    (diagrams: ProcessLevelTree[], catalog: MultiDepartmentCatalogUser[], isSelection: boolean): ProcessLevelFormatted[] => {
      if (!diagrams) return [];

      return diagrams.map((elem) => {
        const { attributes, children, wave } = elem;
        const { RESPONSIBLE_PERSONS, RESPONSIBLE_PACEMAKERS, RESPONSIBLE_COORDINATORS, PROCESS_TITLE } = {
          ...attributes.NOT_TRANSLATABLE,
        };

        const processResponsibles = getUsersFromString(RESPONSIBLE_PERSONS, catalog, !isSelection);
        const pacemakers = getUsersFromString(RESPONSIBLE_PACEMAKERS, catalog, true);
        const processCoordinators = getUsersFromString(RESPONSIBLE_COORDINATORS, catalog, true);
        const department = getDepartmentFromUsersString(RESPONSIBLE_PERSONS, catalog);

        return {
          ...elem,
          title: PROCESS_TITLE as string,
          coordinators: RESPONSIBLE_COORDINATORS,
          wave: wave.toString(),
          processResponsibles,
          pacemakers,
          processCoordinators,
          department,
          children: formatProcessLevel(children, catalog, isSelection),
        };
      });
    },
    [getDepartmentFromUsersString, getUsersFromString],
  );

  const treeNestingFormatter = (currentDiagrams: ProcessLevelRaw[], parentId: number, level: number): ProcessLevelTree[] => {
    const currentLevel = level + firstLevel;
    return currentDiagrams
      .filter((currentDiagram) => currentDiagram.parentId === parentId)
      .map((currentDiagram) => {
        return {
          ...currentDiagram,
          level: currentLevel,
          children: treeNestingFormatter(currentDiagrams, Number(currentDiagram.id), currentLevel),
        };
      });
  };

  const treeFormatter = (diagrams: ProcessLevelRaw[]): ProcessLevelTree[] => {
    const highestLevelDiagrams: ProcessLevelRaw[] = [];
    const dependentDiagrams: ProcessLevelRaw[] = [];
    diagrams.forEach((diagram) => {
      if (diagram.parentId === undefined || diagram.parentId === null) {
        highestLevelDiagrams.push(diagram);
      } else {
        dependentDiagrams.push(diagram);
      }
    });

    const formattedDiagrams = highestLevelDiagrams.map((diagram) => {
      return {
        ...diagram,
        level: firstLevel,
        children: treeNestingFormatter(dependentDiagrams, Number(diagram.id), firstLevel),
      };
    });

    return formattedDiagrams;
  };

  const fetchAllProcesses = useCallback(
    async (isSelection: boolean) => {
      dispatch(setLoadingProcess(true));
      try {
        const { data } = await getAllProcesses();
        const { results, catalog } = data;
        dispatch(setCatalog(catalog));

        const formattedDiagrams = treeFormatter(results);
        const finalResults = formatProcessLevel(formattedDiagrams, catalog.USER as MultiDepartmentCatalogUser[], isSelection);

        dispatch(setLoadingProcess(false));
        return { finalResults, catalog };
      } catch (err) {
        handleServiceError(err);
        dispatch(setLoadingProcess(false));
      }
    },
    [dispatch, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getFinalResults = useCallback(
    async (data) => {
      dispatch(setLoadingProcess(true));
      try {
        const { results, catalog } = data;
        const finalResults = formatProcessLevel(results, catalog.USER, true);

        dispatch(setLoadingProcess(false));
        return finalResults;
      } catch (err) {
        dispatch(setLoadingProcess(false));
        handleServiceError(err);
      }
    },
    [dispatch, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchProcessLevelForm = async (type: string) => {
    try {
      fetchFormTypes(
        getFormTypesCode({
          type: PROCESS_LEVEL,
          actionType: type,
        }),
      );
    } catch (err) {
      handleServiceError(err);
    }
  };

  const formatProcessLevelBeforeCreate = (attributes: Attributes, parentId: string): any | undefined => {
    const formatResponsibleFields = (attributeCode: AttributeCode) => {
      const currentAttribute = attributes.NOT_TRANSLATABLE?.[attributeCode];
      if (!currentAttribute) return;

      return currentAttribute.length > 0 ? currentAttribute : '[]';
    };

    const data: { id?: number; wave?: number; attributes?: Attributes; category?: string; parentId?: string } = {
      attributes: {
        DE: {},
        EN: {},
        NOT_TRANSLATABLE: {
          PROCESS_TITLE: attributes.NOT_TRANSLATABLE?.TITLE,
          RESPONSIBLE_PERSONS: formatResponsibleFields(AttributeCode.RESPONSIBLE),
          RESPONSIBLE_PACEMAKERS: formatResponsibleFields(AttributeCode.PL_PACEMAKER),
          RESPONSIBLE_COORDINATORS: formatResponsibleFields(AttributeCode.COORDINATOR),
        },
      },
      wave: Number(attributes.NOT_TRANSLATABLE?.PL_WAVE as string),
      category: attributes.NOT_TRANSLATABLE?.CATEGORY as string,
      parentId,
    };

    return data;
  };

  const createNewProcessLevel = async (attributes: Attributes, parentId: string) => {
    const data = formatProcessLevelBeforeCreate(attributes, parentId);
    if (!data) return;

    await createProcessLevel(data)
      .then(() => addToast(t('processLevelAdded'), SUCCESS_NEPOS))
      .catch((error) => handleServiceError(error));
  };

  const extractCodesFromUsers = (usersString: string): string | undefined => {
    if (!usersString || usersString.length === 0) return '[]';
    const userNamesArray = usersString.split(',');
    if (!userNamesArray) return '[]';

    const userCodesArray = userNamesArray.map((userCode) => {
      if (!userCode || userCode.length === 0) return '';

      const firstCodePosition = userCode.search('[(]');
      const secondCodePosition = userCode.search('[)]');
      const finalUserCode = userCode.substring(firstCodePosition + 1, secondCodePosition);

      if (!finalUserCode || finalUserCode.length === 0) return '';
      return finalUserCode;
    });

    return userCodesArray.length === 1 && (userCodesArray[0].length === 0 || userCodesArray[0] === '')
      ? undefined
      : `[${userCodesArray.toString()}]`;
  };

  const formatProcessToAttributeValues = (selectedProcessLevel: ProcessLevelFormatted, fatherItem?: ProcessLevelFormatted) => {
    const processResponsibles = extractCodesFromUsers(selectedProcessLevel.processResponsibles.toString());
    const pacemakers = extractCodesFromUsers(selectedProcessLevel.pacemakers.toString());
    const coordinators = extractCodesFromUsers(selectedProcessLevel.processCoordinators.toString());

    const newValues: Attributes = {
      NOT_TRANSLATABLE: {
        CATEGORY: selectedProcessLevel.category,
        PL_WAVE: selectedProcessLevel.wave,
        LEVEL: levelMap.get(selectedProcessLevel.level),
        LEVEL1_PROCESS: selectedProcessLevel.level === 2 ? fatherItem?.title : undefined,
        LEVEL2_PROCESS: selectedProcessLevel.level === 3 ? fatherItem?.title : undefined,
        TITLE: selectedProcessLevel.title,
        RESPONSIBLE: processResponsibles,
        COORDINATOR: coordinators,
        PL_PACEMAKER: pacemakers,
      } as LanguageAttributes,
    };

    return newValues;
  };

  const formatProcessLevelBeforeUpdate = (
    processLevelAttributes: Attributes,
    selectedProcessLevel: ProcessLevelFormatted,
    selectedLinkedLevel?: string,
  ): { id: number; wave?: number; attributes?: Attributes; category?: string; parentId?: number } | undefined => {
    const currentAttributes = processLevelAttributes.NOT_TRANSLATABLE;
    if (!currentAttributes) return;

    const data: { id: number; wave?: number; attributes?: Attributes; level?: string; category?: string; parentId?: number } = {
      id: Number(selectedProcessLevel.id),
      attributes: {
        NOT_TRANSLATABLE: {
          PROCESS_TITLE: currentAttributes.TITLE,
          RESPONSIBLE_PERSONS: currentAttributes.RESPONSIBLE,
          RESPONSIBLE_PACEMAKERS: currentAttributes.PL_PACEMAKER,
          RESPONSIBLE_COORDINATORS: currentAttributes.COORDINATOR,
        },
      },
      wave: currentAttributes.PL_WAVE && currentAttributes.PL_WAVE.length > 0 ? Number(currentAttributes.PL_WAVE) : undefined,
      level: levelMap.get(selectedProcessLevel.level),
      category: currentAttributes.CATEGORY as string,
      parentId: selectedLinkedLevel ? Number(selectedLinkedLevel) : undefined,
    };

    return data;
  };

  const updateProcessLevel = async (
    currentAttributes: Attributes,
    selectedProcess?: ProcessLevelFormatted,
    selectedLinkedLevel?: string,
  ) => {
    if (!currentAttributes || !selectedProcess) return;

    const data = formatProcessLevelBeforeUpdate(currentAttributes, selectedProcess, selectedLinkedLevel);
    if (!data) return;

    await editProcessLevel(data)
      .then(() => addToast(t('processLevelUpdated'), SUCCESS_NEPOS))
      .catch((err) => handleServiceError(err));
  };

  const deleteExistingProcessLevel = async (id: string, confirmingValue: string) => {
    return deleteProcessLevel(id, confirmingValue).catch((err) => handleServiceError(err));
  };
  const getAllProcessesByLevel = async (level: number) => {
    let processesByLevel: ProcessLevelSelectionTableRow[] | undefined;
    await getProcessesByLevel(level)
      .then((res) => {
        const { results, catalog } = res.data;
        processesByLevel = results.map((process: { id: number; wave: number; category: string; attributes: Attributes }) => {
          const users = getUsersFromString(process.attributes.NOT_TRANSLATABLE?.RESPONSIBLE_PERSONS, catalog.USER, false);
          const department = getDepartmentFromUsersString(process.attributes.NOT_TRANSLATABLE?.RESPONSIBLE_PERSONS, catalog.USER);

          return {
            id: process.id,
            processTitle: process.attributes.NOT_TRANSLATABLE?.PROCESS_TITLE,
            processLevel: level,
            processResponsibles: users,
            department,
          };
        });
      })
      .catch((err) => handleServiceError(err));

    return processesByLevel;
  };

  return {
    createNewProcessLevel,
    fetchAllProcesses,
    fetchProcessLevelForm,
    formatProcessLevel,
    formatProcessToAttributeValues,
    getAllProcessesByLevel,
    getFinalResults,
    treeFormatter,
    updateProcessLevel,
    deleteExistingProcessLevel,
    getUsersWithCatalog,
  };
}
