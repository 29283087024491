import { forwardRef } from 'react';

import styles from './TextButton.module.scss';

type TextButtonProps = {
  icon?: string;
  id: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  dataQA?: string;
  disabled?: boolean;
  text: string;
};

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ icon, id, dataQA = id, onClick, className, disabled, text }, ref) => (
    <button
      className={`${styles.Button} ${className}`}
      data-qa={dataQA}
      disabled={disabled}
      id={id}
      onClick={onClick}
      ref={ref}
      type="button"
    >
      {icon && <i className={icon} />}
      {text}
    </button>
  ),
);

export default TextButton;
