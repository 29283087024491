import { useTranslation } from 'react-i18next';

import { RANGE_OF_YEARS } from 'assets/constants/constants';
import { generateYearsArray } from 'assets/js/Utils';
import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import DropdownNEPOS from 'components/UI/Dropdown/DropdownNEPOS';
import InputTextNEPOS from 'components/UI/InputText/InputTextNEPOS';
import { AttributeValue, VariantProperty } from 'types/forms';
import { RequirementFinancialEvaluation } from 'types/requirement';
import { TableColumn } from 'types/tables';

import styles from './FinancialEvaluationTable.module.scss';

type Props = {
  rows: RequirementFinancialEvaluation[];
  columns: TableColumn<RequirementFinancialEvaluation>[];
  onChange: (value: string, rowId: string, columnId: string) => void;
  getValue: (code: string, value?: AttributeValue, variant?: VariantProperty) => string;
  inputValue: { value: string; rowId: string; columnId: string };
  handleCheck: (itemId: string) => void;
  selectedElements: string[];
  isCheckedAll: boolean;
  disabled: boolean;
};

const FinancialEvaluationTable = (props: Props) => {
  const { rows, columns, onChange, getValue, inputValue, handleCheck, selectedElements, isCheckedAll, disabled } = props;
  const { t } = useTranslation();
  const headers = [
    'financial-evaluation.year',
    'requirements.requiredInvest',
    'financial-evaluation.annualEbitEffect',
    'financial-evaluation.oneTimeCashflowEffect',
  ];

  return (
    <div className={styles.Container}>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.Row}>
            <th>
              <Checkbox checked={isCheckedAll} disabled={disabled} handleCheck={() => handleCheck('all')} id="header-checkbox" />
            </th>
            {headers.map((header) => {
              return (
                <th className={`${styles.Cell} ${styles.Header}`} key={header}>
                  {t(header)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((r: any) => {
            return (
              <tr className={styles.Row} id={r.id} key={r.id}>
                <td>
                  <Checkbox
                    checked={selectedElements.some((elem) => elem === r.id)}
                    disabled={disabled}
                    handleCheck={() => handleCheck(r.id)}
                    id={`checkbox-${r.id}`}
                  />
                </td>
                {columns.map((element: { id: string }) => {
                  const elementValue =
                    element.id === inputValue.columnId && r.id === inputValue.rowId ? inputValue.value : r[element.id];

                  return (
                    <td className={styles.Cell} key={element.id}>
                      {element.id === 'year' ? (
                        <DropdownNEPOS
                          className={`${styles.Input} `}
                          code="year"
                          disabled={disabled}
                          id={element.id}
                          label=" "
                          onChange={(value) => onChange(value, r.id, element.id)}
                          options={generateYearsArray(RANGE_OF_YEARS)}
                          value={elementValue}
                        />
                      ) : (
                        <InputTextNEPOS
                          className={styles.Input}
                          disabled={disabled}
                          getValue={() => getValue('', elementValue, VariantProperty.DOTS_DECIMAL)}
                          id={element.id}
                          key={element.id}
                          label=" "
                          onChange={(value) => onChange(value, r.id, element.id)}
                          value={elementValue}
                          variant={VariantProperty.DOTS_DECIMAL}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FinancialEvaluationTable;
