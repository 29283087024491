import React, { useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';
import { FormGenerator } from 'react-reactive-form';

import * as Constants from '../../assets/constants/constants';
import dialogStyles from '../DialogDetail/DialogDetail.module.scss';
import Button from '../UI/Button/Button';
import styles from './DelegateWorkflowDialog.module.scss';

const DelegateWorkflowDialog = (props) => {
  const { close, t, currentUser, delegateFieldConfig, delegateUser } = props;
  const [fieldsFilled, setFieldsFilled] = useState(false);
  const [newUserCode, setNewUserCode] = useState('');
  const [reason, setReason] = useState('');
  let form;

  const checkForm = () => {
    let formFilled = false;

    if (form.get(Constants.NEW_USER) && form.get(Constants.NEW_USER).value?.length > 0 && form.get(Constants.REASON).value) {
      formFilled = true;
    } else if (form.get(Constants.NEW_GROUP) && form.get(Constants.NEW_GROUP).value && form.get(Constants.REASON).value) {
      formFilled = form.get(Constants.NEW_GROUP).value !== currentUser;
    }

    setFieldsFilled(formFilled);
  };

  const onMount = (mountedForm) => {
    form = mountedForm;
    const currentField = form.get(Constants.CURRENT_GROUP) ? Constants.CURRENT_GROUP : Constants.CURRENT_USER;

    form.patchValue({ [currentField]: currentUser });

    form.valueChanges.subscribe(() => {
      checkForm();
    });

    if (form.get(Constants.NEW_USER)) {
      form.get(Constants.NEW_USER).valueChanges.subscribe((value) => {
        const userCode = value[0] ? value[0].Code : '';
        setNewUserCode(userCode);
      });
    }

    if (form.get(Constants.REASON)) {
      form.get(Constants.REASON).valueChanges.subscribe((value) => {
        setReason(value);
      });
    }

    if (form.get(Constants.NEW_GROUP)) {
      form.get(Constants.NEW_GROUP).valueChanges.subscribe((value) => {
        setNewUserCode(value);
      });
    }
  };

  const handleClick = () => {
    close();
    delegateUser(newUserCode, reason);
  };

  return (
    <DialogComponent
      className={dialogStyles.DialogDetail}
      close={close}
      height="480px"
      id="dialog-detail"
      isModal
      target="#dialog"
      visible
      width="510px"
    >
      <div className={dialogStyles.Content}>
        <div className={styles.Section}>
          <div className={styles.Header}>
            <h4>{t('workflowDialog.title')}</h4>
          </div>
          <div className={styles.Form}>
            <p>{t('workflowDialog.description')}</p>
            <FormGenerator fieldConfig={delegateFieldConfig} onMount={onMount} />
          </div>
          <div className={styles.ButtonLine}>
            <Button btnText={t('cancel')} buttonStyle="Secondary" handleClick={close} id="cancel" />
            <Button
              btnText={t('delegate')}
              buttonStyle="Primary"
              disabled={!fieldsFilled}
              handleClick={() => handleClick()}
              id="link"
            />
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default React.memo(
  withTranslation('common')(DelegateWorkflowDialog),
  (prev, next) => JSON.stringify(prev.delegateFieldConfig) === JSON.stringify(next.delegateFieldConfig),
);
