import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { NOT_TRANSLATABLE, STATUS } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { cloneObject, parseWorkflowDataResponse } from 'assets/js/Utils';
import { DatasetActionTypes } from 'contexts/Dataset/DatasetContext';
import useFormTypes from 'hooks/useFormTypes';
import { createDataset, deleteDataset, getDataset, getPublishedDataset, updateDataset } from 'services/dataset';
import { getDatasetworkflowData, getDatasetWorkflowTypes } from 'services/datasetWorkflow';
import { DatasetData } from 'types/dataset';
import { Attributes } from 'types/forms';
import { ProcessType } from 'types/processes';

import useDatasetContext from './useDatasetContext';
import useFeatureFlags from './useFeatureFlags';

export default function useDataset() {
  const history = useHistory();
  const { dispatch } = useDatasetContext();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const { isFreezed } = useFeatureFlags();

  const createNewDataset = useCallback(() => {
    dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });
    createDataset()
      .then((response) => {
        dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
        history.push(`/dataset/${response.data.id}`);
      })
      .catch((error) => {
        dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
        handleServiceError(error);
      });
  }, [dispatch, history]);

  const fetchDataset = useCallback(
    async (idDataset: number) => {
      dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });

      try {
        const { data } = await getDataset(idDataset);

        fetchFormTypes(
          getFormTypesCode({
            status: data.status,
            type: ProcessType.DATA_SET,
          }),
        );

        if (data.status === STATUS.WORKFLOW) {
          const { data: workflowData } = await getDatasetworkflowData(parseInt(data.id, 10), data.version);
          const wfStagesResponse = await getDatasetWorkflowTypes(workflowData.type.code);

          dispatch({
            type: DatasetActionTypes.SET_WORKFLOW_DATA,
            payload: { ...parseWorkflowDataResponse(workflowData, wfStagesResponse.data), type: workflowData.type.code },
          });
        }
        if (isFreezed) {
          data.dataSetInUse.blocked = true;
        }
        dispatch({ type: DatasetActionTypes.SET_DATASET, payload: data });
      } catch (err) {
        handleServiceError(err);
      }
      dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
    },
    [dispatch, fetchFormTypes, getFormTypesCode],
  );

  const fetchPublishedDataset = useCallback(
    async (idDataset: number) => {
      dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });

      try {
        const { data } = await getPublishedDataset(idDataset, 'last');

        fetchFormTypes(
          getFormTypesCode({
            status: data.status,
            type: ProcessType.DATA_SET,
          }),
        );

        dispatch({ type: DatasetActionTypes.SET_DATASET, payload: data });
      } catch (err) {
        handleServiceError(err);
      }
      dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
    },
    [dispatch, fetchFormTypes, getFormTypesCode],
  );

  const saveDataset = useCallback(
    async (data: DatasetData | undefined, unLinkDiagram?: boolean) => {
      if (data) {
        dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });
        try {
          const initialAttributes: Attributes = cloneObject(data.attributes);
          if (unLinkDiagram) {
            initialAttributes[NOT_TRANSLATABLE] = {
              ...initialAttributes[NOT_TRANSLATABLE],
              LINKED_DIAGRAM: '',
            };
          }
          await updateDataset({
            id: data.id,
            diagramId: unLinkDiagram ? undefined : data.linkedDiagram?.id,
            status: data.status,
            version: data.version,
            attributes: initialAttributes,
          });

          dispatch({ type: DatasetActionTypes.SET_DATASET, payload: data });
        } catch (err) {
          handleServiceError(err);
        }
        dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
      }
    },
    [dispatch],
  );

  const deleteExistingDataset = useCallback(
    (idDataset: number, confirmationValue: string) => {
      dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: true });

      return deleteDataset(idDataset, confirmationValue)
        .then(() => {
          dispatch({ type: DatasetActionTypes.DELETE_DATASET });
          dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
          history.push('/datasets');
        })
        .catch((err) => {
          dispatch({ type: DatasetActionTypes.SET_LOADING_DATASET, payload: false });
          handleServiceError(err);
        });
    },
    [dispatch, history],
  );

  return {
    createNewDataset,
    deleteExistingDataset,
    fetchDataset,
    fetchPublishedDataset,
    saveDataset,
  };
}
