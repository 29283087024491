import { Attributes } from 'types/forms';
import { ProcessLevelResponse } from 'types/processLevel';

import { axiosInstance } from './serviceConstants';

const requestUrl = '/backend/ms-request/api/v1';

export const getAllProcesses = () => {
  return axiosInstance.get<ProcessLevelResponse>(`${requestUrl}/process/all`);
};

export const createProcessLevel = (data: { wave?: number; attributes?: Attributes; category?: string; parentId?: number }) =>
  axiosInstance.post(`${requestUrl}/process`, data);

export const editProcessLevel = (data: {
  id: number;
  wave?: number;
  attributes?: Attributes;
  category?: string;
  parentId?: number;
}) => axiosInstance.put(`${requestUrl}/process`, data);

export const getProcessesByLevel = (level: number) => axiosInstance.get(`${requestUrl}/process/level/${level}`);

export const exportProcess = (params = {}) =>
  axiosInstance.get(`${requestUrl}/process/export`, { responseType: 'blob', params: { ...params } });

export const getResponibleInProcesses = () => axiosInstance.get(`${requestUrl}/process/responsible-in`);

export const getAllProcessesWithResponsiblePerson = () => axiosInstance.get(`${requestUrl}/process/responsible-person-assigned`);

export const deleteProcessLevel = (id: string, confirmationString: string) =>
  axiosInstance.delete(`${requestUrl}/process/${id}`, { data: { confirmationString } });

export const getHistoric = (id: string, page: number = 0, size: number = 10) =>
  axiosInstance.get(`${requestUrl}/process/${id}/changelog?page=${page}&size=${size}`);
