import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ATTRIBUTE_CARES, BUTTON_PRIMARY, BUTTON_SECONDARY } from 'assets/constants/constants';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogConfirm from 'components/UI/DialogBasics/DialogConfirm';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import DropdownForm from 'components/UI/Dropdown/DropdownForm';
import InputFile from 'components/UI/InputFile/InputFile';
import ScrollBox from 'components/UI/ScrollBox/ScrollBox';
import useError from 'hooks/useError';
import useLocalization from 'hooks/useLocalization';
import diagramServices from 'services/diagramService';

import styles from './DialogImport.module.scss';

const DialogImport = ({ close, diagramId }) => {
  const { t } = useTranslation();
  const { availableLanguages } = useLocalization();
  const history = useHistory();
  const { handleServiceError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [file, setFile] = useState();
  const languageOptions = availableLanguages.map((option) => ({
    id: option,
    value: t(`language.${option}`),
    text: t(`language.${option}`),
    selected: false,
  }));

  const importXML = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const selectedLanguageCode = languageOptions.find((lang) => lang.value === selectedLanguage).id;
      await diagramServices.importDiagramXML(diagramId, formData, selectedLanguageCode);
      setIsLoading(false);
      setIsWarningDialogOpen(false);
      setIsConfirmationDialogOpen(true);
    } catch (error) {
      setIsLoading(false);
      setIsWarningDialogOpen(false);
      handleServiceError(error);
    }
  };

  const handleConfirm = () => {
    setIsLoading(true);
    history.go(0);
  };

  const buttons = [
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: close,
      key: 'cancel',
    },
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('language.dialog.import.name'),
      disabled: !selectedLanguage || !file,
      handleClick: () => setIsWarningDialogOpen(true),
      key: 'confirm',
    },
  ];

  return (
    <Dialog>
      <DialogHeader>{t('language.dialog.import.title')}</DialogHeader>
      <ScrollBox>
        <div className={styles.Container}>
          <DropdownForm
            meta={{
              label: `${t('language.dialog.import.language.label')}*`,
              placeholder: t('language.dialog.import.language.placeholder'),
              options: languageOptions,
              extraClass: 'import',
            }}
            setValue={setSelectedLanguage}
          />
          <InputFile
            extension=".xml"
            hasError={false}
            meta={{
              attributeType: ATTRIBUTE_CARES.MANDATORY,
              buttonLabel: t('language.dialog.import.file.button'),
              dropzoneLabel: t('language.dialog.import.file.dropzone'),
              id: 'DialogImport-InputFile',
              label: t('language.dialog.import.file.label'),
            }}
            setValue={setFile}
            touched={false}
          />
        </div>
      </ScrollBox>
      <DialogFooter buttons={buttons} />
      {isWarningDialogOpen && (
        <DialogConfirm
          close={() => setIsWarningDialogOpen(false)}
          handleConfirm={importXML}
          isLoading={isLoading}
          title={
            <>
              <i className="fas fa-exclamation-triangle" />
              {`${t('warningText')}!`}
            </>
          }
        >
          {t('language.dialog.import.warning')}
        </DialogConfirm>
      )}
      {isConfirmationDialogOpen && (
        <DialogWarning handleConfirm={handleConfirm} isLoading={isLoading} title={t('success')}>
          {t('language.dialog.import.confirmation')}
        </DialogWarning>
      )}
    </Dialog>
  );
};

export default DialogImport;
