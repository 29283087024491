import React, { useState } from 'react';

import styles from './Collapse.module.scss';

export const Collapse = (props) => {
  const { children, title, defaultExpanded } = props;
  const [isExpanded, setIsExpanded] = useState(Boolean(defaultExpanded));

  const onClickTitle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className={styles.Title} onClick={onClickTitle}>
        <i className={`fas fa-chevron-down ${styles.Chevron} ${isExpanded && styles.Expanded}`} />
        <span className={styles.Text}>{title}</span>
      </div>
      {isExpanded && <div className={styles.List}>{children}</div>}
    </div>
  );
};

export default Collapse;
