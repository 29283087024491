import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import { SearchBy } from 'types/forms';
import { ProcessDataCatalog } from 'types/processes';

import DropdownPanel from '../DropdownPanel/DropdownPanel';
import InputTextNEPOS from '../InputText/InputTextNEPOS';
import stylesNepos from './InputSearchNEPOS.module.scss';
import stylesLegacy from './InputSearchNEPOSLegacyStyle.module.scss';

interface InputSearchProps<T> {
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  error?: string;
  legacyStyles?: boolean;
  onChange: (value: string) => void;
  service: (value: string) => Promise<any>;
  displayValue?: string;
  displayValueFormatter: (item: T) => string;
  onBlur?: () => void;
}

const InputSearchNEPOS = <T extends { code: string; commonName?: string; departments?: string[]; linkTeams?: string }>(
  props: InputSearchProps<T>,
) => {
  const { id, label, required, disabled, onChange, service, displayValue, displayValueFormatter, onBlur, legacyStyles } = props;
  const { t } = useTranslation();
  const { processData, dispatch } = useDiagramContext();
  const { handleServiceError } = useError();
  const containerRef = useRef<HTMLLabelElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const searchResultsRef = useRef<HTMLUListElement>(null);
  const intervalRef = useRef<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [currentDisplayValue, setCurrentDisplayValue] = useState<string | undefined>(displayValue);
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const styles = legacyStyles ? stylesLegacy : stylesNepos;

  if (displayValue && !currentDisplayValue) setCurrentDisplayValue(displayValue);

  const fetchOptions = () => {
    setIsSearchLoading(true);
    return service(searchText)
      .then((res) => {
        setSearchResults(res.data.results);
        setIsSearchLoading(false);
      })
      .catch((err) => {
        handleServiceError(err);
        setIsSearchLoading(false);
      });
  };

  useEffect(() => {
    clearTimeout(intervalRef.current as number);

    if (!searchText.trim()) {
      setSearchResults([]);
      return;
    }

    intervalRef.current = window.setTimeout(() => {
      fetchOptions().then(() => window.clearTimeout(intervalRef.current as number));
    }, 300);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (item?: T) => {
    setSearchText('');
    setCurrentDisplayValue(item ? displayValueFormatter(item) : '');
    onChange(item ? item.code : '');
    setIsOpen(false);
  };

  const handleInputChange = (element: string) => {
    if (!displayValue) setSearchText(element);
    if (element !== '') setIsOpen(!displayValue);
  };

  const handleSelect = (item: T) => {
    if (!textInputRef.current) return;
    handleChange(item);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (currentDisplayValue) {
      if (!textInputRef.current || event.key !== 'Backspace') return;
      handleChange();
    } else {
      if (!searchResultsRef.current || event.key !== 'ArrowDown') return;
      event.preventDefault();
      (searchResultsRef.current.firstElementChild as HTMLLIElement).focus();
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    const active = document.activeElement;
    if (!['ArrowUp', 'ArrowDown'].includes(event.key) || active === null) return;
    event.preventDefault();

    if (event.key === 'ArrowUp') {
      if (active === searchResultsRef.current?.firstElementChild) textInputRef.current?.focus();
      else (active.previousElementSibling as HTMLLIElement).focus();
    }

    if (event.key === 'ArrowDown' && active !== searchResultsRef.current?.lastElementChild) {
      (active.nextElementSibling as HTMLLIElement).focus();
    }
  };

  useEffect(() => {
    const element = searchResults.find((elem) => currentDisplayValue?.includes(elem.code));

    if (!processData || !element) return;

    const newCatalog = {
      [SearchBy.USER]: [
        {
          code: element.code,
          commonName: element.commonName,
          departments: element.departments,
          linkTeams: element.linkTeams,
        },
      ],
    };

    if (!processData.catalog) return;

    const existingCatalog = processData.catalog;

    let payload: ProcessDataCatalog = {
      ...existingCatalog,
      ...newCatalog,
    };

    if (existingCatalog[SearchBy.USER]) {
      const existingElement = existingCatalog[SearchBy.USER].find((elem) => currentDisplayValue?.includes(elem.code));
      if (!existingElement) {
        existingCatalog[SearchBy.USER].push({
          code: element.code,
          commonName: element.commonName || '',
          departments: element.departments,
          linkTeams: element.linkTeams,
        });
      }
      payload = existingCatalog;
    }

    dispatch({
      type: DiagramActionTypes.UPDATE_PROCESS_CATALOG,
      payload,
    });
  }, [currentDisplayValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <InputTextNEPOS
        containerRef={containerRef}
        disabled={disabled}
        error={props.error}
        id={id}
        label={label}
        legacyStyles={legacyStyles}
        onBlur={onBlur}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        reference={textInputRef}
        required={required}
        value={currentDisplayValue || searchText}
      />
      {isOpen && (
        <DropdownPanel
          className={styles.Panel}
          close={() => {
            setSearchText('');
            setIsOpen(false);
          }}
          parentRef={containerRef}
        >
          {isSearchLoading || !searchResults.length ? (
            <div className={styles.Placeholder}>{isSearchLoading ? t('loading') : t('multiselection.noRecordsFound')}</div>
          ) : (
            <ul className={styles.List} onKeyDown={handleListKeyDown} ref={searchResultsRef}>
              {searchResults.map((item, index) => (
                <li
                  className={styles.Item}
                  key={item.code}
                  onClick={() => handleSelect(item)}
                  onKeyDown={(event) => event.key === 'Enter' && handleSelect(item)}
                  tabIndex={index}
                >
                  {displayValueFormatter(item)}
                </li>
              ))}
            </ul>
          )}
        </DropdownPanel>
      )}
    </div>
  );
};

export default InputSearchNEPOS;
