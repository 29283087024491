import React from 'react';

import { withTranslation } from 'react-i18next';

import Page from 'components/Page/Page';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, DATE_TYPE, TEXT_TYPE } from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import TitleBar from '../../components/TitleBar/TitleBar';
import Toolbar from '../../components/Toolbar/Toolbar';
import DialogNEPOS from '../../components/UI/DialogNEPOS/DialogNEPOS';
import Pagination from '../../components/UI/Pagination/Pagination';
import RecycleBinRow from '../../components/UI/TableList/RecycleBinRow/RecycleBinRow';
import TableList from '../../components/UI/TableList/TableList';
import services from '../../services/recycleBinServices';
import titleService from '../../services/titleService';
import { DialogType } from '../../types/dialogs';
import taskStyles from '../MyTasks/MyTasks.module.scss';
import * as Config from './Config';
import styles from './RecycleBin.module.scss';

class RecycleBin extends React.PureComponent {
  constructor(props) {
    super(props);

    const toolIcons = [
      {
        id: 'restore',
        tooltip: this.props.t('tool.restore'),
        iconClass: 'fas fa-trash-restore',
        click: () => {
          if (this.canUseTool('restore')) {
            this.restoreElements();
          }
        },
      },
      {
        id: 'delete',
        tooltip: this.props.t('tool.delete'),
        iconClass: 'di icon-schliessen-2',
        click: () => {
          if (this.canUseTool('delete')) {
            this.setState({ isDeleteConfirmationDialogVisible: true });
          }
        },
      },
    ];

    Utils.toggleTools(toolIcons, ['restore', 'delete'], true);

    this.header = {
      diagramName: this.props.t('recycleBin.table.name'),
      deleterName: this.props.t('recycleBin.table.deleter'),
      typeName: this.props.t('recycleBin.table.type'),
      initialBinDate: this.props.t('recycleBin.table.date'),
      finalBinDate: this.props.t('recycleBin.table.finalDate'),
    };

    this.sortData = {
      diagramName: '',
      deleterName: '',
      typeName: '',
      initialBinDate: '',
      finalBinDate: '',
    };

    this.orderNames = {
      diagramName: 'diagramName',
      deleterName: 'deleter',
      typeName: 'type',
      initialBinDate: 'date',
      finalBinDate: 'finalDeletion',
    };

    this.state = {
      toolIcons,
      recycledElems: null,
      lastFieldSorted: '',
      direction: '',
      page: 1,
      isDeleteConfirmationDialogVisible: false,
      totalPages: 0,
      isLoading: false,
    };

    this.cache = {};

    this.deleteConfirmationDialog = {
      title: this.props.t('recycleBin.modalDeleteTitle'),
      type: DialogType.Warning,
      buttons: [
        {
          id: 'RecycleBin-confirm-deletion',
          handleClick: () => {
            this.deleteElements();
          },
          content: this.props.t('confirmDelete'),
          buttonStyle: BUTTON_PRIMARY,
        },
        {
          id: 'RecycleBin-cancel-deletion',
          handleClick: () => this.closeDeleteConfirmationDialog(),
          content: this.props.t('cancel'),
          buttonStyle: BUTTON_SECONDARY,
        },
      ],
    };
  }

  componentDidMount() {
    titleService.updatePageTitle(this.props.t('recycleBin.name'));
    this.getRecycleList();
  }

  handleCheckAll() {
    if (!this.state.recycledElems) return;

    const recycledElems = [...this.state.recycledElems];
    // Selecciona todos cuando queda alguno sin estar seleccionado
    // Deselecciona todos cuando todos están seleccionados.
    const selectAll = recycledElems.some((elem) => !elem.checked);
    recycledElems.forEach((elem) => {
      elem.checked = selectAll;
    });

    this.header.checked = selectAll;
    this.setState({
      recycledElems,
    });

    this.checkTools();
  }

  handleRowClick(elemId) {
    const recycledElems = [...this.state.recycledElems];
    const clickedElement = recycledElems.find((elem) => elem.id === elemId);
    clickedElement.checked = !clickedElement.checked;

    this.setState({
      recycledElems,
    });

    this.header.checked = this.state.recycledElems.every((elem) => elem.checked);

    this.checkTools();
  }

  setDefaultTools() {
    const toolIcons = Utils.toggleTools(this.state.toolIcons, ['restore', 'delete'], true);
    this.setState({ toolIcons });
  }

  getRecycleList(order, filter, page = 0) {
    this.setState({ isLoading: true });
    const params = {
      size: 20,
      page,
      ...(order && { order }),
      ...(filter && { filter }),
      ...(page && { page }),
    };
    services
      .getAllRecycleBinElements(params)
      .then((res) => {
        if (res.data.results) {
          res.data.results.forEach((elem) => {
            elem.initialBinDate = Config.getFormatedDate(elem.initialBinDate);
            elem.finalBinDate = Config.getFormatedDate(elem.finalBinDate);
            const deleterName = res.data.catalog.USER.find((user) => user.code === elem.deleterCode)?.commonName;
            elem.deleterName = deleterName || '-';
          });
          this.setState({
            recycledElems: res.data.results,
            totalPages: res.data.numberOfPages,
            isLoading: false,
          });
        }
      })
      .catch((error) => services.handleServiceError(error));
  }

  pageClick(page) {
    this.setState({ page });
    this.getRecycleList(this.state.direction, this.orderNames[this.state.lastFieldSorted], page - 1);
  }

  sortRecycleBin(field) {
    let columns = this.sortData;
    const fieldType = field === 'initialBinDate' || field === 'finalBinDate' ? DATE_TYPE : TEXT_TYPE;

    if (columns[field] === 'DESC') {
      columns[field] = '';
    } else {
      const sortResult = Utils.sortTableByColumn(field, fieldType, columns, this.state.recycledElems);
      columns = Utils.cloneObject(sortResult.columns);
    }

    this.setState({
      lastFieldSorted: field,
      direction: columns[field],
      page: 1,
    });
    this.sortData = columns;
    this.getRecycleList(columns[field], columns[field] ? this.orderNames[field] : '', 0);
  }

  canUseTool(toolId) {
    return !this.state.toolIcons.find((tool) => tool.id === toolId).disabled;
  }

  restoreElements() {
    const data = this.state.recycledElems.filter((elem) => elem.checked).map((elem) => elem.idDiagram);
    services
      .restoreRecycledElements(data)
      .then(() => {
        const newElements = [...this.state.recycledElems].filter((elem) => !data.includes(elem.idDiagram));
        this.setState({ recycledElems: newElements });
        this.checkCallBack(newElements);
      })
      .catch((error) => services.handleServiceError(error));
  }

  deleteElements() {
    const data = this.state.recycledElems.filter((elem) => elem.checked).map((elem) => elem.idDiagram);
    services
      .deleteRecycledElements(data)
      .then(() => {
        const newElements = [...this.state.recycledElems].filter((elem) => !data.includes(elem.idDiagram));
        this.setState({ recycledElems: newElements });
        this.closeDeleteConfirmationDialog();
        this.checkCallBack(newElements);
      })
      .catch((error) => services.handleServiceError(error));
  }

  toggleTools(toolIDs = [], isDisabled) {
    const toolIcons = Utils.toggleTools(this.state.toolIcons, toolIDs, isDisabled);
    this.setState({ toolIcons });
  }

  checkTools() {
    if (!this.props.isFreezed) {
      this.setDefaultTools();

      const checkedElem = [...this.state.recycledElems].filter((elem) => elem.checked).length;

      this.toggleTools(['restore', 'delete'], checkedElem === 0);
    }
  }

  closeDeleteConfirmationDialog() {
    this.setState({
      isDeleteConfirmationDialogVisible: false,
    });
  }

  checkCallBack(newElements) {
    if (newElements.length === 0) {
      const dPage = this.state.page === this.state.totalPages ? this.state.page - 2 : this.state.page - 1;
      this.setState({ page: this.state.page === this.state.totalPages ? this.state.page - 1 : this.state.page });
      this.getRecycleList(this.state.direction, this.orderNames[this.state.lastFieldSorted], dPage);
    }
  }

  render() {
    return (
      <>
        <Page>
          <Toolbar toolIcons={this.state.toolIcons} />
          <div className={`${taskStyles.Content} ${styles.Content}`}>
            <h4>{this.props.t('recycleBin.name')}</h4>
            <TableList
              direction={this.state.direction}
              fieldToOrder={this.state.lastFieldSorted}
              handleCheckAll={() => this.handleCheckAll()}
              handleRowClick={(elemId) => this.handleRowClick(elemId)}
              header={this.header}
              isLoading={this.state.isLoading}
              list={this.state.recycledElems}
              RowComponent={RecycleBinRow}
              sortTable={(fieldToOrder) => this.sortRecycleBin(fieldToOrder)}
            />
            {this.state.recycledElems?.length > 0 && this.state.totalPages > 1 && (
              <div className={taskStyles.PagesSection}>
                <Pagination page={this.state.page} pageClick={(n) => this.pageClick(n)} totalPages={this.state.totalPages} />
              </div>
            )}
          </div>
          {this.state.isDeleteConfirmationDialogVisible && (
            <DialogNEPOS dialog={this.deleteConfirmationDialog} extraClass="Modal">
              <p className={styles.DeleteDialogText}>{this.props.t('recycleBin.modalDelete')}</p>
            </DialogNEPOS>
          )}
        </Page>
      </>
    );
  }
}

export default withTranslation('common')(RecycleBin);
