import React, { useState } from 'react';

import styles from './FieldTooltip.module.scss';

const FieldTooltip = ({ description }) => {
  const [openClass, setOpenClass] = useState(styles.Closed);
  const handleClick = () => {
    setOpenClass(openClass === '' ? styles.Closed : '');
  };

  return (
    <i className={`${styles.IcoInfo} ${openClass}`} id="tooltip" onClick={() => handleClick()}>
      <div className={styles.FieldTooltip}>{description}</div>
    </i>
  );
};

export default FieldTooltip;
