import { useContext } from 'react';

import { RequirementContext } from 'contexts/Requirement/RequirementContext';

export default function useRequirementContext() {
  const context = useContext(RequirementContext);

  if (context === undefined) {
    throw new Error('useRequirementContext must be used within a RequirementProvider');
  }

  const { state, dispatch } = context;

  return {
    ...state,
    dispatch,
  };
}
