import { Attributes } from 'types/forms';

import { axiosInstance } from './serviceConstants';

const datasetUrl = '/backend/ms-data-set/api/v1';
const documentationUrl = '/backend/ms-documentation/api/v1';

export const createDataset = () => axiosInstance.post(`${datasetUrl}/dataset`);

export const deleteDataset = (dataSetId: number, confirmationString: string) =>
  axiosInstance.delete(`${datasetUrl}/dataset/${dataSetId}`, { data: { confirmationString } });

export const updateDataset = (data: {
  attributes: Attributes;
  diagramId?: number;
  id: number;
  status: string;
  version: string;
}) => axiosInstance.put(`${datasetUrl}/dataset`, data);

export const getDataset = (dataSetId: number) => axiosInstance.get(`${datasetUrl}/dataset/${dataSetId}`);

export const getPublishedDataset = (dataSetId: number, datasetVersion: string) =>
  axiosInstance.get(`${datasetUrl}/dataset/published/${dataSetId}/${datasetVersion}`);

export const linkDiagramToDataset = (dataSetId: number, linkedDiagramId: number | null) =>
  axiosInstance.post(`${datasetUrl}/dataset/link-diagram`, { dataSetId, linkedDiagramId });

export const getAllDatasets = (params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(`${datasetUrl}/dataset/all?${searchParams}`);
};

export const uploadDocuments = (datasetId: number, data: FormData) => {
  const headers = {
    'Content-Type': 'multipart/form-data; charset=utf-8; boundary="MBCMAP"',
  };
  return axiosInstance.post(`${documentationUrl}/storage/dataset/${datasetId}`, data, { headers });
};

export const getAllDatasetDocuments = ({ id, version }: { id: number; version: string }) =>
  axiosInstance.get(`${documentationUrl}/storage/dataset/all/${id}`, { responseType: 'blob', params: { version } });

export const inUseDataset = (dataSetId: number, inUse: boolean) =>
  axiosInstance.put(`${datasetUrl}/dataset/in-use/${dataSetId}?inUse=${inUse}`);
