import { useCallback, useRef, useState } from 'react';

import useOuterClick from 'hooks/useOuterClick';
import { ActionIcon } from 'types/actionIcon';

import styles from './ButtonsPanel.module.scss';

type Props = {
  buttons: ActionIcon[];
  children: React.ReactNode;
  disabled?: boolean;
};
const ButtonsPanel = ({ buttons, children, disabled = false }: Props) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const handleOuterClick = useCallback((event) => {
    if (contextMenuRef.current?.contains(event.target)) return;
    setIsSelected(false);
  }, []);
  useOuterClick(blockRef, handleOuterClick);

  return (
    <div className={styles.Container} data-testid="ButtonsPanel">
      {isSelected && (
        <div className={styles.ButtonsContainer} onMouseDown={(e: React.MouseEvent) => e.stopPropagation()} ref={contextMenuRef}>
          {buttons.map(
            (button) =>
              button && (
                <button
                  className={styles.Button}
                  id={button.id}
                  key={button.icon}
                  onClick={() => {
                    button.onClick();
                    setIsSelected(false);
                  }}
                  type="button"
                >
                  <i className={`${button.icon} ${styles.IconButton}`} />
                </button>
              ),
          )}
        </div>
      )}
      <div
        className={isSelected ? styles.Selected : ''}
        onClick={(event: React.MouseEvent) => {
          if (!disabled) setIsSelected(!isSelected);
          event.stopPropagation();
        }}
        ref={blockRef}
      >
        {children}
      </div>
    </div>
  );
};

export default ButtonsPanel;
