import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getFullFormattedDate } from 'assets/js/Utils';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import TextAreaNEPOS from 'components/UI/TextArea/TextAreaNEPOS';
import useError from 'hooks/useError';
import useRequirement from 'hooks/useRequirement';
import NEPOSTabWrapper from 'pages/NEPOS/NEPOSTabWrapper';
import { saveRequirementComment } from 'services/requirement';
import { ProcessDataCatalog } from 'types/processes';
import { RequirementComment } from 'types/requirement';

import styles from './Comments.module.scss';

type Props = {
  handleClose: () => void;
  extraClass: string;
  comments: RequirementComment[];
  catalog: ProcessDataCatalog;
};

const Comments = ({ handleClose, extraClass, comments, catalog }: Props) => {
  const { fetchUpdatedComments } = useRequirement();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { handleServiceError } = useError();
  const [commentValue, setCommentValue] = useState('');

  const handleSubmitComments = () => {
    saveRequirementComment(id, commentValue)
      .then(() => {
        setCommentValue('');
        fetchUpdatedComments(id);
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  return (
    <NEPOSTabWrapper extraClass={`${extraClass} ${styles.Wrapper}`} handleClose={handleClose}>
      <h4 className={styles.TabTitle}>
        <i className="di icon-sprechblase-chat" />
        {t('comments')}
      </h4>
      <div className={styles.Content}>
        <ul>
          {comments?.map((comment) => {
            const author = catalog?.USER.find((user) => user.code === comment.createdBy);
            const department = author?.departments?.[0];
            return (
              <li key={comment.id}>
                <div className={styles.TitleWrapper}>
                  <span className={styles.Name}>
                    {author?.commonName || ''} · {department || ''}
                  </span>
                  <span className={styles.Date}>{getFullFormattedDate(comment.creationDate)}</span>
                </div>
                <p className={styles.Text}>{comment.comment}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.Comment}>
        <div className={styles.CommentTextArea}>
          <TextAreaNEPOS
            id="requirement-comment-message-input"
            label={t('comment')}
            onChange={(value) => setCommentValue(value)}
            value={commentValue}
          />
        </div>
        <ButtonNEPOS
          className={styles.SendCommentButton}
          disabled={commentValue.length === 0}
          handleClick={handleSubmitComments}
          id="send-comment-button"
        >
          {t('requirements.send_comment')}
        </ButtonNEPOS>
      </div>
    </NEPOSTabWrapper>
  );
};

export default Comments;
