import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import styles from './Pill.module.scss';

type Props = {
  icon: string;
  text: string;
  className?: string;
  iconTooltip?: { icon: string; content: string };
};

const Pill = (props: Props) => {
  const { icon, text, className, iconTooltip } = props;
  return (
    <div className={`${styles.Wrapper} ${className || ''}`}>
      <i className={`di icon-${icon}`} />
      <TooltipComponent content={text} cssClass="mbc-tooltip nepos-tooltip" showTipPointer={false}>
        <div className={styles.Text}>{text}</div>
      </TooltipComponent>
      {iconTooltip && (
        <div className={styles.iconTooltipWrapper}>
          <TooltipComponent
            className="mbc-tooltip nepos-tooltip"
            content={iconTooltip.content}
            cssClass="mbc-tooltip nepos-tooltip"
            position="BottomCenter"
            showTipPointer={false}
          >
            <i className={`di ${iconTooltip.icon}`} />
          </TooltipComponent>
        </div>
      )}
    </div>
  );
};

export default Pill;
