import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import * as Dialog from '../../components/UI/Dialogs/Dialogs';
import FormDialog from '../../components/UI/FormDialog/FormDialog';
import TextArea from '../../components/UI/TextArea/TextArea';
import services from '../../services/recommendationService';

const RejectDialog = (props) => {
  const { visible, showDialog, recommendationId } = props;
  const { t } = useTranslation();
  const [rejectData, setRejectData] = useState({
    value: '',
    isTouched: false,
    hasError: () => {
      return true;
    },
  });

  const handleChange = (reason) => {
    setRejectData({
      ...rejectData,
      value: reason,
      isTouched: true,
    });
  };

  const commentHandler = () => ({
    value: rejectData.value,
    disabled: false,
    onChange: (event) => handleChange(event.target.value),
  });

  const handleOnBlur = () => {
    if (visible) {
      setRejectData({
        ...rejectData,
        isTouched: true,
      });
    }
  };

  const handleClose = () => {
    setRejectData({
      ...rejectData,
      value: '',
      isTouched: false,
    });
    showDialog(false);
  };

  const rejectRecommendation = () => {
    showDialog(false);

    services
      .rejectRecommendation(rejectData.value, recommendationId)
      .then(() => {
        props.history.push('/dashboard');

        Dialog.showAlert({
          name: t('success'),
          message: t('recommendations.rejectedRecommendation'),
        });
      })
      .catch((error) => services.handleServiceError(error));
  };

  return (
    <FormDialog
      actionButtonId="Reject"
      actionName={t('reject')}
      cancelName={t('cancel')}
      close={handleClose}
      id="rejectTaskDialog"
      info={t('recommendations.view.reject.description')}
      returnAction={rejectRecommendation}
      title={t('recommendations.view.reject.title')}
      value={rejectData.value}
      visible={visible}
    >
      <TextArea
        handleOnBlur={() => handleOnBlur()}
        handler={commentHandler}
        hasError={rejectData.hasError}
        meta={{
          id: 'recommendationRejectComment',
          attributeType: 'M',
          label: t('recommendations.view.reject.comment'),
        }}
        rows="3"
        touched={rejectData.isTouched}
      />
    </FormDialog>
  );
};

export default withRouter(RejectDialog);
