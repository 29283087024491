import React from 'react';

import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { withTranslation } from 'react-i18next';

import { cloneObject } from '../../assets/js/Utils';
import Spinner from '../UI/Spinner/Spinner';
import './DiagramExplorer.scss';

const DiagramExplorer = ({ t, lang, tree, handleClick, loadingTree }) => {
  const cssClass = 'treeStyles';
  let content = '';

  if (tree.dataSource) {
    const newTree = cloneObject(tree);
    newTree.dataSource.forEach((elem) => {
      elem.name = JSON.parse(elem.name) || {};
    });
    newTree.text = `name.${lang}.PROCESS_NAME`;

    content = <TreeViewComponent cssClass={cssClass} fields={newTree} id="treeview" nodeSelected={(args) => handleClick(args)} />;
  }

  if (loadingTree) {
    content = (
      <div className="spinner">
        <Spinner isVisible />
      </div>
    );
  }

  return (
    <>
      <h5>{t('explorer')}</h5>
      {content}
    </>
  );
};

export default React.memo(
  withTranslation('common')(DiagramExplorer),
  (prev, next) => prev.lang === next.lang && prev.tree === next.tree && prev.loadingTree === next.loadingTree,
);
