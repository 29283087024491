import React, { useReducer } from 'react';

const LocalizationActions = {
  GET_AVAILABLE_LANGUAGES: 'GET_AVAILABLE_LANGUAGES',
  GET_AVAILABLE_LANGUAGES_SUCCESS: 'GET_AVAILABLE_LANGUAGES_SUCCESS',
  GET_AVAILABLE_LANGUAGES_ERROR: 'GET_AVAILABLE_LANGUAGES_ERROR',
  GET_TRANSLATIONS: 'GET_TRANSLATIONS',
  GET_TRANSLATIONS_SUCCESS: 'GET_TRANSLATIONS_SUCCESS',
  GET_TRANSLATIONS_ERROR: 'GET_TRANSLATIONS_ERROR',
};

const initialState = {
  isLoading: false,
  areTranslationsLoading: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LocalizationActions.GET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        isLoading: true,
      };
    case LocalizationActions.GET_AVAILABLE_LANGUAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LocalizationActions.GET_AVAILABLE_LANGUAGES_SUCCESS:
      return {
        ...state,
        availableLanguages: payload.availableLanguages,
        defaultLanguage: payload.defaultLanguage,
        optionalLanguages: payload.optionalLanguages,
        isLoading: false,
      };
    case LocalizationActions.GET_TRANSLATIONS:
      return {
        ...state,
        areTranslationsLoading: true,
        isLoading: true,
      };
    case LocalizationActions.GET_TRANSLATIONS_ERROR:
      return {
        ...state,
        areTranslationsLoading: false,
      };
    case LocalizationActions.GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        areTranslationsLoading: false,
        translations: payload,
        isLoading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const LocalizationContext = React.createContext();

const LocalizationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
};

export { LocalizationActions, LocalizationContext, LocalizationProvider };
