import { DiagramActionTypes, movingSwimlaneSymbols } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useTool from 'hooks/useTool';
import { PaletteActions, Tool } from 'types/diagram';
import { SymbolTypes } from 'types/symbols';

import styles from './PaletteItem.module.scss';

const PaletteItem = ({ type }: { type: SymbolTypes }) => {
  const { handleDragStart } = useDragAndDrop();
  const { dispatch, processData } = useDiagramContext();
  const { toggleTool } = useTool();

  const ACTIONS: { [key in SymbolTypes]?: PaletteActions } = {
    [SymbolTypes.SWIMLANE_ELEMENT]: PaletteActions.ADD_SWIMLANE,
    [SymbolTypes.SIPOC_ELEMENT]: PaletteActions.ADD_SYMBOL,
  };

  const BACKGROUND_STYLES: { [key in SymbolTypes]?: string } = {
    [SymbolTypes.SWIMLANE_ELEMENT]: styles.Swimlane,
    [SymbolTypes.SIPOC_ELEMENT]: styles.SipocElement,
  };

  const INNER_TEXTS: { [key in SymbolTypes]?: string } = {
    [SymbolTypes.SWIMLANE_ELEMENT]: 'Swimlane',
    [SymbolTypes.SIPOC_ELEMENT]: 'SIPOC element',
  };

  const onDragStart = (e: any) => {
    handleDragStart(e);
    if (processData?.isSwimlane) {
      dispatch(movingSwimlaneSymbols(true));
    }
    dispatch({
      type: DiagramActionTypes.SET_PALETTE_ACTION,
      payload: ACTIONS[type] || PaletteActions.ADD_SYMBOL,
    });
  };

  const onDragEnd = () => {
    if (type === SymbolTypes.SWIMLANE_ELEMENT) {
      dispatch({
        type: DiagramActionTypes.SET_PALETTE_ACTION,
        payload: PaletteActions.NONE,
      });
    }
    if (processData?.isSwimlane) {
      dispatch(movingSwimlaneSymbols(false));
    }
  };

  return (
    <>
      <div
        className={`${styles.PaletteItem} ${BACKGROUND_STYLES[type]}`}
        draggable
        id={`palette-${type}`}
        onClick={() => toggleTool(Tool.ADD_SYMBOL)}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <div className={styles.Container}>
          <p>{INNER_TEXTS[type]}</p>
        </div>
      </div>
    </>
  );
};

export default PaletteItem;
