import React from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import styles from './Icon.module.scss';

const Icon = ({ onClick, disabled, iconClass, extraClass, tooltip, id, offsetY = -20, buttonLabel = '' }) => {
  const { t } = useTranslation();
  const disabledClass = disabled ? styles.Disabled : '';
  const tooltipClass = `${extraClass} mbc-tooltip`;

  return (
    <TooltipComponent
      className="icon-tooltip"
      content={tooltip || 'tooltip'}
      cssClass={tooltipClass}
      offsetY={offsetY}
      position="BottomCenter"
      showTipPointer={false}
    >
      <button className={`${styles.IconDaimler} ${disabledClass}`} disabled={disabled} onClick={onClick} type="button">
        <i className={iconClass} id={id} />
        <span className={styles.ButtonLabel}>{t(buttonLabel)}</span>
      </button>
    </TooltipComponent>
  );
};

export default React.memo(Icon, (prev, next) => JSON.stringify(prev) === JSON.stringify(next) && prev.onClick === next.onClick);
