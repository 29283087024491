import { useRef, useState, useEffect } from 'react';

import { purify } from 'assets/js/Utils';
import Input from 'components/UI/InputText/Input';
import InputLabel from 'components/UI/InputText/InputLabel';
import useOuterClick from 'hooks/useOuterClick';
import { AttributeCode, AttributeValue, VariantProperty } from 'types/forms';

import stylesNepos from './InputTextNEPOS.module.scss';
import stylesLegacy from './InputTextNEPOSLegacyStyle.module.scss';

type Props = {
  className?: string;
  clearInput?: () => void;
  code?: string;
  containerRef?: React.RefObject<HTMLLabelElement>;
  disabled?: boolean;
  error?: string;
  getValue?: (code: AttributeCode | string, value?: AttributeValue, variant?: VariantProperty) => string;
  hasInfo?: boolean;
  id: string;
  isEditing?: boolean;
  isFocused?: boolean;
  label: string;
  legacyStyles?: boolean;
  limit?: number;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onClick?: (event?: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  reference?: React.RefObject<HTMLInputElement>;
  required?: boolean;
  value: string;
  variant?: string;
  placeholder?: string;
};

const InputTextNEPOS = (props: Props) => {
  const {
    className,
    code,
    disabled,
    id,
    isEditing,
    label,
    legacyStyles,
    onChange,
    onClick,
    required,
    value,
    onKeyDown,
    reference,
    containerRef,
    error,
    onBlur,
    limit,
    variant,
    hasInfo,
    clearInput,
    getValue,
    isFocused,
    placeholder,
  } = props;
  const newInputRef = useRef<HTMLInputElement>(null);
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentTextInputRef = reference || newInputRef;
  const currentContainerRef = containerRef || newContainerRef;
  const [focused, setFocused] = useState(false);
  const hasError = Boolean(error);
  const isErrorTriggered = legacyStyles ? hasError : hasError && !focused;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;
  const [formattedValue, setFormattedValue] = useState<string>('');
  document.getElementById('web-address')?.focus();

  useOuterClick(currentContainerRef, () => setFocused(false));

  const onClickInput = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleContainerClick = () => {
    if (!currentTextInputRef.current) return;
    setFocused(true);
    currentTextInputRef.current.focus();
  };

  const onClickDelete = (event: React.MouseEvent) => {
    if (!clearInput) return;

    event.stopPropagation();
    event.preventDefault();
    clearInput();
  };

  useEffect(() => {
    if (isFocused) {
      setFocused(true);
    }
  }, [isFocused]);

  const formatData = (inputValue: string) =>
    getValue
      ? getValue(
          code as AttributeCode,
          inputValue,
          variant === VariantProperty.DOTS_DECIMAL ? VariantProperty.DOTS_DECIMAL : undefined,
        )
      : inputValue;

  useEffect(() => {
    setFormattedValue(formatData(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onValueChange = (inputValue: string) => {
    const purifyValue = purify(inputValue);

    onChange(purifyValue);
    setFormattedValue(purifyValue);
  };

  return (
    <InputLabel
      handleContainerClick={handleContainerClick}
      hasInfo={hasInfo}
      input={{
        className,
        error: {
          hasError: isErrorTriggered,
          icon: legacyStyles ? 'fas fa-exclamation-circle' : 'icon-blitz-fehler',
        },
        disabled,
        id,
        reference: containerRef as React.RefObject<HTMLLabelElement>,
        styles,
        value: value?.toString(),
      }}
      isEditing={isEditing}
      isFocused={focused}
      label={label}
      placeholder={placeholder}
      required={required}
    >
      <Input
        characterLimit={limit}
        code={code}
        input={{
          className,
          error: {
            hasError: isErrorTriggered,
          },
          disabled,
          id,
          reference: reference as React.RefObject<HTMLInputElement>,
          styles,
          value: formattedValue,
        }}
        onBlur={onBlur}
        onChange={onValueChange}
        onClick={onClickInput}
        onClickDelete={onClickDelete}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        showClearButton={(!disabled && formattedValue && clearInput) as boolean}
        variant={variant}
      />
    </InputLabel>
  );
};

export default InputTextNEPOS;
