import { DragEvent, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { DiagramActionTypes, movingSwimlaneSymbols } from 'contexts/Diagram/DiagramContext';
import { Coordinate, Coordinates, Dimension, Dimensions, Tool, PaletteActions } from 'types/diagram';
import { Symbol } from 'types/symbols';

import useConnector from './useConnector';
import useDiagramContext from './useDiagramContext';
import useError from './useError';
import useSelection from './useSelection';
import useSymbol from './useSymbol';

const ALIGN_THRESHOLD = 2;

export default function useDragAndDrop() {
  const {
    dispatch,
    fontSize,
    initialCoords,
    originRef,
    processData,
    tool,
    shouldPreventClick,
    containerRef,
    grabAndMove,
    selectedSymbolIds,
    paletteAction,
  } = useDiagramContext();
  const { updateLastVertices, checkSplitConnector, updateLastVertex } = useConnector();
  const { clearSelection } = useSelection();
  const { createSymbol, getUpdatedSymbol, getUpdatedSymbols, createTextfield, createSwimlane } = useSymbol();
  const movingSymbols = useRef<Symbol[] | null>(null);
  const symbolsMoved = useRef<boolean>(false);
  const { showAlert } = useError();
  const { t } = useTranslation();

  const unableToDrop = () => {
    showAlert(t('dropSymbolError'));
    dispatch(movingSwimlaneSymbols(false));
  };

  const CreateOptionsMap = {
    [PaletteActions.NONE]: () => {},
    [PaletteActions.ADD_SYMBOL]: createSymbol,
    [PaletteActions.ADD_TEXTFIELD]: createTextfield,
    [PaletteActions.ADD_SWIMLANE]: createSwimlane,
    [PaletteActions.ADD_AND_GATE]: unableToDrop,
    [PaletteActions.ADD_OR_GATE]: unableToDrop,
    [PaletteActions.ADD_XOR_GATE]: unableToDrop,
    [PaletteActions.ADD_PROCESS_INTERFACE]: unableToDrop,
    [PaletteActions.ADD_PROCESS_STEP]: unableToDrop,
    [PaletteActions.ADD_EVENT]: unableToDrop,
  };

  const handleDragStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const eCoords = e.currentTarget.getBoundingClientRect();
      initialCoords.current = {
        x: e.clientX,
        y: e.clientY,
        displacement: { x: eCoords.x - e.clientX, y: eCoords.y - e.clientY },
      };
    },
    [initialCoords],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      if (!originRef.current || !fontSize || !initialCoords.current?.displacement) return;
      const originCoords = originRef.current.getBoundingClientRect();
      clearSelection();
      CreateOptionsMap[paletteAction]({
        top: (e.clientY + initialCoords.current.displacement.y - originCoords.top) / fontSize,
        left: (e.clientX + initialCoords.current.displacement.x - originCoords.left) / fontSize,
      });
      initialCoords.current = null;
    },
    [clearSelection, fontSize, initialCoords, originRef, paletteAction], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getAlignCoordinate = useCallback<(coordinate: keyof Coordinates, id: string, value: number) => number>(
    (coordinate, id, value) => {
      if (!processData || !movingSymbols.current) return value;
      const dimension = coordinate === Coordinate.LEFT ? Dimension.WIDTH : Dimension.HEIGHT;
      const currentSymbol = movingSymbols.current.find((e) => e.id === id);
      if (!currentSymbol) return value;
      const newCenter = value + currentSymbol.meta[dimension] / 2;

      const alignmentSymbol = processData.symbols.find((symbol) => {
        if (symbol.id === currentSymbol.id) return false;

        const symbolCenter = symbol.meta[coordinate] + symbol.meta[dimension] / 2;
        return newCenter < symbolCenter + ALIGN_THRESHOLD && newCenter > symbolCenter - ALIGN_THRESHOLD;
      });

      if (!alignmentSymbol) return value;
      return alignmentSymbol.meta[coordinate] + (alignmentSymbol.meta[dimension] - currentSymbol.meta[dimension]) / 2;
    },
    [processData],
  );

  const handleMove = useCallback(
    (e: MouseEvent) => {
      if (!processData || !movingSymbols.current || !fontSize) return;
      symbolsMoved.current = true;

      const movingSymbolsNewValues: ({ meta: Coordinates & Dimensions } & { id: string })[] = [];

      movingSymbols.current.forEach((element) => {
        if (!initialCoords?.current) return;
        const dx = (e.clientX - initialCoords.current.x) / fontSize;
        const dy = (e.clientY - initialCoords.current.y) / fontSize;
        const newValues = {
          meta: {
            left: getAlignCoordinate(Coordinate.LEFT, element.id, element.meta.left + dx),
            top: getAlignCoordinate(Coordinate.TOP, element.id, element.meta.top + dy),
            width: element.meta.width,
            height: element.meta.height,
          },
          id: element.id,
        };
        movingSymbolsNewValues.push(newValues);
      });

      if (movingSymbolsNewValues.length === 0) return;

      let symbolsToUpdate;
      if (movingSymbolsNewValues.length > 1) {
        symbolsToUpdate = getUpdatedSymbols(movingSymbolsNewValues);
      } else {
        symbolsToUpdate = getUpdatedSymbol(movingSymbolsNewValues[0].id, movingSymbolsNewValues[0]);
      }

      const connectorsData = checkSplitConnector(processData.symbols, processData.connectors, movingSymbolsNewValues);

      const connectorsToUpdate = connectorsData ? updateLastVertex(connectorsData) : updateLastVertices(movingSymbolsNewValues);

      if (!symbolsToUpdate || !connectorsToUpdate) return;

      dispatch({
        type: DiagramActionTypes.UPDATE_SYMBOLS_AND_CONNECTORS,
        payload: { symbols: symbolsToUpdate, connectors: connectorsToUpdate },
      });
    },
    [
      processData,
      fontSize,
      getUpdatedSymbols,
      getUpdatedSymbol,
      checkSplitConnector,
      updateLastVertex,
      updateLastVertices,
      dispatch,
      initialCoords,
      getAlignCoordinate,
    ],
  );

  const handleMoveEnd = useCallback(() => {
    document.body.classList.remove('grabbing');
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleMoveEnd);
    dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: true });

    if (symbolsMoved.current) {
      symbolsMoved.current = false;
      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
    }
  }, [dispatch, handleMove]);

  const handleMoveStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      if (tool === Tool.CONNECT || grabAndMove || !processData) return;
      handleDragStart(e);

      let currentMovingSymbols: Symbol[] | null =
        processData?.symbols.filter((symbol) => selectedSymbolIds.includes(symbol.id) || symbol.id === id) || null;

      const isActualSelection = processData.symbols.find((symbol) => symbol.id === id);
      const selectionHasChanged = !selectedSymbolIds.includes(id);

      if (currentMovingSymbols.length > 0) {
        // There's no previous selected symbols and the control key is not being held
        // Result: Drag&Select a symbol not previously selected when we don't have another symbol selected
        if (selectedSymbolIds.length === 0 && !e.getModifierState('Control')) {
          shouldPreventClick.current = false; // handleSelect(id);
        }

        // There's one previously selected symbol, the selection has been changed and the control key is not being held
        // Result: Drag&Select a symbol not previously selected when we have another symbol selected
        if (selectedSymbolIds.length === 1 && selectionHasChanged && !e.getModifierState('Control')) {
          if (shouldPreventClick.current) shouldPreventClick.current = false; // handleSelect(id, e);
          currentMovingSymbols = isActualSelection ? [isActualSelection] : currentMovingSymbols;
        }

        // There's more than a current moving symbol, the selection has been changed and the control key is being held
        // Result: Drag&Select a symbol not previously selected when we have another symbol selected and whant to move both symbols
        if (currentMovingSymbols.length > 1 && selectionHasChanged && e.getModifierState('Control')) {
          if (shouldPreventClick.current) shouldPreventClick.current = false;
        }

        // There's more than one selected symbol
        if (selectedSymbolIds.length > 1) {
          shouldPreventClick.current = false;

          // Change current moving symbol to the one were we have the cursor and select that new symbol (without control/multiselect key pressed)
          if (selectionHasChanged && !e.getModifierState('Control'))
            currentMovingSymbols = isActualSelection ? [isActualSelection] : currentMovingSymbols;

          // Prevent selection on the symbol were we have the cursor after dragging multiple symbols
          if (currentMovingSymbols.length === selectedSymbolIds.length) shouldPreventClick.current = true;
        }

        document.body.classList.add('grabbing');
        document.addEventListener('mousemove', handleMove);
      }

      movingSymbols.current = currentMovingSymbols;

      document.addEventListener('mouseup', handleMoveEnd);
      dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: false });
    },
    [tool, processData, handleDragStart, handleMove, handleMoveEnd, selectedSymbolIds, grabAndMove, dispatch, shouldPreventClick],
  );

  const mouseMoveHandler = (event: MouseEvent) => {
    if (!containerRef.current || !initialCoords.current || !initialCoords.current.displacement) return;
    const dx = event.clientX - initialCoords.current.x;
    const dy = event.clientY - initialCoords.current.y;

    containerRef.current.scrollLeft = initialCoords.current.displacement.x - dx;
    containerRef.current.scrollTop = initialCoords.current.displacement.y - dy;
  };

  const mouseUpHandler = () => {
    if (!containerRef.current) return;
    document.body.classList.remove('grabbing');
    containerRef.current.style.removeProperty('user-select');

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!containerRef.current || !grabAndMove) return;
    initialCoords.current = {
      x: event.clientX,
      y: event.clientY,
      displacement: { x: containerRef.current.scrollLeft, y: containerRef.current.scrollTop },
    };
    document.body.classList.add('grabbing');
    containerRef.current.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return {
    handleDragStart,
    handleDrop,
    handleMoveStart,
    mouseDownHandler,
  };
}
