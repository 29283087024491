import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY } from 'assets/constants/constants';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import ScrollBox from 'components/UI/ScrollBox/ScrollBox';
import { DialogIcon, DialogType } from 'types/dialogs';

import styles from 'components/UI/DialogNEPOS/DialogNEPOS.module.scss';

type DialogErrorProps = {
  children: ReactNode;
  confirmText?: string;
  handleConfirm: () => void;
  isLoading?: boolean;
  title?: string;
};

const DialogError = (props: DialogErrorProps) => {
  const { children, confirmText, handleConfirm, isLoading, title } = props;
  const { t } = useTranslation();
  const buttons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: confirmText || t('ok').toUpperCase(),
      handleClick: handleConfirm,
      isLoading,
      key: 'confirm',
    },
  ];

  return (
    <Dialog className={`${styles.DialogContainer} ${styles.Modal} ${styles[DialogType.Error]}`}>
      <DialogHeader className={styles.Title}>
        <i className={`di ${DialogIcon.Error} ${styles.Icon}`} />
        {title || t('error')}
      </DialogHeader>
      <ScrollBox>
        <div className={styles.DialogBody}>{children}</div>
      </ScrollBox>
      <DialogFooter buttons={buttons} extraClass={styles.Footer} />
    </Dialog>
  );
};

export default DialogError;
