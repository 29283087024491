import React from 'react';

import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';

import { storageLabels } from 'assets/constants/constants';
import SettingsMenu from 'components/SettingsMenu/SettingsMenu';
import TitleBar from 'components/TitleBar/TitleBar';
import Profile from 'pages/Profile/Profile';
import Settings from 'pages/Settings/Settings';

import styles from './UserConfig.module.scss';

import 'App.scss';

class UserConfig extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      language: props.i18n.language,
    };
  }

  setLanguage = (language) => {
    sessionStorage.setItem(storageLabels.lang, language);
    this.props.i18n.changeLanguage(language);
    this.setState({ language }, () => {
      this.setRememberLang(localStorage.getItem(storageLabels.rememberAppLang));
    });
  };

  setRememberLang = (checked) => {
    localStorage.removeItem(storageLabels.lang);
    localStorage.removeItem(storageLabels.rememberAppLang);

    if (checked) {
      localStorage.setItem(storageLabels.lang, this.state.language);
      localStorage.setItem(storageLabels.rememberAppLang, true);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <TitleBar />
        <div className={styles.Content}>
          <SettingsMenu t={t} />
          <Switch>
            <Route path="/user/settings">
              <Settings updateLanguage={this.setLanguage} updateRememberLang={this.setRememberLang} />
            </Route>
            <Route component={Profile} path="/user/profile" />
            <Redirect from="/user" to="/user/settings" />
          </Switch>
        </div>
      </>
    );
  }
}

export default withTranslation('common')(UserConfig);
