import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SECONDARY_LEFT,
  DIAGRAM_ENVIRONMENTS,
  MAX_NEPOS_VIDEO_FILES,
  MAX_VIDEO_SIZE,
} from 'assets/constants/constants';
import uploadDocumentImage from 'assets/images/uploadDocuments.svg';
import uploadDocumentHoverImage from 'assets/images/uploadDocumentsHover.svg';
import { handleServiceError } from 'assets/js/serviceUtils';
import { getFullFormattedDate } from 'assets/js/Utils';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import Spinner from 'components/UI/Spinner/Spinner';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useFile from 'hooks/useFile';
import { getVideoInfo } from 'services/videoManagement';
import { ButtonProps } from 'types/config';
import { VideoData } from 'types/processes';

import styles from './VideoDialog.module.scss';

type Props = {
  handleClose: () => void;
};

const VideoDialog = ({ handleClose }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { processData } = useDiagramContext();
  const checkEnvironment = useEnvironment();
  const allowedExtensions = ['.mp4', '.mov', '.wmv', '.avi', '.avchd'];
  const allowedTypes = ['video/mp4', 'video/quicktime', 'video/x-ms-wmv', 'video/x-msvideo', 'video/avchd-stream'];
  const { deleteFile, handleDragFile, handleFileSelection } = useFile(
    MAX_NEPOS_VIDEO_FILES,
    MAX_VIDEO_SIZE,
    allowedExtensions,
    allowedTypes,
  );
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [videoToUpload, setVideoToUpload] = useState<FileList | null>();
  const [showPreview, setShowPreview] = useState(false);
  const [videoInfo, setVideoInfo] = useState<VideoData | null>();
  const [videoFile, setVideoFile] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [buttons, setButtons] = useState<ButtonProps[]>([]);
  const filesInputRef = useRef<HTMLInputElement | null>(null);

  const handleShowPreview = async () => {
    try {
      setIsLoading(true);
      const path = checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED)
        ? `https://mbcmap-dev-app.corpinter.net/backend/ms-video-management/api/v1/video/published/${processData?.attributes.NOT_TRANSLATABLE?.PROCESS_NUMBER}/${processData?.attributes.NOT_TRANSLATABLE?.VERSION}`
        : `https://mbcmap-dev-app.corpinter.net/backend/ms-video-management/api/v1/video/${id}`;
      await fetch(path, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/mp4',
          Authorization: `Bearer ${localStorage.mbcMapToken}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          setVideoFile(url);
          setShowPreview(true);
          setIsLoading(false);
        });
    } catch (error) {
      handleServiceError(error);
    }
  };

  const getVideo = async () => {
    try {
      const { data } = await getVideoInfo(id);

      setVideoInfo({ ...data });
    } catch (error) {
      handleClose();
      handleServiceError(error);
    }
  };

  useEffect(() => {
    if (processData?.isOnlyRead) {
      handleShowPreview();
    }
  }, [processData?.isOnlyRead]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoading || showPreview) {
      setButtons([
        {
          buttonStyle: BUTTON_SECONDARY,
          content: t('close'),
          disabled: false,
          handleClick: () => {
            if (processData?.isOnlyRead) {
              handleClose();
            } else {
              setShowPreview(false);
              setShowVideo(false);
            }
          },
          key: 'close-video-button',
        },
      ]);
      return;
    }

    if (processData?.video || isVideoUploaded) {
      let manageVideoDialogButtons = [
        {
          buttonStyle: BUTTON_SECONDARY_LEFT,
          content: t('showPreview'),
          disabled: false,
          handleClick: () => handleShowPreview(),
          key: 'preview-video-button',
        },
        {
          buttonStyle: BUTTON_PRIMARY,
          content: t('upload'),
          disabled: !videoToUpload,
          handleClick: () => videoToUpload && handleFileSelection(videoToUpload),
          key: 'upload-video-button',
        },
        {
          buttonStyle: BUTTON_SECONDARY,
          content: t('cancel'),
          disabled: false,
          handleClick: () => handleClose(),
          key: 'cancel-upload-video-button',
        },
      ];

      if (!processData?.video) {
        manageVideoDialogButtons = manageVideoDialogButtons.filter((button) => button.key !== 'preview-video-button');
      }

      setButtons(manageVideoDialogButtons);
    } else {
      setButtons([
        {
          buttonStyle: BUTTON_SECONDARY,
          content: t('cancel'),
          disabled: false,
          handleClick: () => handleClose(),
          key: 'cancel-upload-video-button',
        },
      ]);
    }
  }, [isLoading, isVideoUploaded, processData?.video, showPreview, videoToUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isUploaded = false;

    if (processData?.video || (videoToUpload && videoToUpload.length > 0)) {
      isUploaded = true;

      if (!processData?.isOnlyRead && processData?.video) {
        getVideo();
      }
    }
    setIsVideoUploaded(isUploaded);
  }, [processData?.video, videoToUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!processData?.video) {
      setVideoFile('');
      setVideoToUpload(null);
      setVideoInfo(null);
    }
  }, [processData?.video]);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>, hover: boolean) => {
    handleDragFile(event);
    setIsHovering(hover);
  };

  return (
    <DialogNEPOS
      dialog={{
        title: showPreview ? t('video') : t('uploadVideo'),
        buttons,
      }}
      maxSize={showPreview}
    >
      {/* eslint-disable-next-line no-nested-ternary, no-nested-ternary */}
      {isLoading ? (
        <div className="spinner">
          <Spinner isVisible />
        </div>
      ) : showPreview && !isLoading ? (
        <div className={styles.VideoContainer}>
          {!showVideo && (
            <div className={styles.VideoBackground}>
              <RoundButton
                className={styles.Button}
                icon="di icon-medien-start-abspielen"
                id="play-video-button"
                onClick={() => setShowVideo(true)}
              />
            </div>
          )}
          {showVideo && <video autoPlay controls muted src={videoFile} />}
        </div>
      ) : (
        <div className={`${styles.Content} ${isVideoUploaded ? styles.UploadVideoContent : ''}`}>
          {isVideoUploaded ? (
            <div className={styles.UploadContainer}>
              <div className={styles.Video}>
                <div className={styles.NameWrapper}>
                  <i className="di icon-datei-mp4" />
                  <span className={styles.Name}>{videoInfo ? videoInfo.name : videoToUpload?.[0].name}</span>
                </div>
                {videoInfo && (
                  <span className={styles.User}>{`${videoInfo.user} - ${getFullFormattedDate(videoInfo.lastModification)}`}</span>
                )}
              </div>
              <RoundButton
                className={styles.RemoveButton}
                icon="di icon-muelleimer-loeschen"
                id="remove-video-button"
                onClick={() => {
                  deleteFile(videoInfo ? videoInfo.name : videoToUpload?.[0].name);
                }}
              />
            </div>
          ) : (
            <>
              <div
                className={styles.InitialView}
                onClick={() => filesInputRef.current?.click()}
                onDragEnter={(event) => handleDrag(event, true)}
                onDragLeave={(event) => handleDrag(event, false)}
                onDragOver={(event) => handleDrag(event, true)}
                onDrop={(event) => {
                  handleDrag(event, false);
                  setVideoToUpload(event.dataTransfer.files);
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <span>{t('dialog.uploadVideo.dropVideo')}</span>
                <img
                  alt="Upload video"
                  id="upload-video-image"
                  src={isHovering ? uploadDocumentHoverImage : uploadDocumentImage}
                />
              </div>
              <input
                accept={`${allowedTypes.join(',')},${allowedExtensions.join(',')}`}
                className={styles.FilesInput}
                id="uploadVideoInput"
                onChange={(event) => {
                  setVideoToUpload(event.target.files);
                }}
                ref={filesInputRef}
                type="file"
              />
            </>
          )}
        </div>
      )}
    </DialogNEPOS>
  );
};

export default VideoDialog;
