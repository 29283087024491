import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useUpdateEffect from 'hooks/useUpdateEffect';

import Button from '../Button/Button';
import DropdownPanel from '../DropdownPanel/DropdownPanel';
import styles from './Search.module.scss';

type Props = {
  searching: (text: string) => void;
  sendSearch?: (text: string) => void;
  extraClass?: string;
  showButton?: boolean;
  disabled?: boolean;
  id: string;
  isAsync?: boolean;
  panelClassName?: string;
  hasDropdown?: boolean;
  onClickDropdownItem?: (item: string) => void;
  dataQA?: string;
  minCharacters?: number;
};

const Search = (props: Props) => {
  const {
    searching,
    sendSearch,
    extraClass,
    showButton = false,
    disabled = false,
    id,
    isAsync = false,
    panelClassName,
    hasDropdown = false,
    onClickDropdownItem,
    dataQA = id,
    minCharacters,
  } = props;
  const { t } = useTranslation();
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [value, setValue] = useState('');
  const [results, setResults] = useState<{ value: string; label: string }[] | null>(null);

  useUpdateEffect(() => {
    if (minCharacters && value.length < minCharacters) return;

    if (!isAsync) {
      searching(value);
    } else {
      clearTimeout(Number(timeout.current));
      setIsOpen(isOpen && !!value);

      timeout.current = setTimeout(() => {
        if (hasDropdown) {
          setIsSearchLoading(true);
          setIsOpen(!!value);
          Promise.resolve(searching(value) as unknown as { value: string; label: string }[]).then((data) => {
            setResults(data);
            setIsOpen(!!data);
            setIsSearchLoading(false);
          });
        }
        searching(value);
        clearTimeout(Number(timeout.current));
      }, 1000);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (!sendSearch || (minCharacters && value.length < minCharacters)) return;
    sendSearch(value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleSubmit();
  };

  const handleDropdownClick = (itemValue: string) => {
    onClickDropdownItem?.(itemValue);
    setResults(null);
    setValue('');
  };

  return (
    <div className={styles.SearchContent}>
      <div
        className={`${styles.Search} ${extraClass} ${showButton ? styles.WidthSearch : ''}`}
        onClick={() => setIsOpen(!!results)}
        ref={boxRef}
      >
        <input
          autoComplete="off"
          data-qa={dataQA || id || 'search'}
          disabled={disabled}
          id={id || 'search-input'}
          onChange={(event) => setValue(event.target.value)}
          onKeyPress={onKeyPress}
          placeholder={t('search')}
          value={value}
        />
        <i aria-hidden="true" className="di icon-lupe-suche" onClick={handleSubmit} />
      </div>
      {showButton && (
        <div className={styles.SearchButton}>
          <Button
            btnText={t('search')}
            buttonStyle="Primary"
            disabled={disabled}
            handleClick={handleSubmit}
            icon={undefined}
            id="search-button"
            isLoading={undefined}
            success={undefined}
          >
            {undefined}
          </Button>
        </div>
      )}
      {isOpen && (
        <DropdownPanel close={() => setIsOpen(false)} parentRef={boxRef}>
          {results?.length ? (
            <div className={`${styles.Options} ${panelClassName}`}>
              {results.map((result) => (
                <div className={styles.Option} key={result.value} onClick={() => handleDropdownClick(result.value)}>
                  {result.label}
                </div>
              ))}
            </div>
          ) : (
            <div className={`${styles.Options} ${panelClassName}`}>
              <div className={`${styles.Option} ${styles.NotFoundMessage}`}>
                {isSearchLoading ? t('loading') : t('multiselection.noRecordsFound')}
              </div>
            </div>
          )}
        </DropdownPanel>
      )}
    </div>
  );
};

export default Search;
