import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { NOT_TRANSLATABLE, REQUIREMENT_REQUEST } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { getFormattedDate, hasDuplicates, removeSquareBracketsFromString } from 'assets/js/Utils';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import {
  deleteRequirementData,
  setColumnsConfig,
  setComments,
  setLoadingRequirement,
  setRequirement,
  setCatalog,
  setLastModifiedDateRequirement,
} from 'contexts/Requirement/RequirementContext';
import useFormTypes from 'hooks/useFormTypes';
import {
  createRequirement,
  getRequirement,
  deleteRequirement,
  updateRequirement,
  getAllRequirements,
  updateRequirementComments,
  performRequirementAction,
} from 'services/requirement';
import { Language } from 'types/config';
import { AttributeCode, AttributeValue, LanguageAttributes } from 'types/forms';
import {
  ColumnsConfig,
  Process,
  RequirementActions,
  RequirementData,
  RequirementFormsViewsVariant,
  RequirementStatus,
  ResponsibleProcessUserTypes,
} from 'types/requirement';
import { MultiDepartmentCatalogUser } from 'types/user';

import useAuth from './useAuth';
import useDiagramContext from './useDiagramContext';
import useRequirementContext from './useRequirementContext';

export default function useRequirement() {
  const history = useHistory();
  const { dispatch, requirementData } = useRequirementContext();
  const { dispatch: diagramDispatch } = useDiagramContext();
  const { fetchFormTypes, getFormTypesCode } = useFormTypes();
  const { userInfo } = useAuth();
  const { i18n, t } = useTranslation();

  const loggedUserMatchByType = useCallback(
    (type: AttributeCode): boolean => {
      const users = requirementData?.attributes[NOT_TRANSLATABLE]?.[type] || [];
      const formattedUsers = typeof users === 'string' ? removeSquareBracketsFromString(users) : users;

      return formattedUsers.some((user: string) => user.trim() === userInfo?.code); // TODO: Check if it is neccessary
    },
    [requirementData?.attributes, userInfo?.code],
  );

  const extractProcessAttributes = useCallback(
    (processId?: AttributeValue): LanguageAttributes | undefined =>
      requirementData?.catalog.PROCESS.find((process) => process.id.toString() === processId)?.attributes.NOT_TRANSLATABLE,
    [requirementData?.catalog.PROCESS],
  );

  const extractProcessesAttributes = useCallback(
    (processIds?: AttributeValue): LanguageAttributes => {
      if (!processIds) return {};
      const processes = removeSquareBracketsFromString(processIds);
      return processes.reduce(
        (attributes, processId) => {
          const processAtributes = extractProcessAttributes(processId);

          return processAtributes
            ? {
                [AttributeCode.RESPONSIBLE_PERSONS]:
                  attributes[AttributeCode.RESPONSIBLE_PERSONS] + processAtributes[AttributeCode.RESPONSIBLE_PERSONS],
                [AttributeCode.RESPONSIBLE_PACEMAKERS]:
                  attributes[AttributeCode.RESPONSIBLE_PACEMAKERS] + processAtributes[AttributeCode.RESPONSIBLE_PACEMAKERS],
              }
            : attributes;
        },
        { [AttributeCode.RESPONSIBLE_PERSONS]: '', [AttributeCode.RESPONSIBLE_PACEMAKERS]: '' },
      );
    },
    [extractProcessAttributes],
  );

  const isUserInProcess = useCallback(
    (processType?: ResponsibleProcessUserTypes): boolean => {
      if (!requirementData?.catalog.PROCESS) return false;

      const originProcessAttributes = extractProcessAttributes(requirementData?.attributes.NOT_TRANSLATABLE?.ID_ORIGIN_PROCESS);
      const responsibleProcessAttributes = extractProcessesAttributes(
        requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS,
      );

      const originProcessUsers = `${originProcessAttributes?.RESPONSIBLE_PERSONS}, ${originProcessAttributes?.RESPONSIBLE_PACEMAKERS}`;
      const responsibleProcessUsers = `${responsibleProcessAttributes?.RESPONSIBLE_PERSONS}, ${responsibleProcessAttributes?.RESPONSIBLE_PACEMAKERS}`;

      const usersMapper: { [key in ResponsibleProcessUserTypes]: string } = {
        [ResponsibleProcessUserTypes.ORIGIN_PROCESS_USERS]: originProcessUsers,
        [ResponsibleProcessUserTypes.RESPONSIBLE_PROCESS_USERS]: responsibleProcessUsers,
      };

      const users = !processType ? originProcessUsers + responsibleProcessUsers : usersMapper[processType];
      const formattedUsers = removeSquareBracketsFromString(users);
      return formattedUsers.some((user: string) => user.trim() === userInfo?.code);
    },
    [
      extractProcessAttributes,
      extractProcessesAttributes,
      requirementData?.attributes.NOT_TRANSLATABLE?.IDS_RESPONSIBLE_PROCESS,
      requirementData?.attributes.NOT_TRANSLATABLE?.ID_ORIGIN_PROCESS,
      requirementData?.catalog.PROCESS,
      userInfo?.code,
    ],
  );

  const isLoggedUserResponsible: boolean = useMemo(
    () => loggedUserMatchByType(AttributeCode.RESPONSIBLE_PERSONS) || loggedUserMatchByType(AttributeCode.RESPONSIBLE_PACEMAKERS),
    [loggedUserMatchByType],
  );

  const isUserResponsibleOrPacemakerInProcess = useCallback(
    (processId: string): boolean =>
      !!requirementData?.responsibleProcesses?.some(
        (process) =>
          process.id === Number(processId) &&
          (process.attributes.NOT_TRANSLATABLE?.RESPONSIBLE_PERSONS?.includes(userInfo?.code) ||
            process.attributes.NOT_TRANSLATABLE?.RESPONSIBLE_PACEMAKERS?.includes(userInfo?.code)),
      ),
    [requirementData?.responsibleProcesses, userInfo?.code],
  );

  const getVariantView = useCallback(
    (status?: RequirementStatus) => {
      let temp = RequirementFormsViewsVariant.EXTERNAL;

      switch (status) {
        case RequirementStatus.ACCEPTED: {
          if (isLoggedUserResponsible) {
            temp = RequirementFormsViewsVariant.RESPONSIBLE;
          } else if (loggedUserMatchByType(AttributeCode.CREATOR)) {
            temp = RequirementFormsViewsVariant.CREATOR;
          }
          break;
        }
        case RequirementStatus.DRAFT: {
          if (
            loggedUserMatchByType(AttributeCode.CREATOR) ||
            isUserInProcess(ResponsibleProcessUserTypes.ORIGIN_PROCESS_USERS) ||
            isLoggedUserResponsible
          ) {
            temp = RequirementFormsViewsVariant.CREATOR;
          }
          break;
        }
        default: {
          if (loggedUserMatchByType(AttributeCode.CREATOR)) {
            temp = RequirementFormsViewsVariant.CREATOR;
          } else if (isLoggedUserResponsible) {
            temp = RequirementFormsViewsVariant.RESPONSIBLE;
          }
          break;
        }
      }
      return temp;
    },
    [isLoggedUserResponsible, isUserInProcess, loggedUserMatchByType],
  );

  const createNewRequirement = useCallback(() => {
    dispatch(setLoadingRequirement(true));
    createRequirement()
      .then((response) => {
        dispatch(setLoadingRequirement(false));
        history.push(`/requirements/${response.data.id}`);
      })
      .catch((error) => {
        dispatch(setLoadingRequirement(false));
        handleServiceError(error);
      });
  }, [dispatch, history]);

  const fetchRequirement = useCallback(
    async (idRequirement: number) => {
      dispatch(setLoadingRequirement(true));

      try {
        const { data } = await getRequirement(idRequirement);

        await fetchFormTypes(
          getFormTypesCode({
            status: data.status,
            type: REQUIREMENT_REQUEST,
            isRequirement: true,
          }),
        );

        dispatch(setRequirement(data));
      } catch (err) {
        handleServiceError(err);
      }
      dispatch(setLoadingRequirement(false));
    },
    [dispatch, fetchFormTypes, getFormTypesCode],
  );

  const fetchUpdatedComments = (id: string) => {
    updateRequirementComments(id)
      .then((response) => {
        dispatch(setComments(response.data.comments));
        dispatch(setCatalog(response.data.catalog));
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const saveRequirement = useCallback(
    async (data: RequirementData | undefined) => {
      if (data) {
        const { attributes, ...restOfData } = data;
        const parsedAttributes = {
          ...attributes,
          NOT_TRANSLATABLE: {
            ...attributes.NOT_TRANSLATABLE,
            RESPONSIBLE_PACEMAKERS: undefined,
            RESPONSIBLE_PERSONS: undefined,
            TARGET_DATE: undefined,
            FINANCE_APPROVED: undefined,
          },
        };
        dispatch(setLoadingRequirement(true));

        let errorFlag = false;

        restOfData.solutionApproach
          .filter((approach) => approach.financialEvaluation.length)
          .forEach((approach) => {
            const yearArray = approach.financialEvaluation.map((elem) => elem.year);

            if (hasDuplicates(yearArray)) {
              errorFlag = true;
              dispatch(setLoadingRequirement(false));
              return diagramDispatch({
                type: DiagramActionTypes.SET_ERROR,
                payload: {
                  title: t('error'),
                  message: t('noRepeatedYear'),
                },
              });
            }
          });
        if (errorFlag) return;

        try {
          await updateRequirement({
            id: restOfData.id,
            attributes: parsedAttributes,
            solutionApproach: restOfData.solutionApproach,
          }).then((res) => {
            dispatch(setLastModifiedDateRequirement(res.data.lastModifiedDate));
          });
        } catch (err) {
          handleServiceError(err);
        }
        dispatch(setLoadingRequirement(false));
      }
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deleteExistingRequirement = useCallback(
    (idRequirement: number, confirmationValue: string) => {
      dispatch(setLoadingRequirement(true));

      return deleteRequirement(idRequirement, confirmationValue)
        .then(() => {
          dispatch(deleteRequirementData());
          dispatch(setLoadingRequirement(false));
          history.push('/requirements');
        })
        .catch((err) => {
          dispatch(setLoadingRequirement(false));
          handleServiceError(err);
        });
    },
    [dispatch, history],
  );

  const triggerRequirementAction = useCallback(
    async (action: RequirementActions, confirmationValue?: string) => {
      if (requirementData) {
        const { attributes, ...restOfData } = requirementData;
        const parsedAttributes = {
          ...attributes,
          NOT_TRANSLATABLE: {
            ...attributes.NOT_TRANSLATABLE,
            RESPONSIBLE_PACEMAKERS: undefined,
            RESPONSIBLE_PERSONS: undefined,
            TARGET_DATE: undefined,
            FINANCE_APPROVED: undefined,
          },
        };
        dispatch(setLoadingRequirement(true));

        let errorFlag = false;

        restOfData.solutionApproach
          .filter((approach) => approach.financialEvaluation.length)
          .forEach((approach) => {
            const reducedValues = Object.values(
              approach.financialEvaluation.reduce((groups: any, current) => {
                if (!groups[current.year]) {
                  groups[current.year] = [];
                }
                groups[current.year].push(current);
                return groups;
              }, {}),
            ).flatMap((value: any) => {
              if (value.length > 1) {
                errorFlag = true;
                return diagramDispatch({
                  type: DiagramActionTypes.SET_ERROR,
                  payload: {
                    title: t('error'),
                    message: t('noRepeatedYear'),
                  },
                });
              }
              return value.length;
            });
            if (reducedValues[reducedValues.length - 1] === undefined) return dispatch(setLoadingRequirement(false));
          });

        if (errorFlag) return;

        try {
          if (
            [
              RequirementStatus.ACCEPTED,
              RequirementStatus.CLOSED,
              RequirementStatus.DRAFT,
              RequirementStatus.FORWARD,
              RequirementStatus.RETRIEVED,
            ].includes(requirementData.status)
          ) {
            await updateRequirement({
              id: restOfData.id,
              attributes: parsedAttributes,
              solutionApproach: restOfData.solutionApproach,
            });
          }
          await performRequirementAction(restOfData.id, action, confirmationValue);
          const { data } = await getRequirement(restOfData.id);
          await fetchFormTypes(
            getFormTypesCode({
              status: data.status,
              type: REQUIREMENT_REQUEST,
              isRequirement: true,
            }),
          );
          dispatch(setRequirement(data));
        } catch (err) {
          handleServiceError(err);
        }
      }
      dispatch(setLoadingRequirement(false));
    },
    [dispatch, fetchFormTypes, getFormTypesCode, requirementData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const triggerRequirementActionFromList = useCallback(
    async (id: number, action: RequirementActions) => {
      dispatch(setLoadingRequirement(true));
      await performRequirementAction(id, action).catch((err) => handleServiceError(err));
      dispatch(setLoadingRequirement(false));
    },
    [dispatch],
  );

  const setUsersCommonName = (catalog: MultiDepartmentCatalogUser[], key: AttributeValue | undefined) => {
    const users = key?.toString().replace('[', '').replace(']', '').split(',');
    return users?.reduce(
      (acc, userCode) => `${acc}${acc === '' ? '' : ', '}${catalog.find((user) => user.code === userCode)?.commonName}`,
      '',
    );
  };

  const fetchAllRequirements = useCallback(
    async (params: {}, currentColumnConfig?: ColumnsConfig) => {
      try {
        const { data } = await getAllRequirements(params);
        const { catalog, requests: requirements } = data;
        const results = requirements.map((elem: RequirementData) => {
          const solutionApproach = elem.solutionApproach.find(
            (approach) => !!approach.attributes[i18n.language as Language]?.SOLUTION_DESCRIPTION && !!approach.targetDate,
          );

          return {
            supportNeeded: elem.supportNeeded,
            id: elem.id,
            title: elem.attributes[i18n.language as Language]?.TITLE,
            category:
              elem.attributes.NOT_TRANSLATABLE?.INITIAL_CATEGORY === ''
                ? ''
                : t(`attributes.INITIAL_CATEGORY.options?.${elem.attributes.NOT_TRANSLATABLE?.INITIAL_CATEGORY}`),
            status: elem.status,
            description: elem.attributes[i18n.language as Language]?.INITIAL_DESCRIPTION,
            example: elem.attributes[i18n.language as Language]?.EXAMPLE,
            responsiblePerson:
              catalog &&
              elem.attributes.NOT_TRANSLATABLE &&
              setUsersCommonName(catalog.USER, elem.attributes.NOT_TRANSLATABLE.RESPONSIBLE_PERSONS),
            responsiblePacemaker:
              catalog &&
              elem.attributes.NOT_TRANSLATABLE &&
              setUsersCommonName(catalog.USER, elem.attributes.NOT_TRANSLATABLE.RESPONSIBLE_PACEMAKERS),
            responsibleProcess: elem.responsibleProcesses?.reduce(
              (title: AttributeValue, process: Process, index: number): AttributeValue =>
                `${index !== 0 ? `${title}, ` : ''}${process.attributes.NOT_TRANSLATABLE?.PROCESS_TITLE}`,
              '',
            ),
            originProcess: elem.originProcess?.attributes.NOT_TRANSLATABLE?.PROCESS_TITLE,
            createdBy:
              catalog &&
              elem.attributes.NOT_TRANSLATABLE &&
              setUsersCommonName(catalog.USER, elem.attributes.NOT_TRANSLATABLE.CREATOR),
            creationInfo: getFormattedDate(elem.attributes.NOT_TRANSLATABLE?.CREATION_DATE),
            acceptedBy:
              elem.status === RequirementStatus.ACCEPTED
                ? catalog &&
                  elem.attributes.NOT_TRANSLATABLE &&
                  setUsersCommonName(catalog.USER, elem.attributes.NOT_TRANSLATABLE.RESPONSIBLE_PERSONS)
                : '',
            acceptanceInfo: elem.acceptanceDate === 'null' ? '' : getFormattedDate(elem.acceptanceDate),
            potentialAnnualEbit: elem.attributes.NOT_TRANSLATABLE?.POTENTIAL_ANNUAL_EBIT,
            potentialOneTimeCashflow: elem.attributes.NOT_TRANSLATABLE?.POTENTIAL_ONE_TIME_CASHFLOW,
            solutionDescription: solutionApproach?.attributes[i18n.language as Language]?.SOLUTION_DESCRIPTION,
            requiredInvest: solutionApproach?.financialEvaluation[0]?.requiredInvest,
            evaluationAnnualEbit: solutionApproach?.financialEvaluation[0]?.annualEbitEffect,
            evaluationOneTimeCashflow: solutionApproach?.financialEvaluation[0]?.oneTimeCashflowEffect,
            targetDate: getFormattedDate(solutionApproach?.targetDate),
            onClick: () => history.push(`/requirements/${elem.id}`),
            creator: elem.attributes.NOT_TRANSLATABLE?.CREATOR,
            lastModifiedDate: elem.lastModifiedDate,
          };
        });

        dispatch(setColumnsConfig(currentColumnConfig || data.columnConfig));
        return { results, numberOfPages: data.numberOfPages, totalResults: data.totalRequest };
      } catch (err) {
        handleServiceError(err);
      }
    },
    [dispatch, history, i18n.language, t],
  );

  return {
    createNewRequirement,
    deleteExistingRequirement,
    fetchRequirement,
    saveRequirement,
    loggedUserMatchByType,
    isUserInProcess,
    triggerRequirementAction,
    triggerRequirementActionFromList,
    getVariantView,
    fetchAllRequirements,
    fetchUpdatedComments,
    isLoggedUserResponsible,
    isUserResponsibleOrPacemakerInProcess,
  };
}
