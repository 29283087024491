import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';

import * as Constants from '../../../assets/constants/constants';
import * as Utils from '../../../assets/js/Utils';
import CheckboxPlain from '../CheckBox/CheckboxPlain';
import DateFilter from './DateFilter';
import MultiselectionFilterLegacy from './MultiselectionFilterLegacy';
import PopupFilter from './PopupFilter';
import styles from './SearchFilter.module.scss';

const SearchFilterLegacy = (props) => {
  const {
    t,
    filter,
    filtersList,
    filtersInfo,
    content,
    options = {},
    isChecked,
    handleSelection,
    handleChange,
    setFilterInfo,
    filterSearch,
    resetFilter,
    isRecommendation = false,
  } = props;
  const [filterOpened, setFilterOpened] = useState(filter.isOpen);
  const [filterSelected, setFilterSelected] = useState(filter.isSelected);
  const [dateSelected, setDateSelected] = useState(null);

  const fieldsFilter = {
    checkbox: Object.keys(options).map((key) => (
      <CheckboxPlain
        checked={options[key].checked}
        getChecked={isChecked}
        id={options[key].keyName}
        key={options[key].id}
        label={options[key].label}
      />
    )),
    input: (
      <input
        className={styles.Input}
        onChange={(e) => handleChange(e)}
        placeholder={filter.placeholder}
        type="text"
        value={content}
      />
    ),
    users: <MultiselectionFilterLegacy allResults={content || []} toggleSelection={handleSelection} />,
    locations: <MultiselectionFilterLegacy allResults={content || []} toggleSelection={handleSelection} />,
    date: <DateFilter setDate={setDateSelected} />,
  };

  const openFilter = () => {
    if (filter.isOpen) {
      // Click for close filter
      filter.isSelected = Utils.getFilterStatus(filter.filterType, filtersList[filter.nameFilter]?.filterData);
    } else {
      // Click for open filter
      filter.isSelected = true;
    }

    filter.isOpen = !filter.isOpen;
    setFilterOpened(filter.isOpen);
    setFilterSelected(filter.isSelected);
    setFilterInfo(filter.nameFilter, filter.isSelected, filter.isOpen);
  };

  const resetClick = () => {
    const name = filter.nameFilter;
    const filters = Utils.cloneObject(filtersList);
    const filterReset = filtersInfo.find((f) => f.nameFilter === name);

    filters[name].filterData = filterReset.filterType === Constants.TEXTINPUT ? '' : [];
    filterReset.isSelected = false;
    filterReset.isOpen = false;

    if (filterReset.filterType === Constants.CHECKBOX) {
      Object.keys(filterReset.options).forEach((option) => {
        filterReset.options[option].checked = true;
      });

      if (filterReset.nameFilter === 'type') {
        filters[name].filterData = [Constants.VCD_DIAGRAM_ID, Constants.EPC_DIAGRAM_ID];
      }
    } else if (filterReset.filterType === Constants.USER) {
      let allUsers = Utils.cloneObject(filtersList[name].filterData);

      allUsers = allUsers.map((user) => ({
        ...user,
        checked: false,
      }));
      filters[name].filterData = allUsers;
    }

    setFilterOpened(filter.isOpen);
    setFilterSelected(filter.isSelected);
    resetFilter(filtersInfo, filters);
  };

  const saveClick = () => {
    // TODO
    // eslint-disable-next-line no-console
    console.log(dateSelected);
  };

  const applyClick = () => {
    filter.isSelected = Utils.getFilterStatus(filter.filterType, filtersList[filter.nameFilter]?.filterData);
    filterSearch();

    setFilterOpened(false);
    setFilterSelected(filter.isSelected);
    setFilterInfo(filter.nameFilter, filter.isSelected, false);
  };

  return (
    <div className={styles.FilterDisplay}>
      <div
        className={`${styles.Filter} ${filterSelected ? styles.Selected : ''}
          ${isRecommendation ? styles.RecommendationFilter : ''}`}
        onClick={openFilter}
      >
        {t(filter.text)}
      </div>
      {filterOpened && (
        <PopupFilter applyClick={applyClick} isRecommendation={isRecommendation} resetClick={resetClick} saveClick={saveClick}>
          {fieldsFilter[filter.filterType]}
        </PopupFilter>
      )}
    </div>
  );
};

export default withTranslation('common')(SearchFilterLegacy);
