import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { KPI, UPDATE } from 'assets/constants/constants';
import Accordion from 'components/UI/Accordion/Accordion';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import { Language } from 'types/config';
import { ProcessKPI } from 'types/processes';
import { TableVariant } from 'types/tables';

import styles from './KPIItem.module.scss';

type Props = {
  checked: Record<string, boolean>;
  handleCheck: () => void;
  kpi: ProcessKPI;
};

const KPIItem = (props: Props) => {
  const { checked, handleCheck, kpi } = props;
  const { i18n } = useTranslation();
  const { diagramLanguage, processData } = useDiagramContext();
  const { getFormTypesCode } = useFormTypes();
  const [isLoading, setIsLoading] = useState(true);
  const formCode = getFormTypesCode({
    status: processData?.status,
    type: KPI,
    variant: UPDATE,
  });
  const { fields, values } = useBackendForm({
    formCode,
    initialValues: kpi.attributes,
    id: kpi.id,
    usesDiagramTranslation: true,
  });

  useEffect(() => {
    kpi.attributes = values;
    if (!Object.keys(fields).length || !Object.keys(values).length || !isLoading) return;
    setIsLoading(false);
  }, [fields, isLoading, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion
      checked={checked}
      handleCheck={handleCheck}
      kpiId={kpi.id}
      name={kpi.attributes[diagramLanguage]?.KPI_NAME as string}
      onlyRead={processData?.isOnlyRead}
      variant={processData?.isOnlyRead ? TableVariant.PLAIN : TableVariant.CHECKBOX}
    >
      {!isLoading && (
        <section className={styles.Attributes} onClick={(e) => e.stopPropagation()}>
          {fields[i18n.language as Language]?.map(({ Component, ...fieldProps }) => (
            <Component {...fieldProps} disabled={processData?.isOnlyRead} />
          ))}
        </section>
      )}
    </Accordion>
  );
};

export default KPIItem;
