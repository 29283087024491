import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LINK_RESPONSIBILITY, NOT_TRANSLATABLE, ROLE } from 'assets/constants/constants';
import Dropdown from 'components/UI/Dropdown/Dropdown';
import useFormTypes from 'hooks/useFormTypes';

import roleStyles from './DialogAddRole.module.scss';
import styles from './DialogDetail.module.scss';

const RoleWithResponsibility = (props) => {
  const { role, setDialogDetail, icon, data, id } = props;
  const { t } = useTranslation();
  const formTypes = useFormTypes();
  const [responsibilities, setResponsibilities] = useState([]);
  const [optionSelected, setOptionSelected] = useState('');

  useEffect(() => {
    const newOption = role.RESPONSIBILITY
      ? responsibilities.find((responsibility) => responsibility === role.RESPONSIBILITY)
      : '';
    setOptionSelected(newOption);
  }, [responsibilities, role.RESPONSIBILITY]);

  useEffect(() => {
    if (!formTypes || responsibilities.length) return;
    let options = [];
    const roleFormTypesKey = Object.keys(formTypes).find((key) => key.includes(`_${ROLE}_`));

    if (roleFormTypesKey) {
      const linkResponsibilityOptions = formTypes[roleFormTypesKey]?.[NOT_TRANSLATABLE][LINK_RESPONSIBILITY].properties.OPTIONS;
      options = linkResponsibilityOptions.replace('[', '').replace(']', '').split(', ');
    }
    setResponsibilities(options);
  }, [formTypes, responsibilities]);

  return (
    <div className={roleStyles.RoleBox} id={id}>
      <div
        className={`
          ${styles.CatalogBox}
          ${role.OBJECT_NAME === data.OBJECT_NAME ? roleStyles.RoleSelected : ''}
          ${roleStyles.Role}`}
        key={role.id}
        onClick={(event) => setDialogDetail(event)}
      >
        <i aria-hidden="true" className={`${icon} ${styles.CatalogIcon}`} />
        <div className={roleStyles.RoleName}>{role.OBJECT_NAME}</div>
      </div>
      <div className={`${roleStyles.Responsibilities} dropdown`}>
        <Dropdown
          code={LINK_RESPONSIBILITY}
          onChange={(newValue) => {
            setOptionSelected(newValue);
            props.returnResponsibility(newValue);
          }}
          options={responsibilities}
          placeholder={t(`attributes.${LINK_RESPONSIBILITY}.placeholder`)}
          value={optionSelected}
        />
      </div>
    </div>
  );
};

export default RoleWithResponsibility;
