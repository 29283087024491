import axios from 'axios';

import * as serviceUtils from 'assets/js/serviceUtils';

import serviceConstants, { axiosInstance } from './serviceConstants';

const axiosInstanceWithoutAuth = axios.create({ baseURL: serviceConstants.baseUrl });

const featuresUrl = '/backend/ms-features/api/v1';

// const getAvailableLanguages = () => axiosInstanceWithoutAuth.get(`${featuresUrl}/languages`);

// const getFeatureFlags = () => axiosInstanceWithoutAuth.get(`${featuresUrl}/feature-toggle`);

// const getTranslations = () => axiosInstance.get(`${featuresUrl}/translations`);

export default {
  // handleServiceError: serviceUtils.handleServiceError,
  // getAvailableLanguages,
  // getFeatureFlags,
  // getTranslations,
};
