import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DEFAULT_FORM_CODE, urlRegEx } from 'assets/constants/constants';
import { getLocalStorageAppLanguage } from 'assets/js/serviceUtils';
import {
  addDotsToNumbers,
  cloneObject,
  purifyAttributes,
  removeSquareBracketsFromString,
  setFormattedDate,
  stringIsArray,
} from 'assets/js/Utils';
import CheckboxNEPOS from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import DatePickerNEPOS from 'components/UI/DatePickerNEPOS/DatePickerNEPOS';
import DiagramSelector from 'components/UI/DiagramSelector/DiagramSelector';
import Dropdown from 'components/UI/Dropdown/Dropdown';
import DropdownNEPOS from 'components/UI/Dropdown/DropdownNEPOS';
import InputSearchNEPOS from 'components/UI/InputSearch/InputSearchNEPOS';
import InputText from 'components/UI/InputText/InputText';
import InputTextChipNEPOS from 'components/UI/InputText/InputTextChipsNEPOS';
import InputTextNEPOS from 'components/UI/InputText/InputTextNEPOS';
import InputUrl from 'components/UI/InputUrl/InputUrl';
import InputWysiwyg from 'components/UI/InputWysiwyg/InputWysiwyg';
import Multiselection from 'components/UI/Multiselection/Multiselection';
import MultiselectionNEPOS from 'components/UI/MultiselectionNEPOS/MultiselectionNEPOS';
import RadioButtonNEPOS from 'components/UI/RadioButtonNEPOS/RadioButtonNEPOS';
import TextArea from 'components/UI/TextArea/TextArea';
import TextAreaNEPOS from 'components/UI/TextArea/TextAreaNEPOS';
import WysiwygNEPOS from 'components/UI/WysiwygNEPOS/WysiwygNEPOS';
import WysiwygNEPOS2 from 'components/UI/WysiwygNEPOS/WysiwygNEPOS2';
import { DiagramContext, DiagramProvider } from 'contexts/Diagram/DiagramContext';
import iconsFilesDisplay from 'services/useFormService';
import userService from 'services/userService';
import { Scope, User } from 'types/catalog';
import { Language } from 'types/config';
import {
  Care,
  AttributeValue,
  FieldError,
  Input,
  Attributes,
  FormLanguage,
  LanguageFormTypes,
  AttributeCode,
  FormTypes,
  AttributeFlags,
  AttributeErrors,
  LanguageAttributeErrors,
  AttributeFormTypes,
  Field,
  FormMeta,
  SearchBy,
  SearchByInfo,
  FormFields,
  VariantProperty,
  Chip,
} from 'types/forms';
import { ProcessDataCatalog } from 'types/processes';
import { Process } from 'types/requirement';

import useDiagramContext from './useDiagramContext';
import useFeatureFlags from './useFeatureFlags';
import useFormTypes from './useFormTypes';

const ComponentMapLegacy = {
  [Input.CHECKBOX]: InputText,
  [Input.DATE_TIME]: InputText,
  [Input.DIAGRAM_SELECTOR]: DiagramSelector,
  [Input.DOCUMENT_ID]: InputText,
  [Input.DROPDOWN]: Dropdown,
  [Input.MULTISELECTION]: Multiselection,
  [Input.PROCESS_ID]: InputText,
  [Input.SEARCHINPUT]: InputText,
  [Input.TEXT]: InputText,
  [Input.TEXTAREA]: TextArea,
  [Input.URL]: InputUrl,
  [Input.USER_ID]: InputText,
  [Input.WYSIWYG]: InputWysiwyg,
  [Input.WYSIWYG2]: InputWysiwyg,
  [Input.RADIOBUTTON]: RadioButtonNEPOS,
  [Input.PROCESS_SELECTOR]: InputTextNEPOS,
};
const ComponentMap = {
  [Input.CHECKBOX]: CheckboxNEPOS,
  [Input.DATE_TIME]: DatePickerNEPOS,
  [Input.DIAGRAM_SELECTOR]: DiagramSelector,
  [Input.DOCUMENT_ID]: InputTextNEPOS,
  [Input.DROPDOWN]: DropdownNEPOS,
  [Input.MULTISELECTION]: MultiselectionNEPOS,
  [Input.PROCESS_ID]: InputTextNEPOS,
  [Input.SEARCHINPUT]: InputSearchNEPOS,
  [Input.TEXT]: InputTextNEPOS,
  [Input.TEXTAREA]: TextAreaNEPOS,
  [Input.URL]: InputTextNEPOS,
  [Input.USER_ID]: InputTextNEPOS,
  [Input.WYSIWYG]: WysiwygNEPOS,
  [Input.WYSIWYG2]: WysiwygNEPOS2,
  [Input.RADIOBUTTON]: RadioButtonNEPOS,
  [Input.PROCESS_SELECTOR]: InputTextNEPOS,
  [Input.INPUT_FILE_CHIPS]: InputTextChipNEPOS,
};

const searchByInfo: SearchByInfo = {
  [SearchBy.USER]: {
    service: userService.getUsersByRole,
    displayValueFormatter: (item?: User) => (item ? `${item.commonName} (${item.code})` : undefined),
    multipleValueFormatter: (
      catalog: { commonName?: string; code: string; description?: { [k in Language]: string } }[],
      userCodes?: string[],
    ) => {
      return userCodes
        ? catalog
            .map((userCatalog) => ({
              code: userCatalog.code,
              displayName: `${userCatalog.commonName} (${userCatalog.code})`,
            }))
            .filter((elem) => userCodes.includes(elem.code))
        : [];
    },
  },
  [SearchBy.SCOPE]: {
    service: () => null,
    displayValueFormatter: (item?: Scope) =>
      item ? `${item.description?.[getLocalStorageAppLanguage() as Language]} (${item.code})` : undefined,
    multipleValueFormatter: (
      catalog: { commonName?: {}; code: string; description?: { [k in Language]: string } }[],
      scopeCodes?: string[],
    ) => {
      return scopeCodes
        ? catalog
            .map((scopeCatalog) => ({
              code: scopeCatalog.code,
              displayName: `${scopeCatalog.description?.[getLocalStorageAppLanguage() as Language]}`,
            }))
            .filter((elem) => scopeCodes.includes(elem.code))
        : [];
    },
  },
  [SearchBy.PROCESS]: {
    service: () => null,
    displayValueFormatter: (item?: Process) =>
      item ? `${item.attributes?.NOT_TRANSLATABLE?.PROCESS_TITLE} (${item.id})` : undefined,
    multipleValueFormatter: (
      catalog: {
        attributes?: Attributes;
        code: string;
        commonName?: {};
        description?: { [k in Language]: string };
        id?: number;
      }[],
      proccesCodes?: string[],
    ): Chip[] =>
      proccesCodes
        ? catalog
            .filter((elem) => elem.id && proccesCodes.includes(elem.id.toString()))
            .map((process) => ({
              code: process.id?.toString() || '',
              displayName: `${process.attributes?.NOT_TRANSLATABLE?.PROCESS_TITLE}`,
            }))
        : [],
  },
};

type Props = {
  formTypes: FormTypes;
  initialValues?: Attributes;
  catalog?: ProcessDataCatalog;
  formCode?: string;
  id?: string;
  isLegacy?: boolean;
  usesDiagramTranslation?: boolean;
  isTouchedNeeded?: boolean;
};

export default function useForm({
  formTypes,
  initialValues,
  catalog,
  formCode,
  id,
  isLegacy,
  usesDiagramTranslation,
  isTouchedNeeded,
}: Props) {
  const { t, i18n } = useTranslation();
  const { getInitialValues } = useFormTypes();
  const { diagramLanguage } = useDiagramContext();
  const { isFreezed } = useFeatureFlags();

  const context = useContext(DiagramContext);
  let languageDiagram: string | undefined;
  let isValidationActive: boolean;
  if (context !== undefined && usesDiagramTranslation) {
    const { state } = context;
    languageDiagram = diagramLanguage;
    isValidationActive = state.isValidationActive.valueOf();
  } else {
    languageDiagram = getLocalStorageAppLanguage();
    isValidationActive = false;
  }
  const [values, setValues] = useState<Attributes>({});
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState<AttributeFlags>(
    Object.fromEntries(Object.keys(formTypes).map((language) => [language, {}])),
  );

  /*
   * VALIDATION & ERRORS
   */
  const getErrorCode = useCallback(
    (care?: Care, value?: AttributeValue) =>
      // TODO: THIS SHOULD BE DELETED IF THERE ARE NO MORE STRING-ARRAYS => '[EXAMPLE, EXAMPLEII]' / HAPPENS WITH AUTHOR FIELD
      care === Care.MANDATORY &&
      ((value instanceof Array && value.length === 0) ||
        (typeof value === 'string' &&
          ((value.indexOf('[' && ']') === 1 && (value as string)?.substring(1, (value as string).length - 1).split(',')) ||
            !value.trim())))
        ? FieldError.REQUIRED
        : FieldError.NONE,
    [],
  );

  const getErrorCodes = useCallback(
    (purifiedValues?: Attributes) =>
      Object.fromEntries(
        (Object.entries(formTypes) as [FormLanguage, LanguageFormTypes][]).map(([language, attributes]) => [
          language,
          Object.fromEntries(
            (Object.keys(attributes) as AttributeCode[]).map((code) => [
              code,
              getErrorCode(formTypes[language]?.[code]?.care, (purifiedValues || values)[language]?.[code]),
            ]),
          ),
        ]),
      ),
    [formTypes, getErrorCode, values],
  );
  const [errorCodes, setErrorCodes] = useState<AttributeErrors>(getErrorCodes());

  const errorMessages: AttributeErrors = useMemo(() => {
    if (!Object.keys(errorCodes).length) return {};
    const newErrorMessages = cloneObject(errorCodes);
    (Object.entries(errorCodes) as [FormLanguage, LanguageAttributeErrors][]).forEach(([language, languageErrors]) => {
      (Object.entries(languageErrors) as [AttributeCode, FieldError][]).forEach(([attributeCode, errorCode]) => {
        const isInvalidField = errorCode && (isTouchedNeeded ? touched[language]?.[attributeCode] : true);
        newErrorMessages[language][attributeCode] =
          isInvalidField && isValidationActive
            ? t(`formFieldErrors.${errorCodes[language]?.[attributeCode]}`, {
                field: t(`attributes.${attributeCode}.name`, { lng: language }),
              })
            : '';
      });
    });

    return newErrorMessages;
  }, [errorCodes, t, touched, isTouchedNeeded, isValidationActive]);

  const isValid = useMemo(() => {
    if (!Object.keys(errorCodes).length) return false;

    return Object.values(errorCodes).every((languageErrors) => Object.values(languageErrors).every((errorCode) => !errorCode));
  }, [errorCodes]);

  const isLanguageValid = useMemo(() => {
    if (!Object.keys(errorCodes).length) return {};

    const isNotTranslatableValid = Object.values(errorCodes[FormLanguage.NOT_TRANSLATABLE] || {}).every(
      (notTranslatableErrorCodes) => Object.values(notTranslatableErrorCodes).every((errorCode) => !errorCode),
    );

    return Object.fromEntries(
      Object.entries(errorCodes)
        .filter(([language]) => language !== FormLanguage.NOT_TRANSLATABLE)
        .map(([language, languageErrorCodes]) => [
          language,
          isNotTranslatableValid && Object.values(languageErrorCodes).every((errorCode) => !errorCode),
        ]),
    );
  }, [errorCodes]);

  /*
   * EFFECTS
   */
  useEffect(() => {
    if (!Object.keys(formTypes).length || Object.keys(values).length) return;

    setValues(initialValues || getInitialValues(formTypes));
    setTouched(Object.fromEntries(Object.keys(formTypes).map((language) => [language, {}])));
  }, [formTypes, getErrorCodes, getInitialValues, id, initialValues, values]);

  useEffect(() => {
    setErrorCodes(getErrorCodes());
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Object.keys(values).length) return;

    setValues({});
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * LANGUAGE MANAGEMENT (APP/DIAGRAM)
   */
  const getLanguage = useCallback(
    (lang: FormLanguage): FormLanguage => {
      if (lang === FormLanguage.NOT_TRANSLATABLE) return FormLanguage.NOT_TRANSLATABLE;
      return usesDiagramTranslation ? (languageDiagram as FormLanguage) : lang;
    },
    [languageDiagram, usesDiagramTranslation],
  );

  /*
   * FIELDS
   */
  const handleBlur = useCallback<
    (event: React.FocusEvent, language: FormLanguage, code: AttributeCode, callback?: (event?: React.FocusEvent) => void) => void
  >((event, language, code, callback) => {
    if (callback) callback(event);
    setTouched((prevTouched) => {
      const newTouched = cloneObject(prevTouched);
      newTouched[language][code] = true;

      return newTouched;
    });
  }, []);

  const handleChange = useCallback(
    (value: AttributeValue, language: FormLanguage, code: AttributeCode, type: string) => {
      if (!values[language]) return;
      const newValues = cloneObject(values);
      const tempElem = document.createElement('p');

      if (typeof value === 'string') {
        tempElem.innerHTML = value;
      }

      if (type === 'CHECKBOX' && !isLegacy) {
        if (
          newValues[language][code] === undefined ||
          newValues[language][code] === '' ||
          newValues[language][code] === 'false'
        ) {
          newValues[language][code] = 'true';
          setChecked(true);
        } else {
          newValues[language][code] = 'false';
          setChecked(false);
        }
      } else if (!tempElem?.textContent?.trim()) {
        newValues[language][code] = '';
      } else {
        newValues[language][code] = value;
      }
      setValues(newValues);
    },
    [values], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setValue = useCallback(
    (attribute: string, value?: AttributeValue, language: string = FormLanguage.NOT_TRANSLATABLE) => {
      const valuesLang = values[language];
      if (valuesLang) {
        valuesLang[attribute] = value;
      }
    },
    [values],
  );

  const getValue = useCallback((code: AttributeCode | string, value?: AttributeValue, variant?: VariantProperty) => {
    if (!value) return '';

    const isDate = code === AttributeCode.PUBLICATION_DATE || code === AttributeCode.NEXT_REVISION;
    const formattedValue = isDate ? setFormattedDate(value) : value;

    if (variant === VariantProperty.DOTS_DECIMAL) {
      return addDotsToNumbers(value);
    }

    return formattedValue;
  }, []);

  /*
   * PURIFY
   */
  const purifyForm = useCallback(() => {
    const purifiedValues = purifyAttributes(values);
    setValues(purifiedValues);
    const newErrorCodes = getErrorCodes(purifiedValues);
    setErrorCodes(newErrorCodes);
    const isPurifiedFormValid = Object.values(newErrorCodes).every((languageErrorCodes) =>
      Object.values(languageErrorCodes).every((errorCode) => !errorCode),
    );

    return isPurifiedFormValid && purifiedValues;
  }, [getErrorCodes, values]);

  const meta: FormMeta = useMemo(() => {
    const newMeta = cloneObject(formTypes);
    const components = isLegacy ? ComponentMapLegacy : ComponentMap;
    (Object.entries(formTypes) as [FormLanguage, LanguageFormTypes][]).forEach(([language, languageFormTypes]) => {
      (Object.entries(languageFormTypes) as [AttributeCode, AttributeFormTypes][]).forEach(
        ([attributeCode, attributeFormTypes]) => {
          const { properties, ...attributeConfig }: AttributeFormTypes = attributeFormTypes;
          const propertiesConfig = {
            ...(properties?.ITEM_ID && { itemId: properties.ITEM_ID }),
            ...(properties?.MAX && { max: Number(properties.MAX) }),
            ...(properties?.MIN && { min: Number(properties.MIN) }),
            ...(properties?.VARIANT && { variant: properties.VARIANT }),
            ...(properties?.OPTIONS && {
              options: properties.OPTIONS.replace('[', '').replace(']', '').split(', '),
            }),
            ...(properties?.SEARCH_BY && {
              searchBy: properties.SEARCH_BY,
              ...searchByInfo[properties.SEARCH_BY],
            }),
            ...(properties?.TOOLTIP && {
              tooltip: properties.TOOLTIP,
            }),
            ...(properties?.DIAGRAM_VCD_TYPE && {
              diagramVcdType: properties.DIAGRAM_VCD_TYPE,
            }),
            ...(properties?.DIAGRAM_EPC_TYPE && {
              diagramEpcType: properties.DIAGRAM_EPC_TYPE,
            }),
          };
          newMeta[language][attributeCode] = {
            ...attributeConfig,
            ...propertiesConfig,
            Component: components[attributeConfig.type],
            'data-language': language,
            'data-code': attributeCode,
            disabled: isFreezed || attributeConfig.care === Care.SYSTEM || attributeConfig.disabled,
            onBlur: (event: React.FocusEvent) => handleBlur(event, getLanguage(language), attributeCode, attributeConfig.onBlur),
            onChange: (value: AttributeValue) => handleChange(value, getLanguage(language), attributeCode, attributeConfig.type),
            required: attributeConfig.care === Care.MANDATORY,
            checked,
          };
        },
      );
    });

    return newMeta;
  }, [formTypes, handleBlur, handleChange, isLegacy, getLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPlaceholder = (language: FormLanguage, code: AttributeCode, label: string) => {
    if (meta[language]?.[code]?.type === 'TEXT') {
      return t('textEnter');
    }
    return meta[language]?.[code]?.type === 'MULTISELECTION' || meta[language]?.[code]?.type === 'INPUT_FILE_CHIPS'
      ? t(`multiselection.placeholder.${meta[language]?.[code]?.['data-code']}`)
      : t('fieldEnter', { field: label, lng: language || language });
  };

  const getFieldProps = useCallback<(code: AttributeCode, language: FormLanguage, translationLanguage?: FormLanguage) => Field>(
    (code, language, translationLanguage) => {
      const label =
        translationLanguage === language
          ? t(`attributes.${code}.name`)
          : t(`attributes.${code}.name`, { lng: translationLanguage || language });
      const searchBy = meta[language]?.[code]?.searchBy;
      const value = getValue(code, values[getLanguage(language)]?.[code]);
      return {
        label,
        placeholder: setPlaceholder(language, code, label),
        ...meta[language]?.[code],
        code,
        catalog,
        tooltip: t(meta[getLanguage(language)]?.[code]?.tooltip || '', { lng: getLanguage(language) }),
        error: errorMessages[language]?.[code] || '',
        id: `${formCode || DEFAULT_FORM_CODE}_${code}`,
        key: `${code}_${language}`,
        touched: touched[language]?.[code],
        value,
        ...(meta[language]?.[code]?.type === Input.PROCESS_SELECTOR &&
          code === AttributeCode.ID_ORIGIN_PROCESS && {
            clearInput: () => {
              setValue(code, '');
              purifyForm();
            },
          }),
        getValue: (fieldCode: AttributeCode, fieldValue?: AttributeValue, variant?: VariantProperty) =>
          getValue(fieldCode, fieldValue, variant),
        ...(meta[language]?.[code]?.type === Input.CHECKBOX && { checked: values[language]?.[code] === 'true' }),
        ...(meta[language]?.[code]?.type === Input.DIAGRAM_SELECTOR && {
          selected: (values[language]?.[code] as string)?.replace('[', '').replace(']', '') ? values[language]?.[code] : '',
          ...(meta[language]?.[code]?.type === Input.INPUT_FILE_CHIPS && {
            icon: iconsFilesDisplay(values[FormLanguage.NOT_TRANSLATABLE]?.[code]?.toString()),
          }),
        }),
        ...(meta[language]?.[code]?.type === Input.URL &&
          code === AttributeCode.LINKAGE && {
            onClick: (event: React.MouseEvent) => {
              if (event.ctrlKey && values[language]?.[code] && urlRegEx.test(values[language]?.[code]?.toString() || '')) {
                const linkage = values[language]?.[code]?.toString();
                const includedProtocol = linkage?.toLowerCase().includes('https://');

                window.open(includedProtocol ? linkage : `//${linkage}`, '_blank', 'noopener noreferrer');
              }
            },
          }),
        ...(searchBy && catalog && meta[language]?.[code]?.type !== Input.DIAGRAM_SELECTOR
          ? {
              displayValue: searchByInfo[searchBy as SearchBy].displayValueFormatter(
                ((catalog[searchBy as SearchBy] || []) as any[]).find((element) => {
                  if (
                    typeof values[FormLanguage.NOT_TRANSLATABLE]?.[code] === 'string' &&
                    !stringIsArray(values[FormLanguage.NOT_TRANSLATABLE]?.[code])
                  ) {
                    return element.code === values[FormLanguage.NOT_TRANSLATABLE]?.[code];
                  }

                  if (Array.isArray(values[FormLanguage.NOT_TRANSLATABLE]?.[code])) {
                    return (values[FormLanguage.NOT_TRANSLATABLE]?.[code] as string[]).includes(element.code);
                  }

                  return false;
                }),
              ),
              selected: searchByInfo[searchBy].multipleValueFormatter(
                (catalog[searchBy] || []) as Object & { commonName: string; code: string }[],
                typeof values[FormLanguage.NOT_TRANSLATABLE]?.[code] === 'string' &&
                  values[FormLanguage.NOT_TRANSLATABLE]?.[code] &&
                  stringIsArray(values[FormLanguage.NOT_TRANSLATABLE]?.[code])
                  ? removeSquareBracketsFromString(values[FormLanguage.NOT_TRANSLATABLE]?.[code] as string)
                  : [],
              ),
            }
          : {}),
      } as Field;
    },
    [t, meta, catalog, getLanguage, errorMessages, formCode, touched, values, getValue, setValue, purifyForm], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fields = useMemo(() => {
    const newFields: FormFields = {};
    if (Object.keys(formTypes).length === 1 && Object.keys(formTypes)[0] === FormLanguage.NOT_TRANSLATABLE) {
      const langFields: Field[] = values
        ? [
            ...(Object.keys(formTypes[FormLanguage.NOT_TRANSLATABLE] || {}) as AttributeCode[]).map((code) =>
              getFieldProps(code, FormLanguage.NOT_TRANSLATABLE, FormLanguage.NOT_TRANSLATABLE),
            ),
          ]
        : [];

      newFields[diagramLanguage as Language] = langFields.sort((a, b) => a.position - b.position);
    }

    (Object.entries(formTypes) as [FormLanguage, LanguageFormTypes][]).forEach(([language, languageFormTypes]) => {
      if (language === FormLanguage.NOT_TRANSLATABLE) return;

      const languageFields: Field[] = values
        ? [
            ...(Object.keys(languageFormTypes) as AttributeCode[]).map((code) => getFieldProps(code, language)),
            ...(Object.keys(formTypes[FormLanguage.NOT_TRANSLATABLE] || {}) as AttributeCode[]).map((code) =>
              getFieldProps(code, FormLanguage.NOT_TRANSLATABLE, language),
            ),
          ]
        : [];

      newFields[language] = languageFields.sort((a, b) => a.position - b.position);
    });

    return newFields;
  }, [formTypes, getFieldProps, diagramLanguage, values]);

  const Form = useCallback(
    ({
      className,
      fields: fieldsProp,
      language = i18n.language,
      legacyStyles,
    }: {
      className: string;
      fields: FormFields;
      language?: string;
      legacyStyles?: boolean;
    }): React.ReactElement => (
      <div className={className}>
        <DiagramProvider>
          {fieldsProp[language as FormLanguage]?.map(({ Component, ...fieldProps }) => (
            <Component {...fieldProps} legacyStyles={legacyStyles} />
          ))}
        </DiagramProvider>
      </div>
    ),
    [i18n.language],
  );

  return {
    errors: errorMessages,
    fields,
    Form,
    isLanguageValid,
    isValid,
    meta,
    purifyForm,
    setValue,
    getValue,
    touched,
    values,
  };
}
