import React, { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormGenerator } from 'react-reactive-form';
import { v4 as uuidv4 } from 'uuid';

import {
  ADDITIONAL_DOCUMENTS,
  ATTRIBUTE_CARES,
  DOCUMENT_LINK,
  DOCUMENT_UUID,
  RECOMMENDATION_TYPE,
  RECOMMENDED_REWORK,
  STATUS,
} from 'assets/constants/constants';
import DialogLinkedForm from 'components/DialogLinkedForm/DialogLinkedForm';
import Spinner from 'components/UI/Spinner/Spinner';
import RecommendedMessage from 'pages/Recommendation/RecommendedMessage';

import WorkflowForm from './WorkflowForm';
import './AttributesForm.scss';

const AttributesForm = (props) => {
  const {
    isLoading,
    diagramStatus,
    previousForm,
    handleFormChange,
    fieldConfig,
    downloadWordFormat,
    formName,
    workflowFieldConfig,
    workflowData,
    loadWorkflow,
    savedDate,
    infoAutoSaved,
    hideAttributes,
    exportDiagram,
    attributesExpandedFullSize,
    updateDiagramName,
    updateLinkage,
    updateResponsibility,
    statusAttributes,
    isPublished,
    isRecommendation,
    isRecommendationReworked,
    recommendedMessage,
    formLanguage,
    availableLanguages,
  } = props;
  const { t } = useTranslation();
  const [linkedFormDialog, setLinkedFormDialog] = useState({ isOpen: false });
  const form = useRef(null);
  const statusTag = diagramStatus && <span className={`diagram-status ${diagramStatus}`}>{t(`${diagramStatus}`)}</span>;
  const recommendationTag = diagramStatus && isRecommendation && !isPublished && (
    <span className={`diagram-status ${RECOMMENDATION_TYPE}`}>{t('recommendations.label')}</span>
  );
  const recommendationReworkedTag = diagramStatus && isRecommendationReworked && !isPublished && (
    <span className={`diagram-status ${RECOMMENDATION_TYPE}`}>{t(`recommendations.type.${RECOMMENDED_REWORK}`)}</span>
  );

  let showSavedDate = '';
  const expandIcon = statusAttributes ? '' : '';
  const filteredFieldConfig = useMemo(() => {
    if (!isPublished || !fieldConfig) return fieldConfig;
    const newControls = { ...fieldConfig.controls };
    delete newControls.CREATION_DATE;
    delete newControls.CREATOR_CODE;
    delete newControls.LAST_EDITOR_CODE;
    delete newControls.LAST_MODIFICATION;

    return { ...fieldConfig, controls: newControls };
  }, [fieldConfig, isPublished]);

  const title = !hideAttributes ? (
    <div className="titles">
      {formName} {statusTag} {recommendationTag} {recommendationReworkedTag}
    </div>
  ) : (
    t('diagram.multipleSelection')
  );

  if (
    !hideAttributes &&
    savedDate &&
    savedDate.split('-').length === 2 &&
    formName === t('general') &&
    diagramStatus !== STATUS.WORKFLOW
  ) {
    showSavedDate = (
      <div className="savingTime">
        <div className="clockIcon"></div>
        <p className="lastSaved">{infoAutoSaved}</p>
        <p className="savedDate">{savedDate.split('-')[0]}</p>
        <div className="separatorIcon">•</div>
        <p className="savedDate">{savedDate.split('-')[1]}</p>
      </div>
    );
  }

  const workflowAttributes = (
    <WorkflowForm data={workflowData} fieldConfig={workflowFieldConfig} key={new Date()} loadWorkflow={loadWorkflow} />
  );

  if (isLoading || !fieldConfig) {
    return (
      <div className="spinner">
        <Spinner isVisible />
      </div>
    );
  }

  const submitAdditionalDocumentsForm = (newValue) => {
    if (!form.current.get(ADDITIONAL_DOCUMENTS)) return;

    const documents = form.current.get(ADDITIONAL_DOCUMENTS).value;
    const index = documents.findIndex((document) => document[DOCUMENT_UUID] === newValue[formLanguage][DOCUMENT_UUID]);

    if (index > -1) {
      documents[index] = newValue[formLanguage];
    } else {
      const uuid = uuidv4();
      availableLanguages.forEach((language) => {
        newValue[language][DOCUMENT_UUID] = uuid;
      });
      documents.push(newValue[formLanguage]);
    }
    form.current.get(ADDITIONAL_DOCUMENTS).patchValue(documents);
    form.current.get(ADDITIONAL_DOCUMENTS).meta.submitAdditionalDocumentsForm(newValue);
  };

  const handleClickAddAdditionalDocuments = () => {
    setLinkedFormDialog({
      code: ADDITIONAL_DOCUMENTS,
      formTypes: form.current.get(ADDITIONAL_DOCUMENTS).meta.linkedForm,
      isOpen: true,
      submit: submitAdditionalDocumentsForm,
      title: t('additionalDocuments.dialog.title.add', { lng: formLanguage }),
    });
  };

  const handleClickAdditionalDocument = (document) => {
    if (form.current.get(ADDITIONAL_DOCUMENTS)?.meta.attributeType === ATTRIBUTE_CARES.SYSTEM) {
      window.open(document[DOCUMENT_LINK], '_blank', 'noopener noreferrer');

      return;
    }
    setLinkedFormDialog({
      code: ADDITIONAL_DOCUMENTS,
      formTypes: form.current.get(ADDITIONAL_DOCUMENTS).meta.linkedForm,
      isOpen: true,
      submit: submitAdditionalDocumentsForm,
      title: t('additionalDocuments.dialog.title.update', { lng: formLanguage }),
      value: form.current.get(ADDITIONAL_DOCUMENTS).meta.getAdditionalDocumentValues(document[DOCUMENT_UUID]),
    });
  };

  const onMount = (mountedForm) => {
    if (!mountedForm) return;
    mountedForm.patchValue(previousForm);
    form.current = mountedForm;

    form.current.valueChanges.subscribe((values) => {
      handleFormChange(values, form.current.invalid);
    });

    if (form.current.get('PROCESS_NAME')) {
      form.current.get('PROCESS_NAME').valueChanges.subscribe((value) => {
        updateDiagramName(value);
      });
    }

    if (form.current.get('LINKAGE')) {
      form.current.get('LINKAGE').valueChanges.subscribe((value) => {
        updateLinkage(value);
      });
    }

    if (form.current.get('RESPONSIBILITY')) {
      form.current.get('RESPONSIBILITY').valueChanges.subscribe((value) => {
        updateResponsibility(value);
      });
    }

    if (form.current.get('LINK_RESPONSIBILITY')) {
      form.current.get('LINK_RESPONSIBILITY').valueChanges.subscribe((value) => {
        updateResponsibility(value);
      });
    }

    if (form.current.get(ADDITIONAL_DOCUMENTS)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      form.current.get(ADDITIONAL_DOCUMENTS).meta.onClickAdd = handleClickAddAdditionalDocuments;
      form.current.get(ADDITIONAL_DOCUMENTS).meta.onClickChip = handleClickAdditionalDocument;
    }
  };

  const downloadManual = (extension = 'pdf') => {
    exportDiagram(extension);
  };

  return (
    <>
      <h5 className="attributes-title">
        {title}
        {!hideAttributes && (
          <div className="titles">
            <span className="di attributesIcons" id="attr-expand" onClick={() => attributesExpandedFullSize()}>
              {expandIcon}
            </span>
            <span className="di attributesIcons" id="attr-download" onClick={() => downloadManual()}>
              
            </span>
            {downloadWordFormat && (
              <span className="di attributesIcons" id="attr-download-word" onClick={() => downloadManual('docx')}>
                <i className="di icon-datei-doc" />
              </span>
            )}
          </div>
        )}
      </h5>
      {showSavedDate}
      {recommendedMessage && <RecommendedMessage {...recommendedMessage} />}
      {hideAttributes ? (
        <p>{t('diagram.multipleSymbols')}</p>
      ) : (
        <FormGenerator fieldConfig={filteredFieldConfig} onMount={onMount} />
      )}
      {loadWorkflow && !hideAttributes && workflowFieldConfig.controls && workflowAttributes}
      {linkedFormDialog.isOpen && (
        <DialogLinkedForm
          close={() => setLinkedFormDialog({ isOpen: false })}
          code={linkedFormDialog.code}
          formTypes={linkedFormDialog.formTypes}
          language={formLanguage}
          submit={linkedFormDialog.submit}
          title={linkedFormDialog.title}
          value={linkedFormDialog.value}
        />
      )}
    </>
  );
};

export default React.memo(
  AttributesForm,
  (prev, next) =>
    !prev.isLoading === !next.isLoading &&
    prev.loadWorkflow === next.loadWorkflow &&
    prev.hideAttributes === next.hideAttributes &&
    JSON.stringify(prev.previousForm) === JSON.stringify(next.previousForm) &&
    JSON.stringify(prev.fieldConfig) === JSON.stringify(next.fieldConfig) &&
    prev.fieldConfig === next.fieldConfig &&
    prev.savedDate === next.savedDate &&
    prev.infoAutoSaved === next.infoAutoSaved &&
    prev.statusAttributes === next.statusAttributes,
);
