import { useEffect, useRef, useState } from 'react';

import { SYMBOL } from 'assets/constants/constants';
import useBackendForm from 'hooks/useBackendForm';
import useDiagramContext from 'hooks/useDiagramContext';
import useFormTypes from 'hooks/useFormTypes';
import { AttributeCode, Attributes } from 'types/forms';
import { ProcessType } from 'types/processes';
import { SymbolTypes } from 'types/symbols';

import styles from './SymbolAttributes.module.scss';

type Props = {
  attributes: Attributes;
  id: string;
  symbolType: SymbolTypes;
  top: number;
  width: number;
};

const SymbolAttributes = (props: Props) => {
  const { attributes, id, symbolType, top, width } = props;
  const { diagramLanguage, processData } = useDiagramContext();
  const { getFormTypesCode } = useFormTypes();
  const ref = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState('10%');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const symbolNameAttribute = processData?.type === ProcessType.SWIMLANE ? AttributeCode.OBJECT_NAME : AttributeCode.NAME;
  const nameAttribute = symbolType === SymbolTypes.TEXT ? AttributeCode.TEXT_BLOCK : symbolNameAttribute;

  const formCode = getFormTypesCode({
    status: processData?.status,
    type: SYMBOL,
    variant: symbolType,
  });

  const { fields, values } = useBackendForm({
    formCode,
    initialValues: attributes,
    id,
    usesDiagramTranslation: true,
    isTouchedNeeded: false,
  });

  useEffect(() => {
    if (!Object.keys(fields).length || !Object.keys(values).length || !isLoading) return;
    setIsLoading(false);
  }, [fields, isLoading, values]);

  useEffect(() => {
    const offsetWidth = ref.current ? ref.current.offsetWidth : 0;
    const clientWidth = ref.current ? ref.current.clientWidth : 0;

    if (offsetWidth > clientWidth) {
      setLeft('10%');
    } else {
      setLeft('35%');
    }
    setHasLoaded(true);
  }, [ref, width]);

  return (
    <div
      className={styles.Container}
      style={{
        top: `calc(${top}em - 8%)`,
        left,
        visibility: hasLoaded ? 'visible' : 'hidden',
      }}
    >
      <div className={styles.Form}>
        {!isLoading &&
          fields[diagramLanguage]?.map(({ Component, ...fieldProps }) => {
            if (fieldProps.code === nameAttribute) return;

            return <Component {...fieldProps} className={styles.FormAttribute} disabled value={fieldProps.value} />;
          })}
      </div>
    </div>
  );
};

export default SymbolAttributes;
