import { useRef } from 'react';

import { InputComponent } from 'types/forms';

interface Props {
  children: React.ReactNode;
  handleContainerClick: () => void;
  hasInfo?: boolean;
  input: InputComponent;
  isEditing?: boolean;
  isFocused?: boolean;
  label: string;
  required?: boolean;
  placeholder?: string;
}

const InputLabel = (props: Props) => {
  const { children, handleContainerClick, hasInfo, input, isEditing, isFocused, label, required, placeholder } = props;
  const { className, disabled, error, id, reference, styles, value } = input;
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentContainerRef = reference || newContainerRef;
  const setFloatingStyle = isEditing || value?.trim() !== '' || placeholder;

  return (
    <label
      className={`${styles.Container} ${isFocused ? styles.Focused : ''} ${disabled ? styles.Disabled : ''}  ${className || ''} ${
        error.hasError && !isFocused ? styles.Error : ''
      }`}
      htmlFor={id}
      onClick={() => handleContainerClick()}
      ref={currentContainerRef as React.RefObject<HTMLLabelElement>}
    >
      <div className={`${styles.Label} ${setFloatingStyle ? styles.Floating : ''} ${error.hasError ? styles.Error : ''}`}>
        {label}
        {required && <span className="error">*</span>}
        {error.hasError && <i className={`di ${error.icon} error ${styles.IconError}`} />}
        {hasInfo && <i className={`di icon-information ${styles.Info}`} />}
      </div>
      {children}
    </label>
  );
};

export default InputLabel;
