import { useCallback } from 'react';

import clone from 'just-clone';

import { ProcessLevelTableFormatted } from 'types/processLevel';

type Props = {
  rows: ProcessLevelTableFormatted[];
  extendedRows: string[];
  checkedMap?: Record<string, boolean>;
  rowRenderer: (row: ProcessLevelTableFormatted) => any;
  searchText?: string;
  propertiesToSearchIn: string[];
};

const filterByProperties = (propertiesToSearchIn, row, searchText) => {
  let filteredRow = '';
  propertiesToSearchIn.forEach((property) => {
    if (!!filteredRow === true) return;
    if (typeof row[property] === 'string') {
      filteredRow = row[property].toLowerCase().includes(searchText!!.toLowerCase());
      return filteredRow;
    }
    filteredRow = row[property].some((element) => element.toLowerCase().includes(searchText!!.toLowerCase()));
  });
  return filteredRow;
};

export default function useTreeStructure(props: Props) {
  const { rows, extendedRows, checkedMap = {}, rowRenderer, searchText, propertiesToSearchIn } = props;
  const filter = (row: any) => {
    if (!row.children || row.children.length === 0) {
      return filterByProperties(propertiesToSearchIn, row, searchText);
    }

    const filteredChildren = (row.children as typeof row[]).filter((child) => filter(child));
    row.children = filteredChildren as typeof row.children;

    return row.children.length > 0 || filterByProperties(propertiesToSearchIn, row, searchText);
  };

  const treeRenderer = useCallback((): JSX.Element[] | undefined => {
    if (!rows) return;

    const filteredRows = !searchText ? rows : clone(rows).filter((row) => filter(row));
    return filteredRows.map((row) => rowRenderer(row));
  }, [rows, extendedRows, checkedMap, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    tree: treeRenderer(),
  };
}
