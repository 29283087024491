import * as Constants from '../assets/constants/constants';
import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const baseDocUrl = '/backend/ms-documentation/api';
const docUrl = `${baseDocUrl}/v1`;
const docUrlv2 = `${baseDocUrl}/v2`;

const uploadImage = (formData) => axiosInstance.post(`${docUrlv2}/storage/`, formData);

const saveFinalImages = (processNumber, images) =>
  axiosInstance.post(`${docUrl}/storage/diagram/img/save/${processNumber}`, images);

const deleteImages = (urls) => axiosInstance.delete(`${docUrlv2}/storage`, { data: urls });

const getVersionsControl = (processNumber) => axiosInstance.get(`${docUrl}/storage/diagram/${processNumber}/versions-control`);

const getDocument = (
  processNumber,
  version,
  language,
  container = Constants.DIAGRAM,
  extension = 'pdf',
  location = '',
  convertToLocation = false,
) => {
  const params = {
    ...(location ? { location } : {}),
    ...(convertToLocation ? { convertToLocation } : {}),
  };
  return axiosInstance.get(`${docUrl}/storage/${container}/${processNumber}/${version}/${extension}`, {
    responseType: 'blob',
    headers: { 'Accept-Language': language },
    params,
  });
};

export const searchDataset = (data) => axiosInstance.post(`${docUrl}/search/dataset`, data);

export const searchPublished = (data) => axiosInstance.post(`${docUrl}/search`, data);

const getReport = (type) => axiosInstance.get(`${docUrl}/storage/report-all-data`, { responseType: 'blob', params: { type } });

const getProcessesReport = () => axiosInstance.get(`${docUrl}/storage/mqs-report`, { responseType: 'blob' });

const getDepartmentViewProcesses = (departmentId, isFromDepartment, params = {}) => {
  const searchParams = new URLSearchParams(params).toString();
  return axiosInstance.get(
    `${docUrl}/search/department-view/${isFromDepartment ? 'from' : 'valid-for'}-the-department/${departmentId}?${searchParams}`,
  );
};

export const getDatasetDocument = ({ id, name, version }) =>
  axiosInstance.get(`${docUrl}/storage/dataset/${id}`, {
    responseType: 'blob',
    params: { documentName: name, version },
  });

export const getRequirementDocument = (id, name, context, processResponsibleId) =>
  axiosInstance.get(`${docUrl}/storage/request/documents/${id}?context=${context}`, {
    responseType: 'blob',
    params: { documentName: name, processResponsibleId },
  });

export default {
  handleServiceError: serviceUtils.handleServiceError,
  uploadImage,
  deleteImages,
  getVersionsControl,
  getDocument,
  searchDataset,
  searchPublished,
  getDatasetDocument,
  getReport,
  getProcessesReport,
  getDepartmentViewProcesses,
  saveFinalImages,
};
