import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PostItComponent from 'components/PostIt/PostIt';
import DialogError from 'components/UI/DialogBasics/DialogError';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import useSymbol from 'hooks/useSymbol';
import { PostIt, PostItTypes } from 'types/symbols';

import styles from './SymbolBoardCategory.module.scss';

const SymbolBoardCategory = (props: {
  category: PostItTypes;
  isLowerLane?: boolean;
  title: string;
  postIts: PostIt[];
  symbolId: string;
}) => {
  const { isLowerLane, title, postIts, symbolId, category } = props;
  const { addPostIt } = useSymbol();
  const { t } = useTranslation();
  const [showMaximumPopUp, setShowMaximumPopUp] = useState(false);
  return (
    <div className={`${styles.PostItContainer} ${isLowerLane ? styles.LowerLane : ''}`}>
      <div className={styles.Header}>
        {title}
        <RoundButton
          className={styles.AddPostIt}
          icon="di icon-plus-hinzufuegen-klein"
          id="add-postIt"
          onClick={() => (postIts.length < 10 ? addPostIt(category, symbolId) : setShowMaximumPopUp(true))}
        />
      </div>
      <div className={`${styles.NotesContainer} ${isLowerLane ? styles.LowerLane : ''}`}>
        {postIts?.length > 0
          ? postIts.map((postIt: PostIt) => (
              <PostItComponent
                category={category}
                isLowerLane={isLowerLane}
                key={postIt.id}
                postIt={postIt}
                symbolId={symbolId}
                title={title}
              />
            ))
          : t('postIts.emptyCategory')}
      </div>
      {showMaximumPopUp && (
        <DialogError handleConfirm={() => setShowMaximumPopUp(false)} title={t('error')}>
          {t('maximumPostItsError')}
        </DialogError>
      )}
    </div>
  );
};

export default SymbolBoardCategory;
