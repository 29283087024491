import { useCallback, useContext } from 'react';

import clone from 'just-clone';
import { useTranslation } from 'react-i18next';
import { Validators } from 'react-reactive-form';
import { useLocation } from 'react-router-dom';

import {
  AREAS,
  ATTRIBUTE_CARES,
  CATALOG_OBJECT,
  CATALOG_OBJECT_CREATION,
  DIAGRAM,
  KPI,
  DS,
  DATA_SET,
  NOT_TRANSLATABLE,
  OBJECT,
  SYMBOL,
  RECOMMENDED,
  DELEGATE_DIAGRAM,
  RR,
  REQUIREMENT_REQUEST,
  PROCESS_LEVEL,
  PL,
  ACTIVITY_LEVEL,
  MODELING_STATUS,
  EPC_DIAGRAM,
} from 'assets/constants/constants';
import { getComponent } from 'assets/js/DiagramUtils';
import { checkWhiteSpacesValidation, getArea } from 'assets/js/Utils';
import { FormTypesActions, FormTypesContext } from 'contexts/FormTypes/FormTypesContext';
import useAuth from 'hooks/useAuth';
import useDiagramConfig from 'hooks/useDiagramConfig';
import useError from 'hooks/useError';
import formService from 'services/formService';
import { Input, VariantProperty } from 'types/forms';

import useFeatureFlags from './useFeatureFlags';

const TYPE_CODES = {
  [DIAGRAM]: 'D',
  [DELEGATE_DIAGRAM]: 'DG',
  [OBJECT]: 'O',
  [CATALOG_OBJECT]: 'OC',
  [CATALOG_OBJECT_CREATION]: 'OCC',
  [KPI]: 'KPI',
  [SYMBOL]: 'S',
  [AREAS.WORKFLOW]: 'W',
  [DS]: 'DS',
  [DATA_SET]: 'DATA_SET',
  [RR]: 'RR',
  [REQUIREMENT_REQUEST]: 'REQUIREMENT_REQUEST',
  [PROCESS_LEVEL]: 'PROCESS_LEVEL',
  [PL]: 'PL',
  [ACTIVITY_LEVEL]: '',
};

const fieldTypeInitialValueMap = {
  [Input.DATE_TIME]: '',
  [Input.DOCUMENT_ID]: '',
  [Input.DROPDOWN]: '',
  [Input.MULTISELECTION]: [],
  [Input.PROCESS_ID]: '',
  [Input.SEARCHINPUT]: '',
  [Input.TEXT]: '',
  [Input.TEXTAREA]: '',
  [Input.URL]: '',
  [Input.USER_ID]: '',
  [Input.WYSIWYG]: '',
  [Input.WYSIWYG2]: '',
};

const useFormTypes = () => {
  const context = useContext(FormTypesContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { handleServiceError } = useError();
  const { setDiagramConfig } = useDiagramConfig();
  const { isOnlyUser } = useAuth(true);
  const { isFreezed } = useFeatureFlags();

  if (context === undefined) {
    throw new Error('useFormTypes must be used within a FormTypesProvider');
  }

  const { state, dispatch } = context;

  const fetchFormTypes = useCallback(
    async (code) => {
      if (state[code]) return;
      dispatch({ type: FormTypesActions.GET_FORMTYPES });
      try {
        const {
          data: { forms, configurations },
        } = await formService.getFormTypesService(code);
        const payload = forms[code] ? forms : { ...forms, [code]: true };
        dispatch({ type: FormTypesActions.GET_FORMTYPES_SUCCESS, payload });
        if (configurations) setDiagramConfig(configurations);
      } catch (error) {
        dispatch({ type: FormTypesActions.GET_FORMTYPES_ERROR });
        handleServiceError(error, { shouldRefreshPage: true });
      }
    },
    [dispatch, handleServiceError, setDiagramConfig, state],
  );

  const getFormTypesCode = ({
    isGroupFetch,
    stage,
    workflowStage,
    status,
    type,
    variant,
    isRecommended,
    actionType,
    isRequirement,
  }) => {
    const typeCode = isGroupFetch ? type.toUpperCase() : TYPE_CODES[type];
    const statusCode = status && (isRequirement ? status : getArea(location.pathname, status, isOnlyUser()));
    const recommended = isRecommended ? `_${RECOMMENDED}` : '';
    const wfStage = workflowStage ? `_${workflowStage}` : '';

    return `${typeCode}${recommended}${variant ? `_${variant}${wfStage}` : ''}_${statusCode || stage || actionType}`;
  };

  const mapAllAttributesToSystem = (fieldConfig) => {
    const mappedFieldConfig = { ...fieldConfig };

    Object.keys(mappedFieldConfig).forEach((language) => {
      Object.keys(mappedFieldConfig[language].controls).forEach((key) => {
        mappedFieldConfig[language] = {
          controls: {
            ...mappedFieldConfig[language].controls,
            [key]: {
              ...mappedFieldConfig[language].controls[key],
              meta: {
                ...mappedFieldConfig[language].controls[key].meta,
                attributeType:
                  key === 'LINKAGE' &&
                  mappedFieldConfig[language].controls[key].meta.id.includes(MODELING_STATUS) &&
                  !mappedFieldConfig[language].controls[key].meta.id.includes(EPC_DIAGRAM) &&
                  !isFreezed
                    ? ATTRIBUTE_CARES.OPTIONAL
                    : ATTRIBUTE_CARES.SYSTEM,
              },
              options: {},
            },
          },
        };
      });
    });

    return mappedFieldConfig;
  };

  // TODO: delete this method and all its uses when all forms have been migrated
  const getReactReactiveFormFieldConfig = (code, onlyRead) => {
    if (state.old?.[code]) return onlyRead ? mapAllAttributesToSystem(clone(state.old[code])) : clone(state.old[code]);
    const formLanguages = Object.keys(state[code]).filter((language) => language !== NOT_TRANSLATABLE);
    const attributeKeys = Object.entries({ ...state[code][formLanguages[0]], ...state[code][NOT_TRANSLATABLE] })
      .sort((a, b) => a[1].position - b[1].position)
      .map(([key]) => key);
    const oldFieldConfig = Object.fromEntries(formLanguages.map((language) => [language, { controls: {} }]));

    attributeKeys.forEach((key) => {
      formLanguages.forEach((language) => {
        const keyConfig = state[code][language][key] || state[code][NOT_TRANSLATABLE][key];
        if (key === 'PROCESS_STEP_NUMBER') {
          keyConfig.type = VariantProperty.NUMERIC;
        }
        oldFieldConfig[language].controls = {
          ...oldFieldConfig[language].controls,
          [key]: {
            meta: {
              attributeType: keyConfig.care,
              code: key,
              // TODO: uncomment this when description tooltip component is refactored
              // description: t(`descriptionAttributes.${key}`, { lng: language, defaultValue: '' }),
              fieldType: keyConfig.type,
              id: `${code}_${key}`,
              ...(keyConfig.properties?.IS_CODE && { isCode: true }),
              ...(keyConfig.properties?.ITEM_ID && { itemId: keyConfig.properties.ITEM_ID }),
              label: t(`nameAttributes.${key}`, { lng: language }),
              ...(keyConfig.properties?.MAX && { max: keyConfig.properties.MAX }),
              ...(keyConfig.properties?.MIN && { min: keyConfig.properties.MIN }),
              ...(keyConfig.properties?.OPTIONS && {
                options: keyConfig.properties.OPTIONS.replace('[', '')
                  .replace(']', '')
                  .split(', ')
                  .map((option) => ({
                    selected: false,
                    text: t(`attributes.${key}.options.${option}`, { lng: language, defaultValue: option }),
                    value: option,
                  })),
              }),
              placeholder: t(`nameAttributes.${key}`, { lng: language }),
              position: keyConfig.position,
              ...(keyConfig.properties?.SEARCH_BY && { searchBy: keyConfig.properties.SEARCH_BY }),
              ...(keyConfig.properties?.LINKED_FORM && { linkedForm: state[keyConfig.properties.LINKED_FORM] }),
            },
            ...(keyConfig.care === ATTRIBUTE_CARES.MANDATORY && {
              options: { validators: [Validators.required, checkWhiteSpacesValidation] },
            }),
            tooltip: keyConfig.properties?.TOOLTIP ? keyConfig.properties.TOOLTIP : '',
            render: getComponent(keyConfig.type),
          },
        };
      });
    });

    dispatch({ type: FormTypesActions.SET_OLD_FORMTYPES, payload: { key: code, fieldConfig: oldFieldConfig } });

    return onlyRead ? mapAllAttributesToSystem(clone(oldFieldConfig)) : clone(oldFieldConfig);
  };

  const getAttributesData = (formTypesCode) => {
    const formTypes = state[formTypesCode];
    const formLanguages = Object.keys(formTypes);
    const attributesData = [];

    formLanguages.forEach((language) => {
      Object.keys(formTypes[language]).forEach((key) => {
        if (attributesData.find((attribute) => attribute.code === key)) return;

        attributesData.push({
          code: key,
          fieldType: formTypes[language][key].type,
          translatable: language !== NOT_TRANSLATABLE,
        });
      });
    });

    return attributesData;
  };

  const getInitialValues = (formTypes) => {
    if (!formTypes) return;

    return Object.fromEntries(
      Object.entries(formTypes).map(([language, attributes]) => [
        language,
        Object.fromEntries(
          Object.keys(attributes).map((code) => [code, fieldTypeInitialValueMap[formTypes[language]?.[code]?.type]]),
        ),
      ]),
    );
  };

  return {
    ...state,
    fetchFormTypes,
    getFormTypesCode,
    getReactReactiveFormFieldConfig,
    getAttributesData,
    getInitialValues,
  };
};

export default useFormTypes;
