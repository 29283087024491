import React, { useState } from 'react';

import styles from './CheckBox.module.scss';

const CheckboxPlain = ({ label, getChecked, id, checked }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const checkIcon = <i className="di icon-check" />;

  const checkbox = () => {
    getChecked(!isChecked, id);
    setIsChecked(!isChecked);
  };

  return (
    <div className={styles.CheckFilter} onClick={() => checkbox()}>
      <div className={`${styles.Box} ${isChecked ? styles.Checked : ''}`}>{isChecked && checkIcon}</div>
      <div className={styles.Label}>{label}</div>
    </div>
  );
};

export default CheckboxPlain;
