import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EPC_DIAGRAM_ID, VCD_DIAGRAM_ID, SIPOC_DIAGRAM_ID, SWIMLANE_DIAGRAM_ID } from 'assets/constants/constants';
import { handleServiceError, isCentral } from 'assets/js/serviceUtils';
import SelectionActions from 'components/Selection/SelectionActions';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes, setDiagramsToImport, setDiagramsToLink, setDiagramsToMove } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useProcess from 'hooks/useProcess';
import useResize from 'hooks/useResize';
import useSwimlane from 'hooks/useSwimlane';
import useSymbol from 'hooks/useSymbol';
import { getSandboxDiagramsToImport } from 'services/design';
import { Coordinate, DiagramToImport, ResizeHandles } from 'types/diagram';
import { ProcessType, ProcessStatus } from 'types/processes';
import { SymbolTypes, SymbolActions, SelectionButton } from 'types/symbols';

import styles from './Selection.module.scss';

// TODO uncomment if resize function is required
const handles = [
  { handleId: ResizeHandles.CENTER_LEFT, style: styles.CenterLeft },
  { handleId: ResizeHandles.CENTER_RIGHT, style: styles.CenterRight },
];

const Selection = ({
  swimlaneId,
  symbolId,
  onClick,
  withinSymbolBoard,
  className,
  actionButtons,
  symbolType,
}: {
  swimlaneId?: string;
  symbolId: string;
  onClick?: (action?: SymbolActions, symbolId?: string) => void;
  withinSymbolBoard?: boolean;
  className?: string;
  actionButtons?: SelectionButton[];
  symbolType?: string;
}) => {
  const { selectedSymbolIds, processData, dispatch, fontSize, currentLevel } = useDiagramContext();
  const { checkDeleteSwimlaneSymbol, duplicateSwimlaneSymbol, getSelectedSwimlane } = useSwimlane();
  const { checkDeleteSymbol, checkCopySymbol, togglePostIts } = useSymbol();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [processAction, setProcessAction] = useState<SymbolActions>();
  const paletteButtonRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { createProcess, getTree } = useProcess(id);
  const checkEnvironment = useEnvironment();
  const selectedSymbol = processData?.symbols.find((symbol) => symbol.id === symbolId);
  const { isCreateSandboxSIPOCEnabled, isCreateSwimlaneEnabled, isImportInSIPOCEnabled, isFreezed } = useFeatureFlags();
  const isSwimlane = processData?.type === ProcessType.SWIMLANE;
  // TODO uncomment if resize function is required
  const { handleResize } = useResize();

  const handleSize = (fontSize || 14) / 2;

  const clickOption = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    setOpenSubMenu(
      (action === SymbolActions.IMPORT && isImportInSIPOCEnabled) ||
        [SymbolActions.CREATE, SymbolActions.MOVE, SymbolActions.LINK].includes(action as SymbolActions),
    );
    setProcessAction(action as SymbolActions);

    if (symbolId.includes(SymbolTypes.PROCESS_INTERFACE) && onClick) {
      onClick();
      setIsMenuOpen(false);
    }
    if (!selectedSymbol || Object.keys(SymbolActions).includes(action)) return;

    createProcess(selectedSymbol.id, action);
  };

  const handleImport = (type: ProcessType, selectedSymbolId: string) => {
    getSandboxDiagramsToImport(type)
      .then((response) => {
        let sandboxTree = response.data.sandbox;

        if (!isCentral() && response.data.recommendation) {
          sandboxTree = [
            ...sandboxTree,
            ...response.data.recommendation.map((recommendation: DiagramToImport) => ({
              ...recommendation,
              isRecommendation: true,
            })),
          ];
        }

        dispatch(setDiagramsToImport(sandboxTree));

        if (onClick && sandboxTree.length > 0) {
          onClick(SymbolActions.IMPORT, selectedSymbolId);
        }
      })
      .catch((err) => {
        handleServiceError(err);
      });

    setIsMenuOpen(false);
    setOpenSubMenu(false);
  };

  const handleTreeDiagram = (type: ProcessType, selectedSymbolId: string, action: SymbolActions) => {
    dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
    getTree().then((res) => {
      const diagramIdByType: { [key in ProcessType]?: number } = {
        [ProcessType.SIPOC]: SIPOC_DIAGRAM_ID,
        [ProcessType.SWIMLANE]: SWIMLANE_DIAGRAM_ID,
        [ProcessType.VCD]: VCD_DIAGRAM_ID,
        [ProcessType.EPC]: EPC_DIAGRAM_ID,
      };

      const diagramsList = res.filter(
        (elem: { id: string; idDiagramType: number | undefined }) =>
          elem.id !== id && elem.idDiagramType === diagramIdByType[type],
      );

      if (action === SymbolActions.MOVE) {
        dispatch(setDiagramsToMove(diagramsList));
      } else if (action === SymbolActions.LINK) {
        dispatch(setDiagramsToLink(diagramsList));
      }

      if (onClick && diagramsList.length > 0) {
        dispatch({ type: DiagramActionTypes.SET_LOADING_FALSE });
        onClick(action, selectedSymbolId);
      }
    });

    setIsMenuOpen(false);
    setOpenSubMenu(false);
  };

  const postItsButton = {
    icon: 'di icon-klemmbrett-aufgabe-liste',
    id: 'symbol-display-post-its-button',
    onClick: () => selectedSymbolIds?.length === 1 && togglePostIts(symbolId),
    tooltipLabel: withinSymbolBoard ? t('tool.hidePostIts') : t('tool.showPostIts'),
  };

  const buttons = [
    {
      icon: 'di icon-overflow-mehr-vertikal',
      id: 'symbol-context-menu-button',
      onClick: () => setIsMenuOpen(!isMenuOpen),
      tooltipLabel: t('tool.actions'),
      disabled: isFreezed,
    },
    {
      icon: 'di icon-datei-doppelt-kopieren',
      id: 'symbol-copy-button',
      onClick: () => {
        if (selectedSymbolIds?.length === 1 && processData) {
          if (processData.type === ProcessType.SWIMLANE) {
            const selectedSwimlane = getSelectedSwimlane(swimlaneId, processData?.swimlanes);

            duplicateSwimlaneSymbol(symbolId, swimlaneId, selectedSwimlane?.symbols);
          } else {
            checkCopySymbol(symbolId);
          }
        }
      },
      disabled: isFreezed,
      tooltipLabel: `${t('tool.copy')}/${t('tool.paste')}`,
    },
    {
      icon: 'di icon-muelleimer-loeschen',
      id: 'symbol-delete-button',
      onClick: () => {
        if (selectedSymbolIds?.length === 1 && processData) {
          if (processData.type === ProcessType.SWIMLANE && symbolType !== SymbolTypes.TEXT) {
            const selectedSwimlane = getSelectedSwimlane(swimlaneId, processData?.swimlanes);
            checkDeleteSwimlaneSymbol(symbolId, selectedSwimlane);
          } else {
            checkDeleteSymbol(symbolId);
          }
        }
      },
      tooltipLabel: t('tool.delete'),
      disabled: isFreezed,
    },
  ];

  const getFilteredButtons = () => {
    if (processData?.isOnlyRead) return [];

    const availableButtons =
      !selectedSymbol?.diagramLink?.id && (processData?.status !== ProcessStatus.SANDBOX || isCreateSandboxSIPOCEnabled)
        ? buttons
        : buttons.slice(1);

    if (checkEnvironment('sandbox') && !isSwimlane && symbolType !== SymbolTypes.TEXT)
      return withinSymbolBoard ? [postItsButton] : [...availableButtons, postItsButton];

    return availableButtons;
  };

  const menuOptions = [
    {
      key: SymbolActions.CREATE,
      icon: 'di icon-plus-hinzufuegen-klein',
    },
    {
      key: SymbolActions.IMPORT,
      icon: 'di icon-import',
    },
    {
      key: SymbolActions.MOVE,
      icon: 'di icon-pfeil-oben',
    },
    {
      key: SymbolActions.LINK,
      icon: 'di icon-link',
    },
  ];

  const createSubMenuOptions = [
    {
      key: SymbolActions.CLOSE,
      icon: 'di icon-pfeil-chevron-links',
      style: styles.Create,
      onClick: (event: React.MouseEvent) => clickOption(event, SymbolActions.CLOSE),
    },
    {
      key: ProcessType.SIPOC,
      onClick: (event: React.MouseEvent) => clickOption(event, ProcessType.SIPOC),
    },
    {
      key: ProcessType.SWIMLANE,
      onClick: (event: React.MouseEvent) => clickOption(event, ProcessType.SWIMLANE),
    },
  ];

  const importSubMenuOptions = [
    {
      key: SymbolActions.CLOSE,
      icon: 'di icon-pfeil-chevron-links',
      style: styles.Create,
      onClick: (event: React.MouseEvent) => clickOption(event, SymbolActions.CLOSE),
    },
    {
      key: ProcessType.SIPOC,
      onClick: () => handleImport(ProcessType.SIPOC, selectedSymbolIds[0]),
    },
    {
      key: ProcessType.SWIMLANE,
      onClick: () => handleImport(ProcessType.SWIMLANE, selectedSymbolIds[0]),
    },
    {
      key: ProcessType.EPC,
      onClick: () => handleImport(ProcessType.EPC, selectedSymbolIds[0]),
    },
  ];

  const linkSubMenuOptions = [
    {
      key: SymbolActions.CLOSE,
      icon: 'di icon-pfeil-chevron-links',
      style: styles.Create,
      onClick: (event: React.MouseEvent) => clickOption(event, SymbolActions.CLOSE),
    },
    {
      key: ProcessType.SIPOC,
      onClick: () => handleTreeDiagram(ProcessType.SIPOC, selectedSymbolIds[0], SymbolActions.LINK),
    },
    {
      key: ProcessType.SWIMLANE,
      onClick: () => handleTreeDiagram(ProcessType.SWIMLANE, selectedSymbolIds[0], SymbolActions.LINK),
    },
    {
      key: ProcessType.VCD,
      onClick: () => handleTreeDiagram(ProcessType.VCD, selectedSymbolIds[0], SymbolActions.LINK),
    },
    {
      key: ProcessType.EPC,
      onClick: () => handleTreeDiagram(ProcessType.EPC, selectedSymbolIds[0], SymbolActions.LINK),
    },
  ];

  const moveSubMenuOptions = [
    {
      key: SymbolActions.CLOSE,
      icon: 'di icon-pfeil-chevron-links',
      style: styles.Create,
      onClick: (event: React.MouseEvent) => clickOption(event, SymbolActions.CLOSE),
    },
    {
      key: ProcessType.SIPOC,
      onClick: () => handleTreeDiagram(ProcessType.SIPOC, selectedSymbolIds[0], SymbolActions.MOVE),
    },
    {
      key: ProcessType.SWIMLANE,
      onClick: () => handleTreeDiagram(ProcessType.SWIMLANE, selectedSymbolIds[0], SymbolActions.MOVE),
    },
    {
      key: ProcessType.VCD,
      onClick: () => handleTreeDiagram(ProcessType.VCD, selectedSymbolIds[0], SymbolActions.MOVE),
    },
    {
      key: ProcessType.EPC,
      onClick: () => handleTreeDiagram(ProcessType.EPC, selectedSymbolIds[0], SymbolActions.MOVE),
    },
  ];

  const filterMenuOptions = () => {
    if (isSwimlane) {
      return [
        {
          icon: 'di icon-link',
          key: 'linkDiagram',
          onClick: () => onClick && onClick(),
        },
      ];
    }

    if (processData?.status === ProcessStatus.SANDBOX) {
      return isCreateSandboxSIPOCEnabled ? [menuOptions[0]] : [];
    }

    return menuOptions;
  };

  const processInterfaceOptions = [SymbolActions.CLOSE, ProcessType.SIPOC, ProcessType.SWIMLANE];
  const SubMenuOptionsByActionMap = {
    [SymbolActions.CREATE]: isSwimlane
      ? createSubMenuOptions.filter((option) => processInterfaceOptions.includes(option.key))
      : createSubMenuOptions,
    [SymbolActions.IMPORT]: isSwimlane
      ? importSubMenuOptions.filter((option) => processInterfaceOptions.includes(option.key))
      : importSubMenuOptions,
    ...(!isSwimlane && { [SymbolActions.MOVE]: moveSubMenuOptions }),
    [SymbolActions.CLOSE]: [],
    ...(!isSwimlane && { [SymbolActions.LINK]: linkSubMenuOptions }),
  };

  const filterSubMenuOptions = () => {
    let options = SubMenuOptionsByActionMap[processAction as SymbolActions];

    if (options && options.length > 1) {
      if (processData?.status === ProcessStatus.SANDBOX) {
        options = isCreateSwimlaneEnabled ? options.slice(0, 3) : options.slice(0, 2);
      } else {
        options =
          isCreateSwimlaneEnabled && currentLevel === 2
            ? options.filter((option) => option.key !== ProcessType.SIPOC)
            : options.filter((option) => option.key !== ProcessType.SWIMLANE);
      }
    }

    return options;
  };

  const handleMouseResize = (event: any, handleId: ResizeHandles) => {
    if (selectedSymbolIds?.includes(symbolId) && selectedSymbolIds.length === 1) {
      handleResize(event, handleId);
    }
  };

  return selectedSymbolIds?.includes(symbolId) || symbolType === SymbolTypes.TEXT ? (
    <div className={`${styles.Container} ${className ? `${className}` : styles.Selection}`} data-qa={symbolId}>
      {/* TODO uncomment if resize function is required */}
      {symbolType === SymbolTypes.TEXT &&
        handles.map(({ handleId, style }) => (
          <svg
            className={`${styles.SelectionHandler} ${style} ${selectedSymbolIds?.includes(symbolId) ? styles.Cursor : ''}`}
            key={handleId}
            onMouseDown={(event) => handleMouseResize(event, handleId)}
            viewBox={`0 0 ${handleSize} ${handleSize}`}
          >
            <rect fill="white" height={handleSize} width={handleSize} />
          </svg>
        ))}
      {selectedSymbolIds.length === 1 && selectedSymbolIds.includes(symbolId) && (
        <>
          <SelectionActions
            buttons={actionButtons || getFilteredButtons()}
            className={symbolType === SymbolTypes.TEXT ? styles.HideBorder : ''}
            paletteButtonRef={paletteButtonRef}
          />

          {isMenuOpen && (
            <DropdownPanel
              className={styles.PalettePanel}
              close={() => setIsMenuOpen(false)}
              parentRef={paletteButtonRef}
              position={Coordinate.TOP}
            >
              {!openSubMenu && (
                <div className={styles.Options} onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
                  {filterMenuOptions().map((option) => (
                    <button
                      className={styles.Option}
                      data-qa={`menu-${option.key}`}
                      disabled={option.disabled}
                      key={`menu-${option.key}`}
                      onClick={(event: React.MouseEvent) => clickOption(event, option.key)}
                      type="button"
                    >
                      <i className={`${option.icon} ${styles.IconMenu}`} onClick={() => setOpenSubMenu(false)} />
                      <span className={styles.OptionText}>{t(option.key)}</span>
                    </button>
                  ))}
                </div>
              )}
              {openSubMenu && (
                <div className={styles.Options} onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
                  {filterSubMenuOptions()?.map((option) => (
                    <div
                      className={option?.icon ? styles.OptionCreate : styles.Option}
                      data-qa={`submenu-${option.key}`}
                      key={`submenu-${option.key}`}
                      onClick={option.onClick}
                    >
                      {option.icon && (
                        <RoundButton
                          className={styles.IconBack}
                          icon={option.icon}
                          id={option.key}
                          onClick={() => setOpenSubMenu(false)}
                        />
                      )}
                      <span className={option?.style || styles.OptionText}>{t(option.key)}</span>
                    </div>
                  ))}
                </div>
              )}
            </DropdownPanel>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default Selection;
