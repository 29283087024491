import { useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

import { getName, getTooltipText, getWarningIcon } from 'assets/js/Utils';
import { DiagramActionTypes, setSwimlaneDisplayingAttributes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useValidation from 'hooks/useValidation';
import { ObjectIOSubTypes, ObjectTypes } from 'types/administration';
import { SymbolObject } from 'types/symbols';

import styles from './ProcessStepObject.module.scss';
import ProcessStepObjectAttributes from './ProcessStepObjectAttributes';

type Props = {
  onDelete: () => void;
  object: SymbolObject;
  swimlaneId: string | undefined;
  swimlaneRoleId: number | undefined;
};

const ProcessStepObject = ({ onDelete, object, swimlaneId, swimlaneRoleId }: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAttributesModal, setShowAttributesModal] = useState(false);
  const { diagramLanguage, dispatch, processData } = useDiagramContext();
  const { symbolObjectsIdsWithErrors } = useValidation();
  const icons: { [key: string]: string } = {
    ROLE: 'icon-person',
    TEMPORARY_ROLE: 'icon-person-konturlinie-angefragt',
    IT_SYSTEM: 'icon-computer',
    TEMPORARY_IT_SYSTEM: 'icon-computer-angefragt',
    INPUT: 'icon-herunterladen',
    OUTPUT: 'icon-hochladen',
  };
  const hasErrors = symbolObjectsIdsWithErrors?.find((errorObject) => object.id === Number(errorObject.id));

  const getIcon = () => {
    switch (object.type) {
      case ObjectTypes.ROLE_SWIMLANE:
        return object.id ? icons.ROLE : icons.TEMPORARY_ROLE;
      case ObjectTypes.IT_SYSTEM_SWIMLANE:
        return object.id ? icons.IT_SYSTEM : icons.TEMPORARY_IT_SYSTEM;
      case ObjectTypes.IO_SWIMLANE:
        return object.subtype === ObjectIOSubTypes.INPUT ? icons.INPUT : icons.OUTPUT;
      default:
        return icons[object.type] || '';
    }
  };

  return (
    <>
      <div
        className={styles.Wrapper}
        onMouseLeave={() => {
          dispatch(setSwimlaneDisplayingAttributes(''));
          setShowAttributesModal(false);
          setSelected(false);
        }}
        ref={containerRef}
      >
        <div
          className={`${styles.Container} ${selected ? styles.Selected : ''} ${hasErrors ? styles.Error : ''}`}
          onClick={() => {
            dispatch(setSwimlaneDisplayingAttributes(!showAttributesModal ? swimlaneId : ''));
            setShowAttributesModal(!showAttributesModal);
            setSelected(!selected);
            dispatch({ type: DiagramActionTypes.UPDATE_PROCESS_DATA });
          }}
        >
          <div className={styles.Type}>
            <i className={`di ${getIcon()}`} />
            <div className={styles.Text}>{getName(object, diagramLanguage, t)}</div>
          </div>
          <div className={styles.NonCatalogIcon}>
            {(object.temporaryText || !object.approved) && (
              <>
                <TooltipComponent
                  className="mbc-tooltip nepos-tooltip"
                  content={getTooltipText(object, t)}
                  cssClass="mbc-tooltip nepos-tooltip"
                  position="BottomCenter"
                  showTipPointer={false}
                >
                  <i
                    className={`di ${getWarningIcon(object)} ${
                      object.type === ObjectTypes.IO_SWIMLANE ? styles.New : styles.Warning
                    }`}
                  />
                </TooltipComponent>
              </>
            )}
          </div>
          {!processData?.isOnlyRead && swimlaneRoleId !== object.id && (
            <i className={`fas fa-times-circle ${styles.Icon}`} onClick={onDelete} />
          )}
        </div>
        {showAttributesModal && (
          <ProcessStepObjectAttributes
            data={object}
            onClose={() => {
              setShowAttributesModal(false);
              setSelected(false);
            }}
            parentRef={containerRef}
          />
        )}
      </div>
    </>
  );
};

export default ProcessStepObject;
