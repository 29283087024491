import { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useOuterClick from 'hooks/useOuterClick';
import { DatasetItem } from 'types/dataset';

import styles from './DatasetDropdown.module.scss';

type Props = {
  id: string;
  items: DatasetItem[];
  buttonText: string;
  disabled: boolean;
  onChange: (code: string) => void;
};

const DatasetDropdown = (props: Props) => {
  const { id, items, buttonText, disabled, onChange } = props;
  const { t } = useTranslation();
  const [openClass, setOpenClass] = useState('');
  const { isDatasetWorkflowEnabled } = useFeatureFlags();
  const wrapperRef = useRef(null);
  useOuterClick(wrapperRef, () => setOpenClass(''));

  const toggleMenu = () => {
    const newClass = openClass === '' ? styles.Open : '';
    setOpenClass(newClass);
  };

  return (
    <div className={`${styles.Wrapper} ${openClass} ${disabled ? styles.Disabled : ''}`} ref={wrapperRef}>
      <div className={`${styles.Button} ${styles.ChevronDataset}`} id={id} onClick={toggleMenu}>
        <span>{buttonText}</span>
        <i className="di icon-pfeil-chevron-unten" />
      </div>
      {isDatasetWorkflowEnabled && (
        <div className={styles.Items}>
          {items &&
            items.map((item: DatasetItem) => (
              <span
                className={item.enabled ? '' : styles.Disable}
                id={item.code}
                key={item.code}
                onClick={() => onChange(item.code)}
              >
                {t(`${item.code}`)}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};

export default DatasetDropdown;
