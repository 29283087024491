import { Attributes, AttributeValue } from './forms';
import { ProcessDataCatalog } from './processes';

export type ProcessLevel = {
  id: string;
  title: string;
  level: number;
  wave: string;
  category: string;
  processResponsibles: string;
  pacemakers: string;
  processCoordinators: string;
  parentId?: number;
};

export type ProcessLevelSelectionTableRow = {
  id: string;
  processTitle: string;
  processLevel: number;
  processResponsibles: string;
  department: string;
};

export interface ProcessLevelResponse {
  results: ProcessLevelRaw[];
  catalog: ProcessDataCatalog;
}

export type ProcessLevelRaw = {
  id: string;
  attributes: Attributes;
  wave: string;
  category: string;
  parentId?: number;
};

export type ProcessLevelTree = ProcessLevelRaw & {
  children: ProcessLevelTree[];
  level: number;
};

export type ProcessLevelFormatted = Omit<ProcessLevelTree, 'attributes' | 'children'> & {
  title: string;
  processResponsibles: string[];
  pacemakers: string[];
  processCoordinators: string[];
  department: (string[] | '')[];
  children: ProcessLevelFormatted[];
  coordinators?: AttributeValue;
};

export type ProcessLevelTableFormatted = Omit<
  ProcessLevelFormatted,
  'processResponsibles' | 'processCoordinators' | 'pacemakers'
> & {
  processResponsibles: string;
  pacemakers: string;
  processCoordinators: string;
};

export type ColumnsConfig = {
  id: boolean;
  title: boolean;
  category: boolean;
  status: boolean;
  description: boolean;
  example: boolean;
  responsiblePerson: boolean;
  responsiblePacemaker: boolean;
  responsibleProcesses: boolean;
  originProcess: boolean;
  createdBy: boolean;
  creationInfo: boolean;
  acceptedBy?: boolean;
  acceptanceInfo?: boolean;
  potentialAnnualEbit: boolean;
  potentialOneTimeCashflow: boolean;
  solutionDescription: boolean;
  requiredInvest: boolean;
  evaluationAnnualEbit: boolean;
  evaluationOneTimeCashflow: boolean;
  targetDate: boolean;
  creator: boolean;
};

export type HistoricData = {
  id: number;
  action: string;
  remark: string[];
  previousValue: string[];
  currentValue: string[];
  modificationDate: string;
  editorCode: string;
};

export enum ProcessLevelFilters {
  CATEGORY = 'CATEGORY',
  WAVE = 'WAVE',
}

export enum ProcessCategories {
  CORE_PROCESS = 'CORE_PROCESS',
  ENABLER_PROCESS = 'ENABLER_PROCESS',
  MANAGEMENT_PROCESS = 'MANAGEMENT_PROCESS',
}

export enum ProcessWaves {
  WAVE_1 = 'WAVE_1',
  WAVE_2 = 'WAVE_2',
  WAVE_3 = 'WAVE_3',
  WAVE_4 = 'WAVE_4',
}

export enum Levels {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}
