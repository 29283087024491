import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  DATA_SET_MODE,
  MAX_DATASET_FILE_SIZE,
  MAX_DATASET_FILES,
  STATUS,
  MODELING_STATUS,
  PUBLISHED_STATUS,
  COMPLETED,
  ARCHIVE_DS_WF,
  DIAGRAM_ENVIRONMENTS,
} from 'assets/constants/constants';
import uploadDocumentImage from 'assets/images/uploadDocuments.svg';
import uploadDocumentHoverImage from 'assets/images/uploadDocumentsHover.svg';
import { downloadBlob, getFullFormattedDate } from 'assets/js/Utils';
import TitleBar from 'components/TitleBar/TitleBar';
import Toolbar from 'components/Toolbar/Toolbar';
import Button from 'components/UI/Button/Button';
import DeleteDialog from 'components/UI/DeleteDialog/DeleteDialog';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import DocumentCard from 'components/UI/DocumentCard/DocumentCard';
import Spinner from 'components/UI/Spinner/Spinner';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import WorkflowBarNEPOS from 'components/WorkflowBarNEPOS/WorkflowBarNEPOS';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useAuth from 'hooks/useAuth';
import useDataset from 'hooks/useDataset';
import useDatasetContext from 'hooks/useDatasetContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useEnvironment from 'hooks/useEnvironment';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLocalization from 'hooks/useLocalization';
import useValidation from 'hooks/useValidation';
import useWorkflow from 'hooks/useWorkflow';
import { uploadDocuments, getAllDatasetDocuments, inUseDataset } from 'services/dataset';
import { getDatasetReleaseMenu } from 'services/datasetWorkflow';
import { deleteDocumentDataset } from 'services/documentation';
import { getDatasetDocument } from 'services/documentationService';
import titleService from 'services/titleService';
import { Interval, Language } from 'types/config';
import { DatasetItem, DatasetStatus, DocumentsDataset } from 'types/dataset';
import { DeleteDialogPrefixes } from 'types/dialogs';
import { TableTool } from 'types/tables';
import { CatalogUser } from 'types/user';

import styles from './Dataset.module.scss';
import DatasetDropdown from './DatasetDropdown/DatasetDropdown';
import DatasetForm from './DatasetForm';

const Dataset = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const intervalInUse = useRef<Interval>();
  const { isLoading, isSaveLoading, diagramLanguage, dispatch } = useDiagramContext();
  const { isLoading: isLoadingDataset, datasetData, workflowData } = useDatasetContext();
  const { isOnlyUser } = useAuth(true);
  const [showDeleteDiagramDialog, setShowDeleteDiagramDialog] = useState(false);
  const [items, setItems] = useState<DatasetItem[]>([]);
  const [isHovering, setIsHovering] = useState(false);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<{ [key: string]: File[] }>({});
  const filesInputRef = useRef<HTMLInputElement | null>(null);
  const checkEnvironment = useEnvironment();
  const { availableLanguages } = useLocalization();
  const { isDatasetValid } = useValidation();
  const { deleteExistingDataset, fetchDataset, fetchPublishedDataset, saveDataset } = useDataset();
  const { handleServiceError } = useError();
  const { isDatasetWorkflowEnabled, isFreezed } = useFeatureFlags();
  const { sendProcessForReleaseDataset } = useWorkflow(id);
  const history = useHistory();
  const allowedTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ];
  const allowedExtensions = [
    '.doc',
    '.docx',
    '.docm',
    '.dot',
    '.csv',
    '.xls',
    '.xlsb',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pdf',
    '.pps',
    '.xlsm',
    '.xltm',
    '.xla',
  ];
  const [documents, setDocuments] = useState<{ name: string; id: number; user: string; date: string; version: string }[]>([]);
  const invalidFilesExist = !!invalidFiles.duplicate?.length || !!invalidFiles.format?.length || !!invalidFiles.size?.length;
  const isEmptyList = !documents.length && !validFiles.length && !invalidFilesExist;
  const environment = checkEnvironment(DATA_SET_MODE.PUBLISHED) ? PUBLISHED_STATUS : MODELING_STATUS;

  const errorReasonMap: { [key: string]: string } = {
    duplicate: 'dataset.errors.API_ERROR_601',
    format: 'dataset.errors.API_ERROR_13',
    size: 'dataset.errors.API_ERROR_12',
    other: 'dataset.errors.API_ERROR_14',
  };

  const apiErrorMap: { [key: string]: string } = {
    API_ERROR_601: 'duplicate',
    API_ERROR_13: 'format',
    API_ERROR_12: 'size',
    API_ERROR_14: 'other',
  };

  const uploadDatasetDocuments = (files: File[]) => {
    if (!datasetData || !files.length) return;

    const formData = new FormData();

    files.forEach((file) => formData.append('files', file, file.name));

    uploadDocuments(datasetData.id, formData).then((res) => {
      const { data } = res;

      if (data.uploaded?.length) {
        setDocuments([
          ...documents,
          ...data.uploaded.map((doc: DocumentsDataset) => ({
            id: datasetData.id,
            name: doc.name,
            user: data.catalog?.users?.find((user: CatalogUser) => user.code === doc.creator)?.commonName || '',
            version: datasetData.version,
            date: getFullFormattedDate(doc.date),
          })),
        ]);
      }

      const uploadedFileNames = data.uploaded?.map((file: DocumentsDataset) => file.name);
      const newInvalidFiles: { [key: string]: File[] } = {};
      ['duplicate', 'format', 'size', 'other'].forEach((key) => {
        newInvalidFiles[key] = [...(invalidFiles[key] || [])].filter((file) => !uploadedFileNames.includes(file.name));
      });

      data.errors?.forEach((error: { file: string; code: string }) => {
        const { file, code } = error;
        const fileObj = validFiles.find((validFile) => validFile.name === file);
        if (!fileObj) return;

        const errorKey = apiErrorMap[code];
        newInvalidFiles[errorKey].push(fileObj);
      });

      setValidFiles([]);
      setInvalidFiles(newInvalidFiles);
    });
  };

  useEffect(() => {
    if (checkEnvironment(DIAGRAM_ENVIRONMENTS.DATASET)) {
      if (environment === PUBLISHED_STATUS) {
        fetchPublishedDataset(+id);
      } else {
        fetchDataset(+id);
      }
      titleService.updatePageTitle(environment === PUBLISHED_STATUS ? t('dataSet.published') : t('dataSet'), id);
    }
  }, [environment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!datasetData?.documents?.length) return setDocuments([]);
    setDocuments(
      datasetData.documents.map((doc) => ({
        id: datasetData.id,
        name: doc.name,
        user: datasetData.catalog.USER.find((user) => user.code === doc.creator)?.commonName || '',
        version: datasetData.version,
        date: getFullFormattedDate(doc.date),
      })),
    );
  }, [datasetData]);

  useEffect(() => {
    if (!validFiles.length) return;
    uploadDatasetDocuments(validFiles);
  }, [validFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !checkEnvironment(DIAGRAM_ENVIRONMENTS.DATASET) ||
      !datasetData ||
      !datasetData.linkedDiagram ||
      datasetData.status === STATUS.WORKFLOW ||
      environment === PUBLISHED_STATUS ||
      datasetData?.dataSetInUse?.blocked
    )
      return;

    getDatasetReleaseMenu(datasetData.id, datasetData.version, datasetData.linkedDiagram.id, datasetData.linkedDiagram.type).then(
      (res) => setItems(res.data.sort((a: { position: number }, b: { position: number }) => a.position - b.position)),
    );
  }, [checkEnvironment, datasetData, environment]);

  const handleConfirmDelete = useCallback(
    async (confirmationValue?: string) => {
      setShowDeleteDiagramDialog(false);
      if (!confirmationValue) return;
      deleteExistingDataset(+id, confirmationValue);
    },
    [deleteExistingDataset, id],
  );

  const datasetInUse = useCallback(
    (inUse = true) => {
      if (!inUse) clearInterval(intervalInUse.current as Interval);

      inUseDataset(+id, inUse).catch(handleServiceError);
    },
    [id], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    datasetInUse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const turnOnDatasetInUse = useCallback(() => {
    clearInterval(intervalInUse.current as Interval);
    if (isOnlyUser() || !datasetData || datasetData?.dataSetInUse?.blocked) return;

    datasetInUse();

    // intervalInUse.current = setInterval(() => {
    //   datasetInUse();
    // }, 120000);
  }, [datasetData]); // eslint-disable-line react-hooks/exhaustive-deps

  const turnOffDatasetInUse = useCallback(() => {
    if (isOnlyUser() || !datasetData || !datasetData?.dataSetInUse?.blocked) return;

    datasetInUse(false);
  }, [datasetData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workflowData && workflowData.status === COMPLETED && workflowData.type.includes(ARCHIVE_DS_WF)) return;
    turnOnDatasetInUse();

    return () => turnOffDatasetInUse();
  }, [turnOnDatasetInUse, turnOffDatasetInUse, workflowData]);

  const toolIcons =
    !isOnlyUser() && datasetData?.status === DatasetStatus.NEW && !datasetData?.dataSetInUse?.blocked
      ? [
          {
            id: TableTool.DELETE,
            tooltip: t('tool.delete'),
            iconClass: 'di icon-schliessen-2',
            disabled: isLoading,
            click: () => setShowDeleteDiagramDialog(true),
          },
        ]
      : [];
  const selectedTools = toolIcons.filter(
    (tool) => tool.id !== TableTool.DELETE || (tool.id === TableTool.DELETE && !checkEnvironment(DATA_SET_MODE.PUBLISHED)),
  );

  const downloadDocument = (documentName: string) => {
    getDatasetDocument({
      id: datasetData?.id,
      name: documentName,
      version: environment === PUBLISHED_STATUS ? datasetData?.version : undefined,
    })
      .then((res) => {
        downloadBlob(res.data, res.headers, documentName);
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const deleteDocument = (documentName?: string) => {
    if (!datasetData?.id || !documentName) return;
    deleteDocumentDataset(datasetData.id, documentName)
      .then(() => {
        const filteredDocus = documents.filter((element) => encodeURI(element.name) !== documentName) || [];
        setDocuments(filteredDocus);
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const downloadAll = () => {
    if (!datasetData) return;
    getAllDatasetDocuments({ id: datasetData.id, version: datasetData.version })
      .then((res) => {
        downloadBlob(res.data, res.headers, 'documents.zip');
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  let toolbarButtons = [
    {
      component: Button,
      buttonStyle: 'Secondary',
      btnText: t('save'),
      disabled: isLoading || isSaveLoading,
      handleClick: async () => {
        await saveDataset(datasetData);
        await fetchDataset(+id);
      },
      id: 'dataset-save',
    },
    {
      component: DatasetDropdown,
      disabled: isFreezed || !isDatasetWorkflowEnabled || isLoading || isSaveLoading || !datasetData?.linkedDiagram,
      id: 'dataset-sendForReleaseDropdown',
      items: !datasetData?.linkedDiagram ? [] : items,
      onChange: (code: string) => {
        const isEnabled = items.filter((e) => e.code === code)[0].enabled;
        if (!isEnabled || !datasetData) return;
        if (!isDatasetValid())
          return handleServiceError(
            { response: { data: { code: 'dataset.emptyMandatoryAttributes' } } },
            { shouldNotShowCode: true },
          );
        sendProcessForReleaseDataset(code);
      },
      buttonText: t('dataset.dropdown.sendForRelease'),
    },
    {
      component: Button,
      buttonStyle: 'Secondary',
      btnText: t('downloadAll'),
      custom: 'custom',
      disabled: isLoading,
      handleClick: () => downloadAll(),
      id: 'dataset-downloadAll',
    },
    {
      component: Button,
      buttonStyle: 'Primary',
      btnText: t('tool.edit'),
      custom: 'custom',
      disabled: isLoading,
      handleClick: () => history.push(`/dataset/${id}`),
      id: 'dataset-edit',
    },
  ];

  const notPublishedButtons = ['dataset-save', 'dataset-sendForReleaseDropdown'];

  toolbarButtons =
    !isOnlyUser() &&
    datasetData?.status !== STATUS.WORKFLOW &&
    environment !== PUBLISHED_STATUS &&
    !datasetData?.dataSetInUse?.blocked
      ? toolbarButtons.filter((button: { id: string }) => notPublishedButtons.includes(button.id))
      : toolbarButtons;

  toolbarButtons =
    environment !== PUBLISHED_STATUS && datasetData?.status !== STATUS.WORKFLOW && !datasetData?.dataSetInUse?.blocked
      ? toolbarButtons.filter((button: { id: string }) => button.id !== 'dataset-downloadAll')
      : toolbarButtons;

  toolbarButtons =
    datasetData?.status === STATUS.WORKFLOW
      ? toolbarButtons.filter((button: { id: string }) => button.id === 'dataset-downloadAll')
      : toolbarButtons;

  toolbarButtons =
    environment === PUBLISHED_STATUS
      ? toolbarButtons.filter((button: { id: string }) => !notPublishedButtons.includes(button.id))
      : toolbarButtons;

  toolbarButtons = datasetData?.dataSetInUse?.blocked
    ? toolbarButtons.filter((button: { id: string }) => !notPublishedButtons.includes(button.id) && button.id !== 'dataset-edit')
    : toolbarButtons;

  const areFilesEqual = (file1: File, file2: File) => file1.name === file2.name;

  const isValidFile = (file: File) =>
    allowedExtensions.some((extension) => file.name.endsWith(extension)) || allowedTypes.includes(file.type);

  const isTooBigFile = (file: File) => file.size > MAX_DATASET_FILE_SIZE;

  const alreadyExistsFile = (file: File) => documents.map((doc) => doc.name).includes(file.name);

  const filterDuplicateFiles = (baseList: File[], newList: File[]) => {
    let filteredList: File[] = [];

    if (baseList) {
      filteredList = newList.filter((newFile) => {
        let include = true;

        baseList.forEach((oldFile) => {
          if (areFilesEqual(newFile, oldFile)) {
            include = false;
          }
        });

        return include;
      });
    }

    return filteredList;
  };

  const retryDocumentUpload = (file: File, failureReason: string) => {
    setInvalidFiles({
      ...invalidFiles,
      [failureReason]: invalidFiles[failureReason]?.filter((f) => !areFilesEqual(f, file)) || [],
    });
    setValidFiles([file]); // This will trigger the upload
  };

  const updateFiles = (selectedFiles: FileList) => {
    const allNewFiles = Array.from(selectedFiles);

    if (allNewFiles.length + documents.length > MAX_DATASET_FILES) {
      Dialog.showAlert({
        name: t('error'),
        message: t('dataset.errors.API_ERROR_11'),
        isError: true,
      });
      return;
    }

    let newValidFiles: File[] = [];
    let invalidFormatFiles: File[] = [];
    let tooBigFiles: File[] = [];
    let alreadyExistingFiles: File[] = [];

    // We filter invalid files
    allNewFiles.forEach((file) => {
      if (alreadyExistsFile(file)) {
        alreadyExistingFiles.push(file);
      } else if (!isValidFile(file)) {
        invalidFormatFiles.push(file);
      } else if (isTooBigFile(file)) {
        tooBigFiles.push(file);
      } else {
        newValidFiles.push(file);
      }
    });

    // We skip duplicate files
    newValidFiles = filterDuplicateFiles(validFiles, newValidFiles);
    alreadyExistingFiles = filterDuplicateFiles(invalidFiles.duplicate || [], alreadyExistingFiles);
    invalidFormatFiles = filterDuplicateFiles(invalidFiles.format || [], invalidFormatFiles);
    tooBigFiles = filterDuplicateFiles(invalidFiles.size || [], tooBigFiles);

    // We update the selected files list
    setValidFiles([...(validFiles || []), ...newValidFiles]);
    setInvalidFiles({
      ...invalidFiles,
      duplicate: [...(invalidFiles.duplicate || []), ...alreadyExistingFiles],
      format: [...(invalidFiles.format || []), ...invalidFormatFiles],
      size: [...(invalidFiles.size || []), ...tooBigFiles],
    });
  };

  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovering(true);
  };

  const handleDragOut = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovering(false);
  };

  // This function handles when files are 'dropped'
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovering(false);
    if (
      !event.dataTransfer ||
      !!validFiles?.length ||
      environment === PUBLISHED_STATUS ||
      datasetData?.status === STATUS.WORKFLOW ||
      datasetData?.dataSetInUse?.blocked
    )
      return;
    updateFiles(event.dataTransfer.files);
  };

  // This function handles when files are manually selected
  const handleFileSelection = (event: any) => {
    const { target } = event;
    if (!target || !target.files || !!validFiles?.length) return;
    updateFiles(target.files);
  };

  const deleteFailure = (failureKey: string, fileName: string) => {
    const files: File[] = invalidFiles[failureKey];
    if (!files?.length) return;

    const filteredFiles = files.filter((file) => file.name !== fileName);
    setInvalidFiles({
      ...invalidFiles,
      [failureKey]: filteredFiles,
    });
  };

  const setLanguage = (language: Language) => dispatch({ type: DiagramActionTypes.UPDATE_DIAGRAM_LANGUAGE, payload: language });

  return (
    <>
      <TitleBar />
      <Toolbar
        buttons={toolbarButtons}
        isDatasetBlocked={!isFreezed ? datasetData?.dataSetInUse?.blocked : false}
        leftButtons={
          !isOnlyUser() &&
          datasetData?.status !== STATUS.WORKFLOW && (
            <>
              <TabSelector
                className={styles.Tabs}
                handleClick={setLanguage}
                isNEPOS
                options={availableLanguages?.map((lang: Language) => ({
                  id: lang,
                  name: t(`language.${lang}`),
                  // TODO: Change when languageValidation is enabled
                  // isValid: isLanguageValid[lang],
                  isValid: true,
                }))}
                selectedOption={diagramLanguage}
              />
              {environment !== PUBLISHED_STATUS && !datasetData?.dataSetInUse?.blocked && (
                <>
                  <button
                    className={styles.Secondary}
                    disabled={isFreezed || isLoading || !!validFiles?.length}
                    id="uploadDocument"
                    key="uploadDocument"
                    onClick={() => {
                      filesInputRef.current?.click();
                    }}
                    type="button"
                  >
                    {t('dataset.button.uploadDocument')}
                  </button>
                  <input
                    accept={`${allowedTypes.join(',')},${allowedExtensions.join(',')}`}
                    className={styles.FilesInput}
                    id="dataset-uploadDocumentInput"
                    multiple
                    onChange={(event) => {
                      handleFileSelection(event);
                      event.target.value = '';
                    }}
                    ref={filesInputRef}
                    type="file"
                  />
                </>
              )}
            </>
          )
        }
        showToolbarButtons
        toolIcons={selectedTools}
        userBlock={!isFreezed ? datasetData?.dataSetInUse?.user : ''}
      />

      {isLoadingDataset && (
        <div className="spinner">
          <Spinner isVisible />
        </div>
      )}

      {!isLoadingDataset && (
        <div className={styles.Container}>
          <div
            className={`${styles.Content} ${datasetData?.status === STATUS.WORKFLOW ? styles.WorkflowContent : ''} ${
              isEmptyList ? styles.NoDocuments : ''
            }`}
            onClick={() => (isEmptyList ? filesInputRef.current?.click() : null)}
            onDragEnter={handleDrag}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {!isEmptyList && (
              <div className={styles.Documents}>
                {validFiles.map((file) => (
                  <DocumentCard
                    blocked={!!datasetData?.dataSetInUse?.blocked}
                    environment={environment}
                    key={file.name}
                    name={file.name}
                    status={datasetData?.status}
                    uploading
                  />
                ))}
                {['other', 'duplicate', 'format', 'size']
                  .map((errorReason) =>
                    invalidFiles[errorReason]?.map((file) => (
                      <DocumentCard
                        blocked={!!datasetData?.dataSetInUse?.blocked}
                        canRetryUpload={errorReason === 'other'}
                        deleteDocument={() => deleteFailure(errorReason, file.name)}
                        environment={environment}
                        error={t(errorReasonMap[errorReason])}
                        key={file.name}
                        name={file.name}
                        retryUpload={() => retryDocumentUpload(file, errorReason)}
                        status={datasetData?.status}
                      />
                    )),
                  )
                  .flat(1)}
                {documents.map((doc) => (
                  <DocumentCard
                    {...doc}
                    blocked={!!datasetData?.dataSetInUse?.blocked}
                    deleteDocument={(documentName?: string) => {
                      deleteDocument(documentName);
                    }}
                    downloadDocument={(documentName: string) => downloadDocument(documentName)}
                    environment={environment}
                    key={doc.name}
                    status={datasetData?.status}
                  />
                ))}
              </div>
            )}
            {!isOnlyUser() && datasetData?.status !== STATUS.WORKFLOW && !datasetData?.dataSetInUse?.blocked && isEmptyList && (
              <div className={styles.InitialView}>
                <span>{t('dataset.drop.document')}</span>
                <img
                  alt="Upload documents"
                  id="uploadDocumentImage"
                  src={isHovering ? uploadDocumentHoverImage : uploadDocumentImage}
                />
              </div>
            )}
          </div>
          <div className={`${styles.FormWrapper} ${datasetData?.status === STATUS.WORKFLOW ? styles.IsWorkflow : ''}`}>
            <DatasetForm />
          </div>
        </div>
      )}

      {showDeleteDiagramDialog && (
        <DeleteDialog
          handleClick={(confirmationValue) => handleConfirmDelete(confirmationValue)}
          isLegacy
          objectName={datasetData?.attributes[diagramLanguage]?.NAME as string}
          prefix={DeleteDialogPrefixes.DATASET}
        />
      )}
      {datasetData?.status === STATUS.WORKFLOW && (
        <WorkflowBarNEPOS isDatasetWorkflow isLegacyStyle isOnlyRead={datasetData?.dataSetInUse?.blocked} />
      )}
    </>
  );
};

export default Dataset;
