import { useTranslation } from 'react-i18next';

import { NOT_TRANSLATABLE } from 'assets/constants/constants';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import useDiagramContext from 'hooks/useDiagramContext';
import useForm from 'hooks/useForm';
import { Care, FormTypes, Input, LanguageAttributes } from 'types/forms';

import styles from './LinkForm.module.scss';

type Props = {
  onClick: (values: LanguageAttributes) => void;
};

const linkFormTypes: FormTypes = {
  [NOT_TRANSLATABLE]: {
    NAME: {
      position: 0,
      care: Care.MANDATORY,
      type: Input.TEXT,
      tooltip: '',
    },
    LINK: {
      position: 1,
      care: Care.MANDATORY,
      type: Input.URL,
      tooltip: '',
    },
  },
};

const LinkForm = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const { diagramLanguage } = useDiagramContext();

  const form = useForm({ formTypes: linkFormTypes });

  const handleClick = () => {
    const values = form.values[NOT_TRANSLATABLE];

    if (form.isValid && values) {
      onClick(values);
      form.setValue('NAME', '');
      form.setValue('LINK', '');
      form.purifyForm();
    }
  };

  return (
    <div>
      {form.fields[diagramLanguage]?.map(({ Component, ...fieldProps }) => (
        <Component {...fieldProps} />
      ))}
      <ButtonNEPOS
        className={styles.Button}
        disabled={!form.isValid}
        handleClick={handleClick}
        id="processLevelDatabase-create-link-button"
        isSecondary
        key="processLevelDatabase-create-link-button"
      >
        {t('link.create')}
      </ButtonNEPOS>
    </div>
  );
};

export default LinkForm;
