import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FILL_COLOR_POPUP, FONT_SIZES, TEXTAREA_DEFAULT_LENGTH, TEXT_COLOR_POPUP, urlRegEx } from 'assets/constants/constants';
import Selection from 'components/Selection/Selection';
import ColorPopup from 'components/UI/ColorPopup/ColorPopup';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDragAndDrop from 'hooks/useDragAndDrop';
import useOuterClick from 'hooks/useOuterClick';
import useSelection from 'hooks/useSelection';
import useSwimlane from 'hooks/useSwimlane';
import useSymbol from 'hooks/useSymbol';
import useTool from 'hooks/useTool';
import useValidation from 'hooks/useValidation';
import { PaletteActions, Tool } from 'types/diagram';
import { Attributes } from 'types/forms';
import { ProcessStatus } from 'types/processes';
import { AlignType, SelectionButton, SymbolFormat, SymbolMeta, SymbolStyle, SymbolTypes } from 'types/symbols';

import styles from './TextFieldNEPOS.module.scss';

type Props = { type?: string; attributes?: Attributes; id?: string; meta?: SymbolMeta };

const TextFieldNEPOS = ({ type, attributes, id = `palette-${type}`, meta }: Props) => {
  const { t } = useTranslation();
  const { diagramLanguage, dispatch, editableSymbol, processData, selectedSymbolIds } = useDiagramContext();
  const { handleDragStart, handleMoveStart } = useDragAndDrop();
  const { handleSelect } = useSelection();
  const { getSelectionButtons } = useSwimlane();
  const { handleOuterClick, onNameChange, updateSymbol } = useSymbol();
  const { symbolsIdsWithErrors } = useValidation();
  const { tool } = useTool();
  const [isMoving, setIsMoving] = useState(false);
  const [selectionButtons, setSelectionButtons] = useState<SelectionButton[]>();
  const textFieldLinkId = `textfield-link-${id}`;
  const containerRef = useRef<HTMLDivElement>(null);
  const symbolNameInputRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hasErrors = symbolsIdsWithErrors && symbolsIdsWithErrors.find((symbolId) => symbolId === id);
  const areNotesDisplayed = tool === Tool.DISPLAY_POSTITS;
  const [style, setStyle] = useState<SymbolFormat>(meta as SymbolFormat);
  const [colorsPicker, setColorsPicker] = useState<{ className: string; id: string } | null>(null);

  useEffect(() => {
    if (!selectedSymbolIds.includes(id)) {
      setColorsPicker(null);
    }
  }, [selectedSymbolIds, id]);

  useOuterClick(symbolNameInputRef, handleOuterClick, containerRef?.current);

  const updateSymbolProps = useCallback(
    (newMeta: SymbolFormat) => {
      const symbolMeta = { meta: newMeta };
      setStyle(newMeta);
      updateSymbol(id, symbolMeta);
    },
    [id, updateSymbol],
  );

  const handleFontChange = useCallback(
    (fontStyle: string) => {
      let textAlign = style.align;
      let bold = style.isBold;
      let italic = style.isItalic;
      let underline = style.isUnderline;

      switch (fontStyle) {
        case SymbolStyle.BOLD:
          bold = !style.isBold;
          break;
        case SymbolStyle.ITALIC:
          italic = !style.isItalic;
          break;
        case SymbolStyle.UNDERLINE:
          underline = !style.isUnderline;
          break;
        default:
          textAlign = fontStyle as AlignType;
          break;
      }

      const newMeta = {
        align: textAlign,
        fontSize: style.fontSize,
        isBold: bold,
        isItalic: italic,
        isUnderline: underline,
      };
      updateSymbolProps(newMeta);
    },
    [style, updateSymbolProps],
  );

  const setColor = (color: string) => {
    const key: string = `${colorsPicker?.id}`;
    updateSymbolProps({ ...meta, [key]: color === 'white' ? '' : color });
    setColorsPicker(null);
  };

  const textFieldButtons = useCallback((): SelectionButton[] => {
    return processData?.isOnlyRead
      ? []
      : [
          {
            extraClass: 'Separator',
            icon: 'di icon-farbtopf-fuellfarbe',
            id: FILL_COLOR_POPUP,
            onClick: () => {
              setColorsPicker(colorsPicker ? null : { className: 'MarginTop', id: 'backgroundColor' });
            },
          },
          {
            icon: 'di icon-schriftfarbe',
            id: TEXT_COLOR_POPUP,
            onClick: () => {
              setColorsPicker(colorsPicker ? null : { className: 'MarginTop', id: 'fontColor' });
            },
          },
          {
            extraClass: 'Separator',
            id: 'fontSize',
            onClick: () => {},
            children: (
              <select
                className={styles.SizeSelect}
                id="fontSize"
                key="fontSizeSelector"
                onChange={(event) => updateSymbolProps({ ...meta, fontSize: Number(event.target.value) })}
                value={style.fontSize}
              >
                {FONT_SIZES.map((fontSize) => (
                  <option
                    className={(meta?.fontSize || 16) === fontSize ? styles.SelectedFontSize : ''}
                    key={`fontSize_${fontSize}`}
                    value={fontSize}
                  >
                    {fontSize}
                  </option>
                ))}
              </select>
            ),
          },
          {
            id: SymbolStyle.BOLD,
            icon: 'di icon-schriftschnitt-fett',
            onClick: () => handleFontChange(SymbolStyle.BOLD),
            selected: style.isBold,
          },
          {
            id: SymbolStyle.ITALIC,
            icon: 'di icon-schriftschnitt-kursiv',
            onClick: () => handleFontChange(SymbolStyle.ITALIC),
            selected: style.isItalic,
          },
          {
            id: SymbolStyle.UNDERLINE,
            icon: 'di icon-schriftschnitt-unterstrichen',
            onClick: () => handleFontChange(SymbolStyle.UNDERLINE),
            selected: style.isUnderline,
          },
          {
            extraClass: 'Separator',
            id: SymbolStyle.ALIGN_LEFT,
            icon: 'di icon-text-linksbuendig',
            onClick: () => handleFontChange(SymbolStyle.ALIGN_LEFT),
            selected: style.align === SymbolStyle.ALIGN_LEFT,
          },
          {
            id: SymbolStyle.ALIGN_CENTER,
            icon: 'di icon-text-zentrieren',
            onClick: () => handleFontChange(SymbolStyle.ALIGN_CENTER),
            selected: style.align === SymbolStyle.ALIGN_CENTER,
          },
          {
            id: SymbolStyle.ALIGN_RIGHT,
            icon: 'di icon-text-rechtsbuendig',
            onClick: () => handleFontChange(SymbolStyle.ALIGN_RIGHT),
            selected: style.align === SymbolStyle.ALIGN_RIGHT,
          },
        ];
  }, [handleFontChange, processData?.isOnlyRead, style, colorsPicker, updateSymbolProps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editableSymbol === id && textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [editableSymbol, id]);

  useEffect(() => {
    setSelectionButtons(
      getSelectionButtons({
        diagramData: {
          diagramAttributes: processData?.attributes,
          isOnlyRead: processData?.isOnlyRead,
        },
        id,
        symbolType: SymbolTypes.TEXT,
      }),
    );
  }, [id, processData?.attributes, processData?.isOnlyRead, processData?.swimlanes]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragStart = (e: any) => {
    if (type !== SymbolTypes.PALETTE_TEXTFIELD) return;
    handleDragStart(e);
    dispatch({
      type: DiagramActionTypes.SET_PALETTE_ACTION,
      payload: PaletteActions.ADD_TEXTFIELD,
    });
  };

  const position = type === SymbolTypes.PALETTE_TEXTFIELD ? 'relative' : 'absolute';
  return (
    <div
      className={`${styles.TextFieldNEPOSBackground} ${hasErrors ? styles.Error : ''}`}
      draggable={type === SymbolTypes.PALETTE_TEXTFIELD}
      id={id}
      onClick={(e) => {
        if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW && editableSymbol !== id) {
          handleSelect(id, e);
          setIsMoving(false);
        }
      }}
      onDoubleClick={() => {
        if (!processData?.isOnlyRead && processData?.status !== ProcessStatus.WORKFLOW) {
          dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: id });
        }
      }}
      onDragStart={onDragStart}
      onMouseDown={(e) => {
        if (!processData?.isOnlyRead && editableSymbol !== id && !areNotesDisplayed) {
          setIsMoving(true);
          const link = document.querySelector(textFieldLinkId);
          const target = e.target as HTMLElement;
          if (target !== link && !link?.contains(target)) {
            handleMoveStart(e, id);
          }
        }
      }}
      ref={containerRef}
      style={{
        color: `${meta?.fontColor}`,
        fontStyle: meta?.isItalic ? 'italic' : 'normal',
        fontWeight: meta?.isBold ? 'bold' : '',
        height: 'auto',
        left: `${meta?.left}em`,
        position: `${position}`,
        textDecoration: meta?.isUnderline ? 'underline' : 'none',
        top: `${meta?.top}em`,
        width: `${meta?.width}em`,
        backgroundColor: `${meta?.backgroundColor}`,
      }}
    >
      <div
        className={`${styles.TextFieldNEPOSContainer} ${
          type === SymbolTypes.PALETTE_TEXTFIELD ? styles.PaletteTextFieldNEPOSContainer : ''
        }`}
      >
        {editableSymbol === id ? (
          <div className={styles.SymbolNameInput} ref={symbolNameInputRef}>
            <textarea
              maxLength={TEXTAREA_DEFAULT_LENGTH}
              onChange={(event) => onNameChange(id, event.target.value, textAreaRef.current)}
              placeholder={t('textfield')}
              ref={textAreaRef}
              rows={1}
              value={attributes && attributes[diagramLanguage]?.TEXT_BLOCK}
            />
          </div>
        ) : (
          <div
            className={styles.SymbolNameWrapper}
            style={{
              fontSize: `${style?.fontSize ? style?.fontSize / 16 : 1}em`,
              textAlign: style?.align || SymbolStyle.ALIGN_CENTER,
            }}
          >
            <p className={`${styles.SymbolName} ${isMoving ? styles.IsMoving : ''}`}>
              {(attributes && attributes[diagramLanguage]?.TEXT_BLOCK) || t('textfield')}
            </p>
          </div>
        )}
        <div className={styles.Link}>
          {attributes && attributes[diagramLanguage]?.LINKAGE && urlRegEx.test(attributes[diagramLanguage]?.LINKAGE as string) && (
            <a href={attributes[diagramLanguage]?.LINKAGE?.toString()} rel="noopener noreferrer" target="_blank">
              <RoundButton
                icon="di icon-link-extern"
                id={textFieldLinkId}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </a>
          )}
        </div>
      </div>
      {(type !== SymbolTypes.PALETTE_TEXTFIELD || areNotesDisplayed) && (
        <Selection
          actionButtons={selectionButtons && [...selectionButtons, ...textFieldButtons()]}
          className={styles.Selection}
          symbolId={id}
          symbolType={type}
        />
      )}

      {colorsPicker && (type !== SymbolTypes.PALETTE_TEXTFIELD || areNotesDisplayed) && (
        <ColorPopup isText="isText" nodeColor={style[colorsPicker.id]} popupOpened={colorsPicker.className} setColor={setColor} />
      )}
    </div>
  );
};

export default TextFieldNEPOS;
