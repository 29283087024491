import { useContext } from 'react';

import { ActivitySpecificationContext } from 'contexts/ActivitySpecification/ActivitySpecificationContext';

export default function useActivitySpecificationContext() {
  const context = useContext(ActivitySpecificationContext);

  if (context === undefined) {
    throw new Error('useActivitySpecificationContext must be used within a ActivitySpecificationProvider');
  }

  const { state, dispatch } = context;

  return {
    ...state,
    dispatch,
  };
}
