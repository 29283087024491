import React, { useEffect, useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import documentationServices from '../../services/documentationService';
import Button from '../UI/Button/Button';
import HistoryRow from '../UI/TableList/HistoryRow/HistoryRow';
import RecommendationHistoryRow from '../UI/TableList/RecommendationHistoryRow/RecommendationHistoryRow';
import TableList from '../UI/TableList/TableList';
import Style from './DialogHistory.module.scss';

const DialogHistory = (props) => {
  const { close, t, refAssignment, dataHistory, language, processNumber, handleDownloadClick, isRecommendationHistory } = props;
  const header = isRecommendationHistory
    ? {
        name: t('history.table.processName'),
        version: t('history.table.version'),
        recommendedBy: t('history.table.recommendedBy'),
        date: t('history.table.date'),
        status: t('history.table.status'),
        gaplog: t('history.table.gaplog'),
        icoPdf: '',
      }
    : {
        type: t('history.table.type'),
        name: t('history.table.name'),
        version: t('history.table.version'),
        author: t('history.table.author'),
        validFrom: t('history.table.validFrom'),
        validTo: t('history.table.validTo'),
        icoPdf: '',
      };

  const [sortedValues, setSortedValues] = useState(dataHistory);
  const [direction, setDirection] = useState('');
  const [lastFieldToOrder, setLastFieldToOrder] = useState('');
  const [sortData, setSortData] = useState({
    type: '',
    name: '',
    version: '',
    author: '',
    validFrom: '',
    validTo: '',
  });
  const title = isRecommendationHistory ? t('history.title') : t('history.overviewTitle');
  const rowComponent = isRecommendationHistory ? RecommendationHistoryRow : HistoryRow;

  const sortHistory = (field) => {
    const isDateField = field === 'validFrom' || field === 'validTo' || field === 'date';
    const fieldType = isDateField ? Constants.DATE_TYPE : Constants.TEXT_TYPE;
    let columns = sortData;
    let results = '';

    if (columns[field] === Constants.SORT_DESC) {
      columns[field] = '';
      results = dataHistory;
    } else {
      const sortResult = Utils.sortTableByColumn(field, fieldType, columns, dataHistory);
      columns = Utils.cloneObject(sortResult.columns);
      results = Utils.cloneObject(sortResult.results);
    }

    setSortedValues(results);
    setSortData(columns);
    setLastFieldToOrder(field);
    setDirection(sortData[field]);
  };

  useEffect(() => {
    if (lastFieldToOrder) sortHistory(lastFieldToOrder);
    else setSortedValues(dataHistory);
  }, [dataHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadPdf = (version, name, versionProcessNumber = processNumber) => {
    documentationServices
      .getDocument(versionProcessNumber, version, language, Constants.DIAGRAM)
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const fileName = `${name}_${version}.pdf`;
        Utils.downloadFile(url, fileName, res.headers['content-disposition']);
      })
      .catch((err) => {
        documentationServices.handleServiceError(err);
      });
  };

  return (
    <DialogComponent
      id="dialog-history"
      isModal
      ref={(dialog) => refAssignment(dialog)}
      target="#dialog"
      visible
      width={isRecommendationHistory ? '1200px' : '1000px'}
    >
      <div className={Style.Header}>{title}</div>
      <TableList
        direction={direction}
        fieldToOrder={lastFieldToOrder}
        handleDownloadClick={isRecommendationHistory ? handleDownloadClick : downloadPdf}
        header={header}
        language={language}
        list={sortedValues}
        RowComponent={rowComponent}
        sortTable={(fieldToOrder) => sortHistory(fieldToOrder)}
      />
      <div className={Style.ButtonLine}>
        {isRecommendationHistory ? (
          <Button btnText={t('close')} buttonStyle="Secundary" handleClick={close} id="close" />
        ) : (
          <Button btnText={t('cancel')} buttonStyle="Secundary" custom="custom" handleClick={close} id="cancel" />
        )}
      </div>
    </DialogComponent>
  );
};

export default withTranslation('common')(DialogHistory);
