import { DatasetProvider } from 'contexts/Dataset/DatasetContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { RequirementProvider } from 'contexts/Requirement/RequirementContext';

import Dataset from './Dataset';

const DatasetWrapper = () => {
  return (
    <DiagramProvider>
      <RequirementProvider>
        <DatasetProvider>
          <Dataset />
        </DatasetProvider>
      </RequirementProvider>
    </DiagramProvider>
  );
};

export default DatasetWrapper;
