import React from 'react';

import { withTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import * as Utils from 'assets/js/Utils';
import DelegateWorkflowDialog from 'components/DelegateWorkflowDialog/DelegateWorkflowDialog';
import Button from 'components/UI/Button/Button';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import Spinner from 'components/UI/Spinner/Spinner';
import WorkflowDialog from 'components/Workflow/WorkflowDialog/WorkflowDialog';
import WorkflowStep from 'components/Workflow/WorkflowStep/WorkflowStep';
import * as Config from 'components/WorkflowBar/Config';
import { AuthContext } from 'contexts/Auth/AuthContext';
import communicationService from 'services/communicationService';
import userService from 'services/userService';
import {
  approveWorkflow,
  delegateWorkflowStep,
  getAllWorkflowTypes,
  rejectWorkflow,
  saveWorkflow,
  workflowById,
  workflowByProcessNumber,
} from 'services/workflowServices';

import styles from './WorkflowBar.module.scss';

class WorkflowBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.currentStage = '';
    this.isQIGroup = false;
    this.delegatedApprover = '';
    this.language = this.props.i18n.language.toUpperCase();
    this.currentStageFields = [];
    this.currentStageData = [];
    this.rejectReason = props.rejectReason;
    this.rejectedBy = props.rejectedBy;

    this.state = {
      isLoading: true,
      initialLiteral: { name: '' },
      isOpen: false,
      openDialog: false,
      fieldConfig: { controls: {} },
      workflow: {},
      idProcess: props.idProcess,
      workflowId: props.workflowId,
      qualityGroups: props.qualityGroups,
      diagramName: props.diagramName,
      workflowSelected: [],
      loadAttributesForm: [],
      showButtonSave: false,
      stageType: '',
      scopes: [],
      locations: [],
      onlyRead: false,
      onlyReadReason: null,
      saved: false,
      showDelegateDialog: false,
      currentUser: '',
      delegateFieldConfig: { controls: {} },
      isClosedWorkflow: props.isClosedWorkflow,
    };
  }

  componentDidMount() {
    this.setLoading(this.state.isLoading || this.props.loading);

    if (!this.props.loading) {
      this.getWorkflow();
    }
  }

  componentDidUpdate() {
    this.getWorkflowFieldConfig();
  }

  getWorkflowFieldConfig() {
    if (!this.props.featureFlags?.isWorkflowFormTypesEnabled) return;
    const keys = Object.keys(this.state.fieldConfig.controls);

    if (keys.length > 1 || this.props.formTypes.isLoading || !this.currentStage || !this.state.workflowSelected?.type) {
      return;
    }

    const workflowTypeCode = this.state.workflowSelected.type;
    const stageCode = Object.keys(Constants.WORKFLOW_STAGES).find((code) => this.currentStage.type.startsWith(code));

    const workflowFormTypesCode = this.props.getFormTypesCode({
      type: Constants.AREAS.WORKFLOW,
      stage: stageCode,
      variant: workflowTypeCode,
      isRecommended: this.props.isRecommended,
    });

    if (!this.props.formTypes[workflowFormTypesCode]) return;

    const wfAttributesFields = Utils.getObjectAttributes(this.state.workflowSelected.attributes);
    let newFieldConfig = this.props.getReactReactiveFormFieldConfig(
      workflowFormTypesCode,
      this.state.isClosedWorkflow || !this.checkResponsibleUser(),
    );
    newFieldConfig = this.props.addExtraMetaFieldConfig(newFieldConfig);
    newFieldConfig = this.props.addSpecialFieldsInitialValuesFieldConfig(newFieldConfig, wfAttributesFields);
    const multilanguageFieldConfig = newFieldConfig[this.language];
    const multilanguageKeys = [];
    delete newFieldConfig[this.language].controls.NOTIFY_ALL_MANAGERS;

    this.setNameWorkflowAttributes(multilanguageFieldConfig);

    Object.keys(this.props.formTypes[workflowFormTypesCode]).forEach((lang) => {
      if (lang === Constants.NOT_TRANSLATABLE) return;
      Object.keys(this.props.formTypes[workflowFormTypesCode][lang]).forEach((key) => {
        if (!multilanguageKeys.includes(key)) multilanguageKeys.push(key);
        const newKey = `${key}_${lang}`;
        multilanguageFieldConfig.controls[newKey] = newFieldConfig[lang].controls[key];
        multilanguageFieldConfig.controls[newKey].meta.label = this.props.t(`nameAttributes.${newKey}`);
      });
    });

    multilanguageKeys.forEach((key) => {
      delete multilanguageFieldConfig.controls[key];
    });

    multilanguageFieldConfig.controls.VALID_FROM.meta.extraClass = 'inputValidFrom';
    multilanguageFieldConfig.controls.MONTHS_VALIDITY.meta.extraClass = 'inputValidTo';

    multilanguageFieldConfig.controls = Object.fromEntries(
      Object.entries(multilanguageFieldConfig.controls).sort((a, b) => a[1].meta.position - b[1].meta.position),
    );

    const { objectData } = this.adaptFieldConfigForDiagramForm(multilanguageFieldConfig);

    this.setState(
      {
        fieldConfig: multilanguageFieldConfig,
        loadAttributesForm: objectData.modalData,
        showButtonSave:
          !this.state.isClosedWorkflow &&
          this.currentStage.type === this.state.workflow.workflowStageTypes[1].stageType.code &&
          this.checkResponsibleUser(),
        workflow: { ...this.state.workflow, fieldConfig: multilanguageFieldConfig },
      },
      () => {
        this.getBackToGroup();
        this.props.stageType(this.state.stageType);
        this.props.onlyReadReason(this.state.onlyReadReason);
        this.props.onlyReadWorkflow(this.state.onlyRead);
        this.getAllScopes();
        this.getAllLocations(this.state.fieldConfig);
      },
    );
  }

  getWorkflow() {
    const getWorkflowService = this.state.isClosedWorkflow
      ? workflowById(this.state.workflowId)
      : workflowByProcessNumber(this.state.idProcess);

    getWorkflowService
      .then((response) => this.getWorkFlowTypes(response.data))
      .catch((error) => {
        this.serviceError(error);
        this.setLoading(false);
        this.setState({ onlyRead: true });
        this.props.onlyReadWorkflow(this.state.onlyRead);
        this.props.backToGroup(false);
      });
  }

  getWorkFlowTypes(workflowSelected) {
    getAllWorkflowTypes(workflowSelected.type, this.props.isRecommended).then((response) => {
      this.currentStage = this.getCurrentStage(workflowSelected);
      response.data.workflowStageTypes.sort((a, b) => a.priority - b.priority);

      if (this.props.featureFlags?.isWorkflowFormTypesEnabled) {
        const workflowTypeCode = workflowSelected.type;
        const stageCode = Object.keys(Constants.WORKFLOW_STAGES).find((code) => this.currentStage.type.startsWith(code));
        this.props.fetchFormTypes(
          this.props.getFormTypesCode({
            isGroupFetch: true,
            type: Constants.AREAS.WORKFLOW,
            stage: stageCode,
            variant: workflowTypeCode,
            isRecommended: this.props.isRecommended,
          }),
        );
      }

      this.setState(
        {
          workflowSelected,
          fieldConfig: { controls: {} },
          initialLiteral: this.props.t(`workflow.step.${workflowSelected.type}`),
          workflow: response.data,
          stageType: this.currentStage.type || '',
          onlyRead: workflowSelected.onlyRead,
          onlyReadReason: workflowSelected.onlyReadReason || '',
        },
        () => {
          this.setLoading(false);
        },
      );
    });
  }

  getAllScopes() {
    if (this.state.workflow.code.includes(Config.EPC_DIAGRAM)) {
      userService
        .getScopesByName({
          page: 0,
          pageSize: 50,
          sortDir: 'ASC',
        })
        .then((response) => {
          const scopesSelected = this.state.workflowSelected.scopes.map((e) => e.departmentId);
          const scopes = response.data.results.map((scope) => ({
            id: scope.departmentId,
            abbreviation: scope.abbreviation,
            description: JSON.parse(scope.description)[this.language],
            firstIncumbentUser: scope.firstIncumbentUser,
            secondIncumbentUser: scope.secondIncumbentUser,
            selected: scopesSelected.includes(scope.departmentId),
          }));
          this.setState({ scopes });
        })
        .catch((err) => {
          this.serviceError(err);
        });
    }
  }

  setLocationsName(fieldConfig, loadAttributesForm, locations) {
    // TODO: ahora mismo construimos el Name aquí encima del que le pusimos porque esta llamada resuelve después, si el back
    //  cambia los valores que guarda podremos borrar esto
    const newFieldConfig = { ...fieldConfig };
    let recommendedLocations = 'RECOMMENDED_LOCATIONS';
    let recommendNewLocations = 'RECOMMEND_NEW_LOCATIONS';

    if ('RECOMMENDED_LOCATIONS_WF' in fieldConfig.controls) {
      recommendedLocations = 'RECOMMENDED_LOCATIONS_WF';
      recommendNewLocations = 'RECOMMEND_NEW_LOCATIONS_WF';
    }

    if (fieldConfig.controls[recommendedLocations] && fieldConfig.controls[recommendedLocations].meta.valuesLoaded) {
      newFieldConfig.controls[recommendedLocations].meta.valuesLoaded = this.mapLocationsName(
        locations,
        fieldConfig.controls[recommendedLocations].meta.valuesLoaded,
      );
    }

    if (fieldConfig.controls[recommendNewLocations] && fieldConfig.controls[recommendNewLocations].meta.valuesLoaded) {
      newFieldConfig.controls[recommendNewLocations].meta.valuesLoaded = this.mapLocationsName(
        locations,
        fieldConfig.controls[recommendNewLocations].meta.valuesLoaded,
      );
    }

    const newLoadAttributesForm = {
      ...loadAttributesForm,
      [recommendedLocations]: loadAttributesForm[recommendedLocations]
        ? this.mapLocationsName(locations, loadAttributesForm[recommendedLocations])
        : loadAttributesForm[recommendedLocations],
      [recommendNewLocations]: loadAttributesForm[recommendNewLocations]
        ? this.mapLocationsName(locations, loadAttributesForm[recommendNewLocations])
        : loadAttributesForm[recommendNewLocations],
    };

    return {
      newFieldConfig,
      newLoadAttributesForm,
    };
  }

  getAllLocations(fieldConfig) {
    if (
      this.state.workflow.code.includes(Config.EPC_DIAGRAM) &&
      this.state.workflowSelected.attributes.some((attr) => attr.code.includes('LOCATIONS'))
    ) {
      communicationService
        .getLocations()
        .then((response) => {
          const locations = response.data
            .filter((location) => location.code !== Constants.CENTRAL)
            .map((elem) => ({
              ...elem,
              displayName: `${elem.name} - ${elem.location}, ${elem.country}${elem.daimlerCode ? ` (${elem.daimlerCode})` : ''}`,
              selected: false,
            }));
          const { newFieldConfig, newLoadAttributesForm } = this.setLocationsName(
            fieldConfig || this.state.fieldConfig,
            this.state.loadAttributesForm,
            locations,
          );

          // TODO: remove console warn when we find out about the bug
          // eslint-disable-next-line no-console
          console.warn({
            fieldConfig: this.state.fieldConfig,
            newFieldConfig,
            loadAttributesForm: this.state.loadAttributesForm,
            newLoadAttributesForm,
            locations,
          });
          this.setState(
            {
              fieldConfig: newFieldConfig,
              loadAttributesForm: newLoadAttributesForm,
              locations,
            },
            () => {
              const showSystemAttributes = this.currentStage.type !== Constants.AUTHOR;

              // Si estamos en el paso más allá de AUTHOR, muestro los atributos en el Diagrama
              if (showSystemAttributes && !this.state.isClosedWorkflow) {
                const diagramWorkflowForm = this.setLocationsName(this.currentStageFields, this.currentStageData, locations);

                this.returnWorkflowFieldConfig(diagramWorkflowForm.newFieldConfig, diagramWorkflowForm.newLoadAttributesForm);
              }
            },
          );
        })
        .catch((err) => {
          this.serviceError(err);
        });
    }
  }

  getBackToGroup() {
    let currentStageQA = false;
    this.state.workflowSelected.stages.forEach((stage) => {
      if (stage.status === Constants.CURRENT && stage.type.includes(Constants.QA_INSPECTOR_GROUP)) {
        stage.responsibleList.forEach((user) => {
          if (user.responsibleType === Config.USER) {
            currentStageQA = true;
          }
        });
      }
    });
    const enableBackToGroup = !this.state.workflowSelected.onlyRead && currentStageQA;
    this.props.backToGroup(enableBackToGroup);
  }

  setLoading(isLoading) {
    this.setState({ isLoading }, () => {
      this.props.returnLoading(isLoading);
    });
  }

  setNameWorkflowAttributes(fieldConfig) {
    const workflowSelected = { ...this.state.workflowSelected };

    for (let i = 0; i < workflowSelected.attributes.length; i++) {
      const field = workflowSelected.attributes[i];
      const fieldMeta = fieldConfig?.controls[field.code]?.meta;

      if (
        (fieldMeta?.fieldType === Constants.INPUT_MULTISELECTION && fieldMeta?.searchBy === Constants.SEARCH_BY.USER) ||
        Config.multiselectUserFields.includes(field.code)
      ) {
        const wfUsers = field.value.map((elem) => elem);
        const users = workflowSelected.users.filter((user) => wfUsers.includes(user.code));
        field.value =
          field.value.length > 0
            ? users.map((elem) => ({
                Code: elem.code,
                Name: elem.commonName,
              }))
            : [];
      } else if (field.code === Constants.SCOPES) {
        const wfScopes = field.value.map((elem) => parseInt(elem, 10));
        const scopes = workflowSelected.scopes.filter((scope) => wfScopes.includes(parseInt(scope.departmentId, 10)));
        field.value =
          field.value.length > 0
            ? scopes.map((elem) => {
                const user = elem.firstIncumbentUser || elem.secondIncumbentUser || 'N.N.';

                return {
                  Code: elem.departmentId,
                  Name: `${elem.abbreviation} - ${JSON.parse(elem.description)[this.language]} (${user})`,
                };
              })
            : [];
      }
    }

    this.setState({ workflowSelected });
  }

  // eslint-disable-next-line class-methods-use-this
  getCurrentStage(workflow) {
    const { stages } = workflow;
    const currentStage = stages.find((s) => s.status === Constants.CURRENT);
    if (!stages) return '';

    if (!currentStage) return this.state.isClosedWorkflow ? stages[stages.length - 1] : '';
    return stages.find((s) => s.status === Constants.CURRENT);
  }

  getDropdownsSelectedValues(fieldConfig) {
    const keys = Object.keys(fieldConfig.controls);
    const optionsSelected = [];

    keys.forEach((key) => {
      if (fieldConfig.controls[key].render.name === Constants.DROPDOWN) {
        const option = fieldConfig.controls[key].meta.options.find(({ selected }) => selected);
        const optionSelected = {
          key,
          value: option ? option.value : '',
        };

        optionsSelected.push(optionSelected);
      }
    });

    return optionsSelected;
  }

  adaptFieldConfigForDiagramForm(fieldConfig) {
    const objectData = Config.parseAttributesResponseNew(
      this.state.workflowSelected.attributes,
      fieldConfig,
      this.state.qualityGroups,
    );
    this.currentStageData = objectData.formData;

    const { $field_0: formButtonsControl, ...filteredControls } = fieldConfig.controls;
    const currentStageFields = Object.fromEntries(Object.entries(filteredControls).map(([key, value]) => [`${key}_WF`, value]));

    this.currentStageFields = { controls: currentStageFields };

    return { objectData };
  }

  mapLocationsName(locations, locationsFieldconfig) {
    return locationsFieldconfig.map((value) => ({
      Code: value.Code || value,
      Name: locations.find((location) => location.code === value.Code || location.code === value).displayName,
    }));
  }

  approveWorkflow(formValues) {
    const data = Config.getWorkflowData(formValues, this.state.qualityGroups);
    this.setLoading(true);

    this.setState({ openDialog: false }, () => {
      approveWorkflow(this.state.idProcess, data)
        .then((response) => {
          if (response.data === Constants.COMPLETED) {
            this.setLoading(false);
            const isHistorical = Constants.HISTORICAL_WF_TYPES.includes(this.state.workflowSelected.type);
            this.props.finishWorkflow(isHistorical);
          } else {
            Dialog.showAlert({
              name: this.props.t('success'),
              message: this.props.t('workflowDialog.approvedWorkflow'),
            });
            this.getWorkflow();
          }
        })
        .catch((error) => {
          this.serviceError(error);
        });
    });
  }

  saveWorkflow(formValues) {
    this.setLoading(true);

    this.setState({ openDialog: false }, () => {
      const data = Config.getWorkflowData(formValues, this.state.qualityGroups);
      saveWorkflow(this.state.idProcess, data)
        .then(() => {
          this.setState({ saved: true }, () => {
            this.getWorkflow();
          });
        })
        .catch((error) => {
          this.serviceError(error);
        });
    });
  }

  openWorkflowDialog() {
    this.setState({
      openDialog: true,
      saved: false,
    });
  }

  returnWorkflowFieldConfig(fields, formData) {
    this.props.setWorkflowFieldConfig(fields);
    this.props.setWorkflowData(formData);
  }

  toggleOpen() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  serviceError(error) {
    this.setLoading(false);
    handleServiceError(error);
  }

  confirmReject(value) {
    this.setLoading(true);

    rejectWorkflow(this.state.idProcess, Utils.purify(value))
      .then(() => {
        this.setLoading(false);
        this.props.rejected();
      })
      .catch((error) => this.serviceError(error));
  }

  openDelegateDialog(currentUser, isQIGroup) {
    const qualityGroups = this.state.qualityGroups.map((option) => ({ text: option, value: option, selected: false }));
    this.isQIGroup = isQIGroup;

    if (isQIGroup) {
      const selectedGroup = isQIGroup ? this.currentStage.responsibleList[0].userCode : '';

      qualityGroups.forEach((qaGroup) => {
        if (qaGroup.value === selectedGroup) {
          qaGroup.selected = true;
        }
      });
    } else if (this.currentStage.type === Constants.APPROVERS || this.currentStage.type === Constants.APPROVERS_HISTORICAL) {
      this.delegatedApprover = currentUser;
    }

    this.setState({
      delegateFieldConfig: Config.getDelegateFieldConfig(this.props.t, this.state.stageType, isQIGroup, qualityGroups),
      currentUser,
      showDelegateDialog: true,
    });
  }

  showWorkFlowSteps() {
    const { stages } = this.state.workflowSelected;
    const { workflowStageTypes } = this.state.workflow;
    let approvers;
    const IsOpenWorkflowDisability = () => approvers || (this.state.onlyRead && !this.context.checkRole('ADMINISTRATOR'));

    return (
      <>
        <WorkflowStep content={[this.state.initialLiteral]} status="Initial" title={this.state.workflow.code?.slice(-3)} />
        {workflowStageTypes &&
          workflowStageTypes
            .sort((a, b) => a.priority - b.priority)
            .map((step, index) => {
              const stage = stages.find((elem) => elem.type === step.stageType.code);
              const users = stage.responsibleList.filter((user) => user.responsibleType === Config.USER).map((user) => user);
              const group = stage.responsibleList.filter((user) => user.responsibleType === Config.GROUP).map((user) => user);
              const userName = this.context?.userName;

              approvers = users.find((approver) => approver.userCode === userName && approver.finishDate !== null);
              const finishDate = stage.responsibleList.map(
                (date) => date.responsibleType === Config.USER && Utils.getFullFormattedDate(date.finishDate),
              );
              const stageCode = Object.keys(Constants.WORKFLOW_STAGES).find((code) => step.stageType.code.startsWith(code));

              return (
                <WorkflowStep
                  code={stageCode}
                  codeStatus={stage.status || ''}
                  content={users}
                  expirationDate={stage.expirationDate !== 'null' ? Utils.getFullFormattedDate(stage.expirationDate) : '-'}
                  finishDate={finishDate}
                  group={group}
                  initialDate={stage.initialDate !== 'null' ? Utils.getFullFormattedDate(stage.initialDate) : '-'}
                  isClosedWorkflow={this.state.isClosedWorkflow}
                  isDelegableStage={
                    !this.state.isClosedWorkflow &&
                    this.context.checkRoles([Constants.ADMINISTRATOR, Constants.QI]) &&
                    stage.status === Constants.CURRENT
                  }
                  isLastStep={index === workflowStageTypes.length - 1}
                  isWorkflowBarOpen={this.state.isOpen}
                  key={step.id}
                  openDelegateDialog={(currentUser, isQIGroup) => this.openDelegateDialog(currentUser, isQIGroup)}
                  rejectedBy={this.rejectedBy}
                  rejectReason={this.rejectReason}
                  status={this.props.t(`workflow.stage.status.${stage.status}`) || ''}
                  title={this.props.t(`workflow.stage.type.${stageCode}`)}
                  usersList={this.state.workflowSelected.users}
                />
              );
            })}
        <div className={styles.Buttons}>
          <Button
            btnText={this.props.t(this.state.isClosedWorkflow ? 'workflowInfo' : 'workflow.nextStep')}
            buttonStyle="Primary"
            custom={this.state.isClosedWorkflow ? 'custom' : ''}
            disabled={
              this.state.isClosedWorkflow
                ? !this.state.workflowSelected.users?.find((user) => user.code === this.context.userCode) &&
                  !this.context.checkRole('ADMINISTRATOR')
                : IsOpenWorkflowDisability()
            }
            handleClick={() => this.openWorkflowDialog()}
            id="nextStep"
          />
        </div>
      </>
    );
  }

  delegateUser(newApproverCode, reason) {
    const userOrGroup = this.isQIGroup ? 'GROUP' : 'USER';
    let userCodeList = [newApproverCode];

    if (this.currentStage.type.includes(Constants.QA_INSPECTOR_GROUP) && !this.isQIGroup) {
      userCodeList = [this.currentStage.responsibleList[0].userCode];
      userCodeList.push(newApproverCode);
    } else if (this.currentStage.type === Constants.APPROVERS || this.currentStage.type === Constants.APPROVERS_HISTORICAL) {
      userCodeList = [];
      this.currentStage.responsibleList.forEach((responsible) => {
        if (this.delegatedApprover.includes(responsible.userCode)) {
          userCodeList.push(newApproverCode);
        } else {
          userCodeList.push(responsible.userCode);
        }
      });
    }

    delegateWorkflowStep(Utils.purify(reason), this.state.idProcess, userCodeList, userOrGroup)
      .then(() => {
        this.props.reloadDiagram(this.props.diagramId);
      })
      .catch((error) => {
        this.serviceError(error);
      });
  }

  checkResponsibleUser() {
    const userName = this.context?.userName || 'PROFILE';
    const responsible = this.currentStage ? this.currentStage.responsibleList.find((user) => user.userCode === userName) : '';

    return Boolean(responsible) && responsible !== '';
  }

  render() {
    return (
      <>
        <div className={`${styles.Wrapper} ${this.state.isOpen ? styles.Open : ''}`}>
          <div className={styles.ExpandBar} id="expand" onClick={() => this.toggleOpen()}>
            <i className="fas fa-angle-double-left" />
          </div>
          <div className={`${this.state.workflow.code} ${styles.Bar}`}>
            {this.state.isLoading || this.props.onlyReadSteps ? (
              <div className={styles.Spinner}>
                <Spinner isVisible />
              </div>
            ) : (
              this.showWorkFlowSteps()
            )}
          </div>
        </div>
        <div id="workflow-dialog">
          <WorkflowDialog
            approve={(formValues) => this.approveWorkflow(formValues)}
            attributesData={this.state.loadAttributesForm}
            close={() =>
              this.setState({
                openDialog: false,
                fieldConfig: Utils.resetDropdowns(
                  this.state.fieldConfig,
                  this.getDropdownsSelectedValues(this.state.fieldConfig),
                  this.state.saved,
                ),
              })
            }
            codeWorkflow={this.state.workflow.code || ''}
            confirm={(reason) => this.confirmReject(reason)}
            fieldConfig={this.state.fieldConfig}
            isOpen={this.state.openDialog}
            isResponsible={!this.state.isClosedWorkflow && this.checkResponsibleUser()}
            labelDiagramName={this.state.diagramName}
            locations={this.state.locations}
            save={(formValues) => this.saveWorkflow(formValues)}
            scopes={this.state.scopes}
            showButtonSave={this.state.showButtonSave}
            showRejectButton={
              !this.state.isClosedWorkflow && (this.checkResponsibleUser() || this.context.checkRole(Constants.ADMINISTRATOR))
            }
          />
        </div>
        <div id="delegate-workflow-dialog">
          {this.state.showDelegateDialog && (
            <DelegateWorkflowDialog
              close={() => this.setState({ showDelegateDialog: false })}
              currentUser={this.state.currentUser}
              delegateFieldConfig={this.state.delegateFieldConfig}
              delegateUser={(newApproverCode, reason) => this.delegateUser(newApproverCode, reason)}
            />
          )}
        </div>
      </>
    );
  }
}
WorkflowBar.contextType = AuthContext;

export default withTranslation('common')(WorkflowBar);
