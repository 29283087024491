import { useContext } from 'react';

import { DiagramContext } from 'contexts/Diagram/DiagramContext';

export default function useDiagramContext() {
  const context = useContext(DiagramContext);

  if (context === undefined) {
    throw new Error('useDiagramContext must be used within a DiagramProvider');
  }

  const { state, dispatch, refs } = context;

  return {
    ...state,
    ...refs,
    dispatch,
  };
}
