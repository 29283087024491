import { ActionIcon } from 'types/actionIcon';

export const EDIT_BUTTON: ActionIcon = {
  icon: 'di icon-stift-bearbeiten',
  id: `edit-button`,
  onClick: () => {},
};

export const DELETE_BUTTON: ActionIcon = {
  icon: 'di icon-muelleimer-loeschen',
  id: `delete-button`,
  onClick: () => {},
};

export const REDIRECT_BUTTON: ActionIcon = {
  icon: 'di icon-link-extern',
  id: 'redirect-button',
  onClick: () => {},
};

export const INFO_BUTTON: ActionIcon = {
  icon: 'di icon-information',
  id: 'block-diagram-info',
  onClick: () => {},
};

export const DOWNLOAD_BUTTON: ActionIcon = {
  icon: 'di icon-herunterladen',
  id: 'download-button',
  onClick: () => {},
};
