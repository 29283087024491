import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import DialogRequestPendingRole from 'components/DialogDetail/DialogRequestPendingRole';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import ScrollBox from 'components/UI/ScrollBox/ScrollBox';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import useLocalization from 'hooks/useLocalization';

import styles from 'components/DialogDetail/DialogRequestITSystem.module.scss';
import dialogStyles from 'components/UI/Dialog/Dialog.module.scss';

import stylesMultilanguage from './DialogMultilanguage.module.scss';

const DialogMultilanguage = ({
  buttons,
  children,
  initialLanguage,
  isLanguageValid,
  isNEPOS,
  title,
  pendingObjects = [],
  objectType = '',
  showRequestedRoles = false,
}) => {
  const { t, i18n } = useTranslation();
  const { availableLanguages } = useLocalization();
  const [language, setLanguage] = useState(initialLanguage || i18n.language);
  return (
    <Dialog className={isNEPOS ? stylesMultilanguage.DialogContainer : dialogStyles.ContainerRow}>
      <div className={stylesMultilanguage.FormWrapper}>
        <DialogHeader>{title}</DialogHeader>
        <TabSelector
          className={stylesMultilanguage.Tabs}
          handleClick={setLanguage}
          isNEPOS={isNEPOS}
          options={availableLanguages.map((lang) => ({ id: lang, name: t(`language.${lang}`), isValid: isLanguageValid[lang] }))}
          selectedOption={language}
        />
        <ScrollBox
          customClassName={`${stylesMultilanguage.DialogScroll} ${!showRequestedRoles ? stylesMultilanguage.FullWidth : ''}`}
        >
          {React.cloneElement(children, { language })}
        </ScrollBox>
        <DialogFooter buttons={buttons} extraClass={isNEPOS ? stylesMultilanguage.Footer : false} />
      </div>
      {showRequestedRoles && (
        <div className={styles.SectionRequest}>
          <DialogRequestPendingRole objectType={objectType} pendingObjects={pendingObjects} />
        </div>
      )}
    </Dialog>
  );
};

export default DialogMultilanguage;
