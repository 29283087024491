import UploadCard, { UploadCardI } from 'components/UploadCard/UploadCard';

import styles from './UploadCardList.module.scss';

type Props = {
  list: UploadCardI[];
};

const UploadCardList = ({ list }: Props) => {
  return (
    <section className={styles.Container}>
      {list.map((item) => (
        <div className={styles.Cell} key={item.id}>
          <UploadCard {...item} />
        </div>
      ))}
    </section>
  );
};

export default UploadCardList;
