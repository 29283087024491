import { createContext, useReducer, Dispatch, ReactNode } from 'react';

import { DatasetData } from 'types/dataset';
import { Attributes } from 'types/forms';
import { DatasetWorkflowData } from 'types/workflow';

enum ActionTypes {
  SET_DATASET_ATTRIBUTES = 'SET_DATASET_ATTRIBUTES',
  DELETE_DATASET = 'DELETE_DATASET',
  FETCH_DATA_SET_SUCCESS = 'FETCH_DATA_SET_SUCCESS',
  SET_LOADING_DATASET = 'SET_LOADING_DATASET',
  SET_DATASET = 'SET_DATASET',
  SET_WORKFLOW_DATA = 'SET_WORKFLOW_DATA',
  FETCH_WORKFLOW = 'FETCH_WORKFLOW',
}

type Action =
  | { type: ActionTypes.SET_DATASET_ATTRIBUTES; payload: Attributes }
  | { type: ActionTypes.FETCH_DATA_SET_SUCCESS; payload: DatasetData }
  | { type: ActionTypes.DELETE_DATASET }
  | { type: ActionTypes.SET_LOADING_DATASET; payload: boolean }
  | { type: ActionTypes.SET_DATASET; payload: DatasetData }
  | { type: ActionTypes.SET_WORKFLOW_DATA; payload: DatasetWorkflowData }
  | { type: ActionTypes.FETCH_WORKFLOW };

type State = {
  datasetData?: DatasetData;
  workflowData?: DatasetWorkflowData;
  isLoading: boolean;
};

const initialState: State = {
  isLoading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING_DATASET:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SET_DATASET:
      return {
        ...state,
        datasetData: action.payload,
      };
    case ActionTypes.SET_DATASET_ATTRIBUTES:
      return {
        ...state,
        datasetData: {
          ...(state.datasetData as DatasetData),
          attributes: action.payload,
        },
      };
    case ActionTypes.SET_WORKFLOW_DATA: {
      return {
        ...state,
        workflowData: action.payload,
      };
    }
    case ActionTypes.DELETE_DATASET:
      return {
        ...state,
        datasetData: undefined,
      };
    case ActionTypes.FETCH_WORKFLOW:
      return {
        ...state,
        isLoading: true,
      };
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface IDatasetContext {
  state: State;
  dispatch: Dispatch<Action>;
}

const DatasetContext = createContext<IDatasetContext | undefined>(undefined);

const DatasetProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <DatasetContext.Provider value={value}>{children}</DatasetContext.Provider>;
};

export { ActionTypes as DatasetActionTypes, DatasetContext, DatasetProvider };
