import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useError from 'hooks/useError';
import { AttributeCode, Chip, LanguageChip, SearchBy } from 'types/forms';
import { ProcessDataCatalog } from 'types/processes';

import DropdownPanel from '../DropdownPanel/DropdownPanel';
import InputTextChipsNEPOS from '../InputText/InputTextChipsNEPOS';
import AddScopeDialog from './AddScopeDialog';
import styles from './MultiselectionNEPOS.module.scss';

interface Props<T> {
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  error?: string;
  max?: number;
  selected: Chip[];
  onChange: (value: string) => void;
  service: (value: string) => Promise<any>;
  displayValueFormatter: (item: T) => string;
  onBlur?: () => void;
  onClick?: () => void;
  onRemoveChip?: (chip: Chip) => void;
  placeholder: string;
  code: string;
  className?: string;
  hasPlusIcon?: boolean;
  tooltip?: string;
  isRequirement?: boolean;
  externalChips?: Chip[];
  catalog?: ProcessDataCatalog;
}

const MultiselectionNEPOS = <T extends { code: string; commonName?: string; departments?: string[]; linkTeams?: string }>(
  props: Props<T>,
) => {
  const {
    id,
    label,
    required,
    disabled,
    onChange,
    service,
    displayValueFormatter,
    placeholder,
    onBlur,
    onClick,
    onRemoveChip,
    max = 1,
    selected = [],
    code,
    className,
    hasPlusIcon,
    tooltip,
    externalChips = [],
    isRequirement,
    catalog,
  } = props;
  const { t } = useTranslation();
  const { handleServiceError } = useError();
  const containerRef = useRef<HTMLLabelElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<Chip[]>(selected);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { processData, dispatch } = useDiagramContext();
  const [internalCatalog, setInternalCatalog] = useState<ProcessDataCatalog | undefined>(catalog);
  const isModal = code === AttributeCode.SCOPE || hasPlusIcon;
  const isSmall = code === AttributeCode.PROCESS_RESPONSIBLE;

  const fetchOptions = () => {
    setIsSearchLoading(true);
    return service(searchText)
      .then((res) => {
        setSearchResults(res.data.results);
        setIsSearchLoading(false);
      })
      .catch((err) => {
        handleServiceError(err);
        setIsSearchLoading(false);
      });
  };

  useEffect(() => {
    clearTimeout(timeoutRef.current as number);

    if (!searchText.trim()) {
      setSearchResults([]);
      return;
    }

    timeoutRef.current = window.setTimeout(() => {
      fetchOptions();
    }, 300);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (item: string) => {
    setSearchText(item);
    setIsOpen(!!item);
  };

  useEffect(() => {
    if (chips.length === selected.length && JSON.stringify(chips) === JSON.stringify(selected)) return;
    setChips(selected);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateChips = (newChips: Chip[]): void => {
    setChips(newChips);
    onChange(`[${newChips && newChips.map((element) => element.code)}]`);
  };

  const handleCheck = (item: T) => {
    if (item.commonName) {
      let newChips = [...chips];
      if (chips.some((elem) => item.code === elem.code)) {
        newChips = chips.filter((el) => el.code !== item.code);
      } else if (!max || (max && chips.length < max)) {
        newChips = [...chips, { code: item.code, displayName: `${item.commonName} (${item.code})` }];
        const existingCatalog = !processData?.catalog ? internalCatalog : processData?.catalog;
        if (existingCatalog) {
          const newCatalog = {
            [SearchBy.USER]: [
              {
                code: item.code,
                commonName: item.commonName,
                departments: item.departments,
                linkTeams: item.linkTeams,
              },
            ],
          };

          let payload: ProcessDataCatalog = {
            ...existingCatalog,
            ...newCatalog,
          };

          if (existingCatalog[SearchBy.USER]) {
            const existingElement = existingCatalog[SearchBy.USER].find((elem) => item.code === elem.code);
            if (!existingElement) {
              existingCatalog[SearchBy.USER].push({
                code: item.code,
                commonName: item.commonName,
                departments: item.departments,
                linkTeams: item.linkTeams,
              });
            }
            payload = existingCatalog;
          }
          setInternalCatalog(payload);

          if (processData?.catalog) {
            dispatch({
              type: DiagramActionTypes.UPDATE_PROCESS_CATALOG,
              payload,
            });
          }
        }
      }
      if (newChips.length !== chips.length) {
        setIsOpen(false);
        setSearchText('');
      }
      updateChips(newChips);
    }
  };

  const handleRemoveChip = (item: Chip) => {
    setIsOpen(false);
    setSearchText('');

    updateChips(chips.filter((e) => e.code !== item.code));
    if (onRemoveChip) onRemoveChip(item);
  };

  const handleClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateChips([]);
  };

  const handleAddScopes = (chipsToAdd: LanguageChip[]) => {
    if (chipsToAdd.length) {
      const existingCatalog = processData?.catalog;
      if (existingCatalog) {
        const newCatalog = {
          [AttributeCode.SCOPE]: chipsToAdd.map((chip) => ({
            code: chip.code,
            description: chip.description,
          })),
        };
        const payload: ProcessDataCatalog = {
          ...existingCatalog,
          ...newCatalog,
        };

        dispatch({
          type: DiagramActionTypes.UPDATE_PROCESS_CATALOG,
          payload,
        });
      }
    }
    updateChips(chipsToAdd);
  };

  return (
    <>
      <div
        className={`${isSmall ? styles.ContainerMultiselectionSmall : styles.ContainerMultiselection} ${className || ''}`}
        onClick={() => {
          if (!isModal && !disabled && onClick) onClick();
          if (!disabled) setIsModalOpen(isModal!!);
        }}
      >
        <InputTextChipsNEPOS
          chips={
            chips.length > 0 && (!isRequirement || (selected.length > 0 && externalChips.length === 0)) ? chips : externalChips
          }
          clearAll={handleClearAll}
          containerRef={containerRef}
          disabled={disabled}
          error={props.error}
          handleRemoveChip={handleRemoveChip}
          id={id}
          isModal={isModal}
          label={label}
          max={max}
          onBlur={() => onBlur && !isOpen && onBlur()}
          onChange={handleChange}
          placeholder={placeholder}
          reference={textInputRef}
          required={required}
          tooltip={tooltip}
          value={searchText}
        />
        {isOpen && (
          <DropdownPanel
            className={styles.Panel}
            close={() => {
              setIsOpen(false);
              setSearchText('');
            }}
            parentRef={containerRef}
          >
            {isSearchLoading || !searchResults.length ? (
              <div className={styles.Placeholder}>{isSearchLoading ? t('loading') : t('multiselection.noRecordsFound')}</div>
            ) : (
              <ul className={styles.List}>
                {searchResults.map((item, index) => (
                  <li className={styles.Item} key={item.code} tabIndex={index}>
                    <Checkbox
                      checked={chips.some((elem) => item.code === elem.code)}
                      handleCheck={() => handleCheck(item)}
                      label={displayValueFormatter(item)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </DropdownPanel>
        )}
      </div>
      {isModalOpen && !hasPlusIcon && (
        <AddScopeDialog add={handleAddScopes} close={() => setIsModalOpen(false)} selected={chips} />
      )}
    </>
  );
};

export default MultiselectionNEPOS;
