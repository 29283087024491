import ConnectorHandles from 'components/ConnectorHandles/ConnectorHandles';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import useConnector from 'hooks/useConnector';
import useDiagramContext from 'hooks/useDiagramContext';
import { Connector as ConnectorType, ConnectorPorts } from 'types/connectors';

import styles from './ConnectorSelection.module.scss';

const ConnectorSelection = (props: ConnectorType & { className?: string }) => {
  const { className = '', source, target } = props;
  const { selectedConnectorId } = useDiagramContext();
  const { sourceCoordinates, deleteConnector } = useConnector(props);

  const ButtonYCoordinatesByPortMap = {
    [ConnectorPorts.BOTTOM]: sourceCoordinates ? sourceCoordinates?.top * 10 + 5 : 0,
    [ConnectorPorts.LEFT]: sourceCoordinates ? sourceCoordinates?.top * 10 - 35 : 0,
    [ConnectorPorts.RIGHT]: sourceCoordinates ? sourceCoordinates?.top * 10 - 35 : 0,
    [ConnectorPorts.TOP]: sourceCoordinates ? sourceCoordinates?.top * 10 - 35 : 0,
  };

  const ButtonXCoordinatesByPortMap = {
    [ConnectorPorts.BOTTOM]: sourceCoordinates ? sourceCoordinates?.left * 10 + 5 : 0,
    [ConnectorPorts.LEFT]: sourceCoordinates ? sourceCoordinates?.left * 10 - 35 : 0,
    [ConnectorPorts.RIGHT]: sourceCoordinates ? sourceCoordinates?.left * 10 + 5 : 0,
    [ConnectorPorts.TOP]: sourceCoordinates ? sourceCoordinates?.left * 10 + 5 : 0,
  };

  return selectedConnectorId?.sourceId === source.id && selectedConnectorId?.targetId === target.id ? (
    <>
      <foreignObject
        className={className}
        height={30}
        transform="scale(0.1,0.1)"
        width={30}
        x={ButtonXCoordinatesByPortMap[source.port]}
        y={ButtonYCoordinatesByPortMap[source.port]}
      >
        <RoundButton
          className={styles.RemoveButton}
          icon="icon-muelleimer-loeschen"
          id="connector-delete-button"
          onClick={() => deleteConnector(source, target)}
        />
      </foreignObject>
      <ConnectorHandles className={className} />
    </>
  ) : null;
};

export default ConnectorSelection;
