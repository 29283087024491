import { useCallback, useMemo, useRef } from 'react';

import { CONNECTOR_ALIGN_SEGMENT_THRESHOLD, CONNECTOR_HIDE_HANDLE_THRESHOLD } from 'assets/constants/constants';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useConnector from 'hooks/useConnector';
import { ConnectorDirectionTypes, ConnectorHandle, ConnectorPorts, ConnectorVertex } from 'types/connectors';
import { Coordinate, Coordinates } from 'types/diagram';

import useDiagramContext from './useDiagramContext';

export default function useConnectorHandles() {
  const { dispatch, fontSize } = useDiagramContext();
  const { selectedConnector, sourceCoordinates, targetCoordinates, updateConnectorVertices } = useConnector();
  const selectedConnectorVertices = useRef({ initial: selectedConnector?.vertices, final: selectedConnector?.vertices });
  const movingHandle = useRef<(Coordinates & { coordinate: keyof Coordinates; index: number; initialCoordinate: number }) | null>(
    null,
  );

  const deleteUnnecessaryVertices = useCallback(() => {
    if (!movingHandle.current || !selectedConnectorVertices.current.final || !sourceCoordinates || !targetCoordinates) return;

    const newVertices: ConnectorVertex[] = [...selectedConnectorVertices.current.final];
    const previousVertex = selectedConnectorVertices.current.final[movingHandle.current.index - 1];
    const coordinate = previousVertex.direction === ConnectorDirectionTypes.HORIZONTAL ? Coordinate.LEFT : Coordinate.TOP;

    if (
      movingHandle.current.index + 1 < newVertices.length &&
      previousVertex.coordinate < newVertices[movingHandle.current.index + 1].coordinate + CONNECTOR_ALIGN_SEGMENT_THRESHOLD &&
      previousVertex.coordinate > newVertices[movingHandle.current.index + 1].coordinate - CONNECTOR_ALIGN_SEGMENT_THRESHOLD
    ) {
      newVertices[movingHandle.current.index - 1].coordinate = newVertices[movingHandle.current.index + 1].coordinate;
      newVertices.splice(movingHandle.current.index, 2);
    }

    if (
      movingHandle.current.index === 2 &&
      previousVertex.coordinate < sourceCoordinates[coordinate] + CONNECTOR_ALIGN_SEGMENT_THRESHOLD &&
      previousVertex.coordinate > sourceCoordinates[coordinate] - CONNECTOR_ALIGN_SEGMENT_THRESHOLD
    ) {
      newVertices.splice(0, 2);
    } else if (
      movingHandle.current.index > 2 &&
      previousVertex.coordinate < newVertices[movingHandle.current.index - 3].coordinate + CONNECTOR_ALIGN_SEGMENT_THRESHOLD &&
      previousVertex.coordinate > newVertices[movingHandle.current.index - 3].coordinate - CONNECTOR_ALIGN_SEGMENT_THRESHOLD
    ) {
      newVertices.splice(movingHandle.current.index - 2, 2);
    }

    updateConnectorVertices(newVertices);
  }, [sourceCoordinates, targetCoordinates, updateConnectorVertices]);

  const handleMove = useCallback(
    (event: MouseEvent) => {
      if (!movingHandle.current || !fontSize || !selectedConnectorVertices.current.initial) return;
      const diff =
        ((movingHandle.current.coordinate === Coordinate.LEFT ? event.clientY : event.clientX) -
          movingHandle.current.initialCoordinate) /
        fontSize;
      const newVertices = selectedConnectorVertices.current.initial.map((vertex, index) => {
        if (!movingHandle.current || index !== movingHandle.current.index - 1) return vertex;
        return { ...vertex, coordinate: vertex.coordinate + diff };
      });
      selectedConnectorVertices.current.final = newVertices;
      updateConnectorVertices(newVertices);
    },
    [fontSize, selectedConnectorVertices, updateConnectorVertices],
  );

  const handleMoveEnd = useCallback(() => {
    deleteUnnecessaryVertices();
    movingHandle.current = null;
    document.body.classList.remove('grabbing');
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleMoveEnd);
    dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: true });
    dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
  }, [deleteUnnecessaryVertices, dispatch, handleMove]);

  const handleMoveStart = useCallback(
    (
      event: React.MouseEvent<SVGPathElement, MouseEvent>,
      coordinate: keyof Coordinates,
      otherCoordinate: keyof Coordinates,
      handleCoordinates: Coordinates,
      handleIndex: number,
    ) => {
      if (!selectedConnector || !sourceCoordinates || !targetCoordinates) {
        return;
      }

      let newVertices = selectedConnector.vertices;
      const newStartVertices: ConnectorVertex[] =
        handleIndex === 0
          ? [
              {
                direction: coordinate === Coordinate.LEFT ? ConnectorDirectionTypes.HORIZONTAL : ConnectorDirectionTypes.VERTICAL,
                coordinate: sourceCoordinates[coordinate] + (handleCoordinates[coordinate] - sourceCoordinates[coordinate]) / 2,
              },
              {
                direction: coordinate === Coordinate.LEFT ? ConnectorDirectionTypes.VERTICAL : ConnectorDirectionTypes.HORIZONTAL,
                coordinate: sourceCoordinates[otherCoordinate],
              },
            ]
          : [];
      const newEndVertices: ConnectorVertex[] =
        handleIndex === selectedConnector.vertices.length
          ? [
              {
                direction: coordinate === Coordinate.LEFT ? ConnectorDirectionTypes.HORIZONTAL : ConnectorDirectionTypes.VERTICAL,
                coordinate: targetCoordinates[coordinate] - (targetCoordinates[coordinate] - handleCoordinates[coordinate]) / 2,
              },
              {
                direction: coordinate === Coordinate.LEFT ? ConnectorDirectionTypes.VERTICAL : ConnectorDirectionTypes.HORIZONTAL,
                coordinate: targetCoordinates[otherCoordinate],
              },
            ]
          : [];
      if (newStartVertices.length || newEndVertices.length) {
        newVertices = [...newStartVertices, ...selectedConnector.vertices, ...newEndVertices];
        updateConnectorVertices(newVertices);
      }
      selectedConnectorVertices.current = { initial: newVertices, final: newVertices };
      movingHandle.current = {
        ...handleCoordinates,
        coordinate,
        index: handleIndex + newStartVertices.length,
        initialCoordinate: coordinate === Coordinate.LEFT ? event.clientY : event.clientX,
      };
      document.body.classList.add('grabbing');
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleMoveEnd);
      dispatch({ type: DiagramActionTypes.TOGGLE_SHOULD_UPDATE_BACKGROUND, payload: false });
    },
    [dispatch, handleMove, handleMoveEnd, selectedConnector, sourceCoordinates, targetCoordinates, updateConnectorVertices],
  );

  const getHandle = useCallback<
    (coordinate: keyof Coordinates, previousCoordinates: Coordinates, vertexCoordinate: number, index: number) => ConnectorHandle
  >(
    (coordinate, previousCoordinates, vertexCoordinate, index) => {
      const otherCoordinate = coordinate === Coordinate.LEFT ? Coordinate.TOP : Coordinate.LEFT;
      const length = vertexCoordinate - previousCoordinates[coordinate];
      const isActive = movingHandle.current?.index === index;
      const handleCoordinates = {
        [coordinate]:
          isActive && movingHandle.current?.[coordinate]
            ? movingHandle.current[coordinate]
            : previousCoordinates[coordinate] + length / 2 - 1 / 2,
        [otherCoordinate]: previousCoordinates[otherCoordinate] - 1 / 2,
      } as Coordinates;
      return {
        ...handleCoordinates,
        index,
        isActive,
        isHidden: Math.abs(length) < CONNECTOR_HIDE_HANDLE_THRESHOLD,
        onMouseDown: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
          handleMoveStart(event, coordinate, otherCoordinate, handleCoordinates, index);
        },
      } as ConnectorHandle;
    },
    [handleMoveStart],
  );

  const handles = useMemo(() => {
    if (!selectedConnector || !sourceCoordinates || !targetCoordinates) return [];

    const [coordinate, otherCoordinate]: (keyof Coordinates)[] = [ConnectorPorts.LEFT, ConnectorPorts.RIGHT].includes(
      selectedConnector.source.port,
    )
      ? [Coordinate.LEFT, Coordinate.TOP]
      : [Coordinate.TOP, Coordinate.LEFT];
    let previousCoordinates = { ...sourceCoordinates };

    const list = selectedConnector.vertices.map((vertex, index) => {
      const handleCoordinate = index % 2 ? otherCoordinate : coordinate;
      const handle = getHandle(handleCoordinate, previousCoordinates, vertex.coordinate, index);
      previousCoordinates = { ...previousCoordinates, [handleCoordinate]: vertex.coordinate };
      return handle;
    });

    const lastCoordinate = selectedConnector.vertices.length % 2 ? otherCoordinate : coordinate;
    list.push(
      getHandle(lastCoordinate, previousCoordinates, targetCoordinates[lastCoordinate], selectedConnector.vertices.length),
    );
    return list;
  }, [getHandle, selectedConnector, sourceCoordinates, targetCoordinates]);

  return {
    handles,
  };
}
