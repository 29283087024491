import React from 'react';

import { Inject } from '@syncfusion/ej2-react-diagrams';
import {
  HtmlEditor,
  Image as SyncfusionImage,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Table,
  ToolbarType,
  PasteCleanup,
} from '@syncfusion/ej2-react-richtexteditor';
import { withTranslation } from 'react-i18next';

import documentationUtils from '../../../assets/js/documentationUtils';
import { purify } from '../../../assets/js/Utils';
import ErrorLabel from '../ErrorLabel/ErrorLabel';
import './InputWysiwyg.scss';

class InputWysiwygLegacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.meta = props.meta || {};
    this.setValue = props.setValue;
    this.systemAttribute = props.disabled || this.meta.attributeType === 'S'; // System
    this.id = `custom_tbar_${this.meta.label}`;
    this.toolbarSettings = {
      type: ToolbarType.Expand,
      enableFloating: false,
      items: [
        {
          template: `<button class="e-tbar-btn e-btn" tabindex="-1" id="${this.id}" style="width:100%"><div class="e-btn-icon e-dimension e-icons" style="font-weight: 500;" /></button>`,
          tooltipText: 'Expand Textarea',
        },
        '|',
      ],
    };

    this.pasteCleanupSettings = {
      plainText: true,
      deniedAttrs: ['class', 'title', 'id', 'style'],
    };

    if (!this.systemAttribute) {
      this.toolbarSettings.items.push(
        'Image',
        'Bold',
        'Italic',
        'Underline',
        '|',
        'UnorderedList',
        'OrderedList',
        'Outdent',
        'Indent',
        'CreateLink',
        '|',
        'Alignments',
        'CreateTable',
        'superscript',
        'subscript',
      );
    }

    this.state = {
      isOpen: false,
      showError: false,
    };
  }

  // TODO: remove this method when all base64 inserted images have been converted
  replaceBase64Images = async (value) => {
    const base64ImageTags = value?.match(/<img\s+[^>]*src="([^"]*)base64([^"]*)"[^>]*>/g);
    let parsedValue = value;

    if (base64ImageTags?.length) {
      const newUrls = await Promise.all(
        base64ImageTags.map(async (imageTag) => {
          const base64Url = imageTag.split('src="')[1].split('"')[0];
          const file = documentationUtils.dataURLtoFile(base64Url);
          const fileData = {
            attributeName: this.meta.id,
            idSymbolFront: this.meta.idSymbolFront,
            language: this.meta.language,
            idDiagram: this.meta.idDiagram,
            version: this.meta.version,
          };
          const response = await documentationUtils.uploadImage({
            file,
            fileData,
            t: this.props.t,
          });

          return { originalValue: base64Url, newValue: response.data };
        }),
      );

      newUrls.forEach(({ originalValue, newValue }) => {
        parsedValue = parsedValue.replace(originalValue, newValue);
      });
    }

    return parsedValue;
  };

  create = async () => {
    let parsedValue = purify(this.props.value);
    this.rteObj.insertImageSettings = {
      ...this.rteObj.insertImageSettings,
      display: 'inline',
      height: '100%',
      saveFormat: 'Blob',
      width: '100%',
    };

    const expandBtn = document.getElementById(this.id);

    if (expandBtn?.onclick) {
      expandBtn.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          isOpen: !this.state.isOpen,
        });
      };
    }
    // TODO: remove this method call when all base64 inserted images have been converted
    if (!this.systemAttribute) {
      parsedValue = await this.replaceBase64Images(parsedValue);
    }

    this.rteObj.value = parsedValue;

    if (parsedValue !== this.props.value) {
      this.setValue(parsedValue);
    }
  };

  checkErrors = (value) => {
    const hasErrors = (this.props.required || this.meta.attributeType === 'M') && (value || '').trim() === '';

    if (hasErrors !== this.state.showError) {
      this.setState({ showError: hasErrors });
    }
  };

  checkUndoDeleteImage = (value) => {
    const imageTags = value?.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);
    if (!imageTags) return;
    const restoredImageIndex = this.meta.deletedImages.findIndex((imageUrl) =>
      imageTags.some((imageTag) => imageTag.includes(imageUrl)),
    );
    if (restoredImageIndex === -1) return;
    this.meta.deletedImages.splice(restoredImageIndex, 1);
  };

  handleChange = (event) => {
    this.checkUndoDeleteImage(event.value);
    this.checkErrors(event.value || '');
    this.setValue(event.value);
  };

  dialogOpen = () => {
    this.currentUploadingImage = null;
  };

  uploadImage = (args) => {
    const fileData = {
      attributeName: this.meta.id,
      idSymbolFront: this.meta.idSymbolFront,
      language: this.meta.language,
      idDiagram: this.meta.idDiagram,
      version: this.meta.version,
    };
    documentationUtils
      .uploadImage({
        file: args.filesData[0].rawFile,
        fileData,
        t: this.props.t,
      })
      .then((response) => {
        this.rteObj.imageModule.uploadUrl.url = response.data;
        this.meta.onInsertImage(response.data);
        this.currentUploadingImage = response.data;
      });
  };

  afterImageDelete = (event) => {
    if (!event.src) return;

    this.meta.onDeleteImage(event.src);
  };

  imageRemoving = () => {
    if (!this.currentUploadingImage) return;

    this.meta.onDeleteImage(this.currentUploadingImage);
    this.currentUploadingImage = null;
  };

  render() {
    const className = this.state.isOpen ? 'wysiwyg-wrapper expanded' : 'wysiwyg-wrapper';

    if (this.meta.forceError) {
      this.checkErrors(this.props.value);
    }

    return (
      <div className={className}>
        <label className={this.state.showError ? 'error' : ''} htmlFor={this.meta.id}>
          {this.meta.label}
          {this.meta.attributeType === 'M' ? '*' : ''}
        </label>
        <ErrorLabel message={`${this.meta.label} is required`} visible={this.state.showError} />
        <RichTextEditorComponent
          afterImageDelete={(event) => this.afterImageDelete(event)}
          change={(event) => this.handleChange(event)}
          className="wysiwyg"
          created={() => this.create()}
          data-qa={`text-editor-${this.meta.code}-${this.meta.language}`}
          dialogOpen={() => this.dialogOpen()}
          imageRemoving={() => this.imageRemoving()}
          imageSelected={(event) => this.uploadImage(event)}
          key={this.id}
          pasteCleanupSettings={this.pasteCleanupSettings}
          readonly={this.systemAttribute}
          ref={(rte) => {
            if (rte !== null) this.rteObj = rte;
          }}
          saveInterval={1000}
          toolbarSettings={this.toolbarSettings}
        >
          <Inject services={[HtmlEditor, Toolbar, SyncfusionImage, Link, QuickToolbar, Table, PasteCleanup]} />
        </RichTextEditorComponent>
      </div>
    );
  }
}

export default withTranslation('common')(InputWysiwygLegacy);
