import React, { useContext } from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import { AuthContext } from '../../contexts/Auth/AuthContext';
import * as Dialog from '../UI/Dialogs/Dialogs';
import styles from './UserNavigator.module.scss';

const UserNavigator = (props) => {
  const { history, t, loading = false } = props;
  const authService = useContext(AuthContext);
  const { logout } = useAuth();
  let confirmDialog;

  const userName = authService?.userName || 'PROFILE';

  const logoutConfirmData = {
    title: `${t('user.logout')} - ${userName}`,
    message: t('user.askLogout'),
    textCancel: t('cancel'),
    textOk: t('user.logout'),
    okClick: () => {
      logout();
      confirmDialog.hide();
    },
  };

  const handleNavigation = (route) => {
    // Por ahora solo navegamos a Settings o a Profile
    if ((route === '/user/settings' || route === '/user/profile') && !loading) {
      history.push(route);
    }
  };

  const askLogout = () => {
    confirmDialog = Dialog.showConfirm(logoutConfirmData);
  };

  return (
    <div className={styles.Navigator}>
      <div className={styles.NavigatorImg}>
        <div className={styles.Wrapper}>
          <div className={styles.User}>
            <div alt="profile pic" className={styles.Image}>
              <i className="di icon-person" />
            </div>
            <i className={`di icon-pfeil-chevron-unten ${styles.Chevron}`} />
          </div>
          {/* <span className={styles.Name}>Anton</span> */}
        </div>
      </div>
      <div className={loading ? styles.ItemsDisabled : styles.NavigatorItems}>
        <span className={styles.UserName}>{userName}</span>
        <span id="profile" onClick={() => handleNavigation('/user/profile')}>
          {t('user.profile')}
        </span>
        <span id="settings" onClick={() => handleNavigation('/user/settings')}>
          {t('user.settings')}
        </span>
        <span id="logout" onClick={() => askLogout()}>
          {t('user.logout')}
        </span>
      </div>
    </div>
  );
};

export default React.memo(
  withRouter(withTranslation('common')(UserNavigator)),
  (prev, next) => prev.loading === (next.loading || false),
);
