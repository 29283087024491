import React, { useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import useFeatureFlags from 'hooks/useFeatureFlags';

import styles from './Button.module.scss';

type Props = {
  btnText?: string;
  buttonStyle?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  id: string;
  isLoading?: boolean;
  success?: boolean;
  tooltip?: string;
  custom?: string;
};

const Button = ({
  children,
  handleClick,
  buttonStyle,
  btnText,
  disabled,
  isLoading,
  success,
  id,
  icon,
  tooltip,
  custom,
}: Props) => {
  let content = children || btnText;
  const [canSelect, setCanSelect] = useState(true);
  const timeoutRef = useRef<number | null>(null);
  const { isFreezed } = useFeatureFlags();

  const resetSelectionControl = (milliseconds = 500) => {
    setCanSelect(false);
    timeoutRef.current = window.setTimeout(() => {
      setCanSelect(true);
    }, milliseconds);
  };

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current as number);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (isLoading) {
    content = (
      <svg viewBox="-2000 -1000 4000 2000">
        <path d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" id="inf" />
        <use strokeDasharray="1570 5143" strokeDashoffset="6713px" xlinkHref="#inf" />
      </svg>
    );
  } else if (success) {
    content = (
      <svg className={styles.Checkmark} viewBox="0 0 52 52">
        <path className={styles.Check} d="M14.1 27.2l7.1 7.2 16.7-16.8" fill="none" />
      </svg>
    );
  }

  return tooltip ? (
    <TooltipComponent
      className={styles.Tooltip}
      content={tooltip}
      cssClass="mbc-tooltip"
      offsetY={-10}
      position="BottomCenter"
      showTipPointer={false}
    >
      <button
        className={(buttonStyle && styles[buttonStyle]) || styles.Secondary}
        data-testid="Button"
        disabled={custom === 'custom' ? false : isFreezed || disabled || isLoading || success}
        id={id}
        onClick={(e) => {
          if (canSelect) {
            resetSelectionControl();
            handleClick(e);
          }
        }}
        type="button"
      >
        {icon && !isLoading && <i className={`di ${icon} ${styles.Icon}`} />}
        {content}
      </button>
    </TooltipComponent>
  ) : (
    <button
      className={(buttonStyle && styles[buttonStyle]) || styles.Secondary}
      data-testid="Button"
      disabled={custom === 'custom' ? false : isFreezed || disabled || isLoading || success}
      id={id}
      onClick={(e) => {
        if (canSelect) {
          resetSelectionControl();
          handleClick(e);
        }
      }}
      type="button"
    >
      {icon && !isLoading && <i className={`di ${icon} ${styles.Icon}`} />}
      {content}
    </button>
  );
};

export default Button;
