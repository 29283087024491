import { useCallback } from 'react';

import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import { Tool } from 'types/diagram';

export default function useTool() {
  const { dispatch, tool, selectedTools } = useDiagramContext();

  const resetTool = useCallback(() => dispatch({ type: DiagramActionTypes.SET_TOOL, payload: Tool.NONE }), [dispatch]);

  const setTool = useCallback((newTool: Tool) => dispatch({ type: DiagramActionTypes.SET_TOOL, payload: newTool }), [dispatch]);

  const toggleTool = useCallback(
    (newTool: Tool) => (newTool === tool ? resetTool() : setTool(newTool)),
    [resetTool, setTool, tool],
  );

  const toggleMultipleTool = useCallback(
    (setMultipleTool: Tool) => {
      const attributeSelected = setMultipleTool;
      const multipleTool: Tool[] = selectedTools;
      const index = multipleTool.indexOf(attributeSelected);
      if (selectedTools.indexOf(attributeSelected) === -1) {
        multipleTool.push(attributeSelected);
      } else {
        multipleTool.splice(index, 1);
      }
      dispatch({ type: DiagramActionTypes.ADD_MULTIPLE_TOOL, payload: multipleTool });
    },
    [dispatch, selectedTools],
  );

  return {
    tool,
    resetTool,
    setTool,
    toggleTool,
    toggleMultipleTool,
  };
}
