import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import stylesNepos from './WysiwygNEPOS.module.scss';
import stylesLegacy from './WysiwygNEPOSLegacyStyle.module.scss';

interface Props {
  buttonId: string;
  disabled?: boolean;
  format: string;
  icon: string;
  legacyStyles?: boolean;
}

const MarkButton = (props: Props) => {
  const { buttonId, disabled, format, icon, legacyStyles } = props;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;
  const newEditor = useSlate();

  const isMarkActive = (editor: Editor, textFormat: string) => {
    const marks = Editor.marks(editor);
    return marks ? textFormat in marks : false;
  };

  const toggleMark = (editor: Editor, textFormat: string) => {
    const isButtonActive = isMarkActive(editor, textFormat);
    if (isButtonActive) {
      Editor.removeMark(editor, textFormat);
    } else {
      Editor.addMark(editor, textFormat, true);
    }
  };

  return (
    <button
      className={`${disabled ? styles.Disabled : ''} ${isMarkActive(newEditor, format) ? styles.Active : ''}`}
      disabled={disabled}
      id={buttonId}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(newEditor, format);
      }}
      type="button"
    >
      <i className={`di ${icon}`} />
    </button>
  );
};

export default MarkButton;
