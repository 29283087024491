import useDiagramContext from 'hooks/useDiagramContext';
import useResize from 'hooks/useResize';
import { ResizeHandles } from 'types/diagram';

import styles from './Symbol.module.scss';

type Props = {
  id: string;
};
const SymbolSelection = ({ id }: Props) => {
  const { selectedSymbolIds } = useDiagramContext();
  const { handleResize } = useResize();

  return (
    <>
      {selectedSymbolIds?.includes(id) ? (
        <>
          <svg
            className={`${styles.SelectionHandler} ${styles.Left}`}
            key={`handler-r-${id}`}
            onMouseDown={(event) => handleResize(event, ResizeHandles.CENTER_LEFT)}
            viewBox="0 0 100 100"
          />
          <svg
            className={`${styles.SelectionHandler} ${styles.Right}`}
            key={`handler-l-${id}`}
            onMouseDown={(event) => handleResize(event, ResizeHandles.CENTER_RIGHT)}
            viewBox="0 0 100 100"
          />
        </>
      ) : null}
    </>
  );
};

export default SymbolSelection;
