import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DEFAULT_PAGE_SIZE, STATUS } from 'assets/constants/constants';
import { getDangerouslySetInnerHTML } from 'assets/js/Utils';
import Checkbox from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import PagePagination from 'components/UI/Pagination/Pagination';
import { LinkedDiagram } from 'types/diagram';
import { RequirementStatus } from 'types/requirement';
import { Pagination, TableProps, TableVariant } from 'types/tables';

import styles from './Table.module.scss';
import TooltipRow from './TooltipCell/TooltipCell';

const Table = <T extends { id: string; onClick?: () => void; checked?: boolean }>(props: TableProps<T>) => {
  const {
    columns,
    getPage = () => {},
    onCheck = () => {},
    onClickIcon = () => {},
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    pagination,
    prefix = '',
    rows,
    variant = TableVariant.PLAIN,
    multiselect = true,
    totalPages = 1,
    horizontallyScrollable,
    getSorted = () => {},
    sorting,
    isLoading,
    className,
    onRowClick,
    selectedRow,
  } = props;
  const { t } = useTranslation();
  const [checkedMap, setCheckedMap] = useState<Record<string, boolean>>({});
  const [rowExpanded, setRowExpanded] = useState('');
  const isCheckedAll = useMemo(() => (!rows?.length ? false : !!rows.every(({ id }) => checkedMap[id])), [checkedMap, rows]);
  const isCheckboxOrRadio = [TableVariant.CHECKBOX, TableVariant.RADIO].includes(variant);
  const isRequirement = [TableVariant.REQUIREMENT].includes(variant);
  const colNumber = columns.length + (isCheckboxOrRadio ? 1 : 0) + (isRequirement ? 2 : 0);
  const showPagination = pagination === Pagination.SHOW_MORE && rows && (page + 1) * pageSize < rows.length;
  const NEW = t('NEW');
  const REQUESTED = t('REQUESTED');
  const { DRAFT, PENDING, DECLINED, DONE, ACCEPTED, RETRIEVED, CLOSED } = RequirementStatus;
  const handleIsRowSelected = (rowId: string) => (selectedRow?.length !== 0 ? selectedRow === rowId : false);

  useEffect(() => {
    setCheckedMap(
      variant === (TableVariant.CHECKBOX || TableVariant.REQUIREMENT) && rows
        ? Object.fromEntries(rows.map(({ id, checked }) => [id, !!checked]))
        : {},
    );
  }, [rows, variant]);

  const getSortedClass = (fieldId: string) => (sorting?.filter === fieldId ? styles[sorting?.order] : '');

  const statusLabelColor = (status: string) => {
    const Color: { [key: string]: string } = {
      [NEW]: styles.New,
      [REQUESTED]: styles.Requested,
      [DRAFT]: styles.Draft,
      [PENDING]: styles.Pending,
      [DECLINED]: styles.Declined,
      [DONE]: styles.Done,
      [ACCEPTED]: styles.Accepted,
      [RETRIEVED]: styles.Retrieved,
      [CLOSED]: styles.Closed,
    };

    return Color[status] || '';
  };

  const handleCheckAll = () => {
    if (!rows) return;
    setCheckedMap(Object.fromEntries(rows.map(({ id }) => [id, !isCheckedAll])));
  };

  const handleExpandButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, cellId: string) => {
    setRowExpanded(rowExpanded ? '' : cellId);
    event.stopPropagation();
  };

  useEffect(() => {
    const checkedList = Object.entries(checkedMap)
      .filter(([, isChecked]) => isChecked)
      .map(([id]) => id);
    onCheck(checkedList);
  }, [checkedMap, onCheck]);

  const Content = () => {
    if (isLoading)
      return (
        <tr>
          <td className={styles.NoResults} colSpan={colNumber}>
            ...
          </td>
        </tr>
      );
    return rows?.length === 0 ? (
      <tr>
        <td className={styles.NoResults} colSpan={colNumber}>
          {t('noResults')}
        </td>
      </tr>
    ) : (
      <>
        {rows?.length &&
          (pagination !== Pagination.SHOW_MORE ? rows.slice(0, (page + 1) * pageSize) : rows).map(
            (row: T & { containedIn?: LinkedDiagram[]; changes?: any[] }) => (
              <tr
                className={`${checkedMap[row.id] || handleIsRowSelected(row.id) ? styles.Checked : ''} ${
                  row.onClick || onRowClick ? styles.Clickable : ''
                }`}
                key={row.id}
                onClick={(e) => {
                  if (row.onClick) {
                    const link = document.getElementById(row.id);
                    const target = e.target as HTMLElement;
                    if (target !== link && !link?.contains(target)) {
                      row.onClick();
                    }
                  }
                }}
                onMouseDown={() => {
                  if (onRowClick) onRowClick(row.id);
                }}
              >
                {(variant === TableVariant.CHECKBOX || variant === TableVariant.REQUIREMENT) && (
                  <td className={styles.CheckboxColumn}>
                    <Checkbox
                      checked={checkedMap[row.id]}
                      handleCheck={() =>
                        multiselect
                          ? setCheckedMap({ ...checkedMap, [row.id]: !checkedMap[row.id] })
                          : setCheckedMap({ [row.id]: !checkedMap[row.id] })
                      }
                      id={row.id}
                    />
                  </td>
                )}
                {columns.map((column) => {
                  return (
                    <td className={column.style || ''} key={`${row.id}-${column.id}`}>
                      {column.id === 'containedIn' && row.containedIn && row.containedIn.length > 0 ? (
                        <>
                          <div className={styles.UsedIn}>
                            {`${row.containedIn.length} ${t('nameAttributes.tables.diagrams')}`}
                            <button
                              className={styles.ExpandButton}
                              onClick={(event) => handleExpandButtonClick(event, `${row.id}-${column.id}`)}
                              type="button"
                            >
                              {rowExpanded ? t('hide') : t('show')}
                            </button>
                          </div>
                          {column.id === 'containedIn' && rowExpanded === `${row.id}-${column.id}` && (
                            <div className={styles.Linklist}>
                              {row.containedIn?.map((diagram) => (
                                <a
                                  className={styles.Link}
                                  href={diagram.status === STATUS.SANDBOX ? `/sandbox/${diagram.id}` : `/diagram/${diagram.id}`}
                                  onClick={(event) => event.stopPropagation()}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <i className="di icon-link" />
                                  <TooltipRow element={diagram.nameMap} value={diagram.nameMap} />
                                </a>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {(prefix === 'role.' ||
                            prefix === 'inputOutput.' ||
                            prefix === 'itSystem.' ||
                            prefix === 'requirements.') &&
                            column.id === 'status' &&
                            (row[column.id] ? (
                              <div className={statusLabelColor(row[column.id] as unknown as string)}>
                                <>{prefix === 'requirements.' ? t(`table.status.${row[column.id]}`) : row[column.id]}</>
                              </div>
                            ) : (
                              ''
                            ))}
                          {prefix === 'changelog.' && row.changes && column.id === 'changes' ? (
                            <div className={styles.InnerListTriggerer}>
                              {`${row.changes.length} ${row.changes.length === 1 ? t('change') : t('changes')}`}
                              <button
                                className={styles.ExpandButton}
                                onClick={(event) => handleExpandButtonClick(event, `${row.id}-${column.id}`)}
                                type="button"
                              >
                                {rowExpanded === `${row.id}-${column.id}` ? t('hide') : t('show')}
                              </button>
                            </div>
                          ) : (
                            ((prefix !== 'role.' &&
                              prefix !== 'inputOutput.' &&
                              prefix !== 'itSystem.' &&
                              prefix !== 'requirements.' &&
                              prefix !== 'financial-evaluation.') ||
                              (column.id !== 'status' && prefix !== 'requirements.' && prefix !== 'financial-evaluation.')) && (
                              <TooltipRow element={row[column.id]} value={row[column.id]} />
                            )
                          )}
                          {prefix === 'changelog.' && column.id === 'changes' && rowExpanded === `${row.id}-${column.id}` && (
                            <div className={styles.InnerList}>
                              {row.changes?.map((elemValue) => (
                                <>
                                  <div className={styles.InnerListRow}>
                                    <div>
                                      · {elemValue.remark}:
                                      <span className={styles.InnerListItem}>
                                        {t('Previous value')}:
                                        <TooltipRow element={elemValue.previousValue} value={elemValue.previousValue} />
                                      </span>
                                      <span className={styles.InnerListItem}>
                                        {t('Updated value')}:
                                        <TooltipRow element={elemValue.updatedValue} value={elemValue.updatedValue} />
                                      </span>
                                    </div>
                                    {elemValue.solutionApproach && (
                                      <>
                                        · {t('solutionApproach')}:
                                        <div>
                                          <TooltipRow
                                            element={elemValue.solutionApproachName}
                                            value={elemValue.solutionApproachName}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              ))}
                            </div>
                          )}
                          {prefix === 'requirements.' && column.id === 'supportNeeded' && row[column.id] && (
                            <i className={`di icon-person-sprechblase-abstimmung ${styles.SupportNeededIconColor}`} />
                          )}
                          {prefix === 'requirements.' &&
                            (column.id === 'description' || column.id === 'example' || column.id === 'solutionDescription') && (
                              <TooltipRow
                                element={row[column.id]}
                                value={
                                  <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={getDangerouslySetInnerHTML(row[column.id])}
                                  />
                                }
                              />
                            )}
                          {prefix === 'requirements.' &&
                            column.id !== 'description' &&
                            column.id !== 'example' &&
                            column.id !== 'solutionDescription' &&
                            column.id !== 'status' && <TooltipRow element={row[column.id]} value={row[column.id]} />}
                        </>
                      )}
                    </td>
                  );
                })}
                {variant === TableVariant.DOCUMENT && (
                  <td onClick={() => onClickIcon(row)}>
                    <div className={styles.DownloadIconContainer}>
                      <i className="di icon-datei-pdf" />
                    </div>
                  </td>
                )}
              </tr>
            ),
          )}
      </>
    );
  };

  return (
    <div className={`${styles.TableWrapper} ${colNumber === columns.length ? styles.Plain : ''} ${className || ''}`}>
      <table
        className={`${styles.Table} ${horizontallyScrollable ? styles.HorizontallyScrollable : ''} ${
          horizontallyScrollable ? styles.LeftPadding : ''
        }  ${prefix === 'requirements.' || prefix === 'predecessor.' ? styles.AdjustRowsHeight : ''}`}
      >
        <thead>
          <tr>
            {(variant === TableVariant.CHECKBOX || variant === TableVariant.REQUIREMENT) && (
              <td className={styles.CheckboxColumn}>
                {multiselect ? (
                  <Checkbox checked={isCheckedAll} handleCheck={handleCheckAll} id="check-all" />
                ) : (
                  <div className={styles.EmptyHeader} />
                )}
              </td>
            )}
            {columns.map(({ id, style = '', isSortable }) => (
              <th className={style} key={id}>
                {id === 'potentialAnnualEbit' && (
                  <span className={styles.HeaderTitle}>
                    {t('requirements.savingPotential')}
                    <br />
                  </span>
                )}
                {!columns.find((column) => column.id === 'potentialAnnualEbit') && id === 'potentialOneTimeCashflow' && (
                  <span className={styles.HeaderTitle}>
                    {t('requirements.savingPotential')}
                    <br />
                  </span>
                )}
                {columns.find((column) => column.id === 'potentialAnnualEbit') && id === 'potentialOneTimeCashflow' && (
                  <span className={styles.HeaderTitle}>
                    <br />
                  </span>
                )}
                {id === 'requiredInvest' && variant === TableVariant.REQUIREMENT && (
                  <span className={styles.HeaderTitle}>
                    {t('requirements.financialEvaluation')}
                    <br />
                  </span>
                )}
                {!columns.find((column) => column.id === 'requiredInvest') && id === 'evaluationAnnualEbit' && (
                  <span className={styles.HeaderTitle}>
                    {t('requirements.financialEvaluation')}
                    <br />
                  </span>
                )}
                {!columns.find((column) => column.id === 'requiredInvest') && id === 'evaluationOneTimeCashflow' && (
                  <span className={styles.HeaderTitle}>
                    <br />
                  </span>
                )}
                {!columns.find((column) => column.id === 'requiredInvest') &&
                  !columns.find((column) => column.id === 'evaluationAnnualEbit') &&
                  id === 'evaluationOneTimeCashflow' && (
                    <span className={styles.HeaderTitle}>
                      {t('requirements.financialEvaluation')}
                      <br />
                    </span>
                  )}
                {columns.find((column) => column.id === 'requiredInvest') &&
                  (id === 'evaluationAnnualEbit' || id === 'evaluationOneTimeCashflow') && (
                    <span>
                      <br />
                    </span>
                  )}
                {id !== 'supportNeeded' && (
                  <span
                    className={`${isSortable ? styles.IsSortable : ''} ${isSortable ? getSortedClass(id) : ''}`}
                    onClick={() => isSortable && getSorted(id)}
                  >
                    {t([`${prefix}${id}`, id])}
                  </span>
                )}
              </th>
            ))}
            {variant === TableVariant.DOCUMENT && <th /> /* eslint-disable-line jsx-a11y/control-has-associated-label */}
          </tr>
        </thead>
        <tbody
          className={(pagination === Pagination.PAGES && totalPages > 1) || showPagination ? styles[pagination as string] : ''}
        >
          <Content />
        </tbody>
        {showPagination && (
          <tfoot onClick={() => getPage(page + 1)}>
            <tr>
              <td colSpan={colNumber}>{t('showMoreResults')}</td>
            </tr>
          </tfoot>
        )}
      </table>
      {pagination === Pagination.PAGES && totalPages > 1 && (
        <div className={styles.Pagination}>
          <PagePagination page={page + 1} pageClick={(newPage: number) => getPage(newPage - 1)} totalPages={totalPages} />
        </div>
      )}
    </div>
  );
};

export default Table;
