import { useMemo } from 'react';

import { Attributes, FormTypes } from 'types/forms';
import { ProcessDataCatalog } from 'types/processes';

import useForm from './useForm';
import useFormTypes from './useFormTypes';

type Props = {
  formCode: string;
  initialValues?: Attributes;
  catalog?: ProcessDataCatalog;
  id?: string;
  isLegacy?: boolean;
  usesDiagramTranslation?: boolean;
  isTouchedNeeded?: boolean;
};

export default function useBackendForm({
  formCode,
  initialValues,
  catalog,
  id,
  isLegacy,
  usesDiagramTranslation = false,
  isTouchedNeeded,
}: Props) {
  const formTypesContext = useFormTypes();
  const formTypes: FormTypes = useMemo(() => formTypesContext[formCode] || {}, [formCode, formTypesContext]);
  const form = useForm({ formTypes, initialValues, catalog, formCode, id, isLegacy, usesDiagramTranslation, isTouchedNeeded });
  return form;
}
