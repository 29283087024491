import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AREAS,
  BUTTON_SECONDARY,
  DIAGRAM_ENVIRONMENTS,
  NOT_TRANSLATABLE,
  PROCESS_NAME,
  SUCCESS_NEPOS,
  SWIMLANE_DIAGRAM,
  EXPORT_XML,
  IMPORT_XML,
} from 'assets/constants/constants';
import { downloadFile, getFullFormattedDate, removeSquareBracketsFromString } from 'assets/js/Utils';
import BreadcrumbsNEPOS from 'components/BreadcrumbsNEPOS/BreadcrumbsNEPOS';
import ExportImportDialog from 'components/ExportImportDialog/ExportImportDialog';
import SaveButton from 'components/SaveButton/SaveButton';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import DeleteDialog from 'components/UI/DeleteDialog/DeleteDialog';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import IconButton from 'components/UI/IconButton/IconButton';
import IconButtonDropdown from 'components/UI/IconButtonDropdown/IconButtonDropdown';
import Table from 'components/UI/Table/Table';
import useToastContext from 'components/UI/ToastContext/useToastContext';
import {
  DiagramActionTypes,
  prepareTakingPictures,
  setBreadcrumbs,
  setCurrentLevel,
  setIsAutoSaving,
  setIsTakingPicturesOnTheFly,
} from 'contexts/Diagram/DiagramContext';
import useDiagramContext from 'hooks/useDiagramContext';
import useDiagramPicture from 'hooks/useDiagramPicture';
import useEnvironment from 'hooks/useEnvironment';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useInterval from 'hooks/useInterval';
import useLocalization from 'hooks/useLocalization';
import useProcess from 'hooks/useProcess';
import useValidation from 'hooks/useValidation';
import { genDocOnTheFly, getProcessLevels } from 'services/design';
import { genDocsOnPublished } from 'services/documentation';
import documentationService from 'services/documentationService';
import hasSwimlaneActivity from 'services/swimlaneService';
import userService from 'services/userService';
import { Language } from 'types/config';
import { PictureAction, Tool } from 'types/diagram';
import { DeleteDialogPrefixes, DialogType } from 'types/dialogs';
import { AttributeValue, FormLanguage } from 'types/forms';
import { ProcessStatus, ProcessTabs, Levels, ProcessHistoryDocuments } from 'types/processes';
import { TableColumn, TableVariant } from 'types/tables';

import styles from './NEPOSHeader.module.scss';

type NEPOSHeaderProps = {
  selectedTab: ProcessTabs | null;
  handleSelectTab?: Dispatch<SetStateAction<ProcessTabs | null>>;
};

interface ProcessResponsibleData {
  name: string;
  department: string;
  linkTeams: string;
  code: string;
  processResponsiblePicture: string;
}

const NEPOSHeader = (props: NEPOSHeaderProps) => {
  const { selectedTab, handleSelectTab = () => null } = props;
  const { id } = useParams<{ id: string }>();
  const { i18n, t } = useTranslation();
  const nameRef = useRef<HTMLSpanElement>(null);
  const { handleServiceError, showAlert } = useError();
  const { breadcrumbs, diagramLanguage, dispatch, processData, isTakingPictures, isAutoSaveOn, isAutoSaving } =
    useDiagramContext();
  const { deleteProcess, filterProcessInterfaces, saveProcess } = useProcess(id);
  const [showDeleteDiagramDialog, setShowDeleteDiagramDialog] = useState(false);
  const [showHistoryDiagramDialog, setShowHistoryDiagramDialog] = useState(false);
  const [availableHistoryItems, setAvailableHistoryItems] = useState<any[]>();
  const [areItemsLoading, setAreItemsLoading] = useState(true);
  const [processResponsibles, setProcessResponsibles] = useState<ProcessResponsibleData[]>();
  const [currentDiagram, setCurrentDiagram] = useState<Levels>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(processData?.favorite);
  const [exportOrImport, setExportOrImport] = useState('');
  const fileNameRef = useRef<string>('');
  const { availableLanguages } = useLocalization();
  const { diagramLanguageErrors, hasDiagramErrors } = useValidation();
  const { makeDiagramPicture } = useDiagramPicture(false);
  const checkEnvironment = useEnvironment();
  const { isProcessResponsiblePictureEnabled, isDisplayVideoEnabled, isFreezed } = useFeatureFlags();
  const { setToggleAutoSave } = useInterval();
  const processResponsibleAttribute = processData?.attributes[FormLanguage.NOT_TRANSLATABLE]?.PROCESS_RESPONSIBLE;

  const errors =
    diagramLanguageErrors &&
    diagramLanguageErrors?.filter((lang) => lang === diagramLanguage || (lang as string) === NOT_TRANSLATABLE);

  const languageErrors = diagramLanguageErrors && diagramLanguageErrors.filter((lang: string) => lang !== NOT_TRANSLATABLE);
  const otherLanguagesHaveErrors = languageErrors && languageErrors?.filter((lang) => lang !== diagramLanguage).length > 0;

  const langOptions = availableLanguages.map((langCode: string) => ({
    value: langCode,
    label: t(langCode),
    error: languageErrors?.includes(langCode as Language),
  }));
  const addToast = useToastContext();

  const isVideoTabDisabled = !processData?.video && processData?.isOnlyRead;
  const NEPOSTabs = [
    { tabId: ProcessTabs.PROCESS, icon: 'icon-information', error: (hasDiagramErrors && errors && errors.length > 0) || false },
    { tabId: ProcessTabs.KPIS, icon: 'icon-computer-diagramm', notifications: processData?.kpis?.length },
    { tabId: ProcessTabs.INTERFACES, icon: 'icon-link', notifications: filterProcessInterfaces()?.length },
    { tabId: ProcessTabs.VIDEO, icon: 'icon-medien-video', disabled: !isDisplayVideoEnabled || isVideoTabDisabled },
  ];

  const historyColumns: TableColumn<ProcessHistoryDocuments>[] = [
    { id: 'type', style: styles.Title },
    { id: 'author', style: styles.Responsible },
    { id: 'version', style: styles.Title },
    { id: 'name', style: styles.Responsible },
    { id: 'validFrom', style: styles.CreationDate },
    { id: 'validTo', style: styles.CreationDate },
  ];

  const handleConfirmDelete = useCallback(
    async (isSwimlane: boolean, confirmationValue?: string) => {
      setShowDeleteDiagramDialog(false);
      if (!confirmationValue) return;

      if (isSwimlane) {
        const hasActivity = processData?.swimlanes.some(hasSwimlaneActivity);

        if (hasActivity) return showAlert(t('errors.DESIGN_042'));
      }

      const parentId = processData?.parentId || 1;
      await deleteProcess(confirmationValue, parentId, processData?.status === ProcessStatus.SANDBOX);
    },
    [deleteProcess, processData?.parentId, processData?.status], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleLanguage = (lang: string) => {
    if (!lang) return;
    dispatch({
      type: DiagramActionTypes.UPDATE_DIAGRAM_LANGUAGE,
      payload: lang as Language,
    });
  };

  const downloadDoc = useCallback(
    (res: any) => {
      const binaryData = [res.data];
      const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
      const fileName = `${processData?.attributes[diagramLanguage]?.PROCESS_NAME}.pdf`;
      downloadFile(url, fileName, res.headers['content-disposition']);
    },
    [diagramLanguage, processData?.attributes],
  );

  const genDocsWhenNotPublished = useCallback(async () => {
    const data = {
      idDiagram: id,
      images: fileNameRef.current === 'pdf' ? [await makeDiagramPicture()] : [],
    };

    genDocOnTheFly(fileNameRef.current, data, diagramLanguage)
      .then(downloadDoc)
      .catch(handleServiceError)
      .finally(() => dispatch(setIsTakingPicturesOnTheFly(false)));
  }, [diagramLanguage, dispatch, handleServiceError, id, makeDiagramPicture, downloadDoc]);

  const downloadDocsWhenPublished = useCallback(
    async (version: string = 'last') => {
      const processNumber = processData && processData?.attributes.NOT_TRANSLATABLE?.PROCESS_NUMBER;
      genDocsOnPublished(processNumber as string, version, fileNameRef.current, diagramLanguage)
        .then((res) => downloadDoc(res))
        .catch(handleServiceError);
    },
    [diagramLanguage, handleServiceError, downloadDoc, processData],
  );

  const prepareGenDocs = async (fileName: string) => {
    dispatch({
      type: DiagramActionTypes.ADD_MULTIPLE_TOOL,
      payload: [Tool.DISPLAY_ROLES, Tool.DISPLAY_IT_SYSTEMS, Tool.DISPLAY_INPUTS, Tool.DISPLAY_OUTPUTS],
    });
    fileNameRef.current = fileName;
    if (!checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED)) {
      handleSelectTab(null);
      dispatch(prepareTakingPictures(PictureAction.onTheFly, false));
    } else {
      downloadDocsWhenPublished();
    }
    dispatch({ type: DiagramActionTypes.ADD_MULTIPLE_TOOL, payload: [] });
  };

  const reportAction = (fileName: string) => {
    if (fileName === IMPORT_XML || fileName === EXPORT_XML) setExportOrImport(fileName);
    if (fileName === 'pdf') prepareGenDocs(fileName);
  };

  useEffect(() => {
    if (!processData) return;
    const environment = checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) ? AREAS.PUBLISHED : AREAS.MODELING;
    getProcessLevels(id, environment).then((response) => {
      dispatch(setBreadcrumbs(response.data));
      const currentElement = response.data.find((element: { id: string }) => String(element.id) === id);
      if (!currentElement) return;
      setCurrentDiagram(currentElement);
      dispatch(setCurrentLevel(response.data.indexOf(currentElement) + 1));
      setIsLoading(false);
    });
  }, [processData?.id, processData?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsFavorite(processData?.favorite);
  }, [processData?.favorite]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
  }, [id, processData?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isTakingPictures.onTheFly) return;
    genDocsWhenNotPublished();
  }, [isTakingPictures.onTheFly, genDocsWhenNotPublished]);

  useEffect(() => {
    if (!processData?.catalog?.USER || !processResponsibleAttribute) return;

    const codes = processResponsibleAttribute as string[];

    const processResponsibleData = removeSquareBracketsFromString(codes).map((code) =>
      processData?.catalog?.USER.find((user) => user.code === code),
    );

    const users = removeSquareBracketsFromString(codes).toString();
    if (!users) return;

    const responsibles: ProcessResponsibleData[] = [];

    processResponsibleData.forEach(async (responsible) => {
      const userImage = userService
        .getUsersImages(responsible.code)
        .then((res) => (res.data.images[0]?.image ? `data:image/pm;base64,${res.data.images[0].image}` : ''));

      responsibles.push({
        name: responsible?.commonName || '-',
        department: responsible?.departments ? responsible.departments[0] : responsible?.department || '-',
        linkTeams: responsible?.linkTeams ? responsible?.linkTeams : '',
        code: responsible.code || '',
        processResponsiblePicture: isProcessResponsiblePictureEnabled ? await userImage : '',
      } as ProcessResponsibleData);
    });

    setProcessResponsibles(responsibles);
  }, [isProcessResponsiblePictureEnabled, processResponsibleAttribute, processData?.catalog?.USER]); // eslint-disable-line react-hooks/exhaustive-deps

  const nameNeedsTooltip =
    !nameRef.current?.firstChild ||
    (nameRef.current.firstChild as HTMLDivElement).scrollWidth > (nameRef.current.firstChild as HTMLDivElement).clientWidth;

  const historyDialog = {
    title: t('history.title'),
    type: DialogType.None,
    buttons: [
      {
        id: 'NEPOSHeader-history-dialog-close',
        handleClick: () => setShowHistoryDiagramDialog(false),
        content: t('close'),
        buttonStyle: BUTTON_SECONDARY,
      },
    ],
  };

  const getHistoryVersions = () => {
    const processNumber = processData?.attributes.NOT_TRANSLATABLE?.PROCESS_NUMBER;
    if (!processNumber) return;

    documentationService
      .getVersionsControl(processData?.attributes.NOT_TRANSLATABLE?.PROCESS_NUMBER)
      .then((res) => {
        const {
          versions,
        }: {
          versions: [
            {
              author: { code: string; commonName: string };
              diagramNames: AttributeValue;
              processType: string;
              reasonOfChange: AttributeValue;
              validFrom: string;
              validTo: string;
              version: string;
            },
          ];
        } = res.data;
        if (!versions) return;
        const newVersions = versions.map((version) => {
          const versionType = version.processType === 'HYBRID' ? SWIMLANE_DIAGRAM : version.processType;
          return {
            type: t(`${versionType}`),
            author: version.author.commonName,
            name: processData?.attributes[i18n.language as Language]?.[PROCESS_NAME],
            version: version.version,
            validTo: getFullFormattedDate(version.validTo),
            validFrom: getFullFormattedDate(version.validFrom),
          };
        });
        setAvailableHistoryItems(newVersions);
        setAreItemsLoading(false);
      })
      .catch((err) => handleServiceError(err));
  };

  const historyRows = availableHistoryItems || [];

  const toggleFavorites = () => {
    const processNumber = processData && processData?.attributes.NOT_TRANSLATABLE?.PROCESS_NUMBER;
    setIsFavorite(!isFavorite);

    if (!isFavorite) {
      userService.addToFavorites(processNumber).catch(handleServiceError);
      addToast(t('addFavorites'), SUCCESS_NEPOS);
    } else {
      userService.removeFavorite(processNumber).catch(handleServiceError);
      addToast(t('removeFavorites'), SUCCESS_NEPOS);
    }
  };

  useEffect(() => {
    if (processData && isAutoSaving) {
      saveProcess(processData);
      dispatch(setIsAutoSaving(false));
    }
  }, [isAutoSaving]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <header>
        <div className={styles.Row}>
          <div className={styles.Info}>
            {checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) && (
              <IconButton
                dataQA="add-to-favorites-button"
                icon={isFavorite ? 'icon-stern-favorit' : 'icon-stern-favorit-konturlinie'}
                noHover
                onClick={() => toggleFavorites()}
              />
            )}
            <span className={styles.DiagramName} ref={nameRef}>
              <TooltipComponent
                content={processData?.attributes[diagramLanguage]?.PROCESS_NAME || ''}
                cssClass={`mbc-tooltip nepos-tooltip ${nameNeedsTooltip ? '-' : 'display-none'}`}
                position="BottomCenter"
                showTipPointer={false}
              >
                <span className={styles.ProcessName}>{processData?.attributes[diagramLanguage]?.PROCESS_NAME}</span>
              </TooltipComponent>
            </span>
            <span className={styles.ProcessLevel}>
              {!isLoading &&
                (currentDiagram && breadcrumbs.indexOf(currentDiagram) < 3
                  ? ` ${t('level')} ${breadcrumbs.indexOf(currentDiagram) + 1}`
                  : ` ${t('activity')}`)}
            </span>
            {processResponsibles && (
              <div className={styles.Responsible}>
                {processResponsibles.map((processResponsible) => {
                  return (
                    <div key={processResponsible.name} style={{ display: 'flex' }}>
                      {processResponsible.processResponsiblePicture ? (
                        <section style={{ display: 'flex' }}>
                          <a href={processResponsible.linkTeams} rel="noopener noreferrer" target="_blank">
                            <img
                              alt="process resposible"
                              className={styles.UserImage}
                              src={processResponsible.processResponsiblePicture as string}
                              style={processResponsible?.linkTeams ? { cursor: 'pointer' } : {}}
                            />
                          </a>
                          <a href={processResponsible.linkTeams} rel="noopener noreferrer" target="_blank">
                            <span className={styles.Name}>{processResponsible.name}</span>
                          </a>
                        </section>
                      ) : (
                        <>
                          {processResponsible?.linkTeams ? (
                            <section style={{ display: 'flex' }}>
                              <a href={processResponsible.linkTeams} rel="noopener noreferrer" target="_blank">
                                <span className={styles.UserImage}>
                                  <i className="di icon-person" />
                                </span>
                              </a>
                              <a href={processResponsible.linkTeams} rel="noopener noreferrer" target="_blank">
                                <span className={styles.Name}>{processResponsible.name}</span>
                              </a>
                            </section>
                          ) : (
                            <section style={{ display: 'flex' }}>
                              <div>
                                <span className={styles.UserImage}>
                                  <i className="di icon-person" />
                                </span>
                              </div>
                              <div>
                                <span className={styles.Name}>{processResponsible.name}</span>
                              </div>
                            </section>
                          )}
                        </>
                      )}
                      <span>{processResponsible.department}</span>
                      {processResponsible.name !== '-' && (
                        <section>
                          <span className={styles.ProcessResponsible}>· {t('processResponsible')}</span>
                        </section>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.Actions}>
            {checkEnvironment(DIAGRAM_ENVIRONMENTS.PUBLISHED) && (
              <IconButton
                dataQA="history-button"
                icon="di icon-historie-verlauf"
                onClick={() => {
                  getHistoryVersions();
                  setShowHistoryDiagramDialog(true);
                }}
              >
                {t('history.title')}
              </IconButton>
            )}
            <IconButtonDropdown
              icon="di icon-datei-zip"
              id="reports-dropdown"
              onChange={reportAction}
              options={
                !processData?.isOnlyRead
                  ? [
                      { value: 'pdf', label: t('report.menu.EXPORT_PDF') },
                      { value: EXPORT_XML, label: t('language.menu.EXPORT_XML') },
                      { value: IMPORT_XML, label: t('language.menu.IMPORT_XML') },
                    ]
                  : [{ value: 'pdf', label: t('report.menu.EXPORT_PDF') }]
              }
              title={t('reports.title')}
            />
            <IconButtonDropdown
              error={otherLanguagesHaveErrors}
              icon="di icon-weltkugel-global"
              id="language-dropdown"
              onChange={handleLanguage}
              options={langOptions}
              value={diagramLanguage}
            />
            {(processData?.status === ProcessStatus.NEW || processData?.status === ProcessStatus.SANDBOX) &&
              !processData?.isOnlyRead && (
                <ButtonNEPOS
                  disabled={isFreezed}
                  handleClick={() => setShowDeleteDiagramDialog(true)}
                  icon="di icon-muelleimer-loeschen"
                  id="remove-process-button"
                >
                  {t('delete')}
                </ButtonNEPOS>
              )}
            {!processData?.isOnlyRead && <SaveButton />}
            {!isFreezed && (
              <ButtonNEPOS handleClick={setToggleAutoSave} id="autosave-process-button">
                {isAutoSaveOn ? t('autoSave-on') : t('autoSave-off')}
              </ButtonNEPOS>
            )}
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.Tabs}>
            {NEPOSTabs.map(({ tabId, ...otherProps }) => (
              <IconButton
                dataQA={`NEPOSHeader-tabs-${tabId}`}
                key={tabId}
                onClick={() => handleSelectTab(selectedTab === tabId || otherProps?.disabled ? null : tabId)}
                selected={selectedTab === tabId}
                {...otherProps}
              >
                {t(tabId)}
              </IconButton>
            ))}
          </div>
          <BreadcrumbsNEPOS
            currentDiagram={currentDiagram}
            isLoading={isLoading}
            language={diagramLanguage}
            levels={breadcrumbs}
            processData={processData}
          />
        </div>
      </header>
      {showDeleteDiagramDialog && (
        <DeleteDialog
          handleClick={(confirmationValue) => handleConfirmDelete(processData?.isSwimlane || false, confirmationValue)}
          objectName={processData?.attributes[i18n.language as Language]?.PROCESS_NAME as string}
          prefix={processData?.isSwimlane ? DeleteDialogPrefixes.SWIMLANE : DeleteDialogPrefixes.SIPOC}
        />
      )}
      {showHistoryDiagramDialog && (
        <DialogNEPOS dialog={historyDialog} extraClass="HistoryDialog">
          <Table<ProcessHistoryDocuments>
            columns={historyColumns}
            isLoading={areItemsLoading}
            onClickIcon={(versionRow) => {
              const { version } = versionRow;
              fileNameRef.current = 'pdf';
              downloadDocsWhenPublished(version);
            }}
            prefix="versionsHistory.table."
            rows={historyRows}
            variant={TableVariant.DOCUMENT}
          />
        </DialogNEPOS>
      )}
      {exportOrImport && (
        <ExportImportDialog closeDialog={() => setExportOrImport('')} isExportDialog={exportOrImport === EXPORT_XML} />
      )}
    </>
  );
};

export default NEPOSHeader;
