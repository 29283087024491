import { useTranslation } from 'react-i18next';

import * as Constants from '../../assets/constants/constants';
import styles from './TransferHistory.module.scss';

const TransferHistoryNode = (props) => {
  const {
    role,
    user: { givenName, surname },
  } = props;

  return (
    <div className={styles.Node}>
      <div className={styles.Dot} />
      <span className={styles.Role}>{role}</span>
      <span className={styles.Name}>{`${surname}, ${givenName}`}</span>
    </div>
  );
};

const TransferHistory = (props) => {
  const { list = [] } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <span>{t('transferHistory.title')}</span>
      <div className={styles.Wrapper}>
        {list.length > 0 && (
          <div className={styles.Step} key={list[0].userCode}>
            <TransferHistoryNode role={Constants.AUTHOR} user={list[0]} />
          </div>
        )}
        {list.length > 1 &&
          list.slice(1).map((user) => (
            <div className={styles.Step} key={user.userCode}>
              <div className={styles.Vertex} />
              <TransferHistoryNode role={Constants.AUTHOR} user={user} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TransferHistory;
