import { withTranslation } from 'react-i18next';

import * as Utils from 'assets/js/Utils';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { WorkflowStatus } from 'types/workflow';

import Icon from '../Icon/Icon';
import Spinner from '../Spinner/Spinner';
import styles from './Card.module.scss';

const Card = (props) => {
  const { loading, state, status, content, card, title = card.title, cardType, handleClick, t, id, iconClick } = props;
  const { isFreezed } = useFeatureFlags();
  const iconsData = {
    fav: (
      <Icon
        iconClass={`${styles.Icon} di icon-stern-favorit`}
        id="favorite"
        offsetY={-10}
        onClick={iconClick}
        tooltip={t('removeFavorite')}
      />
    ),
    /* TODO: Uncomment if needed
      processChange: (
        <>
          <Icon id="attach" iconClass={`${styles.IconsBar} di icon-bueroklammer-anhang`} tooltip={t('tool.attach')} offsetY={-10} />
          <Icon id="close" iconClass={`${styles.IconsBar} fas fa-times`} tooltip={t('tool.close')} offsetY={-10} />
        </>
      ),
      task: (
        <>
          <Icon id="attach" iconClass={`${styles.IconsBar} di icon-bueroklammer-anhang`} extraClass="card-tooltip" tooltip={t('tool.attach')} offsetY={-10} />
          <Icon id="transfer" iconClass={`${styles.IconsBar} di icon-uebertragen`} extraClass="card-tooltip" tooltip={t('tool.transfer')} offsetY={-10} />
          <Icon id="backToGroup" iconClass={`${styles.IconsBar} di icon-zurueck-an-die-gruppe`} extraClass="card-tooltip" tooltip={t('tool.backToGroup')} offsetY={-10} />
        </>
      ),
      recommendedTask: (
        <>
          <Icon id="attach" iconClass={`${styles.IconsBar} di icon-bueroklammer-anhang`} extraClass="card-tooltip" tooltip={t('tool.attach')} offsetY={-10} />
          <Icon id="delegate" iconClass={`${styles.IconsBar} di icon-verleihung`} extraClass="card-tooltip" tooltip={t('delegate')} offsetY={-10} />
        </>
      ),
      approvalCard: (
        <Icon id="attach" iconClass={`${styles.IconsBar} di icon-bueroklammer-anhang`} tooltip={t('tool.attach')} offsetY={-10} />
      ),
    }; */
  };

  let icons = '';

  if (cardType) {
    icons = iconsData[cardType] || '';
  }

  const isClosedWF = [WorkflowStatus.REJECTED, WorkflowStatus.COMPLETED, WorkflowStatus.ESCALATED].includes(card?.workflowStatus);
  const loadingClass = loading ? styles.Loading : '';
  const statusClass = cardType === 'task' ? styles[isClosedWF ? 'CLOSED_WORKFLOW' : card.status] : '';
  const recommendationType = cardType === 'recommendedTask' ? styles[card.type] : '';
  const disability = isFreezed ? styles[WorkflowStatus.BLOCKED] : '';

  const onClick = () => {
    if (loading) return;
    handleClick(card);
  };

  const contentBlock = content || (
    <div className={styles.Content}>
      <section>
        <span className={styles.Label}>{t('nameAttributes.tables.CREATED')}</span>
        <span>{Utils.getFormattedDate(card.creationDate)}</span>
      </section>
    </div>
  );

  return (
    <div className={`${styles.Link} ${statusClass} ${recommendationType} ${disability}`} id={id} onClick={onClick}>
      <div className={`${styles.Card} ${statusClass} ${loadingClass} ${recommendationType}`}>
        {state && (
          <div className={styles.CardTags}>
            {state}
            {status}
          </div>
        )}
        <h5 className={styles.Title}>{title}</h5>
        {icons && <div className={styles.IconsBar}>{icons}</div>}
        <div className={styles.ContentWrapper}>{contentBlock}</div>
        <div className={styles.Spinner}>
          <Spinner isVisible={loading} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('common')(Card);
