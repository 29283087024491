import React from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY } from 'assets/constants/constants';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';

import styles from './DialogBasics.module.scss';

const DialogWarning = ({ children, confirmText, handleConfirm, hasIcon, isLoading, title }) => {
  const { t } = useTranslation();
  const buttons = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: confirmText || t('confirm'),
      handleClick: handleConfirm,
      isLoading,
      key: 'confirm',
    },
  ];

  return (
    <Dialog>
      <DialogHeader className={styles.Title}>
        {hasIcon && <i className="fas fa-exclamation-triangle" />}
        {title || t('warningText')}
      </DialogHeader>
      <div className={styles.Container}>{children}</div>
      <DialogFooter buttons={buttons} />
    </Dialog>
  );
};

export default DialogWarning;
