import React from 'react';

import { withTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import * as Utils from 'assets/js/Utils';
import SearchResult from 'components/SearchResult/SearchResult';
import TitleBar from 'components/TitleBar/TitleBar';
import Button from 'components/UI/Button/Button';
import BoxSearch from 'components/UI/Search/Search';
import SearchFilterLegacy from 'components/UI/SearchFilter/SearchFilterLegacy';
import Spinner from 'components/UI/Spinner/Spinner';
import TabFilter from 'components/UI/TabFilter/TabFilter';
import { AuthContext } from 'contexts/Auth/AuthContext';
import { searchDiagrams } from 'services/diagramService';
import { searchDataset, searchPublished } from 'services/documentationService';
import titleService from 'services/titleService';

import 'App.scss';
import styles from './Search.module.scss';

const NOT_TRANS = Constants.NOT_TRANSLATABLE;
const SCOPES = Constants.SCOPES.toLowerCase();
const SEARCH_SERVICES = {
  [Constants.MODELING]: searchDiagrams,
  [Constants.PUBLISHED]: searchPublished,
  [Constants.DOCUMENT]: searchDataset,
};

class Search extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      filterSelected: Constants.PUBLISHED,
      searchText: '',
      loading: true,
      language: this.props.i18n.language.toUpperCase(),
      pageNumber: 0,
      morePages: false,
      totalResults: 0,
      filterResults: [],
      totalPages: 0,
      filters: {
        type: {
          filterData: [Constants.VCD_DIAGRAM_ID, Constants.EPC_DIAGRAM_ID],
          type: Constants.CHECKBOX,
        },
        author: {
          filterData: [],
          type: Constants.USER,
        },
        methodOwner: {
          filterData: [],
          type: Constants.USER,
        },
        approver: {
          filterData: [],
          type: Constants.USER,
        },
        scopes: {
          filterData: [],
          type: Constants.USER,
        },
        processNumber: {
          filterData: '',
          type: Constants.TEXTINPUT,
        },
      },
      filtersInfo: [
        {
          text: 'recycleBin.table.type',
          filterType: Constants.CHECKBOX,
          nameFilter: 'type',
          isOpen: false,
          isSelected: false,
          options: {
            [Constants.VCD_DIAGRAM]: {
              label: this.props.t('VCD'),
              id: Constants.VCD_DIAGRAM_ID,
              checked: true,
              keyName: Constants.VCD_DIAGRAM,
            },
            [Constants.EPC_DIAGRAM]: {
              label: this.props.t('EPC'),
              id: Constants.EPC_DIAGRAM_ID,
              checked: true,
              keyName: Constants.EPC_DIAGRAM,
            },
          },
        },
        {
          text: 'nameAttributes.AUTHOR',
          filterType: Constants.USER,
          nameFilter: 'author',
          isOpen: false,
          isSelected: false,
        },
        {
          text: 'nameAttributes.METHOD_OWNER',
          filterType: Constants.USER,
          nameFilter: 'methodOwner',
          isOpen: false,
          isSelected: false,
        },
        {
          text: 'nameAttributes.APPROVER',
          filterType: Constants.USER,
          nameFilter: 'approver',
          isOpen: false,
          isSelected: false,
        },
        {
          text: 'nameAttributes.SCOPES',
          filterType: Constants.USER,
          nameFilter: SCOPES,
          isOpen: false,
          isSelected: false,
        },
        {
          text: 'nameAttributes.PROCESS_NUMBER',
          filterType: Constants.TEXTINPUT,
          nameFilter: 'processNumber',
          placeholder: 'e. g. 345437',
          isOpen: false,
          isSelected: false,
        },
      ],
    };
  }

  componentDidMount() {
    titleService.updatePageTitle(this.props.t('search'));
    const text = this.getSearchText();

    if (text?.trim()) {
      const filter = new URLSearchParams(this.props.location.search).get('filter') || window.history.state?.filter;
      this.searchDiagrams(text, filter);
    } else {
      this.setState({ loading: false });
    }
  }

  handleText(event, name) {
    const filters = Utils.cloneObject(this.state.filters);
    filters[name] = {
      filterData: event.target.value,
      type: Constants.TEXTINPUT,
    };
    this.setState({ filters });
  }

  handleCheck(value, id, filter) {
    const filtersInfo = Utils.cloneObject(this.state.filtersInfo);
    const filterClicked = filtersInfo.find((f) => f.nameFilter === filter.nameFilter);
    const filters = Utils.cloneObject(this.state.filters);
    const filterData = [];
    const data = Utils.cloneObject(filter.options);

    data[id].checked = value;
    filterClicked.options = data;

    Object.keys(data).forEach((option) => {
      if (data[option].checked) {
        filterData.push(data[option].id);
      }
    });
    filters[filter.nameFilter] = {
      filterData,
      type: Constants.CHECKBOX,
    };

    this.setState({ filtersInfo, filters });
  }

  handleUser(user, nameFilter) {
    const filters = { ...this.state.filters };
    const userSelected = filters[nameFilter].filterData.find((u) => user.code === u.code);
    userSelected.checked = !userSelected.checked;

    this.setState({ filters });
  }

  getSearchText() {
    return (
      new URLSearchParams(this.props.location.search).get('text') ||
      window.history.state?.text ||
      this.props.location.search.split('=')[1]
    );
  }

  setFilterInfo(filterName, filterSelected, filterOpened) {
    const filtersInfo = Utils.cloneObject(this.state.filtersInfo);
    const filterClicked = filtersInfo.find((f) => f.nameFilter === filterName);

    filterClicked.isSelected = filterSelected;
    filterClicked.isOpen = filterOpened;

    this.setState({ filtersInfo });
  }

  closeAllFilters() {
    return [...this.state.filtersInfo].map((filter) => ({
      ...filter,
      isOpen: false,
      isSelected:
        filter.filterType === Constants.USER
          ? false
          : Utils.getFilterStatus(filter.filterType, this.state.filters[filter.nameFilter]?.filterData),
    }));
  }

  searchDiagrams(text, filter = this.state.filterSelected) {
    window.history.pushState({ text, filter }, this.props.t('search'), 'search');
    this.setState({ searchText: text, loading: true, filtersInfo: this.closeAllFilters(), filterSelected: filter }, () => {
      const dataModeling = {
        page: 0,
        pageSize: 20,
        searchIn: 'MODELING',
        sortDir: 'ASC',
        ...(text ? { value: text } : {}),
      };

      const data = {
        searchIn: '',
        ...(text ? { value: text } : {}),
      };

      SEARCH_SERVICES[filter](filter === Constants.MODELING ? dataModeling : data)
        .then((response) => {
          this.parseSearchData(response);
        })
        .catch((error) => {
          handleServiceError(error);
          this.setState({ loading: false });
        });
    });
  }

  parseUsers(users) {
    return Utils.stringIsArray(users) ? Utils.removeSquareBracketsFromString(users) : [users];
  }

  parseSearchData(response) {
    let results = [];
    const { data } = response;

    if (data.results) {
      results = data.results.map((diagram) => {
        const attributes = JSON.parse(diagram.attributes);

        return {
          title: attributes[this.state.language].PROCESS_NAME || attributes[this.state.language].NAME,
          description: attributes[this.state.language].PROCESS_DESCRIPTION || attributes[this.state.language].DESCRIPTION,
          id: diagram.id,
          type: diagram.idDiagramType,
          status: diagram.status,
          processNumber: attributes[NOT_TRANS].PROCESS_NUMBER,
          author: this.parseUsers(attributes[NOT_TRANS].AUTHOR),
          methodOwner: this.parseUsers(attributes[NOT_TRANS].METHOD_OWNER),
          approver: this.parseUsers(attributes[NOT_TRANS].APPROVER),
          scopes: diagram.scopes?.map((scope) => scope.departmentId) || [],
        };
      });
    }

    const filters = { ...this.state.filters };

    const scopeList = data.allScopes?.map((scope) => ({
      code: scope.departmentId,
      commonName: scope.description[this.state.language],
    }));
    Object.keys(filters).forEach((key) => {
      if (filters[key].type === Constants.USER) {
        filters[key].filterData = key === SCOPES ? scopeList : data.allUsers;
      }
    });

    this.setState(
      {
        loading: false,
        results,
        pageNumber: 0,
        morePages: results.length > Constants.DEFAULT_PAGE_SIZE,
        totalResults: results.length,
        totalPages: Math.ceil(results.length / Constants.DEFAULT_PAGE_SIZE),
        filterResults: results,
        filters,
      },
      () => {
        this.filterSearch();
      },
    );
  }

  changeFilter(filter) {
    this.setState({ totalResults: 0, filterSelected: filter }, () => {
      this.sendSearch(this.state.searchText);
    });
  }

  sendSearch(text) {
    if (text.trim()) {
      this.searchDiagrams(text);
    }
  }

  updatePagination(newTotal = this.state.totalPages, newNumber = this.state.pageNumber) {
    const nextPage = parseInt(newNumber, 10) + 1;

    this.setState({
      morePages: nextPage < newTotal,
      pageNumber: nextPage,
      totalPages: newTotal,
    });
  }

  filterSearch(filtersData = this.state.filters) {
    const { filterList, filterResults } = Utils.getFilteredSearch(filtersData, this.state.results);

    this.setState(
      {
        filters: filterList,
        filterResults,
        totalResults: filterResults.length,
      },
      () => {
        this.updatePagination(Math.ceil(filterResults.length / Constants.DEFAULT_PAGE_SIZE), 0);
      },
    );
  }

  resetFilter(filtersInfo, filters) {
    this.setState({ filtersInfo, filters }, () => {
      this.filterSearch(filters);
    });
  }

  render() {
    const { t } = this.props;
    const resultsBlock =
      this.state.filterResults?.length > 0 ? (
        this.state.filterResults
          .slice(0, Constants.DEFAULT_PAGE_SIZE * this.state.pageNumber)
          .map((result) => (
            <SearchResult
              filterSearch={this.state.filterSelected}
              key={result.id}
              result={result}
              type={this.state.filterSelected === Constants.DOCUMENT ? Constants.DATASET_ID : result.type}
            />
          ))
      ) : (
        <p className={styles.NoResults}>{t('noResults')}</p>
      );
    const spinner = (
      <div className="spinner">
        <Spinner isVisible />
      </div>
    );

    return (
      <>
        <TitleBar />
        <div className={styles.Content}>
          <div className={styles.Header}>
            <div className={styles.Search}>
              <h1>{t('searchResults')}</h1>
              <BoxSearch
                disabled={this.state.loading}
                extraClass="SearchView"
                searching={() => {}}
                sendSearch={(text) => this.sendSearch(text)}
                showButton
              />
            </div>
            {(this.context.checkRoles([Constants.ROLES.MODELER, Constants.ROLES.QI, Constants.ROLES.USER]) ||
              this.context.userInfo?.neposInvolve) && (
              <div className={styles.TabFilters}>
                {Constants.TAB_SEARCH_FILTER.map((filter) => {
                  if (
                    !this.context.checkRoles([Constants.ROLES.MODELER, Constants.ROLES.QI]) &&
                    !this.context.userInfo?.neposInvolve &&
                    filter === Constants.MODELING
                  )
                    return;

                  return (
                    <TabFilter
                      click={(e) => this.changeFilter(e)}
                      id={`search-${filter}`}
                      idFilter={filter}
                      isSelected={filter === this.state.filterSelected}
                      key={filter}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.Filters}>
            {this.state.filtersInfo.map((filter, i) => {
              if (this.state.filterSelected === Constants.DOCUMENT && i !== 0) return;

              return (
                <SearchFilterLegacy
                  content={this.state.filters[filter.nameFilter]?.filterData}
                  filter={filter}
                  filterResults={this.state.results}
                  filterSearch={(filters) => this.filterSearch(filters)}
                  filtersInfo={this.state.filtersInfo}
                  filtersList={this.state.filters}
                  handleChange={(e) => this.handleText(e, filter.nameFilter)}
                  handleSelection={(e) => this.handleUser(e, filter.nameFilter)}
                  isChecked={(value, id) => this.handleCheck(value, id, filter)}
                  key={filter.text}
                  options={filter.options}
                  resetFilter={(filtersInfo, filters) => this.resetFilter(filtersInfo, filters)}
                  setFilterInfo={(filterName, filterSelected, filterOpened) =>
                    this.setFilterInfo(filterName, filterSelected, filterOpened)
                  }
                />
              );
            })}
          </div>
          <div className={styles.ResultsInfo}>
            {this.state.searchText && <span className={styles.ResultsOf}>{`${t('resultsOf')} ${this.state.searchText}`}</span>}
            {this.state.filterResults?.length > 0 && (
              <span className={styles.ResultsLength}>{`${this.state.totalResults} ${t('results')}`}</span>
            )}
          </div>
          <div className={styles.SearchResults}>
            {this.state.loading ? spinner : resultsBlock}
            {!this.state.loading && this.state.filterResults.length > 0 && this.state.morePages && (
              <Button btnText={t('moreResults')} buttonStyle="BorderButton" handleClick={() => this.updatePagination()} />
            )}
          </div>
        </div>
      </>
    );
  }
}

Search.contextType = AuthContext;

export default withTranslation('common')(Search);
