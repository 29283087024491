import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import useFeatureFlags from 'hooks/useFeatureFlags';

import * as Constants from '../../assets/constants/constants';
import * as serviceUtils from '../../assets/js/serviceUtils';
import * as Utils from '../../assets/js/Utils';
import TitleBar from '../../components/TitleBar/TitleBar';
import Toolbar from '../../components/Toolbar/Toolbar';
import toolbarStyles from '../../components/Toolbar/Toolbar.module.scss';
import Button from '../../components/UI/Button/Button';
import * as Dialog from '../../components/UI/Dialogs/Dialogs';
import Spinner from '../../components/UI/Spinner/Spinner';
import TabFilter from '../../components/UI/TabFilter/TabFilter';
import documentationServices from '../../services/documentationService';
import recommendationServices from '../../services/recommendationService';
import styles from './Recommendation.module.scss';
import RecommendedMessage from './RecommendedMessage';
import RejectDialog from './RejectDialog';
import TransferDialog from './TransferDialog';

let acceptDialog = '';

const Recommendation = (props) => {
  const {
    match: { params },
  } = props;
  const { t, i18n } = useTranslation();
  const { isFreezed } = useFeatureFlags();

  const [isLoading, setIsLoading] = useState(true);
  const [areAttributesVisible, setAreAttributesVisible] = useState(true);
  const [language, setLanguage] = useState(i18n.language.toUpperCase());
  const [pdfUrl, setPdfUrl] = useState({ url: '', contentDisposition: '' });
  const [localPdfUrl, setLocalPdfUrl] = useState({ url: '', contentDisposition: '' });
  const [data, setData] = useState({});
  const [usersHistorical, setUsersHistorical] = useState([]);
  const [isTransferDialogVisible, setIsTransferDialogVisible] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [visibleVersion, setVisibleVersion] = useState(Constants.RECOMMENDATION_VERSION_TABS[0]);

  const { date, epcName, messageTypeCode, processNumber, url, version } = data;
  const translatedEPCName = useMemo(
    () => (typeof epcName === 'string' ? JSON.parse(epcName)[i18n.language.toUpperCase()].TITLE : ''),
    [epcName, i18n.language],
  );
  const id = useMemo(() => parseInt(params.id, 10), [params.id]);

  const acceptRecommendation = useCallback(() => {
    acceptDialog.hide();

    recommendationServices
      .acceptRecommendation(id)
      .then(() => {
        props.history.push('/dashboard');

        Dialog.showAlert({
          name: t('success'),
          message: t('recommendations.acceptedRecommendation'),
        });
      })
      .catch((err) => {
        recommendationServices.handleServiceError(err);
      });
  }, [id, props.history, t]);

  const confirmDialogData = useMemo(
    () => ({
      title: t('recommendations.view.accept.title'),
      message: t(`recommendations.view.accept.${messageTypeCode === Constants.RECOMMENDED_REWORK ? 'contentRework' : 'content'}`),
      textCancel: t('cancel'),
      textOk: t('accept'),
      okClick: acceptRecommendation,
    }),
    [acceptRecommendation, messageTypeCode, t],
  );

  const toolbarButtons = useMemo(
    () => [
      {
        btnText: t('recommendations.view.buttons.transfer'),
        component: Button,
        disabled: isFreezed || isLoading,
        handleClick: () => setIsTransferDialogVisible(true),
        id: 'transfer',
      },
      {
        btnText: t('reject'),
        component: Button,
        disabled: isLoading,
        handleClick: () => setShowRejectDialog(true),
        id: 'reject',
      },
      {
        btnText: t('accept'),
        buttonStyle: 'Primary',
        component: Button,
        disabled: isLoading,
        handleClick: () => {
          acceptDialog = Dialog.showConfirm(confirmDialogData);
        },
        id: 'accept',
      },
    ],
    [confirmDialogData, isLoading, t],
  );
  const toolbarLeftButtons = useMemo(() => {
    if (messageTypeCode !== Constants.RECOMMENDED_REWORK) return;

    return (
      <div className={toolbarStyles.LeftSwitch}>
        {Constants.RECOMMENDATION_VERSION_TABS.map((versionTab) => (
          <TabFilter
            click={setVisibleVersion}
            idFilter={versionTab}
            isSelected={versionTab === visibleVersion}
            key={versionTab}
            text={t(`recommendations.switch.${versionTab}`)}
          />
        ))}
      </div>
    );
  }, [messageTypeCode, t, visibleVersion]);

  useEffect(() => {
    recommendationServices
      .getRecommendation(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        recommendationServices.handleServiceError(err);
      });
    recommendationServices
      .getUsersHistorical(id)
      .then((res) => {
        setUsersHistorical(res.data);
      })
      .catch((err) => {
        recommendationServices.handleServiceError(err);
      });
  }, [id]);

  useEffect(() => {
    if (processNumber && version) {
      documentationServices
        .getDocument(processNumber, version, Constants.ENGLISH, Constants.EXCHANGE)
        .then((res) => {
          setPdfUrl({
            url: window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' })),
            contentDisposition: res.headers['content-disposition'],
          });

          if (messageTypeCode === Constants.RECOMMENDED_REWORK) {
            documentationServices
              .getDocument(processNumber, 'last', i18n.language, Constants.DIAGRAM, 'pdf', serviceUtils.getCurrentPlant(), true)
              .then((localRes) => {
                setLocalPdfUrl({
                  url:
                    localRes.status === 204
                      ? ''
                      : window.URL.createObjectURL(new Blob([localRes.data], { type: 'application/pdf' })),
                  contentDisposition: localRes.headers['content-disposition'],
                });
              })
              .catch((err) => {
                documentationServices.handleServiceError(err);
              });
          }
        })
        .catch((err) => {
          documentationServices.handleServiceError(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [i18n.language, language, messageTypeCode, processNumber, version]);

  const onClickToggleAttributes = useCallback(() => {
    setAreAttributesVisible(!areAttributesVisible);
  }, [areAttributesVisible]);

  const onClickDownload = useCallback(() => {
    const fileName = `${translatedEPCName}.pdf`;
    Utils.downloadFile(pdfUrl.url, fileName, pdfUrl.contentDisposition);
  }, [pdfUrl, translatedEPCName]);

  return (
    <>
      <TitleBar />
      <Toolbar
        buttons={toolbarButtons}
        getLanguage={(lng) => setLanguage(lng)}
        language={language}
        leftButtons={toolbarLeftButtons}
      />
      <div className="diagram-space">
        {isLoading && (
          <div className="diagram-loading-layer">
            <Spinner isVisible />
          </div>
        )}
        <div className="diagram-content">
          {visibleVersion === Constants.RECOMMENDATION_VERSION_TABS[0] && (
            <iframe
              height="100%"
              src={pdfUrl.url ? `${pdfUrl.url}#toolbar=0` : ''}
              title="pdf"
              width={areAttributesVisible ? '70%' : '100%'}
            />
          )}
          {visibleVersion === Constants.RECOMMENDATION_VERSION_TABS[1] && localPdfUrl.url ? (
            <iframe
              height="100%"
              src={localPdfUrl.url ? `${localPdfUrl.url}#toolbar=0` : ''}
              title="pdf"
              width={areAttributesVisible ? '70%' : '100%'}
            />
          ) : (
            <div className={`${styles.EmptyPlaceholder} ${areAttributesVisible ? styles.AttributesVisible : ''}`}>
              {t('recommendations.view.emptyLocal')}
            </div>
          )}
        </div>
        <div className={`attributes ${areAttributesVisible ? 'openAttributes' : ''}`} id="attributes-space">
          <div className="toggleButton" id="attributesExpanded" onClick={onClickToggleAttributes}>
            <i className="fas fa-angle-double-left" />
          </div>
          {areAttributesVisible && (
            <div className="measuringWrapper">
              <h5 className="attributes-title">
                <div className="titles">
                  {t('recommendations.processRecommendation')}
                  <span className={styles.TaskStatus}>{t(`recommendations.type.${messageTypeCode}`)}</span>
                </div>
                <div className="titles">
                  <i className="di attributesIcons icon-datei-pdf" onClick={onClickDownload} />
                </div>
              </h5>
              <RecommendedMessage date={date} epcName={epcName} url={url} />
            </div>
          )}
        </div>
      </div>
      <div id="dialog">
        {isTransferDialogVisible && (
          <TransferDialog
            id={id}
            isVisible={isTransferDialogVisible}
            setIsVisible={setIsTransferDialogVisible}
            usersHistorical={usersHistorical}
          />
        )}
        {showRejectDialog && <RejectDialog recommendationId={id} showDialog={setShowRejectDialog} visible={showRejectDialog} />}
      </div>
    </>
  );
};

export default withRouter(Recommendation);
