import React from 'react';

import { useTranslation } from 'react-i18next';

import FieldTooltip from '../FieldTooltip/FieldTooltip';
import styles from './TextArea.module.scss';

const TextArea = ({ handler, meta, touched, hasError, rows = '4', handleOnBlur }) => {
  const { t } = useTranslation();
  const mandatory = meta.attributeType === 'M' ? '*' : ''; // Mandatory
  const systemAttribute = meta.attributeType === 'S'; // System
  // else 'O': Optional
  const showError = !handler().value && !!mandatory && touched && hasError('required');

  return (
    <div className={`form-group ${showError ? styles.Error : ''} ${meta.extraClass}`}>
      <label htmlFor={meta.id}>
        {meta.label}
        {mandatory}
      </label>
      {meta.description && <FieldTooltip description={meta.description} />}
      {showError ? (
        <i className="fas fa-exclamation-circle">
          <span>{`${meta.label} is required`}</span>
        </i>
      ) : (
        ''
      )}
      <textarea
        className={`form-control ${styles.TextArea}`}
        id={meta.id}
        onBlur={handleOnBlur}
        placeholder={systemAttribute ? '-' : t('diagram.objects.text')}
        readOnly={systemAttribute}
        rows={rows}
        {...handler()}
      />
    </div>
  );
};

export default TextArea;
