import React, { useState } from 'react';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { FormGenerator, Validators } from 'react-reactive-form';

import * as Constants from 'assets/constants/constants';
import * as Utils from 'assets/js/Utils';
import Button from 'components/UI/Button/Button';
import FormDialog from 'components/UI/FormDialog/FormDialog';
import WorkflowDialogExplorerLocations from 'components/WorkflowDialogExplorer/WorkflowDialogExplorerLocations';
import WorkflowDialogExplorerScopes from 'components/WorkflowDialogExplorer/WorkflowDialogExplorerScopes';
import useAuth from 'hooks/useAuth';
import './WorkflowDialog.scss';
import useFeatureFlags from 'hooks/useFeatureFlags';

import formDialogStyles from 'components/UI/FormDialog/FormDialog.module.scss';

import styles from './WorkflowDialog.module.scss';

let form;
const WorkflowDialog = (props) => {
  const {
    isOpen,
    fieldConfig,
    save,
    close,
    approve,
    confirm,
    attributesData,
    showButtonSave,
    showRejectButton,
    labelDiagramName,
    codeWorkflow,
    scopes,
    locations,
    isResponsible,
  } = props;
  const { t } = useTranslation();
  const [rejectReason, setRejectReason] = useState('');
  const [showTextInputDialog, setShowTextInputDialog] = useState(false);
  const [showScopes, setShowScopes] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [selectedScopes, setSelectedScopes] = useState([]);
  const [recommendedLocations, setRecommendedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { getManagedPlants } = useAuth();
  const { isFreeze } = useFeatureFlags();

  const onMount = (mountedForm) => {
    form = mountedForm;
    setShowScopes(false);
    if (attributesData) {
      form.patchValue(attributesData);
    }

    if (form.get(Constants.SCOPES)) {
      setSelectedScopes(form.get(Constants.SCOPES).value);
      // valueChange
      form.get(Constants.SCOPES).valueChanges.subscribe((newValue) => setSelectedScopes(newValue));
      // onFocus
      form.get(Constants.SCOPES).stateChanges.subscribe(() => {
        if (!showScopes && form.get(Constants.SCOPES).active) {
          setShowLocations(false);
          setShowScopes(true);
        }
      });
    }

    if (form.get(Constants.RECOMMENDED_LOCATIONS)) {
      setRecommendedLocations(form.get(Constants.RECOMMENDED_LOCATIONS).value);
    }

    if (form.get(Constants.RECOMMEND_NEW_LOCATIONS)) {
      setSelectedLocations(form.get(Constants.RECOMMEND_NEW_LOCATIONS).value);
      // valueChange
      form.get(Constants.RECOMMEND_NEW_LOCATIONS).valueChanges.subscribe((newValue) => setSelectedLocations(newValue));
      // onFocus
      form.get(Constants.RECOMMEND_NEW_LOCATIONS).stateChanges.subscribe(() => {
        if (!showLocations && form.get(Constants.RECOMMEND_NEW_LOCATIONS).active) {
          setShowScopes(false);
          setShowLocations(true);
        }
      });
    }

    if (form.get(Constants.GAP_LOG_CATEGORY)) {
      if (form.get(Constants.GAP_LOG_CATEGORY).value) {
        form.patchValue({
          [Constants.GAP_LOG_CATEGORY]: t(
            `attributes.${Constants.GAP_LOG_CATEGORY}.options.${form.get(Constants.GAP_LOG_CATEGORY).value}`,
          ),
        });
      }

      form.get(Constants.GAP_LOG_CATEGORY).valueChanges.subscribe((newValue) => {
        if (!form.get(Constants.GAP_LOG_COMMENT).value && newValue === Constants.GAP_LOG_CATEGORIES[0]) {
          form.get(Constants.GAP_LOG_COMMENT).setValidators([]);
          form.patchValue({ [Constants.GAP_LOG_COMMENT]: t(`attributes.${Constants.GAP_LOG_CATEGORY}.options.${newValue}`) });
        } else {
          form.get(Constants.GAP_LOG_COMMENT).setValidators([Validators.required, Utils.checkWhiteSpacesValidation]);
          form.patchValue({ [Constants.GAP_LOG_COMMENT]: form.get(Constants.GAP_LOG_COMMENT).value });
        }
      });
    }
  };

  const confirmReject = () => {
    confirm(rejectReason.trim());
    document.getElementById('inputId').value = '';
    setShowTextInputDialog(false);
  };

  const closeRejectDialog = () => {
    document.getElementById('inputId').value = '';
    setShowTextInputDialog(false);
    setRejectReason('');
  };

  const header = () => {
    let explorerTitle = '';

    if (showScopes) {
      explorerTitle = `<div class="workflowHeader scopesHeader">${t('scopes.header')}</div>`;
    } else if (showLocations) {
      explorerTitle = `<div class="workflowHeader scopesHeader">${t('locations.header')}</div>`;
    }

    return `<div class="workflowHeader">${t(`workflow.titles.${codeWorkflow}`)}</div>${explorerTitle}`;
  };

  fieldConfig.controls.$field_0 = {
    isStatic: false,
    render: ({ invalid }) => (
      <div className={styles.ButtonLine}>
        {showButtonSave && (
          <Button btnText={t('save')} buttonStyle="Secondary" handleClick={() => save(form.value)} id="saveWorkflow" />
        )}
        <Button btnText={t('close')} buttonStyle="Secondary" custom="custom" handleClick={close} id="cancelWorkflow" />
        {showRejectButton && (
          <Button
            btnText={t('reject')}
            buttonStyle={isResponsible ? 'Secondary' : 'Primary'}
            handleClick={() => {
              setShowTextInputDialog(true);
              close();
            }}
            id="rejectWorkflow"
          />
        )}
        {isResponsible && (
          <Button
            btnText={t('approve')}
            buttonStyle="Primary"
            disabled={isFreeze || invalid}
            handleClick={() => approve(form.value)}
            id="approveWorkflow"
          />
        )}
      </div>
    ),
  };

  return (
    <DialogComponent
      close={close}
      header={header()}
      height="800px"
      isModal
      target="#workflow-dialog"
      visible={isOpen}
      width={showScopes || showLocations ? '950px' : '500px'}
    >
      <div className={`${styles.Content} ${showScopes || showLocations ? '' : styles.HideWorkflowDialogExplorer}`}>
        <div className={styles.Section}>
          <p>{t(`workflow.info.${codeWorkflow}`)}</p>
          <FormGenerator fieldConfig={fieldConfig} onMount={onMount} />
        </div>
        <FormDialog
          actionButtonId="Reject"
          actionName={t('reject')}
          closeDialog={() => closeRejectDialog()}
          id="textInputDialog"
          info={t('textinputDialog.message', { dialogType: t(`workflow.step.${codeWorkflow}`), labelName: labelDiagramName })}
          returnAction={(reason) => confirmReject(reason)}
          title={t('textinputDialog.header')}
          value={rejectReason}
          visible={showTextInputDialog}
        >
          <input
            className={formDialogStyles.Input}
            id="inputId"
            onChange={(event) => setRejectReason(event.target.value)}
            placeholder={t('textinputDialog.reason')}
          />
        </FormDialog>
        {showScopes && (
          <div className={styles.WorkflowDialogExplorer}>
            <WorkflowDialogExplorerScopes patchValue={form.patchValue} scopes={scopes} selected={selectedScopes} />
          </div>
        )}
        {showLocations && (
          <div className={styles.WorkflowDialogExplorer}>
            <WorkflowDialogExplorerLocations
              locations={locations}
              managedPlants={getManagedPlants()}
              patchValue={form.patchValue}
              recommended={recommendedLocations}
              selected={selectedLocations}
            />
          </div>
        )}
      </div>
    </DialogComponent>
  );
};

export default React.memo(WorkflowDialog, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
