import { useTranslation } from 'react-i18next';

import styles from './MessageTag.module.scss';

type Props = {
  dataQA: string;
  icon: string;
  message: string;
};

const MessageTag = (props: Props) => {
  const { dataQA, icon, message } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.Container} data-qa={dataQA}>
      <i className={`di ${icon}`} />
      <span>{t(message)}</span>
    </div>
  );
};

export default MessageTag;
