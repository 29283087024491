import { useCallback } from 'react';

import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import {
  NOT_TRANSLATABLE,
  SYMBOL,
  SYMBOL_COPY_OFFSET,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
  TEXTFIELD_HEIGHT,
  TEXTFIELD_WIDTH,
} from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { cloneObject } from 'assets/js/Utils';
import { createPostIt, DiagramActionTypes, setSymbolBoardsDisplayed, updateSwimlanes } from 'contexts/Diagram/DiagramContext';
import useProcess from 'hooks/useProcess';
import { importSandbox, moveDiagram } from 'services/design';
import diagramServices from 'services/diagramService';
import { Coordinates } from 'types/diagram';
import { AttributeCode } from 'types/forms';
import { ProcessType } from 'types/processes';
import { PostIt, PostItTypes, Symbol, SymbolMeta, SymbolTypes } from 'types/symbols';

import useConnector from './useConnector';
import useDiagramContext from './useDiagramContext';
import useFormTypes from './useFormTypes';
import useSelection from './useSelection';

export default function useSymbol() {
  const idProcess = useParams<{ id: string }>().id;
  const { saveProcess, fetchProcess } = useProcess(idProcess);
  const { diagramLanguage, dispatch, processData, isCopiedSymbol, displayedSymbolBoards } = useDiagramContext();
  const { deleteSymbolConnectors } = useConnector();
  const { getFormTypesCode, getInitialValues, ...formTypes } = useFormTypes();
  const { clearSelection } = useSelection();
  isCopiedSymbol.current = false;

  const createSymbol = useCallback(
    ({ top, left }: Coordinates) => {
      if (!processData) return;
      const symbolType = SymbolTypes.SIPOC_ELEMENT;
      const attributes = getInitialValues(
        formTypes[
          getFormTypesCode({
            status: processData.status,
            type: SYMBOL,
            variant: symbolType,
          })
        ],
      );
      if (!attributes) return;
      const newSymbol = {
        attributes,
        id: uuidv4(),
        meta: {
          top,
          left,
          height: SYMBOL_HEIGHT,
          width: SYMBOL_WIDTH,
        },
        postIts: {
          INPUT: [],
          OUTPUT: [],
          E2E_PROCESS_OWNER: [{ text: '', order: 0, id: '0' }],
          IT_SYSTEM: [],
          PAINPOINTS: [],
          KPI: [],
          POTENTIAL_ASSESSMENTS: [],
        },
        type: symbolType,
      };
      dispatch({ type: DiagramActionTypes.CREATE_SYMBOL, payload: newSymbol });
    },
    [dispatch, formTypes, getFormTypesCode, getInitialValues, processData],
  );

  const addPostIt = useCallback(
    (category: PostItTypes, symbolId: string) => {
      dispatch(createPostIt(category, symbolId));
    },
    [dispatch],
  );

  const deletePostIt = useCallback(
    (category: string, symbolId: string, postItId: string) => {
      if (!processData) return;
      const clonedSymbols = cloneObject(processData.symbols);

      const newSymbols = clonedSymbols.map((symbol: Symbol) => {
        if (symbol.id === symbolId && symbol.postIts) {
          const updatedPostITs = symbol.postIts[category as PostItTypes]?.filter((postIt) => postIt.id !== postItId);
          updatedPostITs?.map((postIt, index) => {
            postIt.order = index;
            return postIt;
          });
          symbol.postIts[category as PostItTypes] = updatedPostITs;
        }
        return symbol;
      });

      if (!newSymbols) return;

      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols as Symbol[] });
    },
    [dispatch, processData],
  );

  const updatePostIt = useCallback(
    (category: string, newValues: PostIt, symbolId: string) => {
      if (!processData) return;
      const clonedSymbols = cloneObject(processData.symbols);
      const newSymbols = clonedSymbols.map((symbol: Symbol) => {
        if (symbol.id !== symbolId) return symbol;
        if (
          category === PostItTypes.E2E_PROCESS_OWNER &&
          (!symbol.postIts || symbol.postIts[PostItTypes.E2E_PROCESS_OWNER]?.length === 0)
        ) {
          symbol.postIts = {
            ...symbol.postIts,
            E2E_PROCESS_OWNER: [newValues],
          };
        }
        if (symbol.postIts) {
          const symbolPostIts = symbol.postIts[category as PostItTypes]?.map((postIt) => {
            if (postIt.id !== undefined && postIt.id === newValues.id) {
              return {
                ...postIt,
                ...newValues,
              };
            }

            return postIt;
          });

          symbol.postIts[category as PostItTypes] = symbolPostIts;
        }

        return symbol;
      });

      if (!newSymbols) return;
      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols });
    },
    [dispatch, processData],
  );

  const createTextfield = useCallback(
    ({ top, left }: Coordinates) => {
      if (!processData) return;
      const symbolType = SymbolTypes.TEXT;
      const attributes = getInitialValues(
        formTypes[
          getFormTypesCode({
            status: processData.status,
            type: SYMBOL,
            variant: symbolType,
          })
        ],
      );

      if (!attributes) return;
      const newTextFieldNEPOS = {
        attributes,
        id: uuidv4(),
        meta: {
          top,
          left,
          height: TEXTFIELD_HEIGHT,
          width: TEXTFIELD_WIDTH,
        },
        type: symbolType,
      };
      dispatch({ type: DiagramActionTypes.CREATE_TEXTFIELD, payload: newTextFieldNEPOS });
    },
    [dispatch, formTypes, getFormTypesCode, getInitialValues, processData],
  );

  const createSwimlane = useCallback(() => {
    console.log('not implemented: createSwimlane'); // eslint-disable-line
    // TODO: Implement logic to add swimlane
  }, []);

  const deleteSymbol = useCallback(
    (symbolId: string) => {
      if (!processData) return;
      const updatedSymbols = processData.symbols.filter((symbol) => symbol.id !== symbolId);

      dispatch({ type: DiagramActionTypes.SET_SELECTION, payload: [] });
      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: updatedSymbols });
      dispatch({ type: DiagramActionTypes.UPDATE_UNDO_REDO, payload: true });
      deleteSymbolConnectors(symbolId);
    },
    [deleteSymbolConnectors, dispatch, processData],
  );

  const checkDeleteSymbol = useCallback(
    (symbolId: string) => {
      if (!processData) return;

      const selectedSymbol = processData.symbols.find((symbol) => symbol.id === symbolId);

      if (selectedSymbol?.diagramLink?.id && selectedSymbol?.diagramLink?.type !== 'EXTERNAL') {
        dispatch({ type: DiagramActionTypes.SET_ERROR, payload: { title: 'warningText', message: 'errors.diagramLink' } });
        return;
      }

      deleteSymbol(symbolId);
    },
    [deleteSymbol, dispatch, processData],
  );

  const copySymbol = useCallback(
    (symbolId: string) => {
      if (!processData) return;

      const updatedSymbol = processData.symbols.find((symbol) => symbol.id === symbolId);
      if (!updatedSymbol) return;

      isCopiedSymbol.current = true;
      const id = uuidv4();
      const top = updatedSymbol.meta.top + SYMBOL_COPY_OFFSET;
      const left = updatedSymbol.meta.left + SYMBOL_COPY_OFFSET;
      const { diagramLink, ...updatedSymbolWithoutLink } = updatedSymbol;
      const newSymbol = {
        ...updatedSymbolWithoutLink,
        id,
        meta: { ...updatedSymbolWithoutLink.meta, top, left },
      };

      dispatch({ type: DiagramActionTypes.CREATE_SYMBOL, payload: newSymbol });
      dispatch({ type: DiagramActionTypes.SET_SELECTION, payload: [id] });
    },
    [processData, dispatch, isCopiedSymbol],
  );

  const checkCopySymbol = useCallback(
    (symbolId: string) => {
      if (!processData) return;
      const selectedSymbol = processData.symbols.find((symbol) => symbol.id === symbolId);

      if (!selectedSymbol) return;
      copySymbol(symbolId);
    },
    [processData, copySymbol],
  );

  const getUpdatedSymbol = useCallback(
    (symbolId: string, newValues: Partial<Omit<Symbol, 'meta'> & { meta: Partial<SymbolMeta> }>) => {
      if (!processData) return;

      const newSymbols = processData.symbols.map((symbol: Symbol) =>
        symbol.id === symbolId
          ? {
              ...symbol,
              ...newValues,
              meta: {
                ...symbol.meta,
                ...newValues.meta,
              },
            }
          : symbol,
      );
      return newSymbols;
    },
    [processData],
  );

  const updateSymbol = useCallback(
    (symbolId: string, newValues: Partial<Omit<Symbol, 'meta'> & { meta: Partial<SymbolMeta> }>) => {
      const newSymbols = getUpdatedSymbol(symbolId, newValues);

      if (!newSymbols) return;
      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols });
    },
    [dispatch, getUpdatedSymbol],
  );

  const getUpdatedSymbols = useCallback(
    (newValues: (Partial<Omit<Symbol, 'meta'> & { meta: Partial<SymbolMeta> }> & { id: string })[]) => {
      if (!processData) return;

      const newSymbols = processData.symbols.map((symbol: Symbol) => {
        const updatedSymbol = newValues.find(({ id }) => symbol.id === id);
        return updatedSymbol
          ? {
              ...symbol,
              ...updatedSymbol,
              meta: {
                ...symbol.meta,
                ...updatedSymbol.meta,
              },
            }
          : symbol;
      });
      return newSymbols;
    },
    [processData],
  );

  const updateSymbols = useCallback(
    (newValues: (Partial<Omit<Symbol, 'meta'> & { meta: Partial<SymbolMeta> }> & { id: string })[]) => {
      const newSymbols = getUpdatedSymbols(newValues);

      if (!newSymbols) return;
      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols });
    },
    [dispatch, getUpdatedSymbols],
  );

  const onNameChange = (id: string, value: string, textArea: HTMLTextAreaElement | null) => {
    if (!processData) return;

    if (textArea) {
      textArea.style.height = '0';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }

    const symbols =
      processData.type === ProcessType.SWIMLANE
        ? [...processData?.swimlanes.map((swimlane) => swimlane.symbols).flat(), ...processData?.symbols] || []
        : processData?.symbols;
    const symbolType = symbols.find((symbol) => symbol.id === id)?.type;
    const symbolNameAttribute = processData.type === ProcessType.SWIMLANE ? AttributeCode.OBJECT_NAME : AttributeCode.NAME;
    const attribute = symbolType === SymbolTypes.TEXT ? AttributeCode.TEXT_BLOCK : symbolNameAttribute;

    if (processData.type === ProcessType.SWIMLANE && symbolType !== SymbolTypes.TEXT) {
      const newSwimlanes = processData?.swimlanes.map((swimlane) => {
        const modifiedSymbolIndex = swimlane.symbols.findIndex((symbol) => symbol.id === id);

        if (modifiedSymbolIndex !== -1 && swimlane.symbols[modifiedSymbolIndex].attributes?.[diagramLanguage]) {
          swimlane.symbols[modifiedSymbolIndex].attributes[diagramLanguage]![attribute] = value;
        }

        return swimlane;
      });

      dispatch(updateSwimlanes(newSwimlanes));
    } else {
      const newSymbols = processData?.symbols.map((symbol) => {
        if (symbol.attributes?.[diagramLanguage] && symbol.id === id) {
          symbol.attributes[diagramLanguage]![attribute] = value;
        }

        return symbol;
      });

      dispatch({ type: DiagramActionTypes.UPDATE_SYMBOLS, payload: newSymbols });
    }
  };

  const handleOuterClick = useCallback(
    (event, container) => {
      if (container?.id === event.target.id) return;
      clearSelection();
      dispatch({ type: DiagramActionTypes.UPDATING_SYMBOL_NAME, payload: '' });
    },
    [clearSelection, dispatch],
  );

  const importFromSandbox = useCallback(
    async (idLinkedDiagram: number, modelers?: string[], selectedId?: string) => {
      if (processData) {
        try {
          await saveProcess(processData);
          dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
          await importSandbox({
            id: idLinkedDiagram,
            idSymbol: selectedId || '',
            modelers: modelers || [''],
          });
          dispatch({ type: DiagramActionTypes.IMPORT_SANDBOX_SUCCESS });
          fetchProcess();
        } catch (error) {
          dispatch({ type: DiagramActionTypes.IMPORT_SANDBOX_ERROR });
          handleServiceError(error);
        }
      }
    },
    [processData, saveProcess, dispatch, fetchProcess], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const linkDiagramFromTree = useCallback(
    async (idLinkedDiagram: number, selectedId) => {
      if (processData) {
        try {
          await saveProcess(processData);
          dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
          await diagramServices.linkExternalDiagramV2({
            idLinkedDiagram,
            idSourceSymbol: selectedId,
          });
          dispatch({ type: DiagramActionTypes.LINK_DIAGRAM_SUCCESS });
          fetchProcess();
        } catch (error) {
          dispatch({ type: DiagramActionTypes.LINK_DIAGRAM_ERROR });
          handleServiceError(error);
        }
      }
    },
    [processData, saveProcess, dispatch, fetchProcess], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const moveDiagramFromTree = useCallback(
    async (idLinkedDiagram: number, selectedId) => {
      if (processData) {
        try {
          await saveProcess(processData);
          dispatch({ type: DiagramActionTypes.SET_LOADING_TRUE });
          await moveDiagram({
            idDiagram: idLinkedDiagram,
            idSymbol: selectedId,
          });
          dispatch({ type: DiagramActionTypes.MOVE_DIAGRAM_SUCCESS });
          fetchProcess();
        } catch (error) {
          dispatch({ type: DiagramActionTypes.MOVE_DIAGRAM_ERROR });
          handleServiceError(error);
        }
      }
    },
    [processData, saveProcess, dispatch, fetchProcess], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const togglePostIts = (id: string) => {
    if (!processData?.symbols || !displayedSymbolBoards) return;

    const isSymbolDisplayed = displayedSymbolBoards.find((symbolId) => symbolId === id);
    const IdsWithoutCurrentSymbol = displayedSymbolBoards.filter((symbolId) => symbolId !== id);
    const resultIds = isSymbolDisplayed !== undefined ? [...IdsWithoutCurrentSymbol] : [...displayedSymbolBoards, id];

    dispatch(setSymbolBoardsDisplayed(resultIds));
  };

  const getProcessStepNumber = () => {
    if (!processData) return;

    const allProcessSteps = processData.swimlanes.map((swimlane) =>
      swimlane.symbols.filter((symbol) => symbol.type === SymbolTypes.PROCESS_STEP).map((symbol) => symbol),
    );
    let processStepNumber = '1';

    const orderedSymbolsByProcessNumber = allProcessSteps
      .flat()
      .sort(
        (a: any, b: any) =>
          a.attributes[NOT_TRANSLATABLE][AttributeCode.PROCESS_STEP_NUMBER] -
          b.attributes[NOT_TRANSLATABLE][AttributeCode.PROCESS_STEP_NUMBER],
      );

    const highestProcessNumber = orderedSymbolsByProcessNumber[allProcessSteps.flat().length - 1]?.attributes[NOT_TRANSLATABLE];

    if (!highestProcessNumber || highestProcessNumber[AttributeCode.PROCESS_STEP_NUMBER] === undefined) {
      return processStepNumber;
    }
    processStepNumber = String(Number(highestProcessNumber[AttributeCode.PROCESS_STEP_NUMBER]) + 1);

    return processStepNumber;
  };

  return {
    createSymbol,
    addPostIt,
    deletePostIt,
    createSwimlane,
    createTextfield,
    deleteSymbol,
    checkDeleteSymbol,
    checkCopySymbol,
    handleOuterClick,
    importFromSandbox,
    moveDiagramFromTree,
    linkDiagramFromTree,
    onNameChange,
    updatePostIt,
    updateSymbol,
    updateSymbols,
    getProcessStepNumber,
    getUpdatedSymbol,
    getUpdatedSymbols,
    togglePostIts,
  };
}
