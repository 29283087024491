/* eslint-disable react/sort-comp, max-classes-per-file, no-return-assign, class-methods-use-this, no-mixed-operators,
    no-await-in-loop */
import React from 'react';

import {
  Inject,
  DiagramComponent,
  Node as SyncfusionNode,
  ConnectorConstraints,
  PortVisibility,
  NodeConstraints,
  DiagramTools,
  DiagramConstraints,
  Selector,
  SelectorConstraints,
  SnapConstraints,
  Keys,
  KeyModifiers,
  BasicShape,
} from '@syncfusion/ej2-react-diagrams';
// import { Beforeunload } from 'react-beforeunload';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import * as Constants from 'assets/constants/constants';
import * as DiagramUtils from 'assets/js/DiagramUtils';
import * as serviceUtils from 'assets/js/serviceUtils';
import * as Utils from 'assets/js/Utils';
import AttributesForm from 'components/Attributes/Attributes/AttributesForm';
import DiagramExplorer from 'components/DiagramExplorer/DiagramExplorer';
import DialogDeleteDiagram from 'components/DialogDeleteDiagram/DialogDeleteDiagram';
import AssignUserDialog from 'components/DialogDetail/AssignUserDialog';
import DialogAddRole from 'components/DialogDetail/DialogAddRole';
import DialogDetail from 'components/DialogDetail/DialogDetail';
import DialogRecommendToLocation from 'components/DialogDetail/DialogRecommendToLocation';
import LinkDiagramDialog from 'components/DialogDetail/LinkDiagramDialog';
import ObjectDialog from 'components/DialogDetail/ObjectDialog';
import DialogFormError from 'components/DialogFormError/DialogFormError';
import DialogHistory from 'components/DialogHistory/DialogHistory';
import DialogObjectCatalog from 'components/DialogObjectCatalog/DialogObjectCatalog';
import ExportDialog from 'components/ExportDialog/ExportDialog';
import SandboxDraft from 'components/SandboxDraft/SandboxDraft';
import * as SandboxUtils from 'components/SandboxDraft/Utils';
import SendForRelease from 'components/SendForRelease/SendForRelease';
import SwimlaneDialog from 'components/SwimlaneDialog/SwimlaneDialog';
import * as PaletteConfig from 'components/SymbolPalette/Config';
import SymbolPalette from 'components/SymbolPalette/SymbolPalette';
import TitleBar from 'components/TitleBar/TitleBar';
import Toolbar from 'components/Toolbar/Toolbar';
import Button from 'components/UI/Button/Button';
import ButtonToggle from 'components/UI/ButtonToggle/ButtonToggle';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import * as Dialog from 'components/UI/Dialogs/Dialogs';
import DropdownForm from 'components/UI/Dropdown/DropdownForm';
import FormattingTools from 'components/UI/FormattingTools/FormattingTools';
import FormDialog from 'components/UI/FormDialog/FormDialog';
import Multiselection from 'components/UI/Multiselection/Multiselection';
import Spinner from 'components/UI/Spinner/Spinner';
import SwimlaneTools from 'components/UI/SwimlaneTools/SwimlaneTools';
import TabFilter from 'components/UI/TabFilter/TabFilter';
import WorkflowBar from 'components/WorkflowBar/WorkflowBar';
import WorkflowBarWrapper from 'components/WorkflowBar/WorkflowBarWrapper';
import { AuthContext } from 'contexts/Auth/AuthContext';
import copyService from 'services/copyService';
import { createDiagram as createDiagramService, getImportSandboxDiagrams, getProcessLevels } from 'services/design';
import services from 'services/diagramService';
import documentationServices from 'services/documentationService';
import objectCatalogService from 'services/objectCatalogService';
import recommendationServices from 'services/recommendationService';
import sandboxService from 'services/sandboxService';
import titleService from 'services/titleService';
import userService from 'services/userService';
import { workflowById, getBasicWorkflowInfo, backToGroup, transferTask, sendForRelease } from 'services/workflowServices';
import { ProcessType } from 'types/processes';

import toolbarStyles from 'components/Toolbar/Toolbar.module.scss';

import './Diagram.scss';

let diagramInstance;
let recommendationDiagramInstance;
let cMenu;
const E2E = 'e2e';

// Language constants
const EN = Constants.ENGLISH;
class Clickable {
  canClick = true;

  resetClickControl() {
    this.canClick = false;

    setTimeout(() => {
      this.canClick = true;
    }, 500);
  }
}

class DeleteTool extends Clickable {
  mouseDown(args) {
    if (this.canClick) {
      this.resetClickControl();

      let selectedItem;
      if (args.actualObject) {
        selectedItem = diagramInstance.selectedItems.nodes[0];

        if (selectedItem?.id?.includes(Constants.OBJECT)) {
          DiagramUtils.updateObjectsPosition(selectedItem, diagramInstance);
        }
        diagramInstance.remove(selectedItem);
      } else if (diagramInstance.selectedItems.nodes?.length > 0) {
        /* TODO: Esta parte está por hacer, debería de poderse eliminar todos los símbolos seleccionados, y por ahora, sólo se
            selecciona y elimina uno. */
        const numSelectedItems = diagramInstance.selectedItems.nodes?.length;
        let selectedNode = '';

        for (let i = 0; i < numSelectedItems; i++) {
          selectedNode = diagramInstance.selectedItems.nodes[0];

          if (selectedNode.id?.includes(Constants.OBJECT)) {
            DiagramUtils.updateObjectsPosition(selectedNode, diagramInstance);
          } else {
            diagramInstance.remove(selectedNode);
          }
        }
      } else if (diagramInstance.selectedItems.connectors?.length > 0) {
        selectedItem = diagramInstance.selectedItems.connectors[0];

        diagramInstance.remove(selectedItem);
      }
    }
  }
}

class ContextMenuTool extends Clickable {
  mouseDown() {
    if (diagramInstance.selectedItems.nodes?.length > 0) {
      const contextMenuButtonElement = document.getElementById('contextMenuButtonHandle_userhandle');
      const windowScrollTop = window.pageYOffset;
      const windowScrollLeft = window.pageXOffset;
      const rect = contextMenuButtonElement.getBoundingClientRect();

      cMenu.open(rect.top + windowScrollTop, rect.right + windowScrollLeft, contextMenuButtonElement);
    }
  }
}

const deleteTool = new DeleteTool();
const contextMenuTool = new ContextMenuTool();

function getTool(action) {
  let tool;

  if (action === 'deleteButtonHandle') {
    tool = deleteTool;
  } else if (action === 'contextMenuButtonHandle') {
    tool = contextMenuTool;
  }

  return tool;
}

const lineIntervals = [
  1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
];
const gridlines = { lineColor: '#e0e0e0', lineIntervals };
const unblockedClass = 'diagram-unblocked';
const onlyReadClass = 'onlyRead-diagram';
const copyNodeStyleClass = 'copyNodeStyles';

class Diagram extends React.PureComponent {
  constructor(props) {
    super(props);

    const diagramId = parseInt(this.props.match.params.id, 10);
    this.setLocation();

    this.state = {
      loading: true,
      loadingLinkedDiagram: false,
      savingDiagram: false,
      successSavingDiagram: false,
      releaseLoading: true,
      successCopy: false,
      diagramId,
      isAutoSaveOn: false,
      injectServices: [...DiagramUtils.defaultInjectServices],
      symbols: [],
      attributes: [],
      fieldConfig: Object.fromEntries(this.props.availableLanguages?.map((language) => [language, { controls: {} }])),
      diagramType: '',
      symbolsPalette: [],
      showShapes: false,
      paletteToolSelected: '',
      symbolSelected: '',
      symbolToDelete: null,
      groupToDelete: null,
      connectors: [],
      showDialogOk: false,
      explorerOpen: '',
      attributesOpen: 'openAttributes',
      openFullAttributes: '',
      showDialogDetail: false,
      showDialogFormError: false,
      showDialogRequestITSystem: false,
      showDialogRequestRole: false,
      showAssignUserDialog: false,
      showLinkDiagramDialog: false,
      showDeleteDiagramDialog: false,
      showSwimlaneDialog: false,
      showExportDialog: false,
      showFormattingTools: false,
      ctxMenuItems: [],
      dialogAttributes: [],
      loadingTree: true,
      loadingTreeData: true,
      loadingSandboxTree: false,
      showObjectDialog: false,
      processNumber: '',
      onlyRead: false,
      blockedDiagramClass: '',
      idObjectType: 0,
      idRoleType: 0,
      objectCatalogList: [],
      objectsCatalogUsedDiagram: [],
      objectsUsedDiagram: [],
      isObject: false,
      selectionObject: '',
      objectsInfo: [],
      recommendationObjectsInfo: [],
      catalogObjects: {},
      recommendationCatalogObjects: {},
      attributesCatalogObject: [],
      showDialogAddRole: false,
      rolesToBeAdded: [],
      mustShowErrors: false,
      workflowAttributes: [],
      workflowDataForm: [],
      loadWorkflow: false,
      savedDate: '',
      breadcrumb: '',
      multipleSymbols: false,
      loadingWorkflowBar: false,
      language: props.i18n.language.toUpperCase(),
      showTransferTask: false,
      users: [],
      currentStage: '',
      userTransferTask: [],
      loadingAttributes: false,
      workflowBar: '',
      headerDialog: '',
      contentDialog: '',
      widthDialog: 0,
      heightDialog: 0,
      treeExplorer: [],
      sandboxTree: [],
      sandboxSearch: [],
      linkDiagramSearch: [],
      dialogTitle: '',
      buttonText: '',
      linkDiagram: '',
      onlyReadWorkflow: false,
      backToGroup: false,
      showDialogHistory: false,
      userBlock: '',
      dataHistory: [],
      dragOn: '',
      disablePaste: true,
      disableCopy: true,
      idImportedDiagram: 0,
      undoHistory: [],
      lastAction: {},
      redoHistory: [],
      inFavorite: false,
      catalogObjectsAdded: [],
      confirmationValue: '',
      showRecommendToLocationDialog: false,
      activeRecommendationVersion: Constants.RECOMMENDATION_VERSION_TABS[1],
      swimlaneButtonLabel: 'ON',
      isModelingEditable: false,
      selectedXmlLanguage: '',
      copiedNodeStyles: false,
      workflowData: {},
    };
    this.canSelect = true;
    this.hideBtn = true;
    this.symbolPaste = false;
    this.menuItems = [];
    this.workflowBar = '';
    this.breadcrumbNodes = [];
    this.confirmDialog = '';
    this.diagramConnections = '';
    this.imageUrls = {};
    this.modelersAssigned = '';
    this.createConnectorErrorMessage = '';
    this.isRecommendation = false;
    this.insertedImages = [];
    this.deletedImages = [];
    this.symbolsInternalLinks = {};
    this.userHandles = [];
    this.loadDiagram = this.loadDiagram.bind(this);
    this.toggleCanSelect = this.toggleCanSelect.bind(this);

    this.select = (args) => {
      const objectAttributes = [];
      this.contextMenuOptionSelected = args.item.id;

      if (args.item.id === Constants.NEW_VCD_ID) {
        this.linkedDiagramType = Constants.VCD_DIAGRAM;
      } else if (args.item.id === Constants.NEW_EPC_ID) {
        this.linkedDiagramType = Constants.EPC_DIAGRAM;
      } else if (args.item.id === Constants.NEW_NEPOS_SIPOC) {
        this.linkedDiagramType = Constants.SIPOC_DIAGRAM;
      } else if (args.item.id === Constants.NEW_NEPOS_SWIMLANE) {
        this.linkedDiagramType = ProcessType.SWIMLANE;
      } else if (Constants.OBJECTS_OPTIONS?.includes(args.item.id)) {
        // EPC
        const objectType = args.item.id;
        const isCatalogObject = Constants.CATALOG_OBJECTS?.includes(objectType);

        if (objectType) {
          objectAttributes.title = this.props.t(`diagram.objects.${objectType.toLowerCase()}`);
          objectAttributes.icon = Constants.OBJECT_ICONS[objectType];
          objectAttributes.catalog = isCatalogObject;
          objectAttributes.code = objectType;
          objectAttributes.idObject = Constants.OBJECT_TYPE_IDS[objectType];
          const symbol = this.state.symbols.find((s) => s.idSymbolFront === this.state.symbolSelected);
          objectAttributes.objectsList = isCatalogObject ? this.state.catalogObjects[objectType].notPending : [];

          if (symbol) {
            // Filter the objects used by the selected symbol
            if (isCatalogObject) {
              const symbolUsedObjects = symbol.objectsCatalogRelations.map((obj) => obj.idObjectCatalog);
              objectAttributes.objectsList = objectAttributes.objectsList.filter((obj) => symbolUsedObjects?.includes(obj.id));
            } else {
              objectAttributes.symbolUsedObjects = symbol.objectsDiagram
                .filter((obj) => obj?.includes(objectAttributes.code.replace('_', '')))
                .map((obj) => {
                  const originalObject = this.state.objectsUsedDiagram.find((ob) => ob.idFront === obj);

                  return {
                    id: obj,
                    name: Utils.getObjectAttributes(originalObject.attributes)[this.state.language].OBJECT_NAME,
                  };
                });
            }
          }

          this.setState({
            dialogAttributes: objectAttributes,
            showDialogDetail: isCatalogObject,
            showObjectDialog: !isCatalogObject,
          });
        }
      }

      if (args.item.id === Constants.NEW_VCD_ID) {
        this.saveParentDiagram();
      } else if (args.item.id === Constants.NEW_NEPOS_SIPOC) {
        this.saveParentDiagram();
      } else if (args.item.id === Constants.NEW_EPC_ID || args.item.id === Constants.NEW_NEPOS_SWIMLANE) {
        this.setState({ showAssignUserDialog: true });
      } else if (Constants.EXTRA_VCD_OPTIONS?.includes(args.item.id) || args.item.id === Constants.LINK_EPC_ID) {
        let diagramsList = [];
        let dialogTitle = this.props.t('linkExistingDiagram');
        let buttonText = this.props.t('link');
        let linkDiagram = (idLinkedDiagram) =>
          this.linkExternalDiagram(idLinkedDiagram, diagramInstance.selectedItems.nodes[0].id);

        if (args.item.id === Constants.LINK_VCD_EPC_ID) {
          diagramsList = this.state.treeExplorer.dataSource.filter((elem) => elem.id !== this.state.diagramId);
        } else if (args.item.id === Constants.IMPORT) {
          this.loadImportSandboxDiagrams();

          dialogTitle = this.props.t('importDiagram');
          buttonText = this.props.t('import');
          linkDiagram = (idLinkedDiagram) => this.importDiagram(idLinkedDiagram, diagramInstance.selectedItems.nodes[0].id);
        } else if ([Constants.MOVE_EPC_ID, Constants.MOVE_VCD_ID]?.includes(args.item.id)) {
          const isMoveEPC = args.item.id === Constants.MOVE_EPC_ID;
          diagramsList = this.state.treeExplorer.dataSource.filter(
            (elem) =>
              elem.id !== this.state.diagramId &&
              elem.idDiagramType === (isMoveEPC ? Constants.EPC_DIAGRAM_ID : Constants.VCD_DIAGRAM_ID),
          );
          dialogTitle = this.props.t(`move${isMoveEPC ? 'EPC' : 'VCD'}Diagram`);
          buttonText = this.props.t('move');
          linkDiagram = (idLinkedDiagram) => this.moveDiagram(idLinkedDiagram, diagramInstance.selectedItems.nodes[0].id);
        } else {
          dialogTitle = this.props.t('linkEPCDiagram');
          diagramsList = this.state.treeExplorer.dataSource.filter(
            (elem) => elem.id !== this.state.diagramId && elem.idDiagramType === Constants.EPC_DIAGRAM_ID,
          );
        }

        this.setState({
          showLinkDiagramDialog: true,
          linkDiagramSearch: this.mapTreeDiagrams(diagramsList),
          dialogTitle,
          buttonText,
          linkDiagram,
        });
      }
    };

    this.visibleDialog = false;

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const addedNodes = [...mutation.addedNodes];

        if (
          this.state.onlyRead &&
          addedNodes &&
          addedNodes?.length > 0 &&
          Object.keys(this.symbolsInternalLinks)?.length > 0 &&
          (mutation.target.id?.includes('linkProcessOverview_originalIcon_groupElement') ||
            mutation.target.id?.includes('linkProcessOverview_externalIcon_groupElement'))
        ) {
          addedNodes
            .filter((node) => this.symbolsInternalLinks[node.id])
            .forEach((node) => {
              node.addEventListener('click', () => this.handleGlobalClick(this.symbolsInternalLinks[node.id]));
            });
        }
      });
    });
  }

  mapTreeDiagrams(diagramsList) {
    return this.contextMenuOptionSelected === Constants.IMPORT
      ? diagramsList.map((elem) => {
          return {
            id: elem.id,
            name: elem.name[this.state.language],
            isRecommendation: elem.isRecommendation,
            type: elem.type,
          };
        })
      : diagramsList.map((elem) => {
          const name = JSON.parse(elem.name);

          return {
            id: elem.id,
            name: name[this.state.language].PROCESS_NAME,
            isRecommendation: elem.isRecommendation,
            idDiagramType: elem.idDiagramType,
          };
        });
  }

  historyList(processNumber) {
    documentationServices
      .getVersionsControl(processNumber)
      .then((res) => {
        const dataHistory = res.data.versions.map((version) => ({
          processType: version.processType,
          name: version.diagramNames[this.state.language],
          version: version.version,
          author: version.author.commonName,
          validFrom: Utils.getFullFormattedDate(version.validFrom),
          validTo: Utils.getFullFormattedDate(version.validTo),
        }));

        this.setState({ dataHistory, showDialogHistory: true });
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  showDiagramWarning(message, nextRoute) {
    Dialog.showAlert({
      name: this.props.t('warning'),
      message,
      closeClick: () => this.props.history.push(nextRoute),
    });
  }

  componentDidMount() {
    this.changePageTitle();
    this.startDiagram();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setLocation();
      const pathname = Utils.cloneObject(this.props.location.pathname);

      if (pathname?.includes('closed-workflow')) {
        this.getClosedWorkflowInfo(parseInt(this.props.match.params.id, 10));
      } else {
        this.prepareContextualMenu();

        // Handle the url navigation here
        const { language } = this.state;
        const location = this.getLocation();

        // eslint-disable-next-line no-console
        console.log('on route change', this.props.location);
        this.turnOffInterval(this.intervalSaving);
        this.isSandbox = this.props.location.pathname?.includes('/sandbox');
        this.turnOffDiagramInUse();

        if (pathname.indexOf(location) === 1) {
          if (this.state.attributes[language]) {
            if (this.originalName !== this.state.attributes[language].PROCESS_NAME) {
              const resetName = this.state.treeExplorer?.dataSource?.find((elem) => elem.id === this.state.diagramId);
              JSON.parse(resetName.name)[this.state.language].PROCESS_NAME = this.originalName;
            }

            const idDiagram = parseInt(this.props.match.params.id, 10);
            this.reloadDiagram(idDiagram);
          } else {
            this.getDiagramInfo();
          }
        }
      }
    }

    this.getDiagramFieldConfig();
    this.getSymbolsFieldConfig(this.state.symbols);

    if (this.state.recommendationSymbols) {
      this.getSymbolsFieldConfig(this.state.recommendationSymbols, true);
    }

    if (!this.state.symbolsPalette?.length) this.prepareSymbolPalette();

    this.prepareUserHandles();
  }

  getDiagramFieldConfig(forceUpdate = false) {
    if (
      (this.state.diagramFieldConfig && !forceUpdate) ||
      this.props.formTypes.isLoading ||
      !this.state.diagramLoaded?.status ||
      !this.state.diagramType ||
      !this.state.attributes
    ) {
      return;
    }

    const diagramFormTypesCode = this.props.getFormTypesCode({
      status: this.state.diagramLoaded.status,
      type: Constants.DIAGRAM,
      variant: this.state.diagramType,
    });

    if (!this.props.formTypes[diagramFormTypesCode]) return;

    this.prepareDiagramAttributes(this.state.diagramLoaded);
    this.prepareWorkflowBar();

    let newFieldConfig = this.props.getReactReactiveFormFieldConfig(
      diagramFormTypesCode,
      this.props.featureFlags.isFreezed || this.state.diagramLoaded.onlyReadUsedBy || this.state.onlyRead,
    );

    if (!this.state.diagramLoaded?.dataSets) {
      this.props.availableLanguages.forEach((lang) => {
        delete newFieldConfig[lang].controls.DATASET_LINKAGE;
      });
    }
    newFieldConfig = this.addExtraMetaFieldConfig(newFieldConfig);
    newFieldConfig = this.addSpecialFieldsInitialValuesFieldConfig(newFieldConfig, this.state.attributes);

    this.setState(
      {
        diagramFieldConfig: newFieldConfig,
        fieldConfig: forceUpdate ? this.state.fieldConfig : newFieldConfig,
      },
      () => {
        if (
          !serviceUtils.isCentral() &&
          this.state.diagramLoaded.isRecommendationActive &&
          this.state.diagramLoaded.idRecommendation
        ) {
          this.prepareRecommendationDiagramAttributes(this.state.diagramLoaded);
        }
        const swimlane = Utils.getSwimlaneNode(diagramInstance);

        if (swimlane && this.state.diagramLoaded.status === Constants.STATUS.SANDBOX) {
          Utils.resetSwimlane(diagramInstance, this.toggleCanSelect);
        } else if (swimlane) {
          diagramInstance.remove(swimlane);
        }

        this.setState({ loading: this.state.releaseLoad || false, redoHistory: [], undoHistory: [] });
      },
    );
  }

  getSymbolsFieldConfig(symbols, isRecommendation) {
    if (
      (!symbols?.length && !diagramInstance?.nodes?.length) ||
      (symbols?.length && symbols.some(({ fieldConfig }) => fieldConfig)) ||
      this.props.formTypes.isLoading ||
      !this.state.diagramLoaded?.status
    ) {
      return;
    }

    const newSymbols = symbols.map((symbol) => {
      const symbolFormTypesCode = this.props.getFormTypesCode({
        status: this.state.diagramLoaded.status,
        type: Constants.SYMBOL,
        variant: this.getSymbolTypeByIdFront(symbol.idSymbolFront) || symbol.type?.toUpperCase(),
      });
      let newFieldConfig = {};
      if (!this.props.formTypes[symbolFormTypesCode]) return symbol;
      newFieldConfig = this.props.getReactReactiveFormFieldConfig(
        symbolFormTypesCode,
        this.props.featureFlags.isFreezed || this.state?.diagramLoaded.onlyReadUsedBy || symbol.idDiagramLink !== 0,
      );
      newFieldConfig = this.addExtraMetaFieldConfig(newFieldConfig);
      newFieldConfig = this.addSpecialFieldsInitialValuesFieldConfig(newFieldConfig, symbol.attributes);
      return { ...symbol, fieldConfig: newFieldConfig };
    });

    diagramInstance?.nodes.forEach((item) => {
      if (item.shape?.type !== Constants.IMAGE || symbols.some((symbol) => symbol.idSymbolFront === item.id)) return;
      const newImageSymbol = this.createSymbol(Constants.IMAGE.toUpperCase(), item.id);
      if (newImageSymbol.fieldConfig) newSymbols.push(newImageSymbol);
    });
    if (!newSymbols.some(({ fieldConfig }) => fieldConfig)) return;
    this.setState(isRecommendation ? { recommendationSymbols: newSymbols } : { symbols: newSymbols });
  }

  getObjectFieldConfig(object, attributes) {
    let fieldConfig = {};

    const objectFormTypesCode = this.props.getFormTypesCode({
      status: this.state.diagramLoaded?.status,
      type: object.type,
      variant: object.variant,
    });

    fieldConfig = this.props.getReactReactiveFormFieldConfig(
      objectFormTypesCode,
      this.props.featureFlags.isFreezed || this.state.diagramLoaded.onlyReadUsedBy,
    );

    fieldConfig = this.addExtraMetaFieldConfig(fieldConfig);
    fieldConfig = this.addSpecialFieldsInitialValuesFieldConfig(fieldConfig, attributes);

    return fieldConfig;
  }

  addExtraMetaFieldConfig(fieldConfig, idSymbolFront) {
    const newFieldConfig = { ...fieldConfig };
    Object.keys(fieldConfig).forEach((language) => {
      Object.entries(fieldConfig[language].controls).forEach(
        ([
          key,
          {
            meta: { fieldType },
          },
        ]) => {
          let extraMeta = {};

          if (key === Constants.ADDITIONAL_DOCUMENTS) {
            extraMeta = {
              ...extraMeta,
              getAdditionalDocumentValues: (uuid) => this.getAdditionalDocumentValues(uuid),
              getText: (item) => item.DOCUMENT_NAME,
              submitAdditionalDocumentsForm: (newValue) => this.submitAdditionalDocumentsForm(newValue),
            };
          }

          if (fieldType === Constants.INPUT_WYSIWYG) {
            extraMeta = {
              ...extraMeta,
              deletedImages: this.deletedImages,
              idDiagram: this.state.diagramId,
              ...(idSymbolFront && { idSymbolFront }),
              language,
              onDeleteImage: (imageUrl) => this.onDeleteImage(imageUrl),
              onInsertImage: (imageUrl) => this.onInsertImage(imageUrl),
              version: this.state.attributes?.[Constants.NOT_TRANSLATABLE]?.VERSION,
            };
          }

          newFieldConfig[language].controls[key].meta = {
            ...fieldConfig[language].controls[key].meta,
            ...extraMeta,
          };
        },
      );
    });

    return newFieldConfig;
  }

  // TODO: remove this method when all forms have been migrated to new useForm hook
  addSpecialFieldsInitialValuesFieldConfig(fieldConfig, attributes) {
    const newFieldConfig = { ...fieldConfig };
    Object.keys(fieldConfig).forEach((language) => {
      Object.entries(fieldConfig[language].controls).forEach(
        ([
          key,
          {
            meta: { fieldType },
          },
        ]) => {
          const workflowValue = Array.isArray(attributes) && attributes.find(({ code }) => code === key)?.value;
          const value =
            attributes[Constants.NOT_TRANSLATABLE]?.[key] ||
            (fieldType === Constants.INPUT_MULTISELECTION && workflowValue) ||
            workflowValue?.[0];
          if (fieldType === Constants.INPUT_MULTISELECTION && value) {
            const initialValue = Utils.cloneObject(value);
            newFieldConfig[language].controls[key].meta.valuesLoaded = initialValue;
          } else if (fieldType === Constants.DROPDOWN) {
            const newOptions = fieldConfig[language].controls[key].meta.options.map((option) => ({
              ...option,
              selected: option.value === value,
            }));
            newFieldConfig[language].controls[key].meta.options = newOptions;
          }
        },
      );
    });

    return newFieldConfig;
  }

  startDiagram() {
    const { id } = this.props.match.params;
    const isClosedWorkflow = this.props.location.pathname?.includes('closed-workflow') || this.isClosedWF;

    if (isClosedWorkflow) {
      this.getClosedWorkflowInfo(id);
    } else {
      this.getDiagramInfo(id);
    }
  }

  setLocation() {
    this.isPublished = this.props.location.pathname?.includes('published');
    this.isSandbox = this.props.location.pathname?.includes('/sandbox');
    this.isClosedWF = this.props.location.pathname?.includes('/closed-workflow');
  }

  getClosedWorkflowInfo(id) {
    workflowById(id)
      .then((res) => {
        const workflowInfo = res.data;
        const {
          id: workflowId,
          processNumber,
          status,
          diagramVersion: workflowDiagramVersion,
          rejectReason,
          rejectedBy,
        } = workflowInfo;

        services.getBasicDiagramInfo(processNumber).then((info) => {
          const diagramId = info.data.id;

          if (status === Constants.CLOSED_WF_STATUS.IN_PROGRESS) {
            this.props.history.push(`/diagram/${diagramId}`);
          } else if ([...Constants.CLOSED_WF_STATUS.KO, Constants.CLOSED_WF_STATUS.COMPLETED]?.includes(status)) {
            this.setState({ diagramId, workflowId, processNumber, rejectReason, rejectedBy }, () => {
              this.getDiagramInfo(workflowId, workflowDiagramVersion, workflowInfo);
            });
          }
        });
      })
      .catch((error) => services.handleServiceError(error));
  }

  getDiagramInfo(idDiagram, version = 'last', workflowData = undefined) {
    this.changePageTitle();
    const isPublished = this.props.location.pathname?.includes('published');
    const isClosedWorkflow = this.props.location.pathname?.includes('closed-workflow') || this.isClosedWF || !!workflowData;
    const id = idDiagram || this.props.match.params.id;

    // Get Diagram data
    if (isPublished) {
      this.getPublishedDiagramInfoById(id, version);
    } else if (isClosedWorkflow) {
      this.getClosedWorkflowDiagram(id, workflowData.processNumber);
    } else {
      this.getDiagramInfoById(id);
    }

    this.getTree();
  }

  onInsertImage(imageUrl) {
    this.insertedImages.push(imageUrl);
  }

  onDeleteImage(imageUrl) {
    if (this.deletedImages.some((existingImageUrl) => existingImageUrl === imageUrl)) return;

    this.deletedImages.push(imageUrl);
  }

  insertedImagesCleanup() {
    if (this.insertedImages?.length > 0) {
      documentationServices.deleteImages(this.insertedImages);
    }
  }

  deletedImagesCleanup() {
    if (this.deletedImages?.length > 0) {
      documentationServices
        .deleteImages(this.deletedImages)
        .then(() => {
          this.deletedImages = [];
        })
        .catch((err) => {
          documentationServices.handleServiceError(err);
        });
    }
    this.insertedImages = [];
  }

  componentWillUnmount() {
    this.turnOffInterval(this.intervalSaving);
    this.turnOffInterval(this.intervalInUse);
    this.insertedImagesCleanup();
    this.turnOffDiagramInUse();
  }

  diagramIsRecommendation(processNumber) {
    if (this.props.featureFlags.showRecommendationOverview) {
      recommendationServices
        .isRecommendation(processNumber)
        .then((response) => {
          this.isRecommendation = response.data;
        })
        .catch((err) => {
          recommendationServices.handleServiceError(err);
        });
    }
  }

  getRecommendationDiagram(idRecommendation) {
    recommendationServices
      .getRecommendationDiagram(idRecommendation)
      .then(({ data }) => {
        this.setState({ recommendedMessage: data.recommendedMessage });
        this.prepareRecommendationDiagram(data.recommendedDiagram);
      })
      .catch((err) => {
        recommendationServices.handleServiceError(err);
      });
  }

  getPublishedDiagramInfoById(id, version = 'last') {
    services
      .getDiagram(id)
      .then(({ data: diagramLoaded }) => {
        const processNumber = JSON.parse(diagramLoaded.attributes)[Constants.NOT_TRANSLATABLE].PROCESS_NUMBER;
        const diagramType =
          diagramLoaded.idDiagramType === Constants.EPC_DIAGRAM_ID ? Constants.EPC_DIAGRAM : Constants.VCD_DIAGRAM;
        if (id === '1' && !processNumber) {
          this.showDiagramWarning(this.props.t('errors.nothingPublished'), '/dashboard');
        } else if (!processNumber) {
          const landscapeRedirect = window.location.href?.includes(E2E) ? '/e2e' : '';
          this.showDiagramWarning(this.props.t('errors.emptyPublished'), `/published${landscapeRedirect}/1`);
        } else {
          this.props.fetchFormTypes(
            this.props.getFormTypesCode({
              isGroupFetch: true,
              status: Constants.STATUS.PUBLISHED,
              type: Constants.DIAGRAM,
              variant: diagramType,
            }),
          );

          this.diagramIsRecommendation(processNumber);
          this.setState(
            {
              isPublished: this.isPublished,
              onlyRead: this.isPublished,
            },
            () => {
              if (!diagramLoaded) return;
              this.loadDiagramPublished(processNumber, version);
            },
          );
        }
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  getClosedWorkflowDiagram(id, processNumber) {
    services.getClosedWorkflow(id, processNumber).then(async (res) => {
      const diagramData = res.data;
      const { attributes, status, idDiagramType, allUsers, rejectReason } = diagramData;

      const formCode = this.props.getFormTypesCode({
        isGroupFetch: true,
        status,
        rejectReason,
        type: Constants.DIAGRAM,
        variant: DiagramUtils.getDiagramType(idDiagramType),
      });
      await this.props.fetchFormTypes(formCode);

      const diagramType = DiagramUtils.getDiagramType(idDiagramType);

      this.setState(
        {
          diagramLoaded: diagramData,
          diagramType,
          attributes,
          onlyRead: true,
          users: allUsers,
          isPublished: this.isPublished,
        },
        () => {
          this.prepareContextualMenu();
          this.prepareDiagram(diagramData);
          this.getDiagramFieldConfig(true);
        },
      );
    });
  }

  getDiagramInfoById(id) {
    services
      .getDiagram(id)
      .then(({ data: diagramLoaded }) => {
        if (this.props.featureFlags.isFreezed) {
          diagramLoaded.onlyRead = this.props.featureFlags.isFreezed;
        }
        if (this.isSandbox && diagramLoaded.status !== Constants.STATUS.SANDBOX) {
          this.showDiagramWarning(this.props.t('errors.notSandbox'), '/sandbox-overview');
        } else if (!this.isSandbox && diagramLoaded.status === Constants.STATUS.SANDBOX) {
          this.showDiagramWarning(this.props.t('errors.notDiagram'), '/diagram/1');
        } else {
          const diagramType = DiagramUtils.getDiagramType(diagramLoaded.idDiagramType);
          let userBlock = '';

          if (diagramLoaded.onlyReadUsedBy) {
            const user = diagramLoaded.allUsers.find(({ code }) => code === diagramLoaded.onlyReadUsedBy);

            userBlock = user ? user.commonName : diagramLoaded.onlyReadUsedBy;
          }

          this.props.fetchFormTypes(
            this.props.getFormTypesCode({
              isGroupFetch: true,
              status: diagramLoaded.status,
              type: Constants.DIAGRAM,
              variant: diagramType,
            }),
          );

          this.setState(
            {
              diagramType,
              isPublished: this.isPublished,
              onlyRead: this.isPublished || (diagramLoaded.onlyRead && !diagramLoaded.catalogObjectsMerge),
              userBlock,
              users: this.mergeUsers(diagramLoaded.allUsers),
            },
            () => {
              if (this.isSandbox) {
                this.loadSandboxTree();
              } else if (diagramType === Constants.VCD_DIAGRAM || diagramType === Constants.SIPOC_DIAGRAM) {
                this.loadImportSandboxDiagrams();
              }

              this.prepareContextualMenu();

              if (DiagramUtils.isWorkflow(diagramLoaded)) {
                const processNumber = JSON.parse(diagramLoaded.attributes)[Constants.NOT_TRANSLATABLE].PROCESS_NUMBER;

                getBasicWorkflowInfo(processNumber)
                  .then((res) => {
                    if (res.data.workflowType?.includes(Constants.HISTORICAL_WF)) {
                      this.loadDiagramPublished(processNumber, 'last', true);
                    } else {
                      this.prepareDiagram(diagramLoaded);
                    }
                  })
                  .catch((err) => {
                    services.handleServiceError(err);
                  });
              } else {
                this.prepareDiagram(diagramLoaded);
              }
            },
          );
        }
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  toggleAutoSave(isOn = !this.state.isAutoSaveOn) {
    this.turnOffInterval(this.intervalSaving);

    if (isOn) {
      this.intervalSaving = setInterval(() => {
        if (!this.isDiagramLoading() && !this.state.savingDiagram) {
          diagramInstance.clearSelection();
          this.saveDiagram()
            .then((response) => {
              this.updateDiagramData(response);
            })
            .catch((error) => this.errorSavingDiagram(error));
        }
      }, 120000); // AutoSave every 2 minutes
    }

    this.setState({ isAutoSaveOn: isOn });
  }

  turnOffInterval(interval) {
    clearInterval(interval);
  }

  turnOnDiagramInUse() {
    this.turnOffInterval(this.intervalInUse);

    if (
      this.state.onlyRead ||
      (this.state.diagramLoaded.status === Constants.STATUS.WORKFLOW && this.state.diagramLoaded.catalogObjectsMerge)
    ) {
      return;
    }

    this.inUseDiagram(true);

    this.intervalInUse = setInterval(() => {
      if (!this.state.isAutoSaveOn) {
        this.inUseDiagram(true);
      }
    }, 240000); // inUse=true every 4 minutes
  }

  turnOffDiagramInUse() {
    if (this.state.onlyRead) return;

    this.inUseDiagram(false);
  }

  inUseDiagram(inUse = false) {
    if (!inUse) {
      this.turnOffInterval(this.intervalInUse);
    }

    services?.inUseDiagram(this.state.diagramId, inUse).catch((error) => {
      this.errorSavingDiagram(error);
    });
  }

  reloadDiagram(id, reloadWorkflowMessage) {
    diagramInstance.clear();
    this.setLocation();

    setTimeout(() => {
      this.setState(
        {
          releaseLoad: false,
          breadcrumb: this.breadcrumbNodes?.length === 0 || parseInt(id, 10) === 1 ? '' : this.state.breadcrumb,
          loading: true,
          selectionObject: '',
          attributes: [],
          symbolsPalette: [],
          catalogObjects: {},
          diagramId: id,
          workflowAttributes: {},
          loadWorkflow: false,
          workflowDataForm: {},
          language: this.state.language || this.props.i18n.language.toUpperCase(),
          fieldConfig: {},
          userTransferTask: [],
          onlyReadWorkflow: false,
          backToGroup: false,
          loadingTreeData: true,
          disablePaste: true,
          undoHistory: [],
          redoHistory: [],
          inFavorite: false,
          swimlaneButtonLabel: 'ON',
          isWorkflow: this.state.isWorkflow && this.state.diagramId === id,
          diagramLoaded: {},
          diagramType: null,
          diagramFieldConfig: null,
          symbolSelected: '',
        },
        () => {
          services
            .reloadDiagram(id)
            .then((response) => {
              const [diagramLoaded] = response;
              const isPublished = this.props.location.pathname?.includes('published');
              this.modelersAssigned = '';
              this.userHandles = [];

              if (isPublished) {
                const processNumber = JSON.parse(diagramLoaded.attributes)[Constants.NOT_TRANSLATABLE].PROCESS_NUMBER;

                this.diagramIsRecommendation(processNumber);
                this.setState(
                  {
                    onlyRead: true,
                    loadingLinkedDiagram: false,
                  },
                  () => {
                    if (processNumber) {
                      this.getTree().then(() => {
                        this.loadDiagramPublished(processNumber).then(() => {
                          this.fetchFormTypes(diagramLoaded.status, diagramLoaded.idDiagramType);
                        });
                      });
                    } else {
                      this.setState({ loadingTreeData: false, loading: false });
                      if (!this.state.diagramFieldConfig) {
                        this.showDiagramWarning(this.props.t('errors.nothingPublished'), '/dashboard');
                      }
                    }
                  },
                );
              } else {
                let userBlock = '';
                if (this.props.featureFlags.isFreezed) {
                  diagramLoaded.onlyRead = this.props.featureFlags.isFreezed;
                }
                if (diagramLoaded.onlyReadUsedBy) {
                  const user = diagramLoaded.allUsers.find(({ code }) => code === diagramLoaded.onlyReadUsedBy);

                  userBlock = user ? user.commonName : diagramLoaded.onlyReadUsedBy;
                }

                this.setState(
                  {
                    breadcrumb: '',
                    objectsInfo: [],
                    onlyRead: diagramLoaded.onlyRead,
                    users: this.mergeUsers(diagramLoaded.allUsers),
                    userBlock,
                    isModelingEditable:
                      Constants.MODELING_STATUS_CODES?.includes(diagramLoaded.status) && !diagramLoaded.onlyRead,
                    isPublished: false,
                  },
                  () => {
                    if (!this.state.onlyRead && this.state.ctxMenuItems?.length === 0) {
                      this.prepareContextualMenu();
                    }

                    if (DiagramUtils.isWorkflow(diagramLoaded)) {
                      const workflowProcessNumber = JSON.parse(diagramLoaded.attributes)[Constants.NOT_TRANSLATABLE]
                        .PROCESS_NUMBER;

                      getBasicWorkflowInfo(workflowProcessNumber)
                        .then((res) => {
                          if (res.data.workflowType?.includes(Constants.HISTORICAL_WF)) {
                            this.loadDiagramPublished(workflowProcessNumber, 'last', true);
                          } else {
                            this.prepareDiagram(diagramLoaded);
                          }
                        })
                        .catch((err) => {
                          services.handleServiceError(err);
                        });
                    } else {
                      this.prepareDiagram(diagramLoaded);
                    }

                    this.getTree().then(() => {
                      this.fetchFormTypes(diagramLoaded.status, diagramLoaded.idDiagramType).then(() => {
                        if (this.state.loadingLinkedDiagram) {
                          this.setState({ loadingLinkedDiagram: false }, () => {
                            diagramInstance.clearSelection();
                            this.saveDiagram()
                              .then((resp) => {
                                this.updateDiagramData(resp);
                              })
                              .catch((error) => this.errorSavingDiagram(error));
                          });
                        }
                      });
                    });

                    this.changeLanguageAbbreviation(this.state.language);
                    const diagramType = DiagramUtils.getDiagramType(diagramLoaded.idDiagramType);
                    if (this.isSandbox) {
                      this.loadSandboxTree();
                    } else if (diagramType === Constants.VCD_DIAGRAM || diagramType === Constants.SIPOC_DIAGRAM) {
                      this.loadImportSandboxDiagrams();
                    }

                    if (reloadWorkflowMessage) {
                      setTimeout(() => {
                        // APLAALO: This setLanguage call seems to be unnecessary, leaving it here just in case
                        // this.setLanguage(this.state.language);
                        Dialog.showAlert({
                          name: this.props.t('success'),
                          message: reloadWorkflowMessage,
                        });
                      }, 100);
                    }
                  },
                );
              }
            })
            .catch((err) => {
              services.handleServiceError(err);
            });
        },
      );
    }, 250);
  }

  changeLanguageAbbreviation(language) {
    const connectorsList = diagramInstance.connectors;

    if (connectorsList) {
      for (let i = 0; i < connectorsList?.length; i++) {
        if (connectorsList[i].addInfo) {
          connectorsList[i].annotations[0].content = this.props.t(`abbreviation.${connectorsList[i].addInfo}`, { lng: language });
        }
      }
    }
  }

  getNodeSelected() {
    let symbol = null;
    let objectShared = null;
    const isRecommendationActive = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
    const instance = isRecommendationActive ? recommendationDiagramInstance : diagramInstance;
    const catalogObjectsUsed = isRecommendationActive
      ? this.state.recommendationCatalogObjects
      : this.state.objectsCatalogUsedDiagram;
    const objectsInfo = isRecommendationActive ? this.state.recommendationObjectsInfo : this.state.objectsInfo;
    const objectsUsed = isRecommendationActive ? this.state.objectsUsedRecommendationDiagram : this.state.objectsUsedDiagram;
    const symbols = isRecommendationActive ? this.state.recommendationSymbols : this.state.symbols;

    const nodeSelected = instance.selectedItems?.nodes?.[0]?.id || this.state.symbolSelected;
    const isNodeinDiagram = instance.nodes.some(({ id }) => id === nodeSelected);

    if (nodeSelected && isNodeinDiagram) {
      if (
        nodeSelected?.includes('OBJECT') &&
        (nodeSelected?.includes(Constants.IT_SYSTEM) || nodeSelected?.includes(Constants.ROLE))
      ) {
        const selectionObjectSplitId = nodeSelected.split('_');
        const catalogObject = catalogObjectsUsed.find(
          (elem) => elem.id === Number(selectionObjectSplitId[selectionObjectSplitId?.length - 2]),
        );

        if (catalogObject) {
          symbol = { ...catalogObject, isCatalogObject: true };
        }
      } else if (nodeSelected?.includes('OBJECT')) {
        objectShared = objectsInfo.find((elem) => elem.idFront === this.state.selectionObject);

        if (objectShared) {
          symbol = objectsUsed.find((elem) => elem.idFront === objectShared.idObjectShared);
        }
      } else {
        symbol = symbols.find((elem) => elem.idSymbolFront === this.state.symbolSelected);
      }
    }

    return symbol;
  }

  getSelectedItemsConstraints() {
    if (Utils.isSwimlaneSelected(diagramInstance)) {
      return SelectorConstraints.All & ~(SelectorConstraints.ToolTip | SelectorConstraints.UserHandle);
    }

    return SelectorConstraints.All;
  }

  getUserHandles() {
    if (this.state.onlyRead || !diagramInstance) return [];

    const { nodes, connectors } = diagramInstance.selectedItems;

    // Check multiple selection
    if (
      nodes?.length > 1 ||
      connectors?.length > 1 ||
      (nodes?.length === 0 && connectors?.length === 0) ||
      (nodes?.length > 0 && connectors?.length > 0)
    ) {
      return [];
    }

    const selectedSymbolType = this.getSymbolTypeByIdFront(nodes[0]?.id);

    if (selectedSymbolType) {
      const nodeSelected = this.state.symbols.find((symbol) => symbol.idSymbolFront === nodes[0].id);

      if (nodeSelected?.idDiagramLink > 0) {
        return [DiagramUtils.getCommonHandle(0)];
      }

      return this.userHandles[selectedSymbolType];
    }

    if (this.userHandles && Object.keys(this.userHandles)?.length > 0) {
      let topValue = 0;
      let leftValue = 0;

      if (connectors?.length > 0) {
        leftValue = 20;
        topValue = 20;
      }

      return [DiagramUtils.getCommonHandle(leftValue, topValue)];
    }
  }

  getSymbolTypeByIdFront(id) {
    return id && this.props.diagramConfig.symbols[this.state.diagramType]?.find((code) => id.startsWith(code));
  }

  getObjectTypeByIdFront(id) {
    return Constants.OBJECTS.find((elem) => id.indexOf(elem.replace('_', '')) > -1);
  }

  getFormData() {
    if (
      this.state.isObject &&
      (this.state.selectionObject?.includes(Constants.ROLE) ||
        this.state.selectionObject?.includes(Constants.IT_SYSTEM.replace('_', '')))
    ) {
      const keys = Object.keys(this.state.attributesCatalogObject);
      const attributesFields = this.state.attributesCatalogObject;

      // Inicializar todos los campos vacios para que no salga su placeholder
      for (let i = 0; i < keys?.length; i++) {
        if (!attributesFields[keys[i]]) {
          attributesFields[keys[i]] = ' ';
        }
      }

      return attributesFields;
    }

    const symbolSelected = this.getNodeSelected();
    const attributesForm = {};
    let attributes = this.state.diagramLoaded || {};

    if (symbolSelected) {
      // Symbols and Not Catalog Objects
      attributes = Utils.getObjectAttributes(symbolSelected.attributes);
    } else {
      // Diagram
      const isRecommendationActive = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
      attributes = isRecommendationActive ? { ...this.state.recommendationAttributes } : { ...this.state.attributes };
    }
    attributesForm[this.state.language] = {
      ...attributes[this.state.language],
      ...attributes[Constants.NOT_TRANSLATABLE],
    };

    return attributesForm[this.state.language];
  }

  setContextualMenu(symbolId) {
    const symbolType = this.getSymbolTypeByIdFront(symbolId);
    const obj = this.state.ctxMenuItems.find((item) => item.code === symbolType);

    if (obj) {
      this.menuItems = obj.options;
    }
  }

  bindEvents() {
    this.select = this.select.bind(this);
  }

  beforeOpen() {
    const ctxMenuItems = this.state.ctxMenuItems.find((elem) => diagramInstance.selectedItems.nodes[0].id?.includes(elem.code));
    cMenu.items.forEach((item) => {
      ctxMenuItems.options
        .find((elem) => elem.id === item.id)
        .text.forEach((el) => el[this.state.language] !== undefined && (item.text = el[this.state.language]));
    });
  }

  async prepareContextualMenu() {
    let currentLevel;
    const { id } = this.props.match.params;

    const environment = this.props.location.pathname?.includes('published')
      ? Constants.AREAS.PUBLISHED
      : Constants.AREAS.MODELING;

    await getProcessLevels(id, environment).then((response) => {
      const currentElement = response.data.find((element) => String(element.id) === id);
      if (!currentElement) return;
      currentLevel = response.data.indexOf(currentElement) + 1;
    });

    const ctxMenuItems = DiagramUtils.getContextMenuOptions(this.props.t).map((item) => {
      const options = item.options.map((option) => ({
        text: this.props.availableLanguages.map((language) => ({
          [language]: this.props.t(`contextualMenu.${option.code}`, { lng: language }),
        })),
        id: option.code,
        target: '.e-elementcontent',
        iconCss: option.icon,
      }));

      if (
        (item.code?.includes(Constants.OPEN_PROCESS_OVERVIEW) || item.code?.includes(Constants.CLOSED_PROCESS_OVERVIEW)) &&
        this.props.featureFlags.NEPOS &&
        this.context.checkRoles([Constants.QI, Constants.QI_MODELER])
      ) {
        options.push(
          ...DiagramUtils.getNeposOptions(this.props.t, this.props.availableLanguages, this.props.featureFlags, currentLevel),
        );
      }

      if (
        (item.code?.includes(Constants.OPEN_PROCESS_OVERVIEW) || item.code?.includes(Constants.CLOSED_PROCESS_OVERVIEW)) &&
        this.context.checkRole(Constants.QI)
      ) {
        options.push(...DiagramUtils.getQIOptions(this.props.t, this.props.availableLanguages));
      }

      if (
        (item.code?.includes(Constants.OPEN_PROCESS_OVERVIEW) || item.code?.includes(Constants.CLOSED_PROCESS_OVERVIEW)) &&
        this.context.checkRole(Constants.ADMINISTRATOR)
      ) {
        options.push(...DiagramUtils.getAdministratorOptions(this.props.t, this.props.availableLanguages));
      }

      return {
        ...item,
        options,
      };
    });

    this.setState({
      ctxMenuItems,
    });
  }

  prepareUserHandles() {
    if (this.userHandles?.length > 0 || !this.props.diagramConfig.symbols[this.state.diagramType]) return;

    const userHandles = [];

    if (!this.state.onlyRead) {
      this.props.diagramConfig.symbols[this.state.diagramType]?.forEach((code) => {
        let handles = [];
        const hasMenu = this.state.ctxMenuItems.find((elem) => elem.code === code);

        if (hasMenu && this.userHasContextMenu()) {
          handles = [DiagramUtils.getContextMenuHandle()];
        }

        if (this.context.checkPermission('epcRD') || this.context.checkRole('USER')) {
          handles = [...handles, DiagramUtils.getCommonHandle(handles?.length * 30)];
        }

        userHandles[code] = handles;
      });
    }

    this.userHandles = userHandles;
  }

  userHasContextMenu() {
    const checkPermissionEpcVcdCreate = this.context.checkPermission('epcCreate') || this.context.checkPermission('vcdCreate');
    const checkPermissionEpcRD = this.context.checkPermission('epcRD');
    const checkPermissionUser = this.context.checkRole('USER');

    return checkPermissionEpcVcdCreate || checkPermissionEpcRD || checkPermissionUser;
  }

  mergeUsers(users) {
    const codes = this.state.users.map((elem) => elem.code);
    const filteredUsers = users.filter((user) => !codes?.includes(user.code));

    return [...this.state.users, ...filteredUsers];
  }

  loadObjectsPending(type) {
    this.setState({ loading: true }, () => {
      objectCatalogService
        .getPending(type)
        .then((response) => {
          const catalogObjects = { ...this.state.catalogObjects };
          catalogObjects[type === Constants.ROLE_OBJECT_TYPE ? Constants.ROLE : Constants.IT_SYSTEM].pending =
            response.data?.results || [];

          this.setState({ catalogObjects, loading: false });
        })
        .catch((err) => {
          services.handleServiceError(err);
          this.setState({ loading: false });
        });
    });
  }

  getTree() {
    const isPublished =
      this.props.location.pathname?.includes('published') || this.props.location.pathname?.includes('closed-workflow');

    return services
      .getDiagramTreeStructure(isPublished)
      .then((response) => {
        const tree = response.data.results;

        this.prepareTree(tree);
        this.breadcrumbNodes = [];
        this.prepareBreadCrumb(tree, this.state.diagramId);

        this.setState({ loadingTreeData: false });
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  fetchFormTypes(status, idDiagramType) {
    return this.props.fetchFormTypes(
      this.props.getFormTypesCode({
        isGroupFetch: true,
        status,
        type: Constants.DIAGRAM,
        variant: DiagramUtils.getDiagramType(idDiagramType),
      }),
    );
  }

  prepareTree(tree) {
    this.setState({
      treeExplorer: Utils.prepareTree(tree, this.state.diagramId, this.isSandbox, this.state.language),
      loadingTree: false,
    });
  }

  breadCrumbClick = (diagramId) => {
    const route = this.getDiagramRoute(diagramId);

    if (this.props.location.pathname !== route) {
      this.props.history.push(route);
    }
  };

  checkBreadCrumbLength(widthDivDiagramcontent, breadcrumbNodesCopy) {
    const breadCrumbs = !document.getElementById('bread-crumb');
    if (!breadCrumbs) return;

    const widthDivBreadCrumb = breadCrumbs.clientWidth;

    if (widthDivBreadCrumb > widthDivDiagramcontent) {
      if (breadcrumbNodesCopy?.length > 3) {
        breadcrumbNodesCopy.splice(0, breadcrumbNodesCopy?.length - 3);
      }

      breadcrumbNodesCopy[0] = {
        id: breadcrumbNodesCopy[0].id,
        name: '...',
        pid: breadcrumbNodesCopy[0].pid ? breadcrumbNodesCopy[0].pid : 0,
      };

      const itemDOMS = breadcrumbNodesCopy.map(({ id, name }) =>
        name === '...' ? (
          <span className="breadCrumbPoints breadCrumbLink" key={id}>
            {name}
          </span>
        ) : (
          <span
            className="breadCrumbLink"
            key={id}
            onClick={() => {
              this.breadCrumbClick(id);
            }}
          >
            {name}
          </span>
        ),
      );

      this.setState({ breadcrumb: itemDOMS });
    }
  }

  prepareBreadCrumb(tree, pid) {
    if (this.isClosedWF) return;

    if (!pid) {
      const TOTAL_ITEMS = this.breadcrumbNodes?.length;

      if (TOTAL_ITEMS > 1) {
        const itemDOMS = this.breadcrumbNodes.reverse().map(({ id, name }) => (
          <span
            className="breadCrumbLink"
            key={id}
            onClick={() => {
              this.breadCrumbClick(id);
            }}
          >
            {name}
          </span>
        ));
        const widthDivDiagramcontent = document.getElementById('diagramcontent')?.offsetWidth;

        this.setState({ breadcrumb: itemDOMS });
        const breadcrumbNodesCopy = [...this.breadcrumbNodes];
        this.checkBreadCrumbLength(widthDivDiagramcontent, breadcrumbNodesCopy);
      }
    }

    const node = tree?.find((elem) => elem.id === pid);

    if (node) {
      const data = {
        id: node.id,
        name: JSON.parse(node.name)[this.state.language].PROCESS_NAME,
        pid: node.pid ? node.pid : 0,
      };
      this.breadcrumbNodes.push(data);
      this.prepareBreadCrumb(tree, node.pid);
    }
  }

  isDiagramSelected() {
    const isRecommendationActive = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
    const instance = isRecommendationActive ? recommendationDiagramInstance : diagramInstance;

    return this.state.symbolSelected === '' || instance.selectedItems.nodes?.length === 0;
  }

  setWorkflowAttributes = (attributes) => {
    this.setState({ workflowAttributes: attributes, workflowDataForm: {}, loadWorkflow: false });
  };

  setWorkflowData = (data) => {
    this.setState({ workflowDataForm: data, loadWorkflow: true });
  };

  setWorkflowStage = (stage) => {
    this.setState({ currentStage: stage });
  };

  setOnlyReadReason = (readReason) => {
    this.setState({ onlyReadReason: readReason });
  };

  setOnlyReadWorkflow = (readWorkflow) => {
    this.setState({ onlyReadWorkflow: readWorkflow });
  };

  setBackToGroup = (bckToGroup) => {
    this.setState({ backToGroup: bckToGroup });
  };

  rejectWorkflow() {
    Dialog.showAlert({
      name: this.props.t('success'),
      message: this.props.t('textinputDialog.rejected', { type: this.props.t(`${this.state.diagramType}`) }),
      closeClick: () => this.props.history.go(0),
    });
  }

  finishWorkflow(isHistorical) {
    Dialog.showAlert({
      name: this.props.t('success'),
      message: this.props.t('textinputDialog.finished', { type: this.props.t(`${this.state.diagramType}`) }),
      closeClick: () => (isHistorical ? this.props.history.push('/') : this.props.history.go(0)),
    });
  }

  switchVisibility(annotation) {
    annotation.visibility = false;
    annotation.visibility = true;
  }

  prepareConnectorConstraints(isRecommendation = false) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    instance.connectors.forEach((connector) => {
      if (isRecommendation || this.state.onlyRead) {
        connector.constraints = ConnectorConstraints.None;
      } else if (!connector.sourceID?.includes(Constants.OBJECT) && !connector.targetID?.includes(Constants.OBJECT)) {
        connector.constraints |=
          ConnectorConstraints.DragSegmentThumb | (ConnectorConstraints.Interaction & ~ConnectorConstraints.Drag);
      }
    });
  }

  prepareNodeConstraints(isRecommendation = false) {
    (isRecommendation ? recommendationDiagramInstance : diagramInstance).nodes.forEach((node) => {
      if (isRecommendation || this.state.onlyRead) {
        node.constraints =
          NodeConstraints.Select | NodeConstraints.PointerEvents | NodeConstraints.ReadOnly | NodeConstraints.HideThumbs;
      } else if (node.shape.type.toUpperCase() === Constants.TEXT) {
        node.constraints = NodeConstraints.Default & ~NodeConstraints.Tooltip;
      } else if (node.id?.includes('swimlane')) {
        node.constraints &= ~(NodeConstraints.Select | NodeConstraints.PointerEvents);
      } else if (node.id.startsWith(Constants.PROCESS_STEP)) {
        node.constraints = NodeConstraints.Default & ~(NodeConstraints.Rotate | NodeConstraints.Tooltip | NodeConstraints.Drag);
      } else {
        node.constraints = NodeConstraints.Default & ~(NodeConstraints.Rotate | NodeConstraints.Tooltip);
      }

      if (node.shape.type.toUpperCase() === Constants.TEXT && node.shape.content === '') {
        document.getElementById(node.id).style.stroke = Constants.RED_COLOR;
        document.getElementById(node.id).style.strokeWidth = 2;
      }

      if (node.annotations && node.annotations?.length > 0) {
        this.switchVisibility(node.annotations[0]);
      }
      const nodeConstraints = DiagramUtils.getNodeObjectConstraints(node, isRecommendation || this.state.onlyRead);

      if (nodeConstraints) {
        node.constraints = nodeConstraints;
      }
    });
  }

  prepareWorkflowBar(diagram = this.state.diagramLoaded, isReloadingDiagram = false) {
    this.workflowBar = '';
    const isWorkflow = DiagramUtils.isWorkflow(diagram);
    const isClosedWorkflow = this.props.location.pathname?.includes('closed-workflow') || this.isClosedWF;
    const attributes =
      diagram.attributes && typeof diagram.attributes === 'string' ? JSON.parse(diagram.attributes) : diagram.attributes;

    if (isWorkflow || isClosedWorkflow) {
      this.workflowBar = (
        <WorkflowBarWrapper
          addExtraMetaFieldConfig={(fieldConfig, idSymbolFront) => this.addExtraMetaFieldConfig(fieldConfig, idSymbolFront)}
          addSpecialFieldsInitialValuesFieldConfig={(fieldConfig, wfAttributes) =>
            this.addSpecialFieldsInitialValuesFieldConfig(fieldConfig, wfAttributes)
          }
          backToGroup={this.setBackToGroup}
          diagramId={diagram.id}
          diagramName={attributes?.[EN]?.PROCESS_NAME || this.state.processName}
          finishWorkflow={(args) => this.finishWorkflow(args)}
          idProcess={attributes?.[Constants.NOT_TRANSLATABLE]?.PROCESS_NUMBER}
          isClosedWorkflow={isClosedWorkflow}
          isRecommended={Constants.RECOMMENDATION_TYPES?.includes(this.state.diagramLoaded?.recommendationStatusType)}
          key={new Date()}
          loading={isReloadingDiagram && this.state.loadingWorkflowBar}
          onlyReadReason={this.setOnlyReadReason}
          onlyReadSteps={!this.state.loading}
          onlyReadWorkflow={this.setOnlyReadWorkflow}
          qualityGroups={this.props.formTypes[
            this.props.getFormTypesCode({
              status: this.state.diagramLoaded?.status,
              type: Constants.DIAGRAM,
              variant: this.state.diagramType,
            })
          ]?.[Constants.NOT_TRANSLATABLE]?.[Constants.QA_INSPECTOR_GROUP].properties.OPTIONS.replace('[', '')
            .replace(']', '')
            .split(', ')}
          rejected={() => this.rejectWorkflow()}
          rejectedBy={this.state.workflowData?.rejectedBy || this.state.rejectedBy || ''}
          rejectReason={this.state.workflowData?.rejectReason || this.state.rejectReason || ''}
          reloadDiagram={(diagramId) => this.reloadDiagram(diagramId)}
          returnLoading={(isLoading) => this.setState({ loadingWorkflowBar: isLoading })}
          setWorkflowData={this.setWorkflowData}
          setWorkflowFieldConfig={this.setWorkflowAttributes}
          stageType={this.setWorkflowStage}
          workflowId={this.state.workflowId}
        />
      );

      this.setState({
        workflowBar: this.workflowBar,
      });
    }
  }

  prepareSymbolPalette() {
    if (this.state.isWorkflow || !this.props.diagramConfig.symbols[this.state.diagramType]) return;

    const newPaletteClass = this.state.onlyRead ? '' : unblockedClass;
    let symbolSelected;
    const list = [];
    const palette = DiagramUtils.getGeneralPalette(this.props.t, this.state.language);

    for (let i = 0; i < this.props.diagramConfig.symbols[this.state.diagramType]?.length; i++) {
      symbolSelected = palette.find((symbol) => symbol.id === this.props.diagramConfig.symbols[this.state.diagramType][i]);

      if (symbolSelected) {
        list.push(symbolSelected);
      }
    }
    this.setState({
      symbolsPalette: list,
      blockedDiagramClass: newPaletteClass,
    });
  }

  changePageTitle() {
    let page = this.isPublished ? 'processMap' : 'Diagram';
    page = this.isSandbox ? 'sandboxTitle' : page;
    page = this.isClosedWF ? 'workflows.closedWorkflows' : page;
    titleService.updatePageTitle(this.props.t(page), this.isClosedWF ? this.state.workflowId : this.state.diagramId);
  }

  resetReadOnlyInstanceAttributes(isRecommendation = false) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    instance.snapSettings.constraints = SnapConstraints.None;
    instance.selectedItems.userHandles = [];
    instance.selectedItems.constraints &= ~SelectorConstraints.ToolTip;
  }

  prepareDiagram(diagramLoad) {
    const {
      id,
      onlyRead,
      status,
      catalogObjectsMerge,
      idDiagramType,
      onlyReadReason,
      objectCatalogList,
      objectsUsedDiagram,
      isRecommendationActive,
      idRecommendation,
    } = diagramLoad;

    const isOnlyRead = onlyRead && !catalogObjectsMerge;
    const diagramType = DiagramUtils.getDiagramType(idDiagramType);

    this.changePageTitle();
    this.loadDiagram(diagramLoad);
    this.getCatalogs(diagramType, objectCatalogList);
    this.setState(
      {
        diagramLoaded: diagramLoad,
        isCatalogObjectsMergeDialogOpen: catalogObjectsMerge,
        isModelingEditable: Constants.MODELING_STATUS_CODES?.includes(status) && !isOnlyRead,
        isPublished: false,
        isWorkflow: DiagramUtils.isWorkflow(diagramLoad),
        attributesOpen: 'openAttributes',
        onlyRead: this.isClosedWF || isOnlyRead,
        blockedDiagramClass: isOnlyRead ? '' : unblockedClass,
        rejectReason: diagramLoad.rejectReason,
        onlyReadReason,
        diagramId: id,
        diagramType,
        objectCatalogList,
        objectsCatalogUsedDiagram: objectCatalogList,
        objectsUsedDiagram: DiagramUtils.prepareObjectsUsedDiagram(objectsUsedDiagram) || [],
      },
      () => {
        diagramInstance.constraints = DiagramConstraints.Default;

        if (!serviceUtils.isCentral() && isRecommendationActive && idRecommendation) {
          this.getRecommendationDiagram(idRecommendation);
        }

        this.resetZoom(diagramType);
        this.prepareConnectorConstraints();
        this.prepareNodeConstraints();
        this.loadObjectsDiagram();
        this.turnOffInterval(this.intervalSaving);
        this.turnOnDiagramInUse();

        if (this.state.onlyRead) this.resetReadOnlyInstanceAttributes();
        if (this.isClosedWF) this.prepareWorkflowBar();
      },
    );
  }

  prepareDiagramPublished(diagramLoad) {
    this.changePageTitle();
    this.loadDiagram(diagramLoad);
    const diagramType = DiagramUtils.getDiagramType(diagramLoad.idDiagramType);
    this.getCatalogs(diagramType, diagramLoad.objectCatalogList);

    this.setState(
      {
        diagramLoaded: diagramLoad,
        isModelingEditable: false,
        attributesOpen: diagramLoad.idDiagramParent === 0 ? '' : 'openAttributes',
        isPublished: true,
        onlyRead: true,
        blockedDiagramClass: '',
        onlyReadReason: diagramLoad.onlyReadReason,
        diagramId: diagramLoad.id,
        objectCatalogList: diagramLoad.objectCatalogList || [],
        objectsUsedDiagram: DiagramUtils.prepareObjectsUsedDiagram(diagramLoad.objectsUsedDiagram) || [],
        objectsCatalogUsedDiagram: diagramLoad.objectCatalogList || [],
        diagramType,
      },
      () => {
        diagramInstance.updateViewPort();
        this.resetZoom(diagramType);
        this.prepareConnectorConstraints();
        this.prepareNodeConstraints();
        this.loadObjectsDiagram();
        this.resetReadOnlyInstanceAttributes();
        this.setState({ loading: false, undoHistory: [], redoHistory: [] });
      },
    );
  }

  prepareRecommendationDiagram(diagramLoad) {
    this.loadRecommendationDiagram(diagramLoad);
    const diagramType = DiagramUtils.getDiagramType(diagramLoad.idDiagramType);
    this.getCatalogs(diagramType, diagramLoad.objectCatalogList, true);

    this.setState(
      {
        recommendationDiagramLoaded: diagramLoad,
        objectsUsedRecommendationDiagram: DiagramUtils.prepareObjectsUsedDiagram(diagramLoad.objectsUsedDiagram) || [],
        objectsCatalogUsedRecommendationDiagram: diagramLoad.objectCatalogList || [],
      },
      () => {
        this.resetZoom(diagramType, true);
        this.prepareConnectorConstraints(true);
        this.prepareNodeConstraints(true);
        this.loadRecommendationObjectsDiagram();
        this.resetReadOnlyInstanceAttributes(true);
      },
    );
  }

  switchConnectorOptimization() {
    if (this.diagramConnections === '') {
      diagramInstance.clearSelection();
      this.diagramConnections = diagramInstance.saveDiagram();
      diagramInstance.constraints |= DiagramConstraints.LineRouting;

      if (diagramInstance?.nodes?.length > 0) {
        diagramInstance.nodes[0].rotateAngle = 1;
        diagramInstance.nodes[0].rotateAngle = 0;
      }

      setTimeout(() => {
        diagramInstance.constraints &= ~DiagramConstraints.LineRouting;
      }, 50);
    } else {
      diagramInstance.loadDiagram(this.diagramConnections);
      this.diagramConnections = '';
    }
  }

  prepareDiagramAttributes(diagram) {
    const diagramFormTypesCode = this.props.getFormTypesCode({
      status: diagram.status,
      type: Constants.DIAGRAM,
      variant: this.state.diagramType,
    });

    const attributesData = this.props.getAttributesData(diagramFormTypesCode);
    const attributes = JSON.parse(diagram.attributes);

    const NO_TRANS = Constants.NOT_TRANSLATABLE;

    attributesData.forEach(({ code }) => {
      const field = attributesData.find((el) => el.code === code);

      if (code === Constants.APPROVER && attributes[NO_TRANS].APPROVER === '[]') {
        attributes[NO_TRANS].APPROVER = attributes[NO_TRANS].APPROVER.slice(1, -1);
      } else if (code === Constants.SCOPES) {
        attributes[NO_TRANS].SCOPES = Utils.mapScopes(diagram.scopes, this.state.language);
      } else if (code === Constants.MODELER || code === Constants.APPROVER) {
        let initialUsers = this.modelersAssigned || attributes[NO_TRANS][code];
        initialUsers = (initialUsers.replace && initialUsers.replace('[', '').replace(']', '')) || '';
        const usersList = initialUsers.split(',');
        field.fieldType = 'MULTISELECTION';
        let userCode = [];

        if (usersList && initialUsers) {
          const usersMultiSelection = [];
          usersList.forEach((elem) => {
            userCode = this.state.users.find((user) => user.code === elem.trim());

            if (userCode && userCode.commonName) {
              const newValue = {
                Code: userCode.code,
                Name: userCode.commonName,
              };
              usersMultiSelection.push(newValue);
            }
          });
          attributes[NO_TRANS][code] = usersMultiSelection;
        } else {
          userCode = this.state.users.find((user) => user.code === initialUsers);
          attributes[NO_TRANS][code] = userCode?.commonName;
        }
      } else if (code === Constants.AUTHOR || code === Constants.METHOD_OWNER) {
        const newValue = this.state.users
          .filter((user) => user.code === attributes[NO_TRANS][code] && user.commonName)
          .map((elem) => ({
            Code: elem.code,
            Name: elem.commonName,
          }));

        attributes[NO_TRANS][code] = newValue;
      } else if (code === Constants.ADDITIONAL_DOCUMENTS) {
        this.props.availableLanguages.forEach((language) => {
          try {
            if (Array.isArray(attributes[language][code])) return;
            const value = JSON.parse(attributes[language][code]);
            attributes[language][code] = value;
          } catch (error) {
            attributes[language][code] = [];
          }
        });
      } else {
        let dateTimeField = '';

        if (field.fieldType === Constants.DATE_TIME) {
          dateTimeField = Utils.getFullFormattedDate(attributes[NO_TRANS][code]);
        }

        if (field.translatable) {
          this.props.availableLanguages.forEach((lang) => {
            attributes[lang][code] = attributes[lang][code] || '';
          });
        } else if (field.code === Constants.LAST_EDITOR_CODE || field.code === Constants.CREATOR_CODE) {
          const userName =
            attributes[NO_TRANS][code] && this.state.users.find((user) => user.code === attributes[NO_TRANS][code]);
          attributes[NO_TRANS][code] = userName ? userName.commonName : '';
        } else if (field.code === Constants.QA_INSPECTOR_GROUP) {
          const qualityGroups = this.props.formTypes[diagramFormTypesCode][NO_TRANS][
            Constants.QA_INSPECTOR_GROUP
          ].properties.OPTIONS.replace('[', '')
            .replace(']', '')
            .split(', ');
          const isValidValue = qualityGroups.some((group) => group === attributes[NO_TRANS][code]);
          attributes[NO_TRANS][code] = isValidValue ? attributes[NO_TRANS][code] : '';
        } else if (field.fieldType === Constants.INPUT_MULTISELECTION) {
          attributes[NO_TRANS][code] = attributes[NO_TRANS][code] || [];
        } else {
          attributes[NO_TRANS][code] = dateTimeField || attributes[NO_TRANS][code] || '';
        }
      }
    });

    this.originalName = attributes[this.state.language].PROCESS_NAME;

    if (diagram?.dataSets) {
      this.props.availableLanguages.forEach((lang) => {
        attributes[lang] = {
          ...attributes[lang],
          DATASET_LINKAGE: diagram.dataSets
            .map(({ id, name }) => ({
              dataSetId: id,
              name: name[lang] || '',
            }))
            .sort((prev, curr) => prev.name.localeCompare(curr.name)),
        };
      });
    }
    this.setState({ attributes });
  }

  prepareRecommendationDiagramAttributes(diagram) {
    const diagramFormTypesCode = this.props.getFormTypesCode({
      status: diagram.status,
      type: Constants.DIAGRAM,
      variant: this.state.diagramType,
    });

    const attributesData = this.props.getAttributesData(diagramFormTypesCode);
    const attributes = JSON.parse(diagram.attributes);

    const NO_TRANS = Constants.NOT_TRANSLATABLE;

    attributesData.forEach(({ code }) => {
      const field = attributesData.find((el) => el.code === code);

      if (code === Constants.APPROVER && attributes[NO_TRANS].APPROVER === '[]') {
        attributes[NO_TRANS].APPROVER = attributes[NO_TRANS].APPROVER.slice(1, -1);
      } else if (code === Constants.SCOPES) {
        attributes[NO_TRANS].SCOPES = Utils.mapScopes(diagram.scopes, this.state.language);
      } else if (code === Constants.MODELER || code === Constants.APPROVER) {
        let initialUsers = attributes[NO_TRANS][code];
        initialUsers = (initialUsers.replace && initialUsers.replace('[', '').replace(']', '')) || '';
        const usersList = initialUsers.split(',');
        field.fieldType = 'MULTISELECTION';
        let userCode = [];

        if (usersList && initialUsers) {
          const usersMultiSelection = [];
          usersList.forEach((elem) => {
            userCode = this.state.users.find((user) => user.code === elem.trim());

            if (userCode && userCode.commonName) {
              const newValue = {
                Code: userCode.code,
                Name: userCode.commonName,
              };
              usersMultiSelection.push(newValue);
            }
          });
          attributes[NO_TRANS][code] = usersMultiSelection;
        } else {
          userCode = this.state.users.find((user) => user.code === initialUsers);
          attributes[NO_TRANS][code] = userCode?.commonName;
        }
      } else if (code === Constants.AUTHOR || code === Constants.METHOD_OWNER) {
        const newValue = this.state.users
          .filter((user) => user.code === attributes[NO_TRANS][code] && user.commonName)
          .map((elem) => ({
            Code: elem.code,
            Name: elem.commonName,
          }));

        attributes[NO_TRANS][code] = newValue;
      } else if (code === Constants.ADDITIONAL_DOCUMENTS) {
        this.props.availableLanguages.forEach((language) => {
          try {
            if (Array.isArray(attributes[language][code])) return;
            const value = JSON.parse(attributes[language][code]);
            attributes[language][code] = value;
          } catch (error) {
            attributes[language][code] = [];
          }
        });
      } else {
        let dateTimeField = '';

        if (field.fieldType === Constants.DATE_TIME) {
          dateTimeField = Utils.getFullFormattedDate(attributes[NO_TRANS][code]);
        }

        if (field.translatable) {
          this.props.availableLanguages.forEach((lang) => {
            attributes[lang][code] = attributes[lang][code] || '';
          });
        } else if (field.fieldType === Constants.INPUT_MULTISELECTION) {
          attributes[NO_TRANS][code] = attributes[NO_TRANS][code] || [];
        } else {
          attributes[NO_TRANS][code] = dateTimeField || attributes[NO_TRANS][code] || '';
        }
      }
    });

    this.setState({ recommendationAttributes: attributes });
  }

  prepareUsedOjects(loadObjectsUsed) {
    const objectsInfo = [...this.state.objectsInfo];

    for (let objectNode, i = 0; i < this.state.symbols?.length; i++) {
      for (let j = 0; j < this.state.symbols[i].objectsDiagram?.length; j++) {
        const objectUsed = loadObjectsUsed.find((o) => this.state.symbols[i].objectsDiagram[j] === o.idFront);
        objectNode = diagramInstance?.nodes.find(
          (n) =>
            n.addInfo === objectUsed.idFront && DiagramUtils.isObjectFromProcessStep(n.id, this.state.symbols[i].idSymbolFront),
        );
        objectsInfo.push({
          OBJECT_NAME: objectUsed.attributes ? JSON.parse(objectUsed.attributes).name : '',
          idObjectType: objectUsed.idObjectType,
          idObjectShared: objectUsed.idFront,
          idFront: objectNode ? objectNode.id : '',
        });
      }
    }
    this.setState({ objectsInfo });
  }

  prepareRecommendationUsedOjects(loadObjectsUsed) {
    const objectsInfo = [...this.state.recommendationObjectsInfo];

    for (let objectNode, i = 0; i < this.state.recommendationSymbols?.length; i++) {
      for (let j = 0; j < this.state.recommendationSymbols[i].objectsDiagram?.length; j++) {
        const objectUsed = loadObjectsUsed.find((o) => this.state.recommendationSymbols[i].objectsDiagram[j] === o.idFront);
        objectNode = recommendationDiagramInstance.nodes.find(
          (n) =>
            n.addInfo === objectUsed.idFront &&
            DiagramUtils.isObjectFromProcessStep(n.id, this.state.recommendationSymbols[i].idSymbolFront),
        );
        objectsInfo.push({
          OBJECT_NAME: objectUsed.attributes ? JSON.parse(objectUsed.attributes).name : '',
          idObjectType: objectUsed.idObjectType,
          idObjectShared: objectUsed.idFront,
          idFront: objectNode ? objectNode.id : '',
        });
      }
    }
    this.setState({ recommendationObjectsInfo: objectsInfo });
  }

  loadDiagram(diagram) {
    diagramInstance?.clear();

    let purgeResult = {};

    if (diagram?.connections && diagram?.connections !== '{}') {
      const connections = JSON.parse(diagram?.connections);
      if (connections && connections.constraints) {
        connections.constraints = DiagramConstraints.Default;
      }

      // TODO: remove when all diagrams have new font or when we create a backend script for connections manipulation
      connections?.nodes?.forEach((node, index) => {
        // Default height in case it's null to prevent crash on loadDiagram
        if (node.height === null) node.height = 30;
        if (node.annotations[0]) {
          connections.nodes[index].annotations[0].style = { ...node.annotations[0].style, fontFamily: 'MBCorpoSText-Regular' };
        } else {
          connections.nodes[index].style = { ...node.style, fontFamily: 'MBCorpoSText-Regular' };
        }
      });
      connections?.connectors.forEach((connector, index) => {
        if (!connector.annotations[0]) return;
        if (connections) {
          connections.connectors[index].annotations[0].style = {
            ...connector?.annotations[0].style,
            fontFamily: 'MBCorpoSText-Regular',
          };
        }
      });

      if (connections) {
        diagramInstance?.loadDiagram(JSON.stringify(connections));
      }
      purgeResult = this.purgeDiagram(diagram);

      diagramInstance?.connectors?.forEach((connector) => {
        if (connector?.annotations?.length > 0) {
          this.switchVisibility(connector?.annotations[0]);
        }
      });

      diagramInstance?.nodes.forEach((node) => {
        if (node?.shape instanceof BasicShape && node.annotations?.length === 0) {
          const annotation = [
            {
              content: '',
              style: {
                color: Constants.BLACK_COLOR,
              },
            },
          ];

          diagramInstance?.addLabels(node, annotation);
        }
      });
      diagramInstance.tool = DiagramTools.None;
      diagramInstance.snapSettings.constraints &= ~SnapConstraints.SnapToLines;

      const swimlane = Utils?.getSwimlaneNode(diagramInstance);

      if (swimlane && diagram?.status === Constants.STATUS.SANDBOX) {
        swimlane.constraints &= ~NodeConstraints.Select;
      }
    }
    const diagramAttributes =
      diagram?.attributes && typeof diagram?.attributes === 'string' ? JSON?.parse(diagram.attributes) : diagram.attributes;
    const attributes = { ...this.state.attributes, ...diagramAttributes };
    const symbols = this.loadSymbols(diagram.symbols);

    if (purgeResult?.needsProcessStepReordering) {
      this.reorderingProcessStep(symbols);
    }
    const lastDate = Utils.getFullFormattedDate(attributes[Constants.NOT_TRANSLATABLE].LAST_MODIFICATION);

    diagramInstance?.nodes.forEach((item) => {
      const { id } = item.properties;

      if (id?.includes(Constants.AND_GATE)) {
        item.annotations[0].content = Constants.AND;
      } else if (id?.includes(Constants.XOR_GATE)) {
        item.annotations[0].content = Constants.XOR;
      } else if (id?.includes(Constants.OR_GATE)) {
        item.annotations[0].content = Constants.OR;
      }

      if (id?.includes(Constants.EVENT) || id?.includes(Constants.PROCESS_OVERVIEW) || id?.includes(Constants.PROCESS_STEP)) {
        if (item.minHeight > Constants.NODE_MIN_HEIGHT) {
          item.minHeight = Constants.NODE_MIN_HEIGHT;
        }

        if (item.maxWidth > 0) {
          item.maxWidth = undefined;
        }
        this.resizeSymbol(item);
      }
    });

    this.setState(
      {
        attributes,
        symbols,
        connectors: diagramInstance.connectors,
        processNumber: attributes[Constants.NOT_TRANSLATABLE].PROCESS_NUMBER,
        savedDate: lastDate,
      },
      () => {
        this.prepareUsedOjects(diagram.objectsUsedDiagram);
        this.refreshBoxesNames(this.state.language);
        this.changeLanguageAbbreviation(this.state.language);
        DiagramUtils.modifyAnnotationWrap(diagramInstance);

        if (this.state.diagramType === Constants.EPC_DIAGRAM) {
          DiagramUtils.updateDiagramObjectsPosition(diagramInstance, this.state.symbols);
          DiagramUtils.fixZIndex(diagramInstance);
        }

        if (this.state.isPublished || this.state.isWorkflow || this.isClosedWF) {
          this.hideSymbolErrors(true);
        }
      },
    );
  }

  loadRecommendationDiagram(diagram) {
    recommendationDiagramInstance.clear();

    if (diagram?.connections && diagram?.connections !== '{}') {
      const connections = JSON.parse(diagram.connections);
      if (connections.constraints) {
        connections.constraints = DiagramConstraints.Default;
        connections.nodes.forEach((node) => {
          node.annotations.forEach((annotation) => {
            annotation.id = `${annotation.id}Recommendation`;
          });
        });
      }
      recommendationDiagramInstance.loadDiagram(JSON.stringify(connections));

      recommendationDiagramInstance.connectors.forEach((connector) => {
        if (connector.annotations?.length > 0) {
          this.switchVisibility(connector.annotations[0]);
        }
      });
      recommendationDiagramInstance.tool = DiagramTools.None;
    }
    const attributes = { ...this.state.attributes, ...JSON.parse(diagram.attributes) };
    const symbols = this.loadSymbols(diagram.symbols, true);

    recommendationDiagramInstance.nodes.forEach((item) => {
      const { id } = item.properties;

      if (id?.includes(Constants.AND_GATE)) {
        item.annotations[0].content = Constants.AND;
      } else if (id?.includes(Constants.XOR_GATE)) {
        item.annotations[0].content = Constants.XOR;
      } else if (id?.includes(Constants.OR_GATE)) {
        item.annotations[0].content = Constants.OR;
      }

      if (id?.includes(Constants.EVENT) || id?.includes(Constants.PROCESS_OVERVIEW) || id?.includes(Constants.PROCESS_STEP)) {
        if (item.minHeight > Constants.NODE_MIN_HEIGHT) {
          item.minHeight = Constants.NODE_MIN_HEIGHT;
        }

        if (item.maxWidth > 0) {
          item.maxWidth = undefined;
        }
        this.resizeSymbol(item);
      }
    });

    this.setState(
      {
        recommendationAttributes: attributes,
        recommendationSymbols: symbols,
        recommendationConnectors: recommendationDiagramInstance.connectors,
      },
      () => {
        this.prepareRecommendationUsedOjects(diagram.objectsUsedDiagram);

        if (this.state.diagramType === Constants.EPC_DIAGRAM) {
          DiagramUtils.updateDiagramObjectsPosition(recommendationDiagramInstance, this.state.recommendationSymbols);
          DiagramUtils.fixZIndex(recommendationDiagramInstance);
        }

        this.hideSymbolErrors(true, true);
        /*
          this.refreshBoxesNames(this.state.language);
          this.changeLanguageAbbreviation(this.state.language);
          DiagramUtils.modifyAnnotationWrap(recommendationDiagramInstance);
          */
      },
    );
  }

  eliminateDuplicates(collection) {
    const hash = {};
    let duplicates = 0;
    let index = 0;

    while (index < collection?.length) {
      const exists = hash[collection[index].id];
      hash[collection[index].id] = true;

      if (exists) {
        collection.splice(index, 1);
        duplicates += 1;
      } else {
        index += 1;
      }
    }

    // eslint-disable-next-line no-console
    console.log('duplicates eliminated:', duplicates);
  }

  catalogObjectsMerge() {
    this.setState({ isCatalogObjectsMergeDialogOpen: false, loading: true }, () => {
      setTimeout(() => {
        this.state.diagramLoaded.symbols.forEach((symbol) => {
          if (!symbol.idSymbolFront?.includes(Constants.PROCESS_STEP)) return;

          const idRandomPart = symbol.idSymbolFront.replace(Constants.PROCESS_STEP, '');
          const rolesToAdd = symbol.objectsCatalogRelations
            .filter(
              (object) =>
                object.rolResponsability &&
                !diagramInstance?.nodes.some(
                  (node) =>
                    node.id?.includes(idRandomPart) &&
                    node.id?.includes(Constants.ROLE) &&
                    node.id.split('_')[1] === object.idObjectCatalog.toString(),
                ),
            )
            .map((role) => {
              const catalogObject = this.state.diagramLoaded.objectCatalogList.find((obj) => obj.id === role.idObjectCatalog);
              const objectAttributes = JSON.parse(catalogObject.attributes)[this.state.language];
              const symbolNode = diagramInstance?.nodes.find((node) => node.id === symbol.idSymbolFront);

              const responsibility = Constants.LINK_RESPONSIBILITY_BY_ID[role.rolResponsability];

              return {
                LINKAGE: objectAttributes.LINKAGE,
                OBJECT_DESCRIPTION: objectAttributes.OBJECT_DESCRIPTION,
                OBJECT_NAME: objectAttributes.OBJECT_NAME,
                LINK_RESPONSIBILITY: responsibility,
                id: catalogObject.id,
                symbolNode,
              };
            });
          const itSystemsToAdd = symbol.objectsCatalogRelations
            .filter(
              (object) =>
                !object.rolResponsability &&
                !diagramInstance?.nodes.some(
                  (node) =>
                    node.id?.includes(idRandomPart) &&
                    node.id?.includes(Constants.IT_SYSTEM.replace('_', '')) &&
                    node.id.split('_')[1] === object.idObjectCatalog.toString(),
                ),
            )
            .map((itSystem) => {
              const catalogObject = this.state.diagramLoaded.objectCatalogList.find((obj) => obj.id === itSystem.idObjectCatalog);
              const objectAttributes = JSON.parse(catalogObject.attributes)[this.state.language];
              const symbolNode = diagramInstance?.nodes.find((node) => node.id === symbol.idSymbolFront);

              return {
                LINKAGE: objectAttributes.LINKAGE,
                OBJECT_DESCRIPTION: objectAttributes.OBJECT_DESCRIPTION,
                OBJECT_NAME: objectAttributes.OBJECT_NAME,
                id: catalogObject.id,
                symbolNode,
              };
            });
          const objectsToDelete = diagramInstance?.nodes.filter((node) => {
            if (
              node.id?.includes(idRandomPart) &&
              ((node.id?.includes(Constants.IT_SYSTEM.replace('_', '')) &&
                !symbol.objectsCatalogRelations.some((object) => node.id.split('_')[1] === object.idObjectCatalog.toString())) ||
                (node.id?.includes(Constants.ROLE) &&
                  !symbol.objectsCatalogRelations.some((object) => node.id.split('_')[1] === object.idObjectCatalog.toString())))
            ) {
              return true;
            }

            return false;
          });

          objectsToDelete.forEach((node) => DiagramUtils.updateObjectsPosition(node, diagramInstance));

          if (rolesToAdd?.length) {
            this.createObjects(rolesToAdd, null, Constants.ROLE, true);
          }

          if (itSystemsToAdd?.length) {
            this.createObjects(itSystemsToAdd, null, Constants.IT_SYSTEM, true);
          }
        });

        // We need to force saving diagram at the end of the javascript queue or createObjects result wouldn't be completed and
        // diagramInstance.saveDiagram() would produce an incomplete connections (event though diagramInstance has all added nodes)
        setTimeout(() => {
          diagramInstance.clearSelection();
          this.saveDiagram(true)
            .then((resp) => {
              if (this.state.diagramLoaded.status === Constants.STATUS.WORKFLOW) {
                Dialog.showAlert({
                  closeClick: () => this.props.history.go(0),
                  message: this.props.t('mergeDialog.refreshDiagramMessage'),
                  name: this.props.t('warning'),
                  textOk: this.props.t('refresh'),
                });

                return;
              }

              this.setState({ loading: false });
              this.updateDiagramData(resp);
            })
            .catch((error) => this.errorSavingDiagram(error));
        });
      }, 200);
    });
  }

  purgeDiagram(diagram) {
    this.eliminateDuplicates(diagramInstance?.nodes);
    this.eliminateDuplicates(diagramInstance.connectors);

    const symbolsNotInSymbols = [];
    let index = 0;
    let diagramNode;

    while (index < diagramInstance?.nodes?.length) {
      diagramNode = diagramInstance?.nodes[index];

      if (
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        !diagram.symbols.some((elem) => elem.idSymbolFront === diagramNode.id) &&
        !diagramNode.id?.includes('OBJECT') &&
        diagramNode.annotations?.length > 0 &&
        !(diagramNode.shape instanceof BasicShape) &&
        diagramNode.shape.type !== Constants.IMAGE
      ) {
        diagramInstance?.nodes.splice(index, 1);
        symbolsNotInSymbols.push(diagramNode.id);
      } else {
        index += 1;
      }
    }
    // eslint-disable-next-line no-console
    console.log('Nodes in connections and not in symbols:', symbolsNotInSymbols?.length, symbolsNotInSymbols);

    const symbolsNotInConnections = [];
    index = 0;
    let elem;

    while (index < diagram.symbols?.length) {
      elem = diagram.symbols[index];

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      if (!diagramInstance?.nodes.some(({ id }) => id === elem.idSymbolFront)) {
        diagram.symbols.splice(index, 1);
        symbolsNotInConnections.push(elem.idSymbolFront);
      } else {
        index += 1;
      }
    }
    // eslint-disable-next-line no-console
    console.log('Nodes in symbols and not in connections:', symbolsNotInConnections?.length, symbolsNotInConnections);

    const fakeSymbols = [];
    index = 0;

    while (index < diagram.symbols?.length) {
      elem = diagram.symbols[index];

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      if (elem.idSymbolFront.startsWith(Constants.OBJECT)) {
        diagram.symbols.splice(index, 1);
        fakeSymbols.push(elem.idSymbolFront);
      } else {
        index += 1;
      }
    }
    // eslint-disable-next-line no-console
    console.log('False symbols:', fakeSymbols?.length, fakeSymbols);

    if (diagram.objectsUsedDiagram) {
      const objectsNotInConnections = [];
      index = 0;
      let element;

      while (index < diagram.objectsUsedDiagram?.length) {
        element = diagram.objectsUsedDiagram[index];

        // eslint-disable-next-line @typescript-eslint/no-loop-func
        if (!diagramInstance?.nodes.some(({ addInfo }) => addInfo === element.idFront)) {
          diagram.objectsUsedDiagram.splice(index, 1);
          objectsNotInConnections.push(element.idFront);
        } else {
          index += 1;
        }
      }
      // eslint-disable-next-line no-console
      console.log(
        'Objects in objectsUsedDiagram but not in connections:',
        objectsNotInConnections?.length,
        objectsNotInConnections,
      );

      index = 0;
      const symbolObjectsNotInObjectsUsed = [];

      while (index < diagram.symbols?.length) {
        element = diagram.symbols[index];
        let index2 = 0;

        while (index2 < element.objectsDiagram?.length) {
          elem = element.objectsDiagram[index2];

          // eslint-disable-next-line @typescript-eslint/no-loop-func
          if (!diagram.objectsUsedDiagram.some((o) => o.idFront === elem.idFront)) {
            element.objectsDiagram.splice(index2, 1);
            symbolObjectsNotInObjectsUsed.push(element.idFront);
          } else {
            index2 += 1;
          }
        }
        index += 1;
      }

      // eslint-disable-next-line no-console
      console.log(
        'Symbols objects not in objectsUsedDiagram:',
        symbolObjectsNotInObjectsUsed?.length,
        symbolObjectsNotInObjectsUsed,
      );

      const objectsNotInObjectsUsed = [];
      index = 0;

      while (index < diagramInstance?.nodes?.length) {
        diagramNode = diagramInstance?.nodes[index];

        if (
          diagramNode.id?.includes('OBJECT') &&
          !diagramNode.id?.includes('ITSYSTEM') &&
          !diagramNode.id?.includes('ROLE') &&
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          !diagram.objectsUsedDiagram.some(({ idFront }) => idFront === diagramNode.addInfo)
        ) {
          diagramInstance?.nodes.splice(index, 1);
          objectsNotInObjectsUsed.push(diagramNode.id);
        } else {
          index += 1;
        }
      }
      // eslint-disable-next-line no-console
      console.log(
        'Objects in connections but not in objectsUsedDiagram:',
        objectsNotInObjectsUsed?.length,
        objectsNotInObjectsUsed,
      );
    }

    let deletedConnectors = 0;

    if (symbolsNotInSymbols?.length > 0 || symbolsNotInConnections?.length > 0) {
      index = 0;

      while (index < diagramInstance.connectors?.length) {
        const connector = diagramInstance.connectors[index];

        if (
          [...symbolsNotInSymbols, ...symbolsNotInConnections]?.includes(connector.sourceID) &&
          [...symbolsNotInSymbols, ...symbolsNotInConnections]?.includes(connector.targetID)
        ) {
          diagramInstance.connectors.splice(index, 1);
          deletedConnectors += 1;
        } else {
          index += 1;
        }
      }
      // eslint-disable-next-line no-console
      console.log('Deleted nodes connections:', deletedConnectors);
    }

    const nodesWithInEdgesNotInConnections = [];
    const nodesWithOutEdgesNotInConnections = [];

    for (let i = 0; i < diagramInstance?.nodes?.length; i++) {
      diagramNode = diagramInstance?.nodes[i];

      index = 0;
      while (index < diagramNode.inEdges?.length) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        if (!diagramInstance.connectors.some(({ id }) => id === diagramNode.inEdges[index])) {
          diagramInstance?.nodes[i].inEdges.splice(index, 1);
          nodesWithInEdgesNotInConnections.push(diagramNode.inEdges[index]);
        } else {
          index += 1;
        }
      }

      index = 0;
      while (index < diagramNode.outEdges?.length) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        if (!diagramInstance.connectors.some(({ id }) => id === diagramNode.outEdges[index])) {
          diagramInstance?.nodes[i].outEdges.splice(index, 1);
          nodesWithOutEdgesNotInConnections.push(diagramNode.outEdges[index]);
        } else {
          index += 1;
        }
      }
    }

    // eslint-disable-next-line no-console
    console.log(
      'Connectors in inEdges and not in connections:',
      nodesWithInEdgesNotInConnections?.length,
      nodesWithInEdgesNotInConnections,
    );
    // eslint-disable-next-line no-console
    console.log(
      'Connectors in outEdges and not in connections:',
      nodesWithOutEdgesNotInConnections?.length,
      nodesWithOutEdgesNotInConnections,
    );

    const connectorsWithCorruptedWrappers = [];
    index = 0;

    while (index < diagramInstance.connectors?.length) {
      diagramNode = diagramInstance.connectors[index];

      if (!diagramNode.wrapper?.id) {
        diagramInstance.connectors.splice(index, 1);
        connectorsWithCorruptedWrappers.push(diagramNode.id);
      } else {
        index += 1;
      }
    }
    // eslint-disable-next-line no-console
    console.log('Connectors with corrupted wrappers:', connectorsWithCorruptedWrappers?.length, connectorsWithCorruptedWrappers);

    const nodesWithCorruptedWrappers = [];
    let needsProcessStepReordering = false;
    index = 0;

    while (index < diagramInstance?.nodes?.length) {
      diagramNode = diagramInstance?.nodes[index];

      if (!diagramNode.wrapper?.id) {
        diagramInstance?.nodes.splice(index, 1);
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const symbolsIndex = diagram.symbols.findIndex((symbol) => symbol.idSymbolFront === diagramNode.id);
        diagram.symbols.splice(symbolsIndex, 1);
        nodesWithCorruptedWrappers.push(diagramNode.id);
        needsProcessStepReordering = true;
      } else {
        index += 1;
      }
    }
    // eslint-disable-next-line no-console
    console.log('Nodes with corrupted wrappers:', nodesWithCorruptedWrappers?.length, nodesWithCorruptedWrappers);

    const corruptedObjectsInLayers = [];
    let layerIndex = 0;

    for (layerIndex = 0; layerIndex < diagramInstance.layers?.length; layerIndex++) {
      index = 0;

      while (index < diagramInstance.layers[layerIndex].objects?.length) {
        const objectId = diagramInstance.layers[layerIndex].objects[index];

        if (
          (!diagramInstance?.nodes.some((node) => objectId === node.id) &&
            !diagramInstance.connectors.some(({ id }) => id === objectId)) ||
          !Object.keys(diagramInstance.nameTable).some((elemId) => objectId === elemId)
        ) {
          diagramInstance.layers[layerIndex].objects.splice(index, 1);
          corruptedObjectsInLayers.push(objectId);
        } else {
          index += 1;
        }
      }
    }
    // eslint-disable-next-line no-console
    console.log(
      'Objects in connections layers but not in connections nodes or connectors:',
      corruptedObjectsInLayers?.length,
      corruptedObjectsInLayers,
    );

    return { needsProcessStepReordering };
  }

  handleGlobalClick(route) {
    this.props.history.push(route);
    window.location.reload();
  }

  handleExternalClick(route) {
    window.open(route, '_blank');
  }

  checkDiagramLink(symbol, isRecommendation = false) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    const diagramSymbol = instance.nodes.find((elem) => elem.id === symbol.idSymbolFront && elem.shape.type !== Constants.IMAGE);

    if (!diagramSymbol) {
      return;
    }

    if (diagramSymbol.shape.type.toUpperCase() === Constants.TEXT) {
      diagramSymbol.shape.content = symbol.attributes[this.state.language].TEXT_BLOCK;
    } else if (!diagramSymbol.id?.includes(Constants.GATE)) {
      diagramSymbol.annotations[0].content = symbol.attributes[this.state.language]?.OBJECT_NAME || '';
    }

    if (diagramSymbol.annotations?.length > 2) {
      instance.removeLabels(diagramSymbol, diagramSymbol.annotations.slice(2));
    }

    if (symbol.idDiagramLink !== 0 && ((this.state.isPublished && symbol.processNumberLink) || !this.state.isPublished)) {
      const route = this.getDiagramRoute(symbol.idDiagramLink);
      let iconColor = Constants.SHAPES_COLOR;
      let iconId = 'originalIcon';

      if (symbol.diagramLinkType === Constants.EXTERNAL_LINK_TYPE) {
        iconColor = Constants.EXTERNAL_LINK_COLOR;
        iconId = 'externalIcon';
      }
      const annotation = DiagramUtils.getDiagramLinkAnnotation(
        symbol.idSymbolFront,
        iconColor,
        iconId,
        symbol.diagramLinkChildType,
      );
      diagramSymbol.annotations[0].offset.y = 0.5;
      instance.addLabels(diagramSymbol, annotation);
      const symbolLink = document.getElementById(`${diagramSymbol.id}_${diagramSymbol.annotations[2].id}_text`);

      if (symbolLink) {
        symbolLink.addEventListener('click', () => this.handleGlobalClick(route));

        if (this.state.onlyRead) {
          const diagramContentElement = document.getElementById('diagram_diagramLayer');
          this.symbolsInternalLinks[symbolLink.id] = route;
          this.mutationObserver.observe(diagramContentElement, { childList: true, subtree: true });
        }
      }
      instance.dataBind();
    } else if (diagramSymbol.shape.type.toUpperCase() !== Constants.TEXT) {
      diagramSymbol.annotations[0].offset.y = 0.5;
    }
  }

  checkLinkageIcon(id, linkage, isRecommendation = false) {
    if (!id?.includes(Constants.GATE)) {
      const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
      const diagramSymbol = instance.nodes.find((node) => node.id === id);
      this.toggleLinkageIcon(diagramSymbol, linkage, isRecommendation);
    }
  }

  setForm(symbolId, lang = this.state.language, changeLanguage) {
    let fieldConfig;
    let objectSelected;
    let isObjectSelected = false;
    let objectShared = null;
    let catalogType = '';
    let fields = { ...this.state.fieldConfig };
    let responsibility = '';
    const isRecommendationActive = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];

    if (symbolId === '') {
      fieldConfig = this.state.diagramFieldConfig ? this.state.diagramFieldConfig[lang] : '';
      fields = this.state.diagramFieldConfig;
    } else if (symbolId?.includes(Constants.OBJECT)) {
      isObjectSelected = true;

      if (symbolId?.includes(Constants.ROLE) || symbolId?.includes(Constants.IT_SYSTEM.replace('_', ''))) {
        const arrayIdFront = symbolId.split('_');
        let idObject = parseInt(arrayIdFront[arrayIdFront?.length - 1], 10);

        if (symbolId?.includes(Constants.ROLE)) {
          idObject = parseInt(arrayIdFront[arrayIdFront?.length - 2], 10);
          responsibility = this.getReponsibility(symbolId, idObject);
        }

        catalogType = symbolId?.includes(Constants.ROLE) ? Constants.ROLE : Constants.IT_SYSTEM;

        const catalogObjects = isRecommendationActive ? this.state.recommendationCatalogObjects : this.state.catalogObjects;
        objectSelected = {
          ...catalogObjects[catalogType].notPending.find((o) => o.id === idObject),
          type: Constants.CATALOG_OBJECT,
          variant: catalogType,
        };
      } else {
        const objectsInfo = isRecommendationActive ? this.state.recommendationObjectsInfo : this.state.objectsInfo;
        const objectsUsed = isRecommendationActive ? this.state.objectsUsedRecommendationDiagram : this.state.objectsUsedDiagram;
        objectShared = { ...objectsInfo.find((elem) => elem.idFront === symbolId) };
        objectSelected = { ...objectsUsed.find((elem) => elem.idFront === objectShared.idObjectShared) };
        objectSelected = {
          ...objectSelected,
          type: Constants.OBJECT,
          variant: this.getObjectTypeByIdFront(objectSelected.idFront),
        };
      }

      if (objectSelected && Object.keys(objectSelected)?.length > 0) {
        let objectFields = Utils.getObjectAttributes(objectSelected.attributes);

        if (symbolId?.includes(Constants.ROLE) && responsibility) {
          objectFields.NOT_TRANSLATABLE.LINK_RESPONSIBILITY = responsibility;
        }

        fieldConfig = this.getObjectFieldConfig(objectSelected, objectFields);

        const newFields = {
          ...objectFields[lang],
          ...objectFields[Constants.NOT_TRANSLATABLE],
        };
        objectFields = {
          ...DiagramUtils.createSymbolAttributes(fieldConfig[lang]),
          ...newFields,
        };

        objectSelected.attributes = objectFields;
        fields = fieldConfig;
        fieldConfig = fieldConfig[lang];
      }
    } else {
      const symbols = isRecommendationActive ? this.state.recommendationSymbols : this.state.symbols;
      const symbol = symbols.find((elem) => symbolId === elem.idSymbolFront);

      if (symbol?.fieldConfig) {
        fields = symbol.fieldConfig;
        fieldConfig = symbol.fieldConfig[lang];
      }
    }

    if (this.state.mustShowErrors) {
      fieldConfig = Utils.showFormErrors(fieldConfig);
    }

    fields[lang] = { ...fieldConfig };
    this.setState(
      {
        fieldConfig: fields,
        symbolSelected: symbolId,
        isObject: isObjectSelected,
        selectionObject: symbolId,
        attributesCatalogObject: catalogType ? objectSelected.attributes : [],
        language: lang,
      },
      () => {
        if (changeLanguage) {
          this.refreshBoxesNames(this.state.language);
          this.changeLanguageAbbreviation(this.state.language);

          if (this.state.diagramType === Constants.EPC_DIAGRAM) {
            DiagramUtils.updateDiagramObjectsPosition(diagramInstance, this.state.symbols);
          }
        }
      },
    );
  }

  getReponsibility(symbolId, idObject) {
    const isRecommendationActive = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
    const symbols = isRecommendationActive ? this.state.recommendationSymbols : this.state.symbols;
    const symbol = symbols.find((elem) => DiagramUtils.isObjectFromProcessStep(symbolId, elem.idSymbolFront));
    const idRol = symbol.objectsCatalogRelations.find((relation) => relation.idObjectCatalog === idObject)?.rolResponsability;

    return Constants.LINK_RESPONSIBILITY_BY_ID[idRol];
  }

  updateRolePosition(objectFrontId, responsibility) {
    const selectedRole = diagramInstance?.nodes.find((node) => node.id === objectFrontId);
    const connector = diagramInstance.connectors.find((elem) => elem.sourceID === objectFrontId);
    DiagramUtils.updateObjectsPosition(selectedRole, diagramInstance);
    const symbols = [...this.state.symbols];
    const idRole = parseInt(objectFrontId.split('_')[1], 10);

    symbols.forEach((elem) => {
      if (DiagramUtils.isObjectFromProcessStep(objectFrontId, elem.idSymbolFront)) {
        elem.objectsCatalogRelations = elem.objectsCatalogRelations.filter((relation) => relation.idObjectCatalog !== idRole);
      }
    });

    this.setState(
      {
        symbols,
        isObject: false,
      },
      () => {
        const object = this.state.catalogObjects[Constants.ROLE].notPending.find((role) => role.id === idRole);
        const objectAttributes = Utils.getObjectAttributes(object.attributes)[this.state.language];
        const symbolNode = diagramInstance?.nodes.find((node) => node.id === connector.targetID);
        const updatedRole = [
          {
            LINKAGE: objectAttributes.LINKAGE,
            OBJECT_DESCRIPTION: objectAttributes.OBJECT_DESCRIPTION,
            OBJECT_NAME: objectAttributes.OBJECT_NAME,
            LINK_RESPONSIBILITY: responsibility,
            id: object.id,
            symbolNode,
          },
        ];

        this.setForm('');
        this.createObjects(updatedRole);
      },
    );
  }

  updateResponsibilityError(errorMsg, roleUpdatedId) {
    this.setForm(roleUpdatedId);

    this.addRoleError(errorMsg);
  }

  roleResponsibilityChanged(value) {
    const updatedRoleId = diagramInstance.selectedItems.nodes[0].id;
    const symbolId = diagramInstance.connectors.find((connector) => connector.sourceID === updatedRoleId).targetID;
    const symbol = this.state.symbols.find((elem) => elem.idSymbolFront === symbolId);

    const roleResponsibility = this.getRolResponsability(value);
    const rolesFiltered = symbol.objectsCatalogRelations.filter((obj) => obj.rolResponsability === roleResponsibility);

    if (roleResponsibility === 1 && rolesFiltered?.length > 0) {
      this.updateResponsibilityError(this.props.t('errors.oneRoleResponsible'), updatedRoleId);

      return;
    }

    if (rolesFiltered?.length === 5) {
      this.updateResponsibilityError(
        this.props.t('errors.maxRoles', { responsibility: this.props.t(`responsibility.${value}`) }),
        updatedRoleId,
      );

      return;
    }

    const roleNumericalId = Number(updatedRoleId.split('_')[1]);
    const roleData = this.state.objectsCatalogUsedDiagram.find((role) => role.id === roleNumericalId);

    this.setState(
      {
        catalogObjectsAdded: [roleData],
      },
      () => {
        this.updateRolePosition(updatedRoleId, value);
      },
    );
  }

  prepareSymbolsToSend(symbols) {
    return [...symbols].map((symbol) => {
      const formTypesCode = this.props.getFormTypesCode({
        status: this.state.diagramLoaded.status,
        type: Constants.SYMBOL,
        variant: symbol.type,
      });
      symbol.attributes = DiagramUtils.prepareWysiwygAttributes(symbol.attributes, this.props.formTypes[formTypesCode]);

      return {
        attributes: !symbol.attributes ? null : JSON.stringify(symbol.attributes),
        id: symbol.id,
        diagramLinkType: symbol.diagramLinkType,
        idDiagram: symbol.idDiagram,
        idDiagramLink: symbol.idDiagramLink,
        idSymbolFront: symbol.idSymbolFront,
        idSymbolType: symbol.idSymbolType,
        objectsCatalogRelations: symbol.objectsCatalogRelations,
        objectsDiagram: symbol.objectsDiagram,
      };
    });
  }

  prepareAttributes(attributes = {}) {
    const list = {};
    const languages = Object.keys(attributes);
    const attributesData = this.props.getAttributesData(
      this.props.getFormTypesCode({
        status: this.state.diagramLoaded.status,
        type: Constants.DIAGRAM,
        variant: this.state.diagramType,
      }),
    );

    for (let i = 0; i < languages?.length; i++) {
      const lang = languages[i];
      const keys = Object.keys(attributes[lang]);
      list[lang] = {};

      keys.forEach((field) => {
        let attribute = attributes[lang][field];
        const type = attributesData.find((att) => att.code === field);
        attribute = Utils.checkWysiwygCharacters(type, attribute);

        if (attribute && attribute !== '[]' && Constants.MULTISELECT_FIELDS?.includes(field)) {
          let userCode = [];
          let usersList = [];
          let usersString = [];

          if (field === Constants.MODELER || field === Constants.APPROVER) {
            if (Array.isArray(attribute)) {
              usersString = attribute.map((elem) => elem.Code);
              list[lang][field] = `[${usersString.join(',')}]`;
            } else {
              usersList = attribute.split(',');

              if (usersList) {
                usersList.forEach((elem) => {
                  userCode = this.state.users.find((user) => user.commonName === elem);
                  usersString.push(userCode.code);
                });
                list[lang][field] = `[${usersString.join(',')}]`;
              } else {
                userCode = this.state.users.find((user) => user.commonName === attribute);
                list[lang][field] = `[${userCode.code}]`;
              }
            }
          } else {
            // AUTHOR, METHOD OWNER, SUBSCRIBERS
            usersList = attribute.map((elem) => elem.Code);
            list[lang][field] = usersList.shift() || '';
          }
        } else if (field === Constants.ADDITIONAL_DOCUMENTS) {
          list[lang][field] = JSON.stringify(attribute);
        } else if (field === Constants.SCOPES && attribute && attribute !== '[]') {
          list[lang][field] = `[${attribute}]`;
        } else if (field === Constants.SCOPES && attribute === '') {
          list[lang][field] = '[]';
        } else if (field === Constants.DATASET_LINKAGE) {
          if (attribute === '' || !this.state.diagramLoaded?.dataSets) return;
          const datasetsString = attribute?.map((elem) => elem.dataSetId);
          list[lang][field] = `[${datasetsString.join(',')}]`;
        } else if (!attribute || typeof attribute === 'string') {
          list[lang][field] = attribute;
        } else if (attribute) {
          list[lang][field] = attribute[0];
        }
      });
    }

    return JSON.stringify(list);
  }

  saveDiagram(catalogObjectsMerge) {
    if (this.state.diagramType === Constants.EPC_DIAGRAM) {
      diagramInstance.dataBind();
      DiagramUtils.fixZIndex(diagramInstance);
    }

    const diagramJSON = diagramInstance.saveDiagram();
    // This variable assignment resets the values syncfusion saves by default. This is how we get the swimlanes to be behind the diagram
    diagramInstance.swimlaneZIndexTable = {};
    diagramInstance.swimlaneChildTable = {};

    const purifiedAttributes = {
      attributes: Utils.purifyAttributes(this.state.attributes),
      symbols: this.state.symbols
        .filter((symbol) => symbol.idSymbolType)
        .map((symbol) => ({ ...symbol, attributes: Utils.purifyAttributes(symbol.attributes) })),
    };
    const attributesData = this.props.getAttributesData(
      this.props.getFormTypesCode({
        status: this.state.diagramLoaded.status,
        type: Constants.DIAGRAM,
        variant: this.state.diagramType,
      }),
    );

    Object.keys(purifiedAttributes.attributes[Constants.NOT_TRANSLATABLE]).forEach((key) => {
      const attributeType = attributesData.find((attribute) => attribute.code === key)?.fieldType;

      if (!purifiedAttributes.attributes[Constants.NOT_TRANSLATABLE][key] || attributeType !== Constants.DATE_TIME) {
        return;
      }

      return Utils.getUTCDate(purifiedAttributes.attributes[Constants.NOT_TRANSLATABLE][key]);
    });

    const data = {
      attributes: this.prepareAttributes({ ...purifiedAttributes.attributes }),
      connections: diagramJSON,
      symbols: this.prepareSymbolsToSend(purifiedAttributes.symbols),
    };

    if (this.state.diagramType === Constants.EPC_DIAGRAM) {
      purifiedAttributes.objectsUsedDiagram = this.state.objectsUsedDiagram.map((object) => {
        const objectType = Constants.OBJECT_TYPES_BY_ID[object.idObjectType];
        const formTypesCode = this.props.getFormTypesCode({
          status: this.state.diagramLoaded.status,
          type: Constants.CATALOG_OBJECTS?.includes(objectType) ? Constants.CATALOG_OBJECT : Constants.OBJECT,
          variant: objectType,
        });
        return {
          ...object,
          attributes: Utils.purifyAttributes(object.attributes),
          formTypes: this.props.formTypes[formTypesCode],
        };
      });
      data.objectsUsedDiagram = DiagramUtils.saveObjectsUsedDiagram(purifiedAttributes.objectsUsedDiagram);
    }

    if (catalogObjectsMerge) {
      data.catalogObjectsMerge = false;
    }

    this.setState(
      {
        ...purifiedAttributes,
        savingDiagram: true,
        successSavingDiagram: false,
        multipleSymbols: true,
      },
      () => {
        this.refreshBoxesNames(this.state.language);
        this.setState({ multipleSymbols: false });
      },
    );

    return services
      .saveDiagram(data, this.state.diagramId)
      .then((response) => {
        this.deletedImagesCleanup();
        this.setState({
          savingDiagram: false,
        });
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  errorSavingDiagram(error) {
    if (error.response?.status === 401) {
      this.toggleAutoSave(false);
      this.turnOffInterval(this.intervalInUse);
    }

    this.setState({
      savingDiagram: false,
      successSavingDiagram: false,
    });
    services.handleServiceError(error);
  }

  controlSavingSuccess(success) {
    this.setState({
      savingDiagram: !success,
      successSavingDiagram: success,
    });
    setTimeout(() => {
      this.setState({
        successSavingDiagram: false,
      });
    }, 2000);
  }

  saveParentDiagram(users) {
    this.setState({ loading: true }, () => {
      this.saveDiagram()
        .then((response) => {
          const newSymbols = this.loadSymbols(response.data.symbols);
          this.setState({ symbols: newSymbols });
          this.controlSavingSuccess(true);
          const symbolExists = this.state.symbols.find(
            (elem) => elem.idSymbolFront === diagramInstance.selectedItems.nodes[0].id,
          );

          if (symbolExists.idDiagramLink === 0) {
            this.linkNewDiagram(symbolExists.idSymbolFront, this.linkedDiagramType, users);
          }
        })
        .catch((error) => this.errorSavingDiagram(error));
    });
  }

  assignModelers(modelers) {
    let modelersAssigned = '';

    if (modelers.linkDiagram) {
      modelersAssigned = modelers.map((elem) => elem.Code);
    } else {
      modelersAssigned = modelers.map((elem) => elem.Code);
      this.saveParentDiagram(modelersAssigned);
    }

    const users = [...this.state.users];
    modelers.forEach((modeler) => {
      if (users.some((user) => user.code === modeler.Code)) return;
      users.push({
        code: modeler.Code,
        commonName: modeler.Name,
      });
    });
    this.modelersAssigned = `[${modelersAssigned.toString()}]`;
    this.setState({ users });
  }

  linkNewDiagram(idSymbolNewlinked, linkedDiagramType, modelers) {
    const data = {
      symbol: idSymbolNewlinked,
      type: linkedDiagramType,
      modelers,
    };

    this.setState(
      {
        loading: true,
        loadingLinkedDiagram: true,
      },
      () => {
        createDiagramService(data)
          .then((response) => {
            this.props.history.push(`/diagram/${response.data.id}`, { type: response.data.type });
            window.location.reload();
          })
          .catch((err) => {
            services.handleServiceError(err);
          });
      },
    );
  }

  assignModelersSandbox(idImportedDiagram) {
    this.setState({
      showAssignUserDialog: true,
      idImportedDiagram,
    });
  }

  importDiagram(idLinkedDiagram, idFrontSourceSymbol) {
    this.setState({ loading: true }, () => {
      diagramInstance.clearSelection();
      this.saveDiagram()
        .then((res) => {
          const symbols = this.loadSymbols(res.data.symbols);
          const sourceSymbolId = symbols.find((symbol) => symbol.idSymbolFront === idFrontSourceSymbol).id;
          const modelerCodes = this.modelersAssigned.replace('[', '').replace(']', '').split(',');
          const data = {
            id: idLinkedDiagram,
            idSymbol: sourceSymbolId,
            modelers: modelerCodes,
          };

          sandboxService
            .importSandbox(data)
            .then((response) => {
              this.prepareLinkedSymbol(response.data, symbols, idFrontSourceSymbol);
            })
            .catch((err) => {
              this.resetOnLinkError(symbols, () => {
                services.handleServiceError(err);
              });
            });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.errorSavingDiagram(error);
        });
    });
  }

  linkExternalDiagram(idLinkedDiagram, idFrontSourceSymbol) {
    this.setState({ loading: true }, () => {
      diagramInstance.clearSelection();
      this.saveDiagram()
        .then((res) => {
          const symbols = this.loadSymbols(res.data.symbols);
          const sourceSymbol = symbols.find((symbol) => symbol.idSymbolFront === idFrontSourceSymbol);
          const linkData = {
            idLinkedDiagram,
            idSourceSymbol: sourceSymbol.id,
          };

          services
            .linkExternalDiagram(linkData)
            .then((response) => {
              this.prepareLinkedSymbol(response.data, symbols, idFrontSourceSymbol);
            })
            .catch((err) => {
              this.resetOnLinkError(symbols, () => {
                services.handleServiceError(err);
              });
            });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.errorSavingDiagram(error);
        });
    });
  }

  moveDiagram(idLinkedDiagram, idFrontSourceSymbol) {
    this.setState({ loading: true }, () => {
      diagramInstance.clearSelection();
      this.saveDiagram()
        .then((res) => {
          const symbols = this.loadSymbols(res.data.symbols);
          const sourceSymbol = symbols.find((symbol) => symbol.idSymbolFront === idFrontSourceSymbol);
          const linkData = {
            idDiagram: idLinkedDiagram,
            idSymbol: sourceSymbol.id,
          };

          services
            .moveDiagram(linkData)
            .then((response) => {
              this.prepareLinkedSymbol(response.data, symbols, idFrontSourceSymbol);
              this.reloadDiagram(this.state.diagramId);
            })
            .catch((err) => {
              this.resetOnLinkError(symbols, () => {
                services.handleServiceError(err);
              });
            });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.errorSavingDiagram(error);
        });
    });
  }

  resetOnLinkError(symbols, callback) {
    this.setState(
      {
        loading: false,
        savingDiagram: false,
        successSavingDiagram: false,
        symbols,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  }

  prepareLinkedSymbol(symbol, symbols, idFrontSourceSymbol) {
    const lang = this.state.language;

    symbols.forEach((elem) => {
      if (elem.idSymbolFront === idFrontSourceSymbol) {
        elem.attributes = JSON.parse(symbol.attributes);
        elem.diagramLinkType = symbol.diagramLinkType;
        elem.diagramLinkChildType = symbol.diagramLinkChildType;
        elem.idDiagramLink = symbol.idDiagramLink;

        const symbolFormTypesCode = this.props.getFormTypesCode({
          status: this.state.diagramLoaded.status,
          type: Constants.SYMBOL,
          variant: this.getSymbolTypeByIdFront(symbol.idSymbolFront),
        });
        let newFieldConfig = {};
        if (this.props.formTypes[symbolFormTypesCode]) {
          newFieldConfig = this.props.getReactReactiveFormFieldConfig(
            symbolFormTypesCode,
            this.props.featureFlags.isFreezed || this.state.diagramLoaded.onlyReadUsedBy || elem.idDiagramLink !== 0,
          );
          newFieldConfig = this.addExtraMetaFieldConfig(newFieldConfig);
          newFieldConfig = this.addSpecialFieldsInitialValuesFieldConfig(newFieldConfig, elem.attributes);
        }

        elem.fieldConfig = newFieldConfig;
      }
    });

    this.resetOnLinkError(symbols, () => {
      const updatedSymbol = symbols.find((elem) => elem.idSymbolFront === idFrontSourceSymbol);

      this.setForm(idFrontSourceSymbol, lang);
      this.refreshBoxesNames(this.state.language);
      this.checkDiagramLink(updatedSymbol);
    });
  }

  showTransferTask() {
    this.setState({ showTransferTask: true });
  }

  showBackToGroup() {
    const confirmDialogData = {
      title: this.props.t('tool.backToGroup'),
      message: this.props.t('tool.backToGroupMessage'),
      textCancel: this.props.t('cancel'),
      textOk: this.props.t('tool.backToGroup'),
      okClick: () => {
        this.confirmDialog.hide();
        this.confirmBackToGroup();
      },
    };
    this.confirmDialog = Dialog.showConfirm(confirmDialogData);
  }

  showRecommendToLocation() {
    this.setState({ showRecommendToLocationDialog: true });
  }

  confirmBackToGroup() {
    this.setState({ loading: true });
    this.prepareWorkflowBar();
    backToGroup(this.state.processNumber)
      .then(() => {
        this.setState({ loading: false }, () => {
          this.reloadDiagram(this.state.diagramId);
        });
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  setTransferTask(value) {
    this.setState({ userTransferTask: value });
  }

  sendTransferTask() {
    const user =
      typeof this.state.userTransferTask === 'string' ? this.state.userTransferTask : this.state.userTransferTask[0].Code;
    this.setState({ showTransferTask: false });
    transferTask(this.state.processNumber, user)
      .then(() => {
        this.reloadDiagram(this.state.diagramId, this.props.t('transferOk'));
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  closeTransferTask() {
    this.setState({
      userTransferTask: [],
      showTransferTask: false,
    });
  }

  getCatalogs(diagramType, objectCatalogList, isRecommendation = false) {
    const stateCatalogObjects = isRecommendation ? this.state.recommendationCatalogObjects : this.state.catalogObjects;

    if (diagramType === Constants.EPC_DIAGRAM && Object.keys(stateCatalogObjects)?.length === 0) {
      const catalogObjects = {
        IT_SYSTEM: {
          notPending: objectCatalogList.filter((obj) => obj.idObjectType === Constants.IT_SYSTEM_ID),
          pending: [],
          dialogDetailList: objectCatalogList.filter((obj) => obj.idObjectType === Constants.IT_SYSTEM_ID),
        },
        ROLE: {
          notPending: objectCatalogList.filter((obj) => obj.idObjectType === Constants.ROLE_ID),
          pending: [],
          dialogDetailList: objectCatalogList.filter((obj) => obj.idObjectType === Constants.ROLE_ID),
        },
      };
      this.setState({ [isRecommendation ? 'recommendationCatalogObjects' : 'catalogObjects']: catalogObjects });
    }
  }

  objectCatalogInMyPosition(id) {
    if (id) {
      const object = this.state.catalogObjects[this.state.dialogAttributes.code].notPending.find((o) => id === o.id);

      if (object) {
        return true;
      }
    }

    return false;
  }

  getNewObject(id, title, objectType, node, addedOffset, idShared) {
    const objectToReturn = [];
    const objectPosition = this.props.diagramConfig.connections[objectType].find(({ target }) =>
      target?.includes(Constants.PROCESS_STEP),
    )?.position;
    const symbolOffsetY =
      objectPosition === Constants.TOP_LEFT || objectPosition === Constants.TOP_RIGHT
        ? node.offsetY - node.height / 2
        : node.offsetY + node.height / 2;

    const { symbolPortOffsetX, symbolPortOffsetY, objectPortOffsetY, objectOffsetX, objectOffsetY } =
      DiagramUtils.getXYObjectPosition(objectPosition, symbolOffsetY, addedOffset);

    // Check if port exists/Add a new port for connecting the object to the symbol
    if (node.ports?.length > 0) {
      objectToReturn.port = node.ports.find((elem) => elem.id?.includes(objectPosition));
    }

    if (node.ports?.length === 0 || objectToReturn.port === undefined) {
      objectToReturn.port = {
        // Create port id
        id: `port_${objectPosition}`,
        offset: {
          x: symbolPortOffsetX,
          y: symbolPortOffsetY,
        },
        visibility: PortVisibility.Hidden,
      };
      diagramInstance.addPorts(diagramInstance.selectedItems.nodes[0], [objectToReturn.port]);
    }

    objectToReturn.object = DiagramUtils.getNewObject(
      id,
      title,
      objectType,
      idShared,
      objectOffsetX,
      objectOffsetY,
      objectPortOffsetY,
      diagramInstance,
    );

    return objectToReturn;
  }

  getNewConnector(objectType, object, node, port, responsibility = ' ') {
    let shape;
    let incomingArrow = false;
    const connectionType = this.props.diagramConfig.connections[objectType].find(({ target }) =>
      target?.includes(Constants.PROCESS_STEP),
    )?.type;

    if (connectionType === 'SIMPLE_LINE') {
      shape = 'None';
    } else if (connectionType === 'SIMPLE_ARROW') {
      shape = 'Arrow';
    } else if (connectionType === 'INCOMING_ARROW') {
      shape = 'Arrow';
      incomingArrow = true;
    }

    // Create the connector between the symbol and the object
    let constraints = ConnectorConstraints.Default & ~ConnectorConstraints.Select;
    constraints |= ConnectorConstraints.ReadOnly;

    const annotations =
      objectType === Constants.ROLE
        ? DiagramUtils.getConnectorAnnotation(this.props.t(`abbreviation.${responsibility}`, { lng: this.state.language }))
        : [];

    return {
      // Name of the connector
      id: DiagramUtils.generateConnectorId(diagramInstance.connectors),
      // Responsibility
      addInfo: responsibility,
      // ID of the source and target nodes
      sourceID: incomingArrow ? node.id : object.id,
      targetID: incomingArrow ? object.id : node.id,
      sourcePortID: incomingArrow ? port.id : object.ports[0].id,
      targetPortID: incomingArrow ? object.ports[0].id : port.id,
      type: 'Orthogonal',
      style: {
        strokeColor: '#7b7b7b',
      },
      targetDecorator: { shape },
      constraints,
      annotations,
      zIndex: DiagramUtils.getMaxZIndex(diagramInstance),
    };
  }

  addRoleError(errorMsg) {
    Dialog.showAlert({
      name: 'Warning',
      message: errorMsg,
      isError: true,
    });

    return false;
  }

  getNextPosition(symbol, objectType, object, symbolNode, isMerge) {
    const responsibility = object.LINK_RESPONSIBILITY;

    if (objectType === Constants.ROLE) {
      const roles = symbol.objectsCatalogRelations.filter(
        (obj) => obj.rolResponsability && (!isMerge || obj.idObjectCatalog !== object.id),
      );
      const roleResponsibility = this.getRolResponsability(responsibility);
      const rolesFiltered = roles.filter((role) => role.rolResponsability === roleResponsibility);
      let errorMsg;

      if (roleResponsibility === 1 && rolesFiltered?.length > 0) {
        errorMsg = this.props.t('errors.oneRoleResponsible');

        return this.addRoleError(errorMsg);
      }

      if (roleResponsibility === 1) {
        return DiagramUtils.updateRolesOffsetX(diagramInstance, symbolNode);
      }

      if (rolesFiltered?.length === Constants.MAX_ROLES_LENGTH) {
        errorMsg = this.props.t('errors.maxRoles', {
          numRoles: rolesFiltered?.length,
          responsibility: this.props.t(`responsibility.${responsibility}`),
        });

        return this.addRoleError(errorMsg);
      }

      if (rolesFiltered?.length > 0) {
        return DiagramUtils.getExistingCategoryRolePosition(diagramInstance, roleResponsibility, rolesFiltered, symbolNode);
      }

      if (roles?.length > 0) {
        return DiagramUtils.getNewCategoryRolePosition(diagramInstance, symbol.idSymbolFront);
      }

      return symbolNode.offsetX + symbolNode.actualSize.width / 2 + Constants.ADDED_OFFSET_X;
    }

    const objectPosition = this.props.diagramConfig.connections[objectType].find(({ target }) =>
      target?.includes(Constants.PROCESS_STEP),
    )?.position;

    return DiagramUtils.getNewObjectOffsetX(diagramInstance, symbolNode, objectPosition);
  }

  createIdObjectShared(type, list) {
    let id = 0;
    let maxId = 0;

    list.forEach((item) => {
      id = parseInt(item.idObjectShared.split('_')[3], 10);
      maxId = Math.max(id, maxId);
    });

    return `${this.state.diagramId}_${Constants.OBJECT_TYPE_IDS[type]}_${type.replace('_', '')}_${maxId + 1}`;
  }

  getRolResponsability(responsibility) {
    return Constants.LINK_RESPONSIBILITY_IDS[responsibility];
  }

  getRoleR(objects) {
    return objects.filter((obj) => obj.rolResponsability === Constants.LINK_RESPONSIBILITY_IDS[Constants.RESPONSIBLE]);
  }

  updateObjectsSymbols(
    idNode,
    objectType,
    object,
    objectsDiagram,
    objectsUsed,
    objectsInfoList,
    idGenerated,
    diagramIds,
    catalogObjectsAdded = this.state.catalogObjectsAdded,
  ) {
    const catalogObjects = { ...this.state.catalogObjects };
    const objectsCatalogUsedDiagram = [...this.state.objectsCatalogUsedDiagram];
    const updateSymbols = [...this.state.symbols];

    for (let j = 0; j < this.state.symbols?.length; j++) {
      if (updateSymbols[j].idSymbolFront === idNode) {
        if (Constants.CATALOG_OBJECTS?.includes(objectType)) {
          const newCatalogObject = {
            idObjectCatalog: object.id,
            rolResponsability: object.LINK_RESPONSIBILITY ? this.getRolResponsability(object.LINK_RESPONSIBILITY) : null,
          };

          const objectCatalogExists = updateSymbols[j].objectsCatalogRelations.find((elem) => elem.idObjectCatalog === object.id);

          if (!objectCatalogExists) {
            updateSymbols[j].objectsCatalogRelations.push(newCatalogObject);
            const objectCatalogUsed = objectsCatalogUsedDiagram.find((elem) => elem.id === object.id);

            if (!objectCatalogUsed) {
              const newObject = { ...catalogObjectsAdded.find((elem) => elem.id === object.id) };
              objectsCatalogUsedDiagram.push(newObject);
              catalogObjects[objectType].notPending.push(newObject);
            }
          } else if (
            objectType === Constants.ROLE &&
            newCatalogObject.rolResponsability !== objectCatalogExists.rolResponsability
          ) {
            updateSymbols[j].objectsCatalogRelations.push(newCatalogObject);
          }

          const ids = updateSymbols[j].objectsCatalogRelations.map((elem) => elem.idObjectCatalog);
          const catalogDialogDetailFilter = [...catalogObjects[objectType].dialogDetailList].filter(
            (catalog) => !ids?.includes(catalog.id),
          );
          catalogObjects[objectType].dialogDetailList = catalogDialogDetailFilter;
        } else {
          const idShared = object.newObject ? this.createIdObjectShared(objectType, objectsInfoList) : object.idFront;
          diagramIds.push(idGenerated);
          objectsDiagram.push(idShared);

          if (object.newObject) {
            const attributesData = this.props.getAttributesData(
              this.props.getFormTypesCode({
                status: this.state.diagramLoaded.status,
                type: Constants.OBJECT,
                variant: objectType,
              }),
            );

            objectsUsed.push(
              DiagramUtils.createObjectsUsed(
                object.OBJECT_NAME,
                idShared,
                Constants.OBJECT_TYPE_IDS[objectType],
                attributesData,
                this.props.availableLanguages,
              ),
            );
          }

          objectsInfoList.push({
            OBJECT_NAME: object.OBJECT_NAME,
            idObjectType: Constants.OBJECT_TYPE_IDS[objectType],
            idObjectShared: idShared,
            idFront: idGenerated,
          });
        }

        updateSymbols[j].objectsDiagram = objectsDiagram;
      }
    }

    this.setState({
      catalogObjects,
      objectsCatalogUsedDiagram,
      symbols: updateSymbols,
    });

    return {
      diagramIds,
      objectsDiagram,
      objectsUsed,
      objectsInfoList,
    };
  }

  async createObjects(objectsList, catalogObjects = this.state.catalogObjectsAdded, createdObjectsType, isMerge) {
    if (objectsList.addRoles) {
      this.setState({ showDialogAddRole: true, rolesToBeAdded: objectsList, catalogObjectsAdded: catalogObjects });
    } else {
      objectsList.forEach((object, i) => {
        objectsList[i].LINK_RESPONSIBILITY = object.LINK_RESPONSIBILITY || object.RESPONSIBILITY;
      });
      const node = objectsList[0].symbolNode || diagramInstance.selectedItems.nodes[0];
      let idObject;
      let addedOffset = 0;
      let objectsUsed = [...this.state.objectsUsedDiagram];
      const connectorsList = [...this.state.connectors];

      const objectType = Constants.OBJECTS.find(
        (elem) => elem === (this.state.dialogAttributes.code || createdObjectsType || Constants.ROLE),
      );
      const isObjectCatalog = Constants.CATALOG_OBJECTS?.includes(objectType);
      const symbol = this.state.symbols.find((elem) => elem.idSymbolFront === node.id);

      let objectsInfoList = objectType && isObjectCatalog ? [...this.state.objectCatalogList] : [...this.state.objectsInfo];

      const diagramIds =
        objectType && isObjectCatalog
          ? []
          : objectsInfoList
              .filter((elem) => DiagramUtils.isObjectFromProcessStep(elem.idFront, symbol.idSymbolFront))
              .map((elem) => elem.idFront);

      for (let i = 0; i < objectsList?.length; i++) {
        addedOffset = this.getNextPosition(symbol, objectType, objectsList[i], node, isMerge);

        if (addedOffset || addedOffset === 0) {
          if (isObjectCatalog) {
            idObject = `${Constants.OBJECT}${symbol.idSymbolFront.replace('_', '')}${objectType.replace('_', '')}_${
              objectsList[i].id
            }`;

            if (objectType === Constants.ROLE) {
              idObject += `_${this.getRolResponsability(objectsList[i].LINK_RESPONSIBILITY)}`;
            }
          } else {
            idObject = DiagramUtils.generateId(
              diagramIds,
              `${Constants.OBJECT}${symbol.idSymbolFront}${objectType.replace('_', '')}`,
            );
          }

          const updateLists = await this.updateObjectsSymbols(
            node.id,
            objectType,
            objectsList[i],
            symbol.objectsDiagram,
            objectsUsed,
            objectsInfoList,
            idObject,
            diagramIds,
            catalogObjects,
          );
          symbol.objectsDiagram = [...updateLists.objectsDiagram];
          objectsUsed = [...updateLists.objectsUsed];
          objectsInfoList = [...updateLists.objectsInfoList];
          const responsability = objectType === Constants.ROLE ? objectsList[i].LINK_RESPONSIBILITY : '';

          const newObject = this.getNewObject(
            idObject,
            objectsList[i].OBJECT_NAME,
            objectType,
            node,
            addedOffset,
            symbol.objectsDiagram[symbol.objectsDiagram?.length - 1],
          );

          const objectExists = diagramInstance?.nodes.find((elem) => elem.id === newObject.object.id);

          if (!objectExists) {
            const objectPosition = this.props.diagramConfig.connections[objectType].find(({ target }) =>
              target?.includes(Constants.PROCESS_STEP),
            )?.position;

            diagramInstance.addNode(newObject.object);

            if (objectPosition === Constants.TOP_LEFT || objectPosition === Constants.TOP_RIGHT) {
              diagramInstance.nodes[diagramInstance.nodes.length - 1].offsetY -=
                diagramInstance.nodes[diagramInstance.nodes.length - 1].actualSize.height / 2;
            } else {
              diagramInstance.nodes[diagramInstance.nodes.length - 1].offsetY +=
                diagramInstance.nodes[diagramInstance.nodes.length - 1].actualSize.height / 2;
            }

            let obj = objectsUsed.find((elem) => elem.idFront === newObject.object.addInfo);
            let attributes = obj?.attributes || {};

            if (isObjectCatalog) {
              obj = [...this.state.objectCatalogList, ...this.state.objectsCatalogUsedDiagram].find(
                (o) => o.id === this.getIdCatalogObject(newObject.object.id),
              );

              if (obj.attributes) {
                attributes = JSON.parse(obj.attributes);
              }
            } else if (typeof attributes === 'string') {
              attributes = JSON.parse(attributes);
            }

            this.checkLinkageIcon(newObject.object.id, attributes[this.state.language].LINKAGE);

            const newConnector = this.getNewConnector(objectType, newObject.object, node, newObject.port, responsability);
            diagramInstance.addConnector(newConnector);
            connectorsList.push(newConnector);
            DiagramUtils.updateLongObjectsPosition(diagramInstance, objectPosition, node);
          }
        }
      }

      const objectList = objectType && isObjectCatalog ? 'objectCatalogList' : 'objectsInfo';

      if (objectsList.addRoles !== undefined) {
        if (this.hasValidForm(symbol) && this.hasValidRoles(symbol)) {
          this.toggleSymbolValidation(symbol, true);
        }
      }

      this.setState({
        showDialogDetail: false,
        showObjectDialog: false,
        showDialogAddRole: false,
        connectors: connectorsList,
        objectsUsedDiagram: objectsUsed,
        [objectList]: objectsInfoList, // Añade los nuevos objetos a catálogo o no catálogo
        undoHistory: [],
        redoHistory: [],
        dialogAttributes: [],
      });
    }
  }

  findNodeById(id) {
    return [...diagramInstance?.nodes].find((node) => node.id === id);
  }

  deleteObjectsUsed(nodeId, idShared) {
    for (let i = 0; i < this.state.objectsInfo?.length; i++) {
      const obj = this.state.objectsInfo[i];

      if (nodeId !== obj.idFront && obj.idObjectShared === idShared) {
        // El objeto se está usando en otro símbolo
        return;
      }
    }

    // El objeto no se está usando en otro símbolo y lo eliminamos de la lista de en uso
    const objectsUsedDiagram = [...this.state.objectsUsedDiagram].filter((o) => o.idFront !== idShared);
    this.setState({ objectsUsedDiagram });
  }

  getSymbolByEdge(i, edge, connector) {
    return diagramInstance?.nodes[i][edge] ? diagramInstance?.nodes[i][edge].find((con) => con === connector) : '';
  }

  deleteObject(objectToDelete) {
    const symbols = [...this.state.symbols];
    let symbolFound = false;
    let iterator = 0;
    let symbol;
    let catalog = false;
    const connector = objectToDelete.outEdges[0] || objectToDelete.inEdges[0];

    if (objectToDelete.id?.includes(Constants.IT_SYSTEM.replace('_', '')) || objectToDelete.id?.includes(Constants.ROLE)) {
      catalog = true;
    }

    while (!symbolFound && iterator < diagramInstance?.nodes?.length) {
      if (diagramInstance?.nodes[iterator].id !== objectToDelete.id) {
        symbol = this.getSymbolByEdge(iterator, 'outEdges', connector);

        if (symbol) {
          symbolFound = true;
          symbol = diagramInstance?.nodes[iterator];
        } else {
          symbol = this.getSymbolByEdge(iterator, 'inEdges', connector);

          if (symbol) {
            symbolFound = true;
            symbol = diagramInstance?.nodes[iterator];
          }
        }
      }
      iterator += 1;
    }

    if (symbol) {
      const indexSymbol = this.state.symbols.findIndex((s) => s.idSymbolFront === symbol.id);

      if (indexSymbol !== -1) {
        const symbolConnected = this.state.symbols[indexSymbol];
        let objectsCatalogUsedDiagram = [...this.state.objectsCatalogUsedDiagram];
        let objectIndex;
        const { catalogObjects } = this.state;

        if (catalog) {
          const idItemCatalog = objectToDelete.id.split('_')[1];
          objectIndex = symbolConnected.objectsCatalogRelations.findIndex(
            (obj) => obj.idObjectCatalog.toString() === idItemCatalog,
          );
          const objectCatalogSymbols = this.state.symbols
            .map((elem) => elem.objectsCatalogRelations.find((object) => object.idObjectCatalog.toString() === idItemCatalog))
            .filter((elem) => elem);
          symbolConnected.objectsCatalogRelations.splice(objectIndex, 1);

          if (objectCatalogSymbols?.length === 1) {
            const objectCatalogToAdd = [...this.state.objectCatalogList, ...objectsCatalogUsedDiagram].find(
              (object) => object.id && idItemCatalog === object.id.toString(),
            );
            objectsCatalogUsedDiagram = objectsCatalogUsedDiagram.filter(
              (object) => object.id && idItemCatalog !== object.id.toString(),
            );

            const objectType = Constants.OBJECT_TYPES_BY_ID[objectCatalogToAdd.idObjectType];
            catalogObjects[objectType].dialogDetailList.push(objectCatalogToAdd);
          }
        } else {
          objectIndex = symbolConnected.objectsDiagram.findIndex((obj) => obj === objectToDelete.addInfo);
          symbolConnected.objectsDiagram.splice(objectIndex, 1);
        }

        symbols[indexSymbol] = symbolConnected;
        this.setState({
          symbols,
          catalogObjects,
          objectsCatalogUsedDiagram,
        });
      }
    }

    if (!catalog) {
      this.deleteObjectsUsed(objectToDelete.id, objectToDelete.addInfo);
      const objectsInfo = [...this.state.objectsInfo].filter((o) => o.idFront !== objectToDelete.id);
      this.setState({ objectsInfo });
    }
  }

  resetZoom(diagramType = this.state.diagramType, isRecommendation = false) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    instance.reset();
    instance.scrollSettings.minZoom = 0;
    this.zoomToFit(isRecommendation);

    if (diagramType === Constants.EPC_DIAGRAM) {
      if (instance.scrollSettings.currentZoom !== 1) {
        instance.zoom(1 / instance.scrollSettings.currentZoom);
      }

      const maxOffsetYNode = instance.nodes?.reduce((prev, current) => {
        if (current.id?.includes(Constants.OBJECT)) return prev;

        return prev.offsetY < current.offsetY ? prev : current;
      }, '');

      if (maxOffsetYNode) {
        instance.scrollSettings.verticalOffset = maxOffsetYNode.offsetY - Constants.DIAGRAM_OFFSET_Y;
        instance.scrollSettings.horizontalOffset = maxOffsetYNode.offsetX - Constants.DIAGRAM_OFFSET_X;
      }
    }
  }

  zoomDiagram(zoomType) {
    const zoomOptions = {
      type: zoomType,
      zoomFactor: 0.5,
    };
    const instance =
      this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0]
        ? recommendationDiagramInstance
        : diagramInstance;
    instance.zoomTo(zoomOptions);
  }

  zoomToFit(isRecommendation = false) {
    (isRecommendation ? recommendationDiagramInstance : diagramInstance).fitToPage({
      mode: 'Page',
      region: 'Content',
    });
  }

  drag() {
    this.setState({ dragOn: this.state.dragOn ? '' : 'dragOn' });
    const instance =
      this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0]
        ? recommendationDiagramInstance
        : diagramInstance;
    instance.tool = this.state.dragOn === '' ? DiagramTools.ZoomPan : DiagramTools.None;
  }

  copy() {
    this.setState({ disablePaste: false });
    diagramInstance.copy();
  }

  canCopy() {
    if (this.state.symbolSelected && !this.state.symbolSelected?.includes(Constants.OBJECT)) {
      return false;
    }

    return true;
  }

  renderDiagramInstance() {
    if (diagramInstance?.nodes?.length > 0) {
      diagramInstance.nodes[0].rotateAngle = 1;
      diagramInstance.nodes[0].rotateAngle = 0;
    }
  }

  paste() {
    diagramInstance.clearSelection();
    this.symbolPaste = true;
    diagramInstance.paste();
    diagramInstance.clearSelection();
    // this.renderDiagramInstance();
    this.symbolPaste = false;
    this.setState({ disablePaste: true });
  }

  getHistoryName(symbol) {
    return {
      id: symbol.idSymbolFront || symbol.idFront,
      name: symbol.attributes[this.state.language].TEXT_BLOCK || symbol.attributes[this.state.language].OBJECT_NAME,
    };
  }

  undoRedoAction(isUndo) {
    const { lastAction, actionsHistory } = this.getHistory(isUndo);

    if (lastAction?.change || lastAction?.type) {
      const actionType = lastAction.type || lastAction.change.type;
      let symbols = [...this.state.symbols];
      const objectsUsedDiagram = [...this.state.objectsUsedDiagram];

      if ((actionType === Constants.NEW_SYMBOL && isUndo) || (!isUndo && actionType === Constants.REMOVE_SYMBOL)) {
        const symbolIndex = symbols.findIndex((s) => s.idSymbolFront === lastAction.data.idSymbolFront);
        this.removeSymbol(symbolIndex);
        symbols = [...this.state.symbols];
      } else if ((actionType === Constants.REMOVE_SYMBOL && isUndo) || (!isUndo && actionType === Constants.NEW_SYMBOL)) {
        symbols.push(lastAction.data);
      } else if (actionType === Constants.NAME_CHANGED) {
        const symbolEdited = symbols.find((s) => s.idSymbolFront === lastAction.data.id);

        if (symbolEdited) {
          if (symbolEdited.attributes[lastAction.lang].TEXT_BLOCK) {
            symbolEdited.attributes[lastAction.lang].TEXT_BLOCK = isUndo ? lastAction.data.name : lastAction.redoName;
          } else {
            symbolEdited.attributes[lastAction.lang].OBJECT_NAME = isUndo ? lastAction.data.name : lastAction.redoName;
          }
        } else {
          const object = objectsUsedDiagram.find((o) => o.idFront === lastAction.data.id);
          object.attributes[lastAction.lang].OBJECT_NAME = isUndo ? lastAction.data.name : lastAction.redoName;
        }
        diagramInstance.clearSelection();
      }

      if (isUndo) {
        diagramInstance.undo();
      } else {
        diagramInstance.redo();
      }

      const otherHistory = [...this.state[isUndo ? 'redoHistory' : 'undoHistory'], lastAction];
      this.setState({
        undoHistory: isUndo ? actionsHistory : otherHistory.slice(-Constants.MAX_UNDO) || [],
        redoHistory: !isUndo ? actionsHistory : otherHistory.slice(-Constants.MAX_UNDO),
        symbols,
        objectsUsedDiagram,
        lastAction,
      });
    }
  }

  getHistory(isUndo) {
    const actionsHistory = isUndo ? [...this.state.undoHistory] : [...this.state.redoHistory];

    return {
      lastAction: actionsHistory.pop(),
      actionsHistory,
    };
  }

  isShape(event) {
    const formShape = event.source[0]?.shape;
    const id = event.source[0]?.id;
    // Shapes, images and lines. Not connectors, objects or gates

    return (
      (formShape?.type === 'Basic' ||
        (formShape?.type === 'Image' && event.change.type === 'CollectionChanged') ||
        formShape?.type === 'None') &&
      event.source[0]?.type !== 'Orthogonal' &&
      !id?.includes(Constants.OBJECT) &&
      !id?.includes(Constants.GATE)
    );
  }

  historyChange(event) {
    if (event.action !== 'Undo' && (Constants.UNDO_ACTIONS?.includes(event.type || event.change.type) || this.isShape(event))) {
      const undoHistory = [...(this.state.undoHistory || []), event];
      this.setState({ undoHistory: undoHistory.slice(-Constants.MAX_UNDO) });
    }
  }

  hideDialogOk() {
    this.setState({ showDialogOk: false });
  }

  closeDeleteDiagramDialog() {
    this.setState({
      showDeleteDiagramDialog: false,
      confirmationValue: '',
    });
  }

  confirmDelete() {
    if (this.state.symbolToDelete) {
      const idNode = diagramInstance?.nodes.findIndex((n) => this.state.symbolToDelete === n);

      if (idNode !== -1) {
        const nodeToRemove = diagramInstance?.nodes[idNode];
        const idSymbol = this.state.symbols.findIndex((s) => nodeToRemove.id === s.idSymbolFront);
        this.removeSymbol(idSymbol);

        if (nodeToRemove.id?.includes('group')) {
          this.setState({ groupToDelete: nodeToRemove.id });
          nodeToRemove.children.forEach((item) => {
            const nodeEnc = diagramInstance?.nodes.find((node) => node.id === item);
            diagramInstance.remove(nodeEnc);
          });
        } else {
          if (nodeToRemove.shape.type === Constants.IMAGE) {
            this.onDeleteImage(nodeToRemove.shape.properties.source);
          }
          diagramInstance.remove(nodeToRemove);
        }
      }
      this.setState({
        groupToDelete: null,
        symbolToDelete: null,
        undoHistory: [],
        redoHistory: [],
      });
    } else if (this.isSandbox) {
      sandboxService
        .deleteSandbox(this.state.diagramId, this.state.confirmationValue)
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('Deleted sandbox with ID', this.state.diagramId);
          this.closeDeleteDiagramDialog();
          this.props.history.push('/sandbox-overview');
        })
        .catch((err) => {
          this.closeDeleteDiagramDialog();
          services.handleServiceError(err);
        });
    } else {
      services
        .deleteDiagram(this.state.diagramId, this.state.confirmationValue)
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('Deleted diagram with ID', this.state.diagramId);
          this.closeDeleteDiagramDialog();
          this.props.history.push(`/diagram/${this.state.diagramLoaded.idDiagramParent}`);
        })
        .catch((err) => {
          this.closeDeleteDiagramDialog();
          services.handleServiceError(err);
        });
    }
  }

  openPopUpOk(event, diagramOrFunction) {
    const contentDialog = this.props.t(DiagramUtils.iconsEvent[event].content[diagramOrFunction], {
      diagramName: this.state.attributes[this.state.language].PROCESS_NAME,
      diagramType: this.props.t(this.state.diagramType),
    });

    this.setState({
      showDialogOk: true,
      headerDialog: this.props.t(DiagramUtils.iconsEvent[event].header),
      contentDialog,
      heightDialog: DiagramUtils.iconsEvent[event].height,
      widthDialog: DiagramUtils.iconsEvent[event].width,
    });
  }

  openConfirmDialog(event, diagramOrFunction) {
    const contentDialog = this.props.t(DiagramUtils.iconsEvent[event].contentConfirm[diagramOrFunction], {
      diagramName: this.state.attributes[this.state.language].PROCESS_NAME,
      diagramType: this.props.t(this.state.diagramType),
    });
    const confirmDialogData = {
      title: this.props.t(DiagramUtils.iconsEvent[event].header),
      message: contentDialog,
      textCancel: this.props.t('cancel'),
      textOk: this.props.t('ok'),
      okClick: () => {
        this.confirmDialog.hide();
        this.confirmDelete();
      },
    };
    this.confirmDialog = Dialog.showConfirm(confirmDialogData);
  }

  createNewConnector(connector) {
    let errorMessage = '';

    const connectorExists = diagramInstance.connectors.find(
      (elem) =>
        (elem.sourceID === connector.sourceID && elem.targetID === connector.targetID) ||
        (elem.targetID === connector.sourceID && elem.sourceID === connector.targetID),
    );

    if (connector.sourceID === '' || connector.targetID === '') {
      errorMessage = this.props.t('errors.connectionStartEnd');
    } else if (connector.sourceID === connector.targetID) {
      errorMessage = this.props.t('errors.selfConnection');
    } else if (!this.canCreateConnector(connector)) {
      errorMessage = this.props.t('errors.connectionNotAllowed');
    } else if (connectorExists) {
      errorMessage = this.props.t('errors.connectionExists');
    }

    this.createConnectorErrorMessage = errorMessage;

    if (!errorMessage && connector.id !== this.state.lastAction?.id) {
      this.historyChange({ type: Constants.NEW_CONNECTOR });
    }
  }

  canCreateConnector(connector) {
    if (
      this.state.paletteToolSelected !== 'connector' &&
      (connector.sourceID.indexOf('OBJECT') === 0 || connector.targetID.indexOf('OBJECT') === 0)
    ) {
      return true;
    }

    const sourceType = this.getSymbolTypeByIdFront(connector.sourceID);

    return this.props.diagramConfig.connections[sourceType].some(({ target }) =>
      target.some((code) => connector.targetID.startsWith(code)),
    );
  }

  removeConnector(id) {
    const connectors = diagramInstance.connectors.filter((elem) => elem.id !== id);
    diagramInstance.connectors = connectors;
    diagramInstance.dataBind();

    if (connectors) {
      this.historyChange({ type: Constants.REMOVE_CONNECTOR });
    }
  }

  removeProcessStepObjects(index) {
    const symbol = this.state.symbols[index];
    const objects = diagramInstance?.nodes.filter((n) => DiagramUtils.isObjectFromProcessStep(n.id, symbol.idSymbolFront));

    while (objects?.length > 0) {
      const obj = objects.pop();
      diagramInstance.remove(obj);
    }
  }

  removeSymbol(id) {
    if (id !== -1) {
      if (this.state.symbols[id].idSymbolFront?.includes(Constants.PROCESS_STEP)) {
        this.removeProcessStepObjects(id);
      }
      this.state.symbols.splice(id, 1);
    }
  }

  reorderingProcessStep(symbols) {
    const dataSymbols = (symbols || this.state.symbols).filter((symbol) => symbol.idSymbolType === 3);

    if (dataSymbols?.length >= 1) {
      dataSymbols
        .sort(
          (symbolA, symbolB) =>
            symbolA.attributes[Constants.NOT_TRANSLATABLE].PROCESS_STEP_NUMBER -
            symbolB.attributes[Constants.NOT_TRANSLATABLE].PROCESS_STEP_NUMBER,
        )
        .map((symbol, i) => (symbol.attributes[Constants.NOT_TRANSLATABLE].PROCESS_STEP_NUMBER = i + 1));
    }
  }

  updateBreadCrumb() {
    this.breadcrumbNodes = [];
    this.prepareBreadCrumb(this.state.treeExplorer.dataSource, this.state.diagramId);
  }

  handleTextEdit(args) {
    const symbolSelect = this.getNodeSelected();
    const updateSymbols = [...this.state.symbols];

    if (symbolSelect) {
      this.historyChange({
        type: Constants.NAME_CHANGED,
        data: this.getHistoryName(symbolSelect),
        redoName: args.newValue,
        lang: this.state.language,
      });

      const symbol = updateSymbols.find((elem) => elem.idSymbolFront === symbolSelect.idSymbolFront);

      if (symbol.type.toUpperCase() === Constants.TEXT) {
        symbol.attributes[this.state.language].TEXT_BLOCK = args.newValue;
      } else {
        symbol.attributes[this.state.language].OBJECT_NAME = args.newValue;
      }

      this.setState({ symbols: updateSymbols }, () => {
        this.setForm(symbol.idSymbolFront);

        if (symbol.type.toUpperCase() !== Constants.TEXT) {
          this.updateSymbolSize(symbol.idSymbolFront, args.newValue);
        }
      });
    }
  }

  updateSymbolSize(symbolId, parsedName) {
    const node = diagramInstance?.nodes.find((elem) => elem.id === symbolId);
    const nodeWrapperId = node.wrapper.children[0].id;
    const textWrapperId = node.wrapper.children[1].id;

    node.annotations[0].content = parsedName;
    diagramInstance.dataBind();

    const zoom = diagramInstance.scrollSettings.currentZoom;
    const textSize = document.querySelector(`[id^="${textWrapperId}"]`)?.getBoundingClientRect();
    const elementDOM = document.getElementById(`${nodeWrapperId}`)?.getBoundingClientRect();
    const widthMargin = symbolId?.includes(Constants.EVENT) ? Constants.EVENT_WIDTH_MARGIN : Constants.ADDED_NODE_WIDTH;
    let updatedElementDOM;

    if (textSize && elementDOM) {
      if (!symbolId?.includes(Constants.OBJECT)) {
        if (textSize.width / zoom >= elementDOM.width / zoom - widthMargin) {
          if (textSize.width > elementDOM.width) {
            node.width = (textSize.width + (textSize.width - elementDOM.width) + widthMargin * 2) / zoom;
          } else {
            const addedWidth = symbolId?.includes(Constants.PROCESS_INTERFACE)
              ? Constants.ADDED_NODE_WIDTH * 2
              : Constants.ADDED_NODE_WIDTH;
            node.width = node.actualSize.width + addedWidth;
            diagramInstance.dataBind();

            updatedElementDOM = document.getElementById(`${nodeWrapperId}`).getBoundingClientRect();
            node.annotations[0].width = updatedElementDOM.width / zoom - widthMargin;
          }
        }
      } else {
        const iconWrapperId = node.wrapper.children[2].id;
        const iconSize = document.querySelector(`[id^="${iconWrapperId}"]`).getBoundingClientRect();

        node.width =
          (textSize.width +
            iconSize.width +
            (textSize.width + iconSize.width - elementDOM.width) +
            Constants.OBJECT_WIDTH_MARGIN * 2) /
          zoom;

        if (node.width < Constants.OBJECT_DEFAULT_WIDTH) node.width = Constants.OBJECT_DEFAULT_WIDTH;
      }
    }
    Utils.alignProcessInterfaceAnnotations(node);
    diagramInstance.dataBind();

    const updatedTextSize = document.querySelector(`[id^="${textWrapperId}"]`)?.getBoundingClientRect();
    updatedElementDOM = document.getElementById(`${nodeWrapperId}`)?.getBoundingClientRect();

    if (updatedTextSize && updatedElementDOM) {
      if (
        symbolId?.includes(Constants.PROCESS_INTERFACE) &&
        updatedElementDOM.height - updatedTextSize.height / 5 - Constants.PROCESS_INTERFACE_ADDED_MARGIN <= updatedTextSize.height
      ) {
        node.height = (updatedTextSize.height + updatedTextSize.height / 5 + Constants.PROCESS_INTERFACE_ADDED_HEIGHT) / zoom;
        node.annotations[0].margin = { bottom: updatedTextSize.height / 5 / zoom - Constants.PROCESS_INTERFACE_ADDED_MARGIN };
      } else if (!symbolId?.includes(Constants.PROCESS_INTERFACE) && updatedElementDOM.height <= updatedTextSize.height) {
        let zoomFactor = 1;

        if (symbolId?.includes(Constants.EVENT)) {
          zoomFactor = zoom > 1 ? 1 / zoom : zoom;
        }

        node.height = (updatedTextSize.height + (updatedTextSize.height - updatedElementDOM.height) * zoomFactor) / zoom;
      }
    }

    diagramInstance.dataBind();

    Utils.keepNodeAngles(node, node.width, node.height);
  }

  handleDiagramSelectionChange(args) {
    if (args.newValue[0] === null) {
      this.resetSelectionControl();

      return;
    }

    const canSelect = this.isObjectFromProcessStep(args) || this.canSelect;

    if (canSelect) {
      // habilita la opción de copiar siempre y cuando se seleccione un símbolo o varios, no aplica a objetos.
      let disableCopy = true;
      const selectedItem = args.newValue[0];
      let selection = '';
      selection = this.isSymbolSelectable(selectedItem) ? selectedItem.id : '';
      if (args.newValue?.length > 1) {
        disableCopy = false;

        for (let i = 0; i < args.newValue?.length; i++) {
          if (args.newValue[i].id?.includes(Constants.OBJECT) || args.newValue[i].id?.includes('connector')) {
            disableCopy = true;
          }
        }
        if (!this.state.onlyRead && selection.startsWith(Constants.PROCESS_STEP)) {
          diagramInstance.selectedItems.nodes.forEach((node) => {
            node.constraints |= NodeConstraints.Drag;
          });
        }

        if (args.oldValue[0] !== args.newValue[0] && args.oldValue[0]?.id.startsWith(Constants.PROCESS_STEP)) {
          args.oldValue.forEach((node) => {
            if (node.id.startsWith(Constants.OBJECT)) {
              node.constraints =
                NodeConstraints.Default & ~(NodeConstraints.Rotate | NodeConstraints.Tooltip | NodeConstraints.Drag);
            }
          });
        }
        this.setState({ multipleSymbols: true, showFormattingTools: false, disableCopy });

        return;
      }

      if (args.state === 'Changed') {
        const timeout = 500;
        let showFormattingTools = false;

        this.resetSelectionControl(timeout);

        if (selectedItem instanceof SyncfusionNode) {
          selection = this.isSymbolSelectable(selectedItem) ? selectedItem.id : '';

          if (selection !== '' && selectedItem.shape.type !== Constants.IMAGE && !selection?.includes(Constants.OBJECT)) {
            this.setContextualMenu(selection);
          }

          if (!this.state.onlyRead && selection.startsWith(Constants.PROCESS_STEP)) {
            diagramInstance.selectedItems.nodes.forEach((node) => {
              node.constraints |= NodeConstraints.Drag;
            });
          }

          if (
            !this.state.onlyRead &&
            diagramInstance?.selectedItems.nodes?.length === 1 &&
            ((this.state.diagramType === Constants.VCD_DIAGRAM &&
              this.context.checkRole(Constants.QI_MODELER) &&
              diagramInstance.selectedItems.nodes[0].shape.type !== Constants.IMAGE) ||
              (this.state.diagramType === Constants.EPC_DIAGRAM &&
                (this.context.checkRole(Constants.MODELER) || this.context.checkRole(Constants.QI_MODELER)) &&
                diagramInstance.selectedItems.nodes[0].shape.type.toUpperCase() === Constants.TEXT) ||
              (diagramInstance.selectedItems.nodes[0].shape instanceof BasicShape &&
                !diagramInstance.selectedItems.nodes[0].id?.includes(Constants.OBJECT) &&
                !diagramInstance.selectedItems.nodes[0].id?.includes(Constants.GATE) &&
                !diagramInstance.selectedItems.nodes[0].id?.includes(Constants.SWIMLANE)))
          ) {
            showFormattingTools = true;

            if (this.state.copiedNodeStyles && args.oldValue[0]) {
              Utils.copyNodeStyles(selectedItem, args.oldValue[0]);
            }
          }
        }

        if (args.oldValue[0] !== args.newValue[0] && args.oldValue[0]?.id.startsWith(Constants.PROCESS_STEP)) {
          args.oldValue.forEach((node) => {
            if (node.id.startsWith(Constants.PROCESS_STEP)) {
              node.constraints =
                NodeConstraints.Default & ~(NodeConstraints.Rotate | NodeConstraints.Tooltip | NodeConstraints.Drag);
            }
          });
        }

        this.setState(
          {
            multipleSymbols: false,
            disableCopy: selectedItem?.id?.includes(Constants.OBJECT) || selectedItem?.shape.type === Constants.IMAGE,
            showFormattingTools,
          },
          () => {
            this.setForm(selection);
          },
        );
      }

      if (args.newValue?.length === 0) {
        this.setState({ showFormattingTools: false });
      }
    } else {
      args.cancel = true;
    }
  }

  isObjectFromProcessStep({ newValue, oldValue }) {
    return newValue?.length > 0 && oldValue?.length > 0 && DiagramUtils.isObjectFromProcessStep(newValue[0].id, oldValue[0].id);
  }

  resetSelectionControl(milliseconds = 500) {
    this.canSelect = false;

    setTimeout(() => {
      this.canSelect = true;
    }, milliseconds);
  }

  toggleCanSelect(value) {
    this.canSelect = value || !this.canSelect;
  }

  handleDiagramPositionChange(args) {
    const isGroup = args.source.nodes?.length > 1;
    const selectionId = args.source instanceof Selector ? args.source?.nodes[0]?.id : args.source.id;
    let areUserHandlesVisible = false;
    let objects;

    if (isGroup && args.source.nodes.some((node) => node.id?.includes(Constants.OBJECT))) {
      objects = args.source.nodes.filter((node) => node.id?.includes(Constants.OBJECT));

      objects.forEach((object) => {
        if (!args.source.nodes.some((node) => object.id !== node.id && object.id?.includes(node.id.replace('_', '')))) {
          args.cancel = true;
        }
      });
    }

    if (this.isDiagramOnlyRead() || selectionId?.includes(Constants.OBJECT)) {
      args.cancel = true;
    }

    if (args.source.userHandles) {
      if (args.state === 'Completed') areUserHandlesVisible = true;
      args.source.userHandles.forEach((userHandle) => (userHandle.visible = areUserHandlesVisible));
    }

    if (
      !args.cancel &&
      (selectionId?.includes(Constants.PROCESS_STEP) ||
        (isGroup && args.source.nodes.some((node) => node.id?.includes(Constants.PROCESS_STEP))))
    ) {
      if (args.state === 'Start') {
        const nodes = args.source.nodes
          ? args.source.nodes.filter((node) => node.id?.includes(Constants.PROCESS_STEP))
          : [args.source];
        nodes.forEach((processStep) => {
          const symbol = this.state.symbols.find((elem) => elem.idSymbolFront === processStep.id);
          objects = diagramInstance?.nodes.filter((node) => DiagramUtils.isObjectFromProcessStep(node.id, symbol.idSymbolFront));

          diagramInstance.select(objects, true);
        });
      }
      if (args.state === 'Completed') {
        this.resetSelectionControl();
        diagramInstance.clearSelection();
      }
    }

    if (args.state === 'Completed' && this.state.paletteToolSelected === 'optimize') {
      this.diagramConnections = '';
      this.setState({ paletteToolSelected: '' });
    }
  }

  handleDiagramConnectionChange(args) {
    if (args.state === 'Changed') {
      this.createNewConnector(args.connector);

      if (this.createConnectorErrorMessage) {
        Dialog.showAlert({
          name: this.props.t('errors.connectionError'),
          message: this.createConnectorErrorMessage,
        });

        // Timeout to let de event finish.
        setTimeout(() => {
          diagramInstance.undo();
          this.createConnectorErrorMessage = '';
        }, 100);
      }
    }
  }

  handleDiagramDrop(event, pasteSymbol = false) {
    event.cancel = true;
    this.resetSelectionControl();

    if (
      !event.element ||
      (event.element.id &&
        diagramInstance?.nodes.some((node) => node.id === event.element.id) &&
        !pasteSymbol &&
        event.element.shape?.type !== Constants.IMAGE) ||
      (event.element.nodes?.length && event.element.shape?.type !== Constants.IMAGE)
    ) {
      return;
    }

    const symbolCode =
      event.element.shape.type === Constants.IMAGE
        ? Constants.IMAGE.toUpperCase()
        : this.getSymbolTypeByIdFront(event.element.id);
    const idSymbolFront = event.element.shape.type === Constants.IMAGE ? event.element.id : `${symbolCode}${uuidv4()}`;
    const newSymbol = this.createSymbol(symbolCode, idSymbolFront);

    if (event.element.shape.type !== Constants.IMAGE) {
      const symbolDefaults = this.state.symbolsPalette.find((elem) => newSymbol.idSymbolFront?.includes(elem.id));
      const node = event.element;
      const nodeDef = {
        ...symbolDefaults,
        annotations: node.annotations,
        height: node.height,
        id: newSymbol.idSymbolFront,
        offsetX: node.offsetX,
        offsetY: node.offsetY,
        ...(pasteSymbol ? { shape: node.shape, style: node.style } : {}),
        width: node.width,
      };
      diagramInstance.add(nodeDef);
    }

    /* Si se arrastra un objeto, inicializamos los atributos, si se copia y pega, se obtienen los atributos del
        símbolo seleccionado */

    if (pasteSymbol) {
      const symbolCopied = Utils.cloneObject(
        this.state.symbols.find((elem) => elem.idSymbolFront === event.element.id.slice(0, -5)),
      );
      newSymbol.attributes = symbolCopied.attributes;
    } else if (newSymbol.type.toUpperCase() !== Constants.TEXT) {
      Object.keys(newSymbol.attributes).forEach((lang) => {
        if (lang === Constants.NOT_TRANSLATABLE) return;
        newSymbol.attributes[lang].OBJECT_NAME = this.props.t(event.element.properties.data, { lng: lang });
      });
    }

    if (newSymbol.idSymbolType === 3) {
      const dataSymbols = this.state.symbols.filter((symbol) => symbol.idSymbolType === 3);
      let max = 0;
      dataSymbols.forEach((symbol) => {
        max = Math.max(max, symbol.attributes[Constants.NOT_TRANSLATABLE].PROCESS_STEP_NUMBER);
      });
      newSymbol.attributes[Constants.NOT_TRANSLATABLE].PROCESS_STEP_NUMBER = max + 1;

      const { catalogObjects } = this.state;
      catalogObjects.IT_SYSTEM.dialogDetailList = this.state.catalogObjects.IT_SYSTEM.notPending;
      this.setState({ catalogObjects });
    }

    const oldSymbols = this.state.symbols.filter((symbol) => symbol.idSymbolFront !== newSymbol.idSymbolFront);

    this.setState({ symbols: [...oldSymbols, newSymbol] }, () => {
      this.historyChange({ type: Constants.NEW_SYMBOL, data: newSymbol });
    });
  }

  handleDoubleClick(event) {
    if (
      !this.state.onlyRead &&
      event.source instanceof SyncfusionNode &&
      ((this.state.diagramType === Constants.VCD_DIAGRAM &&
        this.context.checkRole(Constants.QI_MODELER) &&
        event.source.shape.type !== Constants.IMAGE) ||
        (this.state.diagramType === Constants.EPC_DIAGRAM &&
          (this.context.checkRole(Constants.MODELER) || this.context.checkRole(Constants.QI_MODELER)) &&
          event.source.shape.type.toUpperCase() === Constants.TEXT) ||
        (event.source.shape instanceof BasicShape && !event.source.id?.includes(Constants.GATE)))
    ) {
      this.setState({ showFormattingTools: false });
    }
  }

  getCommandsManager = () => ({
    commands: [
      {
        name: 'customCopy',
        canExecute: () => !this.state.disableCopy,
        execute: () => {
          // TODO: por ahora se mantiene comentado, para que no se pueda ejecutar la copia desde el teclado.
          // this.copy();
        },
        gesture: {
          key: Keys.C,
          keyModifiers: KeyModifiers.Control,
        },
      },
      {
        name: 'customPaste',
        canExecute: () => !this.state.disablePaste,
        execute: () => {
          // TODO: por ahora se mantiene comentado, para que no se pueda ejecutar el pegado desde el teclado.
          // this.paste();
        },
        gesture: {
          key: Keys.V,
          keyModifiers: KeyModifiers.Control,
        },
      },
      {
        name: 'customUndo',
        canExecute: () => false,
        execute: () => {
          // TODO: por ahora se mantiene comentado, para que no se pueda ejecutar el pegado desde el teclado.
          // this.undoRedoAction(true);
        },
        gesture: {
          key: Keys.Z,
          keyModifiers: KeyModifiers.Control,
        },
      },
      {
        name: 'customRedo',
        canExecute: () => false,
        execute: () => {
          // TODO: por ahora se mantiene comentado, para que no se pueda ejecutar el pegado desde el teclado.
          // this.undoRedoAction(false);
        },
        gesture: {
          key: Keys.Y,
          keyModifiers: KeyModifiers.Control,
        },
      },
      {
        name: 'customRemove',
        canExecute: () => this.canSelect,
        execute: () => {
          this.resetSelectionControl(2000);

          if (
            diagramInstance.selectedItems.nodes?.length === 1 &&
            !diagramInstance.selectedItems.nodes[0].id?.includes(Constants.OBJECT)
          ) {
            diagramInstance.remove(diagramInstance.selectedItems.nodes[0]);
          } else if (
            diagramInstance.selectedItems.nodes?.length === 0 &&
            diagramInstance.selectedItems.connectors?.length === 1
          ) {
            diagramInstance.remove(diagramInstance.selectedItems.connectors[0]);
          } else if (
            diagramInstance.selectedItems.nodes?.length === 1 &&
            diagramInstance.selectedItems.nodes[0].id?.includes(Constants.OBJECT)
          ) {
            DiagramUtils.updateObjectsPosition(diagramInstance.selectedItems.nodes[0], diagramInstance);
          }
        },
        gesture: {
          key: Keys.Delete,
        },
      },
    ],
  });

  createSymbol(symbolCode, idSymbolFront) {
    const idSymbolType = Constants.SYMBOL_TYPE_IDS[this.getSymbolTypeByIdFront(symbolCode)];
    const lang = this.state.language;

    const symbolFormTypesCode = this.props.getFormTypesCode({
      status: this.state.diagramLoaded.status,
      type: Constants.SYMBOL,
      variant: symbolCode,
    });
    let attributesData = [];
    let fieldConfig;

    if (this.props.formTypes[symbolFormTypesCode]) {
      fieldConfig = this.props.getReactReactiveFormFieldConfig(
        symbolFormTypesCode,
        this.props.featureFlags.isFreezed || this.state.diagramLoaded.onlyReadUsedBy,
      );
      fieldConfig = this.addExtraMetaFieldConfig(fieldConfig);
      attributesData = this.props.getAttributesData(symbolFormTypesCode);
    }

    const attributes = DiagramUtils.createNewSymbolAttributes(fieldConfig?.[lang], attributesData, this.props.availableLanguages);

    return {
      attributes,
      id: 0,
      idDiagram: this.state.diagramId,
      idDiagramLink: 0,
      idSymbolFront,
      idSymbolType,
      fieldConfig,
      type: symbolCode,
      objectsCatalogRelations: [],
      objectsDiagram: [],
    };
  }

  prepareFormatAttributes(attributes) {
    const lang = Constants.NOT_TRANSLATABLE;
    if (!attributes[lang]) return attributes;
    const userNameCreator = this.state.users.find((user) => user.code === attributes[lang].CREATOR_CODE);

    if (userNameCreator) {
      attributes[lang].CREATOR_CODE = userNameCreator.commonName;
    }

    const userNameLastEditor = this.state.users.find((user) => user.code === attributes[lang].LAST_EDITOR_CODE);

    if (userNameLastEditor) {
      attributes[lang].LAST_EDITOR_CODE = userNameLastEditor.commonName;
    }

    attributes[lang].CREATION_DATE = Utils.getFullFormattedDate(attributes[lang].CREATION_DATE);
    attributes[lang].LAST_MODIFICATION = Utils.getFullFormattedDate(attributes[lang].LAST_MODIFICATION);

    return attributes;
  }

  loadSymbols(symbols, isRecommendation = false) {
    return symbols?.map((symbol) => {
      symbol.attributes = !symbol.attributes ? null : this.prepareFormatAttributes(JSON.parse(symbol.attributes));
      symbol.objectsDiagram = symbol.objectsDiagram
        ? Utils.parseObjectsDiagramList(symbol.objectsDiagram)
        : symbol.objectsDiagram;
      symbol.objectsCatalogRelations = Utils.parseObjectsCatalogList(symbol.objectsCatalogRelations);

      this.checkDiagramLink(symbol, isRecommendation);
      this.checkLinkageIcon(symbol.idSymbolFront, symbol.attributes?.[this.state.language]?.LINKAGE, isRecommendation);
      return symbol;
    });
  }

  loadObjectsDiagram() {
    for (let i = 0; i < diagramInstance?.nodes?.length; i++) {
      const node = diagramInstance?.nodes[i];
      let object = this.state.objectsUsedDiagram.find((o) => o.idFront === node.addInfo);

      if (node.id?.includes(Constants.ROLE) || node.id?.includes(Constants.IT_SYSTEM.replace('_', ''))) {
        object = this.state.objectsCatalogUsedDiagram.find((o) => o.id === this.getIdCatalogObject(node.id));
      }

      if (object) {
        const attributes = Utils.getObjectAttributes(object.attributes);
        node.annotations[0].content = attributes[this.state.language].OBJECT_NAME;
        this.toggleLinkageIcon(node, attributes[this.state.language].LINKAGE);
      }
    }
  }

  loadRecommendationObjectsDiagram() {
    for (let i = 0; i < recommendationDiagramInstance.nodes?.length; i++) {
      const node = recommendationDiagramInstance.nodes[i];
      let object = this.state.objectsUsedRecommendationDiagram.find((o) => o.idFront === node.addInfo);

      if (node.id?.includes(Constants.ROLE) || node.id?.includes(Constants.IT_SYSTEM.replace('_', ''))) {
        object = this.state.objectsCatalogUsedRecommendationDiagram.find((o) => o.id === this.getIdCatalogObject(node.id));
      }

      if (object) {
        const attributes = Utils.getObjectAttributes(object.attributes);
        node.annotations[0].content = attributes[this.state.language]?.OBJECT_NAME || '';
      }
    }
  }

  getIdCatalogObject(id) {
    const idFront = id.split('_');

    return parseInt(idFront[id?.includes(Constants.ROLE) ? idFront?.length - 2 : idFront?.length - 1], 10);
  }

  refreshForm(form, attributes, formTypesCode, fieldConfig = {}) {
    const keys = Object.keys(form);
    const attributesUpdated = Utils.getObjectAttributes(attributes);
    const attributesData = this.props.getAttributesData(formTypesCode);
    let fieldConfigUpdated = { ...fieldConfig };

    for (let i = 0; i < keys?.length; i++) {
      const field = attributesData.find((el) => el.code === keys[i]);

      if (field?.translatable) {
        if (
          field.code === Constants.ADDITIONAL_DOCUMENTS &&
          attributesUpdated[this.state.language][keys[i]]?.length > form[keys[i]]?.length
        ) {
          this.props.availableLanguages.forEach((language) => {
            if (language === this.state.language) return;
            const filteredValue = attributesUpdated[language][keys[i]].filter((prevItem) =>
              form[keys[i]].some((item) => item[Constants.DOCUMENT_UUID] === prevItem[Constants.DOCUMENT_UUID]),
            );
            attributesUpdated[language][keys[i]] = filteredValue;
          });
        }
        attributesUpdated[this.state.language][keys[i]] = form[keys[i]];
      } else {
        attributesUpdated[Constants.NOT_TRANSLATABLE][keys[i]] = form[keys[i]];
      }
    }

    fieldConfigUpdated = this.addSpecialFieldsInitialValuesFieldConfig(fieldConfigUpdated, attributesUpdated);

    return {
      attributesUpdated,
      fieldConfigUpdated,
    };
  }

  submitAdditionalDocumentsForm(newValue) {
    const attributesUpdated = { ...this.state.attributes };
    this.props.availableLanguages.forEach((language) => {
      if (language === this.state.language) return;
      const index = attributesUpdated[language][Constants.ADDITIONAL_DOCUMENTS]?.findIndex(
        (document) => document[Constants.DOCUMENT_UUID] === newValue[language][Constants.DOCUMENT_UUID],
      );

      if (index > -1) {
        attributesUpdated[language][Constants.ADDITIONAL_DOCUMENTS][index] = newValue[language];
      } else {
        attributesUpdated[language][Constants.ADDITIONAL_DOCUMENTS] = [
          ...attributesUpdated[language][Constants.ADDITIONAL_DOCUMENTS],
          newValue[language],
        ];
      }
    });
    this.setState({
      attributes: attributesUpdated,
    });
  }

  getAdditionalDocumentValues(uuid) {
    const values = {};
    this.props.availableLanguages.forEach((language) => {
      values[language] = this.state.attributes[language][Constants.ADDITIONAL_DOCUMENTS].find(
        (document) => document[Constants.DOCUMENT_UUID] === uuid,
      );
    });

    return values;
  }

  updateDiagramName(newName, isSaved = false) {
    if (!this.state.loadingTree) {
      let tree = [];
      let node = {};
      let treeList = '';

      if (this.isSandbox) {
        tree = Utils.cloneObject(this.state.sandboxTree);
        node = tree.find((elem) => elem.id === this.state.diagramId);
        treeList = 'sandboxTree';
      } else {
        tree = Utils.cloneObject(this.state.treeExplorer);
        node = tree.dataSource.find((elem) => elem.id === this.state.diagramId);
        treeList = 'treeExplorer';
      }
      let notSavedMark = ' *';

      if (isSaved) {
        notSavedMark = '';
        this.originalName = newName;
      }

      const name = JSON.parse(node.name);
      name[this.state.language].PROCESS_NAME = `${newName}${notSavedMark}`;
      node.name = JSON.stringify(name);

      this.setState({ [treeList]: tree, sandboxSearch: this.isSandbox ? tree : [] }, () => {
        if (!this.isSandbox) {
          this.updateBreadCrumb();
        }
      });
    }
  }

  updateLinkage(newLink) {
    this.toggleLinkageIcon(diagramInstance.selectedItems.nodes[0], newLink);
  }

  handleFormChange(form, isInvalid) {
    const symbolSelected = this.getNodeSelected();

    if (symbolSelected?.isCatalogObject) return;

    if (symbolSelected) {
      // this.historyChange({ type: Constants.NAME_CHANGED, data: this.getHistoryName(newSymbol) });

      if (symbolSelected.type?.toUpperCase() !== Constants.IMAGE.toUpperCase()) {
        if (diagramInstance.selectedItems.nodes[0].shape.type.toUpperCase() !== Constants.TEXT) {
          if (diagramInstance.selectedItems.nodes[0].annotations[0].content !== form.OBJECT_NAME) {
            this.updateSymbolSize(diagramInstance.selectedItems.nodes[0].id, form.OBJECT_NAME);
          }
        } else {
          diagramInstance.selectedItems.nodes[0].shape.content = form.TEXT_BLOCK;
        }
      }

      const formTypesCode = diagramInstance.selectedItems.nodes[0].id?.includes(Constants.OBJECT)
        ? this.props.getFormTypesCode({
            status: this.state.diagramLoaded.status,
            type: Constants.OBJECT,
            variant: this.getObjectTypeByIdFront(diagramInstance.selectedItems.nodes[0].id),
          })
        : this.props.getFormTypesCode({
            status: this.state.diagramLoaded.status,
            type: Constants.SYMBOL,
            variant: this.getSymbolTypeByIdFront(symbolSelected.idSymbolFront) || symbolSelected.type?.toUpperCase(),
          });
      symbolSelected.attributes = this.refreshForm(form, symbolSelected.attributes, formTypesCode).attributesUpdated;
      this.refreshBoxesNames(this.state.language, diagramInstance.selectedItems.nodes[0]);

      this.toggleErrorIcon(isInvalid);
      this.setState({ undoHistory: [], redoHistory: [] });
    } else {
      const diagramFormTypesCode = this.props.getFormTypesCode({
        status: this.state.diagramLoaded.status,
        type: Constants.DIAGRAM,
        variant: this.state.diagramType,
      });
      const { attributesUpdated, fieldConfigUpdated } = this.refreshForm(
        form,
        this.state.attributes,
        diagramFormTypesCode,
        this.state.fieldConfig,
      );
      let usersString = [];
      const modeler = attributesUpdated.NOT_TRANSLATABLE.MODELER;

      if (modeler && modeler !== '[]') {
        usersString = modeler.map((elem) => elem.Code);
      }

      this.modelersAssigned = `[${usersString.join(',')}]`;
      this.setState({
        attributes: attributesUpdated,
        fieldConfig: fieldConfigUpdated,
      });
    }
  }

  handleDiagramCollectionChange(args) {
    let symbolExists = [];

    if (args.laneIndex !== undefined) {
      // Swimlane modification event.
      return;
    }

    if (
      !args.element.sourceID &&
      this.state.symbolSelected &&
      this.state.symbolSelected !== args.element.targetID &&
      args.element.id.indexOf('connector') === 0
    ) {
      args.element.sourceID = this.state.symbolSelected;
    }

    if (
      (args.type === 'Removal' || args.type === 'Addition') &&
      args.state === 'Changed' &&
      this.state.paletteToolSelected === 'optimize'
    ) {
      this.diagramConnections = '';
      this.setState({ paletteToolSelected: '' });
    }

    if (
      args.type === 'Removal' &&
      args.state === 'Changed' &&
      args.element.id?.includes(Constants.PROCESS_STEP) &&
      args.element instanceof SyncfusionNode
    ) {
      this.reorderingProcessStep();
    }

    if (args.state === 'Changed' && args.element.id?.includes('swimlane')) {
      const swimlaneButtonLabel = args.type === 'Removal' ? '' : 'ON';

      this.setState({ swimlaneButtonLabel });
    }

    if (
      args.type === 'Removal' &&
      args.element.id?.includes('connector') &&
      args.state === 'Changed' &&
      !this.createConnectorErrorMessage
    ) {
      this.historyChange({ type: Constants.REMOVE_CONNECTOR, id: args.element.id });
    }

    if (args.type === 'Addition' && args.element.id.indexOf('connector') === 0 && args.element.targetDecorator.shape !== 'None') {
      if (args.state === 'Changing') {
        this.createNewConnector(args.element);
      } else if (args.state === 'Changed' && this.createConnectorErrorMessage !== '') {
        Dialog.showAlert({
          name: this.props.t('errors.connectionError'),
          message: this.createConnectorErrorMessage,
        });

        // Timeout to let de event finish.
        setTimeout(() => {
          diagramInstance.undo();
          this.createConnectorErrorMessage = '';
        }, 500);
      }
    }

    if (args.type === 'Removal' && args.state === 'Changing' && args.element instanceof SyncfusionNode) {
      args.cancel = true;

      if (this.state.groupToDelete) {
        args.cancel = false;
      } else {
        symbolExists = this.state.symbols.findIndex((elem) => elem.idSymbolFront === args.element.id);

        if (diagramInstance.selectedItems.nodes[0]?.id === args.element.id && args.element.id?.includes(Constants.OBJECT)) {
          this.setForm('');
          this.deleteObject(args.element);
          this.setForm('');
        }

        if (symbolExists !== -1) {
          if (this.state.symbols[symbolExists].id === 0) {
            this.historyChange({ type: Constants.REMOVE_SYMBOL, data: this.state.symbols[symbolExists] });
            args.cancel = false; // Symbol that is not saved in DB, is deleted
            this.resetSelectionControl();
            this.removeSymbol(symbolExists);
          } else {
            services
              .getSymbolToDelete(this.state.symbols[symbolExists].id)
              .then((res) => {
                if (res.data.allowed) {
                  this.setState({ symbolToDelete: args.element });
                  this.openConfirmDialog('trash', 'functionSelected');
                } else {
                  this.openPopUpOk('trash', 'functionSelected');
                }
              })
              .catch((err) => {
                services.handleServiceError(err);
              });
          }
        } else if (args.element.id?.includes('group')) {
          if (this.state.groupToDelete) {
            args.cancel = false;
          } else {
            args.cancel = true;
            this.deleteSymbol(args);
          }
        } else {
          args.cancel = false;
        }
      }
    }

    if (
      args.type === 'Addition' &&
      args.state === 'Changed' &&
      args.element instanceof SyncfusionNode &&
      args.element.id?.includes('node') &&
      args.element.shape.type === 'Image'
    ) {
      this.resetPaletteTool();
      this.handleDiagramDrop(args);
      this.canSelect = true;
      diagramInstance.select([args.element], true);

      if (diagramInstance?.nodes?.length > 1) {
        const previousHistory = { ...diagramInstance.historyManager }; // Not save sendToBack in history manager

        diagramInstance.sendToBack();
        diagramInstance.historyManager = previousHistory;
      }
      this.setForm(args.element.id);
    }

    if (args.type === 'Addition' && args.state === 'Changing' && this.symbolPaste && !args.element.id?.includes('connector')) {
      const symbolLink = args.element.annotations.findIndex((elem) => elem.id?.includes('linkProcessOverview'));

      if (symbolLink > -1) {
        args.element.annotations.splice(symbolLink, 1);
      }
      setTimeout(() => {
        diagramInstance.undo();
        this.handleDiagramDrop(args, true);
      }, 200);
    }

    if (args.type === 'Removal' && args.state === 'Changed' && args.element.id?.includes(Constants.OBJECT)) {
      this.setState({ undoHistory: [], redoHistory: [] });
    }

    if ((args.type === 'Addition' || args.type === 'Removal') && args.state === 'Changed') {
      if (args.type === 'Removal' && args.element instanceof SyncfusionNode) {
        this.setForm('');
      }

      this.setState({ showFormattingTools: false });
    }
  }

  deleteSymbol(args) {
    let symbolExists = [];

    const element = args.element.children.find((elem) => elem?.includes('PROCESS_STEP'));

    symbolExists = this.state.symbols.findIndex((elem) => elem.idSymbolFront === element);

    if (symbolExists !== -1) {
      if (this.state.symbols[symbolExists].id === 0) {
        args.cancel = false; // Symbol that is not saved in DB, is deleted
        this.removeSymbol(symbolExists);
      } else {
        services.getSymbolToDelete(this.state.symbols[symbolExists].id).then((res) => {
          if (res.data.allowed) {
            this.setState({ symbolToDelete: args.element });
            this.openConfirmDialog('trash', 'functionSelected');
          } else {
            this.openPopUpOk('trash', 'functionSelected');
          }
        });
      }
    }
  }

  getButtonsDialog(t) {
    return [
      {
        click: () => {
          this.hideDialogOk();
        },
        buttonModel: {
          content: t('ok'),
          cssClass: 'buttonOk',
          isPrimary: true,
        },
      },
    ];
  }

  isSymbolSelectable(node) {
    if (!node) {
      return false;
    }

    if (node.id?.includes(Constants.OBJECT)) {
      const objectType = this.getObjectTypeByIdFront(node.id);

      return objectType && Constants.SELECTABLE_OBJECTS?.includes(objectType);
    }

    const symbolCode = node.shape.type === Constants.IMAGE ? Constants.IMAGE : this.getSymbolTypeByIdFront(node.id);

    return Constants.SELECTABLE_SYMBOLS?.includes(symbolCode);
  }

  toggleErrorIcon(showIcon) {
    const symbolSelected = diagramInstance?.nodes.find((node) => node.id === this.getNodeSelected().idSymbolFront);

    if (!symbolSelected) {
      return;
    }

    if (symbolSelected.shape.type.toUpperCase() === Constants.TEXT) {
      document.getElementById(symbolSelected.id).style.stroke = showIcon ? Constants.RED_COLOR : '';
      document.getElementById(symbolSelected.id).style.strokeWidth = 2;
    } else {
      const annotation = symbolSelected.annotations.find((elem) => elem.id === 'error-annotation');

      if (annotation) {
        annotation.visibility = showIcon;
        diagramInstance.dataBind();
      }
      symbolSelected.properties.style.strokeColor = showIcon ? Constants.RED_COLOR : Constants.SHAPES_COLOR;
    }
  }

  toggleLinkageIcon(symbol, linkage = '', isRecommendation = false) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    const trimmedLink = linkage.trim();
    // Refresh the diagram before changing it
    instance.dataBind();

    // Reset linkAnnotation
    const linkAnnotation = symbol.annotations.filter((elem) => elem.id?.includes(Constants.LINKAGE_ANNOTATION_ID));
    instance.removeLabels(symbol, linkAnnotation);

    if (Constants.urlRegEx.test(trimmedLink)) {
      const annotation = [DiagramUtils.getLinkageAnnotation(trimmedLink, symbol.id)];

      instance.addLabels(symbol, annotation);
      instance.dataBind();
    }
  }

  toggleExplorer() {
    this.setState({ explorerOpen: this.state.explorerOpen === '' ? 'openExplorer' : '' });
  }

  toggleAttributes() {
    this.setState({ attributesOpen: this.state.attributesOpen === 'openAttributes' ? '' : 'openAttributes' }, () => {
      if (this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[1]) {
        diagramInstance.updateViewPort();
      } else {
        recommendationDiagramInstance.updateViewPort();
      }
    });
  }

  setObjectCatalogDialog(showDialog, typeId) {
    if (typeId === Constants.ROLE_OBJECT_TYPE) {
      this.setState({
        showDialogDetail: !showDialog,
        showDialogRequestRole: showDialog,
      });
    } else if (typeId === Constants.IT_SYSTEM_OBJECT_TYPE) {
      this.setState({
        showDialogDetail: !showDialog,
        showDialogRequestITSystem: showDialog,
      });
    }
    this.loadObjectsPending(typeId);
  }

  setAddRoleDialogStep(button) {
    if (button === 'back') {
      this.setState({ showDialogAddRole: false, showDialogDetail: true });
    } else {
      this.setState({ showDialogAddRole: false });
    }
  }

  handlePaletteToolChange(tool, image) {
    const paletteToolSelected = Utils.cloneObject(this.state.paletteToolSelected);
    const newTool = tool === paletteToolSelected ? '' : tool;

    if (paletteToolSelected === 'optimize' && tool !== paletteToolSelected) {
      this.diagramConnections = '';
    }

    this.resetPaletteTool(newTool);

    if (newTool === '') {
      return;
    }

    if (newTool === 'connector') {
      let constraints = ConnectorConstraints.Default & ~(ConnectorConstraints.Tooltip | ConnectorConstraints.Drag);
      constraints |= ConnectorConstraints.ReadOnly;
      constraints |= ConnectorConstraints.Select;
      constraints |= ConnectorConstraints.DragSegmentThumb;

      this.setDrawingObject({ type: 'Orthogonal', constraints });
    } else if (newTool === 'shapes') {
      this.setState({ showShapes: true });
    } else if (newTool === 'line') {
      const line = DiagramUtils.getLineToDraw();
      this.setDrawingObject(line);
    } else if (PaletteConfig.tools?.includes(newTool)) {
      const shape = DiagramUtils.getShapeToDraw(newTool);
      this.setDrawingObject(shape);
    } else if (newTool === 'picture') {
      const node = {
        shape: { type: 'Image', source: image },
      };
      this.setDrawingObject(node);
    }
  }

  resetPaletteTool(newTool = '') {
    this.setState({ showShapes: false, paletteToolSelected: newTool });

    diagramInstance.tool = DiagramTools.None;
    diagramInstance.dataBind();
  }

  setDrawingObject(obj) {
    // Enable drawing object.
    diagramInstance.tool = DiagramTools.ContinuousDraw;
    diagramInstance.drawingObject = obj;

    diagramInstance.dataBind();
  }

  showDialogHistory() {
    this.historyList(this.state.processNumber);
  }

  checkDiagramFavorite(processNumber) {
    userService
      .isFavorite(processNumber)
      .then((fav) => {
        this.setState({ inFavorite: fav.data });
      })
      .catch((error) => this.errorSavingDiagram(error));
  }

  toggleFavorites() {
    const newValue = !this.state.inFavorite;

    if (newValue) {
      // No then needed
      userService.addToFavorites(this.state.processNumber).catch((error) => this.errorSavingDiagram(error));
    } else {
      // No then needed
      userService.removeFavorite(this.state.processNumber).catch((error) => this.errorSavingDiagram(error));
    }

    this.setState({ inFavorite: newValue });
  }

  onConfirmTakeOverRecommendation() {
    recommendationServices
      .takeOverRecommendation(this.state.diagramLoaded.idRecommendation)
      .then(() => this.props.history.go(0))
      .catch((err) => {
        this.setState({ loading: false });
        recommendationServices.handleServiceError(err);
      });
  }

  onClickTakeOverRecommendation() {
    const confirmDialogData = {
      title: this.props.t('recommendations.takeOverRecommended.button'),
      message: this.props.t('recommendations.takeOverRecommended.message'),
      textCancel: this.props.t('close'),
      textOk: this.props.t('confirm'),
      okClick: () => {
        this.setState({ loading: true });
        this.onConfirmTakeOverRecommendation();
        this.confirmDialog.hide();
      },
    };
    this.confirmDialog = Dialog.showConfirm(confirmDialogData);
  }

  handleCopyDiagram() {
    if (!this.state.diagramLoaded) return;

    const { symbols, diagramType, objectsCatalogUsedDiagram, objectsUsedDiagram } = this.state;
    copyService.setDiagram({
      symbols: this.prepareSymbolsToSend(
        symbols.map((symbol) => ({ ...symbol, attributes: Utils.purifyAttributes(symbol.attributes) })),
      ),
      diagramType,
      connections: diagramInstance.saveDiagram(),
      objectsCatalogUsedDiagram,
      objectsUsedDiagram,
    });

    this.setState({ successCopy: true }, () => {
      setTimeout(() => this.setState({ successCopy: false }), 2000);
    });
  }

  handlePasteDiagram() {
    const diagramCopied = copyService.getDiagram();
    if (!diagramCopied) return;

    const { symbols, connections, objectsCatalogUsedDiagram, objectsUsedDiagram } = diagramCopied;
    objectsUsedDiagram.forEach((object) => {
      object.idDiagram = this.state.diagramId;
    });
    symbols.forEach((symbol) => {
      symbol.idDiagram = this.state.diagramId;
      symbol.objectsDiagram = symbol.objectsDiagram.map((id) => objectsUsedDiagram.find((obj) => obj.idFront === id));
    });

    const newDiagram = {
      ...this.state.diagramLoaded,
      symbols,
      connections,
      objectCatalogList: objectsCatalogUsedDiagram,
      objectsUsedDiagram,
    };

    this.setState({ catalogObjects: {} }, () => {
      this.prepareDiagram(newDiagram);
      this.getDiagramFieldConfig(true);
    });
  }

  getToolbarButtons() {
    if (this.state.isWorkflow) {
      return [
        {
          handleClick: () => this.showTransferTask(),
          btnText: this.props.t('tool.transferTask'),
          id: 'transfer',
          disabled: this.props.featureFlags.isFreezed || this.state.onlyReadWorkflow || this.isDiagramLoading(),
          component: Button,
        },
        {
          handleClick: () => this.showBackToGroup(),
          btnText: this.props.t('tool.backToGroup'),
          id: 'backToGroup',
          icon: 'icon-zurueck-an-die-gruppe',
          disabled: this.props.featureFlags.isFreezed || !this.state.backToGroup || this.isDiagramLoading(),
          component: Button,
        },
      ];
    }

    const buttons = [];

    if (!this.isClosedWF) {
      if (this.state.isPublished) {
        if (this.props.featureFlags.showRecommendationOverview) {
          buttons.push({
            handleClick: () =>
              this.props.history.push({
                pathname: `/process-recommendations/${this.state.diagramId}`,
                state: {
                  processNumber: this.state.processNumber,
                  version: this.state.attributes[Constants.NOT_TRANSLATABLE].VERSION,
                },
              }),
            buttonStyle: 'Secondary',
            btnText: this.props.t('checkRecommendation'),
            id: 'checkRecommendation',
            custom: !this.isRecommendation ? '' : 'custom',
            component: Button,
            disabled: !this.isRecommendation,
          });

          if (
            this.state.diagramType === Constants.EPC_DIAGRAM &&
            ((this.state.attributes[Constants.NOT_TRANSLATABLE]?.[Constants.AUTHOR]?.length > 0 &&
              this.state.attributes[Constants.NOT_TRANSLATABLE]?.[Constants.AUTHOR][0].Code === this.context.userName) ||
              this.context.userRoles?.includes(
                `MBCMAP.QI_GROUP_${this.state.attributes[Constants.NOT_TRANSLATABLE]?.[Constants.QA_INSPECTOR_GROUP]}-${
                  Constants.CENTRAL
                }`,
              ) ||
              this.context.canManagePlants())
          ) {
            buttons.push({
              handleClick: () => this.showRecommendToLocation(),
              buttonStyle: 'Secondary',
              btnText: this.props.t('recommendations.recommendToLocation'),
              id: 'recommendToLocation',
              // disabled: this.props.featureFlags.isFreezed,
              component: Button,
            });
          }
        }
        buttons.push(
          {
            buttonStyle: 'Secondary',
            btnText: this.state.inFavorite ? this.props.t('favorite') : this.props.t('toFavorites'),
            id: 'addFavorites',
            icon: this.state.inFavorite ? 'icon-stern-favorit' : 'icon-stern-favorit-konturlinie',
            component: Button,
            custom: this.state.loading ? '' : 'custom',
            disabled: this.state.loading,
          },
          {
            handleClick: () => this.showDialogHistory(),
            buttonStyle: 'Secondary',
            btnText: this.props.t('history.title'),
            id: 'history',
            custom: this.state.loading ? '' : 'custom',
            component: Button,
            disabled: this.state.loading,
          },
        );

        if (
          this.context.checkRole(Constants.ADMINISTRATOR) ||
          this.context.checkRole(Constants.QI) ||
          this.context.checkRole(Constants.MODELER)
        ) {
          buttons.push({
            handleClick: () => this.handleGlobalClick(`/diagram/${this.state.diagramId}`),
            buttonStyle: 'Primary',
            btnText: this.props.t('tool.editDiagram'),
            id: 'editDiagram',
            custom: 'custom',
            component: Button,
            disabled: this.state.loading,
          });
        }

        if (
          this.state.diagramType === Constants.VCD_DIAGRAM &&
          this.context.checkRole(Constants.SUPPORT) &&
          this.props.featureFlags?.isFixPDFButtonVisible
        ) {
          buttons.push({
            handleClick: () => this.fixPDF(),
            buttonStyle: 'Primary',
            btnText: this.props.t('fixPdf'),
            icon: 'icon-datei-pdf',
            id: 'fixPDF',
            component: Button,
            disabled: this.state.loading,
          });
        }

        return buttons;
      }

      if (
        this.state.diagramLoaded?.isRecommendationActive &&
        this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0]
      ) {
        buttons.push({
          btnText: this.props.t('recommendations.takeOverRecommended.button'),
          buttonStyle: 'Primary',
          component: Button,
          id: 'takeOverRecommended',
          handleClick: () => this.onClickTakeOverRecommendation(),
        });

        return buttons;
      }

      if (!this.state.onlyReadWorkflow) {
        if (this.state.isModelingEditable) {
          const diagramCopiedType = copyService.getDiagramType();
          const isEmptyDiagram = this.state.symbols.length === 0 && diagramInstance.nodes?.length === 0;
          let pasteTooltip = '';

          if (!diagramCopiedType) {
            pasteTooltip = this.props.t('info.copy.empty');
          } else if (diagramCopiedType !== this.state.diagramType) {
            pasteTooltip = this.props.t('info.copy.noSameType');
          }

          buttons.push({
            handleClick: () => this.handleCopyDiagram(),
            btnText: `${this.props.t('tool.copy')} ${this.props.t(this.state.diagramType)}`,
            disabled: isEmptyDiagram,
            id: 'copy',
            success: this.state.successCopy,
            component: Button,
          });
          buttons.push({
            handleClick: () => this.handlePasteDiagram(),
            disabled: !diagramCopiedType || !isEmptyDiagram,
            tooltip: isEmptyDiagram && pasteTooltip,
            btnText: `${this.props.t('tool.paste')} ${this.props.t(this.state.diagramType)}`,
            id: 'paste',
            component: Button,
          });
        }
        buttons.push({
          isLoading: this.state.savingDiagram,
          success: this.state.successSavingDiagram,
          disabled: this.props.featureFlags.isFreezed || this.isDiagramLoading(),
          handleClick: () =>
            this.saveDiagram()
              .then((response) => {
                this.updateDiagramData(response);
              })
              .catch((error) => this.errorSavingDiagram(error)),
          btnText: this.props.t('save'),
          id: 'save',
          component: Button,
        });
      }
      if (!this.isSandbox && !this.state.onlyReadWorkflow) {
        buttons.push({
          isLoading: this.isDiagramLoading() || this.state.savingDiagram,
          class: 'ButtonPrimary',
          text: this.props.t('send.sendForRelease'),
          icon: 'fas fa-chevron-down',
          id: 'release',
          component: SendForRelease,
          idDiagramType: this.state.diagramType === Constants.EPC_DIAGRAM ? Constants.EPC_DIAGRAM_ID : Constants.VCD_DIAGRAM_ID,
          idProcess: this.state.processNumber,
          idDiagram: this.state.diagramId,
          isRecommendationActive: this.state.diagramLoaded?.isRecommendationActive,
          onOptionClick: (data) => this.setState({ releaseLoad: true }, this.sendForRelease(data)),
          version: this.state.attributes[Constants.NOT_TRANSLATABLE]
            ? this.state.attributes[Constants.NOT_TRANSLATABLE].VERSION
            : '',
        });

        if (!this.state.onlyRead) {
          buttons.push({
            disabled: this.props.featureFlags.isFreezed || this.isDiagramLoading(),
            handleClick: () => this.toggleAutoSave(),
            btnText: 'autoSave',
            selected: this.state.isAutoSaveOn,
            iconClass: 'icon-diskette-speichern',
            id: 'auto-save-diagram',
            component: ButtonToggle,
          });
        }
      }
    }

    return buttons;
  }

  onClickRecommendedVersionTab(recommendationVersion) {
    const isRecommendationDiagramVisible = recommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
    const diagramLanguages = DiagramUtils.getDiagramLanguages(
      isRecommendationDiagramVisible ? this.state.recommendationAttributes : this.state.attributes,
      this.props.availableLanguages,
    );

    if (!diagramLanguages?.includes(this.state.language)) {
      this.setLanguage(diagramLanguages[0]);
    }

    this.setState(
      {
        activeRecommendationVersion: recommendationVersion,
        dragOn: '',
        symbolSelected: '',
      },
      () => {
        const instance =
          recommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0] ? recommendationDiagramInstance : diagramInstance;
        instance.tool = DiagramTools.None;
        instance.updateViewPort();
        recommendationDiagramInstance.clearSelection();
        diagramInstance.clearSelection();

        if (recommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0]) {
          this.resetZoom(this.state.diagramType, true);
        }
      },
    );
  }

  getToolbarLeftButtons() {
    if (this.state.diagramLoaded?.isRecommendationActive) {
      return (
        <div className={toolbarStyles.LeftSwitch}>
          {Constants.RECOMMENDATION_VERSION_TABS.map((version) => (
            <TabFilter
              click={(recommendationVersion) => this.onClickRecommendedVersionTab(recommendationVersion)}
              idFilter={version}
              isSelected={version === this.state.activeRecommendationVersion}
              key={version}
              text={this.props.t(`recommendations.switch.${version}`)}
            />
          ))}
        </div>
      );
    }
  }

  getToolIcons() {
    if (
      this.state.isWorkflow ||
      (this.state.diagramLoaded?.isRecommendationActive &&
        this.state.activeRecommendationVersion === Constants.RECOMMENDATION_RECOMMENDED_VERSION)
    ) {
      return [];
    }

    const { t } = this.props;

    const toolIcons = [
      {
        id: 'undo',
        tooltip: t('tool.undo'),
        iconClass: 'di icon-rueckgaengig-undo',
        disabled: !(this.state.undoHistory?.length > 0),
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
        click: () => this.undoRedoAction(true),
      },
      {
        id: 'redo',
        tooltip: t('tool.redo'),
        iconClass: 'di icon-pfeil-wiederherstellen-redo',
        disabled: !(this.state.redoHistory?.length > 0),
        // disabled: true,
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
        click: () => this.undoRedoAction(false),
      },
      {
        id: 'copy',
        tooltip: t('tool.copy'),
        iconClass: 'di icon-datei-doppelt-kopieren',
        disabled: Utils.isSwimlaneSelected(diagramInstance) || this.state.disableCopy,
        click: !this.state.disableCopy ? () => this.copy() : () => {},
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
      {
        id: 'clipboard',
        tooltip: t('tool.paste'),
        iconClass: 'di icon-klemmbrett-aufgabe-liste',
        disabled: this.state.disablePaste,
        click: !this.state.disablePaste ? () => this.paste() : () => {},
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
    ];
    const diagramPublished = this.isDiagramPublished();

    const isEPC = this.state.diagramType === Constants.EPC_DIAGRAM;
    const isVCD = this.state.diagramType === Constants.VCD_DIAGRAM;

    if (
      (((this.context?.checkPermission('epcDelete') && isEPC) || (this.context?.checkPermission('vcdDelete') && isVCD)) &&
        !diagramPublished) ||
      (this.isSandbox && this.context?.checkRole('USER'))
    ) {
      toolIcons.push({
        id: 'trash',
        tooltip: t('tool.delete'),
        iconClass: 'di icon-schliessen-2',
        disabled: this.props.featureFlags.isFreezed || this.state.releaseLoad || false,
        click: () => {
          if (this.canSelect) {
            this.resetSelectionControl();
            this.setState({ showDeleteDiagramDialog: true });
          }
        },
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      });
    }

    return toolIcons;
  }

  getZoomIcons() {
    if (this.state.isWorkflow) {
      return [];
    }

    const { t } = this.props;

    return [
      {
        id: 'zoomOut',
        tooltip: t('tool.zoomOut'),
        iconClass: 'di icon-lupe-herauszoomen',
        disabled: false,
        click: () => this.zoomDiagram('ZoomOut'),
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
      {
        id: 'zoomIn',
        tooltip: t('tool.zoomIn'),
        iconClass: 'di icon-lupe-hineinzoomen',
        disabled: false,
        click: () => this.zoomDiagram('ZoomIn'),
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
      {
        id: 'zoomToFit',
        tooltip: t('tool.zoomToFit'),
        iconClass: 'di icon-zoom-anpassen-fokus',
        disabled: false,
        click: () => this.zoomToFit(this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0]),
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
      {
        id: 'drag',
        tooltip: t('tool.dragCanvas'),
        iconClass: `${this.state.dragOn} di icon-hand-bewegen`,
        disabled: false,
        click: () => this.drag(),
        extraClass: this.isSandbox ? 'sandbox-tooltip' : null,
      },
    ];
  }

  getSwimlaneIcon() {
    return this.isSandbox
      ? {
          id: 'swimlaneButton',
          tooltip: this.props.t('tool.swimlane'),
          label: this.props.t(ProcessType.SWIMLANE),
          iconClass: 'di icon-zeilen',
          disabled: false,
          click: () => this.handleSwimlaneButtonClick(),
          extraClass: 'sandbox-tooltip',
          buttonLabel: Utils.getSwimlaneNode(diagramInstance) ? this.state.swimlaneButtonLabel : '',
        }
      : {};
  }

  handleSwimlaneButtonClick() {
    const swimlane = Utils.getSwimlaneNode(diagramInstance);

    if (swimlane) {
      swimlane.visible = !swimlane.visible;

      if (!swimlane.visible) {
        diagramInstance.clearSelection();
      }

      this.setState({ swimlaneButtonLabel: swimlane.visible ? 'ON' : 'OFF' });
    } else {
      this.setState({ showSwimlaneDialog: true });
    }
  }

  createSwimlane(rowsNumber, orientation) {
    const lanes = [];

    for (let i = 0; i < rowsNumber; i++) {
      lanes.push(Utils.createLane({ rowNumber: i, orientation }));
    }

    // Create swimlane
    const { horizontalOffset, verticalOffset } = diagramInstance.scrollSettings;

    diagramInstance.add(Utils.createSwimlane(orientation, lanes, horizontalOffset, verticalOffset));
    Utils.sendSwimlaneToBack(diagramInstance, this.toggleCanSelect);
    const laneNodes = diagramInstance?.nodes.filter(({ isLane }) => isLane);
    laneNodes.forEach((node) => (node.constraints = NodeConstraints.Default));
  }

  clickOnNode(args) {
    const route = this.getDiagramRoute(args);
    this.props.history.push(route);
  }

  getDiagramRoute(id) {
    return id === 1 && window.location.href?.includes(E2E)
      ? `/${this.getLocation()}/${E2E}/${id}`
      : `/${this.getLocation()}/${id}`;
  }

  getLocation() {
    const location = window.location.pathname;
    let route = location?.includes('published') || location?.includes('closed-workflow') ? 'published' : 'diagram';
    route = this.isSandbox ? 'sandbox' : route;

    return route;
  }

  isDiagramPublished() {
    let attributes = this.state.diagramLoaded?.attributes;
    attributes = attributes && typeof attributes === 'string' ? JSON.parse(attributes) : {};
    const version = attributes && attributes[Constants.NOT_TRANSLATABLE] ? attributes[Constants.NOT_TRANSLATABLE].VERSION : '';

    return version !== '0' && version !== '0.0';
  }

  isDiagramOnlyRead() {
    return this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0] || this.state.onlyRead;
  }

  getDiagramImages() {
    const lng = this.state.language;
    const images = {};
    const currentLang = this.props.availableLanguages.find((lang) => lang === lng);
    this.diagramToDataURL();
    // Save diagram in selected language
    images[lng] = this.splitDataDiagram();
    // Save diagram in other languages
    this.props.availableLanguages.forEach((lang) => {
      if (lang !== currentLang) {
        this.changeImgDiagram(lang);
        images[lang] = this.splitDataDiagram();
      }
    });
    // Recover selected language in the diagram
    this.changeImgDiagram(currentLang);

    return images;
  }

  sendForRelease(data) {
    if (this.isDiagramValid() || Constants.HISTORICAL_WF_TYPES?.includes(data.workflowTypeCode)) {
      this.hideSymbolErrors(true);
      diagramInstance.clearSelection();
      this.saveDiagram().then((response) => {
        this.updateDiagramData(response);
        const typeDiagram = data.workflowTypeCode.split('_').pop();

        data.images = this.getDiagramImages();

        this.sendForReleasefetch(data, typeDiagram);
        this.diagramToURLData();
      });
    } else {
      diagramInstance.clearSelection();
      this.setForm('');
      Dialog.showAlert({
        name: this.props.t('warning'),
        message: this.props.t('diagram.invalidFieldsMessage', {
          fields: this.invalidFields.join(', '),
          fieldsSymbols: this.invalidFieldSymbol.join(', '),
          info: this.invalidInfo,
          fieldsObjects: this.invalidFieldObject,
        }),
        isError: false,
        closeClick: () => this.setState({ loading: false, releaseLoad: false }),
      });
    }
  }

  fixPDF() {
    const data = {
      idDiagram: this.state.diagramId,
      processNumber: this.state.processNumber,
      images: {},
    };

    data.images = this.getDiagramImages();

    this.setState({ loading: true }, () => {
      documentationServices
        .saveFinalImages(data.processNumber, data.images)
        .then(() => {
          Dialog.showAlert({
            name: this.props.t('success'),
            message: this.props.t('pdfFixed'),
          });
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false }, () => {
            services.handleServiceError(err);
          });
        });
    });
    this.diagramToURLData();
  }

  changeImgDiagram(lng) {
    this.refreshBoxesNames(lng);
    this.changeLanguageAbbreviation(lng);
    diagramInstance.dataBind();
  }

  exportImgDiagram(xBounds, yBounds, heightBounds, widthBounds) {
    const exportOptions = {
      mode: 'Data',
      format: 'JPG',
      region: 'CustomBounds',
      bounds: { x: xBounds, y: yBounds, height: heightBounds, width: widthBounds },
    };
    const dataImg = diagramInstance.exportDiagram(exportOptions);

    return dataImg.split(',')[1];
  }

  sendForReleasefetch(data, typeDiagram) {
    this.setState({ loading: true }, () => {
      sendForRelease(data)
        .then((res) => res.data)
        .then((response) => {
          if (response.code) {
            const errorMessageVars =
              response.code === Constants.ERROR_WORKFLOW_015 ? { diagramType: this.props.t(this.state.diagramType) } : null;

            if (Constants.SET_HISTORICAL_ERRORS?.includes(response.code)) {
              throw { response: { data: response } }; // eslint-disable-line @typescript-eslint/no-throw-literal
            }

            if (response.code === 'FORM_VALIDATION_400') {
              this.setState({ loading: false, showDialogFormError: true, formErrorResponseBody: response, releaseLoad: false });
            } else {
              throw Error(this.props.t(`errors.${response.code}`, errorMessageVars));
            }
          } else {
            this.reloadDiagram(
              this.state.diagramId,
              this.props.t('send.toWorkflowX', { labelX: this.props.t(`${typeDiagram}`) }),
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: false }, () => {
            services.handleServiceError(err);
          });
        });
    });
  }

  isDiagramValid() {
    let isValid = true;
    this.invalidFields = [];
    this.invalidFieldSymbol = [];
    this.invalidInfo = '';
    this.invalidFieldObject = '';

    const attributesList = { ...this.state.attributes };

    // Check the diagram
    const attributesTypes = Object.keys(attributesList);

    for (let i = 0; i < attributesTypes?.length; i++) {
      const diagramAtt = Object.keys(attributesList[attributesTypes[i]]);

      for (let k = 0; k < diagramAtt?.length; k++) {
        const value = Utils.purify(attributesList[attributesTypes[i]][diagramAtt[k]]);
        const formLanguage = attributesTypes[i] === Constants.NOT_TRANSLATABLE ? this.state.language : attributesTypes[i];
        const languageFieldConfig = this.state.diagramFieldConfig[formLanguage];
        const care = languageFieldConfig.controls[diagramAtt[k]]?.meta?.attributeType;

        if (care === Constants.ATTRIBUTE_CARES.MANDATORY && (!value || value?.length === 0)) {
          isValid = false;

          if (!this.invalidFields?.includes(this.props.t(`sendForRelease.validationErrors.diagramAttributes.${formLanguage}`))) {
            this.invalidFields.push(this.props.t(`sendForRelease.validationErrors.diagramAttributes.${formLanguage}`));
          }
        }
      }
    }

    // Check all symbols
    this.state.symbols.forEach((symbol) => {
      isValid = this.validateSymbol(symbol) && isValid && this.validObjects();
    });
    this.setState({ mustShowErrors: !isValid });

    return isValid;
  }

  validateSymbol(symbol) {
    const hasValidRoles = this.hasValidRoles(symbol);
    const isValid = this.validSymbols(symbol) && hasValidRoles;
    this.toggleSymbolValidation(symbol, isValid);

    // In order to inform about errors, we will always show the symbol name on the selected language
    if (!isValid) {
      this.invalidFields.push(symbol.attributes[this.state.language || EN].OBJECT_NAME);
      // We will also show fields errors if there isn't a role error, which is the case where we dont want to show errors.
      if (hasValidRoles) this.invalidFieldSymbol.push(` (${this.invalidFieldAttributesSymbol(symbol)})`);
    }

    return isValid;
  }

  validSymbols(symbol) {
    const { attributes } = symbol;
    if (!attributes) return true;

    const lang = Object.keys(attributes);

    for (let i = 0; i < lang?.length; i++) {
      const att = Object.keys(attributes[lang[i]]);

      for (let k = 0; k < att?.length; k++) {
        const value = Utils.purify(attributes[lang[i]][att[k]]);
        const languageFieldConfig = symbol?.fieldConfig[lang[i] === Constants.NOT_TRANSLATABLE ? this.state.language : lang[i]];
        const care = languageFieldConfig?.controls[att[k]]?.meta?.attributeType;

        if (care === 'M' && !value) {
          return false;
        }
      }
    }

    return true;
  }

  validObjects() {
    for (let index = 0; index < this.state.objectsUsedDiagram?.length; index++) {
      const object = this.state.objectsUsedDiagram[index];
      const attributes = Utils.getObjectAttributes(object.attributes);
      const lang = Object.keys(attributes);

      for (let i = 0; i < lang?.length; i++) {
        const att = Object.keys(attributes[lang[i]]);

        for (let k = 0; k < att?.length; k++) {
          const value = Utils.purify(attributes[lang[i]][att[k]]);

          const objectFieldConfig = this.getObjectFieldConfig(
            {
              ...object,
              type: Constants.OBJECT,
              variant: this.getObjectTypeByIdFront(object.idFront),
            },
            attributes,
          );
          const languageFieldConfig = objectFieldConfig[lang[i] === Constants.NOT_TRANSLATABLE ? this.state.language : lang[i]];
          const care = languageFieldConfig.controls[att[k]]?.meta?.attributeType;

          if (care === 'M' && !value) {
            this.invalidFieldObject = this.props.t('errors.someObjects');

            return false;
          }
        }
      }
    }

    return true;
  }

  invalidFieldAttributesSymbol(symbol) {
    const { attributes } = symbol;
    const lang = Object.keys(attributes);
    const formTypesCode = this.props.getFormTypesCode({
      status: this.state.diagramLoaded.status,
      type: Constants.SYMBOL,
      variant: symbol.type,
    });

    for (let i = 0; i < lang?.length; i++) {
      const att = Object.keys(attributes[lang[i]]);

      for (let k = 0; k < att?.length; k++) {
        if (
          this.props.formTypes[formTypesCode][lang[i]][att[k]]?.care === Constants.ATTRIBUTE_CARES.MANDATORY &&
          !attributes[lang[i]][att[k]]
        ) {
          return this.props.t(`nameAttributes.${att[k]}`, { lng: this.state.language });
        }
      }
    }

    return '';
  }

  hasValidForm(symbol) {
    const { attributes } = symbol;

    let isValid = true;
    const languages = Object.keys(attributes);

    for (let i = 0; i < languages?.length; i++) {
      if (symbol.fieldConfig[languages[i]]) {
        const keys = Object.keys(symbol.fieldConfig[languages[i]].controls);
        const fields = symbol.fieldConfig[languages[i]].controls;

        for (let j = 0; j < keys?.length; j++) {
          if (fields[keys[j]].meta.attributeType === 'M' && (!attributes[keys[j]] || attributes[keys[j]].trim() === '')) {
            isValid = false;
          }
        }
      }
    }

    return isValid;
  }

  hasValidRoles(symbol) {
    let isValid = true;

    if (symbol.idSymbolFront.indexOf(Constants.PROCESS_STEP) === 0) {
      const roleR = this.getRoleR(symbol.objectsCatalogRelations);
      isValid = roleR?.length === 1;

      if (roleR?.length === 0) {
        this.invalidInfo = this.props.t('errors.atLeastOneResponsible');
      } else if (!isValid) {
        this.invalidInfo = this.props.t('errors.onlyOneResponsible');
      }
    }

    return isValid;
  }

  hideSymbolErrors(hideErrors = true, isRecommendation) {
    const symbols = isRecommendation ? this.state.recommendationSymbols : this.state.symbols;
    symbols?.forEach((symbol) => {
      this.toggleSymbolValidation(symbol, hideErrors, isRecommendation);
    });
  }

  toggleSymbolValidation(symbol, isValid, isRecommendation) {
    const instance = isRecommendation ? recommendationDiagramInstance : diagramInstance;
    const diagramSymbol = instance.nodes.find((node) => node.id === symbol.idSymbolFront);
    const annotation = diagramSymbol.annotations.find((elem) => elem.id === 'error-annotation');

    if (annotation) {
      annotation.visibility = !isValid;
    }

    if (diagramSymbol.shape.type.toUpperCase() === Constants.TEXT) {
      const symbolId = document.getElementById(diagramSymbol.id);
      if (symbolId) {
        symbolId.style.strokeWidth = 2;
        symbolId.style.stroke = !isValid ? Constants.RED_COLOR : '';
      }
    } else {
      diagramSymbol.properties.style.strokeColor = isValid ? Constants.SHAPES_COLOR : Constants.RED_COLOR;
    }
  }

  isDiagramLoading() {
    return this.state.loading || this.state.loadingWorkflowBar || this.state.loadingSandboxTree || this.state.releaseLoad;
  }

  setLanguage(lng) {
    if (this.state.attributes[lng]) {
      const imageSymbols = this.state.symbols.filter((symbol) => symbol.type === Constants.IMAGE);

      imageSymbols.forEach((symbol) => {
        const imageNode = diagramInstance?.nodes.find((node) => node.id === symbol.idSymbolFront);

        this.toggleLinkageIcon(imageNode, symbol.attributes[lng].LINKAGE);
      });

      this.setState({ language: lng, symbolsPalette: [] }, () => {
        this.setForm(this.state.selectionObject, lng, true);
        this.updateBreadCrumb();
        this.prepareSymbolPalette();
      });
    }
  }

  refreshBoxesNames(language, selectedNode) {
    const nodeList = selectedNode ? [selectedNode] : diagramInstance?.nodes;
    const previousHistory = { ...diagramInstance.historyManager };

    nodeList.forEach((node) => {
      const symbol = this.state.symbols.find((s) => s.idSymbolFront === node.id && node.shape.type !== Constants.IMAGE);
      if (symbol) {
        const attributes = symbol.attributes?.[language];
        if (attributes && !node.id.startsWith(Constants.TEXT)) {
          node.annotations[0].content = attributes.OBJECT_NAME;
        } else if (node.id.startsWith(Constants.TEXT)) {
          node.shape.content = attributes.TEXT_BLOCK;
        }

        if (attributes) this.toggleLinkageIcon(node, attributes.LINKAGE);
      } else {
        this.refreshObjectBox(node, language);
      }
    });

    diagramInstance.historyManager = previousHistory;
  }

  refreshObjectBox(node, language) {
    let object = this.state.objectsUsedDiagram.find((o) => o.idFront === node.addInfo); // No Catalog Object

    if (!object) {
      // Catalog Object
      object = this.state.objectsCatalogUsedDiagram.find((o) => o.id === this.getIdCatalogObject(node.id));
    }

    if (object) {
      const attributes = Utils.getObjectAttributes(object.attributes);
      node.annotations[0].content = attributes[language].OBJECT_NAME;
      this.toggleLinkageIcon(node, attributes[language].LINKAGE);
    }

    if (node.annotations.length && !this.state.savingDiagram) this.updateSymbolSize(node.id, node.annotations[0].content);
  }

  orderMenu(args) {
    if (args.item.id === Constants.BRING_TO_FRONT) {
      diagramInstance.bringToFront();
    } else {
      const minZIndex = Math.min(
        ...diagramInstance?.nodes.filter((node) => !node.id?.includes('swimlane')).map((node) => node.zIndex),
      );

      if (diagramInstance.selectedItems.nodes[0].zIndex > minZIndex) {
        diagramInstance.sendToBack();
        Utils.sendSwimlaneToBack(diagramInstance, this.toggleCanSelect);
      }
    }
  }

  contextMenuOpen(args) {
    if (
      args.event.target.id?.includes('diagram') ||
      diagramInstance?.nodes?.length === 1 ||
      Utils.isSwimlaneSelected(diagramInstance)
    ) {
      args.cancel = true;
    }
  }

  splitDataDiagram() {
    // Reset zoom to 1
    this.resetZoom(Constants.EPC_DIAGRAM);
    // Get diagram width and height
    const heightDiagram = diagramInstance.getDiagramBounds().height;
    const widthDiagram = diagramInstance.diagramLayer.getBoundingClientRect().width;
    const images = [];
    const { height, width } = Constants.PRINT_SETTINGS[this.state.diagramType];
    let pageHeight = height;
    let pageWidth = width;

    if (widthDiagram > width) {
      // Regla de 3 con el que cálculo el alto de la página del bounds
      pageHeight = (widthDiagram * height) / width;
      pageWidth = widthDiagram;
    }

    for (let i = 0; i * pageHeight <= heightDiagram; i++) {
      const x = Utils.cloneObject(diagramInstance.getDiagramBounds().x);
      const y = diagramInstance.getDiagramBounds().y + i * pageHeight;
      images.push(this.exportImgDiagram(x, y, pageHeight, pageWidth));
    }

    return images;
  }

  exportDiagram(extension) {
    const isPublished = this.props.location.pathname?.includes('published');

    if (isPublished) {
      return documentationServices
        .getDocument(this.state.processNumber, 'last', this.state.language, Constants.DIAGRAM, extension)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const fileName = `${this.state.attributes[this.state.language].PROCESS_NAME}.${extension}`;
          Utils.downloadFile(url, fileName, res.headers['content-disposition']);
        })
        .catch((err) => {
          services.handleServiceError(err);
        });
    }

    if (!this.state.onlyRead) {
      diagramInstance.clearSelection();
      this.saveDiagram()
        .then((response) => {
          this.diagramToDataURL();
          this.genDocs(
            this.splitDataDiagram(),
            `${this.state.attributes[this.state.language].PROCESS_NAME}.${extension}`,
            this.state.language,
            extension,
          );
          this.diagramToURLData();
          this.updateDiagramData(response);
        })
        .catch((error) => this.errorSavingDiagram(error));
    } else {
      this.diagramToDataURL();
      this.genDocs(
        this.splitDataDiagram(),
        `${this.state.attributes[this.state.language].PROCESS_NAME}.${extension}`,
        this.state.language,
        extension,
      );
    }
  }

  exportRecommendationDiagram() {
    const { epcName, processNumber, version } = this.state.recommendedMessage;
    documentationServices
      .getDocument(processNumber, version, Constants.ENGLISH, Constants.EXCHANGE)
      .then((res) => {
        const translatedEPCName = typeof epcName === 'string' ? JSON.parse(epcName)[this.state.language].TITLE : '';
        const fileName = `${translatedEPCName}.pdf`;
        const pdfUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const contentDisposition = res.headers['content-disposition'];
        Utils.downloadFile(pdfUrl, fileName, contentDisposition);
      })
      .catch((err) => {
        documentationServices.handleServiceError(err);
      });
  }

  diagramToURLData() {
    const images = document.getElementsByTagName('image');

    for (let i = 0; i < images?.length; i++) {
      const id = images[i].getAttribute('id');
      images[i].setAttributeNS('http://www.w3.org/2000/svg', 'xlink:href', this.imageUrls[id]);
    }
    this.imageUrls = {};
  }

  setAttributexlink(dataUrl, image) {
    image.setAttribute('xlink:href', dataUrl);
  }

  diagramToDataURL() {
    const images = document.getElementsByTagName('image');

    for (let i = 0; i < images?.length; i++) {
      const id = images[i].getAttribute('id');
      this.imageUrls[id] = images[i].getAttribute('xlink:href');
      this.toDataURL((dataUrl, image) => this.setAttributexlink(dataUrl, image), images[i]);
    }
  }

  // TODO: remove this lint rule disabling when below block comment is removed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toDataURL(callback, image, outputFormat) {
    const src = image.getAttribute('xlink:href');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function onLoadImage() {
      const canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      // const dataURL = canvas.toDataURL(outputFormat);
      // TODO: Comment the call the method so that the behavior of the method does not give the error:
      // Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tained canvases may not be exported
      // callback(dataURL, image);
    };
    img.src = src;

    if (img.complete || img.complete === undefined) {
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  }

  genDocs(imgDoc, fileName, language, extension) {
    const data = {
      idDiagram: this.state.diagramId,
      images: imgDoc,
    };

    return services
      .genDocs(extension, data, language)
      .then((res) => {
        const binaryData = [res.data];
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
        Utils.downloadFile(url, fileName, res.headers['content-disposition']);
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  attributesExpandedFullSize() {
    this.setState({
      openFullAttributes: this.state.openFullAttributes === 'openFullAttributes' ? '' : 'openFullAttributes',
    });
  }

  updateDiagramData(res) {
    const newSymbols = this.loadSymbols(res.data.symbols);
    const attributes = Utils.getObjectAttributes(res.data.attributes);
    const savedDate = Utils.getFullFormattedDate(attributes[Constants.NOT_TRANSLATABLE].LAST_MODIFICATION);
    this.setState(
      {
        symbols: newSymbols,
        diagramLoaded: {
          ...this.state.diagramLoaded,
          attributes: res.data.attributes,
          catalogObjectsMerge: res.data.catalogObjectsMerge,
          status: res.data.status,
        },
        objectsUsedDiagram: DiagramUtils.prepareObjectsUsedDiagram(res.data.objectsUsedDiagram),
        savedDate,
        undoHistory: [],
        redoHistory: [],
        loading: true,
      },
      () => {
        this.prepareUsedOjects(res.data.objectsUsedDiagram);
        this.controlSavingSuccess(true);
        this.updateDiagramName(attributes[this.state.language].PROCESS_NAME, true);
        const newDiagramAttributes = {
          ...this.state.attributes,
          [Constants.NOT_TRANSLATABLE]: {
            ...this.state.attributes[Constants.NOT_TRANSLATABLE],
            LAST_MODIFICATION: savedDate,
          },
        };

        this.setState({ attributes: newDiagramAttributes, loading: false });
      },
    );
  }

  loadImportSandboxDiagrams() {
    this.setState({ loadingSandboxTree: true, sandboxTree: [] }, () => {
      let sandboxTree;

      getImportSandboxDiagrams()
        .then((res) => {
          sandboxTree = res.data.sandbox;

          if (!serviceUtils.isCentral() && res.data.recommendation) {
            sandboxTree = [
              ...sandboxTree,
              ...res.data.recommendation.map((recommendation) => ({ ...recommendation, isRecommendation: true })),
            ];
          }

          this.setState({
            sandboxTree,
            loadingSandboxTree: false,
            loadingTree: false,
            sandboxSearch: sandboxTree,
          });
        })
        .catch((err) => {
          services.handleServiceError(err);
        });
    });
  }

  async loadSandboxTree() {
    let sandboxTree = await SandboxUtils.loadSandboxs(this.props.t, this.state.diagramId);
    if (!serviceUtils.isCentral()) {
      const sandboxRecommendedTree = await SandboxUtils.loadRecommendedSandboxs(this.props.t);
      sandboxTree = [
        ...sandboxTree,
        ...sandboxRecommendedTree.map((recommendation) => ({ ...recommendation, isRecommendation: true })),
      ];
    }
    this.setState({ sandboxTree, loadingSandboxTree: false, loadingTree: false, sandboxSearch: sandboxTree });
  }

  createSandbox(type = ProcessType.EPC) {
    this.setState({ loadingTree: true }, async () => {
      const { sandboxTree, draftName, diagramId } = await SandboxUtils.createSandbox(
        this.state.sandboxTree,
        this.state.language,
        this.props.availableLanguages,
        type,
      );

      Dialog.showAlert({
        name: this.props.t('success'),
        message: this.props.t('sandboxDraftCreated', { draftName }),
        closeClick: () => this.handleGlobalClick(`/sandbox/${diagramId}`),
      });

      this.setState({ sandboxTree, sandboxSearch: sandboxTree, loadingTree: false });
    });
  }

  loadDiagramPublished(processNumber, version, isHistoricalWF = false) {
    if (!this.isClosedWF) {
      this.checkDiagramFavorite(processNumber);
    }

    return services
      .getDiagramPublished(processNumber, version)
      .then((res) => {
        const diagramLoaded = res.data;

        if (isHistoricalWF) {
          diagramLoaded.status = Constants.STATUS.WORKFLOW;
          diagramLoaded.onlyRead = true;
          this.prepareDiagram(diagramLoaded);
        } else {
          const diagramLanguages = DiagramUtils.getDiagramLanguages(
            JSON.parse(diagramLoaded.attributes),
            this.props.availableLanguages,
          );
          this.setState(
            {
              isPublished: true,
              language: diagramLanguages?.includes(this.state.language) ? this.state.language : diagramLanguages[0],
              users: this.mergeUsers(diagramLoaded.allUsers),
              diagramLoaded,
            },
            () => {
              this.prepareDiagramPublished(diagramLoaded);
            },
          );
        }
      })
      .catch((err) => {
        services.handleServiceError(err);
      });
  }

  toggleLoadingImage(isLoading) {
    this.setState({ loading: isLoading });
  }

  handleResizeSymbol(event) {
    if (event.state === 'Completed') {
      const { id } = event.source.nodes[0];
      const node = diagramInstance?.nodes.find((n) => n.id === id);
      const isSymbol = Constants.RESIZABLE_SYMBOLS.find((symbol) => node.id?.includes(symbol));

      if (isSymbol) {
        this.resizeSymbol(node, event.newValue.width, event.newValue.height);
      }
    }
  }

  resizeSymbol(node, w = node.width, h = node.height) {
    const minWidth = node.id?.includes(Constants.GATE) ? Constants.MIN_HEIGHT : Constants.NODE_MIN_WIDTH;
    const minHeight = node.id?.includes(Constants.GATE) ? Constants.MIN_HEIGHT : Constants.NODE_MIN_HEIGHT;
    const width = Math.max(minWidth, w);
    const height = Math.max(minHeight, h);

    if (node.id?.includes(Constants.PROCESS_INTERFACE) || node.id?.includes(Constants.EVENT)) {
      node.annotations[0].width = node.id?.includes(Constants.EVENT) ? width - 30 : width - width / 2;

      if (node.id?.includes(Constants.PROCESS_INTERFACE)) {
        node.annotations[0].margin = { right: width / 8 };

        if (node.annotations?.length > 2) {
          node.annotations[2].margin = { right: width / 8 };
        }
      }
    }

    Utils.keepNodeAngles(node, width, height);
    node.width = width;
    node.height = height;
  }

  render() {
    const { t } = this.props;
    const buttonsDialog = this.getButtonsDialog(t);
    const toolbarButtons = this.getToolbarButtons();
    const toolbarLeftButtons = this.getToolbarLeftButtons();
    const toolIcons = this.getToolIcons();
    const zoomIcons = this.getZoomIcons();
    const swimlaneIcon = this.getSwimlaneIcon();
    let formName = '';
    let symbolsPalette = '';
    let attForm = <AttributesForm isLoading={this.state.loading || this.state.loadingAttributes} />;
    let recommendationAttForm = <AttributesForm isLoading={this.state.loading || this.state.loadingAttributes} />;
    const isRecommendationDiagramVisible = this.state.activeRecommendationVersion === Constants.RECOMMENDATION_VERSION_TABS[0];
    const isClosedWorkflow = this.props.location.pathname?.includes('closed-workflow') || this.isClosedWF;

    if (!this.state.loading) {
      const prevForm = this.getFormData();
      const symbol = this.getNodeSelected();

      if (symbol && symbol.idObjectType) {
        formName = t(`contextualMenu.${Constants.OBJECT_TYPES_BY_ID[symbol.idObjectType]}`);
      } else if (this.state.isObject) {
        // Catalog object
        formName = this.state.selectionObject?.includes(Constants.ROLE)
          ? t('diagram.objects.role')
          : t('diagram.objects.itSystem');
      } else {
        formName = t(symbol ? symbol.type : 'general');
      }

      const formLanguage = this.state.language;
      const currentFieldConfig = (isClosedWorkflow ? this.state.diagramFieldConfig : this.state.fieldConfig)?.[formLanguage];

      attForm = (
        <AttributesForm
          attributesExpandedFullSize={() => this.attributesExpandedFullSize()}
          availableLanguages={this.props.availableLanguages}
          diagramStatus={!this.state.isPublished && this.isDiagramSelected() && this.state.diagramLoaded.status}
          downloadWordFormat={
            this.state.diagramType === Constants.EPC_DIAGRAM && this.state.diagramLoaded?.status !== Constants.AREAS.SANDBOX
          }
          exportDiagram={(extension) => this.exportDiagram(extension)}
          fieldConfig={currentFieldConfig}
          formLanguage={formLanguage}
          formName={formName}
          handleFormChange={(form, isValid) => this.handleFormChange(form, isValid)}
          hideAttributes={this.state.multipleSymbols}
          infoAutoSaved={this.state.savingDiagram ? t('diagram.saving') : t('diagram.lastSaved')}
          isPublished={this.state.isPublished}
          isRecommendation={this.state.diagramLoaded?.recommendationStatusType}
          loadWorkflow={this.state.loadWorkflow}
          previousForm={prevForm}
          savedDate={!this.state.isPublished && this.state.savedDate}
          statusAttributes={this.state.openFullAttributes}
          updateDiagramName={(name) => this.updateDiagramName(name, false)}
          updateLinkage={(newLink) => this.updateLinkage(newLink)}
          updateResponsibility={(value) => this.roleResponsibilityChanged(value)}
          workflowData={this.state.workflowDataForm}
          workflowFieldConfig={this.state.workflowAttributes}
        />
      );

      if (isRecommendationDiagramVisible) {
        const recommendationFieldConfig = { controls: {} };

        if (this.state.fieldConfig[this.state.language]) {
          Object.keys(this.state.fieldConfig[this.state.language].controls).forEach((attr) => {
            recommendationFieldConfig.controls[attr] = {
              ...this.state.fieldConfig[this.state.language].controls[attr],
              meta: {
                ...this.state.fieldConfig[this.state.language].controls[attr].meta,
                attributeType: 'S',
                disabled: true,
              },
            };
          });
        }

        recommendationAttForm = (
          <AttributesForm
            attributesExpandedFullSize={() => this.attributesExpandedFullSize()}
            exportDiagram={() => this.exportRecommendationDiagram()}
            fieldConfig={recommendationFieldConfig}
            formName={formName}
            isRecommendationReworked={this.isDiagramSelected()}
            previousForm={prevForm}
            recommendedMessage={this.isDiagramSelected() && this.state.recommendedMessage}
            statusAttributes={this.state.openFullAttributes}
          />
        );
      }

      if (!this.state.isWorkflow && !isClosedWorkflow && this.state.symbolsPalette?.length > 0) {
        symbolsPalette = (
          <SymbolPalette
            changeTool={(tool, image) => this.handlePaletteToolChange(tool, image)}
            diagramType={this.state.diagramType}
            idDiagram={this.state.diagramId}
            key={this.state.symbolsPalette[0].id}
            loadingImage={(isLoading) => this.toggleLoadingImage(isLoading)}
            onInsertImage={(imageUrl) => this.onInsertImage(imageUrl)}
            optimizeConnector={() => this.switchConnectorOptimization()}
            showShapes={this.state.showShapes}
            symbolsPalette={this.state.symbolsPalette}
            toolSelected={this.state.paletteToolSelected}
            version={this.state.attributes?.[Constants.NOT_TRANSLATABLE]?.VERSION}
          />
        );
      }
    }

    if (this.state.isWorkflow && this.workflowBar === '') {
      this.workflowBar = (
        <WorkflowBar key="loadingWF" loading returnLoading={(isLoading) => this.setState({ loadingWorkflowBar: isLoading })} />
      );
    }

    return (
      <>
        {/* <Beforeunload onBeforeunload={(event) => event.preventDefault()} /> */}
        <TitleBar hiddenWarning={!this.state.isPublished} loading={this.isDiagramLoading()} />
        <Toolbar
          buttons={toolbarButtons}
          diagramId={this.state.diagramId}
          diagramLanguages={DiagramUtils.getDiagramLanguages(
            isRecommendationDiagramVisible ? this.state.recommendationAttributes : this.state.attributes,
            this.props.availableLanguages,
          )}
          diagramType={this.state.diagramType}
          disabled={this.isDiagramLoading()}
          disabledLanguageStatus={
            isRecommendationDiagramVisible
              ? this.state.diagramLoaded?.recommendationStatusType
              : this.state.isPublished && this.state.diagramLoaded?.status
          }
          getLanguage={(lng) => this.setLanguage(lng)}
          inFavorite={this.state.inFavorite}
          isClosedWorkflow={isClosedWorkflow}
          isDiagram
          isPublished={this.state.isPublished}
          isWorkflow={this.state.isWorkflow}
          language={this.state.language}
          leftButtons={toolbarLeftButtons}
          objectSelected={this.state.isObject}
          onlyRead={this.state.onlyRead}
          onlyReadReason={this.state.onlyReadReason}
          openDialog={(showDialog) => this.setState({ [showDialog]: true })}
          setFavorites={() => this.toggleFavorites()}
          showLanguageMenu={
            this.state.diagramType === Constants.EPC_DIAGRAM &&
            this.state.isModelingEditable &&
            (this.context.checkRole(Constants.QI_MODELER) || this.context.checkRole(Constants.MODELER))
          }
          swimlaneIcon={swimlaneIcon}
          toolbarType={this.isSandbox ? 'Sandbox' : ''}
          toolIcons={toolIcons}
          userBlock={this.state.userBlock}
          zoomIcons={zoomIcons}
        />
        <div className={`diagram-space ${this.state.isWorkflow ? 'workflow' : ''}  ${this.state.isPublished ? 'published' : ''}`}>
          {this.isDiagramLoading() && (
            <div className="diagram-loading-layer">
              <Spinner isVisible />
            </div>
          )}
          {this.context.checkPermission('explorerRead') && (
            <div className={`diagramExplorer ${this.state.explorerOpen}`} id="diagramExplorer-space">
              {this.state.explorerOpen && (
                <div className="measuringWrapper">
                  {this.isSandbox ? (
                    <SandboxDraft
                      addSandbox={(type) => this.createSandbox(type)}
                      diagramId={this.state.diagramId}
                      disabled={this.isDiagramLoading()}
                      handleClick={(event) => this.clickOnNode(event)}
                      handleSearch={(text) =>
                        this.setState({
                          sandboxSearch: SandboxUtils.searchSandbox(text, this.state.sandboxTree, this.state.language),
                        })
                      }
                      isLateralPanel
                      loadingSandbox={this.state.loadingTree}
                      results={this.state.sandboxSearch}
                      sandboxTree={this.state.sandboxTree}
                    />
                  ) : (
                    <DiagramExplorer
                      handleClick={(event) => this.clickOnNode(event.nodeData.id)}
                      lang={this.state.language}
                      loadingTree={this.state.loadingTreeData || this.state.loadingTree}
                      tree={this.state.treeExplorer}
                    />
                  )}
                </div>
              )}
              <div className="toggleButton" id="explorerExpanded" onClick={this.toggleExplorer.bind(this)}>
                <i className="fas fa-angle-double-right" />
              </div>
            </div>
          )}
          <div
            className={`diagram-content
                ${isRecommendationDiagramVisible ? '' : this.state.blockedDiagramClass} ${this.state.attributesOpen}
                ${this.isDiagramOnlyRead() ? onlyReadClass : ''}
                ${this.state.copiedNodeStyles ? copyNodeStyleClass : ''}`}
          >
            <div
              className="breadCrumb"
              id="bread-crumb"
              style={{ visibility: this.isClosedWF || isRecommendationDiagramVisible ? 'hidden' : 'visible' }}
            >
              {this.state.loadingTreeData && this.state.diagramLoaded?.idDiagramParent !== 0 ? (
                <div className="breadCrumbPlaceholder" />
              ) : (
                <span>{this.state.breadcrumb}</span>
              )}
            </div>
            {Utils.getSwimlaneNode(diagramInstance)?.visible && !this.state.showFormattingTools && (
              <SwimlaneTools diagramInstance={diagramInstance} toggleCanSelect={this.toggleCanSelect} />
            )}
            {diagramInstance?.selectedItems.nodes?.length === 1 && this.state.showFormattingTools && (
              <FormattingTools
                isText={diagramInstance.selectedItems.nodes[0].shape.type.toUpperCase() === Constants.TEXT}
                node={diagramInstance.selectedItems.nodes[0]}
                resizeNode={(symbolId, symbolText) => {
                  diagramInstance.dataBind();
                  this.updateSymbolSize(symbolId, symbolText);
                }}
                setCopiedNodeStyles={(value) => this.setState({ copiedNodeStyles: value })}
              />
            )}
            <DiagramComponent
              collectionChange={(args) => this.handleDiagramCollectionChange(args)}
              commandManager={this.getCommandsManager()}
              connectionChange={(args) => this.handleDiagramConnectionChange(args)}
              contextMenuClick={(args) => this.orderMenu(args)}
              contextMenuOpen={(args) => this.contextMenuOpen(args)}
              contextMenuSettings={DiagramUtils.getOrderContextMenu(this.props.t)}
              doubleClick={(args) => this.handleDoubleClick(args)}
              dragEnter={DiagramUtils.handleDiagramDragEnter}
              drop={(args) => this.handleDiagramDrop(args)}
              getConnectorDefaults={DiagramUtils.getConnectorDefaults}
              getCustomTool={getTool}
              getNodeDefaults={(node) => Utils.getNodeDefaults(node)}
              historyChange={(e) => this.historyChange(e)}
              id="diagram"
              layout={{
                type: 'None',
                enableAnimation: true,
                connectionPointOrigin: 'SamePoint',
                arrangement: 'Nonlinear',
                enableRouting: false,
              }}
              positionChange={(args) => this.handleDiagramPositionChange(args)}
              ref={(diagram) => (diagramInstance = diagram)}
              scrollSettings={DiagramUtils.scrollSettings}
              selectedItems={{
                constraints: this.getSelectedItemsConstraints(),
                userHandles: this.getUserHandles(),
              }}
              selectionChange={(args) => this.handleDiagramSelectionChange(args)}
              sizeChange={(e) => this.handleResizeSymbol(e)}
              snapSettings={{
                constraints:
                  (this.state.onlyRead && !this.state.diagramLoaded?.catalogObjectsMerge && ~SnapConstraints.All) ||
                  SnapConstraints.All & ~SnapConstraints.SnapToLines,
                horizontalGridlines: gridlines,
                verticalGridlines: gridlines,
              }}
              style={{ display: isRecommendationDiagramVisible ? 'none' : 'block' }}
              textEdit={(args) => this.handleTextEdit(args)}
            >
              <Inject services={this.state.injectServices} />
            </DiagramComponent>
            {!isRecommendationDiagramVisible && (
              <div className={`palette ${this.state.blockedDiagramClass} ${this.state.attributesOpen}`} id="palette-space">
                {!this.state.isWorkflow && symbolsPalette}
              </div>
            )}
            {this.state.diagramLoaded?.isRecommendationActive && (
              <DiagramComponent
                id="recommendationDiagram"
                positionChange={(args) => this.handleDiagramPositionChange(args)}
                ref={(recommendationDiagram) => (recommendationDiagramInstance = recommendationDiagram)}
                scrollSettings={DiagramUtils.scrollSettings}
                selectionChange={(args) => this.handleDiagramSelectionChange(args)}
                style={{ display: isRecommendationDiagramVisible ? 'block' : 'none' }}
              >
                <Inject services={this.state.injectServices} />
              </DiagramComponent>
            )}
          </div>
          <div className={`attributes ${this.state.attributesOpen} ${this.state.openFullAttributes}`} id="attributes-space">
            <div className="toggleButton" id="attributesExpanded" onClick={this.toggleAttributes.bind(this)}>
              <i className="fas fa-angle-double-left" />
            </div>
            {this.state.attributesOpen && (
              <div className="measuringWrapper">{isRecommendationDiagramVisible ? recommendationAttForm : attForm}</div>
            )}
          </div>
        </div>
        {(this.state.isWorkflow || this.isClosedWF) && this.workflowBar}

        <div hidden={this.hideBtn} id="cmcontainer">
          <ContextMenuComponent
            beforeOpen={() => this.beforeOpen()}
            id="contextmenu"
            items={this.menuItems}
            ref={(scope) => (cMenu = scope)}
            select={this.select}
          />
        </div>

        <div id="dialog">
          {(this.state.showDialogRequestRole || this.state.showDialogRequestITSystem) && (
            <DialogObjectCatalog
              close={() =>
                this.setState({
                  [this.state.showDialogRequestRole ? 'showDialogRequestRole' : 'showDialogRequestITSystem']: false,
                })
              }
              dialogType={Constants.CATALOG_OBJECT_ACTIONS.REQUEST}
              objectType={this.state.showDialogRequestRole ? Constants.ROLE : Constants.IT_SYSTEM}
              parentCallback={(showDialog) =>
                this.setObjectCatalogDialog(
                  !showDialog,
                  this.state.showDialogRequestRole ? Constants.ROLE_ID : Constants.IT_SYSTEM_ID,
                )
              }
              pendingObjects={
                this.state.showDialogRequestRole
                  ? this.state.catalogObjects.ROLE.pending
                  : this.state.catalogObjects.IT_SYSTEM.pending
              }
              showRequestedRoles={this.state.showDialogRequestRole || this.state.showDialogRequestITSystem}
            />
          )}
          {this.state.showDialogFormError && (
            <DialogFormError
              catalogObjects={this.state.objectsCatalogUsedDiagram}
              formErrorResponseBody={this.state.formErrorResponseBody}
              handleConfirm={() => this.setState({ showDialogFormError: false })}
              language={this.state.language}
              objectsUsed={this.state.objectsUsedDiagram}
              symbols={this.state.symbols}
            />
          )}
          {this.state.showDialogDetail && (
            <DialogDetail
              close={() => this.setState({ showDialogDetail: false })}
              createCatalogObjects={(newObjects, dataObjects) => this.createObjects(newObjects, dataObjects)}
              language={this.state.language}
              objectAttributes={this.state.dialogAttributes}
              objectsUsedDiagram={this.state.objectsCatalogUsedDiagram}
              parentCallback={(showDialog, typeId) => this.setObjectCatalogDialog(showDialog, typeId)}
              refAssignment={(dialog) => (this.detailDialogInstance = dialog)}
              symbolSelected={this.state.symbols.filter((elem) => elem.idSymbolFront === this.state.symbolSelected)}
              visible={this.state.showDialogDetail}
            />
          )}
          {this.state.showObjectDialog && (
            <ObjectDialog
              close={() => this.setState({ showObjectDialog: false })}
              createObjects={(newObjects) => this.createObjects(newObjects)}
              language={this.state.language}
              objectAttributes={this.state.dialogAttributes}
              objectsUsedDiagram={this.state.objectsUsedDiagram}
              refAssignment={(dialog) => (this.detailDialogInstance = dialog)}
              visible={this.state.showObjectDialog}
            />
          )}
          {this.state.showAssignUserDialog && (
            <AssignUserDialog
              assignUsers={
                this.contextMenuOptionSelected === Constants.NEW_NEPOS_SWIMLANE
                  ? (users) => this.saveParentDiagram(users.map((user) => user.Code))
                  : (modelers) => this.assignModelers(modelers)
              }
              close={() =>
                this.setState({
                  showAssignUserDialog: false,
                  linkDiagram: '',
                })
              }
              contextMenuOptionSelected={this.contextMenuOptionSelected}
              linkDiagram={this.state.linkDiagram ? () => this.state.linkDiagram(this.state.idImportedDiagram) : ''}
              refAssignment={(dialog) => (this.detailDialogInstance = dialog)}
            />
          )}
          {this.state.showLinkDiagramDialog && (
            <LinkDiagramDialog
              assignModelers={(idImportedDiagram) => this.assignModelersSandbox(idImportedDiagram)}
              buttonText={this.state.buttonText}
              close={() => this.setState({ showLinkDiagramDialog: false })}
              diagramList={
                this.contextMenuOptionSelected === Constants.IMPORT
                  ? this.mapTreeDiagrams(this.state.sandboxTree)
                  : this.state.linkDiagramSearch
              }
              dialogTitle={this.state.dialogTitle}
              isLoading={this.state.loadingSandboxTree}
              lang={this.state.language}
              linkDiagram={this.state.linkDiagram}
            />
          )}
          {this.state.showDialogAddRole && (
            <DialogAddRole
              close={() => this.setState({ showDialogAddRole: false })}
              createCatalogObjects={(newObjects) => this.createObjects(newObjects)}
              icon={this.state.dialogAttributes.icon}
              parentCallback={(button) => this.setAddRoleDialogStep(button)}
              refAssignment={(dialog) => (this.detailDialogInstance = dialog)}
              roles={this.state.rolesToBeAdded}
              title={this.state.dialogAttributes.title}
              visible={this.state.showDialogAddRole}
            />
          )}
          {this.state.showDialogOk && (
            <DialogComponent
              buttons={buttonsDialog}
              close={() => this.setState({ showDialogOk: false })}
              content={this.state.contentDialog}
              header={this.state.headerDialog}
              height={this.state.heightDialog}
              id="modalDialog"
              isModal
              target="#dialog"
              visible={this.state.showDialogOk}
              width={this.state.widthDialog}
            />
          )}
          {this.state.showTransferTask && (
            <FormDialog
              actionButtonId="Transfer"
              actionName={t('tool.transfer')}
              close={() => this.setState({ showTransferTask: false })}
              closeDialog={() => this.closeTransferTask()}
              id="transferTaskDialog"
              info={t('transferTaskInfo')}
              returnAction={() => this.sendTransferTask()}
              title={t('tool.transferTask')}
              value={this.state.userTransferTask}
              visible={this.state.showTransferTask}
            >
              {this.state.currentStage?.includes(Constants.QA_INSPECTOR_GROUP) ? (
                <DropdownForm
                  meta={{
                    placeholder: `${t('insertNew')}${t(`workflowDialog.${this.state.currentStage}`)}`,
                    options: this.props.formTypes[
                      this.props.getFormTypesCode({
                        status: this.state.diagramLoaded.status,
                        type: Constants.DIAGRAM,
                        variant: this.state.diagramType,
                      })
                    ][Constants.NOT_TRANSLATABLE][Constants.QA_INSPECTOR_GROUP].properties.OPTIONS.replace('[', '')
                      .replace(']', '')
                      .split(', ')
                      .map((option) => ({ text: option, value: option, selected: false })),
                  }}
                  setValue={(newValue) => this.setTransferTask(newValue)}
                  value={this.state.userTransferTask}
                />
              ) : (
                <Multiselection
                  meta={{
                    placeholder: `${t('insertNew')}${t(`workflowDialog.${this.state.currentStage}`)}`,
                    id: this.state.currentStage,
                    data: [],
                    attributeType: 'M',
                    label: t(`workflowDialog.${this.state.currentStage}`),
                    options: [],
                    valuesLoaded: [],
                    max: 1,
                    searchBy: Constants.SEARCH_BY.USER,
                  }}
                  patchValue={(newValue) => this.setTransferTask(newValue)}
                  value={this.state.userTransferTask}
                />
              )}
            </FormDialog>
          )}
          {this.state.showDialogHistory && (
            <DialogHistory
              close={() => this.setState({ showDialogHistory: false })}
              dataHistory={this.state.dataHistory}
              language={this.state.language}
              processNumber={this.state.processNumber}
              refAssignment={(dialog) => (this.detailDialogInstance = dialog)}
            />
          )}
          {this.state.showDeleteDiagramDialog && (
            <DialogDeleteDiagram
              close={() => this.closeDeleteDiagramDialog()}
              confirmationValue={this.state.confirmationValue}
              confirmDelete={() => this.confirmDelete()}
              diagramType={this.state.diagramType}
              onChange={(value) => this.setState({ confirmationValue: value })}
              processName={this.state.attributes[this.state.language].PROCESS_NAME}
            />
          )}
          {this.state.showSwimlaneDialog && (
            <SwimlaneDialog
              close={() => this.setState({ showSwimlaneDialog: false })}
              createSwimlane={(rowsNumber, orientation) => this.createSwimlane(rowsNumber, orientation)}
              language={this.state.language}
            />
          )}
          {this.state.showExportDialog && (
            <ExportDialog
              close={() => this.setState({ showExportDialog: false })}
              diagramId={this.state.diagramId}
              errorSavingDiagram={(error) => this.errorSavingDiagram(error)}
              saveDiagram={() => this.saveDiagram()}
              setDiagramIsSaved={() => this.setState({ savingDiagram: false, loading: false })}
              setDiagramLoading={(value) => this.setState({ loading: value })}
            />
          )}
          <div id="recommend-to-location-dialog">
            <DialogRecommendToLocation
              attributes={this.state.attributes}
              close={() => this.setState({ showRecommendToLocationDialog: false })}
              diagramId={this.state.diagramId}
              isVisible={this.state.showRecommendToLocationDialog}
              processNumber={this.state.processNumber}
              version={this.state.attributes?.[Constants.NOT_TRANSLATABLE]?.VERSION}
            />
          </div>
          {!this.isDiagramLoading() && this.state.isCatalogObjectsMergeDialogOpen && (
            <DialogWarning
              confirmText={t('ok')}
              handleConfirm={() => this.catalogObjectsMerge()}
              title={t(
                'catalogObjectsMerge.diagramDialogWarning.title',
                this.props.i18n.language === Constants.ENGLISH
                  ? 'Refreshment of catalogue objects'
                  : 'Aktualisierung der Katalogobjekte',
              )}
            >
              {t(
                'catalogObjectsMerge.diagramDialogWarning.message',
                this.props.i18n.language === Constants.ENGLISH
                  ? 'Some of the catalogue objects (roles/it-systems) used in this diagram have been merged by the quality inspectors from your department. Please click "ok" and dont close the window until the automatic refreshment from the catalogue has been applied and saved.'
                  : 'Katalogobjekte (Rollen/IT-Systeme), welche in diesem Prozess verwendet werden, wurden von Qualitätsprüfern im zentralen Stammdatenkatalog angepasst (z.B. gleichbenannte Rollen zusammengeführt). Um die notwendige automatische Aktualisierung dieser Objekte durchzuführen, bestätigen Sie die dieses Fenster bitte mit "ok" und schließen Sie es nicht, bis die Aktualisierung durchgeführt wurde.',
              )}
            </DialogWarning>
          )}
        </div>
      </>
    );
  }
}
Diagram.contextType = AuthContext;

export default withTranslation('common')(Diagram);
