import { useTranslation } from 'react-i18next';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';

import { getCurrentPlant } from 'assets/js/serviceUtils';
import WarningMessage from 'components/UI/WarningMessage/WarningMessage';
import useFeatureFlags from 'hooks/useFeatureFlags';

import { ENVIRONMENTS, VAN } from '../../assets/constants/constants';
import mbcMapImg from '../../assets/images/MBC_MAP_logo.png';
import mbvMapImg from '../../assets/images/MBV_MAP_logo.png';
import useAuth from '../../hooks/useAuth';
import SearchBar from '../SearchBar/SearchBar';
import UserNavigator from '../UserNavigator/UserNavigator';
import styles from './TitleBar.module.scss';

const TitleBar = (props) => {
  const { isLoading, blank = false, hiddenWarning } = props;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const featureToggle = useFeatureFlags();
  const { checkPermission, checkRole, isMaintenance, userInfo } = useAuth();
  const hideTitleOption = blank || isMaintenance;
  const className = isLoading ? styles.LoadingClass : '';
  const env = process.env.REACT_APP_ENV || 'development';
  const environment = env !== ENVIRONMENTS.PRO ? env.toUpperCase().substring(0, 3) : '';
  const mbcMapLogo = getCurrentPlant() === VAN ? mbvMapImg : mbcMapImg;
  const handleClick = (e, route) => {
    if (isLoading || location.pathname === route) {
      e.preventDefault();
    }
  };

  const sendSearch = (text) => {
    history.push({
      pathname: '/search',
      search: `?text=${text}`,
    });
  };

  return (
    <>
      {hiddenWarning ? '' : <WarningMessage />}
      <div className={`${styles.TitleBar} ${className}`}>
        <div className={styles.Logo}>
          <Link onClick={(e) => handleClick(e, '/dashboard')} to="/dashboard">
            <img alt="MBC MAP Logo" id="logo" src={mbcMapLogo} />
          </Link>
          <span className={styles.TextLogo}> {environment}</span>
        </div>
        <div className={styles.NavigationButtons}>
          <NavLink
            activeClassName={styles.LinkSelected}
            className={styles.Link}
            onClick={(e) => handleClick(e, '/dashboard')}
            to="/dashboard"
          >
            {t('home')}
          </NavLink>
          {!hideTitleOption && (checkPermission('epcRead') || userInfo?.neposInvolve) && (
            <NavLink
              activeClassName={styles.LinkSelected}
              className={styles.Link}
              isActive={(match, navLocation) => navLocation.pathname.includes('diagram')}
              onClick={(e) => handleClick(e, '/diagram/1')}
              to="/diagram/1"
            >
              {t('modeling')}
            </NavLink>
          )}
          {!hideTitleOption && checkRole('USER') && (
            <NavLink
              activeClassName={styles.LinkSelected}
              className={styles.Link}
              isActive={(match, navLocation) =>
                navLocation.pathname.includes('published') && !navLocation.pathname.includes('dataset')
              }
              onClick={(e) => handleClick(e, '/published/1')}
              to="/published/1"
            >
              {t('processMap')}
            </NavLink>
          )}
          {featureToggle.isDepartmentViewEnabled && (
            <NavLink
              activeClassName={styles.LinkSelected}
              className={styles.Link}
              onClick={(e) => handleClick(e, '/department-view')}
              to="/department-view"
            >
              {t('departmentView.title')}
            </NavLink>
          )}
          {!hideTitleOption && checkPermission('vcdWrite') && (
            <NavLink
              activeClassName={styles.LinkSelected}
              className={styles.Link}
              onClick={(e) => handleClick(e, '/administration')}
              to="/administration"
            >
              {t('administration.name')}
            </NavLink>
          )}
        </div>
        <div className={styles.Wrapper}>
          {!hideTitleOption && checkRole('USER') && !location.pathname.includes('/search') && (
            <SearchBar loading={isLoading} sendSearch={(text) => sendSearch(text)} />
          )}
          <UserNavigator loading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default TitleBar;
