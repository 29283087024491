import { ObjectIOSubTypes, ObjectTypes, Role } from 'types/administration';
import { Language } from 'types/config';
import { Coordinates, Dimensions } from 'types/diagram';
import { Attributes } from 'types/forms';

import { ProcessSupplierCustomer } from './supplierCustomer';

export enum SymbolTypes {
  AND_GATE = 'AND_GATE',
  EVENT = 'EVENT_SWIMLANE',
  OR_GATE = 'OR_GATE',
  PROCESS_INTERFACE = 'PROCESS_INTERFACE',
  PROCESS_STEP = 'PROCESS_STEP',
  TEXT = 'TEXT',
  XOR_GATE = 'XOR_GATE',
  SIPOC_ELEMENT = 'SIPOC_ELEMENT',
  PALETTE_TEXTFIELD = 'PALETTE_TEXTFIELD',
  SWIMLANE_ELEMENT = 'SWIMLANE_ELEMENT',
}

export enum PostItTypes {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  E2E_PROCESS_OWNER = 'E2E_PROCESS_OWNER',
  IT_SYSTEM = 'IT_SYSTEM',
  PAINPOINTS = 'PAINPOINTS',
  KPI = 'KPI',
  POTENTIAL_ASSESSMENTS = 'POTENTIAL_ASSESSMENTS',
}

export enum SymbolActions {
  CREATE = 'CREATE',
  IMPORT = 'IMPORT',
  MOVE = 'MOVE',
  CLOSE = 'CLOSE',
  LINK = 'LINK',
}

export type SelectionButton = {
  children?: React.ReactNode;
  extraClass?: string;
  icon?: string;
  id: string;
  isHidden?: boolean;
  onClick: (() => void) | undefined;
  selected?: boolean;
  tooltipLabel?: string;
};

export type AlignType = SymbolStyle.ALIGN_LEFT | SymbolStyle.ALIGN_CENTER | SymbolStyle.ALIGN_RIGHT;

export type SymbolFormat = {
  align?: AlignType;
  backgroundColor?: string;
  borderColor?: string;
  fontSize?: number;
  fontColor?: string;
  isBold?: boolean;
  isItalic?: boolean;
  isUnderline?: boolean;
};

export type SymbolMeta = Dimensions & Coordinates & SymbolFormat;

export type PostIt = {
  text: string;
  linkage?: {
    title:
      | {
          [key in Language]?: string | undefined;
        }
      | string;
    id?: number | string;
    text?: string;
  };
  order: number;
  id: string;
};

export type PostItCategory = { [key in PostItTypes]?: PostIt[] };

export type Symbol = {
  activitySpecificationId?: number;
  attributes: Attributes;
  id: string;
  meta: SymbolMeta;
  customer?: ProcessSupplierCustomer;
  supplier?: ProcessSupplierCustomer;
  type: SymbolTypes;
  objects?: SymbolObject[];
  postIts?: { [key in PostItTypes]?: PostIt[] };
  diagramLink?: {
    type: string;
    id: number;
    childType: string;
    processNumber?: string;
  };
  handleOptionClick?: (action?: SymbolActions, symbolId?: string) => void;
  withinSymbolBoard?: boolean;
  offset?: {
    x: number;
    y: number;
  };
  swimlaneId?: string;
  isNew?: boolean;
};

export interface ProcessStepSymbol {
  activitySpecification: ActivitySpecification;
  attributes: Attributes;
  id: string;
  meta: SymbolMeta;
  type: SymbolTypes;
  objects?: SymbolObject[];
}
export interface ActivityProcessesSymbol extends Symbol {
  role: Role | null;
  isSelected: boolean;
}

export type ActivityProcesses = {
  checked: boolean;
  id: string;
  processStepName: any;
  role: string;
};

export type ActivitySpecification = {
  id: number;
  attributes: Attributes;
  predecessors: string[];
  successors: string[];
};

export type TextFieldNEPOS = {
  attributes: Attributes;
  id: string;
  meta: SymbolMeta;
  type: SymbolTypes;
};

export type SymbolObject = {
  id?: number;
  approved?: boolean;
  temporaryText?: string;
  type: ObjectTypes;
  subtype?: ObjectIOSubTypes;
  attributes?: Attributes;
  responsability?: number;
  meta: {
    pos: number;
  };
};

export type SwimlanePaletteItemProps = {
  id: string;
  key: string;
  activitySpecificationId?: number;
  isPaletteItem: boolean;
  attributes?: Attributes;
  meta?: SymbolMeta;
  type?: SymbolTypes;
  objects?: SymbolObject[];
  swimlaneId?: string;
  diagramLink?: {
    type: string;
    id: number;
    childType: string;
    processNumber?: string;
  };
};

export enum ProcessStepObjectsOrderType {
  ROLE_SWIMLANE_RESPONSIBLE = 'ROLE_SWIMLANE_RESPONSIBLE',
  ROLE_SWIMLANE_CONSULTED = 'ROLE_SWIMLANE_CONSULTED',
  ROLE_SWIMLANE_INFORMED = 'ROLE_SWIMLANE_INFORMED',
  ROLE_SWIMLANE_ACCOUNTABLE = 'ROLE_SWIMLANE_ACCOUNTABLE',
  ROLE_SWIMLANE_ACCEPTANCE = 'ROLE_SWIMLANE_ACCEPTANCE',
  ROLE_SWIMLANE_RQ_RESPONSIBLE = 'ROLE_SWIMLANE_RQ_RESPONSIBLE',
  ROLE_SWIMLANE_RQ_CONSULTED = 'ROLE_SWIMLANE_RQ_CONSULTED',
  ROLE_SWIMLANE_RQ_INFORMED = 'ROLE_SWIMLANE_RQ_INFORMED',
  ROLE_SWIMLANE_RQ_ACCOUNTABLE = 'ROLE_SWIMLANE_RQ_ACCOUNTABLE',
  ROLE_SWIMLANE_RQ_ACCEPTANCE = 'ROLE_SWIMLANE_RQ_ACCEPTANCE',
  ROLE_SWIMLANE_TEMPORARY = 'ROLE_SWIMLANE_TEMPORARY',
  IT_SYSTEM_SWIMLANE = 'IT_SYSTEM_SWIMLANE',
  IT_SYSTEM_SWIMLANE_REQUEST = 'IT_SYSTEM_SWIMLANE_REQUEST',
  IT_SYSTEM_SWIMLANE_TEMPORARY = 'IT_SYSTEM_SWIMLANE_TEMPORARY',
  INPUT = 'INPUT',
  INPUT_NEW = 'INPUT_NEW',
  OUTPUT = 'OUTPUT',
  OUTPUT_NEW = 'OUTPUT_NEW',
}

export const ORDER_OBJECTS_PROCESS_STEP: ProcessStepObjectsOrderType[] = [
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RESPONSIBLE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_CONSULTED,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_INFORMED,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_ACCOUNTABLE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_ACCEPTANCE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_RESPONSIBLE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_CONSULTED,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_INFORMED,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_ACCOUNTABLE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_RQ_ACCEPTANCE,
  ProcessStepObjectsOrderType.ROLE_SWIMLANE_TEMPORARY,
  ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE,
  ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE_REQUEST,
  ProcessStepObjectsOrderType.IT_SYSTEM_SWIMLANE_TEMPORARY,
  ProcessStepObjectsOrderType.INPUT,
  ProcessStepObjectsOrderType.INPUT_NEW,
  ProcessStepObjectsOrderType.OUTPUT,
  ProcessStepObjectsOrderType.OUTPUT_NEW,
];

export enum SymbolStyle {
  ALIGN_LEFT = 'left',
  ALIGN_CENTER = 'center',
  ALIGN_RIGHT = 'right',
  BOLD = 'boldStyle',
  BORDER_COLOR_POPUP = 'borderColor',
  FILL_COLOR_POPUP = 'fillColor',
  ITALIC = 'italicStyle',
  TEXT_COLOR_POPUP = 'textColor',
  UNDERLINE = 'underlineStyle',
  TEXT_SIZE = 'textSize',
}
