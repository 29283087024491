import { DEFAULT_PAGE_SIZE } from 'assets/constants/constants';
import { TableProps, TableVariant } from 'types/tables';

import SearchNEPOS from '../SearchNEPOS/SearchNEPOS';
import Table from '../Table/Table';

type Props = {
  buttonText?: string;
  hasDropdown?: boolean;
  id: string;
  isAsync?: boolean;
  onClick?: () => void;
  onClickDropdownItem?: (item: string) => void;
  searching: (text: string) => void;
  sendSearch?: (text: string) => void;
};

const TableWithSearch = <T extends { id: string; onClick?: () => void; checked?: boolean }>(props: Props & TableProps<T>) => {
  const {
    buttonText,
    columns,
    getPage = () => {},
    hasDropdown = false,
    id,
    isAsync = false,
    multiselect = true,
    onCheck = () => {},
    onClick,
    onClickDropdownItem = () => {},
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    pagination,
    rows,
    searching,
    sendSearch,
    totalPages = 1,
    variant = TableVariant.PLAIN,
    isLoading,
    onRowClick,
    selectedRow,
  } = props;

  return (
    <>
      <SearchNEPOS
        buttonText={buttonText}
        hasDropdown={hasDropdown}
        id={`${id}-table-search`}
        isAsync={isAsync}
        onClick={onClick}
        onClickDropdownItem={onClickDropdownItem}
        searching={searching}
        sendSearch={sendSearch}
      />
      <Table
        columns={columns}
        getPage={getPage}
        isLoading={isLoading}
        multiselect={multiselect}
        onCheck={onCheck}
        onClickIcon={() => {}}
        onRowClick={onRowClick}
        page={page}
        pageSize={pageSize}
        pagination={pagination}
        prefix={`${id}.`}
        rows={rows}
        selectedRow={selectedRow}
        totalPages={totalPages}
        variant={variant}
      />
    </>
  );
};

export default TableWithSearch;
