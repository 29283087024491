import { ReactNode, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

type PortalProps = {
  children: ReactNode;
  root: HTMLDivElement | null;
};

const Portal = ({ children, root }: PortalProps) => {
  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    if (!root) return;

    root.appendChild(portal.current);
    const portalNode = portal.current;

    return () => portalNode.remove();
  }, [root]);

  return createPortal(children, portal.current);
};

export default Portal;
