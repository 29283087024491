import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './Profile.module.scss';
import '../../App.scss';

class Profile extends React.PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.Profile}>
        <div className={styles.Block} id="category">
          <h4>{t('settings.myProfile')}</h4>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(Profile);
