import * as serviceUtils from '../assets/js/serviceUtils';
import { axiosInstance } from './serviceConstants';

const communicationUrl = '/backend/ms-communications/api/v1';

const getFilteredLocations = (params) => axiosInstance.get(`${communicationUrl}/plants/filtered/${params}`);

const getLocations = () => axiosInstance.get(`${communicationUrl}/plants`);

export default {
  handleServiceError: serviceUtils.handleServiceError,
  getFilteredLocations,
  getLocations,
};
