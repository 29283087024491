import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TEXTAREA_DEFAULT_LENGTH, TEXTAREA_DEFAULT_ROWS } from 'assets/constants/constants';
import { purify } from 'assets/js/Utils';
import useOuterClick from 'hooks/useOuterClick';

import stylesNepos from './TextAreaNEPOS.module.scss';
import stylesLegacy from './TextAreaNEPOSLegacyStyle.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  id: string;
  label: string;
  legacyStyles?: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  value: string;
  reference?: React.RefObject<HTMLTextAreaElement>;
  containerRef?: React.RefObject<HTMLLabelElement>;
  error?: string;
  onBlur?: () => void;
  maxLength?: number;
  rows?: number;
};

const TextAreaNEPOS = (props: Props) => {
  const {
    className,
    disabled,
    id,
    label,
    legacyStyles,
    onChange,
    required,
    value,
    onKeyDown,
    reference,
    containerRef,
    error,
    onBlur,
    maxLength = TEXTAREA_DEFAULT_LENGTH,
    rows = TEXTAREA_DEFAULT_ROWS,
  } = props;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;
  const newTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const newContainerRef = useRef<HTMLLabelElement>(null);
  const currentTextAreaRef = reference || newTextAreaRef;
  const currentContainerRef = containerRef || newContainerRef;
  const [focused, setFocused] = useState(false);
  const hasError = Boolean(error);
  const isErrorTriggered = legacyStyles ? hasError : hasError && !focused;
  const { t } = useTranslation();

  useOuterClick(currentContainerRef, () => setFocused(false));

  const handleContainerClick = () => {
    if (!currentTextAreaRef.current) return;
    setFocused(true);
    currentTextAreaRef.current.focus();
  };

  return (
    <>
      <label
        className={`${styles.Container} ${focused ? styles.Focused : ''} ${disabled ? styles.Disabled : ''}  ${className || ''} ${
          isErrorTriggered ? styles.Error : ''
        }`}
        htmlFor={id}
        onClick={() => handleContainerClick()}
        ref={currentContainerRef}
      >
        <div className={`${styles.Label} ${styles.Floating} ${isErrorTriggered ? styles.Error : ''}`}>
          {label}
          {required && <span className="error">*</span>}
          {hasError && (
            <i className={`di ${legacyStyles ? 'fas fa-exclamation-circle' : 'icon-blitz-fehler'} error ${styles.IconError}`} />
          )}
        </div>
        <textarea
          className={`${styles.Input} ${isErrorTriggered ? styles.Error : ''}`}
          disabled={disabled}
          id={id}
          key={id}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={(event) => onChange(purify(event.target.value))}
          onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : {})}
          placeholder={t('textfield')}
          ref={currentTextAreaRef}
          rows={rows}
          value={value}
        />
      </label>
      <div className={styles.Count}>
        <span>{value?.length}</span>
        <span> /{maxLength}</span>
      </div>
    </>
  );
};

export default TextAreaNEPOS;
