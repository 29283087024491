import React from 'react';

import FieldTooltip from '../FieldTooltip/FieldTooltip';
import styles from './CheckBox.module.scss';

const CheckBox = ({ handler, meta }) => {
  const mandatory = meta.attributeType === 'M' ? '*' : '';
  const isDisabled = meta.attributeType === 'S' || meta.disabled;

  return (
    <div className={styles.Checkbox}>
      <label htmlFor={meta.id}>
        {meta.label}
        {mandatory}
      </label>
      {meta.description && <FieldTooltip description={meta.description} />}
      <label className={styles.Checkbox} htmlFor={meta.id}>
        <input id={meta.id} {...handler('checkbox')} disabled={isDisabled} />
        <span className={`${styles.Checkmark} ${isDisabled ? styles.Blocked : ''}`} />
      </label>
    </div>
  );
};

export default CheckBox;
