import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { EXPORT_XML, IMPORT_XML, LANGUAGE_MENU_OPTIONS } from 'assets/constants/constants';
import DialogImport from 'components/DialogImport/DialogImport';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useOuterClick from 'hooks/useOuterClick';

import styles from './LanguageMenu.module.scss';

const LanguageMenu = (props) => {
  const { openDialog, diagramId, disabled } = props;
  const { t } = useTranslation();
  const { isFreezed } = useFeatureFlags();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [isDialogImportOpen, setIsDialogImportOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOuterClick(wrapperRef, () => setIsLanguageMenuOpen(false));
  const disabledClass = disabled ? styles.Disabled : '';

  const handleLanguageMenuClick = (option) => {
    if (option === IMPORT_XML) setIsDialogImportOpen(true);
    if (option === EXPORT_XML) openDialog('showExportDialog');
    setIsLanguageMenuOpen(false);
  };

  return (
    <div className={styles.LanguageMenu}>
      {!isFreezed && (
        <i
          className={`di icon-overflow-mehr-vertikal ${disabledClass}`}
          onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
        />
      )}
      {isLanguageMenuOpen && !disabled && (
        <ul ref={wrapperRef}>
          {LANGUAGE_MENU_OPTIONS.map((option) => (
            <li id={option} key={option} onClick={() => handleLanguageMenuClick(option)}>
              {t(`language.menu.${option}`)}
            </li>
          ))}
        </ul>
      )}
      {isDialogImportOpen && <DialogImport close={() => setIsDialogImportOpen(false)} diagramId={diagramId} />}
    </div>
  );
};

export default LanguageMenu;
