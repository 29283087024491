import { getFullFormattedDate } from 'assets/js/Utils';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import Spinner from 'components/UI/Spinner/Spinner';

import styles from './UploadCard.module.scss';

export interface UploadCardI {
  date?: string;
  description?: string;
  errorMessage?: string;
  icon: string;
  id: string;
  isLoading?: boolean;
  title: string;
  onClick?: (event: string) => void;
  onDelete?: (event: string) => void;
}

const UploadCard = ({ date, description, errorMessage, icon, id, isLoading, title, onClick, onDelete }: UploadCardI) => {
  const message = isLoading ? 'Uploading...' : errorMessage;

  return (
    <div
      className={`${styles.Container} ${errorMessage && styles.Error} ${isLoading && styles.Loading} ${
        !isLoading && styles.Pointer
      }`}
      id={id}
      onClick={() => onClick && onClick(id)}
    >
      <div className={styles.Icon}>
        {isLoading ? (
          <div className={styles.UploadingSpinner}>
            <Spinner isVisible />
          </div>
        ) : (
          <i className={`di ${errorMessage ? 'icon-blitz-fehler' : icon}`} />
        )}
      </div>
      <div className={styles.Content}>
        <span className={styles.Title}>{title}</span>
        {(message || description || date) && (
          <div className={styles.Details}>
            {message && <div className={styles.Subdescription}>{message}</div>}
            {description && <div className={styles.Description}>{description}</div>}
            {date && <div className={styles.Subdescription}>{date && getFullFormattedDate(date)}</div>}
          </div>
        )}
      </div>
      {onDelete && (
        <div className={styles.IconBinWrapper}>
          <RoundButton
            className={styles.IconBin}
            icon="icon-muelleimer-loeschen"
            id="bin-icon"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(title);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UploadCard;
