import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import StatusTag from 'components/StatusTag/StatusTag';
import Spinner from 'components/UI/Spinner/Spinner';

import * as Constants from '../../assets/constants/constants';
import * as ServiceUtils from '../../assets/js/serviceUtils';
import * as Utils from '../../assets/js/Utils';
import documentationService from '../../services/documentationService';
import styles from './SearchResult.module.scss';

const ICONS = {
  [Constants.EPC_DIAGRAM_ID]: 'icon-hierarchie-diagramm',
  [Constants.VCD_DIAGRAM_ID]: 'icon-etikett-2',
  [Constants.DATASET_ID]: 'icon-datei',
};

const SearchResult = ({ filterSearch, result, type }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const statusCode = result.status;
  const isHistorical = statusCode === Constants.STATUS.ARCHIVED;
  const navigationClass = isHistorical ? '' : styles.Navigation;
  const diagramIcon = ICONS[type];

  const getDiagramUrl = () => {
    let url = '/diagram/';

    if (filterSearch === Constants.MODELING && statusCode === Constants.STATUS.SANDBOX) {
      url = '/sandbox/';
    } else if (filterSearch === Constants.PUBLISHED) {
      url = '/published/';
    } else if (filterSearch === Constants.DOCUMENT) {
      url = `/dataset-published/`;
    }

    return `${url}${result.id}`;
  };

  const Title = ({ children }) =>
    isHistorical ? (
      <span className={styles.TitleLink}>{children}</span>
    ) : (
      <Link className={styles.TitleLink} to={getDiagramUrl()}>
        {children}
      </Link>
    );

  const downloadPdf = () => {
    setLoadingPdf(true);

    documentationService
      .getDocument(result.processNumber, 'last', ServiceUtils.getUserLanguage())
      .then((res) => {
        Utils.downloadBlob(res.data, res.headers, `${result.title}.pdf`);
      })
      .catch(documentationService.handleServiceError)
      .finally(() => setLoadingPdf(false));
  };

  return (
    <div className={`${styles.Result} ${navigationClass}`} key={result.id}>
      <div className={styles.Title}>
        <i aria-hidden="true" className={`di ${diagramIcon}`} />
        <Title>
          {type === Constants.DATASET_ID && <span className={styles.DatasetId}>{result.id}</span>}
          {result.title}
        </Title>
        <StatusTag code={statusCode} />
        {isHistorical &&
          type !== Constants.DATASET_ID &&
          (loadingPdf ? (
            <span className={styles.PdfLoading}>
              <Spinner isVisible />
            </span>
          ) : (
            <span className={`di ${styles.Icon}`} onClick={downloadPdf}>
              
            </span>
          ))}
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.Description} dangerouslySetInnerHTML={Utils.getDangerouslySetInnerHTML(result.description)} />
    </div>
  );
};

export default withTranslation('common')(SearchResult);
