import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import stylesNepos from './WysiwygNEPOS.module.scss';
import stylesLegacy from './WysiwygNEPOSLegacyStyle.module.scss';

interface Props {
  buttonId: string;
  disabled?: boolean;
  format: string;
  icon: string;
  legacyStyles?: boolean;
  onClick?: () => void;
}

const BlockButton = (props: Props) => {
  const { buttonId, disabled, format, icon, onClick, legacyStyles } = props;
  const styles = legacyStyles ? stylesLegacy : stylesNepos;
  const newEditor = useSlate();
  const LIST_TYPES = ['numbered-list', 'bulleted-list'];

  const isBlockActive = (editor: Editor, textFormat: Partial<SlateElement> | string) => {
    if (textFormat === 'indent' || textFormat === 'outdent') return;
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === textFormat,
      }),
    );

    return !!match;
  };

  const toggleBlock = (editor: Editor, textFormat: string) => {
    const isActive = isBlockActive(editor, textFormat);
    const isList = LIST_TYPES.includes(textFormat);

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });
    const newProperties: Partial<SlateElement> = {
      // eslint-disable-next-line no-nested-ternary
      type: isActive ? 'paragraph' : isList ? 'list-item' : textFormat,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: textFormat, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const handleIndent = (editor: Editor, textFormat: string) => {
    if (textFormat === 'indent') {
      const quote = { type: textFormat, children: [] };
      Transforms.wrapNodes(editor, quote);
    }

    if (textFormat === 'outdent') {
      Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type !== 'outdent',
        split: true,
      });

      const newProperties: Partial<SlateElement> = {
        type: textFormat,
      };
      Transforms.setNodes<SlateElement>(editor, newProperties);
    }
  };

  return (
    <button
      className={`${disabled ? styles.Disabled : ''} ${isBlockActive(newEditor, format) ? styles.Active : ''}`}
      disabled={disabled}
      id={buttonId}
      onMouseDown={(event) => {
        event.preventDefault();
        if (format === 'indent' || format === 'outdent') {
          handleIndent(newEditor, format);
        } else if (format === 'link' && onClick) {
          onClick();
        } else {
          toggleBlock(newEditor, format);
        }
      }}
      type="button"
    >
      <i className={`di ${icon}`} />
    </button>
  );
};

export default BlockButton;
